angular.module('lwNamb').directive(
  'coachPaneProfile',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/coach-pane-profile.html',
        scope: {
          userId: '@',
          paneId: '@',
          orgId: '@',
        },
        replace: true,
        controller: [
          '$scope',
          'profileService',
          'State',
          '$log',
          function($scope, profileService, State, $log) {
            $scope.isLoading = true;

            profileService.getProfile($scope.userId).then(
              function(profile) {
                $scope.profile = profile;
                profileService.getCustomData($scope.orgId, profile.email).then(
                  function(response) {
                    $scope.isLoading = false;
                    $scope.orgFields = response;
                  },
                  function(reason) {
                    $log.error(reason);
                    $scope.isLoading = false;
                  }
                );
              },
              function(reason) {
                $log.error(reason);
                $scope.isLoading = false;
                $scope.isError = true;
              }
            );

            $scope.close = function() {
              State($scope.paneId).clear();
            };
          },
        ],
      };
    },
  ]
);
