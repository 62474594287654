import { Grid, Color, Type } from '../StyleGuide';
import styled from 'styled-components';

const BibleStudyTabs = styled.ul`
  position: relative;
  z-index: 1;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    &:hover {
      border-bottom: 3px solid ${Color.Gray._30};
    }
  }
  li.active-tab {
    border-bottom: 3px solid ${Color.Orange._50};
    > button {
      color: ${Type.Color.dark};
      font-weight: ${Type.Weight.semibold};
    }
  }
  button {
    border: 0;
    background: none;
    padding: ${Grid._4} ${Grid._5};
    text-align: left;
    font-size: ${Type.Scale._4};
    color: ${Type.Color.medium};
    span {
      color: ${Color.Secondary._50};
      margin-right: ${Grid._3};
    }
  }
`;

export default BibleStudyTabs;
