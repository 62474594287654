import React from 'react';
import { PageTitle } from '../../components/Layout';
import { PartialSearchResultItem, SearchProvider, SelectTraining, SelectTrainingProvider } from '../../search';
import { PageContainer } from '../../invites/components/InviteElements';

type Props = {
  preselectedTraining?: PartialSearchResultItem[];
  onAddSelectedTraining: (selectedTraining: PartialSearchResultItem[]) => void | Promise<void>;
  singleSelect?: boolean;
};

export const AddTraining = (props: Props) => {
  return (
    <PageContainer>
      <PageTitle className="mb-40">
        <h1>Add Training</h1>
        <p>Use search to find and select the training that you want to add</p>
      </PageTitle>

      <SelectTrainingProvider {...props}>
        <SearchProvider>
          <SelectTraining />
        </SearchProvider>
      </SelectTrainingProvider>
    </PageContainer>
  );
};
