angular.module('lwNamb').directive(
  'coachPaneFinalReport',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/coach-pane-final-report.html',
        scope: {
          userId: '@',
          paneId: '@',
          orgId: '@',
        },
        replace: true,
        controller: [
          '$scope',
          '$log',
          'State',
          'nambReportService',
          'uuid4',
          'userService',
          'profileService',
          '$q',
          function($scope, $log, State, nambReportService, uuid4, userService, profileService, $q) {
            var fullName = '';
            $scope.isLoading = true;
            $scope.finalReport = {};

            $q.all([
              nambReportService.getScorecardInstances($scope.userId),
              nambReportService.getCPIAInstances($scope.userId, $scope.orgId),
              nambReportService.getFinalReport($scope.userId, $scope.orgId),
              profileService.getProfile($scope.userId),
            ]).then(
              function(values) {
                $scope.scorecards = values[0].data;
                $scope.finalReport.scorecard = $scope.scorecards[0];
                $scope.taskLists = values[1].data;
                $scope.finalReport.taskList = $scope.taskLists[0];
                $scope.latestFinalReport = values[2].data;
                fullName = values[3].firstName + ' ' + values[3].lastName;
                if (Object.keys(values[2].data).length !== 0) {
                  $scope.latestFinalReport.finalReportUrl = nambReportService.generateReportLink(
                    values[2].data.reportUrl,
                    fullName
                  );
                }
                $scope.isLoading = false;
              },
              function(reason) {
                $log.error(reason);
                $scope.isLoading = false;
                $scope.isError = true;
              }
            );

            $scope.generateReport = function() {
              if ($scope.finalReport !== undefined || !$scope.finalReport.isEmpty()) {
                $scope.isReportGenerating = true;
                $scope.isError = false;
                var reportId = uuid4.generate();

                userService.user().then(
                  function(user) {
                    nambReportService
                      .generateFinalReport(
                        reportId,
                        $scope.finalReport.taskList.id,
                        $scope.finalReport.scorecard._id.id,
                        $scope.finalReport.scorecard.groupName,
                        $scope.userId,
                        $scope.orgId,
                        user.userId
                      )
                      .then(
                        function(reportUrl) {
                          $scope.latestFinalReport = {
                            _id: reportId,
                            reportUrl: reportUrl,
                            scorecardListName: $scope.finalReport.scorecard.name,
                            finalReportUrl: nambReportService.generateReportLink(reportUrl, fullName),
                            taskListName: $scope.finalReport.taskList.name,
                          };
                          $scope.isReportGenerating = false;
                        },
                        function(reason) {
                          $log.error(reason);
                          $scope.isError = true;
                          $scope.isReportGenerating = false;
                        }
                      );
                  },
                  function() {
                    $log.error('No user returned in coachPaneFinalReport');
                  }
                );
              }
            };

            var state = State($scope.paneId);
            $scope.selected = state.get();

            $scope.close = function() {
              State($scope.paneId).clear();
              state.put('menu', '');
            };
          },
        ],
      };
    },
  ]
);
