import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';
import { SmallPrimaryButton, SmallWhiteButton } from '../components/Buttons';
import ErrorMessage from '../components/ErrorMessage';
import alertService from '../services/AlertService';

const Form = styled.form`
  border: 1px solid ${Color.Gray._30};
  border-left: ${Grid._3} solid ${Color.Secondary._50};
  padding: ${Grid._4};
  align-items: start;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${Grid._3};
  border-radius: ${Border.radius};
  box-shadow: ${Shadow.light};
  font-size: ${Type.Scale._1};
  background: white;
  color: ${Type.Color.dark};
  position: relative;
  &.has-status {
    padding-top: ${Grid._5};
  }
  label {
    color: ${Type.Color.medium};
    display: flex;
    flex-direction: column;
    text-align: left;
  }
`;

const Input = styled.input`
  padding: ${Grid._3} 12px;
  border-radius: ${Border.radius};
  border: 1px solid ${Color.Gray._30};
  color: ${Type.Color.dark};
  box-shadow: inset ${Shadow.light};
  font-size: ${Type.Scale._2};
  display: block;
  margin-top: ${Grid._3};
  &:disabled {
    background: ${Color.Gray._10};
    box-shadow: none;
    border-color: ${Color.Gray._10};
  }
`;

const StatusContainer = styled.div`
  position: absolute;
  top: 0;
  right: 8px;
  padding: 4px 8px;
  background: ${props => props.bgColor || Color.Navy._50};
  border-radius: 0 0 4px 4px;
  color: white;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
`;

const Status = ({ status }) => {
  if (status === 'in_progress') {
    return <StatusContainer bgColor={Color.Blue._50}>In Progress</StatusContainer>;
  } else if (status.indexOf('complete') > -1) {
    return <StatusContainer bgColor={Color.Primary._50}>Completed</StatusContainer>;
  } else {
    return <StatusContainer>Not Started</StatusContainer>;
  }
};

export default function ObserverForm(props) {
  const [firstName, setFirstName] = useState(props.firstName || '');
  const [emailAddress, setEmailAddress] = useState(props.emailAddress || '');
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [hasBeenInvited, setHasBeenInvited] = useState(props.hasBeenInvited);
  const [hasStatus, setHasStatus] = useState(Boolean(props.status));
  const [hasNotStarted, setHasNotStarted] = useState(!(props.status !== 'not_started'));

  const sendInvite = e => {
    e.preventDefault();
    var isEmailAddressValid = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+\.[A-Za-z.]+$/.test(emailAddress.trim());
    setEmailIsValid(isEmailAddressValid);
    if (isEmailAddressValid) {
      props
        .addFeedbackResponseUser(emailAddress, firstName)
        .then(response => {
          setHasBeenInvited(true);
          setHasStatus(true);
          setHasNotStarted(true);
          alertService.show('Invitation Sent');
        })
        .catch(e => {
          console.error(e);
        });
    }
  };

  const handleResend = e => {
    e.preventDefault();
    props
      .resendFeedbackUser(emailAddress, firstName)
      .then(response => {
        setHasBeenInvited(true);
        setHasStatus(true);
        setHasNotStarted(true);
        alertService.show('Invitation Resent');
      })
      .catch(e => {
        console.error(e);
      });
  };

  const handleReset = e => {
    e.preventDefault();
    props
      .removeFeedbackResponseUser(emailAddress, firstName)
      .then(response => {
        setHasBeenInvited(false);
        setFirstName('');
        setEmailAddress('');
        setHasStatus(false);
        alertService.show('Invitation Cleared');
      })
      .catch(e => {
        console.error(e);
      });
  };

  return (
    <Form className={hasStatus ? 'has-status' : ''}>
      <label>
        First Name:
        <Input
          onChange={e => setFirstName(e.target.value)}
          value={firstName}
          id="firstName"
          type="text"
          placeholder="Enter First Name"
          disabled={hasBeenInvited}
        />
      </label>
      <label>
        Email:
        <Input
          onChange={e => setEmailAddress(e.target.value)}
          value={emailAddress}
          id="emailAddress"
          type="text"
          placeholder="Enter Email"
          disabled={hasBeenInvited}
        />
      </label>
      {!emailIsValid && <ErrorMessage style={{ margin: 0 }}>A valid email address is required</ErrorMessage>}
      {hasStatus && <Status status={props.status} />}
      {hasBeenInvited && hasNotStarted && (
        <div className="grid-container grid-gap-8 grid-col-2">
          <SmallWhiteButton onClick={handleReset}>Reset</SmallWhiteButton>
          <SmallWhiteButton onClick={handleResend}>Resend</SmallWhiteButton>
        </div>
      )}
      {!hasBeenInvited && (
        <SmallPrimaryButton disabled={!firstName || !emailAddress} onClick={sendInvite}>
          Send
        </SmallPrimaryButton>
      )}
    </Form>
  );
}

ObserverForm.propTypes = {
  firstName: PropTypes.string,
  emailAddress: PropTypes.string,
  addFeedbackResponseUser: PropTypes.func,
  removeFeedbackResponseUser: PropTypes.func,
  resendFeedbackUser: PropTypes.func,
  hasBeenInvited: PropTypes.bool,
  status: PropTypes.string,
};
