import React from 'react';
import PropTypes from 'prop-types';
import { useSteps, useCompleteStep } from './StepsContext';
import { PrimaryButton } from '../components/Buttons';

export default function CompleteStepButton({ isDeleted }) {
  const { completeStep } = useCompleteStep();
  const { isCompletingStep } = useSteps();

  return (
    <PrimaryButton
      data-qa-hook="completeStepButton"
      onClick={() => completeStep(isDeleted)}
      disabled={isCompletingStep}
      operating={isCompletingStep}
    >
      Next
    </PrimaryButton>
  );
}

CompleteStepButton.propTypes = {
  isDeleted: PropTypes.bool,
};
