angular.module('lwNamb').controller(
  'InvitesCtrl',

  [
    '$scope',
    '$log',
    '$localStorage',
    'userService',
    'orgService',
    'inviteService',
    function($scope, $log, $localStorage, userService, orgService, inviteService) {
      var init = function() {
        $scope.loading = true;
        $scope.invites = [];

        //Clear Local Storage (Cache)
        $localStorage.invitationName = undefined;
        $localStorage.inviteRoles = undefined;
        $localStorage.inviteGroups = undefined;
        $localStorage.inviteTraining = undefined;
        $localStorage.inviteLicenses = undefined;
        $localStorage.recipients = undefined;
        $localStorage.message = undefined;
        $localStorage.unlimited = undefined;
        $localStorage.redemptionLimit = undefined;
        $localStorage.isExpirationDateSet = undefined;
        $localStorage.searchQueryString = undefined;

        userService.user().then(
          function(user) {
            $scope.user = user;
            retrieveUserData();
          },
          function() {
            $log.error('No user returned in InvitesCtrl');
          }
        );
      };

      var retrieveUserData = function() {
        orgService.getOrg($scope.user.lastSelectedAccount).then(
          function(org) {
            $scope.org = org;
            inviteService.getInvites($scope.org.id).then(
              function(invites) {
                $scope.invites = invites.filter(function(invite) {
                  return invite._type !== 'PendingInviteView';
                });
                $scope.loading = false;
              },
              function(response) {
                $scope.loading = false;
                $log.error('Error: Invites not found; ' + response);
              }
            );
          },
          function(response) {
            $scope.loading = false;
            $log.error('Error: Org not found; ' + response);
          }
        );
      };

      init();
    },
  ] //End Invite Ctrl
);
