import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Type, Breakpoints } from '../StyleGuide';
import { FullWidthCard } from '../components/Layout';
import { PrimaryButton, SmallRoundedIconButton } from '../components/Buttons';
import windowService from '../services/windowService';

const ViewAllTrainingContainer = styled(FullWidthCard)`
  padding: ${Grid._8} ${Grid._4} 100px;
  text-align: center;
  color: ${Type.Color.dark};
  /* background: url("img/training_poster_placeholder.png"); */
  background-repeat: repeat-x;
  background-position-x: center;
  background-position-y: calc(100% - 45px);
  > i {
    color: ${Type.Color.medium};
    font-size: ${Type.Scale._8};
  }
  > button {
    margin-top: 75px;
  }
  h3 button {
    display: inline;
    margin-left: ${Grid._3};
  }
`;

export default function ViewAllTraining({ description, onEditClick }) {
  return (
    <ViewAllTrainingContainer>
      <i className="fas fa-book-open"></i>
      <h3>
        {description}
        {onEditClick && !description && <PrimaryButton onClick={onEditClick}>Add Text Header</PrimaryButton>}
        {onEditClick && description && (
          <SmallRoundedIconButton onClick={onEditClick}>
            <i className="fas fa-pen" />
          </SmallRoundedIconButton>
        )}
      </h3>
      <PrimaryButton onClick={() => windowService.redirectTo('#/search')} disabled={!!onEditClick}>
        View All Training
      </PrimaryButton>
    </ViewAllTrainingContainer>
  );
}

ViewAllTraining.propTypes = {
  description: PropTypes.string,
  onEditClick: PropTypes.func,
};
