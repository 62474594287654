import React from 'react';
import ConfirmationModal from '../../../components/ConfirmationModal';

type Props = {
  subtext: string;
  handleSubmit: () => void;
  handleDismiss: () => void;
};

export const RemoveTrainingStepModal = (props: Props) => (
  <ConfirmationModal
    title="Remove Training Item"
    prompt={<span>Are you sure you want to remove this training item?</span>}
    buttonType="danger"
    buttonActionText="Remove"
    {...props}
  />
);
