import apiClient from './apiClient';
import cacheService from './cacheService';

const cacheableRequest = (endpoint, maxAgeMinutes = 5, bypassCacheLookup = false) => {
  if (bypassCacheLookup !== true) {
    const cached = cacheService.get(endpoint);
    if (cached) return cached;
  }

  const request = apiClient.get(endpoint);
  cacheService.set(endpoint, request, maxAgeMinutes);
  return request;
};

export default cacheableRequest;
