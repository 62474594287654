angular.module('lwNamb').controller(
  'TaskViewerCtrl',

  [
    '$scope',
    '$log',
    '$timeout',
    'userService',
    'windowService',
    'alertService',
    'taskListInstanceService',
    'taskListService',
    '$routeParams',
    '$filter',
    function(
      $scope,
      $log,
      $timeout,
      userService,
      windowService,
      alertService,
      taskListInstanceService,
      taskListService,
      $routeParams,
      $filter
    ) {
      var trainingType, trainingInstanceId;
      var init = function() {
        $scope.loading = true;
        $scope.taskLoading = true;
        $scope.spinner = false;
        $scope.tlId = $scope.courseId = $routeParams.courseId;
        if ($routeParams.workflowId) {
          trainingType = 'workflow';
          trainingInstanceId = $routeParams.workflowId;
        } else {
          trainingType = 'course';
          trainingInstanceId = $routeParams.courseId;
        }
        userService.user().then(
          function(user) {
            $scope.user = user;
            retrieveTLData();
          },
          function() {
            $log.error('No user returned in TaskViewerCtrl');
          }
        );
      };

      var nextTask = function() {
        window.scrollTo(0, 0);
        $scope.taskLoading = true;
        $timeout(function() {
          if ($scope.taskList.progress === 1) {
            $scope.redirectToTrainingViewer();
          }
          for (var i = $scope.currentTaskIndex || 0; i < $scope.taskList.tasks.length; i++) {
            if ($scope.taskList.tasks[i].status !== 'complete') {
              if (
                $scope.taskList.tasks[i].task._type === 'Assessment' ||
                $scope.taskList.tasks[i].task._type === 'Feedback'
              ) {
                var viewerType = angular.copy($scope.taskList.tasks[i].task._type);
                viewerType = viewerType.toLowerCase();
                var path = '#/' + viewerType + '-viewer';
                if (viewerType === 'assessment') {
                  path = path + '/' + $scope.taskList.id + '/' + $scope.taskList.tasks[i].id.id + '/true';
                  windowService.redirectHash(path);
                  break;
                } else if (viewerType === 'feedback' && $scope.taskList.tasks[i].status === 'not_started') {
                  path = path + '/' + $scope.taskList.tasks[i].id.id + '/' + $scope.taskList.id + '/true';
                  windowService.redirectHash(path);
                  break;
                } else if (
                  viewerType === 'feedback' &&
                  $scope.taskList.tasks[i].status === 'in_progress' &&
                  i === $scope.taskList.tasks.length - 1
                ) {
                  $scope.redirectToTrainingViewer();
                }
              } else {
                $scope.task = $scope.taskList.tasks[i];
                $scope.currentTaskIndex = i;
                $scope.taskLoading = false;
                break;
              }
            } else if (
              $scope.taskList.progress < 1 &&
              $scope.taskList.tasks[i].status === 'complete' &&
              i === $scope.taskList.tasks.length - 1
            ) {
              $scope.redirectToTrainingViewer();
              break;
            }
          }
        }, 750);
      };

      var updateTLProgress = function() {
        $scope.taskList.progress =
          $scope.taskList.tasks.filter(function(task) {
            return task.status === 'complete';
          }).length / $scope.taskList.tasks.length;
      };

      var retrieveTLData = function() {
        taskListInstanceService
          .getByInstanceId($routeParams.courseId)
          .then(
            function(taskList) {
              $scope.taskList = taskList;
              if ($routeParams.taskId === undefined || $routeParams.taskId === 'undefined') {
                nextTask();
              } else {
                $scope.task = taskList.tasks.find(function(task, index) {
                  if (task.id.id === $routeParams.taskId) {
                    $scope.currentTaskIndex = index;
                    $scope.taskLoading = false;
                    return task;
                  }
                });
                if ($scope.task.task._type === 'Assessment') {
                  nextTask();
                }
              }
            },
            function(reason) {
              $log.error(reason);
            }
          )
          .finally(function() {
            $scope.loading = false;
          });
      };

      $scope.redirectTo = function(path) {
        windowService.redirectHash(path);
      };

      $scope.redirectToTrainingViewer = function() {
        $scope.redirectTo('#/training-viewer/' + trainingType + '/' + trainingInstanceId);
      };

      $scope.completeTask = function() {
        $scope.spinner = true;
        if ($scope.task.status !== 'complete') {
          taskListService
            .completeTask($scope.courseId, $scope.task.assigneeId.id, $scope.task)
            .then(
              function() {
                $scope.taskList.tasks[$scope.currentTaskIndex].status = 'complete';
                updateTLProgress();
                nextTask();
              },
              function(reason) {
                $log.error(reason);
                alertService.show({
                  title: 'Unexpected Error!',
                  content: 'Please try completing the task again.',
                  type: 'danger danger-with-content',
                  duration: 20,
                });
              }
            )
            .finally(function() {
              $scope.spinner = false;
            });
        }
      };

      $scope.$on('TaskListInstanceSetComplete', function(name, event) {
        if (event.id === $scope.courseId) {
          var label = $filter('label')('course');

          alertService.show({
            title: $filter('capitalize')(label) + ' Completed!',
            content:
              trainingType === 'workflow'
                ? ''
                : 'You can now view this ' + label + ' in your <a href="#/dashboard-history/">Completed Training</a>',
            type: 'success success-with-content',
            duration: 10,
          });
        }
      });

      $scope.$on('WorkflowInstanceEnded', function(name, event) {
        if (event.id === $routeParams.workflowId) {
          alertService.show({
            title: 'Pathway Completed!',
            content: 'You can now view this pathway in your <a href="#/dashboard-history/">Completed Training</a>',
            type: 'success success-with-content',
            duration: 10,
          });
        }
      });

      init();
    },
  ] //End Task Viewer Ctrl
);
