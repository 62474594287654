import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../authentication';
import { Container } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import Breadcrumbs from '../components/Breadcrumbs';
import InlineEmailEditor from '../containers/InlineEmailEditor';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import alertService from '../services/AlertService';
import { groupService } from '../services/groupService';
import emailService from '../services/emailService';

export default function EmailGroup() {
  const user = useUser();
  const [data, setData] = useState({ isLoading: true });
  const [crumbs, setCrumbs] = useState([{ name: 'Groups', route: '#/groups' }]);
  const { orgId, groupId } = useParams();
  const { group, emails, template, isLoading, isError } = data;

  const loadData = () =>
    Promise.all([
      groupService.getSingleGroup(groupId, orgId),
      emailService.getEmailPreview(orgId, 'blank', '###MSG###', 'Log In'),
    ]).then(([group, emailPreview]) => {
      const emails = group.groupUsers.concat(group.pendingMembers || []).map(user => user.email || user.emailAddress);
      const template = emailPreview.emails[0].content.html.replace(/>\s+</g, '><').trim();
      return { group, emails, template };
    });

  useEffect(() => {
    loadData()
      .then(({ group, emails, template }) => {
        setData({ group, emails, template, isLoading: false });
        setCrumbs(prevCrumbs => [
          ...prevCrumbs,
          { name: group.groupName, route: `#/groups/${groupId}/${orgId}` },
          { name: `Email ${group.groupName}` },
        ]);
      })
      .catch(err => {
        setData({ isLoading: false, isError: true });
        console.error(err);
        alertService.showError('An error occurred. Please try again.');
      });
  }, []);

  return (
    <>
      {<Breadcrumbs crumbs={crumbs} />}
      <Container data-qa-hook="emailGroupView">
        {isLoading ? (
          <LoadingState />
        ) : isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : (
          <>
            <HelpAndInfoBox
              title="Edit Your Email"
              description="Add your personal message in the email preview. Use the Send Email button below the email to finish."
            />
            <InlineEmailEditor
              orgId={orgId}
              htmlTemplateString={template}
              defaultSubject={`A message from ${user.firstName}`}
              defaultReplyTo={user.logInEmail}
              messagePlaceholder={`Enter a personal message for ${group.groupName}`}
              recipientEmail={emails}
              groupName={group.groupName}
            />
          </>
        )}
      </Container>
    </>
  );
}
