import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MediumButton, MediumPrimaryButton } from '../../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../../components/ModalElements';
import { RadioListWithDescription, Radio } from '../../components/FormElements';
import useSessionTimeline from '../../hooks/useSessionTimeline';

const PrintPreferenceModal = ({ leaderPreference, handleDismiss }) => {
  const { printPreferenceMap } = useSessionTimeline();
  const [preference, setPreference] = useState(leaderPreference);

  const print = () => {
    window.print();
    handleDismiss();
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>Print Leader Guide</h3>
      </ModalHeader>
      <ModalBody>
        <h3>Print Options:</h3>
        <form>
          <RadioListWithDescription>
            {Object.entries(printPreferenceMap).map(([key, value]) => (
              <li key={key}>
                <label>
                  <Radio
                    data-qa-hook={`printPreference_${key}`}
                    name={key}
                    onChange={() => setPreference(key)}
                    checked={preference === key}
                  />
                  {value.title}
                </label>
                <p>{value.description}</p>
              </li>
            ))}
          </RadioListWithDescription>
        </form>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton data-qa-hook="printPreferenceContinue" onClick={print}>
          Continue
        </MediumPrimaryButton>
        <MediumButton onClick={handleDismiss}>Cancel</MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

PrintPreferenceModal.propTypes = {
  leaderPreference: PropTypes.string.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default PrintPreferenceModal;
