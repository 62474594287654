import React, { useState, useEffect } from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import trainingService from '../services/trainingService';
import { useParams } from 'react-router-dom/';
import CourseCard from '../containers/CourseCard';
import WorkFlowCard from '../containers/WorkflowCard';
import windowService from '../services/windowService';
import { Container } from '../components/Layout';

const ManageAssignmentsViewAssignment = () => {
  const [data, setData] = useState({ isLoading: true, assignment: {} });
  const { assignment, isLoading, isError } = data;
  const { assignmentId } = useParams();

  const [crumbs, setCrumbs] = useState([
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Assignments', route: '#/manage-assignments' },
  ]);

  useEffect(() => {
    trainingService.getAssignment({ assignId: assignmentId }).then(newData => {
      setData({ isLoading: false, assignment: newData });
      setCrumbs([...crumbs, { name: newData.assignable.name }]);
    });
  }, []);

  const sendReminder = (assignmentName, assignedUser, userFirstName, whiteLabelName, orgId) => {
    localStorage.setItem(
      'ngStorage-emailPerson',
      JSON.stringify({
        subject: `${whiteLabelName} reminder from ${userFirstName}`,
        bodyText: `This is just a friendly reminder from ${userFirstName} to continue your work on ${assignmentName}.`,
        buttonText: 'Continue Training',
        placeholder: 'Enter a message',
      })
    );
    windowService.redirectTo('/email/person/' + orgId + '/' + assignedUser);
  };

  const updateAssignment = updateFn =>
    setData(prevData => ({ ...prevData, assignment: updateFn(prevData.assignment) }));

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      {isLoading ? (
        <LoadingState />
      ) : isError ? (
        <ErrorMessage />
      ) : (
        <Container>
          {assignment.assignable._type === 'AssignedTaskList' ? (
            <CourseCard
              updateAssignment={updateAssignment}
              assignment={assignment}
              assignmentLabel="Course"
              sendReminder={sendReminder}
            />
          ) : (
            <WorkFlowCard
              updateAssignment={updateAssignment}
              assignment={assignment}
              assignmentLabel="Pathway"
              sendReminder={sendReminder}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default ManageAssignmentsViewAssignment;
