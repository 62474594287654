angular
  .module('lwNamb')

  .factory(
    'purchaseService',

    [
      '$location',
      '$localStorage',
      '$q',
      function($location, $localStorage, $q) {
        var storageKey = 'pendingPurchases',
          queryParam = 'purchased',
          maxAge = 24 * 60 * 60 * 1000; // one day

        return {
          addPendingPurchase: function(itemNumber) {
            var pendingPurchases = get();
            if (
              pendingPurchases.filter(function(p) {
                return p.itemNumber == itemNumber;
              }).length < 1
            ) {
              pendingPurchases.push({
                itemNumber: itemNumber,
                expires: Date.now() + maxAge,
              });
              save(pendingPurchases);
            }
          },
          removePendingPurchase: function(itemNumber) {
            var pendingPurchases = get();
            pendingPurchases = pendingPurchases.filter(function(p) {
              return p.itemNumber != itemNumber;
            });
            save(pendingPurchases);
          },
          getPendingPurchases: function() {
            return get().map(function(p) {
              return p.itemNumber;
            });
          },
          isPurchaseExpected: function() {
            return !!$location.search()[queryParam];
          },
          getShoppingUrl: function(itemNumber) {
            var item = itemNumber.value ? itemNumber.value : itemNumber;
            return $q(function (resolve) {
              resolve({ data: { url: '/#/purchase?itemNumber=' + item } });
            });
          }
        };

        function get() {
          clearExpired();
          return $localStorage[storageKey] || [];
        }

        function save(pendingPurchases) {
          $localStorage[storageKey] = pendingPurchases;
        }

        function clearExpired() {
          if ($localStorage[storageKey]) {
            $localStorage[storageKey] = $localStorage[storageKey].filter(function(p) {
              return p.expires > Date.now();
            });
          }
        }
      },
    ]
  );
