import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useUser } from '../authentication';
import { Container, PageTitle, RaisedContainer } from '../components/Layout';
import { WhiteButton } from '../components/Buttons';
import { Color, Grid, Type, Breakpoints } from '../StyleGuide';
import windowService from '../services/windowService';
import AccessDenied from '../components/AccessDenied';
import WhiteLabel from '../filters/WhiteLabel';
import capitalizeFilter from '../filters/capitalize-filter';

const BUTTON_SIZE = '128px';

const PageContainer = styled(Container)`
  margin: ${Grid._6} auto;
`;

const ButtonsContainer = styled(RaisedContainer)`
  display: grid;
  grid-template-columns: repeat(auto-fill, ${BUTTON_SIZE});
  justify-content: space-evenly;
  gap: ${Grid._5} ${Grid._4};
  padding: ${Grid._7} ${Grid._5};
  margin: ${Grid._5} auto ${Grid._12} auto;

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._7} ${Grid._6};
  }
`;

const OrganizationButton = styled(WhiteButton)`
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  align-items: center;
  background: ${Color.white};
  font-size: ${Type.Scale._2};
  font-weight: ${Type.Weight.semibold};
  width: ${BUTTON_SIZE};
  height: ${BUTTON_SIZE};
  margin: 0;
  padding: ${Grid._4} ${Grid._5};
  white-space: normal;
`;

const orgButtons = [
  {
    name: 'CPCA Assignments',
    icon: 'fas fa-chart-bar fa-fw',
    route: '/manage-assignments?cpca=1',
    dataHook: 'organizationManageCPCA',
    condition: user => user.lastSelectedAccount === 'F6E09B28-01C1-4D0B-A184-78748E675629',
  },
  {
    name: 'Manage People',
    icon: 'fas fa-user-friends fa-fw',
    route: '/people',
    dataHook: 'organizationAddPeople',
    condition: user => user.permissions.includes('Roles View'),
  },
  {
    name: 'Search Training',
    icon: 'icon ion-ios-search-strong',
    route: '/search',
    dataHook: 'organizationSearchTraining',
    condition: user => user.permissions.includes('Assign View'),
  },
  {
    name: 'Manage Roles',
    icon: 'fas fa-tags fa-fw',
    route: '/roles',
    dataHook: 'organizationManageRoles',
    condition: user => user.permissions.includes('Account View'),
  },
  {
    name: `Purchases&nbsp;and Subscriptions`,
    icon: 'fas fa-address-card fa-fw',
    route: '/org/purchases-and-subscriptions',
    dataHook: 'organizationManageSubscriptions',
    condition: user => user.permissions.includes('Account View'),
  },
  {
    name: 'Manage Assignments',
    icon: 'fas fa-chart-bar fa-fw',
    route: '/manage-assignments',
    dataHook: 'organizationManageAssignments',
    condition: user => user.permissions.includes('Assign View'),
  },
  {
    name: 'Training Progress',
    icon: 'fas fa-chart-pie fa-fw',
    route: '/training-progress',
    dataHook: 'organizationTrainingProgress',
    condition: user => user.permissions.includes('Assign View'),
  },
  {
    name: 'Assessment Results',
    icon: 'icon ion-ios-compose',
    route: '/assessment/results',
    dataHook: 'organizationAssessmentResults',
    condition: user => user.permissions.includes('Assign View'),
  },
  {
    name: 'Organization Settings',
    icon: 'fas fa-cog fa-fw',
    route: '/org/settings',
    dataHook: 'organizationSettings',
    condition: user => user.permissions.includes('Account View') && window.location.origin !== 'TEXASBAPTISTS',
  },
  {
    name: 'Advanced Invites',
    icon: 'fas fa-envelope fa-fw',
    route: '/invites',
    dataHook: 'organizationAdvancedInvites',
    condition: user => user.permissions.includes('Roles View'),
  },
  {
    name: 'Manage Training',
    icon: 'icon ion-settings',
    route: '/manage-training/build/courses',
    dataHook: 'organizationBuildTraining',
    condition: user => user.permissions.includes('Build View') || user.permissions.includes('Build Curriculum View'),
  },
  {
    name: 'Manage Catalog',
    icon: 'fas fa-book fa-fw',
    route: '/manage-catalog',
    dataHook: 'organizationManageCatalog',
    condition: user => user.isAuthor,
  },
  {
    name: 'Training Compilations',
    icon: 'fas fa-stream fa-fw',
    route: '/manage-catalog/compilations',
    dataHook: 'organizationTrainingCompilations',
    condition: user => user.isAuthor,
  },
  {
    name: 'Author Training',
    icon: 'fas fa-pen-alt fa-fw',
    route: '/manage-training/author/courses',
    dataHook: 'organizationAuthor',
    condition: user => user.isAuthor,
  },
  {
    name: 'Manage Curriculum',
    icon: 'fas fa-bible fa-fw',
    route: '/manage-curriculum',
    dataHook: 'organizationManageCurriculum',
    condition: user => user.permissions.includes('Build Curriculum View'),
  },
  // {
  //   name: 'Coach Everyone',
  //   icon: 'icon ion-android-clipboard',
  //   route: '/coach-select/true',
  //   dataHook: 'organizationCoachAll',
  //   condition: user => user.permissions.includes('Account View'),
  // },
  // {
  //   name: 'Coach People',
  //   icon: 'icon ion-android-clipboard',
  //   route: '/coach-select',
  //   dataHook: 'organizationCoach',
  //   condition: user => user.permissions.includes('Coach View') && !user.permissions.includes('Account View'),
  // },
];

const OrganizationOverview = () => {
  const user = useUser();

  const [hasAccess, buttons] = useMemo(() => {
    const accessiblePermissions = ['Account View', 'Roles View', 'Assign View', 'Build View', 'Groups View'];
    const hasAccess = user.permissions?.some(permission => accessiblePermissions.includes(permission));
    const buttons = orgButtons.filter(button => button.condition(user));
    return [hasAccess, buttons];
  }, [user]);

  return hasAccess ? (
    <PageContainer data-qa-hook="organizationView">
      <PageTitle>
        <h1>{capitalizeFilter(WhiteLabel.labels.org)} Management</h1>
      </PageTitle>

      <ButtonsContainer>
        {buttons.map(button => (
          <OrganizationButton
            key={button.name}
            data-qa-hook={button.dataHook}
            onClick={() => windowService.redirectTo(`${button.route}`)}
            className="btn btn-dashboard"
          >
            <div>
              <i className={button.icon}></i>
            </div>
            <div dangerouslySetInnerHTML={{ __html: button.name }}></div>
          </OrganizationButton>
        ))}
      </ButtonsContainer>
    </PageContainer>
  ) : (
    <AccessDenied />
  );
};

export default OrganizationOverview;
