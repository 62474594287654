import React from 'react';
import { SortableContainer, SortableElement, SortableContainerProps, SortableElementProps } from 'react-sortable-hoc';
import { ContentListContainer } from './LayoutElements';

type ContainerProps = SortableContainerProps & {
  children: React.ReactNode;
};

const ContentListSortableContainer = SortableContainer<ContainerProps>(({ children }: ContainerProps) => (
  <ContentListContainer>{children}</ContentListContainer>
));

type SortIndexes = {
  oldIndex: number;
  newIndex: number;
};

export type SortablePathwayListProps = {
  onSort: ({ oldIndex, newIndex }: SortIndexes) => void;
  children: (sortingItemIndex: number | null) => React.ReactNode;
};

export const SortablePathwayList = ({ onSort, children }: SortablePathwayListProps) => {
  const [sortingItemIndex, setSortingItemIndex] = React.useState<number | null>(null);

  const updateBeforeSortStart = ({ index }: { index: number }) => {
    setSortingItemIndex(index);
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortIndexes) => {
    setSortingItemIndex(null);
    onSort({ oldIndex, newIndex });
  };

  return (
    <ContentListSortableContainer updateBeforeSortStart={updateBeforeSortStart} onSortEnd={onSortEnd} useDragHandle>
      {children(sortingItemIndex)}
    </ContentListSortableContainer>
  );
};

type ItemProps = SortableElementProps & {
  children: React.ReactNode;
};

export const SortablePathwayListItem = SortableElement<ItemProps>(({ children, ...props }: ItemProps) => (
  <div {...props}>{children}</div>
));
