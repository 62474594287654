angular.module('lwNamb').directive('easyLink', [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'partials/directives/easy-link.html',
      scope: {
        link: '=',
      },
      controller: [
        '$scope',
        '$log',
        '$timeout',
        function($scope, $log, $timeout) {
          $scope.copyClipboard = function(e) {
            $log.info(e);
            $scope.linkCopied = true;
            $timeout(function() {
              $scope.linkCopied = false;
              e.clearSelection();
            }, 2000);
          };
        },
      ],
    };
  },
]);
