import React, { useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Breakpoints, Color, Type } from '../StyleGuide';

import { useSteps, selectStep, useCompleteStep, setShowTrainingCompletedModal } from './StepsContext';

import StepHeader from './StepHeader';
import StepSection from './StepSection';
import VideoStep from './VideoStep';
import AssessmentStep from './AssessmentStep';
import PrepareEnrichStep from './PrepareEnrichStep';
import CompleteStepButtonContainer from './CompleteStepButtonContainer';
import CompleteStepButton from './CompleteStepButton';
import StepMenu from './StepMenu';
import ErrorMessage from '../components/ErrorMessage';
import { PrimaryButton } from '../components/Buttons';
import Section from './Section';
import WhiteLabel from '../filters/WhiteLabel';
import capitalizeFilter from '../filters/capitalize-filter';

const Content = styled.article`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${Color.Secondary._90};
  background-color: ${Color.Gray._05};
  margin-top: ${props => props.marginTop + 'px' || '64px'};
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    height: calc(100vh - ${props => props.marginTop + 'px' || '64px'});
  }
  .locked-step {
    color: ${Type.Color.dark};
    text-align: center;
    i {
      color: ${Color.Secondary._50};
      font-size: ${Grid._7};
    }
  }
`;

const CompleteCourseButton = ({ disabled, isWorkflow, dispatch }) => {
  const trainingType = isWorkflow ? WhiteLabel.labels.pathway : WhiteLabel.labels.course;

  return (
    <CompleteStepButtonContainer>
      <PrimaryButton
        data-qa-hook="completeCourseButton"
        onClick={() => setShowTrainingCompletedModal(dispatch, { showTrainingCompletedModal: true })}
        disabled={disabled}
      >
        Complete {capitalizeFilter(trainingType)}
      </PrimaryButton>
    </CompleteStepButtonContainer>
  );
};

CompleteCourseButton.propTypes = {
  disabled: PropTypes.bool,
  isWorkflow: PropTypes.bool,
  dispatch: PropTypes.func,
};

const ErrorMessageButton = ({ taskType }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const msg = {
    Link: 'Please visit the link in order to complete this ' + WhiteLabel.labels.step,
    MyWSBLink: 'Please visit the link in order to complete this ' + WhiteLabel.labels.step,
    Upload: 'Please upload the requested file in order to complete this ' + WhiteLabel.labels.step,
    Download: 'Please download the file in order to complete this ' + WhiteLabel.labels.step,
  };

  return (
    <>
      {showErrorMessage && <ErrorMessage data-qa-hook="completeStepButtonError">{msg[taskType]}</ErrorMessage>}
      <PrimaryButton data-qa-hook="completeStepButtonActionNeeded" onClick={() => setShowErrorMessage(true)}>
        Next
      </PrimaryButton>
    </>
  );
};

ErrorMessageButton.propTypes = {
  taskType: PropTypes.string,
};

export default function StepContent() {
  const { completeStep } = useCompleteStep();

  const [
    {
      isWorkflow,
      numTotalCourses,
      training,
      lastStepIndex,
      currentStep,
      currentStepIndex,
      currentCourseIndex,
      currentCourse,
      setShowLockedStep,
      currentStepIsIncomplete,
      currentStepIsInProgress,
      numCompletedSteps,
      completeModalShown,
    },
    dispatch,
  ] = useSteps();

  const [marginTop, setMarginTop] = useState();

  useLayoutEffect(() => {
    const content = document.querySelector('article');
    content.scrollTop = 0;
  }, [currentStep]);

  useEffect(() => {
    if (currentStep.task?._type === 'Text') completeStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  if (setShowLockedStep) {
    return (
      <Content marginTop={marginTop}>
        <StepHeader setMarginTop={setMarginTop} />
        <Section className="locked-step">
          <p>
            <i className="fa fa-lock"></i>
          </p>
          <h3>This {WhiteLabel.labels.step} is locked</h3>
          <p className="text-color-medium">
            This {WhiteLabel.labels.course} will be unlocked upon completion of previous {WhiteLabel.labels.course}.
          </p>
        </Section>
      </Content>
    );
  } else {
    const stepIsComplete = currentStep && currentStep.status === 'complete';
    const isLastCourse = isWorkflow && Number(currentCourseIndex) + 1 === training.progress.steps.total;
    const isLastStep = Number(currentStepIndex) + 1 === currentCourse?.tasks?.length;
    const isNotLastStepInTraining = isWorkflow ? (isLastCourse ? !isLastStep : true) : !isLastStep;
    const courseIsFinished = isWorkflow
      ? currentCourse?.progress?.percent === 100
      : numCompletedSteps / currentCourse?.tasks?.length === 1;
    const isLastStepToBeCompleted =
      numCompletedSteps + 1 === currentCourse?.tasks?.length && currentStepIndex === lastStepIndex;

    const isVideo = currentStep.task._type === 'Video' || currentStep.task._type === 'ThirdPartyVideo';
    const isAssessment = currentStep.task._type === 'Assessment';
    const isPrepareEnrich = currentStep.task._type === 'PrepareEnrich';
    const isFeedback = currentStep.task._type === 'Feedback';
    const isText = currentStep.task._type === 'Text';
    const requiresAction =
      currentStep.task._type === 'Link' ||
      currentStep.task._type === 'Upload' ||
      currentStep.task._type === 'Download' ||
      currentStep.task._type === 'MyWSBLink';
    const showStepMenu = true;

    const goToNextStep = () => {
      selectStep(dispatch, { stepIndex: Number(currentStepIndex) + 1, courseIndex: currentCourseIndex });
    };

    const goToNextCourse = () => {
      selectStep(dispatch, { stepIndex: 0, courseIndex: Number(currentCourseIndex) + 1 });
    };

    return (
      <Content marginTop={marginTop}>
        <StepHeader setMarginTop={setMarginTop} />
        {isVideo && <VideoStep key={currentStep.id.id} />}
        {isAssessment && <AssessmentStep />}
        {isPrepareEnrich && <PrepareEnrichStep />}
        {!isVideo && !isAssessment && !isPrepareEnrich && (
          <>
            <StepSection />
            {showStepMenu && !isFeedback && <StepMenu />}
            {!isFeedback && currentStepIsIncomplete && isNotLastStepInTraining && (
              <CompleteStepButtonContainer>
                {isText || (requiresAction && currentStepIsInProgress) ? (
                  <CompleteStepButton />
                ) : (
                  requiresAction &&
                  !currentStepIsInProgress && <ErrorMessageButton taskType={currentStep.task?._type} />
                )}
              </CompleteStepButtonContainer>
            )}
          </>
        )}
        {stepIsComplete && isNotLastStepInTraining && !isLastStepToBeCompleted && (
          <CompleteStepButtonContainer>
            {isWorkflow && isLastStep && !isLastCourse ? (
              <PrimaryButton onClick={() => goToNextCourse()} disabled={!courseIsFinished}>
                Next Course
              </PrimaryButton>
            ) : (
              <PrimaryButton onClick={() => goToNextStep()}>Next</PrimaryButton>
            )}
          </CompleteStepButtonContainer>
        )}
        {(!isWorkflow || numTotalCourses - 1 === currentCourse.order) &&
          (courseIsFinished || isLastStepToBeCompleted) &&
          !completeModalShown && (
            <CompleteCourseButton isWorkflow={isWorkflow} dispatch={dispatch} disabled={!courseIsFinished} />
          )}
      </Content>
    );
  }
}
