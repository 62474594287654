angular
  .module('lwNamb')

  .factory(
    'jwt',

    [
      function() {
        return {
          get: function() {
            return JWT.get();
          },
          read: function(token) {
            return JWT.read(token);
          },
          validate: function(session) {
            return JWT.validate(session);
          },
          keep: function(token) {
            return JWT.keep(token);
          },
          forget: function() {
            return JWT.forget();
          },
        };
      },
    ]
  );
