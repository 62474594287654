import styled from 'styled-components';
import { Grid, Breakpoints, Color, Type } from '../StyleGuide';

export const TrainingDetailsWrapper = styled.section`
  margin-bottom: ${Grid._12};

  &:last-of-type {
    margin-bottom: 0;
  }

  h1 {
    font-size: ${Type.Scale._6};
  }
  h1,
  h3 {
    font-weight: ${Type.Weight.bold};
    line-height: ${Type.Leading.normal};
    margin: ${Type.Scale._2} auto;
  }
  p {
    color: ${Color.Gray._70};
    margin: ${Type.Scale._3} auto;
  }
  a.link {
    cursor: pointer;
    text-decoration: underline;
  }
  .fa-stack {
    width: ${Grid._6};
    height: ${Grid._6};
  }
  .fa-stack-2x {
    font-size: ${Type.Scale._6};
  }
  .fa-inverse {
    font-size: ${Type.Scale._3};
  }
  button {
    margin-top: ${Grid._5};
  }
  > div:nth-child(1),
  > div:nth-child(3) {
    display: none;
  }
  @media screen and (min-width: ${Breakpoints.screen_md}) {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;

    > div:nth-child(1),
    > div:nth-child(3) {
      position: relative;
      display: flex;
      height: 100%;
      justify-content: center;
    }
  }
`;

export const TrainingDetailsHeader = styled(TrainingDetailsWrapper)`
  margin-bottom: ${Grid._8};

  ul + button {
    margin-top: ${Grid._3};
  }
`;
