import React from 'react';
import styled from 'styled-components';
import { Grid } from '../StyleGuide';
import RestrictedAccessHeader from '../components/RestrictedAccessHeader';
import { PrimaryButton } from '../components/Buttons';
import { useUser } from '../authentication';
import WhiteLabel from '../filters/WhiteLabel';
import userService from '../services/userService';

const LogoutButton = styled(PrimaryButton)`
  margin-top: ${Grid._6};
`;

const UnauthorizedAccount = () => {
  const user = useUser();

  return (
    <RestrictedAccessHeader
      title="Action Required"
      description={
        <>
          We're sorry. We couldn't find <strong>{user ? user.logInEmail : 'this user'}</strong> in our list of people in{' '}
          <strong>{WhiteLabel.name}</strong>.
        </>
      }
    >
      <p>Please log in with a Lifeway Id that is part of {WhiteLabel.name} or contact support to resolve this issue.</p>
      <LogoutButton onClick={userService.logout}>Login with Another Account</LogoutButton>
    </RestrictedAccessHeader>
  );
};

export default UnauthorizedAccount;
