angular.module('lwNamb').directive('btView', [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'partials/directives/view.html',
      replace: true,
      controller: [
        '$scope',
        '$route',
        'userService',
        'catalogService',
        'properties',
        function($scope, $route, userService, catalogService, props) {
          setAccess($route.current.$$route);

          $scope.$on('$routeChangeStart', function(event, next) {
            setAccess(next.$$route);
          });

          $scope.$on('UserLoggedIn', function() {
            if (!$scope.isAccessGranted) {
              setAccess($route.current.$$route);
            }
          });

          function setAccess(route) {
            if (!route) {
              route = {};
            }

            $scope.isAccessGranted = false;
            $scope.isAccessDenied = false;

            var sectionPermission = route.section ? route.section.permission : null,
              requiredPermission = route.permission || sectionPermission;

            if (requiredPermission) {
              userService.user().then(function(user) {
                if (requiredPermission === props.permissions.author) {
                  catalogService.getCatalogForSite().then(function(catalog) {
                    if (
                      user.author &&
                      user.author.length > 0 &&
                      user.author.filter(function(el) {
                        return el._id && catalog._id && el._id.id === catalog._id.id;
                      }).length > 0
                    ) {
                      $scope.isAccessGranted = true;
                    } else {
                      $scope.isAccessDenied = true;
                    }
                  });
                } else if (user.permissions.indexOf(requiredPermission) > -1) {
                  $scope.isAccessGranted = true;
                } else {
                  $scope.isAccessDenied = true;
                }
              });
            } else {
              $scope.isAccessGranted = true;
            }
          }
        },
      ],
    };
  },
]);
