angular.module('lwNamb').factory(
  'backgroundProcessesService',

  [
    '$rootScope',
    'qt',
    'alertService',
    function($rootScope, qt, alertService) {
      return {
        add: function(promise, success, failure) {
          //promise.then pass in new success/failure functions
          promise.then(
            function() {
              success();
            },
            function() {
              failure();
            }
          );
        },
      };
    },
  ]
);
