import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Buttons';
import { Badge } from '../../components/Badge';
import { Grid, Color, Type, Breakpoints } from '../../StyleGuide';
import { useSelectTraining } from '../state/SelectTrainingProvider';

const List = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: ${Grid._3};

  p {
    margin: 0;
    color: ${Type.Color.medium};
  }
`;

const ListItem = styled(Badge)`
  background: ${Color.Gray._10};
  font-size: ${Type.Scale._2};
  padding: ${Grid._3};
  cursor: pointer;

  i {
    margin-left: ${Grid._3};
    color: ${Color.Gray._50};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  button {
    width: 100%;
    min-width: 220px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Grid._4};
  margin-bottom: ${Grid._7};
  padding: ${Grid._4};
  background: ${Color.white};
  border: 2px solid ${Color.Gray._10};

  h4 {
    margin: 0;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: ${Grid._5};
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    > div {
      flex-direction: row;
    }
  }
`;

export type SelectedTrainingProps = {
  title?: string;
  emptyText?: string;
  buttonText?: string;
};

export const SelectedTraining = ({
  title = 'Selected Training',
  emptyText = 'No training selected',
  buttonText = 'Add Training',
}: SelectedTrainingProps) => {
  const { selectedTraining, deselectTraining, onAddSelectedTraining } = useSelectTraining();
  const [isSaving, setIsSaving] = useState(false);

  const submitSelectedTraining = () => {
    setIsSaving(true);

    const returnValue = onAddSelectedTraining(selectedTraining);
    if (returnValue instanceof Promise) {
      returnValue.finally(() => setIsSaving(false));
    }
  };

  return (
    <Container>
      <h4>{title}</h4>

      <div>
        <List>
          {selectedTraining.length === 0 ? (
            <p>{emptyText}</p>
          ) : (
            selectedTraining.map(course => (
              <ListItem key={course.id} onClick={() => deselectTraining(course)}>
                {course.name}
                <i className="fas fa-times"></i>
              </ListItem>
            ))
          )}
        </List>
        <ButtonContainer>
          <Button
            disabled={selectedTraining.length === 0 || isSaving}
            operating={isSaving}
            onClick={submitSelectedTraining}
            loadingIndicatorColor={Type.Color.dark}
          >
            {buttonText}
          </Button>
        </ButtonContainer>
      </div>
    </Container>
  );
};
