import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import uuid from '../services/uuid';
import { Grid } from '../StyleGuide';

import { PageTitle } from '../components/Layout';
import { FormField, FormFieldContainer, FormFieldSet, Select } from '../components/FormElements';
import {
  Button,
  PrimaryButton,
  SmallRoundedIconButton,
  SmallRadioRoundedIconButton,
  SmallPrimaryRoundedIconButton,
} from '../components/Buttons';

import { useUser } from '../authentication';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';

import assessmentService from '../services/assessmentService';
import windowService from '../services/windowService';
import alertService from '../services/AlertService';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 630px;
  margin: ${Grid._7} 0px;

  legend {
    margin-bottom: ${Grid._4};
  }
`;

const AddChoiceDiv = styled.div`
  flex: 0 0 ${Grid._7};
  align-self: end;
`;

const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${Grid._7} 0;
  grid-gap: ${Grid._1};
`;

const ChoiceStyledDiv = styled.div`
  display: flex;
  align-items: center;
  grid-gap: ${Grid._4};
  width: 100%;

  textarea {
    width: 100%;
  }
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: ${Grid._7};
  width: 260px;
`;

const QuestionFormFieldContainer = styled(FormFieldContainer)`
  margin-bottom: 0;
`;

const ChoiceFormFieldContainer = styled(FormFieldContainer)`
  width: 100%;
`;

const CorrectChoiceCheckButton = ({ isCorrect, ...props }) => {
  const ButtonComponent = isCorrect ? SmallPrimaryRoundedIconButton : SmallRadioRoundedIconButton;
  const IconComponent = isCorrect ? <i className="fas fa-check" /> : <i />;

  return <ButtonComponent {...props}>{IconComponent}</ButtonComponent>;
};

CorrectChoiceCheckButton.propTypes = {
  isCorrect: PropTypes.bool,
};

const AssessmentMultipleChoiceView = ({ assessmentId, question, isPublished, questionType, ownerId }) => {
  const newEmptyQuestion = {
    question: '',
    displayType: 'radio',
    choices: [
      {
        id: {
          id: uuid.generate(),
        },
        value: [{ value: '' }],
        isCorrect: false,
      },
      {
        id: {
          id: uuid.generate(),
        },
        value: [{ value: '' }],
        isCorrect: false,
      },
    ],
  };

  const initialQuestionState = question
    ? {
        question: question.questionValue[0].value,
        displayType: question.displayType,
        choices: [...question.possibleAnswers],
      }
    : newEmptyQuestion;

  const user = useUser();
  const [formData] = useState(initialQuestionState);
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();

  const updateCorrectAnswer = (values, setValues, index) => {
    if (isPublished) return;
    if (values.choices[index].isCorrect === true) {
      setValues(prev => ({
        ...prev,
        choices: prev.choices.map(choice => ({
          ...choice,
          isCorrect: false,
        })),
      }));
    } else {
      setValues(prev => ({
        ...prev,
        choices: prev.choices.map((choice, i) => ({
          ...choice,
          isCorrect: index === i,
        })),
      }));
    }
  };

  const addNewChoice = setValues => {
    const falseQuestion = {
      id: {
        id: uuid.generate(),
      },
      value: '',
      isCorrect: false,
    };
    setValues(prev => ({
      ...prev,
      choices: [...prev.choices, falseQuestion],
    }));
  };

  const removeQuestion = (values, setValues, index) => {
    if (values.choices[index].isCorrect === true) {
      updateCorrectAnswer(values, setValues, 0);
      setValues(prev => ({
        ...prev,
        choices: prev.choices.filter((c, i) => i !== index),
      }));
    } else {
      setValues(prev => ({
        ...prev,
        choices: prev.choices.filter((c, i) => i !== index),
      }));
    }
  };

  const updateDisplayType = (updatedDisplayType, setValues) => {
    setValues(prev => ({
      ...prev,
      displayType: updatedDisplayType,
    }));
  };

  const submitQuestion = (values, { setSubmitting }) => {
    const updatedChoices = values.choices.map(choice => ({
      id: choice.id.id,
      answer: choice.value[0].value,
      isCorrect: choice.isCorrect,
    }));

    if (question !== undefined) {
      assessmentService
        .updateQuestion(
          assessmentId,
          user.userId,
          question.id.id,
          values.question,
          questionType,
          updatedChoices,
          values.displayType
        )
        .then(() => {
          alertService.showOnNextPage('Question Updated');
          windowService.redirectTo(`${manageTrainingRoutePrefix}/assessments/${assessmentId}`);
        })
        .catch(error => {
          console.error(error);
          alertService.showError();
          setSubmitting(false);
        });
    } else {
      assessmentService
        .addQuestion(
          assessmentId,
          ownerId,
          uuid.generate(),
          values.question,
          questionType,
          updatedChoices,
          user.userId,
          values.displayType
        )
        .then(() => {
          alertService.showOnNextPage('Question Updated');
          windowService.redirectTo(`${manageTrainingRoutePrefix}/assessments/${assessmentId}`);
        })
        .catch(error => {
          console.error(error);
          alertService.showError();
          setSubmitting(false);
        });
    }
  };

  return (
    <>
      <PageTitle className="mt-40">
        <h1>Multiple Choice</h1>
      </PageTitle>
      <Formik
        initialValues={formData}
        validationSchema={Yup.object({
          question: Yup.string().required('A question is required'),
          choices: Yup.array().of(
            Yup.object().shape({
              value: Yup.array().of(
                Yup.object().shape({
                  value: Yup.string().required('Each choice requires text'),
                })
              ),
              isCorrect: Yup.boolean(),
            })
          ),
        })}
        onSubmit={submitQuestion}
      >
        {({ values, isSubmitting, setValues }) => {
          return (
            <Form>
              <FormContainer>
                <FormFieldSet name="Question">
                  <FormField
                    data-qa-hook="questionBuilderName"
                    name="question"
                    as="textarea"
                    rows="5"
                    placeholder="This Is The Question That Will Be Answered"
                    Wrapper={QuestionFormFieldContainer}
                  />
                </FormFieldSet>
                <ChoiceContainer>
                  <FormFieldSet name="Answers">
                    <FieldArray name="choices">
                      {() =>
                        values.choices.map((choice, i) => (
                          <ChoiceStyledDiv data-qa-hook="questionBuilderMultipleChoiceAnswer" key={choice.id.id}>
                            <CorrectChoiceCheckButton
                              isCorrect={choice.isCorrect}
                              aria-label="Correct Answer"
                              width={Grid._5}
                              height={Grid._5}
                              onClick={() => updateCorrectAnswer(values, setValues, i)}
                            />
                            <FormField
                              data-qa-hook={`questionBuilderMultipleChoiceAnswerName`}
                              label={`Choice ${i + 1}`}
                              name={`choices.${i}.value[0].value`}
                              as="textarea"
                              rows="4"
                              placeholder="Choice Text"
                              Wrapper={ChoiceFormFieldContainer}
                            />
                            {values.choices.length > 2 && (
                              <SmallRoundedIconButton
                                data-qa-hook="questionBuilderMultipleChoiceAnswerRemove"
                                onClick={() => removeQuestion(values, setValues, i)}
                              >
                                <i className="far fa-trash-alt"></i>
                              </SmallRoundedIconButton>
                            )}
                          </ChoiceStyledDiv>
                        ))
                      }
                    </FieldArray>
                  </FormFieldSet>
                  {!isPublished && (
                    <AddChoiceDiv>
                      <Button data-qa-hook="questionBuilderMultipleChoiceAdd" onClick={() => addNewChoice(setValues)}>
                        Add Choice
                      </Button>
                    </AddChoiceDiv>
                  )}
                  {isPublished && (
                    <div>
                      <p className="info-block">
                        <i className="icon ion-android-alert"></i> The answer key cannot be edited after an assessment
                        has been published
                      </p>
                    </div>
                  )}
                </ChoiceContainer>
                <FormFieldSet name="Display Options">
                  <Select
                    data-qa-hook="questionBuilderMultipleChoiceAnswerDisplayFilter"
                    value={values.displayType}
                    name="displayType"
                    onChange={e => updateDisplayType(e.target.value, setValues)}
                  >
                    <option value="radio">Traditional</option>
                    <option value="select">Dropdown</option>
                  </Select>
                </FormFieldSet>
                <SaveButton
                  data-qa-hook="questionBuilderSave"
                  disabled={isSubmitting}
                  operating={isSubmitting}
                  type="submit"
                >
                  Save Question
                </SaveButton>
              </FormContainer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AssessmentMultipleChoiceView;

AssessmentMultipleChoiceView.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  question: PropTypes.object,
  isPublished: PropTypes.bool,
  questionType: PropTypes.string.isRequired,
  ownerId: PropTypes.string.isRequired,
};
