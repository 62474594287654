import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { addRemovableEventListener } from '../utils/domUtils';

const defaultConfig = { interval: 10000 };

export default function useAutosave(onSave, refs = [], config = {}) {
  const onSaveRef = useRef(onSave);
  const [dirty, setDirty] = useState();
  const { interval } = Object.assign({}, defaultConfig, config);

  const save = (type, ref) => onSaveRef.current({ type, ref });

  useEffect(() => {
    // Update the reference to the onSave function when it changes
    // otherwise state within that callback will be stale
    onSaveRef.current = onSave;
  }, [onSave]);

  useEffect(() => {
    const clearListeners = refs.map(ref =>
      addRemovableEventListener(ref.current, 'focus', () => {
        const intervalId = setInterval(() => {
          setDirty(prev => {
            if (prev) save('interval', prev);
            return;
          });
        }, interval);

        const clearInputListener = addRemovableEventListener(ref.current, 'input', () => setDirty(ref));

        const clearBlurListener = addRemovableEventListener(ref.current, 'blur', () => {
          clearInterval(intervalId);
          clearInputListener();
          clearBlurListener();

          setDirty(prev => {
            if (prev) save('blur', prev);
            return;
          });
        });
      })
    );

    return () => {
      clearListeners.forEach(clearListener => clearListener());
    };
  }, []);
}

useAutosave.propTypes = {
  onSave: PropTypes.func.isRequired,
  refs: PropTypes.arrayOf(PropTypes.object),
  config: PropTypes.shape({
    interval: PropTypes.number,
  }),
};
