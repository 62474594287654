import React from 'react';
import PropTypes from 'prop-types';

const DiscussionDisabler = ({ isDisabled, trainingType, EnableButton, children }) => {
  if (!isDisabled) return children;

  const isPathway = trainingType === 'Workflow';
  return (
    <>
      {isPathway ? 'Discussions are turned off for the courses in this pathway' : 'Discussion Off'}
      {!isPathway && <EnableButton>Turn on Discussion</EnableButton>}
    </>
  );
};

DiscussionDisabler.propTypes = {
  isDisabled: PropTypes.bool,
  trainingType: PropTypes.string,
  EnableButton: PropTypes.elementType,
  children: PropTypes.node,
};

export default DiscussionDisabler;
