angular.module('lwNamb').factory(
  'State',

  [
    function() {
      var states = {};

      return function(id) {
        if (!states[id]) {
          states[id] = newState();
        }
        return states[id];
      };

      function newState() {
        var state = {};

        return {
          get: function() {
            return state;
          },
          put: function(key, val) {
            state[key] = val;
          },
          remove: function(key) {
            delete state[key];
          },
          clear: function() {
            for (var prop in state) delete state[prop];
          },
        };
      }
    },
  ]
);
