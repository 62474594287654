angular.module('lwNamb').controller(
  'PrepareEnrichCtrl',

  [
    '$scope',
    '$log',
    'userService',
    'prepareEnrichService',
    'windowService',
    '$routeParams',
    'State',
    'taskListInstanceService',
    function(
      $scope,
      $log,
      userService,
      prepareEnrichService,
      windowService,
      $routeParams,
      State,
      taskListInstanceService
    ) {
      var orgId,
        assessmentState = State('assessmentRedirects');

      function init() {
        assessmentState.put('lastRootView', 'prepareEnrich');

        $scope.loading = true;
        //set default sort
        $scope.reverse = true;
        $scope.sortBy = 'lastModifiedDate';
        $scope.searchPrepareEnrich = {};

        userService.user().then(
          function(user) {
            $scope.user = user;
            loadData();
          },
          function() {
            $log.error('No user returned in PrepareEnrichCtrl');
          }
        );
      }

      function loadData() {
        orgId = $scope.user.lastSelectedAccount;
        prepareEnrichService.get(orgId).then(
          function(response) {
            $scope.loading = false;
            $scope.prepareEnrichTaskInstances = response.reverse();
          },
          function(reason) {
            $log.error(reason);
            $scope.loading = false;
          }
        );
      }

      $scope.clearInput = function() {
        $scope.searchPeople = {};
        $scope.searchPrepareEnrich = {};
      };

      $scope.extractSearchableData = function(elem) {
        if (elem) {
          return {
            taskName: elem.taskName,
            taskListName: elem.taskListName,
            userName: elem.userName,
          };
        }
      };

      $scope.toggleTask = function(task) {
        if (task.taskStatus === undefined || task.taskStatus === 'not_started') {
          taskListInstanceService
            .markPrepareEnrichReady(task._id.id, task.taskInstanceId.id, task.assigneeId.id, $scope.user.userId)
            .then(
              function() {
                task.taskStatus = 'in_progress';
              },
              function(reason) {
                $log.error(reason);
                loadData();
              }
            );
        }
      };

      $scope.takePrepareEnrich = function(task) {
        windowService.redirectHash(
          '/assessment-viewer-publicTraining/prepare-enrich/' +
            task.assigneeId.id +
            '/' +
            task.organizationId.id +
            '/' +
            task._id.id +
            '/' +
            task.taskInstanceId.id +
            '/' +
            $scope.user.logInEmail +
            '/true'
        );
      };

      $scope.matchPrepareEnrichStatus = function(value) {
        var status = $scope.searchPrepareEnrich.status;
        if (status === undefined || status.trim() === '') {
          return true;
        } else if (status === 'ready') {
          return !!value.isLicensed;
        } else if (status === 'not_ready') {
          return !!!value.isLicensed;
        } else {
          return false;
        }
      };

      init();
    },
  ]
);
