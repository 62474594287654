angular.module('lwNamb').directive(
  'helpAndInfoBox',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/help-and-info-box.html',
        scope: {
          //ICON Choices (REQUIRED)
          //Information - fa-info-circle
          //Help - fa-question-circle

          //BUTTON SIZE Choices (REQUIRED)
          //btn-sm
          //btn-md
          //btn-lg

          icon: '@',
          header: '@',
          message: '@',
          buttonIcon: '@',
          buttonText: '@',
          buttonSize: '@',
          action: '&buttonAction',
          dismissible: '@',
          sidebox: '@',
        },
        controller: [
          '$scope',
          '$log',
          'profileService',
          'userService',
          function($scope, $log, profileService, userService) {
            $scope.hidden = true;

            function init() {
              profileService.getPreferences().then(function(prefs) {
                $scope.hidden = !prefs.showAllInformationBoxes;
              });
            }

            $scope.hideAll = function(hide) {
              $scope.hiding = true;
              userService
                .user()
                .then(
                  function(user) {
                    profileService.savePreferences(user.userId, false);
                    $scope.hidden = true;
                  },
                  function() {
                    $log.error('No user returned in helpAndInfoBox');
                  }
                )
                .finally(function() {
                  $scope.hiding = false;
                  hide();
                });
            };

            init();
          },
        ],
      };
    },
  ]
);
