import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { Grid, Type } from '../StyleGuide';
import SessionTimeline from './timeline/SessionTimeline';
import SessionTimelineSupplies from './timeline/SessionTimelineSupplies';
import sessionTimelineService from '../services/sessionTimelineService';
import SessionImageAndDetails from './SessionImageAndDetails';
import BibleStudyBanner from './BibleStudyBanner';
import { Button } from '../components/Buttons';
import { ymdFromDate } from '../utils/dateUtils';
import { SessionTimelineTabs, SessionTimelineTab } from '../components/SessionTimelineElements';
import { SessionTimelineProvider } from '../contexts/SessionTimelineContext';
import SessionTimelinePrintStyles from './timeline/SessionTimelinePrintStyles';
import DownloadPresentationMediaButton, {
  getPresentationMediaArchiveName,
} from './timeline/DownloadPresentationMediaButton';
import { Container, FixedHeaderContainer, FixedHeaderContent } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import SessionHeader from './SessionHeader';
import { CustomizationIndicator } from '../components/SessionElements';
import useCurriculum from '../hooks/useCurriculum';

const Pages = Object.freeze({
  LEADERGUIDE: 'leaderguide',
  SUPPLIES: 'supplies',
});

const PrintTitleHeader = ({ title, subtitle }) => (
  <div className="session-timeline__title">
    <img src="/img/hyfi-logo-print.png" />
    <div>
      <h1>{title}</h1>
      <h5>{subtitle}</h5>
    </div>
  </div>
);

PrintTitleHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

const CustomizationMessage = styled.div`
  font-size: ${Type.Scale._2};
  font-weight: ${Type.Weight.light};
  color: ${Type.Color.medium};
  margin: -${Grid._3} 0 ${Grid._4} 0;

  ${CustomizationIndicator} {
    margin-right: ${Grid._2};
  }
`;

const transformPreviewTimeline = timeline => ({
  ...timeline,
  viewPreference: 'all', // ensure the previewer starts with all the content viewable
});

export default function BibleStudySessionTimeline({ preview }) {
  const [data, setData] = useState({ isLoading: true });
  const [activePage, setActivePage] = useState(Pages.LEADERGUIDE);
  const [scrollToData, setScrollToData] = useState();
  const { sessionId, issueId, timelineId, timelineCustomizationId } = useParams();
  const curriculum = useCurriculum();
  const presentationMediaArchiveName = getPresentationMediaArchiveName(
    curriculum.brand.name,
    curriculum.ageCategory,
    ymdFromDate(data.session?.scheduledDate),
    data.timeline?.name
  );

  useEffect(() => {
    sessionTimelineService
      .getTeacherSessionAndTimeline(issueId, sessionId, timelineId, timelineCustomizationId)
      .then(([session, timeline]) => setData({ isLoading: false, session, timeline }))
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
        setData({ isLoading: false, isError: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scrollToData) {
      scroller.scrollTo(scrollToData.id, {
        smooth: true,
        duration: 500,
        offset: scrollToData.isFlexOption ? -90 : 0,
        containerId: 'session-timeline-container',
      });

      setScrollToData();
    }
  }, [activePage]);

  const scrollTo = (id, isFlexOption) => {
    setScrollToData({ id: `contents-${id}`, isFlexOption });
    setActivePage(Pages.LEADERGUIDE);
  };

  const print = () => {
    navigator.userAgent.indexOf('Safari') > -1 ? document.execCommand('print', false, null) : window.print();
  };

  return (
    <>
      <SessionTimelinePrintStyles options={curriculum.brand.printOptions} />

      <PrintTitleHeader title={data.timeline?.name} subtitle={curriculum.ageCategory} />

      <FixedHeaderContainer fullViewport>
        <div>
          {!preview && (
            <SessionHeader
              brandName={`${curriculum.brand.name}: ${curriculum.ageCategory}`}
              sessionName={data.session ? data.session.name : 'Loading...'}
              isLoading={data.isLoading}
            />
          )}
        </div>

        <FixedHeaderContent id="session-timeline-container">
          <BibleStudyBanner />
          {data.isLoading ? (
            <Container>
              <LoadingState />
            </Container>
          ) : data.isError || !data.session ? (
            <Container>
              <ErrorMessage />
            </Container>
          ) : (
            <>
              <Container>
                <SessionImageAndDetails
                  session={data.session}
                  preDescriptionDetails={
                    timelineCustomizationId && (
                      <CustomizationMessage>
                        <CustomizationIndicator />
                        This session has been customized by one of your church admins
                      </CustomizationMessage>
                    )
                  }
                />
              </Container>

              <SessionTimelineTabs>
                <SessionTimelineTab page={Pages.LEADERGUIDE} activePage={activePage} onTabClick={setActivePage}>
                  <span className="fas fa-chalkboard-teacher fa-fw hidden-xs"></span>Leader Guide
                </SessionTimelineTab>
                <SessionTimelineTab page={Pages.SUPPLIES} activePage={activePage} onTabClick={setActivePage}>
                  <span className="fas fa-folder-open fa-fw hidden-xs"></span>Supplies
                </SessionTimelineTab>
              </SessionTimelineTabs>

              <SessionTimelineProvider
                timeline={preview ? transformPreviewTimeline(data.timeline) : data.timeline}
                curriculum={curriculum}
                session={data.session}
              >
                {activePage === Pages.LEADERGUIDE ? (
                  <SessionTimeline
                    title={data.timeline.name || 'Leader Guide'}
                    subtitle="A step by step leader guide to lead and facilitate the session."
                    actionButtons={
                      <>
                        {presentationMediaArchiveName && (
                          <DownloadPresentationMediaButton
                            timelineSegments={data.timeline.segments}
                            archiveId={data.timeline.archiveId}
                            archiveName={presentationMediaArchiveName}
                          />
                        )}
                        <Button onClick={print}>Print Leader Guide</Button>
                      </>
                    }
                    config={{ stickyOffset: 0, scrollContainerId: 'session-timeline-container', topOffset: 68 }}
                    useViewPreferences
                  />
                ) : (
                  activePage === Pages.SUPPLIES && <SessionTimelineSupplies onViewClick={scrollTo} />
                )}
              </SessionTimelineProvider>
            </>
          )}
        </FixedHeaderContent>
      </FixedHeaderContainer>
    </>
  );
}

BibleStudySessionTimeline.propTypes = {
  preview: PropTypes.bool,
};
