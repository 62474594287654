angular.module('lwNamb').controller(
  'TrainingPathwayCtrl',

  [
    '$scope',
    '$routeParams',
    'windowService',
    'pathwayService',
    '$log',
    function($scope, $routeParams, windowService, pathwayService, $log) {
      var init = function() {
        $scope.loading = true;
        $scope.pathwayId = $routeParams.pathwayId;
        getPathway();
      };

      var getPathway = function() {
        pathwayService.getAllPathways().then(
          function(response) {
            for (var i = 0; i < response.length; i++) {
              if (response[i]._id.id === $scope.pathwayId) {
                $scope.loading = false;
                $scope.pathway = response[i];
              }
            }
          },
          function(reason) {
            $log.error(reason);
          }
        );
      };

      $scope.redirectTo = function(path) {
        windowService.redirectHash(path);
      };

      $scope.redirectToTraining = function(training) {
        var type, trainingId;
        if (training._type === 'TaskList') {
          type = 'Tasklist';
          trainingId = training.taskListId.id;
        } else {
          type = 'Workflow';
          trainingId = training.workflowId;
        }
        windowService.redirectHash('#/training/' + trainingId);
      };

      init();
    },
  ] //End Training Pathway Ctrl
);
