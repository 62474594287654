angular.module('lwNamb').factory(
  'searchService',

  [
    'api',
    'qt',
    'serverPropertiesService',
    'catalogService',
    'apiUrlService',
    function(api, qt, serverPropertiesService, catalogService, apiUrlService) {
      var timeoutSeconds = 20;

      return {
        findAllByOwnerAndQuery: function(orgId, query, selectedFacets, showUnpublished, hasGridSubscription) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          catalogService.getCatalogForSite().then(
            function(catalog) {
              if (orgId === undefined) {
                serverPropertiesService.getGridRootOrgId().then(function(gridRootOrgId) {
                  postQuery(gridRootOrgId, query, selectedFacets, deferred, showUnpublished, catalog._id.id, hasGridSubscription);
                });
              } else {
                postQuery(orgId, query, selectedFacets, deferred, showUnpublished, catalog._id.id, hasGridSubscription);
              }
            },
            function() {
              postQuery(orgId, query, selectedFacets, deferred, showUnpublished, undefined, hasGridSubscription);
            }
          );

          return deferred.promise;
        },
        findTraining: function(trainingId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          api.get('/v1/search/' + trainingId).then(
            function(response) {
              deferred.resolve(response.data);
            },
            function failure(reason) {
              deferred.reject(reason);
            }
          );

          return deferred.promise;
        },
      };

      function postQuery(orgId, query, selectedFacets, deferred, showUnpublished, catalogId, hasGridSubscription) {
        var obj = {
          ownerId: orgId,
          query: query == '' ? undefined : query,
          catalogId: catalogId,
        };

        var publishedOnly = showUnpublished === undefined ? true : !showUnpublished;
        var facets = publishedOnly === true ? { published: 'true' } : {};
        if (selectedFacets !== undefined) {
          selectedFacets.forEach(function(item) {
            facets[item.type] = item.type === 'trainingType' && item.key === 'Task List' ? 'Tasklist' : item.key;
          });
        }
        if (!angular.equals(facets, {})) {
          obj.facets = facets;
        }

        function setupFacet(item) {
          item.type = this.type;
          return item;
        }

        api.post('/v1/search', obj).then(
          function(response) {
            if (response.data.aggregations) {
              response.data.facets = [];
              if (response.data.aggregations.trainingType) {
                response.data.aggregations.trainingType.buckets.map(function(item) {
                  if (item.key === 'Tasklist') item.key = 'Task List';
                  return item;
                });
              }
              for (var key in response.data.aggregations) {
                if (response.data.aggregations.hasOwnProperty(key)) {
                  var facetInfo = '';
                  if (key === 'categories') facetInfo = { key: 'Categories', rank: 4, type: key };
                  else if (key === 'ownerName') facetInfo = { key: 'Content', rank: 2, type: key };
                  else if (key === 'searchAccess') facetInfo = { key: 'Access', rank: !!hasGridSubscription ? 5 : 1, type: key };
                  else if (key === 'trainingType') facetInfo = { key: 'Training Type', rank: 3, type: key };
                  else facetInfo = { key: key, rank: 5, type: key };

                  response.data.aggregations[key].buckets.map(setupFacet, { type: facetInfo.type });
                  response.data.facets.push({
                    key: facetInfo.key,
                    rank: facetInfo.rank,
                    values: response.data.aggregations[key].buckets,
                  });
                }
              }
            }
            delete response.data.aggregations;
            deferred.resolve(response.data);
          },
          function failure(reason) {
            deferred.reject(reason);
          }
        );
      }
    },
  ]
);
