angular.module('lwNamb').controller('AddStepCtrl', [
  '$rootScope',
  '$scope',
  '$window',
  'windowService',
  '$routeParams',
  'buildService',
  'taskListService',
  'alertService',
  '$localStorage',
  function (
    $rootScope,
    $scope,
    $window,
    windowService,
    $routeParams,
    buildService,
    taskListService,
    alertService,
    $localStorage
  ) {
    var init = function () {
      $scope.ownerId = '';
      $scope.allTasklists = [];
      $scope.tasklist = { name: '' };
      $scope.selectedTasklist = {};
      $scope.filter = {
        attr: '-lastModifiedDate',
      };

      $scope.stepId = $routeParams.stepId;
      $scope.stepToAdd = $localStorage.stepToAdd.task;
      $scope.isLoading = true;
      $scope.showOptions = true;

      buildService.ownerId().then(function (response) {
        $scope.ownerId = response;
        taskListService
          .getAvailableTasklistsForOrg($scope.ownerId, true)
          .then(function (allTasklists) {
            $scope.allTasklists = allTasklists;
          }, failure)
          .finally(function () {
            $scope.isLoading = false;
          });
      });
    };

    $scope.cancel = function () {
      $window.history.back();
    };

    $scope.toggleView = function () {
      $scope.showOptions = !$scope.showOptions;
      window.scrollTo(0, 0);
    };

    $scope.redirectToCourseSelection = function () {
      windowService.redirectHash('#/add-step/' + $scope.stepId + '/select-training/');
    };

    $scope.selectCourse = function (tasklist) {
      if ($scope.selectedTasklist.id !== tasklist.id) {
        $scope.selectedTasklist = tasklist;
      } else {
        $scope.selectedTasklist = {};
      }
    };

    $scope.addToExistingCourse = function () {
      $scope.isAddingStep = true;
      taskListService
        .AddTaskToTaskList($scope.selectedTasklist.id, $scope.stepToAdd, $scope.user.userId)
        .then(success, failure);
    };

    $scope.addToNewCourse = function (isValid) {
      $scope.submitted = true;
      $scope.duplicateNameError = $scope.allTasklists.find(function (tl) {
        return tl.name.toLowerCase() === $scope.tasklist.name.toLowerCase();
      });

      if ($scope.duplicateNameError) return ($scope.duplicateName = $scope.tasklist.name);
      if (!isValid) return;

      $scope.isCreating = true;

      taskListService
        .createTasklist($scope.ownerId, $scope.tasklist.name, $scope.user.userId, [$scope.stepToAdd])
        .then(success, function (reason) {
          $scope.isCreating = false;
          failure();
        });
    };

    $scope.clearData = function () {
      $scope.tasklist = { name: '' };
      $scope.submitted = false;
      $scope.isCreating = false;
      $scope.isAddingStep = false;
      $scope.duplicateNameError = false;
      $scope.selectedTasklist = {};
    };

    function success(tasklistId) {
      var name = $scope.isCreating ? $scope.tasklist.name : $scope.selectedTasklist.name,
        tlId = $scope.isCreating ? tasklistId : $scope.selectedTasklist.id;

      alertService.show({
        title: 'Step Added!',
        content:
          'View <a data-qa-hook="stepAddedCourseBuilderLink" href="#/build/courses/' +
          tlId +
          '" ng-click="alertService.destroy()">' +
          name +
          '</a> in Course Builder',
        type: 'success success-with-content',
        dismissible: true,
      });
      windowService.redirectHash($localStorage.stepToAdd.origin);
      $scope.clearData();
    }

    function failure() {
      alertService.show({
        title: 'Error! Something went wrong.',
        type: 'danger',
        show: true,
        dismissible: true,
        duration: 20,
      });
    }

    init();
  },
]);
