angular.module('lwNamb').controller(
  'SessionViewerCtrl',

  [
    '$scope',
    '$routeParams',
    '$log',
    '$window',
    'windowService',
    'apiUrlService',
    'curriculumService',
    'analyticsService',
    'fileUrlService',
    function(
      $scope,
      $routeParams,
      $log,
      $window,
      windowService,
      apiUrlService,
      curriculumService,
      analyticsService,
      fileUrlService
    ) {
      function init() {
        $scope.loading = true;
        $scope.curriculumId = $routeParams.curriculumId;
        $scope.issueId = $routeParams.issueId;
        $scope.series = [];
        $scope.session = {};
        $scope.isSupportMaterials = false;

        if ($window.location.hash.indexOf('manage-session-viewer') > -1) {
          $scope.isManageCurriculum = true;
          analyticsService.trackFeature('manage-session-viewer', 'loaded');
        } else {
          $scope.isManageCurriculum = false;
        }

        loadSession();
      }

      function loadSession() {
        curriculumService
          .getIssueSessionAndMaterials($routeParams.issueId, $routeParams.sessionId, $routeParams.curriculumId)
          .then(
            function(response) {
              $scope.issue = response.issue;
              $scope.session = response.session;
              $scope.materials = response.materials;
              $scope.materials.map(function(material) {
                fileUrlService.getFileMetaData(material.mediaId).then(
                  function(response) {
                    material.fileName = response.fileMetaData.fileName;
                  },
                  function(response) {
                    $log.error(response);
                  }
                );
              });
              $scope.series = response.series;
              sortAllMaterials();
              $scope.name = $scope.session.name;
            },
            function(response) {
              $log.error(response + ' error looking up session');
              $scope.isError = true;
            }
          )
          .finally(function() {
            $scope.loading = false;
          });
      }

      function sortAllMaterials() {
        $scope.series.forEach(function(series) {
          series.materials = $scope.materials.filter(function(material) {
            return series.seriesId === material.seriesId;
          });
        });
      }

      $scope.downloadAll = function(materials, name) {
        var path = curriculumService.downloadAllUrl(materials, name);
        if (path !== '') {
          windowService.openUrl(apiUrlService.getUrl() + path);
        }
      };

      $scope.redirectToMaterial = function(material) {
        if (material.materialType === 'Video' && $scope.isManageCurriculum) {
          windowService.redirectHash(
            '/curriculum/' +
              $routeParams.curriculumId +
              '/issue/' +
              $routeParams.issueId +
              '/series/' +
              material.seriesId +
              '/session/' +
              $routeParams.sessionId +
              '/manage-material-viewer/' +
              material.materialId
          );
        } else if (material.materialType === 'Video' && !$scope.isManageCurriculum) {
          windowService.redirectHash(
            '/curriculum/' +
              $routeParams.curriculumId +
              '/issue/' +
              $routeParams.issueId +
              '/series/' +
              material.seriesId +
              '/session/' +
              $routeParams.sessionId +
              '/material-viewer/' +
              material.materialId
          );
        } else if (material.materialType === 'Link' && $scope.isManageCurriculum) {
          windowService.redirectHash(
            '/curriculum/' +
              $routeParams.curriculumId +
              '/issue/' +
              $routeParams.issueId +
              '/series/' +
              material.seriesId +
              '/session/' +
              $routeParams.sessionId +
              '/manage-material-viewer/' +
              material.materialId
          );
        } else if (material.materialType === 'Link' && !$scope.isManageCurriculum) {
          windowService.redirectHash(
            '/curriculum/' +
              $routeParams.curriculumId +
              '/issue/' +
              $routeParams.issueId +
              '/series/' +
              material.seriesId +
              '/session/' +
              $routeParams.sessionId +
              '/material-viewer/' +
              material.materialId
          );
        } else if (material.materialType === 'Text' && $scope.isManageCurriculum) {
          windowService.redirectHash(
            '/curriculum/' +
              $routeParams.curriculumId +
              '/issue/' +
              $routeParams.issueId +
              '/series/' +
              material.seriesId +
              '/session/' +
              $routeParams.sessionId +
              '/manage-material-viewer/' +
              material.materialId
          );
        } else if (material.materialType === 'Text' && !$scope.isManageCurriculum) {
          windowService.redirectHash(
            '/curriculum/' +
              $routeParams.curriculumId +
              '/issue/' +
              $routeParams.issueId +
              '/series/' +
              material.seriesId +
              '/session/' +
              $routeParams.sessionId +
              '/material-viewer/' +
              material.materialId
          );
        } else if (material.materialType === 'Download') {
          fileUrlService.openFile('', material.fileName, '/curriculum-storage/files/' + material.mediaId);
        } else {
          $log.warning('Material type: ' + material.materialType + ' is not supported.');
        }
      };

      $scope.redirectToPrevious = function() {
        if ($scope.isManageCurriculum) {
          windowService.redirectHash(
            '/curriculum/' + $routeParams.curriculumId + '/manage-issue-viewer/' + $routeParams.issueId
          );
        } else {
          windowService.redirectHash(
            '/curriculum/' + $routeParams.curriculumId + '/issue-viewer/' + $routeParams.issueId
          );
        }
      };

      $scope.setFeedbackType = function(type) {
        $scope.feedbackSuccess = false;
        if (type === 'changeDate') {
          $scope.feedbackType = 'changeDate';
          $scope.feedbackQuestion = 'Would you be interested in changing the date for this session?';
          analyticsService.trackFeature($scope.feedbackType, 'viewed');
        } else if (type === 'disableSession') {
          $scope.feedbackType = 'disableSession';
          $scope.feedbackQuestion = 'Would you be interested in disabling the date for this session?';
          analyticsService.trackFeature($scope.feedbackType, 'viewed');
        }
      };

      $scope.sendFeedback = function(feedbackBoolean) {
        //Send feedback to archive with feedbackBoolean and type for data driven development
        //Look into disabling feedback after user submits feedback
        $scope.feedbackSuccess = true;
        analyticsService.trackFeature($scope.feedbackType, feedbackBoolean);
      };

      $scope.trackClosedFeedback = function() {
        analyticsService.trackFeature($scope.feedbackType, 'closed');
      };

      init();
    },
  ]
);
