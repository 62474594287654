angular.module('lwNamb').directive(
  'loadingAssignTrainingViewer',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/loading-assign-training-viewer.html',
      };
    },
  ]
);
