angular.module('lwNamb').filter('userFilter', [
  '$filter',
  function($filter) {
    var defaultFilter = $filter('filter');
    var convertFieldListToObject = function(fields) {
      var returnObj = {};
      fields.forEach(function(elem, index) {
        returnObj[index] = elem;
      });
      return returnObj;
    };

    var defaultMemberInfo = function(member) {
      var fields = [];
      fields.push(member.email);
      fields.push(member.emailAddress);
      fields.push(member.firstName);
      fields.push(member.lastName);
      fields.push(member.firstName + ' ' + member.lastName);
      fields.push(member.displayName);
      if (member.profile) {
        var profile = member.profile;
        if (profile.notificationEmail && profile.notificationEmail.id) {
          fields.push(profile.notificationEmail.id);
        }
        if (profile.phone) {
          fields.push(profile.phone.number);
        }
      }

      return convertFieldListToObject(fields);
    };

    return function(input, searchParam, preprocessor, transformer) {
      if (input && input.length > 0) {
        var results = input.filter(function(data) {
          var preprocessedData = data;
          if (preprocessor) {
            preprocessedData = preprocessor(preprocessedData);
          }
          var transformerFn = transformer ? transformer : defaultMemberInfo;
          var relevantMemberInfo = transformerFn(preprocessedData);
          var filteredResult = defaultFilter([relevantMemberInfo], searchParam);
          if (filteredResult && filteredResult.length) {
            return true;
          } else {
            return false;
          }
        });
        return results;
      } else {
        return input;
      }
    };
  },
]);
