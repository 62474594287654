angular.module('lwNamb').directive(
  'coachPane',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/coach-pane.html',
        scope: {
          userId: '@',
          _paneId: '@paneId',
          orgId: '@',
          origin: '@',
        },
        replace: true,
        controller: [
          '$scope',
          'State',
          '$localStorage',
          function($scope, State, $localStorage) {
            $scope.paneId = 'coach-pane-' + $scope._paneId;

            $scope.$storage = $localStorage;

            var state = State($scope.paneId);
            $scope.selected = state.get();

            $scope.select = function(menuId) {
              state.put('menu', menuId);
            };

            $scope.$on('Coachee Changed', function() {
              if ($scope.selected.menu === 'task') {
                state.put('menu', 'task-lists');
              }
              if ($scope.selected.menu === 'note') {
                state.put('menu', 'notes');
              }
              if ($scope.selected.menu === 'scorecard') {
                state.put('menu', 'scorecards');
              }
            });

            $scope.$on('Reset Coach Menus', function() {
              state.put('menu', '');
            });
          },
        ],
      };
    },
  ]
);
