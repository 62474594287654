import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MediumPrimaryButton, MediumButton } from '../components/Buttons';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../components/ModalElements';
import { useUser } from '../authentication';
import { handleError } from '../utils/apiUtils';
import licenseService from '../services/licenseService';
import alertService from '../services/AlertService';
import formatDate from './utils/formatDate';
import LicenseDetails from './LicenseDetails';
import { ModalBodyHeader, LicenseDetailsContainer, SubscriptionModalList } from './SubscriptionModalElements';

const ResumeSubscriptionModal = ({ license, updateSubscriptionStatus, handleDismiss }) => {
  const user = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = () => {
    setIsSubmitting(true);

    licenseService
      .resumeSubscription(license.subscription.id, user.userId)
      .then(() => {
        alertService.show('Subscription Resumed');
        updateSubscriptionStatus('Active');
        handleDismiss();
      })
      .catch(reason => {
        handleError(reason);
        handleDismiss();
      });
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3 data-qa-hook="resumeSubscriptionModalHeader">Resume Subscription</h3>
      </ModalHeader>
      <ModalBody data-qa-hook="resumeSubscriptionModalMessage">
        <ModalBodyHeader>Are you sure you want to resume this subscription?</ModalBodyHeader>
        <LicenseDetailsContainer>
          <LicenseDetails license={license} />
        </LicenseDetailsContainer>
        <SubscriptionModalList>
          <li>This subscription will renew on {formatDate(license.subscription.nextRenewalDate)}</li>
          <li>You will not be charged until your next renewal date</li>
        </SubscriptionModalList>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton
          data-qa-hook="resumeSubscriptionModalConfirm"
          onClick={handleConfirm}
          disabled={isSubmitting}
          operating={isSubmitting}
        >
          Yes, Resume Subscription
        </MediumPrimaryButton>
        <MediumButton data-qa-hook="resumeSubscriptionModalCancel" onClick={handleDismiss}>
          No
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

ResumeSubscriptionModal.propTypes = {
  license: PropTypes.shape({
    subscription: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
      nextRenewalDate: PropTypes.string,
    }),
  }).isRequired,
  updateSubscriptionStatus: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default ResumeSubscriptionModal;
