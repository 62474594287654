angular.module('lwNamb').controller(
  'StepsViewerCtrl',

  [
    '$rootScope',
    '$scope',
    '$log',
    'userService',
    'taskListInstanceService',
    'workflowService',
    '$routeParams',
    '$location',
    'profileService',
    'purchaseService',
    'windowService',
    '$timeout',
    'orgService',
    function (
      $rootScope,
      $scope,
      $log,
      userService,
      taskListInstanceService,
      workflowService,
      $routeParams,
      $location,
      profileService,
      purchaseService,
      windowService,
      $timeout,
      orgService
    ) {
      var init = function () {
        $scope.loading = true;

        userService.user().then(
          function (user) {
            $scope.user = user;
            $scope.user.isOrgUser = '';
            $scope.user.currentOrgName = '';
            $scope.user.isCommentModerator = $scope.user.permissions.indexOf('Comment Moderator') > -1;

            getTraining(5);
          },
          function () {
            $log.error('No user returned in StepsViewerCtrl');
          }
        );
      };

      function getTraining(retryLimit) {
        var queryParams = $location.search();

        var promise = queryParams.isWorkflow ?
          workflowService.getWorkflowInstanceDetailById($routeParams.trainingId)
          : taskListInstanceService.getByInstanceId($routeParams.trainingId);

        promise.then(
          function (training) {
            var assignee = training.assignedUser !== undefined ? training.assignedUser : training.assigneeId.id;
            if (assignee !== $scope.user.userId) {
              profileService.getProfile(assignee).then(function (u) {
                $scope.$emit('REDIRECT_TO_LIFEWAY', 'login', 'login?route=' + encodeURIComponent($location.url().substring(1)), u.email);
              });
            }
            if(retryLimit > 0 && queryParams.isWorkflow && training.courses.filter(function(c){ return c._type === 'CourseInstanceDetail'; }).length === 0){
              $timeout(function(){getTraining(retryLimit - 1);}, 1000);
            } else {
              $scope.training = training;
              $scope.stepInstanceId = queryParams.stepId;

              if ($rootScope.org) {
                // No need to getOrg if org data is in scope
                $scope.user.isOrgUser = !!$rootScope.org;
                $scope.user.currentOrgName = $rootScope.org && $rootScope.org.name;
                $scope.loading = false;
              } else if ($scope.user.lastSelectedAccount) {
                // Will be used, in needed, only after a hard reload of the page
                orgService.getOrg($scope.user.lastSelectedAccount)
                  .then(function(org) {
                    $scope.user.isOrgUser = !!org;
                    $scope.user.currentOrgName = org && org.name;
                  })
                  .finally(function() {
                    $scope.loading = false;
                  })
                  .catch(function(reason) {
                    $log.error(reason);
                    $scope.loading = false;
                  });
              } else {
                $scope.loading = false;
              }

            }
          },
          function (reason) {
            $log.error(reason);
            $scope.loading = false;
          }
        );
      }

      $scope.openNotAvailableModal = function() {
        var modal = $modal({
          show: true,
          templateUrl: 'notAvailable.html',
          placement: 'center',
          scope: $scope,
          controller: 'StepsViewerCtrl',
        });
        modal.$promise.then(modal.show);
      };

      init();
    },
  ] //End Step Viewer Ctrl
);
