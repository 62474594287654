export const MaintenanceSVG = `
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 130 198" width="130">
      <path
      fill="#E4E4E4"
      fill-rule="evenodd"
      d="M65 198c35.899 0 65-3.134 65-7s-29.101-7-65-7c-35.898 0-65 3.134-65 7s29.102 7 65 7z"
      clip-rule="evenodd"
      />
      <path fill="#fff" d="M15 43a4 4 0 014-4h92a4 4 0 014 4v144a4 4 0 01-4 4H19a4 4 0 01-4-4V43z" />
      <path
      fill="#222"
      fill-rule="evenodd"
      d="M111 43H19v144h92V43zm-92-4a4 4 0 00-4 4v144a4 4 0 004 4h92a4 4 0 004-4V43a4 4 0 00-4-4H19z"
      clip-rule="evenodd"
      />
      <path
      fill="#E4E4E4"
      fill-rule="evenodd"
      d="M19 96h92v2H19v-2zM19 126h92v2H19v-2zM19 156h92v2H19v-2zM19 185h92v2H19v-2z"
      clip-rule="evenodd"
      />
      <path
      fill="#222"
      fill-rule="evenodd"
      d="M16 98h98v4H16v-4zM16 128h98v4H16v-4zM16 158h98v4H16v-4z"
      clip-rule="evenodd"
      />
      <path fill="#7B9F34" fill-rule="evenodd" d="M97 120a5 5 0 100-10 5 5 0 000 10z" clip-rule="evenodd" />
      <path
      fill="#222"
      fill-rule="evenodd"
      d="M90 115a7 7 0 1114 0 7 7 0 01-14 0zm7-3a3 3 0 100 6 3 3 0 000-6z"
      clip-rule="evenodd"
      />
      <path fill="#7B9F34" fill-rule="evenodd" d="M97 150a5 5 0 100-10 5 5 0 000 10z" clip-rule="evenodd" />
      <path
      fill="#222"
      fill-rule="evenodd"
      d="M90 145a7 7 0 1114 0 7 7 0 01-14 0zm7-3a3 3 0 100 6 3 3 0 000-6z"
      clip-rule="evenodd"
      />
      <path fill="#7B9F34" fill-rule="evenodd" d="M97 180a5 5 0 100-10 5 5 0 000 10z" clip-rule="evenodd" />
      <path
      fill="#222"
      fill-rule="evenodd"
      d="M90 175a7 7 0 1114 0 7 7 0 01-14 0zm7-3a3 3 0 100 6 3 3 0 000-6zM25.5 111a2 2 0 012-2h49.366a2 2 0 110 4H27.5a2 2 0 01-2-2zM25.5 118a2 2 0 012-2h20a2 2 0 110 4h-20a2 2 0 01-2-2z"
      clip-rule="evenodd"
      />
      <path
      fill="#1582A5"
      fill-rule="evenodd"
      d="M51.5 118a2 2 0 012-2h1a2 2 0 110 4h-1a2 2 0 01-2-2z"
      clip-rule="evenodd"
      />
      <path
      fill="#222"
      fill-rule="evenodd"
      d="M25.5 141a2 2 0 012-2h49.366a2 2 0 110 4H27.5a2 2 0 01-2-2zM25.5 148a2 2 0 012-2h20a2 2 0 110 4h-20a2 2 0 01-2-2z"
      clip-rule="evenodd"
      />
      <path
      fill="#1582A5"
      fill-rule="evenodd"
      d="M51.5 148a2 2 0 012-2h1a2 2 0 110 4h-1a2 2 0 01-2-2z"
      clip-rule="evenodd"
      />
      <path
      fill="#222"
      fill-rule="evenodd"
      d="M25.5 171a2 2 0 012-2h49.366a2 2 0 110 4H27.5a2 2 0 01-2-2zM25.5 178a2 2 0 012-2h20a2 2 0 110 4h-20a2 2 0 01-2-2z"
      clip-rule="evenodd"
      />
      <path
      fill="#1582A5"
      fill-rule="evenodd"
      d="M51.5 178a2 2 0 012-2h1a2 2 0 110 4h-1a2 2 0 01-2-2z"
      clip-rule="evenodd"
      />
      <path
      fill="#222"
      fill-rule="evenodd"
      d="M55.317 75.507l-.009-.008a2 2 0 00-2.62 3.022l1.293-1.487a169.571 169.571 0 00-1.292 1.488l.002.002.004.004.012.01.036.03.116.097c.098.079.235.187.41.317.349.261.85.613 1.485 1a21.242 21.242 0 005.331 2.324c4.551 1.262 10.723 1.216 17.135-3.721a2 2 0 10-2.44-3.17c-5.268 4.056-10.096 4.015-13.625 3.036-1.8-.5-3.284-1.252-4.319-1.883a15.164 15.164 0 01-1.172-.79 9.13 9.13 0 01-.347-.271zM52.5 70c1.933 0 3.5-1.343 3.5-3s-1.567-3-3.5-3-3.5 1.343-3.5 3 1.567 3 3.5 3zM77.5 70c1.933 0 3.5-1.343 3.5-3s-1.567-3-3.5-3-3.5 1.343-3.5 3 1.567 3 3.5 3z"
      clip-rule="evenodd"
      />
      <path
      fill="#DC980A"
      fill-rule="evenodd"
      d="M61 6a2 2 0 012-2h6a2 2 0 012 2v25a2 2 0 01-2 2h-6a2 2 0 01-2-2V6zm17 3a2 2 0 012-2h6a2 2 0 012 2v16a2 2 0 01-2 2h-6a2 2 0 01-2-2V9zM46 7a2 2 0 00-2 2v16a2 2 0 002 2h6a2 2 0 002-2V9a2 2 0 00-2-2h-6z"
      clip-rule="evenodd"
      />
      <path
      fill="#222"
      fill-rule="evenodd"
      d="M57.008 5.694A6 6 0 0163 0h6a6 6 0 015.992 5.694A5.995 5.995 0 0180 3h6a6 6 0 016 6v16a6 6 0 01-6 6h-6a5.994 5.994 0 01-5-2.682V31a6 6 0 01-6 6h-6a6 6 0 01-6-6v-2.682A5.994 5.994 0 0152 31h-6a6 6 0 01-6-6V9a6 6 0 016-6h6a5.995 5.995 0 015.008 2.694zM63 4a2 2 0 00-2 2v25a2 2 0 002 2h6a2 2 0 002-2V6a2 2 0 00-2-2h-6zm17 3a2 2 0 00-2 2v16a2 2 0 002 2h6a2 2 0 002-2V9a2 2 0 00-2-2h-6zM44 9a2 2 0 012-2h6a2 2 0 012 2v16a2 2 0 01-2 2h-6a2 2 0 01-2-2V9z"
      clip-rule="evenodd"
      />
      <path
      fill="#FFB20F"
      fill-rule="evenodd"
      d="M39 41H28.003a37.823 37.823 0 01-.003-.5C28 20.342 44.79 4 65.5 4S103 20.342 103 40.5c0 .167-.001.334-.003.5H93l-2.944 6.624A4 4 0 0186.4 50H45.6a4 4 0 01-3.656-2.375L39 41z"
      clip-rule="evenodd"
      />
      <path
      fill="#222"
      fill-rule="evenodd"
      d="M26 40.5C26 19.186 43.736 2 65.5 2S105 19.186 105 40.5c0 .176-.001.352-.004.528L104.969 43h-10.67l-2.416 5.437A6 6 0 0186.4 52H45.6a6 6 0 01-5.483-3.563L37.7 43H26.03l-.026-1.972A38.61 38.61 0 0126 40.5zm4.033-1.5H40.3l3.472 7.812A2 2 0 0045.6 48h40.8a2 2 0 001.828-1.188L91.7 39h9.267C100.159 20.688 84.637 6 65.5 6 46.362 6 30.84 20.688 30.033 39z"
      clip-rule="evenodd"
      />
      <g filter="url(#filter0_i)">
      <path
          fill="#FFB20F"
          fill-rule="evenodd"
          d="M61.071 6.069a2 2 0 012-2.069h5.859a2 2 0 011.999 2.069l-.862 25a2 2 0 01-2 1.931h-4.135a2 2 0 01-1.999-1.931l-.862-25z"
          clip-rule="evenodd"
      />
      </g>
      <g filter="url(#filter1_i)">
      <path
          fill="#FFB20F"
          fill-rule="evenodd"
          d="M78.105 9.1A2 2 0 0180.103 7h5.794a2 2 0 011.998 2.1l-.8 16a2 2 0 01-1.998 1.9h-4.194a2 2 0 01-1.998-1.9l-.8-16z"
          clip-rule="evenodd"
      />
      </g>
      <g filter="url(#filter2_i)">
      <path
          fill="#FFB20F"
          fill-rule="evenodd"
          d="M44.105 9.1A2 2 0 0146.103 7h5.794a2 2 0 011.998 2.1l-.8 16a2 2 0 01-1.998 1.9h-4.194a2 2 0 01-1.998-1.9l-.8-16z"
          clip-rule="evenodd"
      />
      </g>
      <defs>
          <filter
              id="filter0_i"
              width="9.86"
              height="29"
              x="61.07"
              y="4"
              color-interpolation-filters="sRGB"
              filterUnits="userSpaceOnUse"
          >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dx="3" dy="-3" />
              <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
              <feColorMatrix values="0 0 0 0 0.862745 0 0 0 0 0.596078 0 0 0 0 0.0392157 0 0 0 1 0" />
              <feBlend in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
              id="filter1_i"
              width="9.795"
              height="20"
              x="78.102"
              y="7"
              color-interpolation-filters="sRGB"
              filterUnits="userSpaceOnUse"
          >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dx="3" dy="-3" />
              <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
              <feColorMatrix values="0 0 0 0 0.862745 0 0 0 0 0.596078 0 0 0 0 0.0392157 0 0 0 1 0" />
              <feBlend in2="shape" result="effect1_innerShadow" />
          </filter>
          <filter
              id="filter2_i"
              width="9.795"
              height="20"
              x="44.102"
              y="7"
              color-interpolation-filters="sRGB"
              filterUnits="userSpaceOnUse"
          >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
              <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
              <feOffset dx="3" dy="-3" />
              <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
              <feColorMatrix values="0 0 0 0 0.862745 0 0 0 0 0.596078 0 0 0 0 0.0392157 0 0 0 1 0" />
              <feBlend in2="shape" result="effect1_innerShadow" />
          </filter>
      </defs>
  </svg>
`;

export const ServiceUnavailableSVG = `
  <svg xmlns="http://www.w3.org/2000/svg" width="130" height="159" fill="none">
      <style>
          @keyframes blink {
              0% {
                  opacity: 0.2;
              }
              20% {
                  opacity: 1;
              }
              100% {
                  opacity: 0.2;
              }
          }
          .blink {
              animation: blink 0.25s infinite;
          }
      </style>
      <path
          fill="#E4E4E4"
          d="M65 159c35.899 0 65-3.134 65-7s-29.101-7-65-7c-35.898 0-65 3.134-65 7s29.102 7 65 7z"
      />
      <path fill="#fff" d="M111 0H19a4 4 0 0 0-4 4v144a4 4 0 0 0 4 4h92a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4z" />
      <path
          fill="#333"
          fill-rule="evenodd"
          d="M19 4.007v143.986c0 .01-.003.007-.003.007h92.006c-.01 0-.003-.006-.003-.007V4.007c0-.01.003-.007.003-.007H18.997c.01 0 .003.006.003.007zm-4 0C15 1.794 16.8 0 18.997 0h92.006A3.997 3.997 0 0 1 115 4.007v143.986c0 2.213-1.8 4.007-3.997 4.007H18.997A3.997 3.997 0 0 1 15 147.993V4.007z"
          clip-rule="evenodd"
      />
      <path stroke="#E4E4E4" stroke-linecap="square" stroke-width="2" d="M20 58h90" />
      <path fill="#333" fill-rule="evenodd" d="M18 123h96v-4H16v4h2z" clip-rule="evenodd" />
      <path stroke="#E4E4E4" stroke-linecap="square" stroke-width="2" d="M20 148h90" />
      <path
          fill="#333"
          fill-rule="evenodd"
          d="M27.5 134h49.366a2 2 0 1 0 0-4H27.5a2 2 0 1 0 0 4zm0 7h20a2 2 0 1 0 0-4h-20a2 2 0 1 0 0 4z"
          clip-rule="evenodd"
      />
      <path
          fill="#1582A5"
          fill-rule="evenodd"
          d="M53.5 141h1a2 2 0 1 0 0-4h-1a2 2 0 1 0 0 4z"
          clip-rule="evenodd"
      />
      <path class="blink" fill="#F24236" d="M97 141a5 5 0 1 0 0-10 5 5 0 0 0 0 10z" />
      <path
          fill="#333"
          fill-rule="evenodd"
          d="M97 143a7 7 0 1 0 0-14 7 7 0 0 0 0 14zm0-4a3 3 0 1 1 0-6 3 3 0 0 1 0 6zM18 93h96v-4H16v4h2z"
          clip-rule="evenodd"
      />
      <path stroke="#E4E4E4" stroke-linecap="square" stroke-width="2" d="M20 118h90" />
      <path
          fill="#333"
          fill-rule="evenodd"
          d="M27.5 104h49.366a2 2 0 1 0 0-4H27.5a2 2 0 1 0 0 4zm0 7h20a2 2 0 1 0 0-4h-20a2 2 0 1 0 0 4z"
          clip-rule="evenodd"
      />
      <path
          fill="#1582A5"
          fill-rule="evenodd"
          d="M53.5 111h1a2 2 0 1 0 0-4h-1a2 2 0 1 0 0 4z"
          clip-rule="evenodd"
      />
      <path class="blink" fill="#F24236" d="M97 111a5 5 0 1 0 0-10 5 5 0 0 0 0 10z" />
      <path
          fill="#333"
          fill-rule="evenodd"
          d="M97 113a7 7 0 1 0 0-14 7 7 0 0 0 0 14zm0-4a3 3 0 1 1 0-6 3 3 0 0 1 0 6zM18 63h96v-4H16v4h2z"
          clip-rule="evenodd"
      />
      <path stroke="#E4E4E4" stroke-linecap="square" stroke-width="2" d="M20 88h90" />
      <path
          fill="#333"
          fill-rule="evenodd"
          d="M27.5 74h49.366a2 2 0 1 0 0-4H27.5a2 2 0 1 0 0 4zm0 7h20a2 2 0 1 0 0-4h-20a2 2 0 1 0 0 4z"
          clip-rule="evenodd"
      />
      <path fill="#1582A5" fill-rule="evenodd" d="M53.5 81h1a2 2 0 1 0 0-4h-1a2 2 0 1 0 0 4z" clip-rule="evenodd" />
      <path class="blink" fill="#F24236" d="M97 81a5 5 0 1 0 0-10 5 5 0 0 0 0 10z" />
      <path
          fill="#333"
          fill-rule="evenodd"
          d="M97 83a7 7 0 1 0 0-14 7 7 0 0 0 0 14zm0-4a3 3 0 1 1 0-6 3 3 0 0 1 0 6zM76.306 39.623l.715 4.057c.673 3.81-1.876 7.445-5.676 8.115a7.004 7.004 0 0 1-8.11-5.684l-1.32-7.49c.93-.01 2.39-.013 4.065.022l1.193 6.774a3.005 3.005 0 0 0 3.477 2.44 3.008 3.008 0 0 0 2.432-3.48l-.955-5.416c1.602.15 3.076.363 4.18.663l-.001-.001z"
          clip-rule="evenodd"
      />
      <path
          fill="#333"
          fill-rule="evenodd"
          d="M68.514 38.664l1 6a.999.999 0 1 0 1.972-.328l-1-6a.999.999 0 1 0-1.972.328z"
          clip-rule="evenodd"
      />
      <path
          fill="#333"
          fill-rule="evenodd"
          d="M50.027 43.704c.16-.095.51-.285 1.033-.54.9-.434 1.944-.872 3.117-1.282 8.075-2.824 16.994-2.822 25.887 1.886a2.002 2.002 0 0 0 1.872-3.536c-9.988-5.286-20.035-5.29-29.08-2.126-2.274.795-3.94 1.6-4.883 2.165a2.004 2.004 0 0 0-.69 2.745 2.003 2.003 0 0 0 2.744.69v-.002zM77.672 23l-2.122-2.12a2.004 2.004 0 0 1 0-2.83c.78-.78 2.048-.78 2.83 0l2.12 2.122 2.12-2.122c.782-.78 2.05-.78 2.83 0 .78.78.78 2.048 0 2.83L83.328 23l2.122 2.12c.78.782.78 2.05 0 2.83-.78.78-2.048.78-2.83 0l-2.12-2.122-2.12 2.122c-.782.78-2.05.78-2.83 0a2.004 2.004 0 0 1 0-2.83L77.672 23zm-31 0l-2.122-2.12a2.004 2.004 0 0 1 0-2.83c.78-.78 2.048-.78 2.83 0l2.12 2.122 2.12-2.122c.782-.78 2.05-.78 2.83 0 .78.78.78 2.048 0 2.83L52.328 23l2.122 2.12c.78.782.78 2.05 0 2.83-.78.78-2.048.78-2.83 0l-2.12-2.122-2.12 2.122c-.782.78-2.05.78-2.83 0a2.004 2.004 0 0 1 0-2.83L46.672 23z"
          clip-rule="evenodd"
      />
  </svg> 
`;
