angular.module('lwNamb').controller(
  'redeemEasyLinkCtrl',

  [
    '$scope',
    '$log',
    'alertService',
    '$routeParams',
    'userService',
    'blockUX',
    'windowService',
    'inviteService',
    '$localStorage',
    function($scope, $log, alertService, $routeParams, userService, blockUX, windowService, inviteService, $localStorage) {
      var init = function() {
        $scope.errorMessage = '';

        userService.user().then(
          function(user) {
            $scope.user = user;
            //Auto redeem after Login/Register
            if ($routeParams.prefix !== undefined) {
              if ($routeParams.groupCode !== undefined && $routeParams.groupCode.length === 4) {
                inviteService.validateGroupEasyLink($routeParams.prefix, $routeParams.groupCode).then(
                  function() {
                    $scope.redeem($routeParams.prefix, $routeParams.groupCode);
                  },
                  function() {
                    showError('MembershipCodeDeactivated');
                  }
                );
              } else {
                $scope.redeem($routeParams.prefix, $routeParams.groupCode);
              }
            }
          },
          function() {
            $log.error('No user returned in redeemInviteCtrl');
          }
        );
      };

      $scope.redeem = function(prefix, groupCodeOrTrainingId) {
        //Start Block UX
        var isGroupCode = groupCodeOrTrainingId === undefined || groupCodeOrTrainingId.length === 4;
        blockUX.start('Accepting Easy Link...', 0, 15000, false, 'Error');
        inviteService.acceptForPrefix(prefix, isGroupCode ? groupCodeOrTrainingId : undefined).then(
          function() {
            blockUX.stop();
            alertService.show({ type: 'success', title: 'Easy Link Accepted', duration: 5 });
            userService.checkSession().then(function(updatedUser) {
              $scope.user = updatedUser;
              if(isGroupCode) {
                $scope.redirectHashAfterLogin();
              } else {
                windowService.redirectHash("/add-to-your-training/"+groupCodeOrTrainingId+"?noLogout=1");
              }
            });
          },
          function(reason) {
            showError(reason);
          }
        );
      };

      function showError(reason) {
        $log.error(reason);
        blockUX.stop();
        $scope.showError = true;
        if (reason === 'NOT_FOUND') {
          $scope.errorHeader = 'Invalid Easy Link';
          $scope.errorMessage =
            "Oops! It looks like you've been given a bad link. Reach out to your administrator and validate the url.";
          $scope.errorButton = 'Skip for Now';
        } else if (reason === 'Already Exists Error') {
          $scope.errorHeader = "You've Already Used This Easy Link";
          $scope.errorMessage = "No need to use this Easy Link again, you're already in.";
          $scope.errorButton = 'Continue';
        } else if (reason === 'MembershipCodeDeactivated') {
          $scope.errorHeader = 'This link has been deactivated';
          $scope.errorMessage =
            'Oops! It looks like the link you’ve been given has been deactivated by your administrator. Please contact your administrator to receive a new link.';
          $scope.errorButton = 'Continue';
        } else {
          $scope.errorHeader = 'Something Went Wrong';
          $scope.errorMessage = "Oops! We're sorry for the inconvenience. Please try again.";
          $scope.errorButton = 'Skip for Now';
        }
      }

      $scope.redirectHashAfterLogin = function() {
        //Sync whitelabel in case the easy link was invalid
        $localStorage.syncOrg = true;
        $scope.$emit('SYNC_WHITELABEL_ORG');
        windowService.redirectHash(userService.getRedirectHashAfterLogin($scope.user));
      };

      init();
    },
  ]
);
