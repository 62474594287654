import React from 'react';
import Vimeo from '@u-wave/react-vimeo';
import PropTypes from 'prop-types';

export default function ThirdPartyVimeo({ url, onStart, onPlay, onProgress, onEnd, onError }) {
  const onTimeUpdate = ({ seconds, percent, duration }) => {
    if (onProgress) onProgress({ percentComplete: percent, elapsedSeconds: seconds, totalDuration: duration });
  };

  return (
    <Vimeo
      video={url}
      id={url}
      onReady={onStart}
      onPlay={onPlay}
      onEnd={onEnd}
      onError={onError}
      autoplay
      onTimeUpdate={onTimeUpdate}
    />
  );
}

ThirdPartyVimeo.propTypes = {
  url: PropTypes.string.isRequired,
  onStart: PropTypes.func,
  onPlay: PropTypes.func,
  onProgress: PropTypes.func,
  onEnd: PropTypes.func,
  onError: PropTypes.func,
};
