import submitCommand from '../../services/submitCommand';

export const replaceContent: (
  videoId: string,
  fileName: string,
  orgId: string,
  initiatingUserId: string
) => Promise<void> = (fileId, fileName, orgId, initiatingUserId) => {
  const cmd = {
    id: fileId,
    orgAccountId: orgId,
    fileName: fileName,
    forTask: false,
    initiatingUserId: initiatingUserId,
  };

  return submitCommand(fileId, cmd, 'UpdateFileMetadata', 'FileMetadataUpdated', 'UpdateFileMetadataError');
};
