import React from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../authentication';
import { TileButton } from '../components/Buttons';
import Breadcrumbs from '../components/Breadcrumbs';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import LoadingIndicator from '../components/LoadingIndicator';
import { usePathwayModal } from './hooks/usePathwayModal';
import { ActionsContainer } from './components/ActionsContainer';
import SettingsHeader from '../components/SettingsHeader';
import { PathwayTrainingItem } from './components/PathwayTrainingItem';
import {
  PageContainer,
  MainSectionContainer,
  HeaderButtonGroup,
  HeaderRow,
  PublishButton,
} from './components/LayoutElements';
import { AddTrainingListItem } from './components/AddTrainingListItem';
import { SortablePathwayList, SortablePathwayListItem } from './components/SortablePathwayList';
import { usePathwayBuilder } from './hooks/usePathwayBuilder';
import ErrorMessage from '../components/ErrorMessage';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';

export const PathwayBuilder = () => {
  const user = useUser();
  const { trainingId } = useParams<{ trainingId: string }>();
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();

  const {
    pathway,
    tasklists,
    isLoading,
    isError,
    redirectToSettings,
    reorder,
    removeEntity,
    handleTrainingItemRedirect,
    copyPathway,
    publishPathway,
    unpublishPathway,
  } = usePathwayBuilder();

  const {
    modal,
    openAddStepModal,
    openRemoveStepModal,
    openEasyLinkModal,
    openPublishModal,
    openUnpublishModal,
    openCopyModal,
    dismissModal,
  } = usePathwayModal();

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Pathways', route: `#${manageTrainingRoutePrefix}/pathways` },
    { name: pathway?.name || '' },
  ];

  const hasPathwayEntities = pathway.entities?.length > 2;

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      {isLoading ? (
        <LoadingIndicator />
      ) : isError ? (
        <ErrorMessage />
      ) : (
        <PageContainer>
          {pathway && (
            <>
              {hasPathwayEntities && !pathway.published && (
                <HelpAndInfoBox
                  title="Publish Pathway"
                  description="When you are finished building this pathway, use the Publish button to make it available."
                >
                  <PublishButton onClick={() => openPublishModal(publishPathway)}>Publish</PublishButton>
                </HelpAndInfoBox>
              )}

              <HeaderRow>
                <SettingsHeader title={pathway.name} subtitle="Pathway" icon={<i className="fas fa-route"></i>} />
                <HeaderButtonGroup>
                  <TileButton onClick={redirectToSettings}>
                    <i className="fas fa-pencil-alt"></i> Edit Settings
                  </TileButton>
                  {hasPathwayEntities && (
                    <TileButton onClick={() => openCopyModal(name => copyPathway(name, pathway.owner))}>
                      <i className="fas fa-copy"></i>Copy Pathway
                    </TileButton>
                  )}
                </HeaderButtonGroup>
              </HeaderRow>

              <MainSectionContainer>
                <SortablePathwayList onSort={reorder}>
                  {sortingItemIndex => (
                    <>
                      <AddTrainingListItem
                        onAddTrainingClick={() => openAddStepModal(0, pathway.entities, tasklists)}
                      />
                      {tasklists.map((tasklist, index) => (
                        <SortablePathwayListItem key={tasklist.id} index={index}>
                          <PathwayTrainingItem
                            isEmail={tasklist._type === 'Email'}
                            tasklist={tasklist}
                            onClick={() => handleTrainingItemRedirect(tasklist)}
                            onRemove={() =>
                              openRemoveStepModal(tasklist, () => removeEntity(tasklist.id).finally(dismissModal))
                            }
                          />
                          {sortingItemIndex !== index && (
                            <AddTrainingListItem
                              onAddTrainingClick={() => openAddStepModal(index + 1, pathway.entities, tasklists)}
                            />
                          )}
                        </SortablePathwayListItem>
                      ))}
                    </>
                  )}
                </SortablePathwayList>

                <ActionsContainer
                  canAssign={hasPathwayEntities}
                  isPublished={pathway.published}
                  onEasyLinkClick={() => openEasyLinkModal(trainingId, pathway.name, user?.lastSelectedAccount || '')}
                  onUnpublishClick={() => openUnpublishModal(unpublishPathway)}
                />
              </MainSectionContainer>

              {modal}
            </>
          )}
        </PageContainer>
      )}
    </>
  );
};
