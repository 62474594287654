import React from 'react';
import { useSteps } from './StepsContext';
import Section from './Section';
import DownloadStep from './DownloadStep';
import UploadStep from './UploadStep';
import LinkStep from './LinkStep';
import FeedbackStep from './FeedbackStep';

export default function StepSection() {
  const [{ currentStep }] = useSteps();
  const isUpload = currentStep.task._type === 'Upload';
  const taskDescription = currentStep.task && currentStep.task.taskDescription;

  return (
    <>
      {isUpload && <UploadStep />}
      {!isUpload && (
        <Section>
          {taskDescription && (
            <div data-qa-hook="stepDescription" dangerouslySetInnerHTML={{ __html: taskDescription }}></div>
          )}
          {currentStep.task._type === 'Download' && <DownloadStep />}
          {(currentStep.task._type === 'Link' || currentStep.task._type === 'MyWSBLink') && <LinkStep />}
          {currentStep.task._type === 'Feedback' && <FeedbackStep />}
        </Section>
      )}
    </>
  );
}
