import purchaseService from '../services/purchaseService';
import windowService from '../services/windowService';

export const purchaseLockedItem = itemNumber => {
  try {
    purchaseService.storeRedirectLocation();
    windowService.redirectToPurchase(itemNumber);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
