import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Breakpoints, Grid } from '../StyleGuide';
import { Container, PageTitle } from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import Tabs from '../components/Tabs';
import { CourseList } from './CourseList';
import { ContentList } from './ContentList';
import { PathwayList } from './PathwayList';
import catalogService from '../services/catalogService';
import { useUser } from '../authentication';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import windowService from '../services/windowService';

const StyledContainer = styled(Container)`
  @media screen and (max-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._5} 0;

    h1 {
      padding-left: ${Grid._4};
    }
  }
`;

type TrainingComponent = ({ ownerId }: { ownerId: string }) => JSX.Element;

const TabComponent: Record<string, TrainingComponent> = {
  pathways: PathwayList,
  courses: CourseList,
  content: ContentList,
};

const tabOptions = [
  { id: 'pathways', label: 'Pathways' },
  { id: 'courses', label: 'Courses' },
  { id: 'content', label: 'Content' },
];

export const ManageTraining = () => {
  const [data, setData] = useState({
    ownerId: '',
    isLoading: true,
    isError: false,
  });
  const user = useUser();
  const { manageTrainingType, trainingType, manageTrainingRoutePrefix, createManageTrainingCrumb } =
    useManageTrainingRoute();
  const { ownerId, isLoading } = data;
  const isAuthoring = manageTrainingType === 'author';
  const CurrentTab = TabComponent[trainingType];
  const crumbs = [{ name: 'Organization', route: '#/org' }, createManageTrainingCrumb('', false)];

  useEffect(() => {
    if (!user) return setData({ ownerId: '', isLoading: false, isError: true });

    if (isAuthoring) {
      catalogService
        .getCatalogIdForSite()
        .then((ownerId: string) => {
          setData({ ownerId, isLoading: false, isError: false });
        })
        .catch((error: Error) => {
          console.error(error);
          setData({ ownerId: '', isLoading: false, isError: true });
        });
    } else {
      setData({ ownerId: user.lastSelectedAccount, isLoading: false, isError: false });
    }
  }, []);

  const handleTabClick = (tabId: string) => {
    return windowService.redirectTo(`${manageTrainingRoutePrefix}/${tabId}`);
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <StyledContainer>
        <PageTitle className="mt-40">
          <h1>{isAuthoring ? 'Author' : 'Manage'} Training</h1>
        </PageTitle>
        {data.isError ? (
          <ErrorMessage />
        ) : (
          <Tabs items={tabOptions} handleTabClick={handleTabClick} defaultActiveTab={trainingType}>
            {isLoading ? <LoadingState /> : <CurrentTab ownerId={ownerId} />}
          </Tabs>
        )}
      </StyledContainer>
    </>
  );
};
