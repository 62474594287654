angular.module('lwNamb').directive('resumeSubscription', [
  function() {
    return {
      restrict: 'A',
      templateUrl: 'partials/directives/resume-subscription.html',
      controller: [
        '$scope',
        '$log',
        '$timeout',
        'licenseService',
        'alertService',
        'windowService',
        function($scope, $log, $timeout, licenseService, alertService, windowService) {
          $scope.resumeSubscription = function(license, closeModal) {
            $scope.resuming = true;
            licenseService
              .resumeSubscription(license.subscription.id, $scope.user.userId)
              .then(
                function() {
                  alertService.show({ title: 'Subscription Reactivated', type: 'success', duration: 5 });
                  license.subscription.status = 'Active';
                  if (license.seats.count.total > 1) {
                    windowService.redirectHash('/licenses/' + $scope.licenseId);
                  }
                },
                function(reason) {
                  //Check for payment failure vs generic error
                  alertService.show({
                    title: 'Unexpected Error!',
                    content:
                      'We have encountered a problem updating your account. Please call 866-627-8553 to resolve.',
                    type: 'danger danger-with-content',
                    duration: 20,
                  });
                  $log.error(reason);
                }
              )
              .finally(function() {
                $scope.resuming = false;
                $scope.retrieveUserData();
                closeModal();
              });
          };

          $scope.resumeLapsedSubscription = function(license, closeModal) {
            $scope.resuming = true;

            var seatQuantity, sku;

            if (license.seats.count.total === 1) {
              seatQuantity = 1;
              sku = license.subscription.item.number;
            } else {
              seatQuantity = parseInt($scope.pricingTiers[$scope.stepperIndex].seatQuantity);
              sku = $scope.pricingTiers[$scope.stepperIndex].sku;
            }

            licenseService
              .resumeLapsedSubscription(
                license.subscription.id,
                seatQuantity,
                sku,
                $scope.user.userId,
                $scope.user.userId
              )
              .then(
                function() {
                  $timeout(function() {
                    alertService.show({ title: 'Subscription Reactivated', type: 'success', duration: 5 });
                    license.expired = false;
                    if (license.seats.count.total > 1) {
                      windowService.redirectHash('/licenses/' + $scope.licenseId);
                    }
                    $scope.retrieveUserData();
                  }, 5000);
                },
                function(reason) {
                  //Check for payment failure vs generic error
                  alertService.show({
                    title: 'Unexpected Error!',
                    content:
                      'We have encountered a problem updating your account. Please call 866-627-8553 to resolve.',
                    type: 'danger danger-with-content',
                    duration: 20,
                  });
                  $log.error(reason);
                }
              )
              .finally(function() {
                $timeout(function() {
                  $scope.resuming = false;
                  closeModal();
                }, 5000);
              });
          };
        },
      ],
    };
  },
]);
