angular.module('lwNamb').factory('analyticsService', [
  '$window',
  '$log',
  'api',
  'qt',
  'licenseService',
  'userService',
  'date',
  'taskListInstanceService',
  function($window, $log, api, qt, licenseService, userService, date, taskListInstanceService) {
    var blocklist = ['f405ef08-f421-45b1-88f7-dbb6af9e7e00', 'caec3234-15cd-43c6-beed-5436080693ba'];

    function track(ddPayload, satelliteEventName, campaignPayload) {
      try {
        digitalData.event.push(ddPayload);
        _satellite.track(satelliteEventName);
      } catch (e) {}
      $log.debug(satelliteEventName);
      campaignPayload.serviceName = 'lwMgAutoCampaigns';
      campaignPayload.url = getCampaignUrl();
      api.post('/tracking', campaignPayload).then(
        function(result) {
          $log.debug(JSON.stringify(result));
        },
        function(error) {
          $log.error('Error: ' + JSON.stringify(error));
        }
      );
    }

    function excludeBlocklist(orgId, ddPayload, satelliteEventName, campaignPayload) {
      if (
        blocklist.filter(function(blockId) {
          return blockId === orgId;
        }).length === 0
      ) {
        track(ddPayload, satelliteEventName, campaignPayload);
      }
    }

    function communityOnly(ddPayload, satelliteEventName, campaignPayload) {
      userService.lookupUserOrgs().then(function(orgs) {
        if (orgs.length === 1 && orgs[0]._id.id === 'caec3234-15cd-43c6-beed-5436080693ba') {
          track(ddPayload, satelliteEventName, campaignPayload);
        }
      });
    }

    function paidUser(userId, orgId) {
      var deferred = qt.defer();
      var promises = [];
      promises.push(licenseService.getIndividualLicenses(userId));
      if (orgId !== undefined) {
        promises.push(hasGridSubscription(orgId));
        promises.push(hasCurriculumSubscription(orgId));
      }
      qt.all(promises).then(
        function(results) {
          deferred.resolve(results[0].length > 0 || !!results[1] || !!results[2]);
        },
        function() {
          deferred.resolve(undefined);
        }
      );
      return deferred.promise;
    }

    function hasGridSubscription(orgId) {
      getPairedLicenses(true, orgId);
    }

    function hasCurriculumSubscription(orgId) {
      getPairedLicenses(false, orgId);
    }

    function getPairedLicenses(isGrid, orgId) {
      return licenseService.getPairedLicenses(orgId).then(function(licenses) {
        var filtered = licenses.filter(function(license) {
          var isGridBool = !!isGrid;
          return isGridBool ? license.isGridSubscription : !license.isGridSubscription;
        });
        return filtered.length > 0;
      });
    }

    return {
      trackFeature: function(eventName, eventAction) {
        try {
          s.tl(true, 'o', 'FeatureTracking : ' + eventName + ' : ' + eventAction);
        } catch (e) {}
      },
      updateUser: function(user) {
        if (user === null || user === undefined) {
          digitalData.user = [];
        } else {
          userService.getAllPermissions().then(function(response) {
            digitalData.user = [
              {
                profile: [
                  {
                    profileInfo: {
                      profileID: user.userId,
                      permissions: response.permissions,
                    },
                  },
                ],
              },
            ];
          });
        }
      },
      pageChange: function(pageTitle, pageName) {
        var filteredName = pageName.replace(/\.[^/.]+$/, '');
        var windowUrl = $window.location.hostname;
        var env = 'prod';
        if (windowUrl.indexOf('local') >= 0) {
          env = 'local';
        } else if (windowUrl.indexOf('dev') >= 0) {
          env = 'dev';
        } else if (windowUrl.indexOf('stage') >= 0) {
          env = 'stage';
        }
        digitalData.pageInstanceId = env + ' : ' + pageTitle + ' : ' + filteredName;
        digitalData.page = {
          pageInfo: {
            pageID: pageTitle + ' : ' + filteredName,
            pageName: filteredName,
          },
        };
        try {
          _satellite.track('PageChange');
        } catch (e) {}
      },
      orgCreated: function(orgId, orgName, ownerId, firstName, lastName, email) {
        function t(paid) {
          track(
            {
              eventInfo: {
                eventName: 'NewOrganizationCreated',
                timeStamp: date.date(),
              },
              attributes: {
                orgName: orgName,
                orgId: orgId,
                ownerId: ownerId,
              },
            },
            'OrgCreated',
            {
              firstName: firstName,
              lastName: lastName,
              email: email,
              profileId: ownerId,
              orgName: orgName,
              orgId: orgId,
              orgCreator: 1,
              paidUser: !!paid,
            }
          );
        }
        paidUser(ownerId, orgId).then(
          function(paid) {
            t(paid);
          },
          function(error) {
            $log.error(error);
            t(undefined);
          }
        );
      },
      joinedOrg: function(orgId, orgName, firstName, lastName, email, userId) {
        function t(paid) {
          excludeBlocklist(
            orgId,
            {
              eventInfo: {
                eventName: 'JoinedOrg',
                timeStamp: date.date(),
              },
              attributes: {
                orgName: orgName,
                orgId: orgId,
                email: email,
              },
            },
            'JoinedOrg',
            {
              firstName: firstName,
              lastName: lastName,
              email: email,
              profileId: userId,
              orgName: orgName,
              orgId: orgId,
              dateJoinedOrg: date.date(),
              joinedOrg: 1,
              paidUser: !!paid,
            }
          );
        }
        paidUser(userId, orgId).then(
          function(paid) {
            t(paid);
          },
          function(error) {
            $log.error(error);
            t(undefined);
          }
        );
      },
      firstTimeUser: function(user) {
        function t(paid) {
          communityOnly(
            {
              eventInfo: {
                eventName: 'NewUser',
                timeStamp: date.date(),
              },
              attributes: {
                email: user.logInEmail || user.email,
                firstName: user.firstName,
                lastName: user.lastName,
              },
            },
            'FirstTimeUser',
            {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.logInEmail || user.email,
              profileId: user.userId,
              paidUser: !!paid,
            }
          );
        }
        paidUser(user.userId).then(
          function(paid) {
            t(paid);
          },
          function(error) {
            $log.error(error);
            t(undefined);
          }
        );
      },
      firstTaskListComplete: function(user, taskListInstanceId) {
        function t(paid, tlName) {
          communityOnly(
            {
              eventInfo: {
                eventName: 'FirstTaskListComplete',
                timeStamp: date.date(),
              },
              attributes: {
                email: user.logInEmail || user.email,
                firstName: user.firstName,
                lastName: user.lastName,
              },
            },
            'FirstTaskListComplete',
            {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.logInEmail || user.email,
              profileId: user.userId,
              paidUser: !!paid,
              taskListId: taskListInstanceId,
              taskListName: tlName,
            }
          );
        }
        taskListInstanceService.getCompleted(user.userId).then(function(completed) {
          if (completed.length === 1) {
            taskListInstanceService.getByInstanceId(taskListInstanceId).then(function(tl) {
              paidUser(user.userId).then(
                function(paid) {
                  t(paid, tl.name);
                },
                function(error) {
                  $log.error(error);
                  t(undefined);
                }
              );
            });
          }
        });
      },
    };
  },
]);
