import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Color, Type, Breakpoints } from '../StyleGuide';
import { Container } from '../components/Layout';
import Chevron from '../components/Chevron';

const CrumbContainerFluid = styled.div`
  z-index: 100;
  position: relative;
  background: ${Color.white};
  box-shadow: inset 0px 1px 2px rgba(70, 72, 84, 0.1);
  border-bottom: 1px solid ${Color.Gray._10};
`;

const Crumbs = styled.nav`
  display: flex;
  align-items: center;
  margin: 0;
`;

const Crumb = styled.a`
  margin: 20px ${Grid._4};
  font-size: ${Type.Scale._2};
  text-transform: uppercase;
  font-weight: ${Type.Weight.semibold};
  letter-spacing: 0.05em;
  border-bottom: 2px solid transparent;
  transition: all 0.15s ease-in-out;
  &:hover {
    border-bottom-color: ${Color.Blue._50};
  }
  &:first-child {
    margin-left: 0;
  }
  @media screen and (min-width: ${Breakpoints.screen_lg}) {
    &:first-child {
      margin-left: ${Grid._4};
    }
  }
`;

const CurrentPage = styled.span`
  font-size: ${Type.Scale._2};
  margin-left: ${Grid._4};
`;

export default function BreadcrumbsSubMenu({ crumbs }) {
  let hasCurrent = crumbs.filter(c => c.route).length < crumbs.length;
  return (
    <CrumbContainerFluid>
      <Container>
        <Crumbs>
          {crumbs.map((link, i) => (
            <React.Fragment key={i}>
              {link.route && (
                <Crumb data-qa-hook="breadcrumb" className="crumb" href={link.route}>
                  <span data-qa-hook="breadcrumbName">{link.name}</span>
                </Crumb>
              )}
              {i + 1 < crumbs.length && <Chevron />}
              {hasCurrent && !link.route && <CurrentPage>{link.name}</CurrentPage>}
            </React.Fragment>
          ))}
        </Crumbs>
      </Container>
    </CrumbContainerFluid>
  );
}

BreadcrumbsSubMenu.propTypes = {
  crumbs: PropTypes.array.isRequired,
};
