import React from 'react';
import styled from 'styled-components';
import { fromUnixTime, format } from 'date-fns';
import { Grid, Color, Type } from '../../StyleGuide';
import useManageTrainingRoute from '../../hooks/useManageTrainingRoute';
import { InlineList } from '../../components/Layout';
import { ListItemFlexRow } from '../../components/ListItemFlexRow';
import { TrainingEntity, TrainingEntityType, LastModifiedDate } from '../models/training';
import { Content } from '../models/content';

type ContainerProps = {
  published: boolean;
};

export const ListItemContainer = styled(ListItemFlexRow)<ContainerProps>`
  gap: ${Grid._6};
  padding: ${Grid._4} ${Grid._3};
  align-items: center;

  ${props => !props.published && `background-color: ${Color.Gray._075};`}
`;

const ActionItemRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Grid._5};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Grid._2};
  flex: 1;

  h4,
  p {
    margin: 0;
  }
`;

const Icon = styled.i`
  font-size: ${Type.Scale._5};
  color: ${Type.Color.medium};
`;

const TrainingTypeIconMap = {
  course: 'fas fa-tasks',
  pathway: 'fas fa-route',
  content: 'icon ion-ios-compose',
  curriculum: 'far fa-calendar-alt',
};
type Props<T> = {
  item: T;
  type: TrainingEntityType;
  actionButtons: React.JSX.Element;
  onClick?: (item: T, type: TrainingEntityType) => void;
};

const formatLastModifiedDate = (lastModifiedDate: LastModifiedDate) =>
  format(
    typeof lastModifiedDate === 'number'
      ? fromUnixTime(lastModifiedDate / 1000)
      : typeof lastModifiedDate === 'string'
      ? new Date(lastModifiedDate)
      : lastModifiedDate,
    'MMM dd, yyyy'
  );

export function ListItem<T extends TrainingEntity>({ item, type, onClick, actionButtons }: Props<T>) {
  const { manageTrainingType, manageTrainingRoutePrefix } = useManageTrainingRoute();
  const lastModifiedDate = formatLastModifiedDate(item.lastModifiedDate);

  return (
    <ListItemContainer onClick={onClick ? () => onClick(item, type) : undefined} published={item.published}>
      <Icon className={`${TrainingTypeIconMap[type]} hidden-xs`}></Icon>

      <Content>
        <h4>{item.name}</h4>

        <InlineList>
          <li>Edited {lastModifiedDate}</li>
          {type === 'course' && <li>{item.category || 'No Category'}</li>}
          {type === 'content' && item.questions !== undefined && <li>{item.questions} Questions</li>}
        </InlineList>
      </Content>
      <ActionItemRow>{actionButtons}</ActionItemRow>
    </ListItemContainer>
  );
}
