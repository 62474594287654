import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { Container, PageTitle, RaisedContainer } from '../components/Layout';
import { TextFilterInput } from '../components/FormElements';
import { MediumDangerButton, PrimaryButton } from '../components/Buttons';
import { Grid, Type, Breakpoints } from '../StyleGuide';
import userService from '../services/userService';
import ProfileImage from '../components/ProfileImage';
import EmptyState from '../components/EmptyState';
import ErrorMessage from '../components/ErrorMessage';
import ConfirmationModal from '../components/ConfirmationModal';
import useModal from '../hooks/useModal';
import { useUser } from '../authentication';
import alertService from '../services/AlertService';
import { handleError } from '../utils/apiUtils';

const PageContainer = styled(Container)`
  margin: ${Grid._6} auto;
`;

const SearchContainer = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  gap ${Grid._4};

  > div {
    flex: 1 1 520px;
  }

  > button {
    flex: 1;
    min-width: 220px;
  }
`;

const PersonName = styled.div`
  color: ${Type.Color.mediumDark};
  font-weight: ${Type.Weight.semibold};
`;

const PersonItem = styled(RaisedContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: ${Grid._6};
  padding: ${Grid._7} ${Grid._5};
  gap: ${Grid._3};
  color: ${Type.Color.medium};

  > * {
    flex: 1 1 auto;

    &:first-child {
      flex: 0;
    }
  }

  ${PersonName} {
    margin: 0 0 ${Grid._4} 0;
  }

  button {
    flex: 1 1 0;
    margin-top: ${Grid._5};
    width: 100%;
    max-width: 360px;
  }

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    flex-direction: row;

    ${PersonName} {
      margin: 0 0 0 ${Grid._4};
    }

    button {
      margin-top: 0;
    }
  }
`;

export default function RightToBeForgotten() {
  const adminUser = useUser();
  const [user, setUser] = useState();
  const [status, setStatus] = useState({ noResultsFound: false, noRecordsRemoved: false });
  const { noResultsFound, noRecordsRemoved } = status;

  const [modal, openModal, dismissModal] = useModal((type, payload, dismissModal) => {
    if (!type) return;

    return (
      <ConfirmationModal
        buttonActionText="Forget User"
        buttonType="danger"
        title="Forget User"
        prompt={
          <>
            <span>
              Are you sure you want to remove all records of {payload.firstName} {payload.lastName} from Ministry Grid?
            </span>
            <p className="help-block">
              <i className="icon ion-android-alert"></i> This action cannot be undone
            </p>
          </>
        }
        handleSubmit={() => forgetUser(payload._id.id, payload.email)}
        handleDismiss={dismissModal}
      />
    );
  });

  const search = (values, { setFieldValue }) => {
    setStatus({});
    setUser();

    return userService
      .getUserIdByEmail(values.searchTerm)
      .then(data => data.id && userService.getUserById(data.id))
      .then(userData => {
        setFieldValue('searchTerm', '');
        setUser(userData);
      })
      .catch(error => {
        console.error(error);
        setStatus({ noResultsFound: true });
        setUser();
      });
  };

  const forgetUser = (userId, email) =>
    userService
      .forgetUser(userId, email, adminUser.userId)
      .then(response => {
        if (response?.recordsDeleted > 0) {
          setUser();
          alertService.show('User Forgotten');
        } else {
          setStatus({ noRecordsRemoved: true });
        }
      })
      .catch(handleError)
      .finally(dismissModal);

  return (
    <PageContainer>
      <PageTitle className="mb-40">
        <h1>Right to be Forgotten</h1>
        <p>Search for the user who has requested to be forgotten from Ministry Grid</p>
      </PageTitle>
      <Formik initialValues={{ searchTerm: '' }} onSubmit={search}>
        {({ values, setFieldValue, isSubmitting }) => (
          <SearchContainer>
            <TextFilterInput
              placeholder="Search Email Address"
              onChange={e => setFieldValue('searchTerm', e.target.value)}
              value={values.searchTerm}
              clearInput={() => setFieldValue('searchTerm', '')}
              disabled={isSubmitting}
            />
            <PrimaryButton type="submit" disabled={isSubmitting} operating={isSubmitting}>
              Search
            </PrimaryButton>
          </SearchContainer>
        )}
      </Formik>

      {user ? (
        <PersonItem>
          <div>
            <ProfileImage src={user.profile?.imageUrl} size={Grid._8} />
          </div>
          <PersonName>
            {user.firstName} {user.lastName}
          </PersonName>
          <div>{user.email}</div>
          <div>{user._id.id}</div>
          <MediumDangerButton onClick={() => openModal('forget', user)}>Forget User</MediumDangerButton>
        </PersonItem>
      ) : noResultsFound ? (
        <EmptyState
          icon="fa-search"
          title="Your search does not have any matches"
          description="Please try another search."
        />
      ) : null}

      {noRecordsRemoved && (
        <ErrorMessage>
          No records removed for this user. This could be because of an error in processing the request or because the
          user had no data in our system.
        </ErrorMessage>
      )}

      {modal}
    </PageContainer>
  );
}
