import React, { useState, useEffect } from 'react';
import { react2angular } from 'react2angular';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';
import trainingInstanceService from '../services/trainingInstanceService.js';
import uuid from '../services/uuid';
import assessmentService from '../services/assessmentService';
import AssessmentViewer from './assessments/AssessmentViewer';
import Section from './Section';
import LoadingSection from './LoadingSection';

const Container = styled.div`
  display: flex;
  justify-content: center;
  h1 {
    font-size: ${Type.Scale._5};
    margin-bottom: ${Grid._7};
  }
`;

export default function FeedbackAssessmentViewer({ trainingInput, stepInstanceId, email }) {
  const [training] = useState(trainingInput);
  const initialStepIndex = () => {
    const stepIndex = trainingInput.tasks.findIndex(task => task.id.id === stepInstanceId);
    return stepIndex === -1 ? 0 : stepIndex;
  };
  const [currentStep, setCurrentStep] = useState(training.tasks[initialStepIndex()]);
  const feedbackRequest = currentStep.task.feedbackResponses.find(f => f.email === email);
  const [responseId] = useState(
    feedbackRequest.responseId === undefined || feedbackRequest.responseId === ''
      ? uuid.generate()
      : feedbackRequest.responseId
  );
  const [assessment, setAssessment] = useState(null);
  const [showTaker, setShowTaker] = useState(false);
  const [allAnswered, setAllAnswered] = useState(false);
  const [progress, setProgress] = useState(0);
  const [responseComplete, setResponseComplete] = useState(feedbackRequest.status.indexOf('complete') > -1);

  useEffect(() => {
    if (showTaker) {
      getAssessmentResponse(responseId);
    }
  }, [showTaker]);

  useEffect(() => {
    setShowTaker(false);
    if (feedbackRequest.status === 'not_started') {
      assessmentService
        .getAssessment(currentStep.task.relatedTask.task.assessmentId, training.assignedUser)
        .then(response => {
          setAssessment(response);
          console.debug('Assessment Response: ', response);
        })
        .catch(e => {
          console.error(e);
        });
    } else {
      getAssessmentResponse();
    }
  }, [currentStep.id.id]);

  const getAssessmentResponse = () => {
    return assessmentService
      .getAssessmentResponseWithQuestions(responseId, training.assignedUser)
      .then(response => {
        setAssessment(response);
        setProgress(response.progress);
        console.debug('Assessment Response With Questions: ', response);
      })
      .catch(e => {
        console.error(e);
      });
  };

  const startAssessment = () => {
    return trainingInstanceService
      .startFeedbackStep(training.id, currentStep, training.assignedUser, email, responseId, email)
      .then(
        function () {
          getAssessmentResponse(responseId).then(() => setShowTaker(true));
        },
        function (reason) {
          console.error(reason);
        }
      );
  };

  const completeStep = () => {
    if (feedbackRequest.status !== 'complete') {
      trainingInstanceService.completeFeedbackStep(training.id, currentStep, email, responseId, email).then(
        function () {
          setResponseComplete(true);
        },
        function (reason) {
          console.error(reason);
        }
      );
    }
  };

  const handleAllQuestionsAnswered = () => {
    feedbackRequest.status = 'in_progress';
    setAllAnswered(true);
    getAssessmentResponse(responseId);
  };

  return (
    <Container>
      {assessment === null ? (
        <LoadingSection />
      ) : responseComplete === false ? (
        <AssessmentViewer
          assessment={assessment}
          progress={progress}
          handleAllQuestionsAnswered={handleAllQuestionsAnswered}
          allAnswered={allAnswered}
          setShowTaker={setShowTaker}
          startAssessment={startAssessment}
          completeAssessment={completeStep}
          showTaker={showTaker}
          step={feedbackRequest}
          takerId={email}
          isPublicAssessment={true}
        />
      ) : (
        <Section>
          <p>You have finished the {assessment.name} assessment.</p>
          <p>You may close this window. Thank you for your time.</p>
        </Section>
      )}
    </Container>
  );
}

FeedbackAssessmentViewer.propTypes = {
  trainingInput: PropTypes.object.isRequired,
  stepInstanceId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

angular.module('lwNamb').component('feedbackAssessmentViewer', react2angular(FeedbackAssessmentViewer));
