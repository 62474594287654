import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '../StyleGuide';
import { Container, ImageDimensionLabel, SixteenSevenAspectRatioPlaceholder } from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import { FormField, FormFieldMaxCharCountStatus, FormFieldContainer, Label, Header } from '../components/FormElements';
import { TextOverlay, Text, MobileText } from '../components/CarouselElements';
import { TrainingItem } from './CompilationElements';
import { Buttons, PrimaryButton, Button, SmallRoundedIconButton } from '../components/Buttons';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ConfirmationModal from '../components/ConfirmationModal';
import ImageCrop, { ImageCropUtils } from '../components/ImageCrop';
import alertService from '../services/AlertService';
import carouselService from '../services/carouselService';
import catalogService from '../services/catalogService';
import compilationService from '../services/compilationService';
import trainingService from '../services/trainingService';
import windowService from '../services/windowService';
import { useUser } from '../authentication';
import { localStorage, sessionStorage } from '../utils/storageUtils';

const storeFormState = state => sessionStorage.setItem('createCarouselItem', state);
const getStoredFormState = () => sessionStorage.getItem('createCarouselItem');
const clearStoredFormState = () => sessionStorage.removeItem('createCarouselItem');

const getStoredAddedTraining = () => localStorage.getItem('carouselTrainingItem') || {};
const clearStoredAddedTraining = () => localStorage.removeItem('carouselTrainingItem');

const formatTrainingItemForApi = t => {
  const item = {};
  const key =
    t.trainingType === 'Workflow' || t.trainingType === 'Pathway'
      ? 'pathwayId'
      : t.trainingType === 'Compilation'
      ? 'compilationId'
      : 'courseId';
  item[key] = t.id;
  return item;
};

const formatTrainingItem = t => ({
  ...t,
  id: t.trainingId || t._id,
  name: t.trainingName || t.name,
  trainingType: t.trainingType === 'Workflow' ? 'Pathway' : t.trainingType === 'Tasklist' ? 'Course' : t.trainingType,
});

export default function ManageCarouselItem() {
  const user = useUser();
  const [data, setData] = useState({
    isLoading: true,
    isError: false,
    isSubmitting: false,
    carouselItem: { image: '' },
  });
  const [removeModal, setRemoveModal] = useState({ show: false, name: '', id: '' });
  const [form, setForm] = useState();
  const [crumbs, setCrumbs] = useState([
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Catalog', route: '#/manage-catalog' },
    { name: '' },
  ]);
  const [isTrainingRemoved, setIsTrainingRemoved] = useState(false);

  const atIndex = windowService.getRoutePart(3);
  const carouselItemId = windowService.getRoutePart(4);

  const updateFormData = newValues => setForm(form => ({ ...form, ...newValues }));

  const uploadCarouselImage = image =>
    new Promise((resolve, reject) => {
      const carouselFilename = ImageCropUtils.generateFilename();
      if (ImageCropUtils.isCroppedImage(image)) {
        ImageCropUtils.convertCroppedImageToBlob(image)
          .then(blob => carouselService.uploadCarouselItemImage(blob, carouselFilename))
          .then(() => resolve(carouselFilename))
          .catch(reason => reject(reason));
      } else {
        resolve(image);
      }
    });

  const redirectBack = () => windowService.redirectTo(`#/manage-catalog/feature-carousel/${atIndex}`);

  const getCarouselItemDetails = carouselItem => {
    return new Promise((resolve, reject) => {
      const item = Object.entries(carouselItem.link)[0];
      const [trainingType, trainingId] = item;
      const getTrainingItem =
        trainingType === 'compilationId'
          ? compilationService.getCompilation(trainingId)
          : trainingService.getTraining(trainingId);
      getTrainingItem
        .then(response => {
          if (response.status && response.status === 404) {
            setIsTrainingRemoved(true);
            resolve({
              id: carouselItem.id,
              name: carouselItem.text.title,
              label: carouselItem.text.label,
              textPosition: carouselItem.text.position,
              textColor: carouselItem.text.color,
              trainingItem: {},
              image: carouselItem.image,
            });
          } else {
            resolve({
              id: carouselItem.id,
              name: carouselItem.text.title,
              label: carouselItem.text.label,
              textPosition: carouselItem.text.position,
              textColor: carouselItem.text.color,
              trainingItem: formatTrainingItem(response),
              image: carouselItem.image,
            });
          }
        })
        .catch(reason => reject(reason));
    });
  };

  const submitForm = () => {
    setData({ ...data, isSubmitting: true });

    uploadCarouselImage(form.image)
      .then(carouselFilename => {
        const text = {
          title: form.name.trim(),
          label: form.label.trim(),
          position: form.textPosition,
          color: form.textColor,
        };
        carouselItemId
          ? catalogService.updateCarouselItem(
              data.catalog.id,
              data.catalog.featureCarouselSection.sectionId,
              carouselItemId,
              carouselFilename,
              formatTrainingItemForApi(form.trainingItem),
              text,
              parseInt(atIndex),
              user.userId
            )
          : catalogService.createCarouselItem(
              data.catalog.id,
              data.catalog.featureCarouselSection.sectionId,
              carouselFilename,
              formatTrainingItemForApi(form.trainingItem),
              text,
              parseInt(atIndex),
              user.userId
            );
      })
      .then(() => {
        //Give the api a second to update
        setTimeout(() => {
          alertService.showOnNextPage('Training Catalog Updated');
          redirectBack();
        }, 1000);
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
        setData({ ...data, isSubmitting: false });
      });
  };

  const redirectToAddCompilation = () => {
    storeFormState(form);
    windowService.redirectTo('#/manage-catalog/add-compilation-to-carousel-item');
  };

  const redirectToAddTraining = () => {
    storeFormState(form);
    windowService.redirectTo(`${window.location.hash}/add-training`);
  };

  const getAddedTraining = () => {
    const addedTrainingItem = getStoredAddedTraining();
    if (addedTrainingItem.length) {
      clearStoredAddedTraining();
      return {
        ...addedTrainingItem[0],
        trainingType:
          addedTrainingItem[0].trainingType === 'Workflow'
            ? 'Pathway'
            : addedTrainingItem[0].trainingType === 'Compilation'
            ? 'Compilation'
            : 'Course',
      };
    } else {
      return {};
    }
  };

  useEffect(() => {
    let formState = {
      id: '',
      name: '',
      label: '',
      textPosition: 'topRight',
      textColor: 'light',
      trainingItem: {},
      image: '',
    };

    const storedFormState = getStoredFormState();
    if (storedFormState) {
      const shouldRestoreNewFormState = !carouselItemId && storedFormState.id === '';
      const shouldRestoreEditFormState = carouselItemId && storedFormState.id === carouselItemId;

      if (shouldRestoreNewFormState || shouldRestoreEditFormState) formState = storedFormState;
      clearStoredFormState();

      formState.trainingItem = getAddedTraining();
    }

    const updateLastCrumbName = (crumbList, newName) => {
      crumbList[crumbList.length - 1] = { name: newName };
      return crumbList;
    };

    catalogService
      .getCatalogHomePage()
      .then(response => {
        if (carouselItemId) {
          const carouselItem = response.featureCarouselSection.items.find(item => item.id === carouselItemId);
          getCarouselItemDetails(carouselItem).then(carouselItem => {
            setCrumbs(crumbs => updateLastCrumbName(crumbs, carouselItem.name));
            setForm(formState.id === carouselItem.id ? formState : carouselItem);
            setData(prevData => ({ ...prevData, catalog: response, isLoading: false }));
          });
        } else {
          setCrumbs(crumbs => updateLastCrumbName(crumbs, 'New Carousel Item'));
          setForm(formState);
          setData(prevData => ({ ...prevData, catalog: response, isLoading: false }));
        }
      })
      .catch(err => {
        console.error(err);
        setData({ isError: true, isLoading: false });
      });
  }, []);

  const getInitialCarouselImage = formImage => {
    if (ImageCropUtils.isCroppedImage(formImage)) {
      return formImage;
    } else if (formImage) {
      return carouselService.getCarouselItemImageUrl(formImage);
    } else {
      return '';
    }
  };

  const onCarouselImageUpdated = image => {
    if (image === '' || ImageCropUtils.isCroppedImage(image)) {
      updateFormData({ image: image });
    }
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      {data.isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <Container style={{ margin: `${Grid._6} auto` }}>
          <Formik
            enableReinitialize
            initialValues={form}
            validationSchema={Yup.object({
              image: Yup.string().required(),
              name: Yup.string(),
              label: Yup.string(),
              trainingItem: Yup.object().shape({
                id: Yup.string().required(),
                name: Yup.string(),
                trainingType: Yup.string(),
              }),
            })}
            onSubmit={submitForm}
          >
            {({ values, errors, touched }) => (
              <Form data-qa-hook="manageCarouselItemView">
                <Header>{carouselItemId ? 'Edit' : 'New'} Carousel Item</Header>
                <div
                  className="grid-container grid-col-4 grid-sm-col-8 grid-gap-40 grid-row-gap-24"
                  style={{ margin: '32px 0' }}
                >
                  <div className="grid-col-span-4 grid-sm-col-span-8 grid-md-col-span-5">
                    <Label>Preview</Label>
                    <SixteenSevenAspectRatioPlaceholder actionable={!form.image}>
                      <ImageCrop
                        key={form.image}
                        defaultImage={getInitialCarouselImage(form.image)}
                        aspectRatio={16 / 7}
                        onUpdate={onCarouselImageUpdated}
                        showRemoveButton={false}
                      />
                      {form.image && (
                        <div className="hidden-xs">
                          <TextOverlay>
                            <Text textPosition={form.textPosition} textColor={form.textColor}>
                              <h2>{values.name}</h2>
                              <h4>{values.label}</h4>
                            </Text>
                          </TextOverlay>
                        </div>
                      )}
                    </SixteenSevenAspectRatioPlaceholder>
                    <ImageDimensionLabel>Recommended: 741 x 325</ImageDimensionLabel>
                    <div className="visible-xs-block">
                      <MobileText>
                        <h2>{values.name}</h2>
                        <h4>{values.label}</h4>
                      </MobileText>
                    </div>
                    {touched.image && errors.image && <ErrorMessage>An image is required</ErrorMessage>}
                  </div>
                  <div className="grid-col-span-4 grid-sm-col-span-8 grid-md-col-span-3" style={{ alignSelf: 'end' }}>
                    {form.image && (
                      <Button data-qa-hook="imageRemove" onClick={() => setForm(prev => ({ ...prev, image: false }))}>
                        Remove Image
                      </Button>
                    )}
                  </div>
                </div>
                <div style={{ margin: '32px 0' }}>
                  <div className="grid-container grid-col-4 grid-sm-col-8 grid-col-gap-40">
                    <div className="grid-col-span-4 grid-sm-col-span-5">
                      <FormFieldMaxCharCountStatus
                        label="Name"
                        name="name"
                        type="text"
                        placeholder="Enter Name"
                        maxLength={48}
                        onBlur={e => updateFormData({ name: e.target.value })}
                      />
                      <FormFieldMaxCharCountStatus
                        label="Label"
                        name="label"
                        type="text"
                        placeholder="Enter Label"
                        maxLength={54}
                        onBlur={e => updateFormData({ label: e.target.value })}
                      />
                    </div>
                    <div className="grid-col-span-4 grid-sm-col-span-3">
                      <FormField
                        label="Text Position"
                        name="textPosition"
                        as="select"
                        onChange={e => updateFormData({ textPosition: e.target.value })}
                      >
                        <option value={'topRight'}>Top Right</option>
                        <option value={'topLeft'}>Top Left</option>
                        <option value={'bottomRight'}>Bottom Right</option>
                        <option value={'bottomLeft'}>Bottom Left</option>
                      </FormField>
                      <FormField
                        label="Text Color"
                        name="textColor"
                        as="select"
                        onChange={e => updateFormData({ textColor: e.target.value })}
                      >
                        <option value={'light'}>Light</option>
                        <option value={'dark'}>Dark</option>
                      </FormField>
                    </div>
                  </div>
                  <div className="grid-container grid-col-4 grid-sm-col-8 grid-col-gap-40 grid-row-gap-40">
                    <div className="grid-col-span-4 grid-sm-col-span-5">
                      <FormFieldContainer>
                        <Label>Training</Label>
                        {Object.keys(form.trainingItem).length > 0 ? (
                          <TrainingItem>
                            <div>
                              <h4 data-qa-hook="trainingName">{form.trainingItem.name}</h4>
                              <p data-qa-hook="trainingType">{form.trainingItem.trainingType}</p>
                            </div>
                            <SmallRoundedIconButton
                              data-qa-hook="trainingRemove"
                              title="Remove Training"
                              onClick={() =>
                                setRemoveModal({ show: true, name: form.trainingItem.name, id: form.trainingItem.id })
                              }
                            >
                              <i className="far fa-trash-alt"></i>
                            </SmallRoundedIconButton>
                          </TrainingItem>
                        ) : (
                          <Buttons style={{ margin: '0' }}>
                            <Button data-qa-hook="selectCompilation" onClick={redirectToAddCompilation}>
                              Select Compilation
                            </Button>
                            <Button data-qa-hook="selectCourseOrPathway" onClick={redirectToAddTraining}>
                              Select Course or Pathway
                            </Button>
                          </Buttons>
                        )}
                        {touched.trainingItem && errors.trainingItem && errors.trainingItem.id && (
                          <ErrorMessage>A training item is required</ErrorMessage>
                        )}
                        {isTrainingRemoved && (
                          <ErrorMessage>
                            The training attached to this item has been removed. Please choose a new training item.
                          </ErrorMessage>
                        )}
                      </FormFieldContainer>
                    </div>
                    <div className="grid-col-span-4 grid-sm-col-span-3">
                      <Buttons
                        style={{ justifyContent: 'flex-end', alignItems: 'center', height: '100%', margin: '0' }}
                      >
                        <Button data-qa-hook="cancel" onClick={redirectBack}>
                          Cancel
                        </Button>
                        <PrimaryButton
                          data-qa-hook="submit"
                          type="submit"
                          disabled={data.isSubmitting}
                          operating={data.isSubmitting}
                        >
                          {carouselItemId ? 'Save Carousel Item' : 'Add Carousel Item'}
                        </PrimaryButton>
                      </Buttons>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      )}
      {removeModal.show && (
        <ConfirmationModal
          buttonActionText="Remove"
          buttonType="danger"
          title="Remove Training"
          prompt={<span>Are you sure you want to remove this training?</span>}
          subtext={removeModal.name}
          handleSubmit={() => {
            updateFormData({ trainingItem: '' });
            setRemoveModal({ show: false, name: '', id: '' });
          }}
          handleDismiss={() => setRemoveModal({ show: false, name: '', id: '' })}
        />
      )}
    </>
  );
}
