angular.module('lwNamb').controller(
  'WorkflowBuilderEmailPreviewCtrl',

  [
    '$scope',
    '$location',
    '$log',
    '$routeParams',
    '$localStorage',
    '$q',
    'alertService',
    'userService',
    'workflowService',
    '$sce',
    'analyticsService',
    'buildService',
    'emailService',
    function(
      $scope,
      $location,
      $log,
      $routeParams,
      $localStorage,
      $q,
      alertService,
      userService,
      workflowService,
      $sce,
      analyticsService,
      buildService,
      emailService
    ) {
      var queryParams = $location.search(),
        previousId = queryParams.previousId,
        nextId = queryParams.nextId,
        workflowId = $routeParams.workflowId,
        ownerId;

      var init = function() {
        $scope.creating = previousId && nextId;
        $scope.email = $localStorage.workflowEmail;
        analyticsService.trackFeature('WFBuilderEmailPreview' + $scope.creating ? 'Create' : 'Edit', 'loaded');

        $q.all([userService.user(), buildService.ownerId()]).then(function(result) {
          $scope.user = result[0];
          ownerId = result[1];
          getEmailPreview();
        });
      };

      function getEmailPreview() {
        var buttonText = $localStorage.workflowEmail.showButton ? $localStorage.workflowEmail.buttonText : '';
        emailService.getEmailPreview(ownerId, 'blank', $localStorage.workflowEmail.body, buttonText).then(
          function(result) {
            $scope.previewEmails = result;
            $scope.preview = $sce.trustAsHtml($scope.previewEmails.emails[0].content.html);
          },
          function(reason) {
            $log.error(reason);
          }
        );
      }

      $scope.saveEmail = function() {
        $scope.spinner = true;
        if ($scope.creating) {
          analyticsService.trackFeature('WFBuilderEmailPreviewCreate', 'added');
          workflowService
            .addEmailToWorkflow(
              workflowId,
              $localStorage.workflowEmail.subject,
              $localStorage.workflowEmail.body,
              $localStorage.workflowEmail.showButton ? $localStorage.workflowEmail.buttonText : '',
              previousId,
              nextId,
              $scope.user.userId
            )
            .then(
              function() {
                $scope.spinner = false;

                if (window.cacheService) {
                  window.cacheService.removeAllInPath(`/v1/workflows/${workflowId}`);
                }

                alertService.show({
                  title: 'Email Added!',
                  type: 'success success-with-content',
                  duration: 5,
                });
                buildService.ctxRedirectBackTo('/pathways/' + workflowId);
              },
              function(reason) {
                $scope.spinner = false;
                $log.error(reason);
                alertService.show({
                  type: 'danger danger-with-content',
                  title: 'Unexpected Error!',
                  content: 'Something went wrong. Please try again.',
                  duration: 20,
                });
              }
            );
        } else {
          analyticsService.trackFeature('WFBuilderEmailPreviewEdit', 'edited');
          $localStorage.workflowEmail.buttonText = $localStorage.workflowEmail.showButton
            ? $localStorage.workflowEmail.buttonText
            : '';
          workflowService.updateWorkflowEmail(workflowId, $localStorage.workflowEmail).then(
            function() {
              $scope.spinner = false;
              alertService.show({
                title: 'Email Saved!',
                type: 'success success-with-content',
                duration: 5,
              });
              buildService.ctxRedirectBackTo('/pathways/' + workflowId);
            },
            function(reason) {
              $log.error(reason);
              $scope.spinner = false;
              alertService.show({
                type: 'danger danger-with-content',
                title: 'Unexpected Error!',
                content: 'Something went wrong. Please try again.',
                duration: 20,
              });
            }
          );
        }
      };

      init();
    },
  ]
);
