import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Breakpoints, Type, Grid, Color, Border } from '../StyleGuide';
import ProfileImage from '../groups/ProfileImage';
import limitFilter from '../filters/limit-filter';
import InlineAddNewPerson from './InlineAddNewPerson';

const PeopleSelectListTable = styled.table`
  border: 0;
  margin: 0;
  width: 100%;
  transition: opacity 0.25s ease-out;
  opacity: 1;

  tr {
    display: grid;
    grid-template-columns: 1fr 56px;
    border-radius: 0;

    @media screen and (min-width: ${Breakpoints.screen_md}) {
      grid-template-columns: 48px 2fr 2fr 56px;
      grid-column-gap: ${Grid._4};
    }
  }

  thead {
    display: none;

    &.hidden {
      display: none;
    }

    @media screen and (min-width: ${Breakpoints.screen_md}) {
      display: block;
    }

    tr {
      color: ${Type.Color.dark};

      th {
        height: 56px;
        display: flex;
        align-items: center;
      }
    }
  }

  tbody {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr;

    @media screen and (min-width: ${Breakpoints.screen_md}) {
      border-top: ${Grid._2} solid ${Type.Color.medium};
    }

    tr {
      order: 2;
      padding: ${Grid._4};
      align-items: center;
      border: 1px solid ${Color.Blue._15};
      color: ${Type.Color.medium};
      cursor: pointer;
      transition: background 0.15s ease-in-out;

      &:not(:last-of-type) {
        border-bottom: 1px solid ${Color.Blue._15};
      }

      &:hover {
        background: ${Color.Gray._05};
      }

      @media screen and (min-width: ${Breakpoints.screen_md}) {
        border: 0;
        border-bottom: 1px solid ${Color.Blue._15};
        padding: 0;
      }

      td {
        width: 100%;
        text-align: center;

        @media screen and (min-width: ${Breakpoints.screen_md}) {
          width: auto;
        }

        @media screen and (min-width: ${Breakpoints.screen_md}) {
          padding: ${Grid._4} 0;
        }
      }
    }
  }

  .person-row {
    &._isLeader {
      background: ${Color.Blue._10};

      order: 1;

      .person-row__role span {
        padding: ${Grid._2} ${Grid._3};
        border-radius: ${Border.radius};
        background: ${Color.Gray._80};
        color: ${Color.white};
      }
      &:hover {
        background: ${Color.Blue._125};
      }
    }

    &._isUser {
      order: 0 !important;

      .person-row__name span {
        color: ${Type.Color.medium};
        margin-left: ${Grid._3};
        background: rgba(0, 0, 0, 0.025);
        padding: ${Grid._2} ${Grid._3};
        border-radius: ${Grid._7};
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-size: ${Type.Scale._05};
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    &._isSelected {
      .person-row__radio span {
        box-shadow: none;
        background: ${Color.Primary._50};
        color: ${Color.white};
        font-size: ${Type.Scale._2};
      }
    }

    &._isPending {
      background: ${Color.Gray._075};

      order: 3;

      &:hover {
        background: ${Color.Gray._10};
      }

      .person-row__name span {
        color: ${Type.Color.medium};
        font-weight: ${Type.Weight.normal};
        opacity: 0.75;
        display: block;
      }

      .person-row__role {
        color: ${Type.Color.medium};
      }
    }

    &.isUpdating {
      opacity: 0.8;
    }
  }

  .person-row__profile-img {
    display: none;
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      display: block;
    }

    padding-bottom: ${Grid._4};
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      padding-left: ${Grid._3};
    }

    .fa-user-circle {
      color: ${Color.Blue._15};
    }

    span,
    img {
      font-size: ${Type.Scale._8};
      position: relative;
      width: ${Grid._8};
      height: ${Grid._8};
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        font-size: ${Type.Scale._6};
        width: ${Grid._6};
        height: ${Grid._6};
      }
    }
  }

  .person-row__name {
    text-align: left;

    color: ${Type.Color.dark};
    font-weight: ${Type.Weight.semibold};
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      text-align: left;
    }
  }

  .person-row__email {
    text-align: left;
    grid-row-start: 2;

    @media screen and (min-width: ${Breakpoints.screen_md}) {
      grid-row-start: auto;
    }
  }

  .person-row__role {
    padding: ${Grid._4} 0;
  }

  .person-row__radio {
    grid-row-end: span 2;
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      grid-row-end: span 1;
    }

    span {
      margin: 0 auto;
      width: ${Grid._5};
      height: ${Grid._5};
      border-radius: ${Grid._5};
      box-shadow: inset 0 0 0 2px ${Color.Gray._80};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${Type.Scale._05};
      color: transparent;
    }
  }
`;

const SelectedPeopleListTable = styled(PeopleSelectListTable)`
  thead {
    display: block;

    tr {
      grid-template-columns: auto;
      border: 0;
    }

    th {
      padding: 0;
      height: ${Grid._9};
      justify-content: flex-start;
    }
  }

  tbody {
    grid-template-columns: 1fr;
    grid-gap: ${Grid._3};
    border: 0;

    tr {
      grid-template-columns: 1fr auto;
      grid-column-gap: ${Grid._2} !important;
      padding: ${Grid._3} ${Grid._4};
      border: 0 !important;
      border-radius: ${Border.radius};
      background: ${Color.Gray._70};
      color: ${Color.white};

      @media screen and (min-width: ${Breakpoints.screen_sm}) {
        display: grid;
      }

      &:hover {
        background: ${Color.Gray._70};
      }

      td {
        padding: 0;
        text-align: left;
      }
    }

    .person-row td {
      max-width: 185px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .person-row__name {
      color: inherit;
    }

    .person-row__email {
      grid-row-start: none;
    }

    .person-row__remove {
      grid-row-end: span 2;

      .icon {
        font-size: ${Type.Scale._5};
      }
    }
  }
`;

export default function PeopleSelectList({
  user,
  people,
  filteredPeople,
  selectedPeople,
  onSelect,
  onDeselect,
  addAndSelect,
  showInlineAddNewPerson,
}) {
  let allPeople = showInlineAddNewPerson
    ? [...filteredPeople.slice(0, 3), {}, ...filteredPeople.slice(3)]
    : filteredPeople;

  const Person = ({ person }) => {
    const isUser = person.email === user.logInEmail;
    const isPending = person.emailAddress;
    const isSelected = selectedPeople.includes(person);
    const rowClassName =
      'person-row ' +
      (isUser ? '_isUser ' : ' ') +
      (isSelected ? '_isSelected ' : ' ') +
      (isPending ? '_isPending' : ' ');

    return (
      <tr
        data-qa-hook="addPeopleResult"
        className={rowClassName}
        onClick={() => (isSelected ? onDeselect(person, false) : onSelect(person, true))}
      >
        <td className="person-row__profile-img">
          <ProfileImage src={person.emailAddress ? '' : person.profile.imageUrl} />
        </td>
        <td data-qa-hook="addPeopleResultName" className="person-row__name">
          {person?.displayName}
          {person.emailAddress && <span>Not Logged in Yet</span>}
          {isUser && <span>you</span>}
        </td>
        <td data-qa-hook="addPeopleResultEmail" className="person-row__email">
          {limitFilter(person.email || person.emailAddress)}
        </td>
        <td className="person-row__radio">
          <span className="fas fa-check"></span>
        </td>
      </tr>
    );
  };

  return (
    <PeopleSelectListTable>
      <tbody data-qa-hook="addPeopleResultList">
        {allPeople.map((item, i) => {
          return Object.keys(item).length === 0 ? (
            <InlineAddNewPerson
              key={'InlineAddNewPerson'} // we don't want this component to re-render
              allPeople={people}
              selectedPeople={selectedPeople}
              addAndSelect={addAndSelect}
            />
          ) : (
            <Person key={i} person={item} />
          );
        })}
      </tbody>
    </PeopleSelectListTable>
  );
}

PeopleSelectList.defaultProps = {
  showInlineAddNewPerson: false,
};

PeopleSelectList.propTypes = {
  user: PropTypes.object,
  people: PropTypes.arrayOf(PropTypes.object),
  filteredPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
  addAndSelect: PropTypes.func,
  showInlineAddNewPerson: PropTypes.bool,
};

export function SelectedPeopleList({ people, onDeselect, headingText }) {
  return (
    <SelectedPeopleListTable>
      {headingText && (
        <thead>
          <tr>
            <th className="grid-col-span-2">{headingText}</th>
          </tr>
        </thead>
      )}
      <tbody data-qa-hook="selectedPeopleList">
        {people.map((person, index) => (
          <tr key={index} className="person-row _isSelected" onClick={() => onDeselect(person, false)}>
            <td className="person-row__name">{person.displayName}</td>
            <td data-qa-hook="addPeopleResultEmail" className="person-row__email">
              {person.emailAddress ? person.emailAddress : person.email}
            </td>
            <td className="person-row__remove">
              <span className="icon ion-ios-close-empty"></span>
            </td>
          </tr>
        ))}
      </tbody>
    </SelectedPeopleListTable>
  );
}

SelectedPeopleList.propTypes = {
  people: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDeselect: PropTypes.func.isRequired,
  headingText: PropTypes.string,
};
