import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { MediumButton, MediumPrimaryButton } from '../../../components/Buttons';
import { FormField } from '../../../components/FormElements';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../components/ModalElements';
import { useUser } from '../../../authentication';
import useManageTrainingRoute from '../../../hooks/useManageTrainingRoute';
import alertService from '../../../services/AlertService';
import workflowService from '../../../services/workflowService';
import uuid from '../../../services/uuid';
import windowService from '../../../services/windowService';
import { handleError } from '../../../utils/apiUtils';

type Params = {
  ownerId: string;
  closeModal: () => void;
};

type FormValues = {
  name: string;
};

export const AddPathwayModal = ({ ownerId, closeModal }: Params) => {
  const user = useUser();
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();

  const handleSubmit = (values: FormValues, { setSubmitting, setErrors }: FormikHelpers<FormValues>) => {
    if (!user) return;

    workflowService
      .addPathway(uuid.generate(), values.name, user.userId, ownerId)
      .then(response => {
        alertService.showOnNextPage('Pathway Added');
        windowService.redirectTo(`${manageTrainingRoutePrefix}/pathways/${response.id}`);
      })
      .catch(error => {
        setSubmitting(false);

        if (error.eventType === 'WorkflowNameDuplicateError') {
          return setErrors({ name: 'Sorry, that pathway name is taken' });
        }

        handleError(error);
        closeModal();
      });
  };

  return (
    <ModalContainer dismissHandler={closeModal}>
      <ModalHeader>
        <h3>Add Pathway</h3>
      </ModalHeader>
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().max(64, 'Name is too long').required('A name is required'),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form id="unit-form">
            <ModalBody>
              <FormField label="Name" name="name" type="text" placeholder="Enter Name" />
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton type="submit" disabled={isSubmitting} operating={isSubmitting}>
                Add Pathway
              </MediumPrimaryButton>
              <MediumButton onClick={closeModal}>Cancel</MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};
