import React from 'react';
import styled from 'styled-components';
import { Grid, Border, Color, Type } from '../StyleGuide';

const AlertMessage = styled.div`
  font-size: ${Type.Scale._2};
  font-weight: ${Type.Weight.semibold};
  color: ${Color.Red._70};
  margin: ${Grid._3} auto;
  display: flex;
  align-items: center;
  justify-content: ${props => props.align || 'center'};
  background: ${Color.Red._05};
  padding: ${Grid._3};
  border: 1px solid ${Color.Red._15};
  border-radius: ${Border.radius};
  width: 100%;
  i {
    margin-right: ${Grid._3};
  }
`;

const SuccessMessageUI = styled(AlertMessage)`
  background: ${Color.Primary._10};
  color: ${Color.Primary._70};
  border: 1px solid ${Color.Primary._20};
`;

export function SuccessMessage(props) {
  return (
    <SuccessMessageUI {...props}>
      <i className="fas fa-check-circle"></i> {props.children}
    </SuccessMessageUI>
  );
}

const ErrorMessageUI = styled(AlertMessage)`
  background: ${Color.Red._05};
  color: ${Color.Red._70};
  border: 1px solid ${Color.Red._15};
`;

export function ErrorMessage(props) {
  return (
    <ErrorMessageUI {...props}>
      <i className="fas fa-exclamation-triangle"></i> {props.children}
    </ErrorMessageUI>
  );
}

const LoadingMessageUI = styled(AlertMessage)`
  background: ${Color.Secondary._05};
  color: ${Type.Color.dark};
  border: 1px solid rgba(81, 144, 197, 0.15);
  i {
    color: ${Type.Color.medium};
    font-size: ${Type.Scale._4};
  }
`;

export function LoadingMessage(props) {
  return (
    <LoadingMessageUI {...props}>
      <i className="fas fa-spinner fa-spin"></i> {props.children}
    </LoadingMessageUI>
  );
}
