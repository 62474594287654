import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Grid, Breakpoints } from '../StyleGuide';
import { Container, FullWidthCard } from '../components/Layout';
import Tabs from './Tabs';
import PathwayCourse from './PathwayCourse';
import TrainingOverview from './TrainingOverview';
import TrainingContext from '../contexts/TrainingContext';

const PathwayFullWidthCard = styled(FullWidthCard)`
  padding: ${Grid._5} 0;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._7} 0;
  }
`;

const OverviewContainer = styled.div`
  padding: ${Grid._4} 0;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._5} ${Grid._14};
  }
`;

const PathwayContents = ({ localStorage }) => {
  const training = useContext(TrainingContext);
  const [activeTab, setActiveTab] = useState('contents');

  return (
    <>
      <Container>
        <Tabs fontSize="18px">
          <li className={activeTab === 'contents' ? 'active-tab' : ''}>
            <button onClick={() => setActiveTab('contents')}>Contents</button>
          </li>
          <li className={activeTab === 'overview' ? 'active-tab' : ''}>
            <button onClick={() => setActiveTab('overview')}>Overview</button>
          </li>
        </Tabs>
      </Container>
      <PathwayFullWidthCard>
        <Container>
          {activeTab === 'contents' &&
            training.entities
              .filter(e => e._type === 'TaskList')
              .map((c, i) => {
                const course = c.taskList;
                course.ownerName = training.owner === course.owner ? training.ownerName : '';
                return (
                  <PathwayCourse
                    key={course._id.id}
                    course={course}
                    i={i}
                    length={training.numItems}
                    localStorage={localStorage}
                  />
                );
              })}
          {activeTab === 'overview' && (
            <OverviewContainer>
              <TrainingOverview
                description={training.description}
                truncateLength={5000}
                categories={training.categories}
              />
            </OverviewContainer>
          )}
        </Container>
      </PathwayFullWidthCard>
    </>
  );
};

export default PathwayContents;
