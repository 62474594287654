angular.module('lwNamb').controller('analyticsCtrl', [
  '$rootScope',
  '$scope',
  '$localStorage',
  'analyticsService',
  'orgService',
  function($rootScope, $scope, $localStorage, analyticsService, orgService) {
    $scope.$watch(
      function() {
        return $localStorage.user;
      },
      function(newUser, oldUser) {
        if (newUser !== oldUser) {
          analyticsService.updateUser($localStorage.user);
          if ($localStorage.user !== null && $localStorage.user !== undefined && $localStorage.user.pristine) {
            analyticsService.firstTimeUser($localStorage.user);
          }
        }
      }
    );
    $scope.$watch(
      function() {
        return $rootScope.uniquePageName;
      },
      function(newPageName, oldPageName) {
        if (newPageName !== oldPageName) {
          analyticsService.pageChange($rootScope.pageTitle, newPageName);
        }
      }
    );
    $rootScope.$on('OrgAccountMemberAdded', function(name, event) {
      orgService.getOrgOverview(event.id).then(function(org) {
        if (org.owner !== $localStorage.user.userId) {
          //Owner gets added to an org, we don't need to place them on the OrgUser campaign
          analyticsService.joinedOrg(
            event.id,
            org.name,
            $localStorage.user.firstName,
            $localStorage.user.lastName,
            $localStorage.user.logInEmail,
            $localStorage.user.userId
          );
        }
      });
    });
    $scope.$on('TaskListInstanceSetComplete', function(event_name, event) {
      analyticsService.firstTaskListComplete($localStorage.user, event.id);
    });
  },
]);
