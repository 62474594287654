angular.module('lwNamb').directive(
  'assessmentResultsViewer',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/assessment-results-viewer.html',
        scope: {
          taskInstance: '=?',
          responseId: '@?',
          viewerRole: '@',
        },
        replace: true,
        controller: [
          '$scope',
          '$log',
          'assessTakerService',
          'orderByFilter',
          function($scope, $log, assessTakerService, orderBy) {
            $scope.isLoading = true;
            if ($scope.responseId === undefined) {
              $scope.responseId = $scope.taskInstance.task.responseId;
            }
            assessTakerService.getAssessmentResults($scope.responseId).then(
              function(data) {
                $scope.isLoading = false;
                if (
                  data.responseVisibility === 'ALL' ||
                  data.responseVisibility === $scope.viewerRole || // ASSESSOR or ASSESSEE
                  $scope.taskInstance.task._type === 'PrepareEnrich'
                ) {
                  data.questions = data.questions.map(function(question) {
                    question.answers =
                      question.questionType === 'multiselectandinput'
                        ? question.answers.filter(function(a) {
                            return a.value;
                          })
                        : question.answers;
                    question.isGraded = !!question.answers.find(function(a) {
                      return a.isCorrect;
                    });
                    return question;
                  });
                  data.questions = orderBy(data.questions, 'index', false);
                  $scope.results = data;
                }
              },
              function(reason) {
                $log.error(reason);
                $scope.isResultsError = true;
                $scope.isLoading = false;
              }
            );
          },
        ],
      };
    },
  ]
);
