angular.module('lwNamb').controller('manageAssignmentsCtrl', [
  '$scope',
  '$location',
  '$log',
  'userService',
  'windowService',
  'orgService',
  'pagingService',
  'taskListInstanceService',
  'analyticsService',
  'taskListService',
  'api',
  '$timeout',
  'alertService',
  '$localStorage',
  '$route',
  function(
    $scope,
    $location,
    $log,
    userService,
    windowService,
    orgService,
    pagingService,
    taskListInstanceService,
    analyticsService,
    taskListService,
    api,
    $timeout,
    alertService,
    $localStorage,
    $route
  ) {
    $scope.allTaskLists = 'ALL_TL';
    $scope.allWorkflows = 'ALL_WF';

    var init = function() {
      $scope.allAssignments = [];

      $scope.loading = true;
      $scope.roles = [];
      $scope.selectedItems = [];
      $scope.incompleteItems = [];
      $scope.$watchCollection("selectedItems", function(items){
        $scope.incompleteItems = items.filter(function(i) {
          return i.assignable.progress._type !== 'Finished';
        });
      });
      $scope.filteredMembers = [];
      var label = 'All Courses';
      $scope.tasklistsForDropdown = [
        { name: 'All Assignments', value: '' },
        { name: label, value: $scope.allTaskLists },
        { name: 'All Pathways', value: $scope.allWorkflows },
      ];
      $scope.rolesDropdown = []; //leaving in to prevent flicker
      $scope.groupsDropdown = []; //leaving in to prevent flicker
      //Select All boolean
      $scope.submitted = false;
      $scope.workflows = [];
      $scope.isCPCA = !!$location.search().cpca;
      $scope.pageTitle = $scope.isCPCA ? "CPCA Assignments" : "Manage Assignments";

      userService.user().then(
        function(user) {
          $scope.user = user;
          if($localStorage.filters !== undefined && $localStorage.filters.view === 'manageAssignments'){
            $scope.filter = $localStorage.filters;
            $scope.filter.redirectsLeft = undefined;
            $scope.filter.view = undefined;
          } else {
            $scope.clearInput();
          }
          retrieveUserData();
          lookupTLsForOrg();
        },
        function() {
          $log.error('No user returned in manageAssignmentsCtrl');
        }
      );
    };

    var retrieveUserData = function() {
      orgService.getOrg($scope.user.lastSelectedAccount).then(
        function(org) {
          $scope.org = org;
          $scope.rolesDropdown = org.rolesDropdown;
          $scope.groupsDropdown = org.groups.filter(function(g){
            return g.members.length > 0 || g.pendingMembers.length > 0;
          }).map(function(g){
            return {
              name: g.name,
              value: g.name,
            };
          });
        },
        function(response) {
          $log.error('Error: Org not found; ' + response);
        }
      );
    };

    var lookupTLsForOrg = function() {
      var getAssignments = $scope.isCPCA ? taskListService.getCPCAAssignments() : taskListService.getAssignments($scope.user.lastSelectedAccount);
      getAssignments.then(
        function(assignments) {
          $scope.allAssignments = assignments;
          $scope.loading = false;
          if (!$scope.isCPCA && $localStorage.assignedInstances && $localStorage.assignedInstances.length > 0) {
            var assignmentIds = assignments.map(function(assignment) {
              if (assignment.assignable._type === 'AssignedTaskList') {
                return assignment.assignable.taskListInstanceId.id;
              } else if (assignment.assignable._type === 'Workflow') {
                return assignment.assignable.workflowInstanceId;
              } else {
                $log.error('bad assignment type: ' + JSON.stringify(assignment));
                return '';
              }
            });
            $localStorage.assignedInstances = $localStorage.assignedInstances.filter(function(training) {
              var remainingInstances = training.assignmentInstances.filter(function(instance) {
                return !assignmentIds.includes(instance.instanceId);
              });
              training.assignmentInstances = remainingInstances;
              training.progress =
                ((training.originalLength - training.assignmentInstances.length) / training.originalLength) * 100;
              return remainingInstances.length !== 0;
            });
            $scope.pending = $localStorage.assignedInstances;
            if ($scope.pending.length === 0) {
              $route.reload();
            } else if ($scope.pending.length > 0 && $localStorage.assignmentsLookupTimeout === undefined) {
              $localStorage.assignmentsLookupTimeout = $timeout(function() {
                $timeout.cancel($localStorage.assignmentsLookupTimeout);
                $localStorage.assignmentsLookupTimeout = undefined;
                lookupTLsForOrg();
              }, 10000);
            }
          }
          var unsortedTLNames = [],
            unsortedTLNamesForSelect = [];
          for (var i = 0; i < assignments.length; i++) {
            var unique = true;
            for (var j = 0; j < unsortedTLNames.length; j++) {
              if (unsortedTLNames[j] === assignments[i].assignable.name) {
                unique = false;
              }
            }
            if (unique) {
              unsortedTLNames.push(assignments[i].assignable.name);
              unsortedTLNamesForSelect.push(assignments[i].assignable.name);
            }
          }
          unsortedTLNames.forEach(function(tlName) {
            $scope.tasklistsForDropdown.push({
              name: tlName,
              value: tlName,
            });
          });
        },
        function(reason) {
          $log.error(reason);
          alertService.show({ title: 'Error! Something went wrong.', type: 'danger', show: true, dismissible: true, duration: 20, });
        }
      );
    };

    $scope.clearInput = function() {
      $scope.filter = {
        search: '',
        role: [],
        group: [],
        status: '',
        assignmentType: $scope.tasklistsForDropdown[0].value,
      };
    };

    $scope.filterRestriction = function(item) {
      return (
        !item ||
        !item.assignable ||
        item.assignable.progress === undefined ||
        item.assignable.progress._type !== 'Finished'
      );
    };

    var showAlertSuccess = function(qty) {
      alertService.show({
        title: qty + ' Assignment' + (qty > 1 ? 's' : '') + '  removed',
        type: 'success',
        duration: 5,
      });
    };

    $scope.unassignTraining = function(training, closeModal) {
      $scope.unassigning = true;
      taskListService.unAssignTraining(training, $scope.user.userId).then(
        function(removedCount) {
          showAlertSuccess(removedCount);
          $scope.select = true;
          $scope.selectedItems = [];
          lookupTLsForOrg();
          closeModal();
          $scope.unassigning = false;
        },
        function(reason) {
          $log.error(reason);
          $timeout(function() {
            closeModal();
            $scope.unassigning = false;
            windowService.locationReload();
          }, 5000);
        }
      );
    };

    $scope.export = function() {
      $scope.exporting = true;
      var ids = $scope.selectedItems.map(function(i) {
        return i._id;
      });
      var idsObj = !ids || ids.length === 0 ? {} : { ids: ids };
      analyticsService.trackFeature(
        'ExportAssignments',
        'exported:' + ids.length === 0 ? 'All' : ids.length.toString()
      );
      api
        .post('/v1/organizations/' + $scope.user.lastSelectedAccount + '/assignmentsCSV', idsObj, {
          responseType: 'arraybuffer',
        })
        .then(
          function(response) {
            var headers = response.headers();
            var blob = new Blob([response.data], { type: headers['content-type'] });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'assignments.csv';
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            $scope.exporting = false;
            alertService.show({ title: 'Export Success', type: 'success', duration: 5 });
          },
          function() {
            $scope.exporting = false;
            alertService.show({
              type: 'danger danger-with-content',
              title: 'Unexpected Error!',
              content: 'Something went wrong. Please try again.',
              duration: 20,
            });
          }
        );
    };

    init();
  },
]);
