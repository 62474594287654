import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';

const Labels = styled.ul`
  list-style: none;
  margin: 0 ${Grid._8} ${Grid._4} 0;
  padding: 0;
  li {
    color: ${Color.Gray._70};
    display: inline-block;
    &:not(:last-of-type)::after {
      content: '•';
      margin: 0 ${Grid._3};
    }
  }
`;

export default Labels;
