angular.module('lwNamb').factory(
  'emailTemplateService',

  [
    '$rootScope',
    'api',
    'qt',
    'CacheFactory',
    function($rootScope, api, qt, CacheFactory) {
      var timeoutSeconds = 15,
        templateCache = CacheFactory('emailTemplates', {
          maxAge: 60 * 1000, // 1 minute
          deleteOnExpire: 'aggressive',
        });

      return {
        getTemplates: function(orgId, tag, showDeleted) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          if (retrieveFromCache(orgId, tag, showDeleted)) {
            deferred.resolve(retrieveFromCache(orgId, tag, showDeleted));
          } else {
            getTemplateAndCache(orgId, tag, showDeleted).then(function(templates) {
              deferred.resolve(templates);
            });
          }
          return deferred.promise;
        },
      };

      function retrieveFromCache(orgId, tag, showDeleted) {
        return templateCache.get(orgId + '-' + tag + '-' + showDeleted);
      }

      function putInCache(orgId, tag, showDeleted, template) {
        templateCache.put(orgId + '-' + tag + '-' + showDeleted, template);
      }

      function getTemplateAndCache(orgId, tag, showDeleted) {
        var url = '/v1/templates/' + orgId + '?';
        if (tag !== undefined) {
          url = url + 'tag=' + tag + '&';
        }
        if (showDeleted !== undefined) {
          url = url + 'showDeleted=' + showDeleted;
        }
        if (url.slice(-1) === '&' || url.slice(-1) === '?') {
          url = url.slice(0, -1);
        }
        return api.get(url).then(function(response) {
          var templates = [];
          response.data.templates.forEach(function(t) {
            var template = {
              id: t._id,
              ownerId: t.ownerId.id,
              name: t.templateName,
              tags: t.tags,
              subject: t.subject,
              body: t.body,
            };
            templates.push(template);
          });
          putInCache(orgId, tag, showDeleted, templates);
          return templates;
        });
      }
    },
  ]
);
