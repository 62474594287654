const emptyStateData = {
  'no-results': {
    title: 'Your search does not have any matches',
    icon: 'fa-search',
    content: '<p class="text-center">Please try another search.</p>',
  },
  'is-error': {
    title: 'A problem occurred showing your groups',
    icon: 'fa-exclamation-circle',
    content:
      '<p class="text-center">Please refresh the page, and if they still don’t display, <a href="#/help">contact us.</a></p>',
  },
  'is-error-group': {
    title: 'A problem occurred showing your group',
    icon: 'fa-exclamation-circle',
    content:
      '<p class="text-center">Please refresh the page, and if it still doesn’t display, <a href="#/help">contact us.</a></p>',
  },
  'no-groups': {
    title: 'Get Started with Groups',
    icon: 'fa-users',
    content:
      '<p>Groups allow you to organize people within your organization. In each group, you can specify one or more <b>group leaders</b> who can</p><ul><li>add and remove people from their groups,</li><li>make assignments, and</li><li>follow the progress of other group members.</li></ul><p>Groups can be renamed or deleted at any time, so you can come up with what works best for your context. Use the “Add Group” button above to create your first group.</p>',
  },
  'no-people': {
    title: 'No one is in this group',
    icon: 'fa-users',
    content:
      '<p>Use Add People above to</p><ul><li>get an Easy Link to send to people and have them automatically add themselves to this group</li><li>choose people already part of your organization to add to this group</li></ul>',
  },
  'no-people-role': {
    title: 'No one is in this role',
    icon: 'fa-users',
    content: '<p>Use Add People above to add members of your organization to this role</p>',
  },
  everyone: {
    title: 'Everyone Has Been Added',
    icon: 'fa-users',
    content:
      '<p class="text-center">Start <a href="#/invite/new">here</a> to invite more people to your organization.</p>',
  },
};

export default emptyStateData;
