import React, { useState, useEffect, useReducer } from 'react';
import { react2angular } from 'react2angular';
import { Grid, Type } from '../StyleGuide';
import styled from 'styled-components';
import { useSteps } from './StepsContext';
import discussionService from '../services/discussionService';
import eventBus from '../services/globalEventBus';
import NewCommentForm from './discussion/NewCommentForm';
import Comment from './discussion/Comment';
import LoadingState from '../components/LoadingState';
import alertService from '../services/AlertService';

const DiscussionHeader = styled.div`
  display: flex;
  align-items: center;
  h4 {
    flex: 1;
    margin: 0;
    i {
      font-size: ${Grid._5};
      margin-right: ${Grid._3};
      opacity: 0.5;
    }
  }
  p {
    color: ${Type.Color.medium};
    font-size: ${Type.Scale._3};
    margin-bottom: 0;
  }
`;

const Comments = styled.div`
  margin-bottom: ${Grid._8};
`;

const byDate = (a, b) => a.created > b.created;

const commentRemoved = (state, comment) => {
  if (comment.immediateParent === 'top') {
    return !state.comments.find(c => c.id === comment.id)
      ? state
      : { ...state, comments: state.comments.filter(c => c.id !== comment.id) };
  } else {
    return {
      ...state,
      comments: state.comments.map(c => {
        if (c.id === comment.immediateParent) {
          if (!c._replies) {
            return { ...c, replies: c.replies > 0 ? c.replies - 1 : 0 };
          } else {
            if (c._replies.find(r => r.id === comment.id)) {
              return { ...c, replies: c.replies - 1, _replies: c._replies.filter(r => r.id !== comment.id) };
            } else {
              return c;
            }
          }
        } else {
          return c;
        }
      }),
    };
  }
};

const commentPosted = (state, comment, userId) => {
  const newComment = {
    ...comment,
    upvoted: 0,
    upvoters: [],
    replies: 0,
  };
  const prevComments = state.comments || [];
  if (comment.immediateParent === 'top') {
    return prevComments.find(c => c.id === newComment.id)
      ? state
      : { ...state, comments: [...prevComments, newComment].sort(byDate) };
  } else {
    return {
      ...state,
      comments: prevComments.map(c => {
        if (c.id === newComment.immediateParent) {
          if (c._replies) {
            if (!c._replies.find(r => r.id === newComment.id)) {
              return {
                ...c,
                replies: c.replies + 1,
                _replies: [...c._replies, newComment].sort(byDate),
                showReplies: c.showReplies || newComment.lifeway_id === userId,
                scrollToReplies: !c.showReplies && newComment.lifeway_id === userId,
              };
            } else {
              return c;
            }
          } else {
            return { ...c, replies: c.replies + 1 };
          }
        } else {
          return c;
        }
      }),
    };
  }
};

const discussionReducer = userId => (state, event) => {
  try {
    if (!state.id) {
      return event.type === 'DiscussionLoaded' ? event.data : state;
    } else {
      switch (event.type) {
        case 'DiscussionCleared':
          return {};

        case 'DiscussionLoaded':
          return event.data;

        case 'RepliesLoaded':
          return {
            ...state,
            comments: state.comments.map(c =>
              c.id === event.data.id
                ? {
                    ...c,
                    replies: event.data._replies.length,
                    _replies: event.data._replies,
                    showReplies: true,
                    scrollToReplies: event.data.scrollToReplies,
                  }
                : c
            ),
          };

        case 'ShowRepliesSet':
          return {
            ...state,
            comments: state.comments.map(c =>
              c.id === event.data.id ? { ...c, showReplies: event.data.showReplies } : c
            ),
          };

        case 'ScrollToRepliesSet':
          return {
            ...state,
            comments: state.comments.map(c =>
              c.id === event.data.id ? { ...c, scrollToReplies: event.data.scrollToReplies } : c
            ),
          };

        case 'CommentPosted':
          return commentPosted(state, event.data, userId);

        case 'CommentRemoved':
          return commentRemoved(state, event.data);

        case 'CommentStatusChanged':
          if (event.data.status === 'reject') {
            return commentRemoved(state, event.data);
          } else {
            return state;
          }

        case 'CommentUpvoted':
          return {
            ...state,
            comments: state.comments.map(c => {
              if (c.id === event.data.id) {
                if (!c.upvoters.includes(event.data.lifeway_id)) {
                  return { ...c, upvoted: c.upvoted + 1, upvoters: [...c.upvoters, event.data.lifeway_id] };
                } else {
                  return c;
                }
              } else {
                if (c._replies && c._replies.find(r => r.id === event.data.id)) {
                  return {
                    ...c,
                    _replies: c._replies.map(r => {
                      if (r.id === event.data.id && !r.upvoters.includes(event.data.lifeway_id)) {
                        return { ...r, upvoted: r.upvoted + 1, upvoters: [...r.upvoters, event.data.lifeway_id] };
                      } else {
                        return r;
                      }
                    }),
                  };
                } else {
                  return c;
                }
              }
            }),
          };

        default:
          return state;
      }
    }
  } catch (error) {
    console.error('discussionReducer failed', { error: error, state: state, event: event });
    return state;
  }
};

export default function Discussion() {
  const [{ user, currentCourse, currentStep, isCompletingStep }] = useSteps();
  const [isLoading, setIsLoading] = useState(true);
  const [discussion, dispatch] = useReducer(discussionReducer(user.userId), {});

  const hasComments = discussion.comments && discussion.comments.length;
  const originalTaskListId =
    currentCourse._type === 'CourseInstanceDetail'
      ? currentCourse.originalTaskListId.id
      : currentCourse.originalTaskListId;
  const defaultArgs = [user, originalTaskListId, currentStep];

  const showFailure = () =>
    alertService.show(
      'Oops, something went wrong. We’re sorry for the inconvenience. Please try again later.',
      'error'
    );

  useEffect(() => {
    if (!isCompletingStep) {
      // not sure what purpose this serves
      setIsLoading(true);
      discussionService
        .getDiscussion(...defaultArgs)
        .then(result => dispatch({ type: 'DiscussionLoaded', data: result || {} }))
        .catch(_ => showFailure())
        .finally(_ => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep.task?._id.id, isCompletingStep]);

  // not sure if ever invoked, may be safe to remove
  useEffect(() => {
    if (
      discussion.filter &&
      (discussion.filter.indexOf(currentStep.task?._id.id) === -1 ||
        discussion.filter.indexOf(originalTaskListId) === -1)
    ) {
      dispatch({ type: 'DiscussionCleared' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discussion.filter]);

  useEffect(() => {
    if (discussion.id) {
      const eventUnsubscribes = ['CommentPosted', 'CommentRemoved', 'CommentStatusChanged', 'CommentUpvoted'].map(
        eventName => {
          return eventBus.subscribe(eventName, event => {
            if (event.discussionId === discussion.id) {
              dispatch({ type: eventName, data: event });
            }
          });
        }
      );

      const [resubscribe, unsubscribe] = discussionService.subscribeToDiscussion(
        user.userId,
        user.lastSelectedAccount,
        discussion.id
      );

      // sub duration is 30 mins, resubscribe after 29
      const cancelResub = (() => {
        const timeoutId = setInterval(() => {
          resubscribe();
        }, 29 * 60 * 1000);
        return () => {
          clearInterval(timeoutId);
        };
      })();

      return () => {
        unsubscribe();
        eventUnsubscribes.forEach(u => u());
        cancelResub();
      };
    }
  }, [discussion.id]);

  const handleCommentSubmission = commentText => {
    if (discussion.id) {
      return postComment(commentText, discussion.id);
    } else {
      return discussionService.getDiscussion(...defaultArgs).then(discussion => {
        if (discussion && discussion.id) {
          dispatch({ type: 'DiscussionLoaded', data: discussion });
          postComment(commentText, discussion.id);
        } else {
          return discussionService.createDiscussion(...defaultArgs, origin).then(newDiscussion => {
            dispatch({ type: 'DiscussionLoaded', data: newDiscussion });
            postComment(commentText, newDiscussion.id);
          });
        }
      });
    }
  };

  const postComment = (commentText, discussionId) =>
    discussionService
      .postComment(commentText, discussionId, ...defaultArgs)
      .then(comment => dispatch({ type: 'CommentPosted', data: comment })) // not necessary but may improve latency
      .catch(_ => showFailure());

  // UI States
  return isLoading ? (
    <LoadingState />
  ) : (
    <>
      {hasComments ? (
        <>
          <DiscussionHeader data-qa-hook="joinDiscussionHeader">
            <h4>
              <i className="far fa-comments"></i> Join the Discussion
            </h4>
            <p data-qa-hook="numberOfComments">
              {discussion.comments.length} comment{discussion.comments.length > 1 && 's'}
            </p>
          </DiscussionHeader>
          <Comments data-qa-hook="commentsRepliesContainer" className="discussion_comments">
            {discussion.comments.map(comment => (
              <Comment comment={comment} key={comment.id} defaultArgs={defaultArgs} dispatch={dispatch} />
            ))}
          </Comments>
        </>
      ) : (
        <div data-qa-hook="startDiscussion" className="empty-state-tab">
          <p>
            <i className="far fa-comments"></i>
          </p>
          <h4>Start the Discussion</h4>
          <p>Post a question or thought about this step for others to see.</p>
        </div>
      )}
      <NewCommentForm handleSubmission={handleCommentSubmission} orgName={user.currentOrgName} />
    </>
  );
}

angular.module('lwNamb').component('discussion', react2angular(Discussion));
