angular.module('lwNamb').directive(
  'coachPaneTaskList',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/coach-pane-task-list.html',
        scope: {
          userId: '@',
          paneId: '@',
          orgId: '@',
        },
        replace: true,
        controller: [
          '$scope',
          '$log',
          'State',
          'taskListInstanceService',
          'apiUrlService',
          'windowService',
          function($scope, $log, State, taskListInstanceService, apiUrlService, windowService) {
            $scope.isLoading = true;

            $scope.reportVisibility = 'PRIVATE';

            var state = State($scope.paneId);
            $scope.selected = state.get();

            taskListInstanceService.getByInstanceId($scope.selected.taskListId).then(
              function(taskList) {
                $scope.taskList = taskList;
                $scope.isLoading = false;
              },
              function(reason) {
                $log.error(reason);
                $scope.isLoading = false;
                $scope.isError = true;
              }
            );

            $scope.close = function() {
              State($scope.paneId).clear();
              state.put('menu', 'task-lists');
            };

            $scope.downloadFile = function(tasklist, report) {
              var lookupFileName = encodeURIComponent(tasklist.name.replace(/ /gi, '_'));
              var lookupFilePath = encodeURIComponent(report.reportId);
              windowService.openUrl(
                apiUrlService.getUrl() + '/v1/files?fileName=' + lookupFileName + '&filePath=' + lookupFilePath
              );
            };

            $scope.redirectToTaskLists = function() {
              State($scope.paneId).clear();
              state.put('menu', 'task-lists');
            };

            $scope.redirectToTask = function(task) {
              state.put('menu', 'task');
              state.put('taskId', task.id.id);
            };
          },
        ],
      };
    },
  ]
);
