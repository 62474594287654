import { AxiosResponse } from 'axios';
import cacheableRequest from '../../services/cacheableRequest';

type ContentResponse = {
  video: Video;
};

export type Content = {
  contentType: 'Assessment' | 'Video' | 'Download';
  id: string;
  lastEditedDate: string;
  library: boolean;
  name: string;
  numberOfQuestions: number;
  owner: string;
  published: boolean;
  poster: string;
};

export type Video = {
  _id: string;
  bc_id: string;
  deleted: boolean;
  description: string;
  duration: number;
  lastModifiedDate: string;
  poster: string;
  published: boolean;
  thumbnail: string;
  title: string;
};

export const getPosterURL: (contentId: string) => Promise<string> = contentId =>
  cacheableRequest(`/v1/videos/${contentId}/poster`).then(({ data }: AxiosResponse<{ poster: string }>) => {
    return data.poster;
  });

export const getContent: (orgId: string, contentId: string) => Promise<Video> = (orgId, contentId) =>
  cacheableRequest(`/v1/videos/${contentId}`).then(({ data }: AxiosResponse<ContentResponse>) => {
    return data.video;
  });
