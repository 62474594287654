import React from 'react';
import styled from 'styled-components';
import { Grid, Color, Type, Breakpoints } from '../StyleGuide';
import PropTypes from 'prop-types';
import WhiteLabel from '../filters/WhiteLabel';
import capitalizeFilter from '../filters/capitalize-filter';

const InstructionContainer = styled.div`
  background: ${Color.Gray._10};
  margin-top: ${Grid._7};
  padding-bottom: ${Grid._4};
`;

const Instruction = styled.div`
  padding: ${Grid._5} ${Grid._6} ${Grid._4} ${Grid._6};
  border-top: 1px solid ${Color.Gray._20};
`;

const InstructionTitle = styled.div`
  font-weight: ${Type.Weight.bold};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    text-align: right;
  }
`;

export default function NewPurchasesOverlayInstructions({ type }) {
  return (
    <>
      {type === 'curriculum' ? (
        <InstructionContainer>
          <Instruction className="grid-container grid-sm-col-4 grid-col-gap-40 grid-row-gap-8">
            <InstructionTitle className="grid-sm-col-span-1">Distribute</InstructionTitle>
            <div className="grid-sm-col-span-3">
              <p>
                As an Admin, you can distribute Bible Study materials by <b>adding</b> leaders and learners to a{' '}
                <b>collection</b>. A collection includes all of the documents and media that people will use to study
                each session. As soon as you add someone to a collection, <i>all</i> of the available current and future
                sessions for that collection will immediately appear when they log in. “This week’s session” will be
                highlighted so they know exactly what to study.
              </p>
            </div>
          </Instruction>

          <Instruction className="grid-container grid-sm-col-4 grid-col-gap-40 grid-row-gap-8">
            <InstructionTitle className="grid-sm-col-span-1">Schedule</InstructionTitle>
            <div className="grid-sm-col-span-3">
              <p>
                You can <b>reschedule</b> each session to use it on a different day, or remove it from the calendar
                completely. Scheduled session dates apply to everyone who is distributed the curriculum.
              </p>
            </div>
          </Instruction>

          <Instruction className="grid-container grid-sm-col-4 grid-col-gap-40 grid-row-gap-8">
            <InstructionTitle className="grid-sm-col-span-1">Manage</InstructionTitle>
            <div className="grid-sm-col-span-3">
              <p>
                Need to add another admin for your church? Use the <b>Roles</b> feature under the “Organization” menu.
                From there, you can add people to the <b>Admin</b> role so that they can distribute and schedule the
                curriculum you’ve purchased.
              </p>
              <p>
                For more information, visit our curriculum FAQs on{' '}
                <a
                  href="https://support.ministrygrid.com/en/collections/2590229-digital-curriculum-on-ministry-grid"
                  target="_blank"
                  rel="noreferrer"
                >
                  support.ministrygrid.com
                </a>
              </p>
            </div>
          </Instruction>
        </InstructionContainer>
      ) : (
        <InstructionContainer>
          <Instruction className="grid-container grid-sm-col-4 grid-col-gap-40 grid-row-gap-8">
            <InstructionTitle className="grid-sm-col-span-1">Browse Our Catalog</InstructionTitle>
            <div className="grid-sm-col-span-3">
              <p>Discover over 700+ courses and pathways of training across dozens of ministry areas.</p>
            </div>
          </Instruction>

          <Instruction className="grid-container grid-sm-col-4 grid-col-gap-40 grid-row-gap-8">
            <InstructionTitle className="grid-sm-col-span-1">
              Train Your {capitalizeFilter(WhiteLabel.labels.org)}
            </InstructionTitle>
            <div className="grid-sm-col-span-3">
              <p>
                When you find the right training, use the Easy Link or Email options on the overview page to assign that
                training to others.
              </p>
            </div>
          </Instruction>

          <Instruction className="grid-container grid-sm-col-4 grid-col-gap-40 grid-row-gap-8">
            <InstructionTitle className="grid-sm-col-span-1">Customize Your Training</InstructionTitle>
            <div className="grid-sm-col-span-3">
              <p>
                Before assigning training, use the Customize button on any course or pathway to make a version specific
                for your {WhiteLabel.labels.org}. Add or remove content from the training before assigning it out to
                others.
              </p>
            </div>
          </Instruction>
        </InstructionContainer>
      )}
    </>
  );
}

NewPurchasesOverlayInstructions.propTypes = {
  type: PropTypes.string,
};
