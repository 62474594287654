angular.module('lwNamb').directive(
  'taskViewerAssessment',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/task-viewer-assessment.html',
        scope: {
          taskInstance: '=',
          taskListInstanceId: '@',
          twoPane: '=',
          userId: '@',
          orgId: '@',
        },
        replace: true,
        controller: [
          '$scope',
          'taskListInstanceService',
          'userService',
          '$log',
          function($scope, taskListInstanceService, userService, $log) {
            $scope.generateReport = function() {
              $scope.isReportGenerating = true;
              $scope.isError = false;
              userService.user().then(
                function(user) {
                  $scope.user = user;
                  taskListInstanceService
                    .generateTaskReport(
                      $scope.taskInstance.id.id,
                      $scope.taskListInstanceId,
                      $scope.taskInstance.task.reportToGenerate,
                      $scope.userId,
                      $scope.orgId,
                      $scope.user.userId
                    )
                    .then(
                      function(reportId) {
                        $scope.reportLink = taskListInstanceService.generateReportLink(
                          reportId,
                          $scope.taskInstance.task.taskName
                        );
                      },
                      function(reason) {
                        $log.error(reason);
                        $scope.isError = true;
                      }
                    )
                    .finally(function() {
                      $scope.isReportGenerating = false;
                    });
                },
                function() {
                  $log.error('No user returned in taskViewerAssessment');
                }
              );
            };

            var reportId = $scope.taskInstance.task.reportId;
            if (reportId) {
              $scope.reportLink = taskListInstanceService.generateReportLink(
                reportId,
                $scope.taskInstance.task.taskName
              );
            } else if ($scope.taskInstance.status == 'complete' && $scope.taskInstance.task.reportToGenerate) {
              $scope.generateReport();
            }
          },
        ],
      };
    },
  ]
);
