import React from 'react';
import ConfirmationModal from '../../../components/ConfirmationModal';
import capitalizeFilter from '../../../filters/capitalize-filter';
import { ModalWarningList } from '../../../components/ModalElements';
import { TrainingEntityType } from '../../models/training';

type Props = {
  name: string;
  type: TrainingEntityType;
  handleSubmit: () => void;
  handleDismiss: () => void;
};

export const RemoveTrainingModal = ({ name, type, ...props }: Props) => (
  <ConfirmationModal
    buttonActionText="Remove"
    buttonType="danger"
    title={`Remove ${capitalizeFilter(type)}`}
    prompt={<span>Are you sure you want to remove this {type}?</span>}
    subtext={name}
    {...props}
  >
    <ModalWarningList warnings={['This action cannot be undone']} />
  </ConfirmationModal>
);
