import apiClient from './apiClient';
import eventBus from './globalEventBus';

const submitCommand = (id, cmd, cmdName, evtNames, errNames, returnOnlyId) => {
  return new Promise((resolve, reject) => {
    const unsubscribes = [];
    const unsubscribeAll = () => {
      unsubscribes.forEach(u => u());
    };

    if (!Array.isArray(evtNames)) {
      evtNames = [evtNames];
    }
    evtNames.forEach(evtName => {
      const unsubscribe = eventBus.subscribe(evtName, event => {
        if (event.id === id) {
          unsubscribeAll();
          resolve(returnOnlyId === true ? id : event);
        }
      });
      unsubscribes.push(unsubscribe);
    });

    if (!errNames) {
      errNames = [];
    } else if (!Array.isArray(errNames)) {
      errNames = [errNames];
    }
    errNames.forEach(errName => {
      const unsubscribe = eventBus.subscribe(errName, event => {
        if (!event.id || event.id === id) {
          unsubscribeAll();
          reject(event);
        }
      });
      unsubscribes.push(unsubscribe);
    });

    apiClient.post(`/v1/commands/${cmdName}`, cmd).catch(response => {
      reject(response); // todo: more error handling?
      unsubscribeAll();
    });
  });
};

export default submitCommand;
