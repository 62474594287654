import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import AccessDenied from '../components/AccessDenied';
import { Container } from '../components/Layout';
import userService from '../services/userService';

import RightToBeForgotten from './RightToBeForgotten';
import Impersonate from './Impersonate';

export default function AdminController() {
  const [status, setStatus] = useState({ isLoading: true, isError: false, hasAdminPermission: false });
  const { isLoading, isError, hasAdminPermission } = status;

  useEffect(() => {
    userService
      .getHasAdminPermission()
      .then(isAdmin => {
        setStatus({ isLoading: false, hasAdminPermission: isAdmin });
      })
      .catch(error => {
        console.error(error);
        setStatus({ isLoading: false, isError: true });
      });
  }, []);

  return isLoading ? (
    <Container>
      <LoadingState />
    </Container>
  ) : isError ? (
    <Container>
      <ErrorMessage />
    </Container>
  ) : !hasAdminPermission ? (
    <Container>
      <AccessDenied />
    </Container>
  ) : (
    <Router>
      <Switch>
        <Route path="/admin/rtbf" render={props => <RightToBeForgotten {...props} />} />
        <Route path="/admin/impersonate" render={props => <Impersonate {...props} />} />
      </Switch>
    </Router>
  );
}

AdminController.propTypes = {};
