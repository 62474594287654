import { useState, useMemo } from 'react';

type UseTextFilter<T> = {
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  query: string;
  results: T[];
};

const useTextFilter = <T extends Record<string, unknown>>(
  unfilteredList: T[],
  filterKeys: (keyof T)[]
): UseTextFilter<T> => {
  const [query, setQuery] = useState('');

  const results = useMemo(() => {
    if (unfilteredList && query) {
      return unfilteredList.filter(unfilteredItem =>
        filterKeys.some(
          key =>
            typeof unfilteredItem[key] === 'string' &&
            (unfilteredItem[key] as string).toLowerCase().includes(query.toLowerCase())
        )
      );
    } else {
      return unfilteredList;
    }
  }, [query, unfilteredList, filterKeys]);

  return { setQuery, query, results };
};

export default useTextFilter;
