import { createGlobalStyle } from 'styled-components';
import { Type } from './StyleGuide';

const GlobalStyle = createGlobalStyle`
  body react-app {
    font-family: ${Type.family};
    font-size: ${Type.Scale._2};
    color: ${Type.Color.dark};

    h1 {
      font-size: ${Type.Scale._6};
    }

    h2 {
      font-size: ${Type.Scale._5};
    }

    h3 {
      font-size: ${Type.Scale._4};
    }

    h4 {
      font-size: ${Type.Scale._4};
    }

    h5, h6 {
      font-size: ${Type.Scale._3};
    }

    h1, h2, h3, h4, h5, h6 {
      color: ${Type.Color.dark};
      font-weight: ${Type.Weight.semibold};
    }

    p {
      color: ${Type.Color.dark};
      font-size: ${Type.Scale._2};
      font-weight: ${Type.Weight.normal};
      strong {
        font-weight: ${Type.Weight.semibold}
      }
    }
  }
`;

export default GlobalStyle;
