angular.module('lwNamb').filter('label', [
  'apiUrlService',
  function(apiUrlService) {
    var origin = apiUrlService.getOrigin(),
      labels = {
        GRID: {
          course: 'course',
          step: 'step',
          siteName: 'Ministry Grid',
          siteURL: 'ministrygrid.com',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        TEXASBAPTISTS: {
          course: 'course',
          step: 'step',
          siteName: 'Texas Baptists',
          siteURL: 'etraining.texasbaptists.org',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        TRANSFORMATION: {
          course: 'course',
          step: 'step',
          siteName: 'Transformation Church',
          siteURL: 'hub.transformationchurch.tc',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        BUCKHEAD: {
          course: 'course',
          step: 'step',
          siteName: 'Buckhead Church GS',
          siteURL: 'bcguestservices.org',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        IDLEWILD: {
          course: 'course',
          step: 'step',
          siteName: 'Idlewild Church Training',
          siteURL: 'training.idlewild.org',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        BELLEVUE: {
          course: 'course',
          step: 'step',
          siteName: 'Bellevue Training',
          siteURL: 'training.bellevue.org',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        YUMA: {
          course: 'course',
          step: 'step',
          siteName: 'Training Network',
          siteURL: 'training.dswnetwork.org',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        MBCB: {
          course: 'course',
          step: 'step',
          siteName: 'MBCB Training',
          siteURL: 'training.mbcb.org',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        CAMINO: {
          course: 'course',
          step: 'step',
          siteName: 'Camino University',
          siteURL: 'thegrid.caminouniversity.org',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        GRIDAU: {
          course: 'course',
          step: 'step',
          siteName: 'Ministry Grid',
          siteURL: 'ministrygrid.com.au',
          training: 'training',
          catalog: 'training',
          pathways: 'Pathways'
        },
        CONNEXUS: {
          course: 'course',
          step: 'step',
          siteName: 'Connexus Church Training',
          siteURL: 'training.connexuschurch.com',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        RCA: {
          course: 'course',
          step: 'step',
          siteName: 'RCA Church Multiplication Planter Training',
          siteURL: 'training.rca.org',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        CENTRIKID: {
          course: 'course',
          step: 'step',
          siteName: 'CentriKid Training Online',
          siteURL: 'training.centrikid.lifeway.com',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        FUGE: {
          course: 'course',
          step: 'step',
          siteName: 'FUGE Training Online',
          siteURL: 'training.fuge.lifeway.com',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        STUDENTLIFE: {
          course: 'course',
          step: 'step',
          siteName: 'Student Life Camp Training Online',
          siteURL: 'training.studentlife.lifeway.com',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        SBC: {
          course: 'course',
          step: 'step',
          siteName: 'SBC Training & Orientation Portal',
          siteURL: 'training.sbc.net',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
        A21: {
          course: 'course',
          step: 'step',
          siteName: 'A21 Training Center',
          siteURL: 'training.a21.org',
          training: 'training',
          catalog: 'training',
          pathways: 'Recommended Training'
        },
      };

    return function(key) {
      //Type checking to avoid non-strings
      if (typeof key !== 'string') return key;

      var originLabels = labels[origin] || {};
      return originLabels[key] || key;
    };
  },
]);
