import React, { useState, useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled, { createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import { Image, SixteenNinePlaceholderAnimation } from '../../components/Layout';
import { SmallTransparentRoundedIconButton } from '../../components/Buttons';
import { Grid, Type, Color } from '../../StyleGuide';
import VideoPlayer from '../../containers/VideoPlayer';
import { useUser } from '../../authentication';
import { carouselControls as CarouselControl } from '../../components/CarouselElements';
import { addRemovableEventListener, requestFullscreen } from '../../utils/domUtils';
import { isAudioExtension } from '../../utils/fileUtils';
import useDebounceCallback from '../../hooks/useDebounceCallback';

const KeyCodes = Object.freeze({
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
});

const BodyStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${Grid._4};
  position: absolute;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  z-index: 2;
  background: rgba(125, 125, 125, 0.7);
`;

const HeaderTitle = styled.p`
  margin: 0;
  padding: 0;
  color: ${Color.white};
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.semibold};
  text-align: left;
`;

const HeaderCount = styled.p`
  margin: 0;
  padding: 0;
  color: ${Color.white};
  text-align: left;
`;

const ImageContainer = styled.div`
  z-index: 1;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${Image} {
    background: transparent;
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`;

const VideoContainer = styled.div`
  max-width: 1280px;
  max-height: 720px;
  height: 100%;
  aspect-ratio: 16/9;
`;

const NavControls = styled.div`
  height: 0;
  width: 0;

  > button {
    position: absolute;
    opacity: 0.7;
    max-width: 75px;
    top: calc(50% - 60px);
    height: 120px;
    left: -4px;
    z-index: 2;

    &:disabled {
      display: none;
    }

    & + button {
      right: -4px;
      left: auto;
    }
  }
`;

const FullSizeMediaContainer = styled.div`
  background: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  cursor: ${props => (props.hideControls ? 'none' : 'initial')};

  .hideable-control {
    transition: all linear 400ms;
    opacity: ${props => (props.hideControls ? '0' : '1')};
  }
`;

const isIOS = () => {
  switch (window.navigator.platform) {
    case 'iPhone':
    case 'iPad':
    case 'iPod':
      return true;
    default:
      return false;
  }
};

const SectionMediaViewer = ({ mediaItems, startingMediaIndex, handleDismiss }) => {
  const { userId } = useUser();
  const ref = useRef();
  const idleDebounce = useDebounceCallback(3000);
  const [hideControls, setHideControls] = useState(false);
  const [videoRefreshKey, setVideoRefreshKey] = useState('');
  const [data, setData] = useState(() => ({
    isLoading: true,
    position: startingMediaIndex,
  }));

  const { isLoading, position } = data;
  const media = mediaItems[position];
  const isVideo = media._type === 'video';
  const decrementLimit = 0;
  const incrementLimit = mediaItems.length - 1;

  const setIsLoading = value => setData(prev => ({ ...prev, isLoading: value }));

  const updatePosition = (limit, amount) =>
    setData(prev => {
      if (prev.position === limit) return prev;

      return {
        ...prev,
        isLoading: true,
        position: prev.position + amount,
      };
    });

  const decrement = () => updatePosition(decrementLimit, -1);
  const increment = () => updatePosition(incrementLimit, 1);

  const onMouseMove = () => {
    if (hideControls) return setHideControls(false);
    idleDebounce(() => setHideControls(true));
  };

  const onVideoEnd = () => {
    // in iOS, after the video is complete, we need to show the navigation controls and
    // change the key of the video element to force it to re-initialize in order to play again.
    if (isIOS()) {
      // video-js has necessary cleanup logic that follows the triggering of the 'fullscreenchange'
      // event, this timeout allows that code run before we re-initialize the player
      setTimeout(() => {
        setHideControls(false);
        setVideoRefreshKey(Date.now());
      }, 100);
    }
  };

  useEffect(() => {
    requestFullscreen(ref.current);
    idleDebounce(() => setHideControls(true));

    ref.current.addEventListener('fullscreenchange', () => !document.fullscreenElement && handleDismiss());

    return addRemovableEventListener(document, 'keydown', e => {
      if (e.keyCode === KeyCodes.LEFT_ARROW) return decrement();
      if (e.keyCode === KeyCodes.RIGHT_ARROW) return increment();
    });
  }, []);

  useEffect(() => {
    const clearListener = addRemovableEventListener(ref.current, 'mousemove', onMouseMove);

    return () => {
      idleDebounce(null);
      clearListener();
    };
  }, [hideControls]);

  return createPortal(
    <FullSizeMediaContainer ref={ref} hideControls={hideControls}>
      <BodyStyle />
      <Header className="hideable-control">
        <div>
          <HeaderTitle>{media.title}</HeaderTitle>
          <HeaderCount>
            {position + 1} / {mediaItems.length}
          </HeaderCount>
        </div>
        <SmallTransparentRoundedIconButton onClick={handleDismiss}>
          <i className="fas fa-times"></i>
        </SmallTransparentRoundedIconButton>
      </Header>
      <ImageContainer key={position}>
        {isLoading && <SixteenNinePlaceholderAnimation background="#000" />}
        <>
          {isVideo ? (
            <VideoContainer style={{ display: isLoading ? 'none' : 'initial' }}>
              <VideoPlayer
                key={videoRefreshKey}
                setIsLoading={setIsLoading}
                onEnd={onVideoEnd}
                onExitFullscreen={onVideoEnd}
                autoExitFullscreen={false}
                videoId={media.id}
                userId={userId}
                defaultAudioOnly={isAudioExtension(media.ext)}
              />
            </VideoContainer>
          ) : (
            <Image
              src={media.url.original}
              altText={media.title}
              onLoad={() => setIsLoading(false)}
              style={{ display: isLoading ? 'none' : 'initial' }}
            />
          )}
        </>
      </ImageContainer>
      <NavControls className="hideable-control">
        <CarouselControl type="PREV" onClick={decrement} isEdge={position === decrementLimit} />
        <CarouselControl onClick={increment} isEdge={position === incrementLimit} />
      </NavControls>
    </FullSizeMediaContainer>,
    document.body
  );
};

SectionMediaViewer.propTypes = {
  mediaItems: PropTypes.array.isRequired,
  startingMediaIndex: PropTypes.number.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default SectionMediaViewer;
