angular.module('lwNamb').factory(
  'searchDetailService',

  [
    'searchService',
    'taskListService',
    'workflowService',
    'qt',
    'CacheFactory',
    function(searchService, taskListService, workflowService, qt, CacheFactory) {
      var cache = CacheFactory('searchDetail', {
        maxAge: 5 * 60 * 1000, // 5 minutes
        deleteOnExpire: 'aggressive',
      });

      return {
        getTrainingDetail: function(id, preview, userId) {
          var cached, deferred, combinedId = id + '_' + preview + '_' + userId;

          cached = cache.get(combinedId);
          if (cached) {
            deferred = qt.defer();
            deferred.resolve(cached);
            return deferred.promise;
          } else {
            return getTrainingDetail(id, preview, userId).then(function(result) {
              cache.put(combinedId, result);
              return result;
            });
          }
        },

        getTaskDetail: function(trainingId, taskIndex, preview, userId) {
          var deferred = qt.defer();

          this.getTrainingDetail(trainingId, preview, userId).then(function(result) {
            var tasks = [],
              task,
              wfTasklists = [],
              selectedTasklist,
              wfContext;

            try {
              if (result.isTasklist) {
                tasks = result.training.tasks;
              } else {
                wfTasklists = result.training.entities
                  .filter(function(e) {
                    return e._type === 'TaskList';
                  })
                  .map(function(e) {
                    return e.taskList;
                  });
                selectedTasklist = wfTasklists.find(function(tl) {
                  return tl.tasks.findIndex(findById(taskIndex)) !== -1;
                });
                if (selectedTasklist) {
                  tasks = selectedTasklist.tasks;
                  wfContext = {
                    tasklistName: selectedTasklist.name,
                    tasklists: wfTasklists.map(function(tl) {
                      return {
                        taskIndex: tl.tasks[0].taskIndex,
                        isSelected:
                          tl._id.id === selectedTasklist._id.id && tl.tasks.findIndex(findById(taskIndex)) > -1, //This ensures dupe TLs in a WF are only 'active' if you're on the task in THAT tl
                      };
                    }),
                  };
                }
              }

              task = tasks.find(findById(taskIndex));

              if (task) {
                deferred.resolve({
                  task: task,
                  context: {
                    trainingName: result.training.name,
                    isTasklist: result.isTasklist,
                    isWorkflow: result.isWorkflow,
                    tasks: tasksDataFrom(tasks, taskIndex, wfTasklists),
                    workflow: wfContext,
                    itemNumber: result.itemNumber
                  }
                });
              } else {
                deferred.reject('NOT_FOUND');
              }
            } catch (e) {
              deferred.reject(e);
            }
          });

          return deferred.promise;
        },

        clearCache: function() {
          cache.removeAll();
        },
      };

      function getTrainingDetail(id, preview, userId) {
        return searchService.findTraining(id).then(function(searchResult) {
          searchResult.isTasklist = searchResult.trainingType === 'Tasklist';
          searchResult.isWorkflow = searchResult.trainingType === 'Workflow';
          var getDetail = searchResult.isTasklist ? taskListService.getTasklist : workflowService.getWorkflowDetail;
          return getDetail(id, preview, userId).then(function(training) {
            if (searchResult.isTasklist) {
              training.tasks = training.tasks.map(function(task, index) {
                task.taskIndex = index;
                return task;
              });
            }
            searchResult.training = training;
            return searchResult;
          });
        });
      }

      function tasksDataFrom(tasks, taskIndex, tasklists) {
        var index, nextId, prevId, tasklistIndex;

        index = tasks.findIndex(findById(taskIndex));
        tasklistIndex = tasklists.findIndex(function(tl) {
          return tl.tasks.findIndex(findById(taskIndex)) !== -1;
        });
        tasklistIndex = tasklistIndex === -1 ? 0 : tasklistIndex;

        if (index < tasks.length - 1) {
          nextId = tasks[index + 1].taskIndex;
        } else if (tasklistIndex < tasklists.length - 1) {
          nextId = tasklists[tasklistIndex + 1].tasks[0].taskIndex;
        }

        if (index > 0) {
          prevId = tasks[index - 1].taskIndex;
        } else if (tasklistIndex > 0) {
          prevId = tasklists[tasklistIndex - 1].tasks[tasklists[tasklistIndex - 1].tasks.length - 1].taskIndex;
        }

        return {
          count: tasks.length,
          selected: index + 1,
          next: {
            exists: nextId !== undefined,
            index: nextId,
          },
          previous: {
            exists: prevId !== undefined,
            index: prevId,
          },
        };
      }

      function findById(taskIndex) {
        return function(task) {
          return task.taskIndex == taskIndex; //TODO: fix this as one is a String and the other a Number
        };
      }
    },
  ]
);
