import React from 'react';
import PropTypes from 'prop-types';

import TrainingDetails from '../components/TrainingDetails';
import ProgressBar from '../components/ProgressBar';
import DueDateModal from '../manageAssignments/DueDateModal';
import Steps from '../components/Steps';
import windowService from '../services/windowService';
import { Button, ButtonGroup, PrimaryButton } from '../components/Buttons';
import useModal from '../hooks/useModal';
import { purchaseLockedItem } from '../utils/purchaseUtils';
import alertService from '../services/AlertService';

const Course = ({
  course,
  description,
  assignedBy,
  assigneeId,
  isComplete,
  isCollapsed,
  showStart,
  showResume,
  hasCertificate,
  instanceId,
  assignmentLabel,
  retrieveData,
  reminderButton,
  purchaseRequired,
  updateCourse,
}) => {
  const [modal, openModal, dismissModal] = useModal((type, payload, dismissModal) => {
    if (!type) return;
    return (
      <DueDateModal
        handleDismiss={dismissModal}
        updateDueDate={payload}
        course={course}
        firstAllowedDate={new Date()}
        userId={assigneeId}
        retrieveData={retrieveData}
      />
    );
  });

  const purchaseLockedCourse = itemNumber => purchaseLockedItem(itemNumber) || alertService.showError();

  const downloadCertificate = (id, user) => {
    window.open(getApiUrl() + '/v1/files/certificates/' + id + '/' + user);
  };

  const resumeTraining = (instancedId, incompleteStep) => {
    const stepId = incompleteStep.id.id;
    windowService.redirectTo(`#/training-steps/${instancedId}?stepId=${stepId}`);
  };

  const updateDueDate = newDueDate =>
    updateCourse(previousCourse => {
      const { assignable } = previousCourse;
      if (assignable) {
        if (!newDueDate) {
          // eslint-disable-next-line no-unused-vars
          const { dueDate, ...restAssignable } = assignable;
          return { ...previousCourse, assignable: restAssignable };
        }
        return { ...previousCourse, assignable: { ...assignable, dueDate: newDueDate } };
      } else {
        if (!newDueDate) {
          // eslint-disable-next-line no-unused-vars
          const { dueDate, ...restPrev } = previousCourse;
          return restPrev;
        }
        return { ...previousCourse, dueDate: newDueDate };
      }
    });

  const progress = course.progress ? course.progress.steps.completed : 0;
  const steps = course.tasks;
  const incompleteStep = steps.find(step => step.status !== 'complete');
  return (
    <div>
      <TrainingDetails
        name={course.name}
        description={description || course?.description}
        label={'step'}
        units={course.tasks}
        unitsCompleted={progress}
        isComplete={isComplete}
        dueDate={course?.dueDate}
        timeCompleted={course.completedOn}
        assignedBy={assignedBy}
        timeAssigned={course?.assignedOn}
        selfAssigned={course?.selfAssigned}
        isCollapsed={isCollapsed}
      />
      <ProgressBar progress={course.progress.percent} className="tl-progress-bar" />

      {course._type === 'Course' && (
        <p className="collapsable-data">
          <i className="fas fa-info-circle" /> <span className="text-capitalize">Steps</span> will be unlocked upon
          completion of previous <span className="text-lowercase">{assignmentLabel}</span>{' '}
          {purchaseRequired && <span> and purchase</span>}
        </p>
      )}

      <ButtonGroup>
        {showStart && (
          <PrimaryButton
            data-qa-hook="assignedTaskListStart"
            onClick={() => resumeTraining(instanceId, incompleteStep)}
          >
            Start Training
          </PrimaryButton>
        )}

        {!isComplete && showResume && (
          <PrimaryButton
            data-qa-hook="assignedTaskListResume"
            onClick={() => resumeTraining(instanceId, incompleteStep)}
          >
            Resume Training
          </PrimaryButton>
        )}
        {isComplete && hasCertificate && (
          <PrimaryButton
            data-qa-hook="completedTaskListPanelCertificate"
            onClick={() => downloadCertificate(instanceId, assigneeId)}
          >
            <i className="fa fa-award" /> Download Certificate
          </PrimaryButton>
        )}
        {course.purchaseRequired && (
          <PrimaryButton onClick={() => purchaseLockedCourse(course.purchaseRequired)}>
            Purchase to Unlock
          </PrimaryButton>
        )}

        {course.progress._type !== 'Finished' && (
          <Button data-qa-hook="assignmentDetailTrainingDueDate" onClick={() => openModal('dueDate', updateDueDate)}>
            Schedule Due Date
          </Button>
        )}

        {reminderButton}
      </ButtonGroup>

      <Steps steps={course.tasks} training={course} />

      {modal}
    </div>
  );
};
Course.propTypes = {
  course: PropTypes.object.isRequired,
  redirectToTaskLists: PropTypes.func,
  description: PropTypes.string,
  assignmentLabel: PropTypes.string,
  assignedBy: PropTypes.string,
  assigneeId: PropTypes.string,
  isComplete: PropTypes.bool,
  redirectToTask: PropTypes.func,
  isCollapsed: PropTypes.bool,
  showStart: PropTypes.bool,
  showResume: PropTypes.bool,
  hasCertificate: PropTypes.object,
  instanceId: PropTypes.string,
  retrieveData: PropTypes.func,
  updateCourse: PropTypes.func,
  reminderButton: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};
export default Course;
