import uuid from './uuid';
import submitCommand from './submitCommand';
import apiClient from '../services/apiClient';
import WhiteLabel from '../filters/WhiteLabel';
import cacheableRequest from './cacheableRequest';

const addDefaultDetails = (
  section,
  title = 'Recommended Training by Topic',
  description = 'Our experts have curated the best training for each topic'
) => {
  if (section.title === undefined) section.title = title;
  if (section.description === undefined) section.description = description;
  return section;
};

const transformCatalog = catalog => {
  const featureCarouselSection = catalog.sections.find(section => section._type === 'FeatureCarousel');
  const trainingCompilationsSection = catalog.sections.find(section => section._type === 'Compilations');
  const trainingButtonsSection = addDefaultDetails(catalog.sections.find(section => section._type === 'Buttons'));
  const viewAllSection = catalog.sections.find(section => section._type === 'ViewAll');
  return {
    id: catalog._id,
    featureCarouselSection: featureCarouselSection,
    compilationsSection: trainingCompilationsSection,
    buttonsSection: trainingButtonsSection,
    viewAllSection: viewAllSection,
  };
};

const getAllCatalogs = () => {
  return cacheableRequest('/catalogs').then(response => response.data);
};

const getCatalog = () => {
  return getAllCatalogs().then(catalogs => {
    let c = catalogs.filter(catalog => catalog.shortCode === WhiteLabel.catalog.shortCode);
    return c.length ? c[0] : {};
  });
};

const catalogService = {
  getCatalogIdForSite: () => getCatalog().then(catalog => (catalog._id ? catalog._id.id : 'catalog-id-not-found')),
  getCatalogHomePage: () => {
    return apiClient
      .get('/catalog/pages/home')
      .then(response => transformCatalog(response.data))
      .catch(reason => reason);
  },
  setCatalogCompilations: (catalogId, sectionId, compilationIds, initiatingUserId) => {
    const cmd = { id: catalogId, sectionId, compilationIds, initiatingUserId };
    return submitCommand(cmd.id, cmd, 'CpSetCompilations', 'CpCompilationsSet', 'CpCommandFailed');
  },
  createCarouselItem: (catalogId, sectionId, image, link, text, atIndex, initiatingUserId) => {
    const cmd = { id: catalogId, sectionId, featureId: uuid.generate(), image, link, text, atIndex, initiatingUserId };
    return submitCommand(cmd.id, cmd, 'CpCreateFeature', 'CpFeatureCreated', 'CpCommandFailed');
  },
  updateCarouselItem: (catalogId, sectionId, featureId, image, link, text, atIndex, initiatingUserId) => {
    const cmd = { id: catalogId, sectionId, featureId, image, link, text, atIndex, initiatingUserId };
    return submitCommand(cmd.id, cmd, 'CpUpdateFeature', 'CpFeatureUpdated', 'CpCommandFailed');
  },
  removeCarouselItem: (catalogId, sectionId, featureId, initiatingUserId) => {
    const cmd = { id: catalogId, sectionId, featureId, initiatingUserId };
    return submitCommand(cmd.id, cmd, 'CpDeleteFeature', 'CpFeatureDeleted');
  },
  reorderFeatureCarouselItems: (catalogId, sectionId, itemIds, initiatingUserId) => {
    const cmd = { id: catalogId, sectionId, featureIds: itemIds, initiatingUserId };
    return submitCommand(cmd.id, cmd, 'CpOrderFeatures', 'CpFeaturesOrdered', 'CpCommandFailed');
  },
  createTrainingButton: (catalogId, sectionId, link, text, initiatingUserId) => {
    const cmd = { id: catalogId, sectionId, buttonId: uuid.generate(), link, text, initiatingUserId };
    return submitCommand(cmd.id, cmd, 'CpCreateButton', 'CpButtonCreated', 'CpCommandFailed');
  },
  updateTrainingButton: (catalogId, sectionId, buttonId, link, text, initiatingUserId) => {
    const cmd = { id: catalogId, sectionId, buttonId, link, text, initiatingUserId };
    return submitCommand(cmd.id, cmd, 'CpUpdateButton', 'CpButtonUpdated', 'CpCommandFailed');
  },
  removeTrainingButton: (catalogId, sectionId, buttonId, initiatingUserId) => {
    const cmd = { id: catalogId, sectionId, buttonId, initiatingUserId };
    return submitCommand(cmd.id, cmd, 'CpDeleteButton', 'CpButtonDeleted');
  },
  reorderTrainingButtons: (catalogId, sectionId, buttonIds, initiatingUserId) => {
    const cmd = { id: catalogId, sectionId, buttonIds, initiatingUserId };
    return submitCommand(cmd.id, cmd, 'CpOrderButtons', 'CpButtonsOrdered', 'CpCommandFailed');
  },
  updateSectionDetails: (catalogId, sectionId, name, shortDescription, initiatingUserId) => {
    const cmd = { id: catalogId, sectionId, title: name, description: shortDescription, initiatingUserId };
    return submitCommand(
      cmd.id,
      cmd,
      'CpSetSectionTitleAndDescription',
      'CpSectionTitleAndDescriptionSet',
      'CpCommandFailed'
    );
  },
};

export default catalogService;
