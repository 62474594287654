import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useQueryParams = (options = {}) => {
  const { pathname, search } = useLocation();
  const { replace } = useHistory();

  const queryParams = useMemo(() => {
    if (!options.preserveParams) {
      replace(pathname);
    }

    const searchParams = new URLSearchParams(search);
    return Object.fromEntries(searchParams.entries());
  }, []);

  return queryParams;
};

export default useQueryParams;
