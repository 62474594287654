angular.module('lwNamb').factory('workflowService', [
  '$rootScope',
  'api',
  'qt',
  'transformer',
  'uuid4',
  'commandSubmissionService',
  'taskListService',
  function($rootScope, api, qt, transformer, uuid4, commandSubmissionService, taskListService) {
    var timeoutSeconds = 15;
    var service = {
      getWorkflowInstanceDetailById: function(workflowId) {
        return api.get('/v1/user-workflows/' + workflowId + '/detail').then(function(response) {
          return transformer.transformWorkflowInstanceDetail(response.data);
        });
      },
      getWorkflowByInstanceId: function(wfInstanceId) {
        return api.get('/v1/user-workflows/' + wfInstanceId).then(function(response) {
          return transformer.transformWorkflowInstance(response.data);
        });
      },
      getWorkflows: function(orgId) {
        return getAndCacheWorkflows(orgId);
      },

      togglePublished: function(orgId, workflowId, published, initiatingUserId) {
        var cmd = { id: workflowId, initiatingUserId: initiatingUserId };
        if (published) {
          return commandSubmissionService.submitAndWait(
            workflowId,
            cmd,
            'PublishWorkflow',
            'WorkflowPublished',
            ['WorkflowError', 'WorkflowContainsUnpublishedEntities'],
            undefined,
            undefined,
            true
          );
        } else {
          return commandSubmissionService.submitAndWait(
            workflowId,
            cmd,
            'UnPublishWorkflow',
            'WorkflowUnPublished',
            ['WorkflowError', 'WorkflowContainsUnpublishedEntities'],
            undefined,
            undefined,
            true
          );
        }
      },
      getWorkflow: function(orgId, workflowId) {
        var deferred = qt.defer();
        getAndCacheWorkflows(orgId).then(
          function(workflows) {
            var workflow = workflows.find(function(w) {
              return w.id === workflowId;
            });
            if (workflow) {
              deferred.resolve(workflow);
            } else {
              deferred.reject('NOT_FOUND');
            }
          },
          function(reason) {
            deferred.reject(reason);
          }
        );
        return deferred.promise;
      },
      getWorkflowDetail: function(workflowId) {
        return api.get('/v1/workflows/' + workflowId + '/detail').then(function(response) {
          return transformer.transformWorkflow(response.data);
        });
      },
      getWorkflowById: function(workflowId) {
        return api.get('/v1/workflows/' + workflowId).then(function(response) {
          return transformer.transformWorkflow(response.data);
        });
      },
      getWorkflowEntity: function(orgId, workflowId, entityId) {
        var deferred = qt.defer();
        var entity;
        getAndCacheWorkflows(orgId).then(
          function(workflows) {
            var workflow = workflows.find(function(w) {
              return w.id === workflowId;
            });
            for (var t = 0; t < workflow.entities.length; t++) {
              if (workflow.entities[t].id === entityId) {
                entity = workflow.entities[t];
              }
            }
            if (entity) {
              deferred.resolve(entity);
            } else {
              deferred.reject('NOT_FOUND');
            }
          },
          function(reason) {
            deferred.reject(reason);
          }
        );
        return deferred.promise;
      },
      addEmailToWorkflow: function(workflowId, subject, body, buttonText, previousId, nextId, initiatingUserId) {
        var step = {
          id: uuid4.generateId().id,
          subject: subject,
          body: body || '',
          buttonText: buttonText,
          _type: 'Email',
        };
        return addStepToWorkflow(step, workflowId, previousId, nextId, initiatingUserId);
      },
      addTaskListsToWorkflow: function(workflowId, taskListIdsArray, previousId, nextId, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
        var taskListIds = !Array.isArray(taskListIdsArray) ? [taskListIdsArray] : taskListIdsArray;
        var steps = taskListIds.map(function(taskListId) {
          return {
            id: uuid4.generateId().id,
            taskListId: {
              id: taskListId,
            },
            _type: 'TaskList',
          };
        });
        function work() {
          return steps.reduce(
            function(promise, step, i) {
              var previous = i === 0 ? previousId : steps[i - 1].id;
              if (i === 0) {
                return addStepToWorkflow(step, workflowId, previous, nextId, initiatingUserId);
              } else {
                return promise.then(function() {
                  return addStepToWorkflow(step, workflowId, previous, nextId, initiatingUserId);
                });
              }
            },
            Promise.resolve(),
            0
          );
        }
        work().then(function() {
          deferred.resolve(workflowId);
        });

        return deferred.promise;
      },
      updateName: function(workflowId, name, orgId, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        api
          .post('/v1/commands/UpdateWorkflowName', {
            id: workflowId,
            name: name,
            owner: orgId,
            initiatingUserId: initiatingUserId,
          })
          .then(null, function(reason) {
            deferred.reject(reason);
          });

        $rootScope.$on('WorkflowNameChanged', function(name, event) {
          if (event.id === workflowId) {
            deferred.resolve();
          }
        });

        $rootScope.$on('WorkflowNameDuplicateError', function(name, event) {
          if (event.id === workflowId) {
            deferred.reject('DUPLICATE_NAME');
          }
        });

        return deferred.promise;
      },
      updateDescription: function(workflowId, description, initiatingUserId) {
        var cmd = { id: workflowId, description: description, initiatingUserId: initiatingUserId };
        return commandSubmissionService.submitAndWait(
          workflowId,
          cmd,
          'SetWorkflowDescription',
          'WorkflowDescriptionSet',
          'WorkflowError'
        );
      },
      updateWorkflowEmail: function(workflowId, step) {
        step.type = 'Email';
        return replaceStepInWorkflow(workflowId, step);
      },
      removeStepFromWorkflow: function(workflowId, entityId, initiatingUserId) {
        return removeStepFromWorkflow(workflowId, entityId, initiatingUserId);
      },
      cloneWorkflow: function(workflowId, workflowName, user) {
        var gettingWorkflow = false;
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
        var newId = uuid4.generateId().id;
        api
          .post('/v1/commands/CopyWorkflow', {
            id: newId,
            fromId: workflowId,
            newName: workflowName,
            initiatingUserId: user.userId,
            ownerId: user.lastSelectedAccount,
          })
          .then(null, function(error) {
            deferred.reject(error);
          });
        $rootScope.$on('WorkflowNameDuplicateError', function(name, event) {
          if (event.id === newId && !deferred.promise.isResolved()) {
            deferred.reject('DUPLICATE_NAME');
          }
        });
        $rootScope.$on('WorkflowNameChanged', function(name, event) {
          if (event.id === newId && event.name === workflowName && !deferred.promise.isResolved()) {
            // check for success by polling workflows b/c cloning can be too fast
            deferred.promise.then(null, null, function(elapsedSeconds) {
              if (elapsedSeconds > 1 && !gettingWorkflow) {
                gettingWorkflow = true;
                getAndCacheWorkflows(user.lastSelectedAccount).then(
                  function(workflows) {
                    workflows.forEach(function(wf) {
                      if (wf.id === newId && wf.name === workflowName) {
                        deferred.resolve(newId);
                      }
                    });
                    gettingWorkflow = false;
                  },
                  function() {
                    gettingWorkflow = false;
                  }
                );
              }
            });
          }
        });
        return deferred.promise;
      },
      findWorkflowsContaining: function(taskListId, orgId) {
        return api.get('/v1/workflows/contains/'+taskListId+'/'+orgId).then(function(response) {
          return response.data;
        });
      },
      replace: function(workflowId, taskListId, newTL, initiatingUserId, orgId) {
        return removeAndAdd(workflowId, taskListId, newTL, initiatingUserId, orgId, false);
      },
      cloneTaskListAndReplace: function(workflowId, taskListId, initiatingUserId, orgId) {
        return removeAndAdd(workflowId, taskListId, undefined, initiatingUserId, orgId, true);
      },
      reorderWorkflowEntities: function(workflowId, entityIds, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        api
          .post('/v1/commands/ReorderWorkflowEntities', {
            id: workflowId,
            entityIds: entityIds,
            initiatingUserId: initiatingUserId,
          })
          .then(null, function failure(reason) {
            deferred.reject(reason);
          });
        $rootScope.$on('WorkflowEntitiesReordered', function(name, event) {
          if (event.id === workflowId && !deferred.promise.isResolved()) {
            deferred.resolve();
          }
        });

        return deferred.promise;
      },

      deleteWorkflow: function(workflowId, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
        api
          .post('/v1/commands/DeleteWorkflow', {
            id: workflowId,
            initiatingUserId: initiatingUserId,
          })
          .then(null, function failure(reason) {
            deferred.reject(reason);
          });
        $rootScope.$on('WorkflowDeleted', function(name, event) {
          if (event.id === workflowId) {
            deferred.resolve();
          }
        });

        return deferred.promise;
      },

      createWorkflow: function(name, ownerId, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
          id = uuid4.generateId().id;

        api
          .post('/v1/commands/CreateWorkFlow', {
            id: id,
            initiatingUserId: initiatingUserId,
            name: name,
            ownerId: ownerId,
          })
          .then(null, function failure(reason) {
            deferred.reject(reason);
          });

        $rootScope.$on('WorkflowCreated', function(name, event) {
          if (event.id === id) {
            deferred.resolve(id);
          }
        });

        $rootScope.$on('WorkflowNameDuplicateError', function(event_label, event) {
          if (event.id === id) {
            deferred.reject('DUPLICATE_NAME');
          }
        });

        return deferred.promise;
      },
    };

    return service;

    function getAndCacheWorkflows(orgId) {
      return api.get('/v1/workflows?orgId=' + orgId).then(function(response) {
        return transformer.transformWorkflows(response.data.workflows);
      });
    }

    function removeAndAdd(workflowId, taskListId, newTL, initiatingUserId, orgId, clone){
      //  0. get workflow and find task list entity
      //  1. Clone existing task list in a workflow (taskListService.clone — clone task list into org)
      //  2. Remove existing task list from the workflow (workflowService.removeStepFromWorkflow — preserve order/index)
      //  3. Add new(cloned) task list to workflow (workflowService.addStepToWorkflow — in correct spot)

      return service.getWorkflowById(workflowId).then(function(workflow) {
        var entity, index, previousId, nextId;
        entity = workflow.entities.find(function(e) {
          return e._type === 'TaskList' && e.taskListId.id === taskListId;
        });
        var p = qt.defer();
        if(clone === true){
          var newId = uuid4.generateId().id;
          p = taskListService.clone(newId, taskListId, entity.name, initiatingUserId, orgId);
        } else {
          p.resolve();
          p = p.promise;
        }
        return p.then(function(tlId) {
            return removeStepFromWorkflow(workflowId, entity.id, initiatingUserId).then(function() {
              var step = {
                id: uuid4.generateId().id,
                taskListId: {
                  id: clone === true ? tlId : newTL,
                },
                _type: 'TaskList',
              };
              index = workflow.entities.indexOf(entity);
              previousId = workflow.entities[index - 1].id;
              nextId = workflow.entities[index + 1].id;
              return addStepToWorkflow(step, workflowId, previousId, nextId, initiatingUserId).then(function() {
                return tlId;
              });
            });
          });
      });
    }

    function removeStepFromWorkflow(workflowId, entityId, initiatingUserId) {
      var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

      api
        .post('/v1/commands/RemoveStepFromWorkflow', {
          id: workflowId,
          entityId: entityId,
          initiatingUserId: initiatingUserId,
        })
        .then(null, function failure(reason) {
          deferred.reject(reason);
        });
      $rootScope.$on('StepIsRemovedFromWorkflow', function(name, event) {
        if (event.id === workflowId && !deferred.promise.isResolved()) {
          deferred.resolve(workflowId);
        }
      });

      return deferred.promise;
    }

    function addStepToWorkflow(step, workflowId, previousId, nextId, initiatingUserId) {
      var deferred = qt.defer({
        timeoutSeconds: timeoutSeconds,
      });

      api
        .post('/v1/commands/InsertStepToWorkflow', {
          id: workflowId,
          step: step,
          previous: previousId,
          next: nextId,
          initiatingUserId: initiatingUserId,
        })
        .then(null, function failure(reason) {
          deferred.reject(reason);
        });

      $rootScope.$on('StepIsInsertedToWorkflow', function(name, event) {
        if (event.id === workflowId && !deferred.promise.isResolved()) {
          deferred.resolve(workflowId);
        }
      });

      return deferred.promise;
    }

    function replaceStepInWorkflow(workflowId, step) {
      var deferred = qt.defer({
        timeoutSeconds: timeoutSeconds,
      });

      api
        .post('/v1/commands/ReplaceStepInWorkflow', {
          id: workflowId,
          step: {
            id: step.id,
            subject: step.subject,
            body: step.body,
            buttonText: step.buttonText,
            _type: step.type,
          },
        })
        .then(null, function failure(reason) {
          deferred.reject(reason);
        });

      $rootScope.$on('StepIsReplacedInWorkflow', function(name, event) {
        if (event.id === workflowId && !deferred.promise.isResolved()) {
          deferred.resolve(workflowId);
        }
      });

      return deferred.promise;
    }
  },
]);
