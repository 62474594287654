angular
  .module('lwNamb')

  // wraps $q.defer() and adds an optional built-in timeout
  .factory(
    'qt',

    [
      '$q',
      '$timeout',
      function($q, $timeout) {
        return {
          defer: function(options) {
            var deferred,
              timeout,
              timeoutSeconds,
              notifyTimeout,
              isResolved = false;

            timeoutSeconds = options && typeof options.timeoutSeconds == 'number' ? options.timeoutSeconds : 0;
            if (timeoutSeconds > 0) {
              timeout = $timeout(function() {
                deferred.reject('Timeout reached: ' + timeoutSeconds + ' seconds');
                isResolved = true;
                $timeout.cancel(notifyTimeout);
              }, timeoutSeconds * 1000);
            }

            deferred = $q.defer();

            notifyElapsedSeconds(0);

            deferred.promise.isResolved = function() {
              return isResolved;
            };

            return {
              promise: deferred.promise,
              resolve: function(response) {
                if (timeout) {
                  $timeout.cancel(timeout);
                }
                $timeout.cancel(notifyTimeout);
                deferred.resolve(response);
                isResolved = true;
              },
              reject: function(reason) {
                if (timeout) {
                  $timeout.cancel(timeout);
                }
                $timeout.cancel(notifyTimeout);
                deferred.reject(reason);
                isResolved = true;
              },
              notify: function(progress) {
                deferred.notify(progress);
              },
            };

            function notifyElapsedSeconds(seconds) {
              notifyTimeout = $timeout(function() {
                deferred.notify(seconds + 1);
                notifyElapsedSeconds(seconds + 1);
              }, 1000);
            }
          },
          all: $q.all,
        };
      },
    ]
  );
