import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../components/ModalElements';
import { MediumButton, MediumDangerButton } from '../components/Buttons';

import alertService from '../services/AlertService';
import assessmentService from '../services/assessmentService';

import { useUser } from '../authentication';

const RemoveQuestionModal = ({ assessmentId, question, setRemoveQuestionModalOpen, setAssessment }) => {
  const user = useUser();
  const [status, setStatus] = useState({ isSubmitting: false });

  const removeQuestion = () => {
    setStatus({ isSubmitting: true });
    assessmentService
      .removeQuestion(assessmentId, question.id.id, user.userId)
      .then(res => {
        setAssessment(prev => ({
          ...prev,
          questions: prev.questions.filter(q => q.id.id !== res.questionId.id),
        }));
        alertService.show('Question Removed');
        setRemoveQuestionModalOpen(false);
      })
      .catch(error => {
        console.error(error);
        alertService.showError('Error when removing, please try again.');
        setRemoveQuestionModalOpen(false);
      });
  };

  return (
    <ModalContainer dismissHandler={() => setRemoveQuestionModalOpen(false)}>
      <ModalHeader data-qa-hook="removeQuestionModal">
        <h3>Remove Question</h3>
      </ModalHeader>
      <ModalBody>
        <p>Are you sure you want to remove this question from the assessment?</p>
        <b data-qa-hook="removeQuestionName">{question.questionValue[0].value}</b>
        <p className="help-block">
          <i className="icon ion-android-alert"></i> This action cannot be undone
        </p>
      </ModalBody>
      <ModalFooter>
        <MediumDangerButton
          data-qa-hook="continue"
          disabled={status.isSubmitting}
          operating={status.isSubmitting}
          onClick={() => removeQuestion()}
        >
          Yes, Remove
        </MediumDangerButton>
        <MediumButton data-qa-hook="cancel" onClick={() => setRemoveQuestionModalOpen(false)}>
          Cancel
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

RemoveQuestionModal.propTypes = {
  setRemoveQuestionModalOpen: PropTypes.func.isRequired,
  setAssessment: PropTypes.func.isRequired,
  assessmentId: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
};

export default RemoveQuestionModal;
