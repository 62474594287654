import React from 'react';
import styled from 'styled-components';
import { Grid, Color } from '../StyleGuide';

interface Props {
  src?: string;
  size?: string;
}

const CircularProfileImage = styled.img<Props>`
  border-radius: 50%;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  width: ${props => props.size} !important;
  height: ${props => props.size} !important;
  object-fit: cover;
`;

const DefaultProfileImage = styled.span.attrs({
  className: 'fas fa-user-circle',
})<Props>`
  font-size: ${props => props.size};
  width: ${props => props.size};
  height: ${props => props.size};
  color: ${Color.Blue._15};
`;

export default function ProfileImage({ src, size = Grid._10 }: Props) {
  return src ? <CircularProfileImage src={src} size={size} /> : <DefaultProfileImage size={size} />;
}
