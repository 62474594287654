import React from 'react';
import { Container, RaisedContainer } from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import PeopleOnLicensesList from './components/LicensesList';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import GroupContainer from '../groups/GroupContainer';
import { useFetchLicenses } from './hooks/useFetchLicenses';
import useTextFilter from '../hooks/useTextFilter';
import { useParams } from 'react-router-dom';
import SettingsHeader from '../components/SettingsHeader';
import alertService from '../services/AlertService';
import { handleError } from '../utils/apiUtils';

export function ManageLicenses() {
  const { licenseId } = useParams<{ licenseId: string }>();
  const { licenses, isLoading, error, removePerson } = useFetchLicenses(licenseId);
  const { results, query, setQuery } = useTextFilter(licenses?.users || [], [
    'displayName',
    'firstName',
    'lastName',
    'email',
  ]);

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Purchases and Subscriptions', route: '#/org/purchases-and-subscriptions' },
    { name: licenses?.name },
  ];

  function handleRemovePerson(userId: string) {
    removePerson(userId)
      .then(e => alertService.show('Person Removed'))
      .catch(handleError);
  }

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container data-qa-hook="subscriptionsView">
        <div className="mt-32 mb-32">
          <SettingsHeader
            title={licenses?.name || ''}
            subtitle="Licenses"
            icon={<i className="fas fa-address-card fa-fw" />}
          />
        </div>

        <RaisedContainer>
          <GroupContainer>
            {isLoading ? (
              <LoadingState />
            ) : error ? (
              <ErrorMessage />
            ) : (
              <PeopleOnLicensesList
                allLicenses={results || []}
                isUpdating={false}
                query={query}
                setQuery={setQuery}
                handleRemovePerson={handleRemovePerson}
              />
            )}
          </GroupContainer>
        </RaisedContainer>
      </Container>
    </>
  );
}
