angular.module('lwNamb').directive(
  'curriculum',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/curriculum.html',
      };
    },
  ]
);
