angular.module('lwNamb').factory(
  'userService',

  [
    '$rootScope',
    'api',
    'qt',
    'CacheFactory',
    'jwt',
    'uuid4',
    'commandSubmissionService',
    '$log',
    '$localStorage',
    '$sessionStorage',
    'ssoService',
    'catalogService',
    'apiUrlService',
    '$window',
    function (
      $rootScope,
      api,
      qt,
      CacheFactory,
      jwt,
      uuid4,
      commandSubmissionService,
      $log,
      $localStorage,
      $sessionStorage,
      ssoService,
      catalogService,
      apiUrlService,
      $window
    ) {
      var timeoutSeconds = 15,
        userSessionCache = CacheFactory('localSessionUser', {
          maxAge: 3600000,
          deleteOnExpire: 'aggressive',
          storageMode: 'localStorage',
        }),
        userOrgs = CacheFactory('userOrgs', {
          maxAge: 60000,
          deleteOnExpire: 'aggressive',
          storageMode: 'localStorage',
        }),
        pendingUserPromise;

      function check() {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        var token = jwt.get();
        if (token === null || token === undefined) {
          forgetUser();
          deferred.reject();
        } else {
          api.get('/v1/security').then(
            function success(response) {
              return setUser(deferred, response);
            },
            function failure(reason) {
              forgetUser();
              deferred.reject(reason);
            }
          );
        }

        return deferred.promise;
      }

      function setUser(deferred, response) {
        if (response.headers && response.headers('Set-Authorization')) {
          var token = response.headers('Set-Authorization');
          var session = jwt.read(token);

          // Validate it just in case the server would have send something fishy
          if (jwt.validate(session)) {
            // Save it in the storage so that we don't lose
            // if the user refresh the page
            jwt.keep(token);
            // Synchronize if with the current session

            var transformedUser = transformAndCacheUser(response.data);
            deferred.resolve(transformedUser);
          } else {
            // If not valid, let's just logout
            deferred.reject('Something is wrong with the jwt');
          }
        } else {
          forgetUser();
          deferred.reject('No Authorization header');
        }
      }
      function forgetUser() {
        jwt.forget();
      }
      function redirectForAuthorization(url, payload, redirectUrl, login_hint) {
        if (login_hint) {
          payload.login_hint = login_hint;
        }
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
        saveState(redirectUrl, payload.state);
        api.post(url, payload).then(
          function (result) {
            deferred.resolve(result.data);
          },
          function (result) {
            deferred.reject(result);
          }
        );
        return deferred.promise;
      }

      function saveState(redirectUrl, state) {
        var previousRedirect = userSessionCache.get('redirectUrl');
        userSessionCache.removeAll();
        userSessionCache.put('state', state || uuid4.generate());
        if (previousRedirect === undefined || (redirectUrl && redirectUrl.indexOf('route') > -1))
          userSessionCache.put('redirectUrl', redirectUrl);
        else userSessionCache.put('redirectUrl', previousRedirect);
        $localStorage.user = undefined;
        forgetUser();
      }

      return {
        getRedirectHashAfterLogin: function (user) {
          var hash = '/dashboard';
          // Ideally, it should return whatever hash is included in user.redirectURL
          // However, due to many Controllers depending on this method returning "account"
          // I'm leaving as it is and will just return hash for /onboarding for now
          if (user && user.redirectURL && user.redirectURL.indexOf('onboarding') > -1) {
            hash = user.redirectURL.split('#')[1];
          } else if (user && user.redirectURL && user.redirectURL.indexOf('#/select-org-and-assign/') > -1) {
            hash = user.redirectURL.split('#')[1];
          } else if ($localStorage.syncOrg && $rootScope.origin === 'TEXASBAPTISTS') {
            $rootScope.$broadcast('SYNC_WHITELABEL_ORG');
          } else if ($localStorage.newHash) {
            hash = $localStorage.newHash;
          } else {
            hash = '/dashboard';
          }
          return hash;
        },
        redirectToLogin: function (host, redirectUrl, login_hint) {
          return redirectForAuthorization(
            '/v1/sign-in',
            { host: host, state: uuid4.generate() },
            redirectUrl,
            login_hint
          );
        },
        redirectToRegister: function (host, redirectUrl, login_hint) {
          return redirectForAuthorization(
            '/v1/register',
            { host: host, state: uuid4.generate() },
            redirectUrl,
            login_hint
          );
        },
        redirectToLoginV2: function (redirectUrl, login_hint) {
          if (!ssoService.getIsSSO()) return this.redirectToLogin($window.location.host, redirectUrl, login_hint);

          saveState(redirectUrl);
          var extraQueryParams = login_hint ? { extraQueryParams: { login_hint: login_hint } } : '';
          return ssoService.signIn(extraQueryParams);
        },
        redirectToRegisterV2: function (redirectUrl, login_hint) {
          if (!ssoService.getIsSSO()) return this.redirectToRegister($window.location.host, redirectUrl, login_hint);

          saveState(redirectUrl);
          var extraQueryParams = login_hint
            ? { extraQueryParams: { auth_method: 'register', login_hint: login_hint } }
            : { extraQueryParams: { auth_method: 'register' } };
          return ssoService.signIn(extraQueryParams);
        },
        authorizeV2: function (token, host) {
          return auth('/v2/authorize', { token: token, host: host });
        },
        authorize: function (code, state, host) {
          return auth('/v1/authorize', { code: code, host: host }, state);
        },
        logout: function (saveLocation, location) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          function final() {
            //place it after the call in order to send the jwt to the server for authentication
            $localStorage.user = undefined;
            userSessionCache.removeAll();
            if (saveLocation) userSessionCache.put('redirectUrl', location);
            jwt.forget();
            userOrgs.removeAll();
            $sessionStorage.lastAddMaterialSelections = undefined;
          }

          if ($localStorage.user !== undefined) {
            api
              .get('/v1/logout')
              .then(
                function success(response) {
                  deferred.resolve(response);
                },
                function failure(reason) {
                  deferred.reject(reason);
                }
              )
              .finally(function () {
                final();
              });
          } else {
            final();
            deferred.resolve();
          }

          return deferred.promise;
        },
        switchOrg: function (userId, orgId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          api
            .post('/v1/commands/SelectAccount', {
              id: userId,
              accountId: orgId,
            })
            .then(null, function (reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('AccountSelected', function (name, event) {
            deferred.resolve();
          });

          return deferred.promise;
        },
        checkSession: function () {
          return check();
        },
        impersonateUser: function (email) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          $localStorage.user = undefined;

          api.post('/v1/impersonate?email=' + email).then(
            function success(response) {
              return setUser(deferred, response);
            },
            function failure(reason) {
              deferred.reject(reason);
            }
          );

          return deferred.promise;
        },
        user: function () {
          if (pendingUserPromise) {
            return pendingUserPromise;
          }
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
          if ($localStorage.user === undefined) {
            pendingUserPromise = deferred.promise;
            check().then(
              function (user) {
                deferred.resolve(user);
                pendingUserPromise = null;
              },
              function (reason) {
                deferred.reject(reason);
                pendingUserPromise = null;
              }
            );
          } else {
            var token = jwt.get();
            if (token === null || token === undefined) {
              $rootScope.$broadcast('NoUserFound');
              deferred.reject();
            } else {
              deferred.resolve($localStorage.user);
            }
          }
          return deferred.promise;
        },
        lookupUserOrgs: function (filterOnlyAdmin) {
          // todo: remove param
          filterOnlyAdmin = filterOnlyAdmin || false;
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
          if (userOrgs.get(filterOnlyAdmin.toString()) !== undefined) {
            deferred.resolve(userOrgs.get(filterOnlyAdmin.toString()));
          } else {
            api.get('/v2/user/organizations').then(
              function success(response) {
                response.data.forEach(function (org) {
                  var isAdmin = false;
                  org.memberRoles.forEach(function (role) {
                    role.permission.permissions.forEach(function (permission) {
                      if (permission.access === 'Account View') {
                        isAdmin = true;
                      }
                    });
                  });
                  org.isAdmin = isAdmin;
                });
                if (filterOnlyAdmin === true) {
                  var adminOrgs = [];
                  response.data.forEach(function (org) {
                    if (org.isAdmin) {
                      adminOrgs.push(org);
                    }
                  });
                  userOrgs.put(filterOnlyAdmin.toString(), adminOrgs);
                  deferred.resolve(adminOrgs);
                } else {
                  userOrgs.put(filterOnlyAdmin.toString(), response.data);
                  deferred.resolve(response.data);
                }
              },
              function failure(reason) {
                deferred.reject(reason);
              }
            );
          }
          return deferred.promise;
        },
        sendFeedbackEmail: function (
          userId,
          name,
          email,
          subject,
          description,
          callbackHost,
          orgName,
          browserInfo,
          initiatingUserId,
          gitHash
        ) {
          var id = uuid4.generate();
          var cmd = {
            id: id,
            userId: userId,
            name: name,
            email: email,
            subject: subject,
            description: description,
            callbackHost: callbackHost,
            orgName: orgName,
            browserInfo: browserInfo + ' gitHash: ' + gitHash,
            initiatingUserId: initiatingUserId,
          };
          return commandSubmissionService.submitAndWait(
            id,
            cmd,
            'SendFeedbackEmail',
            'FeedbackEmailSent',
            'EmailError'
          );
        },
        clearRedirectUrl: function () {
          userSessionCache.remove('redirectUrl');
          $localStorage.user.redirectUrl = undefined;
        },
        getAllPermissions: function () {
          return api.get('/v1/users/permissions').then(function (r) {
            return r.data;
          });
        },
      };

      function transformAndCacheUser(response) {
        var user = {};
        user.loggedIn = true;
        user.displayName = response.displayName;
        user.firstName = response.firstName;
        user.lastName = response.lastName;
        user.logInEmail = response.email;
        user.roles = response.roles;
        user.impersonating = response.impersonating;
        user.impersonatingWas = response.impersonatingWas;
        user.ssoImpersonation = response.ssoImpersonation;
        user.redirectURL = userSessionCache.get('redirectUrl');
        var perms = [];
        if (response.roles) {
          user.typeClass = 'primary';
          response.roles.forEach(function (role) {
            if (role.name === 'Admin') {
              user.typeClass = 'success';
            }
            if (role.permissions) {
              role.permissions.forEach(function (perm) {
                perms.push(perm.access);
              });
            }
          });
        }
        user.permissions = perms.filter(onlyUnique);
        user.userId = response.userId;
        //Removed until we have an exclusive update API call for user.pristine
        user.pristine = response.firstLogin;
        user.lastSelectedAccount = response.lastSelectedAccount;
        user.author = response.catalogs;

        if (response.catalogs && response.catalogs.length) {
          var siteShortCode = catalogService.getShortCode(apiUrlService.getOrigin());
          user.isAuthor = !!response.catalogs.find(function (catalog) {
            return catalog.shortCode === siteShortCode;
          });
        } else {
          user.isAuthor = false;
        }

        user.businessPartnerSources = response.businessPartnerSources || [];

        $localStorage.user = user;
        $rootScope.$broadcast('UserLoggedIn', user); //leave last so anything that checks the userService will be setup properly

        return user;
      }

      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

      function auth(path, cmd, state) {
        var deferred = qt.defer({ timeoutSeconds: 20 });
        //we return the cached user because the app make multiple authorize calls that we can't explain
        if ($localStorage.user === undefined) {
          if (state === undefined || state === userSessionCache.get('state')) {
            api.post(path, cmd).then(
              function (response) {
                return setUser(deferred, response);
              },
              function (result) {
                forgetUser();
                deferred.reject(result);
              }
            );
          } else {
            deferred.reject('state from authorization was not the one we sent');
          }
        } else {
          deferred.resolve($localStorage.user);
        }

        return deferred.promise;
      }
    },
  ]
);
