angular.module('lwNamb').directive(
  'loadingTaskViewer',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/loading-task-viewer.html',
      };
    },
  ]
);
