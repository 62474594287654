import { addDays, formatISO, parseISO, startOfDay, startOfToday } from 'date-fns';

export const stripUTC = dateString => dateString.replace('[UTC]', '');

export const ymdFromDate = date => !!date && !!date.toISOString && date?.toISOString()?.split('T')[0];

export const getDateFromDateTime = dateString => dateString.split('T')[0];

export const toLocalMidnight = dateString => `${getDateFromDateTime(dateString)}T00:00:00`;

export const parseDate = date => (typeof date === 'string' ? parseISO(date) : date);

export const isWithinNumDays = (date, numDays) => {
  const parsedDate = parseDate(date);
  const today = startOfToday();
  return parsedDate >= today && parsedDate < startOfDay(addDays(today, numDays));
};

export const isWithinOneWeek = date => isWithinNumDays(date, 7);

export const formatMilliseconds = ms => {
  const calc = x => Math.floor((ms / (1000 * x)) % 60);

  const parts = [];

  const hours = calc(60 * 60);
  if (hours) parts.push(`${hours}`);

  const minutes = calc(60);
  parts.push(`${hours && minutes < 10 ? '0' : ''}${minutes}`);

  const seconds = calc(1);
  parts.push(`${seconds < 10 ? '0' : ''}${seconds}`);

  return parts.join(':');
};

export const formatISODate = date => formatISO(date, { representation: 'date' });

/**
 * @param {string} ymd
 * @returns {string}
 */
export const ymdToUTCString = ymd => `${ymd.split('T')[0]}T00:00:00.000Z`;
