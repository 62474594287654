import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Type } from '../StyleGuide';
import { Button } from '../components/Buttons';
import InfoBox from '../components/InfoBox';

const Header = styled.div`
  padding-top: ${Grid._5};
  h3 {
    font-size: ${Type.Scale._5};
    margin: 0 auto ${Grid._5};
  }
  p {
    margin-bottom: ${Grid._2};
  }
`;

export default function Confirmation(props) {
  const [info, toggleInfo] = useState(false);
  const [isSettingUpChurch, setIsSettingUpChurch] = useState(false);
  const { emailAddress, churchName } = props.user;

  function handleToggle(event) {
    event.preventDefault();
    event.stopPropagation();
    toggleInfo(!info);
  }

  function handleOnClick(event) {
    event.preventDefault();
    event.stopPropagation();
    setIsSettingUpChurch(true);
    props.handleChurchSetupRequest(emailAddress, churchName);
  }

  return (
    <React.Fragment>
      <Header>
        <p data-qa-hook="confirmationHeader">You created</p>
        <h3 data-qa-hook="confirmationChurchName">{churchName}</h3>
      </Header>
      <p>
        To assign Church Cares training, we’ll need to secure your information with a Lifeway Id and password. As soon
        as you log in, you’ll be able to add people to your church and assign them training.
      </p>
      <InfoBox>
        <p>
          <a onClick={handleToggle}>What is a Lifeway Id?</a>
        </p>
        {info && (
          <p>
            Your Lifeway Id is an email address and password. You can use a Lifeway Id to log in at Lifeway.com,
            Ministry Grid, and other Lifeway’s sites.
          </p>
        )}
      </InfoBox>
      <Button
        data-qa-hook="nextButton"
        disabled={isSettingUpChurch}
        operating={isSettingUpChurch}
        onClick={handleOnClick}
      >
        Next
      </Button>
    </React.Fragment>
  );
}
