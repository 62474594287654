import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Breakpoints } from '../StyleGuide';
import styled from 'styled-components';
import LoadingState from '../components/LoadingState';
import { Document, Page, pdfjs } from 'react-pdf';
import curriculumService from '../services/curriculumService';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdfjs/build/pdf.worker.min.js';

const Container = styled.div`
  margin: ${Grid._4} 0;
  @media screen and (min-width: ${Breakpoints.screen_lg}) {
    margin: ${Grid._6} 0;
  }

  .react-pdf__Page {
    padding: ${Grid._3} 0;

    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
`;

// // Useful Debugging Utils
// // time from initial load of file
// const startTime = Date.now();
// const logDurationSince = (msg, start = startTime) => console.log(msg, (Date.now() - start) / 1000);
// const logPageLoaded = (num) => logDurationSince(`Page ${num} loaded`);
// const logPageRendered = (num) => logDurationSince(`Page ${num} rendered`);

// // records time from initial call to call of returned function
// // useful for determining single item load/render times
// const logDuration = (msg) => {
//   const start = Date.now();
//   return () => logDurationSince(msg, start);
// }

const PDF = React.memo(function PDF({ requestObject, onReadyToDisplay }) {
  const [pages, setPages] = useState([]);
  const ref = useRef();

  const onDocumentLoaded = ({ numPages }) => {
    setPages(
      pages.concat(
        Array.from(Array(numPages)).map((el, i) => (
          <Page
            key={i}
            pageIndex={i}
            width={ref.current.clientWidth}
            renderAnnotationLayer={false}
            renderInteractiveForms={false}
            onRenderSuccess={() => {
              if (i === 0) onReadyToDisplay();
            }}
          />
        ))
      )
    );
  };

  return (
    <Document
      options={{ cMapUrl: '/pdfjs/cmaps/', cMapPacked: true }}
      file={requestObject}
      inputRef={ref}
      onLoadSuccess={onDocumentLoaded}
      onLoadError={err => console.error(err)}
      externalLinkTarget="_blank"
    >
      {pages}
    </Document>
  );
});

export default function MaterialPDFViewer({ mediaId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [pdfRequestObject, setPdfRequestObject] = useState(null);
  const displayPDF = useCallback(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setPdfRequestObject(curriculumService.getMediaRequestObject(mediaId));
  }, [mediaId]);

  return (
    <Container>
      {isLoading && <LoadingState />}
      <div style={{ visibility: isLoading ? 'hidden' : 'visible' }}>
        {pdfRequestObject && <PDF requestObject={pdfRequestObject} onReadyToDisplay={displayPDF} />}
      </div>
    </Container>
  );
}

MaterialPDFViewer.propTypes = {
  mediaId: PropTypes.string.isRequired,
};
