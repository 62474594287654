import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { groupService } from '../services/groupService';
import orgService from '../services/orgService';
import alertService from '../services/AlertService';
import curriculumService from '../services/curriculumService';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import styled from 'styled-components';
import { Type, Grid } from '../StyleGuide';
import {
  FullWidthCard,
  Container,
  CollectionImgPlaceholder,
  CollectionImgContainer,
  Image,
} from '../components/Layout';
import BibleStudyNavigation from './BibleStudyNavigation';
import BibleStudyHeader from './BibleStudyHeader';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import { useUser } from '../authentication';
import AddPeopleTo from '../components/AddPeopleTo';
import useCurriculum from '../hooks/useCurriculum';

const Content = styled.div`
  .title-heading {
    font-size: ${Type.Scale._4};
    font-weight: ${Type.Weight.semibold};
    color: ${Type.Color.dark};
    margin: ${Grid._6} 0 ${Grid._3} 0;
  }

  .title-container {
    align-items: center;
  }
`;

export default function CurriculumAddPeople() {
  const user = useUser();
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, collectionId } = useParams();
  const [data, setData] = useState({
    people: [],
    alreadyAddedPeople: [],
    groups: [],
    collection: {},
    groupId: '',
    isLoading: true,
  });

  const { alreadyAddedPeople, people, groups, isLoading } = data;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const orgId = user.lastSelectedAccount;

  const [crumbs, setCrumbs] = useState([
    { name: 'Curriculum', route: '#/bible-studies' },
    {
      name: `${brand.name}: ${ageCategory}`,
      route: `#/manage-bible-study/people/${brand.code}/${ageCategory}/${curriculumId}`,
    },
  ]);

  const handleError = () => {
    alertService.show('An error occurred. Please try again.', 'error');
    setIsSubmitting(false);
  };

  const addToCurriculumGroup = users => {
    groupService
      .addMember(data.groupId, orgId, user.userId, users, '')
      .then(() => {
        setTimeout(() => alertService.show(`${users.length} ${users.length > 1 ? 'People' : 'Person'} Added`), 200);
        window.location = `#/manage-bible-study/people/collection/${brand.code}/${ageCategory}/${curriculumId}/${collectionId}`;
      })
      .catch(handleError);
  };

  const sendEmails = selectedPeople => {
    setIsSubmitting(true);

    const invitees = selectedPeople
      .filter(u => !!u.toBeInvited)
      .map(u => ({ email: u.email, firstName: '', lastName: '' }));
    if (invitees.length > 0) {
      orgService
        .createPendingInvites(invitees, orgId, '', user.userId)
        .then(() => addToCurriculumGroup(selectedPeople))
        .catch(handleError);
    } else {
      addToCurriculumGroup(selectedPeople);
    }
  };

  const addExcludedKey = (users, groupId) =>
    users.map(user => {
      user['excluded'] = user.groups.includes(groupId);
      return user;
    });
  const filterExcluded = list => list.filter(item => !item.excluded);

  useEffect(() => {
    const getOrg = orgService.getOrgUsers(orgId);
    const getCollectionGroups = curriculumService.getCollectionGroups(orgId);
    const getIndividualCollection = curriculumService.getIndividualCollection(curriculumId, collectionId);
    Promise.all([getOrg, getCollectionGroups, getIndividualCollection])
      .then(response => {
        const { collection, group } = response[2];
        const formattedPeople = addExcludedKey(response[0].users, collectionId);

        setData({
          people: formattedPeople,
          alreadyAddedPeople: filterExcluded(formattedPeople),
          collection: collection,
          groupId: group.groupId.id,
          groups: response[1].filter(g => g.id.id !== group.groupId.id).filter(g => g.members.length),
          isLoading: false,
        });

        setCrumbs([
          ...crumbs,
          {
            name: collection.name,
            route: `#/manage-bible-study/people/collection/${brand.code}/${ageCategory}/${curriculumId}/${collectionId}`,
          },
          { name: 'Add People' },
        ]);
      })
      .catch(reason => {
        setData({ isLoading: false, isError: true });
        console.error(reason);
      });
  }, []);

  return (
    <>
      <BibleStudyNavigation crumbs={crumbs} curriculumId={curriculumId} activeTab="people" />

      <FullWidthCard>
        <Container data-qa-hook="curriculumAddViaEmailView">
          {data.isLoading ? (
            <LoadingState />
          ) : data.isError ? (
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          ) : (
            <Content className="pb-40">
              <h1 className="title-heading">Select People To Add To Collection:</h1>
              <div className="title-container grid-container grid-col-1 grid-md-col-2">
                <BibleStudyHeader
                  thumbnail={
                    <CollectionImgContainer>
                      {data.collection.image ? (
                        <Image src={data.collection.image} alt={`${brand.name}: ${data.collection.name}`} />
                      ) : (
                        <CollectionImgPlaceholder />
                      )}
                    </CollectionImgContainer>
                  }
                  subtitle={brand.name}
                  title={data.collection.name}
                />
                <HelpAndInfoBox
                  title=""
                  description="As soon as you send emails to people, when they log in to Ministry Grid, they'll be able to read, watch and download the materials in this collection from their dashboard."
                />
              </div>

              <AddPeopleTo
                user={user}
                handleAddPeople={sendEmails}
                submitButtonText="Send Emails"
                showInlineAddNewPerson={true}
                alreadyAddedPeople={alreadyAddedPeople}
                people={people}
                isSubmitting={isSubmitting}
                filters={{
                  groups: { filterLabel: 'Filter by collection', displayName: 'Collection', items: data.groups },
                }}
                preventUnsentEmails={true}
              />
            </Content>
          )}
        </Container>
      </FullWidthCard>
    </>
  );
}
