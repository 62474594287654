import React from 'react';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';

const Container = styled.div`
  background: white;
  padding: ${Grid._4};
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 101;
  div {
    margin: 0 0 8px;
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
    width: 75%;
    div {
      margin: 0 8px;
    }
  }
`;

const CompleteStepButtonContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default CompleteStepButtonContainer;
