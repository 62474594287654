import React, { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Grid } from '../StyleGuide';

const Div = styled.div`
  margin-top: ${Grid._11};
  text-align: center;

  h1 {
    margin-top: 0;
    margin-bottom: ${Grid._3};
  }

  .hero-icon {
    font-size: 120px;
    line-height: 1;
  }
`;

type RestrictedAccessHeaderProps = {
  title: string;
  description: ReactNode;
};

export default function RestrictedAccessHeader({
  title,
  description,
  children,
}: PropsWithChildren<RestrictedAccessHeaderProps>) {
  return (
    <Div data-qa-hook="accessDenied">
      <i className="icon ion-close-circled hero-icon error"></i>
      <h1>{title}</h1>
      <p>{description}</p>
      {children}
    </Div>
  );
}
