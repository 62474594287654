import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Color, Type, Border, Grid } from '../StyleGuide';

const Date = styled.div`
  height: ${props => (props.large ? '90px' : '56px')};
  width: ${props => (props.large ? '80px' : '52px')};
  min-width: ${props => (props.large ? '80px' : '52px')};
  border-radius: ${Border.radius};
  border: 1px solid ${props => (props.primary ? Color.Secondary._80 : Color.Gray._30)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${Type.Color.dark};
  background: ${Color.white};
`;
const Month = styled.div`
  color: ${props => (props.primary ? Color.white : Type.Color.dark)};
  background: ${props => (props.primary ? Color.Secondary._80 : Color.Gray._30)};
  border-bottom: 1px solid ${props => (props.primary ? Color.Secondary._80 : Color.Gray._30)};
  width: 100%;
  height: ${props => (props.large ? '40px' : '30px')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${Type.Weight.semibold};
  font-size: ${props => (props.large ? Type.Scale._3 : Type.Scale._1)};
  text-transform: uppercase;
`;
const Day = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${Type.Weight.semibold};
  font-size: ${props => (props.large ? Type.Scale._7 : Type.Scale._5)};
`;

export default function CalendarDateIcon({ month, day, primary, large }) {
  return (
    <Date className="calendar-date-icon" primary={primary} large={large}>
      <Month data-qa-hook="month" primary={primary} large={large}>
        {month}
      </Month>
      <Day data-qa-hook="day" large={large}>
        {day}
      </Day>
    </Date>
  );
}

CalendarDateIcon.propTypes = {
  month: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  primary: PropTypes.any,
  large: PropTypes.any,
};
