import apiClient from './apiClient';
import cacheableRequest from './cacheableRequest';
import cacheService from './cacheService';
import submitCommand from './submitCommand';
import StepIcons from '../StepIcons';
import uuid from './uuid';

// Add cacheService

export const orderEntities = (entities, transitions) => {
  const es = [];
  const start = entities.find(e => e._type === 'Start');

  const addNext = entityId => {
    const transition = transitions.find(t => t.from == entityId);
    if (transition) {
      es.push(entities.find(e => e.id == transition.to));
      addNext(transition.to);
    }
  };

  es.push(start);
  addNext(start.id);
  if (es.length == 1) {
    es.push(entities.find(e => e._type == 'End'));
  }

  return es;
};

export const indexWorkflowTasks = workflow => {
  let index = 0;
  workflow.entities.forEach(entity => {
    if (entity._type === 'TaskList' && entity.taskList && entity.taskList.tasks.length) {
      entity.taskList.tasks.forEach(task => {
        task.taskIndex = index;
        index++;
      });
    }
  });
};

export const transformWorkflow = ({ _id, ...data }) => {
  const workflow = { ...data, id: _id || data.id };

  workflow.entities = orderEntities(workflow.entities, workflow.transitions);
  if (workflow.itemNumber !== undefined) {
    const itemNumbers = workflow.itemNumber.split(',');
    workflow.itemNumber = {
      value: itemNumbers[0],
    };
  }
  workflow.entities.forEach(entity => {
    if (entity._type === 'TaskList' && entity.taskList?.tasks?.length > 0) {
      entity.taskList.tasks.forEach(t => {
        t.icon = StepIcons[t._type];
      });
    }
  });

  indexWorkflowTasks(workflow);
  return workflow;
};

const removeStepFromWorkflow = (workflowId, entityId, initiatingUserId) => {
  cacheService.remove(`/v1/workflows/${workflowId}`);

  return submitCommand(
    workflowId,
    {
      id: workflowId,
      entityId: entityId,
      initiatingUserId: initiatingUserId,
    },
    'RemoveStepFromWorkflow',
    'StepIsRemovedFromWorkflow',
    ['WorkflowError', 'WorkflowDeleted']
  );
};

const addEntityToPathway = (pathwayId, entity) => {
  cacheService.remove(`/v1/workflows/${pathwayId}`);
  return submitCommand(
    pathwayId,
    { ...entity, id: pathwayId },
    'InsertStepToWorkflow',
    'StepIsInsertedToWorkflow',
    'WorkflowError'
  );
};

const workflowService = {
  getPathways: orgId =>
    cacheableRequest(`/v1/workflows?orgId=${orgId}`).then(response =>
      response.data.workflows.map(workflow => transformWorkflow(workflow))
    ),
  getWorkflow: (orgId, workflowId) =>
    apiClient.get(`/v1/workflows?orgId=${orgId}`).then(response => {
      const workflow = response.data.workflows.find(w => w._id === workflowId);
      if (!workflow) {
        throw 'NOT_FOUND';
      }
      return workflow;
    }),
  getWorkflowInstanceDetailById: workflowId =>
    apiClient.get(`/v1/user-workflows/${workflowId}/detail`).then(response => {
      const workflow = response.data;
      workflow.id = workflow._id;
      workflow.courses.forEach(course => {
        course.id = course._id;
        course.discussionDisabled = course.discussionDisabled === true;
      });
      workflow.courses.sort((a, b) => a.order - b.order);
      return workflow;
    }),
  getWorkflowById: workflowId =>
    cacheableRequest(`/v1/workflows/${workflowId}`).then(response => transformWorkflow(response.data)),
  removeStepFromWorkflow: (workflowId, entityId, initiatingUserId) =>
    removeStepFromWorkflow(workflowId, entityId, initiatingUserId),
  removePathway: (pathwayId, orgId, initiatingUserId) => {
    cacheService.removeAllInPath(`/v1/workflows?orgId=${orgId}`);
    return submitCommand(
      pathwayId,
      {
        id: pathwayId,
        initiatingUserId,
      },
      'DeleteWorkflow',
      'WorkflowDeleted'
    );
  },
  addPathway: (id, name, initiatingUserId, orgId) => {
    cacheService.remove(`/v1/workflows?orgId=${orgId}`);
    return submitCommand(
      id,
      {
        id,
        initiatingUserId,
        name,
        ownerId: orgId,
      },
      'CreateWorkFlow',
      'WorkflowCreated',
      'WorkflowNameDuplicateError'
    );
  },

  addCoursesToPathway: (pathwayId, courseIds, previousId, nextId, initiatingUserId) => {
    cacheService.remove(`/v1/workflows/${pathwayId}`);

    const steps = (Array.isArray(courseIds) ? courseIds : [courseIds]).map(id => ({
      id: uuid.generate(),
      taskListId: { id },
      _type: 'TaskList',
    }));

    return steps.reduce((promise, step, index) => {
      const previous = index === 0 ? previousId : steps[index - 1]?.id;

      return promise.then(() =>
        addEntityToPathway(pathwayId, {
          step,
          previous,
          next: nextId,
          initiatingUserId,
        })
      );
    }, Promise.resolve());
  },

  reorderWorkflowEntities: (workflowId, entityIds, initiatingUserId) => {
    cacheService.remove(`/v1/workflows/${workflowId}`);

    return submitCommand(
      workflowId,
      {
        id: workflowId,
        entityIds,
        initiatingUserId,
      },
      'ReorderWorkflowEntities',
      'WorkflowEntitiesReordered'
    );
  },

  publishWorkflow: (workflowId, initiatingUserId) => {
    cacheService.removeAllInPath('/v1/workflows');
    return submitCommand(workflowId, { id: workflowId, initiatingUserId }, 'PublishWorkflow', 'WorkflowPublished', [
      'WorkflowError',
      'WorkflowContainsUnpublishedEntities',
    ]);
  },

  unpublishWorkflow: (workflowId, initiatingUserId) => {
    cacheService.removeAllInPath('/v1/workflows');
    return submitCommand(workflowId, { id: workflowId, initiatingUserId }, 'UnPublishWorkflow', 'WorkflowUnPublished', [
      'WorkflowError',
      'WorkflowContainsUnpublishedEntities',
    ]);
  },

  cloneWorkflow: (workflowId, workflowName, ownerId, initiatingUserId) => {
    cacheService.removeAllInPath('/v1/workflows');
    const id = uuid.generate();

    const command = {
      id,
      fromId: workflowId,
      newName: workflowName,
      ownerId,
      initiatingUserId,
    };

    return submitCommand(id, command, 'CopyWorkflow', 'WorkflowNameChanged', 'WorkflowNameDuplicateError');
  },

  addEmailToPathway: (pathwayId, subject, body, buttonText, previous, next, userId) => {
    const id = uuid.generate();
    const step = { id, subject, body, buttonText, _type: 'Email' };
    return addEntityToPathway(pathwayId, { step, previous, next, initiatingUserId: userId });
  },

  updatePathwayEmail: (pathwayId, emailId, subject, body, buttonText, initiatingUserId) => {
    cacheService.remove(`/v1/workflows/${pathwayId}`);

    const command = {
      id: pathwayId,
      step: {
        id: emailId,
        subject: subject,
        body: body,
        buttonText: buttonText,
        _type: 'Email',
      },
      initiatingUserId,
    };

    return submitCommand(pathwayId, command, 'ReplaceStepInWorkflow', 'StepIsReplacedInWorkflow');
  },
};

export default workflowService;
