import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../../authentication';
import { handleError } from '../../utils/apiUtils';
import { Video, getContent } from '../api/getContent';
import { uploadPoster } from '../api/uploadPoster';

export type UrlParams = {
  contentId: string;
  buildType: string;
  trainingType: string;
};

type State = {
  content: Video | null;
  isLoading: boolean;
  isError: boolean;
};

export const useContentBuilder = () => {
  const user = useUser();
  const orgId = user?.lastSelectedAccount;
  const { buildType, contentId } = useParams<UrlParams>();

  const [data, setData] = useState<State>({
    content: null,
    isLoading: true,
    isError: false,
  });

  useEffect(() => {
    getContent(orgId || '', contentId)
      .then(response => {
        setData(previousData => ({
          ...previousData,
          content: response,
          isLoading: false,
        }));
      })
      .catch(error => {
        handleError(error);
      });
  }, []);

  return { ...data, uploadPoster };
};
