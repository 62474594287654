import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton } from '../../components/Buttons';

export default function NotStartedAssessment({ assessment, startAssessment }) {
  const [isStarting, setIsStarting] = useState(false);

  const handleStartClick = () => {
    setIsStarting(true);
    const promise = startAssessment();
    promise.catch && promise.catch(() => setIsStarting(false));
  };

  return (
    <>
      <p>Let&rsquo;s get started taking the {assessment.name} assessment.</p>
      <p className="text-color-medium">
        {assessment.questions.length} question{assessment.questions.length > 1 ? 's' : ''}
      </p>
      <div className="text-color-medium" dangerouslySetInnerHTML={{ __html: assessment.instructions }}></div>
      <p>Use the &ldquo;Start&rdquo; button to take this assessment.</p>
      <p>
        <PrimaryButton
          data-qa-hook="assessmentStartButton"
          onClick={handleStartClick}
          disabled={isStarting}
          operating={isStarting}
        >
          Start
        </PrimaryButton>
      </p>
    </>
  );
}

NotStartedAssessment.propTypes = {
  assessment: PropTypes.object.isRequired,
  startAssessment: PropTypes.func.isRequired,
};
