import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from '../components/ConfirmationModal';
import WhiteLabel from '../filters/WhiteLabel';

const RemovePeopleFromOrgModal = ({ selectedPeople, orgName, ...props }) => {
  return (
    <ConfirmationModal
      buttonActionText="Remove"
      buttonType="danger"
      title="Remove Members"
      prompt={
        <span>
          {`Are you sure you want to remove ${selectedPeople.length} ${
            selectedPeople.length > 1 ? 'people' : 'person'
          } from ${orgName}`}
          ?
        </span>
      }
      {...props}
    >
      <p>
        Doing so will cause them to lose access to any content that your {WhiteLabel.labels.org} subscribes to. If you
        want to add them back to your {WhiteLabel.labels.org}, you will have to re-add them.
      </p>
      <p className="help-block">
        <i className="icon ion-android-alert"></i> This action cannot be undone
      </p>
    </ConfirmationModal>
  );
};

RemovePeopleFromOrgModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  selectedPeople: PropTypes.array.isRequired,
  orgName: PropTypes.string.isRequired,
};

export default RemovePeopleFromOrgModal;
