import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Grid, Border, Breakpoints, Color, Shadow, Type } from '../StyleGuide';

import { PrimaryButton, FullWidthButton } from '../components/Buttons';
import ModalBackground from '../components/ModalBackground';
import ThumbnailIcon from '../components/ThumbnailIcon';
import WhiteLabel from '../filters/WhiteLabel';
import capitalizeFilter from '../filters/capitalize-filter';
import { useSteps, setShowTrainingCompletedModal as DefaultSetShowTrainingCompletedModal } from './StepsContext';

const Container = styled.div`
  padding: ${Grid._7} ${Grid._5};
  background-color: ${Color.white};
  box-shadow: ${Shadow.light};
  text-align: center;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  border-radius: ${Border.radius};
  h3 {
    font-size: ${Type.Scale._4};
    font-weight: ${Type.Weight.normal};
    color: ${Type.Color.dark};
    margin-top: ${Grid._4};
  }
  svg {
    height: 60px;
    width: 60px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  button:first-child {
    margin-bottom: ${Grid._5};
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    display: grid;
    margin: ${Grid._8} auto 0;
    max-width: 400px;
    button:first-child {
      margin-bottom: 0;
    }
  }
`;

const Checkmark = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 61 61">
    <path
      fill="#4CB37C"
      d="M56.57 30.498c0 14.399-11.673 26.071-26.072 26.071-14.398 0-26.07-11.672-26.07-26.07C4.428 16.1 16.1 4.427 30.498 4.427c14.399 0 26.071 11.672 26.071 26.07zM27.482 44.303L46.826 24.96a1.682 1.682 0 000-2.379l-2.379-2.378a1.682 1.682 0 00-2.379 0L26.294 35.977l-7.365-7.364a1.682 1.682 0 00-2.38 0l-2.378 2.378a1.682 1.682 0 000 2.379l10.933 10.933a1.682 1.682 0 002.379 0z"
    />
    <circle cx="30.5" cy="30.5" r="29.5" stroke="#2B724C" strokeWidth="2" />
  </svg>
);

const Card = styled.div`
  background: ${Color.white};
  border: 1px solid ${Color.Gray._30};
  box-sizing: border-box;
  border-radius: ${Border.radius};
  padding: ${Grid._6};
  max-width: 760px;
  margin: ${Grid._6} auto;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  h4 {
    font-size: ${Type.Scale._3};
    font-weight: ${Type.Weight.bold};
    color: ${Type.Color.dark};
    margin-bottom: ${Grid._2};
  }
  ul {
    list-style: none;
    margin: 0 0 ${Grid._4} 0;
    padding: 0;
  }
  li {
    color: ${Color.Gray._70};
    display: inline-block;
    &:not(:last-of-type)::after {
      content: '•';
      margin: 0 ${Grid._3};
    }
  }
`;

export default function TrainingCompleted({ setShowTrainingCompletedModal = DefaultSetShowTrainingCompletedModal }) {
  const [{ training, isWorkflow }, dispatch] = useSteps();
  const completedOn = new Date().toLocaleDateString();
  const trainingType = isWorkflow ? WhiteLabel.labels.pathway : WhiteLabel.labels.course;
  let itemsCompleted = '';
  if (isWorkflow) {
    itemsCompleted =
      training?.courses.length + ' ' + WhiteLabel.labels.course + (training?.courses.length > 1 ? 's' : '');
  } else {
    itemsCompleted = training?.tasks?.length + ' ' + WhiteLabel.labels.step + (training?.tasks.length > 1 ? 's' : '');
  }
  let hasCert = training?.certificateInfo;

  const downloadCertificate = (id, user) => {
    window.open(getApiUrl() + '/v1/files/certificates/' + id + '/' + user);
  };

  return (
    <ModalBackground
      dismissHandler={() => setShowTrainingCompletedModal(dispatch, { showTrainingCompletedModal: false })}
    >
      <Container data-qa-hook="trainingCompletedModal">
        <Checkmark />
        <h3>You’ve completed the {trainingType.toLowerCase()}:</h3>
        <Card>
          <ThumbnailIcon style={{ margin: `0 ${Grid._6} 0 0` }}>
            <span className="fa-stack fa-stack-status">
              <i
                className="fas fa-circle fa-stack-2x"
                style={isWorkflow ? { color: '#788796' } : { color: '#85a657' }}
              />
              <i className="fas fa-check fa-stack-1x fa-inverse" />
            </span>
            <i className={'fas fa-fw ' + (isWorkflow ? 'fa-route' : 'fa-tasks')} />
          </ThumbnailIcon>
          <div style={{ flex: 1 }}>
            <h4>{isWorkflow ? training?.workflowName : training?.name}</h4>
            <ul>
              <li>{itemsCompleted}</li>
              <li>Completed on {completedOn}</li>
            </ul>
          </div>
          {hasCert && (
            <div>
              <FullWidthButton onClick={() => downloadCertificate(training?.id, training?.assignedUser)}>
                <i className="fa fa-award"></i> Download Certificate
              </FullWidthButton>
            </div>
          )}
        </Card>
        <ButtonsContainer className="grid-container grid-col-gap-24 grid-col-2">
          <PrimaryButton
            data-qa-hook="goToMyTrainingButton"
            onClick={() => window.location.replace('#/dashboard-training')}
          >
            Go to Your Dashboard
          </PrimaryButton>
          <FullWidthButton
            data-qa-hook="backToTrainingButton"
            onClick={() => {
              setShowTrainingCompletedModal(dispatch, { showTrainingCompletedModal: false });
            }}
          >
            Back to {capitalizeFilter(trainingType)}
          </FullWidthButton>
        </ButtonsContainer>
      </Container>
    </ModalBackground>
  );
}

TrainingCompleted.propTypes = {
  setShowTrainingCompletedModal: propTypes.func,
};
