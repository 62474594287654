import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from '../../components/ErrorMessage';
import ConfirmationModal from '../../components/ConfirmationModal';

const ManageCurriculumRemoveMaterialModal = ({ material, deleteMaterial, ...props }) => (
  <ConfirmationModal
    buttonActionText="Remove"
    buttonType="danger"
    title="Remove Material"
    prompt={<span>Are you sure you want to remove this material?</span>}
    subtext={material.name}
    {...props}
  >
    <ErrorMessage>This action cannot be undone</ErrorMessage>
  </ConfirmationModal>
);

ManageCurriculumRemoveMaterialModal.propTypes = {
  material: PropTypes.object,
  payload: PropTypes.object,
  closeModal: PropTypes.func,
  deleteMaterial: PropTypes.func,
};

export default ManageCurriculumRemoveMaterialModal;
