import styled from 'styled-components';
import { Grid, Breakpoints, Color, Type } from '../StyleGuide';

const SubInfoRow = styled.div`
  background: ${Color.Gray._01};
  display: grid;
  grid-template-columns: 1fr max-content;
  padding: ${Grid._4} ${Grid._7} ${Grid._4} 0;
  color: ${Color.Gray._70};
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  line-height: ${Type.Leading.normal};
  align-items: start;
  div:nth-child(1) {
    display: none;
  }
  div:nth-child(2) {
    padding: 0 ${Grid._5};
    display: block;
    .icon {
      color: ${Color.Gray._90};
      margin-right: ${Grid._3};
      font-size: ${Type.Scale._2};
    }

    button:not(:last-child) {
      margin-bottom: ${Grid._4};
    }
  }
  div:nth-child(3) {
    text-align: right;
  }
  a {
    color: ${Color.Gray._90};
    font-weight: ${Type.Weight.bold};
    display: block;
    width: 100%;
    display: flex;
    cursor: pointer;
    text-align: left;
  }
  a:hover {
    text-decoration: underline;
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: ${Grid._12} 1fr max-content;
    div:nth-child(1) {
      display: block;
      padding-left: ${Grid._5};
      text-align: center;
    }
    div:nth-child(2) {
      padding-left: 0;

      button:not(:last-child) {
        margin-right: ${Grid._5};
      }
    }
  }
  .btn-pill {
    padding-left: ${Grid._4};
    padding-right: ${Grid._4};
    i {
      margin-right: ${Grid._2};
    }
    margin-right: ${Grid._4};
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export default SubInfoRow;
