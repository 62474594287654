angular.module('lwNamb').directive(
  'coachPaneNote',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/coach-pane-note.html',
        scope: {
          userId: '@',
          paneId: '@',
          orgId: '@',
        },
        replace: true,
        controller: [
          '$scope',
          'State',
          'noteService',
          '$log',
          'alertService',
          'userService',
          function($scope, State, noteService, $log, alertService, userService) {
            $scope.isLoading = true;
            $scope.submitted = false;
            $scope.isSaving = false;
            var originalTitle, originalMessage;

            var state = State($scope.paneId);
            $scope.selected = state.get();
            $scope.viewOnly = $scope.selected.viewOnly;
            $scope.creating = $scope.selected.noteId === undefined;

            if ($scope.user === undefined) {
              userService.user().then(
                function(user) {
                  $scope.user = user;
                },
                function() {
                  $log.error('No user returned in manageAssignmentDetailCtrl');
                }
              );
            }

            if (!$scope.creating) {
              noteService.getNote($scope.selected.noteId).then(
                function(note) {
                  $scope.note = note;
                  originalTitle = angular.copy($scope.note.title);
                  originalMessage = angular.copy($scope.note.message);
                  $scope.isLoading = false;
                },
                function(reason) {
                  $log.error(reason);
                  $scope.isLoading = false;
                  $scope.isError = true;
                }
              );
            } else {
              //Load Note or Create New Note if doesn't exist
              $scope.note = {};
              $scope.isLoading = false;
            }

            var saveSuccess = function() {
              $scope.isSaving = false;
              alertService.show({ title: 'Note Saved', type: 'success', duration: 3 });
            };

            var saveFailure = function(reason) {
              $log.error(reason);
              $scope.isSaving = false;
              $scope.isError = true;
            };

            $scope.save = function(isValid) {
              $scope.submitted = true;
              if (isValid) {
                if ($scope.creating) {
                  $scope.isSaving = true;
                  noteService
                    .createNote($scope.userId, $scope.orgId, $scope.note.title, $scope.note.message, $scope.user)
                    .then(function(noteId) {
                      $scope.selected.noteId = noteId;
                      $scope.creating = false;
                      saveSuccess();
                    }, saveFailure);
                } else {
                  if (originalTitle !== $scope.note.title && originalMessage !== $scope.note.message) {
                    $scope.isSaving = true;
                    noteService
                      .saveTitleAndMessage(
                        $scope.selected.noteId,
                        $scope.userId,
                        $scope.orgId,
                        $scope.note.title,
                        $scope.note.message,
                        $scope.user
                      )
                      .then(function() {
                        originalTitle = $scope.note.title;
                        originalMessage = $scope.note.message;
                        saveSuccess();
                      }, saveFailure);
                  } else if (originalTitle !== $scope.note.title) {
                    $scope.isSaving = true;
                    noteService
                      .saveTitle($scope.selected.noteId, $scope.userId, $scope.orgId, $scope.note.title, $scope.user)
                      .then(function() {
                        originalTitle = $scope.note.title;
                        saveSuccess();
                      }, saveFailure);
                  } else if (originalMessage !== $scope.note.message) {
                    $scope.isSaving = true;
                    noteService
                      .saveMessage(
                        $scope.selected.noteId,
                        $scope.userId,
                        $scope.orgId,
                        $scope.note.message,
                        $scope.user
                      )
                      .then(function() {
                        originalMessage = $scope.note.message;
                        saveSuccess();
                      }, saveFailure);
                  } else {
                    $log.debug('Neither the title or message was modified');
                  }
                }
              }
            };

            $scope.close = function() {
              State($scope.paneId).clear();
              state.put('menu', 'notes');
            };
          },
        ],
      };
    },
  ]
);
