import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Color, Type, Breakpoints } from '../StyleGuide';
import { LinkStyleButton } from './Buttons';
import windowService from '../services/windowService';

const Header = styled.div`
  position: relative;
  margin: 0 0 ${Grid._5} 0;
  display: flex;
  div:nth-child(1) {
    flex: 1;
  }
  div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: ${Grid._4};
    border-bottom: ${Grid._1} solid ${Color.Gray._10};
  }
`;

const Title = styled.h4`
  font-weight: ${Type.Weight.bold};
  color: ${Type.Color.dark};
  margin: 0;
`;

const SubTitle = styled.h4`
  margin: 0;
  padding-bottom: ${Grid._3};
  color: ${Type.Color.medium};
  font-weight: ${Type.Weight.normal};
  border-bottom: ${Grid._1} solid ${Color.Orange._50};
  @media screen and (min-width: ${Breakpoints.screen_lg}) {
    border-color: ${Color.Gray._10};
  }
  span {
    padding-bottom: 10px;
    border-bottom: 0;
    @media screen and (min-width: ${Breakpoints.screen_lg}) {
      border-bottom: ${Grid._1} solid ${Color.Orange._50};
    }
  }
`;

export default function SectionTitle({ title, subtitle, children }) {
  return (
    <Header>
      <div>
        <Title data-qa-hook="sectionTitle">{title}</Title>
        <SubTitle data-qa-hook="sectionSubtitle" className="sectionHeader section-header-subtitle">
          <span>{subtitle}</span>
        </SubTitle>
      </div>
      {children}
    </Header>
  );
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};
