import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ContentTitle, ContinueButton, ViewContainer, ViewPropTypes } from './OnboardingElements';
import { FormFieldContainer, FormField } from '../../components/FormElements';

const EmailView = ({ data, handler }) => {
  const submit = ({ email }) => handler(email);

  return (
    <>
      <ContentTitle>
        <h2>Enter Your Email Address</h2>
        <p>Provide the email address you want to link to the preview</p>
      </ContentTitle>
      <ViewContainer>
        <Formik
          initialValues={{ email: data.email ?? '' }}
          validationSchema={Yup.object({
            email: Yup.string().email('Invalid email address').required('An email address is required'),
          })}
          validateOnChange={false}
          onSubmit={submit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormFieldContainer>
                <FormField label="Email Address" name="email" type="text" placeholder="Enter Email Address" />
              </FormFieldContainer>

              <div className="grid-container grid-content-center mt-64">
                <ContinueButton
                  data-qa-hook="continueButton"
                  type="submit"
                  disabled={isSubmitting}
                  operating={isSubmitting}
                >
                  Continue
                </ContinueButton>
              </div>
            </Form>
          )}
        </Formik>
      </ViewContainer>
    </>
  );
};

EmailView.propTypes = ViewPropTypes;

export default EmailView;
