import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Grid, Type } from '../StyleGuide';
import { PlaceholderProfileImage } from '../components/ProfileImageCrop';
import { Button, PrimaryButton, DangerButton } from '../components/Buttons';
import { ProfileCardContainer, ProfileCardDivider, ProfileCardSection } from '../components/ProfileCard';
import ProfileImage from '../groups/ProfileImage';
import windowService from '../services/windowService';
import { getUserId, getUserEmail } from '../services/orgService';

const ProfileImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const YouBadge = styled.span`
  color: ${Type.Color.medium};
  font-weight: ${Type.Weight.semibold};
  margin-left: ${Grid._3};
  background: rgba(0, 0, 0, 0.025);
  padding: ${Grid._1} ${Grid._3};
  border-radius: ${Grid._7};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: ${Type.Scale._05};
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const OnboardingMethod = ({ origin }) => {
  const originType = origin._type;
  const dateAdded = format(new Date(origin.dateAddedToOrg), 'MMM d, yyyy');

  return (
    <div data-qa-hook="onboardingMethod">
      {originType === 'Creator' && <p>Created this organization on {dateAdded}</p>}
      {originType === 'DirectInvite' && (
        <p>
          Joined by accepting the {origin.name} invitation from{' '}
          <a href={`#/person/${origin.invitor.userId}`}>
            {origin.invitor.firstName} {origin.invitor.lastName}
          </a>{' '}
          on {dateAdded}
        </p>
      )}
      {originType === 'EasyLink' && <p>Joined by Easy Link on {dateAdded}</p>}
      {originType === 'GroupEasyLink' && (
        <p>
          Joined via {origin.groupName} Group Easy Link on {dateAdded}
        </p>
      )}
      {originType === 'MembershipCode' && (
        <p>
          Joined through advanced invitation: {origin.prefix}-{origin.suffix} on {dateAdded}
        </p>
      )}
      {originType === 'PendingInvite' && (
        <p>
          Accepted an invitation by{' '}
          <a href={`#/person/${origin.invitor.userId}`}>
            {origin.invitor.firstName} {origin.invitor.lastName}
          </a>{' '}
          on {dateAdded}
        </p>
      )}
    </div>
  );
};

OnboardingMethod.propTypes = {
  origin: PropTypes.object.isRequired,
};

export default function MemberProfileCard({ member, orgId, onRemoveMember }) {
  const navigateToEmail = () => {
    localStorage.removeItem('ngStorage-emailPerson');
    windowService.redirectTo(`/email/person/${orgId}/${getUserId(member)}`);
  };

  return (
    <ProfileCardContainer>
      <ProfileCardSection>
        <ProfileImageWrapper>
          {member.profileImageUrl ? (
            <ProfileImage src={member.profileImageUrl} width="150" />
          ) : (
            <PlaceholderProfileImage>
              <i className="fas fa-user-circle"></i>
            </PlaceholderProfileImage>
          )}
        </ProfileImageWrapper>
        <h6 data-qa-hook="profileFullName">
          {member.name}
          {member.isYou && <YouBadge>you</YouBadge>}
        </h6>
        <p data-qa-hook="profileLoginEmail">{getUserEmail(member)}</p>
      </ProfileCardSection>
      {member.origin && (
        <>
          <ProfileCardDivider />
          <ProfileCardSection>
            <OnboardingMethod origin={member.origin} />
          </ProfileCardSection>
        </>
      )}
      {member.isPending && (
        <>
          <ProfileCardDivider />
          <ProfileCardSection>
            <p>Not Logged in Yet</p>
          </ProfileCardSection>
        </>
      )}
      <ProfileCardDivider />
      <ProfileCardSection>
        <PrimaryButton onClick={() => windowService.redirectTo('/search/')}>Assign Training</PrimaryButton>
      </ProfileCardSection>
      {!member.isYou && (
        <>
          <ProfileCardSection>
            <Button data-qa-hook="profileEmailButton" onClick={navigateToEmail}>
              Email {member.firstName}
            </Button>
          </ProfileCardSection>
          <ProfileCardDivider />
          <ProfileCardSection>
            <DangerButton data-qa-hook="removePersonButton" onClick={onRemoveMember}>
              Remove {member.firstName}
            </DangerButton>
          </ProfileCardSection>
        </>
      )}
    </ProfileCardContainer>
  );
}

MemberProfileCard.propTypes = {
  member: PropTypes.object.isRequired,
  orgId: PropTypes.string.isRequired,
  onRemoveMember: PropTypes.func.isRequired,
};
