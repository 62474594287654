import React from 'react';
import styled from 'styled-components';
import { Grid, Color, Type, Shadow, Breakpoints } from '../StyleGuide';

export const CompilationItems = styled.ul`
  list-style: none;
  margin: ${Grid._6} 0;
  padding: 0;
  margin: 0;
`;

export const Compilation = styled.li`
  align-items: center;
  padding: ${Grid._5} 0;
  transition: all 0.15s ease-in-out;
  min-height: 119px;
  div:nth-child(1) {
    img {
      width: 100%;
    }
  }
  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    h4 {
      flex: 2;
      color: ${Type.Color.dark};
      font-size: ${Type.Scale._3};
      font-weight: ${Type.Weight.semibold};
      margin: 0;
      @media screen and (min-width: ${Breakpoints.screen_sm}) {
        margin: 0 ${Grid._4} 0 0;
      }
    }
    p {
      flex: 1;
      color: ${Type.Color.medium};
      font-weight: ${Type.Weight.normal};
      margin: 0;
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      flex-direction: row;
    }
  }
  div:nth-child(3) {
    text-align: center;
    font-size: ${Type.Scale._5};
    color: ${Type.Color.medium};
  }
  & + li {
    border-top: 1px solid ${Color.Blue._15};
  }
  &:hover {
    background: ${Color.white};
    cursor: pointer;
    h4 {
      text-decoration: underline;
    }
  }
  &.isSelected {
    background: white;
    div:nth-child(3) {
      color: ${Color.Primary._50};
    }
  }
`;

export const TrainingItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${Grid._4} 0;
  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: ${Type.Scale._3};
    padding: 0 ${Grid._4};
    h4 {
      margin: 0;
      color: ${Type.Color.dark};
    }
    p {
      margin: 0;
      color: ${Type.Color.medium};
    }
  }
`;

const BannerContainer = styled.div`
  background: ${Color.Gray._50};
  box-shadow: ${Shadow.small};
`;

const BannerImg = styled.img`
  width: 100%;
  object-fit: cover;
  margin: 0;
`;

export const CompilationBanner = ({ src }) => (
  <BannerContainer>
    <BannerImg src={src} />
  </BannerContainer>
);
