import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useCurriculum from '../../hooks/useCurriculum';
import { EventTracker } from './EventTracker';

const AnalyticsContext = React.createContext<null>(null);

export const AnalyticsProvider = ({ pageName, children }: { pageName: string; children: React.ReactNode }) => {
  const urlParams = useParams();
  const curriculum = useCurriculum();

  useEffect(() => {
    if (pageName) {
      EventTracker.trackEvent(EventTracker.events.PAGE_CHANGED, { pageName, urlParams, curriculum });
    }
  }, [pageName]);

  return <AnalyticsContext.Provider value={null}>{children}</AnalyticsContext.Provider>;
};
