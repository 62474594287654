import React, { useState, useEffect, ChangeEvent } from 'react';
import { AxiosError } from 'axios';
import styled from 'styled-components';
import { Container } from '../components/Layout';
import { useUser } from '../authentication';
import { useBuildFilter } from './hooks/useBuildFilter';
import trainingService from '../services/trainingService';
import { SelectMultiple, TextFilterInput } from '../components/FormElements';
import { ButtonGroup, PrimaryButton, SmallRoundedIconButton, SortButton } from '../components/Buttons';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { ListItem } from './components/ListItem';
import { Breakpoints, Grid } from '../StyleGuide';
import alertService from '../services/AlertService';
import ActiveFilters from '../components/ActiveFilters';
import VideoPreviewModal from '../components/VideoPreviewModal';
import { AddAssessmentModal } from './components/modals/AddAssessmentModal';
import useModal from '../hooks/useModal';
import EmptyState from '../components/EmptyState';
import { handleError } from '../utils/apiUtils';
import { RemoveTrainingModal } from './components/modals/RemoveTrainingModal';
import { Content, ContentTypes } from './models/content';
import fileService from '../services/fileService';
import windowService from '../services/windowService';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';
import { removeContent } from './api/removeContent';
import { stopProp } from '../utils/domUtils';
import AddMediaModal from './components/modals/AddMediaModal';
import useEventBusSubscription from '../hooks/useEventBusSubscription';

const OptionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-gap: ${Grid._4};
  align-items: end;
  margin-bottom: ${Grid._6};

  @media screen and (max-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 1fr;
  }
`;

const ActionButton = styled(SmallRoundedIconButton)`
  width: ${Grid._7};
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SortButtonGroup = styled(ButtonGroup)`
  margin-bottom: ${Grid._3};
`;

type Props = {
  ownerId: string;
};

export const ContentList = ({ ownerId }: Props) => {
  const user = useUser();
  const userId = user?.userId || '';
  const { manageTrainingType, manageTrainingRoutePrefix } = useManageTrainingRoute();

  const [data, setData] = useState<{ contents: Content[]; isError: boolean; isLoading: boolean }>({
    contents: [],
    isError: false,
    isLoading: true,
  });

  const { filteredItems, multiFilter, sortFilter } = useBuildFilter<Content>(data.contents);
  const { filters } = multiFilter;

  useEventBusSubscription('VideoIsViewable', ({ id }) => {
    setData(prev => ({
      ...prev,
      contents: prev.contents.map(item => (item.id === id ? { ...item, published: true } : item)),
    }));
  });

  useEffect(() => {
    if (ownerId) {
      trainingService
        .getTrainingContent(ownerId)
        .then((response: Content[]) => {
          setData(prevState => ({
            ...prevState,
            contents: response,
            isLoading: false,
          }));
        })
        .catch((error: AxiosError) => {
          if (error?.response?.status === 404) return setData(prev => ({ ...prev, contents: [], isLoading: false }));
          return setData(prev => ({ ...prev, isError: true, isLoading: false }));
        });
    }
  }, [ownerId]);

  const [modal, openModal, dismissModal] = useModal(
    (type: string, payload: Content | undefined, dismissModal: () => void) => {
      if (type === 'addMedia') return <AddMediaModal action="add" handleDismiss={dismissModal} setData={setData} />;
      if (type === 'replaceMedia')
        return (
          <AddMediaModal action="replace" handleDismiss={dismissModal} setData={setData} fileToReplace={payload} />
        );
      if (type === 'addContent') return <AddAssessmentModal ownerId={ownerId} closeModal={dismissModal} />;
      if (type === 'viewVideo' && payload)
        return (
          <VideoPreviewModal
            name={payload.name}
            videoId={payload.id}
            type={payload.type}
            handleDismiss={dismissModal}
          />
        );
      if (type === 'removeContent' && payload)
        return (
          <RemoveTrainingModal
            name={payload?.name || ''}
            type="content"
            handleSubmit={() =>
              removeContent(payload, ownerId, userId)
                .then(() => {
                  setData(prev => ({ ...prev, contents: prev.contents.filter(c => c.id !== payload.id) }));
                  alertService.show(`Content Removed`);
                })
                .catch(handleError)
                .finally(dismissModal)
            }
            handleDismiss={dismissModal}
          />
        );
      return null;
    }
  );

  const activeFilters = {};
  // @ts-expect-error
  // TODO: handle this TS error
  if (!filters.type.isEmpty) activeFilters['Type'] = filters.type.reset;

  const showActiveFilters = !!Object.keys(activeFilters).length || !!filters.query?.value;

  return (
    <>
      {data.isLoading ? (
        <LoadingState />
      ) : data.isError ? (
        <ErrorMessage />
      ) : (
        <Container>
          <OptionsContainer>
            <SelectContainer data-qa-hook="contentTypeFilter">
              <SelectMultiple
                label="Type"
                options={ContentTypes}
                selections={filters.type.values}
                onSelection={filters.type.update}
                data-qa-hook="taskListsStatusFilter"
              />
            </SelectContainer>
            <TextFilterInput
              label="Search"
              data-qa-hook="contentSearchFilter"
              value={filters.query.value}
              onChangeHandler={(e: ChangeEvent<HTMLInputElement>) => filters.query.update(e.target.value)}
              placeholder="Search Content"
              clearInput={() => filters.query.update('')}
            />
            <div className="primary-action" style={{ gap: Grid._4 }}>
              <PrimaryButton data-qa-hook="addContentButton" onClick={() => openModal('addMedia')}>
                Add Media
              </PrimaryButton>
              <PrimaryButton data-qa-hook="addContentButton" onClick={() => openModal('addContent')}>
                Add Assessment
              </PrimaryButton>
            </div>
          </OptionsContainer>
          {showActiveFilters && <ActiveFilters count={filteredItems?.length} filters={activeFilters} />}

          <SortButtonGroup>
            <SortButton
              sortActive={sortFilter.method === 'name'}
              direction={sortFilter.order === 'ascending' ? 'up' : 'down'}
              onClick={() => sortFilter.update('name')}
            >
              Name
            </SortButton>
            <SortButton
              sortActive={sortFilter.method === 'lastEdited'}
              direction={sortFilter.order === 'ascending' ? 'up' : 'down'}
              onClick={() => sortFilter.update('lastEdited')}
            >
              Last Edited
            </SortButton>
          </SortButtonGroup>

          {filteredItems?.length === 0 ? (
            filters.query?.value ? (
              <EmptyState
                icon="fa-search"
                title="Your search does not have any matches"
                description="Please try another search."
              />
            ) : (
              <EmptyState title="No Content Added" description="To add some content use the Add button." />
            )
          ) : (
            filteredItems.map((content: Content) => (
              <ListItem
                key={content.id}
                item={content}
                type="content"
                actionButtons={
                  <>
                    {content.type === 'Download' && (
                      <>
                        <ActionButton
                          onClick={() => {
                            window.open(fileService.urlOpenFile(content.id, content.name));
                          }}
                        >
                          <i className="fas fa-download fa-fw" />
                          Download
                        </ActionButton>
                        <ActionButton onClick={stopProp(() => openModal('replaceMedia', content))}>
                          <i className="fas fa-redo-alt"></i>
                          Replace
                        </ActionButton>
                      </>
                    )}
                    {content.type === 'Video' && (
                      <>
                        <ActionButton onClick={() => openModal('viewVideo', content)} aria-label="preview">
                          <i className="fas fa-eye" />
                          Preview
                        </ActionButton>
                        <ActionButton
                          onClick={() =>
                            windowService.redirectTo(`/manage-training/${manageTrainingType}/content/${content.id}`)
                          }
                        >
                          <i className="fas fa-pencil-alt" />
                          Edit
                        </ActionButton>
                      </>
                    )}

                    {content.type === 'Assessment' && (
                      <ActionButton
                        onClick={() =>
                          windowService.redirectTo(`${manageTrainingRoutePrefix}/assessments/${content.id}`)
                        }
                      >
                        <i className="fas fa-pencil-alt" />
                        Edit
                      </ActionButton>
                    )}

                    <ActionButton
                      onClick={stopProp(() => openModal('removeContent', content))}
                      aria-label="remove"
                      disabled={!content.published && content.type === 'Video'}
                    >
                      <i className="far fa-trash-alt fa-fw" />
                      Remove
                    </ActionButton>
                  </>
                }
              />
            ))
          )}
          {modal}
        </Container>
      )}
    </>
  );
};
