import React, { useState } from 'react';
import styled from 'styled-components';
import { Color, Grid } from '../StyleGuide';
import windowService from '../services/windowService';

const UserLink = styled.a`
  color: ${Color.Blue._50};
  border-bottom: 1px solid rgba(81, 144, 196, 0.3);

  &:hover {
    border-bottom: 1px solid rgba(81, 144, 196, 0.8);
  }
}`;

const LimitToggle = styled.span`
  cursor: pointer;
  border-bottom: 1px solid rgba(76, 87, 96, 0.5);
  margin-left: ${Grid._3};

  &:hover {
    border-bottom: 1px solid rgba(76, 87, 96, 0.9);
  }
}`;

const getCollapsedLimit = () => {
  const width = window.innerWidth;
  if (width > 992) {
    return 5;
  } else if (width > 767) {
    return 3;
  } else {
    return 1;
  }
};

export type User = {
  id: string;
  name: string;
};

type UsersProps = {
  users: User[];
};

function Users({ users }: UsersProps) {
  const collapsedLimit = getCollapsedLimit();
  const [isExpanded, setIsExpanded] = useState(false);
  const isOverLimit = users.length > collapsedLimit;
  const list = isExpanded ? users : users.slice(0, collapsedLimit);

  const redirectToProfile = (userId: string) => {
    windowService.redirectTo(`/person/${userId}`);
  };

  return (
    <>
      {list
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map((user, i) => (
          <React.Fragment key={user.id}>
            <UserLink onClick={() => redirectToProfile(user.id)}>{user.name}</UserLink>
            {i < list.length - 1 && ', '}
          </React.Fragment>
        ))}
      {isOverLimit && <LimitToggle onClick={() => setIsExpanded(e => !e)}>{isExpanded ? 'less' : 'more'}</LimitToggle>}
    </>
  );
}

export default Users;
