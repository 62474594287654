import React from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../authentication';
import Breadcrumbs from '../components/Breadcrumbs';
import { PageTitle } from '../components/Layout';
import { Facet, PartialSearchResultItem, SearchProvider, SelectTraining, SelectTrainingProvider } from '../search';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import workflowService from '../services/workflowService';
import { handleError } from '../utils/apiUtils';
import { sessionStorage } from '../utils/storageUtils';
import { PageContainer } from './components/LayoutElements';
import { UrlParams } from './models/url';

export const SelectCourse = () => {
  const user = useUser();
  const { trainingId, buildType, trainingType } = useParams<UrlParams>();

  const { previousId, nextId } = sessionStorage.getItem('pathway-builder__select-course') || {};

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Pathways', route: `#/manage-training/${buildType}/${trainingType}` },
    { name: 'Pathway Builder', route: `#/manage-training/${buildType}/${trainingType}/${trainingId}` },
    { name: 'Select Course' },
  ];

  const CourseFacet = new Facet('Training Type', 3, 'trainingType', [
    { key: 'Tasklist', type: 'trainingType', doc_count: 0 },
  ]);

  const addCourses = (selectedCourses: PartialSearchResultItem[]) => {
    const courseIds = selectedCourses.map(course => course.id);

    return workflowService
      .addCoursesToPathway(trainingId, courseIds, previousId, nextId, user?.userId)
      .then(() => {
        alertService.showOnNextPage('Training Items Added');
        windowService.redirectTo(crumbs[crumbs.length - 2].route);
      })
      .catch(handleError);
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />

      <PageContainer>
        <PageTitle className="mb-40">
          <h1>Select Course</h1>
          <p>Find the courses you would like to add to this pathway</p>
        </PageTitle>

        <SelectTrainingProvider onAddSelectedTraining={addCourses}>
          <SearchProvider limitFacets={[CourseFacet]} includeUnpublished={true}>
            <SelectTraining title="Selected Courses" emptyText="No courses selected" buttonText="Add Courses" />
          </SearchProvider>
        </SelectTrainingProvider>
      </PageContainer>
    </>
  );
};
