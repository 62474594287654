angular.module('lwNamb').controller(
  'LicensesCtrl',

  [
    '$scope',
    '$log',
    'userService',
    'licenseService',
    'alertService',
    function($scope, $log, userService, licenseService, alertService) {
      var init = function() {
        $scope.loading = true;
        $scope.licenses = [];

        userService.user().then(
          function(user) {
            $scope.user = user;
            retrieveUserData();
          },
          function() {
            $log.error('No user returned in LicensesCtrl');
          }
        );
      };

      var retrieveUserData = function() {
        //Get Paired Licenses/Subscriptions
        licenseService.getPairedLicenses($scope.user.lastSelectedAccount).then(
          function(response) {
            $scope.loading = false;
            $scope.licenses = response;
          },
          function(reason) {
            $scope.loading = false;
            $log.error('Failed to Retrieve Licenses:' + reason);
            alertService.show({
              type: 'danger danger-with-content',
              title: 'Unexpected Error!',
              content: 'We were unable to retrieve your subscriptions at this time.',
              duration: 20,
            });
          }
        );
      };

      init();
    },
  ] //End Licenses Ctrl
);
