import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { Grid, Color, Transition, Shadow } from '../StyleGuide';

const HiddenToggle = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 0;
  margin: 0;
  padding: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
`;

const ToggleLabel = styled.label`
  display: inline-block;
  cursor: pointer;
  span {
    vertical-align: 5px;
    margin-left: ${Grid._3};

    &.toggle-label--left {
      margin-left: 0;
      margin-right: ${Grid._3};
    }
  }
`;

const StyledToggle = styled.div`
  ${props => `
    --active-color: ${props.activeColor ?? Color.Blue._50};
    --active-color-transparent: ${props.activeColor ?? Color.Blue._50}25;
  `}
  display: inline-block;
  position: relative;
  height: 20px;
  width: 32px;
  border-radius: 10px;
  box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.05);
  transition: ${Transition.fast};

  &.on {
    background: var(--active-color);

    .knob {
      background: ${Color.white};
      border-color: var(--active-color);
    }
  }

  &.off {
    background: ${Color.Gray._30};

    .knob {
      background: ${Color.Gray._05};
      border-color: ${Color.Gray._50};
    }
  }

  &.left .knob {
    animation: toggleOff 0.15s ease-out;
    left: 0;
  }

  &.right .knob {
    left: 12px;
    animation: toggleOn 0.15s ease-out;
  }

  .knob {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${Color.Gray._30};
    border: 1px solid ${Color.Gray._50};
  }

  ${HiddenToggle}:focus + & {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 5px var(--active-color-transparent);
  }

  ${HiddenToggle}:disabled + & {
    opacity: 0.5;
  }

  @keyframes toggleOn {
    0% {
      left: 0;
    }
    100% {
      left: 14px;
    }
  }

  @keyframes toggleOff {
    0% {
      left: 12px;
    }
    100% {
      left: -2px;
    }
  }
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

const FieldLabel = styled.label`
  ${props => `
    --active-color: ${props.activeColor ?? Color.Blue._50};
  `}
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.3),0 1px rgba(255,255,255,0.1);
  transition: ${Transition.fast};
  height: 100%;
  min-width: 100px;
  width: max-content;
  text-align: center;
  padding: 4px;
  cursor: pointer;

  &.on {
    background: var(--active-color);
    color: ${Color.white};
    box-shadow: ${Shadow.medium};
  }

  &.off {
    background: ${Color.white};
    color: ${Color.Gray._50};
  }

  &.left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &.right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const ToggleSwitch = ({ id, checked, onChange, name, label, disabled, ...props }) => {
  return (
    <ToggleLabel htmlFor={id} data-tracking-component="toggle-switch" {...props}>
      <HiddenToggle id={id} name={name} checked={checked} onChange={() => onChange(!checked)} disabled={disabled} />
      <StyledToggle className={checked ? 'on right' : 'off left'}>
        <div className="knob"></div>
      </StyledToggle>
      <span>{label}</span>
    </ToggleLabel>
  );
};

ToggleSwitch.defaultProps = {
  disabled: false,
};

ToggleSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export const OptionSwitch = ({
  id,
  toggledRight,
  leftLabel,
  rightLabel,
  onChange,
  name,
  activeColor,
  disabled,
  ...props
}) => (
  <ToggleLabel htmlFor={id}>
    <HiddenToggle
      id={id}
      name={name}
      checked={toggledRight}
      onChange={() => onChange(!toggledRight)}
      disabled={disabled}
    />
    <span className="toggle-label--left">{leftLabel}</span>
    <StyledToggle className={`on ${toggledRight ? 'right' : 'left'}`} activeColor={activeColor}>
      <div className="knob" {...props}></div>
    </StyledToggle>
    <span>{rightLabel}</span>
  </ToggleLabel>
);

OptionSwitch.defaultProps = {
  disabled: false,
};

OptionSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  toggledRight: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  name: PropTypes.string,
  activeColor: PropTypes.string,
  disabled: PropTypes.bool,
};

export const FieldSwitch = ({
  leftLabel,
  rightLabel,
  activeColor,
  toggledRight,
  leftLabelProps,
  rightLabelProps,
  onChange,
  ...props
}) => (
  <FieldContainer {...props}>
    <FieldLabel
      className={classNames('left', toggledRight ? 'off' : 'on')}
      activeColor={activeColor}
      onClick={() => onChange(false)}
      {...leftLabelProps}
    >
      {leftLabel}
    </FieldLabel>
    <FieldLabel
      className={classNames('right', toggledRight ? 'on' : 'off')}
      activeColor={activeColor}
      onClick={() => onChange(true)}
      {...rightLabelProps}
    >
      {rightLabel}
    </FieldLabel>
  </FieldContainer>
);

FieldSwitch.defaultProps = {
  activeColor: Color.Primary._50,
};

FieldSwitch.propTypes = {
  leftLabel: PropTypes.string.isRequired,
  rightLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  activeColor: PropTypes.string,
  toggledRight: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  leftLabelProps: PropTypes.any,
  rightLabelProps: PropTypes.any,
};
