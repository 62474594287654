import apiClient from './apiClient';
import submitCommand from './submitCommand';
import uuid from './uuid';

const emailService = {
  getEmailPreview: (orgId, templateName, bodyText, buttonText) => {
    const obj = {
      templateName: templateName,
      orgId: orgId,
      emails: ['email@address.com'],
      data: { bodyText: bodyText, buttonText: buttonText },
    };
    return apiClient.post('/v1/template/preview', obj).then(response => response.data);
  },
  sendEmailForTemplate: (accountId, templateName, subject, bodyText, buttonText, emails, replyTo) => {
    const id = uuid.generate();
    return submitCommand(
      id,
      {
        id,
        accountId: { id: accountId },
        templateName,
        subject,
        emails: Array.isArray(emails) ? emails : [emails],
        replyTo,
        data: {
          bodyText,
          buttonText,
        },
      },
      'SendEmailForTemplate',
      'EmailForTemplateSent',
      'EmailError'
    );
  },
};

export default emailService;
