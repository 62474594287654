import React from 'react';
import PropTypes from 'prop-types';
import { format, subDays } from 'date-fns';
import CalendarDateIcon from '../../components/CalendarDateIcon';
import { MediumButton, MediumPrimaryButton } from '../../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../../components/ModalElements';
import { Formik, Form } from 'formik';
import { Label, FormField, Select } from '../../components/FormElements';
import * as Yup from 'yup';
import curriculumService from '../../services/curriculumService';
import sessionTimelineService from '../../services/sessionTimelineService';
import ErrorMessage from '../../components/ErrorMessage';
import { handleError } from '../../utils/apiUtils';

const AddSessionModal = ({
  curriculumId,
  issueId,
  sessionId,
  collections,
  date,
  handleSessionAdded,
  handleDismiss,
  initiatingUserId,
}) => {
  const defaultSelectedCollection = collections.length === 1 ? collections[0].collection.collectionId : '';

  const { publishStartDate, publishEndDate } = curriculumService.getSessionPublishDates(date);
  const availableThroughDate = publishEndDate ? subDays(publishEndDate, 1) : '';

  const createOrUpdateSession = sessionName => {
    if (sessionId) return curriculumService.updateSessionName(issueId, sessionId, sessionName);

    return curriculumService.createSession(
      curriculumId,
      issueId,
      sessionName,
      date,
      publishStartDate,
      publishEndDate,
      initiatingUserId
    );
  };

  const addSessionToDate = values => {
    if (!issueId) return handleError('Issue ID is undefined');

    createOrUpdateSession(values.name)
      .then(createdSession => {
        const id = sessionId ?? createdSession.id;

        if (!id) return Promise.reject('Invalid session id');

        return sessionTimelineService
          .createSessionTimeline(id, values.collection, initiatingUserId)
          .then(timeline => handleSessionAdded(id, timeline.id));
      })
      .catch(error => {
        handleError(error);
        handleDismiss();
      });
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>Add Session</h3>
      </ModalHeader>
      <Formik
        initialValues={{ name: '', collection: defaultSelectedCollection }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          name: Yup.string().max(64, 'Name is too long').required('A name is required'),
          collection: Yup.string().required('A collection is required'),
        })}
        onSubmit={addSessionToDate}
      >
        {({ values, handleChange, isSubmitting, errors, touched }) => (
          <Form id="add-session-form">
            <ModalBody>
              <div style={{ display: 'flex' }}>
                <CalendarDateIcon month={format(date, 'MMM')} day={format(date, 'd')} primary large />
                <div style={{ marginLeft: '16px' }}>
                  <h3>Add session for {format(date, 'MMMM d, yyyy')}</h3>
                  <p>
                    Available through <i>{format(availableThroughDate, 'MMMM d, yyyy')}</i>
                  </p>
                </div>
              </div>
              <div style={{ margin: '32px 0' }}>
                <FormField
                  data-qa-hook="addSessionName"
                  label="Session Name"
                  name="name"
                  type="text"
                  placeholder="Enter Session Name"
                />
                <Label htmlFor="collection">Collection</Label>
                <Select
                  id="collection"
                  name="collection"
                  aria-label="Select Collection"
                  value={values.collection}
                  placeholder={collections.length > 1 ? 'Select Collection' : null}
                  onChange={handleChange}
                >
                  {collections.map(c => (
                    <option key={c.collection.collectionId} value={c.collection.collectionId}>
                      {c.collection.name}
                    </option>
                  ))}
                </Select>
                {errors.collection && touched.collection && <ErrorMessage>{errors.collection}</ErrorMessage>}
              </div>
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton
                data-qa-hook="addSessionConfirm"
                type="submit"
                disabled={isSubmitting}
                operating={isSubmitting}
              >
                Add Session
              </MediumPrimaryButton>
              <MediumButton data-qa-hook="addSessionCancel" onClick={handleDismiss}>
                Cancel
              </MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};

AddSessionModal.propTypes = {
  curriculumId: PropTypes.string.isRequired,
  issueId: PropTypes.string.isRequired,
  sessionId: PropTypes.string,
  collections: PropTypes.array.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  handleSessionAdded: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  initiatingUserId: PropTypes.string,
};

export default AddSessionModal;
