import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Type } from '../../StyleGuide';
import { TimelinesOverlay, TimelineList } from './MultiTimelineOverlayElements';
import { ActionRowButton } from '../../components/Buttons';
import EmptyState from '../../components/EmptyState';
import sessionTimelineService from '../../services/sessionTimelineService';
import windowService from '../../services/windowService';

const TimelineListLabel = styled.h5`
  margin-top: ${Grid._6};
  font-weight: ${Type.Weight.semibold};
`;

const ViewableTimelineItem = styled(ActionRowButton)`
  margin-bottom: ${Grid._4};
`;

const EmptyStateContainer = styled.div`
  text-align: center;
`;

export default function TeacherTimelinesOverlay({ curriculum, session, handleDismiss }) {
  const { timelines } = session;

  const redirectToTimeline = timeline => {
    windowService.redirectTo(sessionTimelineService.getTeacherSessionTimelineUrl(curriculum, session, timeline));
  };

  return (
    <TimelinesOverlay session={session} handleDismiss={handleDismiss}>
      <TimelineListLabel>Select Leader Guide</TimelineListLabel>
      <TimelineList>
        {timelines?.length ? (
          timelines.map(timeline => (
            <ViewableTimelineItem key={timeline.id} onClick={() => redirectToTimeline(timeline)}>
              {timeline.name} <i className="fas fa-chevron-right" />
            </ViewableTimelineItem>
          ))
        ) : (
          <EmptyStateContainer>
            <EmptyState title="No Leader Guides for This Session" />
          </EmptyStateContainer>
        )}
      </TimelineList>
    </TimelinesOverlay>
  );
}

TeacherTimelinesOverlay.propTypes = {
  curriculum: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};
