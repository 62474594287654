angular.module('lwNamb').controller(
  'SupportMaterialViewerCtrl',

  [
    '$scope',
    '$routeParams',
    '$log',
    '$window',
    'windowService',
    'curriculumService',
    function($scope, $routeParams, $log, $window, windowService, curriculumService) {
      function init() {
        $scope.loading = true;

        if ($window.location.hash.indexOf('manage-material-viewer') > -1) {
          $scope.isManageCurriculum = true;
        } else {
          $scope.isManageCurriculum = false;
        }

        loadData();
      }

      function loadData() {
        curriculumService
          .getSupportMaterialAndSeries(
            $routeParams.curriculumId,
            $routeParams.seriesId,
            $routeParams.issueId,
            $routeParams.materialId
          )
          .then(
            function(response) {
              $scope.material = response.material;
              $scope.series = response.series;
            },
            function(reason) {
              $log.error('No material found with id: ' + $routeParams.materialId + ' because of: ' + reason);
              $scope.isError = true;
            }
          )
          .finally(function() {
            $scope.loading = false;
          });
      }

      $scope.redirectToPrevious = function() {
        if ($scope.isManageCurriculum) {
          windowService.redirectHash(
            '#/curriculum/' +
              $routeParams.curriculumId +
              '/issue/' +
              $routeParams.issueId +
              '/manage-support-materials-viewer'
          );
        } else {
          windowService.redirectHash(
            '#/curriculum/' + $routeParams.curriculumId + '/issue/' + $routeParams.issueId + '/support-materials-viewer'
          );
        }
      };

      init();
    },
  ]
);
