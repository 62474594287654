import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MediumPrimaryButton, MediumButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';
import windowService from '../services/windowService';

const UnlockTrainingModal = ({ itemNumber, handleDismiss }) => {
  const [isRedirecting, setIsRedirecting] = useState(false);

  const redirectToPurchase = itemNumber => {
    setIsRedirecting(true);
    windowService.redirectToPurchase(itemNumber);
  };
  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader data-qa-hook="UnlockTrainingModal">
        <h3>Unlock Training</h3>
      </ModalHeader>
      <ModalBody>
        <h4>
          Oh no! It looks like you don't have a subscription. Let's fix that so that you can train and develop others.
        </h4>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton
          data-qa-hook="navigateToUpsellPage"
          onClick={() => redirectToPurchase(itemNumber)}
          disabled={isRedirecting}
          operating={isRedirecting}
        >
          Get Started
        </MediumPrimaryButton>
        <MediumButton data-qa-hook="UnlockTrainingCloseButton" onClick={handleDismiss}>
          Close
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

UnlockTrainingModal.propTypes = {
  itemNumber: PropTypes.string.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default UnlockTrainingModal;
