import React from 'react';
import PropTypes from 'prop-types';
import CalendarDateIcon from '../components/CalendarDateIcon';
import { monthAbbrFilter } from '../filters/date-filters';
import { Image, InlineList, BulletedList } from '../components/Layout';
import { MediumButton, MediumPrimaryButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';

const ResetScheduleConfirmationModal = ({
  volumeName,
  volumeNum,
  volumeImg,
  scheduleStartDate,
  sessionName,
  brand,
  ageGroup,
  resetSchedule,
  handleDismiss,
}) => {
  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>Reset Curriculum Schedule</h3>
      </ModalHeader>
      <ModalBody>
        <h3 style={{ marginBottom: '24px' }}>Are you sure you want to reset this schedule?</h3>
        <div style={{ display: 'flex' }}>
          <Image
            src={volumeImg}
            alt={volumeName}
            style={{ width: '130px', marginRight: '24px' }}
            className="hidden-xs"
          />
          <div>
            <InlineList content={'•'} fontSize="16px">
              <li>{brand}</li>
            </InlineList>
            <h3 style={{ marginBottom: '24px' }}>{ageGroup}</h3>
            <div style={{ display: 'flex' }}>
              <CalendarDateIcon
                month={monthAbbrFilter(scheduleStartDate.getMonth())}
                day={scheduleStartDate.getDate().toString()}
                primary
                large
              />
              <div style={{ marginLeft: '16px' }}>
                <h3>{sessionName}</h3>
                <InlineList content={'•'} fontSize="16px">
                  <li>{volumeName}</li>
                  {volumeNum && <li>Volume {volumeNum}</li>}
                </InlineList>
              </div>
            </div>
          </div>
        </div>
        <BulletedList>
          <li>
            This schedule will be changed for <strong>everyone</strong> using it
          </li>
          <li>Any rescheduled sessions will be set to their original date</li>
        </BulletedList>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton onClick={() => resetSchedule()}>Yes, Reset Schedule</MediumPrimaryButton>
        <MediumButton onClick={() => handleDismiss()}>No</MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

ResetScheduleConfirmationModal.propTypes = {
  volumeName: PropTypes.string.isRequired,
  volumeNum: PropTypes.number.isRequired,
  volumeImg: PropTypes.string.isRequired,
  scheduleStartDate: PropTypes.instanceOf(Date).isRequired,
  sessionName: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  ageGroup: PropTypes.string.isRequired,
  resetSchedule: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default ResetScheduleConfirmationModal;
