import React from 'react';

const ConditionalFlexOptionWrapper = props => {
  const { FlexOptionComponent, segment, children, ...passThroughProps } = props;
  const { options, sections } = segment;

  if (!segment.isFlex && !options?.length) return children({ segment, sections });

  return (
    <FlexOptionComponent options={options} segment={segment} {...passThroughProps}>
      {children}
    </FlexOptionComponent>
  );
};

export default ConditionalFlexOptionWrapper;
