angular.module('lwNamb').directive(
  'assignedTasklistAdmin',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/assigned-tasklist-admin.html',
      };
    },
  ]
);
