import React from 'react';
import { react2angular } from 'react2angular';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Color } from '../StyleGuide';
import { Container } from '../components/Layout';
import Tabs from '../training/Tabs';
import windowService from '../services/windowService';

const SubMenuContainerFluid = styled.div`
  z-index: 100;
  position: relative;
  background: ${Color.white};
  box-shadow: inset 0px 1px 2px rgba(70, 72, 84, 0.1);
  border-bottom: 1px solid ${Color.Gray._10};
`;

export default function YourDashboardSubMenu({
  activeTab,
  trainingTabLabel,
  showYourCurriculum,
  redirectToCurriculum,
}) {
  return (
    <SubMenuContainerFluid>
      <Container>
        <Tabs style={{ margin: 0 }}>
          {showYourCurriculum && (
            <li className={activeTab === 'curriculum' ? 'active-tab' : ''}>
              <button
                data-qa-hook="subMenuTabYourCurriculum"
                onClick={() => (activeTab !== 'curriculum' ? redirectToCurriculum() : null)}
              >
                <i className="far fa-calendar-alt hidden-xs"></i>Your Curriculum
              </button>
            </li>
          )}
          <li className={activeTab === 'training' ? 'active-tab' : ''}>
            <button
              data-qa-hook="subMenuTabYourTraining"
              onClick={() => (activeTab !== 'training' ? windowService.redirectTo('#/dashboard-training') : null)}
            >
              <i className="fas fa-tasks hidden-xs"></i>
              {` ${trainingTabLabel}`}
            </button>
          </li>
          <li className={activeTab === 'history' ? 'active-tab' : ''}>
            <button
              data-qa-hook="subMenuTabYourHistory"
              onClick={() => (activeTab !== 'history' ? windowService.redirectTo('#/dashboard-history') : null)}
            >
              <i className="fas fa-history hidden-xs"></i>Completed
            </button>
          </li>
        </Tabs>
      </Container>
    </SubMenuContainerFluid>
  );
}

YourDashboardSubMenu.defaultProps = {
  trainingTabLabel: 'Your Training',
  showYourCurriculum: true,
  redirectToCurriculum: () => windowService.redirectTo('#/dashboard'),
};

YourDashboardSubMenu.propTypes = {
  activeTab: PropTypes.string.isRequired,
  trainingTabLabel: PropTypes.string,
  showYourCurriculum: PropTypes.bool,
  redirectToCurriculum: PropTypes.func,
};

angular.module('lwNamb').component('yourDashboardSubMenu', react2angular(YourDashboardSubMenu));
