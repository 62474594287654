import React from 'react';
import { useParams } from 'react-router-dom';
import windowService from '../services/windowService';
import Breadcrumbs from '../components/Breadcrumbs';
import { PartialSearchResultItem } from '../search';
import { localStorage } from '../utils/storageUtils';
import { AddTraining } from './components/AddTraining';

type UrlParams = {
  atIndex: string;
  id?: string;
};

export const AddTrainingToButton = () => {
  const { id } = useParams<UrlParams>();

  const trainingButtonPath = `#/manage-catalog/training-button/training${id ? `/${id}` : ''}`;

  const redirectToTrainingButtonPage = () => {
    windowService.redirectTo(trainingButtonPath);
  };

  const addTraining = (selectedTraining: PartialSearchResultItem[]) => {
    localStorage.setItem('buttonTrainingItem', selectedTraining);
    redirectToTrainingButtonPage();
  };

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Catalog', route: '#/manage-catalog' },
    { name: id ? 'Edit Training Button' : 'New Training Button', route: trainingButtonPath },
    { name: 'Add Training' },
  ];
  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <AddTraining onAddSelectedTraining={addTraining} singleSelect />
    </>
  );
};
