angular.module("lwNamb").factory("uploadService", [
  "$rootScope",
  "Upload",
  "apiUrlService",
  "$log",
  "qt",
  "$http",
  "commandSubmissionService",
  "uuid4",
  function ($rootScope, Upload, apiUrlService, $log, qt, $http, commandSubmissionService, uuid4) {
    var uploader;

    var UploadTypes = {
      FILE: "files",
      VIDEO: "videos",
      IMAGE: "images",
      AUDIO: "audio",
    };

    var buildKeyName = function buildKeyName(id, fileName) {
      return id + (fileName.substring(fileName.lastIndexOf(".")) || fileName).toLowerCase();
    };

    var promisedError = function promisedError(e) {
      var d = qt.defer();
      $log.error(e);
      d.reject(e);
      return d.promise;
    };

    var dataURItoBlob = function dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], {
        type: dataURI.split(",")[0].split(":")[1].split(";")[0],
      });
    };

    var prepareFileForUpload = function prepareFileForUpload(uploadType, key, file) {
      return $http
        .get(apiUrlService.getUrl() + "/v1/storage/" + uploadType + "/upload-url" + "?key=" + key)
        .then(function (result) {
          return {
            url: result.data.url,
            headers: { "Content-Type": file.type !== "" ? file.type : "application/octet-stream" },
            method: "PUT",
            data: file,
          };
        });
    };

    var prepareFileForCurriculumUpload = function prepareFileForCurriculumUpload(uploadType, key, file) {
      return $http
        .get(apiUrlService.getUrl() + "/curriculum-storage/" + uploadType + "/upload-url" + "?key=" + key)
        .then(function (result) {
          return {
            url: result.data.url,
            headers: { "Content-Type": file.type !== "" ? file.type : "application/octet-stream" },
            method: "PUT",
            data: file,
          };
        });
    };

    var transferFile = function transferFile(fileData) {
      var deferred = qt.defer();
      uploader = Upload.http(fileData).then(
        function () {
          fileData.data.progress = 100;
          deferred.resolve(fileData.data);
        },
        function (e) {
          $log.error("could not direct upload file");
          $log.error(e);
          deferred.reject("could not direct upload file");
        },
        function (evt) {
          fileData.data.progress = Math.min(100, parseInt((100.0 * evt.loaded) / evt.total));
        }
      );
      uploader.deferred = deferred;
      return deferred.promise;
    };

    var notifyServer = function notifyServer(id, cmd, commandName, eventName) {
      return function () {
        return commandSubmissionService.submitAndWait(id, cmd, commandName, eventName, undefined, undefined, true);
      };
    };

    var uploadOrgFile = function uploadOrgFile(id, orgId, fileName, file, forTask, userId) {
      if (id && orgId && fileName && file && userId && forTask !== undefined) {
        fileName = fileName.toLowerCase();
        var cmd = { id: id, orgAccountId: orgId, fileName: fileName, forTask: forTask, initiatingUserId: userId };

        return prepareFileForUpload(UploadTypes.FILE, buildKeyName(id, fileName), file)
          .then(transferFile)
          .then(notifyServer(id, cmd, "CreateFileMetadata", "FileMetadataCreated"))
          .catch(promisedError);
      } else return promisedError("invalid parameters passed to uploadOrgFile");
    };

    var uploadCurriculumFile = function uploadCurriculumFile(id, curriculumId, fileName, file, userId) {
      if (id && curriculumId && fileName && file && userId) {
        fileName = fileName.toLowerCase();
        var cmd = { id: id, orgAccountId: curriculumId, fileName: fileName, forTask: false, initiatingUserId: userId };

        return prepareFileForCurriculumUpload(UploadTypes.FILE, buildKeyName(id, fileName), file)
          .then(transferFile)
          .then(notifyServer(id, cmd, "CreateFileMetadata", "FileMetadataCreated"))
          .catch(promisedError);
      } else return promisedError("invalid parameters passed to uploadCurriculumFile");
    };

    var uploadCurriculumImage = function uploadCurriculumImage(fileName, file) {
      if (fileName && file) {
        return prepareFileForCurriculumUpload(UploadTypes.IMAGE, fileName, dataURItoBlob(file))
          .then(transferFile)
          .catch(promisedError);
      } else return promisedError("invalid parameters passed to uploadCurriculumImage");
    };

    var updateOrgFile = function updateOrgFile(id, orgId, fileName, file, forTask, userId) {
      if (id && orgId && fileName && file && userId && forTask !== undefined) {
        fileName = fileName.toLowerCase();
        var cmd = { id: id, orgAccountId: orgId, fileName: fileName, forTask: forTask, initiatingUserId: userId };

        return prepareFileForUpload(UploadTypes.FILE, buildKeyName(id, fileName), file)
          .then(transferFile)
          .then(notifyServer(id, cmd, "UpdateFileMetadata", "FileMetadataUpdated"))
          .catch(promisedError);
      } else return promisedError("invalid parameters passed to updateOrgFile");
    };

    var uploadVideo = function uploadVideo(id, orgId, fileName, file, userId) {
      if (id && orgId && fileName && file && userId !== undefined) {
        fileName = fileName.toLowerCase();
        var cmd = {
          id: id,
          orgId: orgId,
          fileName: fileName,
          title: fileName,
          description: "",
          initiatingUserId: userId,
        };

        return prepareFileForUpload(UploadTypes.VIDEO, buildKeyName(id, fileName), file)
          .then(transferFile)
          .then(notifyServer(id, cmd, "SaveVideo", "VideoCreated"))
          .catch(promisedError);
      } else return promisedError("invalid parameters passed to uploadVideo");
    };

    var uploadCurriculumVideo = function uploadCurriculumVideo(id, curriculumId, fileName, file, userId) {
      if (id && curriculumId && fileName && file && userId !== undefined) {
        fileName = fileName.toLowerCase();
        var cmdSaveCurriculumVideo = {
          id: id,
          orgId: curriculumId,
          fileName: fileName,
          title: fileName,
          description: "",
          initiatingUserId: userId,
        };

        return prepareFileForCurriculumUpload(UploadTypes.VIDEO, buildKeyName(id, fileName), file)
          .then(transferFile)
          .then(notifyServer(id, cmdSaveCurriculumVideo, "SaveCurriculumVideo", "VideoCreated"))
          .catch(promisedError);
      } else return promisedError("invalid parameters passed to uploadCurriculumVideo");
    };

    // using video save commands for now since the audio will be played back through our video streaming service
    var uploadCurriculumAudio = uploadCurriculumVideo;

    var uploadPoster = function uploadPoster(id, userId, fileName, base64data) {
      if (id && fileName && base64data && userId !== undefined) {
        var key = id + fileName.toLowerCase();

        return prepareFileForUpload(UploadTypes.IMAGE, key, base64data)
          .then(transferFile)
          .then(
            notifyServer(
              id,
              {
                id: id,
                poster: apiUrlService.getUrl() + "/v1/storage/" + UploadTypes.IMAGE + "/download" + "?key=" + key,
                initiatingUserId: userId,
              },
              "UpdateVideoPoster",
              "VideoPosterUpdated"
            )
          )
          .catch(promisedError);
      } else return promisedError("invalid parameters passed to uploadPoster");
    };

    var uploadProfilePicture = function uploadProfilePicture(id, userId, fileName, base64data) {
      if (id && fileName && base64data && userId !== undefined) {
        var key = userId + fileName.toLowerCase();
        var cmd = { id: userId, imageUrl: key, initiatingUserId: userId };

        return prepareFileForUpload(UploadTypes.IMAGE, key, dataURItoBlob(base64data))
          .then(transferFile)
          .then(notifyServer(userId, cmd, "UpdateUserProfileImage", "UserProfileImageUpdated"))
          .catch(promisedError);
      } else return promisedError("invalid parameters passed to uploadProfilePicture");
    };

    return {
      uploadVideo: uploadVideo,
      uploadPoster: uploadPoster,
      uploadProfilePicture: uploadProfilePicture,
      uploadFile: uploadOrgFile,
      uploadCurriculumFile: uploadCurriculumFile,
      uploadCurriculumVideo: uploadCurriculumVideo,
      uploadCurriculumAudio: uploadCurriculumAudio,
      updateOrgFile: updateOrgFile,
      uploadCurriculumImage: uploadCurriculumImage,
      cancelUpload: function () {
        if (uploader !== undefined) {
          uploader.deferred.reject("CANCELLED");
          uploader = undefined;
        }
      },
      uploadTextTaskImage: function (base64data) {
        var dataType, ext, key;
        if (base64data) {
          dataType = base64data.split(",")[0].split(":")[1].split(";")[0];
          if (dataType.indexOf("image/") !== 0) {
            return promisedError("uploadTextTaskImage: unsupported data type");
          }
          ext = dataType.split("/")[1];
          key = uuid4.generateId().id + "." + ext.toLowerCase();

          return prepareFileForUpload(UploadTypes.IMAGE, key, dataURItoBlob(base64data))
            .then(transferFile)
            .then(function () {
              return apiUrlService.getUrl() + "/v1/storage/" + UploadTypes.IMAGE + "/download" + "?key=" + key;
            })
            .catch(promisedError);
        } else return promisedError("uploadTextTaskImage: empty data");
      },
    };
  },
]);
