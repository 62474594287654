import React from 'react';
import styled from 'styled-components';
import { Grid, Breakpoints, Color, Transition, Type, Shadow } from '../StyleGuide';

const Header = styled.header`
  display: flex;
  right: 0;
  top: 0;
  left: 0;
  z-index: 200;
  align-items: center;
  color: ${Color.Gray._30};
  background-color: ${Color.Gray._90};
  box-shadow: ${Shadow.large};
  h1 {
    order: 1;
    flex-grow: 1;
    font-size: ${Type.Scale._4};
    margin-top: 10px;
    padding: ${Grid._1} ${Grid._3};
    color: ${Color.Gray._30};
    text-shadow: ${Type.Shadow.light};
    text-transform: capitalize;
    span {
      display: inline-block !important;
      color: ${Color.white};
      opacity: 1;
      transition: opacity 0.25s ease-out;
      &.isLoading {
        opacity: 0;
        width: 100%;
        @media screen and (min-width: ${Breakpoints.screen_sm}) {
          width: inherit;
        }
      }
    }
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      padding: ${Grid._3} ${Grid._7};
    }
  }
  a {
    font-size: ${Type.Scale._4};
    text-decoration: none;
    color: ${Color.white};
    padding: ${Grid._4};
    border: none;
    cursor: pointer;
    transition: ${Transition.fast};
    &:after {
      content: '';
    }
    i {
      margin-right: ${Grid._2};
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      font-size: ${Type.Scale._2};
      &:after {
        content: 'Curriculum';
      }
      &:hover {
        text-decoration: underline;
        background: ${Color.Secondary._80};
      }
      padding: ${Grid._5};
      border-right: 1px solid ${Color.Secondary._80};
    }
  }
`;

export default function SessionHeader({ brandName, sessionName, isLoading }) {
  const handleBackButton = () => {
    window.location.replace('#/dashboard');
  };

  return (
    <Header className="session-header">
      <h1 data-qa-hook="sessionName">
        {brandName} <span className={isLoading ? 'isLoading' : ''}>{sessionName}</span>
      </h1>
      <a data-qa-hook="backToDashboard" onClick={handleBackButton}>
        <i className="fas fa-chevron-left"></i>
      </a>
    </Header>
  );
}
