angular.module('lwNamb').controller(
  'ContentBuilderCtrl',

  [
    '$scope',
    '$log',
    '$q',
    'uuid4',
    '$routeParams',
    'api',
    'userService',
    'videoService',
    'windowService',
    'contentService',
    'uploadService',
    'fileUrlService',
    'alertService',
    'buildService',
    function(
      $scope,
      $log,
      $q,
      uuid4,
      $routeParams,
      api,
      userService,
      videoService,
      windowService,
      contentService,
      uploadService,
      fileUrlService,
      alertService,
      buildService
    ) {
      var updatingFile = false,
        ownerId;

      var init = function() {
        $scope.ctxRedirectTo = buildService.ctxRedirectBackTo;

        $q.all([userService.user(), buildService.ownerId()]).then(function(result) {
          var user = result[0];
          ownerId = result[1];
          $scope.user = user;
          $scope.orgId = $scope.user.lastSelectedAccount;
          if ($routeParams.type) {
            $scope.type = $routeParams.type;
            $scope.enableUpload = true;
            $scope.isLoading = false;
          } else {
            $scope.enableUpload = false;
            $scope.isLoading = true;
            $scope.contentId = $routeParams.contentId;
            getContentData();
          }
        });
      };

      var loadData = function() {
        fileUrlService.getFileMetaData($scope.contentId).then(
          function(response) {
            var metaData = response.fileMetaData;
            $scope.metaData = metaData;
            $scope.currentContent.fileName = metaData.fileName;
            fileUrlService.get($scope.metaData._id.id).then(function(response) {
              $scope.metaData.url = response.url;
            });
          },
          function(response) {
            $log.error(response);
          }
        );
      };

      var getContentData = function() {
        contentService.getAllAvailableContentForOrg
          .with({
            orgId: ownerId,
          })
          .$promise.then(
            function(response) {
              contentService.availableContentRetrieved(response);
              $scope.content = contentService.content;
              for (var i = 0; i < $scope.content.length; i++) {
                if ($scope.contentId === $scope.content[i].id) {
                  $scope.currentContent = $scope.content[i];
                }
              }
              $scope.isLoading = false;
              loadData();
            },
            function(response) {
              $log.error('Error: Content not found; ' + response);
              $scope.isLoading = false;
              $scope.isError = true;
            }
          );
      };

      var showAlertFailure = function() {
        alertService.show({
          title: 'Unexpected Error!',
          content: 'There was an error creating your ' + $scope.type + ' task. Please refresh the page and try again.',
          type: 'danger danger-with-content',
          duration: 20,
        });
      };

      function error(result) {
        $log.error(result);
        showAlertFailure();
      }

      function success(result) {
        $scope.uploading = false;
        $scope.uploadComplete = true;
        updatingFile = false;
      }

      $scope.uploadFile = function(file) {
        if (file) {
          $scope.file = file;
          $scope.uploading = true;
          $scope.enableMetaData = true;
          $scope.uploadId = uuid4.generate();
          $scope.currentFilename = file.name;
          if ($routeParams.type !== 'Video') {
            if (updatingFile) {
              uploadService
                .updateOrgFile($scope.contentId, ownerId, file.name, file, false, $scope.user.userId)
                .then(success, error);
            } else {
              uploadService
                .uploadFile($scope.uploadId, ownerId, file.name, file, false, $scope.user.userId)
                .then(success, error);
            }
          }
          if ($routeParams.type === 'Video') {
            uploadService.uploadVideo($scope.uploadId, ownerId, file.name, file, $scope.user.userId).then(
              function() {
                $scope.uploading = false;
                $scope.uploadComplete = true;
                $scope.spinner = false;
                $scope.uploadToServerComplete = true;
              },
              function(reason) {
                $log.error(reason);
                $scope.uploading = false;
                $scope.uploadComplete = false;
                if (reason === 'FILE_TOO_LARGE') {
                  $scope.fileTooLargeError = true;
                } else if (reason === 'CANCELLED') {
                } else {
                  $scope.videoUploadError = true;
                  showAlertFailure();
                }
              }
            );
          }
        }
      };

      $scope.cancelUpload = function() {
        $log.debug('Upload Cancelled!');
        uploadService.cancelUpload();
        $scope.currentFilename = undefined;
        api.delete(
          '/v1/files?id=' + $scope.uploadId + '&userId=' + $scope.user.userId + '&filename=' + $scope.file.name,
          {}
        );
        $scope.uploading = false;
        $scope.uploadComplete = false;
        $scope.enableUpload = true;
        $scope.enableMetaData = false;
      };

      $scope.replaceFile = function() {
        updatingFile = true;
        $scope.enableUpload = true;
      };

      $scope.downloadFile = function() {
        windowService.openUrl($scope.metaData.url);
      };

      init();
    },
  ] //End Content Builder Ctrl
);
