import React from 'react';
import PropTypes from 'prop-types';

import { PrimaryButton } from '../../components/Buttons';

export default function InProgressAssessment({ assessment, progress, setShowTaker }) {
  return (
    <>
      <p>It looks like you have already started taking the {assessment.name} assessment.</p>
      <p className="text-color-medium">
        You have answered {progress} of {assessment.questions.length} question
        {assessment.questions.length > 1 ? 's' : ''}
      </p>
      <div className="text-color-medium" dangerouslySetInnerHTML={{ __html: assessment.overview }}></div>
      <p>Use the &ldquo;Resume&rdquo; button to continue taking this assessment.</p>
      <p>
        <PrimaryButton onClick={() => setShowTaker(true)}>Resume</PrimaryButton>
      </p>
    </>
  );
}

InProgressAssessment.propTypes = {
  assessment: PropTypes.object.isRequired,
  progress: PropTypes.number.isRequired,
  setShowTaker: PropTypes.func.isRequired,
};
