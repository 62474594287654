angular.module("lwNamb").controller(
  "DashboardController",

  [
    "$rootScope",
    "$scope",
    "$log",
    "userService",
    "profileService",
    "windowService",
    "dashboardService",
    function ($rootScope, $scope, $log, userService, profileService, windowService, dashboardService) {
      var init = function () {
        $scope.didNavigateByTab = dashboardService.didNavigateByTab();
        dashboardService.init();

        $scope.activateTab = $scope.didNavigateByTab;

        $scope.loadingOrgs = true;
        $scope.loadingProfile = true;

        userService.user().then(
          function (user) {
            if (!dashboardService.determineCurriculumTabVisisbilityByOrg(user.lastSelectedAccount))
              redirectToAlternate();
            else {
              $scope.loadingOrgs = false;
              loadUserProfile(user);
            }
          },
          function () {
            $log.error("No user returned!");
            $scope.loadingProfile = false;
          }
        );
      };

      function loadUserProfile(user) {
        profileService.getProfile(user.userId).then(function (response) {
          $rootScope.userProfile = response;
          $scope.loadingProfile = false;
        });
      }

      function redirectToAlternate() {
        if (window.location.hash !== "#/dashboard") return;
        windowService.redirectHash("#/dashboard-training");
        $scope.$applyAsync();
      }

      $scope.onCurriculumLoaded = function (items) {
        if (!items.length && !$scope.didNavigateByTab) redirectToAlternate();
        else {
          $scope.activateTab = true;
          $scope.$applyAsync();
        }
      };

      $scope.redirectToViewer = function (currId) {
        windowService.redirectHash("#/curriculum-viewer/" + currId);
      };

      init();
    },
  ]
);
