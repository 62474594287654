angular.module('lwNamb').factory(
  'windowService',

  [
    '$window',
    'apiUrlService',
    '$location',
    function($window, apiUrlService, $location) {
      return {
        redirect: function(url) {
          $window.location.href = url;
        },
        redirectHash: function(hash) {
          hash = hash.indexOf('#') === 0 ? hash.substring(1) : hash;
          $location.url(hash);
        },
        locationReload: function() {
          $window.location.reload(true);
        },
        openUrl: function(url) {
          $window.open(url);
        },
        openPath: function(path) {
          $window.open(apiUrlService.getUrl() + path);
        },
        back: function() {
          $window.history.back();
        },
      };
    },
  ]
);
