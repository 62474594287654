import React from 'react';
import styled from 'styled-components';
import { Color, Grid, Type } from '../StyleGuide';
import Users, { User } from './Users';

const StatusesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Grid._3};
`;

const StatusRow = styled.table`
  margin-bottom: ${Grid._3};
`;

const StatusLabel = styled.label`
  margin: 0;
  font-size: 13px;
  text-transform: uppercase;
  color: ${Color.Gray._70};
  margin-left: ${Grid._3};
  padding-right: ${Grid._3};
`;

type StatusBadgeProps = {
  backgroundColor: string;
  textColor: string;
};

const StatusBadge = styled.span<StatusBadgeProps>`
  display: inline-block;
  min-width: ${Grid._6};
  padding: ${Grid._1} ${Grid._3};
  text-align: center;
  font-weight: ${Type.Weight.semibold};
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
`;

type TraineeStatusesProps = {
  statuses: Array<TraineeStatus>;
};

export class TraineeStatus {
  label: string;
  badgeBackgroundColor: string;
  badgeTextColor: string;
  count: number;
  users: Array<User>;

  constructor(
    label: string,
    count: number,
    users: Array<User>,
    badgeBackgroundColor: string = Color.Primary._50,
    badgeTextColor: string = Color.white
  ) {
    this.label = label;
    this.count = count;
    this.users = users;
    this.badgeBackgroundColor = badgeBackgroundColor;
    this.badgeTextColor = badgeTextColor;
  }
}

const TraineeStatuses = ({ statuses }: TraineeStatusesProps) => {
  return (
    <StatusesContainer>
      {statuses.map(
        status =>
          status.count > 0 && (
            <StatusRow key={status.label}>
              <tbody>
                <tr>
                  <td>
                    <StatusBadge backgroundColor={status.badgeBackgroundColor} textColor={status.badgeTextColor}>
                      {status.count}
                    </StatusBadge>
                    <StatusLabel>{status.label}</StatusLabel>
                    <Users users={status.users} />
                  </td>
                </tr>
              </tbody>
            </StatusRow>
          )
      )}
    </StatusesContainer>
  );
};

export default TraineeStatuses;
