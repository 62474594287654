angular.module('lwNamb').factory('pagingService', [
  '$filter',
  function($filter) {
    var _filter = function(array, sortBy, reverse) {
      return array.sort(function(a, b) {
        a = a[sortBy];
        b = b[sortBy];
        if (a && a.toLowerCase && b && b.toLowerCase) {
          if (reverse) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          } else {
            return b.toLowerCase().localeCompare(a.toLowerCase());
          }
        } else {
          if (reverse) {
            return a - b;
          } else {
            return b - a;
          }
        }
      });
    };

    return {
      paging: function(lastLoaded, filtered, paged) {
        if (filtered && filtered.length > lastLoaded) {
          for (var i = 0; i < 20; i++) {
            if (filtered.length > lastLoaded) {
              paged.push(filtered[lastLoaded]);
              lastLoaded = lastLoaded + 1;
            }
          }
        }
        return { lastLoaded: lastLoaded, filtered: filtered, paged: paged };
      },
      sortAll: function(sortBy, reverse, lastLoaded, filtered, paged, sortByArray, hasAttribute) {
        if (filtered) {
          reverse = !reverse;

          if (sortByArray) {
            sortByArray.forEach(function(key) {
              filtered = _filter(filtered, key, reverse);
            });
          } else {
            filtered = _filter(filtered, sortBy, reverse);
          }

          // Moves item(s) that hasAttribute to the top
          if (hasAttribute) {
            var tempArray = [];

            filtered.forEach(function(item) {
              if (item[hasAttribute]) {
                tempArray.unshift(item);
              } else {
                tempArray.push(item);
              }
            });

            filtered = tempArray;
          }

          // Move isYou to the top
          var index = filtered.findIndex(function(el) {
            return el.isYou;
          });
          // Prevents mutating array if User is already the first item
          if (index > 0) {
            var isYou = filtered[index];
            filtered.splice(index, 1);
            filtered.unshift(isYou);
          }

          return { reverse: reverse, lastLoaded: 0, filtered: filtered, paged: [] };
        } else {
          return { reverse: reverse, lastLoaded: lastLoaded, filtered: filtered, paged: paged };
        }
      },
      filterData: function(lastLoaded, filtered, paged, all, filterParams, fs) {
        var filteredItems;
        filteredItems = $filter('filter')(all, filterParams !== undefined ? filterParams.search : '');
        fs.forEach(function(f) {
          filteredItems = $filter('filter')(filteredItems, f);
        });
        filtered = filteredItems;
        return { lastLoaded: 0, filtered: filtered, paged: [] };
      },
    };
  },
]);
