const EventList = (reducer, expireSeconds = 60) => {
  let list = [];

  const notExpired = event => event.expiresAt > Date.now();

  const safeReducer = (data, evt) => {
    let result = data;
    try {
      result = reducer(data, evt);
    } catch (e) {
      console.error('[EventList] reducer failed:', { event: evt, error: e });
    }
    return result;
  };

  return {
    isEmpty: () => list.filter(notExpired).length === 0,
    add: (type, value) => {
      list = [...list.filter(notExpired), { type: type, value: value, expiresAt: Date.now() + expireSeconds * 1000 }];
    },
    applyTo: data => {
      const result = list.filter(notExpired).reduce(safeReducer, data);
      return result;
    },
  };
};

export default EventList;
