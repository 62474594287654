angular.module('lwNamb').factory(
  'assessResponseAggregatorService',

  [
    '$rootScope',
    'api',
    'qt',
    function($rootScope, api, qt) {
      return {
        getAllAssessments: function(orgId) {
          var deferred = qt.defer();

          getAssessments(orgId).then(
            function(responseData) {
              //Add some double check on ID's or w/e
              deferred.resolve(responseData);
            },
            function(reason) {
              deferred.reject(reason);
            }
          );

          return deferred.promise;
        },
        getAllAssessmentResults: function(responseIds) {
          var deferred = qt.defer();

          getAssessmentResults(responseIds).then(
            function(responseData) {
              //Add some double check on ID's or w/e
              deferred.resolve(responseData);
            },
            function(reason) {
              deferred.reject(reason);
            }
          );

          return deferred.promise;
        },
        getAllAssesseeData: function(orgId, assessmentId) {
          var deferred = qt.defer();

          getAssesseeData(orgId, assessmentId).then(
            function(responseData) {
              //Add some double check on ID's or w/e
              deferred.resolve(responseData);
            },
            function(reason) {
              deferred.reject(reason);
            }
          );

          return deferred.promise;
        },
      };

      function getAssessments(orgId) {
        return api.get('/v1/assessments/aggregate/' + orgId).then(function(responseData) {
          var assessments = [];
          if (responseData && responseData.data && responseData.data.assessments) {
            assessments = responseData.data.assessments;
          }
          return assessments;
        });
      }

      function getAssesseeData(orgId, assessmentId) {
        return api.get('/v1/assessments/aggregate/' + orgId + '/' + assessmentId).then(function(responseData) {
          return responseData.data;
        });
      }

      function getAssessmentResults(responseIds) {
        return api.post('/v1/assessments/aggregate', { responseIds: responseIds }).then(function(responseData) {
          var results = {};
          if (responseData && responseData.data) {
            results = responseData.data;
          }
          return results;
        });
      }
    },
  ]
);
