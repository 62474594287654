import React from 'react';
import { useSteps } from './StepsContext';
import Section from './Section';
import styled from 'styled-components';
import { Grid, Type } from '../StyleGuide';

const List = styled.ul`
  font-size: ${Type.Scale._4};
  li:not(:last-of-type) {
    margin-bottom: ${Grid._4};
  }
`;

export default function PrepareEnrichStep() {
  const [{ currentStep }] = useSteps();
  const hasNotStarted = currentStep.status === 'not_started';

  return (
    <Section>
      {hasNotStarted ? (
        <div>
          <p>
            Your Prepare/Enrich Marriage Assessment experience will differ slightly from the other assessments on the
            SEND Portal.
          </p>
          <p>
            <strong>Because you have already paid for the CPIA (Married) here is what you can expect:</strong>
          </p>
          <List>
            <li>
              A unique login and passcode is being prepared for you! Within 2 business days, you will receive this by
              email with detailed instructions.
            </li>
            <li>
              Using the link and passcode sent to you, you and your spouse will each complete your designated portions
              of the assessment. (roughly 30 - 45 minutes each)
            </li>
            <li>
              After you and your spouse have completed 100% of the assessment, Prepare/Enrich will complete the report
              process.
            </li>
            <li>
              You will receive a PDF copy of your Prepare/Enrich Couples Report &amp; Couples Workbook for your own
              personal benefit and use. (usually takes 2-3 business days)
            </li>
            <li>
              Your report will be reviewed and refined and your resListts will be uploaded to the Send Portal, updating
              Prepare/Enrich on your task list at that time. (within 5 business days of you and your spouse completing
              100%)
            </li>
            <li>At that time you will have access to your Prepare/Enrich Summary Results as well.</li>
          </List>
        </div>
      ) : (
        <div>
          <p>
            Your results have been entered by the national provider of Prepare-Enrich facilitation for the North
            American Mission Board. Please contact your NAMB representative for more details.
          </p>
        </div>
      )}
    </Section>
  );
}
