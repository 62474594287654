angular.module('lwNamb').factory('emailService', [
  '$rootScope',
  'api',
  'qt',
  'commandSubmissionService',
  function($rootScope, api, qt, commandSubmissionService) {
    var timeoutSeconds = 15;
    var service = {
      getEmailPreview: function(orgId, templateName, bodyText, buttonText) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        var obj = {
          templateName: templateName,
          orgId: orgId,
          emails: ['email@address.com'],
          data: { bodyText: bodyText, buttonText: buttonText },
        };
        api.post('/v1/template/preview', obj).then(
          function failure(response) {
            deferred.resolve(response.data);
          },
          function failure(reason) {
            deferred.reject(reason);
          }
        );

        return deferred.promise;
      },
      sendEmailForTemplate: function(id, accountId, templateName, subject, bodyText, buttonText, emails, replyTo) {
        return commandSubmissionService.submitAndWait(
          id,
          {
            id: id,
            accountId: { id: accountId },
            templateName: templateName,
            subject: subject,
            emails: Array.isArray(emails) ? emails : [emails],
            replyTo: replyTo,
            data: {
              bodyText: bodyText,
              buttonText: buttonText,
            },
          },
          'SendEmailForTemplate',
          'EmailForTemplateSent'
        );
      },
    };

    return service;
  },
]);
