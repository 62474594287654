import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Type } from '../StyleGuide';
import Choices from '../steps/assessments/Choices';
import SelectDropdown from '../steps/assessments/SelectDropdown';
import ShortAnswer from '../steps/assessments/ShortAnswer';
import ErrorMessage from '../components/ErrorMessage';
import { PrimaryButton } from '../components/Buttons';
import assessmentService from '../services/assessmentService';
import alertService from '../services/AlertService';
import Sort from './assessments/Sort';

const Question = styled.div`
  margin: ${Grid._5} auto;
  .question-label {
    font-size: ${Type.Scale._3};
    color: ${Type.Color.medium};
    padding: 0;
  }
`;

const Label = ({ index, length }) => {
  return (
    <p className="question-label">
      Question {index + 1} of {length}
    </p>
  );
};

Label.propTypes = {
  index: PropTypes.number,
  length: PropTypes.number,
};

AssessmentTaker.propTypes = {
  assessment: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  setShowTaker: PropTypes.func.isRequired,
  handleAllQuestionsAnswered: PropTypes.func,
};

export default function AssessmentTaker({ assessment, userId, setShowTaker, handleAllQuestionsAnswered }) {
  const questions = assessment.questions;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(questions.find(q => !q.isAnswered).index);
  const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState();
  const [isNotAnsweredError, setIsNotAnsweredError] = useState(false);
  const [pendingQuestions, setPendingQuestions] = useState([]);
  const [isFinishing, setIsFinishing] = useState(false);

  const savePendingPromise = (promise, currentQuestionIndex, questionId, answer) => {
    const res = [
      ...pendingQuestions,
      {
        index: currentQuestionIndex,
        questionId: questionId,
        answer: answer,
        promise: promise,
      },
    ];
    setPendingQuestions(res);
    nextQuestion(res);
  };

  const saveQuestion = () => {
    const getSubmissionFunction = () => {
      if (questions[currentQuestionIndex].questionType === 'radio' && currentQuestionAnswer) {
        return assessmentService.saveRadio;
      } else if (questions[currentQuestionIndex].questionType === 'textbox' && currentQuestionAnswer) {
        return assessmentService.saveShortAnswer;
      } else if (questions[currentQuestionIndex].questionType === 'sort' && currentQuestionAnswer) {
        return assessmentService.saveSortAnswer;
      } else {
        return () => {
          const errString =
            'Assessment Taker trying to save invalid question type: ' +
            questions[currentQuestionIndex].questionType +
            ' for question ' +
            questions[currentQuestionIndex].questionId;
          console.error(errString);
          return new Promise.reject(errString);
        };
      }
    };
    savePendingPromise(
      getSubmissionFunction()(
        assessment._id.id,
        userId,
        assessment.assessmentId,
        questions[currentQuestionIndex].questionId,
        currentQuestionAnswer
      ),
      currentQuestionIndex,
      questions[currentQuestionIndex].questionId,
      currentQuestionAnswer
    );
  };

  const nextQuestion = pendingQuestions => {
    setCurrentQuestionAnswer(null);
    setIsNotAnsweredError(false);
    window.scrollTo(0, 0);
    if (currentQuestionIndex + 1 < questions.length) {
      const possibleUnansweredQuestion = questions.find(q => {
        return q.index > currentQuestionIndex && !q.isAnswered;
      });
      if (possibleUnansweredQuestion === undefined) {
        setShowTaker(false);
      } else {
        setCurrentQuestionIndex(Math.max(possibleUnansweredQuestion.index, currentQuestionIndex + 1));
      }
    } else {
      setIsFinishing(true);
      Promise.all(pendingQuestions.map(q => q.promise))
        .catch(() => {
          console.error('Failed Questions: ', pendingQuestions);
          alertService.show(
            'Oops, we’re unable to complete assessments at this time. We’re sorry for the inconvenience. Please try again later.',
            'error'
          );
          //TODO: add retry save question service
        })
        .finally(() => {
          setIsFinishing(false);
          setShowTaker(false);
          handleAllQuestionsAnswered();
        });
    }
  };

  const completeQuestion = () => {
    let answer = currentQuestionAnswer;
    if (questions[currentQuestionIndex].questionType === 'textbox' && answer) {
      answer =
        currentQuestionAnswer.replace(/\s+/g, ' ').trim() !== ''
          ? currentQuestionAnswer.replace(/\s+/g, ' ').trim()
          : null;
    }
    if (answer) {
      saveQuestion();
    } else {
      setIsNotAnsweredError(true);
    }
  };

  return (
    <Question>
      <Label index={currentQuestionIndex} length={questions.length} />
      <p className="question-text">{questions[currentQuestionIndex].questionText}</p>
      {questions[currentQuestionIndex].displayType === 'textbox' && (
        <ShortAnswer
          answers={questions[currentQuestionIndex].answers}
          setCurrentQuestionAnswer={setCurrentQuestionAnswer}
        />
      )}
      {questions[currentQuestionIndex].displayType === 'radio' && (
        <Choices items={questions[currentQuestionIndex].answers} setCurrentQuestionAnswer={setCurrentQuestionAnswer} />
      )}
      {questions[currentQuestionIndex].displayType === 'select' && (
        <SelectDropdown
          items={questions[currentQuestionIndex].answers}
          setCurrentQuestionAnswer={setCurrentQuestionAnswer}
        />
      )}
      {questions[currentQuestionIndex].displayType === 'sort' && (
        <Sort items={questions[currentQuestionIndex].answers} setCurrentQuestionAnswer={setCurrentQuestionAnswer} />
      )}
      {isNotAnsweredError && <ErrorMessage>An answer is required</ErrorMessage>}
      <p>
        <PrimaryButton
          data-qa-hook="nextStepInAssessmentButton"
          disabled={isFinishing}
          operating={isFinishing}
          style={{ float: 'right' }}
          onClick={() => completeQuestion()}
        >
          {currentQuestionIndex + 1 < questions.length ? 'Next' : 'Finish'}
        </PrimaryButton>
      </p>
    </Question>
  );
}
