import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Type } from '../StyleGuide';
import { FormField, FormFieldMaxCharCountStatus, FormFieldSet } from '../components/FormElements';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import trainingService from '../services/trainingService';
import useOrg from '../hooks/useOrg';

const Description = styled.p`
  font-size: ${Type.Scale._2};
  color: ${Type.Color.medium};
`;

export default function ManageTrainingButtonSearchForm({ button, onSubmit, children }) {
  const [form, setForm] = useState(() => {
    if (button)
      return {
        text: button.text,
        query: button.link.query,
        ownerName: button.link.facets.ownerName,
        category: button.link.facets.categories,
        trainingType: button.link.facets.trainingType,
        searchAccess: button.link.facets.searchAccess,
      };
    return { text: '', query: '', ownerName: '', category: 'No Category', trainingType: '', searchAccess: '' };
  });
  const [categories] = useState(trainingService.getCategories());
  const org = useOrg();

  const updateFormData = newValues => setForm(form => ({ ...form, ...newValues }));

  const submitForm = () => {
    const facets = {};
    if (form.ownerName !== '') facets['ownerName'] = form.ownerName;
    if (form.trainingType !== '') facets['trainingType'] = form.trainingType;
    if (form.category !== 'No Category') facets['categories'] = form.category;
    if (form.searchAccess !== '') facets['searchAccess'] = form.searchAccess;

    const link = {
      query: form.query.trim(),
      facets: facets,
    };

    onSubmit(form.text.trim(), link);
  };

  return (
    <>
      <Description>This training button will link to the results of the search details.</Description>
      <Formik
        enableReinitialize
        initialValues={form}
        validationSchema={Yup.object({
          text: Yup.string().required('Button text is required'),
          query: Yup.string(),
          facets: Yup.object({
            ownerName: Yup.string(),
            trainingType: Yup.string(),
            category: Yup.string(),
            searchAccess: Yup.string(),
          }),
        })}
        onSubmit={submitForm}
      >
        {() => (
          <Form>
            <div className="grid-container grid-col-4 grid-sm-col-8 grid-gap-40" style={{ margin: '32px 0' }}>
              <div className="grid-col-span-4">
                <FormFieldMaxCharCountStatus
                  label="Button Text"
                  name="text"
                  type="text"
                  placeholder="Enter Button Text"
                  maxLength={64}
                  onBlur={e => updateFormData({ text: e.target.value })}
                />
                <FormFieldSet name={'Search Details'}>
                  <FormField
                    label="Search Term"
                    name="query"
                    type="text"
                    placeholder="Enter Search Term"
                    onBlur={e => updateFormData({ query: e.target.value })}
                  />
                  <FormField
                    label="Content"
                    name="ownerName"
                    as="select"
                    onChange={e => updateFormData({ ownerName: e.target.value })}
                  >
                    <option value="">None</option>
                    <option value="Ministry Grid">Ministry Grid</option>
                    <option value={org.name}>{org.name}</option>
                  </FormField>
                  <FormField
                    label="Training Type"
                    name="trainingType"
                    as="select"
                    onChange={e => updateFormData({ trainingType: e.target.value })}
                  >
                    <option value="">None</option>
                    <option value="Task List">Course</option>
                    <option value="Workflow">Pathway</option>
                  </FormField>
                  <FormField
                    label="Category"
                    name="category"
                    as="select"
                    onChange={e => updateFormData({ category: e.target.value })}
                  >
                    {categories.map((category, i) => (
                      <option key={i} value={category}>
                        {category}
                      </option>
                    ))}
                  </FormField>
                  <FormField
                    label="Access"
                    name="searchAccess"
                    as="select"
                    onChange={e => updateFormData({ searchAccess: e.target.value })}
                  >
                    <option value="">None</option>
                    <option value="Free">Free</option>
                    <option value="Paid">Paid</option>
                  </FormField>
                </FormFieldSet>
                {children}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

ManageTrainingButtonSearchForm.propTypes = {
  button: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
