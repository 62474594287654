import React from 'react';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';
import styled from 'styled-components';

const BrandCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${Border.radius};
  border-color: ${Color.Gray._30};
  box-shadow: ${Shadow.regular};
  background: ${props => props.backgroundColor || 'white'};
  padding: ${Grid._5} ${Grid._4} ${Grid._3};
`;

export default function BibleStudyCard(props) {
  return <BrandCard backgroundColor={props.backgroundColor}>{props.children}</BrandCard>;
}
