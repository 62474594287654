import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';
import { carouselControls } from '../components/CarouselElements';
import TrainingCard from '../training/TrainingCard';
import windowService from '../services/windowService';

const redirectToCompilationTraining = (compilationId, trainingId) =>
  windowService.redirectTo(`#/catalog/compilation/${compilationId}/${trainingId}`);

const TrainingCompilationCarousel = ({ compilationId, trainingItems, canRedirect = true }) => {
  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 629, itemsToShow: 2, itemsToScroll: 2 },
    { width: 992, itemsToShow: 3, itemsToScroll: 3 },
  ];

  return (
    <Carousel
      className="training-compilation-carousel"
      initialFirstItem={0}
      breakPoints={breakPoints}
      renderArrow={carouselControls}
      pagination={false}
      itemPadding={[0, 12, 12, 12]}
    >
      {trainingItems.map(training => (
        <TrainingCard
          key={training.id}
          training={training}
          redirectToTraining={() => redirectToCompilationTraining(compilationId, training.id)}
          canRedirect={canRedirect}
        />
      ))}
    </Carousel>
  );
};

TrainingCompilationCarousel.propTypes = {
  compilationId: PropTypes.string.isRequired,
  trainingItems: PropTypes.array.isRequired,
  canRedirect: PropTypes.bool,
};

export default TrainingCompilationCarousel;
