angular.module('lwNamb').service('dashboardService', [
  'config',
  function(config) {
    var didNavigateByTab;
    var shouldShowCurriculumTab;

    var init = function() {
      didNavigateByTab = false;
      shouldShowCurriculumTab = undefined;
    };

    init();

    return {
      init: init,
      showCurriculumTab: function() { shouldShowCurriculumTab = true; },
      hideCurriculumTab: function() { shouldShowCurriculumTab = false; },
      shouldShowCurriculumTab: function() { return shouldShowCurriculumTab; },
      determineCurriculumTabVisisbilityByOrg: function(org) {
        shouldShowCurriculumTab = config.isCurriculumEnabled && !!org;
        return shouldShowCurriculumTab;
      },
      markNavigatedByTab: function() { didNavigateByTab = true; },
      didNavigateByTab: function() { return didNavigateByTab; },
    };
  },
]);
