import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Type, Breakpoints } from '../StyleGuide';
import TextTruncate from '../containers/TextTruncate';

const Text = styled.p`
  color: ${Type.Color.medium};
  font-size: ${Type.Scale._3};
  margin: ${Grid._3} 0 ${Grid._5};
`;

const StepMetaData = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: ${Type.Scale._3};
  color: ${Type.Color.medium};
  font-weight: ${Type.Weight.normal};
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: ${Grid._4} 0;
  }
  li {
    display: flex;
    align-items: center;
    padding: ${Grid._4};
    i {
      margin-right: ${Grid._4};
      font-size: ${Type.Scale._2};
    }
  }
`;

const Categories = ({ categories }) => {
  return (
    <>
      {categories.map((category, i) => (
        <li key={i}>
          <i className="fas fa-tag"></i>
          {category}
        </li>
      ))}
    </>
  );
};

const TrainingOverview = ({ description, truncateLength, showPlaceholder, date, categories }) => {
  const createdDate = new Date(date).toUTCString();
  return (
    <>
      {description ? (
        <TextTruncate text={description} truncateLength={truncateLength} />
      ) : showPlaceholder ? (
        <Text>No description provided</Text>
      ) : (
        ''
      )}
      <StepMetaData>
        {date && (
          <li>
            <i className="fas fa-calendar-day"></i> {createdDate}
          </li>
        )}
        {categories && <Categories categories={categories} />}
      </StepMetaData>
    </>
  );
};

TrainingOverview.defaultProps = {
  truncateLength: 325,
  showPlaceholder: true,
};

TrainingOverview.propTypes = {
  description: PropTypes.string,
  truncateLength: PropTypes.number,
  showPlaceholder: PropTypes.bool,
  date: PropTypes.string,
  categories: PropTypes.array,
};

export default TrainingOverview;
