angular.module('lwNamb').controller(
  'AssessmentResultsShortAnswerViewerCtrl',

  [
    '$scope',
    '$localStorage',
    function($scope, $localStorage) {
      var init = function() {
        $scope.assessmentName = $localStorage.assessmentName;

        $scope.question = $localStorage.shortAnswerQuestion !== undefined ? $localStorage.shortAnswerQuestion : {};
      };

      init();
    },
  ] //End Assessment Results Short Answer Viewer Ctrl
);
