import React, { useState, useEffect, useContext } from 'react';
import { Grid } from '../StyleGuide';
import { Container, SixOneAspectRatioPlaceholder, Image } from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import { TextFilterInput, Header } from '../components/FormElements';
import { CompilationItems, Compilation } from './CompilationElements';
import { ButtonGroup, PrimaryButton, SmallRoundedIconButton } from '../components/Buttons';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import ConfirmationModal from '../components/ConfirmationModal';
import EmptyState from '../components/EmptyState';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import compilationService from '../services/compilationService';
import { useUser } from '../authentication';

export default function ManageTrainingCompilations() {
  const user = useUser();
  const [data, setData] = useState({ compilations: [], isLoading: true });
  const crumbs = [{ name: 'Organization', route: '#/org' }, { name: 'Training Compilations' }];
  const [filter, setFilter] = useState('');
  const [removeModal, setRemoveModal] = useState({ show: false, name: '' });

  const hasItem = (obj, item) =>
    Object.values(obj).find(val => typeof val === 'string' && val.toLowerCase().includes(item));

  useEffect(() => {
    compilationService
      .getCompilations()
      .then(compilations => {
        setData({ compilations: compilationService.orderByModified(compilations), isLoading: false });
      })
      .catch(err => {
        console.error(err);
        setData({ isError: true, isLoading: false });
      });
  }, []);

  const CompilationItem = ({ compilation }) => {
    const openPreview = e => {
      e.stopPropagation();
      windowService.openRoute(`#/catalog/compilation/${compilation._id}`);
    };

    const showModal = e => {
      e.stopPropagation();
      setRemoveModal({ show: true, id: compilation._id, name: compilation.name });
    };
    return (
      <Compilation
        data-qa-hook="compilationItem"
        className="grid-container grid-col-4 grid-sm-col-8 grid-gap-24"
        onClick={() => windowService.redirectTo(`#/manage-catalog/compilation/${compilation._id}`)}
      >
        <div className="grid-col-span-4 grid-sm-col-span-3">
          {compilation.image ? (
            <Image
              src={compilationService.getCompilationImageUrl(compilation.image)}
              alt={compilation.name}
              style={{ width: '100%' }}
            />
          ) : (
            <SixOneAspectRatioPlaceholder actionable>No Image Provided</SixOneAspectRatioPlaceholder>
          )}
        </div>
        <div className="grid-col-span-3 grid-sm-col-span-4">
          <h4 data-qa-hook="compilationName">{compilation.name}</h4>
          <p data-qa-hook="trainingItemCount">
            {compilation.trainingItems.length
              ? `${compilation.trainingItems.length} Training Item${compilation.trainingItems.length > 1 ? 's' : ''}`
              : 'No Training Items'}{' '}
          </p>
        </div>
        <div className="grid-col-span-1 grid-sm-col-span-1">
          <ButtonGroup gap={Grid._5}>
            <SmallRoundedIconButton data-qa-hook="previewCompilation" onClick={openPreview}>
              <i className="fas fa-external-link-alt fa-fw"></i>
              Preview
            </SmallRoundedIconButton>
            <SmallRoundedIconButton data-qa-hook="removeCompilation" onClick={showModal}>
              <i className="far fa-trash-alt fa-fw"></i>
              Remove
            </SmallRoundedIconButton>
          </ButtonGroup>
        </div>
      </Compilation>
    );
  };

  const Items = () => {
    const filteredItems = data.compilations.filter(c => hasItem(c.filterableData, filter.toLowerCase()));
    return filteredItems.length ? (
      filteredItems.map(compilation => <CompilationItem key={compilation._id} compilation={compilation} />)
    ) : (
      <EmptyState
        icon="fa-search"
        title="Your search does not have any matches"
        description="Please try another search."
      />
    );
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      {data.isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <Container data-qa-hook="manageTrainingCompilationView" style={{ margin: `${Grid._6} auto` }}>
          <Header style={{ marginTop: 0 }}>Training Compilations</Header>
          <p>Add, edit and remove training compilations.</p>
          <div className="grid-container grid-col-4 grid-sm-col-8 grid-gap-24" style={{ margin: '32px 0' }}>
            <div className="grid-col-span-4 grid-sm-col-span-3 grid-md-col-span-2">
              <PrimaryButton
                data-qa-hook="addCompilation"
                onClick={() => windowService.redirectTo(`#/manage-catalog/compilation`)}
                style={{ width: '100%', padding: '12px 20px' }}
              >
                Add Training Compilation
              </PrimaryButton>
            </div>
            <div className="grid-col-span-4 grid-sm-col-span-5 grid-md-col-span-6">
              <TextFilterInput
                id="compilationsFilter"
                placeholder="Search Training Compilations"
                value={filter}
                onChangeHandler={e => setFilter(e.target.value)}
                clearInput={() => setFilter('')}
                disabled={!data.compilations.length}
              />
            </div>
          </div>
          {data.compilations.length ? (
            <CompilationItems>
              <Items />
            </CompilationItems>
          ) : (
            <EmptyState
              icon="fa-grip-horizontal"
              title="Get Started with Compilations"
              description="Use the 'Add Training Compilation' button above to curate a collection of training to feature in the training catalog."
            />
          )}
        </Container>
      )}
      {removeModal.show && (
        <ConfirmationModal
          buttonActionText="Remove"
          buttonType="danger"
          title="Remove Training Compilation"
          prompt={<span>Are you sure you want to remove this training compilation?</span>}
          subtext={removeModal.name}
          handleSubmit={() => {
            setData(data => ({ ...data, isSubmitting: true }));
            compilationService
              .removeCompilation(removeModal.id, user.userId)
              .then(() => {
                const filtered = data.compilations.filter(c => c._id !== removeModal.id);
                setData({ compilations: [...filtered], isLoading: false, isSubmitting: false });
                alertService.show(`Training Compilation Removed`, 'success', 3000);
              })
              .catch(() => {
                setData(data => ({ ...data, isSubmitting: false }));
                alertService.show('An error occurred. Please try again.', 'error');
              })
              .finally(() => setRemoveModal({ show: false, name: '' }));
          }}
          handleDismiss={() => setRemoveModal({ show: false, name: '' })}
        >
          <ErrorMessage>
            This compilation will be removed from the training catalog and will no longer be able to be added to the
            training catalog
          </ErrorMessage>
        </ConfirmationModal>
      )}
    </>
  );
}
