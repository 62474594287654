import React, { useState, useEffect } from 'react';
import { SearchRequestStatus, SearchResponse, SearchResultItem } from '../models/Search';
import { SearchContext } from './SearchContext';
import { Facet } from '../models/Facet';
import { User, useUser } from '../../authentication';
import useSearchQuery from '../../hooks/useSearchQuery';
import orgService from '../../services/orgService';
import { submitQuery } from '../api/submitQuery';

type Props = {
  limitFacets?: Facet[];
  includeUnpublished?: boolean;
  children: React.ReactNode;
};

const getHasSubscription = (user: User): Promise<boolean> => {
  const promises = [];
  promises.push(
    user.lastSelectedAccount ? orgService.hasSubscription(user.lastSelectedAccount) : Promise.resolve(false)
  );
  promises.push(user.userId ? orgService.hasIndividualSubscription(user.userId) : Promise.resolve(false));

  return Promise.allSettled(promises).then(([groupSubscription, individualSubscription]) => {
    const hasGroupSubscription = groupSubscription.status === 'fulfilled' && groupSubscription.value;
    const hasIndividualSubscription = individualSubscription.status === 'fulfilled' && individualSubscription.value;
    return hasGroupSubscription || hasIndividualSubscription;
  });
};

export const SearchProvider = ({ limitFacets = [], includeUnpublished = false, children }: Props) => {
  const user: User = useUser() as unknown as User;
  const [hasSubscription, setHasSubscription] = useState<boolean>(false);
  const [status, setStatus] = useState<SearchRequestStatus>({ isLoading: true, isSearching: false, isError: false });
  const { searchQuery, setSearchQuery } = useSearchQuery('query');
  const [searchText, setSearchText] = useState(searchQuery || '');
  const [searchResults, setSearchResults] = useState<SearchResultItem[]>([]);
  const [searchResultCount, setSearchResultCount] = useState<number>(0);
  const [allFacets, setAllFacets] = useState<Facet[]>([]);
  const [selectedFacets, setSelectedFacets] = useState<Facet[]>([]);

  const selectFacet = (facet: Facet) => {
    updateSelectedFacetValues([...selectedFacets, facet]);
  };

  const deselectFacet = (facet: Facet) => {
    updateSelectedFacetValues(selectedFacets.filter(selected => !facet.hasEqualFacetValue(selected)));
  };

  const updateSelectedFacetValues = (updatedFacets: Facet[]) => {
    setSelectedFacets(updatedFacets);
    search(searchQuery, updatedFacets);
  };

  const updateSearchText = (text: string) => {
    setSearchQuery(text);
    setSearchText(text);
    search(text, selectedFacets);
  };

  const resetSearch = () => {
    if (!searchText && selectedFacets.length === 0) return;

    setSearchText('');
    setSearchQuery('');
    setSelectedFacets([]);
    search('', []);
  };

  const filterFacets = (facets: Facet[], facetsToFilterOut: Facet[]) => {
    const facetKeysToFilterOut = facetsToFilterOut.map(facet => facet.key);
    if (facetsToFilterOut.length === 0) return facets;

    return facets.filter(facet => facetKeysToFilterOut.includes(facet.key) === false);
  };

  const appendLimitFacets = (facets: Facet[]) => [...facets, ...limitFacets];

  const search = (text: string = searchText, facets: Facet[], _hasSubscription: boolean = hasSubscription) => {
    setStatus({ isLoading: false, isSearching: true, isError: false });

    submitQuery(text, user.lastSelectedAccount, {
      selectedFacetValues: appendLimitFacets(facets).map(f => f.values[0]),
      showPublishedOnly: !includeUnpublished,
      hasGridSubscription: _hasSubscription,
    })
      .then((data: SearchResponse) => {
        setSearchResultCount(data.hitTotal);
        setSearchResults(data.training);
        setAllFacets(filterFacets(data.facets, limitFacets));
        setStatus({ isLoading: false, isSearching: false, isError: false });
      })
      .catch(() => {
        setStatus({
          isLoading: false,
          isSearching: false,
          isError: true,
          error: new Error('Failed to fetch search results'),
        });
      });
  };

  useEffect(() => {
    if (!user) return;

    getHasSubscription(user)
      .then(_hasSubscription => {
        setHasSubscription(_hasSubscription);
        setStatus({ isLoading: false, isSearching: true, isError: false });

        search(searchText, selectedFacets, _hasSubscription);
      })
      .catch(() => {
        setStatus({
          isLoading: false,
          isSearching: false,
          isError: true,
          error: new Error('Failed to gather subscription'),
        });
      });
  }, [user?.lastSelectedAccount]);

  return (
    <SearchContext.Provider
      value={{
        searchText,
        searchResults,
        searchResultCount,
        allFacets,
        selectedFacets,
        hasSubscription,
        status,
        updateSearchText,
        selectFacet,
        deselectFacet,
        resetSearch,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
