import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PrimaryButton } from '../components/Buttons';
import { SelectMultiple, TextFilterInput } from '../components/FormElements';
import windowService from '../services/windowService';
import cacheService from '../services/cacheService';

const ResultsButton = styled(PrimaryButton)`
  min-width: 240px;
`;

const AssessmentResultsFilterControl = ({ assessmentData, selectedResults, multiFilter }) => {
  const { filters } = multiFilter;

  const rolesOptions = assessmentData.roleNames.sort((a, b) => a.localeCompare(b));
  const groupsOptions = assessmentData.groupNames.sort((a, b) => a.localeCompare(b));
  const taskListsOptions = assessmentData.taskListNames.sort((a, b) => a.localeCompare(b));

  return (
    <>
      <div
        className="grid-container grid-container grid-col-6 grid-sm-col-6 grid-col-gap-24 grid-row-gap-16 mb-10"
        style={{ alignItems: 'end' }}
      >
        <div className="grid-col-span-6 grid-sm-col-span-2">
          <SelectMultiple
            label="Role"
            options={rolesOptions}
            selections={filters.roles.values}
            onSelection={filters.roles.update}
            data-qa-hook="assessmentResultsRoleFilter"
          />
        </div>
        <div className="grid-col-span-6 grid-sm-col-span-2">
          <SelectMultiple
            label="Group"
            options={groupsOptions}
            selections={filters.groups.values}
            onSelection={filters.groups.update}
            data-qa-hook="assessmentResultsGroupFilter"
          />
        </div>
        <div className="grid-col-span-6 grid-sm-col-span-2">
          <SelectMultiple
            label="Course"
            options={taskListsOptions}
            selections={filters.taskLists.values}
            onSelection={filters.taskLists.update}
            data-qa-hook="assessmentResultsCourseFilter"
          />
        </div>
        <div className="grid-col-span-6 grid-sm-col-span-5">
          <TextFilterInput
            data-qa-hook="assessmentResultsFilter"
            value={filters.query.value}
            onChangeHandler={e => filters.query.update(e.target.value)}
            clearInput={() => filters.query.update('')}
            placeholder="Search"
          />
        </div>
        <div className="grid-col-span-6 grid-sm-col-span-1">
          <ResultsButton
            data-qa-hook="viewResultsButton"
            onClick={() => {
              cacheService.set(`assessmentFilter-${assessmentData.assessmentId.id}`, selectedResults);
              windowService.redirectTo(`/#/assessment/results/${assessmentData.assessmentId.id}`);
            }}
            disabled={selectedResults.length === 0}
          >
            View Results {selectedResults.length > 0 && <span className="rounded-label">{selectedResults.length}</span>}
          </ResultsButton>
        </div>
      </div>
    </>
  );
};

AssessmentResultsFilterControl.propTypes = {
  assessmentData: PropTypes.object.isRequired,
  selectedResults: PropTypes.array,
  multiFilter: PropTypes.object,
};

export default AssessmentResultsFilterControl;
