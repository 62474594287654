angular
  .module('lwNamb')

  .factory(
    'nambReportService',

    [
      '$rootScope',
      '$timeout',
      'api',
      'qt',
      'transformer',
      'apiUrlService',
      'coachService',
      'taskListInstanceService',
      function($rootScope, $timeout, api, qt, transformer, apiUrlService, coachService, taskListInstanceService) {
        return {
          getScorecardInstances: function(userId) {
            var deferred = qt.defer();
            coachService.getScorecardAssignments(userId).then(
              function(scorecardAssignments) {
                var scorecards = scorecardAssignments
                  .filter(function(scorecardAssignment) {
                    return (
                      scorecardAssignment.scorecards &&
                      Array.isArray(scorecardAssignment.scorecards.tasks) &&
                      scorecardAssignment.scorecards.tasks.length > 0
                    );
                  })
                  .map(function(scoreCardAssignment) {
                    var tasks = scoreCardAssignment.scorecards.tasks;
                    if (Array.isArray(tasks)) {
                      scoreCardAssignment.scorecards.tasks = tasks.sort();
                    }
                    scoreCardAssignment.scorecards.formattedName =
                      scoreCardAssignment.scorecards.name + ' (' + scoreCardAssignment.name + ')';
                    scoreCardAssignment.scorecards.groupName = scoreCardAssignment.name;
                    return scoreCardAssignment.scorecards;
                  });

                deferred.resolve({ data: scorecards });
              },
              function(reason) {
                deferred.reject(reason);
              }
            );
            return deferred.promise;
          },
          getCPIAInstances: function(userId, orgId) {
            var deferred = qt.defer();
            taskListInstanceService.getCompleted(userId, orgId).then(
              function(taskListInstances) {
                var cpiaInstances = taskListInstances.filter(function(instance) {
                  return (
                    instance.reports !== undefined &&
                    instance.reports.length > 0 &&
                    instance.name.indexOf('Intern') === -1
                  );
                });
                cpiaInstances.forEach(function(list) {
                  var timeAssigned = new Date(list.timeAssigned);
                  //js month starts at 0
                  list.formattedName =
                    list.name +
                    ' - ' +
                    (timeAssigned.getMonth() + 1) +
                    '/' +
                    timeAssigned.getDate() +
                    '/' +
                    timeAssigned.getFullYear();
                });
                deferred.resolve({ data: cpiaInstances });
              },
              function(reason) {
                deferred.reject(reason);
              }
            );
            return deferred.promise;
          },

          getFinalReport: function(userId, orgId) {
            var deferred = qt.defer();
            get(userId, orgId).then(
              function(response) {
                deferred.resolve({ data: response.data });
              },
              function(reason) {
                deferred.resolve({ data: {} });
              }
            );

            return deferred.promise;
          },

          generateFinalReport: function(
            id,
            taskListInstanceId,
            scorecardListInstanceId,
            groupName,
            userId,
            orgId,
            initiatingUserId
          ) {
            var deferred = qt.defer({ timeoutSeconds: 30 });
            api
              .post('/v1/commands/GenerateNAMBFinalReport', {
                id: id,
                scorecardListInstanceId: scorecardListInstanceId,
                tasklistInstanceId: taskListInstanceId,
                userId: userId,
                orgId: orgId,
                groupName: groupName,
                initiatingUserId: initiatingUserId,
              })
              .then(null, function failure(reason) {
                deferred.reject(reason);
              });

            $rootScope.$on('NAMBFinalReportGenerated', function(name, event) {
              if (event.id === id) {
                deferred.resolve(event.reportUrl);
              }
            });

            return deferred.promise;
          },
          generateReportLink: function(reportId, username) {
            var filename = username + '_Final_Report';
            return (
              apiUrlService.getUrl() +
              '/v1/files?fileName=' +
              encodeURIComponent(filename.replace(/ /gi, '_')) +
              '&filePath=' +
              encodeURIComponent(reportId)
            );
          },
        };

        function get(userId, orgId) {
          var deferred = qt.defer();
          api.get('/v1/organizations/' + orgId + '/users/' + userId + '/final-reports').then(
            function(finalReports) {
              var completedReports = finalReports.data.filter(function(report) {
                return report.reportUrl !== undefined;
              });
              if (completedReports.length > 0) {
                var sortedReports = completedReports.sort(function(a, b) {
                  a = new Date(a.lastUpdateTime.split('Z')[0]);
                  b = new Date(b.lastUpdateTime.split('Z')[0]);
                  return a > b ? -1 : a < b ? 1 : 0;
                });
                deferred.resolve({ data: sortedReports[0] });
              } else {
                deferred.reject('NOT_FOUND');
              }
            },
            function(reason) {
              deferred.reject('NOT_FOUND');
            }
          );
          return deferred.promise;
        }
      },
    ]
  );
