import React from 'react';
import styled from 'styled-components';
import { Grid, Color, Type, Border, Breakpoints } from '../StyleGuide';
import apiClient from '../services/apiClient';

export const ListItemTitleContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: ${Grid._3};
  align-content: center;

  h4,
  p {
    margin: 0;
    padding: 0;
  }

  p {
    color: ${Type.Color.medium};
  }
`;

export const ListItemImageContainer = styled.div`
  background: ${props => props.backgroundColor || Color.Gray._10};
  border-radius: ${Border.radius};

  img {
    height: 100%;
    width: auto;
  }
`;

const ChurchImageContainer = styled(ListItemImageContainer)`
  height: 77px;
  width: 77px;
`;

const ChurchIconContainer = styled(ChurchImageContainer)`
  display: grid;
  place-content: center;
  border: 1px solid ${Color.Gray._30};

  img {
    height: 36px;
    width: auto;
  }
`;

export const ChurchImage = ({ church }) =>
  church?.logo ? (
    <ChurchImageContainer>
      <img alt={church.name} src={`${apiClient.getConfig().baseURL}/v1/images/org/${church.logo}`} />
    </ChurchImageContainer>
  ) : (
    <DefaultChurchIcon altText={church?.name || ''} />
  );

export const DefaultChurchIcon = ({ altText = '' }) => (
  <ChurchIconContainer>
    <img alt={altText} src="/img/church.svg" />
  </ChurchIconContainer>
);
