import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Grid, Type } from '../StyleGuide';
import { Container, SixOneAspectRatioPlaceholder, Image } from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import { TextFilterInput, Header, CircleCheck } from '../components/FormElements';
import { CompilationItems, Compilation } from './CompilationElements';
import { Buttons, Button, PrimaryButton } from '../components/Buttons';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import EmptyState from '../components/EmptyState';
import catalogService from '../services/catalogService';
import compilationService from '../services/compilationService';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import { useUser } from '../authentication';

const Description = styled.p`
  font-size: ${Type.Scale._2};
  color: ${Type.Color.medium};
`;

const getConfigByRoute = routePart => {
  switch (routePart) {
    case 'add-compilation':
      return ['Training Catalog', true];
    case 'add-compilation-to-carousel-item':
      return ['Carousel Item', false];
    case 'add-compilation-to-training-button':
      return ['Training Button', false];
  }
};

export default function AddTrainingCompilation() {
  const user = useUser();
  const [data, setData] = useState({ compilations: [], isLoading: true });
  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Catalog', route: '#/manage-catalog' },
    { name: 'Add Training Compilation' },
  ];
  const [filter, setFilter] = useState('');
  const [selectedCompilation, setSelectedCompilation] = useState('');

  const [type, isCatalog] = getConfigByRoute(windowService.getRoutePart(2));

  const index = windowService.getRoutePart(3);

  const hasItem = (obj, item) =>
    Object.values(obj).find(val => typeof val === 'string' && val.toLowerCase().includes(item));

  const cache = type === 'Carousel Item' ? 'carouselTrainingItem' : 'buttonTrainingItem';

  useEffect(() => {
    const promises = [];
    promises.push(catalogService.getCatalogHomePage());
    promises.push(compilationService.getCompilations());
    Promise.all(promises)
      .then(promises => {
        const filteredCompilations = () => {
          if (isCatalog) {
            const catalogCompilationIds = promises[0].compilationsSection.compilations.map(comp => comp._id);
            return promises[1].filter(compilation => !catalogCompilationIds.includes(compilation._id));
          } else {
            return promises[1];
          }
        };
        setData({
          catalog: promises[0],
          compilations: compilationService.orderByModified(filteredCompilations()),
          isLoading: false,
        });
      })
      .catch(err => {
        console.error(err);
        setData({ isError: true, isLoading: false });
      });
  }, []);

  const addSelectedCompilation = compilation => {
    setData({ ...data, isSubmitting: true });
    if (isCatalog) {
      const existingIds =
        data.catalog.compilationsSection.compilations.length > 0
          ? data.catalog.compilationsSection.compilations.map(comp => comp._id)
          : [];
      const compilationIds = index === 'first' ? [compilation._id, ...existingIds] : [...existingIds, compilation._id];
      catalogService
        .setCatalogCompilations(
          data.catalog.id,
          data.catalog.compilationsSection.sectionId,
          compilationIds,
          user.userId
        )
        .then(() => {
          alertService.showOnNextPage('Training Catalog Updated');
          windowService.redirectTo('#/manage-catalog');
        })
        .catch(reason => {
          console.error(reason);
          alertService.show('An error occurred. Please try again.', 'error');
          setData({ ...data, isSubmitting: false });
        });
    } else {
      //use array to match add training items pattern
      const item = [
        {
          id: compilation._id,
          name: compilation.name,
          trainingType: 'Compilation',
        },
      ];
      localStorage.setItem(cache, JSON.stringify(item));
      window.history.back();
    }
  };

  const CompilationItem = ({ compilation }) => {
    return (
      <Compilation
        data-qa-hook="compilationContainer"
        className={`grid-container grid-col-4 grid-sm-col-8 grid-gap-24 ${
          selectedCompilation._id === compilation._id ? 'isSelected' : ''
        }`}
        onClick={() => setSelectedCompilation(compilation)}
      >
        <div className="grid-col-span-4 grid-sm-col-span-3">
          {compilation.image ? (
            <Image src={compilationService.getCompilationImageUrl(compilation.image)} alt={compilation.name} />
          ) : (
            <SixOneAspectRatioPlaceholder>No Image Provided</SixOneAspectRatioPlaceholder>
          )}
        </div>
        <div className="grid-col-span-3 grid-sm-col-span-4">
          <h4 data-qa-hook="compilationName">{compilation.name}</h4>
          <p data-qa-hook="compilationTrainingCount">
            {compilation.trainingItems.length ? compilation.trainingItems.length : 'No'} Training Items
          </p>
        </div>
        <div className="grid-col-span-1 grid-sm-col-span-1">
          <CircleCheck checked={selectedCompilation._id === compilation._id} />
        </div>
      </Compilation>
    );
  };

  const Items = () => {
    const filteredItems = data.compilations.filter(c => hasItem(c.filterableData, filter.toLowerCase()));
    return filteredItems.length ? (
      filteredItems.map(compilation => <CompilationItem key={compilation._id} compilation={compilation} />)
    ) : (
      <EmptyState
        icon="fa-search"
        title="Your search does not have any matches"
        description="Please try another search."
      />
    );
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      {data.isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <Container data-qa-hook="addCompilationToCatalogView" style={{ margin: `${Grid._6} auto` }}>
          <Header style={{ marginTop: 0 }}>Add Training Compilation to {type}</Header>
          <Description>Select a training compilation to feature in the {type.toLowerCase()}.</Description>
          <div className="grid-container grid-col-4 grid-sm-col-8 grid-gap-24" style={{ margin: '32px 0' }}>
            <div className="grid-col-span-4 grid-sm-col-span-6 grid-md-col-span-4">
              <TextFilterInput
                id="compilationsFilter"
                placeholder="Search Training Compilations"
                value={filter}
                onChangeHandler={e => setFilter(e.target.value)}
                clearInput={() => setFilter('')}
                disabled={!data.compilations.length}
              />
            </div>
          </div>
          {data.compilations.length ? (
            <>
              <CompilationItems>
                <Items />
              </CompilationItems>
              <Buttons style={{ justifyContent: 'flex-end' }}>
                <Button data-qa-hook="cancel" type="button" onClick={() => window.history.back()}>
                  Cancel
                </Button>
                <PrimaryButton
                  data-qa-hook="compilationAdd"
                  onClick={() => addSelectedCompilation(selectedCompilation)}
                  disabled={!selectedCompilation || data.isSubmitting}
                  operating={data.isSubmitting}
                >
                  Add Compilation
                </PrimaryButton>
              </Buttons>
            </>
          ) : (
            <EmptyState
              icon="fa-grip-horizontal"
              title="No Training Compilations"
              description="It looks like either you've already added all of your training compilations to the training catalog or you have not yet created any training compilations. You can create new training compilations from your organization dashboard."
            />
          )}
        </Container>
      )}
    </>
  );
}
