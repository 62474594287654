const Retry = (makePromise, maxRetryCount, retryCondition, optionalIntervalMillis) => {
  const intervalMillis = typeof optionalIntervalMillis === 'number' ? optionalIntervalMillis : 1000;

  return new Promise((resolve, reject) => {
    const retry = tryCount => {
      makePromise().then(
        response => {
          resolve(response);
        },
        error => {
          if (tryCount > maxRetryCount || !retryCondition(error)) {
            reject(error);
          } else {
            setTimeout(() => {
              retry(tryCount + 1);
            }, tryCount * intervalMillis);
          }
        }
      );
    };

    retry(1);
  });
};

export default Retry;
