import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Grid, Breakpoints } from '../StyleGuide';
import { format, parseISO, isAfter } from 'date-fns';
import curriculumService from '../services/curriculumService';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import {
  FullWidthCard,
  Container,
  CollectionImgPlaceholder,
  CollectionImgContainer,
  Image,
  InlineList,
} from '../components/Layout';
import BibleStudyNavigation from './BibleStudyNavigation';
import { ListItemFlexRow } from '../components/ListItemFlexRow';
import ProfileImageList from '../components/ProfileImageList';
import windowService from '../services/windowService';
import useCurriculum from '../hooks/useCurriculum';

const Collections = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 ${Grid._5};
`;

const CollectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${Grid._5} 0;
  h3,
  ul {
    margin-bottom: ${Grid._3};
  }
  p {
    margin: 0;
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: 0 ${Grid._5};
  }
  .profile-images {
    margin: 0 0 ${Grid._3} ${Grid._3};
  }
`;

const FullWidthCardContainer = styled(FullWidthCard)`
  padding-top: ${Grid._5};
`;

const AtHomeBanner = styled.div`
  display: flex;
  justify-content: center;
  padding: ${Grid._4} 0 ${Grid._8} 0;

  img {
    cursor: pointer;
    width: auto;
    max-width: 100%;
  }
`;

export default function ManageBibleStudyPeople() {
  const [data, setData] = useState({ isLoading: true });
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId } = useParams();

  const crumbs = [{ name: 'Curriculum', route: '#/bible-studies' }, { name: `${brand.name}: ${ageCategory}` }];
  const today = new Date();
  const isAfterToday = date => isAfter(parseISO(date), today);

  useEffect(() => {
    curriculumService
      .getCollections(curriculumId)
      .then(collections => setData({ collections: collections, isLoading: false }))
      .catch(reason => {
        setData({ isLoading: false, isError: true });
        console.error(reason);
      });
  }, []);

  const redirectToCollection = collectionId => {
    window.location = `#/manage-bible-study/people/collection/${brand.code}/${ageCategory}/${curriculumId}/${collectionId}`;
  };

  return (
    <>
      <BibleStudyNavigation isLoading={data.isLoading} crumbs={crumbs} curriculumId={curriculumId} activeTab="people" />
      <FullWidthCardContainer>
        <Container data-qa-hook="collectionsViewContainer">
          {data.isLoading ? (
            <LoadingState />
          ) : data.isError ? (
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          ) : (
            <>
              <Collections data-qa-hook="collectionsContainer">
                {data.collections.map(({ collection, group, hasAccess }, i) => {
                  const totalMembers = group.memberCount;
                  const members =
                    totalMembers === 1 ? '1 person' : totalMembers > 1 ? totalMembers + ' people' : 'No people';
                  const hasDates = isAfterToday(collection.startDate) || Boolean(collection.endDate);
                  return (
                    <ListItemFlexRow
                      key={i}
                      id={collection.collectionId}
                      disabled={!hasAccess}
                      onClick={hasAccess ? () => redirectToCollection(collection.collectionId) : null}
                      align="normal"
                    >
                      <CollectionImgContainer>
                        {collection.image ? (
                          <Image src={collection.image} alt={`${brand.name}: ${collection.name}`} />
                        ) : (
                          <CollectionImgPlaceholder />
                        )}
                      </CollectionImgContainer>
                      <CollectionInfo>
                        <h3 data-qa-hook="collectionName">{collection.name}</h3>
                        {hasDates && (
                          <InlineList content={'•'}>
                            {collection.startDate && isAfterToday(collection.startDate) && (
                              <li data-qa-hook="startsOn">
                                Starts {format(parseISO(collection.startDate), 'MMMM d, y')}
                              </li>
                            )}
                            {collection.endDate && (
                              <li data-qa-hook="endsOn">Ends {format(parseISO(collection.endDate), 'MMMM d, y')}</li>
                            )}
                          </InlineList>
                        )}
                        {hasAccess !== false && totalMembers >= 1 && (
                          <ProfileImageList srcUrls={group.profileImages} totalUsers={totalMembers} />
                        )}
                        {hasAccess ? (
                          <p data-qa-hook="collectionNumberOfMembers">{members}</p>
                        ) : (
                          <p>This collection is not included with your Basic subscription.</p>
                        )}
                      </CollectionInfo>
                    </ListItemFlexRow>
                  );
                })}
              </Collections>

              {brand.atHomeBanner && (
                <AtHomeBanner>
                  <img
                    src={brand.atHomeBanner.src}
                    alt={`${brand.name} at home`}
                    onClick={() => windowService.redirectTo(brand.atHomeBanner.url)}
                  />
                </AtHomeBanner>
              )}
            </>
          )}
        </Container>
      </FullWidthCardContainer>
    </>
  );
}
