angular.module('lwNamb').controller(
  'ManageCurriculumCtrl',

  [
    '$scope',
    '$log',
    'windowService',
    'curriculumService',
    'alertService',
    'userService',
    function($scope, $log, windowService, curriculumService, alertService, userService) {
      var init = function() {
        $scope.loading = true;
        $scope.curriculums = [];

        userService.user().then(function(user) {
          $scope.user = user;
          retrieveData();
        });
      };

      var retrieveData = function() {
        curriculumService.getAllCurriculum($scope.user.lastSelectedAccount).then(
          function(response) {
            $scope.loading = false;
            $scope.curriculums = response;
          },
          function(reason) {
            $scope.loading = false;
            $log.error('Failed to Retrieve Curriculum:' + reason);
            alertService.show({
              type: 'danger danger-with-content',
              title: 'Unexpected Error!',
              content: 'We were unable to retrieve your curriculum at this time.',
              duration: 20,
            });
          }
        );
      };

      $scope.redirectToSupport = function() {
        windowService.openUrl('https://support.ministrygrid.com/curriculum');
      };

      init();
    },
  ] //End Manage Curriculum Ctrl
);
