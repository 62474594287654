import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-elastic-carousel';
import { CarouselPagination } from '../components/CarouselElements';

const AUTO_PLAY_INTERVAL = 10000;

const FeatureCarousel = React.forwardRef((props, ref) => {
  const resetTimeout = useRef(null);

  useEffect(() => {
    return () => {
      if (resetTimeout.current) {
        clearTimeout(resetTimeout.current);
      }
    };
  }, []);

  return (
    <Carousel
      className="feature-carousel"
      ref={ref}
      itemsToShow={1}
      itemsToScroll={1}
      itemPadding={[32, 16, 24, 16]}
      initialFirstItem={props.initialFirstItem}
      showArrows={false}
      enableAutoPlay={props.enableAutoPlay}
      autoPlaySpeed={AUTO_PLAY_INTERVAL}
      focusOnSelect={props.focusOnSelect}
      onNextEnd={({ index }) => {
        clearTimeout(resetTimeout.current);
        if (ref.current && index + 1 === ref.current.state.pages.length) {
          resetTimeout.current = setTimeout(() => {
            if (ref.current) {
              ref.current.goTo(0);
            }
          }, AUTO_PLAY_INTERVAL);
        }
      }}
      renderPagination={paginationProps => <CarouselPagination carouselRef={ref} {...paginationProps} />}
    >
      {props.children}
    </Carousel>
  );
});

FeatureCarousel.displayName = 'FeatureCarousel';

FeatureCarousel.propTypes = {
  initialFirstItem: PropTypes.number,
  enableAutoPlay: PropTypes.bool,
  focusOnSelect: PropTypes.bool,
  children: PropTypes.node,
};

FeatureCarousel.defaultProps = {
  initialFirstItem: 0,
  enableAutoPlay: true,
};

export default FeatureCarousel;
