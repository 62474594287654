import React, { useRef } from 'react';
import { Formik, Form } from 'formik';
import { FormFieldContainer, FormField } from '../../components/FormElements';
import * as Yup from 'yup';
import useAutosave from '../../hooks/useAutosave';

const VideoSectionEditor = ({ content, onSave }) => {
  const nameRef = useRef();

  useAutosave(() => {
    onSave({ name: nameRef.current.value });
  }, [nameRef]);

  return (
    <Formik
      initialValues={{ name: content }}
      validationSchema={Yup.object({
        name: Yup.string().max(64, 'Video name is too long'),
      })}
    >
      {() => (
        <Form id="session-information-form">
          <FormFieldContainer>
            <FormField
              ref={nameRef}
              data-qa-hook="videoSectionName"
              label="Video Name"
              name="name"
              type="text"
              placeholder="Enter Video Name"
            />
          </FormFieldContainer>
        </Form>
      )}
    </Formik>
  );
};

export default VideoSectionEditor;
