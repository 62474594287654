import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SixteenNineAspectRatioPlaceholder } from '../components/Layout';
import WhiteLabel, { isGrid } from '../filters/WhiteLabel';
import apiClient from '../services/apiClient';
import useOrg from '../hooks/useOrg';

const LargeLogo = styled.img`
  max-width: 70%;
  height: auto;
  max-height: 40%;
`;

const SmallLogo = styled.img`
  max-width: 24px;
  height: auto;
`;

// Keeping this for reference for when we need to swap
// the large image for the smaller one when the container
// drops below a certain width threshold.
//   const sizeThreshold = 150;
//   const hasSmLogo = WhiteLabel.logoSmSrc && WhiteLabel.logoSmSrc !== "";
//   if (hasSmLogo) {
//     useEffect(() => {
//       const resizeObserver = new ResizeObserver((entries) => {
//         const isGreaterThanThreshold = entries[0].contentRect.width > sizeThreshold;
//         if (isGreaterThanThreshold !== useLargeLogo) setUseLargeLogo(isGreaterThanThreshold);
//       });

//       resizeObserver.observe(ref.current);

//       return () => resizeObserver.disconnect();
//     }, [useLargeLogo]);
//   }

const WhiteLabelLogoPlaceholder = () => {
  return (
    <SixteenNineAspectRatioPlaceholder>
      <LargeLogo alt={WhiteLabel.name} src={WhiteLabel.logoSrc} />
    </SixteenNineAspectRatioPlaceholder>
  );
};

const OrgLogoPlaceholder = ({ name, logoSrc }) => {
  const apiUrl = apiClient.getConfig().baseURL;
  const orgLogoSrc = `${apiUrl}/v1/images/org/${logoSrc}`;

  return (
    <SixteenNineAspectRatioPlaceholder>
      <LargeLogo alt={name} src={orgLogoSrc} />
    </SixteenNineAspectRatioPlaceholder>
  );
};

export default function TrainingPosterPlaceholder({ ownerName }) {
  const org = useOrg();

  const isOrgOwned = ownerName && ownerName != WhiteLabel.name;
  const hasLogo = org && org.logo;
  const useOrgLogo = isGrid() && isOrgOwned && hasLogo;

  return useOrgLogo ? <OrgLogoPlaceholder name={ownerName} logoSrc={org.logo} /> : <WhiteLabelLogoPlaceholder />;
}

TrainingPosterPlaceholder.propTypes = {
  ownerName: PropTypes.string,
};
