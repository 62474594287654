import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Type } from '../StyleGuide';

const AlertBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: ${Color.Secondary._05};
  border-radius: ${Border.radius};
  box-shadow: inset 0 0 0 1px rgba(81, 144, 197, 0.15);
  margin: ${Grid._5} 0;
  padding: ${Grid._4};
  color: ${Type.Color.dark};
  i {
    flex: 0 1 0%;
    color: ${Color.Blue._50};
    font-size: ${Type.Scale._6};
    margin-right: ${Grid._5};
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._4} ${Grid._6};
  }
`;

const AlertInfo = styled.div`
  flex: 1 1 0%;

  h4 {
    text-transform: capitalize;
  }
  p {
    color: ${Type.Color.medium};
  }
`;

const Link = styled.a`
  display: flex;
  margin-top: ${Grid._3};
`;

type Props = {
  title: string;
  description: string;
  link?: Array<{ id: string; url: string; target?: string; text: string }>;
  iconClass?: string;
  children?: React.ReactNode;
};

export default function HelpAndInfoBox({
  title,
  description,
  link,
  iconClass = 'fas fa-info-circle',
  children,
  ...props
}: Props) {
  return (
    <AlertBox {...props}>
      <i className={iconClass}></i>
      <AlertInfo>
        <h4>{title}</h4>
        <p>{description}</p>
        {link &&
          link.map(l => (
            <Link
              key={l.id}
              data-qa-hook="helpInfoBoxURL"
              href={l.url}
              target={l.target ? l.target : '_blank'}
              rel="noreferrer"
            >
              {l.text}
            </Link>
          ))}
      </AlertInfo>
      {children}
    </AlertBox>
  );
}
