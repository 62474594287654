import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../../StyleGuide';

const Question = styled.li`
  margin-bottom: ${Grid._10};
  .question-label {
    padding: 0;
    font-size: ${Type.Scale._2};
    color: ${Type.Color.medium};
  }
  .question-text {
    margin: ${Grid._4} auto;
    font-size: ${Type.Scale._4};
    color: ${Type.Color.dark};
  }
`;

export default Question;
