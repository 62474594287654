import { useContext } from 'react';
import SessionTimelineContext from '../contexts/SessionTimelineContext';

const getPrintPreferenceMap = crmName => {
  const defaultMap = {
    all: { title: 'All Groups', description: 'Print all group sections' },
    large_group_leader: { title: 'Large Group Only', description: 'Print large group sections only' },
    small_group_leader: { title: 'Small Group Only', description: 'Print small group section only' },
  };

  if (crmName === 'preschool' || crmName === 'kids')
    return {
      ...defaultMap,
      small_group_leader: { title: 'Team Time Only', description: 'Print Team Time section only' },
    };

  return defaultMap;
};

const getViewPreferenceMap = crmName => {
  const defaultMap = {
    all: { title: 'All Leaders', description: 'Show all group activities' },
    large_group_leader: { title: 'Large Group Only', description: 'Show large group activities only' },
    small_group_leader: { title: 'Small Group Only', description: 'Show small group activities only' },
  };

  if (crmName === 'preschool' || crmName === 'kids')
    return {
      ...defaultMap,
      small_group_leader: { title: 'Team Time Only', description: 'Show Team Time activities only' },
    };

  return defaultMap;
};

const getSegmentGroupMap = crmName => {
  const defaultMap = {
    leaders: 'Leaders',
    small: 'Small Group',
    large: 'Large Group',
  };

  if (crmName === 'preschool' || crmName === 'kids')
    return {
      ...defaultMap,
      small: 'Team Time',
    };

  return defaultMap;
};

const getSegmentLeaderMap = crmName => {
  const defaultMap = {
    all: 'All Leaders',
    small_group_leader: 'Small Group Leader',
    large_group_leader: 'Large Group Leader',
  };

  if (crmName === 'preschool' || crmName === 'kids')
    return {
      ...defaultMap,
      small_group_leader: 'Team Time Leader',
    };

  return defaultMap;
};

const getSegmentActivityMap = () =>
  Object.freeze({
    Craft: 'Craft',
    Game: 'Game',
    Lesson: 'Lesson',
    Printable: 'Printable',
    'Stage Game': 'Stage Game',
    'Team Game': 'Team Game',
    Transition: 'Transition',
  });

const getSectionTypeMap = () =>
  Object.freeze({
    body: { iconClass: 'fas fa-text-height', description: 'Add a section of text, lists or blockquotes' },
    script: { iconClass: 'fas fa-volume-up', description: 'Add a script box to be read out loud' },
    supplies: {
      iconClass: 'fas fa-tasks',
      description: 'Add a list of activity supplies',
      iconType: { craft: 'Craft', game: 'Game', school: 'School' },
    },
    tip: {
      iconClass: 'fas fa-lightbulb',
      description: 'Add a leader or safety tip',
      tipType: { leader: 'Leader Tip', safety: 'Safety Tip' },
    },
    video: { iconClass: 'fab fa-youtube', description: 'Add a section to present a video' },
  });

const useSessionTimeline = () => {
  const { timeline, setTimeline, curriculum, session } = useContext(SessionTimelineContext);

  const curriculumName = curriculum.name?.toLowerCase();
  const segmentGroupMap = getSegmentGroupMap(curriculumName);
  const segmentLeaderMap = getSegmentLeaderMap(curriculumName);
  const viewPreferenceMap = getViewPreferenceMap(curriculumName);
  const printPreferenceMap = getPrintPreferenceMap(curriculumName);
  const segmentActivityMap = getSegmentActivityMap();
  const sectionTypeMap = getSectionTypeMap();

  return {
    timeline,
    setTimeline,
    curriculum,
    session,
    segmentGroupMap,
    segmentLeaderMap,
    viewPreferenceMap,
    printPreferenceMap,
    segmentActivityMap,
    sectionTypeMap,
  };
};

export default useSessionTimeline;
