const BreakpointValues = {
  screen_xs: 480,
  screen_sm: 767,
  screen_md: 992,
  screen_lg: 1200,
};

const Grid = {
  _1: '2px',
  _2: '4px',
  _3: '8px',
  _4: '16px',
  _5: '24px',
  _6: '32px',
  _7: '40px',
  _8: '48px',
  _9: '56px',
  _10: '64px',
  _11: '80px',
  _12: '96px',
  _13: '112px',
  _14: '128px',
};

const Border = {
  radius: '4px',
};

const Breakpoints = {
  screen_xs: `${BreakpointValues.screen_xs}px`,
  screen_sm: `${BreakpointValues.screen_sm}px`,
  screen_md: `${BreakpointValues.screen_md}px`,
  screen_lg: `${BreakpointValues.screen_lg}px`,
};

const Color = {
  black: '#222',
  white: '#fff',
  Gray: {
    _01: '#fdfdfd',
    _05: '#fbfafa',
    _075: '#f8f8f8',
    _10: '#f4f4f4',
    _20: '#ededed',
    _30: '#dddddd',
    _50: '#b3b3b3',
    _70: '#808080',
    _75: '#6b6c7d',
    _80: '#5f6f84',
    _90: '#464854',
    _95: '#2a3541',
  },
  Green: {
    _10: '#dde3d0',
    _50: '#85a657',
  },
  Navy: {
    _10: '#eaedef',
    _50: '#788796',
  },
  Orange: {
    _50: '#ed4f32',
  },
  Red: {
    _05: '#fff5f5',
    _10: '#ffefef',
    _15: '#f3dede',
    _20: '#f1d2d2',
    _50: '#ce5c5b',
    _60: '#bc5554',
    _70: '#8f3d3c',
  },
  Yellow: {
    _05: '#FFF5E2',
    _10: '#FFE9BE',
    _20: '#FECF74',
    _30: '#EFC183',
    _40: '#CDA14D',
    _50: '#FEB528',
    _80: '#A87410',
    _100: '#373633',
  },
  Blue: {
    _10: '#f3f9ff',
    _125: '#EFF3FF',
    _15: '#dcdfe4',
    _20: '#d0d3d4',
    _30: '#cbd2e0',
    _50: '#5190c4',
    _60: '#427aa8',
  },
  Primary: {
    _05: '#e5faee',
    _10: '#d1ecdd',
    _20: '#b8dfc9',
    _50: '#4cb37c',
    _60: '#45a571',
    _70: '#2b723c',
  },
  Secondary: {
    _05: '#f2f6f9',
    _10: '#eaedef',
    _20: '#dae4ee',
    _30: '#c2d0dc',
    _40: '#a1b1c0',
    _50: '#788796',
    _80: '#63656f',
  },
  Accent: {
    _50: '#ed4f32',
  },
};

const Depth = {
  _1: '0 2px 2px rgba(0,0,0,0.05), 0 6px 10px rgba(0,0,0,0.05);',
};

const Shadow = {
  light: '0 2px 3px 0 rgba(0,0,0,0.05);',
  regular: '0 4px 4px 0 rgba(0,0,0,0.08);',
  small: '0 1px 2px 0 rgba(0,0,0,0.05);',
  medium: '0 4px 10px rgba(0,0,0,0.08);',
  large: '0 15px 25px rgba(0,0,0,0.06);',
};

const Transition = {
  slow: 'all 0.50s ease',
  normal: 'all 0.25s ease',
  fast: 'all 0.15s ease',
};

const Type = {
  family: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif !important`,
  Leading: {
    normal: '1.2',
    tall: '1.4',
    taller: '1.5',
    tallest: '1.7',
  },
  Scale: {
    _05: '10px',
    _1: '12px',
    _2: '14px',
    _3: '16px',
    _4: '18px',
    _5: '24px',
    _6: '32px',
    _7: '40px',
    _8: '48px',
  },
  Weight: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  Color: {
    dark: '#2A3541',
    mediumDark: '#3b4757',
    medium: '#5F6F84',
    white: Color.white,
    placeholder: '#9FA9B5',
    success: Color.Primary._70,
    error: Color.Red._70,
    caution: '#BC9C6C',
    info: Color.Blue._50,
  },
  Shadow: {
    light: '1px 1px 1px rgba(0, 0, 0, 0.1);',
    regular: '1px 1px 1px rgba(0, 0, 0, 0.2);',
    dark: '1px 1px 1px rgba(0, 0, 0, 0.3);',
  },
};

export { Grid, Border, Breakpoints, BreakpointValues, Color, Depth, Shadow, Transition, Type };
