export type License = {
  name: string;
  createdAt: string;
  startDate: string;
  id: string;
  managers: {
    email?: string;
    firstName?: string;
    lastName?: string;
    id: string;
  }[];
  users: Person[];
  subscription?: {
    item?: { title: string };
  };
};

export type OrgUserResponse = {
  _id?: { id: string };
  _type: 'PendingUser' | 'UserAndRole';
  dateCreated: string;
  email: string;
  firstName: string;
  lastName: string;
  profile?: { imageURL?: string };
};

export type Seats = {
  count: {
    occupied: number;
  };
  isLimited: boolean;
  isPermanent: boolean;
  occupants: Occupant[];
};

export type Occupant = {
  email: string;
  firstName: string;
  id: string;
  _type: 'PendingUser' | 'UserAndRole';
  lastName: string;
  seatedAt: string;
  profile?: {
    id: string;
    notificationEmail: {
      id: string;
    };
    imageUrl: string;
  };
};

export type Person = {
  id: string;
  email?: string;
  firstName: string;
  lastName: string;
  displayName: string;
  _type: 'PendingUser' | 'UserAndRole';
  profile?: {
    imageUrl: string;
  };
};

export const addDisplayName = (people: Person[] | Occupant[]) => {
  return people.map(person => ({
    ...person,
    displayName: person.firstName ? person.firstName + ' ' + person.lastName : '',
  }));
};
