import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MediumPrimaryButton, MediumButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';
import useAngularScope from '../hooks/useAngularScope';

const UnsentEmailsModal = ({ sendEmails }) => {
  const [route, setRoute] = useState('');
  const [angularScope] = useAngularScope();

  const navigateToRoute = () => (window.location = route);

  useEffect(() => {
    const deregister = angularScope.$on('$locationChangeStart', (event, route) => {
      deregister();
      event.preventDefault();
      setRoute(route);
    });

    return deregister;
  }, []);

  return (
    route !== '' && (
      <ModalContainer dismissHandler={navigateToRoute}>
        <ModalHeader>
          <h3>Emails Not Sent</h3>
        </ModalHeader>
        <ModalBody>
          <h4>
            You have unsent emails. They will not see this collection on their dashboard until you send the emails.
          </h4>
          <h4>Would you like to send emails to the list of people you added?</h4>
        </ModalBody>
        <ModalFooter>
          <MediumPrimaryButton data-qa-hook="UnsentEmailsModalYesButton" onClick={sendEmails}>
            Yes, Send Emails
          </MediumPrimaryButton>
          <MediumButton data-qa-hook="UnsentEmailsModalNoButton" onClick={navigateToRoute}>
            No
          </MediumButton>
        </ModalFooter>
      </ModalContainer>
    )
  );
};

UnsentEmailsModal.propTypes = {
  sendEmails: PropTypes.func.isRequired,
};

export default UnsentEmailsModal;
