import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Color, Shadow, Type } from '../StyleGuide';
import { Container } from '../components/Layout';
import NewPurchasesOverlay from '../curriculum/NewPurchasesOverlay';

const Banner = styled.div`
  margin: ${Grid._10} 0;
  border-top: 1px solid ${Color.Gray._20};
  background: ${Color.white};
  box-shadow: ${Shadow.large};
  color: ${Type.Color.dark};
`;

const Information = styled.div`
  display: flex;
  padding: ${Grid._8} ${Grid._5};
  h4 {
    text-transform: capitalize;
  }
  p {
    color: ${Type.Color.medium};
  }
  i {
    color: ${Color.Secondary._50};
    font-size: ${Type.Scale._6};
    margin-right: ${Grid._5};
  }
  a:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export default function BibleStudiesInformationBanner() {
  const [showOverlay, setShowOverlay] = useState(false);

  return (
    <>
      <Banner>
        <Container className="grid-container grid-sm-col-2">
          <Information>
            <div>
              <i className="fas fa-church"></i>
            </div>
            <div>
              <h4>How do digital curriculum subscriptions work?</h4>
              <p>Get started distributing, scheduling and managing curriculum for your church.</p>
              <a onClick={() => setShowOverlay(true)}>Learn More</a>
            </div>
          </Information>
          <Information>
            <div>
              <i className="fas fa-concierge-bell"></i>
            </div>
            <div>
              <h4>We're here to answer your questions</h4>
              <p>
                You can get in touch with us via the chat icon in the lower right hand corner or view our help page.{' '}
              </p>
              <a
                href="https://support.ministrygrid.com/en/collections/2590229-digital-curriculum-on-ministry-grid"
                target="_blank"
                rel="noreferrer"
              >
                View FAQs
              </a>
            </div>
          </Information>
        </Container>
      </Banner>
      {showOverlay && (
        <NewPurchasesOverlay
          showOverlay={showOverlay}
          curriculum={[]}
          instructionsType="curriculum"
          dismissHandler={() => setShowOverlay(false)}
        />
      )}
    </>
  );
}
