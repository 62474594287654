import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { Color } from '../../StyleGuide';
import {
  SessionLayout,
  SessionMenu,
  SessionMenuHeader,
  SessionMenuContents,
  SessionMenuButtons,
  SessionAgenda,
  SessionTimelineContainer,
  SessionTimelineHeader,
  DurationIcon,
  SessionMenuListItem,
  SessionTimelinePreferences,
} from '../../components/SessionTimelineElements';
import SessionTimelineContent from './SessionTimelineContent';
import { SectionLabel } from '../../components/SessionTimelineContentElements';
import { PageTitle } from '../../components/Layout';
import sessionTimelineService from '../../services/sessionTimelineService';
import alertService from '../../services/AlertService';
import { useUser } from '../../authentication';
import { handleError } from '../../utils/apiUtils';
import { InlineRadioList, InlineRadioListItem } from '../../components/FormElements';
import PrintPreferenceModal from './PrintPreferenceModal';
import useSessionTimeline from '../../hooks/useSessionTimeline';

const SegmentGroupIconMap = Object.freeze({
  leaders: 'fas fa-chalkboard-teacher',
  small: 'fas fa-user-friends',
  large: 'fas fa-users',
});

const ReorderableSessionMenuContents = SortableContainer(props => (
  <SessionMenuContents>{props.children}</SessionMenuContents>
));
const ReorderableSessionMenuItem = SortableElement(props => (
  <SessionMenuListItem className={props.className}>{props.children}</SessionMenuListItem>
));
const OrderingDragHandle = SortableHandle(() => (
  <i data-qa-hook="menuDragIcon" className="fas fa-bars drag-handle"></i>
));

const SessionTimeline = ({ title, subtitle, customizable, useViewPreferences, actionButtons, ...props }) => {
  const user = useUser();
  const { timeline, setTimeline, viewPreferenceMap, segmentGroupMap, segmentLeaderMap } = useSessionTimeline();

  const [showPrintModal, setShowPrintModal] = useState(false);

  const { stickyOffset = 60, scrollContainerId = '', topOffset = 60 } = props.config ?? {};

  const showGroupHeader = (currentGroup, i) => currentGroup !== timeline.segments[i - 1].group;

  const getIndexFromSortableIndex = sortableIndex => sortableIndex / 10;

  const getSortableIndex = (segmentIndex, isHeader = false) => (isHeader ? segmentIndex * 10 - 5 : segmentIndex * 10);

  const didChangeOrder = (oldIndex, newIndex) => Math.abs(oldIndex - newIndex) >= 10;

  const reorderSegments = ({ oldIndex, newIndex }) => {
    if (!didChangeOrder(oldIndex, newIndex)) return;

    const [reordered, original] = sessionTimelineService.changeOrderOfSegment(
      timeline,
      getIndexFromSortableIndex(oldIndex),
      getIndexFromSortableIndex(newIndex)
    );

    setTimeline(previousTimeline => sessionTimelineService.reorderSegmentsInTimeline(previousTimeline, reordered));

    sessionTimelineService
      .customizeSegmentOrder(
        timeline.id,
        timeline.originalTimelineId,
        user.lastSelectedAccount,
        reordered.map(s => s.segmentId),
        user.userId
      )
      .then(() => {
        alertService.show('Leader Guide Updated');
        setTimeline(previousTimeline => sessionTimelineService.clearTimelineArchiveId(previousTimeline));
      })
      .catch(error => {
        handleError(error);
        setTimeline(previousTimeline => sessionTimelineService.reorderSegmentsInTimeline(previousTimeline, original));
      });
  };

  const updateLeaderViewPreference = preference => {
    setTimeline(previousTimeline =>
      sessionTimelineService.updateViewPreferenceForTimeline(previousTimeline, preference)
    );

    sessionTimelineService.setLeaderViewPreference(timeline.curriculumId, preference).catch(console.error);
  };

  const shouldShowSegment = segment =>
    !useViewPreferences || sessionTimelineService.matchesLeaderViewPreference(timeline.viewPreference, segment.leader);

  return (
    <>
      <SessionLayout stickyOffset={stickyOffset} topOffset={topOffset}>
        <SessionAgenda>
          <div className="session-agenda__title">
            <h3>Agenda</h3>
            <p>
              <i className="fas fa-clock"></i> {timeline.duration} Minutes
            </p>
          </div>
          <ol className="session-agenda__list">
            {timeline.segments?.map((segment, i, segments) => (
              <li key={segment.segmentId} className="session-agenda__list-item">
                {i > 0 && segment.group !== segments[i - 1].group && (
                  <div className="session-agenda__list-item__transition">
                    <i className={SegmentGroupIconMap[segment.group]} />
                    <div>
                      <h5>{segmentGroupMap[segment.group]}</h5>
                      <p>
                        Transition from {segmentGroupMap[segments[i - 1].group]} to {segmentGroupMap[segment.group]}
                      </p>
                    </div>
                  </div>
                )}
                <div className="session-agenda__list-item__segment">
                  <div>
                    <h5>
                      {i + 1} - {segment.name}
                    </h5>
                    <p>{segmentLeaderMap[segment.leader]}</p>
                  </div>
                  <span>
                    <i className="fas fa-clock"></i> {segment.duration} Minutes
                  </span>
                </div>
              </li>
            ))}
          </ol>
        </SessionAgenda>

        <SessionMenu>
          <SessionMenuButtons>{actionButtons}</SessionMenuButtons>
          <SessionMenuHeader>
            <h3 className="no-margin-top">Leader Guide Contents</h3>
            <p data-qa-hook="timelineDuration-Menu">
              <i className="fas fa-clock"></i>
              {` ${timeline.duration} Minutes`}
            </p>
          </SessionMenuHeader>

          <ReorderableSessionMenuContents onSortEnd={reorderSegments} useDragHandle>
            {!!timeline.segments.length &&
              timeline.segments.map((segment, i) => (
                <React.Fragment key={`timeline-menu-contents-${i}`}>
                  {(i === 0 || showGroupHeader(segment.group, i)) && (
                    <ReorderableSessionMenuItem index={getSortableIndex(i, true)}>
                      <Link
                        containerId={scrollContainerId}
                        activeClass={i === 0 ? '' : 'active'}
                        className="group indented"
                        name={i === 0 ? `contents-${segment.segmentId}` : `transition-${i}`}
                        to={i === 0 ? `contents-${segment.segmentId}` : `transition-${i}`}
                        spy={i !== 0}
                        smooth={true}
                        duration={500}
                        offset={stickyOffset * -1}
                      >
                        <i className={SegmentGroupIconMap[segment.group]} /> {segmentGroupMap[segment.group]}
                      </Link>
                    </ReorderableSessionMenuItem>
                  )}
                  {shouldShowSegment(segment) && (
                    <ReorderableSessionMenuItem
                      index={getSortableIndex(i)}
                      className={!segment.enabled ? 'excluded-segment' : ''}
                    >
                      <Link
                        data-qa-hook={`contentMenuSegment-${i}`}
                        containerId={scrollContainerId}
                        activeClass="active"
                        className="indented"
                        name={`contents-${segment.segmentId}`}
                        to={`contents-${segment.segmentId}`}
                        spy={true}
                        smooth={true}
                        duration={500}
                        offset={stickyOffset * -1}
                      >
                        {segment.name}
                      </Link>
                      {customizable && <OrderingDragHandle />}
                    </ReorderableSessionMenuItem>
                  )}
                </React.Fragment>
              ))}
          </ReorderableSessionMenuContents>
        </SessionMenu>
        <SessionTimelineContainer>
          <SessionTimelineHeader className={!useViewPreferences ? 'mb-48' : ''}>
            <PageTitle>
              <h1>{title}</h1>
              <p>{subtitle}</p>
            </PageTitle>
            <div>
              <DurationIcon duration={timeline.duration} />
            </div>
          </SessionTimelineHeader>
          {useViewPreferences && (
            <SessionTimelinePreferences className="mt-24 mb-32">
              <SectionLabel>Preferences</SectionLabel>
              <InlineRadioList>
                {Object.entries(viewPreferenceMap).map(([key, value]) => (
                  <InlineRadioListItem
                    key={key}
                    title={value.title}
                    description={value.description}
                    checkedColor={Color.Blue._50}
                    checked={timeline.viewPreference === key}
                    onChange={() => updateLeaderViewPreference(key)}
                  />
                ))}
              </InlineRadioList>
            </SessionTimelinePreferences>
          )}
          <SessionTimelineContent customizable={customizable} useViewPreferences={useViewPreferences} />
        </SessionTimelineContainer>
      </SessionLayout>
      {showPrintModal && (
        <PrintPreferenceModal
          leaderPreference={timeline.viewPreference}
          handleDismiss={() => setShowPrintModal(false)}
        />
      )}
    </>
  );
};

SessionTimeline.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  customizable: PropTypes.bool,
  useViewPreferences: PropTypes.bool,
  actionButtons: PropTypes.object,
};

export default SessionTimeline;
