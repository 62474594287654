import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Grid, Color, Type, Transition } from '../StyleGuide';
import LoadingState from './LoadingState';
import ErrorMessage from './ErrorMessage';
import { Container, FixedHeaderContainer, FixedHeaderContent, RaisedContainer } from './Layout';
import { RoundedIconButton } from './Buttons';
import { ChurchImage } from './PairingElements';
import ActionableTitleBar from './ActionableTitleBar';
import alertService from '../services/AlertService';
import userService from '../services/userService';
import orgService from '../services/orgService';
import windowService from '../services/windowService';
import { useUser } from '../authentication';
import WhiteLabel from '../filters/WhiteLabel';
import useQueryParams from '../hooks/useQueryParams';

const ChurchItem = styled(RaisedContainer)`
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${Grid._4};
  align-items: center;
  padding: ${Grid._4};
  width: 100%;
  border: 2px solid transparent;
  transition: ${Transition.fast};
  &:hover:not(.active) {
    border-color: ${Color.Gray._80};
    box-shadow: none;
    cursor: pointer;
  }
  &.active {
    border-color: ${Color.Gray._80};
    border-top-width: ${Grid._6};
    &:before {
      content: 'Current ${WhiteLabel.labels.org}';
      position: absolute;
      top: -27px;
      width: 100%;
      text-align: center;
      font-size: ${Type.Scale._2};
      text-transform: uppercase;
      text-shadow: ${Type.Shadow};
      color: ${Color.white};
    }
  }
`;

export default function SwitchOrg() {
  const user = useUser();
  const currentOrgId = user.lastSelectedAccount;
  const [status, setStatus] = useState({ isLoading: true, isError: false });
  const [orgs, setOrgs] = useState([]);
  const { redirect } = useQueryParams();

  const switchChurch = orgId => {
    if (orgId === currentOrgId) return;

    setStatus({ ...status, isLoading: true });

    window.addEventListener('USER_LOADED', () => windowService.redirectTo(redirect || '/dashboard'), { once: true });

    userService.switchOrg(user.userId, orgId).catch(reason => {
      console.error(reason);
      alertService.show('An error occurred. Please try again.', 'error');
      setStatus({ ...status, isLoading: false });
    });
  };

  useEffect(() => {
    userService
      .getUserOrgs()
      .then(data => {
        setOrgs(orgService.putOrgAtBeginning(currentOrgId, orgService.sortAlphabetically(data)));
        setStatus({ ...status, isLoading: false });
      })
      .catch(reason => {
        console.error(reason);
        setStatus({ isLoading: false, isError: true });
      });
  }, []);

  return (
    <FixedHeaderContainer data-qa-hook="switchOrganizationView" fullViewport>
      <ActionableTitleBar
        title="Select A Church"
        leftButton={<div />}
        rightButton={
          <RoundedIconButton data-qa-hook="back" onClick={windowService.redirectBack}>
            <i className="fas fa-times fa-fw"></i>
            Close
          </RoundedIconButton>
        }
      />
      <FixedHeaderContent>
        <Container>
          {status.isLoading ? (
            <LoadingState />
          ) : status.isError ? (
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          ) : (
            <>
              <div
                data-qa-hook="orgCardContainer"
                className="grid-container grid-col-1 grid-sm-col-2 grid-gap-32"
                style={{ margin: `${Grid._10} 0` }}
              >
                {orgs.map((org, i) => (
                  <ChurchItem
                    data-qa-hook="orgCard"
                    key={i}
                    onClick={() => switchChurch(org._id.id)}
                    className={org._id.id === currentOrgId ? 'active' : ''}
                  >
                    <ChurchImage church={org} />
                    <h4 data-qa-hook="orgName">{org.name}</h4>
                  </ChurchItem>
                ))}
              </div>
            </>
          )}
        </Container>
      </FixedHeaderContent>
    </FixedHeaderContainer>
  );
}
