import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { User, useUser } from '../authentication';
import orgService from '../services/orgService';
import GroupContainer from '../groups/GroupContainer';
import LoadingState from '../components/LoadingState';
import Breadcrumbs from '../components/Breadcrumbs';
import AddPeopleTo from '../components/AddPeopleTo';
import { getLicenses, grantAccess, getUsersNotAddedToLicense } from './api';
import type { License, OrgUserResponse, Person } from './models/license';
import windowService from '../services/windowService';
import alertService from '../services/AlertService';
import ErrorMessage from '../components/ErrorMessage';
import { PageTitle } from '../components/Layout';
import WhiteLabel from '../filters/WhiteLabel';
import { handleError } from '../utils/apiUtils';

export const AddPeopleToLicense = () => {
  const user = useUser();
  const { licenseId } = useParams<{ licenseId: string }>();
  const orgId = user?.lastSelectedAccount || '';
  const userId = user?.userId;

  const [data, setData] = useState<{
    people?: Person[];
    license?: License | null;
    groups?: { id: string; name: string }[];
    roles?: string[];
    isLoading?: boolean;
    isSubmitting?: boolean;
    isError?: boolean;
  }>({
    people: [],
    license: null,
    groups: [],
    roles: [],
    isLoading: true,
    isSubmitting: false,
    isError: false,
  });

  const { people, license, isLoading, isSubmitting, isError } = data;

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Purchases and Subscriptions', route: '#/org/purchases-and-subscriptions' },
    ...(license?.name
      ? [{ name: license?.name, route: `#/org/purchases-and-subscriptions/${licenseId}` }, { name: 'Add People' }]
      : []),
  ];

  useEffect(() => {
    const getLicenseInfo = getLicenses(orgId, licenseId);
    const getOrgUsers = orgService.getOrgUsers(orgId);
    const getNotAddedUsers = getUsersNotAddedToLicense(orgId, licenseId);
    const getRoles = orgService.getRolesOverview(orgId, true);

    Promise.all([getLicenseInfo, getOrgUsers, getNotAddedUsers, getRoles])
      .then(response => {
        setData({ license: response[0], people: response[2], groups: response[1].groups, roles: response[3] });
      })
      .catch(e => {
        console.error(e);
        setData({ isError: true, isLoading: false });
      });
  }, [licenseId, orgId]);

  const handleAddPeople = (selectedPeople: OrgUserResponse[]) => {
    setData(prev => ({ ...prev, isSubmitting: true }));

    grantAccess(licenseId, selectedPeople, orgId || '', userId || '')
      .then(res => {
        alertService.showOnNextPage(
          `${selectedPeople.length} ${selectedPeople.length > 1 ? 'People' : 'Person'} Added`
        );
        windowService.redirectTo(`#/org/purchases-and-subscriptions/${licenseId}`);
      })
      .catch(e => {
        handleError(e);
        setData({ isLoading: false, isSubmitting: false });
      });
  };

  return isError ? (
    <ErrorMessage />
  ) : (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <GroupContainer rounded>
        <header className="space-between">
          <PageTitle>
            <h1>Add People to License</h1>
            <p className="group-desc">Select people from your {WhiteLabel.labels.org} to add to this license</p>
          </PageTitle>
        </header>
        <section>
          {data.isLoading ? (
            <LoadingState />
          ) : (
            <AddPeopleTo
              user={user as User}
              isSubmitting={isSubmitting || false}
              alreadyAddedPeople={people || []}
              handleAddPeople={handleAddPeople}
              filters={{
                roles: { displayName: 'Roles', items: data.roles },
                groups: { displayName: 'Groups', items: data.groups },
              }}
            />
          )}
        </section>
      </GroupContainer>
    </>
  );
};
