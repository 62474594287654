angular.module('lwNamb').service('serverPropertiesService', [
  'api',
  '$log',
  '$q',
  function(api, $log, $q) {
    'use strict';

    var retrievedProperties = {};

    // var getServerProperty = function (property) {
    //   return api
    //     .get('/v1/environment-properties/' + property)
    //     .then(function (result) {
    //       retrievedProperties[property] = result.data.property;
    //       return result.data.property;
    //     }, function (e) {
    //       $log.error('could not retrieve server property ' + property + ': ' + e.data);
    //       return e.data;
    //     });
    // };
    //
    // var getProperty = function (property) {
    //   return retrievedProperties[property] ? $q.when(retrievedProperties[property]) : getServerProperty(property);
    // };

    //grid and GC have the same orgID in all envs
    this.getGridCommunityOrgId = function() {
      return $q.when('caec3234-15cd-43c6-beed-5436080693ba');
    };

    this.getGridRootOrgId = function() {
      return $q.when('4ae36f78-299a-425d-91ef-e14a899b725f');
    };
  },
]);
