import React from 'react';
import PropTypes from 'prop-types';
import TrainingWrapper from '../components/TrainingWrapper';
import { TrainingDetailsHeader } from '../components/TrainingDetailsElements';
import ProfileImage from '../components/ProfileImage';
import Course from './Course';
import WhiteLabel from '../filters/WhiteLabel';
import { useUser } from '../authentication';
import { Button } from '../components/Buttons';
import Reports from './Reports';
import windowService from '../services/windowService';

export default function CourseCard({ updateAssignment, assignment, assignmentLabel, sendReminder }) {
  const user = useUser();

  const training = assignment;
  const progressType = training.assignable.progress._type;
  const isComplete = progressType === 'complete' || progressType === 'Finished';
  const hasCertificate = training.certificateInfo ? training.certificateInfo : null;
  const isLocked = training.purchaseRequired;
  const assigneeId = training.assignee.userId;
  const userIsAssignee = assigneeId === user.userId;
  const showAction = !isLocked && userIsAssignee && !isComplete;
  const showStart = showAction && progressType === 'NotStarted';
  const showResume = showAction && progressType === 'Started';
  const instanceId = training.assignable.taskListInstanceId.id;
  const trainingName = training.assignable.name;
  const profileImageUrl = training.assignee.profileImageUrl;
  const assigneeName = training.assignee.name;
  const completeReports = isComplete ? training.assignable.reports?.filter(r => !!r?.reportId) : [];
  return (
    <TrainingWrapper data-qa-hook="assignmentDetailTraining">
      <TrainingDetailsHeader data-qa-hook="managedTaskList">
        <div>
          <ProfileImage size="100px" url={profileImageUrl || 'img/default_profile_photo.png'} />
        </div>
        <div>
          <p>
            <i className="fas fa-chevron-left" style={{ marginRight: '5px' }} />
            <a onClick={() => windowService.redirectTo('/manage-assignments')} className="link text-capitalize">
              {assigneeName ?? 'Your Training'}
            </a>
          </p>
          <p className="visible-sm-block visible-xs-block text-capitalize">{assignmentLabel}</p>
          <Course
            updateCourse={updateAssignment}
            course={training.assignable}
            isLocked={training.purchaseRequired}
            description={training.description}
            assignedBy={training.assignor.name}
            assigneeName={training.assignee.name}
            assigneeId={training.assignee.userId}
            showStart={showStart}
            showResume={showResume}
            purchaseRequired={isLocked}
            isComplete={isComplete}
            hasCertificate={hasCertificate}
            instanceId={instanceId}
            reminderButton={
              !userIsAssignee &&
              !isComplete && (
                <Button
                  onClick={() =>
                    sendReminder(
                      trainingName,
                      training.assignee.userId,
                      user.firstName,
                      WhiteLabel.name,
                      user.lastSelectedAccount
                    )
                  }
                >
                  Send Reminder
                </Button>
              )
            }
          />
          {!!completeReports.length && (
            <Reports
              reports={completeReports}
              tasklistName={trainingName}
              assigneeName={assigneeName}
              tasklistInstanceId={instanceId}
            />
          )}
        </div>
      </TrainingDetailsHeader>
    </TrainingWrapper>
  );
}

CourseCard.propTypes = {
  assignment: PropTypes.object.isRequired,
  assignmentLabel: PropTypes.string.isRequired,
  sendReminder: PropTypes.func,
  updateAssignment: PropTypes.func,
};
