import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Answers from './Answers';
import Question from './Question';

const Questions = styled.ol`
  list-style: none;
  padding: 0 !important;
  margin: 0;
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.05);
`;

const Label = ({ index, length }) => {
  return (
    <p className="question-label">
      Question {index + 1} of {length}
    </p>
  );
};

const Results = ({ questions }) => (
  <>
    {questions && (
      <Questions>
        {questions.map((q, i) => {
          return (
            <Question key={q.questionId.id}>
              <Label index={i} length={questions.length} />
              <p className="question-text">{q.questionText}</p>
              {q.questionType === 'radio' && <Answers isGraded={q.isGraded} answers={q.answers} />}
              {q.questionType === 'textbox' && <p>{q.answers[0].value}</p>}
            </Question>
          );
        })}
      </Questions>
    )}
  </>
);
Results.propTypes = {
  questions: PropTypes.array.isRequired,
};

export default Results;
