import React from 'react';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Shadow, Type } from '../StyleGuide';

const GroupContainer = styled.article<{ rounded?: boolean }>`
  max-width: 1140px;
  margin: ${Grid._5} auto;
  header {
    padding: 0 ${Grid._5} ${Grid._5};
    display: flex;
    flex-direction: column;
    @media screen and (min-width: ${Breakpoints.screen_lg}) {
      padding: ${Grid._4} 0 ${Grid._7};
    }
  }
  header h1 {
    font-weight: ${Type.Weight.bold};
    font-size: ${Type.Scale._6};
    color: ${Type.Color.dark};
    margin: 0;
    order: 1;
    opacity: 1;
    transition: opacity 0.25s ease-out;
  }
  header .group-desc {
    margin: ${Grid._3} 0 0;
    color: ${Type.Color.medium};
    max-width: 540px;
    order: 2;
    font-size: ${Type.Scale._4};
    line-height: ${Type.Leading.taller};
  }
  header.space-between {
    display: block;
    div:first-of-type {
      display: flex;
      flex-direction: column;
      padding-right: ${Grid._3};
    }
    div:not(:first-of-type) {
      margin-top: ${Grid._4};
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      display: flex;
      flex-direction: row;
      div:not(:first-of-type) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0;
      }
    }
  }
  section {
    background: white;
    padding: ${Grid._5};
    box-shadow: 0 0 0 1px ${Color.Blue._15}, ${Shadow.regular};
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      padding: ${Grid._7};
    }
    @media screen and (min-width: ${Breakpoints.screen_lg}) {
      border-radius: ${props => (props.rounded ? `${Border.radius}` : `0 0 ${Border.radius} ${Border.radius}`)};
    }
  }
  .group-page-tabs {
    box-shadow: 0 0 0 1px ${Color.Blue._15}, ${Shadow.regular};
    background: ${Color.Gray._05};
    list-style: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    @media screen and (min-width: ${Breakpoints.screen_lg}) {
      border-radius: ${Border.radius} ${Border.radius} 0 0;
    }
    button {
      padding: ${Grid._4} ${Grid._7};
      border: 0;
      font-weight: ${Type.Weight.semibold};
      background: transparent;
    }
    li {
      display: inline-block;
      color: ${Type.Color.medium};
      border-right: 1px solid ${Color.Blue._15};
      font-size: ${Type.Scale._3};
    }
    .active-tab {
      background: white;
      color: ${Type.Color.dark};
      span {
        opacity: 1;
      }
    }
    span {
      display: none;
      @media screen and (min-width: ${Breakpoints.screen_sm}) {
        display: inline-block;
        opacity: 0.6;
        color: ${Type.Color.medium};
        font-size: ${Type.Scale._2};
        position: relative;
        top: -1px;
      }
    }
    .fa-user-friends {
      margin-right: ${Grid._2};
    }
    .fa-cog {
      margin-right: ${Grid._1};
    }
  }
  .group-people-list {
    tr {
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        grid-template-columns: 48px 1fr 2fr minmax(140px, auto) minmax(152px, auto);
      }
    }
  }
  .group-person-row._isPending .group-person-row__name span {
    color: ${Type.Color.medium};
    font-weight: ${Type.Weight.normal};
    opacity: 0.75;
    display: block;
  }
  .group-people-list {
    margin-top: ${Grid._4};
    width: 100%;
    transition: opacity 0.25s ease-out;
    opacity: 1;
    &.isUpdating {
      opacity: 0.8;
    }
    tr {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        display: grid;
        grid-column-gap: ${Grid._5};
      }
    }
    th {
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        padding: 0 0 ${Grid._4};
      }
    }
    td {
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        padding: ${Grid._4} 0;
      }
    }
    thead {
      display: none;
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        display: block;
      }
    }
    thead tr {
      border-bottom: ${Grid._2} solid ${Type.Color.medium};
      color: ${Type.Color.dark};
    }
    tbody {
      display: grid;
      grid-gap: ${Grid._4};
      @media screen and (min-width: ${Breakpoints.screen_sm}) {
        grid-template-columns: 1fr 1fr;
      }
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        grid-gap: 0;
        grid-template-columns: 1fr;
      }
    }
    tbody tr {
      border: 1px solid ${Color.Blue._15};
      border-radius: ${Border.radius};
      color: ${Type.Color.medium};
      align-items: center;
      padding: ${Grid._4};
      cursor: pointer;
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        border-radius: 0;
        padding: 0;
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
      }
    }
    tbody td {
      width: 100%;
      text-align: center;
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        width: auto;
      }
    }
    .group-person-row__profile-img {
      padding-bottom: ${Grid._4};
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        padding-left: ${Grid._3};
      }
      span,
      img {
        font-size: ${Grid._8};
        position: relative;
        width: ${Grid._8};
        height: ${Grid._8};
        @media screen and (min-width: ${Breakpoints.screen_md}) {
          font-size: ${Grid._6};
          width: ${Grid._6};
          height: ${Grid._6};
        }
      }
    }
    .group-person-row__name {
      color: ${Type.Color.dark};
      font-weight: ${Type.Weight.semibold};
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        text-align: left;
      }
    }
    .group-person-row__role {
      padding: ${Grid._4} 0;
    }
    .group-person-row._isLeader {
      background: ${Color.Blue._10};
      .group-person-row__role span {
        padding: ${Grid._2} ${Grid._3};
        border-radius: ${Border.radius};
        background: ${Color.Gray._80};
        color: white;
      }
      &:hover {
        background: ${Color.Blue._125};
      }
    }
    tbody tr {
      transition: background 0.15s ease-in-out;
    }
    tbody tr:hover {
      background: ${Color.Gray._05};
    }
    .group-person-row._isPending {
      background: ${Color.Gray._075};
      .group-person-row__role {
        color: ${Type.Color.medium};
      }
    }
    .group-person-row._isPending:hover {
      background: ${Color.Gray._10};
    }
    .fa-user-circle {
      color: ${Color.Blue._15};
    }
    select {
      border-color: ${Color.Blue._15};
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        width: 152px;
      }
    }
    .group-person-row._isUser {
      .group-person-row__name span {
        color: ${Type.Color.medium};
        margin-left: ${Grid._3};
        background: rgba(0, 0, 0, 0.025);
        padding: ${Grid._2} ${Grid._3};
        border-radius: ${Grid._7};
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-size: ${Type.Scale._05};
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
  .group-settings__name,
  .group-settings__download {
    color: ${Type.Color.dark};
    max-width: 480px;
    margin: 0 auto;
    label {
      margin: 0 0 ${Grid._3};
      color: ${Type.Color.dark};
      font-weight: ${Type.Weight.semibold};
    }
  }
  .group-settings__download {
    margin-top: ${Grid._7};
    padding-top: ${Grid._7};
    border-top: 2px solid ${Color.Blue._15};
  }
  .group-person-row__radio {
    span {
      margin: 0 auto;
      width: ${Grid._5};
      height: ${Grid._5};
      border-radius: ${Grid._5};
      box-shadow: inset 0 0 0 2px ${Color.Gray._80};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: ${Type.Scale._05};
      color: transparent;
    }
  }
  .group-settings__name-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: ${Grid._3};
  }
  .ui-state-message {
    font-size: ${Type.Scale._1};
    padding-left: ${Grid._4};
    position: relative;
    margin: ${Grid._3} 0 0;
    .icon {
      font-size: ${Type.Scale._2};
      margin-right: ${Grid._1};
      color: ${Color.Red._50};
      position: absolute;
      top: -1px;
      left: 0;
    }
  }
  .group-people-list tbody tr {
    order: 2;
  }
  group-person-row._isPending {
    order: 3;
  }
  .group-person-row._isLeader {
    order: 1;
  }
  .group-person-row._isUser {
    order: 0 !important;
  }
  .group-person-row._isSelected {
    .group-person-row__radio {
      span {
        box-shadow: none;
        background: ${Color.Primary._50};
        color: white;
      }
    }
    &:hover {
    }
  }
  .group-people-row.isLoading {
    opacity: 0 !important;
    display: inline-block !important;
  }
  .sort-selections-container {
    min-height: ${Grid._10};
    display: grid;
    grid-template-columns: 1fr 100px;
    padding: ${Grid._3} 0;
    div:nth-child(2) {
      display: flex;
      align-items: center;
      grid-column-start: 1;
      padding-top: ${Grid._2};
    }
    div:nth-child(3) {
      grid-row-end: span 2;
      grid-column-start: 2;
      grid-row-start: 1;
      justify-content: flex-end;
      display: flex;
    }
    .active-filters {
      display: none;
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      display: grid;
      grid-template-columns: max-content 1fr max-content;
      align-items: center;
      padding: 0;
      min-height: ${Grid._9};
      div:nth-child(2) {
        grid-column-start: auto;
        padding-top: 0;
      }
      div:nth-child(3) {
        grid-column-start: auto;
        grid-row-end: auto;
        grid-row-start: auto;
      }
      .active-filters {
        border-left: 1px solid ${Color.Blue._15};
        padding-left: ${Grid._4};
        display: block;
      }
    }
    p {
      margin: 0 ${Grid._4} 0 0;
      color: ${Type.Color.dark};
    }
    p:first-of-type {
      margin-right: ${Grid._4};
    }
    .sort-tag {
      display: flex;
      cursor: pointer;
      color: white;
      background: ${Color.Gray._80};
      padding: ${Grid._2} ${Grid._4};
      border-radius: ${Grid._7};
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: ${Type.Scale._05};
      align-items: center;
      span {
        margin-left: ${Grid._3};
      }
      &:hover {
        background: ${Color.Gray._90};
      }
    }
  }
  .add-people-controls {
    align-items: end;
    .add-people-sort {
      display: flex;
      flex-direction: column;
    }
    label {
      font-weight: ${Type.Weight.semibold};
      color: ${Type.Color.dark};
      text-transform: capitalize;
    }
    select {
      height: 46px;
    }
  }
  .add-people-controls .primary-action {
    height: 46px;
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      order: 5;
      min-height: 46px;
    }
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      grid-column-start: auto;
    }
  }
`;

export default GroupContainer;
