angular.module('lwNamb').controller(
  'PeopleCtrl',
  [
    '$scope',
    'orgUserService',
    '$log',
    'apiUrlService',
    'inviteService',
    'alertService',
    'orgService',
    '$localStorage',
    'windowService',
    function(
      $scope,
      orgUserService,
      $log,
      apiUrlService,
      inviteService,
      alertService,
      orgService,
      $localStorage,
      windowService
    ) {
      var init = function() {
        $scope.isLoading = true;
        $scope.members = [];
        $scope.filterMembers = '';

        if ($localStorage.filters !== undefined && $localStorage.filters.view === 'people') {
          $scope.filters = $localStorage.filters;
          $scope.filters.redirectsLeft = undefined;
          $scope.filters.view = undefined;
        } else {
          $scope.clearInput();
        }

        loadMembers();
      };

      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

      $scope.clearInput = function() {
        $scope.filters = {
          groups: [],
          roles: [],
          filterMembers: '',
          searchByTextField: '',
        };
      };

      $scope.redirectTo = function(path) {
        $localStorage.filters = $scope.filters;
        if ($localStorage.filters !== undefined) {
          $localStorage.filters.redirectsLeft = 2;
          $localStorage.filters.view = 'people';
        }
        windowService.redirectHash(path);
      };

      $scope.allRoleNames = [];
      $scope.allGroupNames = [];

      $scope.rnf = function(member) {
        return (
          $scope.filters.roles.length === 0 ||
          $scope.filters.roles.some(function(r) {
            return member.payload.roleNames.indexOf(r.name) > -1;
          })
        );
      };
      $scope.gnf = function(member) {
        return (
          $scope.filters.groups.length === 0 ||
          $scope.filters.groups.some(function(g) {
            return member.payload.groups.indexOf(g.id) > -1;
          })
        );
      };

      function insensitiveCaseSort(a, b) {
        a = a.name ? a.name.toLowerCase() : a.toLowerCase();
        b = b.name ? b.name.toLowerCase() : b.toLowerCase();
        if (a === b) return 0;
        if (a > b) return 1;
        return -1;
      }

      function areSameMember(a, b) {
        return a.email === b.email;
      }

      function getUniqueInvitedMembers(invitedMembers, members) {
        return invitedMembers
          .filter(function(i) {
            return !members.find(function(m) {
              return areSameMember(i, m);
            });
          })
          .map(transformMember);
      }

      function transformMember(member) {
        return {
          id: member._id !== undefined ? member._id : { id: undefined },
          inviteId: member.inviteId !== undefined ? member.inviteId.id : undefined,
          firstName: member.firstName,
          lastName: member.lastName,
          email: member.email !== undefined ? member.email : member.emailAddress,
          profileImageUrl:
            member._id !== undefined && member.profile !== undefined && member.profile.imageUrl
              ? apiUrlService.getUrl() + '/v1/images/profile/' + member._id.id + 'profile.png'
              : undefined,
          isYou: member._id && member._id.id === $scope.user.userId,
          status: member._type === 'PendingUser' ? 'Pending' : 'Accepted',
          payload: member,
        };
      }

      function loadMembers() {
        orgUserService.getMembers($scope.user.lastSelectedAccount).then(
          function(members) {
            var allRoles = [];
            members.groups.sort(insensitiveCaseSort).forEach(function(g) {
              $scope.allGroupNames.push(g);
            });
            $scope.members = members.all.map(function(member) {
              member.roleNames.map(function(r) {
                allRoles.push(r);
              });
              return transformMember(member);
            });
            $scope.members = $scope.members.concat(getUniqueInvitedMembers(orgUserService.getInvitedMembers(), $scope.members));
            allRoles = allRoles.filter(onlyUnique).sort(insensitiveCaseSort);
            allRoles.forEach(function(r) {
              $scope.allRoleNames.push({ name: r });
            });
            $scope.isLoading = false;
          },
          function(reason) {
            $log.error('Error: Org not found; ' + reason);
          }
        );
      }

      $scope.removeMember = function(member, closeModal) {
        if ($scope.isRemoving) return;

        $scope.isRemoving = true;
        function getPromise() {
          if (member.status === 'Pending') {
            return orgService.removePendingMember(
              member.payload.inviteId.id,
              member.payload.emailAddress,
              $scope.user.lastSelectedAccount,
              $scope.user.userId
            );
          } else {
            return orgService.removeMembers($scope.user.lastSelectedAccount, [member.payload], $scope.user.userId);
          }
        }
        getPromise()
          .then(
            function() {
              alertService.show({ type: 'success', title: 'Person removed', duration: 5 });
              $scope.members = $scope.members.filter(function(m) {
                return !areSameMember(m, member);
              });
            },
            function(response) {
              if (response === 'NoAdminRemaining') {
                alertService.show({
                  type: 'danger danger-with-content',
                  title: 'Removal not permitted!',
                  content: 'Sorry! Removing this person would not leave any users with the admin permission.',
                  duration: 20,
                });
              } else {
                $log.error('removeFromOrg failed: ' + response);
                alertService.show({
                  type: 'danger danger-with-content',
                  title: 'Unexpected Error!',
                  content: 'It seems something went wrong, please try again.',
                  duration: 20,
                });
              }
            }
          )
          .finally(function() {
            closeModal();
            $scope.isRemoving = false;
          });
      };

      $scope.resendEmail = function(member, closeModal) {
        $scope.resending = true;
        inviteService
          .resendEmailInvite(member.inviteId, $scope.user.lastSelectedAccount, member.email, $scope.user.userId)
          .then(
            function(response) {
              alertService.show({
                title: 'Invitation Resent',
                type: 'success',
                show: true,
                duration: 5,
                dismissible: true,
              });
            },
            function(reason) {
              $log.error(reason);
              alertService.show({
                title: 'Error! Resend Failed.',
                type: 'danger',
                show: true,
                dismissible: true,
                duration: 20,
              });
            }
          )
          .finally(function() {
            closeModal();
            $scope.resending = false;
          });
      };

      init();
    },
  ] //End People Ctrl
);
