import React from 'react';
import ConfirmationModal from '../components/ConfirmationModal';
import { TextFilterInput } from '../components/FormElements';
import GroupContainer from '../groups/GroupContainer';
import useModal from '../hooks/useModal';
import { handleError } from '../utils/apiUtils';
import orgService from '../services/orgService';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import { PrimaryButton } from '../components/Buttons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Breakpoints, Grid } from '../StyleGuide';
import EmptyState from '../groups/EmptyState';
import PeopleTable from '../components/PeopleTable';
import { useUser } from '../authentication';
import useAngularScope from '../hooks/useAngularScope';

const InputContainer = styled.div`
  margin-top: ${Grid._4};

  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'unset')};

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    margin-top: 0px;
  }
`;

function RemoveModal({
  orgId,
  user,
  initiatingUser,
  role,
  filteredMembers,
  setUnfilteredMembers,
  dismissModal,
  ...props
}) {
  const [angularScope] = useAngularScope();

  const onSubmit = () => {
    angularScope.org.memberRoles = angularScope.org.memberRoles?.map(prevMember => {
      if (prevMember.id === user.userId.id) {
        return { id: prevMember.id, roles: prevMember.roles.filter(prevRole => prevRole.id !== role.id.id) };
      } else {
        return prevMember;
      }
    });

    orgService
      .removeUserFromRole(orgId, user.userId.id, role.id.id, initiatingUser.userId)
      .then(res => {
        if (res.eventType === 'RemoveFromRoleSucceeded') {
          alertService.show('Member removed');
          setUnfilteredMembers(prev => prev.filter(member => member?.userId.id !== res?.possibleUserId));
        }
      })
      .catch(err => {
        if (err?.code === 'NoAdminRemaining') {
          alertService.showError('Sorry! Removing this permission will leave no admin. ');
        } else {
          handleError(err);
        }
      });
    dismissModal();
  };

  return (
    <ConfirmationModal
      buttonActionText={'Remove'}
      buttonType="danger"
      title="Remove Member"
      prompt={<span>Are you sure you want to remove this member from the {role?.name} role?</span>}
      handleSubmit={onSubmit}
      {...props}
    >
      <p data-qa-hook="roleDetailRemoveName">
        {user?.firstName} {user?.lastName}
      </p>
      <p data-qa-hook="roleDetailRemoveEmail">{user?.email}</p>
      <p className="help-block">
        <i className="icon ion-android-alert"></i> This will also remove this role from any groups that this member is
        in
      </p>
      <p className="help-block">
        <i className="icon ion-android-alert"></i> This action cannot be undone
      </p>
    </ConfirmationModal>
  );
}

RemoveModal.propTypes = {
  orgId: PropTypes.string,
  user: PropTypes.object,
  setUnfilteredMembers: PropTypes.func,
  initiatingUser: PropTypes.object,
  role: PropTypes.object,
  filteredMembers: PropTypes.array,
  dismissModal: PropTypes.func,
};

const RolePeople = ({
  orgId,
  role,
  initiatingUser,
  unfilteredMembers,
  filteredMembers,
  query,
  setQuery,
  setUnfilteredMembers,
}) => {
  const user = useUser();

  function onDismiss() {
    dismissModal();
  }

  function onOpenModal(user) {
    openModal('removeUser', {
      user,
      role,
      initiatingUser,
      orgId,
      filteredMembers,
      setUnfilteredMembers,
    });
  }

  const [modal, openModal, dismissModal] = useModal((type, payload, dismissModal) => {
    switch (type) {
      case 'removeUser':
        return (
          <RemoveModal
            handleDismiss={onDismiss}
            dismissModal={dismissModal}
            role={payload.role}
            user={payload.user}
            initiatingUser={payload.initiatingUser}
            orgId={payload.orgId}
            filteredMembers={payload.filteredMembers}
            setFilteredMembers={payload.setFilteredMembers}
            setUnfilteredMembers={payload.setUnfilteredMembers}
          />
        );
      default:
        return null;
    }
  });

  return (
    <>
      <div data-qa-hook="roleDetailView">
        <div className="grid-container grid-gap-16 grid-sm-col-2 grid-md-col-6">
          <div className="grid-sm-col-span-2 grid-md-col-span-5">
            <InputContainer hidden={unfilteredMembers?.length === 0}>
              <TextFilterInput
                value={query}
                onChangeHandler={e => setQuery(e.target.value)}
                clearInput={() => {
                  setQuery('');
                }}
                placeholder="Search Members"
              />
            </InputContainer>
          </div>

          <div className="primary-action">
            <PrimaryButton
              data-qa-hook="groupDetailAdd"
              onClick={() => windowService.redirectTo(`/roles/${role.id.id}/add-people`)}
            >
              Add People
            </PrimaryButton>
          </div>
        </div>
      </div>
      {unfilteredMembers?.length > 0 && (
        <GroupContainer>
          {filteredMembers?.length > 0 ? (
            <PeopleTable people={filteredMembers} user={user} onOpenModal={onOpenModal} />
          ) : (
            <>
              <h4>Your search does not have any matches.</h4>
              <p>Please try another search.</p>
            </>
          )}
          {!filteredMembers && <p>Loading</p>}
        </GroupContainer>
      )}

      {unfilteredMembers?.length === 0 && (
        <>
          <EmptyState isError={'no-people-role'} />
        </>
      )}

      {modal}
    </>
  );
};

RolePeople.propTypes = {
  orgId: PropTypes.string,
  role: PropTypes.object,
  initiatingUser: PropTypes.object,
  unfilteredMembers: PropTypes.array,
  filteredMembers: PropTypes.array,
  query: PropTypes.string,
  setQuery: PropTypes.func,
  setUnfilteredMembers: PropTypes.func,
};

export default RolePeople;
