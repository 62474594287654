import React, { useReducer } from 'react';

const useModal = handler => {
  const openModal = (type, payload) => handleModal({ type, payload });
  const dismissModal = () => openModal();
  const [modal, handleModal] = useReducer((state, { type, payload }) => handler(type, payload, dismissModal, state));
  return [modal, openModal, dismissModal];
};

export default useModal;
