import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ActionRowButton } from '../components/Buttons';
import { Label } from '../components/FormElements';
import Overlay from '../components/Overlay';
import windowService from '../services/windowService';
import { Breakpoints, Grid, Type } from '../StyleGuide';
import { License } from './models/license';

const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Grid._6};
  margin-top: ${Grid._5}; // account for the close button on mobile
  padding: ${Grid._4};
  width: 100vw;

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin-top: 0;
    padding: ${Grid._6};
    width: 85vw;
    max-width: 720px;
    height: auto;
  }
`;

const LicenseList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Grid._4};
  padding-bottom: ${Grid._7};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding-bottom: 0;
  }

  ${ActionRowButton} {
    font-size: ${Type.Scale._3};
  }
`;

export const SubscriptionHeader = styled.div`
  display: grid;
  gap: ${Grid._2};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 212px 1fr;
    gap: ${Grid._4};
  }
`;

export const LicenseListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Grid._6};
`;

type SelectSubscriptionLicenseModalProps = {
  subscriptionImage: ReactElement;
  subscriptionName: string;
  licenses: License[];
  handleDismiss: () => void;
};

export const SelectSubscriptionLicenseModal = ({
  subscriptionImage,
  subscriptionName,
  licenses,
  handleDismiss,
}: SelectSubscriptionLicenseModalProps) => {
  const redirectToManageLicense = (id: string) => {
    windowService.redirectTo(`/org/purchases-and-subscriptions/${id}`);
  };

  return (
    <Overlay dismissHandler={handleDismiss}>
      <Overlay.CloseButton />
      <OverlayContainer>
        <SubscriptionHeader>
          {subscriptionImage}
          <h2>{subscriptionName}</h2>
        </SubscriptionHeader>
        <div>
          <Label>Select License To Manage</Label>
          <LicenseList>
            {licenses.map(license => (
              <ActionRowButton key={license.id} onClick={() => redirectToManageLicense(license.id)}>
                {license.name} <i className="fas fa-chevron-right" />
              </ActionRowButton>
            ))}
          </LicenseList>
        </div>
      </OverlayContainer>
    </Overlay>
  );
};
