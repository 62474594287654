import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import Breadcrumbs from '../../components/Breadcrumbs';
import ErrorMessage from '../../components/ErrorMessage';
import LoadingState from '../../components/LoadingState';
import curriculumService from '../../services/curriculumService';
import {
  FormField,
  Label,
  Radio,
  FormFieldContainer,
  FormFieldDate,
  FormGroupContainer,
  Input,
} from '../../components/FormElements';
import { Container, PageTitle, ThreeTwoAspectRatioPlaceholder } from '../../components/Layout';
import BibleStudyBanner from '../BibleStudyBanner';
import RemoveContentSection from '../../components/RemoveContentSection';
import ImageCrop, { ImageCropUtils } from '../../components/ImageCrop';
import { Grid } from '../../StyleGuide';
import { PrimaryButton, ToggleButton } from '../../components/Buttons';
import alertService from '../../services/AlertService';
import windowService from '../../services/windowService';
import useCurriculum from '../../hooks/useCurriculum';

const CurriculumPageTitle = styled(PageTitle)`
  margin: ${Grid._6} 0 ${Grid._5} 0;
`;

const CollectionForm = styled(Form)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: ${Grid._7};
  margin-bottom: ${Grid._7};
`;

const ImageFieldContainer = styled.div`
  flex: 1 1 100%;
  max-width: 420px;
`;

const FieldsContainer = styled.div`
  flex: 1;
  min-width: 320px;
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${Grid._6};

  > ${FormFieldDate} {
    flex: 0 0 220px;
  }
`;

const SaveButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ManageCurriculumCollection = () => {
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, collectionId } = useParams();
  const [data, setData] = useState({
    isLoading: !!collectionId,
    isError: false,
    collection: {},
  });

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Curriculum', route: '#/manage-curriculum' },
    {
      name: `${brand.name}: ${ageCategory}`,
      route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}`,
    },
    { name: collectionId ? 'Edit Collection' : 'New Collection' },
  ];

  useEffect(() => {
    if (!collectionId) return;

    curriculumService
      .getCurriculumCollection(curriculumId, collectionId)
      .then(collection => {
        setData({
          isLoading: false,
          collection,
        });
      })
      .catch(error => {
        console.error(error);
        setData({ isLoading: false, isError: true });
      });
  }, []);

  const uploadCollectionImage = image =>
    new Promise((resolve, reject) => {
      const curriculumFileName = ImageCropUtils.generateFilename();
      if (ImageCropUtils.isCroppedImage(image)) {
        ImageCropUtils.convertCroppedImageToBlob(image)
          .then(blob => curriculumService.uploadCurriculumImage(curriculumFileName, blob))
          .then(() => resolve(curriculumFileName))
          .catch(reject);
      } else {
        resolve(image);
      }
    });

  const redirectToCurriculum = () => {
    windowService.redirectTo(`#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}`);
  };

  const handleSubmit = values => {
    const formatDate = date => (date ? format(date, 'yyyy-MM-dd') : undefined);

    return uploadCollectionImage(values.image)
      .then(image => {
        const collection = {
          ...data.collection,
          ...values,
          image,
          startDate: formatDate(values.startDate),
          endDate: formatDate(values.endDate),
        };

        if (data.collection.image === image) delete collection.image;

        return collectionId
          ? curriculumService.updateCollection(collection)
          : curriculumService.createCollection(
              curriculumId,
              collection.name,
              collection.package,
              collection.image,
              collection.startDate,
              collection.endDate
            );
      })
      .then(() => {
        alertService.showOnNextPage(`Collection ${collectionId ? 'Updated' : 'Added'}`);
        redirectToCurriculum();
      })
      .catch(error => {
        console.error(error);
        alertService.showError();
      });
  };

  const removeCollection = () => {
    return curriculumService
      .deleteCollection(curriculumId, collectionId)
      .then(() => {
        alertService.showOnNextPage('Collection Removed');
        redirectToCurriculum();
      })
      .catch(error => {
        console.error(error);
        alertService.showError();
      });
  };

  const getInitialFormValues = collection => {
    return collection
      ? {
          ...collection,
          isAlwaysAvailable: !collection.startDate && !collection.endDate,
          startDate: collection.startDate ? new Date(collection.startDate) : null,
          endDate: collection.endDate ? new Date(collection.endDate) : null,
        }
      : {
          name: '',
          package: 'Basic',
          isAlwaysAvailable: true,
        };
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <BibleStudyBanner />
      {data.isLoading ? (
        <LoadingState />
      ) : data.isError ? (
        <ErrorMessage />
      ) : (
        <Container>
          <CurriculumPageTitle>
            <h1>{collectionId ? 'Edit Collection' : 'New Collection'}</h1>
          </CurriculumPageTitle>
          <Formik
            initialValues={getInitialFormValues(collectionId && data.collection)}
            validationSchema={Yup.object({
              name: Yup.string().max(64, 'Name is too long').required('A name is required'),
            })}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting, setFieldValue }) => {
              return (
                <CollectionForm>
                  <ImageFieldContainer>
                    <Label>Poster</Label>
                    <ThreeTwoAspectRatioPlaceholder actionable={!values.image}>
                      <ImageCrop
                        key={values.image}
                        defaultImage={values.image}
                        aspectRatio={3 / 2}
                        onUpdate={img => setFieldValue('image', img)}
                      />
                    </ThreeTwoAspectRatioPlaceholder>
                  </ImageFieldContainer>

                  <FieldsContainer>
                    <FormField
                      label="Name"
                      data-qa-hook="collectionName"
                      name="name"
                      placeholder="Enter Name"
                      onChange={e => setFieldValue('name', e.target.value)}
                    />
                    <FormFieldContainer>
                      <Label>Plan Availability</Label>
                      <FormGroupContainer>
                        <p>Select which plans will have access to this collection</p>
                        <div className="controls">
                          <Radio
                            id="plan-availability--basic"
                            checked={values.package === 'Basic'}
                            onChange={() => setFieldValue('package', 'Basic')}
                          />
                          <label htmlFor="plan-availability--basic">All Plans</label>
                          <Radio
                            id="plan-availability--plus"
                            checked={values.package === 'Plus'}
                            onChange={() => setFieldValue('package', 'Plus')}
                          />
                          <label htmlFor="plan-availability--plus">Plus Only</label>
                        </div>
                      </FormGroupContainer>
                    </FormFieldContainer>
                    <FormFieldContainer>
                      <Label>Schedule Availability</Label>
                      <FormGroupContainer>
                        <p>Schedule when the collection will be available for ministry leaders</p>
                        <ToggleButton
                          disabled={values.startDate || values.endDate}
                          isOn={values.isAlwaysAvailable}
                          onClick={() => setFieldValue('isAlwaysAvailable', !values.isAlwaysAvailable)}
                        >
                          Always Available
                        </ToggleButton>
                      </FormGroupContainer>
                    </FormFieldContainer>
                    <DatePickerContainer>
                      <FormFieldDate disabled={values.isAlwaysAvailable}>
                        <Label>Availability Start Date</Label>
                        <DatePicker
                          data-qa-hook="startDatePicker"
                          selected={values.startDate}
                          onChange={date => setFieldValue('startDate', date)}
                          customInput={<Input />}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Select Date"
                          minDate={new Date()}
                          maxDate={values.endDate}
                          disabled={values.isAlwaysAvailable}
                        />
                      </FormFieldDate>
                      <FormFieldDate disabled={values.isAlwaysAvailable}>
                        <Label>Availability End Date</Label>
                        <DatePicker
                          data-qa-hook="endDatePicker"
                          selected={values.endDate}
                          onChange={date => setFieldValue('endDate', date)}
                          customInput={<Input />}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Select Date"
                          minDate={values.startDate}
                          disabled={values.isAlwaysAvailable}
                        />
                      </FormFieldDate>
                    </DatePickerContainer>
                    <SaveButtonContainer>
                      <PrimaryButton type="submit" disabled={isSubmitting} operating={isSubmitting}>
                        Save Collection
                      </PrimaryButton>
                    </SaveButtonContainer>
                    {!!collectionId && (
                      <RemoveContentSection
                        type="Collection"
                        modalSubtext={data.collection.name}
                        onRemove={removeCollection}
                      />
                    )}
                  </FieldsContainer>
                </CollectionForm>
              );
            }}
          </Formik>
        </Container>
      )}
    </>
  );
};

export default ManageCurriculumCollection;
