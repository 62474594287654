import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { Grid, Color, Type, Border, Transition, Breakpoints } from '../StyleGuide';
import { Input } from '../components/FormElements';
import { PrimaryButton } from '../components/Buttons';
import windowService from '../services/windowService';

const SearchBarForm = styled.form`
  position: relative;
  display: flex;
  padding: 0;
  margin: 0;
  border: 1px solid ${Color.Gray._10};
  border-radius: ${Border.radius};
  background: ${Color.white};
`;

const SearchQuery = styled(Input).attrs(() => ({
  type: 'text',
}))`
  flex: 1;
  padding: ${Grid._4} 86px ${Grid._4} ${Grid._4};
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.semibold};
  border-radius: ${Border.radius};
  ::placeholder {
    font-weight: ${Type.Weight.light};
    color: ${Type.Color.placeholder};
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._4} ${Grid._12} ${Grid._4} ${Grid._5};
    font-size: ${Type.Scale._5};
    padding: ${Grid._4} 156px ${Grid._4} ${Grid._5};
  }
`;

const SearchButton = styled(PrimaryButton).attrs(() => ({
  type: 'submit',
}))`
  position: absolute;
  right: 0;
  height: 100%;
  padding: 0 ${Grid._4};
  font-size: ${Type.Scale._3};
  border-radius: 0 ${Border.radius} ${Border.radius} 0;
  width: 70px;
  overflow: hidden;
  transition: ${Transition.normal};
  i,
  span {
    margin-right: 0;
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: 0 ${Grid._5};
    font-size: ${Type.Scale._4};
    span {
      opacity: 0;
      transition: ${Transition.normal};
      &:after {
        content: '';
      }
    }
    &.showButtonText {
      width: 132px;
      span {
        opacity: 1;
        &:after {
          content: ' Search';
        }
      }
    }
  }
`;

export default function SearchBar({ categories }) {
  const [query, setQuery] = useState('');

  const handleOnChange = e => setQuery(e.target.value);

  const status = classNames({
    showButtonText: query.length,
  });

  const submitForm = e => {
    e.preventDefault();
    windowService.redirectTo(`#/search?query=${query}`);
  };

  return (
    <SearchBarForm onSubmit={submitForm}>
      <SearchQuery data-qa-hook="searchQuery" value={query} onChange={handleOnChange} placeholder="Search Training" />
      <SearchButton data-qa-hook="SearchButton" className={`steps-primary-button ${status}`} disabled={!query.length}>
        <i className="fas fa-search"></i>
        <span />
      </SearchButton>
    </SearchBarForm>
  );
}

SearchBar.propTypes = {
  categories: PropTypes.array,
};
