import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import PendingInvitesError from './PendingInvitesError';
import { PrimaryButton, TransparentButton, MediumPrimaryButton, RoundedIconButton } from '../components/Buttons';
import { Type, Grid, Shadow, Color, Breakpoints } from '../StyleGuide';
import windowService from '../services/windowService';
import { useUser } from '../authentication';
import alertService from '../services/AlertService';
import inviteService from '../services/inviteService';
import AngularServiceProvider from '../services/AngularServiceProvider';
import LoadingState from '../components/LoadingState';
import { Container } from '../components/Layout';
import { ChurchImage } from '../components/ChurchImage';

const InviteContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${Grid._5} ${Grid._6};
  & > i {
    cursor: pointer;
  }
`;

const Header = styled.h2`
  font-size: ${Type.Scale._6};
  font-weight: ${Type.Weight.medium};
  margin: ${Grid._6} 0;
  text-align: center;
`;

const CardContainer = styled.div`
  width: 100%;
  margin-bottom: ${Grid._14};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${Grid._6};
  margin: ${Grid._6};
`;

const InviteCard = styled.div`
  box-shadow: ${Shadow.medium};
  background: ${Color.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: ${Grid._4};
  padding: ${Grid._4};
  max-width: 600px;
  width: 100%;

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
  }
`;

const ChurchDetails = styled.div`
  display: flex;
  align-items: center;
  gap: ${Grid._4};
  width: 100%;
`;

const AcceptButton = styled(MediumPrimaryButton)`
  width: 100%;

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    max-width: 140px;
  }
`;

const RoundedIconButtonRightAlign = styled(RoundedIconButton)`
  align-self: flex-end;
`;

const PendingInvites = () => {
  const user = useUser();
  const [data, setData] = useState({
    invites: [],
    isError: false,
    isLoading: true,
    errorType: null,
  });
  // We will migrate this once userSession Management out of angular
  const angularUserService = AngularServiceProvider.get('userService');
  useEffect(() => {
    inviteService.lookupInvitesForEmail(user.logInEmail).then(data => {
      setData(prevData => ({
        ...prevData,
        invites: data.invites,
        isError: false,
        isLoading: false,
      }));
    });
  }, []);

  const redirect = () => {
    windowService.redirectTo(angularUserService.getRedirectHashAfterLogin(user));
  };

  const checkUserSession = () => {
    return angularUserService.checkSession();
  };

  const acceptInvite = orgId => {
    const userId = user.userId;
    const userEmail = user.logInEmail;

    setData(prevData => ({ ...prevData, isLoading: true }));
    inviteService
      .acceptInvite(userId, orgId, userEmail)
      .then(() => {
        alertService.showAngularAlert('Invite Accepted');
        return checkUserSession().then(redirect);
      })
      .catch(error => {
        console.error(error);
        setData(prevData => ({ ...prevData, isLoading: false, isError: true, errorType: error.code }));
      });
  };

  const dismissError = () => {
    setData({ ...data, isError: false });
  };
  const invitesLength = data.invites.length;
  return (
    <InviteContainer>
      {data.isLoading ? (
        <LoadingState />
      ) : data.isError ? (
        <PendingInvitesError
          invitesLength={invitesLength}
          dismissError={dismissError}
          skipInvite={redirect}
          errorType={data.errorType}
        />
      ) : (
        <>
          <RoundedIconButtonRightAlign onClick={redirect}>
            <i className="fas fa-times" />
          </RoundedIconButtonRightAlign>
          <Header>{invitesLength ? 'You Have Pending Invitations' : 'All Invitations Accepted'}</Header>
          <CardContainer>
            {data.invites.map(invite => {
              return (
                <InviteCard key={invite.orgId.id}>
                  <ChurchDetails>
                    <ChurchImage name={invite.orgName} logo={invite.orgLogo} />
                    <h4>{invite.orgName}</h4>
                  </ChurchDetails>
                  <AcceptButton onClick={() => acceptInvite(invite.orgId.id)}>Accept Invitation</AcceptButton>
                </InviteCard>
              );
            })}
          </CardContainer>
          {invitesLength ? (
            <TransparentButton onClick={redirect}>Skip for now</TransparentButton>
          ) : (
            <PrimaryButton onClick={redirect}>Continue</PrimaryButton>
          )}
        </>
      )}
    </InviteContainer>
  );
};

export default PendingInvites;
