angular.module('lwNamb').factory(
  'pathwayService',

  [
    '$rootScope',
    'api',
    'qt',
    'uuid4',
    'commandSubmissionService',
    'catalogService',
    'userService',
    function($rootScope, api, qt, uuid4, commandSubmissionService, catalogService, userService) {
      var timeoutSeconds = 15;

      return {
        getAllPathways: function(admin) {
          return getPathways(!!admin);
        },
      };

      function getPathways(admin) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }

        function getWithOptionalOwnedCatalogs(ownedCatalogs) {
          catalogService.getCatalogs().then(function(catalogs) {
            catalogService.getCatalogForSite($rootScope.origin).then(function(c) {
              var url = '/v1/pathways?ownerId=' + c._id.id;
              if (admin) url = url + '&admin=true&showUnPublished=true';
              api.get(url).then(
                function(response) {
                  var pathways = response.data.pathways.map(function(pathway) {
                    var catalog = catalogs.find(function(cat) {
                      return cat._id.id === pathway.ownerId;
                    });

                    if (catalog !== undefined && ownedCatalogs !== undefined) {
                      pathway.catalogName = catalog.name;
                      pathway.isOwned = ownedCatalogs.includes(catalog.name);
                      pathway.isEditable = c.name === catalog.name;
                    }

                    return pathway;
                  });

                  if (admin) {
                    var viewable = [
                      c._id.id,
                      catalogs.find(function(c) {
                        return c.name === 'Ministry Grid';
                      })._id.id,
                    ].filter(onlyUnique);
                    pathways = pathways.filter(function(p) {
                      return viewable.indexOf(p.ownerId) > -1;
                    });
                  }
                  deferred.resolve(
                    pathways.sort(function(a, b) {
                      return a.sequence - b.sequence;
                    })
                  );
                },
                function(reason) {
                  deferred.reject(reason);
                }
              );
            });
          });
        }

        userService.user().then(
          function(user) {
            var ownedCatalogs = [];
            if (!!user.author && user.author.length > 0) {
              // Get all the catalogs the user is an author of
              user.author.forEach(function(cat) {
                ownedCatalogs.push(cat.name);
              });
            }
            getWithOptionalOwnedCatalogs(ownedCatalogs);
          },
          function() {
            getWithOptionalOwnedCatalogs();
          }
        );

        return deferred.promise;
      }

     
    },
  ]
);
