angular.module('lwNamb').directive(
  'selectTraining',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/select-training.html',
        scope: {
          title: '@',
          description: '@',
          selectionModelType: '@',
          selectionModelFacetKey: '@',
          attachedItems: '=?',
          selectedItems: '=',
          actionText: '@',
          action: '&',
          showUnpublished: '=',
        },
        replace: true,
        controller: [
          '$rootScope',
          '$scope',
          '$log',
          '$location',
          '$window',
          'userService',
          'searchService',
          'windowService',
          '$localStorage',
          'alertService',
          'orgService',
          function(
            $rootScope,
            $scope,
            $log,
            $location,
            $window,
            userService,
            searchService,
            windowService,
            $localStorage,
            alertService,
            orgService
          ) {
            $scope.$on('$routeChangeStart', function() {
              //Reset Select Training Search Cache
              if (
                $location.$$url.indexOf('manage-catalog') >= 0 ||
                (
                  $location.$$url !== '/invite' &&
                  $location.$$url !== '/group' &&
                  $location.$$url !== '/build' &&
                  $location.$$url.indexOf('training') === -1
                )
              ) {
                $localStorage.selectedItems = [];
                $scope.clearSearchResults();
              }
            });

            var init = function() {
              userService.user().then(function(user) {
                $scope.user = user;
                $scope.orgId = $scope.user.lastSelectedAccount;

                orgService.hasGridSubscription($scope.orgId).then(function(hasSub) {
                  $scope.hasGridSubscription = hasSub;
                });
              });

              $scope.selectedItems = $localStorage.selectedItems === undefined ? [] : $localStorage.selectedItems;
              $scope.attachedItems = $scope.attachedItems === undefined ? [] : $scope.attachedItems;
              $scope.facets = [];
              $scope.totalHits = undefined;
              $scope.attaching = false;

              if ($localStorage.searchQueryString !== undefined) {
                $scope.showResults = true;
                $scope.searchQuery = $localStorage.searchQueryString;
                $scope.submittedSearchQuery = $localStorage.searchQueryString;
                $scope.trainingCatalog = $localStorage.searchResults;
                $scope.totalHits = $localStorage.hitTotal;
                $scope.facets = $localStorage.cachedFacets;
                loadFiltersSelected();
                if ($localStorage.toggleTrainingSelection) {
                  toggleSelect($localStorage.trainingToBeToggled);
                }
              } else {
                $scope.clearSearchResults();
              }
            };

            function facetByName(a, b) {
              if (a.key < b.key) return -1;
              else return 1; //facets should never be direct matches since they are unique
            }

            $scope.attach = function(items) {
              $scope.attaching = true;
              $scope.selectedItems = [];
              $scope.action(items);
              $scope.clearSearchResults();
            };

            $scope.search = function() {
              if ($scope.searchQuery !== undefined) {
                $scope.searching = true;
                $scope.showResults = false;
                $scope.trainingCatalog = [];
                $scope.submittedSearchQuery = $scope.searchQuery;
                $localStorage.searchQueryString = $scope.searchQuery;
                var selectedFacets = [];
                if ($scope.selectionModelFacetKey !== undefined) {
                  $log.info('Locked Facet: ', $scope.selectionModelFacetKey);
                  selectedFacets.push({ key: $scope.selectionModelFacetKey, selected: true, type: 'trainingType' });
                }
                $scope.facets.forEach(function(f) {
                  f.values.forEach(function(v) {
                    if (v.selected) selectedFacets.push(v);
                  });
                });
                searchService
                  .findAllByOwnerAndQuery(
                    $scope.orgId ? $scope.orgId : $scope.rootGridId,
                    $scope.searchQuery,
                    selectedFacets,
                    $scope.showUnpublished,
                    $scope.hasGridSubscription
                  )
                  .then(
                    function(response) {
                      $scope.trainingCatalog = response.training;
                      $scope.totalHits = response.hitTotal;
                      $scope.facets = response.facets;
                      $scope.facets.forEach(function(f) {
                        f.values.forEach(function(v) {
                          if (
                            selectedFacets.findIndex(function(selected) {
                              return selected.key === v.key;
                            }) > -1
                          ) {
                            v.selected = true;
                          }
                        });
                        f.values = f.values.sort(facetByName);
                      });
                      if ($scope.selectionModelFacetKey !== undefined) {
                        var pos = $scope.facets
                          .map(function(f) {
                            return f.key;
                          })
                          .indexOf('Training Type');
                        $scope.facets.splice(pos, 1);
                      }
                      loadFiltersSelected();
                      if ($scope.selectedItems.length > 0) {
                        loadTrainingSelected();
                      }
                      if ($scope.attachedItems.length > 0) {
                        loadTrainingLocked();
                      }
                      $scope.searching = false;
                      $scope.showResults = true;
                      $localStorage.searchResults = response.training;
                      $localStorage.hitTotal = response.hitTotal;
                      $localStorage.cachedFacets = $scope.facets;
                    },
                    function(reason) {
                      $log.error(reason);
                      alertService.show({
                        title: 'Search Error!',
                        content: 'Something went wrong. Please try your search again.',
                        type: 'danger danger-with-content',
                        duration: 20,
                      });
                    }
                  );
              }
            };

            $scope.toggle = function(facetValue) {
              $scope.facets.forEach(function(f) {
                f.values.forEach(function(v) {
                  if (facetValue.selected !== true && v.selected === true && v.type === facetValue.type) {
                    v.selected = !v.selected;
                  }
                });
              });
              facetValue.selected = !facetValue.selected;
              loadFiltersSelected();
              $scope.search();
            };

            $scope.clearSearchResults = function() {
              $scope.showResults = false;
              $scope.trainingCatalog = [];
              $scope.totalHits = undefined;
              $scope.searchQuery = undefined;
              $scope.submittedSearchQuery = undefined;
              $scope.facets = [];
              $localStorage.searchResults = undefined;
              $localStorage.searchQueryString = undefined;
              $localStorage.hitTotal = undefined;
              $localStorage.cachedFacets = undefined;
            };

            $scope.back = function() {
              if ($localStorage.selectTrainingBackHash) {
                windowService.redirectHash($localStorage.selectTrainingBackHash);
              } else {
                $window.history.back();
              }
            };

            $scope.redirectTo = function(path) {
              windowService.redirectHash(path);
            };

            $rootScope.$on('ToggleTrainingSelected', function(event, training) {
              $log.info('Toggle: ', training);
              $localStorage.toggleTrainingSelection = true;
              $localStorage.trainingToBeToggled = training.id;
            });

            $scope.$on('$locationChangeStart', function(event, current, previous) {
              if (current.indexOf('training') > -1) {
                $localStorage.selectTrainingOriginHash = previous.split('#')[1];
              }
            });

            $scope.$watch(
              'selectedItems',
              function(newValue) {
                for (var i = 0; i < newValue.length; i++) {
                  for (var t = i + 1; t < newValue.length; t++) {
                    if (newValue[i].id === newValue[t].id) {
                      newValue.splice(i, 1);
                      i--;
                    }
                  }
                }
                if ($scope.selectionModelType === 'single' && newValue.length > 1) {
                  newValue[0].selected = false;
                  newValue.shift();
                }
                $localStorage.selectedItems = newValue;
              },
              true
            );

            $scope.unSelect = function(item) {
              for (var t = 0; t < $scope.selectedItems.length; t++) {
                if ($scope.selectedItems[t] === item) {
                  $scope.selectedItems[t].selected = false;
                  item.selected = false;
                  $scope.selectedItems.splice($scope.selectedItems.indexOf($scope.selectedItems[t]), 1);
                }
              }
            };

            $scope.unSelectAll = function() {
              for (var t = 0; t < $scope.selectedItems.length; t++) {
                $scope.selectedItems[t].selected = false;
              }
              $scope.selectedItems.length = 0;
            };

            function toggleSelect(id) {
              if ($scope.trainingCatalog !== undefined && $scope.trainingCatalog.length > 0) {
                for (var t = 0; t < $scope.trainingCatalog.length; t++) {
                  if (id === $scope.trainingCatalog[t].id) {
                    if ($scope.trainingCatalog[t].selected !== undefined) {
                      if ($scope.trainingCatalog[t].selected === true) {
                        $scope.trainingCatalog[t].selected = false;
                      } else {
                        $scope.trainingCatalog[t].selected = true;
                      }
                    } else {
                      $scope.trainingCatalog[t].selected = true;
                    }
                    break;
                  }
                }
              }
              $localStorage.toggleTrainingSelection = false;
              $localStorage.trainingToBeToggled = undefined;
            }

            function loadFiltersSelected() {
              $scope.filtersSelected =
                $scope.facets.filter(function(f) {
                  return (
                    f.values.filter(function(v) {
                      return v.selected === true;
                    }).length > 0
                  );
                }).length > 0;
            }

            function loadTrainingSelected() {
              var selectedItems = angular.copy($scope.selectedItems);
              for (var i = 0; i < selectedItems.length; i++) {
                for (var t = 0; t < $scope.trainingCatalog.length; t++) {
                  if (selectedItems[i].id === $scope.trainingCatalog[t].id) {
                    var pos = $scope.selectedItems
                      .map(function(e) {
                        return e.id;
                      })
                      .indexOf(selectedItems[i].id);
                    $scope.selectedItems.splice(pos, 1);
                    $scope.trainingCatalog[t].selected = true;
                    break;
                  }
                }
              }
            }

            function loadTrainingLocked() {
              var attachedItems = angular.copy($scope.attachedItems);
              for (var i = 0; i < attachedItems.length; i++) {
                for (var t = 0; t < $scope.trainingCatalog.length; t++) {
                  if (attachedItems[i].id === $scope.trainingCatalog[t].id) {
                    $scope.trainingCatalog[t].disabled = true;
                    break;
                  }
                }
              }
            }

            init();
          },
        ],
      };
    },
  ]
);
