angular.module('lwNamb').factory('intercomService', [
  '$log',
  '$window',
  function ($log, $window) {
    function cleanRoles(roles, append) {
      var roleObj = {};
      for (var i = 0; i < roles.length; i++) {
        var n = roles[i].split(' ').join('') + append;
        roleObj[n] = true;
      }
      return roleObj;
    }

    return {
      show: function () {
        return !!window.INTERCOM_APP_ID;
      },
      bootForUser: function (
        userId,
        email,
        firstName,
        lastName,
        userName,
        currentOrgId,
        currentOrgName,
        currentOrgRoles,
        gitHash,
        url,
        orgSub,
        curriculumSub,
        personalSub,
        allRoles,
        businessPartnerSources
      ) {
        try {
          var all = cleanRoles(allRoles, '');
          var current = cleanRoles(currentOrgRoles, 'Current');
          var roles = Object.assign(current, all);
          var p = {
            app_id: window.INTERCOM_APP_ID,
            email: email,
            user_id: userId.toUpperCase(),
            name: firstName + ' ' + lastName,
            userName: userName,
            currentOrgId: currentOrgId,
            currentOrgName: currentOrgName,
            gitHash: gitHash,
            url: url,
            orgSub: orgSub,
            curriculumSub: curriculumSub,
            personalSub: personalSub,
            ViewOnlyCurrent: false,
            GroupsViewCurrent: false,
            RolesViewCurrent: false,
            AccountViewCurrent: false,
            BuildViewCurrent: false,
            CoachViewCurrent: false,
            AssignViewCurrent: false,
            BuildCurriculumViewCurrent: false,
            ManagePrepareEnrichCurrent: false,
            BuildCurriculumView: false,
            ManagePrepareEnrich: false,
            ViewOnly: false,
            AssessmentsView: false,
            WorkflowsView: false,
            GroupView: false,
            TaskView: false,
            RolesView: false,
            BuildView: false,
            AccountView: false,
            GroupsView: false,
            CoachView: false,
            AssignView: false,
            CruAsiaCustomer: businessPartnerSources.includes('CruAsia'),
          };
          var payload = Object.assign(p, roles);
          $log.debug('booting up Intercom for user: ' + JSON.stringify(payload));
          Intercom('boot', payload);
        } catch (e) {
          $log.error(e);
        }
      },
      bootForGuest: function () {
        try {
          $log.debug('booting up Intercom for guest...');
          Intercom('boot', {
            app_id: window.INTERCOM_APP_ID,
          });
        } catch (e) {}
      },
      shutdown: function () {
        try {
          $log.debug('shutting down Intercom...');
          Intercom('shutdown');
        } catch (e) {}
      },
    };
  },
]);
