angular.module('lwNamb').controller(
  'AddPeopleCtrl',
  [
    '$scope',
    'inviteService',
    '$log',
    'windowService',
    'alertService',
    'orgUserService',
    '$timeout',
    '$window',
    function($scope, inviteService, $log, windowService, alertService, orgUserService, $timeout, $window) {
      var init = function() {
        $scope.isAdding = false;
        $scope.isImporting = false;
        $scope.includePersonalMessage = false;
        $scope.person = {};
        $scope.orgId = $scope.user.lastSelectedAccount;
        $scope.initiatingUserId = $scope.user.userId;
        $scope.members = [];

        // Collectors
        $scope.peopleCollector = []; // Add by Name and Email
        $scope.emailsCollector = []; // Add by Emails

        // Active Tab and Form
        $scope.activeForm = 'addByName';

        loadMembers();
        getEasyLink();
      };

      function loadMembers() {
        orgUserService.getMembers($scope.user.lastSelectedAccount).then(function(members) {
          $scope.members = members.all;
        });
      }

      function getEasyLink() {
        inviteService.getEasyLink($scope.orgId).then(
          function(response) {
            $scope.easy_link = $window.location.origin + '/#/easy-link/' + response.prefix;
          },
          function(reason) {
            $log.error(reason);
            $scope.easy_link = undefined;
            alertService.show({
              title: 'Unexpected Error!',
              content: 'We are unable to get the easy link at this time. Please refresh and try again.',
              type: 'danger danger-with-content',
              duration: 20,
            });
          }
        );
      }

      $scope.addByNameAndEmail = function(isFormValid) {
        var hasEnteredEmail = $scope.person.email;
        var isPeopleCollectorEmpty = $scope.peopleCollector.length == 0;
        $scope.duplicateEmailInOrg = false;
        $scope.duplicateEmailInCollector = false;

        if ((!isFormValid && hasEnteredEmail) || (!isFormValid && !hasEnteredEmail && isPeopleCollectorEmpty)) {
          $scope.showErrorMessage = true;
          return;
        }

        if ($scope.isDuplicate($scope.person.email, $scope.peopleCollector)) {
          return;
        }

        var personalMessage = $scope.includePersonalMessage ? $scope.person.message : undefined;
        $scope.showErrorMessage = false;

        if (isPeopleCollectorEmpty && isFormValid) {
          // Send Single Invite if People Collector is empty and form data is valid
          $scope.singleInvite(personalMessage);
        } else if (!isPeopleCollectorEmpty && !hasEnteredEmail) {
          // Send Bulk Invite if People Collector has items and there is no email in form data to add
          $scope.bulkInvites($scope.peopleCollector, personalMessage);
        } else if (!isPeopleCollectorEmpty && isFormValid) {
          // Send Bulk Invite if People Collector has items and form data is valid
          // First it adds form data to collector and then sends invites
          $scope.addToCollector($scope.person, $scope.peopleCollector);
          $scope.bulkInvites($scope.peopleCollector, personalMessage);
        }

        $scope.isAdding = true;
      };

      $scope.addAnotherPerson = function(isFormValid) {
        $scope.duplicateEmailInOrg = false;
        $scope.duplicateEmailInCollector = false;

        if (!isFormValid) {
          $scope.showErrorMessage = true;
          return;
        }

        if ($scope.isDuplicate($scope.person.email, $scope.peopleCollector)) {
          return;
        }

        $scope.addToCollector($scope.person, $scope.peopleCollector);
        $scope.showErrorMessage = false;
        $scope.clearForm();
      };

      $scope.bulkImport = function(isFormValid) {
        $scope.showBulkErrorMessage = false;
        $scope.showBulkDuplicateErrorMessage = false;
        $scope.duplicateEmailInOrg = false;
        $scope.duplicateEmailInCollector = false;

        if (!isFormValid) {
          $scope.showBulkErrorMessage = true;
          return;
        }

        $scope.isImporting = true;

        var importedEmails = $scope.people.emails.replace(/[\s<>():=|!;"]/g, ',').split(',');
        var validEmail = new RegExp(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"-]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
        );

        importedEmails.forEach(function(email) {
          var transformedEmail = email.toLowerCase();

          // Check if email address is valid
          if (!validEmail.test(String(transformedEmail))) {
            return;
          }
          // Check if email address already exists in Org or in Collector
          if ($scope.isDuplicate(transformedEmail, $scope.emailsCollector)) {
            return;
          }
          // Add to Collector
          $scope.addToCollector({ email: transformedEmail }, $scope.emailsCollector);
        });

        // Show error if valid emails in form data were all duplicates
        if ($scope.duplicateEmailInOrg && !$scope.emailsCollector.length) {
          $scope.isImporting = false;
          $scope.showBulkDuplicateErrorMessage = true;
          return;
        }

        // Show error if no valid emails were found in form data
        if (!$scope.emailsCollector.length) {
          $scope.isImporting = false;
          $scope.showBulkErrorMessage = true;
          return;
        }

        // Send Bulk Invites
        $scope.bulkInvites($scope.emailsCollector, undefined);
      };

      $scope.singleInvite = function(personalMessage) {
        inviteService
          .createPendingInvite(
            $scope.person.email.toLowerCase(),
            $scope.orgId,
            $scope.person.firstname,
            $scope.person.lastname,
            personalMessage,
            $scope.initiatingUserId
          )
          .then(
            function(result) {
              orgUserService.addInvitedMembers([$scope.person]);
              alertService.show({ title: 'Person added', type: 'success', duration: 5 });
              windowService.redirectHash('/people');
            },
            function() {
              alertService.show({
                title: "Person couldn't be added!",
                content: 'Please try again.',
                type: 'danger danger-with-content',
                duration: 20,
              });
            }
          )
          .finally(function() {
            $scope.isAdding = false;
            $scope.submitted = false;
          });
      };

      // Used by both 'Add by Name & Emails' and 'Add by Emails' forms
      $scope.bulkInvites = function(users, personalMessage) {
        inviteService
          .createPendingInvites(users, $scope.orgId, personalMessage, $scope.initiatingUserId)
          .then(
            function(totalInvites) {
              orgUserService.addInvitedMembers(users);
              var successTitle = totalInvites > 1 ? totalInvites + ' People Added' : '1 Person Added';
              alertService.show({ title: successTitle, type: 'success', duration: 5 });
              windowService.redirectHash('/people');
            },
            function() {
              alertService.show({
                title: "People couldn't be added!",
                content: 'Please try again.',
                type: 'danger danger-with-content',
                duration: 20,
              });
            }
          )
          .finally(function() {
            $scope.isAdding = false;
            $scope.isImporting = false;
            $scope.submitted = false;
          });
      };

      // Used by both 'Add by Name & Emails' and 'Add by Emails' forms
      $scope.addToCollector = function(itemToBeAdded, collector) {
        collector.push({
          email: itemToBeAdded.email.toLowerCase(),
          firstName: itemToBeAdded.firstname ? itemToBeAdded.firstname : null,
          lastName: itemToBeAdded.lastname ? itemToBeAdded.lastname : null,
        });
      };

      // Used by both 'Add by Name & Emails' and 'Add by Emails' forms
      $scope.removeFromCollector = function(itemToBeRemoved, collector) {
        collector.find(function(item, index) {
          if (item.email === itemToBeRemoved.email) {
            collector.splice(index, 1);
            return;
          }
        });
      };

      $scope.clearForm = function() {
        $scope.person.email = '';
        $scope.person.firstname = '';
        $scope.person.lastname = '';
        $scope.addByName.$setPristine();
        $scope.addByName.$setUntouched();
      };

      $scope.toggleTab = function(tab) {
        $scope.activeForm = tab;
      };

      $scope.togglePersonalMessage = function() {
        $scope.includePersonalMessage = !$scope.includePersonalMessage;
      };

      $scope.isDuplicate = function(person, collector) {
        return $scope.isAlreadyInOrg(person) || $scope.isAlreadyInCollector(person, collector);
      };

      $scope.isAlreadyInOrg = function(emailToBeAdded) {
        $scope.duplicateEmailInOrg = false;
        if (emailToBeAdded) {
          var transformedEmail = emailToBeAdded.toLowerCase();
          $scope.members.find(function(member) {
            var memberEmail = member.emailAddress ? member.emailAddress : member.email;
            if (memberEmail.toLowerCase() === transformedEmail) {
              $scope.duplicateEmailInOrg = true;
              return true;
            }
          });
        }
        return $scope.duplicateEmailInOrg;
      };

      $scope.isAlreadyInCollector = function(emailToBeAdded, collector) {
        $scope.duplicateEmailInCollector = false;
        if (emailToBeAdded) {
          var transformedEmail = emailToBeAdded.toLowerCase();
          collector.find(function(item) {
            if (item.email === transformedEmail) {
              $scope.duplicateEmailInCollector = true;
              return true;
            }
          });
        }
        return $scope.duplicateEmailInCollector;
      };

      $scope.toggleBulkExample = function() {
        $scope.showBulkExample = true;
      };

      $scope.copyClipboard = function(e) {
        $log.info(e);
        alertService.show({ title: 'Link copied', type: 'success', duration: 5 });
      };

      init();
    },
  ] //End Add People Ctrl
);
