angular
  .module('lwNamb')

  .factory(
    'taskListInstanceService',

    [
      '$rootScope',
      '$timeout',
      'api',
      'qt',
      'transformer',
      'purchaseService',
      'apiUrlService',
      'orgService',
      'commandSubmissionService',
      'uuid4',
      function(
        $rootScope,
        $timeout,
        api,
        qt,
        transformer,
        purchaseService,
        apiUrlService,
        orgService,
        commandSubmissionService,
        uuid4
      ) {
        return {
          getTrainingOverview: function(userId, shouldGetCompleted) {
            var deferred = qt.defer({ timeoutSeconds: 30 });
            api.get('/v1/trainingOverview?userId=' + userId + (shouldGetCompleted ? '&completed=true' : '')).then(
              function(response) {
                deferred.resolve(response.data);
              },
              function(reason) {
                deferred.reject(reason);
              }
            );
            return deferred.promise;
          },
          getByInstanceId: function(taskListInstanceId) {
            var deferred = qt.defer({ timeoutSeconds: 30 });
            api.get('/v1/user-tasklists/' + taskListInstanceId).then(
              function(response) {
                var taskList = response.data;
                deferred.resolve(transformer.transformTaskListInstances([taskList])[0]);
              },
              function(reason) {
                deferred.reject(reason);
              }
            );
            return deferred.promise;
          },
          getTaskById: function(taskListInstanceId, taskId) {
            var deferred = qt.defer({ timeoutSeconds: 30 });
            api.get('/v1/user-tasklists/' + taskListInstanceId).then(
              function(response) {
                var taskList = response.data,
                  taskInstance;

                if (taskList.tasks) {
                  taskInstance = taskList.tasks.find(function(task) {
                    return task.id.id === taskId;
                  });
                }

                if (taskInstance) {
                  deferred.resolve(taskInstance);
                } else {
                  deferred.reject('NOT_FOUND');
                }
              },
              function(reason) {
                deferred.reject(reason);
              }
            );
            return deferred.promise;
          },
          getAssigned: function(userId, orgId) {
            var maxTries = 20;
            if (orgId === undefined) {
              return getForUserOnly({ userId: userId }).then(function(taskListInstances) {
                if (purchaseService.isPurchaseExpected()) {
                  purchaseService.getPendingPurchases().forEach(function(itemNumber) {
                    var utlsWithItem = taskListInstances.filter(function(utl) {
                      return utl.purchaseRequired === itemNumber;
                    });
                    utlsWithItem.forEach(function(utl) {
                      utl.isPurchasePending = true;
                    });
                    if (utlsWithItem.length < 1) {
                      purchaseService.removePendingPurchase(itemNumber);
                    }
                  });

                  if (
                    taskListInstances.find(function(utl) {
                      return utl.isPurchasePending;
                    })
                  ) {
                    $timeout(checkForPurchase(1), 2000);
                  }
                }

                return taskListInstances;

                function checkForPurchase(tries) {
                  return function() {
                    getForUserOnly({ userId: userId }).then(function(newTaskListInstances) {
                      taskListInstances
                        .map(function(taskListInstance, index) {
                          return {
                            i: index,
                            utl: taskListInstance,
                          };
                        })
                        .filter(function(p) {
                          return p.utl.isPurchasePending;
                        })
                        .forEach(function(p) {
                          newTaskListInstances
                            .filter(function(n) {
                              return n.id === p.utl.id && !n.purchaseRequired;
                            })
                            .forEach(function(n) {
                              taskListInstances[p.i] = n;
                              purchaseService.removePendingPurchase(p.utl.purchaseRequired);
                            });
                        });
                      if (
                        tries < maxTries &&
                        taskListInstances.find(function(utl) {
                          return utl.isPurchasePending;
                        })
                      ) {
                        $timeout(checkForPurchase(tries + 1), (tries + 1) * 1000);
                      }
                    });
                  };
                }
              });
            } else {
              return get({ userId: userId, orgId: orgId });
            }
          },

          getCompleted: function(userId, orgId) {
            if (orgId === undefined) {
              return getForUserOnly({ userId: userId, isCompleted: true });
            } else {
              return get({ userId: userId, orgId: orgId, isCompleted: true });
            }
          },

          getAssignedAndCompleted: function(userId, orgId) {
            return qt
              .all([this.getAssigned(userId, orgId), this.getCompleted(userId, orgId)])
              .then(function(responses) {
                return responses[0].sort(byTimeAssigned).concat(responses[1].sort(byTimeAssigned));
              });

            function byTimeAssigned(a, b) {
              return b.timeAssigned - a.timeAssigned;
            }
          },
          getTaskInstance: function(taskInstanceId, userId, orgId) {
            var deferred = qt.defer();
            this.getAssignedAndCompleted(userId, orgId).then(
              function(taskListInstances) {
                var taskInstance = [].concat
                  .apply(
                    [],
                    taskListInstances.map(function(list) {
                      return list.tasks.map(function(t) {
                        return { taskListInstanceId: list.id, taskInstance: t };
                      });
                    })
                  )
                  .find(function(u) {
                    return u.taskInstance.id.id === taskInstanceId;
                  });
                if (taskInstance) {
                  deferred.resolve(taskInstance);
                } else {
                  deferred.reject('NOT_FOUND');
                }
              },
              function(reason) {
                deferred.reject(reason);
              }
            );
            return deferred.promise;
          },

          generateTaskReport: function(
            taskInstanceId,
            taskListInstanceId,
            reportToGenerate,
            userId,
            orgId,
            initiatingUserId
          ) {
            var gettingTaskInstance,
              deferred = qt.defer({ timeoutSeconds: 30 }),
              service = this;
            api
              .post('/v1/commands/GenerateReportForTask', {
                id: taskListInstanceId,
                reportToGenerate: reportToGenerate,
                taskInstanceId: { id: taskInstanceId },
                initiatingUserId: initiatingUserId,
              })
              .then(null, function failure(reason) {
                deferred.reject(reason);
              });

            $rootScope.$on('ReportIdSetOnTask', function(name, event) {
              if (event.taskInstanceId.id === taskInstanceId) {
                deferred.resolve(event.reportId);
              }
            });

            deferred.promise.then(null, null, function(elapsedSeconds) {
              if (elapsedSeconds % 3 === 0 && !gettingTaskInstance) {
                gettingTaskInstance = true;
                service.getTaskInstance(taskInstanceId, userId, orgId).then(
                  function(data) {
                    var task = data.taskInstance.task;
                    var reportId;
                    if (task.relatedTask) {
                      reportId = task.relatedTask.task.reportId;
                    } else {
                      reportId = task.reportId;
                    }
                    gettingTaskInstance = false;
                    if (reportId) {
                      deferred.resolve(reportId);
                    }
                  },
                  function() {
                    gettingTaskInstance = false;
                  }
                );
              }
            });

            return deferred.promise;
          },
          setDueDate: function(taskListInstanceId, year, month, dayOfMonth, timeZone, userId, orgId, initiatingUserId) {
            var gettingTaskListInstance,
              deferred = qt.defer({ timeoutSeconds: 30 });
            api
              .post('/v1/commands/SetDueDate', {
                id: taskListInstanceId,
                year: year,
                month: month,
                dayOfMonth: dayOfMonth,
                timeZone: timeZone,
                initiatingUserId: initiatingUserId,
              })
              .then(null, function failure(reason) {
                deferred.reject(reason);
              });

            $rootScope.$on('DueDateSet', function(name, event) {
              if (event.id === taskListInstanceId) {
                deferred.resolve(event.dueDate);
              }
            });

            deferred.promise.then(null, null, function(elapsedSeconds) {
              if (elapsedSeconds % 2 === 0 && !gettingTaskListInstance) {
                gettingTaskListInstance = true;
                get({ userId: userId, orgId: orgId }).then(
                  function(taskListInstances) {
                    var utl = taskListInstances.filter(function(utl) {
                      return utl.id === taskListInstanceId;
                    })[0];
                    if (utl !== undefined && utl.dueDate !== undefined) {
                      deferred.resolve();
                    } else {
                      gettingTaskListInstance = false;
                    }
                  },
                  function() {
                    gettingTaskListInstance = false;
                  }
                );
              }
            });

            return deferred.promise;
          },
          unsetDueDate: function(taskListInstanceId, initiatingUserId, userId, orgId) {
            var gettingTaskListInstance,
              deferred = qt.defer({ timeoutSeconds: 30 }),
              service = this;
            api
              .post('/v1/commands/UnsetDueDate', {
                id: taskListInstanceId,
                initiatingUserId: initiatingUserId,
              })
              .then(null, function failure(reason) {
                deferred.reject(reason);
              });

            $rootScope.$on('DueDateUnset', function(name, event) {
              if (event.id === taskListInstanceId) {
                deferred.resolve();
              }
            });

            deferred.promise.then(null, null, function(elapsedSeconds) {
              if (elapsedSeconds % 2 === 0 && !gettingTaskListInstance) {
                gettingTaskListInstance = true;
                get({ userId: userId, orgId: orgId }).then(
                  function(taskListInstances) {
                    var utl = taskListInstances.filter(function(utl) {
                      return utl.id === taskListInstanceId;
                    })[0];
                    if (utl !== undefined && utl.dueDate === undefined) {
                      deferred.resolve();
                    } else {
                      gettingTaskListInstance = false;
                    }
                  },
                  function() {
                    gettingTaskListInstance = false;
                  }
                );
              }
            });

            return deferred.promise;
          },
          generateReportLink: function(reportId, taskName) {
            return (
              apiUrlService.getUrl() +
              '/v1/files?fileName=' +
              encodeURIComponent(taskName.replace(/ /gi, '_')) +
              '&filePath=' +
              encodeURIComponent(reportId)
            );
          },
          updateHideCompleted: function(id, userId, hideCompleted) {
            var cmd = { id: id, userId: userId, hideCompleted: hideCompleted, initiatingUserId: userId };
            return commandSubmissionService.submitAndWait(
              id,
              cmd,
              'UpdateHideCompleted',
              'HideCompletedUpdated',
              'TaskListInstanceError'
            );
          },
          markPrepareEnrichReady: function(id, taskInstanceId, assigneeId, initiatingUserId) {
            var cmd = {
              id: id,
              taskInstanceId: taskInstanceId,
              assigneeId: assigneeId,
              initiatingUserId: initiatingUserId,
            };
            return commandSubmissionService.submitAndWait(
              id,
              cmd,
              'MarkPETaskReady',
              'TaskStarted',
              'TaskListInstanceError'
            );
          },
          generateTaskListReport: function(id, initiatingUserId) {
            var cmd = { id: id, initiatingUserId: initiatingUserId };
            return commandSubmissionService.submitAndWait(
              id,
              cmd,
              'GenerateReport',
              'ReportResultToTasklistAdded',
              'CumulativeReportErrorGenerated',
              undefined,
              120
            );
          },
          removeFeedbackResponseUser: function(id, taskInstance, email, initiatingUserId) {
            var cmd = { id: id, taskInstance: taskInstance, email: email, initiatingUserId: initiatingUserId };
            return commandSubmissionService.submitAndWait(
              id,
              cmd,
              'RemoveFeedbackResponseUser',
              'FeedbackResponseUserRemoved',
              'TaskListInstanceError'
            );
          },
          addFeedbackResponseUser: function(id, taskInstance, email, firstName, callbackHost, initiatingUserId) {
            var cmd = {
              id: id,
              taskInstance: taskInstance,
              email: email,
              firstName: firstName,
              callbackHost: callbackHost,
              initiatingUserId: initiatingUserId,
            };
            return commandSubmissionService.submitAndWait(
              id,
              cmd,
              'AddFeedbackResponseUser',
              'FeedbackResponseUserAdded',
              'TaskListInstanceError'
            );
          },
          forceCompleteFeedback: function(id, taskInstanceId, requester, initiatingUserId) {
            var cmd = {
              id: id,
              taskInstanceId: taskInstanceId,
              requester: requester,
              initiatingUserId: initiatingUserId,
            };
            return commandSubmissionService.submitAndWait(
              id,
              cmd,
              'ForceCompleteFeedbackTask',
              'FeedbackTaskForceCompleted',
              'TaskListInstanceError'
            );
          },
          resendFeedbackUser: function(id, taskInstance, email, firstName, initiatingUserId) {
            var cmd = {
              id: id,
              taskInstance: taskInstance,
              email: email,
              firstName: firstName,
              initiatingUserId: initiatingUserId,
            };
            return commandSubmissionService.submitAndWait(
              id,
              cmd,
              'ReSendFeedbackEmailToObserver',
              'FeedbackEmailToObserverReSent',
              'TaskListInstanceError'
            );
          },
          completeFeedbackTask: function(id, taskInstance, email, responseId, initiatingUserId) {
            var cmd = {
              id: id,
              taskInstance: taskInstance,
              email: email,
              responseId: responseId,
              initiatingUserId: initiatingUserId,
            };
            return commandSubmissionService.submitAndWait(
              id,
              cmd,
              'CompleteFeedbackTask',
              'TaskInstanceCompleted',
              'TaskListInstanceError'
            );
          },
          getShares: function(taskListId) {
            return api.get('/v1/user-tasklists/' + taskListId + '/shares').then(function(r) {
              return r.data;
            });
          },
          shareResults: function(instanceId, sharedWith, userId, initiatingUserId, dateOverride) {
            var id = uuid4.generate();
            var d = new Date();
            if (dateOverride !== undefined) d = dateOverride;
            var cmd = {
              id: id,
              sharedOn: d.getTime(),
              assignable: {
                taskListInstanceId: {
                  id: instanceId,
                },
                _type: 'TaskList',
              },
              sharedWith: {
                id: sharedWith,
              },
              userId: {
                id: userId,
              },
              initiatingUserId: initiatingUserId,
            };
            return commandSubmissionService.submitAndWait(
              id,
              cmd,
              'ShareResults',
              'ResultsShared',
              'ResultsSharedError'
            );
          },
        };

        function get(params) {
          var queryUserId = params.userId ? '&userId=' + params.userId : '',
            queryIsCompleted = params.isCompleted ? '&isCompleted=true' : '',
            jsonRoot = params.userId ? 'assignedTasklists' : 'tasklists';
          return api
            .get('/v1/user-tasklists?orgId=' + params.orgId + queryUserId + queryIsCompleted)
            .then(function(response) {
              return transformer.transformTaskListInstances(response.data[jsonRoot]);
            });
        }

        function getForUserOnly(params) {
          var deferred = qt.defer();
          var queryIsCompleted = params.isCompleted ? '&isCompleted=true' : '',
            jsonRoot = params.userId ? 'assignedTasklists' : 'tasklists';
          api.get('/v2/user-tasklists?userId=' + params.userId + queryIsCompleted).then(function(response) {
            var transformed = transformer.transformTaskListInstances(response.data[jsonRoot]);
            deferred.resolve(transformed);
          });
          return deferred.promise;
        }
      },
    ]
  );
