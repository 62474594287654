import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';

const WorkflowCourseIconProgressLine = styled.div`
  position: absolute;
  top: ${Grid._5};
  width: ${Grid._3};
  height: calc(100% + ${Grid._12});
  background: ${props => (props.isComplete ? Color.Green._50 : Color.Gray._10)};
  visibility: ${props => (props.isLast ? 'hidden' : 'visible')};
  z-index: 0;
`;

export default WorkflowCourseIconProgressLine;
