import apiClient from './apiClient';
import uploadService from './uploadService';

export const CatalogComponentStyle = { GRID: 'Grid', CAROUSEL: 'Carousel' };
export const TrainingItemsDisplayStyle = { GRID: 'Grid', LIST: 'List' };

const carouselService = {
  getCarouselItemImageUrl: key => {
    return `${apiClient.getConfig().baseURL}/v1/images/training/${key}`;
  },
  uploadCarouselItemImage: (file, filename) => {
    return uploadService.uploadImage(file, filename, '/training');
  },
};

export default carouselService;
