import React from 'react';
import ConfirmationModal from '../../../components/ConfirmationModal';
import alertService from '../../../services/AlertService';
import { handleError } from '../../../utils/apiUtils';

type Props = {
  trainingType: 'Pathway' | 'Course';
  publish: () => Promise<void>;
  handleDismiss: () => void;
};

export const PublishTrainingModal = ({ trainingType, publish, handleDismiss }: Props) => {
  const trainingTypeLower = trainingType.toLowerCase();

  const handleSubmit = () =>
    publish()
      .then(() => {
        alertService.show(`${trainingType} Published`);
        handleDismiss();
      })
      .catch(error => {
        handleError(error);
        handleDismiss();
      });

  return (
    <ConfirmationModal
      title={`Publish ${trainingType}`}
      prompt={<>Are you sure you want to publish this {trainingTypeLower}?</>}
      buttonActionText="Publish"
      handleSubmit={handleSubmit}
      handleDismiss={handleDismiss}
    >
      Publishing this {trainingTypeLower} makes it searchable and available to be assigned
    </ConfirmationModal>
  );
};
