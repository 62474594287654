import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useManageTrainingRoute from '../../hooks/useManageTrainingRoute';
import workflowService from '../../services/workflowService';
import { useUser } from '../../authentication';
import { sessionStorage } from '../../utils/storageUtils';
import { UrlParams } from '../models/url';

export type EmailFormValues = {
  subject: string;
  body: string;
  buttonText: string;
  showButton: boolean;
};

type EmailUrlParams = UrlParams & {
  emailId?: string;
};

type Data = {
  initialFormValues: EmailFormValues;
  isEditing: boolean;
  isError: boolean;
};

export const usePathwayEmail = () => {
  const user = useUser();
  const { trainingId, emailId } = useParams<EmailUrlParams>();
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();
  const [nextPrevIds, setNextPrevIds] = useState({ previousId: '', nextId: '' });

  const initialFormValues: EmailFormValues = {
    subject: '',
    body: '',
    buttonText: 'Get Started',
    showButton: true,
  };

  const [data, setData] = useState<Data>({
    initialFormValues,
    isEditing: !!emailId,
    isError: false,
  });

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage pathways', route: `#${manageTrainingRoutePrefix}/pathways` },
    { name: 'Pathway Builder', route: `#${manageTrainingRoutePrefix}/pathways/${trainingId}` },
  ];

  const saveEmail = useCallback(
    (subject: string, body: string, buttonText: string): Promise<string> => {
      const redirectLocation = `${manageTrainingRoutePrefix}/pathways/${trainingId}`;

      if (emailId) {
        return workflowService
          .updatePathwayEmail(trainingId, emailId, subject, body, buttonText, user?.userId)
          .then(() => redirectLocation);
      }

      const { previousId, nextId } = nextPrevIds;
      return workflowService
        .addEmailToPathway(trainingId, subject, body, buttonText, previousId, nextId, user?.userId)
        .then(() => redirectLocation);
    },
    [trainingId, emailId, user, nextPrevIds]
  );

  useEffect(() => {
    if (!emailId) {
      const { previousId, nextId } = sessionStorage.getItem('pathway-builder__email') || {};

      if (!previousId && !nextId) {
        // If we don't have both previous and next ids then we can't
        // add a new email to the pathway so there's no point in proceeding
        console.error('Previous and next entity ids are required');
        return setData({ ...data, isError: true });
      }

      return setNextPrevIds({ previousId, nextId });
    }

    const { id, subject, body, buttonText } = sessionStorage.getItem('pathway-builder__email') || {};

    if (emailId !== id) {
      console.error('Stored email id does not match URL email id');
      return setData({ ...data, isError: true });
    }

    setData({ ...data, initialFormValues: { subject, body, buttonText, showButton: !!buttonText } });
  }, []);

  return { ...data, crumbs, saveEmail };
};
