import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { MediumButton, PrimaryButton } from '../../../components/Buttons';
import { FormField } from '../../../components/FormElements';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../components/ModalElements';

type Props = {
  trainingType: 'Pathway' | 'Course';
  handleCopy: (name: string) => Promise<void>;
  handleDismiss: () => void;
};

export const CloneTrainingModal = ({ trainingType, handleCopy, handleDismiss }: Props) => {
  const handleSubmit = ({ name }: { name: string }) => handleCopy(name);

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>Copy {trainingType}</h3>
      </ModalHeader>

      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('A name is required'),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <FormField label="Name" name="name" placeholder="Enter Name" />
            </ModalBody>
            <ModalFooter>
              <PrimaryButton type="submit" operating={isSubmitting}>
                Continue
              </PrimaryButton>
              <MediumButton onClick={handleDismiss}>Cancel</MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};
