import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Breakpoints } from '../../StyleGuide';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { TipSection } from '../../components/SessionTimelineContentElements';
import RichTextEditor from '../../containers/RichTextEditor';
import { Select } from '../../components/FormElements';
import useAutosave from '../../hooks/useAutosave';
import useSessionTimeline from '../../hooks/useSessionTimeline';

const TipSectionEditorContainer = styled(TipSection)`
  padding: ${Grid._4} ${Grid._4} 0px ${Grid._4};
  @media only screen and (min-width: ${Breakpoints.screen_sm}) {
    text-align: left;
    padding: ${Grid._4} ${Grid._4} 0px 72px;
    &:before {
      top: ${Grid._4};
    }
  }
`;

const TipSectionEditor = ({ type, content, onSave }) => {
  const editorRef = useRef();
  const typeRef = useRef();
  const [styleType, setStyleType] = useState(type);
  const [editorState, setEditorState] = useState(
    content ? EditorState.createWithContent(stateFromHTML(content)) : EditorState.createEmpty()
  );
  const { sectionTypeMap } = useSessionTimeline();

  useAutosave(() => {
    onSave({ tipType: styleType, text: stateToHTML(editorState.getCurrentContent()) });
  }, [typeRef, editorRef]);

  return (
    <TipSectionEditorContainer type={styleType}>
      <form id="tip-section-form">
        <Select
          ref={typeRef}
          defaultValue={type}
          name="type"
          onChange={e => setStyleType(e.target.value)}
          style={{ marginBottom: Grid._4 }}
        >
          {Object.entries(sectionTypeMap['tip'].tipType).map(([key, displayName]) => (
            <option key={key} value={key}>
              {displayName}
            </option>
          ))}
        </Select>
        <RichTextEditor
          ref={editorRef}
          editorState={editorState}
          setEditorState={setEditorState}
          blockTypes={[
            { label: 'UL', style: 'unordered-list-item' },
            { label: 'OL', style: 'ordered-list-item' },
          ]}
        />
      </form>
    </TipSectionEditorContainer>
  );
};

TipSectionEditor.propTypes = {
  type: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default TipSectionEditor;
