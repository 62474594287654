import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CalendarDateIcon from '../../components/CalendarDateIcon';
import { InlineList } from '../../components/Layout';
import { format, parseISO } from 'date-fns';
import { Grid, Type, Breakpoints, Color } from '../../StyleGuide';
import windowService from '../../services/windowService';
import useCurriculum from '../../hooks/useCurriculum';
import { ListItemFlexRow } from '../../components/ListItemFlexRow';

const SessionListSection = styled.div`
  flex: ${props => (props.flex ? props.flex : '1')};
  min-width: ${props => (props.minWidth ? props.minWidth : 'none')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : 'none')};
  margin-right: ${Grid._6};
  padding: ${Grid._3} 0px;
`;

const SessionTitle = styled.h3`
  font-size: ${Type.Scale._3};
  font-weight: ${Type.Weight.semibold};
  margin: 0 0 ${Grid._3} 0;
  line-height: 22px;
`;

const MobileMaterialContainer = styled.div`
  display: inline-block;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    display: none;
  }
`;

const ManageCurriculumSessionListItem = ({ session, index }) => {
  const { brand, ageCategory } = useCurriculum();
  const date = new Date(session.useDate);
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate().toString();
  const { curriculumId, issueId, sessionId } = session;

  const handleEditSessionRedirect = () => {
    windowService.redirectTo(
      `/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${issueId}/session/${sessionId}/settings`
    );
  };

  const materialsCount = session?.materials?.length || 0;
  const materialsLabel = materialsCount === 1 ? 'Material' : 'Materials';
  const formattedMaterialString = materialsCount > 0 ? `${materialsCount} ${materialsLabel}` : 'No Materials';

  return (
    <ListItemFlexRow onClick={() => handleEditSessionRedirect()} hoverStyles={`background: ${Color.white};`}>
      <SessionListSection maxWidth="61px">
        <CalendarDateIcon month={month} day={day} />
      </SessionListSection>
      <SessionListSection flex="4">
        <div>
          <SessionTitle data-qa-hook="sessionTitle">{session?.name}</SessionTitle>
          <InlineList>
            <li>Session {index + 1}</li>
            {session.publishStartDate && session.publishEndDate && (
              <li>
                Available {format(parseISO(session.publishStartDate), 'MMM d, yyyy')} -{' '}
                {format(parseISO(session.publishEndDate), 'MMM d, yyyy')}{' '}
                <MobileMaterialContainer> • {formattedMaterialString}</MobileMaterialContainer>
              </li>
            )}
          </InlineList>
        </div>
      </SessionListSection>
      <SessionListSection minWidth="90px">
        <InlineList>
          <li>{formattedMaterialString}</li>
        </InlineList>
      </SessionListSection>
    </ListItemFlexRow>
  );
};

ManageCurriculumSessionListItem.propTypes = {
  session: PropTypes.object,
  index: PropTypes.number,
};

export default ManageCurriculumSessionListItem;
