angular.module('lwNamb').controller(
  'SessionSettingsCtrl',

  [
    '$scope',
    'curriculumService',
    '$routeParams',
    'windowService',
    'alertService',
    'uploadService',
    function(
      $scope,
      curriculumService,
      $routeParams,
      windowService,
      alertService,
      uploadService
    ) {
      function init() {
        $scope.loading = true;
        $scope.curriculumId = $routeParams.curriculumId;
        $scope.issueId = $routeParams.issueId;
        $scope.sessionId = $routeParams.sessionId;
        $scope.session = {};
        $scope.saving = false;
        $scope.isSettingDescription = false;
        $scope.replaceImage = false;

        curriculumService.getSession($scope.issueId, $scope.sessionId)
          .then(function(session) {
            $scope.session = session;
          })
          .catch(failure)
          .finally(function() {
            $scope.loading = false;
          });
      }

      $scope.sessionBuilder = function() {
        windowService.redirectHash(
          'curriculum/' +
          $scope.curriculumId +
          '/issue/' +
          $scope.issueId +
          '/session-builder/' +
          $scope.sessionId
        );
      };

      $scope.setImage = function(image) {
        var fileName = $scope.sessionId + '.jpg';
        $scope.saving = true;

        uploadService.uploadCurriculumImage(fileName, image)
          .then(function() {
            return curriculumService.setImage($scope.issueId, $scope.sessionId, fileName);
          })
          .then(function() {
            alertService.show({ type: 'success', title: 'Session Image Saved', duration: 5 });
            $scope.sessionBuilder();
          })
          .catch(failure)
          .finally(function() {
            $scope.saving = false;
          });
      };

      $scope.setDescription = function(description) {
        $scope.isSettingDescription = true;

        curriculumService.setSessionDescription($scope.issueId, $scope.sessionId, description)
          .then(function() {
            alertService.show({ type: 'success', title: 'Session Description Saved', duration: 5 });
            $scope.sessionBuilder();
          })
          .catch(failure)
          .finally(function() {
            $scope.isSettingDescription = false;
          });
      };

      function failure() {
        alertService.show({ type: 'danger', title: 'Error! Something went wrong.', duration: 5 });
      }

      init();
    },
  ]
);
