import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid, Type } from '../StyleGuide';
import { useSteps, updateCurrentStepInCourse, useCompleteStep } from './StepsContext';
import fileService from '../services/fileService';
import Section from './Section';
import LoadingSection from './LoadingSection';
import { FullWidthButton } from '../components/Buttons';
import FileUploader from '../components/FileUploader';
import trainingInstanceService from '../services/trainingInstanceService';

const MetaData = styled.div`
  text-align: center;
  h4 {
    color: ${Type.Color.dark};
    margin-top: 0;
    margin-bottom: ${Grid._4};
  }
  label {
    text-transform: uppercase;
    font-size: ${Type.Scale._05};
    color: ${Type.Color.medium};
    margin: ${Grid._4} auto 0;
  }
  button {
    margin: ${Grid._4} auto;
  }
`;

export default function UploadStep() {
  const [{ currentCourse, currentStep, assignedUser, assigningOrg }, dispatch] = useSteps();
  const { completeStep } = useCompleteStep();
  const taskDescription = currentStep.task && currentStep.task.taskDescription;
  const [showUpload, setShowUpload] = useState(true);
  const [metaData, setMetaData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [assetId, setAssetId] = useState();

  const showUploadUI = () => {
    setShowUpload(true);
    setMetaData(null);
  };

  useEffect(() => {
    if (currentStep.status === 'in_progress' || currentStep.status === 'complete') {
      setIsLoading(true);
      fileService
        .getMetaData(currentStep.task.uploadedAssetUrl)
        .then(response => {
          const data = response.fileMetaData;
          setMetaData(data);
          setShowUpload(false);
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => setIsLoading(false));
    }
    return () => {
      showUploadUI();
    };
  }, [currentStep.id.id, currentStep.status, currentStep.task.uploadedAssetUrl]);

  useEffect(() => {
    if (metaData && metaData._id && metaData._id.id !== currentStep.task.uploadedAssetUrl) {
      showUploadUI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaData]);

  const submitFile = () => {
    setIsSubmitting(true);

    trainingInstanceService
      .submitFile(currentCourse, currentStep, assetId)
      .then(() => updateCurrentStepInCourse(dispatch, { newStatus: 'complete', uploadedAssetUrl: assetId }))
      .then(() => completeStep())
      .catch(error => {
        console.error('Set Asset Function Error: ', error);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      {isLoading && <LoadingSection />}
      {!isLoading && (
        <Section>
          {taskDescription && (
            <div data-qa-hook="stepDescription" dangerouslySetInnerHTML={{ __html: taskDescription }}></div>
          )}
          {showUpload ? (
            <div style={{ marginTop: '24px' }}>
              <FileUploader
                userId={assigningOrg}
                orgId={assignedUser}
                onFileUploaded={({ id }) => setAssetId(id)}
                onFileRemoved={setAssetId}
              />
              {assetId && <p className="text-center text-color-medium">Your file is ready to be submitted</p>}
              <FullWidthButton onClick={submitFile} disabled={!assetId || isSubmitting} operating={isSubmitting}>
                <i className="fas fa-upload"></i> Submit
              </FullWidthButton>
            </div>
          ) : (
            <MetaData>
              <label>File Name:</label>
              <h4>{metaData.fileName}</h4>
              <FullWidthButton
                onClick={() => {
                  window.open(fileService.urlOpenFile(metaData._id.id, metaData.fileName));
                }}
              >
                <i className="fas fa-download"></i> Download File
              </FullWidthButton>
              <FullWidthButton
                onClick={() => {
                  showUploadUI();
                }}
              >
                <i className="fas fa-redo-alt"></i> Replace File
              </FullWidthButton>
            </MetaData>
          )}
        </Section>
      )}
    </>
  );
}
