import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Type } from '../StyleGuide';
import { MediumPrimaryButton, MediumButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';
import { AutoFocusInput } from '../components/FormElements';
import MaxChars from '../components/MaxChars';
import ErrorMessage from '../components/ErrorMessage';

const AddGroupModalBody = styled(ModalBody)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  label {
    margin: 0 0 ${Grid._3};
    color: ${Type.Color.dark};
    font-weight: ${Type.Weight.semibold};
  }
`;

const AddGroupModal = ({ handleDismiss, handleSubmit, isDuplicateName }) => {
  const [groupName, setGroupName] = useState('');
  const [UIState, setUIState] = useState({ isSubmitting: false, isError: '' });

  useEffect(() => {
    if (isDuplicateName) return setUIState({ isSubmitting: false, isError: isDuplicateName });
  }, [isDuplicateName]);

  const onClickHandler = () => {
    setUIState({ isSubmitting: true, isError: '' });

    if (!groupName.trim()) return setUIState({ isSubmitting: false, isError: 'A name is required' });

    handleSubmit(groupName.trim());
  };

  const handleClearInput = () => {
    setGroupName('');
    if (UIState.isError) setUIState({ isSubmitting: false, isError: '' });
  };

  const handleOnChange = e => {
    if (UIState.isError) setUIState({ isSubmitting: false, isError: '' });
    setGroupName(e.target.value);
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>New Group</h3>
      </ModalHeader>
      <AddGroupModalBody data-qa-hook="groupsAddModal">
        <div className="space-between">
          <label htmlFor="groupName">Name</label>
          <MaxChars maxChars={64} currentChars={groupName.length} />
        </div>
        <AutoFocusInput
          autoFocus={true}
          id="groupName"
          placeholder={'Name the group'}
          value={groupName}
          maxLength={64}
          onChangeHandler={handleOnChange}
          clearInput={handleClearInput}
        />
        {UIState.isError && <ErrorMessage>{UIState.isError}</ErrorMessage>}
      </AddGroupModalBody>
      <ModalFooter>
        <MediumPrimaryButton
          data-qa-hook="groupsAddModalCreateButton"
          onClick={onClickHandler}
          disabled={UIState.isSubmitting || !groupName.length}
          operating={UIState.isSubmitting}
        >
          Create Group
        </MediumPrimaryButton>
        <MediumButton data-qa-hook="groupsAddModalCancelButton" onClick={() => handleDismiss()}>
          Cancel
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

AddGroupModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  isDuplicateName: PropTypes.string,
};

export default AddGroupModal;
