angular.module('lwNamb').controller('WorkflowBuilderSelectCtrl', [
  '$scope',
  '$routeParams',
  '$log',
  '$location',
  'userService',
  'taskListService',
  'alertService',
  'workflowService',
  'analyticsService',
  'buildService',
  function(
    $scope,
    $routeParams,
    $log,
    $location,
    userService,
    taskListService,
    alertService,
    workflowService,
    analyticsService,
    buildService
  ) {
    var init = function() {
      analyticsService.trackFeature('WFBuilderSelect', 'loaded');
      $scope.spinner = false;
      $scope.workflowId = $routeParams.workflowId;

      userService.user().then(
        function(user) {
          $scope.user = user;
        },
        function() {
          $log.error('No user returned in WorkflowBuilderSelectCtrl');
        }
      );
    };

    $scope.addTaskListsToWorkflow = function(selectedTaskLists) {
      selectedTaskLists.forEach(function(tl) {
        analyticsService.trackFeature('WFBuilderSelect', 'addedSelectedTaskList:published-' + tl.published);
      });
      workflowService
        .addTaskListsToWorkflow(
          $scope.workflowId,
          selectedTaskLists.map(function(tl) {
            return tl.id;
          }),
          $location.search().previousId,
          $location.search().nextId,
          $scope.user.userId
        )
        .then(
          function(workflowId) {
            if (window.cacheService) {
              window.cacheService.removeAllInPath(`/v1/workflows/${workflowId}`);
            }

            $scope.attaching = false;
            buildService.ctxRedirectBackTo('/pathways/' + workflowId);
          },
          function(reason) {
            $log.error(reason);
            $scope.attaching = false;
            alertService.show({
              type: 'danger danger-with-content',
              title: 'Unexpected Error!',
              content: 'Something went wrong. Please try again.',
              duration: 20,
            });
          }
        );
    };

    init();
  },
]);
