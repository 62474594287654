import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import discussionService from '../../services/discussionService';
import { Grid, Border, Color, Type } from '../../StyleGuide';
import styled from 'styled-components';
import { RoundedButton, SmallWhiteButton, LinkStyleButton } from '../../components/Buttons';
import TextTruncate from '../../containers/TextTruncate';
import NewCommentForm from './NewCommentForm';
import ConfirmationModal from '../../components/ConfirmationModal';
import alertService from '../../services/AlertService';
import LoadingState from '../../components/LoadingState';

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  border-radius: ${Border.radius};
  background-color: ${props => (props.highlight ? `${Color.Blue._10}` : 'transparent')};
  padding: ${props => (props.highlight ? `${Grid._5} ${Grid._4}` : `${Grid._5} auto`)};
  margin-top: ${Grid._8};

  button {
    width: auto !important;
  }
`;

const DeleteCommentButton = styled(SmallWhiteButton)`
  border: none;
  margin-left: ${Grid._3};
  color: ${Color.Blue._50};
`;

const CommentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  flex-wrap: wrap;
  .comment-author {
    color: ${Color.Primary._70};
    font-size: ${Type.Scale._3};
    font-weight: ${Type.Weight.semibold};
  }
  .comment-time {
    color: ${Type.Color.medium};
    margin-right: ${Grid._2};
    text-transform: lowercase;
  }
  .comment-time,
  .comment-date {
    font-size: ${Type.Scale._2};
    font-weight: ${Type.Weight.light};
  }
`;

const CommentFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Comment = ({ comment, defaultArgs, dispatch }) => {
  const replies = comment._replies || [];
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [loadingReplies, setLoadingReplies] = useState(false);
  const [removeCommentModal, setRemoveCommentModal] = useState({ show: false, comment: {} });
  const user = defaultArgs[0];
  const canRejectComment = comment.lifeway_id !== user.userId && user.isCommentModerator;
  const hasReplies = comment.replies > 0;

  const showFailure = () =>
    alertService.show(
      'Oops, something went wrong. We’re sorry for the inconvenience. Please try again later.',
      'error'
    );

  useEffect(() => {
    if (comment.scrollToReplies) {
      document.getElementById(`comment-replies-${comment.id}`).scrollIntoView({ block: 'end', behavior: 'smooth' });
      dispatch({ type: 'ScrollToRepliesSet', data: { id: comment.id, scrollToReplies: false } });
    }
  }, [comment.scrollToReplies]);

  useEffect(() => {
    if (showReplyForm) {
      const replyForm = document.getElementById(`reply-form-comment-${comment.id}`);
      replyForm.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [showReplyForm]);

  //State Handlers
  const removeComment = (commentToRemove, isRejected) => {
    const promise = isRejected
      ? discussionService.rejectComment(commentToRemove.id, commentToRemove.discussionId)
      : discussionService.removeComment(commentToRemove.id, commentToRemove.discussionId);

    promise
      .then(() => {
        // fake a removed event (required until api adds status events)
        dispatch({ type: 'CommentRemoved', data: commentToRemove });
      })
      .catch(() => showFailure());
  };

  const upvoteComment = (commentId, discussionId) => {
    discussionService.upvoteComment(commentId, discussionId, ...defaultArgs).catch(_ => showFailure());
  };

  const handleReplySubmission = (commentText, parentId) => {
    return discussionService
      .postReply(commentText, parentId, comment.discussionId, ...defaultArgs)
      .then(reply => {
        if (!comment.showReplies) {
          if (comment._replies) {
            dispatch({ type: 'ShowRepliesSet', data: { id: comment.id, showReplies: true } });
            dispatch({ type: 'ScrollToRepliesSet', data: { id: comment.id, scrollToReplies: true } });
          } else {
            setLoadingReplies(true);
            discussionService
              .getCommentReplies(comment.id, comment.discussionId, ...defaultArgs)
              .then(replies => {
                const _replies = replies.find(r => r.id === reply.id) ? replies : [...replies, reply];
                dispatch({
                  type: 'RepliesLoaded',
                  data: { id: comment.id, _replies: _replies, scrollToReplies: true },
                });
              })
              .finally(_ => setLoadingReplies(false))
              .catch(_ => showFailure());
          }
        }
      })
      .finally(_ => setShowReplyForm(false))
      .catch(_ => showFailure());
  };

  const handleViewReplies = () => {
    if (comment.showReplies || replies.length) {
      dispatch({ type: 'ShowRepliesSet', data: { id: comment.id, showReplies: !comment.showReplies } });
    } else {
      setLoadingReplies(true);
      discussionService
        .getCommentReplies(comment.id, comment.discussionId, ...defaultArgs)
        .then(replies => dispatch({ type: 'RepliesLoaded', data: { id: comment.id, _replies: replies } }))
        .finally(_ => setLoadingReplies(false))
        .catch(_ => showFailure());
    }
  };

  // Render Components
  const Replies = props => (
    <div id={`comment-replies-${comment.id}`}>
      {props.replies.map((reply, i) => (
        <CommentUI key={i} comment={reply} isHighlighted={true} />
      ))}
    </div>
  );

  const CommentUI = props => {
    const { author, id, discussionId, lifeway_id, text, created, upvoted, upvoters, replies } = props.comment;
    const { isHighlighted } = props;
    let createdOnDate = new Date(created);
    const isAuthor = lifeway_id === user.userId;
    const canRejectComment = lifeway_id !== user.userId && user.isCommentModerator;
    const hasUpvoted = upvoters && upvoters.find(u => u === user.userId);
    const canUpvote = lifeway_id !== user.userId;
    const [removeReplyModal, setRemoveReplyModal] = useState({ show: false, comment: {} });

    return (
      <CommentContainer data-qa-hook="commentReplyContainer" highlight={isHighlighted}>
        <CommentHeader>
          <div data-qa-hook="commentReplyAuthor" className="comment-author">
            {author}
            {isAuthor && <span> (you)</span>}
          </div>
          <div>
            <span data-qa-hook="commentReplyTime" className="comment-time">
              {createdOnDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}
            </span>
            <span data-qa-hook="commentReplyDate" className="comment-date">
              {createdOnDate.toLocaleDateString()}
            </span>
            {(isAuthor || canRejectComment) && (
              <DeleteCommentButton
                data-qa-hook="removeCommentReplyButton"
                onClick={() => setRemoveReplyModal({ show: true, comment: props.comment })}
              >
                <i className="far fa-trash-alt" />
              </DeleteCommentButton>
            )}
            {removeReplyModal.show && (
              <ConfirmationModal
                buttonActionText="Remove"
                buttonType="danger"
                title="Remove Comment"
                prompt={<span>Are you sure you want to remove this comment?</span>}
                subtext={removeReplyModal.comment.author}
                handleSubmit={() => {
                  removeComment(removeReplyModal.comment, canRejectComment);
                }}
                handleDismiss={() => setRemoveReplyModal({ show: false, comment: {} })}
              >
                <p data-qa-hook="removeCommentReplyModalComment">{removeReplyModal.comment.text}</p>
              </ConfirmationModal>
            )}
          </div>
        </CommentHeader>
        <TextTruncate text={text} truncateLength={165} />

        <CommentFooter>
          {!canUpvote && (
            <LinkStyleButton data-qa-hook="numberOfLikes" disabled={true}>
              <i className="fa fa-thumbs-up" /> {upvoted} Likes
            </LinkStyleButton>
          )}

          {/* Like/Upvote button */}
          {canUpvote && (
            <RoundedButton
              data-qa-hook="likeButton"
              active={hasUpvoted}
              disabled={hasUpvoted}
              onClick={() => upvoteComment(id, discussionId)}
            >
              <i className="fa fa-thumbs-up" /> Like{hasUpvoted ? 'd' : ''}{' '}
              <span data-qa-hook="numberOfLikes">+{upvoted}</span>
            </RoundedButton>
          )}

          <div>
            {!hasReplies && !isHighlighted && (
              <LinkStyleButton data-qa-hook="viewHideReplies" disabled={true}>
                0 Replies
              </LinkStyleButton>
            )}

            {/* View Replies */}
            {hasReplies && !isHighlighted && (
              <LinkStyleButton data-qa-hook="viewHideReplies" onClick={handleViewReplies}>
                {comment.showReplies ? 'Hide' : 'View'} {replies} Repl{replies > 1 ? 'ies' : 'y'}
              </LinkStyleButton>
            )}

            {/* Reply Button */}
            {!isHighlighted && (
              <RoundedButton
                data-qa-hook="replyButton"
                active={showReplyForm}
                onClick={() => setShowReplyForm(prevState => !prevState)}
              >
                <i className="fa fa-comment" /> Reply
              </RoundedButton>
            )}
          </div>
        </CommentFooter>
      </CommentContainer>
    );
  };

  return (
    <>
      <CommentUI comment={comment} />
      {loadingReplies && (
        <CommentContainer highlight={true}>
          <LoadingState />
        </CommentContainer>
      )}
      {comment.showReplies && <Replies replies={replies} />}
      {showReplyForm && (
        <div id={`reply-form-comment-${comment.id}`}>
          <NewCommentForm
            handleSubmission={handleReplySubmission}
            orgName={user.currentOrgName}
            isReply={comment.id}
            replyToName={comment.author}
          />
        </div>
      )}
      {removeCommentModal.show && (
        <ConfirmationModal
          buttonActionText="Remove"
          buttonType="danger"
          title={'Remove Comment'}
          prompt={<span>Are you sure you want to remove this comment?</span>}
          subtext={removeCommentModal.comment.author}
          handleSubmit={() => removeComment(removeCommentModal.comment, canRejectComment)}
          handleDismiss={() => setRemoveCommentModal({ show: false, comment: {} })}
        >
          <p data-qa-hook="removeCommentReplyModalComment">{removeCommentModal.comment.text}</p>
        </ConfirmationModal>
      )}
    </>
  );
};

Comment.propTypes = {
  comment: PropTypes.object.isRequired,
  defaultArgs: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Comment;
