import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';

const InfoBox = styled.div`
  font-size: ${Type.Scale._2};
  line-height: ${Type.Leading.tall};
  a {
    text-decoration: underline;
    color: ${Color.Gray._90};
    cursor: pointer;
  }
`;

export default InfoBox;
