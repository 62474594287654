angular.module('lwNamb').factory('alertService', [
  '$alert',
  function($alert) {
    var currentAlert;
    return {
      //Must pass in type with two classes i.e. 'success success-with-content' or 'danger danger-with-content' to render correctly with a title and a message
      show: function(alert) {
        if (currentAlert !== undefined) {
          currentAlert.destroy(); //need to use destroy vs hide because we "fade" so we get stacking alerts. this will spit out errors we can ignore
        }
        alert.container = '#alerts-banner-container';
        currentAlert = $alert(alert);
      },
      destroy: function(){
        if (currentAlert !== undefined) {
          currentAlert.destroy(); //need to use destroy vs hide because we "fade" so we get stacking alerts. this will spit out errors we can ignore
        }
      }
    };
  },
]);
