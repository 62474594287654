import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import RichTextEditor from '../../containers/RichTextEditor';
import { MediumButton, MediumPrimaryButton } from '../../components/Buttons';
import ErrorMessage from '../../components/ErrorMessage';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../../components/ModalElements';
import { Label, FormField } from '../../components/FormElements';

const NoteModal = ({ note, handleSubmit, handleDismiss }) => {
  const [editorState, setEditorState] = useState(
    note.body ? EditorState.createWithContent(stateFromHTML(note.body)) : EditorState.createEmpty()
  );

  const validateRichText = () => !editorState.getCurrentContent().hasText() && 'A description is required';

  return (
    <ModalContainer dismissHandler={handleDismiss} disableBackgroundDismiss>
      <ModalHeader data-qa-hook="modalContainer">
        <h3>{note.id ? 'Edit' : 'Add'} Note</h3>
      </ModalHeader>
      <Formik
        initialValues={{ title: note.title ?? '', body: note.body ?? '' }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          title: Yup.string().max(48, 'Title is too long'),
        })}
        onSubmit={({ title }) => handleSubmit({ ...note, title, body: stateToHTML(editorState.getCurrentContent()) })}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <FormField data-qa-hook="noteTitle" label="Title" name="title" type="text" placeholder="Enter Title" />
              <Label>Description</Label>
              <Field validate={validateRichText} name="body">
                {({ meta }) => (
                  <>
                    <RichTextEditor
                      editorState={editorState}
                      setEditorState={setEditorState}
                      blockTypes={[]}
                      config={{
                        placeholder: 'Enter Description',
                        height: '120px',
                      }}
                    />
                    {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                  </>
                )}
              </Field>
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton
                data-qa-hook="modalConfirm"
                type="submit"
                disabled={isSubmitting}
                operating={isSubmitting}
              >
                {note.id ? 'Save' : 'Add'} Note
              </MediumPrimaryButton>
              <MediumButton data-qa-hook="modalCancel" onClick={handleDismiss}>
                Cancel
              </MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};

NoteModal.propTypes = {
  note: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default NoteModal;
