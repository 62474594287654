angular.module('lwNamb').factory('activeCampaignService', [
  '$window',
  '$log',
  'api',
  function($window, $log, api) {
    return {
      event: function(eventName, email){
        return api.post("/trackingAC", {
          event: {},
          eventName: eventName,
          email: email
        }, {}, 0);
      },
      track: function(userId, email, firstName, lastName, userName, currentOrgId, currentOrgName,
                      currentOrgRoles, gitHash, url, orgSub, curriculumSub, personalSub, allRoles){
        function cleanRoles(roles, append){
          var roleObj = {};
          for(var i = 0; i < roles.length; i++){
            var n = roles[i].split(' ').join('') + append;
            n = n.charAt(0).toLocaleLowerCase() + n.slice(1);
            roleObj[n] = true;
          }
          return roleObj;
        }
        var all = cleanRoles(allRoles, "");
        var current = cleanRoles(currentOrgRoles, "Current");
        var roles = Object.assign(current, all);
        var p = {
          currentOrgId: currentOrgId,
          currentOrgName: currentOrgName,
          orgSub: orgSub,
          curriculumSub: curriculumSub,
          personalSub: personalSub,
          viewOnlyCurrent: false,
          groupsViewCurrent: false,
          rolesViewCurrent: false,
          accountViewCurrent: false,
          buildViewCurrent: false,
          coachViewCurrent: false,
          assignViewCurrent: false,
          buildCurriculumViewCurrent: false,
          buildCurriculumView: false,
          viewOnly: false,
          assessmentsView: false,
          workflowsView: false,
          groupView: false,
          taskView: false,
          rolesView: false,
          buildView: false,
          accountView: false,
          groupsView: false,
          coachView: false,
          assignView: false
        };
        var payload = Object.assign(p, roles);
        return api.post('/trackUser', {
          email: email,
          payload: payload
        });
      }
    };
  },
]);
