import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { Container } from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import { TextFilterInput } from '../components/FormElements';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { TileButton, PrimaryButton } from '../components/Buttons';
import AssessmentQuestionsList from './AssessmentQuestionsList';
import AddAssessmentQuestionModal from './AddAssessmentQuestionModal';
import CopyAssessmentModal from './CopyAssessmentModal';
import PublishAssessmentModal from './PublishAssessmentModal';

import windowService from '../services/windowService';
import assessmentService from '../services/assessmentService';

import { useUser } from '../authentication';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';

import { Grid, Color, Breakpoints } from '../StyleGuide';

const StyledContainer = styled(Container)`
  margin-bottom: ${Grid._14};
`;

const DivRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: ${Grid._3};
  align-items: center;
`;

const DivColumn = styled.div`
  flex: column;
`;

const MinorText = styled.p`
  color: ${Color.Gray._80};
`;

const StyledHeader = styled.h1`
  margin-top: 0;
  color: ${Color.Gray._95};
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
  grid-gap: ${Grid._4};

  > button {
    margin: 0;
  }
`;

const PublishButton = styled(PrimaryButton)`
  flex: 0 1 180px;
  margin-left: ${Grid._4};
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: ${Grid._5};
  margin: ${Grid._6} 0;

  @media screen and (min-width: ${Breakpoints.screen_xs}) {
    flex-direction: row;

    > button {
      max-width: 340px;
      min-width: 220px;
    }
  }

  > * {
    flex: 1 1 100%;
  }
`;

const AssessmentBuilder = () => {
  const user = useUser();
  const { assessmentId } = useParams();
  const { manageTrainingRoutePrefix, createManageTrainingCrumb } = useManageTrainingRoute();

  const [status, setStatus] = useState({ isLoading: true, isError: false, isSubmitting: false });
  const [assessment, setAssessment] = useState({});
  const [filterQuery, setFilterQuery] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [addQuestionModalOpen, setAddQuestionModalOpen] = useState(false);
  const [publishAssessmentModalOpen, setPublishAssessmentModalOpen] = useState(false);
  const [copyAssessmentModalOpen, setCopyAssessmentModalOpen] = useState(false);

  useEffect(() => {
    assessmentService
      .getAssessment(assessmentId, user.userId)
      .then(res => {
        setAssessment(res);
        setStatus({ ...status, isLoading: false, isError: false });
      })
      .catch(error => {
        console.error(error);
        setStatus({ ...status, isLoading: false, isError: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredQuestions(assessment.questions);
  }, [assessment.questions]);

  useEffect(() => {
    if (filteredQuestions.length === 0 || filteredQuestions === undefined) return;

    setFilteredQuestions(
      assessment.questions.filter(
        q =>
          q.questionValue.some(val => val.value.toLowerCase().includes(filterQuery.toLowerCase())) ||
          q.possibleAnswers.some(({ value }) =>
            value?.some(val => val.value.toLowerCase().includes(filterQuery.toLowerCase()))
          )
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery]);

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    createManageTrainingCrumb('content'),
    { name: 'Assessment Builder' },
  ];

  const handleKeyDown = event => {
    if (event.key === 'Enter') setFilterQuery(event.target.value);
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <StyledContainer data-qa-hook="assessmentBuilderView">
        {status.isLoading ? (
          <LoadingState />
        ) : status.isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : (
          <>
            {assessment.published ? (
              <HelpAndInfoBox
                title={'This Assessment Has Been Published'}
                description={
                  'We have locked this assessment to prevent it from breaking any reporting features. If you need to make major changes, consider copying this assessment and creating a new one to assign in future courses'
                }
              />
            ) : (
              <div>
                <HelpAndInfoBox
                  title={'Publish Assessment'}
                  description={
                    'When you are finished building this assessment use the Publish button to make it available in the course builder. Once published you will not be able to make any major changes.'
                  }
                >
                  <PublishButton
                    data-qa-hook="publishAssessment"
                    disabled={status.isSubmitting}
                    operating={status.isSubmitting}
                    onClick={() => setPublishAssessmentModalOpen(true)}
                  >
                    Publish
                  </PublishButton>
                </HelpAndInfoBox>
              </div>
            )}
            <DivRow>
              <DivColumn>
                <MinorText>
                  <i className="fas fa-clipboard-list"></i> Assessment
                </MinorText>
                <StyledHeader data-qa-hook="assessmentBuilderName">{assessment.name}</StyledHeader>
              </DivColumn>
              <ButtonWrapper>
                <TileButton
                  data-qa-hook="assessmentBuilderSettings"
                  onClick={() =>
                    windowService.redirectTo(`${manageTrainingRoutePrefix}/assessments/${assessmentId}/settings`)
                  }
                >
                  <i className="fas fa-pencil-alt"></i> Edit Settings
                </TileButton>
                <TileButton data-qa-hook="assessmentBuilderCopy" onClick={() => setCopyAssessmentModalOpen(true)}>
                  <i className="fas fa-copy"></i>Copy Assessment
                </TileButton>
              </ButtonWrapper>
            </DivRow>
            <ActionRow>
              {!assessment.published && (
                <PrimaryButton
                  data-qa-hook="assessmentBuilderAdd"
                  onClick={() => setAddQuestionModalOpen(true)}
                  style={{ width: '100%', padding: '12px 20px' }}
                >
                  <i className="fas fa-plus"></i> Add Question
                </PrimaryButton>
              )}
              <TextFilterInput
                id="filterQuestions"
                className="filterQuestions"
                placeholder="Filter Questions"
                value={filterQuery}
                onChangeHandler={event => {
                  setFilterQuery(event.target.value);
                  setFilteredQuestions(assessment.questions);
                }}
                onKeyDownHandler={handleKeyDown}
                clearInput={() => {
                  setFilterQuery('');
                  setFilteredQuestions(assessment.questions);
                }}
              />
            </ActionRow>
            <AssessmentQuestionsList
              assessmentQuestions={filteredQuestions}
              assessmentId={assessmentId}
              assessment={assessment}
              setAssessment={setAssessment}
              setFilteredQuestions={setFilteredQuestions}
              isFiltering={filterQuery.length > 0}
            />
            {copyAssessmentModalOpen && (
              <CopyAssessmentModal
                assessment={assessment}
                assessmentId={assessmentId}
                setCopyAssessmentModalOpen={setCopyAssessmentModalOpen}
              />
            )}
            {addQuestionModalOpen && (
              <AddAssessmentQuestionModal
                setAddQuestionModalOpen={setAddQuestionModalOpen}
                assessmentId={assessmentId}
              />
            )}
            {publishAssessmentModalOpen && (
              <PublishAssessmentModal
                setPublishAssessmentModalOpen={setPublishAssessmentModalOpen}
                assessmentId={assessmentId}
                setAssessment={setAssessment}
              />
            )}
          </>
        )}
      </StyledContainer>
    </>
  );
};

export default AssessmentBuilder;
