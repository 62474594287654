angular.module('lwNamb').directive(
  'taskViewerVideo',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/task-viewer-video.html',
        scope: {
          task: '=',
          taskListId: '@',
          isTaker: '=?',
          completeTask: '&',
          colClass: '@',
        },
        replace: true,
        controller: [
          '$scope',
          'taskService',
          '$log',
          function($scope, taskService, $log) {
            $scope.$on('TaskInstanceStatusInTaskListInstanceSetInProgress', function(event_name, event) {
              if (event.taskInstance.id.id === $scope.task.id.id && $scope.isTaker) {
                $scope.task.status = 'in_progress';
              }
            });

            $scope.$on('TaskInstanceStatusInTaskListInstanceSetComplete', function(event_name, event) {
              if (event.taskInstanceId.id === $scope.task.id.id && $scope.isTaker) {
                $scope.task.status = 'complete';
              }
            });

            $scope.$on('VideoDurationFound', function(event_name, event) {
              if (event.id === $scope.task.task.videoId) {
                $scope.video = {
                  minutes: event.minutes,
                  seconds: event.seconds,
                };
              }
            });

            $scope.$on('VideoStarted', function(event_name, event) {
              $scope.start();
            });

            $scope.$on('VideoComplete', function(event_name, event) {
              if (event.id && event.id.indexOf($scope.task.task.videoId) > -1) {
                $scope.complete();
              }
            });

            $scope.start = function() {
              if ($scope.task.status === 'not_started' && $scope.isTaker) {
                taskService
                  .startTask($scope.taskListId, $scope.task, $scope.task.assigneeId.id, $scope.task.assigneeId.id)
                  .then(
                    function() {
                      $scope.task.status = 'in_progress';
                    },
                    function(reason) {
                      $log.error(reason);
                    }
                  );
              }
            };

            $scope.complete = function() {
              if ($scope.task.status !== 'complete') {
                $scope.completeTask($scope.task, $scope.taskListId, $scope.isTaker);
              }
            };
          },
        ],
      };
    },
  ]
);
