import assessmentService from '../../services/assessmentService';
import trainingService from '../../services/trainingService';
import videoService from '../../services/VideoService';
import { Content } from '../models/content';

export const removeContent: (content: Content, ownerId: string, initiatingUserId: string) => Promise<void> = (
  content,
  ownerId,
  initiatingUserId
) => {
  if (content.type === 'Assessment') return assessmentService.deleteAssessment(content.id, ownerId, initiatingUserId);
  if (content.type === 'Download')
    // TODO: I don't think we want to download the archive here
    return trainingService.downloadArchive(content.id);
  if (content.type === 'Video') return videoService.deleteVideo(content.id, content.name, ownerId, initiatingUserId);
  return Promise.reject(new Error(`Invalid content type: ${content.type}`));
};
