angular.module('lwNamb').controller(
  'WorkflowBuilderEmailCtrl',

  [
    '$scope',
    '$routeParams',
    '$localStorage',
    '$location',
    '$q',
    'userService',
    'workflowService',
    'emailTemplateService',
    '$timeout',
    '$log',
    'analyticsService',
    'buildService',
    function(
      $scope,
      $routeParams,
      $localStorage,
      $location,
      $q,
      userService,
      workflowService,
      emailTemplateService,
      $timeout,
      $log,
      analyticsService,
      buildService
    ) {
      var queryParams = $location.search(),
        previousId = queryParams.previousId,
        nextId = queryParams.nextId,
        entityId = queryParams.entityId,
        workflowId = $routeParams.workflowId,
        ownerId;
      
      $scope.ctxRedirectTo = buildService.ctxRedirectBackTo;
      $scope.workflowId = $routeParams.workflowId;

      var init = function() {
        $scope.loading = true;
        $scope.creating = previousId && nextId;
        analyticsService.trackFeature('WFBuilderEmail' + $scope.creating ? 'Create' : 'Edit', 'loaded');

        $scope.variables = [{ name: '---variables---' }, { name: 'Assignee First Name', value: '{assigneeFirstName}' }];

        $q.all([userService.user(), buildService.ownerId()]).then(function(result) {
          ownerId = result[1];

          emailTemplateService.getTemplates(ownerId, 'workflow').then(
            function(templates) {
              if (templates.length > 0) {
                $scope.templates = templates;
                $scope.templates.splice(0, 0, { name: '---none selected---' });
                $scope.selectedTemplate = $scope.templates[0];
              }
            },
            function(reason) {
              $log.error(reason);
            }
          );
          if (!$scope.creating && $localStorage.workflowEmail === undefined) {
            retrieveEmailData();
          } else if ($localStorage.workflowEmail) {
            $scope.email = $localStorage.workflowEmail;
            $scope.showButton = $localStorage.workflowEmail.showButton;
            $scope.loading = false;
          } else {
            $scope.showButton = true;
            $scope.email = { buttonText: 'Get Started' };
            $scope.loading = false;
          }
        });
      };

      var retrieveEmailData = function() {
        workflowService.getWorkflowEntity(ownerId, workflowId, entityId).then(
          function(entity) {
            $scope.email = entity;
            $localStorage.workflowEmail = entity;
            $scope.showButton = !!$localStorage.workflowEmail.buttonText;
            $scope.loading = false;
          },
          function(reason) {
            $log.error(reason);
            $scope.loading = false;
          }
        );
      };

      $scope.goToPreview = function(isValid, email) {
        $scope.submitted = true;
        if (isValid) {
          $localStorage.workflowEmail = email;
          $localStorage.workflowEmail.showButton = $scope.showButton;
          buildService.ctxRedirectTo('/pathways/' + workflowId + '/email-preview', queryParams);
        }
      };

      $scope.$watch('selectedTemplate', function(template) {
        if (template && template.subject && template.body) {
          $scope.email.subject = template.subject;
          $scope.email.message = template.body;
        }
      });

      $scope.selectVariable = function(selectedVariable) {
        if (selectedVariable) {
          $scope.copied = true;
          $timeout(function() {
            $scope.copied = false;
          }, 2000);
        }
      };

      $scope.toggleShowButton = function() {
        $scope.showButton = !$scope.showButton;
      };

      init();
    },
  ]
);
