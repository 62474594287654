import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { react2angular } from 'react2angular';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Buttons, Button, PrimaryButton } from '../../components/Buttons';
import ErrorMessage from '../../components/ErrorMessage';
import {
  Header,
  Label,
  FormFieldContainer,
  FormFieldSet,
  FormGroupContainer,
  Radio,
  FormFieldMaxCharCountStatus,
} from '../../components/FormElements';
import { useParams } from 'react-router-dom';
import FileUploader from '../../components/FileUploader';
import { Container, Image } from '../../components/Layout';
import LoadingState from '../../components/LoadingState';
import Notifications from 'react-notify-toast';
import alertService from '../../services/AlertService';
import curriculumService from '../../services/curriculumService';
import windowService from '../../services/windowService';
import useCurriculum from '../../hooks/useCurriculum';
import { useUser } from '../../authentication';
import useOrg from '../../hooks/useOrg';

export default function AddVolumeHeaderMaterial() {
  const user = useUser();
  const org = useOrg();
  const { brand, ageCategory } = useCurriculum();
  const [data, setData] = useState({ isLoading: true, isError: false });
  const [form, setForm] = useState({ name: '', plan: 'basic-plan' });
  const [file, setFile] = useState();

  const { curriculumId, volumeId, headerId } = useParams();

  const [crumbs, setCrumbs] = useState([
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Curriculum', route: '#/manage-curriculum' },
  ]);

  const redirectBack = () => windowService.redirectTo(crumbs[crumbs.length - 2].route);

  useEffect(() => {
    Promise.all([
      curriculumService.getIndividualCurriculum(curriculumId),
      curriculumService.getVolume(volumeId),
      curriculumService.getVolumeHeader(volumeId, headerId),
    ])
      .then(values => {
        const curriculum = values[0];
        const volume = values[1];
        const header = values[2];

        setCrumbs([
          ...crumbs,
          {
            name: `${curriculum.name}: ${curriculum.brand}`,
            route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}`,
          },
          {
            name: volume.name,
            route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/volume/${volumeId}`,
          },
          {
            name: header.header,
            route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/volume/${volumeId}/header/${headerId}`,
          },
          { name: 'Add Material' },
        ]);
        setData({ ...data, isLoading: false, volume: volume, header: header });
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
        setData({ ...data, isLoading: false, isError: true });
      });
  }, []);

  const updateForm = values => setForm({ ...form, ...values });

  const submitForm = () =>
    curriculumService
      .addVolumeHeaderMaterial(
        volumeId,
        headerId,
        form.name.trim(),
        form.plan ? 'basic' : 'plus',
        { id: file.id, ext: file.extension },
        file.name,
        user.userId
      )
      .then(() => {
        //Give the api a second to update
        setTimeout(() => {
          alertService.showOnNextPage('Header Material Added');
          redirectBack();
        }, 1000);
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
      });

  return (
    <>
      <Notifications />
      <Breadcrumbs crumbs={crumbs} />
      {data.isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <Container>
          <Header>Add Material to Header</Header>
          <div style={{ margin: '32px 0' }}>
            <div className="grid-container grid-col-4 grid-sm-col-8 grid-gap-32">
              <div className="grid-col-span-4 grid-sm-col-span-2">
                <Label>{data.volume.name}</Label>
                <Image src={data.volume.imageUrl} />
              </div>
              <div className="grid-col-span-4 grid-sm-col-span-5 grid-md-col-span-4">
                <FormFieldSet name={`${data.header.header} Material`} />
                <Formik
                  initialValues={form}
                  validationSchema={Yup.object({
                    name: Yup.string().required('A name is required'),
                  })}
                  onSubmit={submitForm}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <FormFieldMaxCharCountStatus
                        label="Material Name"
                        name="name"
                        type="text"
                        placeholder="Enter Material Name"
                        maxLength={48}
                        onBlur={e => updateForm({ name: e.target.value })}
                      />
                      <FormFieldContainer>
                        <Label>Material Plan</Label>
                        <FormGroupContainer>
                          <p>This material will be associated with this plan.</p>
                          <div className="controls">
                            <Radio
                              data-qa-hook="deliverCertificateRadio"
                              id="basic-plan"
                              name="plan"
                              onChange={() => updateForm({ plan: true })}
                              checked={form.plan}
                            />
                            <label htmlFor="basic-plan">Basic</label>
                            <Radio
                              data-qa-hook="noCertificateRadio"
                              id="plus-plan"
                              name="plan"
                              onChange={() => updateForm({ plan: false })}
                              checked={!form.plan}
                            />
                            <label htmlFor="plus-plan">Plus</label>
                          </div>
                        </FormGroupContainer>
                      </FormFieldContainer>
                      <Label>Upload File</Label>
                      <FileUploader
                        userId={user.userId}
                        orgId={org.id}
                        onFileUploaded={setFile}
                        onFileRemoved={setFile}
                        uploadLocation="curriculum-storage/files"
                      />
                      <Buttons style={{ justifyContent: 'flex-end' }}>
                        <Button data-qa-hook="cancel" onClick={redirectBack}>
                          Cancel
                        </Button>
                        <PrimaryButton
                          data-qa-hook="submit"
                          type="submit"
                          disabled={!file || isSubmitting}
                          operating={isSubmitting}
                        >
                          Add Material
                        </PrimaryButton>
                      </Buttons>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

AddVolumeHeaderMaterial.propTypes = {
  user: PropTypes.object,
  org: PropTypes.object,
};

angular.module('lwNamb').component('addVolumeHeaderMaterial', react2angular(AddVolumeHeaderMaterial));
