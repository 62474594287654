export type FacetValue = {
  type: string;
  key: string;
  doc_count: number;
};

export type AggregateKey = 'categories' | 'ownerName' | 'searchAccess' | 'trainingType';
export type FacetKey = 'Categories' | 'Content' | 'Access' | 'Training Type';

export class Facet {
  constructor(
    public readonly key: FacetKey,
    public readonly rank: number,
    public readonly type: AggregateKey,
    public readonly values: FacetValue[]
  ) {}

  static fromAggregateKey(key: AggregateKey, facetValues: FacetValue[], hasSubscription: boolean = false): Facet {
    switch (key) {
      case 'categories':
        return new Facet('Categories', 4, key, facetValues);
      case 'ownerName':
        return new Facet('Content', 2, key, facetValues);
      case 'searchAccess':
        return new Facet('Access', hasSubscription ? 5 : 1, key, facetValues);
      case 'trainingType':
        return new Facet('Training Type', 3, key, facetValues);
    }
  }

  static getFacetValueDisplayText(facetValue: FacetValue): string {
    if (facetValue.key.toLowerCase() === 'tasklist') return 'Course';
    if (facetValue.key.toLowerCase() === 'workflow') return 'Pathway';
    return facetValue.key;
  }

  fromFacetValue(facetValue: FacetValue): Facet {
    return new Facet(this.key, this.rank, this.type, [facetValue]);
  }

  hasEqualFacetValue(facet: Facet): boolean {
    if (this.values.length !== 1 || facet.values.length !== 1) return false;
    return this.key === facet.key && this.values[0].key === facet.values[0].key;
  }
}
