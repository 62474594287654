import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Grid, Color, Breakpoints } from '../StyleGuide';
import { useUser } from '../authentication';
import Breadcrumbs from '../components/Breadcrumbs';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { LinkStyleButton, MediumOutlineButton } from '../components/Buttons';
import { Container, PageTitle } from '../components/Layout';
import assessmentService from '../services/assessmentService';
import AssessmentMultipleChoiceResults from './AssessmentMultipleChoiceResults';
import windowService from '../services/windowService';
import cacheService from '../services/cacheService';

const PageContainer = styled(Container)`
  margin: ${Grid._6} auto;
`;

const InfoContainer = styled.div`
  margin-top: ${Grid._3};

  span {
    margin-right: ${Grid._4};
  }
`;

const Question = styled.div`
  padding: ${Grid._6} 0;
  border-bottom: 1px solid ${Color.Gray._30};

  &:last-child {
    border-bottom: none;
  }

  h3 {
    margin: 0 0 ${Grid._4} 0;
  }
`;

const ShortAnswerQuestionContainer = styled(Question)`
  display: flex;
  flex-direction: column;
  grid-gap: ${Grid._4};

  @media screen and (min-width: ${Breakpoints.screen_xs}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &:hover {
    background: ${Color.white};
    cursor: pointer;

    h3 {
      text-decoration: underline;
    }
  }

  h3 {
    margin: 0;
  }
`;

const AnswerRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${Grid._5} 0;
  border-bottom: 1px solid ${Color.Gray._30};

  &:last-child {
    border-bottom: none;
  }
`;

const ShortAnswerQuestion = ({ question, questionNum }) => {
  const [showShortAnswer, setShowShortAnswer] = useState(false);
  const { answers, questionText } = question.questionHistory[0];

  return (
    <>
      <ShortAnswerQuestionContainer
        data-qa-hook="toggleAnswersButton"
        key={question.questionId.id}
        onClick={() => setShowShortAnswer(!showShortAnswer)}
      >
        <h3 data-qa-hook={questionText}>{`${questionNum}. ${questionText}`}</h3>
        <MediumOutlineButton>{showShortAnswer ? 'Hide' : 'Show'} Answers</MediumOutlineButton>
      </ShortAnswerQuestionContainer>
      {showShortAnswer &&
        answers.map(answer => (
          <AnswerRow key={answer.value}>{answer.value ? answer.value : <i>no answer</i>}</AnswerRow>
        ))}
    </>
  );
};

ShortAnswerQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  questionNum: PropTypes.number.isRequired,
};

const AssessmentResultsViewer = () => {
  const user = useUser();
  const { assessmentId, assignmentId, responseId } = useParams();
  const orgId = user.lastSelectedAccount;
  const [data, setData] = useState({ isLoading: true, isError: false, assessment: {} });
  const [results, setResults] = useState({});
  const { isLoading, isError, assessment } = data;
  const [crumbs, setCrumbs] = useState(
    responseId
      ? [
          { name: 'Organization', route: '#/org' },
          { name: 'Manage Assignments', route: '#/manage-assignments' },
          { name: cacheService.get('trainingName') || 'Assignment', route: `#/manage-assignments/${assignmentId}` },
        ]
      : [
          { name: 'Organization', route: '#/org' },
          { name: 'Assessment Results', route: '/#/assessment/results' },
          { name: 'Assessment Results Viewer' },
        ]
  );

  const filteredResponseIds = cacheService
    .get(`assessmentFilter-${assessmentId}`)
    ?.map(result => ({ id: result.assessmentResponseId.id }));

  const fetchAssessmentResultsData = responseIds =>
    assessmentService.getAssessmentResultsAggregate(responseIds).then(res => {
      setResults({ ...res, allQuestions: res.allQuestions.sort((a, b) => a.index - b.index) });
    });

  const getSelectedResults = aggregateResponseIds => {
    if (responseId) return [{ id: responseId }];

    return filteredResponseIds || aggregateResponseIds;
  };

  useEffect(() => {
    assessmentService
      .getAssessmentsAggregate(orgId)
      .then(response => {
        const currentAssessment = response.find(asmt => asmt.id === assessmentId);
        const selectedResponseIds = getSelectedResults(currentAssessment.responseIds);

        if (responseId) {
          setCrumbs(prev => [...prev, { name: currentAssessment.name }]);
        }

        return fetchAssessmentResultsData(selectedResponseIds).then(() => {
          setData(prev => ({ ...prev, isLoading: false, assessment: currentAssessment }));
        });
      })
      .catch(error => {
        console.error(error);
        setData({ isLoading: false, isError: true });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetFilterResponseIds = () => {
    setData(prev => ({ ...prev, isLoading: true }));
    cacheService.remove(`assessmentFilter-${assessmentId}`);

    fetchAssessmentResultsData(assessment.responseIds)
      .then(() => {
        setData(prev => ({ ...prev, isLoading: false }));
      })
      .catch(error => {
        console.error(error);
        setData(prev => ({ ...prev, isLoading: false, isError: true }));
      });
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <PageContainer>
        {isLoading ? (
          <LoadingState />
        ) : isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : (
          <>
            <PageTitle>
              <h1>{`${assessment.name} Results`}</h1>
            </PageTitle>
            {!responseId && (
              <InfoContainer>
                {filteredResponseIds ? (
                  <span>{filteredResponseIds.length === 1 ? '1 Result' : `${filteredResponseIds.length} Results`}</span>
                ) : (
                  <span>
                    {assessment.responseIds.length === 1 ? '1 Result' : `${assessment.responseIds.length} Results`}
                  </span>
                )}
                <LinkStyleButton
                  data-qa-hook="filterResultsButton"
                  onClick={() => windowService.redirectTo(`/#/assessment/results/${assessmentId}/filter`)}
                >
                  Filter Results
                </LinkStyleButton>
                {filteredResponseIds && (
                  <LinkStyleButton data-qa-hook="clearFilteredResultsButton" onClick={resetFilterResponseIds}>
                    Clear Filtered Results
                  </LinkStyleButton>
                )}
              </InfoContainer>
            )}
            {results.allQuestions.map((question, i) =>
              question.questionType === 'radio' ? (
                <Question key={question.questionId.id}>
                  <h3>{`${i + 1}. ${question.questionHistory[0].questionText}`}</h3>
                  <AssessmentMultipleChoiceResults
                    answers={question.questionHistory[0].answers}
                    hasCorrect={question.questionHistory[0].hasCorrect}
                  />
                </Question>
              ) : (
                <ShortAnswerQuestion key={question.questionId.id} question={question} questionNum={i + 1} />
              )
            )}
          </>
        )}
      </PageContainer>
    </>
  );
};

export default AssessmentResultsViewer;
