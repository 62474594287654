angular.module('lwNamb').controller(
  'SupportMaterialsViewerCtrl',

  [
    '$scope',
    '$routeParams',
    '$log',
    '$window',
    'windowService',
    'apiUrlService',
    'fileUrlService',
    'curriculumService',
    function($scope, $routeParams, $log, $window, windowService, apiUrlService, fileUrlService, curriculumService) {
      function init() {
        $scope.loading = true;
        $scope.series = [];
        $scope.issue = {};
        $scope.session = { name: 'Support Materials' };
        $scope.isSupportMaterials = true;

        if ($window.location.hash.indexOf('manage-support-materials-viewer') > -1) {
          $scope.isManageCurriculum = true;
        } else {
          $scope.isManageCurriculum = false;
        }

        loadSupportMaterials();
      }

      function loadSupportMaterials() {
        curriculumService
          .getIssueAndMaterials($routeParams.issueId, $routeParams.curriculumId)
          .then(
            function(response) {
              $scope.issue = response.issue;
              $scope.materials = response.materials;
              $scope.materials.map(function(material) {
                fileUrlService.getFileMetaData(material.mediaId).then(function(response) {
                  material.fileName = response.fileMetaData.fileName;
                });
              });
              $scope.series = response.series;
              sortAllMaterials();
            },
            function(reason) {
              $log.error(reason, ' error looking up support materials');
            }
          )
          .finally(function() {
            $scope.loading = false;
          });
      }

      function sortAllMaterials() {
        $scope.series.forEach(function(series) {
          series.materials = $scope.materials.filter(function(material) {
            return series.seriesId === material.seriesId;
          });
        });
      }

      $scope.downloadAll = function(materials, name) {
        var path = curriculumService.downloadAllUrl(materials, name);
        if (path !== '') {
          windowService.openUrl(apiUrlService.getUrl() + path);
        }
      };

      $scope.redirectToMaterial = function(material) {
        if (material.materialType === 'Download') {
          fileUrlService.openFile('', material.fileName, '/curriculum-storage/files/' + material.mediaId);
        } else {
          windowService.redirectHash(
            '/curriculum/' +
              $routeParams.curriculumId +
              '/issue/' +
              $routeParams.issueId +
              '/series/' +
              material.seriesId +
              ($scope.isManageCurriculum ? '/manage-material-viewer/' : '/material-viewer/') +
              material.materialId
          );
        }
      };

      $scope.redirectToPrevious = function() {
        if ($scope.isManageCurriculum) {
          windowService.redirectHash(
            '/curriculum/' + $routeParams.curriculumId + '/manage-issue-viewer/' + $routeParams.issueId
          );
        } else {
          windowService.redirectHash(
            '/curriculum/' + $routeParams.curriculumId + '/issue-viewer/' + $routeParams.issueId
          );
        }
      };

      init();
    },
  ]
);
