import React from 'react';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${Grid._5};
  span {
    background: ${Color.Gray._50};
    width: ${Grid._3};
    height: ${Grid._3};
    display: block;
    border-radius: 100%;
    margin: 0 ${Grid._1};
  }
  span:nth-child(${props => props.dot}) {
    background: ${Color.Orange._50};
  }
`;

function Dots({ step }) {
  return (
    <Div dot={step === 4 ? 3 : step}>
      <span />
      <span />
      <span />
    </Div>
  );
}

export default Dots;
