import styled from 'styled-components';
import PropTypes from 'prop-types';

const BibleStudyHeaderImg = styled.img`
  width: min(100%, 230px);
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  align-self: center;
  background: ${props => props.background || 'transparent'};
`;

BibleStudyHeaderImg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  background: PropTypes.string,
};

export default BibleStudyHeaderImg;
