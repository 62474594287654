import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useSteps, useCompleteStep as DefaultUseCompleteStep } from './StepsContext';
import { FullWidthButton } from '../components/Buttons';
import fileService from '../services/fileService';

// event types (aka actions)
const EVENT = {
  REQUEST_MADE: 'REQUEST_MADE',
  REQUEST_SUCCEEDED: 'REQUEST_SUCCEEDED',
  REQUEST_FAILED: 'REQUEST_FAILED',
  DOWNLOAD_CLICKED: 'DOWNLOAD_CLICKED',
  DOWNLOAD_OPENED: 'DOWNLOAD_OPENED',
  UNMOUNTED: 'UNMOUNTED',
};

// state types
const STATE = {
  INIT: 'INIT',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
};

const reducer = (state, event) => {
  switch (event.type) {
    case EVENT.REQUEST_MADE:
      return {
        ...state,
        type: STATE.LOADING,
      };
    case EVENT.REQUEST_SUCCEEDED:
      return {
        ...state,
        type: STATE.LOADED,
        fileMetaData: event.payload.fileMetaData,
      };
    case EVENT.REQUEST_FAILED:
      return {
        ...state,
        type: STATE.ERROR,
        // error: event.payload,
        isClicked: false,
        previouslyClicked: state.isClicked,
      };
    case EVENT.DOWNLOAD_CLICKED:
      return {
        ...state,
        type: state.type === STATE.ERROR ? STATE.INIT : state.type, // reset to retry data load
        isClicked: true,
      };
    case EVENT.DOWNLOAD_OPENED:
      return {
        ...state,
        isClicked: false,
      };
    case EVENT.UNMOUNTED:
      return {
        type: STATE.INIT,
        isClicked: false,
      };
    default:
      throw new Error();
  }
};

export default function DownloadStep({ useCompleteStep = DefaultUseCompleteStep }) {
  const { completeStep } = useCompleteStep();
  const [{ currentStep }] = useSteps();

  const [state, dispatch] = useReducer(reducer, {
    type: STATE.INIT,
    isClicked: false,
  });

  useEffect(() => {
    return () => {
      dispatch({ type: EVENT.UNMOUNTED });
    };
  }, [currentStep.task.assetUrl]);

  useEffect(() => {
    if (state.type === STATE.INIT) {
      dispatch({ type: EVENT.REQUEST_MADE });

      fileService
        .getMetaData(currentStep.task.assetUrl)
        .then(response => {
          dispatch({ type: EVENT.REQUEST_SUCCEEDED, payload: response });
        })
        .catch(error => {
          dispatch({ type: EVENT.REQUEST_FAILED, payload: error });
        });
    }
  }, [state, currentStep]);

  // since reducers don't handle effects, this is the somewhat clunky solution
  useEffect(() => {
    if (state.type === STATE.LOADED && state.isClicked) {
      download(state.fileMetaData);
      dispatch({ type: EVENT.DOWNLOAD_OPENED });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const download = fileMetaData => {
    window.open(fileService.urlOpenFile(fileMetaData._id.id, fileMetaData.fileName));
    completeStep();
  };

  const downloadButtonHandler = () => {
    const handleAction = () => {
      if (state.type === STATE.LOADED) {
        download(state.fileMetaData);
      } else {
        if (!state.isClicked) {
          dispatch({ type: EVENT.DOWNLOAD_CLICKED });
        }
      }
    };
    handleAction();
  };

  return (
    <>
      <p>
        <FullWidthButton data-qa-hook="downloadButton" onClick={downloadButtonHandler}>
          <i className="fas fa-download"></i> {state.isClicked && state.type === STATE.LOADING ? 'Loading' : 'Download'}
        </FullWidthButton>
      </p>
      {state.previouslyClicked && state.type === STATE.ERROR && (
        <p className="error">We're sorry, an error occurred. Please try again.</p>
      )}
    </>
  );
}

DownloadStep.propTypes = {
  useCompleteStep: PropTypes.func,
};
