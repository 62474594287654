import React from 'react';
import PropTypes from 'prop-types';
import VolumeCard from './VolumeCard';
import windowService from '../../services/windowService';
import Carousel from 'react-elastic-carousel';
import { carouselControls } from '../../components/CarouselElements';
import useCurriculum from '../../hooks/useCurriculum';

export default function VolumeCarousel({ volumes = [] }) {
  const { brand, ageCategory } = useCurriculum();
  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 577, itemsToShow: 2, itemsToScroll: 2 },
    { width: 767, itemsToShow: 3, itemsToScroll: 3 },
    { width: 992, itemsToShow: 4, itemsToScroll: 4 },
  ];

  const redirectToVolumeForm = (curriculumId, volumeId) =>
    windowService.redirectTo(`/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/volume/${volumeId}`);

  return (
    !!volumes.length && (
      <Carousel
        initialFirstItem={volumes.length - 1}
        breakPoints={breakPoints}
        renderArrow={carouselControls}
        pagination={false}
        itemPadding={[0, 16, 0, 16]}
      >
        {volumes.map(volume => (
          <VolumeCard
            volume={volume}
            onClick={() => redirectToVolumeForm(volume.curriculumId, volume._id)}
            key={volume._id}
          />
        ))}
      </Carousel>
    )
  );
}

VolumeCarousel.propTypes = {
  volumes: PropTypes.array,
};
