import React, { Dispatch, SetStateAction, useState } from 'react';
import { MediumButton, MediumPrimaryButton } from '../../../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../../../components/ModalElements';
import { Label } from '../../../components/FormElements';
import FileUploader from '../../../components/FileUploader';
import { useUser } from '../../../authentication';
import { getFileType } from '../../../utils/fileUtils';
import { UploadedFile } from '../../models/file';
import { saveVideo } from '../../api/saveVideo';
import { uploadContent } from '../../api/uploadContent';
import alertService from '../../../services/AlertService';
import { replaceContent } from '../../api/replaceContent';
import { Content } from '../../models/content';

type AddMediaModalProps = {
  action: 'add' | 'replace';
  onError?: (error: Error) => void;
  setData: Dispatch<SetStateAction<{ contents: Content[]; isError: boolean; isLoading: boolean }>>;
  handleDismiss: () => void;
  fileToReplace?: Content;
};

const AddMediaModal: React.FC<AddMediaModalProps> = ({ onError, setData, handleDismiss, action, fileToReplace }) => {
  const user = useUser();
  const userId = user?.userId || '';
  const orgId = user?.lastSelectedAccount || '';
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const determineUploadLocationByFile = (fileToUpload: File) => {
    switch (getFileType(fileToUpload.type)) {
      case 'audio':
      case 'video':
        return 'v1/storage/videos';
      default:
        return 'v1/storage/files';
    }
  };

  const handleOnFileUpload = (fileToUpload: UploadedFile) => {
    if (action === 'add') {
      switch (getFileType(fileToUpload.type)) {
        case 'audio':
        case 'video':
          return saveVideo(fileToUpload.id, fileToUpload.name, orgId, userId).then(res => {
            const videoContentItem = {
              id: res.id,
              title: res.title,
              contentType: 'Video',
              type: 'Video',
              name: res.title,
              lastModifiedDate: new Date().toISOString(),
              published: false,
            } as Content;
            alertService.show('Media Added');
            setData(prev => ({ ...prev, contents: [...prev.contents, videoContentItem] }));
            handleDismiss();
          });

        default:
          return uploadContent(fileToUpload.id, fileToUpload.name, orgId, userId).then(res => {
            const downloadContentItem = {
              id: res.id,
              contentType: 'Download',
              type: 'Download',
              name: fileToUpload.name,
              lastModifiedDate: new Date().toISOString(),
              published: true,
            } as Content;
            alertService.show('Media Added');
            setData(prev => ({ ...prev, contents: [...prev.contents, downloadContentItem] }));
            handleDismiss();
          });
      }
    }
    if (action === 'replace' && fileToReplace) {
      return replaceContent(fileToReplace.id, fileToUpload.name, orgId, userId).then(() => {
        const downloadContentItem = {
          id: fileToReplace.id,
          contentType: fileToUpload.type,
          type: fileToUpload.type,
          name: fileToUpload.name,
          lastModifiedDate: new Date().toISOString(),
          published: false,
        } as Content;
        alertService.show(fileToUpload.type === 'Video' ? 'Media Saved. Video Encoding In Progress.' : 'Media Saved');
        setData(prev => ({
          ...prev,
          contents: [...prev.contents.filter(item => item.id !== fileToReplace.id), downloadContentItem],
        }));
        handleDismiss();
      });
    }
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>{action === 'add' ? 'Add' : 'Replace'} Media</h3>
      </ModalHeader>
      <ModalBody>
        <Label>Upload Media</Label>
        <FileUploader
          userId={userId}
          onFileUploadStarted={() => setIsSubmitting(true)}
          onFileUploaded={handleOnFileUpload}
          onFileRemoved={() => {}}
          uploadLocation={determineUploadLocationByFile}
        />
      </ModalBody>

      <ModalFooter>
        <MediumButton onClick={handleDismiss} disabled={isSubmitting}>
          Cancel
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

export default AddMediaModal;
