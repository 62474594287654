import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../../StyleGuide';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/Buttons';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: ${Grid._4};
  background-color: ${Color.Blue._10};
  border-radius: ${Border.radius};
  margin-top: ${Grid._8};
  p {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${Type.Color.medium};
  }
  label {
    font-size: ${Type.Scale._1};
    font-weight: ${Type.Weight.semibold};
    color: ${Type.Color.dark};
    margin: 0;
  }
  .form-information {
    font-size: ${Type.Scale._1};
    font-weight: ${Type.Weight.normal};
    color: ${Type.Color.light};
  }
  textarea {
    font-size: ${Type.Scale._2};
    margin: ${Grid._2} 0 ${Grid._4};
    padding: ${Grid._3};
    border-radius: ${Border.radius};
    border: 1px solid ${Color.Secondary._20};
    resize: none;
    box-shadow: inset ${Shadow.light};
    transition: ${Transition.fast};
    outline: none;
    ::placeholder {
      color: ${Type.Color.placeholder};
    }
    &:focus {
      border-color: transparent;
      box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Blue._50},
        0px 0px 0px 5px rgba(172, 211, 239, 0.3);
    }
  }
  .form-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
      width: 100%;
      margin-bottom: ${Grid._4};
    }
    p i {
      margin-right: ${Grid._2};
    }
  }
`;

const NewCommentForm = ({ handleSubmission, orgName, isReply, replyToName }) => {
  const [commentText, setCommentText] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const type = isReply ? 'reply' : 'comment';
  const maxCharCount = 500;
  const showCharCountWarningThreshold = maxCharCount / 2;

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const submitForm = e => {
    setIsPosting(true);
    e.preventDefault();
    handleSubmission(commentText, isReply).finally(_ => {
      if (isMounted.current) {
        setIsPosting(false);
        setCommentText('');
      }
    });
  };

  const MaxCharCountStatus = ({ count }) => {
    return (
      <span data-qa-hook="maxCharacterWarning" className="warning">
        {maxCharCount - count} characters left
      </span>
    );
  };

  return (
    <Form onSubmit={submitForm}>
      <p>
        <label data-qa-hook="ReplyToLabel" htmlFor="new-comment">
          {isReply ? `Reply to ${replyToName}` : 'New Comment'}
        </label>
        {commentText && commentText.length > showCharCountWarningThreshold && (
          <MaxCharCountStatus count={commentText.length} />
        )}
      </p>
      <textarea
        data-qa-hook="commentReplyTextBox"
        id="new-comment"
        value={commentText}
        onChange={e => setCommentText(e.target.value)}
        placeholder={`Enter your ${type} here`}
        rows="4"
        maxLength="500"
        required
      />
      <div className="form-footer">
        <PrimaryButton data-qa-hook="postCommentReplyButton" type="submit" disabled={isPosting} operating={isPosting}>
          {isReply ? 'Reply' : 'Post'} <i className="fa fa-paper-plane" />
        </PrimaryButton>
        <p data-qa-hook="postCommentReplyOrgInfo" className="form-information">
          <i className="fas fa-info-circle"></i> This {type} will be shown only to people in {orgName}
        </p>
      </div>
    </Form>
  );
};

NewCommentForm.propTypes = {
  handleSubmission: PropTypes.func.isRequired,
  orgName: PropTypes.string.isRequired,
  isReply: PropTypes.string,
  replyToName: PropTypes.string,
};

export default NewCommentForm;
