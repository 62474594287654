import React from 'react';
import { react2angular } from 'react2angular';
import PropTypes from 'prop-types';
import { Grid } from '../StyleGuide';
import { Container } from '../components/Layout';
import BreadcrumbsSubMenu from '../components/BreadcrumbsSubMenu';
import MobileCrumb from '../components/MobileCrumb';

export default function Breadcrumbs({ crumbs }) {
  return (
    <>
      <div className="hidden-xs hidden-sm">
        <BreadcrumbsSubMenu crumbs={crumbs} />
      </div>
      <Container className="visible-xs-block visible-sm-block" style={{ margin: `${Grid._4} 0` }}>
        <MobileCrumb crumbs={crumbs} />
      </Container>
    </>
  );
}

Breadcrumbs.propTypes = {
  crumbs: PropTypes.array.isRequired,
};

angular.module('lwNamb').component('breadcrumbs', react2angular(Breadcrumbs));
