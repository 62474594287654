import { AxiosResponse } from 'axios';
import cacheableRequest from '../../services/cacheableRequest';
import { License, Person, addDisplayName } from '../models/license';
import { getLicenses } from './getLicenses';

export const getUsersNotAddedToLicense: (orgId: string, licenseId: string) => Promise<Person[]> = (orgId, licenseId) =>
  Promise.all([cacheableRequest(`/v1/organizations/${orgId}/users`), getLicenses(orgId, licenseId)]).then(
    ([{ data: organizationResponse }, userOnLicense]: [AxiosResponse<{ org: { users: Person[] } }>, License]) => {
      const usersInOrganization: Person[] = organizationResponse.org.users.filter(
        (item: Person) => item._type === 'UserAndRole'
      );

      const usersNotAddedToLicesnse = usersInOrganization.filter(
        (item: Person) =>
          !userOnLicense.users.map(item => item.email?.toLowerCase()).includes(item?.email?.toLowerCase() || '')
      );

      return addDisplayName(usersNotAddedToLicesnse);
    }
  );
