angular.module('lwNamb').directive('taskViewerText', [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'partials/directives/task-viewer-text.html',
      scope: {
        task: '=',
        taskStatus: '=?',
        isTaker: '=?',
        isPreview: '=?',
        colClass: '@',
      },
    };
  },
]);
