import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Color, Type } from '../StyleGuide';
import { DangerButton } from './Buttons';
import ConfirmationModal from './ConfirmationModal';

const ContentContainer = styled.div`
  margin: ${Grid._6} 0;
  padding: ${Grid._6} 0;
  border-top: 2px solid ${Color.Gray._20};
  p {
    margin: 0;
    color: ${Type.Color.dark};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export default function RemoveContentSection({
  type,
  message,
  buttonText,
  onRemove,
  useModal = true,
  modalSubtext,
  modalContent,
}) {
  const [showModal, setShowModal] = useState(false);

  const remove = () => onRemove()?.catch(() => setShowModal(false));

  return (
    <ContentContainer className="grid-container grid-col-2 grid-gap-24">
      <div className="grid-col-span-2 grid-sm-col-span-1">
        <p>
          {message ||
            `If this ${type.toLowerCase()} is no longer needed, you can remove it. This action is not reversible.`}
        </p>
      </div>
      <ButtonContainer className="grid-col-span-2 grid-sm-col-span-1">
        <DangerButton data-qa-hook="removeButton" onClick={() => (useModal ? setShowModal(true) : onRemove())}>
          {buttonText || `Remove ${type}`}
        </DangerButton>
      </ButtonContainer>
      {showModal && (
        <ConfirmationModal
          buttonActionText="Remove"
          buttonType="danger"
          title={`Remove ${type}`}
          prompt={<span>Are you sure you want to remove this {type.toLowerCase()}?</span>}
          subtext={modalSubtext}
          handleSubmit={remove}
          handleDismiss={() => setShowModal(false)}
        >
          {modalContent}
        </ConfirmationModal>
      )}
    </ContentContainer>
  );
}

RemoveContentSection.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  useModal: PropTypes.bool,
  modalSubtext: PropTypes.string,
  modalContent: PropTypes.object,
};
