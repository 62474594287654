import React, { useState } from 'react';
import { react2angular } from 'react2angular';
import PropTypes from 'prop-types';

// Components
import DialogContainer from '../components/DialogContainer';
import MGLogo from '../components/MGLogo';
import Dots from '../components/Dots';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Confirmation from './Confirmation';
import { Button } from '../components/Buttons';

export default function Wizard(props) {
  const [user, setUser] = useState({
    step: 1,
    type: '',
    emailAddress: props.loggedInEmail,
    churchName: '',
  });

  function updateState(key, val) {
    setUser(
      Object.assign({}, user, {
        [key]: val,
      })
    );
  }

  function setUserType(event) {
    updateState('type', event.target.value);
  }

  function setEmailAddress(event) {
    updateState('emailAddress', event.target.value);
  }

  function setChurchName(event) {
    updateState('churchName', event.target.value);
  }

  function handleNextStep(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    updateState('step', user.step + 1);
  }

  function handleStartOver() {
    setUser({
      step: 1,
      type: '',
      emailAddress: props.loggedInEmail,
      churchName: '',
    });
  }

  const { step } = user;

  return (
    <React.Fragment>
      <DialogContainer>
        <Dots step={step} />
        <MGLogo />
        <form>
          {step === 1 && <Step1 handleNextStep={handleNextStep} handleOnChange={setUserType} userType={user.type} />}
          {step === 2 && (
            <Step2
              handleEmailSubmission={props.handleEmailSubmission}
              handleNextStep={handleNextStep}
              isAuthenticating={props.isAuthenticating}
              setEmailAddress={setEmailAddress}
              user={user}
            />
          )}
          {step === 3 && (
            <Step3
              handleEmailSubmission={props.handleEmailSubmission}
              handleLifeWayRedirection={props.handleLifeWayRedirection}
              handleNextStep={handleNextStep}
              setChurchName={setChurchName}
              user={user}
            />
          )}
          {step === 4 && <Confirmation handleChurchSetupRequest={props.handleChurchSetupRequest} user={user} />}
        </form>
        {step !== 1 && step !== 4 && (
          <Button data-qa-hook="startOverButton" style={{ marginTop: '16px' }} onClick={handleStartOver}>
            Start Over
          </Button>
        )}
      </DialogContainer>
    </React.Fragment>
  );
}

Wizard.propTypes = {
  handleEmailSubmission: PropTypes.func.isRequired,
  handleChurchSetupRequest: PropTypes.func.isRequired,
  handleLifeWayRedirection: PropTypes.func.isRequired,
  isAuthenticating: PropTypes.bool,
  loggedInEmail: PropTypes.string,
};

angular.module('lwNamb').component('wizard', react2angular(Wizard));
