import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '../authentication';

// Global Components
import TrainingWrapper from '../components/TrainingWrapper';
import { TrainingDetailsWrapper, TrainingDetailsHeader } from '../components/TrainingDetailsElements';
import Labels from '../components/Labels';
import WorkflowCourseIcon from '../components/WorkflowCourseIcon';
import TextTruncate from '../containers/TextTruncate';

import WorkflowCourseIconProgressLine from '../components/WorkflowCourseIconProgressLine';
import ProfileImage from '../components/ProfileImage';
import WhiteLabel from '../filters/WhiteLabel';
import { Button } from '../components/Buttons';
import Reports from './Reports';
import windowService from '../services/windowService';

import Course from './Course';

// Main Component
export default function WorkflowCard({ assignment, assignmentLabel, firstName, sendReminder, updateAssignment }) {
  const user = useUser();
  const workflow = assignment;
  const workflowProgress = workflow.assignable.progress._type;
  const assignedOn =
    (workflow.assignable.assignedOn || 0) && new Date(workflow.assignable.assignedOn).toLocaleDateString();
  const profileImageUrl = workflow.assignee.profileImageUrl;
  const userIsAssignee = workflow.assignee.userId === user.userId;
  const workflowName = workflow.assignable.name;

  const completedTasklistsWithReports = workflow.assignable.tasklists.filter(
    tl => tl?.progress?.percent == 100 && tl.reports?.length > 0
  );

  const reportsTasklistInstanceId = completedTasklistsWithReports[0]?.taskListInstanceId.id;

  const completeReports = completedTasklistsWithReports
    .flatMap(tl => tl?.reports || [])
    .filter(report => !!report?.reportId);

  const updateCourse = courseId => updateFn =>
    updateAssignment(previousAssignment => ({
      ...previousAssignment,
      assignable: {
        ...previousAssignment.assignable,
        tasklists: previousAssignment.assignable.tasklists.map(tasklist => {
          if (tasklist.taskListInstanceId.id === courseId) {
            return updateFn(tasklist);
          }
          return tasklist;
        }),
      },
    }));
  return (
    <TrainingWrapper>
      <TrainingDetailsHeader>
        <div>
          <ProfileImage size="100px" url={profileImageUrl || 'img/default_profile_photo.png'} />
        </div>
        <div>
          <p>
            <i className="fas fa-chevron-left" style={{ marginRight: '5px' }} />
            {workflow.assignee.name ? (
              <a
                data-qa-hook="assignmentDetailBackToManageAssignments"
                data-qa-hook2="assignmentDetailFullName"
                onClick={() => windowService.redirectTo('/manage-assignments')}
                className="link text-capitalize"
              >
                {workflow.assignee.name}
              </a>
            ) : (
              <a
                data-qa-hook="assignedTaskListBackToMyTasks"
                onClick={() => windowService.redirectTo('/manage-assignments')}
                className="link text-capitalize"
              >
                {firstName ? `${firstName}\u2019s` : 'Your'} Training
              </a>
            )}
          </p>
          <p className="visible-sm-block visible-xs-block">Pathway</p>
          <h1 data-qa-hook="assignedTaskListName">{workflow.assignable.name}</h1>
          {workflow.assignable.selfAssigned && (
            <Labels>
              <li data-qa-hook="assignmentDetailFullName" data-qa-hook2="assignmentDetailTrainingAssignedBy">
                Self-assigned {assignedOn ? `on ${assignedOn}` : ''}
              </li>
            </Labels>
          )}
          {!workflow.assignable.selfAssigned && workflow.assignor.name && (
            <Labels>
              <li data-qa-hook="assignmentDetailFullName" data-qa-hook2="assignmentDetailTrainingAssignedBy">
                Assigned by {workflow.assignor.name} {assignedOn ? `on ${assignedOn}` : ''}
              </li>
            </Labels>
          )}
          {!userIsAssignee && workflow.assignable.progress._type !== 'Finished' && (
            <Button
              onClick={() =>
                sendReminder(
                  workflowName,
                  workflow.assignee.userId,
                  user.firstName,
                  WhiteLabel.name,
                  user.lastSelectedAccount
                )
              }
            >
              Send Reminder
            </Button>
          )}
          {workflow.workflowDescription && <TextTruncate text={workflow.workflowDescription} />}
        </div>
        <div />
      </TrainingDetailsHeader>

      {workflow.assignable.tasklists.map((course, index) => (
        <TrainingDetailsWrapper key={course.taskListInstanceId.id}>
          <div>
            <WorkflowCourseIcon
              className={
                course.progress._type === 'Finished' ? 'background-color-complete' : 'background-color-incomplete'
              }
              isComplete={course.progress._type === 'Finished' ? true : false}
              number={index + 1}
            />
            <WorkflowCourseIconProgressLine
              className={
                workflow.assignable.tasklists[index + 1] &&
                workflow.assignable.tasklists[index + 1].progress._type === 'Finished'
                  ? 'background-color-complete'
                  : 'background-color-incomplete'
              }
              isComplete={
                workflow.assignable.tasklists[index + 1] &&
                workflow.assignable.tasklists[index + 1].progress._type === 'Finished'
              }
              isLast={workflow.assignable.tasklists[index + 1] ? false : true}
            />
          </div>
          <div>
            <Labels>
              {course._type && course._type === 'Course' && <li>Upcoming {assignmentLabel}</li>}
              <li>
                Course {index + 1} of {workflow.assignable.tasklists.length}
              </li>
            </Labels>
            <Course
              updateCourse={updateCourse(course.taskListInstanceId.id)}
              course={course}
              label="step"
              isCollapsed={
                workflowProgress !== 'Finished' && (course.progress._type === 'Finished' || course._type === 'Course')
              }
              showStart={
                !course.purchaseRequired &&
                userIsAssignee &&
                course.progress._type === 'NotStarted' &&
                course._type === 'AssignedTaskList'
              }
              showResume={
                !course.purchaseRequired &&
                userIsAssignee &&
                course.progress._type == 'Started' &&
                course._type === 'AssignedTaskList'
              }
              purchaseRequired={course.purchaseRequired}
              assigneeId={workflow.assignee.userId}
              isComplete={course.progress._type === 'Finished'}
              hasCertificate={course.certificateInfo}
              instanceId={course.taskListInstanceId.id}
              isWorkFlow={true}
            />
          </div>
        </TrainingDetailsWrapper>
      ))}

      {!!completeReports?.length && (
        <Reports
          reports={completeReports}
          tasklistName={workflowName}
          assigneeName={workflow.assignee?.name}
          tasklistInstanceId={reportsTasklistInstanceId}
        />
      )}
    </TrainingWrapper>
  );
}

WorkflowCard.propTypes = {
  assignment: PropTypes.object.isRequired,
  assignmentLabel: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  sendReminder: PropTypes.func,
  updateAssignment: PropTypes.func,
};
