import React, { useEffect, useReducer, useRef } from 'react';
import { useParams } from 'react-router-dom';
import licenseService from '../services/licenseService';
import orgService from '../services/orgService';
import windowService from '../services/windowService';
import purchaseService from '../services/purchaseService';
import cacheService from '../services/cacheService';
import { pollTimes } from '../utils/apiUtils';
import { debounce } from '../utils/funcUtils';

const LICENSE_CHECK_ATTEMPTS = 5;
const SINGLE_ORG_CHECK_DELAY = 5000;

const initialState = {
  licenses: [],
  pairableOrgs: [],
  isChecking: true,
  isLoading: true,
  isError: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'pairableOrgsFound':
      return {
        ...state,
        pairableOrgs: action.payload,
        isLoading: false,
      };

    case 'licensesFound':
      return {
        ...state,
        licenses: action.payload,
      };

    case 'checksComplete':
      return {
        ...state,
        isChecking: false,
      };

    case 'error':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
  }
};

const useFetchPairableLicenses = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { checkout } = useParams();
  const fromCheckout = !!checkout;

  // This will clear the polling timeout and stop all processing of
  // active promises responses.
  const stopRequestProcess = useRef(() => {});

  const onError = error => {
    console.error(error);
    stopRequestProcess.current();
    dispatch({ type: 'error' });
  };

  const onRequestSuccess = fetchedLicenses => {
    const pairableLicenses = fetchedLicenses.length > 0 ? fetchedLicenses : state.licenses;

    if (pairableLicenses.length !== state.licenses.length) {
      dispatch({ type: 'licensesFound', payload: pairableLicenses });
    }
  };

  const onFetchingComplete = () => {
    if (state.licenses.length === 0) {
      const individualPurchaseRedirect = purchaseService.getRedirectLocation();

      if (individualPurchaseRedirect) {
        purchaseService.clearRedirectLocation();
        return windowService.redirectTo(individualPurchaseRedirect);
      }
    }

    purchaseService.clearRedirectLocation();
    dispatch({ type: 'checksComplete' });
  };

  const fetchUniquelyPairableLicenses = () =>
    licenseService
      .getAllUnpairedLicenses(true)
      .then(([groupLicenses]) =>
        groupLicenses?.length ? licenseService.filterUniqueSubscriptions(groupLicenses) : []
      );

  // This ensures that each poll request is dealing with new promise rather
  // than calling the same promise over again which would not respond with
  // new data.
  const createPollableLicensePromise = fetchLicenseFn => () => fetchLicenseFn().then(onRequestSuccess).catch(onError);

  // We have to provide pairableOrgs to this function because of the way React
  // handles state updates. See this for more details: https://react.dev/reference/react/useReducer#ive-dispatched-an-action-but-logging-gives-me-the-old-state-value
  const createSingleLicensePromise = (fetchLicenseFn, pairableOrgs) => () =>
    fetchLicenseFn()
      .then(fetchedLicenses => {
        onRequestSuccess(fetchedLicenses);

        if (fetchedLicenses?.length && pairableOrgs?.length === 1) {
          return windowService.redirectTo('/pairing/purchases');
        }

        onFetchingComplete();
      })
      .catch(onError);

  useEffect(() => {
    cacheService.remove('pairingLicenses');

    orgService
      .getLicensePairingOrgs()
      .then(orgs => {
        if (!Array.isArray(orgs)) return Promise.reject('Invalid response');

        dispatch({ type: 'pairableOrgsFound', payload: orgs });

        stopRequestProcess.current =
          orgs.length === 1 // we know we're going to auto-pair
            ? debounce(
                createSingleLicensePromise(fetchUniquelyPairableLicenses, orgs),
                fromCheckout ? SINGLE_ORG_CHECK_DELAY : 0
              )
            : pollTimes(
                createPollableLicensePromise(fetchUniquelyPairableLicenses),
                onFetchingComplete,
                fromCheckout ? LICENSE_CHECK_ATTEMPTS : 1
              );
      })
      .catch(onError);

    return () => stopRequestProcess.current();
  }, []);

  return state;
};

export default useFetchPairableLicenses;
