angular.module('lwNamb').factory(
  'licenseService',

  [
    '$rootScope',
    'api',
    'qt',
    'transformer',
    'CacheFactory',
    'commandSubmissionService',
    'apiUrlService',
    '$q',
    '$timeout',
    function($rootScope, api, qt, transformer, CacheFactory, commandSubmissionService, apiUrlService, $q, $timeout) {
      var timeoutSeconds = 15,
        unpairedCache = CacheFactory('unpairedLicensesCache', {
          maxAge: 10 * 60 * 1000, // 10 minutes
          deleteOnExpire: 'aggressive',
        }),
        unpairedPromiseCache = CacheFactory('unpairedLicensesPromiseCache', {
          maxAge: 5000, // 5 seconds
          deleteOnExpire: 'aggressive',
        }),
        pairedPromiseCache = CacheFactory('pairedLicensesPromiseCache', {
          maxAge: 5000, // 5 seconds
          deleteOnExpire: 'aggressive',
        }),
        pairedCache = CacheFactory('pairedLicensesCache', {
          maxAge: 10 * 60 * 1000, // 10 minutes
          deleteOnExpire: 'aggressive',
        }),
        licenseCache = CacheFactory('licenseCache', {
          maxAge: 10 * 60 * 1000, // 10 minutes
          deleteOnExpire: 'aggressive',
        });

      function dropCaches() {
        pairedCache.removeAll();
        unpairedCache.removeAll();
        licenseCache.removeAll();
      }

      $rootScope.dropPairingCaches = dropCaches; // For React pairing cleanup

      // todo: stop getting paired license detail every 10 mins - see getPairedLicenses note
      // todo: stop getting indv licenses every 10 mins

      return {
        getIndividualLicenses: function(userId) {
          return getByType('individual', userId, isIndividualLicense, "TMG");
        },
        getAllUnpairedGroupLicenses: function() {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
          $q.all([getUnpairedGroupLicenses('TMG'), getUnpairedGroupLicenses('CLC')]).then(
            function(results) {
              deferred.resolve(results[0].concat(results[1]));
            },
            function(reason) {
              deferred.reject(reason);
            }
          );
          return deferred.promise;
        },
        getPairedLicenses: function(orgId, bustCache) { // todo: exclude detail if not needed, or add includeDetail boolean param (requires api impl) and use only when needed
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          if (bustCache !== true && pairedCache.get(orgId) !== undefined) {
            deferred.resolve(pairedCache.get(orgId));
            return deferred.promise;
          } else if (pairedPromiseCache.get(orgId) === undefined) {
            pairedPromiseCache.put(orgId, deferred.promise);
            api.get('/v1/licenses?detail=true&orgId=' + orgId).then(
              function(response) {
                var licenses = transformer.transformLicenses(response.data.licenses);
                pairedCache.put(orgId, licenses);
                deferred.resolve(licenses.slice());
              },
              function(reason) {
                deferred.reject(reason);
              }
            );
            return deferred.promise;
          } else {
            return pairedPromiseCache.get(orgId);
          }
        },
        getLicense: function(licenseId) {
          return get(licenseId);
        },
        getLicensePricing: function(licenseId, userId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          api.get('/v1/pricing').then(
            function(response) {
              deferred.resolve(response.data.group);
            },
            function(reason) {
              deferred.reject(reason);
            }
          );

          return deferred.promise;
        },
        pairLicenses: function(licenses, orgId, managerId, initiatingUserId) {
          dropCaches();
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
          var licensePromises = [];
          licenses.forEach(function(license) {
            var cmd = { id: license.id, accountId: orgId, managerId: managerId, initiatingUserId: initiatingUserId };
            licensePromises.push(
              commandSubmissionService.submitAndWait(license.id, cmd, 'PairLicense', 'LicensePaired', 'LicenseError')
            );
          });
          $q.all(licensePromises).then(
            function() {
              deferred.resolve();
            },
            function(error) {
              deferred.reject(error);
            }
          );
          return deferred.promise;
        },
        grantAccess: function(licenseId, users, orgId, initiatingUserId) {
          var userIds = getUserIdsFromAllOrgMembers(users);
          var cmd = { id: licenseId, userIds: userIds.filter(function(userId){return userId.id.indexOf('@') === -1;}), managerId: orgId, initiatingUserId: initiatingUserId };
          var deferred = qt.defer({ timeoutSeconds: 120 });

          commandSubmissionService
            .submitAndWait(licenseId, cmd, 'AllocateSeats', 'LicenseResult', 'LicenseError')
            .then(function(response) {
              get(licenseId).then(function(license) {
                for (var i = 0; i < response.results.length; i++) {
                  for (var j = 0; j < users.length; j++) {
                    if (
                      users[j]._id.id === response.results[i].userId.id &&
                      response.results[i].status.status === 'ADDED'
                    ) {
                      var personSeatIndex = -1;
                      for (var k = 0; k < license.seats.occupants.length; k++) {
                        if (license.seats.occupants[k].id === users[j]._id.id) {
                          personSeatIndex = k;
                          break;
                        }
                      }
                      if (personSeatIndex === -1) {
                        license.seats.count.occupied += 1;
                        if (!isNaN(license.seats.count.empty)) {
                          license.seats.count.empty -= 1;
                        }
                        license.seats.occupants.push({
                          id: users[j]._id.id,
                          firstName: users[j].firstName,
                          lastName: users[j].lastName,
                          email: users[j].email,
                          profileImageUrl:
                            apiUrlService.getUrl() + '/v1/images/profile/' + users[j]._id.id + 'profile.png',
                        });
                      }
                      break;
                    }
                  }
                }
                licenseCache.put(licenseId, license);
                deferred.resolve(response);
              });
            });

          return deferred.promise;
        },
        revokeAccess: function(licenseId, users, orgId, initiatingUserId) {
          var userIds = getUserIdsFromAllOrgMembers(users);

          var deferred = qt.defer({ timeoutSeconds: 120 });

          var cmd = { id: licenseId, userIds: userIds, managerId: orgId, initiatingUserId: initiatingUserId };

          commandSubmissionService
            .submitAndWait(licenseId, cmd, 'DeallocateSeats', 'LicenseResult', 'LicenseError')
            .then(function(response) {
              get(licenseId).then(function(license) {
                for (var i = 0; i < response.results.length; i++) {
                  for (var j = 0; j < license.seats.occupants.length; j++) {
                    if (
                      license.seats.occupants[j].id === response.results[i].userId.id &&
                      response.results[i].status.status === 'REMOVED'
                    ) {
                      license.seats.count.occupied -= 1;
                      if (!isNaN(license.seats.count.empty)) {
                        license.seats.count.empty += 1;
                      }
                      license.seats.occupants.splice(j, 1);
                      break;
                    }
                  }
                }
                licenseCache.put(licenseId, license);
                deferred.resolve(response);
              });
            });

          return deferred.promise;
        },
        upgradeToUnlimited: function(licenseId, subId, oldItemNumber, newItemNumber, userId) {
          dropCaches();
          var deferred = qt.defer({ timeoutSeconds: 120 });
          api.post('/v1/changeSubscriptionItem', {
            id: subId,
            oldItemSku: oldItemNumber,
            newItemSku: newItemNumber,
            userId: userId,
          });

          $rootScope.$on('SeatCountSetUnlimited', function(name, event) {
            if (event.id === licenseId) {
              deferred.resolve();
            }
          });

          return deferred.promise;
        },
        updateSeats: function(licenseId, subId, itemNumber, newQuantity, userId, managerId, initiatingUserId) {
          dropCaches();
          var cmd = {
            id: licenseId,
            subscriptionId: subId,
            itemSku: itemNumber,
            newQuantity: newQuantity,
            userId: { id: userId },
            managerId: managerId,
            initiatingUserId: initiatingUserId,
          };
          return commandSubmissionService.submitAndWait(
            licenseId,
            cmd,
            'ChangeSeatQuantity',
            ['SeatQuantityChanged', 'SeatQuantityChangeRequested'],
            'SeatQuantityChangedError'
          );
        },
        resumeSubscription: function(subId, initiatingUserId) {
          dropCaches();
          var cmd = { id: subId, initiatingUserId: initiatingUserId };
          return commandSubmissionService.submitAndWait(
            subId,
            cmd,
            'ResumeSubscription',
            'SubscriptionResumed',
            'ResumeSubscriptionError'
          );
        },
        resumeLapsedSubscription: function(subId, seatCount, itemSku, userId, initiatingUserId) {
          dropCaches();
          var cmd = {
            id: subId,
            seats: seatCount,
            itemSku: itemSku,
            userId: { id: userId },
            initiatingUserId: initiatingUserId,
          };
          return commandSubmissionService.submitAndWait(
            subId,
            cmd,
            'ResumeLapsedSubscription',
            'SubscriptionResumed',
            'ResumeSubscriptionError'
          );
        },
        cancelSubscription: function(subId, initiatingUserId) {
          dropCaches();
          var cmd = { id: subId, initiatingUserId: initiatingUserId };
          return commandSubmissionService.submitAndWait(
            subId,
            cmd,
            'CancelSubscription',
            'SubscriptionCancelled',
            'CancelSubscriptionError'
          );
        },
        getSubscription: function(subId) {
          return api.get('/v1/subscription/' + subId);
        },
        getDefaultProductNumbers: function() {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
          api.get('/v1/products/defaultProductNumbers').then(function(response){
            deferred.resolve(response.data);
          }, function(response){
            if(response.status === 404){
              deferred.resolve({});
            } else {
              deferred.reject(response.data);
            }
          });

          return deferred.promise;
        },
        getPairingOrgs: function() {
          return api.get('/v1/license-pairing-orgs').then(function(response){
            return response.data;
          });
        }
      };

      function getUnpairedGroupLicenses(channel) {
        return api.get('/v1/licenses?unpaired=true&itemChannel=' + channel).then(
          function(response) {
            return transformer.transformLicenses(response.data.licenses).filter(isGroupLicense);
          }
        );
      }

      function isGroupLicense(license) {
        return license.seats === undefined || license.seats.count === undefined || license.seats.count.total !== 1;
      }

      function isIndividualLicense(license) {
        return license.seats !== undefined && license.seats.count !== undefined && license.seats.count.total === 1;
      }

      // todo: simplify and move inside getIndividualLicenses method
      function getByType(type, userId, filter, channel) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        function f(l) {
          var filtered = l.filter(filter);
          unpairedCache.put(userId + type, filtered);
          deferred.resolve(filtered);
        }

        var unpairedTypedLicenses = unpairedCache.get(userId + type);
        if (unpairedTypedLicenses) {
          deferred.resolve(unpairedTypedLicenses);
          return deferred.promise;
        } else {
          var cachedPromise = unpairedPromiseCache.get(userId+channel);
          if (cachedPromise === undefined) {
            var deferred2 = qt.defer({ timeoutSeconds: timeoutSeconds });
            unpairedPromiseCache.put(userId+channel, deferred2.promise);
            api.get('/v1/licenses?unpaired=true&itemChannel='+channel).then(
              function(response) {
                var licenses = transformer.transformLicenses(response.data.licenses);
                deferred2.resolve(licenses);
                f(licenses);
              },
              function(reason) {
                deferred.reject(reason);
              }
            );
            return deferred.promise;
          } else {
            cachedPromise.then(
              function(licenses) {
                f(licenses);
              },
              function(reason) {
                deferred.reject(reason);
              }
            );
            return deferred.promise;
          }
        }
      }

      function get(licenseId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        if (licenseCache.get(licenseId) !== undefined) {
          deferred.resolve(licenseCache.get(licenseId));
        } else {
          api.get('/v1/licenses/' + licenseId).then(
            function(response) {
              var license = transformer.transformLicenses([response.data.license])[0];
              licenseCache.put(licenseId, license);
              deferred.resolve(license);
            },
            function(reason) {
              deferred.reject(reason);
            }
          );
        }

        return deferred.promise;
      }

      function getUserIdsFromAllOrgMembers(allOrgMembers) {
        return allOrgMembers.map(function(item) {
          if (item._id && item._id.id) return item._id;
          else return { id: item._id };
        });
      }
    },
  ]
);
