import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Type, Color } from '../../StyleGuide';
import {
  SectionMediaItem,
  AddSectionMedia,
  SectionMediaPlaceholder,
} from '../../components/SessionTimelineContentElements';
import { Image } from '../../components/Layout';
import { ImageButtonGroup, MediumOutlineButton } from '../../components/Buttons';
import { RoundedBadge } from '../../components/Badge';
import LoadingIndicator from '../../components/LoadingIndicator';
import videoService from '../../services/VideoService';
import { isAudioExtension } from '../../utils/fileUtils';
import { formatMilliseconds } from '../../utils/dateUtils';

const MediaTypes = Object.freeze({
  IMAGE: 'image',
  VIDEO: 'video',
  AUDIO: 'audio',
});

export const DurationBadge = styled(RoundedBadge)`
  background: ${Color.Gray._30};
  position: absolute;
  top: ${Grid._3};
  left: ${Grid._3};
`;

const getThumbnailUrl = media => media?.url?.thumbnail;

const SectionMediaThumbnail = ({ media, viewOnly, title, onAddMedia, buttons, onClick }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState(getThumbnailUrl(media));
  const { id, _type, ext } = media ?? {};
  const hasMedia = !!id;
  const mediaType = _type === MediaTypes.VIDEO && isAudioExtension(ext) ? MediaTypes.AUDIO : _type;

  useEffect(() => {
    if (hasMedia && !thumbnailUrl && mediaType === MediaTypes.VIDEO) {
      videoService
        .getPoster(id)
        .then(poster => {
          if (!poster) return Promise.reject('Empty poster response. Possibly still encoding.');

          setThumbnailUrl(poster);
        })
        .catch(console.error);
    }
  }, []);

  useEffect(() => {
    const thumbnail = getThumbnailUrl(media);
    if (thumbnail !== thumbnailUrl) setThumbnailUrl(thumbnail);
  }, [media?.url?.thumbnail]);

  if (mediaType === MediaTypes.AUDIO)
    return (
      <SectionMediaItem onClick={onClick} data-tracking-id={media.name || id}>
        <SectionMediaPlaceholder>
          {!!media.duration && <DurationBadge>{formatMilliseconds(media.duration)}</DurationBadge>}
          <i className="fas fa-music" style={{ fontSize: Type.Scale._7 }}></i>
          <ImageButtonGroup>{buttons}</ImageButtonGroup>
        </SectionMediaPlaceholder>
      </SectionMediaItem>
    );

  if (hasMedia && thumbnailUrl)
    return (
      <SectionMediaItem onClick={onClick} data-tracking-id={media.name || id}>
        {!!media.duration && <DurationBadge>{formatMilliseconds(media.duration)}</DurationBadge>}
        <Image src={thumbnailUrl} alt={title} width="100%" />
        <ImageButtonGroup>{buttons}</ImageButtonGroup>
      </SectionMediaItem>
    );

  if (hasMedia)
    return (
      <SectionMediaItem>
        <AddSectionMedia>
          <LoadingIndicator />
        </AddSectionMedia>
      </SectionMediaItem>
    );

  if (!viewOnly)
    return (
      <SectionMediaItem>
        <AddSectionMedia>
          <MediumOutlineButton data-qa-hook="addSectionMedia" onClick={onAddMedia}>
            Add Media
          </MediumOutlineButton>
        </AddSectionMedia>
      </SectionMediaItem>
    );

  return null;
};

SectionMediaThumbnail.defaultProps = {
  viewOnly: false,
  title: '',
};

SectionMediaThumbnail.propTypes = {
  media: PropTypes.object,
  viewOnly: PropTypes.bool,
  title: PropTypes.string,
  onAddMedia: PropTypes.func,
  buttons: PropTypes.object,
  onClick: PropTypes.func,
};

export default SectionMediaThumbnail;
