angular.module('lwNamb').filter('questionLabel', function() {
  var labels = {
    radio: 'Multiple Choice',
    sort: 'Sort',
    textbox: 'Short Answer',
  };

  return function(questionType) {
    return labels[questionType] || 'Question';
  };
});
