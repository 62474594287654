angular.module('lwNamb').factory('redeemService', [
  function() {
    return {
      parseCode: function(code) {
        if (code) {
          if (code.length == 8) {
            return [code.slice(0, 4).toUpperCase(), code.slice(-4).toUpperCase()];
          } else if (code.length == 9 && (code.split('-').length === 2 || code.split(' ').length === 2)) {
            return [code.slice(0, 4).toUpperCase(), code.slice(-4).toUpperCase()];
          }
        }
        return ['', ''];
      },
    };
  },
]);
