angular.module('lwNamb').factory(
  'groupService',

  [
    '$rootScope',
    'api',
    'qt',
    'uuid4',
    'CacheFactory',
    function($rootScope, api, qt, uuid4, CacheFactory) {
      var timeoutSeconds = 15,
        cache = CacheFactory('group', {
          maxAge: 60 * 1000, // 1 minute
          deleteOnExpire: 'aggressive',
        });

      $rootScope.$on('ClearGroupCache', function() {
        cache.removeAll();
      });

      return {
        getGroup: function(groupId, orgId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          if (cache.get(groupId)) {
            deferred.resolve(cache.get(groupId));
          } else {
            getAndCacheGroup(groupId, orgId).then(function(group) {
              deferred.resolve(group);
            });
          }

          return deferred.promise;
        },
        addMember: function(groupId, orgId, userId, members, groupName) {
          var gettingGroup = false,
            deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
            receivedEvent = false;

          cache.remove(groupId);

          var userIds = [];
          members.forEach(function(i) {
            userIds.push({
              id: i._id,
              position: 'Member',
              roleIds: [],
            });
          });

          api
            .post('/v1/commands/AddToGroup', {
              id: uuid4.generate(),
              groupName: groupName,
              orgId: { id: orgId },
              initiatingUserId: userId,
              groupId: { id: groupId },
              assigneeIds: members.map(function(m) {
                return m._id.id;
              }),
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('AddToGroupSucceeded', function(name, event) {
            if (event.groupId.id === groupId) {
              receivedEvent = true;
            }
          });

          deferred.promise.then(null, null, function(elapsedSeconds) {
            if (!gettingGroup) {
              gettingGroup = true;
              getAndCacheGroup(groupId, orgId).then(
                function(group) {
                  group.groupUsers.forEach(function(member) {
                    if (member.userId.id === userIds[0].id.id) {
                      deferred.resolve(groupId);
                    }
                  });
                  group.pendingMembers.forEach(function(member) {
                    if (member.emailAddress === userIds[0].id.id) {
                      deferred.resolve(groupId);
                    }
                  });
                  gettingGroup = false;
                },
                function() {
                  gettingGroup = false;
                }
              );
            }
            if (elapsedSeconds + 2 >= timeoutSeconds && receivedEvent === true) {
              deferred.resolve(groupId);
            }
          });

          $rootScope.$on('OrgAccountError', function() {
            deferred.reject('OrgAccountError');
          });

          return deferred.promise;
        },
        getGroupsOverview: function(orgId) {
          return api.get('/v1/organizations/' + orgId + '/groups').then(function(response) {
            response.data.forEach(function(groupOverview) {
              var groupSearchData = [];
              groupSearchData.push(groupOverview.name);
              groupOverview.groupUsers.forEach(function(user) {
                groupSearchData.push(user.displayName);
                groupSearchData.push(user.email);
              });
              groupOverview.hasActiveMembers = Boolean(groupOverview.groupUsers.length);
              groupOverview.searchData = groupSearchData;
              groupOverview.unassignIncompleteTrainingOnRemove = groupOverview.hasTraining;
              groupOverview.totalMembers = groupOverview.groupUsers.length + groupOverview.pendingMemberCount;
            });
            return response.data;
          });
        },
      };

      function getAndCacheGroup(groupId, orgId) {
        return api.get('/v1/organizations/' + orgId + '/groups/' + groupId).then(function(response) {
          cache.put(groupId, response.data);
          return response.data;
        });
      }
    },
  ]
);
