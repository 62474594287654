import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import Breadcrumbs from '../components/Breadcrumbs';
import AddPeopleTo from '../components/AddPeopleTo';
import { useUser } from '../authentication';
import orgService from '../services/orgService';
import LoadingState from '../components/LoadingState';
import { groupService } from '../services/groupService';
import { Grid, Color, Breakpoints } from '../StyleGuide';
import StepArrows from './StepArrows';
import { Container } from '../components/Layout';
import trainingService from '../services/trainingService';
import windowService from '../services/windowService';
import { PrimaryButton, ToggleButton } from '../components/Buttons';
import ErrorMessage from '../components/ErrorMessage';
import { FormFieldContainer, FormFieldSet, FormGroupContainer, Input, Label, Radio } from '../components/FormElements';
import alertService from '../services/AlertService';
import { handleError } from '../utils/apiUtils';
import ConfirmationModal from '../components/ConfirmationModal';
import useModal from '../hooks/useModal';

const StyledContainer = styled(Container)`
  margin: 32px auto;

  @media screen and (max-width: ${Breakpoints.screen_sm}) {
    padding: 0;
  }
`;

const AssignTrainingContainer = styled.div`
  background: ${Color.white};
  padding: ${Grid._5};

  h3 {
    font-weight: 400;

    &.options-header {
      font-weight: 700;
      font-size: ${Grid._5};
    }
  }
`;

const AssignmentOptionsForm = styled.form`
  margin-top: ${Grid._5};
  max-width: 640px;
`;

const FormFieldDate = styled(FormFieldContainer)`
  .react-datepicker-wrapper {
    width: 100%;
  }

  &.due-date-container {
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      justify-content: center;
      grid-template-columns: 0.5fr;
    }

    > div.disabled {
      opacity: 0.5;
    }
  }
`;

const Steps = Object.freeze({
  SELECT_PEOPLE: 'Select People',
  OPTIONS: 'Options',
});

const AssignTraining = () => {
  const user = useUser();
  const { trainingId } = useParams();

  const [data, setData] = useState({
    org: {},
    roles: [],
    people: [],
    groups: [],
    training: {},
    instances: [],
    trainingType: '',
    isLoading: true,
    isSubmitting: false,
    isError: false,
  });
  const [step, setStep] = useState(Steps.SELECT_PEOPLE);
  const [dueDate, setDueDate] = useState('');
  const [dueDateToggle, setDueDateToggle] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [selectedPeople, setSelectedPeople] = useState([]);
  const [crumbs, setCrumbs] = useState([]);

  const { people, isLoading, isError, isSubmitting, groups, roles, training } = data;
  const orgId = user.lastSelectedAccount;
  const today = new Date();

  const [modal, openModal] = useModal((type, payload, dismissModal) => {
    if (type === 'confirm' && payload.numberOfPeople) {
      return (
        <ConfirmationModal
          buttonActionText="Continue"
          title="Assign Training"
          prompt={<span>Are you sure you want to assign this training to {payload.numberOfPeople} people?</span>}
          handleSubmit={() => {
            dismissModal();
            setStep(Steps.OPTIONS);
          }}
          handleDismiss={dismissModal}
        />
      );
    }
  });

  useEffect(() => {
    const getOrgUsers = orgService.getOrgUsers(orgId);
    const getRoles = orgService.getRolesOverview(orgId, true);
    const getGroups = groupService.getGroups(orgId);
    const getTraining = trainingService.getTraining(trainingId);

    const promises = [getOrgUsers, getRoles, getGroups, getTraining];
    Promise.all(promises)
      .then(([orgUsersResponse, roleResponse, groupResponse, trainingResponse]) => {
        const isFromTrainingProgress = windowService.getPreviousRoute() === '/training-progress';

        const baseCrumbs = isFromTrainingProgress
          ? [
              { name: 'Organization', route: '#/org' },
              { name: 'Training Progress', route: '#/training-progress' },
            ]
          : [
              { name: 'Search Results', route: '#/search' },
              {
                name: trainingResponse.trainingName,
                route: `#/training/${trainingResponse.trainingId}`,
              },
            ];
        setCrumbs([...baseCrumbs, { name: 'Assign Training' }]);

        const formattedGroups = groupResponse.map(el => ({
          id: el.id.id,
          name: el.name,
        }));

        setData({
          roles: roleResponse,
          people: orgUsersResponse.users,
          groups: formattedGroups,
          training: trainingResponse,
          isLoading: false,
          isError: false,
        });
      })
      .catch(() => setData({ isError: true, isLoading: false }));
  }, []);

  const updatePeople = selectedIds => {
    setData(prev => ({
      ...prev,
      people: prev.people.map(person => ({
        ...person,
        isSelected: selectedIds.includes(person?._id?.id || person?.inviteId?.id),
      })),
    }));
  };

  const getFormattedDueDate = date => ({
    dayOfMonth: date.getDate(),
    month: date.getMonth() + 1,
    year: date.getFullYear(),
    timeZone: 0,
  });

  const onPeopleAdded = selected => {
    const formattedPeople = selected.map(select => ({
      ...select,
      selectedId: select?._id?.id || select?.inviteId?.id,
      email: select.email || select.emailAddress,
    }));

    setSelectedPeople(formattedPeople);
    updatePeople(formattedPeople.map(person => person.selectedId));

    if (formattedPeople.length > 10) {
      return openModal('confirm', {
        numberOfPeople: formattedPeople.length,
      });
    }

    return setStep(Steps.OPTIONS);
  };

  const handleAssignTraining = e => {
    e.preventDefault();

    setData(previousData => ({
      ...previousData,
      isSubmitting: true,
    }));

    const assigneeIds = selectedPeople.filter(selected => selected?._id?.id).map(people => people.selectedId);
    const invitedAssignees = selectedPeople.filter(selected => selected._type === 'PendingUser');

    const invite =
      invitedAssignees.length === 0
        ? null
        : {
            invitees: invitedAssignees.map(people => ({
              _type: people.type,
              email: people.email,
              firstName: people.firstName,
              groups: people.type,
              lastName: people.lastName,
              name: people.name,
              roles: people.roles,
              selected: people.isSelected,
              toBeInvited: people.toBeInvited,
            })),
          };

    const trainingDueDate = dueDateToggle ? getFormattedDueDate(dueDate || today) : null;

    trainingService
      .assignTraining(
        training.trainingId,
        training.trainingType === 'Workflow',
        assigneeIds,
        trainingDueDate,
        orgId,
        emailNotification,
        invite,
        user.userId
      )
      .then(() => {
        alertService.showOnNextPage(
          `Training Assigned to ${selectedPeople.length} ${selectedPeople.length === 1 ? 'Person' : 'People'}`
        );
        windowService.redirectTo(`/training/${training.trainingId}`);
      })
      .catch(error => {
        handleError(error);
        setData(previousData => ({
          ...previousData,
          isSubmitting: false,
        }));
      });
  };

  return isLoading ? (
    <LoadingState />
  ) : isError ? (
    <ErrorMessage />
  ) : (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <StyledContainer>
        <StepArrows
          steps={Object.values(Steps)}
          currentStep={step}
          isDisabled={step === Steps.SELECT_PEOPLE}
          handleStepChange={setStep}
        />

        {step === Steps.SELECT_PEOPLE ? (
          <AssignTrainingContainer>
            <AddPeopleTo
              showInlineAddNewPerson
              user={user}
              submitButtonText="Continue"
              handleAddPeople={onPeopleAdded}
              preventSubmitConfirmation
              alreadyAddedPeople={people}
              filters={{
                roles: { displayName: 'Roles', items: roles },
                groups: { displayName: 'Groups', items: groups },
              }}
            />
          </AssignTrainingContainer>
        ) : (
          <AssignTrainingContainer>
            <h3 className="options-header">{training.trainingName}</h3>
            <AssignmentOptionsForm onSubmit={handleAssignTraining}>
              <FormFieldSet name="Assignment Options">
                <FormFieldContainer>
                  <Label>Email Notification</Label>
                  <FormGroupContainer>
                    <p>Send an email notification once training has been assigned.</p>
                    <div className="controls">
                      <Radio
                        id="email-notification-yes"
                        name="email-notification"
                        data-qa-hook="searchAssignTrainingSendEmail"
                        onChange={() => setEmailNotification(true)}
                        checked={emailNotification}
                      />
                      <label htmlFor="email-notification-yes">Yes</label>
                      <Radio
                        id="email-notification-no"
                        name="email-notification"
                        data-qa-hook="searchAssignTrainingListDoNotSendEmail"
                        onChange={() => setEmailNotification(false)}
                        checked={!emailNotification}
                      />
                      <label htmlFor="email-notification-no">No</label>
                    </div>
                  </FormGroupContainer>
                </FormFieldContainer>

                <FormFieldContainer>
                  <Label>Due Date</Label>
                  <FormGroupContainer>
                    <p>Set a due date for this assigned training</p>
                    <ToggleButton isOn={dueDateToggle} onClick={() => setDueDateToggle(previous => !previous)}>
                      {dueDateToggle ? 'Has Due Date' : 'No Due Date'}
                    </ToggleButton>
                  </FormGroupContainer>
                </FormFieldContainer>
                <FormFieldContainer>
                  <FormFieldDate className="due-date-container grid-container grid-col-1">
                    <div className={`grid-span-col-1${!dueDateToggle ? ' disabled' : ''}`}>
                      <Label>Availability End Date</Label>
                      <DatePicker
                        data-qa-hook="datePicker"
                        selected={dueDate || today}
                        onChange={date => setDueDate(date)}
                        minDate={today}
                        customInput={<Input />}
                        dateFormat="MM/dd/yyyy"
                        disabled={!dueDateToggle}
                        placeholderText="Select Date"
                      />
                    </div>
                  </FormFieldDate>
                </FormFieldContainer>
                <PrimaryButton
                  data-qa-hook="searchAssignTrainingAssign"
                  type="submit"
                  operating={isSubmitting}
                  disabled={isSubmitting}
                >
                  Assign Training
                </PrimaryButton>
              </FormFieldSet>
            </AssignmentOptionsForm>
          </AssignTrainingContainer>
        )}
      </StyledContainer>
      {modal}
    </>
  );
};

export default AssignTraining;
