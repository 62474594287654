import React from 'react';
import CompleteStepButtonContainer from './CompleteStepButtonContainer';
import CompleteStepButton from './CompleteStepButton';
import { Button } from '../components/Buttons';
import { SixteenNineAspectRatioPlaceholder } from '../components/Layout';

export default function VideoCompleted(props) {
  return (
    <>
      {!props.showVideoPlayer && (
        <SixteenNineAspectRatioPlaceholder borderRadius="0" className="aspect-ratio-box">
          <Button
            data-qa-hook="rewatchButton"
            onClick={() => {
              props.setShowVideoPlayer(true);
            }}
          >
            <i className="fas fa-redo-alt"></i> Rewatch
          </Button>
        </SixteenNineAspectRatioPlaceholder>
      )}
      {props.currentStepIsIncomplete && (
        <CompleteStepButtonContainer>
          <CompleteStepButton isDeleted={false} />
        </CompleteStepButtonContainer>
      )}
    </>
  );
}
