import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '../StyleGuide';
import ReportRow from './ReportRow';
import { FormFieldSet } from '../components/FormElements';

const ReportsContainer = styled.div`
  max-width: 720px;
  margin: ${Grid._6} auto 0 auto;

  legend {
    margin-bottom: ${Grid._2};
  }

  h4,
  button {
    margin: 0;
  }

  ul {
    padding: 0;
  }

  li {
    justify-content: space-between;
  }
`;

const Reports = ({ reports, ...props }) => {
  return (
    <ReportsContainer>
      <FormFieldSet name="Reports" />
      <ul>
        {reports.map(report => (
          <ReportRow key={report.reportId} initialReport={report} {...props} />
        ))}
      </ul>
    </ReportsContainer>
  );
};

Reports.propTypes = {
  reports: PropTypes.array.isRequired,
};

export default Reports;
