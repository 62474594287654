import React, { useState, useEffect } from 'react';
import { react2angular } from 'react2angular';
import PropTypes from 'prop-types';
import Notifications from 'react-notify-toast';

// Services
import { groupService } from '../services/groupService';
import alertService from '../services/AlertService';

// Components
import GroupCard from './GroupCard';
import LoadingState from '../components/LoadingState';
import AddGroup from './AddGroup';
import { TextFilterInput } from '../components/FormElements';
import EmptyState from './EmptyState';
import styled from 'styled-components';
import { Grid } from '../StyleGuide';

// Utilities
const hasItem = (list, item) => list.find(entry => entry.toLowerCase().includes(item.toLowerCase()));

const CustomContainer = styled.div`
  margin-top: ${Grid._6};
`;

// Groups
export default function Groups({ user }) {
  const [data, setData] = useState({ isLoading: true });
  const [filter, setFilter] = useState(localStorage.getItem('groupsFilter') || '');
  const [isDuplicateName, setIsDuplicateName] = useState('');
  const orgId = user.lastSelectedAccount;
  const userId = user.userId;
  const isAdmin = user.permissions.includes('Groups View');
  const isLeader = user.permissions.includes('Group Leader');

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    localStorage.setItem('groupsFilter', filter);
  }, [filter]);

  useEffect(() => {
    if (!isAdmin && !isLeader) window.location = '#/dashboard';
  }, [user]);

  // GroupService
  const getGroups = () =>
    groupService
      .getGroups(orgId)
      .then(response => setData({ groups: response, isLoading: false }))
      .catch(() => setData({ isError: true, isLoading: false }));

  const removeGroup = groupToRemove =>
    groupService
      .removeGroup(groupToRemove.id, groupToRemove.hasTraining, orgId, userId)
      .then(() => {
        setData(prevData => {
          const updatedGroups = prevData.groups.filter(group => group.id.id !== groupToRemove.id.id);
          return {
            ...prevData,
            groups: updatedGroups,
          };
        });
      })
      .finally(_ => {
        if (!isAdmin) window.location = '#/dashboard';
      })
      .catch(() => alertService.show('Error! Please try again.', 'error'));

  const createGroup = groupName =>
    groupService
      .createGroup(groupName, orgId, userId)
      .then(newGroup => (window.location = '#/groups/' + newGroup.groupId.id + '/' + orgId))
      .catch(reason => {
        if (reason && reason.code === 'GroupNameDuplicateError') {
          return setIsDuplicateName('Sorry, that Group name is taken');
        } else {
          alertService.show('Error! Please try again.', 'error');
        }
      });

  const GroupCards = () => {
    const groups = data.groups.filter(group => hasItem(group.filterableData, filter));

    return groups.length ? (
      <div
        data-qa-hook="groupCardContainer"
        className="grid-container grid-col-2 grid-md-col-4 grid-gap-16 grid-md-gap-24"
      >
        {groups.map(group => (
          <GroupCard
            group={group}
            redirectToGroup={() => (window.location = '#/groups/' + group.id.id + '/' + orgId)}
            handleRemoval={() => removeGroup(group)}
            key={group.id.id}
          />
        ))}
      </div>
    ) : (
      <EmptyState noResults={true} />
    );
  };

  return data.isError ? (
    <EmptyState isError={'is-error'} />
  ) : (
    <>
      <Notifications />

      <div className="grid-container grid-gap-16 grid-sm-col-2 grid-md-col-6">
        <div className="primary-action">
          {!data.isLoading && isAdmin && (
            <AddGroup
              handleCreateGroup={createGroup}
              isDuplicateName={isDuplicateName}
              setIsDuplicateName={setIsDuplicateName}
            />
          )}
        </div>
        <div className="grid-sm-col-span-2 grid-md-col-span-5">
          {!data.isLoading && !!data.groups.length && (
            <TextFilterInput
              id="groupsFilter"
              placeholder="Search Groups"
              value={filter}
              onChangeHandler={e => setFilter(e.target.value)}
              clearInput={() => setFilter('')}
            />
          )}
        </div>
      </div>
      {data.isLoading ? (
        <LoadingState />
      ) : data.groups.length ? (
        <CustomContainer>
          <GroupCards />
        </CustomContainer>
      ) : (
        <EmptyState defaultMsg={'no-groups'} />
      )}
    </>
  );
}

Groups.propTypes = {
  user: PropTypes.object.isRequired,
};

angular.module('lwNamb').component('groups', react2angular(Groups));
