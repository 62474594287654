import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { EventTracker } from '../../analytics';
import { useUser } from '../../authentication';
import { SmallRoundedIconButton, SmallTertiaryButton } from '../../components/Buttons';
import ConfirmationModal from '../../components/ConfirmationModal';
import EmptyState from '../../components/EmptyState';
import {
  EditSegmentToolbar,
  FlexSegmentOptionSelectHeader,
  FlexSegmentOptionsSelectedHeader,
  GroupDuration,
  GroupTransition,
  NoteSection,
  ScriptSection,
  SectionBody,
  SectionContent,
  SectionLabel,
  SectionMedia,
  SectionMediaLabel,
  SegmentContainer,
  SegmentHeader,
  SegmentLeader,
  StickySectionMediaItem,
  SuppliesSection,
  TextSection,
  TimelineContainer,
  TimelineLabel,
  TimelineVerticalSpacer,
  TipSection,
  VideoSection,
} from '../../components/SessionTimelineContentElements';
import { DurationStepper } from '../../components/SessionTimelineElements';
import { ToggleSwitch } from '../../components/Switches';
import useDebounceCallback from '../../hooks/useDebounceCallback';
import useEventBusSubscription from '../../hooks/useEventBusSubscription';
import useModal from '../../hooks/useModal';
import useSessionTimeline from '../../hooks/useSessionTimeline';
import alertService from '../../services/AlertService';
import sessionTimelineService, { mapSections, renderSegments } from '../../services/sessionTimelineService';
import { generateUUID } from '../../services/uuid';
import windowService from '../../services/windowService';
import { handleError } from '../../utils/apiUtils';
import ConditionalFlexOptionWrapper from './ConditionalFlexOptionWrapper';
import NoteModal from './NoteModal';
import SectionMediaThumbnail from './SectionMediaThumbnail';
import SectionMediaViewer from './SectionMediaViewer';
import { ListItemFlexRow } from '../../components/ListItemFlexRow';

const getMediaTitle = (section, segment) => section?.name || segment?.name || '';

const DownloadableMediaThumbnail = ({ media, title }) => {
  const downloadMedia = () => {
    const downloadUrl = media._type === 'video' ? media.url : media.url.download;

    if (!downloadUrl) return alertService.showError();
    windowService.openUrl(downloadUrl);
  };

  return (
    <SectionMediaThumbnail
      viewOnly
      media={media}
      title={title}
      buttons={
        <SmallRoundedIconButton
          data-qa-hook="downloadSetionMediaButton"
          className="transparent"
          onClick={downloadMedia}
        >
          <i className="fas fa-download fa-fw"></i>
        </SmallRoundedIconButton>
      }
    />
  );
};

DownloadableMediaThumbnail.propTypes = {
  media: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

const ExpandableMediaThumbnail = ({ sectionId, media, title, expand }) => (
  <SectionMediaThumbnail
    viewOnly
    media={media}
    title={title}
    onClick={() => expand(media.id, sectionId)}
    buttons={
      <SmallRoundedIconButton data-qa-hook="expandSetionMediaButton" className="transparent">
        <i className="fas fa-expand"></i>
      </SmallRoundedIconButton>
    }
  />
);

ExpandableMediaThumbnail.propTypes = {
  sectionId: PropTypes.string.isRequired,
  media: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  expand: PropTypes.func.isRequired,
};

const SegmentSection = ({ timelineClassName, segment, section, onExpandMedia }) => {
  const { segmentLeaderMap, sectionTypeMap } = useSessionTimeline();

  return (
    <SectionContent verticalSpaces={section.media ? 7 : 3}>
      <SectionMedia className={!!timelineClassName && 'timeline-highlight-mobile-only'}>
        {section.media && (
          <StickySectionMediaItem className={!!timelineClassName && 'flex-segment-sticky-section-media-item'}>
            <SectionMediaLabel>{section.name || segment.name} Media</SectionMediaLabel>
            {onExpandMedia ? (
              <ExpandableMediaThumbnail
                sectionId={section.id}
                media={section.media}
                title={getMediaTitle(section, segment)}
                expand={onExpandMedia}
              />
            ) : (
              <DownloadableMediaThumbnail media={section.media} title={getMediaTitle(section, segment)} />
            )}
          </StickySectionMediaItem>
        )}
      </SectionMedia>
      <SectionBody className={timelineClassName}>
        {section.type === 'body' && <TextSection dangerouslySetInnerHTML={{ __html: section.text }}></TextSection>}
        {section.type === 'script' && (
          <>
            <SectionLabel>{segment.name} Script</SectionLabel>
            <ScriptSection leader={segment.leader}>
              <h5>{segmentLeaderMap[segment.leader]}</h5>
              <div dangerouslySetInnerHTML={{ __html: section.text }}></div>
            </ScriptSection>
          </>
        )}
        {section.type === 'supplies' && (
          <SuppliesSection>
            <SectionLabel>Supplies</SectionLabel>
            {section.supplies.length ? (
              <ul>
                {section.supplies.map(supply => (
                  <ListItemFlexRow
                    key={supply.name}
                    onClick={supply.downloadable?.url ? () => window.open(supply.downloadable.url) : undefined}
                  >
                    <h5>{supply.name}</h5>
                    <p>{supply.quantity}</p>
                    {supply.downloadable?.url && <i className="fas fa-download fa-fw"></i>}
                  </ListItemFlexRow>
                ))}
              </ul>
            ) : (
              <p>No Supplies Needed</p>
            )}
          </SuppliesSection>
        )}
        {section.type === 'tip' && (
          <TipSection type={section.tipType}>
            <h5>{sectionTypeMap.tip.tipType[section.tipType]}</h5>
            <p dangerouslySetInnerHTML={{ __html: section.text }}></p>
          </TipSection>
        )}
        {section.type === 'video' && <VideoSection videoName={section.name} fileName={section.media?.name} />}
      </SectionBody>
    </SectionContent>
  );
};

SegmentSection.propTypes = {
  timelineClassName: PropTypes.string,
  segment: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired,
  onExpandMedia: PropTypes.func,
};

const FlexSegment = ({ options, children, ...passThroughProps }) => {
  return options.map((option, i) => (
    <FlexSegmentOption key={option.segmentId} option={option} index={i} {...passThroughProps}>
      {children({ timelineClassName: 'timeline-highlight', segment: option, sections: option.sections })}
    </FlexSegmentOption>
  ));
};

FlexSegment.propTypes = {
  options: PropTypes.array.isRequired,
  children: PropTypes.func,
};

const FlexSegmentOption = ({ option, index, onFlexOptionToggle, customizable, children }) => {
  const [showSections, setShowSections] = useState(!customizable);

  return (
    <SegmentContainer id={`contents-${option.segmentId}`} name={`contents-${option.segmentId}`}>
      <FlexSegmentOptionSelectHeader
        option={option}
        i={index + 1}
        onToggle={() => onFlexOptionToggle(option)}
        showSections={showSections}
        setShowSections={setShowSections}
        customizable={customizable}
      />
      {showSections && (
        <>
          <SegmentLeader timelineClassName={'timeline-highlight'} leader={option.leader} />
          <TimelineVerticalSpacer timelineClassName={'timeline-highlight'} spaces="1" />
          {children}
        </>
      )}
    </SegmentContainer>
  );
};

const RemoveNoteModal = props => (
  <ConfirmationModal
    buttonActionText="Remove"
    buttonType="danger"
    title="Remove Note"
    prompt={<span>Are you sure you want to remove the note from this segment?</span>}
    {...props}
  />
);

const SessionTimelineContent = ({ customizable, useViewPreferences }) => {
  const user = useUser();
  const updateDurationDebounce = useDebounceCallback(400);
  const [mediaItems, setMediaItems] = useState([]);
  const { timeline, setTimeline } = useSessionTimeline();

  const DURATION_INCREASE_AMOUNT = 1;
  const DURATION_DECREASE_AMOUNT = -1;

  useEventBusSubscription('TimelineArchiveCreated', ({ id, archiveId }) => {
    if (id === timeline.id) {
      setTimeline(previousTimeline => ({ ...previousTimeline, archiveId }));
    }
  });

  useEffect(() => {
    setMediaItems(
      mapSections(
        timeline,
        (section, segment) =>
          segment.enabled &&
          !!section.media && {
            ...section.media,
            sectionId: section.id,
            title: getMediaTitle(section, segment),
          }
      ).filter(Boolean)
    );
  }, [timeline.archiveId]);

  const showTransition = (currentGroup, i) => currentGroup !== timeline.segments[i - 1].group;

  const toggleFlexOptionSelectedStatus = option => {
    const status = !option.enabled;

    setTimeline(previousTimeline =>
      sessionTimelineService.updateFlexOptionSelectedStatus(
        sessionTimelineService.clearTimelineArchiveId(previousTimeline),
        option.flexSegmentId,
        option.segmentId,
        status
      )
    );

    updateSegmentStatus(option, status);
  };

  const updateSegmentDuration = (segment, amount) => {
    const updatedDuration = segment.duration + amount;

    setTimeline(previousTimeline =>
      sessionTimelineService.updateSegmentInTimeline(previousTimeline, segment.segmentId, {
        ...segment,
        duration: updatedDuration,
      })
    );

    updateDurationDebounce(() => {
      sessionTimelineService
        .customizeSegmentDuration(
          timeline.id,
          timeline.originalTimelineId,
          user.lastSelectedAccount,
          segment.segmentId,
          updatedDuration,
          user.userId
        )
        .then(() => alertService.show('Leader Guide Updated'))
        .catch(handleError);
    });
  };

  const updateNoteInTimeline = (segmentId, note) =>
    setTimeline(previousTimeline =>
      sessionTimelineService.updateSegmentInTimeline(previousTimeline, segmentId, { note }, false)
    );

  const createNote = note => {
    const noteWithId = generateUUID(id => ({ ...note, id }));

    sessionTimelineService
      .createNote(timeline.id, timeline.originalTimelineId, user.lastSelectedAccount, noteWithId, user.userId)
      .then(() => {
        alertService.show('Note Added');
        updateNoteInTimeline(note.segmentId, noteWithId);
      })
      .catch(handleError)
      .finally(dismissModal);
  };

  const updateNote = note => {
    sessionTimelineService
      .updateNote(timeline.id, timeline.originalTimelineId, user.lastSelectedAccount, note, user.userId)
      .then(() => {
        alertService.show('Note Saved');
        updateNoteInTimeline(note.segmentId, note);
      })
      .catch(handleError)
      .finally(dismissModal);
  };

  const removeNote = note => {
    sessionTimelineService
      .deleteNote(timeline.id, timeline.originalTimelineId, note.id, user.userId)
      .then(() => {
        alertService.show('Note Removed');
        updateNoteInTimeline(note.segmentId, null);
      })
      .catch(handleError)
      .finally(dismissModal);
  };

  const toggleSegment = segment => {
    const status = !segment.enabled;

    setTimeline(previousTimeline =>
      sessionTimelineService.updateSegmentInTimeline(
        sessionTimelineService.clearTimelineArchiveId(previousTimeline),
        segment.segmentId,
        { enabled: status }
      )
    );

    EventTracker.trackEvent(EventTracker.events.TIMELINE_SEGMENT_TOGGLED, { ...segment, enabled: status });

    updateSegmentStatus(segment, status);
  };

  const updateSegmentStatus = (segment, status) => {
    sessionTimelineService
      .toggleSegment(timeline.id, timeline.originalTimelineId, segment.segmentId, status, user.userId)
      .then(() => alertService.show('Leader Guide Updated'))
      .catch(handleError);
  };

  const shouldShowSegment = segment =>
    !useViewPreferences || sessionTimelineService.matchesLeaderViewPreference(timeline.viewPreference, segment.leader);

  const hasSegmentsToDisplay = segments =>
    segments?.length &&
    (!useViewPreferences ||
      segments.some(s => sessionTimelineService.matchesLeaderViewPreference(timeline.viewPreference, s.leader)));

  const getDurationOfGroup = (segments, segmentIndex) => {
    let duration = 0;

    for (let i = segmentIndex; i < segments.length; i++) {
      duration += segments[i].duration;
      if (segments[i].group !== segments[i + 1]?.group) break;
    }

    return duration;
  };

  const viewMedia = (mediaId, sectionId) =>
    openModal('expandMedia', {
      startingMediaIndex: mediaItems.findIndex(m => m.id === mediaId && m.sectionId === sectionId),
    });

  const [modal, openModal, dismissModal] = useModal((type, payload, dismissModal) => {
    switch (type) {
      case 'addNote':
        return (
          <NoteModal note={{ segmentId: payload.segmentId }} handleSubmit={createNote} handleDismiss={dismissModal} />
        );

      case 'editNote':
        return <NoteModal note={payload.note} handleSubmit={updateNote} handleDismiss={dismissModal} />;

      case 'removeNote':
        return <RemoveNoteModal handleSubmit={() => removeNote(payload.note)} handleDismiss={dismissModal} />;

      case 'expandMedia':
        return (
          <SectionMediaViewer
            mediaItems={mediaItems}
            startingMediaIndex={payload.startingMediaIndex}
            handleDismiss={dismissModal}
          />
        );

      default:
        return null;
    }
  });

  return (
    <>
      <TimelineContainer>
        {!hasSegmentsToDisplay(timeline.segments) ? (
          <EmptyState
            title="There are no activities for this preference selection."
            description="Please try another preference, or contact your church administrator if you expected content to be here."
          />
        ) : (
          renderSegments(timeline, (segment, durationRemaining, i) => (
            <React.Fragment key={`timeline-segment-${i}`}>
              {i > 0 && showTransition(segment.group, i) && (
                <div id={`transition-${i}`} name={`transition-${i}`} className="group-transition">
                  {shouldShowSegment(segment) ? (
                    <GroupTransition prevGroup={timeline.segments[i - 1].group} nextGroup={segment.group} />
                  ) : (
                    <GroupDuration
                      group={timeline.segments[i].group}
                      duration={getDurationOfGroup(timeline.segments, i)}
                    />
                  )}
                  <TimelineVerticalSpacer spaces="3" />
                </div>
              )}
              {shouldShowSegment(segment) && (
                <div
                  data-qa-hook={`segmentContainer-${i}`}
                  data-tracking-component="timeline-segment"
                  data-tracking-id={segment.name}
                  id={`contents-${segment.segmentId}`}
                  name={`contents-${segment.segmentId}`}
                >
                  <SegmentContainer className={!segment.enabled ? 'excluded-segment' : ''}>
                    <SegmentHeader
                      name={
                        <>
                          <span>{i + 1} - </span>
                          {segment.name}
                        </>
                      }
                      group={segment.group}
                      duration={segment.duration}
                      durationControls={
                        customizable && (
                          <DurationStepper
                            increment={
                              !!segment.duration && segment.duration < 60
                                ? () => updateSegmentDuration(segment, DURATION_INCREASE_AMOUNT)
                                : null
                            }
                            decrement={
                              !!segment.duration && segment.duration > DURATION_INCREASE_AMOUNT
                                ? () => updateSegmentDuration(segment, DURATION_DECREASE_AMOUNT)
                                : null
                            }
                          />
                        )
                      }
                    />

                    <TimelineLabel
                      totalDuration={timeline.duration}
                      remainingDuration={durationRemaining}
                      currentDuration={segment.duration}
                    />

                    {customizable && (
                      <EditSegmentToolbar
                        leftContent={
                          <ToggleSwitch
                            id={`segment-toggle-${segment.segmentId}`}
                            data-tracking-id={`segment-toggle-${segment.enabled ? 'off' : 'on'}`}
                            label={segment.enabled ? 'Included in Session' : 'Excluded from Session'}
                            checked={segment.enabled}
                            onChange={() => toggleSegment(segment)}
                          />
                        }
                        rightContent={
                          segment.note ? (
                            <SmallTertiaryButton
                              data-qa-hook="editNote"
                              disabled={!segment.enabled}
                              onClick={() => openModal('editNote', { note: segment.note })}
                            >
                              Edit Note
                            </SmallTertiaryButton>
                          ) : (
                            <SmallTertiaryButton
                              data-qa-hook="addNote"
                              disabled={!segment.enabled}
                              onClick={() => openModal('addNote', { segmentId: segment.segmentId })}
                            >
                              Add Note
                            </SmallTertiaryButton>
                          )
                        }
                      />
                    )}

                    {segment.enabled && (
                      <>
                        {!segment.isFlex ? (
                          <SegmentLeader leader={segment.leader} />
                        ) : (
                          customizable && (
                            <FlexSegmentOptionsSelectedHeader
                              numSelected={timeline.segments[i].options.filter(o => o.enabled).length}
                            />
                          )
                        )}

                        {segment.note && (
                          <>
                            <NoteSection
                              note={segment.note}
                              onRemove={customizable ? () => openModal('removeNote', { note: segment.note }) : null}
                            />
                            <TimelineVerticalSpacer spaces="1" />
                          </>
                        )}

                        <ConditionalFlexOptionWrapper
                          FlexOptionComponent={FlexSegment}
                          segment={segment}
                          onFlexOptionToggle={toggleFlexOptionSelectedStatus}
                          customizable={customizable}
                        >
                          {({ timelineClassName, segment, sections }) =>
                            sections.map((section, i) => (
                              <React.Fragment key={section.id}>
                                <SegmentSection
                                  timelineClassName={timelineClassName}
                                  segment={segment}
                                  section={section}
                                  onExpandMedia={customizable ? null : viewMedia}
                                />
                                {i + 1 < segment.sections.length && (
                                  <TimelineVerticalSpacer spaces="1" timelineClassName={timelineClassName} />
                                )}
                              </React.Fragment>
                            ))
                          }
                        </ConditionalFlexOptionWrapper>
                      </>
                    )}

                    <TimelineVerticalSpacer spaces="3" />
                  </SegmentContainer>
                </div>
              )}
            </React.Fragment>
          ))
        )}
      </TimelineContainer>
      {modal}
    </>
  );
};

SessionTimelineContent.propTypes = {
  customizable: PropTypes.bool,
  useViewPreferences: PropTypes.bool,
};

export default SessionTimelineContent;
