angular.module('lwNamb').directive('lwVideo', [
  function() {
    return {
      restrict: 'E',
      scope: {
        videoid: '@id',
        videourl: '@',
        videofallback: '@',
        autoplay: '@',
        poster: '@',
        preview: '@',
        progress: '@',
        audioonly: '@',
      },
      controller: [
        '$scope',
        '$log',
        '$localStorage',
        function($scope, $log, $localStorage) {
          var myPlayer;
          var audioOnly = $localStorage.audioOnly === true || $scope.audioonly === true || $scope.audioonly === 'true';

          if (typeof myPlayer !== 'undefined' && myPlayer.destroy) {
            myPlayer.destroy();
          }
          var autoplay = '';
          function checkAutoPlay(override) {
            if (override === true || ($scope.autoplay !== undefined && $scope.autoplay === 'true')) {
              autoplay = 'autoplay';
            }
          }
          checkAutoPlay();

          $scope.$watch('poster', function() {
            create();
          });
          $scope.$watch('videourl', function(vidsrc) {
            if (vidsrc && vidsrc.length > 0) {
              $scope.vidsrc = vidsrc;
              create();
            }
          });

          function toggleAudio(toAudio) {
            if (audioOnly !== toAudio) {
              audioOnly = !audioOnly;
              $localStorage.audioOnly = audioOnly;
              $scope.audioonly = audioOnly ? 'true' : 'false';
              try {
                $scope.progress = myPlayer.currentTime();
                myPlayer.dispose();
                myPlayer.destroy();
              } catch (e) {}
              checkAutoPlay(true);
              create();
            }
          }

          function create() {
            if (typeof myPlayer !== 'undefined') {
              myPlayer.dispose();
            }

            var mb = videojs.getComponent('MenuButton');
            var settingsCog = videojs.extend(mb, {
              constructor: function(player, options) {
                mb.call(this, player, options);
                this.controlText('Settings');
              },
              createMenu: function() {
                var Menu = videojs.getComponent('Menu');
                var MenuItem = videojs.getComponent('MenuItem');
                var menu = new Menu(this.player());

                var videoMenuItem = new MenuItem(this.player(), {
                  label: 'Video',
                  selectable: true,
                  multiSelectable: false,
                  selected: !audioOnly,
                });
                videoMenuItem.on('click', function() {
                  toggleAudio(false);
                });
                menu.addChild(videoMenuItem);

                var audioMenuItem = new MenuItem(this.player(), {
                  label: 'Audio Only',
                  selectable: true,
                  multiSelectable: false,
                  selected: audioOnly,
                });
                audioMenuItem.on('click', function() {
                  toggleAudio(true);
                });
                menu.addChild(audioMenuItem);
                return menu;
              },
              handleClick: function() {
                toggleAudio(!audioOnly);
              },
              buildCSSClass: function() {
                return 'vjs-icon-cog vjs-menu-button-popup vjs-menu-button vjs-control vjs-button';
              },
            });
            videojs.registerComponent('SettingsCog', settingsCog);

            var tag = audioOnly ? 'audio' : 'video';
            var element =
              '<' +
              tag +
              ' id="video-' +
              $scope.videoid +
              '" data-setup=\'{"fluid": true}\' class="video-js vjs-default-skin vjs-fluid" poster="' +
              $scope.poster +
              '" ' +
              autoplay +
              ' controls ><source src="' +
              $scope.vidsrc +
              '" type="application/x-mpegURL" /><source src="' +
              $scope.videofallback +
              '" type="video/mp4" /></' +
              tag +
              '>';
            angular
              .element('#' + $scope.videoid)
              .empty()
              .append(element);
            angular.element('#video-' + $scope.videoid).ready(function() {
              myPlayer = videojs('video-' + $scope.videoid, {
                playbackRates: [0.75, 1, 1.25, 1.5, 1.75, 2],
                controlBar: { volumePanel: { inline: false }, settingsCog: {} },
              });

              var seekingEvent = false;

              $scope.$on('$routeChangeStart', function() {
                myPlayer.dispose();
              });
              $scope.$on('$stateChangeStart', function() {
                myPlayer.dispose();
              });
              myPlayer.on('loadedmetadata', function() {
                var minutes = parseInt(myPlayer.duration() / 60, 10);
                var seconds = parseInt(myPlayer.duration() % 60, 10);
                $scope.$emit('VideoDurationFound', {
                  id: $scope.videoid,
                  minutes: minutes,
                  seconds: seconds,
                });
                if (minutes < 2 && myPlayer.src() !== $scope.videofallback) {
                  $log.debug('going mp4: ' + $scope.videofallback);
                  myPlayer.src($scope.videofallback);
                }
                if ($scope.progress !== 0) {
                  $log.debug('seeking to: ' + $scope.progress);
                  myPlayer.currentTime($scope.progress);
                  myPlayer.controls(true);
                }
                if ($localStorage.playbackRate !== undefined) {
                  myPlayer.playbackRate($localStorage.playbackRate);
                }
              });
              myPlayer.on('canplay', function() {
                $log.error('canplay, seekingEvent: ' + seekingEvent);
                if (seekingEvent) {
                  seekingEvent = false;
                } else {
                  if (myPlayer.currentTime() < parseInt($scope.progress) && parseInt($scope.progress) !== 0) {
                    $log.debug('canplay seeking to: ' + $scope.progress);
                    myPlayer.currentTime($scope.progress);
                    myPlayer.controls(true);
                  }
                }
              });
              myPlayer.on('seeking', function() {
                seekingEvent = true;
              });
              myPlayer.on('timeupdate', function() {
                $scope.$emit('TimeUpdated', {
                  time: myPlayer.currentTime(),
                });
                if ($scope.preview === 'true' && myPlayer.currentTime() > 30) {
                  myPlayer.exitFullscreen();
                  myPlayer.exitFullWindow();
                  myPlayer.dispose();
                  $scope.$emit('PreviewEnded');
                }
              });
              myPlayer.on('play', function() {
                $scope.$emit('VideoStarted', {
                  id: $scope.videoid,
                });
              });
              myPlayer.on('ended', function() {
                myPlayer.exitFullscreen();
                myPlayer.exitFullWindow();
                $scope.$emit('VideoComplete', {
                  id: $scope.videoid,
                });
              });
              myPlayer.on('ratechange', function() {
                $localStorage.playbackRate = myPlayer.playbackRate();
              });
            });
          }
        },
      ],
    };
  },
]);
