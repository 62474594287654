angular.module('lwNamb').factory('eventDispatchService', [
  '$rootScope',
  '$log',
  '$resource',
  'apiUrlService',
  '$timeout',
  '$localStorage',
  function($rootScope, $log, $resource, apiUrlService, $timeout, $localStorage) {
    var getData = function(response) {
      if (response.detail) {
        //only for testing purposes, guaranteed to have a .data field
        return response.detail.data;
      } else {
        return response.data; //will ALWAYS be hit from events coming from the server
      }
    };

    function getTimeout(timeout) {
      //since we can't use ES6 default params
      if (timeout !== undefined) {
        return timeout;
      } else {
        return 21000;
      }
    }

    var es = {
      esFeed: undefined,
      isShutdown: false,
      failedCreations: 0,
      pingTimeout: null,
      shutdown: function() {
        if (es.esFeed !== undefined) {
          es.isShutdown = true;
          $timeout.cancel(es.pingTimeout);
          es.failedCreations = 0;
          es.esFeed.close();
          es.esFeed = undefined;
        }
      },
      closeES: function(id) {
        //app should never call this method, only used for getting new socket
        es.shutdown();
        es.pingTimeout = $timeout(function() {
          es.failedCreations++;
          if (es.isShutdown) {
            $rootScope.$broadcast('SOCKET_DISCONNECTED', { userId: id });
          }
        }, 1000 * Math.pow(es.failedCreations + 1, 2));
      },
      createES: function(id, t) {
        var timeout = getTimeout(t);

        if (es.esFeed === undefined) {
          es.isShutdown = false;
          $log.info('creating sse connection');
          if (id !== undefined && id.length > 0) {
            var feed = new EventSource(apiUrlService.getUrl() + '/v1/users/' + id + '/events');
            $localStorage.$default({
              receivedEvents: [],
            });
            var pingHandler = function(response) {
              var data = getData(response);
              // $log.debug(response.type + " " + data);
              es.failedCreations = 0; //reset failures since we got a successful ping
              if (es.pingTimeout !== null) {
                //cancel previous timeout
                $timeout.cancel(es.pingTimeout);
              }
              es.pingTimeout = $timeout(function() {
                //set new timeout
                $log.error('PING TIMEOUT');
                es.closeES(id);
              }, timeout); //each ping comes in 5 seconds, this is 2 missed pongs plus network delay
              $rootScope.$apply(function() {
                //reply to a ping with a pong
                var clientId = JSON.parse(data).clientId;
                $resource(
                  apiUrlService.getUrl() + '/v1/users/' + id + '/events/pong?clientId=' + clientId,
                  {},
                  {
                    pong: {
                      method: 'POST',
                      headers: { 'Content-Type': 'application/json' },
                    },
                  }
                ).pong();
              }, 1000);
            };
            feed.addEventListener('ping', pingHandler, false);

            feed.addEventListener(
              'error',
              function(e) {
                if (e.eventPhase == EventSource.CLOSED) {
                  $log.error('actually caught an ES error, disconnecting');
                  es.closeES(id);
                }
              },
              false
            );

            feed.addEventListener(
              'ApiError',
              function(response) {
                var data = getData(response);
                var json = JSON.parse(data);
                $log.debug(json.code + ' ' + data);
                $rootScope.$broadcast(json.code, json);
                publishToEventBus(json.code, json);
              },
              false
            );

            feed.addEventListener(
              'message',
              function(response) {
                var data = getData(response);
                var json = JSON.parse(data);
                var eventHash = hash64(id, data);
                if ($localStorage.receivedEvents.indexOf(eventHash) > -1) {
                  $log.debug('Duplicate event received, not broadcasting: ' + json.eventType + ' ' + data);
                } else {
                  $localStorage.receivedEvents.push(eventHash);
                  if ($localStorage.receivedEvents.length > 1000) {
                    $localStorage.receivedEvents.shift();
                  }
                  $log.debug(json.eventType + ' ' + data);
                  $rootScope.$broadcast(json.eventType, json);
                  publishToEventBus(json.eventType, json);
                }
              },
              false
            );

            es.esFeed = feed;
            $rootScope.$broadcast('SOCKET_CONNECTED');
          }
        }
      },
    };

    function publishToEventBus(eventType, event) {
      try {
        window.eventBus.publish(eventType, event);
      } catch (e) {
        console.error('publishing to global eventBus failed for event:', eventType);
      }
    }

    function hash64(userId, str) {
      var seed;
      try {
        seed = parseInt(userId.substring(0, 8), 16);
      } catch (e) {}
      var h = hashFnv32a(str, seed);
      return h + hashFnv32a(h + str, seed);
    }

    function hashFnv32a(str, seed) {
      var i, l, hval = (seed === undefined) ? 0x811c9dc5 : seed;
      for (i = 0, l = str.length; i < l; i++) {
        hval ^= str.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
      }
      return ("0000000" + (hval >>> 0).toString(16)).substr(-8);
    }

    return es;
  },
]);
