import React, { useRef, useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';

export default function ThirdPartyYouTube({ startTime, url, onStart, onPlay, onProgress, onEnd, onError }) {
  const [progressData, setProgressData] = useState({});
  const playerRef = useRef();

  const opts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
      modestbranding: 1,
      start: startTime || 0,
    },
  };

  useEffect(() => {
    if (onProgress) onProgress(progressData);
  }, [progressData]);

  useEffect(() => {
    const calcPercentageWatched = () => {
      Promise.all([
        playerRef?.current?.internalPlayer?.getDuration(),
        playerRef?.current?.internalPlayer?.getCurrentTime(),
      ]).then(([totalDuration, elapsedSeconds]) => {
        setProgressData({ percentComplete: elapsedSeconds / totalDuration, elapsedSeconds, totalDuration });
      });
    };

    const intervalId = setInterval(calcPercentageWatched, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <YouTube
        videoId={url}
        opts={opts}
        onReady={onStart}
        onPlay={onPlay}
        onEnd={onEnd}
        onError={onError}
        ref={playerRef}
      />
    </>
  );
}

ThirdPartyYouTube.propTypes = {
  startTime: PropTypes.number,
  url: PropTypes.string.isRequired,
  onStart: PropTypes.func,
  onPlay: PropTypes.func,
  onProgress: PropTypes.func,
  onEnd: PropTypes.func,
  onError: PropTypes.func,
};
