import React, { useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Breakpoints, Color, Transition, Type } from '../StyleGuide';
import trainingService from '../services/trainingService';
import StepLabel from './StepLabel';
import { useSteps, toggleMobileTrainingMenu } from './StepsContext';

const Header = styled.header`
  position: fixed;
  display: flex;
  right: 0;
  top: 0;
  left: 0;
  z-index: 200;
  box-shadow: none;
  align-items: center;
  color: ${Color.white};
  background-color: ${Color.Gray._90};
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    right: 25%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.23);
  }
  h1 {
    order: 1;
    flex-grow: 1;
    font-size: ${Type.Scale._4};
    margin-top: 10px;
    padding: ${Grid._1} ${Grid._3};
    text-shadow: ${Type.Shadow.light};
    span {
      color: ${Color.Gray._30};
      &:after {
        content: ':';
      }
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      padding: ${Grid._3} ${Grid._7};
    }
  }
  a {
    font-size: ${Type.Scale._4};
    text-decoration: none;
    color: ${Color.white};
    padding: ${Grid._4};
    border: none;
    cursor: pointer;
    transition: ${Transition.fast};
    &:after {
      content: '';
    }
  }
  a:nth-of-type(1) {
    order: 0;
    i {
      margin-right: ${Grid._2};
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      font-size: ${Type.Scale._2};
      &:after {
        content: 'Back';
      }
      &:hover {
        text-decoration: underline;
        background: ${Color.Secondary._80};
      }
      padding: ${Grid._5};
      border-right: 1px solid ${Color.Secondary._80};
    }
  }
  a:nth-of-type(2) {
    display: block;
    order: 2;
    i {
      margin-right: ${Grid._2};
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      display: none;
    }
  }
`;

export default function StepHeader({ setMarginTop }) {
  const [{ currentStep, isWorkflow, training }, dispatch] = useSteps();
  const ref = useRef(null);
  const handleBackButton = () => {
    let nextRoute = '/dashboard-training';
    let stepsPrevRoute = localStorage.getItem('routeBeforeSteps');
    let selfAssignPrevRoute = localStorage.getItem('routeBeforeSelfAssign');
    let unAssignOnBack = localStorage.getItem('unAssignOnBack');

    if (stepsPrevRoute) localStorage.removeItem('routeBeforeSteps');
    if (selfAssignPrevRoute) localStorage.removeItem('routeBeforeSelfAssign');
    if (unAssignOnBack) localStorage.removeItem('unAssignOnBack');

    if (stepsPrevRoute === '/dashboard-history') {
      nextRoute = '/dashboard-history';
    }

    if (
      (stepsPrevRoute && stepsPrevRoute.indexOf('easy-link') > -1) ||
      (selfAssignPrevRoute && selfAssignPrevRoute.indexOf('easy-link') > -1)
    ) {
      window.location.replace('#/dashboard-training');
    } else if (
      stepsPrevRoute &&
      stepsPrevRoute.indexOf('add-to-your-training') > -1 &&
      selfAssignPrevRoute &&
      selfAssignPrevRoute.indexOf('oauth2') === -1
    ) {
      if (unAssignOnBack) {
        trainingService.unassignMyTraining(training.id, isWorkflow ? 'Workflow' : 'TaskList', training.assignedUser);
      }
      window.history.go(-2);
    } else {
      window.location.replace('#' + nextRoute);
    }
  };
  const taskName = currentStep.taskName || currentStep.task.taskName;
  const taskType = currentStep._type || currentStep.task._type;

  useLayoutEffect(() => {
    setMarginTop(ref.current.clientHeight);
  });

  return (
    <Header ref={ref} className="step-header">
      <h1 data-qa-hook="stepName">
        <StepLabel taskType={taskType} /> {taskName}
      </h1>
      <a data-qa-hook="backToMyTraining" onClick={handleBackButton}>
        <i className="fas fa-chevron-left"></i>
      </a>
      <a data-qa-hook="openMobileTrainingSlideMenu" onClick={() => toggleMobileTrainingMenu(dispatch, true)}>
        <i className="fas fa-bars"></i>
      </a>
    </Header>
  );
}

StepHeader.propTypes = {
  setMarginTop: PropTypes.func,
};
