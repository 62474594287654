import React from 'react';
import styled from 'styled-components';
import { Grid } from '../../StyleGuide';
import { SortableCourseStepListItem, SortableDragHandle } from '../components/SortableCourseStepList';
import { ButtonGroup, SmallRoundedIconButton } from '../../components/Buttons';
import { CourseStep } from '../models/course';
import StepIcons from '../../StepIcons';
import { SortableElementProps } from 'react-sortable-hoc';

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 18px;
  }
`;

const TaskDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Grid._2};
  flex: 1;

  h4,
  p {
    margin: 0;
  }
`;

type StepIconType = keyof typeof StepIcons;

type Props = SortableElementProps & {
  step: CourseStep;
  onClick: () => void;
  onRemove: () => void;
};

export const CourseStepListItem = ({ step, onRemove, ...props }: Props) => {
  return (
    <SortableCourseStepListItem {...props}>
      <IconContainer>
        <i className={StepIcons[step._type as StepIconType]}></i>
      </IconContainer>

      <TaskDetails>
        <h4>{step.taskName}</h4>
        <p>{step._type}</p>
      </TaskDetails>

      <ButtonGroup>
        <SortableDragHandle />

        <SmallRoundedIconButton aria-label="remove" onClick={onRemove}>
          <i className="far fa-trash-alt remove-icon" data-qa-hook="removeTaskIcon"></i>
        </SmallRoundedIconButton>
      </ButtonGroup>
    </SortableCourseStepListItem>
  );
};
