import axios from 'axios';
import apiClient from './apiClient';
import submitCommand from './submitCommand';

const API_BASE_URL = getApiUrl(); // todo: improve config injection

let cancelToken;

const buildKeyName = (id, fileName) => {
  return id + (fileName.substring(fileName.lastIndexOf('.')) || fileName).toLowerCase();
};

const upload = (location, key, file, onProgress = () => {}, useCancelToken = false) => {
  return apiClient.get(`${API_BASE_URL}/${location}/upload-url?key=${key}`).then(response => {
    const requestData = {
      headers: { 'Content-Type': file.type !== '' ? file.type : 'application/octet-stream' },
      onUploadProgress: onProgress,
    };

    if (useCancelToken) {
      cancelToken = axios.CancelToken.source();
      requestData.cancelToken = cancelToken.token;
    }

    return axios.put(response.data.url, file, requestData);
  });
};

const uploadImage = (file, key, subdir = '', onProgress = () => {}) => {
  return upload('v1/storage/images' + subdir, key, file, onProgress);
};

const uploadOrgFile = (id, orgId, fileName, file, forTask, userId, onProgress) => {
  fileName = fileName.toLowerCase();
  let cmd = { id: id, orgAccountId: orgId, fileName: fileName, forTask: forTask, initiatingUserId: userId };
  return upload('v1/storage/files', buildKeyName(id, fileName), file, onProgress, true).then(() =>
    submitCommand(id, cmd, 'UpdateFileMetadata', 'FileMetadataUpdated')
  );
};

const uploadOrgLogo = (orgId, file) => uploadImage(file, `${orgId}org-icon.png`);

const uploadProfileImage = (userId, image) => uploadImage(image, `${userId}profile.png`);

const cancel = str => cancelToken.cancel(str);

module.exports = {
  uploadImage,
  uploadFile: uploadOrgFile,
  uploadOrgLogo,
  uploadProfileImage,
  upload,
  cancel,
};
