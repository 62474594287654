import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Breakpoints, Grid } from '../StyleGuide';
import { useUser } from '../authentication';
import { Container } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import Breadcrumbs from '../components/Breadcrumbs';
import RoleSettings from './RoleSettings';
import windowService from '../services/windowService';
import orgService from '../services/orgService';
import RolePeople from './RolePeople';
import SettingsHeader from '../components/SettingsHeader';
import GroupContainer from '../groups/GroupContainer';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import useTextFilter from '../hooks/useTextFilter';
import AccessDenied from '../components/AccessDenied';

const CustomContainer = styled.div`
  margin-top: ${Grid._7};
  padding: 0;
`;

const CustomGroupContainer = styled(GroupContainer)`
  .group-people-list_quick-actions {
    margin-top: ${Grid._4};
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      margin-top: 0px;
    }
  }
`;

const Tabs = ({ activeTab, setActiveTab }) => {
  return (
    <ul className="group-page-tabs">
      <li className={activeTab === 'members' ? 'active-tab' : ''}>
        <button data-qa-hook="roleDetailMembers" onClick={() => setActiveTab('members')}>
          <span className="fas fa-user-friends fa-fw"></span> Members
        </button>
      </li>
      <li className={activeTab === 'settings' ? 'active-tab' : ''}>
        <button data-qa-hook="roleDetailSettings" onClick={() => setActiveTab('settings')}>
          <span className="fas fa-cog fa-fw"></span> Settings
        </button>
      </li>
    </ul>
  );
};

Tabs.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
};

export default function ManageRole() {
  const user = useUser();
  const [activeTab, setActiveTab] = useState('members');
  const [data, setData] = useState({ isLoading: true });
  const { role, isLoading, isError } = data;
  const orgId = user.lastSelectedAccount;
  const roleId = windowService.getRoutePart(2);
  const [permissions, setPermissions] = useState();
  const [unfilteredMembers, setUnfilteredMembers] = useState();
  const { results, query, setQuery } = useTextFilter(unfilteredMembers, [
    'displayName',
    'emailAddress',
    'firstName',
    'lastName',
    'email',
  ]);

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Roles', route: '#/roles' },
    { name: role?.name },
  ];

  useEffect(() => {
    orgService
      .getRole(orgId, roleId)
      .then(role => {
        setData({ role: role, isLoading: false });
        setPermissions(role.permissions);
        setUnfilteredMembers(role.usersInRole);
      })
      .catch(reason => {
        setData({ isLoading: false, isError: true });
        // eslint-disable-next-line no-unreachable, no-console
        console.error(reason);
      });
  }, [orgId, roleId]);

  const hasViewPermission = useMemo(() => {
    return user?.roles?.some(role => role.id.id === 'AdminId');
  }, [user]);

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <GroupContainer>
        <Container>
          {activeTab === 'members' && (
            <HelpAndInfoBox
              title={'Did You Know?'}
              description={
                'Here you may manage this role by adding and removing members. Use the Settings tab to change the name of the role or edit the permissions of the role.'
              }
            />
          )}
          {!isLoading && (
            <SettingsHeader
              data-qa-hook="SettingsHeader"
              title={role?.name}
              subtitle="Role"
              icon={<i className="fas fa-tags fa-fw" />}
            />
          )}
        </Container>
      </GroupContainer>
      {hasViewPermission ? (
        <CustomContainer>
          <CustomGroupContainer>
            {isLoading ? (
              <LoadingState />
            ) : isError ? (
              <ErrorMessage>
                A problem occurred showing this page. Please refresh the page to try again.{' '}
                <a href="#/help">Contact Us</a>
              </ErrorMessage>
            ) : (
              <>
                <Tabs setActiveTab={setActiveTab} activeTab={activeTab} />
                <section>
                  {activeTab === 'settings' && (
                    <RoleSettings
                      user={user}
                      orgId={orgId}
                      roleId={roleId}
                      permissions={permissions}
                      setPermissions={setPermissions}
                      role={role}
                      setData={setData}
                      setActiveTab={setActiveTab}
                      data={data}
                    />
                  )}
                  {activeTab === 'members' && results && (
                    <RolePeople
                      filteredMembers={results}
                      unfilteredMembers={unfilteredMembers}
                      query={query}
                      setQuery={setQuery}
                      role={role}
                      orgId={orgId}
                      initiatingUser={user}
                      setUnfilteredMembers={setUnfilteredMembers}
                    />
                  )}
                </section>
              </>
            )}
          </CustomGroupContainer>
        </CustomContainer>
      ) : (
        <AccessDenied />
      )}
    </>
  );
}
