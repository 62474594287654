import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../components/ModalElements';
import { MediumButton, MediumPrimaryButton } from '../components/Buttons';

import alertService from '../services/AlertService';
import assessmentService from '../services/assessmentService';

import { useUser } from '../authentication';

const PublishAssessmentModal = ({ assessmentId, setPublishAssessmentModalOpen, setAssessment }) => {
  const user = useUser();
  const [status, setStatus] = useState({ isSubmitting: false });

  const publishAssessment = () => {
    setStatus({ isSubmitting: true });

    assessmentService
      .publishAssessment(assessmentId, user.userId)
      .then(() => {
        setAssessment(prev => ({
          ...prev,
          published: true,
        }));
        setPublishAssessmentModalOpen(false);
        alertService.show('Assessment Published');
      })
      .catch(error => {
        console.error(error);
        setPublishAssessmentModalOpen(false);
        alertService.showError();
      });
  };

  return (
    <ModalContainer dismissHandler={() => setPublishAssessmentModalOpen(false)}>
      <ModalHeader data-qa-hook="publishAssessmentModal">
        <h3>Publish Assessment</h3>
      </ModalHeader>
      <ModalBody>
        <p>Are you sure you want to publish this assessment?</p>
        <p>
          <strong>
            Any changes made to this assessment after publishing can affect the consistency of reports when comparing
            answers before and after the change.
          </strong>
        </p>
        <p className="help-block">
          <i className="icon ion-android-alert"></i> Be sure to proofread your assessment for spelling and answer errors
        </p>
        <p className="help-block">
          <i className="icon ion-android-alert"></i> This action cannot be undone
        </p>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton
          data-qa-hook="continue"
          disabled={status.isSubmitting}
          operating={status.isSubmitting}
          onClick={() => publishAssessment()}
        >
          Yes, Publish
        </MediumPrimaryButton>
        <MediumButton data-qa-hook="cancel" onClick={() => setPublishAssessmentModalOpen(false)}>
          No
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

PublishAssessmentModal.propTypes = {
  setPublishAssessmentModalOpen: PropTypes.func.isRequired,
  assessmentId: PropTypes.string.isRequired,
  setAssessment: PropTypes.func.isRequired,
};

export default PublishAssessmentModal;
