import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Grid, Color, Type, Border, Transition, Breakpoints } from '../StyleGuide';
import SessionTimelineStatusBadge from '../curriculum/timeline/SessionTimelineStatusBadge';

export const CalendarHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: ${Grid._11};
  margin-bottom: ${Grid._4};
  h2,
  h4 {
    margin: 0;
  }
`;

export const QuarterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const buildStatusIndicator = timelines => {
  if (!timelines?.length) return <p data-qa-hook="statusBadge">Add Session</p>;

  if (timelines.length > 1) return <p>{timelines.length} Timelines</p>;

  return <SessionTimelineStatusBadge status={timelines[0].status.status} />;
};

export const Quarter = ({ quarter, onDateClick }) =>
  quarter.map(month => (
    <MonthContainer data-qa-hook="monthContainer" key={month.name}>
      <h3>{month.name}</h3>
      <Days>
        {month.sundays.map((sunday, di) => (
          <DateContainer
            data-qa-hook={`${sunday.date.toString().substring(4, 15)}`}
            key={`date-${di}`}
            date={sunday.date}
            onClick={() => onDateClick(sunday.date, sunday.session, sunday.session?.timelines[0])}
          >
            {sunday.date && (
              <>
                <h5>SUN</h5>
                <Day>{format(sunday.date, 'd')}</Day>
                {buildStatusIndicator(sunday.session?.timelines)}
              </>
            )}
          </DateContainer>
        ))}
      </Days>
    </MonthContainer>
  ));

const MonthContainer = styled.div`
  width: 100%;
  border: 1px solid ${Color.Gray._30};
  border-top-right-radius: ${Border.radius};
  border-top-left-radius: ${Border.radius};
  text-align: center;
  margin-bottom: ${Grid._5};
  h3 {
    background-color: ${Color.Gray._30};
    margin: 0;
    padding: ${Grid._3};
  }
`;

const Days = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
  }
`;

const DateContainer = styled.div`
  flex: 1;
  padding: ${Grid._5};
  background-color: ${props => (props.date ? Color.white : Color.Gray._10)};
  transition: ${Transition.fast};
  &:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid ${Color.Gray._30};
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      border-right: 1px solid ${Color.Gray._30};
      border-bottom: none;
    }
  }
  h5 {
    margin: 0;
  }
  p {
    color: ${Type.Color.info};
  }
  &:hover {
    background-color: ${Color.Gray._10};
    cursor: pointer;
  }
`;

const Day = styled.h4`
  font-weight: ${Type.Weight.semibold};
  font-size: ${Type.Scale._8};
  margin: ${Grid._3} 0;
`;
