angular.module('lwNamb').filter('stripStyles', function() {
  return function($html) {
    //Strip Styles on Paste
    var walk_the_DOM = function walk(node, func) {
      func(node);
      node = node.firstChild;
      while (node) {
        walk(node, func);
        node = node.nextSibling;
      }
    };
    var wrapper = document.createElement('div');
    wrapper.innerHTML = $html;
    walk_the_DOM(wrapper, function(el) {
      if (el.removeAttribute) {
        el.removeAttribute('style');
        el.removeAttribute('class');
        el.removeAttribute('color');
        el.removeAttribute('href');
      }
    });

    var result = wrapper.innerHTML;
    //Strip span tags that Word might add
    result = result.replace(/<\/?span[^>]*>/g, '');
    return result;
  };
});
