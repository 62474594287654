import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BibleStudyCard from './BibleStudyCard';
import BibleStudyHeaderImg from './BibleStudyHeaderImg';
import BibleStudyDescription from './BibleStudyDescription';
import BibleStudyAgeGroups from './BibleStudyAgeGroups';
import { CurriculumBrandWrapper } from '../contexts/CurriculumContext';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 24px;
`;

export default function BibleStudyCards({
  BibleStudies,
  subscribedCurriculumIds = [],
  recentlyPairedCurriculumIds = [],
}) {
  return (
    <Grid>
      {BibleStudies.map(bs => (
        <CurriculumBrandWrapper key={bs.brand.code} brandCode={bs.brand.code}>
          {({ brand }) => (
            <BibleStudyCard backgroundColor={brand.background}>
              <BibleStudyHeaderImg src={brand.imgSrc} alt={brand.name} />
              <BibleStudyDescription theme={brand.theme}>{brand.description}</BibleStudyDescription>
              <BibleStudyAgeGroups
                brand={brand}
                ages={bs.curriculum}
                subscribedCurriculumIds={subscribedCurriculumIds}
                recentlyPairedCurriculumIds={recentlyPairedCurriculumIds}
              />
            </BibleStudyCard>
          )}
        </CurriculumBrandWrapper>
      ))}
    </Grid>
  );
}

BibleStudyCards.propTypes = {
  BibleStudies: PropTypes.array.isRequired,
  subscribedCurriculumIds: PropTypes.array.isRequired,
  recentlyPairedCurriculumIds: PropTypes.array.isRequired,
};
