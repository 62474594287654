import React from 'react';
import styled from 'styled-components';
import { Color, Border } from '../StyleGuide';
import apiClient from '../services/apiClient';

const ChurchImageContainer = styled.div`
  background: ${Color.Gray._10};
  border-radius: ${Border.radius};
  height: 77px;
  width: 77px;

  img {
    height: 100%;
    width: auto;
  }
`;

const DefaultChurchIconContainer = styled(ChurchImageContainer)`
  display: grid;
  place-content: center;
  border: 1px solid ${Color.Gray._30};

  img {
    height: 36px;
    width: auto;
  }
`;

type ChurchImageProps = {
  name: string;
  logo: string;
};

export const ChurchImage = ({ name, logo }: ChurchImageProps) =>
  logo ? (
    <ChurchImageContainer>
      <img alt={name} src={`${apiClient.getConfig().baseURL}/v1/images/org/${logo}`} />
    </ChurchImageContainer>
  ) : (
    <DefaultChurchIcon altText={name || ''} />
  );

type DefaultChurchIconProps = {
  altText: string;
};

export const DefaultChurchIcon = ({ altText = '' }: DefaultChurchIconProps) => (
  <DefaultChurchIconContainer>
    <img alt={altText} src="/img/church.svg" />
  </DefaultChurchIconContainer>
);
