import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PrimaryButton, Button, TransparentButton } from '../components/Buttons';
import { Grid, Breakpoints } from '../StyleGuide';
import capitalizeFilter from '../filters/capitalize-filter';
import OrgRequiredModal from './OrgRequiredModal';
import UnlockTrainingModal from './UnlockTrainingModal';
import CloneTrainingModal from './CloneTrainingModal';
import EasyLinkModal from '../components/EasyLinkModal';
import windowService from '../services/windowService';
import { useUser } from '../authentication';
import useOrg from '../hooks/useOrg';
import WhiteLabel from '../filters/WhiteLabel';
import orgService from '../services/orgService';
import trainingService from '../services/trainingService';
import alertService from '../services/AlertService';
import { purchaseLockedItem } from '../utils/purchaseUtils';

const Buttons = styled.div`
  margin-top: ${Grid._5};
  display: flex;
  flex-direction: column;
  button {
    margin: 0 0 ${Grid._4} 0;
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin-top: 0;
    flex-direction: row;
    flex-wrap: wrap;
    button {
      margin: ${Grid._5} ${Grid._4} 0 0;
    }
  }
`;

export const TakeTrainingButton = ({ training, trainingProgress }) => {
  const progressType = trainingProgress.progress?._type;
  const instanceId = trainingProgress.instanceId;
  const stepId = trainingProgress.stepId;

  const actionText = progressType === 'Started' ? 'Resume' : progressType === 'Finished' ? 'View' : 'Start';

  const assigned = !!instanceId;
  const route = assigned
    ? `#/training-steps/${instanceId}?stepId=${stepId}${training.type === 'Pathway' ? '&isWorkflow=true' : ''}`
    : `#/add-to-your-training/${training.id}?noLogout=1&reassign=1`;

  return (
    <PrimaryButton data-qa-hook="searchPreviewTrainingMyTasks" onClick={() => windowService.redirectTo(route)}>
      {actionText} {capitalizeFilter(WhiteLabel.labels[training.type.toLowerCase()])}
    </PrimaryButton>
  );
};

export default function TrainingButtons({ training, redirectToLifeWayLoginFromLoggedOutState }) {
  const user = useUser();
  const org = useOrg();
  const [showOrgRequired, setShowOrgRequired] = useState(false);
  const [showUnlockTraining, setShowUnlockTraining] = useState(false);
  const [showCloneTraining, setShowCloneTraining] = useState(false);
  const [showEasyLink, setShowEasyLink] = useState(false);
  const [easyLinkPrefix, setEasyLinkPrefix] = useState();
  const [nextAction, setNextAction] = useState({});
  const hasReports =
    (training.type === 'Course' && training.reports.length) ||
    (training.type === 'Pathway' && training.entities.some(c => c.taskList?.reports.length));
  const trainingItemNumber = training.itemNumber?.value || '';

  const purchaseLockedTraining = itemNumber => purchaseLockedItem(itemNumber) || alertService.showError();

  const CustomizeTrainingButton = () => {
    return user.hasBuildPermission ? (
      <TransparentButton data-qa-hook="copyTrainingCopy" onClick={() => setShowCloneTraining(true)}>
        <i className="fas fa-edit"></i>Customize
      </TransparentButton>
    ) : (!user.hasBuildPermission && user.isAdmin) || (user.hasIndividualGridSubscription && user.isAdmin) ? (
      <UnlockButton transparent>
        <i className="fas fa-edit"></i>Customize
      </UnlockButton>
    ) : (
      ''
    );
  };

  const PromptButton = props =>
    props.primary ? (
      <PrimaryButton data-qa-hook="unlockButton" onClick={props.onClick}>
        {props.children}
      </PrimaryButton>
    ) : props.transparent ? (
      <TransparentButton data-qa-hook="unlockButton" onClick={props.onClick}>
        {props.children}
      </TransparentButton>
    ) : (
      <Button data-qa-hook="unlockButton" onClick={props.onClick}>
        {props.children}
      </Button>
    );

  const OrgRequiredButton = props => (
    <PromptButton {...props} onClick={() => setShowOrgRequired(true)}>
      {props.children}
    </PromptButton>
  );

  const UnlockButton = props => (
    <PromptButton {...props} onClick={() => setShowUnlockTraining(true)}>
      {props.children}
    </PromptButton>
  );

  const UnlockOrTakeTrainingButton = ({ canTake }) => {
    return canTake ? (
      <TakeTrainingButton training={training} trainingProgress={nextAction} />
    ) : (
      <UnlockButton primary>Start {capitalizeFilter(WhiteLabel.labels[training.type.toLowerCase()])}</UnlockButton>
    );
  };

  useEffect(() => {
    if (user && org) {
      orgService
        .getEasyLink(org.id)
        .then(res => setEasyLinkPrefix(res.prefix))
        .catch(err => console.error(err));
    }
  }, []);

  useEffect(() => {
    trainingService
      .getNextAction(training.type, training.id)
      .then(setNextAction)
      .catch(reason => {
        console.error(reason);
      });
  }, []);

  return (
    <Buttons>
      {!user ? (
        <PrimaryButton data-qa-hook="logInToStart" onClick={() => redirectToLifeWayLoginFromLoggedOutState()}>
          Log In To Start
        </PrimaryButton>
      ) : training.locked && !training.isIncludedWithGridSubscription ? (
        <PrimaryButton data-qa-hook="purchaseToUnlockButton" onClick={() => purchaseLockedTraining(trainingItemNumber)}>
          Purchase to Unlock
        </PrimaryButton>
      ) : user.canAccess ? (
        <>
          <UnlockOrTakeTrainingButton canTake={user.canTake} />
          <div>
            {user.canAssign && (
              <Button data-qa-hook="shareEasyLink" onClick={() => setShowEasyLink(true)}>
                Assign by Easy Link
              </Button>
            )}
            {user.canAssign && (
              <Button
                data-qa-hook="searchPreviewTrainingAssign"
                onClick={() => windowService.redirectTo(`#/assign-training/${training.id}`)}
              >
                Assign by Email
              </Button>
            )}
            {!hasReports && <CustomizeTrainingButton />}
          </div>
        </>
      ) : !user.canAccess ? (
        <>
          <UnlockOrTakeTrainingButton canTake={user.canTake} />
          {training.searchAccess === 'Free' && !org ? (
            <div>
              <OrgRequiredButton>Assign by Easy Link</OrgRequiredButton>
              <OrgRequiredButton>Assign by Email</OrgRequiredButton>
              <UnlockButton transparent>
                <i className="fas fa-edit"></i>Customize
              </UnlockButton>
            </div>
          ) : (
            (!org || user.isAdmin) && (
              <div>
                <UnlockButton>Assign by Easy Link</UnlockButton>
                <UnlockButton>Assign by Email</UnlockButton>
                <UnlockButton transparent>
                  <i className="fas fa-edit"></i>Customize
                </UnlockButton>
              </div>
            )
          )}
        </>
      ) : (
        ''
      )}
      {showOrgRequired && <OrgRequiredModal handleDismiss={() => setShowOrgRequired(false)} />}
      {showUnlockTraining && (
        <UnlockTrainingModal itemNumber={trainingItemNumber} handleDismiss={() => setShowUnlockTraining(false)} />
      )}
      {showCloneTraining && (
        <CloneTrainingModal training={training} user={user} handleDismiss={() => setShowCloneTraining(false)} />
      )}
      {showEasyLink && (
        <EasyLinkModal
          link={{ orgPrefix: easyLinkPrefix, groupCode: training.id }}
          description={`
                <div>
                  <h4>
                    Anyone who uses this link will automatically be assigned <b>${training.name}</b> and added to your organization.
                  </h4>
                  <h4>
                    Please share this link <b>directly</b> with church members. You can send it many ways including:
                  </h4>
                  <ul style="margin-left: 16px;">
                    <li>Text Messages</li>
                    <li>Facebook and Facebook Messenger</li>
                    <li>Church Management Systems</li>
                    <li>Email</li>
                    <li>A Special Page on Your Website</li>
                  </ul>
                </div>
              `}
          handleDismiss={() => setShowEasyLink(false)}
        />
      )}
    </Buttons>
  );
}

TrainingButtons.propTypes = {
  training: PropTypes.object.isRequired,
  redirectToLifeWayLoginFromLoggedOutState: PropTypes.func,
};
