angular.module('lwNamb').directive('taskViewerUpload', [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'partials/directives/task-viewer-upload.html',
      scope: {
        task: '=',
        taskListId: '@',
        isTaker: '=?',
        colClass: '@',
      },
      replace: true,
      controller: [
        '$scope',
        'taskListService',
        'windowService',
        'uploadService',
        'userService',
        'fileUrlService',
        'uuid4',
        'apiUrlService',
        '$log',
        'alertService',
        function(
          $scope,
          taskListService,
          windowService,
          uploadService,
          userService,
          fileUrlService,
          uuid4,
          apiUrlService,
          $log,
          alertService
        ) {
          $scope.spinner = false;
          $scope.enableUpload = false;
          $scope.enableMetaData = false;

          var loadData = function() {
            userService.user().then(
              function(user) {
                $scope.user = user;
                if ($scope.task.status === 'complete') {
                  fileUrlService.getFileMetaData($scope.task.task.uploadedAssetUrl).then(
                    function(response) {
                      var metaData = response.fileMetaData;
                      $scope.metaData = metaData;
                      $scope.task.fileName = metaData.fileName;
                    },
                    function(response) {
                      $log.error(response);
                    }
                  );
                }
              },
              function() {
                $log.error('No user returned in manageAssignmentDetailCtrl');
              }
            );
          };

          $scope.uploadFile = function(file) {
            if (file) {
              $scope.uploading = true;
              $scope.enableMetaData = true;
              $scope.assetId = uuid4.generate();
              $scope.currentFilename = file.name;

              uploadService
                .uploadFile($scope.assetId, $scope.user.lastSelectedAccount, file.name, file, true, $scope.user.userId)
                .then(
                  function(file) {
                    $scope.uploading = false;
                    $scope.uploadComplete = true;
                  },
                  function(reason) {
                    $log.error(reason);
                    alertService.show({
                      title: 'Unexpected Error!',
                      content: 'Please try uploading again.',
                      type: 'danger danger-with-content',
                      duration: 20,
                    });
                  }
                );
            }
          };

          $scope.completeTask = function(task, tasklistId) {
            $scope.spinner = true;
            taskListService.setAssetIdInUploadTask(tasklistId, task.assigneeId.id, task.id.id, $scope.assetId).then(
              function() {
                var d = new Date();
                windowService.redirectHash('#/task-viewer/' + tasklistId + '?t=' + d.getTime());
              },
              function(reason) {
                $log.error(reason);
                alertService.show({
                  title: 'Unexpected Error!',
                  content: 'Please try again.',
                  type: 'danger danger-with-content',
                  duration: 20,
                });
              }
            );
          };

          $scope.cancelUpload = function() {
            $log.debug('Upload Cancelled!');
            $scope.uploading = false;
            $scope.currentFilename = undefined;
            $scope.uploadComplete = false;
            $scope.enableMetaData = false;
            uploadService.cancelUpload();
          };

          $scope.replaceFile = function() {
            $scope.enableUpload = true;
          };

          $scope.downloadFile = function() {
            fileUrlService.openFile($scope.metaData._id.id, $scope.metaData.fileName);
          };

          loadData();
        },
      ],
    };
  },
]);
