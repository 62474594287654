import React from 'react';
import { User } from '../domain/User';

type UserProviderProps = {
  user: User;
  children: React.ReactNode;
};

export const UserContext = React.createContext<User | undefined>(undefined);
UserContext.displayName = 'UserContext';

export const UserProvider = ({ user, children }: UserProviderProps) => (
  <UserContext.Provider value={user}>{children}</UserContext.Provider>
);
