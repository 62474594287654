angular.module('lwNamb').directive(
  'taskViewerLink',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/task-viewer-link.html',
        scope: {
          task: '=',
          taskStatus: '=?',
          isTaker: '=?',
          isPreview: '=?',
          colClass: '@',
        },
        replace: true,
        controller: [
          '$scope',
          'windowService',
          function($scope, windowService) {
            $scope.openLink = function() {
              windowService.openUrl($scope.task.linkUrl);
            };
          },
        ],
      };
    },
  ]
);
