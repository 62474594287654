angular.module('lwNamb').factory('fullstoryService', [
  '$window',
  '$log',
  function ($window, $log) {
    return {
      identify: function (userId, email, firstName, lastName) {
        try {
          FS.identify(userId.toUpperCase(), {
            displayName: firstName + ' ' + lastName,
            email: email,
          });
        } catch (e) {}
      },
      shutdown: function () {
        try {
          FS.shutdown();
        } catch (e) {}
      },
    };
  },
]);
