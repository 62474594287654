import React, { useState } from 'react';
import styled from 'styled-components';
import { Breakpoints, Color, Grid, Type } from '../../StyleGuide';
import { useUser } from '../../authentication';
import { SmallOutlineButton } from '../../components/Buttons';
import { InlineList, RaisedContainer } from '../../components/Layout';
import alertService from '../../services/AlertService';
import { handleError } from '../../utils/apiUtils';
import { resendEmailInvite } from '../api/resendEmailInvite';
import { Invitee, Redemption } from '../models/invite';
import { Status } from '../models/status';

const Container = styled(RaisedContainer)`
  display: flex;
  justify-content: space-between;
  padding: ${Grid._4};
  width: 100%;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${Grid._3};

  h4,
  p {
    margin: 0;
  }
`;

const Status = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: ${Grid._3};

  p {
    margin: 0;
  }
`;

const AcceptedStatus = styled(Status)`
  p: first-child {
    color: ${Color.Green._50};
  }
`;

const DisabledStatus = styled(Status)`
  p: first-child {
    color: ${Color.Gray._50};
  }
`;

const ResendInviteButton = styled(SmallOutlineButton)`
  padding: ${Grid._2} ${Grid._3};
`;

export const RecipientList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Grid._2};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 1fr 1fr;
  }
`;

type Props = {
  inviteId: string;
  orgId: string;
  recipient: Invitee | Redemption;
};

const isInvitee = (recipient: Invitee | Redemption): recipient is Invitee => 'status' in recipient;

export const RecipientListItem = ({ inviteId, orgId, recipient }: Props) => {
  const user = useUser();
  const [resendingInvite, setResendingInvite] = useState(false);

  const resendInvite = () => {
    setResendingInvite(true);
    resendEmailInvite(inviteId, orgId, recipient.email, user?.userId)
      .then(() => alertService.show('Invitation Resent'))
      .catch(handleError)
      .finally(() => setResendingInvite(false));
  };

  return (
    <Container>
      <Details>
        <h4>{recipient.fullName}</h4>
        <InlineList>{recipient.email}</InlineList>
      </Details>

      {isInvitee(recipient) ? (
        <>
          {recipient.status === 'Accepted' ? (
            <AcceptedStatus>
              <p>{recipient.status}</p>
              {recipient.redeemedAs !== recipient.email && <p>as {recipient.redeemedAs}</p>}
            </AcceptedStatus>
          ) : recipient.status === 'Disabled' ? (
            <DisabledStatus>
              <p>{recipient.status}</p>
            </DisabledStatus>
          ) : (
            <Status>
              <p>{recipient.status}</p>
              {recipient.status === 'Pending' && (
                <ResendInviteButton
                  onClick={resendInvite}
                  operating={resendingInvite}
                  disabled={resendingInvite}
                  loadingIndicatorColor={Type.Color.dark}
                >
                  Resend Invite
                </ResendInviteButton>
              )}
            </Status>
          )}
        </>
      ) : (
        <AcceptedStatus>
          <p>Redeemed</p>
        </AcceptedStatus>
      )}
    </Container>
  );
};
