angular.module('lwNamb').controller(
  'AccountProfileCtrl',

  [
    '$rootScope',
    '$scope',
    '$log',
    'userService',
    'profileService',
    'alertService',
    'licenseService',
    '$localStorage',
    function($rootScope, $scope, $log, userService, profileService, alertService, licenseService, $localStorage) {
      var init = function() {
        $rootScope.showClassicTheme = $scope.showClassicTheme = $localStorage.showClassicTheme = Boolean(
          $localStorage.showClassicTheme
        );
        userService.user().then(
          function(user) {
            $scope.localUser = user;
            $scope.retrieveUserData();
          },
          function() {
            $log.error('No user returned in AccountProfileCtrl');
          }
        );
      };

      $scope.toggleBetaTheme = function() {
        $rootScope.showClassicTheme = $scope.showClassicTheme = $localStorage.showClassicTheme = Boolean(
          !$localStorage.showClassicTheme
        );
      };

      $scope.retrieveUserData = function() {
        profileService.getProfile($scope.localUser.userId).then(
          function(user) {
            $scope.user = user;
            $scope.user.form = {};
            profileService.getCustomData($scope.localUser.lastSelectedAccount, $scope.localUser.logInEmail).then(
              function(response) {
                $scope.user.form.orgFields = response;
              },
              function(reason) {
                $log.error(reason);
              }
            );
          },
          function(reason) {
            $log.error(reason);
          }
        );

        getPreferences();

        licenseService.getIndividualLicenses($scope.localUser.userId).then(
          function(licenses) {
            $scope.individualLicenses = licenses;
          },
          function(reason) {
            $log.error('Failed to retrieve Licenses in AccountProfile: ' + reason);
          }
        );
      };

      function getPreferences() {
        profileService.getPreferences().then(
          function(preferences) {
            $scope.showTips = preferences.showAllInformationBoxes;
          },
          function(reason) {
            $log.error('error looking up prefs: ' + reason);
            $scope.showTips = true;
          }
        );
      }

      $scope.savePreferences = function(showTips) {
        profileService.savePreferences($scope.localUser.userId, showTips).then(
          function() {
            alertService.show({ title: 'preferences saved', type: 'success', duration: 5 });
          },
          function(reason) {
            getPreferences();
            $log.error(reason);
            alertService.show({
              type: 'danger danger-with-content',
              title: 'Unexpected Error!',
              content: 'It seems something went wrong, please try again.',
              duration: 20,
            });
          }
        );
      };

      $scope.csCookieSet = function() {
        return document.cookie.indexOf('_cs_optout') >= 0;
      };

      $scope.optOutOfContentsquare = function() {
        var checkCSCookie = function(attemptsRemaining) {
          if (attemptsRemaining > 0) {
            setTimeout(function() {
              if ($scope.csCookieSet()) {
                alertService.show({ title: 'Opt-out Successful', type: 'success', duration: 5 });
              } else {
                checkCSCookie(attemptsRemaining - 1);
              }
            }, 500);
          } else {
            alertService.show({ title: 'Something went wrong. Please try again.', type: 'danger', duration: 20 });
          }
        };

        window._uxa.push(['optout']);
        checkCSCookie(5);
      };

      init();
    },
  ] //End Account Ctrl
);
