let cache = {};

const cacheService = {
  set: (key, value, minutes = 1) => {
    cache[key] = {
      value: value,
      maxAge: Date.now() + minutes * 60000, // milliseconds
    };
  },
  remove: key => delete cache[key],
  get: key => {
    if (cache[key] && cache[key].maxAge < Date.now()) {
      delete cache[key];
    }
    return cache[key] ? cache[key].value : cache[key];
  },
  removeAll: () => (cache = {}),
  removeAllInPath: path =>
    Object.keys(cache)
      .filter(x => x.indexOf(path) === 0)
      .forEach(cacheService.remove),
};

// Allow Angular to clear caches when necessary
if (window) {
  window.cacheService = cacheService;
}

export default cacheService;
