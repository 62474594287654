angular.module("lwNamb").controller(
  "AddToCtrl",

  [
    "$scope",
    "$log",
    "$routeParams",
    "userService",
    "windowService",
    "orgService",
    "orgUserService",
    "alertService",
    "groupService",
    "licenseService",
    "qt",
    "$localStorage",
    function (
      $scope,
      $log,
      $routeParams,
      userService,
      windowService,
      orgService,
      orgUserService,
      alertService,
      groupService,
      licenseService,
      qt,
      $localStorage
    ) {
      var init = function () {
        $scope.loading = true;
        $scope.selectedItems = [];
        $scope.members = [];
        $scope.failureAlert = undefined;
        $scope.maxAdd = $routeParams.maxAdd;
        $scope.type = $routeParams.type;

        $scope.customFields = [{ name: "All Fields" }];
        $scope.rolesDropdown = [];
        $scope.groups = [];
        $scope.clearInput();

        userService.user().then(
          function (user) {
            $scope.user = user;
            $scope.orgId = $scope.user.lastSelectedAccount;
            if ($scope.type === "group") {
              $scope.groupId = $routeParams.id;
              $scope.back = "/groups/" + $scope.groupId + "/" + $scope.orgId;
            } else if ($scope.type === "role") {
              $scope.roleId = $routeParams.id;
              $scope.back = "/roles/" + $scope.roleId;
            } else {
              $scope.licenseId = $routeParams.id;
              $scope.back = "/licenses/" + $scope.licenseId;
            }
            retrieveUserData();
          },
          function () {
            $log.error("No user returned in AddToCtrl");
          }
        );
      };

      var retrieveUserData = function () {
        orgService.dropCache($scope.orgId);
        orgService.getOrg($scope.orgId).then(
          function (org) {
            if ($scope.type === "role") {
              for (var i = 0; i < org.roles.length; i++) {
                if ($scope.roleId === org.roles[i].id.id) {
                  $scope.role = org.roles[i];
                }
              }
            } else {
              $scope.role = {};
            }
            $scope.rolesDropdown = org.rolesDropdown;
            lookupUsersForOrg();
          },
          function (response) {
            $scope.loading = false;
            $log.error("Error: Org not found: " + response);
          }
        );
      };

      function lookupUsersForOrg() {
        getMembersFilter($scope.type).then(function (result) {
          orgUserService.getMembers($scope.orgId, result.filter).then(
            function (members) {
              $scope.members = members.all.map(function (member) {
                if (member.email === $scope.user.logInEmail) {
                  member.isYou = true;
                }
                return member;
              });
              $scope.groups = $scope.groups.concat(members.groups);
              $scope.customFields = $scope.customFields.concat(members.filters);
              $scope.loading = false;
            },
            function (response) {
              $scope.loading = false;
              $log.error("Error: " + response);
            }
          );
        });
      }

      function getMembersFilter(addType) {
        var deferred = qt.defer();
        if (addType === "group") {
          deferred.resolve({
            filter: function (member) {
              return (
                !member.groups ||
                member.groups.findIndex(function (groupId) {
                  return groupId === $scope.groupId;
                }) === -1
              );
            },
          });
        } else if (addType === "role") {
          deferred.resolve({
            filter: function (member) {
              return (
                !member.roles ||
                member.roles.filter(function (role) {
                  return role.id === undefined ? false : role.id.id === $scope.role.id.id;
                }).length === 0
              );
            },
          });
        } else if (addType === "license" || addType === "license-remove") {
          licenseService.getLicense($scope.licenseId).then(function (license) {
            var occupantIds = license.seats.occupants.map(function (occupant) {
              return occupant.id;
            });
            deferred.resolve({
              filter: function (member) {
                if (member._id === undefined || (member._id && member._id.id.indexOf("@") > -1)) return false;
                var isSeated = member._id && occupantIds.includes(member._id.id);
                if (addType === "license") {
                  return !isSeated;
                } else {
                  // 'license-remove'
                  return isSeated;
                }
              },
            });
          });
        }

        return deferred.promise;
      }

      $scope.clearInput = function () {
        $scope.filter = {
          role: [],
          search: "",
          selectedCustomField: $scope.customFields[0],
          customSearch: "",
          group: [],
        };
      };

      $scope.assign = function (selectedItems) {
        $scope.spinner = true;
        orgService.dropCache($scope.orgId);

        if ($scope.type === "group") {
          groupService
            .addMember($scope.groupId, $scope.orgId, $scope.user.userId, selectedItems, $localStorage.groupName)
            .then(success, failure);
        } else if ($scope.type === "role") {
          orgService
            .assignRoleToUsers($scope.orgId, selectedItems, $scope.role, $scope.roleId, $scope.user.userId)
            .then(success, failure);
        } else if ($scope.type === "license") {
          licenseService
            .grantAccess($scope.licenseId, selectedItems, $scope.orgId, $scope.user.userId)
            .then(success, failure);
        }
      };

      $scope.remove = function (selectedItems) {
        $scope.spinner = true;
        licenseService
          .revokeAccess($scope.licenseId, selectedItems, $scope.orgId, $scope.user.userId)
          .then(success, failure);
      };

      var success = function () {
        $scope.spinner = false;
        windowService.redirectHash($scope.back);
      };

      var failure = function (response) {
        $scope.spinner = false;
        $log.error("Error: Assign " + $scope.type + " failed: " + response);
        alertService.show({
          type: "danger danger-with-content",
          title: "Unexpected Error!",
          content: "It seems something went wrong, please try again.",
          duration: 20,
        });
      };

      init();
    },
  ]
);
