angular.module('lwNamb').directive(
  'asideBox',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/aside-box.html',
        scope: {
          icon: '@',
          header: '@',
          message: '@',
          buttonText: '@',
          action: '&buttonAction',
        },
      };
    },
  ]
);
