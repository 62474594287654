import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';

import { Grid, Color, Type, Border } from '../StyleGuide';
import { MediumButton, MediumPrimaryButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';
import { handleError } from '../utils/apiUtils';
import trainingService from '../services/trainingService';
import alertService from '../services/AlertService';
import { RadioListWithDescription, Radio, DatePickerInput } from '../components/FormElements';

const SelectSessionDate = styled.div`
  label {
    cursor: default;
    color: ${Type.Color.dark};
    font-size: ${Type.Scale._1};
    font-weight: ${Type.Weight.bold};
    margin: ${Grid._4} 0 ${Grid._2};
  }
  input[type='text'] {
    font-size: ${Type.Scale._3};
    color: ${Type.Color.dark};
    border: 2px solid ${Color.Primary._50};
    border-radius: ${Border.radius};
    padding: ${Grid._3};
    :disabled {
      color: ${Type.Color.placeholder};
      background: ${Color.Gray._10};
      border-color: transparent;
    }
  }
  p {
    font-size: ${Type.Scale._1};
    margin-top: ${Grid._2};
  }
`;

const RadioListContainer = styled(RadioListWithDescription)`
  li:first-child {
    margin-bottom: ${Grid._7};
  }
`;

const FormRadioListItem = ({ label, children, ...props }) => {
  return (
    <li>
      <label>
        <Radio {...props} />
        {label}
      </label>
      {children}
    </li>
  );
};

FormRadioListItem.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const DueDateModal = ({ course, updateDueDate, handleDismiss, userId, firstAllowedDate }) => {
  const submitDueDate = ({ hasDueDate, dueDate }) => {
    const updateTrainingDueDate =
      course?.dueDate && !hasDueDate
        ? trainingService.unsetDueDate(course.taskListInstanceId.id, userId)
        : trainingService.setDueDate(course.taskListInstanceId.id, userId, dueDate);

    return updateTrainingDueDate
      .then(() => {
        alertService.show('Due Date Updated');
        updateDueDate(hasDueDate ? dueDate : null);
      })
      .catch(handleError)
      .finally(handleDismiss);
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>Schedule Due Date</h3>
      </ModalHeader>
      <Formik
        initialValues={{
          hasDueDate: !!course?.dueDate,
          dueDate: course?.dueDate || firstAllowedDate,
        }}
        onSubmit={submitDueDate}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form>
            <ModalBody preventScroll>
              <h3>{course.name}</h3>
              <RadioListContainer>
                <FormRadioListItem
                  data-qa-hook="radioScheduled"
                  name="hasDueDate"
                  onChange={() => setFieldValue('hasDueDate', true)}
                  checked={values.hasDueDate}
                  label="Schedule This Assignment"
                >
                  <SelectSessionDate>
                    <label htmlFor="selected-date">Due Date</label>
                    <DatePicker
                      data-qa-hook="datePicker"
                      customInput={<DatePickerInput />}
                      selected={values.dueDate}
                      onChange={date => setFieldValue('dueDate', date)}
                      minDate={new Date()}
                      disabled={!values.hasDueDate}
                    />
                  </SelectSessionDate>
                </FormRadioListItem>
                <FormRadioListItem
                  data-qa-hook="radioUnscheduled"
                  name="hasDueDate"
                  onChange={() => setFieldValue('hasDueDate', false)}
                  checked={!values.hasDueDate}
                  label="Don't Schedule This Assignment"
                >
                  <p>
                    This assignment will not have a date by which it must be completed. You can change this at any time
                    by choosing "Schedule Due Date".
                  </p>
                </FormRadioListItem>
              </RadioListContainer>
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton
                data-qa-hook="assignmentDetailDueDateSave"
                type="submit"
                disabled={isSubmitting}
                operating={isSubmitting}
              >
                Save
              </MediumPrimaryButton>
              <MediumButton onClick={handleDismiss}>Cancel</MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};

DueDateModal.propTypes = {
  course: PropTypes.object.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  firstAllowedDate: PropTypes.instanceOf(Date),
  updateDueDate: PropTypes.func,
};

export default DueDateModal;
