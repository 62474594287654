angular.module('lwNamb').controller('eventDispatchController', [
  '$scope',
  '$location',
  'eventDispatchService',
  'alertService',
  'toastService',
  function($scope, $location, eventDispatchService, alertService, toastService) {
    function create(userIdParam) {
      if (eventDispatchService.esFeed === undefined && userIdParam !== undefined) {
        eventDispatchService.createES(userIdParam);
      }
    }

    function idFromUserEvent(user) {
      return user !== undefined ? user.userId : undefined;
    }

    $scope.$on('CheckSocket', function(event, user) {
      create(idFromUserEvent(user));
    });

    $scope.$on('SOCKET_DISCONNECTED', function(event, userIdOnlyObj) {
      create(idFromUserEvent(userIdOnlyObj));
    });

    $scope.$on('UserLoggedIn', function(event, user) {
      create(idFromUserEvent(user));
    });

    $scope.$on('CreateSocketForEmail', function(event, email) {
      create(email);
    });

    $scope.$on('VideoIsViewable', function(event) {
      toastService.success('Your video is encoded and ready to be viewed.', 'Encoding Complete!');
    });

    $scope.$on('unauthorized', function() {
      eventDispatchService.shutdown();
    });
  },
]);
