import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Breakpoints, Color } from '../StyleGuide';

const StepContainer = styled.ul`
  background: ${Color.Gray._10};
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  background: lighten(@brand-success, 35%);
  font-size: 18px;
`;

const Step = styled.li`
  &:first-child {
    z-index: 2;
    margin-right: 5px;
    button {
      border-top-left-radius: 6px;
      &:before {
        border-left: none;
      }
    }
  }
  @media screen and (max-width: ${Breakpoints.screen_sm}) {
    flex-grow: 1;
    &:first-child {
      button {
        border-top-left-radius: 0;
        padding-right: 0;
      }
    }
    &:last-child {
      button:after {
        border-left: none;
      }
    }
  }
  button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 20px 8px 40px;
    font-weight: 500;
    height: 100%;
    width: 100%;
    position: relative;
    color: ${Color.white};
    background: ${Color.Gray._75};
    border: none;
    @media screen and (max-width: ${Breakpoints.screen_sm}) {
      padding: 8px 40px;
    }
    &:before,
    &:after {
      width: 0;
      height: 0;
      border-top: 29px inset transparent;
      border-bottom: 29px inset transparent;
      position: absolute;
      content: '';
      top: 0;
    }
    &:before {
      left: 0;
      border-left: 15px solid ${Color.Gray._10};
    }
    &:after {
      right: -15px;
      border-left: 15px solid ${Color.Gray._75};
    }
    span {
      font-weight: 300;
      font-size: 70%;
      text-transform: uppercase;
    }
    &.active {
      background: ${Color.Gray._90};
      color: #fff;
      &:after {
        border-left-color: ${Color.Gray._90};
      }
    }
  }
`;

const StepArrows = ({ steps, handleStepChange, currentStep, isDisabled }) => (
  <StepContainer>
    {steps.map((step, index) => (
      <Step key={step}>
        <button
          className={currentStep === step ? 'active' : ''}
          onClick={() => handleStepChange(step)}
          disabled={isDisabled}
        >
          <span>{`Step ${index + 1}`}</span>
          {step}
        </button>
      </Step>
    ))}
  </StepContainer>
);

StepArrows.propTypes = {
  steps: PropTypes.array.isRequired,
  handleStepChange: PropTypes.func,
  currentStep: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default StepArrows;
