import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Border, Color, Shadow, Type } from '../StyleGuide';

import { useSteps } from './StepsContext';
import { PrimaryButton, FullWidthButton } from '../components/Buttons';
import ModalBackground from '../components/ModalBackground';
import WhiteLabel from '../filters/WhiteLabel';

const Container = styled.div`
  padding: ${Grid._7} ${Grid._5};
  background-color: ${Color.white};
  box-shadow: ${Shadow.light};
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
  border-radius: ${Border.radius};
  h3 {
    font-size: ${Type.Scale._4};
    font-weight: ${Type.Weight.normal};
    color: ${Type.Color.dark};
    margin-top: ${Grid._4};
  }
  svg {
    height: 60px;
    width: 60px;
  }
`;

const ButtonsContainer = styled.div`
  margin: ${Grid._8} auto 0;
  max-width: 400px;
`;

export default function ForceCompleteModal({ setShowForceCompleteModal, forceComplete }) {
  const [{ currentStep }] = useSteps();
  const [numCompleteResponses] = useState(
    currentStep.task.feedbackResponses.filter(r => r.status === 'complete').length
  );
  const [numIncompleteResponses] = useState(currentStep.task.numEmailsToGather - numCompleteResponses);

  return (
    <ModalBackground dismissHandler={() => setShowForceCompleteModal(false)}>
      <Container data-qa-hook="forceCompleteModal">
        <h2>Mark as Complete</h2>
        <div>
          <p>
            <strong>
              Are you sure you want to complete this feedback {WhiteLabel.labels.step} with {numIncompleteResponses}{' '}
              incomplete response{numIncompleteResponses > 1 ? 's' : ''}?
            </strong>
          </p>
          <p>
            Force completing this {WhiteLabel.labels.step} will prevent any future invitations from being completed and
            will result in an incomplete and less accurate report for the church planting organization that is viewing
            your report.
          </p>
        </div>
        <ButtonsContainer className="grid-container grid-col-gap-24 grid-col-2">
          <PrimaryButton data-qa-hook="feedbackViewerCompleteYes" onClick={() => forceComplete()}>
            Yes, Complete
          </PrimaryButton>
          <FullWidthButton data-qa-hook="feedbackViewerCompleteNo" onClick={() => setShowForceCompleteModal(false)}>
            No
          </FullWidthButton>
        </ButtonsContainer>
      </Container>
    </ModalBackground>
  );
}

ForceCompleteModal.propTypes = {
  setShowForceCompleteModal: PropTypes.func,
  forceComplete: PropTypes.func,
};
