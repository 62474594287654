import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { MediumButton, MediumDangerButton, MediumPrimaryButton } from '../../components/Buttons';
import ErrorMessage from '../../components/ErrorMessage';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter, ModalWarningList } from '../../components/ModalElements';
import ConfirmationModal from '../../components/ConfirmationModal';
import { Label, DatePickerInput, FormField } from '../../components/FormElements';
import curriculumService from '../../services/curriculumService';
import alertService from '../../services/AlertService';
import windowService from '../../services/windowService';
import { handleError } from '../../utils/apiUtils';
import useCurriculum from '../../hooks/useCurriculum';
import { Grid } from '../../StyleGuide';

const SaveButton = styled(MediumPrimaryButton)`
  margin-bottom: 0 !important;
`;

const ModalFooterWithLeftAlignedButton = styled(ModalFooter)`
  justify-content: space-between;

  > div {
    display: flex;
    justify-content: flex-end;
    flex: 1 0 auto;
  }
`;

const RemoveUnitModal = ({ unitName, ...props }) => (
  <ConfirmationModal
    title="Remove Unit"
    prompt={<span>Are you sure you want to remove this unit?</span>}
    subtext={unitName}
    confirmText="Remove"
    buttonType="danger"
    buttonActionText="Remove"
    {...props}
  >
    <ModalWarningList
      warnings={[
        'All sessions associated with this unit will be deleted',
        'All Materials associated with this unit will be deleted',
        'This Action cannot be undone',
      ]}
    />
  </ConfirmationModal>
);

RemoveUnitModal.propTypes = {
  unitName: PropTypes.string.isRequired,
};

export default function ManageCurriculumUnitModal({ unit, curriculumId, handleDismiss, setUnitName }) {
  const { brand, ageCategory } = useCurriculum();
  const [isRemoving, setIsRemoving] = useState(false);
  const isEditing = !!unit?.id;

  const handleRemoveUnit = () => {
    return curriculumService
      .removeUnit(curriculumId, unit.id)
      .then(() => {
        alertService.showOnNextPage('Unit removed');
        windowService.redirectTo(`/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}`, true);
      })
      .catch(error => {
        handleError(error);
        handleDismiss();
      });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    if (isEditing) {
      return curriculumService
        .updateUnitName(curriculumId, unit.id, values.name)
        .then(() => {
          alertService.show('Unit Updated');
          setUnitName(values.name);
          handleDismiss();
        })
        .catch(error => {
          handleDismiss();
          handleError(error);
        });
    }
    return curriculumService
      .createUnit(curriculumId, values.name, `${format(values.firstUseDate, 'yyyy-MM-dd')}T00:00:00.000Z`)
      .then(({ id }) => {
        windowService.redirectTo(`/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${id}`);
      })
      .catch(error => {
        handleError(error);
        handleDismiss();
      });
  };

  return isRemoving ? (
    <RemoveUnitModal unitName={unit.name} handleSubmit={handleRemoveUnit} handleDismiss={handleDismiss} />
  ) : (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>{isEditing ? 'Edit' : 'Add'} Unit</h3>
      </ModalHeader>
      <Formik
        initialValues={{
          name: unit?.name ?? '',
          firstUseDate: unit?.firstUseDate ? new Date(unit.firstUseDate) : new Date(),
        }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          name: Yup.string().max(64, 'Name is too long').required('A name is required'),
          firstUseDate: Yup.date().required('A first use date is required'),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
          <Form id="unit-form">
            <ModalBody>
              <div style={{ margin: '32px 0' }}>
                <FormField data-qa-hook="unitName" label="Name" name="name" type="text" placeholder="Enter Name" />
                <Label htmlFor="selected-date">First Use Date</Label>
                <DatePicker
                  data-qa-hook="datePicker"
                  customInput={<DatePickerInput />}
                  selected={values.firstUseDate}
                  onChange={date => setFieldValue('firstUseDate', date)}
                  minDate={new Date()}
                  disabled={isEditing}
                  showDisabledMonthNavigation
                />
                {errors.firstUseDate && touched.firstUseDate && <ErrorMessage>{errors.firstUseDate}</ErrorMessage>}
              </div>
            </ModalBody>
            <ModalFooterWithLeftAlignedButton>
              {isEditing && <MediumDangerButton onClick={() => setIsRemoving(true)}>Remove Unit</MediumDangerButton>}
              <div style={{ gap: Grid._3 }}>
                <SaveButton data-qa-hook="unitConfirm" type="submit" disabled={isSubmitting} operating={isSubmitting}>
                  {isEditing ? 'Save' : 'Add'} Unit
                </SaveButton>
                <MediumButton data-qa-hook="unitCancel" onClick={handleDismiss}>
                  Cancel
                </MediumButton>
              </div>
            </ModalFooterWithLeftAlignedButton>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
}

ManageCurriculumUnitModal.propTypes = {
  unit: PropTypes.object,
  curriculumId: PropTypes.string.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  setUnitName: PropTypes.func,
};
