angular.module('lwNamb').directive(
  'videoPoster',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/video-poster.html',
        scope: {
          contentId: '@',
          userId: '@',
        },
        replace: true,
        controller: [
          '$scope',
          'uploadService',
          '$log',
          'alertService',
          'videoService',
          function(
            $scope,
            uploadService,
            $log,
            alertService,
            videoService
          ) {
            $scope.editPoster = false;

            var getPoster = function(){
              videoService.getVideo($scope.contentId).then(function(response) {
                $scope.video = response.video;
              },
              function(reason) {
                $log.error('Failed to get video poster: ' + reason);
              });
            };
            
            getPoster();
            
            $scope.uploadPoster = function(file) {
              if (!file) return;
              $scope.uploading = true;
              uploadService
                .uploadPoster($scope.contentId, $scope.userId, file.name, file)
                .then(
                  function() {
                    alertService.show({ 
                      title: 'Poster Image Saved',
                      content: 'Refresh your browser to see the changes.',
                      type: 'success',
                      duration: 5,
                    });
                    getPoster();
                  },
                  function(reason) {
                    alertService.show({
                      title: 'Unexpected Error!',
                      content: 'There was an error uploading your poster image.',
                      type: 'danger danger-with-content',
                      duration: 20,
                    });
                    $log.error('Failed to upload a poster image: ' + reason);
                  }
                )
                .finally(function() {
                  $scope.uploading = false;
                  $scope.editPoster = false;
                });
            };
          },
        ],
      };
    },
  ]
);
