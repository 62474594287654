import { AxiosResponse } from 'axios';
import cacheService from '../../services/cacheService';
import submitCommand from '../../services/submitCommand';
import { OrgUserResponse } from '../models/license';

export type GrantAccessResponse = {
  eventType: string;
  id: string;
  initiatingUserId: string;
  results: { status: { status: 'ADDED' | string; failureReason: string[] }; userId: string }[];
};

export const grantAccess: (
  licenseId: string,
  users: OrgUserResponse[],
  orgId: string,
  initiatingUserId: string
) => Promise<GrantAccessResponse> = (licenseId, users, orgId, initiatingUserId) => {
  cacheService.removeAllInPath(`/v1/licenses`);

  const cmd = {
    id: licenseId,
    userIds: users.map(user => ({ id: user?._id?.id })),
    managerId: orgId,
    initiatingUserId: initiatingUserId,
  };

  return submitCommand(licenseId, cmd, 'AllocateSeats', 'LicenseResult', 'LicenseError').then(
    (res: GrantAccessResponse) => {
      if (res.results.some(item => !item.status.status.includes('ADDED'))) {
        return Promise.reject();
      } else {
        return res;
      }
    }
  );
};
