import React from 'react';
import PropTypes from 'prop-types';
import ThirdPartyYouTube from './ThirdPartyYouTube';
import ThirdPartyVimeo from './ThirdPartyVimeo';
import ThirdPartyVideoService from '../services/ThirdPartyVideoService';

export default function ThirdPartyVideoPlayer(props) {
  const data = ThirdPartyVideoService.getData(props.url);
  if (data.type === 'youtube') {
    return <ThirdPartyYouTube onProgress={props.onProgress} onEnd={props.onEnd} url={data.id} startTime={data.time} />;
  } else {
    return <ThirdPartyVimeo onProgress={props.onProgress} onEnd={props.onEnd} url={data.url} />;
  }
}

ThirdPartyVideoPlayer.propTypes = {
  currentStep: PropTypes.object,
  url: PropTypes.string.isRequired,
  onProgress: PropTypes.func,
  onEnd: PropTypes.func,
};
