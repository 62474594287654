angular.module('lwNamb').controller(
  'TrainingTaskCtrl',

  [
    '$scope',
    '$routeParams',
    '$location',
    '$timeout',
    '$log',
    'assessBuilderService',
    'userService',
    'thirdPartyVideoService',
    'alertService',
    'searchDetailService',
    '$localStorage',
    '$window',
    'windowService',
    'purchaseService',
    function(
      $scope,
      $routeParams,
      $location,
      $timeout,
      $log,
      assessBuilderService,
      userService,
      thirdPartyVideoService,
      alertService,
      searchDetailService,
      $localStorage,
      $window,
      windowService,
      purchaseService
    ) {
      var trainingId = $routeParams.id,
        taskIndex = $routeParams.taskIndex,
        isAlternatePreview = !!$location.search().alt;

      var resetDirective = function() {
        $timeout(function() {
          $scope.showThirdPartyVideo = true;
        }, 500);
      };

      $scope.$on('$destroy', function() {
        alertService.destroy();
      });

      $scope.purchase = function(itemNumber) {
        var item = itemNumber || '';
        purchaseService.getShoppingUrl(item).then(
          function(response) {
            windowService.redirect(response.data.url);
          },
          function(reason) {
            $log.error(reason);
            alertService.show({
              title: 'Unexpected Error!',
              content: 'We were unable to access shopping at this time.',
              type: 'danger danger-with-content',
              duration: 20,
            });
          }
        );
      };

      $scope.addStepToCourse = function(task) {
        $localStorage.stepToAdd = {
          task: task,
          taskList: trainingId,
          origin: $window.location.hash,
        };
        $location.url('/add-step/' + task._id.id);
      };

      $scope.hideToolTip = $localStorage.hideToolTip;
      $scope.isLoading = true;
      $scope.showThirdPartyVideo = true;
      $scope.trainingId = trainingId;
      $scope.isSelectTraining = $routeParams.isSelectTraining;

      $scope.redirectToTraining = function() {
        redirect('/training/' + trainingId);
      };

      $scope.dismissToolTip = function() {
        $scope.hideToolTip = $localStorage.hideToolTip = true;
      };

      $scope.redirectToTask = function(taskIndex) {
        redirect('/training-task/' + trainingId + '/' + taskIndex);
      };

      userService.user().then(function(user) {
        $scope.loggedIn = true;
        $scope.user = user;
        $scope.orgId = $scope.user.lastSelectedAccount;
      }, function(){
        $scope.loggedIn = false;
      }).finally(function(){
        searchDetailService.getTaskDetail(trainingId, taskIndex, $scope.isSelectTraining === 'preview', $scope.user ? $scope.user.userId : undefined).then(
          function success(result) {
            $scope.task = result.task;
            $scope.context = result.context;
            $scope.task.isPreview = isAlternatePreview ? !!result.context.itemNumber : !!result.task.isPreview;

            if (
              $scope.task.isPreview &&
              $scope.task._type === 'ThirdPartyVideo' &&
              $scope.task.thirdPartySite === 'YouTube'
            ) {
              var videoId = thirdPartyVideoService.getThirdPartyData($scope.task.videoUrl);
              $scope.task.videoImageUrl = 'https://img.youtube.com/vi/' + videoId.id + '/maxresdefault.jpg';
            }
            if ($scope.task._type === 'ThirdPartyVideo') {
              $scope.showThirdPartyVideo = false;
              resetDirective();
            }
            if ($scope.task._type === 'Assessment') {
              assessBuilderService.getAssessment($scope.task.assessmentId, $scope.user.userId).then(
                function(assessment) {
                  if (assessment.deleted) {
                    $scope.taskDeleted = true;
                    $scope.deletedBy = assessment.deletedBy;
                  }
                },
                function(reason) {
                  $log.error(reason);
                }
              );
            }
          },
          function failure(reason) {
            if (reason === 'NOT_FOUND') {
              $scope.redirectToTraining();
            } else {
              $log.error(reason);
              $scope.isError = true;
            }
            alertService.show({
              title: 'Unexpected Error!',
              content: 'We are unable to get your preview at this time.',
              type: 'danger danger-with-content',
              duration: 20,
            });
          }
        ).finally(function() {
          $scope.isLoading = false;
        });
      });

      function redirect(url) {
        var select = $routeParams.isSelectTraining ? '/' + $routeParams.isSelectTraining : '',
          query = isAlternatePreview ? '?alt=1' : '';
        $location.url(url + select + query);
      }
    },
  ]
);
