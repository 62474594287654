import React from 'react';
import styled from 'styled-components';
import { Grid, Breakpoints, Color } from '../StyleGuide';
import Step from './Step';
import StepIcons from '../StepIcons';

// TODO: Consolidate types with Step.tsx
type StepIcon = keyof typeof StepIcons;

type Training = {
  _type: string;
  purchaseRequired: string | boolean;
  taskListInstanceId: { id: string };
  name: string;
};

type Task = {
  taskName: string;
  _type: StepIcon;
  assessmentId: string;
  linkUrl: string;
  assetUrl: string;
  responseId: string;
  uploadedAssetUrl?: string;
};

type Step = {
  _type: StepIcon;
  status: string;
  task: Task;
};

type StepsProp = {
  training: Training;
  steps: Step[];
};

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: ${Grid._7} -${Grid._4} 0 -${Grid._4};
  li {
    display: block;
    padding: ${Grid._5};
    border-top: 1px solid ${Color.Gray._30};
  }
  li:last-child {
    border-bottom: 1px solid ${Color.Gray._30};
  }
  li.complete,
  li.locked {
    background-color: ${Color.Gray._05};
  }
  li.locked {
    cursor: not-allowed;
  }
  li.locked > a {
    pointer-events: none;
  }
  li.locked > a:hover {
    text-decoration: none;
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: ${Grid._7} 0 0;
  }
`;
const Steps = ({ training, steps }: StepsProp) => {
  const isLocked = training._type === 'Course' || training.purchaseRequired;

  const stepList = steps.map((step, index) => (
    <li
      data-qa-hook="assignedTaskListTask"
      key={index}
      className={`${step.status === 'complete' ? 'complete' : ''} ${isLocked ? 'locked' : ''}`}
    >
      <Step isLocked={isLocked} step={step} training={training} />
    </li>
  ));

  return <List className="collapsable-data">{stepList}</List>;
};

export default Steps;
