import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  position: relative;
  width: 15px;
`;

const Triangle = styled.div`
  position: relative;
  &:before,
  &:after {
    width: 0;
    height: 0;
    border-top: 30px inset transparent;
    border-bottom: 30px inset transparent;
    position: absolute;
    content: "";
    top: -30px;
  }
  &:before {
    left: -1px;
    border-left: 15px solid #fff};
  }
`;

const Line = styled(Triangle)`
  z-index: -1;
  &:before {
    left: 0;
    border-left-color: ${props => props.color};
  }
`;

export default function Chevron() {
  return (
    <Div>
      <Triangle />
      <Line color="#dfdfdf" />
    </Div>
  );
}
