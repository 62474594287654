import React from 'react';
import styled from 'styled-components';
import { CircleCheck } from '../../components/FormElements';
import ThumbnailIcon from '../../components/ThumbnailIcon';
import limitFilter from '../../filters/limit-filter';
import { SearchResultItem } from '../models/Search';
import { SearchAccessDetails, SearchResultItemContainer } from './SearchElements';

type Props = {
  training: SearchResultItem;
  isSelected: boolean;
  onClick: (item: SearchResultItem) => void;
};

const SelectButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 60px;
`;

export const SelectableSearchResultsListItem = ({ training, isSelected, onClick = () => {} }: Props) => {
  return (
    <SearchResultItemContainer onClick={() => onClick(training)}>
      <div>
        <ThumbnailIcon>
          <i className={`fas fa-fw ${training.trainingType === 'Workflow' ? 'fa-route' : 'fa-tasks'}`} />
        </ThumbnailIcon>
      </div>

      <div>
        <h4>{training.name}</h4>

        <SearchAccessDetails>
          <p>{training.ownerName}</p>
        </SearchAccessDetails>

        {training.description && <p>{limitFilter(training.description, 195)}</p>}
      </div>

      <SelectButtonContainer>
        <CircleCheck checked={isSelected} />
      </SelectButtonContainer>
    </SearchResultItemContainer>
  );
};
