const TimeoutPromise = (conf, executor) => {
  if (!conf || (typeof conf.seconds !== 'number' && typeof conf.millis !== 'number')) {
    throw new Error('A value in seconds or millis is required e.g. { seconds: 5 }');
  }

  const timeoutMillis = conf.seconds ? conf.seconds * 1000 : conf.millis;

  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(`Configured timeout reached: ${timeoutMillis / 1000}s`);
    }, timeoutMillis);

    executor(
      value => {
        clearTimeout(timeoutId);
        resolve(value);
      },
      value => {
        clearTimeout(timeoutId);
        reject(value);
      }
    );
  });
};

export default TimeoutPromise;
