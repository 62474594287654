import React from 'react';
import PropTypes from 'prop-types';
import { react2angular } from 'react2angular';
import styled from 'styled-components';
import { Color } from '../StyleGuide';
import { isGrid } from '../filters/WhiteLabel';
import LoadingIndicator from './LoadingIndicator';

// This styling is a legacy holdover.
// We likely will want to remove/replace it at some point.
const Styles = styled.div`
  margin: 80px 20px;
`;

const LoadingState = props => (
  <Styles>
    <LoadingIndicator {...props} color={isGrid() ? Color.Blue._50 : Color.Gray._50}>
      {!props.hideText && (props.children || <span>Loading</span>)}
    </LoadingIndicator>
  </Styles>
);

LoadingState.propTypes = {
  hideText: PropTypes.bool,
  children: PropTypes.any,
};

export default LoadingState;

// eslint-disable-next-line no-undef
angular.module('lwNamb').component('loadingState', react2angular(LoadingState));
