angular.module('lwNamb').directive('lifewayInfiniteScroll2', [
  function() {
    return {
      restrict: 'E',
      templateUrl: function(elem, attr) {
        //Has to be explicit so the Grunt task that hashes the filenames links them properly
        if (attr.view.indexOf('{{view}}') > -1 || attr.view.indexOf('{{ view }}') > -1) {
          return 'partials/directives/infinite-scroll-addTo.html';
        } else if (attr.view === 'assessmentResults') {
          return 'partials/directives/infinite-scroll-assessmentResults.html';
        } else if (attr.view === 'assessmentResultsFilter') {
          return 'partials/directives/infinite-scroll-assessmentResultsFilter.html';
        } else if (attr.view === 'coachSelect') {
          return 'partials/directives/infinite-scroll-coachSelect.html';
        } else if (attr.view === 'content') {
          return 'partials/directives/infinite-scroll-content.html';
        } else if (attr.view === 'manageAssignments') {
          return 'partials/directives/infinite-scroll-manageAssignments.html';
        } else if (attr.view === 'multiSelectPeople') {
          return 'partials/directives/infinite-scroll-multiSelectPeople.html';
        } else if (attr.view === 'removeFromOrg') {
          return 'partials/directives/infinite-scroll-removeFromOrg.html';
        } else if (attr.view === 'tasklists') {
          return 'partials/directives/infinite-scroll-tasklists.html';
        } else if (attr.view === 'workflows') {
          return 'partials/directives/infinite-scroll-workflows.html';
        } else if (attr.view === 'curriculum') {
          return 'partials/directives/infinite-scroll-curriculum.html';
        }
      },
      scope: {
        all: '=',
        filter: '=',
        selectedItems: '=',
        loading: '=',
        sortBy: '@',
        sortByArray: '=',
        completedSort: '@',
        view: '@',
        roleKey: '@',
        clearInput: '&',
        filterRestriction: '=',
        rowClick: '=',
        rowHover: '@',
        removeItem: '=',
        extras: '=',
        preventSearchSave: '@',
        maxAdd: '=',
        reverseItems: '=',
        stickToTop: '@',
      },
      replace: true,
      controller: [
        '$scope',
        'pagingService',
        '$localStorage',
        'windowService',
        'userService',
        function($scope, pagingService, $localStorage, windowService, userService) {
          function init() {
            $scope.all = $scope.all === undefined ? [] : $scope.all;
            $scope.filter = $scope.filter === undefined ? {} : $scope.filter;
            $scope.selectedItems = $scope.selectedItems === undefined ? [] : $scope.selectedItems;
            $scope.filtered = $scope.all;
            $scope.paged = [];
            $scope.lastLoaded = 0;
            $scope.select = true;
            $scope.hasAttribute = $scope.stickToTop;
            $scope.reverse = $scope.reverseItems ? $scope.reverseItems : false;
            userService.user().then(function(accountOwner) {
              $scope.all.forEach(function(item) {
                if (item.email === accountOwner.logInEmail) {
                  item.isYou = true;
                }
              });
              loadSearch();
            });
          }

          $scope.paging = function() {
            var result = pagingService.paging($scope.lastLoaded, $scope.filtered, $scope.paged);
            $scope.lastLoaded = result.lastLoaded;
            $scope.filtered = result.filtered;
            $scope.paged = result.paged;
          };
          $scope.sortAll = function(field, forFilter) {
            if ($scope.filter && $scope.filter.sortDropDown) {
              field = $scope.filter.sortDropDown;
              forFilter = false;
            } else {
              field = field || 'timeAssigned';
            }

            switch (field) {
              case '-name':
                field = 'name';
                $scope.reverse = false;
                break;
              case '-email':
                field = 'email';
                $scope.reverse = false;
                break;
              case '-mostRecentCompletion':
                field = 'mostRecentCompletion';
                $scope.reverse = false;
                break;
              default:
                $scope.reverse = true;
            }

            var result = pagingService.sortAll(
              field,
              $scope.reverse,
              $scope.lastLoaded,
              $scope.filtered,
              $scope.paged,
              $scope.sortByArray,
              $scope.hasAttribute
            );
            $scope.sortBy = field;
            if (forFilter === undefined) $scope.reverse = result.reverse;
            $scope.lastLoaded = result.lastLoaded;
            $scope.filtered = result.filtered;
            $scope.paged = result.paged;
            $scope.$emit('list:filtered');
          };

          function filterData() {
            var result = pagingService.filterData(
              $scope.lastLoaded,
              $scope.filtered,
              $scope.paged,
              $scope.all,
              $scope.filter,
              [
                filterRoles,
                filterTasklists,
                filterGroups,
                filterPublished,
                filterCategory,
                filterVisible,
                filterAssignments,
                filterProgress,
                filterNumComplete,
                filterContentType,
                filterTrainingType,
                customFieldsFilter,
              ]
            );
            $scope.lastLoaded = result.lastLoaded;
            $scope.filtered = result.filtered;
            $scope.paged = result.paged;
            $scope.sortAll($scope.sortBy, true);
          }

          $scope.$watchCollection('filter', function() {
            if (!$scope.loading) {
              saveSearch();
              filterData();
            }
          });

          $scope.$watchCollection('all', function(value) {
            if (value !== undefined) {
              filterData();
            }
          });

          $scope.$watch('loading', function() {
            if (!$scope.loading) {
              init();
            }
          });

          function filterAssignments(item) {
            return (
              !$scope.filter ||
              !$scope.filter.assignmentType ||
              ($scope.filter.assignmentType.indexOf('ALL_TL') > -1 && item.assignable._type === 'AssignedTaskList') ||
              ($scope.filter.assignmentType.indexOf('ALL_WF') > -1 && item.assignable._type === 'Workflow') ||
              item.assignable.name === $scope.filter.assignmentType
            );
          }

          function filterProgress(item) {
            return (
              !$scope.filter ||
              !$scope.filter.status ||
              (item.assignable.progress && item.assignable.progress._type === $scope.filter.status)
            );
          }

          function filterGroups(item) {
            return (
              !$scope.filter ||
              !$scope.filter.group ||
              $scope.filter.group.length === 0 ||
              $scope.filter.group.some(function(g){
                return item.groupNames.find(function(gn){ return gn === g;}) !== undefined;
              })
            );
          }

          function filterTasklists(item) {
            return !$scope.filter || !$scope.filter.taskList || item.taskListName === $scope.filter.taskList;
          }

          function filterPublished(item) {
            return (
              !$scope.filter ||
              $scope.filter.published === undefined ||
              $scope.filter.published === '' ||
              ($scope.filter.published === 'true' && item.published) ||
              ($scope.filter.published === 'false' && !item.published)
            );
          }

          function filterCategory(item) {
            return (
              !$scope.filter ||
              $scope.filter.category === undefined ||
              $scope.filter.category === 'Show All' ||
              $scope.filter.category === 'No Category' && item.category === undefined ||
              $scope.filter.category === item.category
            );
          }

          function filterVisible(item) {
            return (
              !$scope.filter ||
              $scope.filter.isPublished === undefined ||
              $scope.filter.isPublished === '' ||
              ($scope.filter.isPublished === 'true' && item.isPublished) ||
              ($scope.filter.isPublished === 'false' && !item.isPublished)
            );
          }

          function filterNumComplete(item) {
            return (
              !$scope.filter ||
              $scope.filter.numComplete === undefined ||
              $scope.filter.numComplete === '' ||
              ($scope.filter.numComplete === 'true' && item.numComplete > 0) ||
              ($scope.filter.numComplete === 'false' && item.numComplete === 0)
            );
          }

          function filterTrainingType(item) {
            return (
              !$scope.filter ||
              !$scope.filter.trainingType ||
              (item.trainingType && item.trainingType === $scope.filter.trainingType)
            );
          }

          function filterContentType(item) {
            return (
              !$scope.filter || !$scope.filter.contentType || (item.type && item.type === $scope.filter.contentType)
            );
          }

          function customFieldsFilter(member) {
            return (
              !$scope.filter ||
              !$scope.filter.selectedCustomField ||
              !$scope.filter.customSearch ||
              (!!member.customFields &&
                !!member.customFields.find(function(g) {
                  var isAllSelected = $scope.filter.selectedCustomField.name == 'All Fields';
                  return (
                    (isAllSelected || g.name === $scope.filter.selectedCustomField.header) &&
                    g.fields.find(function(f) {
                      return (
                        isAllSelected ||
                        (f.name == $scope.filter.selectedCustomField.name &&
                          f.value.toLowerCase().indexOf($scope.filter.customSearch.toLowerCase()) > -1)
                      );
                    })
                  );
                }))
            );
          }

          function filterRoles(item) {
            var roles = resolve($scope.roleKey, item);
            return (
              !$scope.filter ||
              !$scope.filter.role ||
              !roles ||
              $scope.filter.role.length === 0 ||
              $scope.filter.role.some(function(r){
                return roles.find(function(role){ return r === role || r === role.name || r.name === role || r.id === role ||
                  (r.id && r.id.id === role) || (role.id && role.id.id === r) || role.id === r;}) !== undefined;
              })
            );
          }

          function resolve(path, obj) {
            return (
              !path ||
              path.split('.').reduce(function(prev, curr) {
                return prev ? prev[curr] : undefined;
              }, obj || self)
            );
          }

          $scope.ensureSelection = function(person) {
            var i = $scope.selectedItems.findIndex(function(p) {
              return p === person;
            });
            if (person.selected && i === -1) {
              $scope.selectedItems.push(person);
            } else if (!person.selected && i > -1) {
              $scope.selectedItems.splice(i, 1);
            }
            $scope.$emit('list:filtered');
          };

          $scope.selectAll = function(items) {
            items.forEach(function(item) {
              item.selected = true;
              $scope.ensureSelection(item);
            });
            $scope.select = false; //only toggle to false if we've added something
          };

          $scope.unselectAll = function(items) {
            items.forEach(function(item) {
              $scope.unSelect(item);
            });
            $scope.select = true;
          };

          $scope.unSelect = function(item) {
            item.selected = false;
            $scope.ensureSelection(item);
          };

          function filteredFilters() {
            return Object.keys($scope.filter)
              .filter(function(f) {
                return (
                  ($scope.filter[f] !== '' &&
                    $scope.filter[f] !== undefined &&
                    $scope.filter[f].length > 0 &&
                    f !== 'search' &&
                    f !== 'customSearch' &&
                    f !== 'selectedCustomField') ||
                  ($scope.filter[f] !== '' &&
                    $scope.filter[f] !== undefined &&
                    f === 'selectedCustomField' &&
                    $scope.filter[f].name !== 'All Fields' &&
                    $scope.filter[f].length > 0)
                );
              })
              .filter(function(f) {
                return f != 'sortDropDown';
              });
          }

          $scope.hasMoreThanOneAppliedFilter = function() {
            if ($scope.filter === undefined) {
              return false;
            } else {
              return filteredFilters().length > 1;
            }
          };

          $scope.hasAtLeastOneAppliedFilter = function() {
            if ($scope.filter === undefined) {
              return false;
            } else {
              return filteredFilters().length > 0;
            }
          };

          $scope.redirectTo = function(path) {
            $localStorage.filters = $scope.filters !== undefined ? $scope.filters : $scope.filter;
            if($localStorage.filters !== undefined) {
              $localStorage.filters.redirectsLeft = 2;
              $localStorage.filters.view = $scope.view;
            }
            windowService.redirectHash(path);
          };

          function saveSearch() {
            if (!$scope.preventSearchSave) {
              if ($localStorage.savedInfiniteScrollSearch === undefined) $localStorage.savedInfiniteScrollSearch = [];
              $localStorage.savedInfiniteScrollSearch[$scope.view] = $scope.filter;
              $localStorage.lastInfiniteScrollView = $scope.view;
            }
          }

          function loadSearch() {
            if (
              $localStorage.savedInfiniteScrollSearch !== undefined &&
              $localStorage.savedInfiniteScrollSearch[$scope.view] !== undefined
            ) {
              $scope.filter = $localStorage.savedInfiniteScrollSearch[$scope.view];
            }
            filterData();
          }
        },
      ],
    };
  },
]);
