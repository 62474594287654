import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Type, Color } from '../StyleGuide';

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    color: ${Type.Color.medium};
    font-size: ${Type.Scale._3};
  }
`;

const Spinner = styled.svg`
  animation: rotate 2s linear infinite;
  z-index: 2;
  width: ${props => props.width || '50px'};
  height: ${props => props.height || '50px'};

  & .track {
    stroke: ${Color.Gray._20};
  }
  & .path {
    stroke: ${props => props.color || Color.Blue._50};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const LoadingIndicator = ({ width, height, strokeWidth = 4, color, hideTrack, children }) => {
  return (
    <SpinnerContainer data-qa-hook="loadingIndicator">
      <Spinner viewBox="0 0 50 50" width={width} height={height} strokeWidth={strokeWidth} color={color}>
        {hideTrack || <circle className="track" cx="25" cy="25" r="20" fill="none" strokeWidth={strokeWidth}></circle>}
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth={strokeWidth}></circle>
      </Spinner>
      {children}
    </SpinnerContainer>
  );
};

LoadingIndicator.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  hideTrack: PropTypes.bool,
  children: PropTypes.any,
};

export default LoadingIndicator;
