import React from 'react';
import styled from 'styled-components';
import { Grid, Type, Color, Shadow, Breakpoints } from '../StyleGuide';

export const TrackLine = styled.div`
  position: absolute;
  width: ${Grid._1};
  background: ${Color.Gray._20};
  height: calc(100% + 40px);
  &.start {
    bottom: -20px;
    height: calc(50% + 20px);
  }
  &.end {
    top: -20px;
    height: calc(50% + 20px);
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    height: calc(100% + 80px);
    &.start {
      bottom: -40px;
      height: calc(50% + 40px);
    }
    &.end {
      top: -40px;
      height: calc(50% + 40px);
    }
  }
`;

const Icon = styled.p`
  z-index: 1;
  position: relative;
  color: ${Color.Blue._50};
  font-size: ${Type.Scale._8};
  &:after {
    position: absolute;
    content: '${props => props.marker}';
    top: 23%;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: ${Type.Scale._4};
    color: ${Color.white};
    text-shadow: ${Shadow.light};
    text-align: center;
  }
`;

const MapMarker = ({ marker }) => {
  return (
    <Icon marker={marker}>
      <i className="fas fa-map-marker"></i>
    </Icon>
  );
};

export const TrackContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const PathwayTrack = ({ i, length }) => {
  const isFirst = i === 1;
  const isLast = i === length;
  return (
    <TrackContainer>
      <MapMarker marker={i} />
      <TrackLine className={isFirst ? 'start' : isLast ? 'end' : ''} />
    </TrackContainer>
  );
};

export default PathwayTrack;
