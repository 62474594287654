angular
  .module('lwNamb')

  .factory(
    'api',

    [
      '$http',
      'apiUrlService',
      'qt',
      '$timeout',
      function($http, apiUrlService, qt, $timeout) {
        var defaultMaxRetryCount = 10,
          retryStatuses = [500, 502, 503, 504];

        return {
          get: function(path, config, optionalMaxRetryCount) {
            return requestWithRetry(function() {
              return $http.get(apiUrlService.getUrl() + path, config);
            }, optionalMaxRetryCount);
          },
          post: function(path, data, config, optionalMaxRetryCount) {
            return requestWithRetry(function() {
              return $http.post(apiUrlService.getUrl() + path, data, config);
            }, optionalMaxRetryCount);
          },
          put: function(path, data, config, optionalMaxRetryCount) {
            return requestWithRetry(function() {
              return $http.put(apiUrlService.getUrl() + path, data, config);
            }, optionalMaxRetryCount);
          },
          delete: function(path, config, optionalMaxRetryCount) {
            return requestWithRetry(function() {
              return $http.delete(apiUrlService.getUrl() + path, config);
            }, optionalMaxRetryCount);
          },
        };

        function requestWithRetry(requestFunction, optionalMaxRetryCount) {
          var deferred = qt.defer(),
            scheduled,
            maxRetryCount = typeof optionalMaxRetryCount === 'number' ? optionalMaxRetryCount : defaultMaxRetryCount;
          retry(1);
          return deferred.promise;

          function retry(tryCount) {
            requestFunction().then(
              function success(response) {
                if (scheduled) {
                  $timeout.cancel(scheduled);
                }
                deferred.resolve(response);
              },
              function failure(reason) {
                if (
                  tryCount > maxRetryCount ||
                  retryStatuses.findIndex(function(s) {
                    return s === reason.status;
                  }) === -1
                ) {
                  deferred.reject(reason);
                } else {
                  scheduled = $timeout(function() {
                    retry(tryCount + 1);
                  }, tryCount * 1000); // linear backoff
                }
              }
            );
          }
        }
      },
    ]
  );
