import React from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';

const Background = styled.div`
  position: fixed;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
`;

const ModalBackground = ({ children, dismissHandler }) => {
  return createPortal(
    <Background
      id="background"
      onClickCapture={e => {
        if (['background'].indexOf(e.target.id) > -1) {
          dismissHandler();
        }
      }}
    >
      {children}
    </Background>,
    document.body
  );
};

export default ModalBackground;
