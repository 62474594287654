import React, { useEffect } from 'react';
import styled from 'styled-components';

import WhiteLabel from '../filters/WhiteLabel';
import { Grid, Color, Breakpoints } from '../StyleGuide';
import apiClient from '../services/apiClient';
import windowService from '../services/windowService';

// These SVGs need to be outputted directly on the page rather than requested
// from the server because the server may be down.
import { MaintenanceSVG, ServiceUnavailableSVG } from './StatusSVGs';

// This will need to be changed once a global variable file is created for maintenance.
const maintenance = false;
const CONSECUTIVE_SUCCESSES_NEEDED = 4;
const RETRY_INTERVAL = 2000;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
`;

const Header = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
`;

const Logo = styled.img`
  display: block;
  margin: auto;
  width: 175px;
  padding: ${Grid._2} 0;
`;

const SvgObject = styled.object`
  width: ${Grid._14};
  height: 159px;
  margin-right: ${Grid._5};
  margin-bottom: ${Grid._8};
`;

const Body = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  background: ${Color.Gray._10};
  h2 {
    font-size: ${Grid._6};
  }
  @media (max-width: ${Breakpoints.screen_sm}) {
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: center;
    img {
      margin-bottom: 0;
      margin-top: ${Grid._5};
    }
  }
`;

const SubBody = styled.div`
  text-align: left;
  max-width: 480px;
  p:first-of-type {
    font-size: ${Grid._4};
  }
  @media (max-width: ${Breakpoints.screen_sm}) {
    text-align: inherit;
  }
`;

const Divider = styled.hr`
  background: #c4c4c4;
  height: ${Grid._2};
  border: 0;
  width: ${Grid._7};
  margin-left: 0;
  @media (max-width: ${Breakpoints.screen_sm}) {
    text-align: inherit;
    margin: auto;
    margin-bottom: ${Grid._4};
  }
`;

const ServiceUnavailable = () => {
  useEffect(() => {
    let successCount = 0;
    const storedRedirectLocation = localStorage.getItem('currentPage');
    const intervalId = setInterval(() => {
      apiClient
        .get('/system-info/version')
        .then(() => {
          if (++successCount >= CONSECUTIVE_SUCCESSES_NEEDED || !storedRedirectLocation) {
            windowService.redirectTo(storedRedirectLocation || '/dashboard');
          }
        })
        .catch(() => {
          successCount = 0;
        });
    }, RETRY_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);

  const email = WhiteLabel.contact?.email;
  const phone = WhiteLabel.contact?.phone;
  const svg = maintenance ? MaintenanceSVG : ServiceUnavailableSVG;
  const description = maintenance ? 'is offline for planned maintenance.' : 'is temporarily unavailable';

  return (
    <Div>
      <Header>
        <Logo src={WhiteLabel.logoSrc} alt={WhiteLabel.name} />
      </Header>
      <Body>
        <SvgObject dangerouslySetInnerHTML={{ __html: svg }} />
        <SubBody>
          <h2>
            {WhiteLabel.name} {description}
          </h2>
          <p>But fear not: Our team is working on it, and we'll get things up and running ASAP</p>
          <Divider />
          <p>If you need help, you can contact us at:</p>
          <p>
            <a href={`mailto:${email}`}> {email}</a>
            {phone && phone !== '' && (
              <>
                <span> • </span>
                <a href={`tel:${phone}`}> {phone}</a>
              </>
            )}
          </p>
        </SubBody>
      </Body>
    </Div>
  );
};

export default ServiceUnavailable;
