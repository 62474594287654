angular.module('lwNamb').controller('manageAssignmentTaskViewerCtrl', [
  '$scope',
  '$location',
  '$log',
  '$routeParams',
  'userService',
  'orgService',
  'taskListService',
  function($scope, $location, $log, $routeParams, userService, orgService, taskListService) {
    var init = function() {
      $scope.hasAccess = true;
      $scope.loading = true;

      $scope.assignmentId = $routeParams.assignmentId;
      $scope.taskId = $routeParams.taskId;

      userService.user().then(
        function(user) {
          $scope.user = user;
          getAssignmentData();
        },
        function() {
          $log.error('No user returned in manageAssignmentTaskViewerCtrl');
        }
      );
    };

    var getAssignmentData = function() {
      taskListService.getAssignment($scope.assignmentId).then(
        function(assignment) {
          $scope.assignment = assignment;
          checkAccess();
        },
        function(response) {
          $log.error('Error: Assignment not found; ' + response);
        }
      );
    };

    var checkAccess = function() {
      var canViewAssignments = $scope.user.permissions.includes('Assign View');

      if (canViewAssignments)
        return getSubscriptionStatus();

      if ($scope.user.permissions.includes('Group Leader')) {
        var denyAccess = function() {
          $scope.hasAccess = false;
          $scope.loading = false;
        };

        var hasLeaderAccess = function(groups, leaderUserId, assigneeUserId) {
          return !!groups.find(function(group) {
            return group.visible
              && !!group.members.find(function(member) { return member.id.id === assigneeUserId; })
              && !!group.members.find(function(member) { return member.position === 'Leader' && member.id.id === leaderUserId; });
          });
        };

        orgService.getGroups($scope.user.lastSelectedAccount)
          .then(function(groups) {
            if (hasLeaderAccess(groups, $scope.user.userId, $scope.assignment.assignee.userId)) {
              getSubscriptionStatus();
            } else {
              denyAccess();
            }
          })
          .catch(denyAccess);
      }
    };

    var getSubscriptionStatus = function() {
      orgService
        .hasGridSubscription($scope.user.lastSelectedAccount)
        .then(
          function(hasSub) {
            $scope.hasSubscription = hasSub;
          },
          function(reason) {
            $log.error('Unable to get subscription information: ' + reason);
          }
        )
        .finally(function() {
          retrieveTask();
        });
    };

    var retrieveTask = function() {
      $scope.task = taskListService.getTaskFromAssignment($scope.assignment, $scope.taskId);
      $scope.task.task.isPreview = !$scope.hasSubscription;
      if (!$scope.hasSubscription) {
        if (
          $scope.task.task.isPreview &&
          $scope.task.task._type === 'ThirdPartyVideo' &&
          $scope.task.task.thirdPartySite === 'YouTube'
        ) {
          var videoId = thirdPartyVideoService.getThirdPartyData($scope.task.task.videoUrl);
          $scope.task.task.videoImageUrl = 'https://img.youtube.com/vi/' + videoId.id + '/maxresdefault.jpg';
        }
      }
      $scope.loading = false;
    };

    init();
  },
]);
