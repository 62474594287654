import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { MediumButton, MediumPrimaryButton } from '../../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../../components/ModalElements';
import { Label, FormField, Select } from '../../components/FormElements';
import FileUploader from '../../components/FileUploader';
import { useUser } from '../../authentication';

const units = ['per person', 'per group'];
const quantities = Array.from(Array(10)).map((_, i) => i + 1);

const SupplyNameContainer = styled.div`
  > div {
    margin: 0;
  }
`;

const AddSupplyModal = ({ supply = {}, onSubmit, handleDismiss }) => {
  const { userId } = useUser();

  const transformDownloadableToFile = downloadable => downloadable && { name: downloadable.fileName };
  const transformFileToDownloadable = file =>
    file && { fileId: { id: file.id, ext: file.extension }, fileName: file.name };

  const setFileFieldValue = setFieldValue => file => setFieldValue('file', transformFileToDownloadable(file));

  const addSupply = ({ name, quantity, unit, file }) =>
    onSubmit({
      name,
      quantity: `${quantity} ${unit}`,
      downloadable: file,
      supplyType: 'craft',
    });

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader data-qa-hook="modalHeader">
        <h3>{supply.name ? 'Edit Supply' : 'Add Supply'}</h3>
      </ModalHeader>
      <Formik
        initialValues={{
          name: supply.name || '',
          quantity: supply.quantity ? Number(supply.quantity.match(/^(\S+)\s(.*)/).slice(1)[0]) : 1,
          unit: supply.quantity ? supply.quantity.match(/^(\S+)\s(.*)/).slice(1)[1] : 'per person',
          file: supply.downloadable,
        }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          name: Yup.string().max(64, 'Name is too long').required('A name is required'),
        })}
        onSubmit={addSupply}
      >
        {({ values, isSubmitting, handleChange, dirty, setFieldValue }) => (
          <Form id="add-session-form">
            <ModalBody className="grid-container grid-row-gap-24">
              <div className="grid-container grid-col-6 grid-col-gap-24 grid-row-gap-8">
                <SupplyNameContainer className="grid-col-span-6 grid-sm-col-span-3">
                  <FormField
                    data-qa-hook="addSupplyName"
                    label="Supply Name"
                    name="name"
                    type="text"
                    placeholder="Enter Supply Name"
                  />
                </SupplyNameContainer>
                <div className="grid-col-span-2 grid-sm-col-span-1">
                  <Label htmlFor="supplyQty">Quantity</Label>
                  <Select
                    data-qa-hook="quantityDropdown"
                    value={`${values.quantity}`}
                    name="quantity"
                    onChange={handleChange}
                  >
                    {quantities.map(qty => (
                      <option key={qty} value={qty}>
                        {qty}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="grid-col-span-4 grid-sm-col-span-2">
                  <Label htmlFor="supplyUnit">Unit</Label>
                  <Select data-qa-hook="unitDropdown" value={values.unit} name="unit" onChange={handleChange}>
                    {units.map(unit => (
                      <option key={unit} value={unit}>
                        {unit}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>

              <div>
                <Label>Attach Resource (optional)</Label>
                <FileUploader
                  userId={userId}
                  initialFile={transformDownloadableToFile(values.file)}
                  onFileUploaded={setFileFieldValue(setFieldValue)}
                  onFileRemoved={setFileFieldValue(setFieldValue)}
                  uploadLocation="curriculum-storage/files"
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton
                data-qa-hook="modalConfirm"
                type="submit"
                disabled={isSubmitting || (supply.name && !dirty)}
                operating={isSubmitting}
              >
                {supply.name ? 'Save Supply' : 'Add Supply'}
              </MediumPrimaryButton>
              <MediumButton onClick={handleDismiss}>Cancel</MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};

AddSupplyModal.propTypes = {
  supply: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default AddSupplyModal;
