import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import alertService from '../services/AlertService';
import assessmentService from '../services/assessmentService';
import uuid from '../services/uuid';
import windowService from '../services/windowService';

import { useUser } from '../authentication';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';

import { MediumButton, MediumPrimaryButton } from '../components/Buttons';
import { FormFieldMaxCharCountStatus } from '../components/FormElements';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../components/ModalElements';

const CopyAssessmentModal = ({ setCopyAssessmentModalOpen, assessment, assessmentId }) => {
  const user = useUser();
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();

  const copyAssessment = (values, { setSubmitting, setFieldError }) => {
    const newId = uuid.generate();
    assessmentService
      .copyAssessment(assessmentId, newId, values.name, assessment.ownerId, user.userId)
      .then(res => {
        alertService.showOnNextPage('Assessment Duplicated');
        windowService.redirectTo(`${manageTrainingRoutePrefix}/assessments/${res.id}`);
      })
      .catch(error => {
        console.error(error);

        setSubmitting(false);

        if (error.eventType === 'AssessmentNameDuplicateError')
          return setFieldError('name', 'Sorry, that assessment name is taken');

        setCopyAssessmentModalOpen(false);
        alertService.showError();
      });
  };

  return (
    <ModalContainer dismissHandler={() => setCopyAssessmentModalOpen(false)}>
      <ModalHeader data-qa-hook="copyAssessmentModal">
        <h3>Copy Assessment</h3>
      </ModalHeader>
      <Formik
        initialValues={{ name: '' }}
        validationSchema={Yup.object({
          name: Yup.string().required('A name is required'),
        })}
        onSubmit={copyAssessment}
      >
        {({ setValues, isSubmitting }) => (
          <Form>
            <ModalBody>
              <FormFieldMaxCharCountStatus
                label="Name:"
                id="name"
                name="name"
                type="text"
                placeholder="Name the assessment copy"
                maxLength={64}
                onBlur={e => setValues(prevValues => ({ ...prevValues, name: e.target.value }))}
              />
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton
                data-qa-hook="continue"
                disabled={isSubmitting}
                operating={isSubmitting}
                type="submit"
              >
                Continue
              </MediumPrimaryButton>
              <MediumButton data-qa-hook="cancel" onClick={() => setCopyAssessmentModalOpen(false)}>
                Cancel
              </MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};

CopyAssessmentModal.propTypes = {
  setCopyAssessmentModalOpen: PropTypes.func.isRequired,
  assessment: PropTypes.object.isRequired,
  assessmentId: PropTypes.string.isRequired,
};

export default CopyAssessmentModal;
