angular.module('lwNamb').directive(
  'redeemMc',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/redeem-mc.html',
      };
    },
  ]
);
