import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid, Breakpoints, Color, Border, Type } from '../StyleGuide';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { Container, FixedHeaderContainer, FixedHeaderContent, RaisedContainer } from '../components/Layout';
import { PrimaryButton, RoundedIconButton, SmallRadioRoundedIconButton } from '../components/Buttons';
import { ListItemTitleContainer, ChurchImage } from '../components/PairingElements';
import ActionableTitleBar from '../components/ActionableTitleBar';
import windowService from '../services/windowService';
import alertService from '../services/AlertService';
import orgService from '../services/orgService';
import userService from '../services/userService';
import { useUser } from '../authentication';
import WhiteLabel from '../filters/WhiteLabel';
import capitalizeFilter from '../filters/capitalize-filter';

const orgLabel = WhiteLabel.labels.org;
const capitalizedOrgLabel = capitalizeFilter(orgLabel);

const ListItemContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: ${Grid._4};
  padding: ${Grid._4};
  width: 100%;

  > div:last-child {
      display: grid;
      place-content: center;
    }
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding-right: ${Grid._5};
  }
`;

const ChurchList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${Grid._6};
  margin-top: ${Grid._10};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const RaisedItemContainer = styled(RaisedContainer)`
  width: 100%;
  padding: 0;
`;

const RaisedChurchListItem = ({ church, selected, onButtonClick }) => (
  <RaisedItemContainer>
    <ListItemContainer>
      <ChurchImage church={church} />
      <ListItemTitleContainer>
        <h4>{church.name}</h4>
        {church.members && (
          <p>
            {church.members.length} {church.members.length === 1 ? 'Person' : 'People'}
          </p>
        )}
      </ListItemTitleContainer>
      <div>
        <SmallRadioRoundedIconButton onClick={onButtonClick}>
          {selected ? <i className="fas fa-circle color-complete" /> : <i />}
        </SmallRadioRoundedIconButton>
      </div>
    </ListItemContainer>
  </RaisedItemContainer>
);

const CreateChurchContainer = styled(ListItemContainer)`
  border: 1px dashed ${Color.Gray._50};
  border-radius: ${Border.radius};
  grid-template-columns: 1fr auto;

  i.fa-plus {
    font-size: ${Type.Scale._5};
  }

  button {
    background: none;
    border: none;
    padding-right: 6px;
  }
`;

const CreateChurchListItem = ({ onButtonClick }) => (
  <CreateChurchContainer>
    <ListItemTitleContainer>
      <h4>Create Another {capitalizedOrgLabel}</h4>
      <p>Connect your purchases with a new {orgLabel}</p>
    </ListItemTitleContainer>
    <div>
      <button onClick={onButtonClick}>
        <i className="fas fa-plus" />
      </button>
    </div>
  </CreateChurchContainer>
);

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: ${Grid._7};
  padding-bottom: ${Grid._10};

  button {
    min-width: 134px;

    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      margin: 5px;
    }
  }
`;

export default function SelectOrg() {
  const user = useUser();
  const currentOrgId = user.lastSelectedAccount;
  const [status, setStatus] = useState({ isLoading: true, isError: false, isSubmitting: false });
  const [orgs, setOrgs] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState();

  const redirectToCreateChurch = () => windowService.redirectTo('#/org/create');

  const submitOrgChange = () => {
    setStatus({ ...status, isSubmitting: true });

    if (selectedOrgId === currentOrgId) windowService.redirectTo('#/pairing/purchases');
    else {
      userService
        .switchOrg(user.userId, selectedOrgId)
        .then(() => {
          user.lastSelectedAccount = selectedOrgId;
          windowService.redirectTo('#/pairing/purchases');
        })
        .catch(reason => {
          console.error(reason);
          alertService.show('An error occurred. Please try again.', 'error');
          setStatus({ ...status, isSubmitting: false });
        });
    }
  };

  useEffect(() => {
    orgService
      .getLicensePairingOrgs()
      .then(orgs => {
        const isPairableOrg = orgs.some(o => o._id.id === currentOrgId);
        setSelectedOrgId(isPairableOrg ? currentOrgId : null);
        const sortedOrgs = orgService.sortAlphabetically(orgs);
        setOrgs(isPairableOrg ? orgService.putOrgAtBeginning(currentOrgId, sortedOrgs) : sortedOrgs);
        setStatus({ ...status, isLoading: false });
      })
      .catch(reason => {
        console.error(reason);
        setStatus({ isLoading: false, isError: true });
      });
  }, []);

  return (
    <FixedHeaderContainer fullViewport>
      <ActionableTitleBar
        title={`Select A ${capitalizedOrgLabel}`}
        subtitle={`Which ${orgLabel} do you want to use your purchase with?`}
        leftButton={
          <RoundedIconButton onClick={windowService.redirectBack}>
            <i className="fas fa-arrow-left fa-fw"></i>
            Back
          </RoundedIconButton>
        }
      />
      <FixedHeaderContent>
        <Container>
          {status.isLoading ? (
            <LoadingState />
          ) : status.isError ? (
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          ) : (
            <>
              <ChurchList>
                {orgs.map((org, i) => (
                  <RaisedChurchListItem
                    church={org}
                    selected={org._id.id === selectedOrgId}
                    onButtonClick={() => setSelectedOrgId(org._id.id)}
                    key={i}
                  />
                ))}
                <CreateChurchListItem onButtonClick={redirectToCreateChurch} />
              </ChurchList>

              <ButtonContainer>
                <PrimaryButton
                  onClick={submitOrgChange}
                  disabled={!selectedOrgId || status.isSubmitting}
                  operating={status.isSubmitting}
                >
                  Continue
                </PrimaryButton>
              </ButtonContainer>
            </>
          )}
        </Container>
      </FixedHeaderContent>
    </FixedHeaderContainer>
  );
}
