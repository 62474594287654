import React from 'react';
import { react2angular } from 'react2angular';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Border, Breakpoints } from '../StyleGuide';
import { SmallPrimaryButton } from '../components/Buttons';
import StepIcons from '../StepIcons';

// Global Components
import ProgressBarContainer from '../components/ProgressBarContainer';
import ProgressBar from '../components/ProgressBar';
import ThumbnailIcon from '../components/ThumbnailIcon';
import SubInfoRow from '../components/SubInfoRow';
import TrainingDetailsContainer from '../components/TrainingDetailsContainer';
import TrainingContainer from '../components/TrainingContainer';
import { purchaseLockedItem } from '../utils/purchaseUtils';
import alertService from '../services/AlertService';

// Internal Components
const TrainingCardContainer = styled(TrainingContainer)`
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.075);
  h4,
  ul {
    margin-right: 0;
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    border-radius: ${Border.radius};
  }
`;

// Helper functions
const formatDate = date => new Date(date).toLocaleDateString();

// Main Component
export default function TrainingCard({
  assignment,
  assignmentLabel,
  label,
  userId,
  shareResults,
  downloadFile,
  redirectToTask,
  reportVisibility,
}) {
  const isActiveWorkFlow = assignment._type === 'ActiveWorkflowOverview';
  const isCompleteWorkFlow = assignment._type === 'CompleteWorkflowOverview';
  const isComplete = assignment.completedOn;
  const completedOn = isComplete && formatDate(assignment.completedOn);

  const hasCertificate = (
    assignment.certificateInfo !== undefined
      ? [assignment]
      : assignment.completedCourses !== undefined
      ? assignment.completedCourses.map(c => (c.certificateInfo ? c : undefined))
      : []
  ).filter(a => a !== undefined);
  const isLocked = assignment.purchaseRequired;
  const dueDate = assignment.dueDate && formatDate(assignment.dueDate);

  const training = isActiveWorkFlow ? assignment.activeCourse : assignment;
  const upNext = training.upNext || '';
  const upNextTrainingType = assignment._type === 'ActiveWorkflowOverview' ? 'workflow' : 'course';
  const trainingProgress = training.progress.percent;
  const steps = training.progress.steps;
  const stepLabel = steps && (steps.total > 1 ? label + 's' : label);

  const completedCourses = assignment.progress.steps.completed;
  const totalCourses = assignment.progress.steps.total;

  const hasReports = assignment.reports && assignment.reports.length >= 1;

  const downloadCertificate = id => {
    window.open(getApiUrl() + '/v1/files/certificates/' + id + '/' + userId);
  };

  const purchaseLockedTraining = itemNumber => purchaseLockedItem(itemNumber) || alertService.showError();

  const ShareResultsIcon = () => (
    <span
      className="fa-stack fa-2x fa-stack-button share-results-button"
      title="Share Results"
      onClick={() => shareResults(assignment)}
    >
      <i className="fas fa-circle fa-stack-2x" />
      <i className="icon ion-share fa-stack-1x fa-inverse" />
    </span>
  );

  const TrainingDetails = () => (
    <ul>
      {isComplete ? (
        <li>
          {steps ? `${steps.total} ${stepLabel}` : `${assignmentLabel}`} completed{' '}
          {completedOn ? `on ${completedOn}` : ''}
          {!isCompleteWorkFlow && shareResults && <ShareResultsIcon />}
        </li>
      ) : (
        <li>
          {steps.completed} of {steps.total} {stepLabel} complete
        </li>
      )}
      {dueDate && <li data-qa-hook="assignedTaskListPanelDueDate">Finish by {dueDate}</li>}
    </ul>
  );

  const TrainingInfo = () => {
    return (
      <TrainingDetailsContainer>
        <div>
          <ThumbnailIcon>
            {isComplete && (
              <span className="fa-stack fa-stack-status">
                <i
                  className="fas fa-circle fa-stack-2x"
                  style={isCompleteWorkFlow ? { color: '#788796' } : { color: '#85a657' }}
                />
                <i className="fas fa-check fa-stack-1x fa-inverse" />
              </span>
            )}
            {isLocked && (
              <span className="fa-stack fa-stack-status">
                <i style={{ color: '#85a657' }} className="fas fa-circle fa-stack-2x" />
                <i className="fas fa-lock fa-stack-1x fa-inverse" />
              </span>
            )}
            <i className={'fas fa-fw ' + (isActiveWorkFlow || isCompleteWorkFlow ? 'fa-route' : 'fa-tasks')} />
          </ThumbnailIcon>
        </div>
        <div>
          <h4>
            {isActiveWorkFlow || isCompleteWorkFlow ? (
              <a
                data-qa-hook="assignedTaskListPanelName"
                href={
                  '#/training-steps/' + assignment.instanceId + '?stepId=' + upNext.instanceId + '&isWorkflow=' + true
                }
              >
                {training.name}
              </a>
            ) : (
              <a
                data-qa-hook="assignedTaskListPanelName"
                href={'#/training-steps/' + assignment.instanceId + '?stepId=' + upNext.instanceId}
              >
                {training.name}
              </a>
            )}
          </h4>

          <TrainingDetails />

          {isCompleteWorkFlow ? (
            <ProgressBarContainer>
              {assignment.completedCourses.map((e, i) => (
                <ProgressBar
                  isWorkFlow="true"
                  key={`${assignment.instanceId}_${i}`}
                  progress="100"
                  className="wf-progress-bar"
                />
              ))}
            </ProgressBarContainer>
          ) : (
            <ProgressBar progress={trainingProgress} className="tl-progress-bar" />
          )}
        </div>
      </TrainingDetailsContainer>
    );
  };

  return (
    <TrainingCardContainer>
      {isActiveWorkFlow && (
        <SubInfoRow style={{ borderTop: 'none', borderBottom: '1px solid rgba(0,0,0,0.05)' }}>
          <div />
          <div>
            {assignmentLabel} {completedCourses + 1} of {totalCourses} in {assignment.name}
          </div>
        </SubInfoRow>
      )}

      <TrainingInfo />

      {upNext && !isLocked && (
        <SubInfoRow>
          <div>Next</div>
          <div>
            <div style={{ display: 'flex' }}>
              <i className={'icon ' + StepIcons[upNext._type]} />
              <a onClick={() => redirectToTask(assignment, upNextTrainingType)}>{upNext.name}</a>
            </div>
          </div>
        </SubInfoRow>
      )}

      {(hasCertificate.length > 0 || hasReports) && (
        <SubInfoRow>
          <div />
          <div>
            {hasCertificate.length > 0 && (
              <SmallPrimaryButton
                data-qa-hook="downloadCertificateButton"
                onClick={() => hasCertificate.map(c => downloadCertificate(c.instanceId))}
              >
                <i className="fa fa-award" /> Download Certificate{hasCertificate.length > 1 ? 's' : ''}
              </SmallPrimaryButton>
            )}
            {hasReports &&
              assignment.reports.map((report, i) => {
                if (
                  report.reportId &&
                  (((report.id === 'CPIACandidate' || report.id === 'CPCACandidate') &&
                    reportVisibility === 'PUBLIC') ||
                    ((report.id === 'CPIA' || report.id === 'CPCA') && reportVisibility === 'PRIVATE'))
                ) {
                  return (
                    <SmallPrimaryButton
                      key={report.reportId}
                      data-qa-hook="completedTaskListPanelReport"
                      onClick={() => downloadFile(assignment, report)}
                    >
                      <i className="fa fa-chart-bar" /> View Report
                    </SmallPrimaryButton>
                  );
                }
              })}
          </div>
        </SubInfoRow>
      )}

      {isLocked && (
        <SubInfoRow>
          <div />
          <div>
            <SmallPrimaryButton
              data-qa-hook="assignedTaskListPanelPurchase"
              onClick={() => purchaseLockedTraining(assignment.purchaseRequired.value || assignment.purchaseRequired)}
            >
              Purchase to Unlock
            </SmallPrimaryButton>
          </div>
        </SubInfoRow>
      )}
    </TrainingCardContainer>
  );
}

TrainingCard.propTypes = {
  assignment: PropTypes.object.isRequired,
  assignmentLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  shareResults: PropTypes.func,
  downloadFile: PropTypes.func,
  redirectToTask: PropTypes.func,
  reportVisibility: PropTypes.string,
};

angular.module('lwNamb').component('trainingCard', react2angular(TrainingCard));
