angular.module('lwNamb').factory('ssoService', [
  '$rootScope',
  'qt',
  '$log',
  '$http',
  'apiUrlService',
  function($rootScope, qt, $log, $http, apiUrlService) {
    function getClientId() {
      switch ($rootScope.origin) {
        case 'GRID':
          return '5E04CBD4-1664-4FDA-B00A-4EBA1425456A';
        case 'CENTRIKID':
          return 'F557D26C-0B9A-11EA-8D71-362B9E155667';
        case 'FUGE':
          return '0B98ECDD-7EF1-4FBA-ABB3-0077E7706511';
        case 'STUDENTLIFE':
          return '1F7ED1F6-3F74-4035-991C-902467BEB1F0';
        default:
          return 'B4963680-11AD-47C9-AAB7-8D7F12252363';
      }
    }

    $log.debug('sso usrmgr service');
    var mgr = new Oidc.UserManager({
      userStore: new Oidc.WebStorageStateStore(),
      authority: getAccountUrl(),
      client_id: getClientId(),
      redirect_uri: window.location.origin + '/#/oauth2/callback',
      response_type: 'code',
      scope: 'openid email profile',
      post_logout_redirect_uri: window.location.origin + '/#/logout',
      silent_redirect_uri: window.location.origin + '/oauth2/silent_v2.html',
      automaticSilentRenew: true,
      filterProtocolClaims: true,
      loadUserInfo: true,
      clockSkew: 3600,
      monitorAnonymousSession: true,
      revokeAccessTokenOnSignout: true,
      silentRequestTimeout: 10000,
      stopCheckSessionOnError: false,
      offsetSeconds: fetch(getAccountUrl() + "/oauth2/servertime").then(function(res) {
        return res.json();
      }).then(function(data) {
          return parseInt(data.unixtime - (Date.now() / 1000));
      })
    });
    $log.debug('sso usrmgr service loaded');

    Oidc.Log.logger = console;
    Oidc.Log.level = Oidc.Log.INFO;

    mgr.events.addUserSignedIn(function() {
      $log.debug('sso signin on another site');
      f.getSignedIn();
    });

    mgr.events.addUserLoaded(function(user) {
      $log.debug('UserLoaded Access_token: ', user.access_token);
      mgr.getUser().then(function(user){
        $log.debug('UserLoaded User: ', user);
      });
    });

    mgr.events.addAccessTokenExpiring(function() {
      $log.debug('AccessToken Expiring：', arguments);
    });

    mgr.events.addAccessTokenExpired(function() {
      $log.debug('AccessToken Expired：', arguments);
      mgr.signinSilent()
        .then(function (user) {
          if (!user) return Promise.reject('Re-authentication required');
          return user;
        })
        .catch(function () {
          $rootScope.$broadcast('UserSignedOut');
        });
    });

    mgr.events.addSilentRenewError(function() {
      $log.error('Silent Renew Error：', arguments);
    });

    mgr.events.addUserUnloaded(function () {
      $log.debug("user unloaded");
      $rootScope.$broadcast('UserSignedOut');
    });

    mgr.events.addUserSignedOut(function() {
      $log.debug('UserSignedOut：', arguments);
      $rootScope.$broadcast('UserSignedOut');
    });

    var f = {
      getIsSSO: function() {
        return getIsSSO();
      },
      getMgr: function() {
        return mgr;
      },
      getUser: function() {
        var deferred = qt.defer();
        mgr
          .getUser()
          .then(function(user) {
            if (user === null) {
              deferred.reject(null);
            } else {
              $log.debug(JSON.stringify(user));
              deferred.resolve(user);
            }
          })
          .catch(function(err) {
            $log.debug(err);
            deferred.reject(err);
          });
        return deferred.promise;
      },
      signIn: function(extraParams) {
        // Redirect of the current window to the authorization endpoint.
        return s(extraParams);
      },
      removeUser: function() {
        return mgr.removeUser();
      },
      signOut: function() {
        // Redirect of the current window to the end session endpoint
        return mgr.signoutRedirect();
      }, //,
      // renewToken: function() {
      //   // Renew the token manually
      //   var deferred = qt.defer();
      //   mgr.signinSilent().then(function(user) {
      //     if (user == null) {
      //       s();
      //     } else {
      //       deferred.resolve(user);
      //     }
      //   }).catch(function(err) {
      //     $log.debug(err);
      //     deferred.reject(err);
      //   });
      //   return deferred.promise;
      // },
      signInSilent: function() {
        $log.debug("silent");
        mgr.signinSilent().catch(function() {});
      },
      getSignedIn: function() {
        // Check if there is any user logged in
        var deferred = qt.defer();
        mgr.signinSilent().then(function() {
          s();
          deferred.resolve();
          mgr.clearStaleState();
        }).catch(function() {
          deferred.reject();
        });
        return deferred.promise;
      },
      clearStaleState: function() {
        $log.debug('clearing stale SSO state');
        return mgr.clearStaleState();
      }
      // getProfile: function() {
      //   // Get the profile of the user logged in
      //   var deferred = qt.defer();
      //   mgr.getUser().then(function(user) {
      //     if (user == null) {
      //       s();
      //       deferred.resolve(null);
      //     } else {
      //       deferred.resolve(user.profile);
      //     }
      //   }).catch(function(err) {
      //     $log.debug(err);
      //     deferred.reject(err);
      //   });
      //   return deferred.promise;
      // },
      // getIdToken: function() {
      //   // Get the token id
      //   var deferred = qt.defer();
      //   mgr.getUser().then(function(user) {
      //     if (user == null) {
      //       s();
      //       deferred.resolve(null);
      //     } else {
      //       deferred.resolve(user.id_token);
      //     }
      //   }).catch(function(err) {
      //     $log.debug(err);
      //     deferred.reject(err);
      //   });
      //   return deferred.promise;
      // },
      // getSessionState: function() {
      //   // Get the session state
      //   var deferred = qt.defer();
      //   mgr.getUser().then(function(user) {
      //     if (user == null) {
      //       s();
      //       deferred.resolve(null);
      //     } else {
      //       deferred.resolve(user.session_state);
      //     }
      //   }).catch(function(err) {
      //     $log.debug(err);
      //     deferred.reject(err);
      //   });
      //   return deferred.promise;
      // },
      //   // Get the access token of the logged in user
      // function getAccessToken() {
      //     return new Promise((resolve, reject) => {
      //       mgr.getUser().then(function(user) {
      //         if (user == null) {
      //           s();
      //           return resolve(null);
      //         } else {
      //           return resolve(user.access_token);
      //         }
      //       }).catch(function(err) {
      //         $log.debug(err);
      //         return reject(err);
      //       });
      //     });
      //   }
      //
      //   // Takes the scopes of the logged in user
      // function getScopes() {
      //     return new Promise((resolve, reject) => {
      //       mgr.getUser().then(function(user) {
      //         if (user == null) {
      //           s();
      //           return resolve(null);
      //         } else {
      //           return resolve(user.scopes);
      //         }
      //       }).catch(function(err) {
      //         $log.debug(err);
      //         return reject(err);
      //       });
      //     });
      //   }
      //
      //   // Get the user roles logged in
      // function getRole() {
      //     return new Promise((resolve, reject) => {
      //       mgr.getUser().then(function(user) {
      //         if (user == null) {
      //           s();
      //           return resolve(null);
      //         } else {
      //           return resolve(user.profile.role);
      //         }
      //       }).catch(function(err) {
      //         $log.debug(err);
      //         return reject(err);
      //       });
      //     });
      //   }
    };

    function s(extraParams) {
      mgr.signinRedirect(extraParams).then(function(){
        mgr.clearStaleState();
      }).catch(function(err) {
        $log.debug(err);
      });
    }

    return f;
  },
]);
