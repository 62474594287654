import React, { useState } from 'react';
import { Input } from '../components/FormElements';
import { PrimaryButton } from '../components/Buttons';
import InfoBox from '../components/InfoBox';

export default function Step3(props) {
  const { emailAddress, churchName, type } = props.user;
  const [churchNameIsValid, setChurchNameIsValid] = useState(false);

  function handleOnChange(event) {
    props.setChurchName(event);
    var isValid = event.target.value.length;
    setChurchNameIsValid(isValid);
  }

  function handleRedirect(event) {
    event.preventDefault();
    event.stopPropagation();
    props.handleLifeWayRedirection(emailAddress);
  }

  return (
    <React.Fragment>
      <h3 data-qa-hook="step3Header">Setup Your Church</h3>
      <p>What is the name of your Church?</p>
      <Input
        style={{ margin: '16px 0 8px' }}
        data-qa-hook="churchNameField"
        type="text"
        aria-label="Church Name"
        placeholder="Enter Church Name"
        value={churchName}
        onChange={handleOnChange}
        required
      />
      <InfoBox>
        <p>
          <a data-qa-hook="alreadyHaveChurchLabel" onClick={handleRedirect}>
            I already have a church set up on Ministry Grid
          </a>
        </p>
      </InfoBox>
      <PrimaryButton data-qa-hook="createChurchButton" disabled={!churchNameIsValid} onClick={props.handleNextStep}>
        Create
      </PrimaryButton>
    </React.Fragment>
  );
}
