import submitCommand from './submitCommand';

const trainingInstanceService = {
  startStep: (id, initiatingUserId, assigneeId, step) => {
    let cmd = { id: id, initiatingUserId: initiatingUserId, assigneeId: assigneeId, taskInstance: step };
    return submitCommand(id, cmd, 'StartTask', 'TaskInstanceStarted', 'TaskInstanceError');
  },
  completeStep: (courseInstanceId, stepInstance, deletedStepOpt) => {
    if (stepInstance.status === 'complete') {
      return Promise.resolve();
    }

    const command = {
      id: courseInstanceId,
      assigneeId: stepInstance.assigneeId.id,
      taskInstance: stepInstance,
      deleted: deletedStepOpt === true,
    };

    return submitCommand(
      courseInstanceId,
      command,
      'CompleteTask',
      ['TaskInstanceStatusInTaskListInstanceSetComplete', 'TaskInstanceAlreadyCompleted'],
      'TaskListInstanceError'
    );
  },
  startFeedbackStep: (id, task, assigneeId, email, responseId, initiatingUserId) => {
    var cmd = {
      id: id,
      initiatingUserId: initiatingUserId,
      assigneeId: assigneeId,
      taskInstance: task,
      email: email,
      responseId: responseId,
    };
    return submitCommand(id, cmd, 'StartFeedbackTask', 'TaskInstanceStarted', 'TaskInstanceError');
  },
  completeFeedbackStep: (id, taskInstance, email, responseId, initiatingUserId) => {
    var cmd = {
      id: id,
      taskInstance: taskInstance,
      email: email,
      responseId: responseId,
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(id, cmd, 'CompleteFeedbackTask', 'TaskInstanceCompleted', 'TaskListInstanceError');
  },
  submitFile: (training, currentStep, assetId) => {
    const cmd = {
      id: training.id,
      assigneeId: currentStep.assigneeId.id,
      taskInstanceId: { id: currentStep.id.id },
      assetId: assetId,
      version: 2,
    };

    return submitCommand(training.id, cmd, 'SetAssetIdInUploadTask', 'AssetIdSetInUploadTask');
  },
  setStepToNotStarted: (tasklistId, taskId) => {
    return submitCommand(
      tasklistId,
      { id: tasklistId, taskInstanceId: taskId },
      'MarkPETaskNotReady',
      'TaskInstanceStatusInTaskListInstanceSetNotStarted',
      'TaskListInstanceError'
    );
  },
  generateReport: (tasklistInstanceId, initiatingUserId) => {
    return submitCommand(
      tasklistInstanceId,
      { id: tasklistInstanceId, initiatingUserId },
      'GenerateReport',
      'ReportResultToTasklistAdded',
      'CumulativeReportErrorGenerated'
    );
  },
};

export default trainingInstanceService;
