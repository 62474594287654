import React, { useState } from 'react';
import { LoadingVideoPlaceholder } from '../components/Layout';
import PropTypes from 'prop-types';
import { Grid, Border, Breakpoints } from '../StyleGuide';
import styled from 'styled-components';
import VideoPlayer from '../containers/VideoPlayer';

const Container = styled.div`
  margin: ${Grid._4} 0;
  @media screen and (min-width: ${Breakpoints.screen_lg}) {
    margin: ${Grid._6} 0;
  }
`;

export default function MaterialMediaPlayer(props) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Container>
      {isLoading && <LoadingVideoPlaceholder />}
      <VideoPlayer setIsLoading={setIsLoading} userId={props.userId} videoId={props.videoId} onEnd={() => null} />
    </Container>
  );
}

MaterialMediaPlayer.propTypes = {
  userId: PropTypes.string,
  videoId: PropTypes.string,
};
