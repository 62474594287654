import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';

// Global Components
import TextTruncate from '../containers/TextTruncate';
import Labels from '../components/Labels';

// Helper functions
const formatDate = date => new Date(date).toLocaleDateString();

function TrainingDetails({
  type,
  name,
  description,
  label,
  units,
  unitsCompleted,
  isComplete,
  dueDate,
  timeCompleted,
  isCollapsed,
  assignedBy,
  timeAssigned,
  selfAssigned,
}) {
  const unitLabel = units && (units.length > 1 ? label + 's' : label);
  const completedOn = timeCompleted && formatDate(timeCompleted);
  const FormattedDueDate = dueDate && formatDate(dueDate);
  const upcomingCourse = type && type === 'Course';
  const assignedOn = timeAssigned && formatDate(timeAssigned);

  return (
    <React.Fragment>
      {type ? (
        <h3 className={isCollapsed ? 'collapsable-title' : ''} data-qa-hook="assignedTaskListName">
          {name}
        </h3>
      ) : (
        <h1 data-qa-hook="assignedTaskListName">{name}</h1>
      )}
      <Labels>
        {upcomingCourse && (
          <li>
            {units.length} {unitLabel}
          </li>
        )}
        {!upcomingCourse &&
          (isComplete ? (
            <li data-qa-hook="completedTaskListDate">
              {units.length} of {units.length} {unitLabel} completed {completedOn ? `on ${completedOn}` : ''}
            </li>
          ) : (
            <li>
              {unitsCompleted} of {units.length} {unitLabel} complete
            </li>
          ))}
        {dueDate && <li data-qa-hook="managedTaskListDueDate">Finish by {FormattedDueDate}</li>}
        {selfAssigned && (
          <li data-qa-hook="assignmentDetailFullName" data-qa-hook2="assignmentDetailTrainingAssignedBy">
            Self-assigned {assignedOn ? `on ${assignedOn}` : ''}
          </li>
        )}
        {!selfAssigned && assignedBy && (
          <li data-qa-hook="assignmentDetailFullName" data-qa-hook2="assignmentDetailTrainingAssignedBy">
            Assigned by {assignedBy} {assignedOn ? `on ${assignedOn}` : ''}
          </li>
        )}
      </Labels>
      {description && <TextTruncate text={description} className="collapsable-data" />}
    </React.Fragment>
  );
}

TrainingDetails.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  units: PropTypes.array.isRequired,
  unitsCompleted: PropTypes.number,
  isComplete: PropTypes.bool,
  dueDate: PropTypes.any,
  timeCompleted: PropTypes.any,
  assignedBy: PropTypes.string,
  timeAssigned: PropTypes.any,
  selfAssigned: PropTypes.any,
};

export default TrainingDetails;
