import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { Grid, Breakpoints } from '../../StyleGuide';
import { ScriptSection } from '../../components/SessionTimelineContentElements';
import RichTextEditor from '../../containers/RichTextEditor';
import useAutosave from '../../hooks/useAutosave';
import useSessionTimeline from '../../hooks/useSessionTimeline';

const ScriptSectionEditorContainer = styled(ScriptSection)`
  padding: ${Grid._4} ${Grid._4} 0px ${Grid._4};
  @media only screen and (min-width: ${Breakpoints.screen_sm}) {
    text-align: left;
    padding: ${Grid._4} ${Grid._4} 0px 72px;
  }
`;

const ScriptSectionEditor = ({ leader, content, onSave }) => {
  const editorRef = useRef();
  const [editorState, setEditorState] = useState(
    content ? EditorState.createWithContent(stateFromHTML(content)) : EditorState.createEmpty()
  );
  const { segmentLeaderMap } = useSessionTimeline();

  useAutosave(() => {
    onSave({ text: stateToHTML(editorState.getCurrentContent()) });
  }, [editorRef]);

  return (
    <ScriptSectionEditorContainer leader={leader}>
      <h5>{segmentLeaderMap[leader]}</h5>
      <RichTextEditor ref={editorRef} editorState={editorState} setEditorState={setEditorState} blockTypes={[]} />
    </ScriptSectionEditorContainer>
  );
};

ScriptSectionEditor.propTypes = {
  leader: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ScriptSectionEditor;
