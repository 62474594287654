import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Color, Type, Breakpoints, Border } from '../StyleGuide';
import {
  TertiaryButton,
  OutlineButton,
  SmallRoundedIconButton,
  SmallRadioRoundedIconButton,
  ButtonGroup,
} from '../components/Buttons';
import { DurationIcon } from '../components/SessionTimelineElements';
import { Badge, SmallIconBadge } from './Badge';
import useSessionTimeline from '../hooks/useSessionTimeline';
import { ListItemFlexRow } from './ListItemFlexRow';

export const Variables = {
  _timelineVerticalSpacer: 'var(--session-timeline__vertical-spacer)',
  _sectionMediaWidth: 'var(--session-timeline__section-media-width)',
  _sectionMediaWidthMobile: 'var(--session-timeline__section-media-width-mobile)',
  _timeline_bg_color: '#fcfcfd',
  _disabledOpacity: 0.5,
};

//Timeline Components
export const TimelineContainer = styled.article`
  div:first-child > section > header {
    background: ${Variables._timeline_bg_color};
  }
`;

const HeaderContextText = styled.div`
  margin: 0 ${Grid._4};
`;

const TimelineBackground = styled.div`
  /* Mobile Background */
  background-color: ${Variables._timeline_bg_color};
  background-image: linear-gradient(#efeff0 1px, transparent 1px); /* fallback */
  background-image: linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px),
    linear-gradient(
      90deg,
      transparent calc(${Variables._sectionMediaWidthMobile} - 5px),
      ${Color.Blue._50} calc(${Variables._sectionMediaWidthMobile} - 5px),
      ${Color.Blue._50} ${Variables._sectionMediaWidthMobile},
      transparent ${Variables._sectionMediaWidthMobile}
    ),
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #f0f9ff ${Variables._sectionMediaWidthMobile},
      transparent ${Variables._sectionMediaWidthMobile}
    );
  background-size: 100% ${Variables._timelineVerticalSpacer};
  @media only screen and (min-width: ${Breakpoints.screen_sm}) {
    /* Background */
    background-image: linear-gradient(#efeff0 1px, transparent 1px); /* fallback */
    background-image: linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px),
      linear-gradient(
        90deg,
        transparent calc(${Variables._sectionMediaWidth} - 5px),
        ${Color.Blue._50} calc(${Variables._sectionMediaWidth} - 5px),
        ${Color.Blue._50} ${Variables._sectionMediaWidth},
        transparent ${Variables._sectionMediaWidth}
      ),
      linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) calc(${Variables._sectionMediaWidth} - 5%),
        #f0f9ff ${Variables._sectionMediaWidth},
        transparent ${Variables._sectionMediaWidth}
      );
    background-size: 100% ${Variables._timelineVerticalSpacer};
  }
`;

const TimelineComponent = styled(TimelineBackground)`
  display: grid;
  grid-template-columns: ${Variables._sectionMediaWidthMobile} 1fr;
  .timeline-highlight,
  .timeline-highlight-mobile-only {
    border-left: ${Grid._3} solid ${Color.Secondary._20};
  }
  @media only screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: ${Variables._sectionMediaWidth} 1fr;
    .timeline-highlight-mobile-only {
      border-left: none;
    }
  }
`;

const TimelineVerticalSpaces = styled(TimelineComponent)`
  height: ${props => `calc(${Variables._timelineVerticalSpacer} * ${props.spaces})`};
  div:nth-child(2) {
    background: ${Variables._timeline_bg_color};
  }
`;

export const TimelineVerticalSpacer = ({ timelineClassName, spaces }) => (
  <TimelineVerticalSpaces spaces={spaces}>
    <div></div>
    <div className={timelineClassName}></div>
  </TimelineVerticalSpaces>
);

export const TimelineLabelContainer = styled(TimelineBackground)`
  display: none;
  @media only screen and (min-width: ${Breakpoints.screen_sm}) {
    display: block;
    height: calc(${Variables._timelineVerticalSpacer} * 1);
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${Type.Color.medium};
    padding-left: ${Grid._3};
    & + div {
      margin-top: -${Grid._6};
    }
  }
`;

export const TimelineLabel = ({ totalDuration, remainingDuration, currentDuration }) => (
  <TimelineLabelContainer>
    {remainingDuration === totalDuration && currentDuration === 0
      ? 'Before Session'
      : remainingDuration === totalDuration && currentDuration !== 0
      ? 'Start Session'
      : currentDuration === 0 && remainingDuration === 0
      ? 'After Session'
      : currentDuration === 0 && remainingDuration !== 0
      ? ''
      : `${remainingDuration}m Remaining`}
  </TimelineLabelContainer>
);

const GroupTransitionContainer = styled.div`
  height: calc(${Variables._timelineVerticalSpacer} * 4);
  display: grid;
  grid-template-columns: ${Variables._sectionMediaWidthMobile} 1fr;
  background: linear-gradient(
      90deg,
      transparent calc(${Variables._sectionMediaWidthMobile} - 5px),
      ${Color.Blue._50} calc(${Variables._sectionMediaWidthMobile} - 5px),
      ${Color.Blue._50} ${Variables._sectionMediaWidthMobile},
      transparent ${Variables._sectionMediaWidthMobile}
    ),
    linear-gradient(90deg, rgba(242, 246, 249, 0) 0%, #edf2f7 5%, #edf2f7 90%, rgba(242, 246, 249, 0) 100%);
  background-size: 100%;
  div:nth-child(1) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .circle {
      min-width: 48px;
      min-height: 48px;
      margin-right: -20px;
      background: ${Color.Blue._50};
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '${props =>
          props.nextGroup === 'large' ? '\f0c0' : props.nextGroup === 'small' ? '\f500' : '\f51c'}';
        color: ${Color.white};
        font-size: 20px;
        text-shadow: ${Type.Shadow.dark};
        @media only screen and (min-width: ${Breakpoints.screen_sm}) {
          font-size: 24px;
        }
      }
      @media only screen and (min-width: ${Breakpoints.screen_sm}) {
        width: 72px;
        height: 72px;
        margin-right: -33px;
      }
    }
  }
  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 60px;
    h3 {
      margin-bottom: 8px;
    }
  }
  @media only screen and (min-width: 767px) {
    grid-template-columns: ${Variables._sectionMediaWidth} 1fr;
    background: linear-gradient(
        90deg,
        transparent calc(${Variables._sectionMediaWidth} - 5px),
        ${Color.Blue._50} calc(${Variables._sectionMediaWidth} - 5px),
        ${Color.Blue._50} ${Variables._sectionMediaWidth},
        transparent ${Variables._sectionMediaWidth}
      ),
      linear-gradient(90deg, rgba(242, 246, 249, 0) 0%, #edf2f7 5%, #edf2f7 90%, rgba(242, 246, 249, 0) 100%);
  }
`;

export const GroupTransition = ({ prevGroup, nextGroup }) => {
  const { segmentGroupMap } = useSessionTimeline();

  return (
    <GroupTransitionContainer nextGroup={nextGroup}>
      <div>
        <div className="circle"></div>
      </div>
      <div>
        <h3>{segmentGroupMap[nextGroup]}</h3>
        <p>
          Transition from {segmentGroupMap[prevGroup]} to {segmentGroupMap[nextGroup]}
        </p>
      </div>
    </GroupTransitionContainer>
  );
};

export const GroupDuration = ({ group, duration }) => {
  const { segmentGroupMap } = useSessionTimeline();

  return (
    <GroupTransitionContainer nextGroup={group}>
      <div>
        <div className="circle"></div>
      </div>
      <div>
        <h3>{segmentGroupMap[group]}</h3>
        <p>
          {duration} minute{duration === 1 ? '' : 's'} of {segmentGroupMap[group]} activities
        </p>
      </div>
    </GroupTransitionContainer>
  );
};

// Segment Components
export const SegmentContainer = styled.section``;

export const SegmentHeaderContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div.segment-content-text {
    flex: 1;
    h3 {
      font-size: 20px;
    }
    p {
      color: ${Type.Color.medium};
      margin-top: ${Grid._3};
    }
  }

  > div.segment-content-duration {
    display: flex;
  }

  > div.header-content-badge {
    margin-right: ${Grid._5};
  }

  ${SegmentContainer}.excluded-segment & {
    opacity: ${Variables._disabledOpacity};
  }
`;

export const SegmentHeaderContent = ({ name, group, duration, durationControls }) => {
  const { segmentGroupMap } = useSessionTimeline();

  return (
    <SegmentHeaderContentContainer data-qa-hook="segmentHeaderContainer">
      <div className="segment-content-text">
        <h3 data-qa-hook="segmentName">{name}</h3>
        <p data-qa-hook="segmentGroup">
          <i className="fas fa-map-marker-alt"></i>
          {segmentGroupMap[group]}
        </p>
      </div>
      <div className="segment-content-duration">
        {!!duration && (
          <>
            <DurationIcon duration={duration} />
            {durationControls}
          </>
        )}
      </div>
    </SegmentHeaderContentContainer>
  );
};

const FlexSegmentOptionHeaderContent = ({ name, activity, position }) => {
  const { segmentActivityMap } = useSessionTimeline();

  return (
    <SegmentHeaderContentContainer className="timeline-highlight">
      <div>
        <SmallIconBadge>{position}</SmallIconBadge>
      </div>
      <div className="segment-content-text" style={{ margin: '0 16px' }}>
        <h3>{name}</h3>
        <p>{segmentActivityMap[activity]}</p>
      </div>
      <div className="header-content-badge">
        <Badge>Option {position}</Badge>
      </div>
    </SegmentHeaderContentContainer>
  );
};

const FlexSegmentOptionSelectHeaderContent = ({ option, i, onToggle, showSections, setShowSections, customizable }) => {
  const { segmentActivityMap } = useSessionTimeline();

  return (
    <SegmentHeaderContentContainer className="timeline-highlight">
      {customizable && (
        <div>
          <SmallRadioRoundedIconButton onClick={() => onToggle()}>
            {option.enabled ? <i className="fas fa-circle color-complete" /> : <i />}
          </SmallRadioRoundedIconButton>
        </div>
      )}
      <HeaderContextText className="header-content-text">
        <h3>{option.name}</h3>
        <p>{segmentActivityMap[option.activity]}</p>
      </HeaderContextText>
      {customizable && (
        <>
          <div className="header-content-badge">
            <Badge>Option {i}</Badge>
          </div>
          <div>
            <SmallRoundedIconButton onClick={() => setShowSections(!showSections)}>
              <i className="fas fa-chevron-down" style={{ transform: `rotate(${showSections ? '180' : '0'}deg)` }}></i>
            </SmallRoundedIconButton>
          </div>
        </>
      )}
    </SegmentHeaderContentContainer>
  );
};

export const SegmentHeaderContainer = styled(TimelineComponent).attrs({ className: 'segment-header' })`
  min-height: calc(${Variables._timelineVerticalSpacer} * 3);
  z-index: 5;
  position: -webkit-sticky;
  position: sticky;
  border-bottom: 2px solid ${Color.Secondary._20};

  ${SegmentHeaderContentContainer} {
    background: ${Variables._timeline_bg_color};
    padding: 0 0 0 ${Grid._4};

    @media only screen and (min-width: ${Breakpoints.screen_lg}) {
      padding: 0 0 0 ${Grid._6};
    }
  }
`;

export const SegmentHeader = props => (
  <SegmentHeaderContainer as="header">
    <div></div>
    <SegmentHeaderContent {...props} />
  </SegmentHeaderContainer>
);

const FlexSegmentHeaderContainer = styled(SegmentHeaderContainer)`
  z-index: 3;
  top: calc(60px + ${Variables._timelineVerticalSpacer} * 3);
`;

export const FlexSegmentOptionHeader = props => (
  <FlexSegmentHeaderContainer as="header">
    <div></div>
    <FlexSegmentOptionHeaderContent {...props} />
  </FlexSegmentHeaderContainer>
);

export const FlexSegmentOptionSelectHeader = props => (
  <FlexSegmentHeaderContainer as="header">
    <div></div>
    <FlexSegmentOptionSelectHeaderContent {...props} />
  </FlexSegmentHeaderContainer>
);

export const SegmentLeaderContainer = styled(TimelineComponent)`
  height: calc(${Variables._timelineVerticalSpacer} * 2);
  margin-top: 0;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      display: flex;
      align-items: center;
      color: ${Type.Color.medium};
      i {
        color: ${Color.Blue._50};
        font-size: 24px;
        margin-right: 8px;
      }
    }
  }
`;

export const SegmentLeader = ({ timelineClassName, leader }) => {
  const { segmentLeaderMap } = useSessionTimeline();

  return (
    <SegmentLeaderContainer>
      <SectionBody data-qa-hook="segmentLeader" className={timelineClassName}>
        <p>
          <i className="fas fa-map-marker-alt"></i>By {segmentLeaderMap[leader]}
        </p>
      </SectionBody>
    </SegmentLeaderContainer>
  );
};

const FlexSegmentSelectedContainer = styled(SegmentLeaderContainer)`
  height: calc(${Variables._timelineVerticalSpacer} * 3);

  > div {
    flex-direction: column;

    p {
      padding: 0;

      &:first-child {
        flex: 1;
        color: ${Type.Color.dark};
      }

      &:last-child {
        font-weight: ${Type.Weight.semibold};
      }
    }

    @media only screen and (min-width: ${Breakpoints.screen_sm}) {
      flex-direction: row;

      p:first-child {
        padding-right: ${Grid._6};
      }
    }
  }
`;

export const FlexSegmentOptionsSelectedHeader = ({ numSelected }) => (
  <FlexSegmentSelectedContainer>
    <SectionBody>
      <p>
        Select activities below by toggling them on/off to determine what your leaders see. Time will not change based
        on your selections.
      </p>
      <p>{numSelected > 0 ? numSelected : 'None'} Selected</p>
    </SectionBody>
  </FlexSegmentSelectedContainer>
);

// Section Layout Components
export const SectionContent = styled(TimelineComponent)`
  z-index: 0;
  min-height: ${props => `calc(${Variables._timelineVerticalSpacer} * ${props.verticalSpaces})`};
  div:nth-child(2) {
    justify-content: flex-start;
  }
`;

export const SectionMedia = styled.div`
  grid-column-start: 2;
  @media only screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-column-start: 1;
  }
`;

export const StickySectionMediaItem = styled.div`
  position: relative;
  @media only screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-column-start: 1;
    position: -webkit-sticky;
    position: sticky;
    top: 182px;
    &.flex-segment-sticky-section-media-item {
      top: 272px;
    }
  }
`;

export const SectionBody = styled.div`
  grid-column-start: 2;
  display: flex;
  flex-direction: column;
  background: ${Variables._timeline_bg_color};
  padding: 0 0 0 ${Grid._4};
  @media only screen and (min-width: ${Breakpoints.screen_lg}) {
    padding: 0 0 0 ${Grid._6};
  }
`;

export const SectionLabel = styled.label`
  margin: 0;
  height: calc(${Variables._timelineVerticalSpacer} * 1);
  display: flex;
  align-items: center;
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._2};
  font-weight: ${Type.Weight.semibold};
`;

export const SectionMediaLabel = styled(SectionLabel)`
  margin: 0 ${Grid._4};
  @media only screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: 0 calc(16px + 5px) 0 ${Grid._3};
  }
  @media only screen and (min-width: ${Breakpoints.screen_lg}) {
    margin: 0 calc(32px + 5px) 0 ${Grid._4};
  }
`;

//Mixins
const withIconQuotes = ({ color }) => `
  &:before,
  &:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: ${Type.Scale._3};
    color: ${color};
  }
  &:first-of-type:before {
    content: "\f10d";
    margin-right: ${Grid._3};
  }
  &:last-of-type:after {
    content: "\f10e";
    margin-left: ${Grid._3};
  }
`;

const sectionIcon = ({ color, unicode, left, centered = true }) => `
  &:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: ${Type.Scale._6};
    color: ${color};
    content: ${unicode};
    position: relative;
    @media print, (min-width: ${Breakpoints.screen_sm}) {
      position: absolute;
      left: ${left};
      ${
        centered === true
          ? `
        display: grid;
        align-content: center;
        height: 100%;
        top: 0;
      `
          : `
        top: ${Grid._3};
      `
      }
    }
  }
`;

//Timeline Builder Components
const AddSegment = styled.div`
  height: calc(${Variables._timelineVerticalSpacer} * 3);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${Color.Gray._30};
  border-radius: ${Border.radius};
  background: ${Color.Gray._10};
`;

export const AddSegmentPlaceholder = ({ showModal }) => (
  <AddSegment>
    <TertiaryButton data-qa-hook="addSegmentButton" onClick={() => showModal(true)}>
      Add Segment
    </TertiaryButton>
  </AddSegment>
);

const EditSegment = styled(TimelineComponent)`
  height: calc(${Variables._timelineVerticalSpacer} * 2);

  ${SectionBody} {
    background: ${Color.Gray._10};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 ${Grid._4};
    @media only screen and (min-width: ${Breakpoints.screen_lg}) {
      padding: 0 ${Grid._6};
    }
  }

  ${SegmentContainer}.excluded-segment & ${SectionBody} > div:last-child {
    opacity: ${Variables._disabledOpacity};
  }
`;

export const EditSegmentToolbar = ({ leftContent, rightContent, ...props }) => (
  <EditSegment>
    <div></div>
    <SectionBody {...props}>
      <div>{leftContent}</div>
      <div>{rightContent}</div>
    </SectionBody>
  </EditSegment>
);

export const EditFlexOptionToolbar = props => (
  <EditSegmentToolbar
    {...props}
    className="timeline-highlight"
    style={{ backgroundColor: Variables._timeline_bg_color }}
  />
);

// Section Components
export const TextSection = styled.div`
  padding: 6px 0;
  p,
  blockquote {
    line-height: ${Type.Leading.tallest};
  }
  blockquote {
    ${withIconQuotes({ color: Color.Secondary._30 })}
    font-size: ${Type.Scale._2};
    padding: ${Grid._3};
    margin: ${Grid._4} ${Grid._3};
    border-left: 3px solid ${Color.Blue._50};
  }
  ul {
    padding-left: ${Grid._4};
    line-height: ${Type.Leading.tallest};
  }
`;

export const ScriptSection = styled.div`
  ${props =>
    sectionIcon({
      color: props.leader === 'small_group_leader' ? Color.Yellow._80 : Color.Gray._80,
      unicode: '"\\f028"',
      left: '20px',
      centered: false,
    })};
  position: relative;
  background: ${props =>
    props.leader === 'small_group_leader'
      ? `radial-gradient(72.41% 72.41% at 28.65% 19.69%, ${Color.Yellow._20} 0%, ${Color.Yellow._30} 100%);`
      : `radial-gradient(
    72.41% 72.41% at 28.65% 19.69%,
    ${Color.Secondary._20} 0%,
    ${Color.Secondary._30} 100%
  )`};
  border: ${props =>
    props.leader === 'small_group_leader' ? `1px solid ${Color.Yellow._30}` : `1px solid ${Color.Secondary._30}`};
  box-sizing: border-box;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  border-radius: ${Border.radius};
  text-align: center;
  padding: 16px;
  margin-bottom: ${Grid._4};
  h5 {
    font-size: ${Type.Scale._2};
    color: ${props => (props.leader === 'small_group_leader' ? Color.Yellow._80 : Color.Gray._80)};
    margin-bottom: ${Grid._3};
  }
  p {
    ${props =>
      props.leader === 'small_group_leader'
        ? withIconQuotes({ color: Color.Yellow._40 })
        : withIconQuotes({ color: Color.Secondary._40 })}
    line-height: 2;
    margin: 0;
    font-size: ${Type.Scale._3};
    color: ${props => (props.leader === 'small_group_leader' ? Color.Yellow._100 : Type.Color.dark)};
  }
  @media print, (min-width: ${Breakpoints.screen_sm}) {
    text-align: left;
    padding: ${Grid._4} ${Grid._8} ${Grid._4} 72px;
    &:before {
      top: ${Grid._4};
    }
  }
`;

export const TipSectionContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  border-radius: ${Border.radius};
  text-align: center;
  padding: ${Grid._4};
  margin-bottom: ${Grid._4};

  h5 {
    font-size: ${Type.Scale._2};
    font-weight: ${Type.Weight.semibold};
  }

  p {
    line-height: ${Type.Leading.tallest};
  }

  select {
    &:focus {
      box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px inherit, inherit;
    }
  }

  @media print, (min-width: ${Breakpoints.screen_sm}) {
    text-align: left;
    padding: ${Grid._4} ${Grid._8} ${Grid._4} ${Grid._11};
  }
`;

const LeaderTipSection = styled(TipSectionContainer)`
  ${sectionIcon({
    color: Color.Blue._50,
    unicode: '"\\f0eb"',
    left: Grid._6,
  })}

  background: ${Color.Secondary._05};
  border: 1px solid ${Color.Secondary._20};
`;

const SafetyTipSection = styled(TipSectionContainer)`
  ${sectionIcon({
    color: Color.Yellow._50,
    unicode: '"\\f071"',
    left: Grid._5,
  })}

  background: ${Color.Yellow._05};
  border: 1px solid ${Color.Yellow._10};
  select {
    &:focus {
      box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Yellow._50},
        0px 0px 0px 5px rgba(217, 184, 87, 0.3);
    }
  }
`;

export const TipSection = ({ type, children, ...props }) => {
  switch (type) {
    case 'leader':
      return <LeaderTipSection {...props}>{children}</LeaderTipSection>;
    case 'safety':
      return <SafetyTipSection {...props}>{children}</SafetyTipSection>;
    default:
      return null;
  }
};

//Use to compose Presentation and Bible Verse Practice sections
export const IconSectionContainer = styled.div`
  height: calc(${Variables._timelineVerticalSpacer} * 6);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  i {
    color: ${Type.Color.medium};
    font-size: ${Type.Scale._6};
  }
  h5 {
    font-size: ${Type.Scale._3};
    background: ${Variables._timeline_bg_color};
    z-index: 1;
    padding: ${Grid._3} ${Grid._4} ${Grid._4} ${Grid._4};
  }
  p {
    margin: 0;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    bottom: 0;
    right: 0;
    border-top: 1px solid ${Color.Gray._30};
    z-index: 1;
  }
`;

const IconSection = ({ iconClass, headerText, descriptionText }) => (
  <IconSectionContainer>
    <i className={iconClass}></i>
    <h5>{headerText}</h5>
    <p>{descriptionText}</p>
  </IconSectionContainer>
);

export const VideoSection = ({ videoName, fileName }) => {
  const { sectionTypeMap } = useSessionTimeline();

  const getDisplayName = () => {
    if (videoName) return videoName;
    if (fileName) return fileName.split('.')[0];
    return 'the presentation video';
  };

  return (
    <IconSection
      iconClass={sectionTypeMap['video'].iconClass}
      headerText="Presentation"
      descriptionText={`Show ${getDisplayName()}`}
    />
  );
};

VideoSection.propTypes = {
  videoName: PropTypes.string,
  fileName: PropTypes.string,
};

export const SuppliesSection = styled.div`
  ul {
    padding: 0;
    list-style: none;
  }

  ${ListItemFlexRow} {
    display: grid;
    grid-template-columns: 1fr 1fr ${Grid._5};
    padding: ${Grid._4};
    min-height: ${Grid._8};

    h5 {
      margin: 0;
      color: ${Type.Color.dark};
      font-weight: ${Type.Weight.medium};
      font-size: ${Type.Scale._2};
    }

    p {
      margin: 0;
      color: ${Type.Color.medium};
    }
  }
`;

//  Section Builder Components
const AddSectionContainer = styled(TimelineComponent)`
  height: calc(${Variables._timelineVerticalSpacer} * 2);
  div:last-child {
    display: flex;
    justify-content: center;
    > div {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        bottom: 0;
        right: 0;
        border-top: 2px dashed ${Color.Gray._30};
        z-index: 1;
      }
      button {
        z-index: 2;
      }
    }
  }
`;

export const AddSectionPlaceholder = ({ timelineClassName, showModal }) => (
  <AddSectionContainer>
    <div></div>
    <SectionBody className={timelineClassName}>
      <div>
        <OutlineButton
          data-qa-hook="addSectionButton"
          onClick={() => showModal(true)}
          style={{ backgroundColor: 'white' }}
        >
          Add Section
        </OutlineButton>
      </div>
    </SectionBody>
  </AddSectionContainer>
);

export const AddFlexOptionPlaceholder = ({ showModal }) => (
  <AddSectionContainer>
    <div></div>
    <SectionBody>
      <div>
        <TertiaryButton onClick={() => showModal(true)}>Add Flex Option</TertiaryButton>
      </div>
    </SectionBody>
  </AddSectionContainer>
);

const SectionHeaderContainer = styled.div`
  height: calc(${Variables._timelineVerticalSpacer} * 2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  h5 {
    color: ${Type.Color.dark};
    font-weight: ${Type.Weight.semibold};
    display: flex;
    align-items: center;
    font-size: ${Type.Scale._2};
    text-transform: capitalize;
    i {
      color: ${Type.Color.medium};
      font-size: ${Type.Scale._4};
      margin-right: ${Grid._3};
    }
  }
`;

export const SectionMediaItem = styled.div.attrs(() => ({
  'data-tracking-component': 'timeline-section-media',
}))`
  position: relative;
  aspect-ratio: 16 / 9;
  margin: 0 0 ${Grid._4} ${Grid._4};
  cursor: ${props => (!props.onClick ? 'initial' : 'pointer')};
  @media only screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: 0 calc(16px + 5px) 0 ${Grid._3};
  }
  @media only screen and (min-width: ${Breakpoints.screen_lg}) {
    margin: 0 calc(32px + 5px) 0 ${Grid._4};
  }
`;

export const SectionMediaPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Type.Color.medium};
  background: ${Color.Gray._10};
  border: 2px solid ${Color.Gray._30};
  border-radius: ${Border.radius};
  width: 100%;
  height: 100%;
`;

export const AddSectionMedia = styled(SectionMediaPlaceholder)`
  border: 2px dashed ${Color.Gray._30};
`;

export const SectionHeader = ({ type, onRemove, children }) => {
  const { sectionTypeMap } = useSessionTimeline();

  return (
    <SectionHeaderContainer>
      <h5>
        <i className={`${sectionTypeMap[type].iconClass}`}></i>
        {type} Section
      </h5>
      <ButtonGroup>
        {children}
        {onRemove && (
          <SmallRoundedIconButton data-qa-hook="sectionRemove" onClick={onRemove}>
            <i className="far fa-trash-alt"></i>
          </SmallRoundedIconButton>
        )}
      </ButtonGroup>
    </SectionHeaderContainer>
  );
};

export const NoteSectionContainer = styled(TipSectionContainer)`
  ${sectionIcon({
    color: Color.white,
    unicode: '"\\f249"',
    left: Grid._5,
  })}

  background: ${Color.Blue._50};
  border: 1px solid ${Color.Blue._60};
  position: relative;
  text-shadow: ${Type.Shadow.dark};

  h5 {
    color: ${Color.white};
  }

  p {
    color: ${Color.Gray._075};
  }
`;

const RemoveNoteButton = styled.button`
  background: none;
  border: none;

  position: absolute;
  top: ${Grid._1};
  right: ${Grid._1};
  padding: ${Grid._1} ${Grid._3};
  font-size: 16px;

  :focus-visible {
    outline: 1px solid ${Color.white};
  }

  i {
    color: ${Color.white};
    text-shadow: ${Type.Shadow.dark};
  }
`;

export const NoteSection = ({ note, onRemove }) => (
  <TimelineComponent>
    <SectionBody>
      <NoteSectionContainer data-qa-hook="noteContainer">
        {note.title && <h5 data-qa-hook="noteTitle">{note.title}</h5>}
        <p data-qa-hook="noteBody" dangerouslySetInnerHTML={{ __html: note.body }}></p>

        {onRemove && (
          <RemoveNoteButton data-qa-hook="removeNote" onClick={onRemove}>
            <i className="fas fa-times" />
          </RemoveNoteButton>
        )}
      </NoteSectionContainer>
    </SectionBody>
  </TimelineComponent>
);
