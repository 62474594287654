angular.module('lwNamb').controller(
  'acceptInviteCtrl',

  [
    '$scope',
    '$location',
    '$log',
    '$routeParams',
    'userService',
    'membershipService',
    'blockUX',
    'windowService',
    'profileService',
    'inviteService',
    'alertService',
    function(
      $scope,
      $location,
      $log,
      $routeParams,
      userService,
      membershipService,
      blockUX,
      windowService,
      profileService,
      inviteService,
      alertService
    ) {
      var init = function() {
        $scope.errorMessage = '';
        $scope.emailMismatch = false;

        userService.user().then(
          function(user) {
            $scope.user = user;
            if ($routeParams.forEmail !== undefined) {
              $scope.forEmail = $routeParams.forEmail.replace(' ', '+');
              $scope.acceptForOrg({ id: $routeParams.orgId });
            } else {
              inviteService.lookupInvitesForEmail($scope.user.logInEmail).then(
                function(invites) {
                  $scope.forEmail = $scope.user.logInEmail;
                  $scope.invites = invites;
                },
                function() {
                  $scope.redirect();
                }
              );
            }
          },
          function() {
            showError();
          }
        );
      };

      $scope.redirect = function() {
        windowService.redirectHash(userService.getRedirectHashAfterLogin($scope.user));
      };

      $scope.acceptForOrg = function(orgId) {
        blockUX.start('Accepting Invite...', 0, 60000, false, 'Error');
        $scope.orgId = orgId;

        if ($scope.forEmail !== $scope.user.logInEmail) {
          blockUX.stop();
          $scope.emailMismatch = true;
        } else {
          $scope.acceptInvite();
        }
      };

      $scope.acceptInvite = function() {
        function check() {
          membershipService
            .joinOrgViaInvite(
              $scope.user.userId,
              $scope.orgId.id,
              $scope.forEmail,
              $scope.user.logInEmail,
              $scope.user.lastSelectedAccount
            )
            .then(userService.checkSession)
            .then(success)
            .catch(failure);
        }

        if ($scope.invites === undefined) {
          $scope.forEmail = $routeParams.forEmail.replace(' ', '+');
          inviteService.lookupInvitesForEmail($scope.forEmail).then(
            function(invites) {
              $scope.invites = invites;
              check();
            },
            function(reason) {
              $log.error(reason);
              check();
            }
          );
        } else {
          check();
        }
      };

      function success() {
        blockUX.stop();
        alertService.show({ title: 'invite accepted', type: 'success', show: true, dismissible: true, duration: 5 });
        if ($scope.invites !== undefined)
          $scope.invites.splice(
            $scope.invites.indexOf(
              $scope.invites.filter(function(invite) {
                return invite.orgId === $scope.orgId;
              })
            ),
            1
          );
        $scope.redirect();
      }

      function failure(response) {
        $scope.error = true;
        blockUX.stop();
        showError(response);
      }

      function showError(response) {
        $log.error(response);
        //Show Error
        $scope.error = true;
        if (response === 'NO_PENDING_INVITES') {
          $scope.errorHeader = 'This Invitation Is Not Valid!';
          $scope.errorMessage = 'Please contact the person who sent you this invitation.';
        } else if (response === 'INVITES_ARE_DEACTIVATED') {
          $scope.errorHeader = 'This Invitation Has Been Deactivated!';
          $scope.errorMessage = 'Please contact the person who sent you this invitation.';
        } else if (response === 'NO_SEATS_AVAILABLE') {
          $scope.errorHeader = "Uh oh! It looks like there aren't any open seats left for you. ";
          profileService.getProfile($scope.invitorId).then(
            function(user) {
              $scope.errorMessage +=
                'Please contact ' + user.email + " to receive access. They'll need to add more seats. ";
            },
            function() {
              $scope.errorMessage =
                "Please contact the person who sent you this invitation to receive access. They'll need to add more seats.";
            }
          );
        } else {
          $scope.errorHeader = 'Something Went Wrong!';
          $scope.errorMessage = "Oops! We're sorry for the inconvenience. Please try again later.";
        }
      }

      init();
    },
  ]
);
