import React from 'react';
import styled from 'styled-components';
import { Grid, Border, Color, Type } from '../StyleGuide';

function ErrorIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
      <path d="M256 48C141.6 48 48 141.601 48 256s93.6 208 208 208 208-93.601 208-208S370.4 48 256 48zm24 312h-48v-40h48v40zm0-88h-48V144h48v128z" />
    </svg>
  );
}

const Div = styled.div`
  font-size: ${Type.Scale._2};
  font-weight: ${Type.Weight.semibold};
  color: ${Color.Red._70};
  margin: ${Grid._3} auto;
  display: flex;
  align-items: center;
  justify-content: ${props => props.align || 'center'};
  background: ${Color.Red._05};
  padding: ${Grid._3};
  border: 1px solid ${Color.Red._15};
  border-radius: ${Border.radius};
  width: 100%;
  svg {
    min-width: ${Type.Scale._6};
    height: ${Type.Scale._3};
    fill: ${Color.Red._70};
  }
  a {
    margin: ${Grid._3};
  }
`;

function ErrorMessage(props) {
  return (
    <Div role="alert" data-qa-hook="errorMessage" {...props}>
      <ErrorIcon />{' '}
      {props.children ?? (
        <>
          A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
        </>
      )}
    </Div>
  );
}

export default ErrorMessage;
