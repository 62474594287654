import React from 'react';
import PropTypes from 'prop-types';
import { Badge, SuccessBadge, InfoBadge } from '../../components/Badge';
import { SESSION_TIMELINE_STATUS } from '../../services/sessionTimelineService';

export default function SessionTimelineStatusBadge({ status, updatedBy, updatedDate }) {
  const updatedByText = updatedBy ? ` by ${updatedBy}` : '';
  const updatedDateText = updatedDate ? ` on ${updatedDate}` : '';
  const postfix = `${updatedByText}${updatedDateText}`;

  return status === SESSION_TIMELINE_STATUS.IN_PROGRESS ? (
    <Badge data-qa-hook="statusBadge">{`In Progress${postfix}`}</Badge>
  ) : status === SESSION_TIMELINE_STATUS.IN_REVIEW ? (
    <InfoBadge data-qa-hook="statusBadge">Ready for Review{postfix}</InfoBadge>
  ) : status === SESSION_TIMELINE_STATUS.PUBLISHED ? (
    <SuccessBadge data-qa-hook="statusBadge">Published{postfix}</SuccessBadge>
  ) : (
    <></>
  );
}

SessionTimelineStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
  updatedBy: PropTypes.string,
  updatedDate: PropTypes.string,
};
