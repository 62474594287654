angular.module('lwNamb').controller(
  'HelpCtrl',

  [
    '$rootScope',
    '$scope',
    '$log',
    '$window',
    'alertService',
    'userService',
    'apiUrlService',
    function($rootScope, $scope, $log, $window, alertService, userService, apiUrlService) {
      var init = function() {
        $scope.props = {
          channel: '#bethlehem-feedback',
          user: {},
        };
        $scope.gitHash = apiUrlService.getCommitHash();
        $scope.submitted = false;
        $scope.sendFeedbackForm = {};
        loadUser();
      };

      function loadUser() {
        userService.user().then(
          function(user) {
            $scope.user = user;
            $scope.props.user = {
              displayName: user.displayName,
              email: user.logInEmail,
              userId: user.userId,
              currentOrgId: user.lastSelectedAccount,
              currentOrgName: $rootScope.org === undefined ? undefined : $rootScope.org.name,
              impersonatedBy: user.impersonating === true ? user.impersonatingWas : undefined,
              browserInfo: navigator.userAgent,
              gitHash: $scope.gitHash,
            };
          },
          function() {
            $scope.props.user = {
              browserInfo: navigator.userAgent,
              gitHash: $scope.gitHash,
            };
            $log.error('No user returned in HelpCtrl');
          }
        );
      }

      $scope.$on('ORG_LOADED', function() {
        $scope.props.user.currentOrgName = $rootScope.org === undefined ? undefined : $rootScope.org.name;
      });

      $scope.$on('UserLoggedIn', function() {
        loadUser();
      });

      $scope.sendFeedback = function(name, subject, message, isValid) {
        if (!isValid) {
          $scope.submitted = true;
          return;
        }
        var orgName = $scope.org === undefined ? '' : $scope.org.name;
        //Send Command for submit feedback
        userService
          .sendFeedbackEmail(
            $scope.user.userId,
            name,
            $scope.user.logInEmail,
            subject,
            message,
            $window.location.origin,
            orgName,
            navigator.userAgent,
            $scope.user.id,
            $scope.gitHash
          )
          .then(
            function() {
              //Display Alert to confirm feedback has been sent
              alertService.show({ type: 'success', title: 'Feedback Sent', duration: 5 });
              $scope.sendFeedbackForm.$setPristine();
              $scope.feedback = {};
              $scope.submitted = false;
            },
            function(reason) {
              $log.error(reason);
              alertService.show({
                type: 'danger danger-with-content',
                title: 'Unexpected Error!',
                content: 'Something went wrong. Please try again.',
                duration: 20,
              });
            }
          );
      };

      init();
    },
  ] //End Account Ctrl
);
