angular.module('lwNamb').controller(
  'IssueBuilderCtrl',

  [
    '$scope',
    '$log',
    'curriculumService',
    'alertService',
    '$routeParams',
    'windowService',
    function($scope, $log, curriculumService, alertService, $routeParams, windowService) {
      function init() {
        $scope.loading = true;
        $scope.curriculumId = $routeParams.curriculumId;
        $scope.issueId = $routeParams.issueId;
        $scope.issue = {};
        $scope.session = { name: '' };
        $scope.sortableOptions = {
          orderChanged: $scope.orderChanged,
          containerPositioning: 'relative',
          additionalPlaceholderClass: 'panel-boxshadow',
        };
        loadData();
      }

      function loadData() {
        curriculumService
          .getCurriculumIssueAndSessions($scope.issueId, $scope.curriculumId)
          .then(
            function(response) {
              $scope.issue = response.issue;
              if ($scope.issue.publishStartDate !== undefined) {
                $scope.willBePublished = new Date($scope.issue.publishStartDate) > new Date();
              }
              $scope.sessions = response.sessions;
              $scope.useDates = $scope.sessions.map(function(session) {
                return session.useDate;
              });
              if ($scope.sessions.length === 0) {
                createPlaceholder($scope.issue.firstUseDate, 1);
              } else if ($scope.sessions.length < $scope.issue.sessionCount) {
                createPlaceholder($scope.sessions[$scope.sessions.length - 1].useDate, $scope.sessions.length + 1);
              }
            },
            function(reason) {
              $log.error(reason + ' error in IssueBuilderCtrl');
            }
          )
          .finally(function() {
            $scope.loading = false;
          });
      }

      function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      }

      function removeDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
      }
      
      function createPlaceholder(useDate, index) {
        var date = index > 1 ? addDays(useDate, 7) : new Date(useDate);
        $scope.sessionPlaceholder = {
          date: date,
          index: index,
          publishStartDate: removeDays(date, 63),
          publishEndDate: addDays(date, 28)
        };
      }

      $scope.createSession = function(valid, name, date, publishStartDate, publishEndDate) {
        $scope.submitted = true;
        if (valid) {
          $scope.creating = true;
          curriculumService
            .createSession(name, date, $scope.issueId, $scope.curriculumId, publishStartDate, publishEndDate)
            .then(
              function(response) {
                $scope.redirectToSession(response);
              },
              function(response) {
                $log.error(response + ' failed to create session');
              }
            )
            .finally(function() {
              $scope.creating = false;
            });
        }
      };

      $scope.orderChanged = function() {
        $scope.sessions.map(function(session, i) {
          session.useDate = $scope.useDates[i];
        });
        curriculumService.reorderSessionsForIssue($scope.sessions, $scope.issueId).then(
          function() {
            alertService.show({ title: 'Order Saved', type: 'success', duration: 5 });
          },
          function() {
            alertService.show({
              type: 'danger danger-with-content',
              title: 'Reorder Error!',
              content: 'Something went wrong. Please try again.',
              duration: 20,
            });
          }
        );
      };

      $scope.redirectToSession = function(sessionId) {
        windowService.redirectHash(
          '/curriculum/' + $scope.curriculumId + '/issue/' + $scope.issueId + '/session-builder/' + sessionId
        );
      };

      $scope.redirectToSupportMaterials = function() {
        windowService.redirectHash(
          '/curriculum/' + $scope.curriculumId + '/issue/' + $scope.issueId + '/support-materials-builder'
        );
      };

      $scope.clearForm = function() {
        $scope.session = {
          name: '',
        };
        $scope.submitted = false;
      };

      init();
    },
  ]
);
