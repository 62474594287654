angular.module('lwNamb').constant(
  'properties',
  (function() {
    var permissions, icons, tabs, headers, sections;

    permissions = {
      build: 'Build View',
      author: 'Author',
      org: 'Account View',
      invite: 'Roles View',
      groups: 'Groups View',
      coach: 'Coach View',
      curriculum: 'Build Curriculum View',
      assign: 'Assign View',
    };

    icons = {
      settings: {
        classes: 'icon ion-settings',
      },
      church: {
        classes: 'fas fa-church fa-fw',
      },
      users: {
        classes: 'fas fa-user-friends',
      },
      book: {
        classes: 'icon ion-ios-book',
      },
      envelope: {
        classes: 'fas fa-envelope fa-fw',
      },
      clipboard: {
        classes: 'icon ion-android-clipboard',
      },
    };

    tabs = {
      org: 'Organization',
      invite: 'Invites',
      build: 'Build',
      author: 'Authoring',
      groups: 'Groups',
      coach: 'Coach',
      catalog: 'Catalog',
    };

    headers = {
      // todo: refactor for new menu and add more
      org: {
        containerClass: 'header-org',
        icon: icons.church,
        text: 'Organization',
      },
      build: {
        containerClass: 'header-tasks',
        icon: icons.settings,
        text: 'Build',
      },
      author: {
        containerClass: 'header-invites',
        icon: icons.settings,
        text: 'Authoring',
      },
      groups: {
        containerClass: 'header-groups',
        iconClass: 'fas fa-users',
        text: 'Groups',
      },
      catalog: {
        containerClass: 'header-org',
        icon: icons.book,
        text: 'Training',
      },
    };

    sections = {
      org: {
        tab: tabs.org,
        permission: permissions.org,
        header: headers.org,
      },
      invite: {
        tab: tabs.invite,
        permission: permissions.invite,
      },
      build: {
        tab: tabs.build,
        permission: permissions.build,
        header: headers.build,
      },
      author: {
        tab: tabs.author,
        permission: permissions.author,
        header: headers.author,
      },
      groups: {
        tab: tabs.groups,
        permission: permissions.groups,
        header: headers.groups,
      },
      scorecards: {
        tab: tabs.groups,
        permission: permissions.build,
        header: headers.groups,
      },
      coach: {
        tab: tabs.coach,
        permission: permissions.coach,
      },
      curriculum: {
        tab: tabs.build,
        permission: permissions.curriculum,
      },
      catalog: {
        tab: tabs.catalog,
        header: headers.catalog,
      },
      assign: {
        permission: permissions.assign,
      },
    };

    return {
      permissions: permissions,
      headers: headers,
      sections: sections,
    };
  })()
);
