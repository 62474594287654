import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Type } from '../StyleGuide';
import ProgressBar from '../components/ProgressBar';
import WhiteLabel from '../filters/WhiteLabel';

const formatDate = date => new Date(date).toLocaleDateString();

const Container = styled.div`
  ul {
    list-style: none;
    margin: ${Grid._4} 0;
    padding: 0;
  }
  li {
    color: ${Type.Color.medium};
    display: inline-block;
    &:not(:last-of-type)::after {
      content: '•';
      margin: 0 ${Grid._3};
    }
  }
`;

const CourseInformation = ({ course, numCompletedSteps, progress }) => {
  const isComplete =
    course.progress && course.progress._type ? course.progress._type === 'Finished' : course.status === 'complete';
  const completedOn =
    isComplete && course.completedOn ? formatDate(course.completedOn) : formatDate(course.timeCompleted);
  const dueDate = course.dueDate && formatDate(course.dueDate);

  return (
    <Container>
      <ul>
        {isComplete ? (
          <li>
            {numCompletedSteps} {WhiteLabel.labels.step + (numCompletedSteps > 1 ? 's' : '')} completed on {completedOn}
          </li>
        ) : (
          <li>
            {numCompletedSteps} of {course.tasks.length} {WhiteLabel.labels.step + (course.tasks.length > 1 ? 's' : '')}{' '}
            complete
          </li>
        )}
        {!isComplete && dueDate && <li data-qa-hook="assignedTaskListPanelDueDate">Finish by {dueDate}</li>}
      </ul>
      <ProgressBar progress={progress} className="tl-progress-bar" />
    </Container>
  );
};

CourseInformation.propTypes = {
  course: PropTypes.object.isRequired,
  numCompletedSteps: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
};

export default CourseInformation;
