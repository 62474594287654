const redirectStorageKey = 'afterPurchaseRedirect';
const storage = localStorage;

const ItemRedirectMap = (hostname => {
  switch (hostname) {
    case 'grid.local.lifeway.com':
    case 'ministrygrid.dev.lifeway.com':
      return {
        '005841738': '/catalog/compilation/9b3b4f2c-e88c-42bb-edf1-9939944edd8e',
      };
    case 'ministrygrid.stage.lifeway.com':
      return {
        '005841738': '/catalog/compilation/ea427536-b697-4efc-a08d-2e0046a4a0c5',
      };
    default:
      return {
        '005841738': '/catalog/compilation/d6e892a1-d121-4249-b80a-1f3775b99267',
      };
  }
})(window.location.host);

const purchaseService = {
  storeRedirectLocation: (location = window.location.hash) => {
    storage.setItem(redirectStorageKey, location);
  },

  getRedirectLocation: (itemNumbers = []) => {
    const storedLocation = storage.getItem(redirectStorageKey);
    if (storedLocation) return storedLocation;

    const matchedLocation = itemNumbers.reduce(
      (location, itemNumber) => (location ? location : ItemRedirectMap[itemNumber]),
      undefined
    );

    return matchedLocation;
  },

  clearRedirectLocation: () => {
    storage.removeItem(redirectStorageKey);
  },
};

export default purchaseService;
