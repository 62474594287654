export const addRemovableEventListener = (element, type, listener, options) => {
  element.addEventListener(type, listener, options);
  return () => element.removeEventListener(type, listener, options);
};

export const requestFullscreen = (element, options) => {
  if (element.requestFullscreen) return element.requestFullscreen(options);
  if (element.webkitRequestFullscreen) return element.webkitRequestFullscreen(options);
  if (element.mozRequestFullscreen) return element.mozRequestFullscreen(options);
  if (element.msRequestFullscreen) return element.msRequestFullscreen(options);
};

export const stopProp = fn => e => {
  e.stopPropagation();
  fn(e);
};
