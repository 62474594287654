import React from 'react';
import styled from 'styled-components';
import WhiteLabel from '../filters/WhiteLabel';
import { RaisedContainer } from '../components/Layout';
import BrowserItem from './BrowserItem';
import { Grid } from '../StyleGuide';

const Header = styled.div`
  width: 100%;
  margin-top: ${Grid._5};
  position: relative;
  text-align: center;
`;

const Logo = styled.img`
  display: block;
  margin: auto;
  width: 175px;
  padding: ${Grid._2} 0;
`;

const BrowserSupportCard = styled(RaisedContainer)`
  margin-top: ${Grid._5};
  padding: ${Grid._7};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BrowserRow = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  width: 100%;
`;

const browsers = [
  {
    title: 'Google Chrome',
    src: 'img/chrome.png',
    alt: 'Google Chrom Logo',
    link: 'https://www.google.com/chrome',
  },
  { title: 'Mozilla Firefox', src: 'img/firefox.png', alt: 'Mozilla Firefox Logo', link: 'https://www.getfirefox.com' },
  { title: 'Safari', src: 'img/safari.png', alt: 'Safari Logo', link: 'https://www.apple.com/support/mac-apps/safari' },
  { title: 'Opera', src: 'img/opera.png', alt: 'Opera Logo', link: 'https://www.opera.com/' },
  {
    title: 'Microsoft Edge',
    src: 'img/microsoft_edge128.png',
    alt: 'Microsoft Edge Logo',
    link: 'https://www.microsoft.com/en-us/windows/microsoft-edge',
  },
];

const BrowserSupport = () => {
  const email = WhiteLabel.contact.email || 'support@lifeway.com';

  return (
    <div>
      <Header>
        <Logo src={WhiteLabel.logoSrc} />
      </Header>

      <BrowserSupportCard>
        <p>Having trouble with your browser? We're here to help!</p>
        <p>
          Feel free to contact us at <a href={`mailto:${email}`}>{email}</a>
        </p>
        <h4>Supported Browsers: </h4>
        <BrowserRow>
          {browsers.map(browser => {
            return <BrowserItem key={browser.title} {...browser} />;
          })}
        </BrowserRow>
      </BrowserSupportCard>
    </div>
  );
};

export default BrowserSupport;
