import React from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../authentication';
import Breadcrumbs from '../components/Breadcrumbs';
import { OutlineButton, TileButton } from '../components/Buttons';
import EmptyState from '../components/EmptyState';
import ErrorMessage from '../components/ErrorMessage';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import LoadingIndicator from '../components/LoadingIndicator';
import SettingsHeader from '../components/SettingsHeader';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';
import windowService from '../services/windowService';
import { stopProp } from '../utils/domUtils';
import { ActionsContainer } from './components/ActionsContainer';
import { CourseStepListItem } from './components/CourseStepListItem';
import {
  ContentListContainer,
  CourseTitleContainer,
  HeaderButtonGroup,
  HeaderRow,
  MainSectionContainer,
  PageContainer,
  PublishButton,
} from './components/LayoutElements';
import { SortableCourseStepList } from './components/SortableCourseStepList';
import { useCourseBuilder } from './hooks/useCourseBuilder';
import { useCourseModal } from './hooks/useCourseModal';
import { CourseStep } from './models/course';
import { UrlParams } from './models/url';

export const CourseBuilder = () => {
  const user = useUser();
  const { buildType, trainingId } = useParams<UrlParams>();
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();
  const {
    course,
    steps,
    isOwner,
    isLoading,
    isError,
    reorder,
    removeStep,
    publishCourse,
    unpublishCourse,
    handleCourseCopy,
    hasSteps,
  } = useCourseBuilder();

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage courses', route: `#${manageTrainingRoutePrefix}/courses` },
    { name: course?.name || '' },
  ];

  const redirectToSettings = () => {
    windowService.redirectTo(`${manageTrainingRoutePrefix}/courses/${trainingId}/settings`);
  };

  const redirectToStep = (step: CourseStep) => {
    windowService.redirectTo(`#/${buildType}/courses/${trainingId}/tasks/${step._id.id}?taskType${step._type}`);
  };

  const getNextStepOrder = () => steps.reduce((max, step) => Math.max(max, step.order), 0) + 1;

  const {
    modal,
    openAddStepModal,
    openRemoveStepModal,
    openEasyLinkModal,
    openPublishModal,
    openUnpublishModal,
    openCopyModal,
    dismissModal,
  } = useCourseModal();

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <PageContainer>
        {isLoading ? (
          <LoadingIndicator />
        ) : isError ? (
          <ErrorMessage />
        ) : (
          course && (
            <>
              {hasSteps &&
                (course.published ? (
                  <HelpAndInfoBox
                    title="This Course Has Been Published"
                    description="Publishing a course makes it searchable and available to be assigned."
                  />
                ) : (
                  <HelpAndInfoBox
                    title="Publish Course"
                    description="Publishing a course makes it searchable and available to be assigned."
                  >
                    <PublishButton onClick={() => openPublishModal(publishCourse)}>Publish</PublishButton>
                  </HelpAndInfoBox>
                ))}

              <HeaderRow>
                <SettingsHeader title={course.name} subtitle="Course" icon={<i className="fas fa-tasks"></i>} />
                <HeaderButtonGroup>
                  <TileButton onClick={redirectToSettings}>
                    <i className="fas fa-pencil-alt"></i> Edit Settings
                  </TileButton>
                  {hasSteps && (
                    <TileButton onClick={() => openCopyModal(name => handleCourseCopy(name, course.owner))}>
                      <i className="fas fa-copy"></i>Copy Course
                    </TileButton>
                  )}
                </HeaderButtonGroup>
              </HeaderRow>

              <MainSectionContainer>
                <ContentListContainer>
                  <CourseTitleContainer>
                    <h4>Course Steps</h4>
                    <OutlineButton actionable onClick={() => openAddStepModal(getNextStepOrder(), isOwner)}>
                      <i className="icomoon-addList"></i>Add Step
                    </OutlineButton>
                  </CourseTitleContainer>

                  <SortableCourseStepList onSort={reorder}>
                    {hasSteps ? (
                      steps.map((step: CourseStep, index: number) => (
                        <CourseStepListItem
                          index={index}
                          key={step._id.id}
                          step={step}
                          onClick={() => redirectToStep(step)}
                          onRemove={stopProp(() =>
                            openRemoveStepModal(step.taskName, () => removeStep(step._id.id).finally(dismissModal))
                          )}
                        />
                      ))
                    ) : (
                      <EmptyState
                        title="No Steps Added"
                        description="Please use the Add Step button to begin creating steps."
                      />
                    )}
                  </SortableCourseStepList>
                </ContentListContainer>

                <ActionsContainer
                  canAssign={hasSteps}
                  isPublished={course.published}
                  onEasyLinkClick={() => openEasyLinkModal(trainingId, course.name, user?.lastSelectedAccount || '')}
                  onUnpublishClick={() => openUnpublishModal(unpublishCourse)}
                />
              </MainSectionContainer>

              {modal}
            </>
          )
        )}
      </PageContainer>
    </>
  );
};
