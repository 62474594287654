import React from 'react';
import useModal from '../../hooks/useModal';
import { AddCourseStepModal } from '../components/modals/AddCourseStepModal';
import { RemoveTrainingStepModal } from '../components/modals/RemoveTrainingStepModal';
import { TrainingEasyLinkModal } from '../components/modals/TrainingEasyLinkModal';
import { PublishTrainingModal } from '../components/modals/PublishTrainingModal';
import { UnpublishTrainingModal } from '../components/modals/UnpublishTrainingModal';
import { CloneTrainingModal } from '../components/modals/CloneTrainingModal';

type ModalType = 'addStep' | 'removeStep' | 'easyLink' | 'publishModal' | 'unpublishModal' | 'cloneTraining';

type AddStepPayload = {
  position: number;
  isOwner: boolean;
};

type RemoveStepPayload = {
  stepName: string;
  remove: () => Promise<void>;
};

type EasyLinkPayload = {
  trainingId: string;
  trainingName: string;
  orgId: string;
};

type HandlerPayload<T = null> = {
  handler: (data?: T) => Promise<void>;
};

type Payload = AddStepPayload | RemoveStepPayload | EasyLinkPayload | HandlerPayload | HandlerPayload<string>;

export const useCourseModal = () => {
  const [modal, openModal, dismissModal] = useModal((type: ModalType, payload: Payload, dismissHandler: () => void) => {
    if (type === 'addStep') {
      const { position, isOwner } = payload as AddStepPayload;
      return <AddCourseStepModal position={position} isOwner={isOwner} handleDismiss={dismissHandler} />;
    }

    if (type === 'removeStep') {
      const { stepName, remove } = payload as RemoveStepPayload;
      return <RemoveTrainingStepModal subtext={stepName} handleSubmit={remove} handleDismiss={dismissHandler} />;
    }

    if (type === 'easyLink') {
      const { trainingId, trainingName, orgId } = payload as EasyLinkPayload;
      return (
        <TrainingEasyLinkModal
          trainingId={trainingId}
          trainingName={trainingName}
          orgId={orgId}
          handleDismiss={dismissHandler}
        />
      );
    }

    if (type === 'publishModal') {
      const { handler } = payload as HandlerPayload;
      return <PublishTrainingModal trainingType="Course" publish={handler} handleDismiss={dismissHandler} />;
    }

    if (type === 'unpublishModal') {
      const { handler } = payload as HandlerPayload;
      return <UnpublishTrainingModal trainingType="Course" unpublish={handler} handleDismiss={dismissHandler} />;
    }

    if (type === 'cloneTraining') {
      const { handler } = payload as HandlerPayload<string>;
      return <CloneTrainingModal trainingType="Course" handleCopy={handler} handleDismiss={dismissHandler} />;
    }

    return null;
  });

  const openAddStepModal = (position: number, isOwner: boolean) => openModal('addStep', { position, isOwner });

  const openRemoveStepModal = (stepName: string, remove: () => Promise<void>) =>
    openModal('removeStep', { stepName, remove });

  const openEasyLinkModal = (trainingId: string, trainingName: string, orgId: string) =>
    openModal('easyLink', { trainingId, trainingName, orgId });

  const openPublishModal = (handler: () => Promise<void>) => openModal('publishModal', { handler });

  const openUnpublishModal = (handler: () => Promise<void>) => openModal('unpublishModal', { handler });

  const openCopyModal = (handler: (name: string) => Promise<void>) => openModal('cloneTraining', { handler });

  return {
    modal,
    openAddStepModal,
    openRemoveStepModal,
    openEasyLinkModal,
    openPublishModal,
    openUnpublishModal,
    openCopyModal,
    dismissModal,
  };
};
