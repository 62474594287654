const getRouteFromUrl = url => url?.split('#')[1];
const makeHashRoute = route => `#${getRouteFromUrl(route) || route}`;

let previousRoute = '';
let currentRoute = getRouteFromUrl(window.location.href);

window.onAngularRouteChanged = current => {
  if (currentRoute !== current) previousRoute = currentRoute;
  currentRoute = current;
};

const windowService = {
  redirectTo: (location, replace = false) => {
    const hashRoute = makeHashRoute(location);

    if (replace === true) window.location.replace(hashRoute);
    else window.location = hashRoute;
  },
  redirectBack: () => {
    window.history.back();
  },
  openRoute: (route, openInNewTab) => {
    window.open(makeHashRoute(route), openInNewTab ? '_blank' : null);
  },
  openUrl: url => {
    window.open(url);
  },
  openUrlInSameTab: url => {
    window.location.href = url;
  },
  getParameterByName: (name, url = window.location.href) => {
    name = name.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  getPreviousRoute: () => previousRoute,
  getCurrentRoute: () => currentRoute,
  getRoutePart: index => window.location.hash.split('/')[index]?.split('?')[0],
  locationReload: () => {
    window.location.reload(true);
  },
  redirectToPurchase: itemNumber =>
    windowService.redirectTo(`/purchase${itemNumber ? `?itemNumber=${itemNumber}` : ''}`),
};

export default windowService;
