angular.module('lwNamb').directive(
  'cloneTraining',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/clone-training.html',
        scope: {
          trainingType: '@',
          trainingName: '@',
          showIcon: '@',
          btnClass: '@',
          id: '=',
          buttonName: '@',
          userId: '=',
          orgId: '=',
        },
        replace: true,
        controller: [
          '$scope',
          'userService',
          'taskListService',
          'workflowService',
          'uuid4',
          '$log',
          'alertService',
          'buildService',
          'apiUrlService',
          '$filter',
          function(
            $scope,
            userService,
            taskListService,
            workflowService,
            uuid4,
            $log,
            alertService,
            buildService,
            apiUrlService,
            $filter
          ) {
            $scope.origin = apiUrlService.getOrigin();

            $scope.clearForm = function() {
              $scope.clone = { name: '' };
              $scope.submitted = false;
            };

            $scope.cloneTraining = function(newName, isValid) {
              $scope.submitted = true;
              if (!isValid) {
                return;
              }
              $scope.spinner = true;
              $scope.duplicateNameError = false;
              $scope.cloneError = false;
              if ($scope.trainingType === 'Tasklist') {
                cloneTaskList(newName, isValid);
              } else if ($scope.trainingType === 'Workflow') {
                cloneWorkflow(newName, isValid);
              } else {
                $scope.cloneError = true;
              }
            };

            var cloneTaskList = function(newName) {
              var newId = uuid4.generate();
              taskListService.clone(newId, $scope.id, newName, $scope.userId, $scope.orgId).then(
                function() {
                  var label = $filter('capitalize')($filter('label')('course')) + " Copied";
                  alertService.show({ type: 'success', title: label, duration: 5 });
                  buildService.ctxRedirectTo('/courses/' + newId);
                },
                function(reason) {
                  $log.error(reason);
                  $scope.spinner = false;
                  if (reason === 'DUPLICATE_NAME') {
                    $scope.duplicateNameError = true;
                  } else {
                    $scope.cloneError = true;
                  }
                }
              );
            };

            var cloneWorkflow = function(newName) {
              userService.user().then(
                function(user) {
                  workflowService.cloneWorkflow($scope.id, newName, user).then(
                    function(workflowId) {
                      buildService.ctxRedirectTo('/pathways/' + workflowId);
                      alertService.show({ type: 'success', title: 'Pathway Copied', duration: 5 });
                    },
                    function(reason) {
                      $log.error(reason);
                      $scope.submitted = false;
                      $scope.spinner = false;
                      if (reason === 'DUPLICATE_NAME') {
                        $scope.duplicateNameError = true;
                      } else {
                        $scope.cloneError = true;
                      }
                    }
                  );
                },
                function() {
                  $log.error('No user returned in HelpCtrl');
                }
              );
            };
          },
        ],
      };
    },
  ]
);
