import React from 'react';
import styled from 'styled-components';
import { SortableHandle } from 'react-sortable-hoc';
import { Color, Grid, Breakpoints } from '../../StyleGuide';
import { ListItemFlexRow } from '../../components/ListItemFlexRow';
import { ButtonGroup, SmallRoundedIconButton } from '../../components/Buttons';
import ThumbnailIcon from '../../components/ThumbnailIcon';
import { InlineList } from '../../components/Layout';
import { PathwayEntity } from '../models/pathway';
import { stopProp } from '../../utils/domUtils';

const TrainingListItemContainer = styled(ListItemFlexRow)`
  display: grid;
  grid-template-columns: 1fr auto;
  place-content: center;
  grid-gap: ${Grid._4};
  background: ${Color.white};
  padding: ${Grid._4};

  + * {
    border-top: none;
  }

  ${ThumbnailIcon} {
    display: none;
    margin: 0;
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: auto 1fr auto;

    ${ThumbnailIcon} {
      display: flex;
    }
  }
`;

const TrainingItemDetails = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: ${Grid._2};
  place-content: center start;

  h3 {
    margin: 0;
  }
`;

const TaskDragHandle = SortableHandle(() => (
  <SmallRoundedIconButton>
    <i className="fas fa-bars drag-handle"></i>
  </SmallRoundedIconButton>
));

type TrainingItemRowProps = {
  isEmail: boolean;
  tasklist: PathwayEntity;
  onClick: () => void;
  onRemove: () => void;
};

export const PathwayTrainingItem = ({ isEmail, tasklist, onClick, onRemove }: TrainingItemRowProps) => (
  <TrainingListItemContainer hoverStyles={`background: ${Color.white};`} onClick={onClick}>
    <ThumbnailIcon>
      {isEmail ? <i className="fas fa-paper-plane"></i> : <i className="fas fa-tasks fa-fw"></i>}
    </ThumbnailIcon>

    <TrainingItemDetails>
      <h3>{isEmail ? tasklist.subject : tasklist.name}</h3>
      <InlineList>
        <li>{tasklist._type === 'TaskList' ? 'Course' : tasklist._type}</li>
      </InlineList>
    </TrainingItemDetails>

    <ButtonGroup>
      <TaskDragHandle />

      <SmallRoundedIconButton>
        <i className="far fa-trash-alt remove-icon" data-qa-hook="removeTaskIcon" onClick={stopProp(onRemove)}></i>
      </SmallRoundedIconButton>
    </ButtonGroup>
  </TrainingListItemContainer>
);
