import React from 'react';
import { RadioList, Radio } from '../components/FormElements';
import { PrimaryButton } from '../components/Buttons';

export default function Step1(props) {
  return (
    <React.Fragment>
      <h3 data-qa-hook="step1Header">Let’s Get Started</h3>
      <RadioList>
        <li>
          <label data-qa-hook="leaderAssigningTrainingRadio">
            <Radio
              name="onboarding"
              value="Pastor"
              onChange={props.handleOnChange}
              checked={props.userType === 'Pastor'}
            />
            I’m a pastor or ministry leader ready to assign training to my church.
          </label>
        </li>
        <li>
          <label data-qa-hook="churchMemberTraineeRadio">
            <Radio
              name="onboarding"
              value="Church"
              onChange={props.handleOnChange}
              checked={props.userType === 'Church'}
            />
            I’ve received an assignment to train with my church.
          </label>
        </li>
        <li>
          <label data-qa-hook="selfTraineeRadio">
            <Radio
              name="onboarding"
              value="Individual"
              onChange={props.handleOnChange}
              checked={props.userType === 'Individual'}
            />
            I want to train on my own.
          </label>
        </li>
      </RadioList>
      <PrimaryButton data-qa-hook="nextButton" disabled={!props.userType} onClick={props.handleNextStep}>
        Next
      </PrimaryButton>
    </React.Fragment>
  );
}
