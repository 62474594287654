angular.module('lwNamb').controller(
  'ContentCtrl',

  [
    '$scope',
    '$log',
    '$q',
    'userService',
    'windowService',
    'contentService',
    'assessBuilderService',
    'uuid4',
    'alertService',
    'buildService',
    'videoService',
    '$timeout',
    function (
      $scope,
      $log,
      $q,
      userService,
      windowService,
      contentService,
      assessBuilderService,
      uuid4,
      alertService,
      buildService,
      videoService,
      $timeout
    ) {
      var ownerId;
      var init = function () {
        $scope.loading = true;
        $scope.roles = [];
        $scope.workflows = [];
        $scope.filter = {};
        $scope.submitted = false;
        $scope.extras = {
          resetModalErrors: function () {
            $scope.extras.deleteFailure = undefined;
          },
        };

        $q.all([userService.user(), buildService.ownerId()]).then(function (result) {
          $scope.user = result[0];
          ownerId = result[1];
          contentService.getAllAvailableContentForOrg
            .with({
              orgId: ownerId,
            })
            .$promise.then(
              function (response) {
                contentService.availableContentRetrieved(response);
                $scope.allContent = contentService.content || [];
                $timeout(function () {
                  if ($scope.filter && !$scope.filter.sortDropDown) {
                    $scope.filter.sortDropDown = 'lastModifiedDate';
                  }
                }, 100);
              },
              function (response) {
                $log.error('Error: Content not found; ' + response);
              }
            )
            .finally(function () {
              $scope.loading = false;
            });
        });
      };

      $scope.clearInput = function () {
        if ($scope.filter && $scope.filter.sortDropDown) {
          $scope.filter = Object.assign({}, { sortDropDown: $scope.filter.sortDropDown });
        } else {
          $scope.filter = {};
        }
      };

      $scope.clearForm = function () {
        $scope.task = {
          type: '',
        };
        $scope.submitted = false;
        $scope.duplicateNameError = false;
      };

      function showAlert(type, msg, duration) {
        alertService.show({ title: msg, type: type, show: true, dismissible: true, duration: duration });
      }

      //Add Task
      $scope.addTask = function (type, name, isValid) {
        $scope.submitted = true;
        if (isValid) {
          if (type === 'Assessment') {
            $scope.spinner = true;
            var assessmentId = uuid4.generateId().id;
            assessBuilderService.createAssessment(assessmentId, $scope.user.userId, ownerId, name).then(
              function () {
                buildService.ctxRedirectTo('/assessments/' + assessmentId);
              },
              function (reason) {
                $log.error(reason);
                if (reason === 'AssessmentNameTaken') {
                  $scope.spinner = false;
                  $scope.duplicateNameError = true;
                } else {
                  alertService.show({
                    title: 'There was an error creating your Assessment. Please try again.',
                    type: 'danger',
                    show: true,
                    dismissible: true,
                    duration: 20,
                  });
                }
              }
            );
          }
          if (type === 'Download' || type === 'Video') {
            buildService.ctxRedirectTo('/content/creating/' + type);
          }
        }
      };

      $scope.archiveContent = function archiveContent(task, closeModal) {
        $scope.extras.spinner = true;
        $scope.extras.deleteFailure = false;
        function deleteSuccess() {
          showAlert('success', 'Content Removed', 5);
          closeModal();
          $scope.allContent.splice($scope.allContent.indexOf(task), 1);
          $scope.extras.spinner = false;
        }
        function deleteFailure(failure) {
          $log.error(failure);
          $scope.extras.spinner = false;
          $scope.extras.deleteFailure = typeof failure === 'string' ? { reason: 'unknown' } : failure;
        }
        if (task.type === 'Download') {
          contentService.archiveDownload(task.id).then(deleteSuccess, deleteFailure);
        } else if (task.type === 'Video') {
          videoService.deleteVideo(task, ownerId, $scope.user.userId).then(deleteSuccess, deleteFailure);
        } else if (task.type === 'Assessment') {
          assessBuilderService.deleteAssessment(task, ownerId, $scope.user.userId).then(deleteSuccess, deleteFailure);
        } else {
          $scope.extras.spinner = false;
          $scope.extras.deleteFailure = false;
          showAlert('danger', 'Cannot delete this content type', 5);
        }
      };

      $scope.goToItem = function (item) {
        if (item.type === 'Assessment') {
          buildService.ctxRedirectTo('/assessments/' + item.id);
        } else {
          buildService.ctxRedirectTo('/content/' + item.id);
        }
      };

      init();
    },
  ]
);
