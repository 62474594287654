angular.module('lwNamb').factory(
  'profileService',

  [
    '$rootScope',
    'api',
    'qt',
    'CacheFactory',
    function($rootScope, api, qt, CacheFactory) {
      var timeoutSeconds = 20,
        cache = CacheFactory('profile', {
          maxAge: 10 * 60 * 1000, // 10 minutes
          deleteOnExpire: 'aggressive',
        }),
        preferenceCache = CacheFactory('preferences', {
          maxAge: 30 * 60 * 1000, // 30 minutes
          deleteOnExpire: 'aggressive',
        });

      //Group Objects by Property into New Arrays
      function groupBy(collection, property) {
        var i = 0,
          val,
          index,
          values = [],
          result = [];
        for (; i < collection.length; i++) {
          val = collection[i][property];
          index = values.indexOf(val);
          if (index > -1) result[index].push(collection[i]);
          else {
            values.push(val);
            result.push([collection[i]]);
          }
        }
        return result;
      }

      return {
        getProfile: function(userId) {
          return api.get('/v1/users/' + userId).then(function(response) {
            return response.data;
          });
        },
        getPreferences: function() {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
          var cachedPrefs = preferenceCache.get('prefs');
          if (cachedPrefs !== undefined) {
            deferred.resolve(cachedPrefs);
          } else {
            api.get('/preferences').then(
              function(response) {
                var prefs = response.data;
                preferenceCache.put('prefs', prefs);
                deferred.resolve(prefs);
              },
              function(reason) {
                deferred.reject(reason);
              }
            );
          }
          return deferred.promise;
        },
        savePreferences: function(userId, showAllInfoBoxes) {
          preferenceCache.removeAll();
          return api.post('/preferences', { _id: { id: userId }, showAllInformationBoxes: showAllInfoBoxes });
        },
        getCustomData: function(orgId, email) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
          var cacheKey = orgId + '-' + email;

          if (cache.get(cacheKey)) {
            deferred.resolve(cache.get(cacheKey));
          } else {
            api.get('/v1/namb/custom-fields?orgId=' + orgId + '&email=' + email).then(
              function(response) {
                if (response.data.data) {
                  var groupedResponse = groupBy(response.data.data.customFields, 'header');
                  deferred.resolve(groupedResponse);
                  cache.put(cacheKey, groupedResponse);
                } else {
                  deferred.reject('NOT_FOUND');
                }
              },
              function(reason) {
                deferred.reject(reason);
              }
            );
          }

          return deferred.promise;
        },
        updateProfilePhone: function(id, phone) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          api
            .post('/v1/commands/UpdateUserProfilePhoneNumber', {
              id: id,
              phone: phone,
            })
            .then(null, function failure(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('UserProfilePhoneNumberUpdated', function() {
            deferred.resolve();
          });

          return deferred.promise;
        },
      };
    },
  ]
);
