angular.module('lwNamb').controller(
  'ListBuilderCtrl',

  [
    '$scope',
    '$routeParams',
    '$log',
    '$q',
    '$location',
    'userService',
    'workflowService',
    'taskListService',
    'uuid4',
    'alertService',
    'analyticsService',
    'buildService',
    '$modal',
    'orgService',
    'windowService',
    '$filter',
    function (
      $scope,
      $routeParams,
      $log,
      $q,
      $location,
      userService,
      workflowService,
      taskListService,
      uuid4,
      alertService,
      analyticsService,
      buildService,
      $modal,
      orgService,
      windowService,
      $filter
    ) {
      var ctrlName = '',
        queryParams = $location.search(),
        workflowId = queryParams.workflowId;

      var init = function () {
        $scope.label = $filter('capitalize')($filter('label')('course'));

        $scope.loading = true;
        $scope.tasklistId = $routeParams.tasklistId;
        $scope.isTasklist = !buildService.isScorecards();
        ctrlName = $scope.isTasklist ? 'TaskListBuilder' : 'ScorecardBuilder';
        $scope.isWorkflowBuilder = !!workflowId;
        $scope.isTaskListOwner = true;
        $scope.isCustomizing = false;
        if (workflowId) {
          ctrlName = 'WFBuilderTaskListBuilder';
          analyticsService.trackFeature(ctrlName, 'loaded');
          getWorkflow();
        }
        $scope.submitted = false;
        $scope.spinner = false;
        $scope.tasklistEdit = false;
        $scope.showToolbox = true;
        $scope.sortableOptions = {
          containerPositioning: 'relative',
        };
        $scope.ctxRedirectTo = buildService.ctxRedirectBackTo;

        $q.all([userService.user(), buildService.ownerId()]).then(
          function (result) {
            var user = result[0];
            $scope.ownerId = result[1];
            $scope.user = user;
            $scope.userId = user.userId;
            retrieveTaskListData($scope.tasklistId).then(
              function () {
                $scope.showAssignButton =
                  !$scope.isWorkflowBuilder &&
                  !buildService.isAuthoring() &&
                  user.permissions.indexOf('Assign View') > -1 &&
                  $scope.tasklist.tasks.length > 0;
                $scope.loading = false;
                $scope.showOwnershipMsg = !$scope.loading && $scope.isWorkflowBuilder;
              },
              function (reason) {
                $log.error(reason);
                alertService.show({
                  type: 'danger danger-with-content',
                  title: 'Unexpected Error!',
                  content: 'We were unable to retrieve this training at this time.',
                  duration: 20,
                });
                $scope.loading = false;
              }
            );
          },
          function () {
            $log.error('No user returned in ListBuilderCtrl');
          }
        );
      };

      function retrieveTaskListData(tasklistId) {
        var deferred = $q.defer();
        taskListService.getTasklist(tasklistId).then(
          function (tasklist) {
            tasklist.tasks.sort(byOrder);
            $scope.tasklist = tasklist;
            $scope.isTaskListOwner = $scope.ownerId === $scope.tasklist.owner;
            if (!$scope.isTaskListOwner || buildService.isAuthoring()) {
              orgService.getOrgOverview($scope.tasklist.owner).then(function (response) {
                $scope.ownerName = response.data.name;
              });
            }
            deferred.resolve(tasklistId);
          },
          function (reason) {
            deferred.reject(reason);
          }
        );
        return deferred.promise;
      }

      function byOrder(t1, t2) {
        return t1.order - t2.order;
      }

      function getWorkflow() {
        workflowService.getWorkflowDetail(workflowId).then(function success(workflow) {
          $scope.workflow = workflow;
          if (
            workflow.entities
              .map(function (e) {
                if (e.taskList) {
                  return e.taskList._id.id === $scope.tasklistId;
                } else {
                  return false;
                }
              })
              .indexOf(true) === -1
          ) {
            $scope.tasklistDoesNotExist = true;
          }
        });
      }

      $scope.openModal = function () {
        var modal = $modal({
          show: true,
          templateUrl: 'duplicateNameModal.html',
          placement: 'center',
          scope: $scope,
          controller: 'ListBuilderCtrl',
        });
        modal.$promise.then(modal.show);
      };

      function openCloneAllModal() {
        var modal = $modal({
          show: true,
          templateUrl: 'cloneAllTasklists.html',
          placement: 'center',
          scope: $scope,
          controller: 'ListBuilderCtrl',
        });
        modal.$promise.then(modal.show);
      }

      function redirectToCloned(tasklistId) {
        if (buildService.isAuthoring()) {
          windowService.redirectHash('/author/courses/' + tasklistId + '?workflowId=' + queryParams.workflowId);
        } else {
          windowService.redirectHash('/build/courses/' + tasklistId + '?workflowId=' + queryParams.workflowId);
        }
      }

      $scope.cloneAll = function (allOr1, $hide) {
        $scope.isCustomizing = true;
        workflowService
          .cloneTaskListAndReplace(workflowId, $scope.tasklist.id, $scope.user.userId, $scope.ownerId)
          .then(
            function (tasklistId) {
              if (allOr1 === 'All') {
                analyticsService.trackFeature(ctrlName, 'automaticCloning_ALL');
                $q.all(
                  $scope.workflowsInfo
                    .filter(function (wi) {
                      return wi._id !== workflowId;
                    })
                    .map(function (info) {
                      workflowService.replace(
                        info._id,
                        $scope.tasklist.id,
                        tasklistId,
                        $scope.user.userId,
                        $scope.ownerId
                      );
                    })
                ).then(function () {
                  $hide();
                  redirectToCloned(tasklistId);
                });
              } else {
                analyticsService.trackFeature(ctrlName, 'automaticCloning_ONE');
                $hide();
                redirectToCloned(tasklistId);
              }
            },
            function (reason) {
              $hide();
              if (reason === 'DUPLICATE_NAME') {
                $scope.openModal();
              }
              $log.error(reason);
            }
          )
          .finally(function () {
            $scope.isCustomizing = false;
          });
      };

      $scope.clone = function () {
        workflowService.findWorkflowsContaining($scope.tasklist.id, $scope.ownerId).then(function (workflows) {
          if (workflows.length === 1) {
            $scope.isCustomizing = true;
            analyticsService.trackFeature(ctrlName, 'automaticCloning');
            workflowService
              .cloneTaskListAndReplace(workflowId, $scope.tasklist.id, $scope.user.userId, $scope.ownerId)
              .then(
                function (tasklistId) {
                  redirectToCloned(tasklistId);
                },
                function (reason) {
                  if (reason === 'DUPLICATE_NAME') {
                    $scope.openModal();
                  }
                  $log.error(reason);
                }
              )
              .finally(function () {
                $scope.isCustomizing = false;
              });
          } else {
            $scope.workflowsInfo = workflows;
            openCloneAllModal();
          }
        });
      };

      $scope.togglePublished = function (published, closeModal) {
        $scope.spinner = true;
        analyticsService.trackFeature(ctrlName, 'publishing');
        if ($scope.isTasklist) {
          taskListService.togglePublished($scope.tasklistId, !published, $scope.userId).then(
            function (taskListId) {
              closeModal();
              $scope.spinner = false;
              alertService.show({ type: 'success', title: $scope.label + ' Updated', duration: 5 });
              $scope.tasklist.published = !$scope.tasklist.published;
            },
            function (reason) {
              closeModal();
              $log.error(reason);
              $scope.spinner = false;
              alertService.show({
                type: 'danger danger-with-content',
                title: 'Unexpected Error!',
                content: 'Something went wrong. Please try again',
                duration: 20,
              });
            }
          );
        }
      };

      $scope.saveTasksOrder = function (tasks) {
        analyticsService.trackFeature(ctrlName, 'reorderingTasks');
        var seqOfTasks,
          hasChanged = !!$scope.tasklist.tasks.find(function (task, i) {
            return task.order != i;
          });
        if (hasChanged) {
          $scope.isSaving = true;
          seqOfTasks = tasks.reduce(function (r, task, i) {
            r[task._id.id] = i;
            return r;
          }, {});
          taskListService.reorderTasks($scope.tasklist.id, seqOfTasks, $scope.userId).then(
            function () {
              alertService.show({ type: 'success', title: $scope.label + ' Reordered', duration: 5 });
              $scope.tasklist.tasks = tasks;
              $scope.isReordering = false;
              $scope.isSaving = false;
            },
            function (reason) {
              alertService.show({
                type: 'danger danger-with-content',
                title: 'Reorder ' + $scope.label + ' Failed',
                content: 'Something went wrong. Please try again',
                duration: 20,
              });
              $log.error(reason);
              $scope.isReordering = false;
              $scope.isSaving = false;
            }
          );
        } else {
          $scope.isReordering = false;
        }
      };

      $scope.maxTaskOrder = function (taskList) {
        if (taskList && taskList.tasks) {
          var taskOrders = taskList.tasks.map(function (task) {
            return task.order || 0;
          });
          return Math.max(Math.max.apply(null, taskOrders), 0);
        } else {
          return 0;
        }
      };

      $scope.addTask = function (type, isValid) {
        if (!isValid) {
          $scope.submitted = true;
          return;
        }
        analyticsService.trackFeature(ctrlName, 'addingTask:' + type);

        var order = $scope.maxTaskOrder($scope.tasklist) + 1;

        if (
          type === 'Text' ||
          type === 'Upload' ||
          type === 'Link' ||
          type === 'YouTube' ||
          type === 'Vimeo' ||
          type === 'MyWSBLink' ||
          type === 'Zoom'
        ) {
          queryParams.taskType = type;
          queryParams.order = order;
          buildService.ctxRedirectTo('/courses/' + $scope.tasklistId + '/tasks/', queryParams);
        }
        if (type === 'Assessment' || type === 'Video' || type === 'Download' || type === 'Audio') {
          queryParams.taskType = type;
          queryParams.order = order;
          queryParams.isOwner = $scope.isTaskListOwner;
          buildService.ctxRedirectTo('/courses/' + $scope.tasklistId + '/content-select', queryParams);
        }
      };

      $scope.removeTaskFromTasklist = function (task, index) {
        $scope.removing = true;
        analyticsService.trackFeature(ctrlName, 'removingTask');
        taskListService
          .removeTask($scope.tasklistId, task._id.id, $scope.userId)
          .then(
            function () {
              var label = 'Step';
              alertService.show({ type: 'success', title: label + ' Removed', duration: 5 });
              $scope.tasklist.tasks.splice(index, 1);
              $scope.isTaskListOwner = true;
            },
            function (reason) {
              $log.error(reason);
              alertService.show({
                type: 'danger danger-with-content',
                title: 'Unexpected Error!',
                content: 'Something went wrong. Please try again.',
                duration: 20,
              });
            }
          )
          .finally(function () {
            $scope.removing = false;
          });
      };

      $scope.clearForm = function () {
        $scope.task = { type: '' };
        $scope.submitted = false;
      };

      $scope.toggleReordering = function () {
        $scope.isReordering = !$scope.isReordering;
      };

      $scope.redirectToTask = function (task) {
        if ($scope.isWorkflowBuilder) {
          analyticsService.trackFeature(ctrlName, 'viewingTask');
        }
        queryParams.taskType = task._type;
        buildService.ctxRedirectTo('/courses/' + $routeParams.tasklistId + '/tasks/' + task._id.id, queryParams);
      };

      $scope.redirectToSettings = function () {
        if ($scope.isWorkflowBuilder) {
          analyticsService.trackFeature(ctrlName, 'viewingSettings');
        }
        buildService.ctxRedirectTo('/courses/' + $routeParams.tasklistId + '/settings', queryParams);
      };

      $scope.$on('TaskIsRemovedFromTasklist', function (event_label, event) {
        $scope.removing = false;
      });

      init();
    },
  ]
);
