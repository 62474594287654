angular.module('lwNamb').directive(
  'loginGrid',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/login-grid.html',
      };
    },
  ]
);
