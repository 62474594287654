import React from 'react';
import styled from 'styled-components';
import { InfoBanner } from '../components/AlertBanners';
import BibleStudySessionTimeline from './BibleStudySessionTimeline';

const BibleStudySessionTimelinePreview = () => (
  <>
    <InfoBanner style={{ position: 'fixed' }}>Leader Guide Preview</InfoBanner>

    <div className="bible-study-session-timeline-preview__banner-spacer">
      <BibleStudySessionTimeline preview />
    </div>
  </>
);

export default BibleStudySessionTimelinePreview;
