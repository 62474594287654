import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styled from 'styled-components';
import { Grid, Type } from '../../StyleGuide';
import { SmallRoundedIconButton, ButtonGroup } from '../../components/Buttons';
import { OrderingDragHandle } from '../../components/OrderingElements';

const Supplies = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const Supply = styled.li`
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 ${Grid._4};
`;

const SupplyDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: ${Grid._4};
  h5 {
    margin: 0;
    color: ${Type.Color.dark};
    font-size: ${Type.Scale._2};
  }
  p {
    margin: 0;
    color: ${Type.Color.medium};
    font-size: ${Type.Scale._2};
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-left: auto;
`;

const ReorderableSupplyList = SortableContainer(props => <Supplies>{props.children}</Supplies>);
const ReorderableSupplyItem = SortableElement(props => <Supply>{props.children}</Supply>);
const SupplyDragHandle = styled(OrderingDragHandle)`
  margin-right: ${Grid._4};
`;

const SuppliesSectionEditor = ({ supplies, onRemove, onReorder, onEdit }) => {
  return supplies?.length ? (
    <ReorderableSupplyList onSortEnd={onReorder} useDragHandle>
      {supplies.map((supply, i) => (
        <ReorderableSupplyItem key={i} index={i}>
          <SupplyDragHandle />
          <SupplyDetails>
            <h5>{supply.name}</h5>
            <p>{supply.quantity}</p>
          </SupplyDetails>
          <StyledButtonGroup>
            <SmallRoundedIconButton onClick={() => onEdit(supply, i)}>
              <i className="fas fa-pen"></i>
            </SmallRoundedIconButton>
            <SmallRoundedIconButton data-qa-hook="removeSupply" onClick={() => onRemove(supply)}>
              <i className="far fa-trash-alt"></i>
            </SmallRoundedIconButton>
          </StyledButtonGroup>
        </ReorderableSupplyItem>
      ))}
    </ReorderableSupplyList>
  ) : (
    <Supplies>
      <Supply>
        <div>
          <p>No Supplies Added</p>
        </div>
      </Supply>
    </Supplies>
  );
};

SuppliesSectionEditor.propTypes = {
  supplies: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
};

export default SuppliesSectionEditor;
