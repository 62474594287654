import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PieChart from 'react-minimal-pie-chart';
import { Grid, Breakpoints, Color, Type } from '../StyleGuide';
import DiscussionDisabler from './DiscussionDisabler';
import TraineeStatuses, { TraineeStatus } from './TraineeStatuses';
import DiscussionModal from './DiscussionModal';
import useModal from '../hooks/useModal';
import { SmallOutlineButton } from '../components/Buttons';
import windowService from '../services/windowService';

const NOT_STARTED_COLOR = Color.Gray._20;
const IN_PROGRESS_COLOR = '#5188CA';
const COMPLETED_COLOR = Color.Primary._50;

const TrainingRow = styled.div`
  padding: ${Grid._4} 0;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._5};
  }
  border-bottom: 1px solid #ddd;
  .tn-header {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      flex-direction: row;
    }
    h3 {
      display: flex;
      align-items: center;
      flex: 1;
      font-size: ${Type.Scale._4};
      @media screen and (min-width: ${Breakpoints.screen_sm}) {
        font-size: ${Grid._5};
        margin-top: 0;
      }
      i {
        padding-right: ${Grid._4};
      }
    }
  }
  .assigned {
    font-size: ${Type.Scale._1};
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      float: right;
      margin-left: ${Grid._3};
    }
    .btn {
      margin: -4px 0 0 ${Grid._3};
      font-size: ${Type.Scale._1};
      float: right;
      @media screen and (min-width: ${Breakpoints.screen_sm}) {
        float: none;
        margin-top: 0;
      }
    }
  }
  .tn-data {
    padding-top: ${Grid._6};
    display: grid;
    grid-gap: ${Grid._5};

    @media screen and (min-width: 420px) {
      grid-template-columns: 80px 1fr;
      padding-top: ${Grid._3};
    }
  }
`;

const DataAndActionList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${Grid._3};
`;

const DataAndActionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: ${Type.Scale._1};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    justify-content: flex-end;
  }

  button {
    margin-left: ${Grid._3};
  }
`;

const Training = ({ training }) => {
  const { id, name, _type, notStarted, inProgress, completed, lastAssignedOn, comments, replies, discussionDisabled } =
    training;

  const statuses = [
    new TraineeStatus('Not Started', notStarted.count, notStarted.users, NOT_STARTED_COLOR, Type.Color.medium),
    new TraineeStatus('In Progress', inProgress.count, inProgress.users, IN_PROGRESS_COLOR),
    new TraineeStatus('Completed', completed.count, completed.users, COMPLETED_COLOR),
  ];

  const redirectToAssignTraining = () => {
    windowService.redirectTo(`#/assign-training/${id}`);
  };

  const redirectToTraining = trainingId => windowService.redirectTo(`#/add-to-your-training/${trainingId}?noLogout=1`);

  const [modal, openModal] = useModal((type, _, dismissModal) => {
    if (!type) return;

    return <DiscussionModal courseId={id} handleDismiss={dismissModal} />;
  });

  return (
    <TrainingRow data-qa-hook="trainingRow" className="clearfix">
      <div className="tn-header">
        <h3 data-qa-hook="trainingName">
          <i className={_type === 'Course' ? 'fa fa-tasks' : 'fa fa-route'} /> {name}
        </h3>
        <DataAndActionList>
          <DataAndActionRow>
            Last Assigned: {new Date(lastAssignedOn).toLocaleDateString()}
            <SmallOutlineButton onClick={redirectToAssignTraining}>Assign to More People</SmallOutlineButton>
          </DataAndActionRow>
          <DataAndActionRow>
            <DiscussionDisabler
              isDisabled={discussionDisabled}
              trainingType={_type}
              EnableButton={({ children }) => (
                <SmallOutlineButton onClick={() => openModal('disscussion-modal')}>{children}</SmallOutlineButton>
              )}
            >
              {(comments || 0) + (replies || 0)} comments &amp; replies
              <SmallOutlineButton onClick={() => redirectToTraining(id)} data-qa-hook="startDiscussion">
                {comments > 0 ? 'Join' : 'Start'} the Discussion
              </SmallOutlineButton>
            </DiscussionDisabler>
          </DataAndActionRow>
        </DataAndActionList>
      </div>
      <div className="tn-data">
        <div className="chart">
          <PieChart
            data={[
              { value: notStarted.count, color: NOT_STARTED_COLOR },
              { value: inProgress.count, color: IN_PROGRESS_COLOR },
              { value: completed.count, color: COMPLETED_COLOR },
            ]}
            lineWidth={40}
            style={{
              height: '80px',
            }}
          />
        </div>
        <TraineeStatuses statuses={statuses} />
      </div>
      {modal}
    </TrainingRow>
  );
};

Training.propTypes = {
  training: PropTypes.object,
};

export default Training;
