import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// @ts-ignore
import CopyToClipboard from 'react-copy-to-clipboard';
import { Grid } from '../../StyleGuide';
import { FormFieldContainer, Label, Select, FormGroupContainer } from '../../components/FormElements';
import { Button } from '../../components/Buttons';

const Container = styled(FormFieldContainer)`
  ${FormGroupContainer} {
    gap: ${Grid._4};
  }

  button {
    min-width: 120px;
  }
`;

export const ContentVariableFormField = () => {
  const [variable, setVariable] = useState('assigneeFirstName');
  const [copied, setCopied] = useState(false);

  const updateVariable = (e: React.ChangeEvent<HTMLSelectElement>) => setVariable(e.target.value);

  useEffect(() => {
    if (!copied) return;
    const timeoutId = setTimeout(() => setCopied(false), 1500);
    return () => clearTimeout(timeoutId);
  }, [copied]);

  return (
    <Container>
      <Label>Content Variables</Label>

      <FormGroupContainer>
        <Select label="Content Variable" name="contentVariable" onChange={updateVariable}>
          <option value="assigneeFirstName">Assignee First Name</option>
        </Select>
        <CopyToClipboard text={`{${variable}}`} onCopy={() => setCopied(true)}>
          <Button>
            {copied ? (
              <>
                <i className="fas fa-check" /> Copied
              </>
            ) : (
              <>
                <i className="fas fa-copy" /> Copy
              </>
            )}
          </Button>
        </CopyToClipboard>
      </FormGroupContainer>
    </Container>
  );
};
