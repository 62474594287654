import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import { Grid, Color, Type, Border, Transition } from '../StyleGuide';
import { SixteenSevenAspectRatioPlaceholder, Image } from '../components/Layout';
import { redirectToTrainingLinkUrl } from '../components/CatalogElements';
import { SmallRoundedIconButton } from '../components/Buttons';
import carouselService from '../services/carouselService';

const CarouselButton = styled.button`
  padding: 0;
  align-self: center;
  height: 100%;
  width: 40px;
  max-width: 40px;
  background: ${Color.Gray._10};
  border: none;
  border-radius: ${Border.radius};
  font-size: ${Type.Scale._5};
  box-shadow: none;
  border: 1px solid #eee;
  outline: none;
  transition: ${Transition.fast};
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${Color.Gray._20};
  }
  &:focus {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px ${Color.Gray._50},
      0px 0px 0px 5px rgba(179, 179, 179, 0.3);
  }
`;

export const carouselControls = ({ type, onClick, isEdge }) => {
  const pointer = type === 'PREV' ? 'fa-chevron-left' : 'fa-chevron-right';
  return (
    <CarouselButton data-qa-hook={`carouselButton-${type}`} onClick={onClick} disabled={isEdge}>
      <i className={`fas ${pointer}`}></i>
    </CarouselButton>
  );
};

export const FeatureCarouselControls = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto ${Grid._5};
  button:first-child {
    margin-right: ${Grid._3};
  }
  button:last-child {
    margin-left: ${Grid._3};
  }
`;

export const Dot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${Color.Gray._30};
  margin: 0 ${Grid._1};
  &.active {
    background: ${Color.Blue._50};
  }
`;

export const TextOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const Text = styled.div`
  position: absolute;
  top: ${props => (props.textPosition === 'topRight' || props.textPosition === 'topLeft' ? Grid._6 : '')};
  bottom: ${props => (props.textPosition === 'bottomRight' || props.textPosition === 'bottomLeft' ? Grid._6 : '')};
  right: ${props => (props.textPosition === 'topRight' || props.textPosition === 'bottomRight' ? Grid._6 : '')};
  left: ${props => (props.textPosition === 'topLeft' || props.textPosition === 'bottomLeft' ? Grid._6 : '')};
  display: flex;
  flex-direction: column;
  text-align: ${props =>
    props.textPosition === 'topRight' || props.textPosition === 'bottomRight' ? 'right' : 'left'};
  padding: ${props =>
    props.textPosition === 'topRight' || props.textPosition === 'bottomRight' ? `0 0 0 25%` : `0 25% 0 0`};
  word-break: break-all;
  h2 {
    order: 1;
    margin: 0;
    color: ${props => (props.textColor === 'light' ? Color.white : Type.Color.dark)};
    font-size: ${Type.Scale._7};
    font-weight: ${Type.Weight.bold};
    text-shadow: ${props => (props.textColor === 'light' ? Type.Shadow.regular : '')};
  }
  h4 {
    order: 0;
    margin: 0;
    color: ${props => (props.textColor === 'light' ? Color.Gray._10 : Type.Color.medium)};
    font-size: ${Type.Scale._3};
    font-weight: ${Type.Weight.normal};
    text-transform: uppercase;
    text-shadow: ${props => (props.textColor === 'light' ? Type.Shadow.regular : '')};
  }
`;

export const MobileText = styled.div`
  h2 {
    margin: 0;
    font-size: ${Type.Scale._5};
    font-weight: ${Type.Weight.bold};
    color: ${Type.Color.dark};
  }
  h4 {
    margin: ${Grid._3} 0 0;
    font-size: ${Type.Scale._3};
    font-weight: ${Type.Weight.normal};
    color: ${Type.Color.medium};
    text-transform: uppercase;
  }
`;

export const CarouselItem = props => {
  const link = props.item.link;
  return (
    <>
      <SixteenSevenAspectRatioPlaceholder
        className={props.canRedirect ? 'clickable' : ''}
        onClick={() => (props.canRedirect ? redirectToTrainingLinkUrl(link) : null)}
      >
        <Image
          src={carouselService.getCarouselItemImageUrl(props.item.image)}
          alt={props.item.text.title}
          style={{ width: '100%' }}
        />
        <div className="hidden-xs">
          <TextOverlay>
            <Text textPosition={props.item.text.position} textColor={props.item.text.color}>
              <h2 data-qa-hook="carouselItemName">{props.item.text.title}</h2>
              <h4 data-qa-hook="carouselItemlabel">{props.item.text.label}</h4>
            </Text>
          </TextOverlay>
        </div>
        {props.children}
      </SixteenSevenAspectRatioPlaceholder>
      <div className="visible-xs-block">
        <MobileText>
          <h4 data-qa-hook="carouselItemlabel">{props.item.text.label}</h4>
          <h2 data-qa-hook="carouselItemName">{props.item.text.title}</h2>
        </MobileText>
      </div>
    </>
  );
};

export const CarouselPagination = ({ carouselRef, pages, activePage, onClick, disableAtEnd }) => {
  const goToPrev = () => {
    if (carouselRef.current.state.activeIndex === 0) {
      carouselRef.current.goTo(carouselRef.current.state.pages.length - 1);
    } else {
      carouselRef.current.slidePrev();
    }
  };

  const goToNext = () => {
    if (carouselRef.current.state.activeIndex === carouselRef.current.state.pages.length - 1) {
      carouselRef.current.goTo(0);
    } else {
      carouselRef.current.slideNext();
    }
  };

  return (
    <FeatureCarouselControls>
      <SmallRoundedIconButton
        data-qa-hook="featureCarouselLeft"
        onClick={() => goToPrev()}
        disabled={disableAtEnd && activePage === 0}
      >
        <i className="fas fa-chevron-left"></i>
      </SmallRoundedIconButton>
      {pages.map(page => {
        const isActivePage = activePage === page;
        const active = classNames({
          active: isActivePage,
        });
        return <Dot key={page} onClick={() => onClick(page)} active={isActivePage} className={`dot ${active}`} />;
      })}
      <SmallRoundedIconButton
        data-qa-hook="featureCarouselRight"
        onClick={() => goToNext()}
        disabled={disableAtEnd && activePage === pages.length - 1}
      >
        <i className="fas fa-chevron-right"></i>
      </SmallRoundedIconButton>
    </FeatureCarouselControls>
  );
};

CarouselPagination.propTypes = {
  carouselRef: PropTypes.object,
  pages: PropTypes.array,
  activePage: PropTypes.number,
  onClick: PropTypes.func,
  disableAtEnd: PropTypes.bool,
};
