import uuid from './uuid';
import submitCommand from './submitCommand';
import apiClient from '../services/apiClient';
import uploadService from './uploadService';

export const CatalogComponentStyle = { GRID: 'Grid', CAROUSEL: 'Carousel' };
export const TrainingItemsDisplayStyle = { GRID: 'Grid', LIST: 'List' };

const transformCompilations = compilations =>
  compilations.map(compilation => {
    return {
      ...compilation,
      id: compilation._id,
      trainingType: 'Compilation',
      modifiedTime: compilation.modifiedAt ? new Date(compilation.modifiedAt).getTime() : null,
      filterableData: [compilation.name],
    };
  });

const compilationService = {
  createCompilation: (compilation, initiatingUserId) => {
    let {
      name,
      shortDescription,
      longDescription,
      image,
      catalogComponentStyle,
      trainingItemsDisplayStyle,
      trainingItems,
    } = compilation;
    const cmd = {
      id: uuid.generate(),
      name,
      shortDescription,
      longDescription,
      image,
      catalogComponentStyle,
      trainingItemsDisplayStyle,
      trainingItems,
      initiatingUserId,
    };
    return submitCommand(cmd.id, cmd, 'CreateCompilation', 'CompilationCreated', 'CompilationCommandFailed');
  },
  updateCompilation: (compilation, initiatingUserId) => {
    if (compilation.id) {
      const cmd = { initiatingUserId: initiatingUserId };
      const fields = [
        'id',
        'name',
        'shortDescription',
        'longDescription',
        'image',
        'catalogComponentStyle',
        'trainingItemsDisplayStyle',
        'trainingItems',
      ];
      fields.forEach(field => {
        cmd[field] = compilation[field];
      });

      return submitCommand(cmd.id, cmd, 'UpdateCompilation', 'CompilationUpdated', 'CompilationCommandFailed');
    } else {
      return Promise.reject('No id provided');
    }
  },
  getCompilations: () => {
    return apiClient
      .get('/catalog/compilations')
      .then(response => transformCompilations(response.data))
      .catch(reason => reason);
  },
  getCompilation: compilationId => {
    return apiClient
      .get(`/catalog/compilations/${compilationId}`)
      .then(response => transformCompilations([response.data])[0])
      .catch(err => err.response);
  },
  getCompilationImageUrl: key => {
    return `${apiClient.getConfig().baseURL}/v1/images/training/${key}`;
  },
  uploadCompilationImage: (file, filename) => {
    return uploadService.uploadImage(file, filename, '/training');
  },
  removeCompilation: (id, initiatingUserId) => {
    const cmd = { id, initiatingUserId };
    return submitCommand(cmd.id, cmd, 'DeleteCompilation', 'CompilationDeleted');
  },
  orderByModified: (compilations, desc = true) => {
    const tempList = [...compilations]; // keep from modifiying original list
    return tempList.sort((a, b) => (desc ? b.modifiedTime - a.modifiedTime : a.modifiedTime - b.modifiedTime));
  },
};

export default compilationService;
