import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Grid, Color, Breakpoints, Type } from '../StyleGuide';
import bulkSelect from '../utils/bulkSelect';
import { useUser } from '../authentication';
import { useArrayFilter, useTextFilter, useMultiFilter, useSortFilter } from '../hooks/filters';
import EmptyState from '../components/EmptyState';
import Breadcrumbs from '../components/Breadcrumbs';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { LinkStyleButton, SortButton, ButtonGroup } from '../components/Buttons';
import ProfileImage from '../groups/ProfileImage';
import { PlaceholderProfileImage } from '../components/ProfileImageCrop';
import AssessmentResultsFilterControl from './AssessmentResultsFilterControls';
import { PageTitle, Container, InlineList, FullWidthCard } from '../components/Layout';
import cacheService from '../services/cacheService';
import assessmentService from '../services/assessmentService';
import ActiveFilters from '../components/ActiveFilters';

const PageContainer = styled(Container)`
  margin-top: ${Grid._5};
`;

const PageCard = styled(FullWidthCard)`
  margin-top: ${Grid._7};
  padding: ${Grid._5};
`;

const ProfileImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  min-width: 80px;
  text-align: center;

  i,
  img {
    width: 50px;
    height: 50px;
    font-size: 48px;
    color: ${Color.Gray._50};
  }
`;

const AssessmentInfoContainer = styled.td`
  display: flex;
  gap: ${Grid._5};
  flex-direction: column;
  align-items: start;
  padding: ${Grid._4} ${Grid._3} !important;

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
  }
`;

const RadioContainer = styled.td`
  cursor: pointer;
`;

const AssessmentInfo = styled.div`
  h4 {
    margin: 0 0 ${Grid._3} 0;
  }

  li span {
    font-weight: ${Type.Weight.semibold};
  }
`;

const SelectTableHeaderCell = styled.th`
  min-width: 115px;
  text-align: center;
`;

const AssessmentResultsFilter = () => {
  const { assessmentId } = useParams();
  const user = useUser();
  const orgId = user.lastSelectedAccount;
  const [data, setData] = useState({
    isLoading: true,
    isError: false,
    assessmentData: {},
  });
  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Assessment Results', route: '/#/assessment/results' },
    { name: 'Assessment Results Viewer', route: `/#/assessment/results/${assessmentId}` },
    { name: 'Assessment Results Filter' },
  ];
  const { assessmentData, isLoading, isError } = data;

  const formatDate = date => format(new Date(date), 'MMMM d, yyyy');

  const rolesMatcher = (assessment, roleName) => assessment.roleNames.some(role => role === roleName);
  const groupsMatcher = (assessment, groupName) => assessment.groupNames.some(name => name === groupName);
  const taskListMatcher = (assessment, taskListName) => assessment.taskListName === taskListName;

  const queryMatcher = (assessment, query) =>
    [
      assessment.firstName,
      assessment.lastName,
      assessment.assessmentName,
      assessment.taskListName,
      ...assessment.groupNames,
      ...assessment.roleNames,
    ].some(name => name.toLowerCase().includes(query.toLowerCase()));

  const completionDateComparator = (a, b) => new Date(a.timeCompleted).getTime() - new Date(b.timeCompleted).getTime();

  const rolesFilter = useArrayFilter({ matcher: rolesMatcher });
  const groupsFilter = useArrayFilter({ matcher: groupsMatcher });
  const taskListFilter = useArrayFilter({ matcher: taskListMatcher });
  const queryFilter = useTextFilter({ matcher: queryMatcher });

  const multiFilter = useMultiFilter({
    roles: rolesFilter,
    groups: groupsFilter,
    taskLists: taskListFilter,
    query: queryFilter,
  });

  const sortFilter = useSortFilter({
    comparators: {
      lastName: (a, b) => a.lastName.localeCompare(b.lastName),
      timeCompleted: completionDateComparator,
    },
    initialState: { method: 'timeCompleted', order: 'descending' },
  });

  const selectedResults = assessmentData.views?.filter(view => view.isSelected) || [];
  const filteredAssessments = assessmentData.views && sortFilter.filter(multiFilter.filter([...assessmentData.views]));

  const { filters } = multiFilter;

  const activeFilters = {};
  if (!filters.roles.isEmpty) activeFilters['Role'] = filters.roles.reset;
  if (!filters.groups.isEmpty) activeFilters['Group'] = filters.groups.reset;
  if (!filters.taskLists.isEmpty) activeFilters['Course'] = filters.taskLists.reset;

  const anyFilteredSelected = filteredAssessments && filteredAssessments.some(assessment => assessment.isSelected);

  useEffect(() => {
    assessmentService
      .getAssesseeData(orgId, assessmentId)
      .then(response => {
        const cachedFilteredResults = cacheService.get(`assessmentFilter-${assessmentId}`);
        setData(prev => ({
          ...prev,
          assessmentData: {
            ...response,
            views: response.views.map(view => ({
              ...view,
              isSelected:
                cachedFilteredResults?.length > 0 &&
                cachedFilteredResults.some(c => c.assessmentResponseId.id === view.assessmentResponseId.id),
            })),
          },
          isLoading: false,
        }));
      })
      .catch(error => {
        console.error(error);
        setData(prev => ({ ...prev, isLoading: false, isError: true }));
      });
  }, []);

  const handleSelect = (assessment = null, selected) => {
    setData(prevData => {
      const allAssessmentsWithSelectionUpdates = bulkSelect(
        prevData.assessmentData.views,
        assessment ? [assessment] : filteredAssessments,
        (a, b) => a.assessmentResponseId.id === b.assessmentResponseId.id,
        selected
      );

      return { ...prevData, assessmentData: { ...prevData.assessmentData, views: allAssessmentsWithSelectionUpdates } };
    });
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      {isLoading ? (
        <LoadingState />
      ) : isError ? (
        <ErrorMessage>
          A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
        </ErrorMessage>
      ) : (
        <PageContainer>
          <PageTitle>
            <h1>{`${assessmentData.assessmentName} Results`}</h1>
          </PageTitle>
          <PageCard>
            <AssessmentResultsFilterControl
              assessmentData={assessmentData}
              selectedResults={selectedResults}
              multiFilter={multiFilter}
            />
            <div>
              {!multiFilter.isEmpty && (
                <ActiveFilters
                  count={filteredAssessments.length}
                  filters={activeFilters}
                  onClearAll={multiFilter.reset}
                />
              )}
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <ButtonGroup>
                      <SortButton
                        sortActive={sortFilter.method === 'lastName'}
                        direction={sortFilter.order === 'ascending' ? 'up' : 'down'}
                        onClick={() => sortFilter.update('lastName')}
                      >
                        People
                      </SortButton>
                      <SortButton
                        sortActive={sortFilter.method === 'timeCompleted'}
                        direction={sortFilter.order === 'ascending' ? 'up' : 'down'}
                        onClick={() => sortFilter.update('timeCompleted')}
                      >
                        Completion Date
                      </SortButton>
                    </ButtonGroup>
                  </th>
                  <SelectTableHeaderCell>
                    {anyFilteredSelected ? (
                      <LinkStyleButton onClick={() => handleSelect(null, false)} data-qa-hook="assessmentsDeselectAll">
                        Select None
                      </LinkStyleButton>
                    ) : (
                      <LinkStyleButton onClick={() => handleSelect(null, true)} data-qa-hook="assessmentsSelectAll">
                        Select All
                      </LinkStyleButton>
                    )}
                  </SelectTableHeaderCell>
                </tr>
              </thead>
              <tbody data-qa-hook="assessmentsResultList">
                {!filteredAssessments.length ? (
                  <tr className="no-table-hover">
                    <td colSpan="2">
                      <EmptyState
                        icon="fa-search"
                        title="Your search does not have any matches"
                        description="Please try another search."
                      />
                    </td>
                  </tr>
                ) : (
                  filteredAssessments.map((assessment, i) => {
                    return (
                      <tr key={assessment.assessmentResponseId.id} data-qa-hook="assessmentsResult">
                        <AssessmentInfoContainer>
                          <ProfileImageWrapper>
                            {assessment.profileImage ? (
                              <ProfileImage src={assessment.profileImage} />
                            ) : (
                              <PlaceholderProfileImage>
                                <i className="fas fa-user-circle"></i>
                              </PlaceholderProfileImage>
                            )}
                          </ProfileImageWrapper>
                          <AssessmentInfo>
                            <h4>{assessment.fullName}</h4>

                            <InlineList>
                              <li>
                                Completed in <span>{assessment.taskListName}</span>
                              </li>
                            </InlineList>
                            <InlineList>
                              <li>Started on {formatDate(assessment.timeStarted)}</li>
                              <li>Completed on {formatDate(assessment.timeCompleted)}</li>
                            </InlineList>
                          </AssessmentInfo>
                        </AssessmentInfoContainer>
                        <RadioContainer
                          data-qa-hook={`assessmentSelectRadio`}
                          className="select-wrapper toggle partition"
                          onClick={() => handleSelect(assessment, !assessment.isSelected)}
                        >
                          <span>
                            <i
                              className={
                                assessment.isSelected ? 'icon ion-ios-checkmark' : 'icon ion-ios-circle-outline'
                              }
                            />
                          </span>
                        </RadioContainer>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </PageCard>
        </PageContainer>
      )}
    </>
  );
};

export default AssessmentResultsFilter;
