import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { react2angular } from 'react2angular';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { ContainerFluid, Container } from '../components/Layout';
import CurriculumCarousel from './CurriculumCarousel';
import curriculumService from '../services/curriculumService';

const NoCurriculum = () => (
  <span className="empty-state">
    <h1 className="no-cover">No Curriculum</h1>
    <p className="head-light text-center">When you are assigned curriculum it will show up here.</p>
  </span>
);

// TODO: remove onCurriculumLoaded when not integrated into Angular
export default function CurriculumDashboard({ onCurriculumLoaded }) {
  const [data, setData] = useState({ isLoading: true });

  useEffect(() => {
    // prevent a redirect if the component is unmounted
    let handleComplete = crmItems => {
      onCurriculumLoaded(crmItems);
    };

    curriculumService
      .getDistributedCurriculum()
      .then(curriculums => {
        curriculums.sort((a, b) => {
          if (a.curriculum.brand === b.curriculum.brand) {
            return curriculumService.compareAgeCategory(a.curriculum.name, b.curriculum.name);
          } else {
            return curriculumService.compareBrandName(a.curriculum.brand, b.curriculum.brand);
          }
        });

        setData({ curriculums: curriculums, isLoading: false });
        handleComplete(curriculums);
      })
      .catch(reason => {
        console.error(reason);
        setData({ isLoading: false, isError: true });
        handleComplete([]);
      });

    return () => {
      handleComplete = () => {};
    };
  }, []);

  return (
    <ContainerFluid style={{ margin: '80px auto' }}>
      <Container data-qa-hook="curriculumCarouselsContainer">
        {data.isLoading ? (
          <LoadingState />
        ) : data.isError ? (
          <ErrorMessage>
            An error occurred in gathering your assigned curriculum. Please refresh the page to try again.
          </ErrorMessage>
        ) : !data.curriculums.length ? (
          <NoCurriculum />
        ) : (
          data.curriculums.map(item => (
            <CurriculumCarousel key={item.curriculum.id} curriculum={item.curriculum} sessions={item.sessions} />
          ))
        )}
      </Container>
    </ContainerFluid>
  );
}

CurriculumDashboard.propTypes = {
  onCurriculumLoaded: PropTypes.func, // unnecessary once component is out of Angular
};

angular.module('lwNamb').component('curriculumDashboard', react2angular(CurriculumDashboard, ['onCurriculumLoaded']));
