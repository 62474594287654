import { useEffect, useRef, useContext } from 'react';
import { useUser } from '../authentication';
import OrgContext from '../contexts/OrgContext';

const useOrg = handler => {
  const user = useUser();
  const org = useContext(OrgContext);
  const orgRef = useRef();

  useEffect(() => {
    if (!handler) return;

    // keep effect from firing handler when there's not be a change
    if (orgRef.current?.id && (!org || orgRef.current.id === org.id)) return;

    if (!user) return handler({ isLoading: false, error: 'NO_USER' });
    if (!user.lastSelectedAccount) return handler({ isLoading: false, error: 'NO_ORG' });

    if (org?.id && org.id === user.lastSelectedAccount) {
      orgRef.current = org;
      return handler({ org, isLoading: false });
    }

    handler({ isLoading: true });
  }, [org, user?.lastSelectedAccount]);

  return org;
};

export default useOrg;
