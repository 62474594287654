angular.module('lwNamb').factory('inviteDataFormatter', [
  '$localStorage',
  function ($localStorage) {
    return {
      get: function () {
        var invitees = [];
        if ($localStorage.recipients !== undefined) {
          $localStorage.recipients.forEach(function (recipient) {
            invitees.push({
              email: recipient.email.toLowerCase(),
              firstName: recipient.firstName,
              lastName: recipient.lastName,
            });
          });
        }
        var email = {
          subject: 'Invitation',
          body: $localStorage.message,
        };
        var roles = [];
        if ($localStorage.inviteRoles !== undefined) {
          $localStorage.inviteRoles.forEach(function (role) {
            roles.push({
              id: role.id,
              name: role.name,
            });
          });
        }

        var licenses = [];
        if ($localStorage.inviteLicenses !== undefined) {
          $localStorage.inviteLicenses.forEach(function (license) {
            licenses.push({
              id: { id: license.id },
              name: license.name,
              managerId: license.orgId,
            });
          });
        }
        var groups = [];
        if ($localStorage.inviteGroups !== undefined) {
          $localStorage.inviteGroups.forEach(function (group) {
            groups.push({
              id: group.id,
              name: group.name,
            });
          });
        }
        var trainings = [];
        if ($localStorage.inviteTraining) {
          $localStorage.inviteTraining.forEach(function (training) {
            var item = {};
            if (
              training.trainingType === 'Tasklist' ||
              training._type === 'TaskList' ||
              training._type === 'Tasklist' ||
              (training.training !== undefined && training.training.id.taskListId !== undefined)
            ) {
              item._type = 'TaskList';
              item.taskListId = {
                id: training.trainingId,
              };
            } else {
              item._type = 'Workflow';
              item.workflowId = {
                id: training.trainingId,
              };
            }
            trainings.push(item);
          });
        }

        return {
          invitationName: $localStorage.invitationName,
          roles: roles,
          trainings: trainings,
          licenses: licenses,
          groups: groups,
          invitees: invitees,
          email: email,
          expirationDate: $localStorage.expirationDate,
          isExpirationDateSet: $localStorage.isExpirationDateSet,
          redemptionLimit: $localStorage.unlimited ? undefined : $localStorage.redemptionLimit,
          unlimited: $localStorage.unlimited,
          limit: $localStorage.unlimited ? undefined : $localStorage.redemptionLimit,
          date: $localStorage.isExpirationDateSet ? new Date($localStorage.expirationDate) : undefined,
        };
      },
      clear: function () {
        $localStorage.invitationName = undefined;
        $localStorage.inviteRoles = undefined;
        $localStorage.inviteTraining = undefined;
        $localStorage.inviteLicenses = undefined;
        $localStorage.inviteGroups = undefined;
        $localStorage.recipients = undefined;
        $localStorage.message = undefined;
        $localStorage.expirationDate = undefined;
        $localStorage.redemptionLimit = undefined;
        $localStorage.unlimited = undefined;
        $localStorage.isExpirationDateSet = undefined;
      },
    };
  },
]);
