import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Type, Breakpoints } from '../StyleGuide';
import { InlineList } from '../components/Layout';
import { CurriculumImage, TrainingImage } from '../curriculum/CurriculumElements';
import licenseService from '../services/licenseService';
import formatDate from './utils/formatDate';

const Container = styled.div`
  align-content: center;

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: min-content 1fr;
  }
`;

const Details = styled.div`
  align-content: center;

  h4,
  p {
    margin: 0;
    padding: 0;
  }

  p,
  ul li {
    color: ${Type.Color.medium};
  }
`;

const LicenseDetails = ({ license, showAdditionalDetails }) => (
  <Container className="grid-container grid-gap-24">
    {license.curriculum ? <CurriculumImage brandCode={license.curriculum.brand.code} /> : <TrainingImage />}
    <Details className="grid-container grid-row-gap-8">
      <h4 data-qa-hook="itemName">{license.subscription?.item.title || license.name}</h4>
      <InlineList content="•">
        {license.subscription?.period && <li>{license.subscription.period} Subscription</li>}

        {showAdditionalDetails &&
          (license.expired === true ? (
            <li>Expired {formatDate(license.endDate)}</li>
          ) : license.subscription?.nextRenewalDate && licenseService.isActiveSubscription(license) ? (
            <li>Renews {formatDate(license.subscription.nextRenewalDate)}</li>
          ) : license.subscription?.nextRenewalDate && licenseService.isInactiveSubscription(license) ? (
            <li>Expires {formatDate(license.subscription.nextRenewalDate)}</li>
          ) : license.endDate ? (
            <li>Ends {formatDate(license.endDate)}</li>
          ) : (
            <></>
          ))}
      </InlineList>
      {showAdditionalDetails && license.owner && <p>Owned by {license.owner.email}</p>}
    </Details>
  </Container>
);

LicenseDetails.propTypes = {
  license: PropTypes.shape({
    name: PropTypes.string,
    curriculum: PropTypes.shape({
      brand: PropTypes.shape({
        code: PropTypes.string,
      }),
    }),
    subscription: PropTypes.shape({
      item: PropTypes.shape({
        title: PropTypes.string,
      }),
      period: PropTypes.string,
      nextRenewalDate: PropTypes.string,
    }),
    expired: PropTypes.bool,
    endDate: PropTypes.string,
    owner: PropTypes.shape({
      email: PropTypes.string,
    }),
  }),
  showAdditionalDetails: PropTypes.bool,
};

export default LicenseDetails;
