import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fnOrUndef } from '../../utils/funcUtils';
import { OrderingButtonGroup } from '../../components/OrderingElements';
import sessionTimelineService from '../../services/sessionTimelineService';
import alertService from '../../services/AlertService';
import {
  SegmentContainer,
  EditFlexOptionToolbar,
  FlexSegmentOptionHeader,
  SegmentLeader,
  TimelineVerticalSpacer,
} from '../../components/SessionTimelineContentElements';
import { ButtonGroup, SmallRoundedIconButton } from '../../components/Buttons';
import { useUser } from '../../authentication';
import { updateItemInList } from '../../services/sessionTimelineService';
import useSessionTimeline from '../../hooks/useSessionTimeline';

const FlexOption = ({ option, index, openModal, children, segment, segmentData, setSegmentData, timelineId }) => {
  const user = useUser();
  const { setTimeline } = useSessionTimeline();

  const updateItemIndex = (list, fromIndex, toIndex) => {
    const listCopy = [...list];
    listCopy.splice(toIndex, 0, listCopy.splice(fromIndex, 1)[0]);
    return listCopy;
  };

  const updateFlexItemIndex = (from, to) => {
    const reordered = updateItemIndex(segmentData.options, from, to);
    setSegmentData(prev => ({
      ...prev,
      hasOrderChanged: true,
      options: reordered,
    }));
  };

  const updateFlexOptionOrder = () => {
    setSegmentData(prev => ({
      ...prev,
      isSaving: segmentData.hasOrderChanged,
      isOrdering: false,
    }));

    if (segmentData.hasOrderChanged) {
      sessionTimelineService
        .reorderSegments(
          timelineId,
          segmentData.options.map(s => s.segmentId),
          user.userId
        )
        .then(() => alertService.show('Flex Options Updated'))
        .then(() => {
          setSegmentData(prev => ({
            ...prev,
            hasOrderChanged: false,
            isSaving: false,
            previousOptions: segmentData.options,
          }));
        })
        .then(() => {
          const updatedSegment = { ...segment, options: segmentData.options };
          setTimeline(previousTimeline => {
            const updatedTimeline = {
              ...previousTimeline,
              segments: updateItemInList(previousTimeline.segments, 'segmentId', segment.segmentId, segment => ({
                ...segment,
                ...updatedSegment,
              })),
            };
            return updatedTimeline;
          });
        })
        .catch(error => {
          alertService.showError();
          setSegmentData(prev => ({
            ...prev,
            hasOrderChanged: false,
            isSaving: false,
            options: segmentData.previousOptions,
          }));
        });
    }
  };

  return (
    <>
      <SegmentContainer id={`contents-${option.segmentId}`} name={`contents-${option.segmentId}`}>
        <FlexSegmentOptionHeader name={option.name} activity={option.activity} position={index + 1} />
        <EditFlexOptionToolbar
          leftContent={null}
          rightContent={
            <ButtonGroup>
              {segmentData.isOrdering ? (
                <OrderingButtonGroup
                  horizontal={false}
                  save={updateFlexOptionOrder}
                  isSaving={segmentData.isSaving}
                  decrement={fnOrUndef(index > 0, () => updateFlexItemIndex(index, index - 1))}
                  increment={fnOrUndef(index < segmentData.options?.length - 1, () =>
                    updateFlexItemIndex(index, index + 1)
                  )}
                />
              ) : (
                <>
                  <SmallRoundedIconButton
                    onClick={() =>
                      setSegmentData(prev => ({
                        ...prev,
                        isOrdering: true,
                      }))
                    }
                  >
                    <i className="fas fa-bars"></i>
                  </SmallRoundedIconButton>
                  <SmallRoundedIconButton onClick={() => openModal('editSegment', { index, segment: option })}>
                    <i className="fas fa-pen"></i>
                  </SmallRoundedIconButton>
                  <SmallRoundedIconButton onClick={() => openModal('removeSegment', { segment: option })}>
                    <i className="far fa-trash-alt"></i>
                  </SmallRoundedIconButton>
                </>
              )}
            </ButtonGroup>
          }
        />
        <SegmentLeader timelineClassName={'timeline-highlight'} leader={option.leader} />
        {children}
      </SegmentContainer>
      <TimelineVerticalSpacer timelineClassName={'timeline-highlight'} spaces="1" />
    </>
  );
};

FlexOption.propTypes = {
  option: PropTypes.object.isRequired,
  index: PropTypes.number,
  openModal: PropTypes.func,
  children: PropTypes.node,
  segmentData: PropTypes.object,
  setSegmentData: PropTypes.func,
  timelineId: PropTypes.string,
};

const FlexBuilderSegment = ({ options, children, ...passThroughProps }) => {
  const [segmentData, setSegmentData] = useState({
    isOrdering: false,
    hasOrderChanged: false,
    isSaving: false,
    previousOptions: options,
    options: options,
  });

  useEffect(() => {
    setSegmentData(prev => ({
      ...prev,
      previousOptions: options,
      options: options,
    }));
  }, [options]);

  return segmentData.options.map((option, i) => (
    <FlexOption
      key={option.segmentId}
      option={option}
      index={i}
      segmentData={segmentData}
      setSegmentData={setSegmentData}
      {...passThroughProps}
    >
      {children({ timelineClassName: 'timeline-highlight', segment: option, sections: option.sections })}
    </FlexOption>
  ));
};

FlexBuilderSegment.propTypes = {
  options: PropTypes.array.isRequired,
  timelineId: PropTypes.string.isRequired,
  children: PropTypes.func,
};

export default FlexBuilderSegment;
