import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Breadcrumbs from '../components/Breadcrumbs';
import { MediumPrimaryButton, MediumButton, PrimaryButton } from '../components/Buttons';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import { Card, Container } from '../components/Layout';
import useOrg from '../hooks/useOrg';
import { Grid, Color, Type } from '../StyleGuide';
import userService from '../services/userService';
import ProfileImage from '../groups/ProfileImage';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../components/ModalElements';
import { FormField } from '../components/FormElements';
import { useUser } from '../authentication';
import useAngularScope from '../hooks/useAngularScope';
import orgService from '../services/orgService';
import windowService from '../services/windowService';
import uuid from '../services/uuid';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import useModal from '../hooks/useModal';
import AccessDenied from '../components/AccessDenied';
import ProfileImageList from '../components/ProfileImageList';

export const PanelCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${Grid._4};
  height: 250px;

  h5 {
    margin: ${Grid._4} 0 ${Grid._5} 0;
  }

  p {
    font-size: ${Type.Scale._1};
    color: ${Type.Color.medium};
    margin-top: ${Grid._2};
  }
`;

const ImagesWrapper = styled.div`
  display: flex;
  position: relative;
  margin: ${Grid._3};

  & > * {
    width: ${Grid._6};
    height: ${Grid._6};
    margin: 0 -${Grid._3};
    border: 1px solid ${Color.white};
    border-radius: 50%;
  }

  span {
    &:before {
      background: ${Color.white};
      font-size: ${Grid._6};
      border-radius: 50%;
      color: ${Color.Gray._50};
    }
  }
`;

const AdditionalMembers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Color.Gray._20};
  color: ${Color.Gray._95};
  font-size: ${Type.Scale._1};
`;

const TagImage = styled.i`
  font-size: ${Grid._6};
  color: ${Color.Gray._80};
  margin: ${Grid._4} 0 0;
`;

const MemberImages = ({ members }) => {
  const [memberImageUrls, setMemberImageUrls] = useState([]);

  useEffect(() => {
    members.map(member =>
      userService.getProfile(member.id).then(res => {
        if (res?.profile?.imageUrl) {
          setMemberImageUrls(prev => [res?.profile?.imageUrl, ...prev]);
        }
      })
    );
  }, [members]);

  return (
    <ImagesWrapper>
      {members.length > 0 && <ProfileImage src={memberImageUrls[0]} />}
      {members.length > 1 && <ProfileImage src={memberImageUrls[1]} />}
      {members.length > 2 && <ProfileImage src={memberImageUrls[2]} />}
      {members.length > 3 && <AdditionalMembers>+{members.length - 3}</AdditionalMembers>}
    </ImagesWrapper>
  );
};

MemberImages.propTypes = {
  members: PropTypes.object,
};

const RolesAddLocked = ({ handleDismiss }) => {
  const handleSubmit = () => windowService.redirectToPurchase();

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader data-qa-hook="rolesAddLockedView">
        <h3>Unlock Roles</h3>
      </ModalHeader>
      <ModalBody>
        <h5>
          When you purchase and pair a <strong>team</strong> subscription, you will be able to create an unlimited
          number of roles to give people specific responsibilities on the platform.
        </h5>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton data-qa-hook="rolesAddLockedContinue" type="submit" onClick={handleSubmit}>
          Get Started
        </MediumPrimaryButton>
      </ModalFooter>
    </ModalContainer>
  );
};

RolesAddLocked.propTypes = {
  handleDismiss: PropTypes.func,
};

const AddRoleModal = ({ orgId, user, handleDismiss }) => {
  const [, updateAngularScope] = useAngularScope();

  const addRoleToOrgRoles = role => updateAngularScope(scope => scope.org.roles.push(role));

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const roleUUID = uuid.generate();

    orgService
      .createRole(orgId, roleUUID, values?.name, user.userId)
      .then(res => {
        addRoleToOrgRoles(res.role);

        windowService.redirectTo(`#/roles/${roleUUID}`);
        handleDismiss();
      })
      .catch(err => {
        setSubmitting(false);

        if (err.code === 'RoleNameTaken') setFieldError('name', 'That name is already in use');
        if (err.code === 'RoleNameTooLong') setFieldError('name', 'Name is too long');
      });
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader data-qa-hook="rolesAddView">
        <h3>Add Role</h3>
      </ModalHeader>
      <Formik
        initialValues={{ name: '' }}
        validationSchema={Yup.object({
          name: Yup.string().max(64, 'Name is too long').required('A name is required'),
        })}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              <FormField data-qa-hook="rolesAddName" label="Name" name="name" type="text" placeholder="Enter Name" />
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton
                data-qa-hook="rolesAddConfirm"
                type="submit"
                disabled={isSubmitting}
                operating={isSubmitting}
              >
                Add Role
              </MediumPrimaryButton>
              <MediumButton data-qa-hook="rolesAddCancel" type="button" disabled={isSubmitting} onClick={handleDismiss}>
                Cancel
              </MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};

AddRoleModal.propTypes = {
  orgId: PropTypes.string,
  user: PropTypes.object,
  handleDismiss: PropTypes.func,
};

export default function Roles() {
  const user = useUser();
  const [status, setStatus] = useState({ isLoading: true, isError: false });
  const [roles, setRoles] = useState([]);
  const [hasSubscription, setHasSubscription] = useState(false);
  const hasRolesViewPermission = user.permissions.filter(p => p === 'Roles View');
  const crumbs = [{ name: 'Organization', route: '#/org' }, { name: 'Manage Roles' }];

  const [modal, openModal] = useModal((type, payload, dismissModal) => {
    switch (type) {
      case 'addRole':
        return <AddRoleModal orgId={user.lastSelectedAccount} user={user} handleDismiss={dismissModal} />;
      case 'rolesAddLocked':
        return <RolesAddLocked handleDismiss={dismissModal} />;
      default:
        return null;
    }
  });

  useOrg(({ org, isLoading, error }) => {
    if (error) {
      console.error('Unable to retrieve org', error);
      return setStatus({ isError: true });
    }

    if (isLoading) return;

    Promise.all([orgService.getOrgUsers(org.id), orgService.hasSubscription(org.id)])
      .then(([{ users }, hasSub]) => {
        setRoles(
          org.roles
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(role => {
              const usersInRole = users.filter(u => u.roles.some(ur => ur.id.id === role.id.id));
              const memberProfileImages = usersInRole.map(uir => uir.profile?.imageUrl).filter(img => !!img);

              return {
                ...role,
                memberCount: usersInRole.length,
                memberProfileImages,
              };
            })
        );

        setHasSubscription(hasSub);
        setStatus({ isLoading: false, isError: false });
      })
      .catch(error => {
        console.error(error);
        setStatus({ isLoading: false, isError: true });
      });
  });

  const handleSubscriptionRole = () => {
    hasSubscription ? openModal('addRole') : openModal('rolesAddLocked');
  };

  const getMemberCountText = memberCount => {
    if (memberCount === 0) return 'No members';
    if (memberCount === 1) return '1 member';
    return `${memberCount} members`;
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container data-qa-hook="rolesView">
        {!hasRolesViewPermission ? (
          <AccessDenied />
        ) : status.isLoading ? (
          <LoadingState />
        ) : status.isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : (
          <>
            <HelpAndInfoBox
              title={'Manage Organization Roles'}
              description={'Roles allow you to group people by what they can do'}
            />
            <div className="grid-container grid-row-gap-24 mb-40">
              <div className="grid-container grid-col-1 grid-sm-col-3 grid-md-col-4 grid-gap-32">
                <PrimaryButton data-qa-hook="rolesAdd" className="grid-col-span-1" onClick={handleSubscriptionRole}>
                  <i className="fas fa-plus" />
                  Add Role
                </PrimaryButton>
              </div>

              <div
                data-qa-hook="rolesPanelList"
                className="grid-container grid-col-1 grid-sm-col-3 grid-md-col-4 grid-gap-32"
              >
                {roles.map(role => (
                  <PanelCard
                    key={role.name}
                    data-qa-hook="rolesPanel"
                    className="grid-col-span-1"
                    onClick={() => windowService.redirectTo(`#/roles/${role.id.id}`)}
                  >
                    <TagImage className="fas fa-tag"></TagImage>
                    <h5 data-qa-hook="rolesPanelName">{role.name}</h5>
                    <ProfileImageList srcUrls={role.memberProfileImages} totalUsers={role.memberCount} />
                    <p>{getMemberCountText(role.memberCount)}</p>
                  </PanelCard>
                ))}
              </div>
            </div>
            {modal}
          </>
        )}
      </Container>
    </>
  );
}
