import { TrainingEntity } from './training';

// If we use this outside of the manageTraining context, then
// we may need to name it something more specific like TrainingContent

export const ContentTypes = ['Download', 'Video', 'Assessment'] as const;

export type ContentType = (typeof ContentTypes)[number];

export type Content = TrainingEntity & {
  type: ContentType;
};
