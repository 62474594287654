import React, { useState, useEffect } from 'react';
import eventBus from '../services/globalEventBus';

type Subscriber<T = any> = (data: T) => void;

/*
  Allows for easier use of the event bus in React.

  If you want a single, long-running event listener for your component,
  set it as the initialSubscriber. It will be set once on component
  mount then unsubscribed upon component unmount.

  If you want to set a single or multiple listeners later in the
  component lifecycle, use the push function returned by the
  hook to push listeners which will be cleaned up when the
  component is unmounted.
*/
export default function useEventBusSubscription(
  eventName: string,
  initialSubscriber?: Subscriber
): (sub: Subscriber) => () => void {
  const [subscribers, setSubscribers] = useState<Subscriber[]>(initialSubscriber ? [initialSubscriber] : []);

  const removeSubscription = (sub: Subscriber) => setSubscribers(subs => subs.filter(s => s !== sub));

  const pushSubscription = (sub: Subscriber) => {
    setSubscribers(subs => [...subs, sub]);
    return () => removeSubscription(sub);
  };

  useEffect(() => {
    const unsubscribe = eventBus.subscribe(eventName, (data: any) => {
      subscribers.forEach(sub => sub(data));
    });

    return () => unsubscribe();
  }, [subscribers]);

  return pushSubscription;
}
