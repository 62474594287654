import React from 'react';
import styled from 'styled-components';
import { Grid } from '../StyleGuide';
import { Container } from '../components/Layout';
import RestrictedAccessHeader from '../components/RestrictedAccessHeader';
import windowService from '../services/windowService';
import { MediumPrimaryButton } from '../components/Buttons';

// The `margin-top` for this container is only necessary because
// this component is used as an Angular directive. Once Angular
// is removed, the `margin-top` on this container can be removed as well.
const ContentContainer = styled(Container)`
  margin-top: ${Grid._14};

  button {
    margin-top: ${Grid._5};
  }
`;

const PageNotFound = () => {
  const redirectToDashboard = () => {
    windowService.redirectTo('/dashboard');
  };

  return (
    <ContentContainer>
      <RestrictedAccessHeader
        title="Page Not Found"
        description={<>Sorry, we're unable to find the page you're looking for</>}
      >
        <MediumPrimaryButton onClick={redirectToDashboard}>Return to Dashboard</MediumPrimaryButton>
      </RestrictedAccessHeader>
    </ContentContainer>
  );
};

export default PageNotFound;
