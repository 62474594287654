import { useCallback, useMemo, useState } from 'react';

type SortBy = {
  [key: string]: string[];
};

const usePropertyFilter = (
  results: any[],
  filters: {
    [key: string]: {
      displayName: string;
    };
  }
) => {
  const [sortBy, setSortBy] = useState<SortBy>(
    Object.keys(filters).reduce((acc, key) => {
      return { ...acc, [key]: [] };
    }, {} as SortBy)
  );

  const updateSortBy = useCallback((val: string, type: string) => {
    setSortBy(prevData => ({
      ...prevData,
      [type]: prevData[type].includes(val) ? prevData[type].filter(i => i !== val) : [...prevData[type], val],
    }));
  }, []);

  const sortedResults = useMemo(() => {
    const hasSortItem = (list: any[], sortLists: string[]) => {
      return sortLists.length
        ? sortLists.some(sortListItem =>
            list.some(listItem => (listItem.id ? listItem.id.id === sortListItem : listItem === sortListItem))
          )
        : true;
    };
    return results?.filter(res => Object.entries(sortBy).every(([type, value]) => hasSortItem(res[type], value)));
  }, [sortBy, results]);

  const resetSortBy = (type: string) => setSortBy(prevData => ({ ...prevData, [type]: [] }));

  return { sortBy, sortedResults, updateSortBy, resetSortBy };
};

export default usePropertyFilter;
