angular.module('lwNamb').controller(
  'LockedTaskListTaskViewerCtrl',

  [
    '$scope',
    '$log',
    'userService',
    'taskListService',
    '$routeParams',
    '$window',
    function($scope, $log, userService, taskListService, $routeParams, $window) {
      var init = function() {
        $scope.loading = true;
        $scope.id = $routeParams.id;
        $scope.taskIndex = $routeParams.taskIndex;
        userService.user().then(
          function(user) {
            $scope.user = user;
            retrieveTLData();
          },
          function() {
            $log.error('No user returned in TaskViewerCtrl');
          }
        );
      };

      var retrieveTLData = function() {
        taskListService
          .getTasklist($routeParams.id)
          .then(
            function(taskList) {
              $scope.taskList = taskList;
              $scope.taskList.tasks.map(function(task) {
                task.isPreview = true;
                return task;
              });
            },
            function(reason) {
              $log.error(reason);
              $scope.isError = true;
            }
          )
          .finally(function() {
            $scope.loading = false;
          });
      };

      $scope.redirectToTrainingViewer = function() {
        $window.history.back();
      };

      $scope.next = function() {
        $scope.taskIndex++;
        scrollToTop();
      };

      $scope.previous = function() {
        $scope.taskIndex--;
        scrollToTop();
      };

      var scrollToTop = function() {
        window.scrollTo(0, 0);
      };

      init();
    },
  ] //End Locked Task List Task Viewer Ctrl
);
