import React from 'react';
import PropTypes from 'prop-types';
import { MediumButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';

const TermsModal = ({ handleDismiss }) => {
  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3 data-qa-hook="removeModalHeader">Terms</h3>
      </ModalHeader>
      <ModalBody data-qa-hook="removeModalMessage">
        <h4 className="modal-component-subtext">Subscription and Cancellation Terms</h4>
        <p>
          By purchasing a subscription to one of Lifeway's Bible studies on Ministry Grid, you agree to an initial and
          recurring subscription fee at the then-current subscription rate, and you accept responsibility for all
          recurring charges until you cancel your subscription. All subscription fees are non-refundable. You may cancel
          your subscription anytime from the Ministry Grid Organization dashboard. Upon cancellation, your organization
          will retain access until the next billing period, at which point you will no longer be billed and you will
          immediately lose access. You can reactivate your cancelled subscription prior to the next billing period from
          the Organization dashboard. Once the billing period passes, a new subscription will need to be purchased.
        </p>
      </ModalBody>
      <ModalFooter>
        <MediumButton onClick={() => handleDismiss()}>Close</MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

TermsModal.propTypes = {
  handleDismiss: PropTypes.func.isRequired,
};

export default TermsModal;
