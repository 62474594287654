import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { useParams } from 'react-router-dom';

import { Container, PageTitle } from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import { PrimaryButton } from '../components/Buttons';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import RichTextEditor from '../containers/RichTextEditor';
import { Label, FormFieldMaxCharCountStatus, Select, FormFieldContainer } from '../components/FormElements';
import { handleError } from '../utils/apiUtils';

import { Grid } from '../StyleGuide';

import { useUser } from '../authentication';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';

import windowService from '../services/windowService';
import alertService from '../services/AlertService';
import assessmentService from '../services/assessmentService';
import { create } from 'yup/lib/Reference';

const StyledSelect = styled(Select)`
  width: 50%;
`;

const ButtonWrapper = styled.div`
  margin-top: ${Grid._5};
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  grid-gap: ${Grid._4};
  margin: ${Grid._7} 0px;
`;

const AssessmentFormFieldContainer = styled(FormFieldContainer)`
  margin-bottom: ${Grid._4};
`;

const AssessmentSettings = () => {
  const user = useUser();
  const { assessmentId } = useParams();

  const [status, setStatus] = useState({ isLoading: true, isError: false });
  const [formData, setFormData] = useState({});
  const [assessment, setAssessment] = useState({});

  const introductionEditorRef = useRef();
  const conclusionEditorRef = useRef();

  const { manageTrainingRoutePrefix, createManageTrainingCrumb } = useManageTrainingRoute();

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    createManageTrainingCrumb('content'),
    { name: 'Assessment Builder', route: `#${manageTrainingRoutePrefix}/assessments/${assessmentId}` },
    { name: 'Assessment Settings' },
  ];

  useEffect(() => {
    assessmentService
      .getAssessment(assessmentId, user.userId)
      .then(res => {
        setAssessment(res);
        setFormData({
          name: res.name,
          introduction: EditorState.createWithContent(stateFromHTML(res.instructions)),
          conclusion: EditorState.createWithContent(stateFromHTML(res.overview)),
          displayResults: res.responseVisibility,
        });
        setStatus(prevStatus => ({ ...prevStatus, isLoading: false, isError: false }));
      })
      .catch(error => {
        console.error(error);
        setStatus(prevStatus => ({ ...prevStatus, isLoading: false, isError: true }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    const promises = [];

    if (formData.name !== values.name)
      promises.push(assessmentService.updateAssessmentName(assessmentId, user.userId, assessment.ownerId, values.name));

    if (formData.introduction !== values.introduction)
      promises.push(
        assessmentService.updateAssessmentIntro(
          assessmentId,
          user.userId,
          stateToHTML(values.introduction.getCurrentContent())
        )
      );
    if (formData.conclusion !== values.conclusion)
      promises.push(
        assessmentService.updateAssessmentOutro(
          assessmentId,
          user.userId,
          stateToHTML(values.conclusion.getCurrentContent())
        )
      );
    if (formData.displayResults !== values.displayResults)
      promises.push(assessmentService.setResponseVisibility(assessmentId, user.userId, values.displayResults));

    Promise.all(promises)
      .then(() => {
        alertService.showOnNextPage('Assessment Settings Updated');
        windowService.redirectTo(`${manageTrainingRoutePrefix}/assessments/${assessmentId}`);
      })
      .catch(error => {
        error.eventType === 'AssessmentNameDuplicateError'
          ? alertService.showError('Sorry, that assessment name is taken')
          : handleError(error);

        setSubmitting(false);
      });
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container data-qa-hook="assessmentSettingsView">
        {status.isLoading ? (
          <LoadingState />
        ) : status.isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : (
          <>
            <PageTitle className="mt-40">
              <h1>Edit Assessment Settings</h1>
            </PageTitle>
            <Formik
              initialValues={formData}
              validationSchema={Yup.object({
                name: Yup.string().required('A name is required'),
              })}
              onSubmit={handleSubmit}
            >
              {({ values, setValues, setFieldValue, isSubmitting }) => (
                <Form>
                  <FormContainer>
                    <div>
                      <FormFieldMaxCharCountStatus
                        label="Name"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Enter Name"
                        maxLength={64}
                        onBlur={e => setValues(prevValues => ({ ...prevValues, name: e.target.value }))}
                        Wrapper={AssessmentFormFieldContainer}
                      />
                    </div>
                    <div>
                      <Label htmlFor="introduction">Introduction</Label>
                      <RichTextEditor
                        id="introduction"
                        name="introduction"
                        ref={introductionEditorRef}
                        editorState={values.introduction}
                        setEditorState={intro => setFieldValue('introduction', intro)}
                        config={{
                          placeholder: 'Enter Introduction',
                          height: '200px',
                        }}
                      />
                    </div>
                    <div>
                      <Label htmlFor="conclusion">Conclusion</Label>
                      <RichTextEditor
                        id="conclusion"
                        ref={conclusionEditorRef}
                        editorState={values.conclusion}
                        setEditorState={conclusion => setFieldValue('conclusion', conclusion)}
                        config={{
                          placeholder: 'Enter Conclusion',
                          height: '200px',
                        }}
                      />
                    </div>
                    <div>
                      <Label htmlFor="displayResults">Display Results</Label>
                      <StyledSelect
                        value={values.displayResults}
                        name="displayResults"
                        data-qa-hook="assessmentSettingsDisplayResults"
                        onChange={event =>
                          setValues(prevValues => ({ ...prevValues, displayResults: event.target.value }))
                        }
                      >
                        <option value="NONE">No One</option>
                        <option value="ASSESSEE">Taker Only</option>
                        <option value="ASSESSOR">Coach Only</option>
                        <option value="ALL">Taker & Coaches</option>
                      </StyledSelect>
                    </div>
                    <ButtonWrapper>
                      <PrimaryButton data-qa-hook="save" disabled={isSubmitting} operating={isSubmitting} type="submit">
                        Save Settings
                      </PrimaryButton>
                    </ButtonWrapper>
                  </FormContainer>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Container>
    </>
  );
};

export default AssessmentSettings;
