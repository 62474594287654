import React from 'react';
import { useSearch } from '../hooks/useSearch';
import { Facet } from '../models/Facet';
import { FacetContainer } from './SearchElements';

export const FacetList = () => {
  const { allFacets, selectedFacets, selectFacet, deselectFacet } = useSearch();

  const isSelected = (facet: Facet) => {
    return selectedFacets.some(selected => facet.hasEqualFacetValue(selected));
  };

  return allFacets
    .sort((a, b) => a.rank - b.rank)
    .map(facet => (
      <FacetContainer key={facet.key}>
        <h5>{facet.key}</h5>
        <ul>
          {facet.values.map(facetValue => {
            const selected = isSelected(facet.fromFacetValue(facetValue));
            const className = selected ? 'facet-value--selected' : '';
            const updateFn = selected ? deselectFacet : selectFacet;

            return (
              <li
                key={facetValue.key}
                className={className}
                onClick={() => updateFn(facet.fromFacetValue(facetValue))}
                data-selected={selected}
              >
                {Facet.getFacetValueDisplayText(facetValue)} ({facetValue.doc_count})
              </li>
            );
          })}
        </ul>
      </FacetContainer>
    ));
};
