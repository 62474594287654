import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import windowService from '../../services/windowService';
import { Grid, Type, Breakpoints, Color } from '../../StyleGuide';
import useCurriculum from '../../hooks/useCurriculum';
import { InlineList } from '../../components/Layout';
import { OrderingDragHandle } from '../../components/OrderingElements';
import { ButtonGroup, SmallRoundedIconButton } from '../../components/Buttons';
import fileService from '../../services/fileService';
import useModal from '../../hooks/useModal';
import { DeemphasizedBadge } from '../../components/Badge';
import ManageCurriculumRemoveMaterialModal from './ManageCurriculumRemoveMaterialModal';
import { ListItemFlexRow } from '../../components/ListItemFlexRow';
import { FileTypeIconMap } from '../../utils/fileUtils';
import { stopProp } from '../../utils/domUtils';

const MaterialTitleContainer = styled.div`
  display: flex;
  gap: ${Grid._4};
`;

const MaterialDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${Grid._3};

  @media screen and (min-width: ${Breakpoints.screen_xs}) {
    margin-left: ${Grid._4};
  }

  h3 {
    margin: 0;
  }
`;

const CollectionsList = styled(InlineList)`
  display: none;

  @media screen and (min-width: ${Breakpoints.screen_xs}) {
    display: initial;
  }
`;

const PlusBadge = styled(DeemphasizedBadge)`
  margin-left: ${Grid._3};
  padding: 0 ${Grid._3};
`;

const EditOptionsContainer = styled(ButtonGroup)`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const FileTypeContainer = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: ${Type.Scale._4};
  margin: 0px ${Grid._4};

  @media screen and (min-width: ${Breakpoints.screen_xs}) {
    display: flex;
  }
`;

const MaterialListItemFlexRow = styled(ListItemFlexRow)`
  flex-direction: row;
  padding: ${Grid._4};
`;

const ManageCurriculumMaterialListItem = ({ material, handleDeleteMaterial }) => {
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, unitId, sessionId } = useParams();

  const deleteMaterial = () => {
    handleDeleteMaterial(material.materialId);
    dismissModal();
  };

  const [modal, openModal, dismissModal] = useModal((type, payload, dismissModal) => {
    if (!type) return;

    return (
      <ManageCurriculumRemoveMaterialModal
        material={material}
        handleSubmit={deleteMaterial}
        handleDismiss={dismissModal}
      />
    );
  });

  const handleRedirectToEditMaterial = () => {
    windowService.redirectTo(
      `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${unitId}/session/${sessionId}/material/${material.materialId}/settings`
    );
  };

  const handleMaterialPreview = () => {
    if (material.materialType === 'download') {
      window.open(fileService.urlDownloadFile(material.mediaId));
    } else {
      windowService.redirectTo(
        `/curriculum/${curriculumId}/issue/${unitId}/series/:seriesId/session/${sessionId}/build-material-viewer/${material.materialId}`
      );
    }
  };

  return (
    <>
      <MaterialListItemFlexRow onClick={handleMaterialPreview} hoverStyles={`background: ${Color.white};`}>
        <OrderingDragHandle />

        <MaterialDetailsContainer>
          <FileTypeContainer>
            <i className={FileTypeIconMap[material.materialType]} />
          </FileTypeContainer>
          <div>
            <MaterialTitleContainer>
              <h3>
                {material.name}
                {material.package === 'Plus' && <PlusBadge>Plus</PlusBadge>}
              </h3>
            </MaterialTitleContainer>

            <CollectionsList>
              {material.collections.map(collection => (
                <li key={collection.order}>{collection.name}</li>
              ))}
            </CollectionsList>
          </div>
        </MaterialDetailsContainer>
        <EditOptionsContainer>
          <SmallRoundedIconButton>
            <i
              className="fas fa-solid fa-pen"
              data-qa-hook="materialEdit"
              onClick={stopProp(handleRedirectToEditMaterial)}
            />
          </SmallRoundedIconButton>
          <SmallRoundedIconButton>
            <i
              className="far fa-trash-alt"
              data-qa-hook="materialDelete"
              onClick={stopProp(() => openModal('remove'))}
            />
          </SmallRoundedIconButton>
        </EditOptionsContainer>
      </MaterialListItemFlexRow>
      {modal}
    </>
  );
};

ManageCurriculumMaterialListItem.propTypes = {
  material: PropTypes.object,
  handleDeleteMaterial: PropTypes.func,
};

export default ManageCurriculumMaterialListItem;
