angular.module('lwNamb').controller(
  'QuestionBuilderCtrl',

  [
    '$scope',
    '$routeParams',
    '$location',
    '$log',
    '$q',
    'userService',
    'assessBuilderService',
    'orgUserService',
    'uuid4',
    '$timeout',
    'alertService',
    'buildService',
    function(
      $scope,
      $routeParams,
      $location,
      $log,
      $q,
      userService,
      assessBuilderService,
      orgUserService,
      uuid4,
      $timeout,
      alertService,
      buildService
    ) {
      var queryParams = $location.search(),
        questionType = queryParams.questionType,
        assessmentId = $routeParams.assessmentId,
        questionId = $routeParams.questionId,
        creating = !questionId && !!questionType,
        user,
        ownerId;

      var init = function() {
        $scope.questionType = questionType;
        $scope.creating = creating;
        $scope.isAssessment = !buildService.isScorecards();

        //Set Question
        if (creating) {
          questionId = uuid4.generate();
          //Init Empty Question
          if (questionType === 'radio') {
            $scope.question = {
              id: questionId,
              question: '',
              type: 'radio',
              icon: 'fa fa-list-ol',
              placeholder: 'Multiple-Choice',
              displayType: 'radio',
              choices: [{ id: uuid4.generate(), text: '' }, { id: uuid4.generate(), text: '' }],
            };
          }
          if (questionType === 'textbox') {
            $scope.question = {
              id: questionId,
              question: '',
              type: 'textbox',
              icon: 'fa fa-font',
              placeholder: 'Short Answer',
              choices: [{ id: 'possibleansid', text: 'text', isCorrect: true }],
            };
          }
        }

        $scope.submitted = false;
        $scope.maxChoices = false;

        $q.all([userService.user(), buildService.ownerId()]).then(function(result) {
          user = result[0];
          ownerId = result[1];
          if (!creating) {
            loadQuestion();
          }
        });
      };

      function loadQuestion() {
        assessBuilderService.getAssessment(assessmentId, user.userId).then(
          function(response) {
            $scope.isPublished = response.published;
            var question = response.questions.filter(function(q) {
              return q.id.id === questionId;
            })[0];
            if (question) {
              $scope.question = {
                id: questionId,
                question: question.question,
                type: question.questionType,
                displayType: question.displayType,
                icon: 'fa fa-list-ol',
                choices: question.choices,
              };
            }
          },
          function(response) {
            $log.error('Error: Assessment not found; ' + response);
          }
        );
      }

      $scope.addChoice = function() {
        if ($scope.question.choices.length < 15) {
          var choice = { id: uuid4.generate(), text: '', selected: false };
          $scope.question.choices.push(choice);
        } else {
          $scope.maxChoices = true;
        }
      };

      $scope.removeChoice = function(index) {
        if (index > 1) {
          $scope.question.choices.splice(index, 1);
        }
      };

      $scope.toggleAnswers = function(index) {
        if ($scope.question.choices[index].selected === false) {
          return;
        } else {
          for (var i = 0; i < $scope.question.choices.length; i++) {
            $scope.question.choices[i].selected = false;
          }
          $scope.question.choices[index].selected = true;
        }
      };

      $scope.saveQuestion = function(question, isValid) {
        $scope.submitted = true;
        if (!isValid) {
          return;
        }
        $scope.spinner = true;
        var possibleAnswers = [];
        angular.forEach(
          question.choices,
          function(choice) {
            var possibleAnswer = {};
            possibleAnswer.id = choice.id;
            possibleAnswer.answer = choice.text;
            possibleAnswer.isCorrect = choice.selected === undefined ? false : choice.selected;
            this.push(possibleAnswer);
          },
          possibleAnswers
        );
        if (creating) {
          assessBuilderService
            .addQuestion(
              assessmentId,
              ownerId,
              question.id,
              question.question,
              question.type,
              possibleAnswers,
              user.userId,
              question.displayType
            )
            .then(
              function() {
                $scope.redirectToAssessment();
              },
              function(reason) {
                $log.error(reason);
                $scope.spinner = false;
                showAlert('danger', 'Error! Please try again.', false);
              }
            );
        } else {
          if (question.type === 'radio') {
            $scope.questionBackup = $scope.question;
            assessBuilderService
              .updateQuestion(
                assessmentId,
                question.id,
                question.question,
                question.type,
                possibleAnswers,
                user.userId,
                question.displayType
              )
              .then(
                function() {
                  $scope.redirectToAssessment();
                },
                function(reason) {
                  $log.error(reason);
                  $scope.spinner = false;
                  $scope.question = $scope.questionBackup;
                  showAlert('danger', 'Error! Please try again.', false);
                }
              );
          } else if (question.type === 'textbox') {
            $scope.questionBackup = $scope.question;
            assessBuilderService
              .updateQuestion(assessmentId, question.id, question.question, question.type, possibleAnswers, user.userId)
              .then(
                function() {
                  $scope.redirectToAssessment();
                },
                function(reason) {
                  $log.error(reason);
                  $scope.spinner = false;
                  $scope.question = $scope.questionBackup;
                  showAlert('danger', 'Error! Please try again.', false);
                }
              );
          } else {
            showAlert('danger', 'Error! Please try again.', false);
            $scope.spinner = false;
          }
        }
      };

      $scope.cloneQuestion = function(closeModal) {
        $scope.cloning = true;
        var newQuestionId = uuid4.generate();
        var possibleAnswers = [];

        angular.forEach(
          $scope.question.choices,
          function(choice) {
            var possibleAnswer = {};
            possibleAnswer.id = choice.id;
            possibleAnswer.answer = choice.text;
            possibleAnswer.isCorrect = choice.selected === undefined ? false : choice.selected;
            this.push(possibleAnswer);
          },
          possibleAnswers
        );

        assessBuilderService
          .addQuestion(
            assessmentId,
            ownerId,
            newQuestionId,
            $scope.question.question,
            $scope.question.type,
            possibleAnswers,
            user.userId,
            $scope.question.displayType
          )
          .then(
            function() {
              buildService.ctxRedirectTo('/assessments/' + assessmentId + '/questions/' + newQuestionId, {
                questionType: $scope.question.type,
              });
              showAlert('success', 'Question Copied', 5);
            },
            function(reason) {
              $log.error(reason);
              closeModal();
              $scope.cloning = false;
              showAlert('danger', 'Error! Please try again.', false);
            }
          );
      };

      function showAlert(type, msg, duration) {
        alertService.show({ title: msg, type: type, show: true, dismissible: true, duration: duration });
      }

      $scope.redirectToAssessment = function() {
        buildService.ctxRedirectTo('/assessments/' + assessmentId);
      };

      init();
    },
  ]
);
