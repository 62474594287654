angular.module('lwNamb').directive(
  'cloneAllTasklists',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/clone-all-tasklists.html',
      };
    },
  ]
);
