//bulk selection utility for updating multiple items' selection status in a pure manner
//allItems: all items in the collection
//itemsToUpdate: items which should have their isSelected status changed
//comparisonFunc: a function that should return true if a and b represent the same item (e.g. (a, b) => a.id === b.id)
//selected: boolean selection value
//selectedField: name of selection status field on items; defaults to isSelected
const bulkSelect = (allItems, itemsToUpdate, comparisonFunc, selected, selectedField = 'isSelected') =>
  allItems.map(item => {
    //if the item is found to match the criteria specified by comparison func, update it's selected status
    if (itemsToUpdate.some(e => comparisonFunc(item, e))) {
      return {
        ...item,
        [selectedField]: selected,
      };
    }

    return item;
  });

export default bulkSelect;
