angular.module('lwNamb').directive(
  'selectOrganization',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/select-organization.html',
        scope: {
          header: '@',
          orgs: '=',
          selectedOrg: '=',
          action: '&buttonAction',
        },
      };
    },
  ]
);
