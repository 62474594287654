angular.module('lwNamb').controller(
  'CurriculumBuilderCtrl',

  [
    '$scope',
    '$log',
    'curriculumService',
    '$routeParams',
    'alertService',
    'windowService',
    '$rootScope',
    function ($scope, $log, curriculumService, $routeParams, alertService, windowService, $rootScope) {
      function init() {
        $scope.loading = true;
        $scope.curriculumId = $routeParams.curriculumId;
        $scope.curriculum = {};
        $scope.session_count = 0;

        loadData();
      }

      function loadData() {
        curriculumService
          .getCurriculumDetail($scope.curriculumId)
          .then(
            function (response) {
              $scope.curriculum = response.curriculum;
              $scope.curriculum.issues = response.issues;
              $scope.curriculum.series = response.series;
            },
            function (reason) {
              $log.error(reason, ' error in CurriculumBuilderCtrl');
            }
          )
          .finally(function () {
            $scope.loading = false;
          });
      }

      $scope.createSeries = function (valid) {
        $scope.submitted = true;
        if (valid) {
          $scope.creating = true;
          curriculumService
            .createSeries($scope.curriculumId, $scope.name, $scope.classification, $scope.subdivision, $scope.target)
            .then(
              function (seriesId) {
                alertService.show({ type: 'success', title: 'Collection Created', duration: 5 });
                windowService.redirectHash('/manage-curriculum/' + $scope.curriculumId);
              },
              function (response) {
                alertService.show({
                  type: 'danger danger-with-content',
                  title: 'Create Collection Failed',
                  content: 'Something went wrong. Please try again',
                  duration: 20,
                });
                $log.error(response + ' unable to create collection for curriculumId: ' + $scope.curriculumId);
              }
            )
            .finally(function () {
              $scope.creating = false;
            });
        }
      };

      $scope.createIssue = function (valid) {
        $scope.submitted = true;
        if (valid) {
          $scope.creating = true;
          curriculumService
            .createIssue($scope.name, $scope.session_count, $scope.first_use_date, $scope.curriculumId)
            .then(
              function (issueId) {
                windowService.redirectHash('/curriculum/' + $scope.curriculumId + '/issue-builder/' + issueId);
              },
              function (response) {
                $log.error(response + ' unable to create issue for curriculumId: ' + $scope.curriculumId);
              }
            )
            .finally(function () {
              $scope.creating = false;
            });
        }
      };

      $scope.redirectBack = function () {
        if (
          $rootScope.previousRoute &&
          $rootScope.previousRoute.$$route &&
          $rootScope.previousRoute.$$route.originalPath === '/manage-curriculum'
        ) {
          windowService.redirectHash('/manage-curriculum');
        } else {
          windowService.redirectHash('/curriculum');
        }
      };

      init();
    },
  ]
);
