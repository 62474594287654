import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid } from '../StyleGuide';

const BrowserItemDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-width: 210px;
  margin-top: ${Grid._5};
`;

const BrowserIcon = styled.img`
  width: 50px;
  margin-bottom: ${Grid._4};
`;

const BrowserItem = ({ src, alt, title, link }) => {
  return (
    <BrowserItemDiv>
      <BrowserIcon src={src} alt={alt} />
      <strong>{title}</strong>
      <p>
        Supported
        <br />
        Versions:
      </p>
      <p>
        <em>Latest</em>
      </p>
      <p>
        <a href={link}>More Info</a>
      </p>
    </BrowserItemDiv>
  );
};

BrowserItem.propTypes = {
  title: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default BrowserItem;
