import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Type, Color } from '../../StyleGuide';
import { Card, CardPoster, InlineList } from '../../components/Layout';
import limitFilter from '../../filters/limit-filter';

const VolumePoster = styled.div`
  padding-top: 128.7%;
`;

const Details = styled.div`
  border-top: 2px solid ${Color.Gray._10};
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${Grid._3};
  text-transform: capitalize;
  color: ${Type.Color.dark};

  h4 {
    margin: 0 0 ${Grid._3} 0;
    font-weight: ${Type.Weight.semibold};
    flex-grow: 1;
  }
`;

export default function VolumeCard({ volume, onClick }) {
  return (
    <Card data-qa-hook="volumeCard" style={{ flexDirection: 'column' }} onClick={onClick}>
      <VolumePoster as={CardPoster} src={volume.imageUrl} alt={volume.name} />
      <Details>
        <h4>{limitFilter(volume.name, 48)}</h4>
        <InlineList content="•" fontSize="14px">
          {volume.number && <li>Volume {volume.number}</li>}
          {volume.quarter && (
            <li>
              {volume.quarter.season} {volume.quarter.year}
            </li>
          )}
        </InlineList>
      </Details>
    </Card>
  );
}

VolumeCard.propTypes = {
  volume: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
