import React from 'react';
import PropTypes from 'prop-types';

const MaxChars = ({ maxChars, currentChars }) =>
  currentChars > maxChars / 2 ? (
    <span>
      {maxChars - currentChars} character{maxChars - currentChars === 1 ? '' : 's'} left
    </span>
  ) : (
    <span></span>
  );

MaxChars.propTypes = {
  maxChars: PropTypes.number.isRequired,
  currentChars: PropTypes.number.isRequired,
};

export default MaxChars;
