angular.module('lwNamb').directive('btPageHeader', [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'partials/directives/page-header.html',
      replace: true,
      scope: {
        noBtmMargin: '=?', // todo: remove the style dependency on the no-buttons class
      },
      controller: [
        '$scope',
        '$route',
        'properties',
        'catalogService',
        'userService',
        'orgService',
        'apiUrlService',
        function($scope, $route, props, catalogService, userService, orgService, apiUrlService) {
          update($route.current.$$route);

          $scope.$on('$routeChangeSuccess', function(event, current) {
            update(current.$$route);
          });

          function update(route) {
            $scope.containerClass = null;
            $scope.iconClass = null;
            $scope.text = null;

            if (!route) {
              return;
            }

            var section = route.section || {},
              header = route.header || {};

            if (section.header) {
              $scope.containerClass = section.header.containerClass;
              $scope.iconClass = section.header.iconClass;
              $scope.text = section.header.text;
            }

            if (header.containerClass) {
              $scope.containerClass = header.containerClass;
            }

            if (header.iconClass) {
              $scope.iconClass = header.iconClass;
            }

            if (header.text) {
              $scope.text = header.text;
            }

            // special case: org header
            if (section === props.sections.org && !header.text) {
              userService.user().then(function(user) {
                orgService.getOrgOverview(user.lastSelectedAccount).then(function(org) {
                  $scope.text = org.name;
                });
              });
            }

            // special case: authoring header
            if (section === props.sections.author) {
              catalogService.getCatalogForSite(apiUrlService.getOrigin()).then(function(catalog) {
                $scope.text = 'Authoring: ' + catalog.name + ' Catalog';
              });
            }

            $scope.show = !header.hide && $scope.text;
          }
        },
      ],
    };
  },
]);
