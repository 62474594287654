import React, { useState } from 'react';
import styled from 'styled-components';
import { Color, Grid } from '../StyleGuide';
import orgService from '../services/orgService';
import { groupService } from '../services/groupService';
import alertService from '../services/AlertService';
import AddPeopleTo from '../components/AddPeopleTo';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import Breadcrumbs from '../components/Breadcrumbs';
import { Container } from '../components/Layout';
import RemovePeopleFromOrgModal from './RemovePeopleFromOrgModal';
import useOrg from '../hooks/useOrg';
import { useUser } from '../authentication';
import useModal from '../hooks/useModal';
import { DangerButton } from '../components/Buttons';

const PageContainer = styled(Container)`
  background: ${Color.white};
  padding: ${Grid._5};
  margin-top: ${Grid._6};
  margin-bottom: ${Grid._10};
`;

const DangerButtonWithRoundedLabel = styled(DangerButton)`
  .rounded-label {
    color: ${Color.Red._50};
  }
`;

const RemovePeopleFromOrg = () => {
  const user = useUser();
  const [data, setData] = useState({
    org: {},
    roles: [],
    people: [],
    groups: [],
    isLoading: true,
    isSubmitting: false,
    isError: false,
  });
  const [selectedPeople, setSelectedPeople] = useState([]);

  const { isLoading, isError, roles, groups, people, org } = data;

  const crumbs = [{ name: 'People', route: '#/people' }, { name: 'Remove' }];

  useOrg(({ org, isLoading }) => {
    if (isLoading) return;

    const getOrg = orgService.getOrgUsers(org.id);
    const getRoles = orgService.getRolesOverview(org.id, true);
    const getGroups = groupService.getGroups(org.id);

    const promises = [getOrg, getRoles, getGroups];
    Promise.all(promises)
      .then(([orgResponse, roleResponse, groupResponse]) => {
        const formattedGroups = groupResponse.map(el => ({
          id: el.id.id,
          name: el.name,
        }));

        const formattedPeople = orgResponse?.users?.filter(user => user._id && user._id.id !== org.ownerId);

        setData({
          org: orgResponse,
          roles: roleResponse,
          people: formattedPeople,
          groups: formattedGroups,
          isLoading: false,
          isError: false,
        });
      })
      .catch(error => {
        console.error(error);
        setData({ isError: true, isLoading: false });
      });
  });

  const handleRemovePeople = () => {
    orgService
      .removeMembers(org._id.id, selectedPeople, user.userId)
      .then(response => {
        alertService.show(`${selectedPeople.length} ${selectedPeople.length > 1 ? 'People' : 'Person'} removed`);
        dismissModal();
        setSelectedPeople([]);

        const removedPeopleIds = response.map(person => person.userId.id);

        setData(prev => ({
          ...prev,
          people: prev?.people?.filter(person => !removedPeopleIds.includes(person._id.id)),
        }));
      })
      .catch(() =>
        setData(prev => ({
          ...prev,
          isError: true,
          isLoading: false,
        }))
      );
  };

  const selectedPeopleCallback = peopleToRemove => {
    setSelectedPeople(peopleToRemove);
  };

  const [modal, openModal, dismissModal] = useModal((type, _, dismissModal) => {
    if (!type) return;

    return (
      <RemovePeopleFromOrgModal
        handleSubmit={handleRemovePeople}
        selectedPeople={selectedPeople}
        handleDismiss={dismissModal}
        orgName={org.accountName}
      />
    );
  });

  return isLoading ? (
    <LoadingState />
  ) : isError ? (
    <ErrorMessage />
  ) : (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <PageContainer>
        <AddPeopleTo
          user={user}
          submitButtonText="Remove"
          handleSubmitFunction={() => openModal('remove-org')}
          selectedPeopleCallback={selectedPeopleCallback}
          alreadyAddedPeople={people}
          filters={{
            roles: { displayName: 'Roles', items: roles },
            groups: { displayName: 'Groups', items: groups },
          }}
          ButtonComponent={DangerButtonWithRoundedLabel}
        />
      </PageContainer>
      {modal}
    </>
  );
};

export default RemovePeopleFromOrg;
