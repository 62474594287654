import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MediumPrimaryButton, MediumButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';
import { Label, AutoFocusInput } from '../components/FormElements';
import MaxChars from '../components/MaxChars';
import ErrorMessage from '../components/ErrorMessage';
import trainingService from '../services/trainingService';
import windowService from '../services/windowService';
import { handleError } from '../utils/apiUtils';

const CloneTrainingModal = ({ training, user, handleDismiss }) => {
  const [name, setName] = useState(training.owner !== user.lastSelectedAccount ? training.name : '');
  const [UIState, setUIState] = useState({ isSubmitting: false, isError: '' });

  const redirectToTraining = id => {
    switch (training.type) {
      case 'Course':
        return windowService.redirectTo(`#/manage-training/build/courses/${id}`);
      case 'Pathway':
        return windowService.redirectTo(`#/manage-training/build/pathways/${id}`);
      default:
        return handleError('Unable to redirect. Invalid training type.');
    }
  };

  const cloneTraining = newName => {
    setUIState({ isSubmitting: true, isError: '' });
    trainingService
      .cloneTraining(training.type, training.id, newName, user.lastSelectedAccount, user.userId)
      .then(res => redirectToTraining(res.id))
      .catch(reason => {
        console.error(reason);
        reason &&
        (reason.eventType === 'TaskListNameDuplicateError' || reason.eventType === 'WorkflowNameDuplicateError')
          ? setUIState({ isSubmitting: false, isError: 'Sorry, that name is already taken' })
          : setUIState({ isSubmitting: false, isError: 'Something went wrong. Please try again.' });
      });
  };

  const handleClearInput = () => {
    setName('');
    if (UIState.isError) setUIState({ isSubmitting: false, isError: '' });
  };

  const handleOnChange = e => {
    if (UIState.isError) setUIState({ isSubmitting: false, isError: '' });
    setName(e.target.value);
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader data-qa-hook="copyTrainingCopyView">
        <h3>Customize Training</h3>
      </ModalHeader>
      <ModalBody>
        <h4>
          Make your own version of <strong>{training.name}</strong> exclusively for use within your organization.
        </h4>
        <div className="space-between">
          <Label htmlFor="name">Name</Label>
          <MaxChars maxChars={64} currentChars={name.length} />
        </div>
        <AutoFocusInput
          autoFocus={true}
          id="name"
          aria-label="name"
          placeholder={'Enter Name'}
          value={name}
          maxLength={64}
          onChangeHandler={handleOnChange}
          clearInput={handleClearInput}
        />
        {UIState.isError && <ErrorMessage>{UIState.isError}</ErrorMessage>}
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton
          data-qa-hook="copyTrainingCopyTraining"
          onClick={() => cloneTraining(name)}
          disabled={UIState.isSubmitting || !name.length}
          operating={UIState.isSubmitting}
        >
          Customize
        </MediumPrimaryButton>
        <MediumButton data-qa-hook="cloneTrainingModalCancelButton" onClick={() => handleDismiss()}>
          Cancel
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

CloneTrainingModal.propTypes = {
  training: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default CloneTrainingModal;
