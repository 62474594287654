import styled from 'styled-components';
import { Breakpoints, Color, Grid } from '../../StyleGuide';
import { TileButton } from '../../components/Buttons';

export const HeaderRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: ${Grid._4};
  margin-bottom: ${Grid._5};

  ${TileButton} {
    margin: 0;
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Grid._4};
  align-items: flex-end;
  margin-bottom: ${Grid._5};

  > * {
    width: 100%;
  }

  select {
    min-width: 180px;
  }

  button {
    height: 46px;
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;

    > * {
      flex: 0;

      &:last-child {
        flex: 1;
      }
    }

    button {
      order: 3;
    }
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Grid._3};

  span.invite--deactivated {
    color: ${Color.Red._70};
  }
`;
