import cacheService from '../../services/cacheService';
import submitCommand from '../../services/submitCommand';

export type RevokeAccessResponse = {
  eventType: string;
  id: string;
  initiatingUserId: string;
  results: { status: { status: 'REMOVED' | string; failureReason: string[] }; userId: string }[];
};

export const revokeAccess: (
  licenseId: string,
  userId: string,
  orgId: string,
  initiatingUserId: string
) => Promise<RevokeAccessResponse> = async (licenseId, userId, orgId, initiatingUserId) => {
  cacheService.removeAllInPath(`/v1/licenses`);

  const cmd = { id: licenseId, userIds: [{ id: userId }], managerId: orgId, initiatingUserId: initiatingUserId };

  return submitCommand(licenseId, cmd, 'DeallocateSeats', 'LicenseResult', 'LicenseError');
};
