import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useSearchQuery = queryString => {
  const { search } = useLocation();
  let history = useHistory();

  const queryParams = new URLSearchParams(search);
  const [searchQuery, setSearchQuery] = useState(queryParams?.get(queryString) || '');

  useEffect(() => {
    const params = new URLSearchParams();
    if (searchQuery) {
      params.append(queryString, searchQuery);
    } else {
      params.delete(queryString);
    }
    history.replace({ search: params.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, history]);

  return { searchQuery, setSearchQuery };
};

export default useSearchQuery;
