import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Grid, Color, Type } from '../StyleGuide';

const AnswersList = styled.ul`
  margin: ${Grid._6} auto;
  padding: 0 0 0 ${Grid._8};

  li {
    list-style: none;
    display: flex;
    border-top: 1px solid ${Color.Gray._20};
    border-right: 1px solid ${Color.Gray._20};
    margin: 0;
    color: ${Type.Color.medium};
    font-size: ${Type.Scale._3};
    &:last-child {
      border-bottom: 1px solid ${Color.Gray._20};
    }
  }
`;

const AnswerIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  min-width: 60px;

  background: ${props =>
    props.hasCorrect ? (props.isCorrect ? Color.Primary._50 : Color.Red._50) : Color.Secondary._80};

  i {
    font-size: 26px;
  }
`;

const AnswerText = styled.div`
  padding: 16px;

  p {
    margin: 0;

    &:first-child {
      font-size: ${Type.Scale._3};
    }
    &:last-child {
      font-style: italic;
    }
  }
`;

const AssessmentMultipleChoiceResults = ({ answers, hasCorrect }) => {
  const [totalResponses, setTotalResponses] = useState(0);

  useEffect(() => {
    setTotalResponses(answers.reduce((acc, answer) => acc + Number(answer.numChosen), 0));
  }, [answers]);

  return (
    <AnswersList>
      {totalResponses &&
        answers
          .sort((a, b) => a.index - b.index)
          .map(a => (
            <li key={a.value}>
              <AnswerIndicator hasCorrect={hasCorrect} isCorrect={a.isCorrect}>
                {hasCorrect ? (
                  <i className={a.isCorrect ? 'ion-ios-checkmark' : 'ion-ios-close'} />
                ) : (
                  <i className={a.numChosen > 0 ? 'ion-ios-circle-filled' : 'ion-ios-circle-outline'} />
                )}
              </AnswerIndicator>
              <AnswerText>
                <p>{a.value}</p>
                <p>{Math.round((a.numChosen / totalResponses) * 100) + '%'}</p>
              </AnswerText>
            </li>
          ))}
    </AnswersList>
  );
};

AssessmentMultipleChoiceResults.propTypes = {
  answers: PropTypes.array.isRequired,
  hasCorrect: PropTypes.bool.isRequired,
};

export default AssessmentMultipleChoiceResults;
