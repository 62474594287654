import React from 'react';
import limitFilter from '../filters/limit-filter';
import ProfileImage from '../groups/ProfileImage';
import { OutlineButton } from './Buttons';
import { Select } from './FormElements';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Border, Breakpoints, Color, Grid, Type } from '../StyleGuide';

const Table = styled.table`
  width: 100%;
  transition: opacity 0.25s ease-out;
  opacity: 1;
  margin-top: ${Grid._5};
  @media screen and (min-width: ${Breakpoints.screen_md}) {
    margin-top: 0;
  }
  &.isUpdating {
    opacity: 0.8;
  }
  tbody {
    display: grid;
    grid-gap: ${Grid._4};
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      grid-gap: 0;
      grid-template-columns: 1fr;
    }
  }
  tr {
    order: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid ${Color.Blue._15};
    border-radius: ${Border.radius};
    color: ${Type.Color.medium};
    align-items: center;
    padding: ${Grid._4};
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      display: grid;
      grid-template-columns: 48px 1fr 1fr 168px;
      grid-column-gap: ${Grid._5};
      border-radius: 0;
      padding: 0;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
    }
  }
  td {
    width: 100%;
    text-align: center;
    padding: ${Grid._2};
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      padding: ${Grid._4} 0;
      text-align: left;
      width: auto;
    }
  }
  .group-person-row__profile-img {
    padding-bottom: ${Grid._1};
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      padding-left: ${Grid._3};
    }
    span,
    img {
      font-size: ${Grid._8};
      position: relative;
      width: ${Grid._8};
      height: ${Grid._8};
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        font-size: ${Grid._6};
        width: ${Grid._6};
        height: ${Grid._6};
      }
    }
  }
  .group-person-row__name {
    color: ${Type.Color.dark};
    font-weight: ${Type.Weight.semibold};
    padding: ${Grid._2} 0;
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      text-align: left;
      padding: 0;
    }
  }
  .group-person-row__email {
    padding: ${Grid._2} 0;
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      padding: 0;
    }
  }
  .group-person-row._isPending {
    background: ${Color.Gray._075};
    .group-person-row__role {
      color: ${Type.Color.medium};
    }
  }
  .fa-user-circle {
    color: ${Color.Blue._15};
  }
  button {
    width: 100%;
  }
  .group-person-row._isUser {
    .group-person-row__name span {
      color: ${Type.Color.medium};
      margin-left: ${Grid._3};
      background: rgba(0, 0, 0, 0.025);
      padding: ${Grid._1} ${Grid._3};
      border-radius: ${Grid._7};
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: ${Type.Scale._05};
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  .group-person-row._isUser {
    order: 0 !important;
  }
  ._isPending .group-person-row__name span {
    color: ${Type.Color.medium};
    font-weight: ${Type.Weight.normal};
    opacity: 0.75;
    display: block;
  }
`;

const PeopleTable = ({ people, user, isUpdating = false, handleQuickAction, onOpenModal, resendEmail }) => {
  const redirectToProfile = person => {
    const urlPath = person.userId ? person.userId.id : person.emailAddress || person._id.id;
    window.location = '#/person/' + urlPath;
  };

  return (
    <Table className={'group-people-list ' + (isUpdating ? 'isUpdating ' : '')}>
      <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th className="text-center">Email Address</th>
          {handleQuickAction ? <th className="text-center">Role</th> : <th></th>}
          <th></th>
        </tr>
      </thead>
      <tbody data-qa-hook="groupDetailResultList">
        {people?.map((person, index) => {
          const isUser = person.email === user?.logInEmail || person.emailAddress === user?.logInEmail;
          const isPending = person._type === 'PendingUser' || !!person.inviteId;
          const isLeader = person.position === 'Leader';
          const isYou = person._id && person._id.id === user.userId;
          const rowClassName =
            'group-person-row ' +
            (isUser ? '_isUser ' : ' ') +
            (isPending ? '_isPending ' : ' ') +
            (isLeader ? '_isLeader ' : ' ');
          const imageUrl = person.profile
            ? person.profile.imageUrl
            : person.profileImage || person.profileImageUrl || person.imageUrl;

          return (
            <tr data-qa-hook="groupDetailResult" key={index} className={rowClassName}>
              <td className="group-person-row__profile-img" onClick={() => redirectToProfile(person)}>
                <ProfileImage src={imageUrl} />
              </td>
              <td
                data-qa-hook="groupDetailResultName"
                className="group-person-row__name"
                onClick={() => redirectToProfile(person)}
              >
                {person?.displayName}
                {isUser && <span data-qa-hook="youLabel">you</span>}
                {isPending && <span data-qa-hook="addPeoplePending">Not Logged in Yet</span>}
              </td>
              <td
                data-qa-hook="groupDetailResultEmail"
                className="group-person-row__email"
                onClick={() => redirectToProfile(person)}
              >
                {limitFilter(person.email || person.emailAddress)}
              </td>
              {handleQuickAction ? (
                <td
                  data-qa-hook="groupDetailResultRole"
                  className="group-person-row__role"
                  onClick={() => redirectToProfile(person)}
                >
                  <span>{person.position}</span>
                </td>
              ) : (
                <td>
                  {resendEmail && isPending && (
                    <OutlineButton
                      data-qa-hook="resendEmailInvite"
                      onClick={() => {
                        resendEmail(person.inviteId.id, person.emailAddress, user.userId);
                      }}
                    >
                      Resend Invite
                    </OutlineButton>
                  )}
                </td>
              )}

              <td className="group-people-list_quick-actions">
                {handleQuickAction ? (
                  <Select
                    data-qa-hook="quickActionsDropdown"
                    name="quickActions"
                    id="quickActions"
                    value={''}
                    onChange={e => handleQuickAction(e.target.value, person)}
                  >
                    <option value="" defaultValue>
                      Quick Actions
                    </option>
                    {isLeader && <option value="Member">Make Regular Member</option>}
                    {!isLeader && <option value="Leader">Make Leader</option>}
                    {!isUser && <option value="emailPerson">Email Person</option>}
                    <option value="confirmRemovePerson">Remove Person</option>
                  </Select>
                ) : (
                  !isYou && (
                    <OutlineButton data-qa-hook="groupDetailResultRemove" onClick={() => onOpenModal(person)}>
                      Remove
                    </OutlineButton>
                  )
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

PeopleTable.propTypes = {
  people: PropTypes.array,
  user: PropTypes.object,
  isUpdating: PropTypes.bool,
  personOnClick: PropTypes.func,
  handleQuickAction: PropTypes.func,
  onOpenModal: PropTypes.func,
  resendEmail: PropTypes.func,
};

export default PeopleTable;
