import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BrightcoveReactPlayerLoader from '@brightcove/react-player-loader';
import { isGrid } from '../filters/WhiteLabel';

const BrightcoveContainer = styled.section.attrs({
  className: `brightcove-container${isGrid() ? '' : '--white-labeled'}`,
})`
  position: relative;

  .vjs-menu-item-text {
    text-transform: capitalize;
  }

  .vjs-errors-dialog,
  .vjs-error-display,
  .vjs-error-display:before {
    display: none;
  }

  .vjs-current-time {
    height: 30px;
  }

  .vjs-big-play-button {
    transform: none;
  }
`;

const BrightcoveVideoPlayer = ({ poster, videoId, accountId, audioOnly, autoPlay, setup }) => {
  const hideTextTracks = tracks => {
    for (let i = 0; i < tracks?.length; i++) {
      tracks[i].mode = 'hidden';
    }
  };

  const hidePlayerTextTracks = player => {
    hideTextTracks(player?.textTracks?.());
    hideTextTracks(player?.remoteTextTracks?.());
  };

  const onSuccess = player => {
    /*
      disabled automatic fullscreen on play
      iOS devices play fullscreen by default
    */
    player.ref.playsinline(true);

    player.ref.on('loadedmetadata', () => {
      /*
        hide closed captions by default
      */
      hidePlayerTextTracks(player.ref);

      if (audioOnly) {
        player.ref.audioOnlyMode(true);
      }
      /*
        autoplay option if available
      */
      if (autoPlay) {
        player.ref.play();
      }
    });

    setup();
  };

  const html5 = {
    vhs: {
      overrideNative: true,
    },
    nativeAudioTracks: false,
    nativeTextTracks: false,
  };

  const getBrightCovePlayerId = () => {
    switch (window.location.hostname) {
      case 'grid.local.lifeway.com':
      case 'ministrygrid.dev.lifeway.com':
      case 'ministrygrid.stage.lifeway.com':
        return 'Fd9l8GS55';
      default:
        return '9uXttJEmo';
    }
  };

  const playerId = getBrightCovePlayerId();

  return (
    <BrightcoveContainer>
      <BrightcoveReactPlayerLoader
        accountId={accountId}
        playerId={playerId}
        videoId={videoId}
        onSuccess={onSuccess}
        manualReloadFromPropChanges
        options={{
          fluid: true,
          muted: false,
          responsive: true,
          autoPlay,
          playbackRates: [0.75, 1, 1.25, 1.5, 1.75, 2],
          controlBar: {
            volumePanel: {
              inline: false,
            },
          },
          id: `video-${videoId}`,
          html5,
        }}
        poster={poster}
      />
    </BrightcoveContainer>
  );
};

BrightcoveVideoPlayer.propTypes = {
  poster: PropTypes.string,
  videoId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  audioOnly: PropTypes.bool,
  autoPlay: PropTypes.bool,
  setup: PropTypes.func.isRequired,
};

export default BrightcoveVideoPlayer;
