angular.module('lwNamb').controller(
  'logoutCtrl',

  [
    '$rootScope',
    '$scope',
    'windowService',
    '$localStorage',
    'userService',
    function($rootScope, $scope, windowService, $localStorage, userService) {
      var init = function() {
        
        if ($localStorage.redirectToLifeWay) {
          var _ = $localStorage.redirectToLifeWay;
          $localStorage.redirectToLifeWay = undefined;
          
          if (_.cmd === 'login') {
            userService.redirectToLoginV2(_.redirectURL, _.email);
          } else {
            userService.redirectToRegisterV2(_.redirectURL, _.email);
          }

        } else {
          windowService.redirectHash('/login');
        }
      };

      init();
    },
  ]
);
