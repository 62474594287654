import React from 'react';
import PropTypes from 'prop-types';
import { MediumButton } from './Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from './ModalElements';

const InfoModal = ({ title, prompt, subtext, buttonText = 'Close', handleDismiss, children }) => {
  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3 data-qa-hook="modalHeader">{title}</h3>
      </ModalHeader>
      <ModalBody data-qa-hook="modalMessage">
        <h4>{prompt}</h4>
        <p className="modal-component-subtext">{subtext}</p>
        {children}
      </ModalBody>
      <ModalFooter>
        <MediumButton data-qa-hook="modalClose" onClick={handleDismiss}>
          {buttonText}
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

InfoModal.propTypes = {
  title: PropTypes.string.isRequired,
  prompt: PropTypes.object,
  subtext: PropTypes.string,
  buttonText: PropTypes.string,
  handleDismiss: PropTypes.func.isRequired,
};

export default InfoModal;
