angular.module('lwNamb').directive(
  'coachPaneTaskLists',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/coach-pane-task-lists.html',
        scope: {
          userId: '@',
          paneId: '@',
          orgId: '@',
        },
        replace: true,
        controller: [
          '$scope',
          '$log',
          'State',
          'taskListInstanceService',
          'apiUrlService',
          'windowService',
          function($scope, $log, State, taskListInstanceService, apiUrlService, windowService) {
            $scope.isLoading = true;

            $scope.reportVisibility = 'PRIVATE';

            taskListInstanceService.getAssignedAndCompleted($scope.userId, $scope.orgId).then(
              function(taskListInstances) {
                taskListInstances.forEach(function(list) {
                  list.activePanel = 0;
                });
                $scope.taskLists = taskListInstances;
                $scope.isLoading = false;
              },
              function(reason) {
                $log.error(reason);
                $scope.isLoading = false;
                $scope.isError = true;
              }
            );

            var state = State($scope.paneId);
            $scope.selected = state.get();

            $scope.close = function() {
              state.clear();
            };

            $scope.downloadFile = function(tasklist, report) {
              var lookupFileName = encodeURIComponent(tasklist.name.replace(/ /gi, '_'));
              var lookupFilePath = encodeURIComponent(report.reportId);
              windowService.openUrl(
                apiUrlService.getUrl() + '/v1/files?fileName=' + lookupFileName + '&filePath=' + lookupFilePath
              );
            };

            $scope.redirectToViewer = function(taskListId) {
              state.put('menu', 'task-list');
              state.put('taskListId', taskListId);
            };
          },
        ],
      };
    },
  ]
);
