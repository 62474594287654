angular.module('lwNamb').factory(
  'customerService',

  [
    'api',
    function(api) {
      return {
        findUserByEmail: function(email) {
          return api.get('/users/id?email=' + email).then(
            function success(response) {
              return response.data;
            },
            function error(reason) {
              return reason;
            }
          );
        },
      };
    },
  ]
);
