import React, { useState, useEffect } from 'react';
import EasyLinkModal from '../../../components/EasyLinkModal';
import { handleError } from '../../../utils/apiUtils';
import orgService from '../../../services/orgService';

type Props = {
  trainingId: string;
  trainingName: string;
  orgId: string;
  handleDismiss: () => void;
};

export const TrainingEasyLinkModal = ({ trainingId, trainingName, orgId, handleDismiss }: Props) => {
  const [orgPrefix, setOrgPrefix] = useState<string>('');

  useEffect(() => {
    orgService
      .getEasyLink(orgId)
      .then(response => {
        if (!response.prefix) {
          throw new Error('No easy link org prefix found');
        }

        setOrgPrefix(response.prefix);
      })
      .catch(error => {
        handleError(error);
        handleDismiss();
      });
  }, []);

  return (
    <EasyLinkModal
      isLoading={!orgPrefix}
      link={{ orgPrefix, groupCode: trainingId }}
      description={`
        <h4>Anyone who uses this link will automatically be assigned <b>${trainingName}</b> and added to your organization.</h4>
      `}
      handleDismiss={handleDismiss}
    />
  );
};
