angular.module('lwNamb').directive(
  'addPerson',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/add-person.html',
        scope: {
          callback: '=',
          person: '=',
          orgName: '=',
          errors: '=',
        },
        controller: [
          '$scope',
          function($scope) {
            $scope.hideFormOnMobile = true;
            $scope.isAdding = false;
            $scope.isDuplicate = false;
            $scope.emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            $scope.toggleForm = function() {
              $scope.hideFormOnMobile = false;
            };

            $scope.handleSubmit = function(isFormValid) {
              $scope.isAdding = true;
              $scope.showErrorMessage = false;
              $scope.isDuplicate = false;

              if (!isFormValid) {
                $scope.showErrorMessage = true;
                $scope.isAdding = false;
                return;
              } else {
                $scope.isDuplicate = $scope.callback();
                if (!$scope.isDuplicate) {
                  $scope.addPerson.$setPristine();
                  $scope.addPerson.$setUntouched();
                }
                $scope.isAdding = false;
              }
            };
          },
        ],
      };
    },
  ]
);
