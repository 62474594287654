import React, { useState, useEffect } from 'react';
import { groupService } from '../services/groupService';
import orgService from '../services/orgService';
import GroupContainer from './GroupContainer';
import LoadingState from '../components/LoadingState';
import EmptyState from './EmptyState';
import EasyLinkModal from '../components/EasyLinkModal';
import Breadcrumbs from '../components/Breadcrumbs';
import AddPeopleTo from '../components/AddPeopleTo';
import { OutlineButton } from '../components/Buttons';
import { useUser } from '../authentication';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const GroupAddPeople = () => {
  const user = useUser();
  const { groupId, orgId } = useParams();
  const userId = user.userId;

  const [data, setData] = useState({
    org: [],
    people: [],
    group: [],
    groups: [],
    isLoading: true,
    isSubmitting: false,
    isError: false,
  });

  const { people, group, isLoading, isSubmitting, isError } = data;

  const crumbs = [
    { name: 'Groups', route: '#/groups' },
    { name: group.groupName, route: `#/groups/${groupId}/${orgId}` },
    { name: 'Add People' },
  ];

  const [showEasyLink, setShowEasyLink] = useState(false);

  useEffect(() => {
    const getGroup = groupService.getSingleGroup(groupId, orgId);
    const getOrg = orgService.getOrgUsers(orgId);
    const getRoles = orgService.getRolesOverview(orgId, true);
    Promise.all([getGroup, getOrg, getRoles])
      .then(response =>
        setData({
          group: response[0],
          org: response[1],
          roles: response[2],
          people: response[1].users.filter(item => !item['groups'].includes(groupId)),
          groups: response[1].groups.filter(group => group.id !== groupId),
          isLoading: false,
        })
      )
      .catch(() => setData({ isError: true, isLoading: false }));
  }, [groupId, orgId]);

  const handleAddPeople = selectedPeople => {
    setData(prev => ({ ...prev, isSubmitting: true }));
    groupService
      .addMember(groupId, orgId, userId, selectedPeople, group.groupName)
      .then(() => (window.location = '#/groups/' + groupId + '/' + orgId))
      .catch(() => {
        setData({ isError: true, isLoading: false, isSubmitting: false });
      });
  };

  return isError ? (
    <EmptyState isError={'is-error'} />
  ) : (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <GroupContainer data-qa-hook="addPeopleView" rounded>
        <header className="space-between">
          <div>
            <h1>Add People to Group</h1>
            <p className="group-desc">
              Select people from your organization to add or use the Group Easy Link button to share a link people can
              use to join this group.
            </p>
          </div>
          <div>
            <OutlineButton
              data-qa-hook="EasyLinkModalButton"
              disabled={isLoading}
              onClick={() => setShowEasyLink(true)}
            >
              <span className="fas fa-share-square"></span> Group Easy Link
            </OutlineButton>
            {showEasyLink && (
              <EasyLinkModal
                link={group?.groupLink}
                description={`<h4>Anyone who uses this link will automatically be added to <b>${group?.groupName}</b>.</h4>`}
                handleDismiss={() => setShowEasyLink(false)}
              />
            )}
          </div>
        </header>
        <section>
          {data.isLoading ? (
            <LoadingState />
          ) : (
            <AddPeopleTo
              user={user}
              isSubmitting={isSubmitting}
              alreadyAddedPeople={people}
              handleAddPeople={handleAddPeople}
              filters={{
                roles: { displayName: 'Roles', items: data.roles },
                groups: { displayName: 'Groups', items: data.groups },
              }}
            />
          )}
        </section>
      </GroupContainer>
    </>
  );
};

export default GroupAddPeople;
