import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ThirdPartyVideoPlayer from './ThirdPartyVideoPlayer';
import VideoPlayer from '../containers/VideoPlayer';
import VideoCompleted from '../steps/VideoCompleted';
import { VideoPlaceholder, LoadingVideoPlaceholder } from '../components/Layout';
import { useCompleteStep, useSteps } from '../steps/StepsContext';

export default function VideoStepPlayer(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [showVideoPlayer, setShowVideoPlayer] = useState(true);
  const [fullyWatchedVideo, setFullyWatchedVideo] = useState(false);
  const { completeStep } = useCompleteStep();
  const [{ status: asyncStatus }] = useSteps();
  const autocompleteThreshold = 0.5;

  const onProgress = useCallback(
    ({ percentComplete }) => {
      if (
        props.currentStep.status !== 'complete' &&
        asyncStatus !== 'COMPLETING' &&
        percentComplete > autocompleteThreshold
      )
        completeStep();
    },
    [completeStep, props.currentStep.status, asyncStatus]
  );

  const onEnd = () => {
    setShowVideoPlayer(false);
    setFullyWatchedVideo(true);
  };

  return (
    <>
      {showVideoPlayer &&
        (props.currentStep.task._type === 'ThirdPartyVideo' ? (
          <VideoPlaceholder thirdParty borderRadius="0">
            <ThirdPartyVideoPlayer
              onProgress={onProgress}
              onEnd={onEnd}
              currentStep={props.currentStep}
              url={props.currentStep.task.videoUrl}
            />
          </VideoPlaceholder>
        ) : (
          <VideoPlaceholder borderRadius="0">
            {isLoading && <LoadingVideoPlaceholder />}
            <VideoPlayer
              onProgress={onProgress}
              setIsLoading={setIsLoading}
              onEnd={onEnd}
              videoId={props.currentStep.task.videoId}
              userId={props.userId}
            />
          </VideoPlaceholder>
        ))}
      {fullyWatchedVideo && (
        <VideoCompleted
          setShowVideoPlayer={setShowVideoPlayer}
          currentStepIsIncomplete={props.currentStepIsIncomplete}
          showVideoPlayer={showVideoPlayer}
        />
      )}
    </>
  );
}

VideoStepPlayer.propTypes = {
  currentStep: PropTypes.object.isRequired,
  userId: PropTypes.string,
  currentStepIsIncomplete: PropTypes.bool,
};
