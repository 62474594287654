angular.module('lwNamb').filter('capitalize', function() {
  return function(text) {
    //Type checking to avoid non-strings
    if (typeof text !== 'string') return text;

    return text.replace(/(^|\s)\S/g, function(t) {
      return t.toUpperCase();
    });
  };
});
