import React from 'react';
import { PrimaryButton, SmallRoundedIconButton, ButtonGroup } from '../components/Buttons';
import { SixteenSevenAspectRatioPlaceholder, SixteenNineAspectRatioPlaceholder } from '../components/Layout';
import windowService from '../services/windowService';
import { OrderingDragHandle } from './OrderingElements';

export const EditCatalogButtons = props => (
  <ButtonGroup>
    {props.edit && (
      <SmallRoundedIconButton
        data-qa-hook="catalogItemEdit"
        title="Edit Item"
        className="transparent"
        onClick={() => props.edit()}
      >
        <i className="far fa-edit"></i>
      </SmallRoundedIconButton>
    )}
    {props.reorder && (
      <OrderingDragHandle
        data-qa-hook="catalogItemReorder"
        title="Reorder Items"
        className="transparent"
        onClick={() => props.reorder()}
      />
    )}
    {props.remove && (
      <SmallRoundedIconButton
        data-qa-hook="catalogItemRemove"
        title="Remove Item"
        className="transparent"
        onClick={() => props.remove()}
      >
        <i className="far fa-trash-alt"></i>
      </SmallRoundedIconButton>
    )}
  </ButtonGroup>
);

export const AddCarouselItemPlaceholder = ({ index }) => (
  <SixteenSevenAspectRatioPlaceholder actionable>
    <PrimaryButton
      data-qa-hook="addCarouselItem"
      data-qa-hook2={`${index}`}
      style={{ maxWidth: '195px' }}
      onClick={() => windowService.redirectTo(`#/manage-catalog/carousel-item/${index}`)}
    >
      <i className="fas fa-plus"></i> Add Carousel Item
    </PrimaryButton>
  </SixteenSevenAspectRatioPlaceholder>
);

export const AddTrainingCompilationPlaceholder = ({ index }) => (
  <>
    <div className="hidden-xs hidden-sm">
      <SixteenNineAspectRatioPlaceholder actionable />
    </div>
    <SixteenNineAspectRatioPlaceholder actionable>
      <PrimaryButton
        data-qa-hook="addTrainingCompilation"
        style={{ maxWidth: '245px' }}
        onClick={() => windowService.redirectTo(`#/manage-catalog/add-compilation/${index}`)}
      >
        <i className="fas fa-plus" />
        Add Training Compilation
      </PrimaryButton>
    </SixteenNineAspectRatioPlaceholder>
    <div className="hidden-xs ">
      <SixteenNineAspectRatioPlaceholder actionable />
    </div>
  </>
);

export const getButtonLinkType = link => {
  const key = Object.keys(link)[0];
  return key === 'query' ? 'search' : 'training';
};

export const redirectToTrainingLinkUrl = link => {
  const trainingItem = Object.entries(link)[0];
  const [trainingType, trainingId] = trainingItem;
  return windowService.redirectTo(
    `#/catalog/${trainingType === 'compilationId' ? 'compilation' : 'training'}/${trainingId}`
  );
};

function encodeQueryData(data) {
  const ret = [];
  for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

export const redirectToSearchLinkUrl = link => {
  const facetsParameters = encodeQueryData(link.facets);
  return windowService.redirectTo(
    `#/search/${link.query ? link.query : ''}${facetsParameters.length ? '?' + facetsParameters : ''}`
  );
};
