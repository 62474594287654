angular.module('lwNamb').directive(
  'thirdPartyVideoPlayer',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/third-party-video-player.html',
        scope: {
          url: '@',
          start: '&',
          complete: '&',
        },
        replace: true,
        controller: [
          '$scope',
          '$log',
          'thirdPartyVideoService',
          function($scope, $log, thirdPartyVideoService) {
            function onYTPlayerStateChange(event) {
              if (event.data === 1) {
                $scope.start();
              } else if (event.data === 0) {
                $scope.complete();
              }
            }

            var data = thirdPartyVideoService.getThirdPartyData($scope.url);

            if (data.type === 'youtube') {
              new YT.Player('player', {
                videoId: data.id,
                events: {
                  onStateChange: onYTPlayerStateChange,
                },
                playerVars: {
                  start: data.time,
                },
              });
            } else {
              var player = new Vimeo.Player('player', {
                id: data.url,
              });

              player.ready().catch(function(reason) {
                $scope.isVimeoError = true;
                $log.error(reason);
              });
              player.on('play', $scope.start);
              player.on('ended', $scope.complete);
            }
          },
        ],
      };
    },
  ]
);
