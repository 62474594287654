angular.module('lwNamb').controller(
  'AssessViewerCtrl',

  [
    '$scope',
    '$rootScope',
    '$log',
    'assessBuilderService',
    'assessTakerService',
    'taskService',
    'taskListInstanceService',
    'windowService',
    '$routeParams',
    'uuid4',
    'blockUX',
    'alertService',
    'userService',
    'taskListService',
    function(
      $scope,
      $rootScope,
      $log,
      assessBuilderService,
      assessTakerService,
      taskService,
      taskListInstanceService,
      windowService,
      $routeParams,
      uuid4,
      blockUX,
      alertService,
      userService,
      taskListService
    ) {
      $scope.taskListInstanceId = $routeParams.taskListInstanceId;
      $scope.taskInstanceId = $routeParams.taskInstanceId;

      var init = function() {
        $scope.spinner = false;
        $scope.loading = true;
        $scope.taskLoading = true;
        //Set Task List id

        $scope.fromTaker = $routeParams.fromTaker ? $routeParams.fromTaker : false;

        userService.user().then(
          function(user) {
            $scope.user = user;
            retrieveUserData();
          },
          function() {
            $log.error('No user returned in AssessViewerCtrl');
          }
        );

      };

      var getAssessment = function (assessmentId) {
        assessBuilderService.getAssessment(assessmentId, $scope.user.userId).then(
          function(response) {
            $scope.assessment = response;
          },
          function(reason) {
            $log.error(reason);
          }
        );
      };

      var retrieveUserData = function() {
        taskListInstanceService
          .getByInstanceId($scope.taskListInstanceId)
          .then(
            function(taskList) {
            $scope.taskList = taskList;
              for (var i = 0; i < taskList.tasks.length; i++) {
                if (taskList.tasks[i].id.id === $scope.taskInstanceId) {
                  $scope.task = taskList.tasks[i];
                  $scope.assessmentId = $scope.task.task.assessmentId;
                  getAssessment($scope.assessmentId);
                  break;
                }
              }
            },
            function(reason) {
              $log.error(reason);
            }
          )
          .finally(function() {
            $scope.loading = false;
            $scope.taskLoading = false;
            setUpAssessmentState($scope.assessmentId);
          });
      };

      function setUpAssessmentState(assessmentId) {
        $scope.publicTaskStarted = $scope.task.status;
        if ($scope.task.task.responseId === '') {
          $scope.assessResponseId = uuid4.generateId().id;
          $scope.task.task.responseId = $scope.assessResponseId;
        } else {
          $scope.assessResponseId = $scope.task.task.responseId;
        }

        if ($scope.assessResponseId === undefined) {
          acceptFailure();
        }
        //Get Assessment Response id if Task List status is in_progress or complete
        assessTakerService.getAssessmentResponse($scope.user.userId, assessmentId, $scope.assessResponseId).then(
          function(response) {
            $scope.assessmentResponse = response;
          },
          function(reason) {
            $log.debug(reason);
          }
        );
      }

      var redirectToTaker = function() {
        var assessmentId = $scope.task.task.assessmentId, assessResponseId = $scope.task.task.responseId;
        assessTakerService.getAssessmentResponse($scope.user.userId, assessmentId, assessResponseId).then(
          function(response) {
            if (Object.keys(response).length !== 0 && response._id.id === assessResponseId) {
              var taskInstanceId = $scope.task.id.id;
              taskListInstanceService.getTaskById($scope.taskListInstanceId, taskInstanceId).then(
                function(task) {
                  if (task.task.responseId === $scope.task.task.responseId) {
                    blockUX.stop();
                    windowService.redirectHash('/assessment-taker/' + $scope.taskListInstanceId + '/' + $scope.taskInstanceId + '/' + $scope.assessmentId);
                  } else {
                    $scope.startAssessment();
                  }
                },
                function(reason) {
                  $log.debug(reason);
                }
              );
            } else {
              $scope.startAssessment();
            }
          },
          function(reason) {
            $log.debug(reason);
          }
        );
      };

      //Start Assessment
      $scope.startAssessment = function() {
        blockUX.start('Setting up your Assessment...', 0, 30000, false, 'Error! Please try again.', null);
        $scope.spinner = true;
        taskService.startTask($scope.taskListInstanceId, $scope.task, $scope.user.userId, $scope.user.userId).then(
          function() {
            redirectToTaker();
          },
          function(reason) {
            $log.error(reason);
            blockUX.stop();
            $scope.spinner = false;
            alertService.show({
              title: 'Unexpected Error!',
              content: 'Please try again.',
              type: 'danger danger-with-content',
              duration: 20,
            });
          }
        );
      };

      $scope.redirectToTaker = function() {
        windowService.redirectHash('task-viewer/' + $scope.taskListInstanceId);
      };

      $scope.resumeAssessment = function() {
        $scope.spinner = true;
        redirectToTaker();
      };

      $scope.redirectToTaskViewer = function() {
        $scope.redirectToTaker();
      };

      var acceptFailure = function() {
        alertService.show({
          title: 'Something is wrong with your invitation',
          content: 'Please contact the person who sent it to you.',
          type: 'danger danger-with-content',
          duration: 20,
        });
      };

      $scope.completeDeletedAssessment = function() {
        $scope.spinner = true;
        taskListService
          .completeTask($routeParams.taskListInstanceId, $scope.user.userId, $scope.task, true)
          .then(
            function() {
              $scope.redirectToTaskViewer();
            },
            function(reason) {
              $log.error(reason);
              alertService.show({
                title: 'Unexpected Error!',
                content: 'Please try again.',
                type: 'danger danger-with-content',
                duration: 20,
              });
            }
          )
          .finally(function() {
            $scope.spinner = false;
          });
      };

      init();
    },
  ] //End Assess Ctrl
);
