import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';

const ProgressBarContainer = styled.div`
  display: flex;
  div {
    flex: 1;
    margin-right: ${Grid._4};
  }
  div:last-of-type {
    margin-right: 0;
  }
`;

export default ProgressBarContainer;
