import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { react2angular } from 'react2angular';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Breakpoints, Grid, Color, Type, Border } from '../StyleGuide';
import curriculumService from '../services/curriculumService';
import windowService from '../services/windowService';
import { BulletedList } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { SizeTiers, PlanTiers, SinglePlanTier } from './BibleStudyTiers';
import PlanContents from './PlanContents';
import { PrimaryButton } from '../components/Buttons';
import { Steps } from '../components/FormElements';
import TermsModal from './TermsModal';
import BibleStudyHeaderImg from './BibleStudyHeaderImg';
import BibleStudyHeader from './BibleStudyHeader';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import { UserContext } from '../authentication';
import useCurriculum from '../hooks/useCurriculum';
import { OptionSwitch } from '../components/Switches';
import { formatCurrency } from '../utils/textUtils';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${Grid._4} auto ${Grid._5};
  h1 {
    font-size: ${Type.Scale._4};
    font-weight: ${Type.Weight.semibold};
    color: ${Type.Color.dark};
    margin: 0;
  }
`;

const BundleInfoBoxContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.screen_md}) {
    > div {
      margin: 0;
    }
  }
`;

const Text = styled.p`
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.normal};
  color: ${Type.Color.dark};
`;

const PriceTotal = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${Type.Scale._4};

  span:last-child {
    font-weight: ${Type.Weight.semibold};
  }
`;

const TaxStatement = styled.p`
  margin: 0;
  font-size: ${Type.Scale._1};
  font-weight: ${Type.Weight.light};
  text-align: right;
`;

const Terms = styled.a`
  display: inline-block;
  width: 100%;
  text-align: center;
`;

const PeriodSwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${Grid._4};

  label {
    display: flex;
    margin-bottom: 0;
    padding: ${Grid._4} ${Grid._7};
    background: ${Color.Gray._10};
    border: 1px solid ${Color.Gray._30};
    border-radius: ${Border.radius};
  }
`;

const CheckoutContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${Grid._5};
  padding-top: ${Grid._4};
  border-top: 1px solid ${Color.Gray._30};

  > div {
    max-width: 420px;
  }
`;

const Checkout = ({ children }) => (
  <CheckoutContainer>
    <div>{children}</div>
  </CheckoutContainer>
);

Checkout.propTypes = {
  children: PropTypes.any,
};

const StepTwoContainer = styled.div`
  > div:last-child {
    padding: 32px 24px;
  }
`;

const StepTwo = ({ tiers, planContent, checkoutContent }) => {
  const containerGridClasses = tiers.length === 1 ? 'grid-sm-col-2 grid-lg-col-5' : 'grid-lg-col-3';
  const checkoutSpanClasses = tiers.length === 1 ? 'grid-lg-col-span-3' : 'grid-lg-col-span-1';

  return (
    <StepTwoContainer className={`grid-container grid-col-1 ${containerGridClasses}`}>
      <div className={`grid-lg-col-span-2`}>{planContent}</div>
      <div className={checkoutSpanClasses}>{checkoutContent}</div>
    </StepTwoContainer>
  );
};

StepTwo.propTypes = {
  tiers: PropTypes.array.isRequired,
  planContent: PropTypes.element.isRequired,
  checkoutContent: PropTypes.element.isRequired,
};

const calculateSavings = (monthlyPrice, yearlyPrice) => {
  const savings = Math.floor(monthlyPrice * 12 - yearlyPrice);
  return (savings > 0 ? savings : 0).toString();
};

export default function SubscribeBibleStudy() {
  const user = useContext(UserContext);
  const [data, setData] = useState({ curriculum: {}, isLoading: true });
  const [activeTab, setActiveTab] = useState('step-one');
  const [isAnnualAvailable, setIsAnnualAvailable] = useState(false);
  const [selectedSizeTier, setSelectedSizeTier] = useState('');
  const [selectedPackageTier, setSelectedPackageTier] = useState('plus');
  const [selectedPeriod, setSelectedPeriod] = useState('year');
  const [sizeTiers, setSizeTiers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [comboLinks, setComboLinks] = useState([]);

  const packageTiers = useMemo(() => {
    if (!data.curriculum.subscription) return [];

    const monthlyTiers = data.curriculum.subscription.filter(s => s.size === selectedSizeTier && s.period === 'month');
    if (selectedPeriod === 'month') return monthlyTiers;

    const yearlyTiers = data.curriculum.subscription.filter(s => s.size === selectedSizeTier && s.period === 'year');

    const yearlyTiersWithSavings = yearlyTiers.map(yt => ({
      ...yt,
      savings: calculateSavings(monthlyTiers.find(mt => mt.package === yt.package)?.price || 0, yt.price),
    }));

    return yearlyTiersWithSavings;
  }, [data.curriculum.subscription, selectedSizeTier, selectedPeriod]);

  const selectedPackage = packageTiers.find(p => p.package === selectedPackageTier);

  const { brand } = useCurriculum();
  const { curriculumId } = useParams();

  const showDateWarning = new Date() < new Date(2020, 11, 1); // 11 because JS months are 0-indexed

  useEffect(() => {
    curriculumService
      .getIndividualPricing(brand.code, curriculumId)
      .then(response => {
        setComboLinks(
          response.combos.length > 0 &&
            response.combos.map(combo => ({
              id: combo.id,
              text: `Explore the ${combo.name} bundle`,
              url: `#/subscribe-bible-study/${brand.code}/${combo.id}`,
              target: '_self',
            }))
        );
        setData({ curriculum: response, isLoading: false });
        const hasPlusOption = response.subscription.some(s => s.package === 'plus');
        if (!hasPlusOption) setSelectedPackageTier('basic');

        const hasYearlyOption = response.subscription.some(s => s.period === 'year');
        const period = hasYearlyOption ? 'year' : 'month';
        setSizeTiers(response.subscription.filter(s => s.package === 'basic' && s.period === period));
        setSelectedPeriod(period);
        setIsAnnualAvailable(hasYearlyOption);
        setData({ curriculum: response, isLoading: false });
      })
      .catch(() => setData({ isError: true, isLoading: false }));
  }, []);

  const loginBeforePurchase = itemNumber => {
    const redirectUrl = encodeURIComponent(`purchase?itemNumber=${itemNumber}&source=curriculum`);
    window.location.href = `/#/login?route=${redirectUrl}&checkout`;
  };

  const updateActiveTab = step => {
    setSelectedPeriod(isAnnualAvailable ? 'year' : 'month');
    setActiveTab(step);
  };

  const redirectToPurchase = selectedSubscription => {
    setData(prevData => ({ ...prevData, isRedirecting: true }));

    if (!user) return loginBeforePurchase(selectedSubscription.itemNumber);
    windowService.redirectToPurchase(selectedSubscription.itemNumber);
  };

  return (
    <div data-qa-hook="bibleStudyPurchaseView" className="container">
      <div className="row">
        <div className="col-md-12" style={{ margin: '30px auto' }}>
          <Header>
            <h1>Subscribe to Curriculum Age Group:</h1>
            <a data-qa-hook="exit" href="#/bible-studies">
              Exit
            </a>
          </Header>

          {data.isLoading ? (
            <LoadingState />
          ) : data.isError ? (
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          ) : (
            <>
              <div className="grid-container grid-md-col-2">
                <BibleStudyHeader
                  className="grid-sm-col-span-1"
                  thumbnail={<BibleStudyHeaderImg src={brand.imgSrc} background={brand.background} alt={brand.name} />}
                  subtitle={brand.name}
                  title={data.curriculum.name}
                />
                {comboLinks.length > 0 && (
                  <BundleInfoBoxContainer>
                    <HelpAndInfoBox
                      className="grid-sm-col-span-1"
                      title="Save More with Bundles"
                      description="Bundle together and get a discounted price."
                      link={comboLinks}
                      iconClass="fas fa-gift"
                    />
                  </BundleInfoBoxContainer>
                )}
              </div>
              <Steps className="grid-container grid-col-2 grid-gap-16">
                <li className={activeTab === 'step-one' ? 'active-tab' : ''}>
                  <button onClick={() => updateActiveTab('step-one')}>
                    <span className="step-number">Step 1</span>Select Your {data.curriculum.name} Ministry Size
                  </button>
                </li>
                <li className={activeTab === 'step-two' ? 'active-tab' : ''}>
                  <button onClick={() => updateActiveTab('step-two')} disabled={!selectedSizeTier}>
                    <span className="step-number">Step 2</span>Select Your Plan
                  </button>
                </li>
              </Steps>
              {activeTab === 'step-one' && (
                <section>
                  <Text>
                    How many average attendees (leaders + participants) will use {brand.name} for {data.curriculum.name}{' '}
                    each week?
                  </Text>
                  <SizeTiers tiers={sizeTiers} selectedTier={selectedSizeTier} setSelectedTier={setSelectedSizeTier} />
                  <PrimaryButton
                    data-qa-hook="nextButton"
                    className="pull-right"
                    style={{ textAlign: 'left', minWidth: '290px', margin: '24px auto', padding: '16px' }}
                    onClick={() => updateActiveTab('step-two')}
                    disabled={!selectedSizeTier}
                  >
                    Next <i className="fas fa-chevron-right pull-right"></i>
                  </PrimaryButton>
                </section>
              )}
              {activeTab === 'step-two' && (
                <StepTwo
                  tiers={packageTiers}
                  planContent={
                    <>
                      {packageTiers.length === 1 ? (
                        <SinglePlanTier curriculumName={data.curriculum.name} tier={packageTiers[0]} />
                      ) : (
                        <PlanTiers
                          curriculumName={data.curriculum.name}
                          tiers={packageTiers}
                          selectedTier={selectedPackageTier}
                          setSelectedTier={setSelectedPackageTier}
                        />
                      )}
                      {isAnnualAvailable && (
                        <PeriodSwitchContainer>
                          <OptionSwitch
                            id="subscribe-bible-study--toggle"
                            name="subscribe-bible-study--toggle"
                            data-qa-hook="toggleSubscriptionPeriod"
                            leftLabel="Billed Yearly"
                            rightLabel="Billed Monthly"
                            toggledRight={selectedPeriod === 'month'}
                            onChange={() => setSelectedPeriod(prev => (prev === 'year' ? 'month' : 'year'))}
                            activeColor={Color.Primary._50}
                          />
                        </PeriodSwitchContainer>
                      )}
                      <div className={`grid-container grid-sm-col-${packageTiers.length}`}>
                        <PlanContents plans={brand[data.curriculum.name.toLowerCase()]} />
                      </div>
                    </>
                  }
                  checkoutContent={
                    <>
                      <Text>
                        <strong>What happens next:</strong>
                      </Text>
                      <BulletedList fontSize="18px">
                        {showDateWarning && (
                          <li style={{ color: Color.Orange._50 }}>
                            Ongoing sessions start on December&nbsp;6,&nbsp;2020
                          </li>
                        )}
                        <li>
                          Upon checkout, your credit card or Lifeway payment account will be billed immediately for the
                          first {selectedPeriod}
                        </li>
                        <li>Access will start immediately</li>
                        {brand.timelineEnabled ? (
                          <li>
                            {`Your church will have immediate and rolling access to the current month's session as well as
                            the upcoming 2 months, and previous month's last 2 sessions (14 weeks minimum). New sessions
                            are added on the first of the month, so you will always be up to date and able to plan ahead.`}
                          </li>
                        ) : (
                          // TODO: Delete this and remove check when all brands go to the new window
                          <li>
                            Your church will have immediate and rolling access to the current week's session as well as
                            the upcoming 8 sessions, and previous 4 sessions (13 weeks total). New sessions are added
                            weekly, so you will always be up to date and able to plan ahead
                          </li>
                        )}
                      </BulletedList>
                      <Checkout>
                        <PriceTotal>
                          <span>Total Due Today</span>
                          <span data-qa-hook="totalDueToday">{`$${formatCurrency(selectedPackage.price)}`}</span>
                        </PriceTotal>
                        <TaxStatement>plus applicable taxes</TaxStatement>
                        <PrimaryButton
                          data-qa-hook="checkoutButton"
                          className="pull-right"
                          style={{ textAlign: 'left', width: '100%', margin: '24px auto', padding: '16px' }}
                          onClick={() => redirectToPurchase(selectedPackage)}
                          disabled={data.isRedirecting}
                        >
                          Go To Checkout <i className="fas fa-external-link-alt pull-right"></i>
                        </PrimaryButton>
                        <Terms onClick={() => setShowModal(true)}>Subscription and Cancellation Terms</Terms>
                      </Checkout>
                    </>
                  }
                />
              )}
              {showModal && <TermsModal handleDismiss={() => setShowModal(false)}></TermsModal>}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

SubscribeBibleStudy.propTypes = {
  user: PropTypes.object,
};

angular.module('lwNamb').component('subscribeBibleStudy', react2angular(SubscribeBibleStudy));
