import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Form, Formik } from 'formik';
import Notifications from 'react-notify-toast';
import styled from 'styled-components';
import * as Yup from 'yup';
import Breadcrumbs from '../../components/Breadcrumbs';
import HelpAndInfoBox from '../../components/HelpAndInfoBox';
import { Buttons, PrimaryButton, ToggleButton } from '../../components/Buttons';
import ErrorMessage from '../../components/ErrorMessage';
import {
  FormFieldSet,
  FormFieldContainer,
  FormFieldMaxCharCountStatus,
  Header,
  Input,
  Label,
  Select,
  FormGroupContainer,
  FormFieldDate,
} from '../../components/FormElements';
import ImageCrop, { ImageCropUtils } from '../../components/ImageCrop';
import { Container, CurriculumVolumeImagePlaceholder } from '../../components/Layout';
import LoadingState from '../../components/LoadingState';
import RemoveContentSection from '../../components/RemoveContentSection';
import alertService from '../../services/AlertService';
import curriculumService from '../../services/curriculumService';
import windowService from '../../services/windowService';
import { Breakpoints } from '../../StyleGuide';
import { ymdFromDate, ymdToLocalMidnight } from '../../filters/date-filters';
import VolumeHeadersList from './VolumeHeadersList';
import limitFilter from '../../filters/limit-filter';
import useCurriculum from '../../hooks/useCurriculum';
import { useUser } from '../../authentication';
import { useParams } from 'react-router-dom';

export default function ManageCurriculumVolume() {
  const user = useUser();
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, volumeId } = useParams();

  const [form, setForm] = useState({
    name: '',
    volumeNumber: '',
    quarterSeason: '',
    quarterYear: '',
    startDate: '',
    endDate: '',
    image: '',
    availabilityEndDate: undefined,
  });
  const [crumbs, setCrumbs] = useState([
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Curriculum', route: '#/manage-curriculum' },
  ]);
  const [data, setData] = useState({ isLoading: true, isError: false, curriculum: {}, volume: {} });
  const [isAlwaysAvailable, setIsAlwaysAvailable] = useState(true);

  const volumeNameCharLimit = 48;

  const AvailabilityEndDateContainer = styled(FormFieldDate)`
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      justify-content: center;
      grid-template-columns: 0.5fr;
    }
  `;

  const OptionalSelect = styled(Select)`
    &.unselected {
      color: #cbd1d7;

      option {
        color: initial;
      }
    }
  `;

  const formatVolumeToForm = volume => ({
    ...volume,
    startDate: ymdToLocalMidnight(volume.startDate),
    endDate: ymdToLocalMidnight(volume.endDate),
    volumeNumber: volume.number || '',
    quarterSeason: volume.quarter ? volume.quarter.season : '',
    quarterYear: volume.quarter ? ymdToLocalMidnight(`${volume.quarter.year}-01-01`) : '',
    availabilityEndDate: volume.availabilityEndDate ? ymdToLocalMidnight(volume.availabilityEndDate) : undefined,
  });

  useEffect(() => {
    const promises = [curriculumService.getIndividualCurriculum(curriculumId)];
    if (volumeId) promises.push(curriculumService.getVolume(volumeId));

    Promise.all(promises)
      .then(values => {
        const curriculum = values[0];
        const volume = values[1];

        if (volume) {
          setForm(formatVolumeToForm(volume));
          setIsAlwaysAvailable(!volume.availabilityEndDate);
          setCrumbs([
            ...crumbs,
            {
              name: `${curriculum.brand}: ${curriculum.name}`,
              route: brand.timelineEnabled
                ? `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/session-calendar`
                : `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}`,
            },
            { name: limitFilter(volume.name, volumeNameCharLimit) },
          ]);
          setData({ ...data, isLoading: false, curriculum: curriculum, volume: volume });
        } else {
          setCrumbs([
            ...crumbs,
            {
              name: `${curriculum.brand}: ${curriculum.name}`,
              route: brand.timelineEnabled
                ? `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/session-calendar`
                : `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}`,
            },
            { name: 'New Curriculum Volume' },
          ]);
          setData({ ...data, isLoading: false, curriculum: curriculum });
        }
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
        setData({ ...data, isLoading: false, isError: true });
      });
  }, []);

  const toggleAvailabilityEndDate = () => {
    if (!isAlwaysAvailable) setForm({ ...form, availabilityEndDate: undefined });
    setIsAlwaysAvailable(!isAlwaysAvailable);
  };

  const updateFormData = newValues => setForm(form => ({ ...form, ...newValues }));

  const uploadVolumePoster = (image, originalImage) =>
    new Promise((resolve, reject) => {
      if (ImageCropUtils.isCroppedImage(image)) {
        const filename = ImageCropUtils.generateFilename();
        ImageCropUtils.convertCroppedImageToBlob(image)
          .then(blob => curriculumService.uploadCurriculumImage(filename, blob))
          .then(() => resolve(filename))
          .catch(reason => reject(reason));
      } else {
        resolve(originalImage);
      }
    });

  const redirectToCurriculum = successMessage => {
    if (brand.timelineEnabled === true) {
      windowService.redirectTo(`/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/session-calendar`);
      alertService.showOnNextPage(successMessage);
    } else {
      windowService.redirectTo(`/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}`);
      alertService.showOnNextPage(successMessage);
    }
  };

  const submitForm = () =>
    uploadVolumePoster(form.image, data.volume.image)
      .then(filename =>
        curriculumService
          .saveVolume(
            curriculumId,
            volumeId,
            form.name,
            parseInt(form.volumeNumber),
            form.quarterSeason,
            form.quarterYear && form.quarterYear.getFullYear(),
            ymdFromDate(form.startDate),
            ymdFromDate(form.endDate),
            filename,
            form.availabilityEndDate && ymdFromDate(form.availabilityEndDate),
            user.userId
          )
          .then(() => {
            redirectToCurriculum('Curriculum Volume Saved');
          })
      )
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
      });

  const removeVolume = () =>
    curriculumService
      .removeVolume(volumeId, user.userId)
      .then(() => {
        redirectToCurriculum('Curriculum Volume Removed');
      })
      .catch(reason => {
        console.error(reason);
        alertService.showError();
        throw reason;
      });

  const getDateByDaysAhead = (date, days) => new Date(new Date(date).setDate(date.getDate() + days));

  const validateQuarter = ({ quarterSeason, quarterYear }) => {
    const error = {};

    if (!!quarterSeason && !quarterYear) error.quarterYear = 'Year is required when season is provided';
    if (!!quarterYear && !quarterSeason) error.quarterSeason = 'Season is required when year is provided';

    return error;
  };

  return (
    <>
      <Notifications />
      <Breadcrumbs crumbs={crumbs} />
      {data.isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <Container style={{ minHeight: '100vh' }}>
          <Formik
            enableReinitialize
            initialValues={form}
            validate={validateQuarter}
            validationSchema={Yup.object({
              image: Yup.string().required(),
              name: Yup.string().trim().required('A subtitle is required'),
              volumeNumber: Yup.string(),
              quarterSeason: Yup.string(),
              quarterYear: Yup.string(),
              startDate: Yup.date().required(),
              endDate: Yup.date()
                .required('An end date is required')
                .when('startDate', (startDate, schema) =>
                  startDate
                    ? schema.min(getDateByDaysAhead(startDate, 1), 'End date must come after start date')
                    : schema
                ),
              availabilityEndDate: Yup.date().test(
                'requireIfActive',
                'An availability end date is required',
                value => isAlwaysAvailable || !!value
              ),
            })}
            onSubmit={submitForm}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form data-qa-hook="manageCurriculumVolumeView">
                <div className="grid-container grid-col-4 grid-sm-col-8 grid-col-gap-24" style={{ margin: '32px 0' }}>
                  <div className="grid-col-span-4">
                    <Header>{volumeId ? 'Edit' : 'New'} Curriculum Volume</Header>
                  </div>
                  <div className="grid-col-span-4">
                    {!volumeId && (
                      <HelpAndInfoBox description="Once a new volume is saved, volume headers will be available for creating and editing." />
                    )}
                    {volumeId && !data.volume.sections.length && (
                      <HelpAndInfoBox description="To copy all of the volume headers from the previous volume, use the Add Header button at the bottom of the page." />
                    )}
                  </div>
                </div>
                <div className="grid-container grid-col-4 grid-sm-col-8 grid-col-gap-24" style={{ margin: '32px 0' }}>
                  <div className="grid-col-span-4 grid-sm-col-span-8">
                    <FormFieldSet name={`${data.curriculum.name}: ${data.curriculum.brand}`} />
                  </div>
                  <FormFieldContainer className="grid-col-span-4 grid-sm-col-span-3">
                    <Label>Poster</Label>
                    <CurriculumVolumeImagePlaceholder actionable={!form.image}>
                      <ImageCrop
                        defaultImage={data.volume.imageUrl}
                        aspectRatio={258 / 332}
                        onUpdate={img => updateFormData({ image: img })}
                      />
                    </CurriculumVolumeImagePlaceholder>
                    {errors.image && <ErrorMessage>An image is required</ErrorMessage>}
                  </FormFieldContainer>
                  <div className="grid-col-span-4 grid-sm-col-span-5">
                    <div className="grid-container grid-col-1 grid-sm-col-2 grid-col-gap-24">
                      <FormFieldDate>
                        <Label>Start Date</Label>
                        <DatePicker
                          data-qa-hook="startDatePicker"
                          selected={form.startDate}
                          onChange={date => updateFormData({ startDate: date || '' })}
                          customInput={<Input />}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Select Date"
                        />
                        {touched.startDate && errors.startDate && <ErrorMessage>A start date is required</ErrorMessage>}
                      </FormFieldDate>
                      <FormFieldDate>
                        <Label>End Date</Label>
                        <DatePicker
                          data-qa-hook="endDatePicker"
                          selected={form.endDate}
                          onChange={date => updateFormData({ endDate: date || '' })}
                          customInput={<Input />}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Select Date"
                        />
                        {touched.endDate && errors.endDate && <ErrorMessage>{errors.endDate}</ErrorMessage>}
                      </FormFieldDate>
                    </div>
                    <FormFieldMaxCharCountStatus
                      label="Subtitle"
                      name="name"
                      type="text"
                      placeholder="Enter Subtitle"
                      maxLength={128}
                      onBlur={e => updateFormData({ name: e.target.value })}
                    />
                    <FormFieldMaxCharCountStatus
                      label="Volume Number"
                      name="volumeNumber"
                      type="number"
                      placeholder="Enter Volume Number"
                      maxLength={48}
                      min="1"
                      step="1"
                      onChange={e => updateFormData({ volumeNumber: e.target.value })}
                    />
                    <FormFieldContainer>
                      <Label>Quarter</Label>
                      <FormFieldDate>
                        <div className="grid-container grid-col-1 grid-sm-col-2 grid-gap-24">
                          <div className="grid-span-col-1">
                            <OptionalSelect
                              className={form.quarterSeason === '' ? 'unselected' : ''}
                              value={form.quarterSeason}
                              onChange={e => updateFormData({ quarterSeason: e.target.value })}
                            >
                              <option value="">Select Season</option>
                              <option value="Spring">Spring</option>
                              <option value="Summer">Summer</option>
                              <option value="Fall">Fall</option>
                              <option value="Winter">Winter</option>
                            </OptionalSelect>
                          </div>
                          <div className="grid-span-col-1">
                            <DatePicker
                              data-qa-hook="quarterYearPicker"
                              selected={form.quarterYear}
                              onChange={date => updateFormData({ quarterYear: date || '' })}
                              customInput={<Input />}
                              dateFormat="yyyy"
                              placeholderText="Select Year"
                              showYearPicker
                            />
                          </div>
                        </div>
                      </FormFieldDate>
                      {(errors.quarterSeason || errors.quarterYear) && (
                        <ErrorMessage>{errors.quarterSeason || errors.quarterYear}</ErrorMessage>
                      )}
                    </FormFieldContainer>
                    {data.curriculum.offCycleEnabled && (
                      <>
                        <FormFieldContainer>
                          <Label>Reset Schedule Availability</Label>
                          <FormGroupContainer>
                            <p>Adjust the availability of when the schedule can be reset for this volume.</p>
                            <ToggleButton isOn={isAlwaysAvailable} onClick={toggleAvailabilityEndDate}>
                              Always Available
                            </ToggleButton>
                          </FormGroupContainer>
                        </FormFieldContainer>
                        <FormFieldContainer>
                          <AvailabilityEndDateContainer className="grid-container grid-col-1">
                            <div className={`grid-span-col-1${isAlwaysAvailable ? ' disabled' : ''}`}>
                              <Label>Availability End Date</Label>
                              <DatePicker
                                data-qa-hook="availabilityEndDatePicker"
                                selected={form.availabilityEndDate}
                                onChange={date => updateFormData({ availabilityEndDate: date || '' })}
                                customInput={<Input />}
                                dateFormat="MM/dd/yyyy"
                                disabled={isAlwaysAvailable}
                                placeholderText="Select Date"
                              />
                              {!isAlwaysAvailable && touched.availabilityEndDate && errors.availabilityEndDate && (
                                <ErrorMessage>{errors.availabilityEndDate}</ErrorMessage>
                              )}
                            </div>
                          </AvailabilityEndDateContainer>
                        </FormFieldContainer>
                      </>
                    )}
                    <div className="grid-col-span-4 grid-sm-col-span-2">
                      <Buttons style={{ justifyContent: 'flex-end' }}>
                        <PrimaryButton
                          data-qa-hook="submit"
                          type="submit"
                          disabled={isSubmitting}
                          operating={isSubmitting}
                        >
                          Save Volume
                        </PrimaryButton>
                      </Buttons>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          {volumeId && (
            <>
              <VolumeHeadersList headers={data.volume.sections} user={user} />
              <div className="grid-container grid-col-4 grid-sm-col-8 grid-col-gap-32">
                <div className="grid-col-span-4 grid-sm-col-span-2"></div>
                <div className="grid-col-span-4">
                  <RemoveContentSection
                    type="Volume"
                    modalSubtext={data.volume.name}
                    modalContent={<ErrorMessage>Any materials associated with this volume will be lost</ErrorMessage>}
                    onRemove={removeVolume}
                  />
                </div>
              </div>
            </>
          )}
        </Container>
      )}
    </>
  );
}
