angular.module('lwNamb').controller(
  'AssessmentResultsCtrl',

  [
    '$scope',
    '$log',
    '$localStorage',
    'userService',
    'windowService',
    'assessResponseAggregatorService',
    '$timeout',
    function($scope, $log, $localStorage, userService, windowService, assessResponseAggregatorService, $timeout) {
      var init = function() {
        $scope.loading = true;

        $scope.clearInput();

        $scope.sortBy = 'assessment.mostRecentCompletion';

        $scope.reverse = true;

        //Clear Local Storage (Cache)
        $localStorage.assessmentResult = undefined;
        $localStorage.shortAnswerQuestion = undefined;
        //May want to add the results to cache too for coming back from viewing short answer results
        $localStorage.assessmentResultResponseIds = undefined;
        $localStorage.assessment = undefined;
        $localStorage.allResults = true;

        userService.user().then(
          function(user) {
            $scope.user = user;
            retrieveAssessments();
          },
          function() {
            $log.error('No user returned in AssessmentResultsCtrl');
          }
        );
      };

      var retrieveAssessments = function() {
        assessResponseAggregatorService.getAllAssessments($scope.user.lastSelectedAccount).then(
          function(response) {
            $scope.allAssessments = response.map(function(assessment) {
              return assessment.assessment;
            });
            $scope.loading = false;
            $timeout(function() {
              if ($scope.filter && !$scope.filter.sortDropDown) {
                $scope.filter.sortDropDown = 'mostRecentCompletion';
              }
            }, 100);
          },
          function(reason) {
            $log.error(reason);
            $scope.error = true;
            $scope.loading = false;
          }
        );
      };

      $scope.openAssessmentResults = function(assessment) {
        if (assessment.numComplete > 0) {
          $localStorage.assessment = assessment;
          $localStorage.assessmentResultResponseIds = assessment.responseIds;
          windowService.redirectHash('/assessment-results-viewer/' + assessment.id);
        }
      };

      $scope.clearInput = function() {
        if ($scope.filter && $scope.filter.sortDropDown) {
          $scope.filter = Object.assign({}, { sortDropDown: $scope.filter.sortDropDown });
        } else {
          $scope.filter = {};
        }
      };

      init();
    },
  ] //End Assessment Results Ctrl
);
