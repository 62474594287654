import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Type, Grid } from '../StyleGuide';
import { PrimaryButton, TransparentButton, OutlineButton } from '../components/Buttons';
import userService from '../services/userService';

const Header = styled.h2`
  font-size: ${Type.Scale._6};
  font-weight: ${Type.Weight.medium};
  padding: ${Grid._12} ${Grid._5} 0;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Grid._4};
  margin-top: ${Grid._8};
`;

const getErrorMessage = errorType => {
  if (errorType === 'NO_PENDING_INVITES') {
    return {
      errorHeader: 'This Invitation Is Not Valid',
      errorMessage: 'Please contact the person who sent you this invitation.',
    };
  } else if (errorType === 'INVITES_ARE_DEACTIVATED') {
    return {
      errorHeader: 'This Invitation Has Been Deactivated',
      errorMessage: 'Please contact the person who sent you this invitation.',
    };
  } else if (errorType === 'NO_SEATS_AVAILABLE') {
    return {
      errorHeader: "Uh oh! It looks like there aren't any open seats left for you.",
      errorMessage:
        "Please contact the person who sent you this invitation to receive access. They'll need to add more seats. ",
    };
  }
  return {
    errorHeader: 'Something Went Wrong',
    errorMessage: "Oops! We're sorry for the inconvenience. Please try again later.",
  };
};

const PendingInvitesError = ({ invitesLength, dismissError, skipInvite, errorType }) => {
  const { errorHeader, errorMessage } = getErrorMessage(errorType);
  return (
    <>
      <Header>{errorHeader}</Header>
      <h4 className="head-light text-center">{errorMessage}</h4>
      <Buttons>
        {invitesLength > 1 && <PrimaryButton onClick={dismissError}>Try Again</PrimaryButton>}
        <OutlineButton onClick={skipInvite}>Skip for Now</OutlineButton>
        <TransparentButton onClick={userService.logout}>Log Out</TransparentButton>
      </Buttons>
    </>
  );
};

PendingInvitesError.propTypes = {
  invitesLength: PropTypes.number,
  dismissError: PropTypes.func,
  skipInvite: PropTypes.func,
  errorType: PropTypes.string,
  invitorId: PropTypes.string,
};

export default PendingInvitesError;
