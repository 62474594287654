angular.module('lwNamb').factory(
  'contentService',

  [
    '$rootScope',
    'api',
    '$resource',
    'apiUrlService',
    'iconForType',
    '$log',
    '$q',
    function($rootScope, api, $resource, apiUrlService, iconForType, $log, $q) {
      var content = {
        content: [],
        assessments: [],
        downloads: [],
        videos: [],
      };

      content.clear = function() {
        content.availableAssessments = [];
        return content;
      };

      content.getAllAvailableContentForOrg = $resource(
        apiUrlService.getUrl() + '/v1/organizations/:orgId/content',
        {},
        {
          with: { method: 'GET', params: { orgId: 'orgId' } },
        }
      );

      content.availableContentRetrieved = function(response) {
        content.content = setupAssessmentListResponse(response, []);
      };

      content.filterAssessmentsRetrieved = function(response) {
        content.assessments = setupAssessmentListResponse(response, [], 'Assessment');
      };

      content.filterDownloadsRetrieved = function(response) {
        content.downloads = setupAssessmentListResponse(response, [], 'Download');
      };

      content.filterVideosRetrieved = function(response) {
        content.videos = setupAssessmentListResponse(response, [], 'Video');
      };

      content.archiveDownload = function archiveDownload(fileId) {
        var deferred = $q.defer();
        api.post('/v1/files/' + fileId + '/archive', {}).then(
          function(success) {
            deferred.resolve(success.data);
          },
          function(failure) {
            $log.error('could not archive file: ' + failure.status + ' ' + failure.data);
            deferred.reject({ reason: 'unknown' });
          }
        );
        return deferred.promise;
      };

      var setupAssessmentListResponse = function(response, array, type) {
        if (response && response.content && response.content.length > 0) {
          for (var i = 0; i < response.content.length; i++) {
            var item = response.content[i];
            if (type === undefined || type === item.contentType) {
              array.push({
                id: item.id,
                type: item.contentType,
                icon: iconForType.with(item, 'contentType'),
                name: item.name,
                published: item.published,
                questions: item.numberOfQuestions,
                library: item.library,
                lastModifiedDate: item.lastEditedDate,
              });
            }
          }
          return array;
        }
      };

      return content;
    },
  ]
);
