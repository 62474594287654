import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Color, Grid, Shadow } from '../../StyleGuide';
import { RaisedContainer, PageTitle } from '../../components/Layout';
import { PrimaryButton } from '../../components/Buttons';

export const ContentContainer = styled(RaisedContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: ${Grid._8};
  max-width: 820px;
  padding: ${Grid._6};
`;

export const ContentTitle = styled(PageTitle)`
  text-align: center;

  p {
    margin: 0;
  }
`;

export const ContinueButton = styled(PrimaryButton)`
  min-width: 220px;
`;

export const ListItem = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Grid._3} ${Grid._6};
  background: ${Color.Gray._05};
  border: none;
  width: 100%;
  box-shadow: ${Shadow.light};
`;

export const ViewContainer = styled.div`
  width: 100%;
  max-width: 550px;
`;

export const ViewPropTypes = {
  data: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired,
};
