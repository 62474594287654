import React, { useState, useEffect } from 'react';
import Notifications from 'react-notify-toast';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { Grid, Type } from '../StyleGuide';
import { Container, ImageDimensionLabel, SixteenNineAspectRatioPlaceholder } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { Label, FormFieldContainer, FormField } from '../components/FormElements';
import { PrimaryButton } from '../components/Buttons';
import ImageCrop, { ImageCropUtils } from '../components/ImageCrop';
import Breadcrumbs from '../components/Breadcrumbs';
import alertService from '../services/AlertService';
import catalogService from '../services/catalogService';
import trainingService from '../services/trainingService';
import workflowService from '../services/workflowService';
import { useUser } from '../authentication';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';

const Header = styled.h2`
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._5};
  font-weight: ${Type.Weight.bold};
  overflow-wrap: break-word;
  margin: 0;
`;

const SubHeader = styled.h5`
  color: ${Type.Color.medium};
  font-size: ${Type.Scale._2};
`;

export default function PathwaySettings() {
  const user = useUser();
  const { trainingId } = useParams();
  const { manageTrainingRoutePrefix, buildType } = useManageTrainingRoute();

  const [data, setData] = useState({ isLoading: true });

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Pathways', route: `#${manageTrainingRoutePrefix}/pathways` },
    ...(data.pathway?.name
      ? [
          { name: data.pathway.name, route: `#${manageTrainingRoutePrefix}/pathways/${trainingId}` },
          { name: 'Settings' },
        ]
      : []),
  ];

  const getPathway = ownerId => {
    workflowService
      .getWorkflow(ownerId, trainingId)
      .then(pathway => {
        setData({
          pathway: {
            ...pathway,
            posterImage: pathway.posterImage
              ? trainingService.getTrainingImageUrl(pathway.posterImage)
              : pathway.posterImage,
          },
          isLoading: false,
        });
      })
      .catch(() => setData(data => ({ ...data, isLoading: false, isError: true })));
  };

  useEffect(() => {
    if (buildType === 'author') {
      catalogService.getCatalogIdForSite().then(id => {
        getPathway(id);
      });
    } else {
      getPathway(user.lastSelectedAccount);
    }
  }, []);

  const saveSettings = (values, { setSubmitting }) => {
    let name = values.name.trim();
    let description = values.description ? values.description.trim() : '';
    let promises = [];
    if (name !== data.pathway.name) {
      promises.push(trainingService.updatePathwayName(data.pathway._id, name, data.pathway.owner, user.userId));
    }
    if (description !== data.pathway.description) {
      promises.push(trainingService.updatePathwayDescription(data.pathway._id, description, user.userId));
    }
    if (values.posterImage !== data.pathway.posterImage) {
      if (!values.posterImage && !!data.pathway.posterImage) {
        promises.push(trainingService.removePathwayImage(data.pathway._id));
      } else if (ImageCropUtils.isCroppedImage(values.posterImage)) {
        promises.push(
          ImageCropUtils.convertCroppedImageToBlob(values.posterImage).then(blob =>
            trainingService.uploadPathwayImage(data.pathway._id, blob, ImageCropUtils.generateFilename())
          )
        );
      }
    }
    Promise.all(promises)
      .then(() => {
        setData(data => ({ pathway: { ...data.pathway, name: name, description: description } }));
        alertService.show(`Pathway Settings Saved`, 'success', 3000);
      })
      .catch(err => {
        console.error(err);
        err && err.eventType === 'WorkflowNameDuplicateError'
          ? alertService.show('Sorry, that name is already taken', 'error', 3000)
          : alertService.show(
              'Oops, we’re unable to save changes at this time. We’re sorry for the inconvenience. Please try again later.',
              'error'
            );
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <>
      <Notifications />
      <Breadcrumbs crumbs={crumbs} />
      {data.isLoading ? (
        <LoadingState />
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <Container data-qa-hook="pathwaySettingsView" style={{ margin: `${Grid._6} auto` }}>
          <div className="grid-container grid-col-4 grid-sm-col-8 grid-col-gap-40" style={{ marginBottom: Grid._5 }}>
            <div className="grid-col-span-4">
              <SubHeader>
                <i className="fas fa-cogs"></i> Pathway Settings
              </SubHeader>
              <Header>{data.pathway.name}</Header>
            </div>
          </div>
          <Formik
            initialValues={data.pathway}
            enableReinitialize={true}
            validationSchema={Yup.object({
              name: Yup.string().max(256, 'Name is too long').required('A name is required'),
              description: Yup.string().max(32767, 'Description is too long'),
            })}
            onSubmit={saveSettings}
          >
            {({ dirty, isSubmitting, values, setValues }) => (
              <Form>
                <div className="grid-container grid-col-4 grid-sm-col-8 grid-col-gap-40">
                  <div className="grid-col-span-4">
                    <FormFieldContainer>
                      <Label>Poster</Label>
                      <SixteenNineAspectRatioPlaceholder actionable={!values.posterImage}>
                        <ImageCrop
                          defaultImage={values.posterImage}
                          onUpdate={image => setValues(prevValues => ({ ...prevValues, posterImage: image }), false)}
                          buttonText="Upload Poster"
                          aspectRatio={16 / 9}
                        />
                      </SixteenNineAspectRatioPlaceholder>
                      <ImageDimensionLabel>Recommended: 405 x 227</ImageDimensionLabel>
                    </FormFieldContainer>
                  </div>
                  <div className="grid-col-span-4">
                    <FormField
                      data-qa-hook="pathwaySettingsName"
                      label="Name"
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                    />
                    <FormField
                      data-qa-hook="pathwaySettingsDescription"
                      label="Description"
                      name="description"
                      as="textarea"
                      rows="10"
                      placeholder="Enter Description"
                    />
                    <FormFieldContainer>
                      {!dirty && (
                        <PrimaryButton
                          data-qa-hook="pathwaySettingsSave"
                          onClick={() => alertService.show(`Pathway Settings Saved`, 'success', 3000)}
                        >
                          Save Settings
                        </PrimaryButton>
                      )}
                      {dirty && (
                        <PrimaryButton
                          data-qa-hook="pathwaySettingsSave"
                          type="submit"
                          disabled={isSubmitting}
                          operating={isSubmitting}
                        >
                          Save Settings
                        </PrimaryButton>
                      )}
                    </FormFieldContainer>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      )}
    </>
  );
}
