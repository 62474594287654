angular.module('lwNamb').factory(
  'assessTakerService',

  [
    '$rootScope',
    'api',
    'qt',
    'uuid4',
    function($rootScope, api, qt, uuid4) {
      var timeoutSeconds = 15;

      return {
        getAssessmentResponse: function(assesseeID, assessmentID, assessmentResponseId) {
          return getAssessmentResponse(assesseeID, assessmentID, assessmentResponseId);
        },
        saveRadio: function(assessmentResponseId, assessmentId, questionId, answerId, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          api
            .post('/v1/commands/SaveRadio', {
              id: assessmentResponseId,
              initiatingUserId: initiatingUserId,
              assessmentId: assessmentId,
              questionId: questionId,
              answerId: answerId,
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('RadioToQuestionSaved', function(name, event) {
            if (event.questionId.id == questionId.id) {
              deferred.resolve();
            }
          });

          var gettingResponse;
          deferred.promise.then(null, null, function(elapsedSeconds) {
            if (elapsedSeconds > 1 && !gettingResponse) {
              gettingResponse = true;
              getAssessmentResponse(initiatingUserId, assessmentId.id, assessmentResponseId).then(
                function(assessmentResponse) {
                  gettingResponse = false;
                  var saved = false;
                  for (var i = 0; i < assessmentResponse.answeredQuestions.length; i++) {
                    if (assessmentResponse.answeredQuestions[i].questionId.id === questionId.id) {
                      saved = true;
                    }
                  }
                  if (saved) {
                    deferred.resolve();
                  }
                },
                function() {
                  gettingResponse = false;
                }
              );
            }
          });

          return deferred.promise;
        },
        saveTextBox: function(assessmentResponseId, assessmentId, questionId, answer, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          api
            .post('/v1/commands/SaveTextBox', {
              id: assessmentResponseId,
              initiatingUserId: initiatingUserId,
              assessmentId: assessmentId,
              questionId: questionId,
              answer: answer,
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('TextBoxToQuestionSaved', function(name, event) {
            if (event.questionId.id == questionId.id) {
              deferred.resolve();
            }
          });

          var gettingResponse;
          deferred.promise.then(null, null, function(elapsedSeconds) {
            if (elapsedSeconds > 1 && !gettingResponse) {
              gettingResponse = true;
              getAssessmentResponse(initiatingUserId, assessmentId.id, assessmentResponseId).then(
                function(assessmentResponse) {
                  gettingResponse = false;
                  var saved = false;
                  for (var i = 0; i < assessmentResponse.answeredQuestions.length; i++) {
                    if (assessmentResponse.answeredQuestions[i].questionId.id === questionId.id) {
                      saved = true;
                    }
                  }
                  if (saved) {
                    deferred.resolve();
                  }
                },
                function() {
                  gettingResponse = false;
                }
              );
            }
          });

          return deferred.promise;
        },
        saveSort: function(assessmentResponseId, assessmentId, questionId, answerIds, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          api
            .post('/v1/commands/SaveSort', {
              id: assessmentResponseId,
              initiatingUserId: initiatingUserId,
              assessmentId: assessmentId,
              questionId: questionId,
              answerIds: answerIds,
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('SortToQuestionSaved', function(name, event) {
            if (event.questionId.id == questionId.id) {
              deferred.resolve();
            }
          });

          var gettingResponse;
          deferred.promise.then(null, null, function(elapsedSeconds) {
            if (elapsedSeconds > 1 && !gettingResponse) {
              gettingResponse = true;
              getAssessmentResponse(initiatingUserId, assessmentId.id, assessmentResponseId).then(
                function(assessmentResponse) {
                  gettingResponse = false;
                  var saved = false;
                  for (var i = 0; i < assessmentResponse.answeredQuestions.length; i++) {
                    if (assessmentResponse.answeredQuestions[i].questionId.id === questionId.id) {
                      saved = true;
                    }
                  }
                  if (saved) {
                    deferred.resolve();
                  }
                },
                function() {
                  gettingResponse = false;
                }
              );
            }
          });

          return deferred.promise;
        },
        saveAll: function(assessmentResponseId, assessmentId, answers, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          //         case class SaveAllAnswers(id: String, initiatingUserId: Option[String], assessmentId: AssessmentId, answers: Seq[QuestionIdAndAnswer]) extends AssessmentResponseServiceCommand

          api
            .post('/v1/commands/SaveAllAnswers', {
              id: assessmentResponseId,
              initiatingUserId: initiatingUserId,
              assessmentId: assessmentId,
              answers: answers,
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          var answerResponsesRemaining = (answers || []).length;
          var checkAnswerBeforeResolving = function() {
            if (answerResponsesRemaining === 0) {
              deferred.resolve();
            }
          };
          $rootScope.$on('TextBoxToQuestionSaved', function(name, event) {
            if (event.id == assessmentResponseId) {
              answerResponsesRemaining -= 1;
              checkAnswerBeforeResolving();
            }
          });
          $rootScope.$on('RadioToQuestionSaved', function(name, event) {
            if (event.id == assessmentResponseId) {
              answerResponsesRemaining -= 1;
              checkAnswerBeforeResolving();
            }
          });
          $rootScope.$on('MultiSelectAndInputToQuestionSaved', function(name, event) {
            if (event.id == assessmentResponseId) {
              answerResponsesRemaining -= 1;
              checkAnswerBeforeResolving();
            }
          });
          $rootScope.$on('DateToQuestionSaved', function(name, event) {
            if (event.id == assessmentResponseId) {
              answerResponsesRemaining -= 1;
              checkAnswerBeforeResolving();
            }
          });

          var gettingResponse;
          deferred.promise.then(null, null, function(elapsedSeconds) {
            if (elapsedSeconds > 1 && !gettingResponse) {
              gettingResponse = true;
              getAssessmentResponse(initiatingUserId, assessmentId.id, assessmentResponseId).then(
                function(assessmentResponse) {
                  gettingResponse = false;
                  if (
                    assessmentResponse.answeredQuestions &&
                    assessmentResponse.answeredQuestions.length === answers.length
                  ) {
                    deferred.resolve();
                  } else {
                    gettingResponse = false;
                  }
                },
                function() {
                  gettingResponse = false;
                }
              );
            }
          });

          return deferred.promise;
        },
        getAssessmentResponseWithQuestions: function(responseId) {
          return getResponseWithQuestions('responseWithQuestions', responseId);
        },
        getAssessmentResults: function(responseId) {
          return getResponseWithQuestions('results', responseId);
        }
      };

      function getResponseWithQuestions(endpoint, responseId){
        return api.get('/v1/assessments/' + endpoint + '/' + responseId).then(function(responseData) {
          var data = responseData.data;
          if (Array.isArray(data)) {
            data = data.map(function(scorecardAssignment) {
              if (scorecardAssignment.scorecards && Array.isArray(scorecardAssignment.scorecards.tasks)) {
                var tasks = scorecardAssignment.scorecards.tasks.sort(function(task1, task2) {
                  if (task1.task.taskName == task2.task.taskName) {
                    return 0;
                  }
                  if (task1.task.taskName < task2.task.taskName) {
                    return -1;
                  } else {
                    return 1;
                  }
                });
                scorecardAssignment.scorecards.tasks = tasks;
              }
              return scorecardAssignment;
            });
          }
          return data;
        });
      }

      function getAssessmentResponse(assesseeID, assessmentID, assessmentResponseId) {
        return api.get('/v1/assessments/' + assessmentID + '/responses/' + assesseeID).then(function(responseData) {
          var response = {};
          for (var i = 0; i < responseData.data.assessmentResponse.length; i++) {
            if (assessmentResponseId === responseData.data.assessmentResponse[i]._id.id) {
              response = responseData.data.assessmentResponse[i];
            }
          }
          return response;
        });
      }
    },
  ]
);
