angular.module('lwNamb').controller(
  'WorkflowsCtrl',

  [
    '$scope',
    'alertService',
    'userService',
    'workflowService',
    '$log',
    '$q',
    'buildService',
    '$timeout',
    function($scope, alertService, userService, workflowService, $log, $q, buildService, $timeout) {
      var ownerId;
      function init() {
        $scope.loading = true;
        $scope.wf = {};
        $scope.allWorkflows = [];
        $scope.selectedItems = [];
        $scope.form = {};
        $scope.submitted = false;
        $scope.extras = {};

        $q.all([userService.user(), buildService.ownerId()]).then(function(result) {
          var user = result[0];
          ownerId = result[1];
          $scope.user = user;
          $scope.clearInput();
          loadData();
        });
      }

      function loadData() {
        workflowService.getWorkflows(ownerId).then(
          function success(workflows) {
            $scope.allWorkflows = workflows;
            $scope.loading = false;
            $timeout(function() {
              if ($scope.filter && !$scope.filter.sortDropDown) {
                $scope.filter.sortDropDown = 'lastModifiedDate';
              }
            }, 100);
          },
          function failure(reason) {
            $log.error(reason);
            $scope.loading = false;
          }
        );
      }

      $scope.clearInput = function() {
        if ($scope.filter && $scope.filter.sortDropDown) {
          $scope.filter = Object.assign({}, { sortDropDown: $scope.filter.sortDropDown });
        } else {
          $scope.filter = {};
        }
      };

      $scope.clearForm = function() {
        $scope.workflow = { name: '' };
        $scope.submitted = false;
        $scope.duplicateNameError = false;
      };

      $scope.addWorkflow = function(name, isValid) {
        $scope.submitted = true;
        if (!isValid) {
          return;
        }
        $scope.spinner = true;
        $scope.duplicateNameError = false;
        workflowService.createWorkflow(name, ownerId, $scope.user.userId).then(
          function(id) {
            $scope.wf = { id: id, name: name, tasks: [] };
            buildService.ctxRedirectTo('/pathways/' + id);
          },
          function(reason) {
            if (reason === 'DUPLICATE_NAME') {
              $scope.duplicateNameError = true;
            } else {
              showAlert('danger', 'Error! Please try again.', false);
            }
            $scope.submitted = false;
            $scope.spinner = false;
          }
        );
      };

      $scope.deleteWorkflow = function(workflow, closeModal) {
        $scope.extras.spinner = true;
        workflowService.deleteWorkflow(workflow.id, $scope.user.userId).then(
          function() {
            $scope.allWorkflows.splice($scope.allWorkflows.indexOf(workflow), 1);
            closeModal();
            showAlert('success', 'pathway removed', 5);
            $scope.extras.spinner = false;
          },
          function(reason) {
            $log.error(reason);
            closeModal();
            showAlert('danger', 'Error! Please try again.', false);
            $scope.extras.spinner = false;
          }
        );
      };

      function showAlert(type, msg, duration) {
        alertService.show({ title: msg, type: type, show: true, dismissible: true, duration: duration });
      }

      $scope.goToWorkflow = function(id) {
        buildService.ctxRedirectTo('/pathways/' + id);
      };

      init();
    },
  ]
);
