angular.module('lwNamb').factory(
  'coachService',

  [
    '$rootScope',
    'api',
    'userService',
    'apiUrlService',
    '$log',
    '$q',
    function($rootScope, api, userService, apiUrlService, $log, $q) {
      return {
        getCoachableUsers: function(coachId, orgId) {
          return api.get('/v2/organizations/' + orgId + '/coach/' + coachId + '/false').then(function(response) {
            return transformUsers(response);
          });
        },
        getCoachableUsersForAdmin: function(coachId, orgId) {
          return api.get('/v2/organizations/' + orgId + '/coach/' + coachId + '/true').then(function(response) {
            return transformUsers(response);
          });
        },
        getScorecardAssignments: function(userId) {
          var deferred = $q.defer();
          userService.user().then(
            function(user) {
              var orgId = user.lastSelectedAccount,
                leaderId = user.userId;
              return api
                .get('/v1/organizations/' + orgId + '/users/' + userId + '/user-scorecards?leaderId=' + leaderId)
                .then(
                  function(response) {
                    deferred.resolve(response.data.groups);
                  },
                  function(reason) {
                    deferred.reject(reason);
                  }
                );
            },
            function() {
              $log.error('No user returned in coachService.getScorecardAssignments');
              deferred.reject('NoUser');
            }
          );
          return deferred.promise;
        },
        populateGroupsDropdown: function(coachableUsers) {
          var groupDropdown = [];
          var groupNames = [];
          for (var g = 0; g < coachableUsers.length; g++) {
            for (var i = 0; i < coachableUsers[g].groups.length; i++) {
              if (coachableUsers[g].groups[i].name !== undefined) {
                groupNames.push(coachableUsers[g].groups[i].name);
              }
            }
          }
          var uniqueNames = groupNames.filter(onlyUnique).sort();
          uniqueNames.forEach(function(item) {
            groupDropdown.push({
              name: item,
              value: item,
            });
          });
          return groupDropdown;
        },
      };

      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

      function transformUsers(response) {
        var transformedUsers = [];
        response.data.coachableUsers.forEach(function(user) {
          user.groupNames = [''];
          if (user.groups.length > 0) {
            user.groups.forEach(function(group) {
              user.groupNames.push(group.name);
            });
          }
          if (user.profileImage) {
            user.profileImage = apiUrlService.getUrl() + '/v1/images/profile/' + user.profileImage;
          }
          transformedUsers.push(user);
        });
        return transformedUsers;
      }
    },
  ]
);
