import React, { useState, useEffect } from 'react';
import { react2angular } from 'react2angular';
import PropTypes from 'prop-types';
import { Grid } from '../StyleGuide';
import { ContainerFluid, Container } from '../components/Layout';
import { CompilationBanner } from './CompilationElements';
import BreadcrumbsSubMenu from '../components/BreadcrumbsSubMenu';
import MobileCrumb from '../components/MobileCrumb';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import compilationService from '../services/compilationService';
import windowService from '../services/windowService';

export default function TrainingCompilationHeader({ trainingName }) {
  const [crumbs, setCrumbs] = useState([{ name: 'Catalog', route: '#/catalog' }, { name: '' }]);
  const [data, setData] = useState({
    training: {},
    isLoading: true,
  });

  const compilationId = windowService.getRoutePart(3);

  useEffect(() => {
    compilationService
      .getCompilation(compilationId)
      .then(compilation => {
        setData({ compilation: compilation, isLoading: false });
        setCrumbs([
          { name: 'Catalog', route: '#/catalog' },
          { name: `${compilation.name}`, route: `#/catalog/compilation/${compilationId}` },
          { name: trainingName },
        ]);
      })
      .catch(err => {
        console.error(err);
        setData({ isError: true, isLoading: false });
      });
  }, []);

  return (
    <>
      <div className="hidden-xs hidden-sm">
        <BreadcrumbsSubMenu crumbs={crumbs} />
      </div>
      {data.isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <>
          {data.compilation.image && (
            <ContainerFluid>
              {data.compilation.image && (
                <CompilationBanner
                  src={compilationService.getCompilationImageUrl(data.compilation.image)}
                  alt={data.compilation.name}
                />
              )}
            </ContainerFluid>
          )}
          <Container className="visible-xs-block visible-sm-block" style={{ margin: `${Grid._4} 0 -${Grid._4} 0` }}>
            <MobileCrumb crumbs={crumbs} />
          </Container>
        </>
      )}
    </>
  );
}

TrainingCompilationHeader.propTypes = {
  trainingName: PropTypes.string.isRequired,
};

angular
  .module('lwNamb')
  .component(
    'trainingCompilationHeader',
    react2angular(TrainingCompilationHeader, ['trainingName'], ['$localStorage'])
  );
