import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import Carousel from 'react-elastic-carousel';
import { InlineList } from '../components/Layout';
import { CarouselPagination } from '../components/CarouselElements';
import { Grid } from '../StyleGuide';
import { Card } from '../components/Layout';
import windowService from '../services/windowService';
import useCurriculum from '../hooks/useCurriculum';

const StyledCarousel = styled(Carousel)`
  .rec.rec-carousel .rec-slider-container {
    margin: 0;
  }
`;

const UnitsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-template-rows: repeat(${props => props.rows}, min-content);
  grid-gap: ${Grid._5};
  width: 100%;
  margin-bottom: ${Grid._5};
`;

const UnitCard = styled(Card)`
  display: flex;
  flex-direction: column;
  grid-gap: ${Grid._2};
  padding: ${Grid._4};
  background: white;

  h6 {
    margin: 0;
  }
`;

export default function UnitCarousel({ units }) {
  const ref = useRef();
  const [unitColumns, setUnitColumns] = useState(1);
  const { brand, ageCategory } = useCurriculum();

  const breakPoints = [
    { width: 1, columnsPerPage: 1 },
    { width: 600, columnsPerPage: 2 },
    { width: 992, columnsPerPage: 3 },
  ];

  const getUnitPages = (units, columns) => {
    const unitsPerColumn = 3;
    const unitsPerPage = columns * unitsPerColumn;

    const pages = [];
    for (let i = 0; i < units.length; i += unitsPerPage) {
      pages.push(units.slice(i, i + unitsPerPage));
    }

    return pages;
  };

  const redirectToSettings = ({ id, curriculumId }) => {
    windowService.redirectTo(`/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${id}`);
  };

  return (
    <StyledCarousel
      ref={ref}
      itemsToShow={1}
      itemsToScroll={1}
      breakPoints={breakPoints}
      onResize={({ columnsPerPage }) => setUnitColumns(columnsPerPage)}
      showArrows={false}
      renderPagination={paginationProps => <CarouselPagination carouselRef={ref} disableAtEnd {...paginationProps} />}
    >
      {getUnitPages(units, unitColumns).map((page, index) => (
        <UnitsContainer key={index} columns={unitColumns} rows={3}>
          {page.map(unit => (
            <UnitCard data-qa-hook="unitCard" key={unit.id} onClick={() => redirectToSettings(unit)}>
              <h6>{unit.name}</h6>
              <InlineList>
                <li>{format(parseISO(unit.firstUseDate), 'MMMM d, yyyy')}</li>
              </InlineList>
            </UnitCard>
          ))}
        </UnitsContainer>
      ))}
    </StyledCarousel>
  );
}

UnitCarousel.propTypes = {
  units: PropTypes.array,
};
