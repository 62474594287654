import React from 'react';
import { createGlobalStyle } from 'styled-components';
import {
  IconSectionContainer,
  NoteSectionContainer,
  ScriptSection,
  SectionBody,
  SectionContent,
  SectionMedia,
  SegmentContainer,
  SegmentHeaderContainer,
  SegmentHeaderContentContainer,
  SegmentLeaderContainer,
  SuppliesSection,
  TimelineLabelContainer,
  TipSectionContainer,
} from '../../components/SessionTimelineContentElements';
import { InfoBanner } from '../../components/AlertBanners';
import { Container, FixedHeaderContainer } from '../../components/Layout';
import {
  SessionLayout,
  SessionAgenda,
  SessionTimelinePreferences,
  SessionTimelineTabs,
  SessionMenu,
  SessionTimelineHeader,
  SessionTimelineContainer,
  DurationIconContainer,
} from '../../components/SessionTimelineElements';

const SessionTimelinePrintStyles = createGlobalStyle`
  :root {
    --session-timeline__section-media-width: 30%;
    --session-timeline__section-media-width-mobile: 5%;
    --session-timeline__vertical-spacer: 32px;
    --session-timeline__print-default-color: #518fc5;
    --session-timeline__print-option-color: ${props =>
      props.options?.color || '--session-timeline__print-default-color'};
  }

  .full-scheduled-date,
  .session-timeline__title,
  .segment-content-text > h3 > span,
  .segment-content-text > p > i {
    display: none;
  }
  
  .bible-study-session-timeline-preview__banner-spacer {
    padding-top: 60px;
  }

  @media print {
    :root {
      --session-timeline__section-media-width: 0%;
      --session-timeline__section-media-width-mobile: 0%;
      --session-timeline__vertical-spacer: 0;
    }

    .session-agenda__list {
      gap: 0 !important;
    }

    .session-timeline-container {
      padding-top: 0 !important;
      margin-top: 0 !important;
    }

    ${SegmentContainer} p {
      font-size: 14px;
      line-height: 1.2 !important;
    }

    .session-info {
      margin-bottom: 0 !important;
    }

    * {
      overflow: visible !important;
    }

    .bible-study-session-timeline-preview__banner-spacer {
      padding-top: 0;
    }

    .timeline-highlight {
      border-left-width: 0 !important;
    }

    .session-timeline__title,
    ${SessionLayout} {
      max-width: 1170px;
      margin: 0 auto;
    }

    .session-timeline__title {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      margin: 8px auto 24px auto;

      img {
        width: 65px;
        height: 60px;
      }

      h1, h5 {
        margin: 0;
      }

      h5 {
        font-size: 20px;
        color: var(--session-timeline__print-option-color);
      }
    }

    #session-timeline-container {
      .session-info {
        display: flex;
        padding: 10px 0;
        border-top: 4px solid var(--session-timeline__print-option-color);
        border-bottom: 1px solid var(--session-timeline__print-option-color);
        gap: 18px;

        .session-image {
          width: 180px;
          height: auto;
          margin-top: 0;
        }

        .session-image + div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-content: flex-start;
          padding: 0;

          > div {
            margin: 0;
          }

          .session-name {
            margin-left: 0;
          }

          .available-through-date {
            display: none;
          }

          .full-scheduled-date {
            display: initial;
          }

          h3, h4, p {
            margin: 0;
            padding: 0;
          }
        }
      }

      ${SessionAgenda} {
        display: block;
        page-break-after: auto;

        .session-agenda__list-item__transition i {
          color: var(--session-timeline__print-default-color);
        }
      }

      ${SessionTimelineContainer} {
        margin: 0;
      }
      
      .group-transition,
      ${SectionContent},
      ${SegmentContainer},
      ${SegmentHeaderContainer} {
        page-break-inside: auto;
        break-inside: auto;
      }

      ${IconSectionContainer} h5 {
        background: #fff !important;
      }

      ${Container},
      ${SectionBody},
      ${SegmentHeaderContentContainer} {
        padding: 0 !important;
      }

      ${SegmentHeaderContainer} {
        border-top: 4px solid var(--session-timeline__print-option-color);
        border-bottom: none;
        margin-bottom: 32px;
        padding-top: 12px;
      }

      ${DurationIconContainer} {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        p {
          color: #2A3541;
          font-size: 14px;

          i, span {
            font-size: 14px;
          }

          i {
            margin-right: 4px;
          }
          
          span {
            font-weight: 400;
          }

          &:first-child {
            justify-content: center;
            align-items: center;
            margin-right: 4px;
          }

          &:last-child {
            margin-top: 0;
          }
        }
      }

      .segment-content-text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p {
          margin-top: 0;
        }

        > h3 > span {
          display: initial;
        }

        > p > i {
          display: initial;
          color: #518fc5;
          font-size: 18px;
          margin-right: 8px;
        }
      }

      ${ScriptSection},
      ${TipSectionContainer} {
        background: none;
        box-shadow: none;
        margin-bottom: 0 !important;
      }

      ${SuppliesSection} {
        li {
          padding: 4px;
          min-height: auto;

          i {
            display: none;
          }
        }
      }

      ${NoteSectionContainer} {
        &:before {
          color: #5190c4;
        }

        h5, p {
          color: #2A3541;
        }
      }

    }

    body,
    #session-timeline-container,
    ${FixedHeaderContainer},
    ${SessionLayout}, 
    ${SessionLayout} > main {
      display: block;
      height: auto;
      width: auto;
      overflow: auto;
      background: none;
      border: none;
    }


    .bible-study-banner,
    .session-badge,
    .calendar-date-icon,
    .group-transition,
    .session-header,
    ${SectionContent} > div:first-child,
    ${SessionTimelinePreferences},
    ${TimelineLabelContainer},
    ${SegmentLeaderContainer},
    ${SessionTimelineHeader},
    ${SessionTimelineTabs},
    ${SessionMenu},
    ${SectionMedia},
    ${InfoBanner} {
      display: none !important;
    }
  }
`;

SessionTimelinePrintStyles.propTypes = {};

export default SessionTimelinePrintStyles;
