import React from 'react';
import styled from 'styled-components';
import { Grid, Breakpoints, Color, Type } from '../StyleGuide';

type Props = {
  align?: 'center' | 'normal';
  hoverStyles?: string;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const ListItemFlexRow = styled.li<Props>`
  display: flex;
  flex-direction: column;
  padding: ${Grid._4} 0;
  color: ${Type.Color.medium};
  list-style: none;
  transition: background 0.15s ease-in-out;
  cursor: ${props => (props.onClick ? 'pointer' : 'normal')};
  &[disabled] {
    opacity: 0.4;
  }
  ${props =>
    props.onClick &&
    `
    &:not([disabled]):hover {
      background: ${Color.Gray._05};
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        text-decoration: underline;
      }
      ${props.hoverStyles}
    }
  `}
  + * {
    border-top: 1px solid ${Color.Gray._20};
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
    align-items: ${props => props.align ?? 'center'};
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    font-size: ${Type.Scale._4};
    font-weight: ${Type.Weight.semibold};
    color: ${Type.Color.dark};
  }
`;
