import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Breakpoints, Type } from '../StyleGuide';
import styled from 'styled-components';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { Container, FixedHeaderContainer, FixedHeaderContent } from '../components/Layout';
import { RoundedIconButton } from '../components/Buttons';
import SessionHeader from './SessionHeader';
import MaterialMediaPlayer from './MaterialMediaPlayer';
import MaterialPDFViewer from './MaterialPDFViewer';
import curriculumService from '../services/curriculumService';
import ActionableTitleBar from '../components/ActionableTitleBar';
import { useUser } from '../authentication';
import useCurriculum from '../hooks/useCurriculum';

const MaterialRichText = styled.div`
  padding: ${Grid._8} 0;
  font-size: ${Type.Scale._3};
  font-weight: ${Type.Weight.normal};
  color: ${Type.Color.dark};
  line-height: ${Type.Leading.taller};
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._8} ${Grid._10};
    font-size: ${Type.Scale._4};
  }
`;

export default function BibleStudySessionMaterial() {
  const user = useUser();
  const { brand, ageCategory } = useCurriculum();
  const { sessionId, materialId } = useParams();
  const [data, setData] = useState({ isLoading: true });

  useEffect(() => {
    const getSession = curriculumService
      .getDistributedCurriculum()
      .then(curriculums =>
        curriculumService.getSessionFromCurriculums(curriculums, sessionId, brand.code, ageCategory)
      );
    const getMaterial = curriculumService.getSessionMaterial(sessionId, materialId);
    Promise.all([getSession, getMaterial]).then(responses => {
      setData({ isLoading: false, session: responses[0], material: responses[1] });
    });
  }, []);

  return (
    <FixedHeaderContainer fullViewport>
      <div>
        <SessionHeader
          brandName={`${brand.name}: ${ageCategory}`}
          sessionName={data.session ? data.session.name : 'Loading...'}
          isLoading={data.isLoading}
        />
      </div>
      <FixedHeaderContent>
        {data.isLoading ? (
          <>
            <ActionableTitleBar title="" />
            <LoadingState />
          </>
        ) : data.isError ? (
          <Container>
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          </Container>
        ) : (
          <FixedHeaderContainer scrollSm>
            <ActionableTitleBar
              title={data.material.name}
              subtitle={
                data.material.materialType === 'download' && <span>Use the Download button to print this material</span>
              }
              leftButton={
                <RoundedIconButton
                  onClick={() =>
                    (window.location = `#/curriculum/${brand.code}/${ageCategory}/session/${sessionId}/issue/${data.session.issueId}`)
                  }
                >
                  <i className="fas fa-arrow-left fa-fw"></i>
                  Back
                </RoundedIconButton>
              }
              rightButton={
                data.material.materialType === 'download' && (
                  <RoundedIconButton
                    onClick={() => window.open(`${getApiUrl()}/curriculum-storage/files/${data.material.mediaId}`)}
                  >
                    <i className="fas fa-download fa-fw"></i>
                    Download
                  </RoundedIconButton>
                )
              }
            />
            <FixedHeaderContent>
              <Container>
                {data.material.materialType === 'text' && (
                  <MaterialRichText dangerouslySetInnerHTML={{ __html: data.material.description }}></MaterialRichText>
                )}
                {(data.material.materialType === 'video' || data.material.materialType === 'audio') && (
                  <MaterialMediaPlayer userId={user.userId} videoId={data.material.mediaId} />
                )}
                {data.material.materialType === 'download' && <MaterialPDFViewer mediaId={data.material.mediaId} />}
              </Container>
            </FixedHeaderContent>
          </FixedHeaderContainer>
        )}
      </FixedHeaderContent>
    </FixedHeaderContainer>
  );
}

BibleStudySessionMaterial.propTypes = {};
