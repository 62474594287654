import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorMessage from '../../components/ErrorMessage';
import { useUser } from '../../authentication';
import emailService from '../../services/emailService';
import { handleError } from '../../utils/apiUtils';

const EmailPreviewWrapper = styled.div`
  > table {
    margin: 0 !important;
    width: 100%;
  }
`;

const BODY_KEY = '{bodyText}';
const BUTTON_KEY = '{buttonText}';

type Props = {
  bodyContent: string;
  buttonText: string;
};

type Status = {
  isPending: boolean;
  isError: boolean;
  errors: Error[];
};

export const PreviewEmailTemplate = ({ bodyContent = '', buttonText = '' }: Props) => {
  const user = useUser();
  const [status, setStatus] = useState({ isPending: true, isError: false, errors: [] } as Status);
  const [template, setTemplate] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    emailService
      .getEmailPreview(user?.lastSelectedAccount, 'blank', BODY_KEY, BUTTON_KEY)
      .then(response => {
        const html = response?.emails[0]?.content?.html || '';
        setTemplate(html);
        setStatus({ isPending: false, isError: false, errors: [] });
      })
      .catch((error: Error) => {
        console.error(error);
        setStatus({ isPending: false, isError: true, errors: [error] });
      });
  }, []);

  useEffect(() => {
    const replaceBody = (html: string) => html.replace(BODY_KEY, bodyContent);
    const replaceButton = (html: string) => html.replace(BUTTON_KEY, buttonText);

    if (!buttonText) {
      try {
        const el = document.createElement('template');
        el.innerHTML = template;
        el.content.querySelector('#button')?.remove();
        setEmail(replaceBody(el.innerHTML));
      } catch (e) {
        handleError(e as Error);
      }
    } else {
      setEmail(replaceButton(replaceBody(template)));
    }
  }, [template, bodyContent, buttonText]);

  return status.isPending ? (
    <LoadingIndicator />
  ) : status.isError ? (
    <ErrorMessage>A problem occurred showing the email preview</ErrorMessage>
  ) : (
    <EmailPreviewWrapper dangerouslySetInnerHTML={{ __html: email }} />
  );
};
