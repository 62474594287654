angular.module('lwNamb').config(['AnalyticsProvider', function(AnalyticsProvider) {
  AnalyticsProvider.setAccount('G-82CFJDJ237');
  // AnalyticsProvider.logAllCalls(true);
  // Track all routes (default is true).
  AnalyticsProvider.trackPages(true);

  // Track all URL query params (default is false).
  AnalyticsProvider.trackUrlParams(true);

  // Ignore first page view (default is false).
  // Helpful when using hashes and whenever your bounce rate looks obscenely low.
  AnalyticsProvider.ignoreFirstPageLoad(true);

  // Change the default page event name.
  // Helpful when using ui-router, which fires $stateChangeSuccess instead of $routeChangeSuccess.
  AnalyticsProvider.setPageEvent('$routeChangeSuccess');

  // Activate reading custom tracking urls from $routeProvider config (default is false)
  // This is more flexible than using RegExp and easier to maintain for multiple parameters.
  // It also reduces tracked pages to routes (only those with a templateUrl) defined in the
  // $routeProvider and therefore reduces bounce rate created by redirects.
  // NOTE: The following option requires the ngRoute module
  AnalyticsProvider.readFromRoute(true);
}]);

if (
  window.location.host.indexOf('local') === -1 &&
  window.location.host.indexOf('.dev.') === -1 &&
  window.location.host.indexOf('.stage.') === -1
) {
  angular.module('lwNamb').run(['Analytics', '$rootScope', function(Analytics, $rootScope) {
    $rootScope.ga = Analytics;
  }]);
}
