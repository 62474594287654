angular.module('lwNamb').directive('addTo', [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'partials/directives/add-to-directive.html',
      scope: {
        userPermissions: '=',
        viewPermission: '@',
        headerName: '@',
        backPath: '=',
        filter: '=',
        rolesDropdown: '=',
        groups: '=',
        customFields: '=',
        view: '@',
        actionText: '@',
        actionTextWaiting: '@',
        action: '=',
        spinner: '=',
        members: '=',
        selectedItems: '=',
        loading: '=',
        clearInput: '&',
        type: '@',
        maxAdd: '=',
      },
      replace: true,
      controller: [
        '$scope',
        'windowService',
        function($scope, windowService) {
          $scope.redirectTo = function(path) {
            windowService.redirectHash(path);
          };
        },
      ],
    };
  },
]);
