import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Color, Grid, Type, Breakpoints } from '../StyleGuide';
import LoadingState from '../components/LoadingState';
import { PlaceholderProfileImage } from '../components/ProfileImageCrop';
import EmptyState from '../components/EmptyState';
import ProfileImage from '../groups/ProfileImage';
import windowService from '../services/windowService';
import WhiteLabel from '../filters/WhiteLabel';
import capitalizeFilter from '../filters/capitalize-filter';

const formatDate = date => format(new Date(date), 'MMMM d, yyyy');

const ProfileImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  min-width: 180px;
  text-align: center;

  i,
  img {
    width: 50px;
    height: 50px;
    font-size: 48px;
    color: ${Color.Gray._50};
  }
`;

const AssignmentSelectContainer = styled.td`
  cursor: pointer;
`;

const AssignmentInfoContainer = styled.td`
  cursor: pointer;
  display: flex;
  gap: ${Grid._5};
  flex-direction: column;
  align-items: start;
  padding: ${Grid._4} ${Grid._3} !important;

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
  }
  p.assignee-name {
    margin: ${Grid._3} 0 0 0;
  }
  h4.assignment-name {
    margin: 0 0 ${Grid._2} 0;
  }
  span.progress-label {
    font-weight: ${Type.Weight.semibold};
  }
`;

const DetailsList = styled.ul`
  padding: 0;
  margin: 0 0 ${Grid._4} 0;
  li {
    display: inline-block;
    color: ${Color.Gray._70};
    &:not(:last-of-type)::after {
      content: '•';
      margin: 0 ${Grid._2};
    }
  }
`;

const AssignmentProfileImage = ({ assignment }) => {
  const profileImageUrl = assignment.assignee.profileImageUrl;

  return (
    <ProfileImageWrapper>
      {profileImageUrl ? (
        <ProfileImage src={profileImageUrl} width="150" />
      ) : (
        <PlaceholderProfileImage>
          <i className="fas fa-user-circle"></i>
        </PlaceholderProfileImage>
      )}
      <p className="assignee-name" data-qa-hook="manageAssignmentsResultName">
        {assignment.assignee?.name}
      </p>
    </ProfileImageWrapper>
  );
};

AssignmentProfileImage.propTypes = {
  assignment: PropTypes.object.isRequired,
};

const AssignmentInfo = ({ assignment }) => {
  const courseOrPathwayLabel = capitalizeFilter(
    assignment.assignable._type === 'AssignedTaskList' ? WhiteLabel.labels.course : WhiteLabel.labels.pathway
  );

  const isShared = assignment.shared;
  const isSelfAssigned = assignment.assignable && assignment.assignable.selfAssigned;
  const sharedOn = assignment.sharedOn && formatDate(assignment.sharedOn);
  const assignedOn = assignment.assignedOn && formatDate(assignment.assignedOn);

  return (
    <AssignmentInfoContainer onClick={() => windowService.redirectTo(`/manage-assignments/${assignment._id}`)}>
      <AssignmentProfileImage assignment={assignment} />
      <div>
        <h4 className="assignment-name" data-qa-hook="manageAssignmentsResultTraining">
          {assignment.assignable.name}
        </h4>
        <DetailsList>
          <li>{courseOrPathwayLabel}</li>
          <li data-qa-hook="manageAssignmentsResultAssignedBy">
            {isShared && sharedOn
              ? `Shared on ${sharedOn}`
              : assignedOn && (
                  <>
                    {isSelfAssigned ? 'Self-assigned' : 'Assigned'}{' '}
                    {assignment.assignor?.name ? `by ${assignment.assignor.name}` : ''}{' '}
                    {assignedOn ? `on ${assignedOn}` : ''}
                  </>
                )}
          </li>
        </DetailsList>
        <>
          <span className="progress-label">Progress:</span>{' '}
          {assignment.assignable.progress._type === 'NotStarted' && (
            <span data-qa-hook="manageAssignmentsResultStatus">
              Not Started
              <br />
            </span>
          )}
          {assignment.assignable.progress._type === 'Started' && (
            <span>
              {assignment.assignable._type === 'AssignedTaskList' && (
                <span data-qa-hook="manageAssignmentsResultStatus">{assignment.assignable.progress.percent}%</span>
              )}
              {assignment.assignable._type === 'Workflow' && (
                <span data-qa-hook="manageAssignmentsResultStatus">In Progress</span>
              )}
            </span>
          )}
          {assignment.assignable.progress._type === 'Finished' && assignment.assignable.completedOn && (
            <span data-qa-hook="manageAssignmentsResultStatus">
              Completed on {formatDate(assignment.assignable.completedOn)}
              <br />
            </span>
          )}
        </>
      </div>
    </AssignmentInfoContainer>
  );
};

AssignmentInfo.propTypes = {
  assignment: PropTypes.object.isRequired,
};

const AssignmentSelectArea = ({ assignment, handleSelect }) => {
  return (
    <AssignmentSelectContainer
      className="select-wrapper toggle partition"
      onClick={() => handleSelect(assignment, !assignment.isSelected)}
    >
      <span>
        {assignment.isSelected ? (
          <span>
            <i data-qa-hook="manageAssignmentsResultSelected" className="icon ion-ios-checkmark"></i>
          </span>
        ) : (
          <span>
            <i data-qa-hook="manageAssignmentsResultDeselected" className="icon ion-ios-circle-outline"></i>
          </span>
        )}
      </span>
    </AssignmentSelectContainer>
  );
};

AssignmentSelectArea.propTypes = {
  assignment: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default function AssignmentResults({ allAssignments, filteredAssignments, isLoadingAssignments, handleSelect }) {
  return (
    <tbody data-qa-hook="manageAssignmentsResultList">
      {isLoadingAssignments ? (
        <tr className="no-table-hover">
          <td colSpan="2">
            <LoadingState />
          </td>
        </tr>
      ) : !allAssignments.length ? (
        <tr className="no-table-hover">
          <td colSpan="2">
            <EmptyState icon="fa-search" title={`There are no assignments in your ${WhiteLabel.labels.org}`} />
          </td>
        </tr>
      ) : !filteredAssignments.length ? (
        <tr className="no-table-hover">
          <td colSpan="2">
            <EmptyState
              icon="fa-search"
              title="Your search does not have any matches"
              description="Please try another search."
            />
          </td>
        </tr>
      ) : (
        filteredAssignments.map(assignment => (
          <tr key={assignment.id} data-qa-hook="manageAssignmentsResult">
            <AssignmentInfo assignment={assignment} />
            <AssignmentSelectArea assignment={assignment} handleSelect={handleSelect} />
          </tr>
        ))
      )}
    </tbody>
  );
}

AssignmentResults.propTypes = {
  allAssignments: PropTypes.array.isRequired,
  filteredAssignments: PropTypes.array.isRequired,
  isLoadingAssignments: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
};
