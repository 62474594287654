import React, { useState, useContext } from 'react';
import { Container, FullWidthCard } from '../components/Layout';
import { HeaderUnderlined } from '../components/TrainingElements';
import Tabs from './Tabs';
import CourseSteps from './CourseSteps';
import TrainingOverview from './TrainingOverview';
import TrainingContext from '../contexts/TrainingContext';

const CourseContents = ({ localStorage }) => {
  const training = useContext(TrainingContext);
  const [activeTab, setActiveTab] = useState('contents');

  return (
    <>
      <div className="hidden-sm hidden-xs">
        <Container className="grid-container grid-col-4 grid-sm-col-8 grid-gap-24">
          <div className="grid-col-span-4 grid-sm-col-span-5">
            <HeaderUnderlined className="header-underlined">Contents</HeaderUnderlined>
          </div>
          <div className="grid-col-span-4 grid-sm-col-span-3">
            <HeaderUnderlined className="header-underlined">Overview</HeaderUnderlined>
          </div>
        </Container>
        <FullWidthCard style={{ padding: '24px 0' }}>
          <Container className="grid-container grid-col-4 grid-sm-col-8 grid-gap-24">
            <div className="grid-col-span-4 grid-sm-col-span-5">
              <CourseSteps course={training} localStorage={localStorage} />
            </div>
            <div className="grid-col-span-4 grid-sm-col-span-3">
              <TrainingOverview
                description={training.description}
                truncateLength={1000}
                categories={training.categories}
              />
            </div>
          </Container>
        </FullWidthCard>
      </div>
      <div className="hidden-md hidden-lg">
        <Container>
          <Tabs fontSize="18px">
            <li className={activeTab === 'contents' ? 'active-tab' : ''}>
              <button data-qa-hook="contentTab" onClick={() => setActiveTab('contents')}>
                Contents
              </button>
            </li>
            <li className={activeTab === 'overview' ? 'active-tab' : ''}>
              <button data-qa-hook="overviewTab" onClick={() => setActiveTab('overview')}>
                Overview
              </button>
            </li>
          </Tabs>
        </Container>
        <FullWidthCard style={{ padding: '24px 0' }}>
          <Container>
            {activeTab === 'contents' && <CourseSteps course={training} localStorage={localStorage} />}
            {activeTab === 'overview' && (
              <TrainingOverview
                description={training.description}
                truncateLength={1000}
                categories={training.categories}
              />
            )}{' '}
          </Container>
        </FullWidthCard>
      </div>
    </>
  );
};

export default CourseContents;
