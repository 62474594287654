angular.module('lwNamb').directive('btBuildTabs', [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'partials/directives/build-tabs.html',
      scope: {
        activeTab: '@',
      },
      replace: true,
      controller: [
        '$scope',
        '$location',
        'buildService',
        'userService',
        function($scope, $location, buildService, userService) {
          var defaultTabs = [
              {
                title: 'Pathways',
                value: 1,
                qa: 'Pathways',
                onClick: function() {
                  buildService.ctxRedirectTo('/pathways');
                },
              },
              {
                title: 'Courses',
                value: 2,
                qa: 'TaskLists',
                onClick: function() {
                  buildService.ctxRedirectTo('/courses');
                },
              },
              {
                title: 'Content',
                qa: 'Content',
                value: 3,
                onClick: function() {
                  buildService.ctxRedirectTo('/content');
                },
              },
            ],
            curriculumTab = {
              title: 'Curriculum',
              value: 5,
              qa: 'Curriculum',
              onClick: function() {
                $location.url('/curriculum');
              },
            };

          $scope.radio = {
            active: parseInt($scope.activeTab, 10),
          };

          userService.user().then(function(user) {
            $scope.tabs = defaultTabs
              .concat(user.permissions.indexOf('Build Curriculum View') > -1 ? [curriculumTab] : []);
          });
        },
      ],
    };
  },
]);
