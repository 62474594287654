import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useSearchQuery from '../hooks/useSearchQuery';

import { Container } from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import AssessmentMultipleChoiceView from './AssessmentMultipleChoiceView';
import AssessmentShortAnswerView from './AssessmentShortAnswerView';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';

import assessmentService from '../services/assessmentService';
import { useUser } from '../authentication';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';

const AssessmentQuestionView = () => {
  const { assessmentId, questionId } = useParams();
  const [question, setQuestion] = useState([]);
  const [assessment, setAssessment] = useState({});
  const [status, setStatus] = useState({ isLoading: true, isError: false, isSubmitting: false });
  const user = useUser();
  const { manageTrainingRoutePrefix, createManageTrainingCrumb } = useManageTrainingRoute();
  const { searchQuery: questionType } = useSearchQuery('questionType');

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    createManageTrainingCrumb('content'),
    { name: 'Assessment Builder', route: `#${manageTrainingRoutePrefix}/assessments/${assessmentId}` },
    { name: 'Question View' },
  ];

  useEffect(() => {
    assessmentService
      .getAssessment(assessmentId, user.userId)
      .then(res => {
        setAssessment(res);
        setQuestion(res.questions.filter(q => q.id.id === questionId));
        setStatus({ ...status, isLoading: false, isError: false });
      })
      .catch(error => {
        console.error(error);
        setStatus({ ...status, isLoading: false, isError: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container data-qa-hook="questionBuilderView">
        {status.isLoading ? (
          <LoadingState />
        ) : status.isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : (
          <>
            {assessment.published && (
              <HelpAndInfoBox
                title={'This Question Has Been Published'}
                description={
                  'We have locked this question to prevent it from breaking for anyone already using this assessment. You may, however, edit text anywhere in the question.'
                }
              />
            )}
            {questionType === 'radio' && (
              <AssessmentMultipleChoiceView
                assessmentId={assessmentId}
                question={question[0]}
                isPublished={assessment.published}
                questionType={questionType}
                ownerId={assessment.ownerId}
              />
            )}
            {questionType === 'textbox' && (
              <AssessmentShortAnswerView
                assessmentId={assessmentId}
                questionType={questionType}
                question={question[0]}
                ownerId={assessment.ownerId}
              />
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default AssessmentQuestionView;
