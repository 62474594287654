angular.module('lwNamb').controller(
  'IssueMaterialViewerCtrl',

  [
    '$scope',
    '$routeParams',
    '$log',
    '$window',
    'windowService',
    'curriculumService',
    function ($scope, $routeParams, $log, $window, windowService, curriculumService) {
      function init() {
        $scope.loading = true;

        $scope.isManageCurriculum = $window.location.hash.indexOf('manage-material-viewer') > -1;
        $scope.isBuildCurriculum = $window.location.hash.indexOf('build-material-viewer') > -1;

        loadData();
      }

      function loadData() {
        curriculumService
          .getSessionMaterialAndSeries(
            $routeParams.curriculumId,
            $routeParams.issueId,
            $routeParams.sessionId,
            $routeParams.materialId
          )
          .then(
            function (response) {
              $scope.session = response.session;
              $scope.material = response.material;
              $scope.collections = response.material.seriesIds
                .map(function (id) {
                  var series = response.series.find(function (series) {
                    return series.seriesId === id;
                  });
                  return series ? series.name : '';
                })
                .join(', ');
            },
            function (reason) {
              $log.error('No material found with id: ' + $routeParams.materialId + ' because of: ' + reason);
              $scope.isError = true;
            }
          )
          .finally(function () {
            $scope.loading = false;
          });
      }

      $scope.redirectToPrevious = function () {
        var sessionViewer;

        if ($scope.isManageCurriculum) {
          sessionViewer = '/manage-session-viewer/';
        } else {
          sessionViewer = '/session-viewer/';
        }

        if ($scope.isBuildCurriculum) {
          windowService.back();
        } else {
          windowService.redirectHash(
            '/curriculum/' +
              $routeParams.curriculumId +
              '/issue/' +
              $routeParams.issueId +
              sessionViewer +
              $routeParams.sessionId
          );
        }
      };

      init();
    },
  ]
);
