angular.module('lwNamb').controller('onboardingCtrl', [
  '$scope',
  'customerService',
  'orgUserService',
  'windowService',
  '$window',
  '$routeParams',
  'taskListInstanceService',
  'trainingService',
  'alertService',
  'orgService',
  'analyticsService',
  'uuid4',
  '$localStorage',
  '$log',
  'activeCampaignService',
  '$timeout',
  function(
    $scope,
    customerService,
    orgUserService,
    windowService,
    $window,
    $routeParams,
    taskListInstanceService,
    trainingService,
    alertService,
    orgService,
    analyticsService,
    uuid4,
    $localStorage,
    $log,
    activeCampaignService,
    $timeout
  ) {
    var init = function() {
      $scope.isAuthenticating = false;
      $scope.isLoading = true;
      $scope.isWaiting = false;
      $scope.isLoggedIn = Boolean($localStorage.user);
      $scope.loggedInEmail = '';
      $scope.onboardingName = $routeParams.onboardingName.toLowerCase();
      $scope.host = $window.location.host;
      var training = ($scope.training = selectTraining($scope.onboardingName));
      var hasRedirectURL;
      var timer;

      var isRedeeming = $routeParams.redeem;
      var isSettingUpChurch = $routeParams.setupChurch;

      if ($scope.isLoggedIn) {
        $scope.loggedInEmail = $scope.user.logInEmail;
        hasRedirectURL = $scope.user.redirectURL && $scope.user.redirectURL.indexOf('#/onboarding/') > -1;
      }

      if (isRedeeming && $scope.isLoggedIn) {
        redeem(training);
      } else if (isSettingUpChurch && $scope.isLoggedIn && hasRedirectURL) {
        $scope.isWaiting = true;
        $scope.isLoading = false;
        timer = $timeout(function() {
          setupChurch(isSettingUpChurch, training);
        }, 5000);
      } else if (isSettingUpChurch && $scope.isLoggedIn && !hasRedirectURL) {
        windowService.redirectHash('/dashboard');
      } else {
        $scope.isLoading = false;
      }
    };

    init();

    function _encodeEmail(preEncodedEmail) {
      return preEncodedEmail.replace(/\+/gi, '%2B');
    }

    $scope.handleEmailSubmission = function(preEncodedEmail, userType, url, callback) {
      preEncodedEmail = preEncodedEmail.toLowerCase();
      var email = _encodeEmail(preEncodedEmail);
      $scope.isAuthenticating = true;

      if (userType === 'Pastor') {
        orgUserService.getMemberByEmail(email).then(function(response) {
          var filteredOrgs = [],
            isGridUser = response.length;
          if (isGridUser) {
            filteredOrgs = response
              .map(function(res) {
                return {
                  isSiteWide: getSiteWideOrgIds(res.org.id).some(function(checkId) {
                    return res.org.id === checkId;
                  }),
                };
              })
              .filter(function(org) {
                return !org.isSiteWide;
              });
          }
          if (isGridUser && filteredOrgs.length) {
            var redirectURL = url ? url : $scope.host + '/#/select-org-and-assign/' + $scope.training.trainingId;
            customerService.findUserByEmail(email).then(function(response) {
              var cmd = response.id ? 'login' : 'register';
              redirectToLifeWay(cmd, redirectURL, preEncodedEmail);
            });
          } else {
            $scope.isAuthenticating = false;
            callback();
          }
        });
      } else {
        var redirectURL = url ? url : $scope.host + '/#/onboarding/' + $scope.onboardingName + '?redeem';
        customerService.findUserByEmail(email).then(function(response) {
          var cmd = response.id ? 'login' : 'register';
          redirectToLifeWay(cmd, redirectURL, preEncodedEmail);
        });
      }
    };

    $scope.handleChurchSetupRequest = function(preEncodedEmail, churchName) {
      var email = _encodeEmail(preEncodedEmail);
      var redirectURL = $scope.host + '/#/onboarding/' + $scope.onboardingName + '?setupChurch=' + churchName;
      customerService.findUserByEmail(email).then(function(response) {
        var cmd = response.id ? 'login' : 'register';
        redirectToLifeWay(cmd, redirectURL, preEncodedEmail);
      });
    };

    $scope.handleLifeWayRedirection = function(preEncodedEmail) {
      var email = _encodeEmail(preEncodedEmail);
      customerService.findUserByEmail(email).then(function(response) {
        var cmd = response.id ? 'login' : 'register';
        redirectToLifeWay(cmd, '', preEncodedEmail);
      });
    };

    function redirectToLifeWay(cmd, redirectURL, email) {
      $scope.$emit('REDIRECT_TO_LIFEWAY', cmd, redirectURL, email);
    }

    function selectTraining(onboardingName) {
      if (onboardingName === 'churchcares') {
        return {
          trainingId: 'ac19f483-b98a-4d91-a4bf-47e7bfb57e69',
          trainingType: 'Tasklist',
        };
      }
    }

    function setupChurch(churchName, training) {
      var orgId = uuid4.generateId();
      orgService
        .createOrgAccount(
          orgId.id,
          $scope.user.lastSelectedAccount,
          churchName,
          $scope.user.userId,
          analyticsService.trackFeature
        )
        .then(function() {
          tagCCAdmin();
          analyticsService.orgCreated(
            orgId.id,
            $scope.name,
            $scope.user.userId,
            $scope.user.firstName,
            $scope.user.lastName,
            $scope.user.logInEmail
          );
          windowService.redirectHash('/assign-training/' + training.trainingId);
        });
    }

    function redeem(training) {
      $scope.orgId = $scope.user.lastSelectedAccount;

      taskListInstanceService.getAssignedAndCompleted($scope.user.userId).then(function(assignments) {
        var hasTrainingAlready = assignments.some(function(assignment) {
          return assignment.originalTaskListId === training.trainingId;
        });

        if (hasTrainingAlready) {
          windowService.redirectHash('/dashboard-training');
        } else {
          if ($scope.orgId === "") {
            trainingService
              .selfAssign($scope.user.userId, training)
              .then(onSuccess);
          } else {
            trainingService
              .assign($scope.orgId, $scope.user.userId, [$scope.user.userId], training, true)
              .then(onSuccess);
          }
        }

        function onSuccess() {
          windowService.redirectHash('/dashboard-training');
          alertService.show({
            type: 'success success-with-content',
            title: 'Training Assigned',
            duration: 5,
          });
        }

      });
    }

    function tagCCAdmin() {
      if ($scope.onboardingName === 'churchcares') {
        activeCampaignService.event('CC_Admin', $scope.user.logInEmail);
      }
    }
  },
]);
