import React from 'react';
import styled from 'styled-components';
import { Grid, Type, Breakpoints, Color, Border, Shadow } from '../StyleGuide';
import { Container } from '../components/Layout';

export const ProfileCardContainer = styled(Container)`
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${Grid._4};
  width: 100%;
  text-align: center;

  & h6 {
    margin-bottom: 0;
  }
  & p {
    color: ${Type.Color.placeholder};
    font-size: ${Type.Scale._1};
    margin-bottom: 0;
  }
  & a {
    color: ${Type.Color.placeholder};
    font-size: ${Type.Scale._1};
    margin-bottom: 0;
    margin-top: -${Grid._4};
  }

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    align-self: start;
    min-width: 280px;
    background: ${Color.white};
    border: 1px solid ${Color.Gray._10};
    border-radius: ${Border.radius};
    box-shadow: ${Shadow.light};
    padding: ${Grid._4} 0;
    flex: 1;
    align-items: center;

    & h6 {
      margin-bottom: ${Grid._3};
    }
  }
`;

export const ProfileCardSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 ${Grid._4};
  margin: ${Grid._3} 0;
  overflow-wrap: anywhere;
`;

export const ProfileCardDivider = styled.div`
  width: 100%;
  margin: ${Grid._3} auto;

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    border-top: 1px solid ${Color.Gray._10};
    margin: ${Grid._4} auto;
  }
`;
