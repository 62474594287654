angular.module('lwNamb').controller(
  'AssessmentResultsFilterCtrl',

  [
    '$scope',
    '$localStorage',
    'userService',
    'windowService',
    'assessResponseAggregatorService',
    '$routeParams',
    'pagingService',
    function (
      $scope,
      $localStorage,
      userService,
      windowService,
      assessResponseAggregatorService,
      $routeParams,
      pagingService
    ) {
      var init = function () {
        $scope.allAssessments = [];

        $scope.loading = true;
        $scope.selectedItems = [];

        $scope.assessmentId = $routeParams.assessmentId;
        $scope.rolesDropdown = [];
        $scope.groupsDropdown = [];
        var label = 'All Courses';
        $scope.tasklistsDropdown = [{ name: label, value: '' }];

        $scope.clearInput();
        userService.user().then(
          function (user) {
            $scope.user = user;
            loadData();
          },
          function () {
            $log.error('No user returned in AssessmentResultsFilterCtrl');
          }
        );
      };

      function pushDropdown(to, from) {
        from.forEach(function (item) {
          to.push({
            name: item,
            value: item,
          });
        });
      }

      function loadData() {
        assessResponseAggregatorService.getAllAssesseeData($scope.user.lastSelectedAccount, $scope.assessmentId).then(
          function (assessments) {
            $scope.loading = false;
            $scope.allAssessments = assessments.views;
            pushDropdown($scope.groupsDropdown, assessments.groupNames);
            pushDropdown($scope.rolesDropdown, assessments.roleNames);
            pushDropdown($scope.tasklistsDropdown, assessments.taskListNames);
            $scope.loading = false;
          },
          function (reason) {
            $scope.loading = false;
            $log.error(reason);
            alertService.show({
              title: 'Error! Something went wrong.',
              type: 'danger',
              show: true,
              dismissible: true,
              duration: 20,
            });
          }
        );
      }

      //KEEP
      $scope.clearInput = function () {
        $scope.filter = {
          search: '',
          role: [],
          status: '',
          group: [],
          taskList: $scope.tasklistsDropdown[0].value,
        };
      };

      $scope.viewResults = function () {
        $localStorage.allResults = false;
        $localStorage.assessmentResultResponseIds = [];
        $scope.selectedItems.forEach(function (item) {
          $localStorage.assessmentResultResponseIds.push(item.assessmentResponseId);
        });
        windowService.redirectHash('/assessment/results/' + $scope.assessmentId);
      };

      init();
    },
  ] //End Assessment Results Filter Ctrl
);
