import cacheService from '../../services/cacheService';
import submitCommand from '../../services/submitCommand';

type UploadResponse = {
  eventType: string;
  fileName: string;
  id: string;
};

export const uploadContent: (
  videoId: string,
  fileName: string,
  orgId: string,
  initiatingUserId: string
) => Promise<UploadResponse> = (videoId, fileName, orgId, initiatingUserId) => {
  const cmd = {
    id: videoId,
    orgAccountId: orgId,
    fileName: fileName,
    forTask: false,
    initiatingUserId: initiatingUserId,
  };
  cacheService.removeAllInPath(`/v1/organizations/${orgId}/content`);
  return submitCommand(videoId, cmd, 'CreateFileMetadata', 'FileMetadataCreated', 'FileMetadataCreatedError');
};
