import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import curriculumService from '../../services/curriculumService';
import { Grid, Breakpoints } from '../../StyleGuide';
import Breadcrumbs from '../../components/Breadcrumbs';
import useCurriculum from '../../hooks/useCurriculum';
import { PrimaryButton, TileButton } from '../../components/Buttons';
import BibleStudyBanner from '../BibleStudyBanner';
import LoadingState from '../../components/LoadingState';
import ErrorMessage from '../../components/ErrorMessage';
import { Container, PageTitle, InlineList } from '../../components/Layout';
import ManageCurriculumSessionListItem from './ManageCurriculumSessionListItem';
import useModal from '../../hooks/useModal';
import ManageCurriculumUnitModal from './ManageCurriculumUnitModal';
import windowService from '../../services/windowService';
import { handleError } from '../../utils/apiUtils';
import EmptyState from '../../components/EmptyState';
import { TextFilterInput } from '../../components/FormElements';
import limitFilter from '../../filters/limit-filter';

const PageContainer = styled(Container)`
  padding-bottom: ${Grid._10};
`;

const TitleHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${Grid._6} 0;

  ${TileButton} {
    margin: 0;
  }

  @media screen and (max-width: ${Breakpoints.screen_xs}) {
    flex-direction: column;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${Grid._6};
  margin-bottom: ${Grid._4};
  width: 100%;
  @media screen and (max-width: ${Breakpoints.screen_xs}) {
    gap: ${Grid._4};
    flex-direction: column-reverse;
  }

  & > div {
    width: 100%;
  }
`;

const AddSessionButton = styled(PrimaryButton)`
  width: 260px;
  @media screen and (max-width: ${Breakpoints.screen_xs}) {
    width: 100%;
  }
`;

const SessionListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const ManageCurriculumUnit = () => {
  const { curriculumId, unitId } = useParams();
  const { brand, ageCategory } = useCurriculum();
  const [crumbs, setCrumbs] = useState([
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Curriculum', route: '#/manage-curriculum' },
    {
      name: `${brand.name}: ${ageCategory}`,
      route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}`,
    },
  ]);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState({
    isLoading: true,
    isError: false,
    unit: {},
    sessions: [],
  });

  const { isLoading, isError, unit, sessions } = data;

  const unitNameCharLimit = 64;

  const setUnitName = name => {
    setData(prev => ({ ...prev, unit: { ...prev.unit, name } }));
    const copy = [...crumbs];
    copy.splice(copy.length - 1, 1, { name: limitFilter(name, unitNameCharLimit) });
    setCrumbs(copy);
  };

  const [modal, openModal] = useModal((type, payload, dismissModal) => {
    if (!type) return;
    return (
      <ManageCurriculumUnitModal
        curriculumId={curriculumId}
        handleDismiss={dismissModal}
        unit={unit}
        setUnitName={setUnitName}
      />
    );
  });

  useEffect(() => {
    Promise.all([
      curriculumService.getIndividualCurriculum(curriculumId),
      curriculumService.getIndividualUnit(unitId, curriculumId),
      curriculumService.getAllSessions(unitId),
    ])
      .then(([curriculum, unit, sessions]) => {
        const sessionMaterialsPromises = sessions.map(session =>
          curriculumService.getSessionMaterials(session.sessionId)
        );
        Promise.all(sessionMaterialsPromises).then(allSessionMaterials => {
          const sessionsWithMaterials = sessions.map((session, index) => {
            return { ...session, materials: allSessionMaterials[index] };
          });
          setData(prev => ({
            ...prev,
            curriculum,
            unit,
            sessions: sessionsWithMaterials,
            isLoading: false,
          }));
          setCrumbs([...crumbs, { name: `${unit.name}` }]);
        });
      })
      .catch(error => {
        handleError(error);
        setData(prev => ({ ...prev, isError: true, isLoading: false }));
        setCrumbs([...crumbs, { name: `New Unit` }]);
      });
  }, []);

  const handleAddSession = () => {
    windowService.redirectTo(`/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/unit/${unitId}/session`);
  };

  const filteredSessions = sessions.filter(session => {
    return session.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <>
      <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
      <BibleStudyBanner />
      {isLoading ? (
        <LoadingState />
      ) : isError ? (
        <ErrorMessage />
      ) : (
        <PageContainer>
          <TitleHeaderContainer>
            <PageTitle>
              <h1 data-qa-hook="unitHeader">{unit.name}</h1>
              <InlineList>
                <li>{format(parseISO(unit.firstUseDate), 'MMM d, yyyy')}</li>
                <li>{`${sessions.length} Session${sessions.length === 1 ? `` : 's'}`}</li>
              </InlineList>
            </PageTitle>
            <TileButton onClick={() => openModal('editUnit')} data-qa-hook="unitEdit">
              <i className="fas fa-solid fa-pen"></i>
              Edit Unit
            </TileButton>
          </TitleHeaderContainer>
          <SearchContainer>
            <AddSessionButton data-qa-hook="sessionAdd" type="button" onClick={() => handleAddSession()}>
              Add Session
            </AddSessionButton>
            <TextFilterInput
              id="sessionsFilter"
              placeholder="Search Sessions"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
              clearInput={() => setSearchQuery('')}
              disabled={!sessions.length}
            />
          </SearchContainer>
          {sessions.length > 0 ? (
            filteredSessions.length > 0 ? (
              <SessionListContainer>
                {filteredSessions.map((session, index) => (
                  <ManageCurriculumSessionListItem session={session} key={session.sessionId} index={index} />
                ))}
              </SessionListContainer>
            ) : (
              <EmptyState
                title="Your search does not have any matches"
                description="Please try another search."
                icon="fa-search"
              />
            )
          ) : (
            <EmptyState
              title="There are no sessions"
              description="Please use the Add Session button to begin creating sessions"
            />
          )}
        </PageContainer>
      )}
      {modal}
    </>
  );
};

export default ManageCurriculumUnit;
