import { useState, useEffect, useCallback } from 'react';
import { useUser } from '../../authentication';
import { getLicenses, revokeAccess } from '../api';
import type { License } from '../models/license';

type State = {
  isLoading: boolean;
  licenses?: License;
  error?: boolean;
};

export const useFetchLicenses = (licenseId: string) => {
  const user = useUser();
  const orgId = user?.lastSelectedAccount;
  const [removedUsersIds, setRemovedUserIds] = useState<string[]>([]);
  const [data, setData] = useState<State>({
    isLoading: true,
  });

  useEffect(() => {
    const fetchLicenses = () => {
      setData(prevState => ({ ...prevState, isLoading: true }));

      getLicenses(orgId || '', licenseId)
        .then(licenses => {
          setData({ licenses, isLoading: false });
        })
        .catch(error => {
          console.error(error);
          setData({ isLoading: false, error: true });
        });
    };

    fetchLicenses();
  }, [orgId, licenseId]);

  const removePerson = (userId: string): Promise<void> => {
    return revokeAccess(licenseId, userId, orgId || '', user?.userId || '')
      .then(res => {
        const removedUserIds: string[] = res.results.map((item: any) => item.userId.id);

        if (res.results.some((item: any) => !item.status.status.includes('REMOVED'))) {
          return Promise.reject();
        } else {
          setRemovedUserIds([...removedUsersIds, ...removedUserIds]);
          const remainingUsers = data.licenses?.users.filter(
            user => ![...removedUsersIds, ...removedUserIds].includes(user.id)
          );
          setData({ isLoading: false, licenses: { ...data.licenses, users: remainingUsers } as License });
        }
      })
      .catch(err => {
        console.error(err);
        return Promise.reject();
      });
  };

  return { ...data, removePerson };
};
