import cacheService from '../../services/cacheService';
import submitCommand from '../../services/submitCommand';
import uuid from '../../services/uuid';
import { InviteSettings } from '../models/inviteSettings';

export const setOptionsForInvite = (
  inviteId: string,
  settingsId: string | undefined,
  inviteSettings: InviteSettings,
  initiatingUserId?: string
) => {
  cacheService.remove(`/v1/workflows/${settingsId}`);

  const id = settingsId || uuid.generate();
  const cmd = { id, inviteId, inviteOptions: inviteSettings, initiatingUserId };
  return submitCommand(id, cmd, 'SetOptionsForInvite', 'OptionsForInviteSet', 'InviteError');
};
