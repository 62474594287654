import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Grid } from '../StyleGuide';
import AccessDenied from '../components/AccessDenied';
import Breadcrumbs from '../components/Breadcrumbs';
import ErrorMessage from '../components/ErrorMessage';
import { Container } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import SectionHeader from '../components/SectionHeader';
import useModal from '../hooks/useModal';
import windowService from '../services/windowService';
import LicenseListItem from './LicenseListItem';
import { SelectSubscriptionLicenseModal } from './SelectSubscriptionLicenseModal';
import useFetchSubscriptions from './hooks/useFetchSubscriptions';
import { CurriculumImage, TrainingImage } from '../curriculum/CurriculumElements';

const LicenseList = styled.div`
  display: grid;
  grid-gap: ${Grid._7};
  margin-bottom: ${Grid._11};
`;

const LicenseListSection = ({ title, licenses, onItemClick }) => (
  <>
    <SectionHeader title={title} />
    <LicenseList data-qa-hook={`licenseList${title}`}>
      {licenses.map(license => (
        <LicenseListItem key={license.id} license={license} onClick={() => onItemClick(license)} />
      ))}
    </LicenseList>
  </>
);

LicenseListSection.propTypes = {
  title: PropTypes.string.isRequired,
  licenses: PropTypes.array.isRequired,
};

export function ManageSubscriptions() {
  const { isLoading, isError, isAccessDenied, activeLicenses, expiredLicenses, allLicenses } = useFetchSubscriptions();
  const crumbs = [{ name: 'Organization', route: '#/org' }, { name: 'Purchases and Subscriptions' }];

  const [modal, openModal] = useModal((type, payload, dismissModal) => {
    if (!type) return null;
    return <SelectSubscriptionLicenseModal {...payload} handleDismiss={dismissModal} />;
  });

  const redirectToManageLicense = licenseId =>
    windowService.redirectTo(`/org/purchases-and-subscriptions/${licenseId}`);

  const handleClick = license => {
    if (!license.subscription) return redirectToManageLicense(license.id);

    const subscriptionLicenses = allLicenses.filter(l => l.subscription?.id === license.subscription.id);
    if (subscriptionLicenses.length === 1) return redirectToManageLicense(license.id);

    const curriculumBrandCode = license.curriculum?.brand?.code;
    const subscriptionImage = curriculumBrandCode ? (
      <CurriculumImage brandCode={curriculumBrandCode} />
    ) : (
      <TrainingImage />
    );
    const subscriptionName = license.subscription?.item?.title ?? license.name;
    openModal('select', { subscriptionImage, subscriptionName, licenses: subscriptionLicenses });
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container data-qa-hook="subscriptionsView">
        <h2 className="mt-40 mb-48">Purchases and Subscriptions</h2>
        {isLoading ? (
          <LoadingState />
        ) : isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : isAccessDenied ? (
          <AccessDenied />
        ) : (
          <>
            {!activeLicenses.length && !expiredLicenses.length && <p>No Purchases or Subscriptions</p>}
            {!!activeLicenses.length && (
              <LicenseListSection title="Active" licenses={activeLicenses} onItemClick={handleClick} />
            )}
            {!!expiredLicenses.length && <LicenseListSection title="Expired" licenses={expiredLicenses} />}
          </>
        )}
        {modal}
      </Container>
    </>
  );
}
