import React, { useEffect, useLayoutEffect, useState } from 'react';
import { react2angular } from 'react2angular';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Breakpoints, Color, Type } from '../StyleGuide';

import { useSteps } from './StepsContext';

import CourseMenu from './CourseMenu';
import CourseSteps from './CourseSteps';
import TrainingOverview from './TrainingOverview';
import CourseInformation from './CourseInformation';
import WhiteLabel from '../filters/WhiteLabel';
import pluralFilter from '../filters/plural-filter';

const Aside = styled.aside`
  background-color: ${Color.Gray._10};
  position: fixed;
  top: 0;
  right: -325px;
  width: 325px;
  height: 100vh;
  padding-bottom: ${Grid._13}; /* Prevent iOS Safari Menu Bar from covering up last step */
  transition: all 0.2s ease-in-out;
  overflow: auto;
  z-index: 500;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    position: static;
    flex: 0 0 25%;
    right: 0;
    width: inherit;
  }
  &.visible {
    right: 0;
  }
  @media screen and (min-width: ${Breakpoints.screen_lg}) {
    padding-bottom: 0;
  }
`;

const TrainingInformation = styled.div`
  padding: ${Grid._4};
  color: ${Type.Color.dark};
  h3 {
    font-weight: ${Type.Weight.bold};
    margin-top: 0;
  }
  p {
    color: ${Type.Color.medium};
  }
  .tl-progress-bar {
    margin-top: ${Grid._4};
  }
`;

const Tabs = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  border-bottom: 1px solid ${Color.Gray._30};
  li {
    flex: 1 0 0;
    padding: ${Grid._4};
    text-align: center;
    font-size: ${Type.Scale._3};
    color: ${Type.Color.medium};
    cursor: pointer;
    &:hover {
      border-bottom: 3px solid ${Color.Gray._30};
    }
  }
  .active-training-menu-tab {
    border-bottom: 3px solid ${Color.Primary._50};
    color: ${Type.Color.dark};
    font-weight: ${Type.Weight.bold};
  }
`;

const StickyContent = styled.div`
  background: ${Color.Gray._10};
  box-shadow: inset 1px 0 rgba(0, 0, 0, 0.05);
  position: sticky;
  top: 0;
  z-index: 99;
  h3 {
    margin-top: 0;
  }
`;

const CloseButton = styled.i`
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  color: ${Color.Gray._50};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    display: none;
  }
`;

export default function TrainingMenu({ close }) {
  const [
    {
      numCompletedSteps,
      currentStepIndex,
      training,
      isMobileTrainingMenuOpen,
      isWorkflow,
      currentCourse,
      currentCourseIndex,
    },
  ] = useSteps();
  const menuClass = isMobileTrainingMenuOpen ? 'visible' : '';
  const [showCourseMenu, setShowCourseMenu] = useState(true);
  const [checkAutoScroll, setCheckAutoScroll] = useState(false);

  const autoScrollMenu = () => {
    try {
      const menu = document.querySelector('aside.training-menu');
      const menuHeader = menu.querySelector('.training-header');
      const selectedStep = menu.querySelector('li.course-menu-active');

      const menuEdgeTop = menu.scrollTop + menuHeader.clientHeight;
      const selectedStepTop = selectedStep.offsetTop;
      const hiddenTopBy = menuEdgeTop - selectedStepTop;
      const menuEdgeBottom = menu.clientHeight + menu.scrollTop;
      const selectedStepBottom = selectedStep.offsetTop + selectedStep.clientHeight;
      const hiddenBottomBy = selectedStepBottom - menuEdgeBottom;

      let target, incrementPx;

      const maxIncrementPx = 100;
      const minIncrementPx = 12;
      const getIncrementPx = dist => {
        const x = Math.floor(dist / 3);
        return x > maxIncrementPx ? maxIncrementPx : x < minIncrementPx ? minIncrementPx : x;
      };

      if (hiddenTopBy > 0) {
        target = menu.scrollTop - hiddenTopBy;
        incrementPx = getIncrementPx(hiddenTopBy) * -1;
      }

      if (hiddenBottomBy > 0) {
        target = menu.scrollTop + hiddenBottomBy;
        incrementPx = getIncrementPx(hiddenBottomBy);
      }

      const incrementMs = 20;
      const animateScroll = () => {
        if (Math.abs(target - menu.scrollTop) > Math.abs(incrementPx)) {
          menu.scrollTop = menu.scrollTop + incrementPx;
          setTimeout(() => {
            animateScroll();
          }, incrementMs);
        } else {
          menu.scrollTop = target;
        }
      };

      if (target) {
        animateScroll();
      }
    } catch (e) {} // eslint-disable-line no-empty
  };

  const [isCourseExpanded, setIsCourseExpanded] = useState({});

  const toggleIsCourseExpanded = courseIndex => () => {
    setIsCourseExpanded(s => {
      return { ...s, [courseIndex]: !s[courseIndex] };
    });
  };

  useEffect(() => {
    if (!isCourseExpanded[currentCourseIndex]) {
      toggleIsCourseExpanded(currentCourseIndex)();
      setCheckAutoScroll(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex, currentCourseIndex]);

  useLayoutEffect(() => {
    autoScrollMenu();
  }, [currentStepIndex, currentCourseIndex]);

  useLayoutEffect(() => {
    if (checkAutoScroll) {
      autoScrollMenu();
      setCheckAutoScroll(false);
    }
  }, [checkAutoScroll]);

  const StepsList = () =>
    isWorkflow ? (
      <div>
        {training.courses.map((course, i) => {
          if (course.id === currentCourse.id) {
            return (
              <CourseMenu
                key={i}
                training={training}
                course={currentCourse}
                isCurrentCourse={true}
                courseIndex={i}
                isCourseExpanded={isCourseExpanded[i]}
                toggleIsCourseExpanded={toggleIsCourseExpanded(i)}
              />
            );
          } else {
            return (
              <CourseMenu
                key={i}
                training={training}
                course={course}
                courseIndex={i}
                isCourseExpanded={isCourseExpanded[i]}
                toggleIsCourseExpanded={toggleIsCourseExpanded(i)}
              />
            );
          }
        })}
      </div>
    ) : (
      <CourseSteps course={currentCourse} isCurrentCourse={true} />
    );

  return (
    <Aside data-qa-hook="trainingSlideMenu" className={`training-menu ${menuClass}`}>
      <StickyContent className="training-header">
        <CloseButton data-qa-hook="closeSlideMenu" className="icon ion-close" onClick={close} />
        <TrainingInformation>
          <h3 data-qa-hook="trainingName">{training.workflowName || training.name}</h3>
          {isWorkflow ? (
            <p>
              {training.progress.steps.completed +
                ' of ' +
                training.progress.steps.total +
                ' ' +
                pluralFilter(WhiteLabel.labels.course) +
                ' complete'}
            </p>
          ) : (
            <CourseInformation
              course={currentCourse}
              numCompletedSteps={numCompletedSteps}
              progress={currentCourse.progress * 100}
            />
          )}
        </TrainingInformation>
        <Tabs>
          <li
            data-qa-hook="contentsTab"
            className={showCourseMenu ? 'active-training-menu-tab' : ''}
            onClick={() => setShowCourseMenu(true)}
          >
            Contents
          </li>
          <li
            data-qa-hook="overviewTab"
            className={showCourseMenu ? '' : 'active-training-menu-tab'}
            onClick={() => setShowCourseMenu(false)}
          >
            Overview
          </li>
        </Tabs>
      </StickyContent>
      {showCourseMenu ? <StepsList /> : <TrainingOverview />}
    </Aside>
  );
}

TrainingMenu.propTypes = {
  close: PropTypes.func.isRequired,
};

angular.module('lwNamb').component('trainingMenu', react2angular(TrainingMenu));
