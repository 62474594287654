angular.module('lwNamb').controller(
  'AssessBuilderCtrl',

  [
    '$scope',
    '$filter',
    '$routeParams',
    '$location',
    '$log',
    '$modal',
    'alertService',
    'userService',
    'orgUserService',
    'assessBuilderService',
    'uuid4',
    '$timeout',
    '$q',
    'buildService',
    'analyticsService',
    function (
      $scope,
      $filter,
      $routeParams,
      $location,
      $log,
      $modal,
      alertService,
      userService,
      orgUserService,
      assessBuilderService,
      uuid4,
      $timeout,
      $q,
      buildService,
      analyticsService
    ) {
      var assessmentId = $routeParams.assessmentId,
        user,
        ownerId;

      var init = function () {
        $scope.submitted = false;
        $scope.loading = true;
        $scope.isAssessment = !buildService.isScorecards();
        $q.all([userService.user(), buildService.ownerId()]).then(function (result) {
          user = result[0];
          ownerId = result[1];
          getAssessment();
        });
      };

      var getAssessment = function (checkValueFunction) {
        assessBuilderService.getAssessment(assessmentId, user.userId).then(
          function (response) {
            $scope.assessment = response;
            if (checkValueFunction !== undefined) {
              checkValueFunction();
            }
            $scope.rollBackName = $scope.assessment.title;
            $scope.rollBackNameOverview = $scope.assessment.overview;
            $scope.rollBackNameInstructions = $scope.assessment.instructions;
            $scope.rollBackNameResponseVisibility = $scope.assessment.responseVisibility;
            $scope.loading = false;
          },
          function () {
            $log.error('Error: Assessment not found');
            $scope.loading = false;
          }
        );
      };

      $scope.clearInput = function () {
        $scope.searchQuestions = {};
      };

      $scope.clearForm = function () {
        $scope.question = { type: '' };
        $scope.submitted = false;
        $scope.spinner = false;
      };

      $scope.openModal = function () {
        var modal = $modal({
          show: true,
          templateUrl: 'togglePublished.html',
          placement: 'center',
          scope: $scope,
          controller: 'AssessBuilderCtrl',
        });
        modal.$promise.then(modal.show);
      };

      $scope.stripFormat = function ($html) {
        return $filter('stripStyles')($html);
      };

      $scope.saveAssessSettings = function (name, intro, outro, responseVisibility, isValid) {
        $scope.submitted = true;
        $scope.nameSent = false;
        $scope.introSent = false;
        $scope.outroSent = false;
        $scope.responseVisibilitySent = false;

        var callTimeoutCancel = function () {
          if (
            $scope.nameSent === false &&
            $scope.introSent === false &&
            $scope.outroSent === false &&
            $scope.responseVisibilitySent === false
          ) {
            $scope.$evalAsync(function () {
              $timeout.cancel($scope.timer);
            });
            $scope.spinner = false;
            $scope.redirectToAssessment();
          }
        };

        if (isValid) {
          $scope.spinner = true;
          $scope.timer = $timeout(function () {
            $scope.spinner = false;
            var check = function () {
              if (
                ($scope.nameSent === false || $scope.rollBackName !== $scope.assessment.title) &&
                ($scope.introSent === false || $scope.rollBackNameInstructions !== $scope.assessment.instructions) &&
                ($scope.outroSent === false || $scope.rollBackNameOverview !== $scope.assessment.overview) &&
                ($scope.responseVisibilitySent === false ||
                  $scope.rollBackNameResponseVisibility !== $scope.assessment.responseVisibility)
              ) {
                $scope.spinner = false;
                $scope.redirectToAssessment();
              } else {
                showAlert('danger', 'Error! Please try again.', false);
              }
            };
            getAssessment(check);
          }, 3000);

          if ($scope.rollBackName !== name) {
            $scope.duplicateNameError = false;
            $scope.nameSent = true;
            assessBuilderService.updateAssessmentName(assessmentId, user.userId, ownerId, name).then(
              function () {
                $scope.nameSent = false;
                $scope.duplicateNameError = false;
                callTimeoutCancel();
              },
              function (reason) {
                $scope.nameSent = false;
                $scope.$evalAsync(function () {
                  $timeout.cancel($scope.timer);
                });
                $scope.submitted = false;
                $scope.spinner = false;
                if (reason === 'AssessmentNameTaken') {
                  $scope.duplicateNameError = true;
                } else {
                  showAlert('danger', 'Error! Please try again.', false);
                }
              }
            );
          }

          if ($scope.rollBackNameInstructions !== intro) {
            $scope.introSent = true;
            assessBuilderService.updateAssessmentIntro(assessmentId, user.userId, intro).then(
              function () {
                $scope.introSent = false;
                callTimeoutCancel();
              },
              function () {
                showAlert('danger', 'Error! Please try again.', false);
              }
            );
          }

          if ($scope.rollBackNameOverview !== outro) {
            $scope.outroSent = true;
            assessBuilderService.updateAssessmentOutro(assessmentId, user.userId, outro).then(
              function () {
                $scope.outroSent = false;
                callTimeoutCancel();
              },
              function () {
                showAlert('danger', 'Error! Please try again.', false);
              }
            );
          }

          if ($scope.rollBackNameResponseVisibility !== responseVisibility) {
            $scope.responseVisibilitySent = true;
            assessBuilderService.setResponseVisibility(assessmentId, user.userId, responseVisibility).then(
              function () {
                $scope.responseVisibilitySent = false;
                callTimeoutCancel();
              },
              function () {
                showAlert('danger', 'Error! Please try again.', false);
              }
            );
          }
        }
      };

      $scope.addQuestion = function (type, isValid) {
        $scope.submitted = true;
        if (!isValid) {
          return;
        }
        buildService.ctxRedirectTo('/assessments/' + assessmentId + '/questions/', { questionType: type });
      };

      $scope.removeQuestion = function (question) {
        $scope.assessment.questions.splice($scope.assessment.questions.indexOf(question), 1);
        $scope.spinner = true;
        assessBuilderService.removeQuestion(assessmentId, question.id.id, user.userId).then(
          function () {
            getAssessment();
          },
          function () {
            showAlert('danger', 'Error! Please try again.', false);
          }
        );
      };

      $scope.cloneAssessment = function (newName, isValid) {
        $scope.submitted = true;
        if (!isValid) {
          return;
        }
        $scope.spinner = true;
        $scope.duplicateNameError = false;
        $scope.cloneError = false;

        assessBuilderService.clone(assessmentId, newName, ownerId, user.userId).then(
          function (newAssessmentId) {
            buildService.ctxRedirectTo('/assessments/' + newAssessmentId);
            showAlert('success', 'Assessment Copied', 5);
          },
          function (reason) {
            $log.error(reason);
            $scope.spinner = false;
            if (reason == 'DUPLICATE_NAME') {
              $scope.duplicateNameError = true;
            } else {
              $scope.cloneError = true;
            }
          }
        );
      };

      $scope.publishAssessment = function (closeModal) {
        $scope.spinner = true;
        assessBuilderService.publishAssessment(assessmentId, user.userId).then(
          function () {
            $scope.spinner = false;
            closeModal();
            showAlert('success', 'Assessment Published', 5);
            $scope.assessment.published = true;
          },
          function (reason) {
            $scope.spinner = false;
            closeModal();
            $log.error(reason);
            $scope.assessment.published = false;
            showAlert('danger', 'Error! Please try again.', false);
          }
        );
      };

      function showAlert(type, msg, duration) {
        alertService.show({ title: msg, type: type, show: true, dismissible: true, duration: duration });
      }

      $scope.redirectToList = function () {
        buildService.ctxRedirectTo('/content');
      };

      $scope.redirectToAssessment = function () {
        buildService.ctxRedirectTo('/assessments/' + assessmentId);
      };

      $scope.redirectToSettings = function () {
        buildService.ctxRedirectTo('/assessments/' + assessmentId + '/settings');
      };

      $scope.redirectToQuestion = function (questionId, type) {
        buildService.ctxRedirectTo('/assessments/' + assessmentId + '/questions/' + questionId, { questionType: type });
      };

      $scope.toggleReordering = function () {
        $scope.isReordering = !$scope.isReordering;
      };

      $scope.saveQuestionsOrder = function (questions) {
        analyticsService.trackFeature('AssessBuilderCtrl', 'reorderingQuestions');
        var seqOfQuestions,
          hasChanged = !!$scope.assessment.questions.find(function (question, i) {
            return question.order != i;
          });

        seqOfQuestions = questions.reduce(function (obj, item, i) {
          obj[item.id.id] = i;
          return obj;
        }, {});

        if (hasChanged) {
          $scope.isSaving = true;
          assessBuilderService.reorderQuestions($scope.assessment.id.id, seqOfQuestions, $scope.user.userId).then(
            function () {
              alertService.show({ type: 'success', title: 'Assessment Reordered', duration: 5 });
              $scope.assessment.questions = questions;
              $scope.isReordering = false;
              $scope.isSaving = false;
            },
            function (reason) {
              alertService.show({
                type: 'danger danger-with-content',
                title: 'Reorder Assessment Failed',
                content: 'Something went wrong. Please try again',
                duration: 20,
              });
              $log.error(reason);
              $scope.isReordering = false;
              $scope.isSaving = false;
            }
          );
        } else {
          $scope.isReordering = false;
        }
      };

      init();
    },
  ]
);
