angular.module('lwNamb').controller('SeriesSettingsCtrl', [
  '$scope',
  '$log',
  '$routeParams',
  'curriculumService',
  'alertService',
  'windowService',
  'uploadService',
  function ($scope, $log, $routeParams, curriculumService, alertService, windowService, uploadService) {
    function init() {
      $scope.loading = true;
      $scope.curriculumId = $routeParams.curriculumId;
      $scope.seriesId = $routeParams.seriesId;
      $scope.saving = false;
      $scope.replaceImage = false;
      $scope.dateErrorMessage = '';

      loadSeries();
      loadMaterialCount();
    }

    function loadSeries() {
      curriculumService
        .getSingleSeries($scope.curriculumId, $scope.seriesId)
        .then(
          function (response) {
            $scope.seriesName = response.name;
            $scope.classification = response.ageRange;
            $scope.subdivision = response.subdivision;
            $scope.target = response.target;
            $scope.image = response.image;
            $scope.minimumPlan = response.package || 'Basic';
            $scope.startDate = ymdToDate(response.startDate);
            $scope.endDate = ymdToDate(response.endDate);
          },
          function (reason) {
            $log.error(reason + ' in collection settings controller');
          }
        )
        .finally(function () {
          $scope.loading = false;
        });
    }

    function loadMaterialCount() {
      curriculumService.getSeriesMaterials($scope.curriculumId, $scope.seriesId).then(function (response) {
        $scope.relatedMaterialsCount = response.length;
      });
    }

    function dateToYMD(date) {
      return date && date.toISOString ? date.toISOString().split('T')[0] : date;
    }

    function ymdToDate(ymd) {
      return ymd ? new Date(ymd + 'T00:00:00') : ymd;
    }

    function hasDateError() {
      if ($scope.startDate && $scope.startDate >= $scope.endDate) {
        $scope.dateErrorMessage = 'The start date must come before the end date';
      } else {
        $scope.dateErrorMessage = '';
      }

      return $scope.dateErrorMessage;
    }

    $scope.submit = function (isValid) {
      if (hasDateError()) return;

      $scope.submitted = true;

      if (isValid) {
        $scope.isSaving = true;
        curriculumService
          .updateSingleSeries(
            $scope.curriculumId,
            $scope.seriesId,
            $scope.seriesName,
            $scope.classification,
            $scope.target,
            $scope.subdivision,
            $scope.minimumPlan,
            dateToYMD($scope.startDate),
            dateToYMD($scope.endDate)
          )
          .then(
            function () {
              showSuccess();
              windowService.redirectHash('#/manage-curriculum/' + $scope.curriculumId);
              $scope.isSaving = false;
            },
            function (reason) {
              $scope.isSaving = false;
              $log.error(reason);
              showError();
            }
          );
      }
    };

    function showSuccess() {
      alertService.show({ type: 'success', title: 'Collection Settings Saved', duration: 5 });
    }

    function showError() {
      alertService.show({
        type: 'danger danger-with-content',
        title: 'Unexpected Error!',
        content: 'Something went wrong. Please try again.',
        duration: 20,
      });
    }

    $scope.setImage = function (image) {
      var fileName = $scope.seriesId + '.jpg';
      $scope.saving = true;

      uploadService
        .uploadCurriculumImage(fileName, image)
        .then(function (response) {
          return curriculumService.updateSingleSeries(
            $scope.curriculumId,
            $scope.seriesId,
            $scope.seriesName,
            $scope.classification,
            $scope.target,
            $scope.subdivision,
            $scope.minimumPlan,
            dateToYMD($scope.startDate),
            dateToYMD($scope.endDate),
            fileName
          );
        })
        .then(function () {
          alertService.show({ type: 'success', title: 'Collection Image Saved', duration: 5 });
          windowService.redirectHash('#/manage-curriculum/' + $scope.curriculumId);
        })
        .catch(showError)
        .finally(function () {
          $scope.saving = false;
        });
    };

    $scope.deleteSeries = function () {
      $scope.deleting = true;

      curriculumService
        .deleteSeries($scope.curriculumId, $scope.seriesId)
        .then(function () {
          alertService.show({ type: 'success', title: 'Collection Deleted', duration: 5 });
          windowService.redirectHash('#/manage-curriculum/' + $scope.curriculumId);
        })
        .catch(showError)
        .finally(function () {
          $scope.deleting = false;
        });
    };

    init();
  },
]);
