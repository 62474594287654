import React from 'react';
import PropTypes from 'prop-types';
import Section from './Section';
import CompleteStepButtonContainer from './CompleteStepButtonContainer';
import CompleteStepButton from './CompleteStepButton';

const DeletedStep = ({ isComplete, deletedMessage }) => (
  <Section>
    <p data-qa-hook="deletedStepMessage">{deletedMessage}</p>
    {!isComplete && (
      <CompleteStepButtonContainer>
        <CompleteStepButton isDeleted={true} />
      </CompleteStepButtonContainer>
    )}
  </Section>
);
DeletedStep.propTypes = {
  deletedMessage: PropTypes.string.isRequired,
  isComplete: PropTypes.bool.isRequired,
};

export default DeletedStep;
