import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Border, Color, Type } from '../StyleGuide';

const Container = styled.span`
  width: ${Grid._6};
  height: ${Grid._6};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${Type.Color.medium};
  border-radius: ${Border.radius};
  transition: all 0.125s linear;
  &:hover {
    color: ${Color.Red._60};
    background: ${Color.Red._05};
  }
`;

const RemoveIcon = ({ onClickHandler, title }) => (
  <Container
    title={title || 'Remove'}
    className="icon ion-ios-trash-outline remove-icon-component"
    onClick={onClickHandler}
  />
);

RemoveIcon.propTypes = {
  title: PropTypes.string,
  onClickHandler: PropTypes.func.isRequired,
};

export default RemoveIcon;
