angular.module('lwNamb').controller('SelectOrgAndAssignCtrl', [
  '$scope',
  '$log',
  '$routeParams',
  '$modal',
  'userService',
  'windowService',
  'apiUrlService',
  'blockUX',
  'activeCampaignService',
  '$timeout',
  function(
    $scope,
    $log,
    $routeParams,
    $modal,
    userService,
    windowService,
    apiUrlService,
    blockUX,
    activeCampaignService,
    $timeout
  ) {
    function init() {
      $scope.orgs = [];
      $scope.isLoading = true;
      $scope.selectedOrg = [];
      $timeout(function() {
        userService.user().then(
          function(user) {
            $scope.user = user;
            $scope.userId = user.userId;
            $scope.orgId = user.lastSelectedAccount;
            getUserOrgsData();
          },
          function() {
            $log.error('No user returned in SelectOrgAndAssignCtrl');
          }
        );
      }, 3000);
    }

    function getUserOrgsData() {
      userService.lookupUserOrgs(false).then(
        function(response) {
          var filteredOrgs = response;
          if (!filteredOrgs.length) {
            windowService.redirectHash('/training/' + $routeParams.trainingId);
            userService.clearRedirectUrl();
          } else if (filteredOrgs.length) {
            filteredOrgs.forEach(function(org) {
              if (org.logo) {
                org.logo = apiUrlService.getUrl() + '/v1/images/org/' + org.logo;
              }
              org.isSiteWide = getSiteWideOrgIds().some(function(checkId) {
                return org._id.id === checkId;
              });
            });
            $scope.selectedOrg.push(filteredOrgs[getCurrentOrgIndex(filteredOrgs)]);
            $scope.selectedOrg[0].selected = true;
          }
          $scope.orgs = filteredOrgs.filter(function(org) {
            return !org.isSiteWide;
          });

          $scope.isLoading = false;
        },
        function(error) {
          $log.error('Failed to retrieve User Organizations.');
        }
      );
    }

    function getCurrentOrgIndex(array) {
      return array.findIndex(function(org) {
        return org._id.id === $scope.orgId;
      });
    }

    $scope.selectOrgAndRedirect = function() {
      if (hasOrgRolePermission('Account View')) {
        //TODO: we need to pass along the 'onboardingType' to make sure we're tagging the right people
        activeCampaignService.event('CC_Admin', $scope.user.logInEmail);
      }
      if (hasOrgRolePermission('Assign View')) {
        if ($scope.orgId !== $scope.selectedOrg[0]._id.id) {
          blockUX.start('Updating Organization...', 0, 10000, false, 'Error', function() {});
          userService.switchOrg($scope.userId, $scope.selectedOrg[0]._id.id).then(
            function() {
              windowService.redirectHash('/assign-training/' + $routeParams.trainingId);
            },
            function(reason) {
              $log.error(reason);
              $scope.error = true;
              $log.error('Error! Select Organization failed.');
            }
          );
        } else {
          windowService.redirectHash('/assign-training/' + $routeParams.trainingId);
        }
      } else {
        var modal = $modal({
          scope: $scope,
          templateUrl: 'modal.html',
          placement: 'center',
          show: false,
        });
        modal.$promise.then(modal.show);
      }
    };

    function hasOrgRolePermission(perm) {
      var hasAssign = [];
      $scope.selectedOrg[0].memberRoles.forEach(function(orgRole) {
        hasAssign.push(
          orgRole.permission.permissions
            .map(function(perm) {
              return perm.access;
            })
            .indexOf(perm) > -1
        );
      });
      return hasAssign.indexOf(true) > -1;
    }

    init();
  },
]);
