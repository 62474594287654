angular.module('lwNamb').factory(
  'taskService',

  [
    'commandSubmissionService',
    function(commandSubmissionService) {
      return {
        startTask: function(id, task, assigneeId, initiatingUserId) {
          var cmd = { id: id, initiatingUserId: initiatingUserId, assigneeId: assigneeId, taskInstance: task };
          return commandSubmissionService.submitAndWait(
            id,
            cmd,
            'StartTask',
            'TaskInstanceStarted',
            'TaskInstanceError'
          );
        },
        startFeedbackTask: function(id, task, assigneeId, email, responseId, initiatingUserId) {
          var cmd = {
            id: id,
            initiatingUserId: initiatingUserId,
            assigneeId: assigneeId,
            taskInstance: task,
            email: email,
            responseId: responseId,
          };
          return commandSubmissionService.submitAndWait(
            id,
            cmd,
            'StartFeedbackTask',
            'TaskInstanceStarted',
            'TaskInstanceError'
          );
        },
      };
    },
  ]
);
