angular.module('lwNamb').directive(
  'coachPaneNotes',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/coach-pane-notes.html',
        scope: {
          userId: '@',
          paneId: '@',
          orgId: '@',
        },
        replace: true,
        controller: [
          '$scope',
          'State',
          'noteService',
          '$log',
          'alertService',
          'userService',
          function($scope, State, noteService, $log, alertService, userService) {
            $scope.isLoading = true;

            $scope.notes = [];

            userService.user().then(
              function(user) {
                $scope.user = user;
                noteService.getNotesForUserOrg($scope.userId, $scope.orgId, $scope.user).then(
                  function(notes) {
                    $scope.notes = notes;
                    $scope.isLoading = false;
                  },
                  function(reason) {
                    $log.error(reason);
                    $scope.isLoading = false;
                    $scope.isError = true;
                  }
                );
              },
              function() {
                $log.error('No user returned in coachPaneNotes');
              }
            );

            var state = State($scope.paneId);
            $scope.selected = state.get();

            $scope.select = function(note) {
              state.put('menu', 'note');
              state.put('noteId', note.id.id);
              state.put('viewOnly', note.viewOnly);
            };

            $scope.createNote = function() {
              state.put('menu', 'note');
              state.put('viewOnly', false);
              state.put('noteId', undefined);
            };

            $scope.close = function() {
              State($scope.paneId).clear();
            };
            $scope.deleteNote = function(note) {
              noteService.deleteNote(note, $scope.userId, $scope.user).then(
                function() {
                  deleteNoteFromList(note, $scope.notes);
                },
                function(reason) {
                  $log.error(reason);
                  alertService.show({
                    content: 'Error! Something went wrong.',
                    type: 'danger',
                    show: true,
                    dismissible: true,
                    duration: 20,
                  });
                }
              );
            };
            var deleteNoteFromList = function(note, notes) {
              var noteIndex = -1;
              notes.forEach(function(noteElem, index) {
                if (noteElem.id.id == note.id.id) {
                  noteIndex = index;
                }
              });

              if (noteIndex >= 0) {
                notes.splice(noteIndex, 1);
              } else {
                $log.error('Unexpected: note not in list.');
              }
            };
            $scope.setNoteToDelete = function(note) {
              $scope.noteToDelete = note;
            };
          },
        ],
      };
    },
  ]
);
