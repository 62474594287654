import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { Breakpoints, Grid, Type } from '../../StyleGuide';
import { PrimaryButton } from '../../components/Buttons';
import EmptyState from '../../components/EmptyState';
import { CircleCheck, TextFilterInput } from '../../components/FormElements';
import { RaisedContainer } from '../../components/Layout';
import { ListItemFlexRow } from '../../components/ListItemFlexRow';
import WhiteLabel from '../../filters/WhiteLabel';
import { useTextFilter } from '../../hooks/filters';
import { InviteGroup, InviteRole } from '../models/inviteSettings';

const ControlsContainer = styled.div`
  display: grid;
  grid-row-gap: ${Grid._4};
  grid-column-gap: ${Grid._4};
  padding: ${Grid._6} ${Grid._4};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 1fr 220px;
  }

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    grid-template-columns: 1fr 250px;
    grid-column-gap: ${Grid._6};
  }
`;

const ListContainer = styled.ul`
  padding: 0;
  list-style: none;

  ${ListItemFlexRow} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${Grid._5} ${Grid._4};

    h4 {
      font-weight: ${Type.Weight.medium};
      margin: 0;
    }
  }
`;

type InviteSetting = InviteGroup | InviteRole;

type Props = {
  settingLabel: 'Groups' | 'Roles';
  items: InviteSetting[];
  preselectedItems: InviteSetting[];
  onSubmit: (selectedItems: InviteSetting[]) => void;
};

export const AddSetting = ({ settingLabel, items, preselectedItems, onSubmit }: Props) => {
  const [selectedItems, setSelectedItems] = useState<InviteSetting[]>(preselectedItems);
  const textFilter = useTextFilter({
    matcher: (item: InviteSetting, query: string) => item.name.toLowerCase().includes(query.toLowerCase()),
  });
  const filteredItems = textFilter.filter(items);

  const toggleSelection = (item: InviteSetting, isSelected: boolean) => {
    if (isSelected)
      return setSelectedItems(prev => prev.filter((selected: InviteSetting) => selected.id.id !== item.id.id));
    return setSelectedItems(prev => [...prev, item]);
  };

  const submitSelectedItems = () => onSubmit(selectedItems.map((item: InviteSetting) => ({ ...item, active: true })));

  return (
    <RaisedContainer>
      <ControlsContainer>
        <TextFilterInput
          value={textFilter.value}
          onChange={(e: ChangeEvent<HTMLInputElement>) => textFilter.update(e.target.value)}
          clearInput={textFilter.reset}
          placeholder={`Search ${settingLabel}`}
        />
        <PrimaryButton onClick={submitSelectedItems}>
          Add {settingLabel} {selectedItems.length > 0 && <span className="rounded-label">{selectedItems.length}</span>}
        </PrimaryButton>
      </ControlsContainer>
      <ListContainer>
        {items.length === 0 ? (
          <EmptyState
            title={`No ${settingLabel} Available`}
            description={`${settingLabel} will appear when they have been added to your ${WhiteLabel.labels.org}`}
          />
        ) : filteredItems.length === 0 ? (
          <EmptyState
            icon="fa-search"
            title="Your search does not have any matches"
            description="Please try another search"
          />
        ) : (
          <>
            {filteredItems.map((item: InviteSetting) => {
              const selected = !!selectedItems.find((selected: InviteSetting) => selected.id.id === item.id.id);
              return (
                <ListItemFlexRow key={item.id.id} onClick={() => toggleSelection(item, selected)}>
                  <h4>{item.name}</h4>
                  <CircleCheck checked={selected} />
                </ListItemFlexRow>
              );
            })}
          </>
        )}
      </ListContainer>
    </RaisedContainer>
  );
};
