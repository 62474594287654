import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Type, Grid, Color, Breakpoints } from '../StyleGuide';
import { LinkStyleButton } from './Buttons';

const ActiveFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 36px; // accounting for 'Clear All' button

  @media screen and (min-width: ${Breakpoints.screen_xs}) {
    flex-direction: row;
    align-items: center;
  }
`;

const ResultCount = styled.div`
  margin-right: ${Grid._4};
  font-weight: ${Type.Weight.bold};
`;

const FilterPillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${Grid._3};
  align-items: center;

  @media screen and (min-width: ${Breakpoints.screen_xs}) {
    padding-left: ${Grid._4};
    border-left: 1px solid ${Color.Blue._15};
  }
`;

const FilterPill = styled.span`
  display: flex;
  cursor: pointer;
  color: white;
  background: ${Color.Gray._80};
  padding: ${Grid._2} ${Grid._4};
  border-radius: ${Grid._7};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: ${Type.Scale._05};
  align-items: center;

  i {
    margin-left: ${Grid._3};
  }

  &:hover {
    background: ${Color.Gray._90};
  }
`;

export default function ActiveFilters({ count, filters, onClearAll }) {
  const filterCount = Object.keys(filters || {}).length;

  return (
    <ActiveFiltersContainer>
      <ResultCount>{`${count} Found`}</ResultCount>
      {filterCount > 0 && (
        <FilterPillsContainer>
          <span data-qa-hook="activeFilters">{`Active Filters:`}</span>
          {Object.entries(filters).map(([label, handler]) => (
            <FilterPill key={label} onClick={handler} data-qa-hook={`${label}--filter-pill`}>
              {label} <i className="fas fa-times"></i>
            </FilterPill>
          ))}
          {!!onClearAll && filterCount > 1 && <LinkStyleButton onClick={onClearAll}>Clear All</LinkStyleButton>}
        </FilterPillsContainer>
      )}
    </ActiveFiltersContainer>
  );
}

ActiveFilters.propTypes = {
  count: PropTypes.number.isRequired,
  filters: PropTypes.object,
  onClearAll: PropTypes.func,
};
