angular.module('lwNamb').directive(
  'coachPaneScorecard',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/coach-pane-scorecard.html',
        scope: {
          userId: '@',
          paneId: '@',
        },
        replace: true,
        controller: [
          '$scope',
          'State',
          'scorecardInstanceService',
          '$log',
          'userService',
          function($scope, State, scorecardInstanceService, $log, userService) {
            var state = State($scope.paneId);
            $scope.selected = state.get();

            $scope.isLoading = true;

            //Sample Scorecard
            $scope.isLoading = true;

            userService.user().then(
              function(user) {
                $scope.localUserId = user.userId;
                scorecardInstanceService
                  .getScoreCard(
                    $scope.selected.scorecardTask,
                    $scope.userId,
                    $scope.selected.scorecardId,
                    $scope.localUserId
                  )
                  .then(
                    function(scorecard) {
                      $scope.scorecard = scorecard;
                    },
                    function(reason) {
                      $log.error(reason);
                      $scope.isError = true;
                    }
                  )
                  .finally(function() {
                    $scope.isLoading = false;
                  });
              },
              function() {
                $log.error('No user returned in coachPaneScorecard');
              }
            );

            $scope.save = function() {
              $scope.isSaving = true;
              $scope.isErrorWhileSaving = false;
              $scope.isEmptyListOfQuestions = false;
              scorecardInstanceService
                .saveQuestionsAndAnswers(
                  $scope.selected.scorecardId,
                  $scope.scorecard,
                  $scope.selected.scorecardTask,
                  $scope.userId,
                  $scope.localUserId
                )
                .then(
                  function() {
                    $scope.close();
                  },
                  function(error) {
                    $log.error(error);
                    if (error === 'NO_QUESTIONS_ANSWERED') {
                      $scope.isEmptyListOfQuestions = true;
                    } else {
                      $scope.isSaving = false;
                      $scope.isErrorWhileSaving = true;
                    }
                  }
                )
                .finally(function() {
                  $scope.isSaving = false;
                });
            };

            $scope.close = function() {
              State($scope.paneId).clear();
              state.put('menu', 'scorecards');
            };
          },
        ],
      };
    },
  ]
);
