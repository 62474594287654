import submitCommand from '../../services/submitCommand';

export const resendEmailInvite = (inviteId: string, orgId: string, inviteeEmail: string, initiatingUserId?: string) =>
  submitCommand(
    inviteId,
    {
      id: inviteId,
      orgId: { id: orgId },
      forEmail: inviteeEmail,
      initiatingUserId,
    },
    'ResendEmailInvite',
    'EmailInviteResent'
  );
