export const getFileExtension = filename => {
  const parts = filename.split('.');
  return parts[parts.length - 1];
};

export const getFileType = mimeType => {
  // this is by no means as robust as it could be,
  // but it serves our purposes of broadly distinguishing file types
  return mimeType.split('/')[0]; // video, image, audio, etc.
};

export const isAudioExtension = ext => ['mp3', 'wav'].includes(ext);

export const FileTypeMap = {
  image: 'image/*',
  audio: 'audio/*,audio/x-m4a',
  video: 'video/*,video/mp4,.mov,.mp4',
  document:
    'application/msword,application/pdf,.rtf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,.key,.pages,.numbers',
  epub: 'application/epub+zip',
  zip: 'application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip',
};

export const getFileTypeByMimeType = mimeType => {
  let matchedFileType;

  const entries = Object.entries(FileTypeMap);
  for (let i = 0; i < entries.length; i++) {
    const [fileType, mimeTypes] = entries[i];

    if (mimeType.match(mimeTypes.replaceAll(/\+|-/g, '.').replaceAll(',', '|'))) {
      matchedFileType = fileType;
      break;
    }
  }

  return matchedFileType;
};

export const FileTypeIconMap = {
  audio: 'fas fa-volume-up',
  video: 'fas fa-play',
  download: 'fas fa-download',
  text: 'fas fa-file-alt',
};
