angular.module('lwNamb').factory('taskListService', [
  '$rootScope',
  'api',
  'qt',
  'CacheFactory',
  'transformer',
  'uuid4',
  'alertService',
  'taskListApiService',
  'commandSubmissionService',
  'uploadService',
  function(
    $rootScope,
    api,
    qt,
    CacheFactory,
    transformer,
    uuid4,
    alertService,
    taskListApiService,
    commandSubmissionService,
    uploadService
  ) {
    var timeoutSeconds = 15;
    /*,
       personalTLCache = CacheFactory('personal-task-lists', {
       maxAge: 60 * 1000, // 1 minute
       deleteOnExpire: 'aggressive'
       }),
       assignedUTLCache = CacheFactory('assigned-user-task-lists', {
       maxAge: 60 * 1000, // 1 minute
       deleteOnExpire: 'aggressive'
       }),
       completeUTLCache = CacheFactory('complete-user-task-lists', {
       maxAge: 60 * 1000, // 1 minute
       deleteOnExpire: 'aggressive'
       }),
       orgAssignedUTLCache = CacheFactory('assigned-user-task-lists-per-org', {
       maxAge: 60 * 1000, // 1 minute
       deleteOnExpire: 'aggressive'
       }),
       tasklistsCache = CacheFactory('task-lists', {
       maxAge: 60 * 1000, // 1 minute
       deleteOnExpire: 'aggressive'
       }),
       tasklistCache = CacheFactory('task-list', {
       maxAge: 60 * 1000, // 1 minute
       deleteOnExpire: 'aggressive'
       });
       */
    var assignmentCache = CacheFactory('assignments', {
      maxAge: 60 * 1000, // 1 minute
      deleteOnExpire: 'aggressive',
    });

    return {
      getFeedbackTasksForOrg: function(orgId) {
        return api
          .get('/v1/organizations/' + orgId + '/user-tasklists?containsFeedbackTask=true&reduceData=true')
          .then(function(response) {
            return transformer.transformTaskListInstances(response.data.tasklists);
          });
      },
      getAvailableTasklistsForOrg: function(orgId, showUnPublished, filterOwned) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        getAndCacheTaskLists(orgId, showUnPublished, filterOwned).then(function(taskLists) {
          deferred.resolve(taskLists);
        });
        return deferred.promise;
      },
      getAssignments: function(orgId) {
        var deferred = qt.defer({ timeoutSeconds: 600 });
        // if(assignmentCache.get(orgId) !== undefined){
        //   deferred.resolve(assignmentCache.get(orgId));
        // }else {
        getAndCacheAssignments(orgId).then(function(assignments) {
          deferred.resolve(assignments);
        });
        // }
        return deferred.promise;
      },
      getCPCAAssignments: function() {
        return api.get('/v1/cpca-assignments').then(function(response) {
          return transformer.transformAssignments(response.data);
        });
      },
      getAssignmentsForUser: function(userId) {
        return getAssignmentsForUser(userId);
      },
      getAssignment: function(assignmentId, includeAssignmentDetail) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        getSingleAssignment(assignmentId, includeAssignmentDetail).then(function(assignment) {
          deferred.resolve(assignment);
        });
        // if(assignmentCache.get(orgId) !== undefined){
        //   var assignments = assignmentCache.get(orgId);
        //   getAssignmentById(assignments, assignmentId, deferred);
        // }else {
        //   getAndCacheAssignments(orgId).then(function(assignments){
        //     getAssignmentById(assignments, assignmentId, deferred);
        //   });
        // }
        return deferred.promise;
      },
      getTaskFromAssignment: function(assignment, taskId) {
        return getTaskFromAssignment(assignment, taskId);
      },
      getTasklist: function(tasklistId, preview, userId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
        getAndCacheTaskList(tasklistId, preview, userId).then(function(tasklist) {
          deferred.resolve(tasklist);
        });
        return deferred.promise;
      },
      createTasklist: function(ownerId, name, initiatingUserId, tasks) {
        var gettingTasklists,
          tasklistId = uuid4.generateId().id,
          deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        api
          .post('/v1/commands/CreateTaskList', {
            id: tasklistId,
            owner: ownerId,
            name: name,
            tasks: tasks ? tasks : [],
            reports: [],
            initiatingUserId: initiatingUserId,
          })
          .then(null, function failure(reason) {
            deferred.reject(reason);
          });

        $rootScope.$on('TasklistCreated', function(name, event) {
          if (event.id === tasklistId && !deferred.promise.isResolved()) {
            deferred.resolve(tasklistId);
          }
        });

        $rootScope.$on('TaskListNameDuplicateError', function(name, event) {
          if (event.id === tasklistId) {
            deferred.reject('DUPLICATE_NAME');
          }
        });

        // check for success by polling tasklists since TasklistCreated is not reliable
        deferred.promise.then(null, null, function(elapsedSeconds) {
          if (elapsedSeconds > 1 && !gettingTasklists) {
            gettingTasklists = true;
            getAndCacheTaskList(tasklistId).then(
              function(tasklist) {
                if (tasklist && tasklist.id === tasklistId) {
                  deferred.resolve(tasklistId);
                }
                gettingTasklists = false;
              },
              function() {
                gettingTasklists = false;
              }
            );
          }
        });

        return deferred.promise;
      },
      togglePublished: function(taskListId, published, initiatingUserId) {
        var gettingTasklist,
          shouldBePublished,
          deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
        if (published) {
          taskListApiService.publishTasklist(taskListId, initiatingUserId).then(
            function(taskListId) {
              deferred.resolve(taskListId);
            },
            function(reason) {
              deferred.reject(reason);
            }
          );

          shouldBePublished = true;
        } else {
          taskListApiService.unpublishTasklist(taskListId, initiatingUserId).then(
            function(taskListId) {
              deferred.resolve(taskListId);
            },
            function(reason) {
              deferred.reject(reason);
            }
          );

          shouldBePublished = false;
        }

        // check for success by polling tasklists since UnPublishTaskList is not reliable
        deferred.promise.then(null, null, function(elapsedSeconds) {
          if (elapsedSeconds > 1 && !gettingTasklist) {
            gettingTasklist = true;
            getAndCacheTaskList(taskListId).then(
              function(tasklist) {
                if (tasklist.published === shouldBePublished) {
                  gettingTasklist = false;
                  deferred.resolve(taskListId);
                } else {
                  gettingTasklist = false;
                }
              },
              function() {
                gettingTasklist = false;
              }
            );
          }
        });

        return deferred.promise;
      },
      completeTask: function(tasklistId, userId, taskInstance, deletedTask) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
        if (taskInstance.task) taskInstance.task.taskDescription = undefined;
        api
          .post('/v1/commands/CompleteTask', {
            id: tasklistId,
            assigneeId: userId,
            taskInstance: taskInstance,
            deleted: deletedTask ? deletedTask : false,
          })
          .then(null, function failure(reason) {
            deferred.reject(reason);
          });
        $rootScope.$on('TaskInstanceStatusInTaskListInstanceSetComplete', function(name, event) {
          if (event.id === tasklistId) {
            deferred.resolve();
          }
        });

        return deferred.promise;
      },
      setSuggestedTimeFrameForTaskList: function(tasklistId, days, initiatingUserId) {
        var gettingTasklist,
          deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        api
          .post('/v1/commands/SetSuggestedTimeFrameForTaskList', {
            id: tasklistId,
            days: days,
            initiatingUserId: initiatingUserId,
          })
          .then(null, function(reason) {
            deferred.reject(reason);
          });

        $rootScope.$on('SuggestedTimeFrameForTaskListSet', function(name, event) {
          if (event.id === tasklistId) {
            deferred.resolve(tasklistId);
          }
        });

        // check for success by polling tasklists since SuggestedTimeFrameForTaskListSet is not reliable
        deferred.promise.then(null, null, function(elapsedSeconds) {
          if (elapsedSeconds > 1 && !gettingTasklist) {
            gettingTasklist = true;
            getAndCacheTaskList(tasklistId).then(
              function(tasklist) {
                if (tasklist.suggestedTimeFrameDays === days) {
                  gettingTasklist = false;
                  deferred.resolve(tasklistId);
                } else {
                  gettingTasklist = false;
                }
              },
              function() {
                gettingTasklist = false;
              }
            );
          }
        });

        return deferred.promise;
      },
      unSetSuggestedTimeFrameForTaskList: function(tasklistId, initiatingUserId) {
        var gettingTasklist,
          deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        api
          .post('/v1/commands/UnSetSuggestedTimeFrameForTaskList', {
            id: tasklistId,
            initiatingUserId: initiatingUserId,
          })
          .then(null, function(reason) {
            deferred.reject(reason);
          });

        $rootScope.$on('SuggestedTimeFrameForTaskListUnSet', function(name, event) {
          if (event.id === tasklistId) {
            deferred.resolve(tasklistId);
          }
        });

        // check for success by polling tasklists since SuggestedTimeFrameForTaskListUnSet is not reliable
        deferred.promise.then(null, null, function(elapsedSeconds) {
          if (elapsedSeconds > 1 && !gettingTasklist) {
            gettingTasklist = true;
            getAndCacheTaskList(tasklistId).then(
              function(tasklist) {
                if (tasklist.suggestedTimeFrameDays === undefined) {
                  gettingTasklist = false;
                  deferred.resolve(tasklistId);
                } else {
                  gettingTasklist = false;
                }
              },
              function() {
                gettingTasklist = false;
              }
            );
          }
        });

        return deferred.promise;
      },

      setAssetIdInUploadTask: function(tasklistId, initiatingUserId, taskInstanceId, assetId) {
        return commandSubmissionService.submitAndWait(
          tasklistId,
          {
            id: tasklistId,
            initiatingUserId: initiatingUserId,
            taskInstanceId: { id: taskInstanceId },
            assetId: assetId,
          },
          'SetAssetIdInUploadTask',
          ['AssetIdSetInUploadTask', 'TaskInstanceStatusInTaskListInstanceSetComplete'],
          'TaskListError',
          undefined,
          undefined,
          true
        );
      },

      reorderTasks: function(taskListId, seqOfTasks, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        api
          .post('/v1/commands/ReorderTaskList', {
            id: taskListId,
            initiatingUserId: initiatingUserId,
            newSeqOfTasks: seqOfTasks,
          })
          .then(null, function(reason) {
            deferred.reject(reason);
          });

        $rootScope.$on('TaskListIsReordered', function(name, event) {
          if (event.id === taskListId && angular.equals(event.newSeqOfTasks, seqOfTasks)) {
            deferred.resolve();
          }
        });

        return deferred.promise;
      },

      updateName: function(taskListId, name, orgId, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        api
          .post('/v1/commands/UpdateTaskListName', {
            id: taskListId,
            name: name,
            owner: orgId,
            initiatingUserId: initiatingUserId,
          })
          .then(null, function(reason) {
            deferred.reject(reason);
          });

        $rootScope.$on('TaskListRenamed', function(name, event) {
          if (event.id === taskListId) {
            deferred.resolve();
          }
        });

        $rootScope.$on('TaskListNameDuplicateError', function(name, event) {
          if (event.id === taskListId) {
            deferred.reject('DUPLICATE_NAME');
          }
        });

        return deferred.promise;
      },

      updateDescription: function(taskListId, description, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        api
          .post('/v1/commands/SetDescriptionForTaskList', {
            id: taskListId,
            description: description,
            initiatingUserId: initiatingUserId,
          })
          .then(null, function(reason) {
            deferred.reject(reason);
          });

        $rootScope.$on('DescriptionForTaskListSet', function(name, event) {
          if (event.id === taskListId) {
            deferred.resolve();
          }
        });

        return deferred.promise;
      },
      setItemNumber: function(taskListId, itemNumber, initiatingUserId) {
        return commandSubmissionService.submitAndWait(
          taskListId,
          {
            id: taskListId,
            initiatingUserId: initiatingUserId,
            itemNumber: itemNumber,
          },
          'SetItemNumberToTasklist',
          'ItemNumberIsSetToTaskList',
          'TaskListError'
        );
      },
      unsetItemNumber: function(taskListId, itemNumber, initiatingUserId) {
        return commandSubmissionService.submitAndWait(
          taskListId,
          {
            id: taskListId,
            initiatingUserId: initiatingUserId,
          },
          'RemoveItemNumberFromTasklist',
          'ItemNumberRemovedFromTaskList',
          'TaskListError'
        );
      },
      clone: function(newId, fromId, newName, initiatingUserId, toOrgId) {
        var gettingTasklist = false,
          deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        api
          .post('/v1/commands/CloneTaskList', {
            id: newId,
            fromId: fromId,
            newName: newName,
            initiatingUserId: initiatingUserId,
            newOwner: toOrgId,
          })
          .then(null, function(reason) {
            deferred.reject(reason);
          });
        $rootScope.$on('TaskListCloned', function(name, event) {
          if (event.id === newId) {
            // check for success by polling tasklists b/c cloning can be too fast
            deferred.promise.then(null, null, function(elapsedSeconds) {
              if (elapsedSeconds > 1 && !gettingTasklist) {
                gettingTasklist = true;
                getAndCacheTaskList(newId).then(
                  function(tasklist) {
                    gettingTasklist = false;
                    deferred.resolve(newId);
                  },
                  function() {
                    gettingTasklist = false;
                  }
                );
              }
            });
          }
        });

        $rootScope.$on('TaskListFailedToClone', function(name, event) {
          if (event.id === newId) {
            deferred.reject('CLONE_ERROR: ' + event.error);
          }
        });

        $rootScope.$on('TaskListNameDuplicateError', function(name, event) {
          if (event.id === newId) {
            deferred.reject('DUPLICATE_NAME');
          }
        });

        return deferred.promise;
      },
      unAssignTraining: function(training, initiatingUserId) {
        var deferred = qt.defer({
            timeoutSeconds: 30,
          }),
          id = uuid4.generateId().id;

        assignmentCache.removeAll();

        if (!angular.isArray(training)) training = [training];
        removeAssignments(training, initiatingUserId).then(function(removedCount) {

          var assignables = [];
          training.filter(function(i) {
            return i.assignable.selfAssigned !== true;
          }).forEach(function(item) {
            if (item.assignable._type === 'AssignedTaskList') {
              assignables.push({
                taskListInstanceId: item.assignable.taskListInstanceId,
                _type: 'TaskList',
              });
            } else {
              assignables.push({
                workflowInstanceId: item.assignable.workflowInstanceId,
                _type: item.assignable._type,
              });
            }
          });
          if (assignables.length > 0) {
            api
              .post('/v1/commands/UnassignTraining', {
                id: id,
                assignables: assignables,
                initiatingUserId: initiatingUserId,
              })
              .then(null, function(reason) {
                deferred.reject(reason);
              });
            $rootScope.$on('AssignmentsRemoved', function(name, event) {
              deferred.resolve(event.successfullyRemoved + removedCount);
            });
          } else {
            deferred.resolve(removedCount);
          }
        }, function(reason){
          deferred.reject(reason);
        });

        return deferred.promise;
      },
      unassignMyTraining: function(training, trainingType, initiatingUserId) {
        var deferred = qt.defer({
            timeoutSeconds: timeoutSeconds,
          }),
          id = uuid4.generateId().id;

        var assignables = [];
        if (trainingType === 'TaskList') {
          assignables.push({
            taskListInstanceId: { id: training.id },
            _type: trainingType,
          });
        } else if (trainingType === 'Workflow') {
          assignables.push({
            workflowInstanceId: training.id,
            _type: trainingType,
          });
        }
        api
          .post('/v1/commands/UnassignTraining', {
            id: id,
            assignables: assignables,
            initiatingUserId: initiatingUserId,
          })
          .then(null, function(reason) {
            deferred.reject(reason);
          });
        $rootScope.$on('TrainingUnassigned', function(name, event) {
          if (event.id === id) {
            deferred.resolve();
          }
        });
        return deferred.promise;
      },
      deleteTaskList: function(taskListId, initiatingUserId, orgId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
        api
          .post('/v1/commands/DeleteTaskList', {
            id: taskListId,
            initiatingUserId: initiatingUserId,
            taskListId: taskListId,
            organizationId: orgId,
          })
          .then(null, function() {
            deferred.reject({ reason: 'unknown' });
          });
        $rootScope.$on('TaskListDeleted', function(name, event) {
          if (event.id === taskListId) {
            deferred.resolve();
          }
        });
        $rootScope.$on('DeleteTaskListFailed', function(name, event) {
          if (event.id === taskListId) {
            if (event.workflows.length > 0 && event.assignedCount > 0) {
              deferred.reject({
                reason: 'workflows-instances',
                workflows: event.workflows,
                assignedCount: event.assignedCount,
              });
            } else if (event.workflows.length > 0) {
              deferred.reject({ reason: 'workflows', workflows: event.workflows });
            } else if (event.assignedCount > 0) {
              deferred.reject({ reason: 'instances', assignedCount: event.assignedCount });
            } else if (event.groups.length > 0) {
              deferred.reject({ reason: 'groups', groups: event.groups });
            } else {
              deferred.reject({ reason: 'unknown' });
            }
          }
        });
        return deferred.promise;
      },
      addContentTasks: function(taskListId, tasksToAdd, order, _type, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
          tasks = [];

        //  case class ContentTask(taskId: String, taskName: String, content: String, _type: String, taskDescription: Option[String] = None){}
        //  case class AddTasksToTaskList(id: String, tasks: Set[ContentTask], order: Int, initiatingUserId: Option[String] = None) extends TaskCommand
        tasksToAdd.forEach(function(task) {
          tasks.push({
            taskId: uuid4.generate(),
            taskName: task.name,
            content: task.id,
            _type: _type,
          });
        });

        api
          .post('/v1/commands/AddTasksToTaskList', {
            id: taskListId,
            initiatingUserId: initiatingUserId,
            tasks: tasks,
            order: parseInt(order),
          })
          .then(null, function() {
            deferred.reject({ reason: 'unknown' });
          });

        $rootScope.$on('TasksAddedToTaskList', function(name, event) {
          if (event.id === taskListId) {
            deferred.resolve();
          }
        });

        $rootScope.$on('TasksFailedToAddToTaskList', function(name, event) {
          if (event.id === taskListId) {
            deferred.reject({ reason: 'unknown' });
          }
        });

        return deferred.promise;
      },
      addLinkTask: function(taskListId, taskName, taskDesc, link, taskType, order, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
          taskId = uuid4.generateId();
        //TODO: refactor this into a private method and have all the "Addxyz" call AddTaskToTaskList
        api
          .post('/v1/commands/AddTaskToTaskList', {
            id: taskListId,
            initiatingUserId: initiatingUserId,
            task: {
              _id: taskId,
              taskName: taskName,
              taskDescription: taskDesc,
              linkUrl: link,
              order: order,
              _type: taskType,
            },
          })
          .then(null, function() {
            deferred.reject();
          });
        $rootScope.$on('TaskAddedToTaskList', function(name, event) {
          if (event.id === taskListId && event.task._id.id === taskId.id) {
            deferred.resolve();
          }
        });
        $rootScope.$on('TaskListError', function(name, event) {
          if (event.id === taskListId && event.command.taskId === taskId) {
            deferred.reject();
          }
        });
        return deferred.promise;
      },
      AddTaskToTaskList: function(tlId, task, initiatingUserId, order) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
          cmd = {
            id: tlId,
            initiatingUserId: initiatingUserId,
            task: task,
          };

        cmd.task._id = uuid4.generateId();
        cmd.task.order = order ? order : -1;

        api.post('/v1/commands/AddTaskToTaskList', cmd).then(null, function() {
          deferred.reject();
        });
        $rootScope.$on('TaskAddedToTaskList', function(name, event) {
          if (event.id === cmd.id && event.task._id.id === cmd.task._id.id) {
            deferred.resolve();
          }
        });
        $rootScope.$on('TaskListError', function(name, event) {
          if (event.id === cmd.id && event.command.taskId === cmd.task._id) {
            deferred.reject();
          }
        });
        return deferred.promise;
      },
      addTextTask: function(id, taskId, taskName, taskDescription, order, initiatingUserId) {
        return processImages(taskDescription).then(function(updatedDescription) {
          return commandSubmissionService.submitAndWait(
            id,
            {
              id: id,
              taskId: taskId,
              taskName: taskName,
              taskDescription: updatedDescription,
              order: order,
              response: '',
              initiatingUserId: initiatingUserId,
            },
            'AddTextTask',
            'TextTaskIsAddedToTasklist',
            'TaskListError'
          );
        });

      },
      addUploadTask: function(id, taskId, taskName, taskDescription, order, initiatingUserId) {
        var cmd = {
          id: id,
          taskId: taskId,
          taskName: taskName,
          taskDescription: taskDescription,
          order: order,
          initiatingUserId: initiatingUserId,
        };
        return commandSubmissionService.submitAndWait(
          id,
          cmd,
          'AddUploadTask',
          'UploadTaskIsAddedToTasklist',
          'TaskListError'
        );
      },
      addDownloadTask: function(id, taskId, taskName, taskDescription, order, assetId, initiatingUserId) {
        var cmd = {
          id: id,
          taskId: taskId,
          taskName: taskName,
          taskDescription: taskDescription,
          order: order,
          assetId: assetId,
          initiatingUserId: initiatingUserId,
        };
        return commandSubmissionService.submitAndWait(
          id,
          cmd,
          'AddDownloadTask',
          'DownloadTaskIsAddedToTasklist',
          'TaskListError'
        );
      },
      addVideoTask: function(id, taskId, taskName, taskDescription, order, videoId, initiatingUserId) {
        var cmd = {
          id: id,
          taskId: taskId,
          taskName: taskName,
          taskDescription: taskDescription,
          order: order,
          videoId: videoId,
          initiatingUserId: initiatingUserId,
        };
        return commandSubmissionService.submitAndWait(
          id,
          cmd,
          'AddVideoTask',
          'VideoTaskIsAddedToTasklist',
          'TaskListError'
        );
      },
      addThirdPartyVideoTask: function(
        id,
        taskId,
        taskName,
        taskDescription,
        order,
        videoUrl,
        thirdPartySite,
        initiatingUserId
      ) {
        var cmd = {
          id: id,
          taskId: taskId,
          taskName: taskName,
          taskDescription: taskDescription,
          order: order,
          videoUrl: videoUrl,
          thirdPartySite: thirdPartySite,
          initiatingUserId: initiatingUserId,
        };
        return commandSubmissionService.submitAndWait(
          id,
          cmd,
          'AddThirdPartyVideoTask',
          'ThirdPartyVideoTaskIsAddedToTasklist',
          'TaskListError'
        );
      },
      setThirdPartyVideoUrl: function(id, taskId, videoUrl, thirdPartySite, initiatingUserId) {
        var cmd = {
          id: id,
          taskId: taskId,
          videoUrl: videoUrl,
          thirdPartySite: thirdPartySite,
          initiatingUserId: initiatingUserId,
        };
        return commandSubmissionService.submitAndWait(
          id,
          cmd,
          'SetThirdPartyVideoUrl',
          'ThirdPartyVideoUrlSet',
          'TaskListError'
        );
      },
      setLinkUrl: function(id, taskId, linkUrl, initiatingUserId) {
        var cmd = { id: id, taskId: taskId, linkUrl: linkUrl, initiatingUserId: initiatingUserId };
        return commandSubmissionService.submitAndWait(id, cmd, 'SetLinkUrl', 'LinkUrlSetForLinkTask', 'TaskListError');
      },
      setTaskNameAndDescription: function(id, taskId, taskName, taskDescription, initiatingUserId) {
        return processImages(taskDescription).then(function(updatedDescription) {
          return commandSubmissionService.submitAndWait(
            id,
            {
              id: id,
              taskId: taskId,
              taskName: taskName,
              taskDescription: updatedDescription,
              initiatingUserId: initiatingUserId
            },
            'SetTaskNameAndDescription',
            'NameAndDescriptionSetForTask',
            'TaskListError'
          );
        });
      },
      setCertificateForTaskList: function(taskListId, initiatingUserId) {
        var cmd = { id: taskListId, initiatingUserId: initiatingUserId };
        return commandSubmissionService.submitAndWait(
          taskListId,
          cmd,
          'SetCertificateForTaskList',
          'CertificateForTaskListSet',
          'TaskListError'
        );
      },
      unSetCertificateForTaskList: function(taskListId, initiatingUserId) {
        var cmd = { id: taskListId, initiatingUserId: initiatingUserId };
        return commandSubmissionService.submitAndWait(
          taskListId,
          cmd,
          'UnSetCertificateForTaskList',
          'CertificateForTaskListUnSet',
          'TaskListError'
        );
      },
      removeTask: function(id, taskId, initiatingUserId) {
        var cmd = { id: id, taskId: taskId, initiatingUserId: initiatingUserId };
        return commandSubmissionService.submitAndWait(
          id,
          cmd,
          'RemoveTaskFromTasklist',
          'TaskIsRemovedFromTasklist',
          'TaskListError'
        );
      },
      enableDiscussion: function(id, initiatingUserId) {
        var cmd = { id: id, initiatingUserId: initiatingUserId };
        return commandSubmissionService.submitAndWait(
          id,
          cmd,
          'EnableDiscussionForTaskList',
          'DiscussionForTaskListEnabled'
        );
      },
      disableDiscussion: function(id, initiatingUserId) {
        var cmd = { id: id, initiatingUserId: initiatingUserId };
        return commandSubmissionService.submitAndWait(
          id,
          cmd,
          'DisableDiscussionForTaskList',
          'DiscussionForTaskListDisabled'
        );
      },
      addCategory: function(id, category, oldCategory, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        if (oldCategory !== 'No Category') {
          removeCategory(id, oldCategory, initiatingUserId);
        }

        var cmd = { id: id, category: category, initiatingUserId: initiatingUserId };
        commandSubmissionService
          .submitAndWait(id, cmd, 'AddCategoryToTaskList', 'CategoryAddedToTaskList', 'TaskListError')
          .then(
            function(result) {
              deferred.resolve(result);
            },
            function(reason) {
              deferred.reject(reason);
            }
          );

        return deferred.promise;
      },
      removeCategory: function(id, category, initiatingUserId) {
        return removeCategory(id, category, initiatingUserId);
      },
      getCategories: function() {
        var deferred = qt.defer();
        deferred.resolve([
          'No Category',
          '90 Second Leadership',
          'Bible Studies for Life',
          'Church Ministry',
          'Church Planting and Multisite',
          'Church Safety and Security',
          'Conferences and Events',
          'Deacon Ministry',
          'Discipleship',
          'Explore the Bible',
          'Guest Services',
          'Kids Ministry',
          'Leadership',
          'Leadership Pipeline',
          'MOOC',
          'Marriage and Family Ministry',
          "Men's Ministry",
          'Missions and Evangelism',
          'Older Adult Ministry',
          'Pastoral Development',
          'Small Groups',
          'Student Ministry',
          'Sunday School',
          'The Gospel Project',
          "Women's Ministry",
          'Worship and Music Ministry',
        ]);
        return deferred.promise;
      },
    };

    function removeAssignments(assignments, initiatingUserId) {
      var deferred = qt.defer(), id = uuid4.generate(), selfAssigneds = assignments.filter(function(a) {
        return a.assignable.selfAssigned === true;
      });
      if (selfAssigneds.length === 0) {
        deferred.resolve(0);
      } else {
        commandSubmissionService.submitAndWait(
          id,
          {
            id: id,
            initiatingUserId: initiatingUserId,
            assignments: selfAssigneds.map(function(a) {
              return a.id;
            })
          },
          'RemoveAssignments',
          'AssignmentsRemoved',
          'AssignmentsError',
          undefined,
          undefined,
          false
        ).then(function(event) {
          deferred.resolve(event.successfullyRemoved);
        }, function(evt) {
          deferred.reject(evt);
        });
      }
      return deferred.promise;
    }

    function removeCategory(id, category, initiatingUserId) {
      var cmd = { id: id, category: category, initiatingUserId: initiatingUserId };
      return commandSubmissionService.submitAndWait(
        id,
        cmd,
        'RemoveCategoryFromTaskList',
        'CategoryRemovedFromTaskList',
        'TaskListError'
      );
    }

    function getSingleAssignment(assignmentId, includeAssignmentDetail) {
      return api.get('/v1/assignments/' + assignmentId).then(function(response) {
        return transformer.transformAssignment(response.data[0], includeAssignmentDetail);
      });
    }

    function getAndCacheAssignments(orgId) {
      return api.get('/v1/organizations/' + orgId + '/assignments').then(function(response) {
        var assignments = transformer.transformAssignments(response.data);
        assignmentCache.put(orgId, assignments);
        return assignments;
      });
    }

    function getAndCacheAssignedTaskListInstances(userId, orgId) {
      return api.get('/v1/organizations/' + orgId + '/users/' + userId + '/user-tasklists').then(function(response) {
        var assignedTaskInstancelists = transformer.transformTaskListInstances(response.data.assignedTasklists);
        //assignedUTLCache.put(userId+orgId, assignedTaskInstancelists);
        return assignedTaskInstancelists;
      });
    }

    function getAndCacheTaskLists(orgId, showUnPublished, filterOwned) {
      var url = '/v1/tasklists?orgId=' + orgId;
      if (showUnPublished !== undefined) {
        url = url + '&showUnPublished=' + showUnPublished;
      }
      if (filterOwned !== undefined) {
        url = url + '&filterOwned=' + filterOwned;
      }
      return api.get(url).then(function(response) {
        var allTasklists = transformer.transformTaskLists(response.data.availableTasklists);
        //tasklistsCache.put(orgId, allTasklists);
        return allTasklists;
      });
    }

    function getAssignmentsForUser(userId) {
      return api.get('/v1/assignments/users/' + userId).then(function(response) {
        return transformer.transformAssignmentsForUser(response.data);
      });
    }

    function getAndCacheTaskList(tasklistId, preview, userId) {
      var url = '/v1/tasklists/' + tasklistId;
      if (preview === true) {
        url = url + '?preview=true&key=' + userId;
      }
      return api.get(url).then(function(response) {
        var tasklist = transformer.transformTaskLists([response.data.tasklist])[0];
        //tasklistCache.put(tasklistId, tasklist);
        return tasklist;
      });
    }

    //      function getAssignmentById(assignments, assignmentId, deferred) {
    //        var found = false;
    //        for (var j = 0; j < assignments.length; j++) {
    //          if (assignments[j]._id === assignmentId) {
    //            deferred.resolve(assignments[j]);
    //            found = true;
    //            break;
    //          }
    //        }
    //        if (!found) {
    //          deferred.reject("Assignment not found");
    //        }
    //      }

    function getTaskFromAssignment(assignment, taskId) {
      function getPossibleTaskFromTaskList(items) {
        if (items !== undefined) {
          for (var i = 0; i < items.length; i++) {
            if ((items[i].id && taskId === items[i].id.id) || (items[i]._id && taskId === items[i]._id.id)) {
              return items[i];
            }
          }
        }
        return {};
      }

      if (assignment.assignable._type === 'AssignedTaskList') {
        return getPossibleTaskFromTaskList(assignment.assignable.tasks);
      } else if (assignment.assignable._type === 'Workflow') {
        for (var t = 0; t < assignment.assignable.tasklists.length; t++) {
          var possibleTask = getPossibleTaskFromTaskList(assignment.assignable.tasklists[t].tasks);
          if (Object.keys(possibleTask).length !== 0) {
            if (assignment.assignable.tasklists[t]._type === 'NotYetAssignedTaskList') {
              possibleTask = { task: possibleTask };
              possibleTask.status = 'not_started';
            }
            return possibleTask;
          }
        }
      }
      return {};
    }

    function processImages(description) {

      if (typeof description === 'string') {

        var splits = description.split('<img src="data:');

        if (splits.length > 1) {

          var start = splits.shift();

          var fs = splits.map(function(s) {

            var indexOfCloseQuote = s.indexOf('"');

            if (indexOfCloseQuote > 0) {
              var imageData = s.slice(0, indexOfCloseQuote);
              return uploadService.uploadTextTaskImage('data:' + imageData).then(function(url) {
                return '<img src="' + url + '" style="max-width:100%"' + s.slice(indexOfCloseQuote + 1);
              });
            } else {
              var d = qt.defer();
              d.resolve(s);
              return d.promise;
            }
          });

          return qt.all(fs).then(function(updatedSplits) {
            return start + updatedSplits.join('');
          });

        } else {
          return resolveInput();
        }
      } else {
        return resolveInput();
      }

      function resolveInput() {
        var deferred = qt.defer();
        deferred.resolve(description);
        return deferred.promise;
      }
    }
  },
]);
