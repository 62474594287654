angular.module('lwNamb').controller(
  'FeedbackCtrl',

  [
    '$scope',
    '$window',
    '$log',
    'userService',
    'taskListInstanceService',
    'windowService',
    '$routeParams',
    'alertService',
    function ($scope, $window, $log, userService, taskListInstanceService, windowService, $routeParams, alertService) {
      var init = function () {
        $scope.isLoading = true;
        $scope.taskId = $routeParams.id;
        $scope.taskListInstanceId = $routeParams.taskListInstanceId;
        $scope.isPersonal = $routeParams.isPersonal === 'true';
        $scope.feedbackForm = {};

        userService.user().then(
          function (user) {
            $scope.userId = user.userId;
            retrieveUserData();
          },
          function () {
            $log.error('No user returned in FeedbackCtrl');
          }
        );
      };

      var retrieveUserData = function () {
        // todo: refactor to taskListInstanceService.getTaskInstance
        taskListInstanceService.getByInstanceId($scope.taskListInstanceId).then(
          function (atl) {
            //using filter instead of find for tests
            var task = atl.tasks.filter(function (task) {
              return task.id.id === $scope.taskId;
            })[0];
            if (task !== undefined) {
              $scope.tlId = atl.id;
              $scope.task = task;
              $scope.taskInstance = task;
              $scope.requests = [];
              $scope.numCompleteResponses = $scope.task.task.feedbackResponses.filter(function (response) {
                return response.status === 'complete';
              }).length;
              $scope.numIncompleteResponses = $scope.task.task.numEmailsToGather - $scope.numCompleteResponses;
              for (var i = 0; i < task.task.numEmailsToGather; i++) {
                if (task.task.feedbackResponses[i]) {
                  $scope.requests.push(task.task.feedbackResponses[i]);
                } else {
                  $scope.requests.push({
                    email: '',
                    firstName: '',
                    status: '',
                    responseId: '',
                  });
                }
              }
              $scope.feedback = task.task;
              $scope.isLoading = false;
              $log.debug($scope.feedback);
            }
          },
          function (reason) {
            $log.error(reason);
            $scope.isLoading = false;
            $scope.isError = true;
          }
        );
      };

      $scope.resetFeedbackUser = function (feedbackUser) {
        taskListInstanceService
          .removeFeedbackResponseUser($scope.tlId, $scope.taskInstance, feedbackUser.email, $scope.userId)
          .then(
            function () {
              retrieveUserData();
              showAlert('Invitation cleared');
            },
            function (reason) {
              $log.error(reason);
              alertService.show({
                title: 'Error! Something went wrong.',
                type: 'danger',
                show: true,
                dismissible: true,
                duration: 20,
              });
            }
          );
      };

      $scope.resendFeedbackUser = function (feedbackUser) {
        taskListInstanceService
          .resendFeedbackUser(
            $scope.tlId,
            $scope.taskInstance,
            feedbackUser.email,
            feedbackUser.firstName,
            $scope.userId
          )
          .then(
            function () {
              showAlert('Invitation resent');
            },
            function (reason) {
              $log.error(reason);
              alertService.show({
                title: 'Error! Something went wrong.',
                type: 'danger',
                show: true,
                dismissible: true,
                duration: 20,
              });
            }
          );
      };

      var showAlert = function (message) {
        alertService.show({ title: message, type: 'success', show: true, dismissible: true, duration: 5 });
      };

      //Send Requests
      $scope.sendRequest = function (index, feedbackUser) {
        var setFeedbacktoNotStarted = function (index) {
          $scope.requests[index].status = 'not_started';
        };
        taskListInstanceService
          .addFeedbackResponseUser(
            $scope.tlId,
            $scope.taskInstance,
            feedbackUser.email,
            feedbackUser.firstName,
            window.location.origin,
            $scope.userId
          )
          .then(
            function () {
              setFeedbacktoNotStarted(index);
              showAlert('Invitation sent');
            },
            function (reason) {
              $log.error(reason);
              alertService.show({
                title: 'Error! Something went wrong.',
                type: 'danger',
                show: true,
                dismissible: true,
                duration: 20,
              });
            }
          );
      };

      //Mark Feedback Task Complete
      $scope.markFeedbackTaskComplete = function () {
        taskListInstanceService
          .forceCompleteFeedback($scope.tlId, $scope.taskInstance.id, $scope.userId, $scope.userId)
          .then(
            function (completedFeedback) {
              $scope.task.status = 'complete';
              var label = 'step';
              showAlert(label + ' completed');
            },
            function (reason) {
              $log.error(reason);
              alertService.show({
                title: 'Error! Something went wrong.',
                type: 'danger',
                show: true,
                dismissible: true,
                duration: 20,
              });
            }
          );
      };

      $scope.redirectToPrevious = function () {
        if ($routeParams.isPersonal && $scope.isPersonal) {
          windowService.redirectHash('#/training-viewer/course/' + $scope.taskListInstanceId);
        } else {
          $window.history.back();
        }
      };

      init();
    },
  ] //End Feedback Ctrl
);
