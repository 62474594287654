import React, { useEffect, useState } from 'react';
import StepMenu from './StepMenu';
import assessmentService from '../services/assessmentService';
import trainingInstanceService from '../services/trainingInstanceService';
import DeletedStep from './DeletedStep';
import ErrorMessage from '../components/ErrorMessage';
import AssessmentViewer from './assessments/AssessmentViewer';
import { useSteps, useCompleteStep, updateCurrentStepInCourse, addAssessmentTaskResponseId } from './StepsContext';

export default function AssessmentStep() {
  const { completeStep } = useCompleteStep();
  const [{ currentCourse, currentStep }, dispatch] = useSteps();
  const [showTaker, setShowTaker] = useState(false);
  const [assessment, setAssessment] = useState(null);
  const [isDeletedAssessment, setIsDeletedAssessment] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [allAnswered, setAllAnswered] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (isDeletedAssessment) completeStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeletedAssessment]);

  const resetState = () => {
    setShowTaker(false);
    setAssessment(null);
    setIsDeletedAssessment(false);
    setProgress(0);
    setShowResults(false);
    setAllAnswered(false);
  };

  const startAssessment = () => {
    setAssessment(null);

    return trainingInstanceService
      .startStep(currentCourse.id, currentStep.assignorId.id, currentStep.assigneeId.id, currentStep)
      .then(() => updateCurrentStepInCourse(dispatch, { newStatus: 'in_progress' }))
      .then(() => {
        assessmentService
          .getAssessmentResponseWithQuestions(currentStep.task.responseId)
          .then(handleAssessmentSupplimentResponse);
      })
      .then(() => setShowTaker(true))
      .catch(console.error);
  };

  const isAssessmentStuck = (responseCode, stepStatus) => responseCode == '404' && stepStatus === 'in_progress';

  const shouldShowResults = response =>
    response.responseVisibility === 'ALL' &&
    ((currentStep.status === 'in_progress' && response.allAnswered) || currentStep.status === 'complete');

  const handleAssessmentSupplimentResponse = response => {
    setAssessment(response);
    setProgress(response.progress);
    setShowResults(shouldShowResults(response));
    setAllAnswered(!!response?.allAnswered);
  };

  const handleAllQuestionsAnswered = () => {
    setAssessment(null);
    assessmentService
      .getAssessmentResults(currentStep.task.responseId)
      .then(response => {
        handleAssessmentSupplimentResponse(response);
        updateCurrentStepInCourse(dispatch);
      })
      .catch(console.error);
  };

  const handleError = error => {
    console.error(error);
    setHasError(true);
  };

  useEffect(() => {
    resetState();

    assessmentService
      .getAssessment(currentStep.task.assessmentId, currentStep.assigneeId.id)
      .then(assessmentResponse => {
        if (assessmentResponse.deleted) {
          if (currentStep.status === 'not_started') {
            addAssessmentTaskResponseId(dispatch);
          }

          setIsDeletedAssessment(true);
          setAssessment(assessmentResponse);
          return;
        }

        if (currentStep.status === 'not_started') {
          addAssessmentTaskResponseId(dispatch);
          setAssessment(assessmentResponse);
          return;
        }

        if (currentStep.status === 'in_progress') {
          assessmentService
            .getAssessmentResponseWithQuestions(currentStep.task.responseId)
            .then(handleAssessmentSupplimentResponse)
            .catch(error => {
              if (isAssessmentStuck(error.response.status, currentStep.status)) {
                trainingInstanceService
                  .setStepToNotStarted(currentCourse.id, currentStep.id.id)
                  .then(() => {
                    addAssessmentTaskResponseId(dispatch, { newStatus: 'not_started' });
                    setAssessment(assessmentResponse);
                  })
                  .catch(handleError);
              } else {
                handleError(error);
              }
            });

          return;
        }

        assessmentService
          .getAssessmentResults(currentStep.task.responseId)
          .then(handleAssessmentSupplimentResponse)
          .catch(handleError);
      })
      .catch(handleError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep.id.id]);

  return (
    <>
      {hasError ? (
        <ErrorMessage>
          A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
        </ErrorMessage>
      ) : isDeletedAssessment && assessment ? (
        <DeletedStep
          deletedMessage={`This assessment has been removed by an admin in ${assessment.deletedBy}.`}
          isComplete={currentStep.status === 'complete'}
        />
      ) : (
        <>
          <AssessmentViewer
            assessment={assessment}
            progress={progress}
            setShowTaker={setShowTaker}
            showTaker={showTaker}
            step={currentStep}
            startAssessment={startAssessment}
            completeAssessment={completeStep}
            takerId={currentStep.assigneeId.id}
            handleAllQuestionsAnswered={handleAllQuestionsAnswered}
            allAnswered={allAnswered}
          />
          {showResults && assessment && (
            <StepMenu name="Results" content={assessment.questions} disableDiscussion={true} type="results" />
          )}
        </>
      )}
    </>
  );
}
