import React from 'react';
import { FacetListContainer, FacetListSidebarOverlay, FacetListSidebarHeader } from './SearchElements';

type Props = {
  show: boolean;
  close: () => void;
  children: React.ReactNode;
};

export const FacetListSidebar = ({ show = false, close = () => {}, children }: Props) => {
  return (
    <>
      <FacetListContainer show={show}>
        <FacetListSidebarHeader>
          <i className="fas fa-sliders-h" /> Filters:
        </FacetListSidebarHeader>
        {children}
      </FacetListContainer>
      {show && <FacetListSidebarOverlay onClick={close} />}
    </>
  );
};
