import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { MediumPrimaryButton, MediumButton } from './Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from './ModalElements';
import { FormFieldMaxCharCountStatus } from './FormElements';

const EditTextModal = ({ label, value, handleSubmit, handleDismiss, isEdit = true, maxLength = 48, children }) => (
  <ModalContainer dismissHandler={handleDismiss}>
    <ModalHeader>
      <h3 data-qa-hook="editTextModalHeader">{`${isEdit ? 'Edit' : 'Add'} ${label}`}</h3>
    </ModalHeader>
    <Formik
      initialValues={{ value: value }}
      validationSchema={Yup.object({
        value: Yup.string().trim().required(`${label} is required`),
      })}
      onSubmit={values => handleSubmit(values.value)}
    >
      {({ isSubmitting }) => (
        <Form>
          <ModalBody data-qa-hook="editTextModalMessage">
            {children}
            <FormFieldMaxCharCountStatus
              label={label}
              name="value"
              type="text"
              placeholder={`Enter ${label}`}
              maxLength={maxLength}
            />
          </ModalBody>
          <ModalFooter>
            <MediumPrimaryButton
              data-qa-hook="editTextModalConfirm"
              type="submit"
              disabled={isSubmitting}
              operating={isSubmitting}
            >
              {isEdit ? 'Save' : 'Add'}
            </MediumPrimaryButton>
            <MediumButton data-qa-hook="editTextModalCancel" onClick={handleDismiss}>
              Cancel
            </MediumButton>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  </ModalContainer>
);

EditTextModal.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  maxLength: PropTypes.number,
};

export default EditTextModal;
