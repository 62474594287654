angular.module('lwNamb').factory(
  'orgUserService',

  [
    'api',
    'apiUrlService',
    function(api, apiUrlService) {
      var invitedMembers = [];

      return {
        getMembers: function(orgId, optionalFilter) {
          return api.get('/v1/organizations/' + orgId + '/users').then(function success(response) {
            var users = response.data.org.users;
            if (optionalFilter) {
              users = users.filter(optionalFilter);
            }
            users = users.map(transformUser);
            return {
              all: users,
              active: users.filter(function(member) {
                return member.active;
              }),
              filters: getFilters(users),
              groups: response.data.org.groups.filter(groupsWhichInclude(users)).sort(function(a, b) {
                if (a.name > b.name) return 1;
                else if (a.name < b.name) return -1;
                else return 0;
              }),
            };
          });
        },
        getMember: function(member) {
          // `member` can be either an id or, if it is a Pending User, an email address
          // (For the Organization that the requester is currently in)
          return api.get('/v1/organizations/users/' + member).then(function success(response) {
            var user = response.data.user;
            user.origin = response.data.origin;
            return transformUser(user);
          });
        },
        getMemberByEmail: function(email) {
          return api.get('/v1/organizations/users/' + email + '/email').then(
            function success(response) {
              return response.data;
            },
            function error(reason) {
              return reason;
            }
          );
        },
        pairTasklistsToMembers: function(members, tasklists, calculateProgress) {
          var tasklistsForMembers = [];
          for (var i = 0; i < tasklists.length; i++) {
            for (var j = 0; j < members.length; j++) {
              if (tasklists[i].assignedUser === members[j]._id.id) {
                tasklists[i].member = members[j];
                if (calculateProgress !== false) {
                  tasklists[i].progress =
                    tasklists[i].tasks.filter(function(t) {
                      return t.status === 'complete';
                    }).length / tasklists[i].tasks.length;
                }
                tasklistsForMembers.push(tasklists[i]);
              }
            }
          }
          return tasklistsForMembers;
        },
        addInvitedMembers: function(members) {
          if (window.cacheService) window.cacheService.removeAll();

          if (!Array.isArray(members)) members = [members];

          invitedMembers = invitedMembers.concat(
            members
              .filter(function(m) { return !!m.email; })
              .map(function(m) {
                m._type = 'PendingUser';
                return m;
              })
          );
        },
        getInvitedMembers: function() {
          return invitedMembers;
        }
      };

      function transformUser(user) {
        // Is Pending User?
        if (user.emailAddress) {
          user._id = { id: user.emailAddress };
          user.email = user.emailAddress;
        }
        user.name = user.firstName ? user.firstName + ' ' + user.lastName : null;
        user.profileImageUrl =
          user.profile !== undefined && user.profile.imageUrl
            ? apiUrlService.getUrl() + '/v1/images/profile/' + user._id.id + 'profile.png'
            : undefined;
        user.groupNames = user.groups; // todo: standardize all group dropdowns
        // todo: remove any parts of this that are dead code
        if (!user.roles) {
          user.roles = [{ name: '' }];
        } else {
          var roleNames = [];
          user.roles.map(function(role) {
            roleNames.push(role.name);
            var roleType = 'success';
            if (role.name === 'Candidate') {
              roleType = 'primary';
            }
            role.label = roleType;
          });
          user.roleNames = roleNames;
        }
        return user;
      }

      function getFilters(users) {
        return users.reduce(function(filters, user) {
          if (!user.customFields) {
            user.customFields = [];
          }

          var userFilters = user.customFields
            .map(function(group) {
              return group.fields.map(function(field) {
                return { header: group.name, name: field.name };
              });
            })
            .reduce(function(a, b) {
              return a.concat(b);
            }, []);

          return filters.concat(
            [],
            userFilters.filter(function(userFilter) {
              return !filters.find(function(filter) {
                return filter.header === userFilter.header && filter.name === userFilter.name;
              });
            })
          );
        }, []);
      }

      function groupsWhichInclude(users) {
        return function(group) {
          return (
            users.findIndex(function(user) {
              if (!user.groups) {
                return false;
              }
              return (
                user.groups.findIndex(function(id) {
                  return id === group.id;
                }) !== -1
              );
            }) !== -1
          );
        };
      }
    },
  ]
);
