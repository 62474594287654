angular.module('lwNamb').controller(
  'RemovePeopleCtrl',

  [
    '$scope',
    '$log',
    'alertService',
    'userService',
    'orgService',
    'orgUserService',
    function($scope, $log, alertService, userService, orgService, orgUserService) {
      var orgId, initiatingUserId;

      var init = function() {
        $scope.loading = true;
        $scope.selectedItems = [];
        $scope.submitted = false;
        $scope.isRemoving = false;
        $scope.clearInput();

        userService.user().then(function(user) {
          orgId = user.lastSelectedAccount;
          initiatingUserId = user.userId;
          orgService.getOrg(orgId).then(function(org) {
            $scope.rolesDropdown = org.rolesDropdown;
            orgUserService
              .getMembers(orgId, function(member) {
                return member._id !== undefined && member._id.id !== org.ownerId;
              })
              .then(
                function(members) {
                  $scope.members = members.active;
                  $scope.groups = [].concat(members.groups);
                  $scope.loading = false;
                },
                function(reason) {
                  $log.error(reason);
                  $scope.loading = false;
                }
              );
          });
        });
      };

      $scope.clearInput = function() {
        $scope.filter = { search: '', group: '', role: '' };
      };

      $scope.removeFromOrg = function(selectedItems, closeModal) {
        $scope.isRemoving = true;
        var p = orgService.removeMembers(orgId, selectedItems, initiatingUserId);
        p.then(
          function() {
            alertService.show({ type: 'success', title: 'Member(s) removed', duration: 5 });
            $scope.members = $scope.members.filter(function(member) {
              return $scope.selectedItems.indexOf(member) === -1;
            });
            $scope.selectedItems = [];
          },
          function(response) {
            if (response === 'NoAdminRemaining') {
              alertService.show({
                type: 'danger danger-with-content',
                title: 'Removal not permitted!',
                content: 'Sorry! Removing this person would not leave any users with the admin permission.',
                duration: 20,
              });
            } else {
              $log.error('removeFromOrg failed: ' + response);
              alertService.show({
                type: 'danger danger-with-content',
                title: 'Unexpected Error!',
                content: 'It seems something went wrong, please try again.',
                duration: 20,
              });
            }
          }
        );
        p.finally(function() {
          closeModal();
          $scope.isRemoving = false;
        });
      };

      init();
    },
  ]
);
