angular.module('lwNamb').directive(
  'taskViewerDownload',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/task-viewer-download.html',
        scope: {
          task: '=',
          taskStatus: '=?',
          isTaker: '=?',
          isPreview: '=?',
          colClass: '@',
        },
        replace: true,
        controller: [
          '$scope',
          '$log',
          'fileUrlService',
          function($scope, $log, fileUrlService) {
            var loadData = function() {
              fileUrlService.getFileMetaData($scope.task.assetUrl).then(
                function(response) {
                  var metaData = response.fileMetaData;
                  $scope.metaData = metaData;
                },
                function(response) {
                  $log.error(response);
                }
              );
            };

            $scope.openFile = function() {
              fileUrlService.openFile($scope.metaData._id.id, $scope.metaData.fileName);
            };

            loadData();
          },
        ],
      };
    },
  ]
);
