angular.module('lwNamb').directive(
  'taskViewerPrepareEnrich',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/task-viewer-prepare-enrich.html',
        scope: {
          task: '=',
          taskListId: '@',
          colClass: '@',
        },
        replace: true,
      };
    },
  ]
);
