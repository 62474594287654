import React from 'react';
import { useParams } from 'react-router-dom';
import windowService from '../services/windowService';
import Breadcrumbs from '../components/Breadcrumbs';
import { PartialSearchResultItem } from '../search';
import { localStorage, sessionStorage } from '../utils/storageUtils';
import { AddTraining } from './components/AddTraining';

type UrlParams = {
  atIndex: string;
  id?: string;
};

export const AddTrainingToCompilation = () => {
  const { id } = useParams<UrlParams>();

  const compilationName = id && sessionStorage.getItem('createCompilation')?.name;
  const compilationPath = `#/manage-catalog/compilation${id ? `/${id}` : ''}`;

  const redirectToManageCatalog = () => {
    windowService.redirectTo(compilationPath);
  };

  const addTraining = (selectedTraining: PartialSearchResultItem[]) => {
    localStorage.setItem('compilationTrainingItems', selectedTraining);
    redirectToManageCatalog();
  };

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Training Compilations', route: '#/manage-catalog/compilations' },
    { name: compilationName || 'New Training Compilation', route: compilationPath },
    { name: 'Add Training' },
  ];

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <AddTraining
        onAddSelectedTraining={addTraining}
        preselectedTraining={sessionStorage.getItem('createCompilation')?.trainingItems}
      />
    </>
  );
};
