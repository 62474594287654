const StepIcons = {
  Video: 'fas fa-play',
  YouTube: 'fas fa-play',
  Vimeo: 'fas fa-play',
  Audio: 'fas fa-volume-up',
  ThirdPartyVideo: 'fas fa-play',
  Download: 'fas fa-download',
  Link: 'fas fa-link',
  MyWSBLink: 'fas fa-link',
  Upload: 'fas fa-upload',
  Assessment: 'fas fa-pencil-alt',
  PrepareEnrich: 'fas fa-pen-square',
  Text: 'fas fa-align-center',
  Feedback: 'fas fa-envelope',
  Locked: 'fas fa-lock',
  Complete: 'fas fa-check-circle color-complete',
};

export default StepIcons;
