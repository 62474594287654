type ReorderedFn<T> = (newItems: T[], originalItems: T[]) => void;
type CurriedReorderFn = (args: { oldIndex: number; newIndex: number }) => void;

export function reorderSortableItems<T>(items: T[], onReordered: ReorderedFn<T>): CurriedReorderFn {
  return ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) return;

    const originalItems = [...items];
    const newItems = [...items];
    newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);

    onReordered(newItems, originalItems);
  };
}
