angular.module('lwNamb').factory('iconForType', [
  function() {
    return {
      with: function(item, fieldName) {
        if (item !== undefined && item[fieldName] !== undefined) {
          if (item[fieldName] === 'Assessment') {
            return 'icon ion-ios-compose';
          } else if (item[fieldName] === 'Feedback') {
            return 'fas fa-envelope';
          } else if (item[fieldName] === 'Link') {
            return 'icon ion-link';
          } else if (item[fieldName] === 'MyWSBLink') {
            return 'icomoon-myWSB';
          } else if (item[fieldName] === 'Text') {
            return 'icon ion-android-list';
          } else if (item[fieldName] === 'Upload') {
            return 'icon ion-ios-upload';
          } else if (item[fieldName] === 'Download') {
            return 'icon ion-ios-download';
          } else if (item[fieldName] === 'Video' || item[fieldName] === 'ThirdPartyVideo') {
            return 'icon ion-ios-play';
          } else if (item[fieldName] === 'Audio') {
            return 'fas fa-volume-up';
          } else {
            return 'icon ion-ios-compose';
          }
        }
      },
    };
  },
]);
