angular.module('lwNamb').controller(
  'AssessTakerCtrl',

  [
    '$rootScope',
    '$scope',
    '$timeout',
    '$routeParams',
    '$location',
    '$log',
    'assessBuilderService',
    'assessTakerService',
    'taskListInstanceService',
    'windowService',
    'blockUX',
    'State',
    'taskListService',
    'userService',
    function(
      $rootScope,
      $scope,
      $timeout,
      $routeParams,
      $location,
      $log,
      assessBuilderService,
      assessTakerService,
      taskListInstanceService,
      windowService,
      blockUX,
      State,
      taskListService,
      userService
    ) {
      var assessmentState = State('assessmentRedirects');

      $scope.taskListInstanceId = $routeParams.taskListInstanceId;
      $scope.taskInstanceId = $routeParams.taskInstanceId;

      var init = function() {
        $scope.loading = true;
        var saved = assessmentState.get();
        $scope.lastRootView = saved.lastRootView; //for showing Return to My Tasks button on assessment complete

        //Set Question & Answer
        $scope.question = {};
        $scope.answer = {};
        $scope.sortAnswers = [];
        $scope.selected_answer = {};
        $scope.index = '';

        //Set Question Index
        $scope.question_index = 0;

        userService.user().then(
          function(user) {
            $scope.user = user;
            //Get Assessment
            assessBuilderService.getAssessment($routeParams.id, $scope.user.userId).then(
              function(response) {
                $scope.assessment = response;
                $scope.assessment.isExpanded = false;
                retrieveUserData();
              },
              function(reason) {
                $log.error(reason);
              }
            );
          },
          function() {
            $log.error('No user returned in AssessTakerCtrl');
          }
        );
      };

      var retrieveUserData = function() {

          taskListInstanceService.getByInstanceId($scope.taskListInstanceId).then(
            function(tasklist) {
              var task;

              if (tasklist.tasks) {
                task = tasklist.tasks.filter(function(task) {
                  return task.id.id === $scope.taskInstanceId;
                })[0];
              }

              if (task !== undefined) {
                $scope.loading = false;
                $scope.taskListName = tasklist.name;
                $scope.task = task;
                if ($scope.task.status === 'complete') {
                  $scope.redirectToViewer();
                } else {
                  //Set Response Id to the Task Response Id to use correct Assessment Response
                  $scope.assessmentResponseId = $scope.task.task.responseId;
                  if ($scope.assessmentResponseId === undefined || $scope.assessmentResponseId === '') {
                    $scope.redirectToViewer();
                  } else {
                    $scope.retrieveAssessmentResponse();
                  }
                }
              } else {
                $scope.redirectToViewer();
              }
            },
            function(reason) {
              $scope.loading = false;
              $log.error(reason);
            }
          );

      };

      $scope.retrieveAssessmentResponse = function() {
        var assesseeID = $scope.user.userId;
        //Retrieve Assessment Response
        assessTakerService.getAssessmentResponse(assesseeID, $routeParams.id, $scope.assessmentResponseId).then(
          function(response) {
            $scope.assessmentResponse = response;

            //Set the Question Index to resume Assessment on
            if (
              $scope.assessmentResponse.answeredQuestions &&
              $scope.assessmentResponse.answeredQuestions.length >= $scope.assessment.questions.length
            ) {
              if ($scope.task.status === 'complete') {
                $scope.redirectToViewer();
              } else {
                $scope.completeAssessment();
              }
            } else {
              findNextUnansweredQuestion();
            }
          },
          function(reason) {
            $log.error(reason);
          }
        );
      };

      $scope.toggleSpinner = function() {
        if ($scope.question_index < $scope.assessment.questions.length) {
          $scope.spinner = !$scope.spinner;
        }
      };

      var setupDate = function(date) {
        var d = {};
        d.year = date.getFullYear();
        d.month = date.getMonth() + 1; //uses 0 based month structure
        d.dayOfMonth = date.getDate();
        d.timeZone = -date.getTimezoneOffset() / 60;
        return d;
      };

      $scope.selectAnswer = function(question, answer, index) {
        $scope.question = question;
        $scope.selected_answer.index = index;
        if ($scope.question.questionType === 'radio' || $scope.question.questionType === 'textbox') {
          $scope.answer = answer;
        }
        if ($scope.question.questionType === 'sort') {
          $scope.setSortAnswers();
        }
      };

      var findNextUnansweredQuestion = function() {
        $scope.selected_answer.index = undefined;
        $scope.spinner = false;
        $scope.answeredQuestions = [];
        var firstUnanswered;
        for (var i = 0; i < $scope.assessment.questions.length; i++) {
          $scope.answeredQuestions.push({ question: $scope.assessment.questions[i], answer: {} });
          if (
            $scope.assessment.questions[i].questionType === 'radio' ||
            $scope.assessment.questions[i].questionType === 'multiselectandinput'
          ) {
            $scope.answeredQuestions[i].answer.possibleAnswers = $scope.assessment.questions[i].possibleAnswers;
          }
        }
        if (
          $scope.assessmentResponse.answeredQuestions === undefined ||
          $scope.assessmentResponse.answeredQuestions.length === 0
        ) {
          $scope.question_index = 0;
        } else {
          var questionIDs = [];
          for (var qs = 0; qs < $scope.assessment.questions.length; qs++) {
            questionIDs.push($scope.assessment.questions[qs].id.id);
          }

          for (var qids = 0; qids < questionIDs.length; qids++) {
            var answered = false;
            for (var aqids = 0; aqids < $scope.assessmentResponse.answeredQuestions.length; aqids++) {
              if (questionIDs[qids] === $scope.assessmentResponse.answeredQuestions[aqids].questionId.id) {
                answered = true;
                if ($scope.assessment.questions[qids].questionType === 'radio') {
                  for (var j = 0; j < $scope.answeredQuestions[qids].answer.possibleAnswers.length; j++) {
                    if (
                      $scope.answeredQuestions[qids].answer.possibleAnswers[j].id.id ===
                      $scope.assessmentResponse.answeredQuestions[aqids].possibleAnswerIds[0].id
                    )
                      $scope.answeredQuestions[qids].answer.possibleAnswers[j].selected = true;
                  }
                } else if ($scope.assessment.questions[qids].questionType === 'multiselectandinput') {
                  for (var k = 0; k < $scope.answeredQuestions[qids].answer.possibleAnswers.length; k++) {
                    if ($scope.assessmentResponse.answeredQuestions[aqids].possibleAnswerIds !== undefined) {
                      for (
                        var l = 0;
                        l < $scope.assessmentResponse.answeredQuestions[aqids].possibleAnswerIds.length;
                        l++
                      ) {
                        if (
                          $scope.answeredQuestions[qids].answer.possibleAnswers[k].id.id ===
                          $scope.assessmentResponse.answeredQuestions[aqids].possibleAnswerIds[l].id
                        )
                          $scope.answeredQuestions[qids].answer.possibleAnswers[k].selected = true;
                      }
                    }
                    if ($scope.assessmentResponse.answeredQuestions[aqids].answerText === undefined) {
                      if (
                        $scope.answeredQuestions[qids].answer.possibleAnswers[k].value.length === 0 ||
                        $scope.answeredQuestions[qids].answer.possibleAnswers[k].value[0].localeCode === undefined
                      ) {
                        $scope.answeredQuestions[qids].answer.possibleAnswers[k].selected = false;
                        $scope.answeredQuestions[qids].answer.possibleAnswers[k].value = [];
                      }
                    } else {
                      if ($scope.answeredQuestions[qids].answer.possibleAnswers[k].value.length === 0) {
                        $scope.answeredQuestions[qids].answer.possibleAnswers[k].selected = true;
                        $scope.answeredQuestions[qids].answer.possibleAnswers[k].value.push({
                          value: $scope.assessmentResponse.answeredQuestions[aqids].answerText,
                        });
                      }
                    }
                  }
                } else if ($scope.assessment.questions[qids].questionType === 'date') {
                  if ($scope.assessmentResponse.answeredQuestions[aqids].answerText !== undefined)
                    $scope.answeredQuestions[qids].answer.value = new Date(
                      $scope.assessmentResponse.answeredQuestions[aqids].answerText
                    );
                } else if ($scope.assessment.questions[qids].questionType === 'textbox') {
                  if ($scope.assessmentResponse.answeredQuestions[aqids].answerText !== undefined)
                    $scope.answeredQuestions[qids].answer.value =
                      $scope.assessmentResponse.answeredQuestions[aqids].answerText;
                } else {
                  $log.debug('uhoh ' + $scope.assessment.questions[qids].questionType);
                }
              }
            }
            if (!answered && firstUnanswered === undefined) {
              $scope.question_index = qids;
              firstUnanswered = qids;
            }
          }
        }
        if ($scope.assessment.questions[$scope.question_index].questionType === 'sort') {
          $scope.selectAnswer(
            $scope.assessment.questions[$scope.question_index],
            $scope.assessment.questions[$scope.question_index].possibleAnswers,
            $scope.question_index
          );
        }
        window.scrollTo(0, 0);
      };

      $scope.nextQuestion = function() {
        $scope.spinner = true;
        if ($scope.question.questionType === 'radio') {
          $scope.saveRadio();
        }
        if ($scope.question.questionType === 'sort') {
          $scope.saveSort();
        }
        if ($scope.question.questionType === 'textbox' || $scope.question.questionType === 'textfield') {
          $scope.saveTextBox();
        }
      };

      var advanceQuestion = function() {
        if (
          $scope.assessmentResponse.answeredQuestions !== undefined &&
          $scope.assessmentResponse.answeredQuestions.length >= $scope.assessment.questions.length
        ) {
          $scope.completeAssessment();
        } else if (
          $scope.assessmentResponse.answeredQuestions === undefined ||
          $scope.assessmentResponse.answeredQuestions.length < $scope.assessment.questions.length
        ) {
          $scope.retrieveAssessmentResponse();
        }
      };

      $scope.previousQuestion = function() {
        if ($scope.question_index >= $scope.assessment.questions.length - 1) {
          $scope.question_index = 0;
        } else {
          $scope.question_index--;
          window.scrollTo(0, 0);
        }
      };

      //Forced Rank Questions
      $scope.setSortAnswers = function() {
        for (var i = 0; i < $scope.assessment.questions[$scope.question_index].possibleAnswers.length; i++) {
          $scope.sortAnswers[i] = $scope.assessment.questions[$scope.question_index].possibleAnswers[i].id;
        }
      };

      $scope.moveDown = function(from, to) {
        $scope.assessment.questions[$scope.question_index].possibleAnswers.splice(
          to,
          0,
          $scope.assessment.questions[$scope.question_index].possibleAnswers.splice(from, 1)[0]
        );
        $scope.setSortAnswers();
      };

      $scope.moveUp = function(from, to) {
        $scope.assessment.questions[$scope.question_index].possibleAnswers.splice(
          to,
          0,
          $scope.assessment.questions[$scope.question_index].possibleAnswers.splice(from, 1)[0]
        );
        $scope.setSortAnswers();
      };

      //Send Save Answer Command
      $scope.saveRadio = function() {
        assessTakerService
          .saveRadio(
            $scope.assessmentResponseId,
            $scope.assessment.id,
            $scope.question.id,
            $scope.answer.id,
            $scope.user.userId
          )
          .then(
            function() {
              advanceQuestion();
            },
            function() {
              $scope.retrieveAssessmentResponse();
            }
          );
      };

      //Send Save Answer Command For Text Box
      $scope.saveTextBox = function() {
        assessTakerService
          .saveTextBox(
            $scope.assessmentResponseId,
            $scope.assessment.id,
            $scope.question.id,
            $scope.answer,
            $scope.user.userId
          )
          .then(
            function() {
              advanceQuestion();
            },
            function() {
              $scope.retrieveAssessmentResponse();
            }
          );
      };

      //Send Save Answer Command for Force-Rank
      $scope.saveSort = function() {
        assessTakerService
          .saveSort(
            $scope.assessmentResponseId,
            $scope.assessment.id,
            $scope.question.id,
            [$scope.sortAnswers[0], $scope.sortAnswers[1], $scope.sortAnswers[2], $scope.sortAnswers[3]],
            $scope.user.userId
          )
          .then(
            function() {
              advanceQuestion();
            },
            function() {
              $scope.retrieveAssessmentResponse();
            }
          );
      };

      $scope.saveAll = function(showSpinner) {
        var answers = [];
        $scope.answeredQuestions.forEach(function(answeredQuestion) {
          switch (answeredQuestion.question.questionType) {
            case 'date':
              if (answeredQuestion.answer.value !== undefined) {
                var dateObj = setupDate(answeredQuestion.answer.value);
                answers.push({
                  questionId: answeredQuestion.question.id.id,
                  answer: {
                    questionType: 'date',
                    year: dateObj.year,
                    month: dateObj.month,
                    dayOfMonth: dateObj.dayOfMonth,
                    timeZone: dateObj.timeZone,
                  },
                });
              }
              break;
            case 'textbox':
              answers.push({
                questionId: answeredQuestion.question.id.id,
                answer: {
                  questionType: 'textbox',
                  value: answeredQuestion.answer.value === undefined ? '' : answeredQuestion.answer.value,
                },
              });
              break;
            case 'radio':
              var possibleAnswer = answeredQuestion.answer.possibleAnswers.filter(function(answer) {
                return answer.selected === true;
              });
              if (possibleAnswer.length > 0) {
                answers.push({
                  questionId: answeredQuestion.question.id.id,
                  answer: { questionType: 'radio', answerId: possibleAnswer[0].id },
                });
              }
              break;
            case 'multiselectandinput':
              var possibleAnswers = answeredQuestion.answer.possibleAnswers.filter(function(answer) {
                return answer.selected === true;
              });
              var multiSelectAnswers = [];
              possibleAnswers.forEach(function(ans) {
                if (ans.value[0].localeCode !== undefined) {
                  multiSelectAnswers.push({ id: ans.id.id });
                } else {
                  multiSelectAnswers.push({ id: ans.id.id, value: ans.value[0].value });
                }
              });
              answers.push({
                questionId: answeredQuestion.question.id.id,
                answer: { questionType: 'multiselectandinput', answers: multiSelectAnswers },
              });
              break;
            default:
              $log.error(answeredQuestion.question.questionType + ' type not supported for saveAll');
          }
        });
        if (showSpinner) {
          blockUX.start(
            'Saving your assessment...',
            0,
            10000,
            false,
            'Error saving up your Assessment. Please try again.',
            $scope.redirectToViewer
          );
        }
        assessTakerService.saveAll($scope.assessmentResponseId, $scope.assessment.id, answers, $scope.user.userId).then(
          function() {
            $scope.spinner = true;
            $scope.assessmentResponse = undefined;
            if (showSpinner) {
              blockUX.stop();
            }
          },
          function() {
            $scope.retrieveAssessmentResponse();
          }
        );
      };

      $scope.saveAllAndComplete = function() {
        $scope.saveAll(false);
        $scope.completeAssessment();
      };

      $scope.redirectToViewer = function() {
        if ($scope.lastRootView === 'prepareEnrich') {
          windowService.redirectHash('/prepare-enrich');
        } else {
          windowService.redirectHash('/assessment-viewer/' + $routeParams.taskListInstanceId + '/' + $routeParams.taskInstanceId + '/true');
        }
      };

      //Complete Assessment
      $scope.completeAssessment = function() {
        var label = 'step';
        blockUX.start(
          'Saving your assessment...',
          1000,
          10000,
          false,
          'Error saving up your Assessment. Please try again.',
          $scope.redirectToViewer
        );
        taskListService.completeTask($scope.taskListInstanceId, $scope.user.userId, $scope.task).then(
          function() {
            blockUX.stop();
            $scope.redirectToViewer();
          },
          function(reason) {
            blockUX.stop();
            $log.error(reason);
            alertService.show({
              title: 'Unexpected Error!',
              content: 'Please try completing the ' + label + ' again.',
              type: 'danger danger-with-content',
              duration: 20,
            });
          }
        );
      };

      init();

      $scope.isAllAnswered = function() {
        return (
          !!$scope.answeredQuestions &&
          !$scope.answeredQuestions.find(function(q) {
            return (
              angular.equals(q.answer, {}) ||
              (q.answer.possibleAnswers &&
                !q.answer.possibleAnswers.find(function(a) {
                  return a.selected;
                }))
            );
          })
        );
      };
    },
  ] //End Assess Taker Ctrl
);
