import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser, User } from '../../authentication';
import WhiteLabel from '../../filters/WhiteLabel';
import { Course, CourseStep } from '../models/course';
import useManageTrainingRoute from '../../hooks/useManageTrainingRoute';
import alertService from '../../services/AlertService';
import trainingService from '../../services/trainingService';
import windowService from '../../services/windowService';
import { handleError } from '../../utils/apiUtils';
import { reorderSortableItems } from '../../utils/reorderSortableItems';
import { UrlParams } from '../models/url';

const isCourseOwner = (user: User | undefined, course: Course, isAuthoring: boolean) => {
  if (isAuthoring) {
    if (!user?.author?.find(catalog => catalog.shortCode === WhiteLabel.catalog.shortCode)) {
      return false;
    }
    return !!user?.author?.find(catalog => catalog._id.id === course.owner);
  }

  return user?.lastSelectedAccount === course?.owner;
};

type State = {
  course: Course;
  steps: CourseStep[];
  isOwner: boolean;
  isLoading: boolean;
  isError: boolean;
};

export const useCourseBuilder = () => {
  const user = useUser();
  const [data, setData] = useState<State>({
    course: {
      id: '',
      name: '',
      category: '',
      published: false,
      owner: '',
      tasks: [],
      lastModifiedDate: new Date(),
    },
    steps: [],
    isOwner: false,
    isLoading: true,
    isError: false,
  });

  const { buildType, trainingId } = useParams<UrlParams>();
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();

  const hasSteps = data.course.tasks?.length > 0;

  const reorder = reorderSortableItems(data.steps, (newTasks, originalTasks) => {
    setData(prev => ({ ...prev, steps: newTasks }));

    const orderMap = Object.fromEntries(newTasks.map((s, i) => [s._id.id, i]));

    return trainingService
      .reorderTasks(trainingId, orderMap, user?.userId)
      .then(() => {
        alertService.show('Steps Updated');
      })
      .catch(error => {
        handleError(error);
        setData(prev => ({ ...prev, steps: originalTasks }));
      });
  });

  useEffect(() => {
    trainingService
      .getTasklist(trainingId)
      .then(response => {
        setData(previousData => ({
          ...previousData,
          course: response,
          steps: response.tasks,
          isOwner: isCourseOwner(user, response, buildType === 'author'),
          isLoading: false,
        }));
      })
      .catch(error => {
        handleError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeStep = (stepId: string) =>
    trainingService
      .removeTask(trainingId, stepId, user?.userId)
      .then(() => {
        alertService.show('Step Removed');
        const updatedSteps = data.course.tasks.filter(task => task._id.id !== stepId);

        setData(previousData => ({
          ...previousData,
          steps: updatedSteps,
        }));
      })
      .catch(handleError);

  const publishCourse = () =>
    trainingService.publishCourse(trainingId, user?.userId).then(() => updatePublishedStatus(true));

  const unpublishCourse = () =>
    trainingService.unpublishCourse(trainingId, user?.userId).then(() => updatePublishedStatus(true));

  const handleCourseCopy = (name: string, ownerId: string) =>
    trainingService
      .cloneTraining('Course', trainingId, name, ownerId, user?.userId)
      .then(response => {
        // This timeout gives time for the service to fully complete all the changes
        // before redirecting. In some cases, the name wasn't updated in time.
        return new Promise<void>(resolve =>
          setTimeout(() => {
            windowService.redirectTo(`${manageTrainingRoutePrefix}/courses/${response.id}`);
            resolve();
          }, 500)
        );
      })
      .catch(handleError);

  const updatePublishedStatus = (isPublished: boolean) => {
    setData(prev => ({ ...prev, course: { ...prev.course, published: isPublished } }));
  };

  return { ...data, reorder, removeStep, publishCourse, unpublishCourse, handleCourseCopy, hasSteps };
};
