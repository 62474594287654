angular.module("lwNamb").factory(
  "orgService",

  [
    "$rootScope",
    "toastService",
    "api",
    "qt",
    "uuid4",
    "CacheFactory",
    "permissionsService",
    "licenseService",
    "commandSubmissionService",
    "$timeout",
    "userService",
    "properties",
    "apiUrlService",
    "$q",
    function (
      $rootScope,
      toastService,
      api,
      qt,
      uuid4,
      CacheFactory,
      permissionsService,
      licenseService,
      commandSubmissionService,
      $timeout,
      userService,
      props,
      apiUrlService,
      $q
    ) {
      var timeoutSeconds = 15,
        cache = CacheFactory("org", {
          maxAge: 60 * 1000, // 1 minute
          deleteOnExpire: "aggressive",
        }),
        orgOverviewCache = CacheFactory("orgName", {
          maxAge: 60 * 1000 * 10, // 10 minutes
          deleteOnExpire: "aggressive",
        }),
        pendingOrgPromise;

      $rootScope.$on("ClearRolesCache", function () {
        cache.removeAll();
      });

      function getCurriculumLicenseItemNumber() {
        return "005831306";
      }

      return {
        dropCache: function (orgId) {
          cache.remove(orgId);
        },
        getOrg: function (orgId) {
          var deferred;
          if (orgId === undefined || orgId === "") {
            deferred = qt.defer();
            deferred.reject("no orgId passed in");
            return deferred.promise;
          } else if (cache.get(orgId)) {
            deferred = qt.defer();
            deferred.resolve(cache.get(orgId));
            return deferred.promise;
          } else {
            return getAndCacheOrg(orgId);
          }
        },
        getOrgOverview: function (orgId) {
          var deferred = qt.defer();
          if (orgOverviewCache.get(orgId)) {
            deferred.resolve(orgOverviewCache.get(orgId));
          } else {
            api.get("/v1/organizations/" + orgId + "/overview").then(
              function (name) {
                orgOverviewCache.put(orgId, name);
                deferred.resolve(name);
              },
              function () {
                deferred.reject();
              }
            );
          }
          return deferred.promise;
        },
        setLogo: function (orgId, logo) {
          var possibleOrg = cache.get(orgId);
          if (possibleOrg !== undefined) {
            possibleOrg.logo = logo;
            cache.remove(orgId);
            cache.put(orgId, possibleOrg);
          }
        },
        createOrgAccount: function (orgId, initiatingUserId, orgName, userId, trackFeature, shouldNotify) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
            promise = deferred.promise,
            cleanOrgName = orgName.replace(/\n|\r/g, " ").trim();

          api
            .post("/v1/commands/CreateOrgAccount", {
              id: orgId,
              initiatingUserId: initiatingUserId,
              name: cleanOrgName,
              owner: {
                id: userId,
              },
            })
            .then(
              function () {
                check(1000);
              },
              function (reason) {
                deferred.reject(reason);
              }
            );

          return promise;

          // wait for expected user permissions to avoid temporary access denied view
          function check(delayMillis) {
            if (!promise.isResolved()) {
              $timeout(function () {
                userService.user().then(
                  function (user) {
                    if (user.lastSelectedAccount === orgId && user.permissions.indexOf(props.permissions.org) > -1) {
                      deferred.resolve();
                      // if (shouldNotify) {
                      //   notify();
                      // }
                    } else {
                      check(500);
                    }
                  },
                  function () {
                    check(500);
                  }
                );
              }, delayMillis);
            }
          }

          // function notify() {
          //   var stopListener;
          //   toastService.success(
          //     'You’re a new admin! You’ve got several new tools and abilities now. Learn about them on your <a href="/#/dashboard-training">Training page</a>.',
          //     'Organization Created',
          //     {
          //       onclick: function(event) {
          //         window.location = '/#/dashboard-training';
          //         trackFeature('orgCreatedNotification', 'notificationLinkClicked');
          //         jQuery(event.currentTarget).fadeOut();
          //       },
          //       onShown: function(event) {
          //         trackFeature('orgCreatedNotification', 'notificationDisplayed');
          //       },
          //       onCloseClick: function(event) {
          //         trackFeature('orgCreatedNotification', 'notificationClosed');
          //       },
          //     }
          //   );
          //   stopListener = $rootScope.$on('$routeChangeSuccess', function() {
          //     var currentPage = window.location.hash;
          //     if (currentPage !== '#/org' && currentPage !== '#/people') {
          //       toastService.clear();
          //       stopListener();
          //     }
          //   });
          // }
        },
        createRole: function (orgId, initiatingUserId, roleId, roleName) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          cache.remove(orgId);

          api
            .post("/v1/commands/CreateOrgAccountRole", {
              id: orgId,
              initiatingUserId: initiatingUserId,
              role: {
                id: roleId,
                name: roleName,
                permission: {
                  id: uuid4.generateId(),
                  name: roleName,
                  permissions: [],
                },
              },
            })
            .then(null, function (reason) {
              deferred.reject(reason);
            });

          $rootScope.$on("OrgAccountRoleCreated", function (name, event) {
            if (event.role.name == roleName) {
              deferred.resolve();
            }
          });
          $rootScope.$on("RoleNameTaken", function (name, event) {
            deferred.reject("RoleNameTaken");
          });

          return deferred.promise;
        },
        removeRole: function (orgId, roleId, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          cache.remove(orgId);
          $rootScope.$broadcast("ClearRolesCache");

          api
            .post("/v1/commands/DisableOrgAccountRole", {
              id: orgId,
              roleId: roleId,
              initiatingUserId: initiatingUserId,
            })
            .then(null, function (reason) {
              deferred.reject(reason);
            });

          $rootScope.$on("OrgAccountRoleDisabled", function (name, event) {
            deferred.resolve();
          });

          $rootScope.$on("NoAdminRemaining", function () {
            deferred.reject("NoAdminRemaining");
          });

          return deferred.promise;
        },
        assignRoleToUsers: function (orgId, users, role, roleName, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          cache.remove(orgId);
          $rootScope.$broadcast("ClearGroupCache");

          function rejectPromise(reason) {
            deferred.reject(reason);
          }

          api
            .post("/v1/commands/AddToRole", {
              id: uuid4.generate(),
              roleId: role.id,
              roleName: roleName,
              assigneeIds: users.map(function (m) {
                return m._id.id;
              }),
              orgId: { id: orgId },
              initiatingUserId: initiatingUserId,
            })
            .then(null, rejectPromise);

          $rootScope.$on("AddToRoleSucceeded", function (name, event) {
            if (event.roleId.id === role.id.id) {
              deferred.resolve();
            }
          });

          $rootScope.$on("OrgAccountError", function (name, event) {
            rejectPromise(event.apiError.message);
          });

          return deferred.promise;
        },
        removeUserFromRole: function (orgId, userId, roleId, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          cache.remove(orgId);
          $rootScope.$broadcast("ClearGroupCache");

          function rejectPromise() {
            deferred.reject(reason);
          }

          api
            .post("/v1/commands/RemoveFromRole", {
              id: uuid4.generate(),
              orgId: { id: orgId },
              possibleUserId: userId.id,
              roleId: { id: roleId },
              initiatingUserId: initiatingUserId,
            })
            .then(null, rejectPromise);

          $rootScope.$on("RemoveFromRoleSucceeded", function (name, event) {
            deferred.resolve();
          });

          $rootScope.$on("NoAdminRemaining", function () {
            deferred.reject("NoAdminRemaining");
          });

          return deferred.promise;
        },
        updateRoleName: function (orgId, roleId, name, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          cache.remove(orgId);

          api
            .post("/v1/commands/RenameOrgAccountRole", {
              id: orgId,
              roleId: roleId,
              name: name,
              initiatingUserId: initiatingUserId,
            })
            .then(null, function (reason) {
              deferred.reject(reason);
            });

          $rootScope.$on("OrgAccountRoleRenamed", function (name, event) {
            deferred.resolve();
          });

          $rootScope.$on("RoleNameTaken", function (name, event) {
            deferred.reject("DUPLICATE_NAME");
          });

          return deferred.promise;
        },
        getGroups: function (orgId) {
          var deferred = qt.defer();
          this.getOrg(orgId).then(
            function (org) {
              var groups = org.groups;
              deferred.resolve(groups);
            },
            function (reason) {
              deferred.reject(reason);
            }
          );
          return deferred.promise;
        },
        getGroup: function (groupId, orgId) {
          var deferred = qt.defer();
          this.getGroups(orgId).then(
            function (groups) {
              var group = groups.find(function (g) {
                return g.id.id === groupId;
              });
              if (group !== undefined) {
                deferred.resolve(group);
              } else {
                deferred.reject("not found");
              }
            },
            function (reason) {
              deferred.reject(reason);
            }
          );
          return deferred.promise;
        },
        addPermission: function (orgId, roleId, access, initiatingUserId) {
          var deferred = qt.defer();

          this.getRole(roleId, orgId).then(function (role) {
            if (
              !role.permission.permissions.find(function (p) {
                return p.access === access;
              })
            ) {
              delete role.permissions;
              role.permission.permissions.push({
                id: { id: access },
                access: access,
              });
              updateRolePermission(orgId, role, initiatingUserId).then(
                function (response) {
                  deferred.resolve(response);
                },
                function (reason) {
                  deferred.reject(reason);
                }
              );
            } else {
              deferred.resolve();
            }
          });
          return deferred.promise;
        },
        removePermission: function (orgId, roleId, access, initiatingUserId) {
          var deferred = qt.defer();
          this.getRole(roleId, orgId).then(function (role) {
            if (
              role.permission.permissions.find(function (p) {
                return p.access === access;
              })
            ) {
              delete role.permissions;
              role.permission.permissions = role.permission.permissions.filter(function (p) {
                return p.access !== access;
              });
              updateRolePermission(orgId, role, initiatingUserId).then(
                function (response) {
                  deferred.resolve(response);
                },
                function (reason) {
                  deferred.reject(reason);
                }
              );
            } else {
              deferred.resolve();
            }
          });
          return deferred.promise;
        },
        removePendingMember: function (inviteId, email, orgId, initiatingUserId) {
          return commandSubmissionService.submitAndWait(
            inviteId,
            {
              id: inviteId,
              email: email,
              orgId: {
                id: orgId,
              },
              initiatingUserId: initiatingUserId,
            },
            "DisablePendingInvite",
            "PendingInviteDisabled",
            "PendingInviteError"
          );
        },
        removeMembers: function (orgId, users, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
            userCount = users.length,
            usersDisabledCount = 0;

          cache.remove(orgId);

          function rejectPromise() {
            deferred.reject(reason);
          }

          for (var j = 0; j < userCount; j++) {
            api
              .post("/v1/commands/DisableOrgAccountMember", {
                id: orgId,
                initiatingUserId: initiatingUserId,
                userId: users[j]._id,
              })
              .then(null, rejectPromise);
          }

          $rootScope.$on("OrgAccountMemberDisabled", function (name, event) {
            usersDisabledCount = usersDisabledCount + 1;
            if (event.id === orgId && usersDisabledCount === userCount) {
              deferred.resolve();
            }
          });

          $rootScope.$on("NoAdminRemaining", function () {
            deferred.reject("NoAdminRemaining");
          });

          return deferred.promise;
        },
        // Does this need to be deleted?
        resetOrgLogo: function (orgId, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
            getting;

          cache.remove(orgId);

          api
            .post("/v1/commands/SetOrgLogo", {
              id: orgId,
              initiatingUserId: initiatingUserId,
            })
            .then(null, function (reason) {
              deferred.reject(reason);
            });

          $rootScope.$on("OrgLogoSet", function (name, event) {
            if (event.id == orgId) {
              deferred.resolve();
            }
          });

          return deferred.promise;
        },
        createGroup: function (orgId, groupId, groupName, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
            getting;

          api
            .post("/v1/commands/CreateOrgAccountGroup", {
              id: orgId,
              groupId: { id: groupId },
              groupName: groupName,
              initiatingUserId: initiatingUserId,
            })
            .then(null, function (reason) {
              deferred.reject(reason);
            });

          $rootScope.$on("OrgAccountGroupCreated", function (name, event) {
            if (event.id == orgId) {
              cache.remove(orgId);
              deferred.resolve();
            }
          });
          $rootScope.$on("GroupNameDuplicateError", function (name, event) {
            deferred.reject(event);
          });

          deferred.promise.then(null, null, function (elapsedSeconds) {
            if (elapsedSeconds > 1 && !getting) {
              getting = true;
              getAndCacheOrg(orgId).then(
                function (org) {
                  if (
                    org.groups.find(function (group) {
                      return group.id.id === groupId;
                    })
                  ) {
                    deferred.resolve();
                  } else {
                    getting = false;
                  }
                },
                function () {
                  getting = false;
                }
              );
            }
          });

          return deferred.promise;
        },
        getRole: function (roleId, orgId) {
          return api.get("/v1/organizations/" + orgId + "/roles/" + roleId).then(
            function (response) {
              var role = response.data;
              role.permissions = permissionsService.get();
              role.permissions.forEach(function (permission) {
                permission.selected = !!role.permission.permissions.find(function (p) {
                  return p.access === permission.access;
                });
              });
              role.permissions = role.permissions.filter(function (permission) {
                return !(permission.label === "Can Build Curriculum" && permission.selected === false);
              });
              return role;
            },
            function (reason) {
              return reason;
            }
          );
        },
        getRolesOverview: function (orgId) {
          return api.get("/v1/organizations/" + orgId + "/roles").then(function (response) {
            response.data.forEach(function (roleOverview) {
              var roleSearchData = [];
              roleSearchData.push(roleOverview.name);
              roleOverview.usersInRole.forEach(function (user) {
                roleSearchData.push(user.displayName);
                roleSearchData.push(user.email);
              });
              roleOverview.searchData = roleSearchData;
            });
            return response.data;
          });
        },
        hasSubscription: function (orgId) {
          return licenseService.getPairedLicenses(orgId).then(function (licenses) {
            return (
              licenses.filter(function (license) {
                return !license.expired;
              }).length > 0
            );
          });
        },
        hasIndividualGridSubscription: function (userId) {
          return licenseService.getIndividualLicenses(userId).then(function (licenses) {
            return (
              licenses.filter(function (license) {
                return license.subscription && license.subscription.status === "Active";
              }).length > 0
            );
          });
        },
        hasLimitedGridSubscription: function (userId) {
          // todo: this is incorrect, how is it being used?
          return licenseService.getIndividualLicenses(userId).then(function (licenses) {
            return (
              licenses.filter(function (license) {
                return license.seats && license.seats.isPermanent && license.seats.isLimited;
              }).length > 0
            );
          });
        },
        hasGridSubscription: function (orgId) {
          // todo: don't need detail view for this query
          return licenseService.getPairedLicenses(orgId).then(function (licenses) {
            return (
              licenses.filter(function (license) {
                return license.isGridSubscription && !license.expired;
              }).length > 0
            );
          });
        },
        hasCurriculumSubscription: function (orgId) {
          // todo: don't need detail view for this query
          return licenseService.getPairedLicenses(orgId).then(function (licenses) {
            var filtered = licenses.filter(function (license) {
              return !license.isGridSubscription;
            });
            return filtered.length > 0;
          });
        },
        getCurriculumLicenseItemNumber: getCurriculumLicenseItemNumber,
        hasPurchasedCurriculumSubscription: function (orgId) {
          return licenseService.getPairedLicenses(orgId).then(function (licenses) {
            var filtered = licenses.filter(function (license) {
              return license.item && license.item.number == getCurriculumLicenseItemNumber();
            });
            return filtered.length > 0;
          });
        },
        // delete?
        hideSearch: function (orgId, initiatingUserId) {
          return toggleSearch(orgId, initiatingUserId, "HideSearchForOrg", "SearchHiddenForOrg");
        },
        unhideSearch: function (orgId, initiatingUserId) {
          return toggleSearch(orgId, initiatingUserId, "UnhideSearchForOrg", "SearchUnhiddenForOrg");
        },
      };

      // delete?
      function toggleSearch(orgId, initiatingUserId, success, failure) {
        cache.remove(orgId);
        var cmd = { id: orgId, initiatingUserId: initiatingUserId };
        return commandSubmissionService.submitAndWait(orgId, cmd, success, failure, "OrgAccountError");
      }

      function getAndCacheOrg(orgId) {
        if (pendingOrgPromise) {
          return pendingOrgPromise;
        }
        var promise = api.get("/v1/organizations/" + orgId).then(function (response) {
          var org = {};
          if (response.data && response.data._id) {
            var r = response.data;
            org.id = r._id.id;
            org.name = r.name;
            org.logo = r.logo;
            org.ownerId = r.owner.id;
            org.imported = r.imported;
            org.roles = [];
            r.roles.forEach(function (role) {
              if (role.active) org.roles.push(role);
            });
            org.members = r.members;
            org.memberRoles = r.memberRoles;
            org.groups = r.groups;

            var roleIDsToPush = [];
            org.memberRoles.forEach(function (memberRole) {
              memberRole.roles.forEach(function (role) {
                roleIDsToPush.push(role.id);
              });
            });
            var uniqueRoleIDs = roleIDsToPush.filter(function (value, index, self) {
              return self.indexOf(value) === index;
            });
            var unSortedRoles = [];
            uniqueRoleIDs.forEach(function (roleId) {
              org.roles.forEach(function (role) {
                if (role.id.id === roleId) {
                  unSortedRoles.push(role);
                }
              });
            });
            var uniqueRolesToPush = [];
            unSortedRoles.sort(insensitiveCaseSort).forEach(function (role) {
              uniqueRolesToPush.push({
                name: role.name,
                value: role.id.id,
              });
            });
            org.rolesDropdown = uniqueRolesToPush;
            org.showSearch = r.showSearch === undefined ? true : r.showSearch;

            cache.put(orgId, org);
          }
          return org;
        });
        pendingOrgPromise = promise;
        promise.finally(function () {
          pendingOrgPromise = null;
        });
        return promise;
      }

      function updateRolePermission(orgId, role, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        cache.remove(orgId);

        api
          .post("/v1/commands/UpdateOrgAccountRole", {
            id: orgId,
            initiatingUserId: initiatingUserId,
            role: role,
          })
          .then(null, function (reason) {
            deferred.reject(reason);
          });

        $rootScope.$on("OrgAccountRoleUpdated", function (name, event) {
          if (event.id === orgId && role.id.id === event.role.id.id) {
            deferred.resolve();
          }
        });

        $rootScope.$on("NoAdminRemaining", function () {
          deferred.reject("NoAdminRemaining");
        });

        return deferred.promise;
      }

      function insensitiveCaseSort(a, b) {
        a = a.name.toLowerCase();
        b = b.name.toLowerCase();
        if (a === b) return 0;
        if (a > b) return 1;
        return -1;
      }
    },
  ]
);
