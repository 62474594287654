import styled from 'styled-components';
import { Grid, Border, Color, Type, Shadow, Breakpoints } from '../StyleGuide';
import capitalizeFilter from '../filters/capitalize-filter';
import WhiteLabel from '../filters/WhiteLabel';

export const TrainingPoster = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: ${Border.radius};
  background: ${Color.Gray._20};
  box-shadow: ${Shadow.light};
`;

export const Labels = styled.div`
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  margin: 0;
  color: ${Type.Color.medium};
  font-size: ${props => (props.fontSize ? props.fontSize : Type.Scale._4)};
  text-transform: uppercase;
  span {
    margin-left: ${Grid._3};
    text-transform: none;
  }
`;

export const TrainingInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  h2 {
    color: ${Type.Color.dark};
    font-size: ${Type.Scale._5};
    font-weight: ${Type.Weight.semibold};
    line-height: ${Type.Leading.normal};
    margin: ${Grid._4} 0;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    color: ${Type.Color.mediumDark};
    font-size: ${Type.Scale._4};
    margin: 0;
  }
`;

export const HeaderUnderlined = styled.h3`
  display: inline-block;
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.semibold};
  padding: ${Grid._5} ${Grid._4} ${Grid._4};
  margin: 0;
  border-bottom: 3px solid ${Color.Orange._50};
`;

export const DocumentThumbnailPlaceholder = styled.div`
  max-width: 100%;
  height: auto;
  position: relative;
  padding-top: 66.66%; /*Percentage ratio for 3:2 */
  color: ${Color.Gray._50};
  background: ${Color.Gray._20};
  border-radius: ${Border.radius};
  border: 1px solid ${Color.Gray._30};
  &::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '${props =>
      props.type === 'zip'
        ? '\f1c6'
        : props.type === 'pdf'
        ? '\f1c1'
        : props.type === 'doc' || props.type === 'docx'
        ? '\f1c2'
        : props.type === 'mp4'
        ? '\f1c8'
        : props.type === 'jpg' || props.type === 'png' || props.type === 'gif'
        ? '\f1c5'
        : '\f15c'}';
    font-size: ${Type.Scale._5};
    position: absolute;
    top: 18% !important;
    right: 0;
    bottom: 0;
    left: 0;
    text-transform: lowercase;
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      font-size: ${Type.Scale._6};
      top: 25%;
    }
  }
`;

export const numTrainingItemsLabelText = training => {
  const numItems = training._type === 'Course' ? training.stepCount : training.courseCount;
  const item = `${numItems} ${
    training._type === 'Course' ? capitalizeFilter(WhiteLabel.labels.step) : capitalizeFilter(WhiteLabel.labels.course)
  }${numItems > 1 ? 's' : ''}`;
  return item;
};
