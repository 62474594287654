const EventBus = () => {
  let subscribers = {};

  return {
    subscribe: (eventName, callback) => {
      if (typeof eventName !== 'string') {
        console.error('[EventBus] subscribe invoked with invalid argument: eventName must be a string');
        return () => {};
      }

      if (typeof callback !== 'function') {
        console.error('[EventBus] subscribe invoked with invalid argument: callback must be a function');
        return () => {};
      }

      let subscriber = {
        callback: callback,
      };

      if (!subscribers[eventName]) {
        subscribers[eventName] = [];
      }
      subscribers[eventName].push(subscriber);

      // unsubscribe
      return () => {
        try {
          subscribers[eventName] = subscribers[eventName].filter(s => s !== subscriber);
          if (subscribers[eventName].length === 0) {
            delete subscribers[eventName];
          }
        } catch (error) {
          console.error(`[EventBus] unsubscribe failed for eventName: ${eventName}), error:`, error);
        }
      };
    },

    publish: (eventName, payload) => {
      if (typeof eventName !== 'string') {
        console.error('[EventBus] publish invoked with invalid argument: eventName must be a string');
        return;
      }

      if (subscribers[eventName]) {
        subscribers[eventName].forEach(subscriber => {
          try {
            subscriber.callback(payload);
          } catch (e) {} // eslint-disable-line no-empty
        });
      }
    },
  };
};

export default EventBus;
