import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Grid, Type } from '../StyleGuide';
import { MediumPrimaryButton, MediumButton } from './Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from './ModalElements';
import { AutoFocusInput } from './FormElements';
import LoadingState from './LoadingState';

const EasyLinkModalBody = styled(ModalBody)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  label {
    margin: 0 0 ${Grid._3};
    color: ${Type.Color.dark};
    font-weight: ${Type.Weight.semibold};
  }
  .description {
    margin-bottom: ${Grid._4};
    ul {
      color: ${Type.Color.dark};
    }
  }
`;

const EasyLinkModal = ({ link, description, handleDismiss, isLoading = false }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;
    const timeoutId = setTimeout(() => setCopied(false), 1500);
    return () => clearTimeout(timeoutId);
  }, [copied]);

  const getEasyLink = () => {
    if (typeof link === 'string') return link;
    if (typeof link === 'object' && link.orgPrefix && link.groupCode)
      return window.location.origin + '/#/easy-link/' + link.orgPrefix + '/' + link.groupCode;
    return '';
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader data-qa-hook="EasyLinkModalView">
        <h3>Easy Link</h3>
      </ModalHeader>
      {isLoading ? (
        <ModalBody>
          <LoadingState />
        </ModalBody>
      ) : (
        <EasyLinkModalBody>
          <div className="description" dangerouslySetInnerHTML={{ __html: description }} />
          <AutoFocusInput className="valid" selectTextOnFocus={true} id="EasyLinkURL" value={getEasyLink()} readOnly />
        </EasyLinkModalBody>
      )}
      <ModalFooter>
        <CopyToClipboard text={getEasyLink()} onCopy={() => setCopied(true)}>
          <MediumPrimaryButton disabled={copied || isLoading}>
            {copied ? 'Copied' : 'Copy Easy Link'}
          </MediumPrimaryButton>
        </CopyToClipboard>
        <MediumButton data-qa-hook="EasyLinkModalCloseButton" onClick={handleDismiss}>
          Close
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

EasyLinkModal.propTypes = {
  link: PropTypes.oneOfType([
    PropTypes.shape({
      orgPrefix: PropTypes.string,
      groupCode: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
  description: PropTypes.string.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default EasyLinkModal;
