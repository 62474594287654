angular.module('lwNamb').directive(
  'taskViewerFeedback',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/task-viewer-feedback.html',
        scope: {
          taskInstance: '=',
          taskListInstanceId: '@',
          isCoach: '=',
          userId: '@',
          orgId: '@',
        },
        replace: true,
        controller: [
          '$scope',
          'taskListInstanceService',
          'userService',
          '$log',
          function($scope, taskListInstanceService, userService, $log) {
            var reportId = $scope.taskInstance.task.relatedTask.task.reportId;

            if (reportId) {
              $scope.reportLink = taskListInstanceService.generateReportLink(
                reportId,
                $scope.taskInstance.task.taskName
              );
            } else if ($scope.taskInstance.status === 'complete') {
              generateReport();
            }

            $scope.generateReport = generateReport;

            function generateReport() {
              $scope.isReportGenerating = true;
              $scope.isError = false;
              userService.user().then(
                function(user) {
                  $scope.user = user;
                  taskListInstanceService
                    .generateTaskReport(
                      $scope.taskInstance.id.id,
                      $scope.taskListInstanceId,
                      $scope.taskInstance.task.relatedTask.task.reportToGenerate,
                      $scope.userId,
                      $scope.orgId,
                      $scope.user.userId
                    )
                    .then(
                      function(reportId) {
                        $scope.reportLink = taskListInstanceService.generateReportLink(
                          reportId,
                          $scope.taskInstance.task.taskName
                        );
                        $scope.isReportGenerating = false;
                      },
                      function(reason) {
                        $log.error(reason);
                        $scope.isError = true;
                        $scope.isReportGenerating = false;
                      }
                    );
                },
                function() {
                  $log.error('No user returned in taskViewerFeedback');
                }
              );
            }
          },
        ],
      };
    },
  ]
);
