import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Type } from '../StyleGuide';
import { Card, CardPoster, InlineList } from '../components/Layout';
import { numTrainingItemsLabelText } from '../components/TrainingElements';
import TrainingPosterPlaceholder from '../training/TrainingPosterPlaceholder';
import { IconBadge } from '../components/Badge';
import capitalizeFilter from '../filters/capitalize-filter';
import WhiteLabel from '../filters/WhiteLabel';
import trainingService from '../services/trainingService';
import useOrg from '../hooks/useOrg';

const Details = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${Grid._4};
  text-transform: capitalize;
  color: ${Type.Color.dark};
  h4 {
    margin: 0 0 ${Grid._4} 0;
    font-weight: ${Type.Weight.semibold};
  }
  span {
    position: absolute;
    top: -25px;
    right: 25px;
  }
`;

export default function TrainingCard({ training, redirectToTraining, canRedirect = true }) {
  const org = useOrg();
  const isOrgOwned = !!org && org.id === training.ownerId;

  return (
    <Card
      data-qa-hook="trainingItem"
      style={{ flexDirection: 'column' }}
      onClick={() => redirectToTraining(training.id)}
      className={canRedirect ? '' : 'disabled'}
    >
      {training.image !== undefined ? (
        <CardPoster src={trainingService.getTrainingImageUrl(training.image)} alt={training.name} />
      ) : (
        <TrainingPosterPlaceholder ownerName={isOrgOwned ? org.name : WhiteLabel.name} />
      )}
      <Details>
        {training._type === 'Pathway' && (
          <IconBadge className="icon-badge">
            <i className="fas fa-route"></i>
          </IconBadge>
        )}
        <h4 data-qa-hook="trainingName" style={{ paddingRight: training._type === 'Pathway' ? `${Grid._11}` : '0' }}>
          {training.name}
        </h4>
        <InlineList content="•" fontSize="16px">
          <li>
            {training._type === 'Course'
              ? capitalizeFilter(WhiteLabel.labels.course)
              : capitalizeFilter(WhiteLabel.labels.pathway)}
          </li>
          <li>{numTrainingItemsLabelText(training)}</li>
        </InlineList>
      </Details>
    </Card>
  );
}

TrainingCard.propTypes = {
  training: PropTypes.object.isRequired,
  redirectToTraining: PropTypes.func.isRequired,
  canRedirect: PropTypes.bool,
};
