import { useArrayFilter, useMultiFilter, useSortFilter, useTextFilter } from '../../hooks/filters';
import { TrainingEntity } from '../models/training';

export function useBuildFilter<T>(items: TrainingEntity[]) {
  const statusMatcher = (item: TrainingEntity, status: string) => {
    if (status === 'Show Published') {
      return item.published;
    } else if (status === 'Show Not Published') {
      return !item.published;
    }
  };

  const categoryMatcher = (item: TrainingEntity, category: string) => item.category === category;

  const typeMatcher = (item: TrainingEntity, type: string) => item.type === type;

  const queryMatcher = (item: TrainingEntity, query: string) => item.name.toLowerCase().includes(query.toLowerCase());

  // @ts-expect-error
  const statusFilter = useArrayFilter({ matcher: statusMatcher });
  // @ts-expect-error
  const categoryFilter = useArrayFilter({ matcher: categoryMatcher });
  // @ts-expect-error
  const typeFilter = useArrayFilter({ matcher: typeMatcher });
  const queryFilter = useTextFilter({ matcher: queryMatcher });

  const multiFilter = useMultiFilter({
    status: statusFilter,
    category: categoryFilter,
    type: typeFilter,
    query: queryFilter,
  });

  const sortFilter = useSortFilter({
    comparators: {
      name: (a: TrainingEntity, b: TrainingEntity) => a.name.localeCompare(b.name),
      lastEdited: (a: TrainingEntity, b: TrainingEntity) =>
        new Date(b.lastModifiedDate).getTime() - new Date(a.lastModifiedDate).getTime(),
    },
    initialState: { method: 'lastEdited', order: 'ascending' },
  });

  const filteredItems: T[] = sortFilter.filter(multiFilter.filter([...items])) as T[];

  return { filteredItems, multiFilter, sortFilter };
}
