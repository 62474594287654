angular.module('lwNamb').directive(
  'shareEasyLink',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/share-easy-link.html',
        scope: {
          trainingId: '@',
          trainingName: '@',
          btnClass: '@',
          orgId: '@',
          altText: '@?',
        },
        replace: true,
        controller: [
          '$scope',
          '$window',
          'inviteService',
          '$log',
          'alertService',
          function(
            $scope,
            $window,
            inviteService,
            $log,
            alertService
          ) {
            $scope.actionText = $scope.altText ? $scope.altText : 'Share via Easy Link';

            inviteService.getEasyLink($scope.orgId).then(
              function(response) {
                $scope.easyLink = $window.location.origin + '/#/easy-link/' + response.prefix + '/' + $scope.trainingId;
              },
              function(reason) {
                $log.error(reason);
                $scope.easyLink = undefined;
                alertService.show({
                  title: 'Unexpected Error!',
                  content: 'We are unable to get the easy link at this time. Please refresh and try again.',
                  type: 'danger danger-with-content',
                  duration: 20,
                });
              });
          },
        ],
      };
    },
  ]
);
