angular.module('lwNamb').controller(
  'SessionBuilderCtrl',

  [
    '$scope',
    'uuid4',
    '$log',
    '$sessionStorage',
    'curriculumService',
    '$routeParams',
    'windowService',
    'alertService',
    'fileUrlService',
    function(
      $scope,
      uuid4,
      $log,
      $sessionStorage,
      curriculumService,
      $routeParams,
      windowService,
      alertService,
      fileUrlService
    ) {
      function init() {
        $scope.loading = true;
        $scope.curriculumId = $routeParams.curriculumId;
        $scope.issueId = $routeParams.issueId;
        $scope.isDeleting = false;
        $scope.editingName = false;
        $scope.collections = [];
        $scope.material = {};
        $scope.packageType = '';
        $scope.submitted = false;
        $scope.filters = {
          collection: null
        };
        $scope.sortableOptions = {
          accept: function(sourceItemHandleScope, destSortableScope) {
            return sourceItemHandleScope.itemScope.sortableScope.$id === destSortableScope.$id;
          },
        };
        if ($routeParams.sessionId !== 'undefined' && $routeParams.sessionId !== undefined) {
          $scope.sessionId = $routeParams.sessionId;
          $scope.isSupportMaterials = false;
          loadSession();
        } else {
          $scope.isSupportMaterials = true;
          loadSupportMaterials();
        }

        //Sample Collection Data
        $scope.collections = [
          {name: 'Babies & Toddlers', value: false},{name: 'Preschool', value: false}, {name: 'Kids', value: false},{name: 'Adults', value: false}
        ];
      }

      $scope.cnf = function(material) {
        return (
          $scope.filters.collection === null ||
            material.seriesIds.indexOf($scope.filters.collection.seriesId) > -1
        );
      };

      $scope.toggleSelectedCollection = function(id){
        $scope.selectedCollections = $scope.collections.map(function(c){
          if (id === c.seriesId){
            c.value = !!c.value;
          }
          return c;
        });
      };

      $scope.collectionIsSelected = function(collections){
        return collections.some(function (c) {
          return c.value;
        });
      };

      $scope.changeSessionName = function(name) {
        curriculumService
          .changeSessionName($scope.issueId, $scope.sessionId, name)
          .then(
            function() {
              alertService.show({ type: 'success', title: 'Session Name Changed', duration: 5 });
              $scope.session.name = name;
              $scope.name = name;
              $scope.editedName = name;
            },
            function() {
              failureAlert('404', 'Session Name Change Failed');
            }
          )
          .finally(function() {
            $scope.editingName = false;
          });
      };

      $scope.changeMaterialName = function(material) {
        curriculumService
          .changeMaterialName(
            $scope.sessionId !== undefined ? $scope.sessionId : $scope.issueId,
            material.materialId,
            material.editedName
          )
          .then(
            function() {
              alertService.show({ type: 'success', title: 'Material Name Changed', duration: 5 });
              material.name = material.editedName;
            },
            function() {
              failureAlert('404', 'Material Name Change Failed');
            }
          )
          .finally(function() {
            material.editingName = false;
          });
      };

      function loadSession(closeModal) {
        curriculumService
          .getIssueSessionAndMaterials($scope.issueId, $scope.sessionId, $scope.curriculumId)
          .then(
            function(response) {
              $scope.session = response.session;
              $scope.editedName = response.session.name;
              $scope.materials = response.materials.map(function(m) {
                m.editingName = false;
                m.editedName = m.name;
                return m;
              });
              $scope.collections = response.series;
              sortAllMaterials();
              $scope.name = $scope.session.name;
            },
            function(response) {
              $log.error(response + ' error looking up session');
            }
          )
          .finally(function() {
            $scope.loading = false;
            if (closeModal !== undefined) {
              $scope.isDeleting = false;
              alertService.show({ type: 'success', title: 'Material Removed', duration: 5 });
              closeModal();
            }
          });
      }

      function loadSupportMaterials() {
        curriculumService
          .getIssueAndMaterials($scope.issueId, $scope.curriculumId)
          .then(
            function(response) {
              $scope.issue = response.issue;
              $scope.materials = response.materials.map(function(m) {
                m.editingName = false;
                m.editedName = m.name;
                return m;
              });
              $scope.collections = response.series;
              sortAllMaterials();
              $scope.name = 'Support Materials';
            },
            function(reason) {
              $log.error(reason, ' error looking up support materials');
            }
          )
          .finally(function() {
            $scope.loading = false;
          });
      }

      function sortAllMaterials() {
        $scope.materials.forEach(function(material) {
          material.collections = $scope.collections.filter(function(collection) {
            return material.seriesIds.includes(collection.seriesId);
          });
        });
      }

      var maxMaterialOrder = function() {
        if ($scope.materials) {
          var materialOrders = $scope.materials.map(function(material) {
            return material.order || 0;
          });
          return Math.max(Math.max.apply(null, materialOrders), 0);
        } else {
          return 0;
        }
      };

      function loadLastAddMaterialSelections() {
        var last = $sessionStorage.lastAddMaterialSelections;

        if (last) {
          $scope.collections.map(function(collection) {
            collection.value = last.collectionIds.includes(collection.seriesId);
          });

          $scope.material = { type: last.type };
          $scope.packageType = last.packageType;
        }
      }

      function storeAddMaterialSelections(collectionIds, materialType, packageType) {
        $sessionStorage.lastAddMaterialSelections = {
          collectionIds: collectionIds,
          type: materialType,
          packageType: packageType
        };
      }

      $scope.addMaterial = function(collections, type, packageType, isValid) {
        if (!isValid || !$scope.collectionIsSelected(collections)) {
          $scope.submitted = true;
          return;
        }

        var collectionIds =
          collections.filter(function(c) { return c.value; }).map(function (c) { return c.seriesId; });

        storeAddMaterialSelections(collectionIds, type, packageType);

        $scope.material = { id: uuid4.generateId(), type: type };
        var order = maxMaterialOrder() + 1;

        if (type === 'Text' || type === 'Link' || type === 'Video' || type === 'Download' || type === 'Audio') {
          var sessionPath =
            $routeParams.sessionId !== 'undefined' && $routeParams.sessionId !== undefined
              ? $scope.sessionId + '/'
              : '';
          windowService.redirectHash(
            '/materials/' +
              $scope.material.type +
              '/' +
              $scope.curriculumId +
              '/' +
              $scope.issueId +
              '/' +
              sessionPath +
              $scope.material.id.id +
              '/' +
              order +
              '/true/' +
              packageType +
              '?collectionIds=' + collectionIds.join(',')
          );
        }
      };

      $scope.editMaterial = function(type, id, order) {
        if (type === 'Text' || type === 'Link' || type === 'Video' || type === 'Download') {
          var sessionPath =
            $routeParams.sessionId !== 'undefined' && $routeParams.sessionId !== undefined
              ? $scope.sessionId + '/'
              : '';
          windowService.redirectHash(
            '/materials/' +
              type +
              '/' +
              $scope.curriculumId +
              '/' +
              $scope.issueId +
              '/' +
              sessionPath +
              id +
              '/' +
              order +
              '/false'
          );
        }
      };

      $scope.deleteMaterial = function(id, closeModal) {
        $scope.isDeleting = true;
        if ($scope.isSupportMaterials) {
          curriculumService.deleteIssueMaterial($scope.issueId, id).then(
            function() {
              loadSupportMaterials();
              loadSession(closeModal);
            },
            function(reason) {
              failureAlert(reason, 'Delete Material Failed');
              $scope.isDeleting = false;
              closeModal();
            }
          );
        } else {
          curriculumService.deleteSessionMaterial($scope.sessionId, id).then(
            function() {
              loadSession(closeModal);
            },
            function(reason) {
              failureAlert(reason, 'Delete Material Failed');
              $scope.isDeleting = false;
              closeModal();
            }
          );
        }
      };

      $scope.saveOrder = function() {
        $scope.isSaving = true;
        $scope.materials.map(function(material, i) {
          material.order = i;
        });

        if ($scope.isSupportMaterials) {
          curriculumService.reorderMaterialsForIssue($scope.issueId, $scope.materials).then(
            function() {
              reorderSuccess();
            },
            function(reason) {
              failureAlert(reason, 'Reorder Materials Failed');
            }
          );
        } else {
          curriculumService.reorderMaterialsForSession($scope.sessionId, $scope.materials).then(
            function() {
              reorderSuccess();
            },
            function(reason) {
              failureAlert(reason, 'Reorder Materials Failed');
            }
          );
        }
      };

      function reorderSuccess() {
        alertService.show({ type: 'success', title: 'Materials Reordered', duration: 5 });
        $scope.isReordering = false;
        $scope.isSaving = false;
      }

      function failureAlert(reason, title) {
        alertService.show({
          type: 'danger danger-with-content',
          title: title,
          content: 'Something went wrong. Please try again',
          duration: 20,
        });
        $log.error(reason);
        $scope.isReordering = false;
        $scope.isSaving = false;
      }

      $scope.clearForm = function() {
        $scope.material = { type: '' };
        $scope.submitted = false;
        $scope.selectedMaterials = [];

        loadLastAddMaterialSelections();
      };

      $scope.previewMaterial = function(material) {
        if (material.materialType === 'Download') {
          fileUrlService.openFile('', material.fileName, '/curriculum-storage/files/' + material.mediaId);
        } else {
          windowService.redirectHash(
            'curriculum/' +
              $scope.curriculumId +
              '/issue/' +
              $scope.issueId +
              '/series/' +
              material.seriesId +
              '/session/' +
              material.id +
              '/build-material-viewer/' +
              material.materialId
          );
        }
      };

      $scope.sessionSettings = function() {
        windowService.redirectHash(
          'curriculum/' +
          $scope.curriculumId +
          '/issue/' +
          $scope.issueId +
          '/session-builder/' +
          $scope.sessionId +
          '/settings'
        );
      };

      $scope.editMaterialProps = function(material) {
        $scope.editCollections = $scope.collections;
        $scope.editCollections.map(function(collection) {
          collection.value = material.seriesIds.includes(collection.seriesId);
        });
        $scope.editPackage = material.package;
        $scope.editing = {
          sessionId: material.id,
          materialId: material.materialId
        };
      };

      $scope.saveMaterialProps = function(editCollections, editPackage, hideModal) {
        var newCollections = editCollections.filter(function(c) { return c.value; });
        var newSeriesIds = newCollections.map(function(c) { return c.seriesId; });
        $scope.isSavingEdits = true;
        curriculumService.updateMaterial($scope.editing.sessionId, $scope.editing.materialId, newSeriesIds, editPackage)
          .then(function() {
            $scope.materials.map(function(material) {
              if(material.materialId === $scope.editing.materialId) {
                material.package = editPackage;
                material.collections = newCollections;
                material.seriesIds = newSeriesIds;
              }
            });
          }, function(error) {
            failureAlert(error, 'Material Edits Were Not Saved');
          }).finally(function () {
            $scope.editing = {};
            $scope.isSavingEdits = false;
            hideModal();
        });
      };

      init();
    },
  ]
);
