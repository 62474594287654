import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Color, Type } from '../StyleGuide';
import { useSteps } from './StepsContext';
import Results from '../steps/assessments/Results';
import Discussion from './Discussion';

const Container = styled.div`
  flex: 1;
  width: 100%;
  background: white;
  line-height: ${Type.Leading.tall};
  padding-bottom: ${Grid._5};
`;

const Tabs = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  background: ${Color.Gray._05};
  box-shadow: inset 0 -1px rgba(0, 0, 0, 0.05);
  li {
    font-size: ${Type.Scale._3};
    padding: ${Grid._4} ${Grid._5};
    font-weight: ${Type.Weight.normal};
    color: ${Type.Color.medium};
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    border-top: 3px solid transparent;
    cursor: pointer;
  }
  li.active-step-menu-tab {
    background: white;
    font-weight: ${Type.Weight.medium};
    color: ${Type.Color.dark};
    border-top-color: ${Color.Orange._50};
    box-shadow: 1px 0 rgba(0, 0, 0, 0.05), -1px 0 rgba(0, 0, 0, 0.05);
  }
`;

const TabContent = styled.div`
  color: ${Type.Color.dark};
  max-width: 700px;
  margin: ${Grid._7} auto;
  padding: ${Grid._4};
  .step-description {
    font-size: ${Type.Scale._4};
  }
  .inactive-step-menu-tab {
    display: none;
  }
  .active-step-menu-tab {
    display: block;
  }
  .empty-state-tab {
    text-align: center;
    h4 {
      font-weight: ${Type.Weight.semibold};
      font-size: ${Type.Scale._5};
      color: ${Type.Color.dark};
      margin: ${Grid._3} auto ${Grid._2};
    }
    h4 + p {
      font-size: ${Type.Scale._3};
      color: ${Type.Color.medium};
      max-width: 520px;
      margin: 0 auto;
    }
    .fa-comments {
      font-size: ${Grid._7};
      opacity: 0.5;
    }
  }
`;

const StepMenu = ({ name, content, type, disableDiscussion }) => {
  const [{ currentCourse, isOrgUser }] = useSteps();
  const [activeTab, setActiveTab] = useState(name || 'Discussion');
  const showEmptyDiscussionTab = !isOrgUser;
  const toggleTab = event => setActiveTab(event.target.textContent);

  disableDiscussion = disableDiscussion || currentCourse.discussionDisabled === true;

  const EmptyState = () => (
    <div data-qa-hook="joinDiscussionNoOrg" className="empty-state-tab">
      <p>
        <i className="far fa-comments"></i>
      </p>
      <h4>Join the Discussion</h4>
      <p>
        When people in an organization receive this training they’ll be able to ask questions and share their thoughts
        with each other.
      </p>
    </div>
  );

  return (
    <Container>
      <Tabs>
        {name && (
          <li className={name === activeTab ? 'active-step-menu-tab' : ''} onClick={toggleTab}>
            {name}
          </li>
        )}
        {!disableDiscussion && (
          <li
            data-qa-hook="discussionTab"
            className={'Discussion' === activeTab ? 'active-step-menu-tab' : ''}
            onClick={toggleTab}
          >
            Discussion
          </li>
        )}
      </Tabs>
      <TabContent>
        {!disableDiscussion && (
          <div className={'Discussion' === activeTab ? 'active-step-menu-tab' : 'inactive-step-menu-tab'}>
            {showEmptyDiscussionTab ? <EmptyState /> : <Discussion />}
          </div>
        )}
        <div className={name === activeTab ? 'active-step-menu-tab' : 'inactive-step-menu-tab'}>
          {type === 'text' && <div className="step-description" dangerouslySetInnerHTML={{ __html: content }}></div>}
          {type === 'results' && <Results questions={content} />}
        </div>
      </TabContent>
    </Container>
  );
};

StepMenu.propTypes = {
  name: PropTypes.string,
  content: PropTypes.any,
  type: PropTypes.string,
  disableDiscussion: PropTypes.bool,
};

export default StepMenu;
