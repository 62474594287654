import React from 'react';
import styled from 'styled-components';
import { Grid } from '../../StyleGuide';
import { DashedBackgroundButtonContainer, WhiteButton } from '../../components/Buttons';

const AddTrainingItemButtonContainer = styled(DashedBackgroundButtonContainer)`
  margin: ${Grid._8};
`;

type Props = {
  onAddTrainingClick: () => void;
};

export const AddTrainingListItem = ({ onAddTrainingClick, ...props }: Props) => (
  <AddTrainingItemButtonContainer {...props}>
    <WhiteButton onClick={onAddTrainingClick}>Add Training Item</WhiteButton>
  </AddTrainingItemButtonContainer>
);
