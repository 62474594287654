import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Grid, Color, Shadow } from '../../StyleGuide';
import { Button, DangerButton } from '../../components/Buttons';
import windowService from '../../services/windowService';
import { UrlParams } from '../models/url';

const SectionHeader = styled.h3`
  border-bottom: 1px solid ${Color.Gray._20};
  padding-bottom: ${Grid._2};
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Grid._4};
  padding: ${Grid._2} ${Grid._4} ${Grid._6} ${Grid._4};
  background: ${Color.white};
  box-shadow: ${Shadow.medium};
`;

type Props = {
  canAssign: boolean;
  isPublished: boolean;
  onEasyLinkClick: () => void;
  onUnpublishClick: () => void;
};

export const ActionsContainer = ({ canAssign, isPublished, onEasyLinkClick, onUnpublishClick }: Props) => {
  const { trainingId } = useParams<UrlParams>();

  return canAssign ? (
    <div>
      <Container>
        <SectionHeader>Assignment Actions</SectionHeader>
        <Button onClick={onEasyLinkClick}>Assign by Easy Link</Button>
        <Button onClick={() => windowService.redirectTo(`/assign-training/${trainingId}`)}>Assign by Email</Button>
        <Button onClick={() => windowService.redirectTo(`/add-to-your-training/${trainingId}?noLogout=1&reassign=1`)}>
          Assign to Yourself
        </Button>

        {isPublished && (
          <>
            <SectionHeader>Publishing Actions</SectionHeader>
            <DangerButton onClick={onUnpublishClick}>Unpublish</DangerButton>
          </>
        )}
      </Container>
    </div>
  ) : null;
};
