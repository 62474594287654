import apiClient from './apiClient';
import cacheService from './cacheService';
import submitCommand from './submitCommand';

const videoService = {
  getVideoPlaybackById: videoId => {
    return apiClient.get('/v1/videos/' + videoId + '/playback').then(function (response) {
      return response.data;
    });
  },
  getPoster: function (videoId) {
    return new Promise(function (resolve) {
      apiClient.get('/v1/videos/' + videoId).then(
        function (response) {
          resolve(response.data.video?.poster || '');
        },
        function () {
          resolve('');
        }
      );
    });
  },
  getUrls: function (videoId) {
    return apiClient.get('/v1/videos/' + videoId + '/urls').then(function (response) {
      return response.data;
    });
  },
  getProgress: function (videoId) {
    return new Promise(function (resolve) {
      apiClient.get('/v1/videos/progress/' + videoId).then(
        function (response) {
          resolve(response.data.progress === undefined ? 0 : response.data.progress);
        },
        function () {
          resolve(0);
        }
      );
    });
  },
  saveProgress: function (userId, videoId, progress) {
    return apiClient.post('/v1/videos/progress', { userId: { id: userId }, videoId: videoId, progress: progress });
  },
  deleteVideo: (videoId, videoName, orgId, userId) => {
    cacheService.remove(`/v1/DeleteVideo`);
    cacheService.remove(`/v1/organizations/${orgId}/content`);

    const command = {
      id: videoId,
      deletedBy: `${orgId}_${userId}`,
      filename: `${videoId}.${videoName.split('.')[1]}`,
      initiatingUserId: userId,
    };
    return submitCommand(videoId, command, 'DeleteVideo', 'VideoDeleted', 'VideoError');
  },
};

export default videoService;
