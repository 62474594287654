import React from 'react';
import RestrictedAccessHeader from './RestrictedAccessHeader';

export default function AccessDenied() {
  return (
    <RestrictedAccessHeader
      title="Access Denied"
      description="You don’t have access to view this page. If you feel you received this message in error, please contact your administrator."
    />
  );
}
