import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Type, Breakpoints, Border, Color, Shadow } from '../StyleGuide';
import { ThreeTwoAspectRatioPlaceholder, Image } from '../components/Layout';
import CalendarDateIcon from '../components/CalendarDateIcon';
import { SessionBadge } from '../components/Badge';
import { ButtonGroup } from './Buttons';
import { monthAbbrFilter } from '../filters/date-filters';

export const SessionDateHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: ${Grid._4} 0;

  ${ButtonGroup} {
    gap: ${Grid._4};

    button {
      margin: 0;
      flex: 1 1 auto;
    }
  }
`;

export const SessionDescription = styled.div`
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._4};
  p {
    font-size: ${Type.Scale._4};
  }
  margin-bottom: ${Grid._5};
`;

export const CalendarDate = styled.div`
  padding-right: ${Grid._4};
`;

export const SessionDetails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: 0 0 0 ${Grid._4};
  }
  h2 {
    margin: ${Grid._1} 0;
    font-size: ${Type.Scale._5};
    font-weight: ${Type.Weight.semibold};
    color: ${Type.Color.dark};
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      margin: ${Grid._1} ${Grid._4} ${Grid._1} 0;
      font-size: ${Type.Scale._6};
    }
  }
  p {
    color: ${Type.Color.medium};
    margin: 0;
  }
`;

const SessionCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: ${Type.Color.dark};
  margin: 0;
  padding: 0;
  border: 1px solid transparent;
  border-radius: ${Border.radius};
  transition: all 0.15s ease-in-out;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
    padding: ${Grid._4};
    &.selectable:hover {
      background: white;
      border: 1px solid ${Color.Gray._10};
      cursor: pointer;
      h5 {
        text-decoration: underline;
      }
    }
  }
`;

const SessionImgContainer = styled.div`
  position: relative;
  width: inherit;
  &.not-scheduled {
    opacity: 0.5;
  }
  img {
    box-shadow: ${Shadow.light};
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    width: 240px;
    min-width: 240px;
  }
`;

const ThisWeekBanner = styled.div`
  position: absolute;
  top: 0;
  height: 28px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Color.Secondary._80};
  color: ${Color.white};
  font-size: ${Type.Scale._1};
  font-weight: ${Type.Weight.light};
  text-transform: uppercase;
  border-radius: ${Border.radius} ${Border.radius} 0 0;
`;

const SessionCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  margin: -${Grid._6} 0 0;
  z-index: 1;
  & > div:first-child,
  & > span:first-child {
    margin-left: ${Grid._3};
    min-height: ${Grid._6};
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      margin-left: 0;
      min-height: 0;
    }
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: 0 0 0 ${Grid._5};
  }
`;

const Title = styled.h5`
  margin: ${Grid._3} 0;
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.semibold};
  text-transform: capitalize;
`;

const ThroughDate = styled.div`
  font-size: ${Type.Scale._2};
  color: ${Type.Color.medium};
  font-weight: ${Type.Weight.normal};
`;

export const CustomizationIndicator = styled.i.attrs({
  className: 'fas fa-check-circle fa-fw',
})`
  color: ${Color.Primary._50};
  font-size: ${Type.Scale._1};
`;

export const SessionDateHeaderAndDetails = ({ session, children }) => {
  return (
    <SessionDateHeader>
      {session.isScheduled && (
        <CalendarDate>
          <CalendarDateIcon
            month={monthAbbrFilter(session.scheduledDate.getMonth())}
            day={session.scheduledDate.getDate().toString()}
            primary={session.isThisWeek}
            large={true}
          />
        </CalendarDate>
      )}
      <SessionDetails>
        <SessionBadge session={session} />
        <h2>{session.name}</h2>
        {children}
      </SessionDetails>
    </SessionDateHeader>
  );
};

export const SessionCard = ({ session, onClick, CalendarDateComponent, ...props }) => {
  const isScheduled = session.isScheduled;

  return (
    <SessionCardContainer
      className={`session-card-container ${onClick ? 'selectable' : ''}`}
      onClick={onClick}
      {...props}
    >
      <SessionImgContainer className={isScheduled ? '' : 'not-scheduled'}>
        {session.image ? (
          <Image src={session.image} alt={session.name} />
        ) : (
          <ThreeTwoAspectRatioPlaceholder>No Image Provided</ThreeTwoAspectRatioPlaceholder>
        )}
        {isScheduled && session.isThisWeek && (
          <ThisWeekBanner data-qa-hook="thisWeeksSession">this week's session</ThisWeekBanner>
        )}
      </SessionImgContainer>
      <SessionCardDetails>
        {isScheduled ? (
          <CalendarDateComponent
            month={monthAbbrFilter(session.scheduledDate.getMonth())}
            day={session.scheduledDate.getDate().toString()}
            primary={session.isThisWeek}
          />
        ) : (
          <SessionBadge session={session} />
        )}
        <Title data-qa-hook="sessionName">{session.name}</Title>
        <ThroughDate data-qa-hook="availableThrough">{session.getAvailableThroughMessage('MMMM d')}</ThroughDate>
      </SessionCardDetails>
    </SessionCardContainer>
  );
};

SessionCard.defaultProps = {
  CalendarDateComponent: CalendarDateIcon,
};

SessionCard.propTypes = {
  session: PropTypes.shape({
    sessionId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    scheduledDate: PropTypes.instanceOf(Date).isRequired,
    publishEndDate: PropTypes.string.isRequired,
    image: PropTypes.string,
    isScheduled: PropTypes.bool,
    getAvailableThroughMessage: PropTypes.func.isRequired,
    isThisWeek: PropTypes.bool,
  }),
  onClick: PropTypes.func,
  CalendarDateComponent: PropTypes.elementType,
};
