import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '../StyleGuide';
import EmptyState from '../groups/EmptyState';
import { LinkStyleButton, PrimaryButton } from './Buttons';
import PeopleSelectList, { SelectedPeopleList } from './PeopleSelectList';
import useTextFilter from '../hooks/useTextFilter';
import usePropertyFilter from '../hooks/usePropertyFilter';
import useModal from '../hooks/useModal';

import { Select, TextFilterInput } from './FormElements';
import ActiveFilters from './ActiveFilters';
import UnsentEmailsModal from '../curriculum/UnsentEmailsModal';
import InlineAddNewPerson from './InlineAddNewPerson';
import GroupContainer from '../groups/GroupContainer';
import ConfirmationModal from './ConfirmationModal';
import { User } from '../authentication';

type AddPeopleToProps = {
  user: User;
  isSubmitting: boolean;
  handleAddPeople: (people: any[]) => void;
  preventSubmitConfirmation?: boolean;
  showInlineAddNewPerson?: boolean;
  preventUnsentEmails?: boolean;
  submitButtonText?: string;
  people?: any[];
  alreadyAddedPeople: any[];
  filters: Record<string, any>;
  ButtonComponent?: React.ElementType;
};

type ActiveFilters = {
  [key: string]: () => void;
};

const FiltersContainer = styled.div`
  margin-top: ${Grid._4};
`;

const ActiveFiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin: ${Grid._4} 0;
`;

type AddPeopleModalProps = {
  sortBy: Record<string, any>;
  onOpenModal?: () => void;
  selectedPeople: any[];
  handleDismiss: () => void;
  handleSubmit: () => void;
};

const AddPeopleModal: React.FC<AddPeopleModalProps> = ({ sortBy, onOpenModal, selectedPeople, ...props }) => {
  return (
    <>
      <ConfirmationModal
        buttonActionText={'Add'}
        buttonType="primary"
        title="Add Members"
        prompt={<span>Are you sure you want to add {selectedPeople?.length} people?</span>}
        {...props}
      />
    </>
  );
};

const AddPeopleTo = ({
  user,
  isSubmitting,
  handleAddPeople,
  preventSubmitConfirmation = false,
  showInlineAddNewPerson = false,
  preventUnsentEmails = false,
  submitButtonText = 'Add',
  people = [],
  alreadyAddedPeople = [],
  filters,
  ButtonComponent = PrimaryButton,
}: AddPeopleToProps) => {
  const [data, setData] = useState({
    people: people,
    filteredPeople: alreadyAddedPeople,
  });

  const { results, query, setQuery } = useTextFilter(data.filteredPeople, [
    'displayName',
    'emailAddress',
    'firstName',
    'lastName',
    'email',
  ]);

  const { sortBy, sortedResults, updateSortBy, resetSortBy } = usePropertyFilter(results, filters);

  const activeFilters: ActiveFilters = Object.entries(sortBy).reduce<ActiveFilters>((acc, [key, value]) => {
    if (value.length > 0 && filters[key]?.displayName) {
      acc[filters[key].displayName] = () => resetSortBy(key);
    }
    return acc;
  }, {} as ActiveFilters);

  const selectAll = data.filteredPeople?.filter(person => !person.isSelected).length > 0;
  const selectedPeople = data.filteredPeople?.filter(person => person.isSelected);
  const showUnsentEmailsModal = !isSubmitting && selectedPeople.length > 0;
  const numFilterColumns = 5;
  const searchBoxSpan = numFilterColumns - (Object.keys(filters).length + 1);

  const resetFilters = () => Object.entries(activeFilters).forEach(([, resetFn]) => resetFn());

  const toggleSelection = (item: { emailAddress: string; email: string }, selection?: boolean) => {
    setData(prevData => ({
      ...prevData,
      filteredPeople: prevData.filteredPeople.map(person => {
        const isUser = person.emailAddress ? person.emailAddress === item.emailAddress : person.email === item.email;
        if (isUser) {
          return {
            ...person,
            isSelected: selection ?? !person.isSelected,
          };
        }
        return person;
      }),
    }));
  };

  const handleBulkSelect = (people: any[]) => people?.forEach(person => toggleSelection(person, selectAll));
  function onDismiss() {
    dismissModal();
  }

  const onSubmit = () => {
    handleAddPeople(selectedPeople);
  };

  const onOpenModal = (
    selectedPeople: any[],
    sortBy: Record<string, any>,
    handleAddPeople: (people: any[]) => void
  ) => {
    if (selectedPeople.length > 10) {
      openModal('addPeople', { selectedPeople, sortBy, handleAddPeople });
    } else {
      handleAddPeople(selectedPeople);
    }
  };

  const [modal, openModal, dismissModal] = useModal(
    (type: string, payload: { selectedPeople: any[] }, dismissModal: () => void) => {
      switch (type) {
        case 'addPeople':
          return (
            <AddPeopleModal
              // dismissModal={dismissModal}
              selectedPeople={payload.selectedPeople}
              handleDismiss={onDismiss}
              sortBy={sortBy}
              handleSubmit={onSubmit}
            />
          );
        default:
          return null;
      }
    }
  );

  const addAndSelect = (newPeople: any[], existingPeople: any[]) => {
    setData(prevData => ({
      ...prevData,
      people: [...prevData.people, ...newPeople],
      filteredPeople: [...prevData.filteredPeople, ...newPeople],
    }));
    handleBulkSelect([...selectedPeople, ...newPeople, ...existingPeople]);
  };

  const handleSubmit = () =>
    preventSubmitConfirmation ? onSubmit() : onOpenModal(selectedPeople, sortBy, handleAddPeople);

  return (
    <>
      {preventUnsentEmails && showUnsentEmailsModal && <UnsentEmailsModal sendEmails={onSubmit} />}
      <GroupContainer style={{ margin: '0', maxWidth: 'none' }}>
        <FiltersContainer>
          <div className={`grid-container grid-gap-16 grid-sm-col-${numFilterColumns} add-people-controls`}>
            <div className="primary-action">
              <ButtonComponent
                data-qa-hook="addPeopleAdd"
                disabled={!selectedPeople.length}
                operating={isSubmitting}
                onClick={handleSubmit}
              >
                {submitButtonText}{' '}
                {selectedPeople.length > 0 && !isSubmitting && (
                  <span className="rounded-label">{selectedPeople.length}</span>
                )}
              </ButtonComponent>
            </div>

            {Object.entries(sortBy)?.map(([key, items]) => {
              const defaultValue = items.length ? items.length + ' Selected' : 'None Selected';

              return (
                <Fragment key={key}>
                  <div className="add-people-sort">
                    <label>{filters[key].filterLabel ? filters[key].filterLabel : filters[key].displayName}</label>
                    <Select
                      data-qa-hook={key}
                      value={defaultValue}
                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                        updateSortBy(event.target.value, key);
                      }}
                    >
                      <option defaultValue={defaultValue}>{defaultValue}</option>
                      {filters[key].items?.map((item: any) => (
                        <option key={item.id} value={item.id} id={item.id}>
                          {items.includes(item.id) ? '✓ ' : ' '} {item.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                </Fragment>
              );
            })}

            <div className={`grid-sm-col-span-${searchBoxSpan}`}>
              <TextFilterInput
                id="addPeopleFilter"
                data-qa-hook="searchPeopleFilter"
                value={query || ''}
                onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
                clearInput={() => {
                  setQuery('');
                }}
                placeholder={'Search People'}
              />
            </div>
          </div>
        </FiltersContainer>

        <div className={'grid-container grid-sm-gap-16 grid-md-gap-40 grid-sm-col-4'}>
          <div className="grid-sm-col-span-3">
            <ActiveFiltersContainer>
              {Object.keys(activeFilters).length ? (
                <ActiveFilters count={sortedResults?.length} filters={activeFilters} onClearAll={resetFilters} />
              ) : (
                <div></div>
              )}
              <div>
                {sortedResults?.length > 0 && (
                  <LinkStyleButton onClick={() => handleBulkSelect(data.filteredPeople)} data-qa-hook="peopleSelectAll">
                    Select {selectAll ? 'All' : 'None'}
                  </LinkStyleButton>
                )}
              </div>
            </ActiveFiltersContainer>
            {sortedResults?.length > 0 ? (
              <PeopleSelectList
                user={user}
                filteredPeople={sortedResults}
                selectedPeople={selectedPeople}
                onSelect={toggleSelection}
                onDeselect={toggleSelection}
                showInlineAddNewPerson={showInlineAddNewPerson}
                people={data.people}
                addAndSelect={addAndSelect}
              ></PeopleSelectList>
            ) : showInlineAddNewPerson ? (
              <div>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <InlineAddNewPerson
                      allPeople={data.people}
                      selectedPeople={selectedPeople}
                      addAndSelect={addAndSelect}
                    />
                  </tbody>
                </table>
                <EmptyState noResults={true} />
              </div>
            ) : (
              <EmptyState noResults={true} />
            )}
          </div>
          <div className="hidden-xs">
            {!!selectedPeople?.length && (
              <SelectedPeopleList
                people={selectedPeople}
                onDeselect={toggleSelection}
                headingText="Selected"
              ></SelectedPeopleList>
            )}
          </div>
        </div>

        {modal}
      </GroupContainer>
    </>
  );
};

export default AddPeopleTo;
