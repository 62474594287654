angular.module('lwNamb').factory('taskListApiService', [
  '$rootScope',
  'api',
  'qt',
  function($rootScope, api, qt) {
    var timeoutSeconds = 15;
    return {
      publishTasklist: function(taskListId, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
        api
          .post('/v1/commands/PublishTaskList', {
            id: taskListId,
            initiatingUserId: initiatingUserId,
          })
          .then(null, function failure(reason) {
            deferred.reject(reason);
          });

        $rootScope.$on('TaskListPublished', function(name, event) {
          if (event.id == taskListId && !deferred.promise.isResolved()) {
            deferred.resolve(taskListId);
          }
        });

        return deferred.promise;
      },

      unpublishTasklist: function(taskListId, initiatingUserId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
        api
          .post('/v1/commands/UnPublishTaskList', {
            id: taskListId,
            initiatingUserId: initiatingUserId,
          })
          .then(null, function failure(reason) {
            deferred.reject(reason);
          });

        $rootScope.$on('TaskListUnPublished', function(name, event) {
          if (event.id == taskListId && !deferred.promise.isResolved()) {
            deferred.resolve(taskListId);
          }
        });

        return deferred.promise;
      },
    };
  },
]);
