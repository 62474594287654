import React from 'react';
import PropTypes from 'prop-types';
import { Select, Label, TextFilterInput, SelectMultiple } from '../components/FormElements';
import { PrimaryButton, DangerButton } from '../components/Buttons';
import WhiteLabel from '../filters/WhiteLabel';
import capitalizeFilter from '../filters/capitalize-filter';
import pluralFilter from '../filters/plural-filter';

const insensitiveCaseSort = (a, b) => {
  a = a.name.toLowerCase();
  b = b.name.toLowerCase();
  if (a === b) return 0;
  if (a > b) return 1;
  return -1;
};

const getRolesDropdown = org => {
  const roleIDs = [...new Set(org.memberRoles.flatMap(memberRole => memberRole.roles.map(role => role.id)))];
  const roles = roleIDs.map(roleId => org.roles.find(role => role.id.id === roleId));
  const rolesDropdown = roles.sort(insensitiveCaseSort).map(role => ({ name: role.name, value: role.id.id }));

  return rolesDropdown;
};

export const AssignmentStatusFilter = ({ filter }) => {
  const { value, update } = filter;
  return (
    <>
      <Label>Status</Label>
      <Select
        data-qa-hook="manageAssignmentsTrainingStatusFilter"
        value={value}
        onChange={event => update(event.target.value)}
      >
        <option value="">Show All</option>
        <option value="Finished">Show Completed</option>
        <option value="Started">Show In Progress</option>
        <option value="NotStarted">Show Not Started</option>
      </Select>
    </>
  );
};

AssignmentStatusFilter.propTypes = {
  filter: PropTypes.object.isRequired,
};

export const AssignmentAssignmentFilter = ({ allAssignments, filter, disabled }) => {
  const { value, update } = filter;
  const courseLabel = `All ${pluralFilter(capitalizeFilter(WhiteLabel.labels.course))}`;
  const pathwayLabel = `All ${pluralFilter(capitalizeFilter(WhiteLabel.labels.pathway))}`;

  //if course and pathway labels are the same, don't show options for selecting all courses/pathways
  //this happens in e.g. Family Research Council whitelabel, where both are called "material"
  const baseOptions =
    courseLabel === pathwayLabel
      ? [{ name: 'All Assignments', value: '' }]
      : [
          { name: 'All Assignments', value: '' },
          { name: courseLabel, value: 'ALL_TL' },
          { name: pathwayLabel, value: 'ALL_WF' },
        ];
  const assignmentNames = allAssignments.map(assignment => assignment.assignable.name);
  const uniqueAssignmentNames = [...new Set(assignmentNames)].sort((a, b) => a.localeCompare(b));
  const assignmentDropdown = [...baseOptions, ...uniqueAssignmentNames.map(name => ({ name: name, value: name }))];

  return (
    <>
      <Label>Assignment</Label>
      <Select
        data-qa-hook="manageAssignmentsTrainingFilter"
        value={value}
        onChange={event => update(event.target.value)}
        disabled={disabled}
      >
        {assignmentDropdown.map(tl => (
          <option key={tl.value} value={tl.value}>
            {tl.name}
          </option>
        ))}
      </Select>
    </>
  );
};

AssignmentAssignmentFilter.propTypes = {
  allAssignments: PropTypes.array.isRequired,
  filter: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

export const AssignmentQueryFilter = ({ filter, ...props }) => (
  <TextFilterInput
    value={filter.value}
    onChangeHandler={e => filter.update(e.target.value)}
    clearInput={() => filter.update('')}
    placeholder="Search"
    {...props}
  />
);

AssignmentQueryFilter.propTypes = {
  filter: PropTypes.object.isRequired,
};

const UnassignButton = ({ incompleteAssignments, selectedAssignments, isUnassigning, openModal }) => {
  const handleOpenModal = () => {
    if (incompleteAssignments.length > 0) {
      openModal('unassignTraining', { incompleteAssignments, selectedAssignments });
    } else {
      openModal('unassignNoTraining', { incompleteAssignments, selectedAssignments });
    }
  };

  return (
    <DangerButton
      disabled={!selectedAssignments.length}
      style={{ width: '100%' }}
      operating={isUnassigning}
      onClick={handleOpenModal}
    >
      Unassign
      {incompleteAssignments.length > 0 && !isUnassigning && (
        <>
          {' '}
          <span className="rounded-label" data-qa-hook="manageAssignmentsUnassign">
            {incompleteAssignments.length >= 999 ? '+ 999' : incompleteAssignments.length}
          </span>
        </>
      )}
    </DangerButton>
  );
};

UnassignButton.propTypes = {
  incompleteAssignments: PropTypes.array.isRequired,
  selectedAssignments: PropTypes.array.isRequired,
  isUnassigning: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
};

const ExportButton = ({ selectedAssignments, isExporting, openModal }) => {
  return (
    <PrimaryButton
      disabled={!selectedAssignments.length}
      style={{ width: '100%' }}
      operating={isExporting}
      onClick={() => openModal('exportAssignments', { selectedAssignments })}
    >
      Export
      {selectedAssignments.length > 0 && !isExporting && (
        <>
          {' '}
          <span className="rounded-label" data-qa-hook="manageAssignmentsExport">
            {selectedAssignments.length >= 999 ? '+ 999' : selectedAssignments.length}
          </span>
        </>
      )}
    </PrimaryButton>
  );
};

ExportButton.propTypes = {
  selectedAssignments: PropTypes.array.isRequired,
  isExporting: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
};

export default function ManageAssignmentsControls({
  org,
  allAssignments,
  incompleteAssignments,
  selectedAssignments,
  multiFilter,
  isCPCA,
  isUnassigning,
  isExporting,
  openModal,
}) {
  const { filters } = multiFilter;

  const roleOptions = getRolesDropdown(org).sort((a, b) => a.name.localeCompare(b.name));
  const groupOptions = org.groups
    .filter(g => g.members.length > 0 || g.pendingMembers.length > 0)
    .map(g => g.name)
    .sort((a, b) => a.localeCompare(b))
    .map(group => ({ name: group, value: group }));

  const noAssignments = !allAssignments.length;

  return (
    <>
      <div
        className="grid-container grid-container grid-col-6 grid-sm-col-6 grid-col-gap-24 grid-row-gap-16 mb-10"
        style={{ alignItems: 'end' }}
      >
        <div className="grid-col-span-6 grid-sm-col-span-2">
          <SelectMultiple
            label="Role"
            options={roleOptions}
            selections={filters.roles.values}
            onSelection={filters.roles.update}
            disabled={noAssignments || isCPCA}
            data-qa-hook="manageAssignmentsRoleFilter"
          />
        </div>
        <div className="grid-col-span-6 grid-sm-col-span-2">
          <SelectMultiple
            label="Group"
            options={groupOptions}
            selections={filters.groups.values}
            onSelection={filters.groups.update}
            disabled={noAssignments || isCPCA}
            data-qa-hook="manageAssignmentsGroupFilter"
          />
        </div>
        <div className="grid-col-span-6 grid-sm-col-span-2">
          <AssignmentStatusFilter filter={filters.status} disabled={noAssignments} />
        </div>
        <div className="grid-col-span-6 grid-sm-col-span-2">
          <AssignmentAssignmentFilter
            allAssignments={allAssignments}
            filter={filters.assignment}
            disabled={noAssignments || isCPCA}
          />
        </div>
        <div className="grid-col-span-6 grid-sm-col-span-2">
          <AssignmentQueryFilter
            filter={filters.query}
            disabled={noAssignments}
            data-qa-hook="manageAssignmentsFilter"
          />
        </div>
        <div className="grid-col-span-6 grid-sm-col-span-1">
          <UnassignButton
            incompleteAssignments={incompleteAssignments}
            selectedAssignments={selectedAssignments}
            isUnassigning={isUnassigning}
            openModal={openModal}
          />
        </div>
        <div className="grid-col-span-6 grid-sm-col-span-1">
          <ExportButton selectedAssignments={selectedAssignments} isExporting={isExporting} openModal={openModal} />
        </div>
      </div>
    </>
  );
}

ManageAssignmentsControls.propTypes = {
  org: PropTypes.object.isRequired,
  allAssignments: PropTypes.array.isRequired,
  incompleteAssignments: PropTypes.array.isRequired,
  selectedAssignments: PropTypes.array.isRequired,
  multiFilter: PropTypes.object.isRequired,
  isUnassigning: PropTypes.bool,
  isExporting: PropTypes.bool,
  isCPCA: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
};
