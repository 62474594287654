angular.module('lwNamb').controller(
  'roleEditCtrl',
  [
    '$scope',
    '$log',
    '$routeParams',
    'userService',
    'orgService',
    'alertService',
    '$localStorage',
    function($scope, $log, $routeParams, userService, orgService, alertService, $localStorage) {
      var init = function() {
        $scope.isLoading = true;
        $scope.roleId = $routeParams.roleId;

        userService.user().then(
          function(user) {
            $scope.user = user;
            $scope.orgId = $scope.user.lastSelectedAccount;
            retrieveRole();
          },
          function() {
            $log.error('No user returned in roleEditCtrl');
          }
        );
      };

      var retrieveRole = function() {
        orgService.getRole($scope.roleId, $scope.orgId).then(
          function(role) {
            $scope.role = role;
            $localStorage.roleName = role.name;
            $scope.members = role.usersInRole.map(function(member) {
              if (member.email === $scope.user.logInEmail) {
                member.isYou = true;
              }
              return member;
            });
            $scope.isLoading = false;
          },
          function(reason) {
            $log.error(reason);
            $scope.isError = true;
            $scope.isLoading = false;
          }
        );
      };

      $scope.removeMember = function(memberId, closeModal) {
        $scope.removing = true;
        orgService.removeUserFromRole($scope.orgId, memberId, $scope.roleId, $scope.user.userId).then(
          function() {
            //Success
            for (var j = 0; j < $scope.members.length; j++) {
              if (memberId.id === $scope.members[j].userId.id) {
                showAlert('success', 'Member removed', 5);
                $scope.members.splice(j, 1);
                $scope.removing = false;
                closeModal();
                break;
              }
            }
          },
          function(response) {
            //Failure
            $log.error('Error! Member removal failure.');
            if (response && response === 'NoAdminRemaining') {
              showAlert('danger', 'Sorry! Removing this permission will leave no admin.', false);
            } else {
              showAlert('danger', 'Error! Please try again.', false);
            }
            closeModal();
            $scope.removing = false;
          }
        );
      };

      function showAlert(type, msg, duration) {
        alertService.show({ title: msg, type: type, show: true, dismissible: true, duration: duration });
      }

      init();
    },
  ]
);
