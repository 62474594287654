angular.module('lwNamb').controller(
  'MaterialCreateEditCtrl',

  [
    '$scope',
    '$log',
    '$filter',
    '$routeParams',
    '$localStorage',
    'windowService',
    'alertService',
    'uuid4',
    'userService',
    'fileUrlService',
    'apiUrlService',
    'videoService',
    'uploadService',
    '$timeout',
    'toastService',
    '$sce',
    'thirdPartyVideoService',
    'curriculumService',
    function(
      $scope,
      $log,
      $filter,
      $routeParams,
      $localStorage,
      windowService,
      alertService,
      uuid4,
      userService,
      fileUrlService,
      apiUrlService,
      videoService,
      uploadService,
      $timeout,
      toastService,
      $sce,
      thirdPartyVideoService,
      curriculumService
    ) {
      var init = function() {
        $scope.failureAlert = undefined;
        $scope.type = $routeParams._type;
        $scope.curriculumId = $routeParams.curriculumId;
        $scope.issueId = $routeParams.issueId;
        $scope.collectionIds = $routeParams.collectionIds ? $routeParams.collectionIds.split(',')
          .filter(function(c) { return c.length > 0; }) : [];
        $scope.selectedSeriesName = $localStorage.selectedSeriesName;
        $scope.sessionId = $routeParams.sessionId;
        $scope.volumeId = $routeParams.volumeId;
        $scope.materialId = $routeParams.materialId;
        $scope.order = parseInt($routeParams.order || 0);
        $scope.creating = isCreateRoute($routeParams.creating, !!$scope.volumeId, $scope.materialId);
        $scope.packageType = $routeParams.packageType;
        $scope.enableMetaData = !$scope.creating;
        $scope.material = {};
        $scope.assetId = '';
        $scope.spinner = false;
        $scope.uploadToServerComplete = false;
        //Retrieve Task if not creating
        if (($scope.sessionId !== 'undefined' && $scope.sessionId !== undefined) || !!$scope.volumeId) {
          $scope.isSupportMaterial = false;
        } else {
          $scope.isSupportMaterial = true;
        }
        userService.user().then(
          function(user) {
            $scope.user = user;
            if ($scope.creating !== undefined && !isCreate()) {
              retrieveUserData();
            }
          },
          function() {
            $log.error('No user returned in MaterialCreateEditCtrl');
          }
        );
      };

      var isCreateRoute = function(creatingParam, isVolume, materialId) {
        return creatingParam === 'true' || (isVolume && !materialId);
      };

      var isCreate = function() {
        return $scope.creating === true;
      };

      var retrieveUserData = function() {
        var id;
        if ($scope.isSupportMaterial) {
          id = $scope.issueId;
        } else {
          id = $scope.sessionId || $scope.volumeId;
        }
        curriculumService.getMaterial(id, $scope.materialId).then(
          function(material) {
            if (material !== undefined) {
              $scope.material = material;
              if ($scope.material.mediaId !== undefined && $scope.material.materialType === 'Download') {
                fileUrlService.getFileMetaData($scope.material.mediaId).then(
                  function(response) {
                    var metaData = response.fileMetaData;
                    $scope.material.fileName = metaData.fileName;
                  },
                  function(response) {
                    $log.error(response);
                  }
                );
              } else if ($scope.material.mediaId !== undefined && $scope.material.materialType === 'Video') {
                videoService.getVideo($scope.material.mediaId).then(
                  function(response) {
                    var video = response.video;
                    $scope.material.videoTitle = video.title;
                  },
                  function(response) {
                    $log.error(response);
                  }
                );
              }
            }
          },
          function(reason) {
            $log.error(reason);
            alertService.show({ title: 'Error! Something went wrong.', type: 'danger', show: true, dismissible: true, duration: 20, });
          }
        );
      };

      $scope.stripFormat = function($html) {
        return $filter('stripStyles')($html);
      };

      //Growl Test Button
      $scope.growl = function() {
        toastService.success('Your video is encoded and ready to be viewed.', 'Encoding Complete!');
      };

      $scope.saveMaterial = function(material, isValid) {
        $scope.submitted = true;
        if (isValid) {
          $scope.spinner = true;
          if (isCreate()) {
            var promise;

            if (!!$scope.volumeId) {
              var materialData = {
                name: material.name,
                materialType: $scope.type.toLowerCase(),
                package: $scope.packageType,
              };

              if ($scope.type === 'Download' || $scope.type === 'Audio' || $scope.type === 'Video') {
                materialData.assetId = $scope.assetId;
              }
              if ($scope.type === 'Document' || $scope.type === 'Link') {
                materialData.description = material.description;
              }
              if ($scope.type === 'Link') {
                materialData.linkUrl = material.linkUrl;
              }

              promise = curriculumService.createVolumeMaterial($scope.curriculumId, $scope.volumeId, materialData);
            } else {
              if ($scope.type === 'Download') {
                if ($scope.isSupportMaterial) {
                  promise = curriculumService.createIssueDownloadMaterial(
                    material.name,
                    $scope.order,
                    $scope.assetId,
                    $scope.issueId,
                    $scope.collectionIds,
                    $scope.curriculumId
                  );
                } else {
                  promise = curriculumService.createSessionDownloadMaterial(
                    material.name,
                    $scope.order,
                    $scope.assetId,
                    $scope.sessionId,
                    $scope.collectionIds,
                    $scope.curriculumId,
                    $scope.packageType
                  );
                }
              }

              if ($scope.type === 'Video') {
                if ($scope.isSupportMaterial) {
                  promise = curriculumService.createIssueVideoMaterial(
                    material.name,
                    $scope.order,
                    $scope.assetId,
                    $scope.issueId,
                    $scope.collectionIds,
                    $scope.curriculumId
                  );
                } else {
                  promise = curriculumService.createSessionVideoMaterial(
                    material.name,
                    $scope.order,
                    $scope.assetId,
                    $scope.sessionId,
                    $scope.collectionIds,
                    $scope.curriculumId,
                    $scope.packageType
                  );
                }
              }

              if ($scope.type === 'Audio') {
                promise = curriculumService.createSessionAudioMaterial(
                  material.name,
                  $scope.order,
                  $scope.assetId,
                  $scope.sessionId,
                  $scope.collectionIds,
                  $scope.curriculumId,
                  $scope.packageType
                );
              }

              if ($scope.type === 'Link') {
                if ($scope.isSupportMaterial) {
                  promise = curriculumService.createIssueLinkMaterial(
                    material.name,
                    material.description,
                    material.linkUrl,
                    $scope.order,
                    $scope.issueId,
                    $scope.collectionIds,
                    $scope.curriculumId
                  );
                } else {
                  promise = curriculumService.createSessionLinkMaterial(
                    material.name,
                    material.description,
                    material.linkUrl,
                    $scope.order,
                    $scope.sessionId,
                    $scope.collectionIds,
                    $scope.curriculumId,
                    $scope.packageType
                  );
                }
              }

              if ($scope.type === 'Text') {
                if ($scope.isSupportMaterial) {
                  promise = curriculumService.createIssueTextMaterial(
                    material.name,
                    material.description,
                    $scope.order,
                    $scope.issueId,
                    $scope.collectionIds,
                    $scope.curriculumId
                  );
                } else {
                  promise = curriculumService.createSessionTextMaterial(
                    material.name,
                    material.description,
                    $scope.order,
                    $scope.sessionId,
                    $scope.collectionIds,
                    $scope.curriculumId,
                    $scope.packageType
                  );
                }
              }
            }

            promise.then(
              function() {
                $scope.redirectToBuilder();
              },
              function() {
                $scope.spinner = false;
                showAlertFailure();
              }
            );
          }
        }
      };

      $scope.redirectToBuilder = function() {
        if (!$scope.volumeId) {
          windowService.redirectHash(
            '#/curriculum/' + $scope.curriculumId + '/issue/' + $scope.issueId + '/session-builder/' + $scope.sessionId
          );
        } else {
          windowService.redirectHash('#/curriculum/' + $scope.curriculumId + '/manage-volume/' + $scope.volumeId + '/add-material');
        }
      };

      var showAlertFailure = function() {
        var creatingSaving = 'save';
        if (isCreate()) {
          creatingSaving = 'create';
        }

        alertService.show({
          title: 'Unexpected Error!',
          content: 'We were unable to ' + creatingSaving + ' material at this time.',
          type: 'danger danger-with-content',
          duration: 20,
        });
      };

      var uploadSuccess = function(file) {
        $scope.uploadToServerComplete = true;
      };

      var uploadFailure = function(reason) {
        $scope.uploadToServerComplete = false;
        if (reason === 'FILE_TOO_LARGE') {
          $scope.fileTooLargeError = true;
        } else if (reason === 'CANCELLED') {
        } else {
          $scope.mediaUploadError = true;
        }
      };

      $scope.uploadMaterialFile = function(file) {
        if (file) {
          $scope.enableMetaData = true;
          $scope.assetId = uuid4.generate();
          if ($scope.type === 'Download') {
            uploadService
              .uploadCurriculumFile($scope.assetId, $scope.curriculumId, file.name, file, $scope.user.userId)
              .then(
                function() {
                  uploadSuccess(file);
                },
                function(reason) {
                  uploadFailure(reason);
                }
              );
          }
          if ($scope.type === 'Video') {
            uploadService
              .uploadCurriculumVideo($scope.assetId, $scope.curriculumId, file.name, file, $scope.user.userId)
              .then(
                function() {
                  uploadSuccess(file);
                },
                function(reason) {
                  uploadFailure(reason);
                }
              );
          }
          if ($scope.type === 'Audio') {
            uploadService
              .uploadCurriculumAudio($scope.assetId, $scope.curriculumId, file.name, file, $scope.user.userId)
              .then(
                function() {
                  uploadSuccess(file);
                },
                function(reason) {
                  uploadFailure(reason);
                }
              );
          }
        }
      };

      $scope.cancelUpload = function() {
        $log.debug('Upload Cancelled!');
        $scope.uploadToServerComplete = false;
        $scope.fileTooLargeError = false;
        $scope.videoUploadError = false;
        $scope.enableMetaData = false;
        uploadService.cancelUpload();
      };

      $scope.downloadFile = function() {
        fileUrlService.openFile('', $scope.material.fileName, '/curriculum-storage/files/' + $scope.material.mediaId);
      };

      $scope.parseVideo = function(url, task) {
        $scope.parseError = false;
        var result = thirdPartyVideoService.getVideoDataFromUrl(url);
        if (result !== undefined) {
          if (task !== undefined) {
            task.videoUrl = result.url;
          }
        } else {
          $scope.parseError = true;
        }
      };

      init();
    },
  ] //End Tasks Ctrl
);
