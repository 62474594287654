import React from 'react';
import styled from 'styled-components';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { MediumPrimaryButton, SmallRoundedIconButton, ButtonGroup } from './Buttons';

const UL = styled.ul`
  padding: 0;
  margin: 0;
`;

const LI = styled.li`
  list-style: none;
`;

export const OrderingDragContainer = SortableContainer(props => <UL {...props}>{props.children}</UL>);
export const OrderingDragItem = SortableElement(props => <LI {...props}>{props.children}</LI>);
export const OrderingDragHandle = SortableHandle(props => (
  <SmallRoundedIconButton {...props}>
    <i className="fas fa-bars"></i>
  </SmallRoundedIconButton>
));

export const OrderingButtonGroup = ({ save, isSaving, decrement, increment, horizontal = true }) => (
  <ButtonGroup>
    <MediumPrimaryButton data-qa-hook="OrderSave" onClick={save} disabled={isSaving} operating={isSaving}>
      Save Order
    </MediumPrimaryButton>
    <SmallRoundedIconButton
      data-qa-hook="OrderIncrement"
      title="Decrement Item"
      className="transparent"
      disabled={!decrement}
      onClick={decrement}
      style={{ transform: `rotate(${horizontal ? '270' : '0'}deg)` }}
    >
      <i className="fas fa-chevron-up"></i>
    </SmallRoundedIconButton>
    <SmallRoundedIconButton
      data-qa-hook="OrderDecrement"
      title="Increment Item"
      className="transparent"
      disabled={!increment}
      onClick={increment}
      style={{ transform: `rotate(${horizontal ? '90' : '180'}deg)` }}
    >
      <i className="fas fa-chevron-up"></i>
    </SmallRoundedIconButton>
  </ButtonGroup>
);
