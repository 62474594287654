angular.module('lwNamb').factory(
  'scorecardInstanceService',

  [
    '$rootScope',
    'api',
    'qt',
    'uuid4',
    'userService',
    'assessTakerService',
    function($rootScope, api, qt, uuid4, userService, assessTakerService) {
      var timeoutSeconds = 15;

      return {
        getScoreCard: function(scorecardTask, userId, scorecardId, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
          var retrieveAssessmentResponseWithQuestions = function(assessmentResponseId) {
            return assessTakerService
              .getAssessmentResponseWithQuestions(assessmentResponseId)
              .then(function(responseWithQuestions) {
                var sortedQuestions = responseWithQuestions.questions.sort(function(q1, q2) {
                  var i1 = q1.index;
                  var i2 = q2.index;
                  if (i1 < i2) {
                    return -1;
                  } else if (i1 > i2) {
                    return 1;
                  } else {
                    return 0;
                  }
                });
                // transform breaks to newlines for text answers
                sortedQuestions.forEach(function(question) {
                  if (question.questionType === 'textbox' && question.answers) {
                    question.answers.forEach(function(answer) {
                      answer.value = answer.value.replace(/<br>/g, '\n');
                    });
                  }
                });
                responseWithQuestions.questions = sortedQuestions;
                return responseWithQuestions;
              });
          };

          if (scorecardTask.task.responseId === '' || scorecardTask.task.responseId === undefined) {
            var responseId = uuid4.generateId().id;
            scorecardTask.task.responseId = responseId;
            var startTask = {
              id: scorecardId,
              initiatingUserId: initiatingUserId,
              assigneeId: userId,
              taskInstance: scorecardTask,
            };
            api.post('/v1/commands/StartTask', startTask).then(null, function() {
              deferred.reject('Error Starting Task.');
            });
            $rootScope.$on('AssessmentResponseCreated', function(name, event) {
              if (event.id == responseId) {
                retrieveAssessmentResponseWithQuestions(responseId).then(
                  function(responseWithQuestions) {
                    deferred.resolve(responseWithQuestions);
                  },
                  function() {
                    deferred.reject('Error retrieving assessment response with questions.');
                  }
                );
              }
            });
          } else {
            retrieveAssessmentResponseWithQuestions(scorecardTask.task.responseId).then(
              function(responseWithQuestions) {
                deferred.resolve(responseWithQuestions);
              },
              function() {
                deferred.reject('Error retrieving response with questions.');
              }
            );
          }
          return deferred.promise;
        },
        saveQuestionsAndAnswers: function(
          scorecardId,
          scorecardResult,
          scorecardTask,
          assigneeUserId,
          initiatingUserId
        ) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          var unfilteredAnsweredQuestions = scorecardResult.questions.map(function(question) {
            if (question.questionType === 'radio') {
              var selectedAnswer = question.answers.find(function(answer) {
                return answer.wasChosen === true;
              });
              if (selectedAnswer) {
                return {
                  questionId: question.questionId.id,
                  answer: {
                    questionType: question.questionType,
                    answerId: selectedAnswer.possibleAnswerId,
                  },
                };
              }
            } else if (question.questionType == 'textbox') {
              var value = question.answers[0].value;
              if (value && value.trim()) {
                return {
                  questionId: question.questionId.id,
                  answer: {
                    questionType: question.questionType,
                    value: question.answers[0].value.replace(/\r\n|\r|\n/g, '<br>'), // transform newlines to breaks
                  },
                };
              }
            }
          });
          var answeredQuestions = unfilteredAnsweredQuestions.filter(function(qp) {
            return qp !== undefined;
          });

          var completeTaskHandler = function() {
            if (answeredQuestions.length == scorecardResult.questions.length) {
              api
                .post('/v1/commands/CompleteTask', {
                  id: scorecardId,
                  initiatingUserId: initiatingUserId,
                  assigneeId: assigneeUserId,
                  taskInstance: scorecardTask,
                })
                .then(null, function() {
                  deferred.reject('Error completing task');
                });
            } else {
              deferred.resolve();
            }
          };
          $rootScope.$on('TaskInstanceCompleted', function(name, event) {
            if (event.id == scorecardId) {
              deferred.resolve();
            }
          });
          if (answeredQuestions.length > 0) {
            assessTakerService
              .saveAll(scorecardResult._id.id, scorecardResult.assessmentId, answeredQuestions, initiatingUserId)
              .then(
                function() {
                  completeTaskHandler();
                },
                function() {
                  deferred.reject('Error saving all questions.');
                }
              );
          } else {
            deferred.reject('NO_QUESTIONS_ANSWERED');
          }

          return deferred.promise;
        },
      };
    },
  ]
);
