import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import uuid from '../services/uuid';

import eventBus from '../services/globalEventBus';
import TimeoutPromise from '../services/TimeoutPromise';
import trainingInstanceService from '../services/trainingInstanceService';
import workflowService from '../services/workflowService';

import { handleError } from '../utils/apiUtils';

export const StepsStateContext = React.createContext();
export const StepsDispatchContext = React.createContext();

const EMPTY = Symbol('Empty Context');

export const useStepsState = () => {
  const context = React.useContext(StepsStateContext);
  if (context === EMPTY) {
    throw new Error('useStepsState must be used within a StepsProvider');
  }
  return context;
};

export const useStepsDispatch = () => {
  const context = React.useContext(StepsDispatchContext);
  if (context === EMPTY) {
    throw new Error('useStepsDispatch must be used within a StepsProvider');
  }
  return context;
};

const ASYNCSTATUS = {
  IDLE: 'IDLE',
  COMPLETING: 'COMPLETING',
  FETCHING: 'FETCHING',
  SAVING: 'SAVING',
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED',
};

export const ACTIONS = {
  SET_TRAINING: 'set-training',
  SET_MOBILE_TRAINING_MENU: 'set-mobile-training-menu',
  GET_CURRENT_COURSE: 'get-current-course',
  UPDATE_CURRENT_STEP: 'update-current-step',
  ADD_ASSESSMENT_TASK_RESPONSE_ID: 'add-assessment-task-response-id',
  ADD_TO_COMPLETING_STEP_INDEXES: 'add-to-completing-step-indexes',
  REMOVE_FROM_COMPLETING_STEP_INDEXES: 'remove-from-completing-step-indexes',
  FINISH_COURSE_IN_WORKFLOW: 'finish-course-in-workflow',
  SET_SHOW_TRAINING_COMPLETED_MODAL: 'set-show-training-completed-modal',
  CHANGE_STEP: 'change-step',
};

const initialStepIndex = (tasks, stepInstanceId, localStorageKey) => {
  const saved = localStorage.getItem(localStorageKey);
  if (saved && saved < tasks?.length) {
    return Number(saved);
  } else {
    const stepIndex =
      stepInstanceId === undefined
        ? -1
        : tasks.findIndex(function (task) {
            return task.id.id === stepInstanceId;
          });
    return stepIndex === -1 ? 0 : stepIndex;
  }
};

const updateCourseProgress = course => {
  let updatedProgress,
    completedSteps = course.tasks.filter(step => step.status === 'complete').length,
    totalTasks = course.tasks.length;

  if (course.progress.steps) {
    updatedProgress = {
      steps: {
        total: totalTasks,
        completed: completedSteps,
      },
      percent: (completedSteps / totalTasks) * 100,
      _type: completedSteps / totalTasks === 1 ? 'Finished' : 'Started',
    };
  } else {
    updatedProgress = completedSteps / totalTasks;
  }

  return updatedProgress;
};

const getCurrentCourseIndex = (training, isWorkflow) =>
  isWorkflow && training?.progress?._type !== 'Finished'
    ? training?.courses?.findIndex(course => course?.progress?._type !== 'Finished')
    : 0;

const updateCurrentCourse = (course, newStatus, uploadedAssetUrl, currentStepIndex) => {
  const updatedCourse = {
    ...course,
    tasks: course.tasks.map((courseTask, i) => {
      if (i == currentStepIndex) {
        return {
          ...courseTask,
          status: newStatus,
          ...(uploadedAssetUrl && {
            task: {
              ...courseTask.task,
              uploadedAssetUrl,
            },
          }),
        };
      } else {
        return courseTask;
      }
    }),
  };
  return {
    ...updatedCourse,
    progress: updateCourseProgress(updatedCourse),
  };
};

const updateCurrentCourseResponseId = (course, newStatus, responseId, currentStepIndex) => {
  const updatedCourse = {
    ...course,
    tasks: course.tasks.map((courseTask, i) => {
      if (i === currentStepIndex) {
        return {
          ...courseTask,
          ...(newStatus && { status: newStatus }),
          ...(responseId && {
            task: {
              ...courseTask.task,
              responseId,
            },
          }),
        };
      } else {
        return courseTask;
      }
    }),
  };
  return updatedCourse;
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_TRAINING: {
      return {
        ...state,
        training: action.payload.training,
      };
    }
    case ACTIONS.FINISH_COURSE_IN_WORKFLOW: {
      const training = action.payload.training;
      return {
        ...state,
        training: {
          ...training,
          progress: {
            ...training.progress,
            _type: 'Finished',
          },
        },
      };
    }
    case ACTIONS.ADD_ASSESSMENT_TASK_RESPONSE_ID: {
      const { responseId, newStatus } = action.payload;
      const { currentCourseIndex, training, currentStepIndex, isWorkflow } = state;
      return isWorkflow
        ? (() => {
            const updatedCourses = training.courses.map((course, i) =>
              i === currentCourseIndex
                ? updateCurrentCourseResponseId(course, newStatus, responseId, currentStepIndex)
                : course
            );

            return {
              ...state,
              training: {
                ...training,
                courses: updatedCourses,
              },
            };
          })()
        : {
            ...state,
            training: updateCurrentCourseResponseId(training, newStatus, responseId, currentStepIndex),
          };
    }
    case ACTIONS.SET_MOBILE_TRAINING_MENU: {
      return {
        ...state,
        isMobileTrainingMenuOpen: action.payload.isMobileTrainingMenuOpen,
      };
    }
    case ACTIONS.UPDATE_CURRENT_STEP: {
      const { newStatus, uploadedAssetUrl } = action.payload;
      const { currentCourseIndex, training, currentStepIndex, isWorkflow } = state;

      return isWorkflow
        ? (() => {
            const updatedCourses = training.courses.map((course, i) =>
              i === currentCourseIndex
                ? updateCurrentCourse(course, newStatus, uploadedAssetUrl, currentStepIndex)
                : course
            );

            const completedCourses = updatedCourses.filter(course => course.progress._type === 'Finished');
            const percent = (completedCourses.length / training.courses.length) * 100;

            return {
              ...state,
              training: {
                ...training,
                courses: updatedCourses,
                progress: {
                  _type: percent === 100 ? 'Finished' : 'Started',
                  percent: percent,
                  steps: {
                    total: training.courses.length,
                    completed: completedCourses.length,
                  },
                },
              },
            };
          })()
        : {
            ...state,
            training: updateCurrentCourse(training, newStatus, uploadedAssetUrl, currentStepIndex),
          };
    }
    case ACTIONS.CHANGE_STEP: {
      return {
        ...state,
        currentStepIndex: action.payload.stepIndex,
        currentCourseIndex: action.payload.courseIndex,
        ...(action.payload.setShowLockedStep
          ? { setShowLockedStep: action.payload.setShowLockedStep }
          : { setShowLockedStep: false }),
        ...(action.payload.courseIndex && { currentCourse: action.payload.courseIndex }),
        ...(action.payload.isMobileTrainingMenuOpen
          ? {
              isMobileTrainingMenuOpen: action.payload.isMobileTrainingMenuOpen,
            }
          : { isMobileTrainingMenuOpen: false }),
      };
    }
    case ACTIONS.ADD_TO_COMPLETING_STEP_INDEXES: {
      const newIndexes = [...state.completingStepIndexes, action.payload.currentStepIndex];
      return {
        ...state,
        completingStepIndexes: newIndexes,
        status: ASYNCSTATUS.COMPLETING,
      };
    }
    case ACTIONS.REMOVE_FROM_COMPLETING_STEP_INDEXES: {
      return {
        ...state,
        status: ASYNCSTATUS.IDLE,
        completingStepIndexes: state.completingStepIndexes.filter(i => i !== action.payload.currentStepIndex),
      };
    }
    case ACTIONS.SET_SHOW_TRAINING_COMPLETED_MODAL: {
      return {
        ...state,
        showTrainingCompletedModal: action.payload.showTrainingCompletedModal,
        completeModalShown: true,
      };
    }
    default:
      break;
  }
  return state;
};

export const useSteps = () => {
  const stepsData = useStepsState();
  const { isWorkflow, training, currentStepIndex, currentCourseIndex, status } = stepsData;

  const dispatch = useStepsDispatch();

  const currentCourse = isWorkflow ? training?.courses[currentCourseIndex] : training;
  const currentStep = currentCourse?.tasks?.length > 0 ? currentCourse?.tasks[currentStepIndex] : {};
  const numCompletedSteps = currentCourse?.tasks?.filter(step => step.status === 'complete').length;
  const isLastStepInCourse = numCompletedSteps + 1 === currentCourse?.tasks?.length;
  const incompleteStepIndex = currentCourse?.tasks?.findIndex(step => step.status !== 'complete');
  const lastStepIndex =
    isLastStepInCourse && incompleteStepIndex >= 0 ? incompleteStepIndex : currentCourse?.tasks?.length - 1;

  return [
    {
      ...stepsData,
      currentStep,
      currentCourse: {
        ...currentCourse,
        progress: {
          ...currentCourse?.progress,
          _type: 'Started',
        },
      },
      currentStepIsIncomplete: currentStep.status !== 'complete',
      currentStepIsInProgress: currentStep.status === 'in_progress',
      numCompletedSteps,
      lastStepIndex,
      isLastStepInCourse,
      isCompletingStep: status === 'COMPLETING',
    },
    dispatch,
  ];
};

export const StepsProvider = ({ children, trainingInput, stepInstanceId, user }) => {
  const isWorkflow = Boolean(trainingInput?.courses);
  const localStorageKey = 'currentStepIndex';
  const currentCourseIndex = getCurrentCourseIndex(trainingInput, isWorkflow);
  const currentCourse = isWorkflow ? trainingInput.courses[currentCourseIndex] : trainingInput;
  // This is an expensive call since it checks local storage on every re-render. Maybe we can useMemo?
  const currentStepIndex = initialStepIndex(currentCourse?.tasks, stepInstanceId, localStorageKey);

  const initialState = {
    isWorkflow,
    numTotalCourses: trainingInput?.courses?.length,
    stepInstanceId,
    training: trainingInput,
    currentCourseIndex,
    currentStepIndex,
    completingStepIndexes: [],
    isMobileTrainingMenuOpen: false,
    localStorageKey,
    status: ASYNCSTATUS.IDLE,
    user,
    setShowLockedStep: false,
    lastStepIndex: currentCourse?.tasks?.length - 1,
    isOrgUser: user?.isOrgUser,
    assignedUser: isWorkflow ? trainingInput?.assigneeId?.id : trainingInput?.assignedUser,
    assigningOrg: isWorkflow ? trainingInput?.assignorId?.id : trainingInput?.assignedUser,
    showTrainingCompletedModal: false,
    completeModalShown: isWorkflow ? trainingInput.progress._type === 'Finished' : trainingInput.status === 'complete',
  };
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <StepsStateContext.Provider value={state}>
      <StepsDispatchContext.Provider value={dispatch}>{children}</StepsDispatchContext.Provider>
    </StepsStateContext.Provider>
  );
};

StepsProvider.propTypes = {
  trainingInput: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  stepInstanceId: PropTypes.string,
  user: PropTypes.object,
};

export const selectStep = (dispatch, payload) => {
  dispatch({
    type: ACTIONS.CHANGE_STEP,
    payload,
  });
};

export const setTraining = (dispatch, payload) => {
  dispatch({ type: ACTIONS.SET_TRAINING, payload: { training: payload } });
};

export const toggleMobileTrainingMenu = (dispatch, payload) => {
  dispatch({ type: ACTIONS.SET_MOBILE_TRAINING_MENU, payload: { isMobileTrainingMenuOpen: payload } });
};

export const updateCurrentStepInCourse = (dispatch, payload) => {
  dispatch({
    type: ACTIONS.UPDATE_CURRENT_STEP,
    payload: {
      newStatus: payload?.newStatus || 'complete',
      uploadedAssetUrl: payload?.uploadedAssetUrl || undefined,
    },
  });
};

export const addAssessmentTaskResponseId = (dispatch, payload) => {
  dispatch({
    type: ACTIONS.ADD_ASSESSMENT_TASK_RESPONSE_ID,
    payload: {
      responseId: uuid.generate(),
      newStatus: payload?.newStatus || undefined,
    },
  });
};

export const setShowTrainingCompletedModal = (dispatch, payload) => {
  dispatch({
    type: ACTIONS.SET_SHOW_TRAINING_COMPLETED_MODAL,
    payload,
  });
};

const subscribeToEvent = eventName =>
  TimeoutPromise({ seconds: 60 }, resolve => {
    const unsubscribe = eventBus.subscribe(eventName, event => {
      if (event.eventType === eventName) {
        unsubscribe();
        resolve();
      }
    });
  });

export const useCompleteStep = () => {
  const { isWorkflow, training, currentStepIndex, currentCourseIndex } = useStepsState();
  const dispatch = useStepsDispatch();

  const currentCourse = isWorkflow ? training?.courses[currentCourseIndex] : training;
  const currentStep = currentCourse?.tasks?.length > 0 ? currentCourse?.tasks[currentStepIndex] : undefined;
  const numCompletedSteps = currentCourse?.tasks?.filter(step => step.status === 'complete').length;
  const isLastStepInCourse = numCompletedSteps + 1 === currentCourse?.tasks?.length;

  const completeStep = useCallback(
    deletedStepOpt => {
      if (currentStep.status === 'complete') return;

      // Just in case the currentStepIndex in state changes while we are running the promise
      // we are setting completedIndex to send to the reducer so it doesn't change
      const completedIndex = currentStepIndex;

      dispatch({ type: ACTIONS.ADD_TO_COMPLETING_STEP_INDEXES, payload: { currentStepIndex: completedIndex } });

      const isLastCourseInWF = isWorkflow && training.progress.steps.completed + 1 === training.progress.steps.total;

      const completeStepPromises = [
        trainingInstanceService.completeStep(currentCourse.id, currentStep, deletedStepOpt),
      ];

      if (isWorkflow && isLastStepInCourse) {
        // These are side-effects of the CompleteTask command. They are not initiated directly by the frontend
        // but are initiated by the CompleteTask process. In these scenarios, in order for us to
        // proceed to the next task, we need these events to complete first. Once completed, we are
        // safe to either move to the next course instance or end the workflow. If neither of these
        // scenarios are in view, the CompleteTask command will be called, and we will proceed once we've
        // received the event response back for that single command.
        completeStepPromises.push(
          subscribeToEvent(isLastCourseInWF ? 'WorkflowInstanceEnded' : 'TaskListInstanceCreated')
        );
      }

      Promise.all(completeStepPromises)
        .then(() => {
          if (isWorkflow && isLastStepInCourse) {
            workflowService.getWorkflowInstanceDetailById(training.id).then(training => {
              dispatch({ type: ACTIONS.FINISH_COURSE_IN_WORKFLOW, payload: { training, isLastCourseInWF } });
            });
          } else {
            updateCurrentStepInCourse(dispatch);
          }
        })
        .catch(() => {
          handleError(
            'Oops, we’re unable to complete training at this time. We’re sorry for the inconvenience. Please try again later.'
          );
        })
        .finally(() => {
          dispatch({
            type: ACTIONS.REMOVE_FROM_COMPLETING_STEP_INDEXES,
            payload: { currentStepIndex: completedIndex },
          });
        });
    },
    [
      currentCourse.id,
      currentCourseIndex,
      currentStep,
      currentStepIndex,
      dispatch,
      isLastStepInCourse,
      isWorkflow,
      training,
    ]
  );

  return { completeStep };
};
