import React from 'react';
import { react2angular } from 'react2angular';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Color } from '../StyleGuide';
import { Container } from '../components/Layout';
import Tabs from '../training/Tabs';
import windowService from '../services/windowService';

const SubMenuContainerFluid = styled.div`
  z-index: 100;
  position: relative;
  background: ${Color.white};
  box-shadow: inset 0px 1px 2px rgba(70, 72, 84, 0.1);
  border-bottom: 1px solid ${Color.Gray._10};
`;

export default function TrainingSubMenu({ activeTab }) {
  return (
    <SubMenuContainerFluid>
      <Container>
        <Tabs style={{ margin: 0 }}>
          <li className={activeTab === 'catalog' ? 'active-tab' : ''}>
            <button
              data-qa-hook="subMenuTabCatalog"
              onClick={() => (activeTab !== 'catalog' ? windowService.redirectTo('#/catalog') : null)}
            >
              <i className="fas fa-grip-horizontal"></i> Catalog
            </button>
          </li>
          <li className={activeTab === 'search' ? 'active-tab' : ''}>
            <button
              data-qa-hook="subMenuTabSearch"
              onClick={() => (activeTab !== 'search' ? windowService.redirectTo('#/search') : null)}
            >
              <i className="icon ion-ios-search-strong"></i>Search
            </button>
          </li>
        </Tabs>
      </Container>
    </SubMenuContainerFluid>
  );
}

TrainingSubMenu.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

angular.module('lwNamb').component('trainingSubMenu', react2angular(TrainingSubMenu));
