import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Border, Color, Type, Shadow, Breakpoints } from '../StyleGuide';
import { SmallSecondaryButton } from './Buttons';

const PopOverTarget = styled.div`
  position: relative;
  width: 100%;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    width: inherit;
  }
`;

const calcPositionX = width => parseInt(width) + 10;
const calcPositionY = (height, offsetY) => parseInt(height) / 2 - parseInt(offsetY) / 2;
const calcPositionXMobile = (width, offsetX) => (parseInt(offsetX) - parseInt(width)) / 2;
const calcPositionYMobile = height => parseInt(height) + 10;

const PopOverContainer = styled.div`
  position: absolute;
  top: -${props => calcPositionYMobile(props.height)}px;
  left: ${props => calcPositionXMobile(props.width, props.offsetX)}px;
  width: ${props => props.width};
  border-radius: ${Border.radius};
  box-shadow: ${Shadow.large};
  background-color: ${Color.Blue._50};
  padding: ${Grid._4};
  h4,
  p {
    color: ${Color.white};
    margin: 0 0 10px;
    text-shadow: ${Type.Shadow.dark};
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    top: -${props => calcPositionY(props.height, props.offsetY)}px;
    left: -${props => calcPositionX(props.width)}px;
  }
`;

const Caret = styled.i`
  position: absolute;
  color: ${Color.Blue._50};
  bottom: -19px;
  right: 48%;
  &::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    text-shadow: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: ${Type.Scale._5};
    content: '\f0d7';
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      content: '\f0da';
    }
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    top: 40%;
    right: -7px;
  }
`;

const hasAcknowledged = key => localStorage.getItem(`popover__${key}`) === 'true';
const markAcknowledged = key => localStorage.setItem(`popover__${key}`, 'true');

//Defaults to left of popover target on desktop and top on mobile
const PopOver = ({ title, description, buttonText, autoFocus, storageKey, children }) => {
  const [showPopOver, setShowPopOver] = useState(!hasAcknowledged(storageKey));
  const [popOverHeight, setPopOverHeight] = useState(0);
  const [targetHeight, setTargetHeight] = useState(0);
  const [targetWidth, setTargetWidth] = useState(0);

  const targetRef = useRef();
  const popOverRef = useRef();

  const PopOverWidth = '250px';

  useLayoutEffect(() => {
    setTargetWidth(targetRef.current.clientWidth);
    setTargetHeight(targetRef.current.clientHeight);
    if (popOverRef.current) setPopOverHeight(popOverRef.current.clientHeight);
  }, []);

  const acknowledge = () => {
    setShowPopOver(false);
    markAcknowledged(storageKey);
  };

  return (
    <PopOverTarget ref={targetRef}>
      {children}
      {showPopOver && (
        <PopOverContainer
          ref={popOverRef}
          offsetX={targetWidth}
          offsetY={targetHeight}
          width={PopOverWidth}
          height={popOverHeight}
        >
          <h4>{title}</h4>
          <p>{description}</p>
          {buttonText && (
            <SmallSecondaryButton onClick={acknowledge} autoFocus={autoFocus}>
              {buttonText}
            </SmallSecondaryButton>
          )}
          <Caret />
        </PopOverContainer>
      )}
    </PopOverTarget>
  );
};

PopOver.defaultProps = {
  autoFocus: true,
};

PopOver.propTypes = {
  position: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  autoFocus: PropTypes.bool,
  storageKey: PropTypes.string.isRequired,
};

export default PopOver;
