import React from 'react';
import styled from 'styled-components';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { MediumButton, MediumPrimaryButton } from '../../../components/Buttons';
import { FormField, Radio, RadioListWithDescription } from '../../../components/FormElements';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../components/ModalElements';
import { useUser } from '../../../authentication';
import useManageTrainingRoute from '../../../hooks/useManageTrainingRoute';
import assessmentService from '../../../services/assessmentService';
import windowService from '../../../services/windowService';
import uuid from '../../../services/uuid';
import { handleError } from '../../../utils/apiUtils';

const Icon = styled.i`
  font-size: 16px !important;
`;

type Params = {
  closeModal: () => void;
  ownerId: string;
};

type FormValues = {
  name: string;
};

export const AddAssessmentModal = ({ ownerId, closeModal }: Params) => {
  const user = useUser();
  const { manageTrainingType, manageTrainingRoutePrefix } = useManageTrainingRoute();

  const handleSubmit = (values: FormValues, { setSubmitting, setErrors }: FormikHelpers<FormValues>) => {
    if (!user) return;

    assessmentService
      .createAssessment(uuid.generate(), user.userId, ownerId, values.name)
      .then(response => windowService.redirectTo(`${manageTrainingRoutePrefix}/assessments/${response.id}`))
      .catch(error => {
        if (error.eventType === 'AssessmentNameDuplicateError') {
          setSubmitting(false);
          setErrors({ name: 'Sorry, that assessment name is taken' });
        } else {
          handleError(error);
          closeModal();
        }
      });
  };

  return (
    <ModalContainer dismissHandler={closeModal}>
      <ModalHeader>
        <h3>Add Assessment</h3>
      </ModalHeader>
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(64, 'Name is too long')
            .when('contentType', {
              is: 'Assessment',
              then: Yup.string().required('A name is required'),
            }),
        })}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <ModalBody>
              <RadioListWithDescription>
                <FormField label="Name" name="name" type="text" placeholder="Enter Name" />
              </RadioListWithDescription>
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton type="submit" operating={isSubmitting} disabled={!values.name}>
                Add Assessment
              </MediumPrimaryButton>
              <MediumButton onClick={closeModal}>Cancel</MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};
