//Customer Experience Digital Data Layer
//spec: https://www.w3.org/2013/12/ceddl-201312.pdf
//
// Initial Object
// eslint-disable-next-line no-unused-vars
var digitalData = {
  page: null,
  event: [],
  user: [],
  version: '1.0',
};
// EXAMPLE structure of relevant attributes - see spec for details.
// Anything can be stored in these objects but some attributes are
// reserved in name and value type
//
// var digitalData = {
//   pageInstanceId: '',
//   page: {
//     pageInfo: {
//       pageID: '',
//       pageName: ''
//     },
//     category: null
//   },
//   event: [{
//     eventInfo: {
//       eventName: '',
//       eventAction: '',
//       type: '',
//       timeStamp: new Date()
//     },
//     category: {
//       primaryCategory: ''
//     },
//     attributes: null
//   }],
//   component: [{
//     componentInfo: {
//       componentID: '',
//       componentName: ''
//     },
//     category: {
//       primaryCategory: ''
//     },
//     attributes: null
//   }],
//   user: [{
//     segment: null,
//     profile: [{
//       profileInfo: {
//         profileID: ''
//       },
//       address: null,
//       social: null,
//       attributes: null
//     }]
//   }],
//   privacy: {
//     accessCategories: []
//   },
//   version: '1.0'
// };
