import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Color, Type, Border, Shadow, Transition } from '../StyleGuide';
import { Editor, RichUtils } from 'draft-js';

// Mixin
const withIconQuotes = ({ color }) => `
&:before,
  &:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: ${Type.Scale._3};
    color: ${color};
  }

  &:before {
    content: "\f10d";
    margin-right: ${Grid._3};
  }

  &:after {
    content: "\f10e";
    margin-left: ${Grid._3};
  }
`;

const EditorContainer = styled.div`
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 14px;
  padding: ${Grid._4};
  border-radius: ${Border.radius};
  margin-bottom: ${Grid._4};
  box-shadow: inset ${Shadow.small};
  transition: ${Transition.fast};
  overflow: hidden;

  div:nth-child(2) {
    /* override timeline component second child background inheritance */
    background: var(--white) !important;
  }

  &.has-focus {
    box-shadow: inset 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 1px rgba(0, 0, 0, 0.3),
      0px 0px 0px 5px rgba(255, 255, 255, 0.2);
  }
`;

const EditorControls = styled.div`
  padding-bottom: ${Grid._4};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  span {
    color: rgba(0, 0, 0, 0.7);
    margin: 0 ${Grid._3};

    &:first-child {
      margin-left: 0;
    }

    &.active {
      color: rgba(0, 0, 0, 0.8);
      font-weight: ${Type.Weight.bold};
    }
  }
`;

const EditorTextArea = styled.div`
  padding-top: ${Grid._4};
  line-height: ${Type.Leading.tallest};

  &.RichEditor-hidePlaceholder > .DraftEditor-root > .public-DraftEditorPlaceholder-root {
    display: none;
  }

  .DraftEditor-root {
    overflow: auto;
    height: ${props => props.height};
  }

  .public-DraftEditorPlaceholder-root {
    color: rgba(0, 0, 0, 0.25);
  }

  .RichEditor-blockquote {
    font-size: ${Type.Scale._2};
    line-height: ${Type.Leading.tallest};
    padding: ${Grid._3};
    margin: ${Grid._4} ${Grid._3};
    border-left: 3px solid ${Color.Blue._50};

    div {
      ${withIconQuotes({ color: Color.Secondary._30 })}
    }
  }
`;

const shouldHidePlaceholder = editorState => {
  const contentState = editorState.getCurrentContent();
  return contentState.hasText() || contentState.getBlockMap().first().getType() !== 'unstyled';
};

const getBlockStyle = block => {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    case 'ordered-list-item':
      return 'RichEditor-ordered-list-item';
    default:
      return null;
  }
};

const StyleButton = ({ active, label, onToggle, style }) => {
  const toggleStyle = e => {
    e.preventDefault();
    onToggle(style);
  };
  return (
    <span style={{ cursor: 'pointer' }} className={active ? 'active' : ''} onMouseDown={e => toggleStyle(e)}>
      {label}
    </span>
  );
};

/**
 * @type React.FC<any>
 */
// eslint-disable-next-line react/display-name
const RichTextEditor = React.forwardRef(
  ({ editorState, setEditorState, inlineStyles, blockTypes, config = {} }, ref) => {
    const [hasFocus, setHasFocus] = useState(false);
    const BLOCK_TYPES = blockTypes
      ? blockTypes
      : [
          { label: 'Blockquote', style: 'blockquote' },
          { label: 'UL', style: 'unordered-list-item' },
          { label: 'OL', style: 'ordered-list-item' },
        ];

    const BlockStyleControls = ({ editorState, onToggle }) => {
      const selection = editorState.getSelection();
      const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
      return (
        <>
          {BLOCK_TYPES.map(type => (
            <StyleButton
              key={type.label}
              active={type.style === blockType}
              label={type.label}
              onToggle={onToggle}
              style={type.style}
            />
          ))}
        </>
      );
    };

    const toggleBlockType = blockType => {
      let nextState = RichUtils.toggleBlockType(editorState, blockType);
      setEditorState(nextState);
    };

    const INLINE_STYLES = inlineStyles
      ? inlineStyles
      : [
          { label: 'Bold', style: 'BOLD' },
          { label: 'Italic', style: 'ITALIC' },
          { label: 'Underline', style: 'UNDERLINE' },
        ];

    const InlineStyleControls = ({ editorState, onToggle }) => {
      let currentStyle = editorState.getCurrentInlineStyle();
      return (
        <>
          {INLINE_STYLES.map(type => (
            <StyleButton
              key={type.label}
              active={currentStyle.has(type.style)}
              label={type.label}
              onToggle={onToggle}
              style={type.style}
            />
          ))}
        </>
      );
    };

    const toggleInlineStyle = inlineStyle => {
      let nextState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
      setEditorState(nextState);
    };

    const handleEvent = eventType => data => {
      if (eventType === 'input') setEditorState(data);
      if (eventType === 'focus') setHasFocus(true);
      if (eventType === 'blur') setHasFocus(false);

      if (ref) ref.current.dispatchEvent(new Event(eventType)); // a synthetic DOM event
    };

    return (
      <EditorContainer ref={ref} className={hasFocus && 'has-focus'}>
        <EditorControls>
          <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
          <BlockStyleControls editorState={editorState} onToggle={toggleBlockType} />
        </EditorControls>
        <EditorTextArea
          className={shouldHidePlaceholder(editorState) && 'RichEditor-hidePlaceholder'}
          height={config.height ?? 'auto'}
        >
          {/* TODO: Update to set arialabeledby */}
          <Editor
            blockStyleFn={getBlockStyle}
            editorState={editorState}
            onChange={handleEvent('input')}
            placeholder={config.placeholder ?? 'Enter Text'}
            spellCheck={true}
            stripPastedStyles={false}
            onFocus={handleEvent('focus')}
            onBlur={handleEvent('blur')}
          />
        </EditorTextArea>
      </EditorContainer>
    );
  }
);

export default RichTextEditor;

RichTextEditor.propTypes = {
  editorState: PropTypes.object,
  setEditorState: PropTypes.func,
  inlineStyles: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, style: PropTypes.string.isRequired })
  ),
  blockTypes: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, style: PropTypes.string.isRequired })
  ),
  config: PropTypes.shape({
    placeholder: PropTypes.string,
    height: PropTypes.string,
  }),
};
