import React, { useState, useMemo } from 'react';
import { Link } from 'react-scroll';
import { parseISO, format } from 'date-fns';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import {
  SessionLayout,
  SessionMenu,
  SessionMenuButtons,
  SessionMenuHeader,
  SessionMenuContents,
  SessionTimelineContainer,
  SessionTimelineHeader,
  DurationIcon,
  SessionMenuListItem,
} from '../../components/SessionTimelineElements';
import SessionTimelineBuilderContent from './SessionTimelineBuilderContent';
import { Button, PrimaryButton } from '../../components/Buttons';
import windowService from '../../services/windowService';
import sessionTimelineService, { SESSION_TIMELINE_STATUS } from '../../services/sessionTimelineService';
import alertService from '../../services/AlertService';
import SessionTimelineStatusBadge from './SessionTimelineStatusBadge';
import { useUser } from '../../authentication';
import ConfirmationModal from '../../components/ConfirmationModal';
import ErrorMessage from '../../components/ErrorMessage';
import { stripUTC } from '../../utils/dateUtils';
import useSessionTimeline from '../../hooks/useSessionTimeline';
import useCurriculum from '../../hooks/useCurriculum';

const StatusMap = {};
StatusMap[SESSION_TIMELINE_STATUS.PUBLISHED] = {
  bannerText: 'Leader Guide Unpublished',
  buttonText: 'Unpublish Leader Guide',
  modalButtonText: 'Unpublish',
  modalButtonType: 'danger',
  modalText: 'Are you sure you want to unpublish this leader guide?',
  modalWarning: 'This action will remove this leader guide from anyone who currently has access to it',
  nextStatus: SESSION_TIMELINE_STATUS.IN_PROGRESS,
};
StatusMap[SESSION_TIMELINE_STATUS.IN_REVIEW] = {
  bannerText: 'Leader Guide Published',
  buttonText: 'Publish Leader Guide',
  modalButtonText: 'Publish',
  modalButtonType: '',
  modalText: 'Are you sure you want to publish this leader guide?',
  modalWarning: '',
  nextStatus: SESSION_TIMELINE_STATUS.PUBLISHED,
};
StatusMap[SESSION_TIMELINE_STATUS.IN_PROGRESS] = {
  bannerText: 'Leader Guide Ready for Review',
  buttonText: 'Ready for Review',
  modalButtonText: 'Ready for Review',
  modalButtonType: '',
  modalText: 'Are you sure you want to mark this leader guide ready for review?',
  modalWarning: '',
  nextStatus: SESSION_TIMELINE_STATUS.IN_REVIEW,
};

const SessionTimelineBuilder = () => {
  const user = useUser();
  const { timeline, setTimeline, curriculum, session } = useSessionTimeline();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const { brand, ageCategory } = useCurriculum();

  const statusData = StatusMap[timeline.status.status];
  const isPreviewButtonDisabled =
    timeline.status.status === SESSION_TIMELINE_STATUS.IN_PROGRESS || !timeline.segments.length;

  const statusUpdatedBy = useMemo(() => {
    if (timeline.status.firstName && timeline.status.lastName)
      return `${timeline.status.firstName} ${timeline.status.lastName}`;

    if (timeline.status.userId === user.userId) {
      return `${user.firstName} ${user.lastName}`;
    }

    return '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeline.status]);

  const statusUpdatedDate = timeline.status.at ? format(parseISO(stripUTC(timeline.status.at)), 'MM/dd/yyyy') : '';

  const updateSessionTimelineStatus = () =>
    sessionTimelineService
      .updateSessionTimelineStatus(timeline.id, statusData.nextStatus, user.userId)
      .then(() => {
        alertService.showOnNextPage(statusData.bannerText);
        windowService.redirectTo(
          `#/manage-curriculum/${brand.code}/${ageCategory}/${timeline.curriculumId}/session-calendar`
        );
      })
      .catch(error => {
        console.error(error);
        alertService.showError();
        setShowConfirmationModal(false);
      });

  const reorderSegments = ({ oldIndex, newIndex }) => {
    const [reorderedSegments, originalOrderedSegments] = sessionTimelineService.changeOrderOfSegment(
      timeline,
      oldIndex,
      newIndex
    );

    setTimeline(previousTimeline =>
      sessionTimelineService.reorderSegmentsInTimeline(previousTimeline, reorderedSegments)
    );

    sessionTimelineService
      .reorderSegments(
        timeline.id,
        reorderedSegments.map(s => s.segmentId),
        user.userId
      )
      .then(() => alertService.show('Leader Guide Updated'))
      .catch(error => {
        console.error(error);
        alertService.showError();
        setTimeline(previousTimeline =>
          sessionTimelineService.reorderSegmentsInTimeline(previousTimeline, originalOrderedSegments)
        );
      });
  };

  const openPreview = () =>
    windowService.openRoute(
      `/curriculum/${curriculum.brandCode}/${curriculum.name}/session/${session.sessionId}/issue/${session.issueId}/timeline/${timeline.id}/preview`,
      true
    );

  const ReorderableSessionMenuContents = SortableContainer(props => (
    <SessionMenuContents>{props.children}</SessionMenuContents>
  ));
  const ReorderableSessionMenuItem = SortableElement(props => (
    <SessionMenuListItem>{props.children}</SessionMenuListItem>
  ));
  const OrderingDragHandle = SortableHandle(() => <i className="fas fa-bars drag-handle"></i>);

  return (
    <SessionLayout stickyOffset={60} topOffset={60}>
      <SessionMenu>
        <SessionMenuButtons>
          <PrimaryButton
            data-qa-hook="timelineStatusButton"
            disabled={!timeline.segments?.length}
            onClick={() => setShowConfirmationModal(true)}
          >
            {statusData.buttonText}
          </PrimaryButton>
          <Button onClick={openPreview} disabled={isPreviewButtonDisabled}>
            <i className="fas fa-external-link-alt"></i> View as Group Leader
          </Button>
        </SessionMenuButtons>
        <SessionMenuHeader>
          <h3 className="no-margin-top">Leader Guide Contents</h3>
          <p data-qa-hook="timelineDuration-Menu">
            <i className="fas fa-clock"></i>
            {` ${timeline.duration} Minutes`}
          </p>
        </SessionMenuHeader>
        <ReorderableSessionMenuContents onSortEnd={reorderSegments} useDragHandle>
          {!!timeline.segments.length &&
            timeline.segments.map((segment, i) => (
              <ReorderableSessionMenuItem key={`timeline-menu-contents-${i}`} index={i}>
                <Link
                  data-qa-hook={`contentMenuSegment-${i}`}
                  activeClass="active"
                  className="is-draggable"
                  name={`contents-${segment.segmentId}`}
                  to={`contents-${segment.segmentId}`}
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-60}
                >
                  {segment.name}
                </Link>
                <OrderingDragHandle />
              </ReorderableSessionMenuItem>
            ))}
        </ReorderableSessionMenuContents>
      </SessionMenu>
      <SessionTimelineContainer>
        <SessionTimelineHeader data-qa-hook="timelineHeaderContainer" className="mb-48">
          <div>
            <h1>Leader Guide</h1>
            <SessionTimelineStatusBadge
              status={timeline.status.status}
              updatedBy={statusUpdatedBy}
              updatedDate={statusUpdatedDate}
            />
          </div>
          <div>
            <DurationIcon duration={timeline.duration} />
          </div>
        </SessionTimelineHeader>
        <SessionTimelineBuilderContent />
      </SessionTimelineContainer>
      {showConfirmationModal && (
        <ConfirmationModal
          title={statusData.buttonText}
          prompt={<span>{statusData.modalText}</span>}
          buttonActionText={statusData.modalButtonText}
          buttonType={statusData.modalButtonType}
          handleSubmit={updateSessionTimelineStatus}
          handleDismiss={() => setShowConfirmationModal(false)}
        >
          {!!statusData.modalWarning && <ErrorMessage>{statusData.modalWarning}</ErrorMessage>}
        </ConfirmationModal>
      )}
    </SessionLayout>
  );
};

SessionTimelineBuilder.propTypes = {};

export default SessionTimelineBuilder;
