import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Type } from '../StyleGuide';
import { Container, InlineList } from '../components/Layout';
import { TrainingPoster, Labels } from '../components/TrainingElements';
import { RoundedSecondaryBadge, IconBadge } from '../components/Badge';
import TrainingButtons from './TrainingButtons';
import capitalizeFilter from '../filters/capitalize-filter';
import TrainingPosterPlaceholder from './TrainingPosterPlaceholder';
import WhiteLabel from '../filters/WhiteLabel';
import { useUser } from '../authentication';
import TrainingContext from '../contexts/TrainingContext';
import trainingService from '../services/trainingService';

const TrainingInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const TrainingName = styled.h1`
  margin: ${Grid._3} 0;
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._6};
  font-weight: ${Type.Weight.bold};
  line-height: ${Type.Leading.normal};
`;

const TrainingHeader = ({ redirectToLifeWayLoginFromLoggedOutState }) => {
  const user = useUser();
  const training = useContext(TrainingContext);
  return (
    <Container className="grid-container grid-col-4 grid-sm-col-8 grid-gap-24">
      <div className="grid-col-span-4 grid-sm-col-span-3">
        {training.posterImage ? (
          <TrainingPoster src={trainingService.getTrainingImageUrl(training.posterImage)} alt={training.name} />
        ) : (
          <TrainingPosterPlaceholder ownerName={training.ownerName} />
        )}
      </div>
      <div className="grid-col-span-4 grid-sm-col-span-5">
        <TrainingInformation>
          <Labels>
            {training.type === 'Pathway' && (
              <IconBadge className="icon-badge">
                <i className="fas fa-route"></i>
              </IconBadge>
            )}
            {training.type === 'Course'
              ? capitalizeFilter(WhiteLabel.labels.course)
              : capitalizeFilter(WhiteLabel.labels.pathway)}
            {training.searchAccess === 'Free' &&
              (!user || (!user.hasGridSubscription && !user.hasIndividualGridSubscription)) && (
                <RoundedSecondaryBadge className="rounded-secondary-badge">Free</RoundedSecondaryBadge>
              )}
          </Labels>
          <TrainingName data-qa-hook="searchPreviewTrainingName">{training.name}</TrainingName>
          <InlineList content="•" fontSize="18px">
            <li>
              {training.numItems}{' '}
              {training.type === 'Course'
                ? capitalizeFilter(WhiteLabel.labels.step)
                : capitalizeFilter(WhiteLabel.labels.course)}
              {training.numItems > 1 ? 's' : ''}
            </li>
            {training.ownerName && <li data-qa-hook="searchPreviewTrainingOwner">{training.ownerName}</li>}
          </InlineList>
          <TrainingButtons
            training={training}
            redirectToLifeWayLoginFromLoggedOutState={redirectToLifeWayLoginFromLoggedOutState}
          />
        </TrainingInformation>
      </div>
    </Container>
  );
};

TrainingHeader.propTypes = {
  redirectToLifeWayLoginFromLoggedOutState: PropTypes.func,
};

export default TrainingHeader;
