import apiClient from './apiClient';
import cacheableRequest from './cacheableRequest';
import submitCommand from './submitCommand';
import cacheService from './cacheService';

const assessmentService = {
  getAssessment: (assessmentId, assesseeId) => {
    return apiClient.get(`/v1/assessments/${assessmentId}?userId=${assesseeId}`).then(response => {
      return transformAssessment(response.data.assessment);
    });
  },

  getAssessmentsAggregate: orgId =>
    cacheableRequest(`/v1/assessments/aggregate/${orgId}`, 1).then(response =>
      response?.data.assessments.map(assessment => assessment.assessment)
    ),

  getAssessmentResultsAggregate: responseIds => {
    return apiClient.post(`/v1/assessments/aggregate`, { responseIds: responseIds }).then(response => response.data);
  },

  getAssessmentResponseWithQuestions: (assessmentResponseId, userIdOpt) => {
    return apiClient
      .get(
        `/v1/assessments/responseWithQuestions/${assessmentResponseId}${
          userIdOpt === undefined ? '' : '?userId=' + userIdOpt
        }`,
        undefined,
        [404],
        3
      )
      .then(response => {
        return transformAssessmentResponse(response.data);
      });
  },
  getAssessmentResults: assessmentResponseId => {
    return apiClient.get(`/v1/assessments/results/${assessmentResponseId}`).then(response => {
      return transformAssessmentResponse(response.data);
    });
  },
  getAssesseeData: (orgId, assessmentId) => {
    return apiClient.get(`v1/assessments/aggregate/${orgId}/${assessmentId}`).then(response => {
      return response.data;
    });
  },
  addQuestion: (
    assessmentId,
    ownerId,
    questionId,
    questionText,
    questionType,
    possibleAnswers,
    initiatingUserId,
    displayType
  ) => {
    cacheService.removeAllInPath(`/v1/organizations`);
    const cmd = {
      id: assessmentId,
      initiatingUserId,
      ownerId,
      questionId,
      questionText,
      questionType,
      possibleAnswers,
    };

    if (displayType) cmd.displayType = displayType;
    return submitCommand(assessmentId, cmd, 'AddQuestionToAssessment', 'QuestionAddedToAssessment', 'AssessmentError');
  },
  removeQuestion: (assessmentId, questionId, initiatingUserId) => {
    cacheService.removeAllInPath(`/v1/organizations`);
    const cmd = {
      id: assessmentId,
      initiatingUserId,
      questionId,
    };
    return submitCommand(assessmentId, cmd, 'RemoveQuestionFromAssessment', 'QuestionRemovedFromAssessment');
  },
  reorderQuestions: (assessmentId, questions, initiatingUserId) => {
    const cmd = {
      id: assessmentId,
      newSeqOfQuestions: questions,
      initiatingUserId,
    };
    return submitCommand(assessmentId, cmd, 'ReorderQuestions', 'QuestionsReordered', 'AssessmentError');
  },
  saveRadio: (responseId, initiatingUserId, assessmentId, questionId, answerId) => {
    const cmd = {
      id: responseId,
      assessmentId,
      questionId,
      answerId,
      initiatingUserId,
    };
    return submitCommand(responseId, cmd, 'SaveRadio', 'RadioToQuestionSaved', 'AssessmentResponseError', false);
  },
  saveShortAnswer: (responseId, initiatingUserId, assessmentId, questionId, answer) => {
    const cmd = {
      id: responseId,
      assessmentId,
      questionId,
      answer,
      initiatingUserId,
    };
    return submitCommand(responseId, cmd, 'SaveTextBox', 'TextBoxToQuestionSaved', 'AssessmentResponseError', false);
  },
  saveSortAnswer: (responseId, initiatingUserId, assessmentId, questionId, answers) => {
    const cmd = {
      id: responseId,
      initiatingUserId,
      assessmentId,
      questionId,
      answerIds: answers,
    };
    return submitCommand(responseId, cmd, 'SaveSort', 'SortToQuestionSaved', 'AssessmentResponseError', false);
  },
  updateAssessmentName: (assessmentId, userId, ownerId, name) => {
    const cmd = {
      id: assessmentId,
      initiatingUserId: userId,
      owner: ownerId,
      assessmentName: name,
    };
    return submitCommand(
      assessmentId,
      cmd,
      'UpdateAssessmentName',
      'AssessmentNameUpdated',
      'AssessmentNameDuplicateError'
    );
  },
  updateAssessmentIntro: (assessmentId, userId, introduction) => {
    const cmd = {
      id: assessmentId,
      initiatingUserId: userId,
      instructions: introduction,
    };
    return submitCommand(assessmentId, cmd, 'UpdateAssessmentIntro', 'AssessmentIntroUpdated');
  },
  updateAssessmentOutro: (assessmentId, userId, conclusion) => {
    const cmd = {
      id: assessmentId,
      initiatingUserId: userId,
      overview: conclusion,
    };
    return submitCommand(assessmentId, cmd, 'UpdateAssessmentOutro', 'AssessmentOutroUpdated');
  },
  setResponseVisibility: (assessmentId, userId, responseVisibility) => {
    const cmd = {
      id: assessmentId,
      initiatingUserId: userId,
      responseVisibility: responseVisibility,
    };
    return submitCommand(assessmentId, cmd, 'SetResponseVisibility', 'ResponseVisibilitySet');
  },
  copyAssessment: (assessmentIdToClone, newId, newName, owner, initiatingUserId) => {
    const cmd = {
      id: newId,
      initiatingUserId,
      fromId: assessmentIdToClone,
      newName,
      owner,
      published: false,
    };
    return submitCommand(newId, cmd, 'CloneAssessment', 'AssessmentNameUpdated', [
      'AssessmentFailedToClone',
      'AssessmentNameDuplicateError',
    ]);
  },
  publishAssessment: (assessmentId, initiatingUserId) => {
    const cmd = {
      id: assessmentId,
      initiatingUserId,
    };
    return submitCommand(assessmentId, cmd, 'PublishAssessment', 'AssessmentPublished');
  },
  createAssessment: (assessmentId, initiatingUserId, ownerId, name) => {
    cacheService.removeAllInPath(`/v1/organizations`);
    return submitCommand(
      assessmentId,
      {
        id: assessmentId,
        initiatingUserId,
        ownerId,
        name,
      },
      'CreateAssessment',
      'AssessmentCreated',
      'AssessmentNameDuplicateError'
    );
  },
  deleteAssessment: (assessmentId, orgId, userId) => {
    cacheService.remove(`/v1/organizations/${orgId}/content`);
    return submitCommand(
      assessmentId,
      {
        id: assessmentId,
        deletedBy: `${orgId}_${userId}`,
        initiatingUserId: userId,
      },
      'DeleteAssessment',
      'AssessmentDeleted',
      'AssessmentError'
    );
  },
  updateQuestion: (
    assessmentId,
    initiatingUserId,
    questionId,
    questionText,
    questionType,
    possibleAnswers,
    displayType
  ) => {
    const cmd = {
      id: assessmentId,
      initiatingUserId: initiatingUserId,
      questionId: questionId,
      questionText: questionText,
      questionType: questionType,
      possibleAnswers: possibleAnswers,
      ...(displayType && { displayType: displayType }),
    };

    return submitCommand(
      assessmentId,
      cmd,
      'UpdateQuestionForAssessment',
      'QuestionForAssessmentUpdated',
      'AssessmentError'
    );
  },
};

const transformAssessment = assessment => {
  return {
    name: assessment.name[0].value,
    instructions: (assessment.instructions.length && assessment.instructions[0].value) || '',
    overview: (assessment.overview.length && assessment.overview[0].value) || '',
    questions: assessment.questions.sort((a, b) => a.index - b.index),
    responseVisibility: assessment.responseVisibility,
    deleted: Boolean(assessment.deleted),
    deletedBy: assessment.deleted ? assessment.deletedBy : undefined,
    published: assessment.published || false,
    ownerId: assessment.ownerId.id,
  };
};

const transformAssessmentResponse = response => {
  return {
    ...response,
    questions: setupQuestions(response.questions).sort((a, b) => a.index - b.index),
    progress: response ? response.questions.filter(q => q.isAnswered).length : 0,
    allAnswered: response ? response.questions.every(q => q.isAnswered) : false,
  };
};

const setupQuestions = questions => {
  return questions.map(q => {
    q.isAnswered = q.answers.filter(a => a.wasChosen).length > 0 || q.answered === true;
    q.isGraded = !!q.answers.find(a => a.isCorrect);
    q.displayType = q.displayType ? q.displayType : q.questionType;
    return q;
  });
};

export default assessmentService;
