angular.module('lwNamb').controller(
  'TaskCreateEditCtrl',

  [
    '$scope',
    '$log',
    '$q',
    '$filter',
    '$modal',
    '$routeParams',
    '$location',
    'alertService',
    'uuid4',
    'userService',
    'taskListService',
    'assessBuilderService',
    'fileUrlService',
    'apiUrlService',
    'videoService',
    'uploadService',
    'thirdPartyVideoService',
    'buildService',
    function(
      $scope,
      $log,
      $q,
      $filter,
      $modal,
      $routeParams,
      $location,
      alertService,
      uuid4,
      userService,
      taskListService,
      assessBuilderService,
      fileUrlService,
      apiUrlService,
      videoService,
      uploadService,
      thirdPartyVideoService,
      buildService
    ) {
      var taskId = $routeParams.taskId,
        queryParams = $location.search(),
        workflowId = queryParams.workflowId,
        creating = !taskId,
        ownerId;

        $scope.ctxRedirectTo = buildService.ctxRedirectBackTo;
        $scope.workflowId = $routeParams.tasklistId;

      var init = function() {
        $scope.type = queryParams.taskType;
        $scope.creating = creating;
        $scope.enableMetaData = !creating;
        $scope.task = {};
        $scope.assetId = '';
        $scope.spinner = false;
        $scope.uploadToServerComplete = false;
        if (workflowId) {
          $scope.isWorkflowBuilder = true;
        }

        $q.all([userService.user(), buildService.ownerId()]).then(function(result) {
          var user = result[0];
          ownerId = result[1];
          $scope.user = user;
          retrieveTaskListData();
        });
      };

      var retrieveTaskListData = function() {
        taskListService.getTasklist($routeParams.tasklistId, true, $scope.user.userId).then(
          function(tasklist) {
            if (tasklist !== undefined && tasklist.tasks !== undefined) {
              $scope.tasklist = tasklist;
              $scope.isTaskListOwner = ownerId === tasklist.owner;
              var task = tasklist.tasks.filter(function(task) {
                return task._id.id === taskId;
              });
              if (task !== undefined && task.length === 1) {
                $scope.task = task[0];
                $scope.type = task[0]._type;
                if (task[0].assetUrl !== undefined && task[0]._type === 'Download') {
                  fileUrlService.getFileMetaData(task[0].assetUrl).then(
                    function(response) {
                      var metaData = response.fileMetaData;
                      $scope.task.fileName = metaData.fileName;
                    },
                    function(response) {
                      $log.error(response);
                    }
                  );
                }
                if (task[0].videoId !== undefined && task[0]._type === 'Video') {
                  videoService.getVideo(task[0].videoId).then(
                    function(response) {
                      var video = response.video;
                      $scope.task.videoTitle = video.title;
                    },
                    function(response) {
                      $log.error(response);
                    }
                  );
                }
                if (task[0]._type === 'ThirdPartyVideo') {
                  $scope.type = task[0].thirdPartySite; //set the type to YouTube/Vimeo
                }
                if (task[0]._type === 'Assessment') {
                  assessBuilderService.getAssessment(task[0].assessmentId, $scope.user.userId).then(
                    function(response) {
                      $scope.assessment = response;
                    },
                    function(reason) {
                      $log.error(reason);
                    }
                  );
                }
              }
            }
          },
          function(reason) {
            $log.error(reason);
            alertService.show({
              title: 'Error! Something went wrong.',
              type: 'danger',
              show: true,
              dismissible: true,
              duration: 20,
            });
          }
        );
      };

      $scope.stripFormat = function($html) {
        return $filter('stripStyles')($html);
      };

      $scope.saveTask = function(task, isValid) {
        $scope.submitted = true;
        if (isValid) {
          $scope.spinner = true;
          if (creating) {
            var newTaskId = uuid4.generate();
            var order = parseInt(queryParams.order);
            if ($scope.type === 'Text') {
              taskListService
                .addTextTask($routeParams.tasklistId, newTaskId, task.taskName, task.taskDescription, order, $scope.user.userId)
                .then(
                  function() {
                    redirectToBuilder($routeParams.tasklistId);
                  },
                  function() {
                    $scope.spinner = false;
                    showAlertFailure();
                  }
                );
            }
            if ($scope.type === 'Upload') {
              taskListService
                .addUploadTask($routeParams.tasklistId, newTaskId, task.taskName, task.taskDescription, order, $scope.user.userId)
                .then(
                  function() {
                    redirectToBuilder($routeParams.tasklistId);
                  },
                  function() {
                    $scope.spinner = false;
                    showAlertFailure();
                  }
                );
            }
            if ($scope.type === 'Download') {
              taskListService
                .addDownloadTask(
                  $routeParams.tasklistId,
                  newTaskId,
                  task.taskName,
                  task.taskDescription,
                  order,
                  $scope.assetId,
                  $scope.user.userId
                )
                .then(
                  function() {
                    redirectToBuilder($routeParams.tasklistId);
                  },
                  function() {
                    $scope.spinner = false;
                    showAlertFailure();
                  }
                );
            }
            if ($scope.type === 'Video' || $scope.type === 'Audio') {
              taskListService
                .addVideoTask(
                  $routeParams.tasklistId,
                  newTaskId,
                  task.taskName,
                  task.taskDescription,
                  order,
                  $scope.assetId,
                  $scope.user.userId
                )
                .then(
                  function() {
                    redirectToBuilder($routeParams.tasklistId);
                  },
                  function() {
                    $scope.spinner = false;
                    showAlertFailure();
                  }
                );
            }
            if ($scope.type === 'YouTube' || $scope.type === 'Vimeo') {
              var parsedUrl = thirdPartyVideoService.getVideoDataFromUrl(task.videoUrl);
              if (parsedUrl !== undefined) {
                var url = parsedUrl.url;

                taskListService
                  .addThirdPartyVideoTask(
                    $routeParams.tasklistId,
                    newTaskId,
                    task.taskName,
                    task.taskDescription,
                    order,
                    url,
                    $scope.type,
                    $scope.user.userId
                  )
                  .then(
                    function() {
                      redirectToBuilder($routeParams.tasklistId);
                    },
                    function() {
                      $scope.spinner = false;
                      showAlertFailure();
                    }
                  );
              } else {
                $scope.spinner = false;
              }
            }
            if ($scope.type === 'Link' || $scope.type === 'MyWSBLink' || $scope.type === 'Zoom') {
              var taskType = $scope.type === 'Zoom' ? 'Link' : $scope.type;
              
              taskListService
                .addLinkTask(
                  $routeParams.tasklistId,
                  task.taskName,
                  task.taskDescription,
                  task.linkUrl,
                  taskType,
                  order,
                  $scope.user.userId
                )
                .then(
                  function() {
                    redirectToBuilder($routeParams.tasklistId);
                  },
                  function() {
                    $scope.spinner = false;
                    showAlertFailure();
                  }
                );
            }
          } else {
            if ($scope.type === 'YouTube' || $scope.type === 'Vimeo') {
              var parsed = thirdPartyVideoService.getVideoDataFromUrl(task.videoUrl);
              if (parsed !== undefined) {
                var newUrl = parsed.url;
                taskListService
                  .setThirdPartyVideoUrl($routeParams.tasklistId, taskId, newUrl, $scope.type, $scope.user.userId)
                  .then(
                    function() {
                      setTaskNameAndDescription($routeParams.tasklistId, task);
                    },
                    function() {
                      $scope.spinner = false;
                      showAlertFailure();
                    }
                  );
              } else {
                $scope.spinner = false;
              }
            } else if ($scope.type === 'Link' || $scope.type === 'MyWSBLink') {
              taskListService.setLinkUrl($routeParams.tasklistId, taskId, task.linkUrl, $scope.user.userId).then(
                function() {
                  setTaskNameAndDescription($routeParams.tasklistId, task);
                },
                function() {
                  $scope.saveError = true;
                  $scope.spinner = false;
                  showAlertFailure();
                }
              );
            } else {
              setTaskNameAndDescription($routeParams.tasklistId, task);
            }
          }
        }
      };

      function redirectToBuilder(tasklistId) {
        delete queryParams.taskType;
        delete queryParams.order;
        buildService.ctxRedirectBackTo('/courses/' + tasklistId, queryParams);
      }

      var setTaskNameAndDescription = function(tasklistId, task) {
        taskListService
          .setTaskNameAndDescription(tasklistId, taskId, task.taskName, task.taskDescription, $scope.user.userId)
          .then(
            function() {
              $scope.spinner = false;
              redirectToBuilder(tasklistId, task);
            },
            function(reason) {
              $log.error(reason);
              $scope.spinner = false;
              showAlertFailure();
            }
          );
      };

      var showAlertFailure = function() {
        var itemCreating = 'task';
        var creatingSaving = 'save';
        if (creating) {
          creatingSaving = 'create';
        }

        alertService.show({
          title: 'Unexpected Error!',
          content: 'We were unable to ' + creatingSaving + ' this ' + itemCreating + ' at this time.',
          type: 'danger danger-with-content',
          duration: 20,
        });
      };

      var uploadSuccess = function(file) {
        $scope.uploadToServerComplete = true;
      };

      var uploadFailure = function(reason) {
        $scope.uploadToServerComplete = false;
        if (reason === 'FILE_TOO_LARGE') {
          $scope.fileTooLargeError = true;
        } else if (reason === 'CANCELLED') {
        } else {
          $scope.videoUploadError = true;
        }
      };

      $scope.uploadFile = function(file) {
        if (file) {
          $scope.enableMetaData = true;
          $scope.assetId = uuid4.generate();
          if ($scope.type === 'Download') {
            uploadService.uploadFile($scope.assetId, ownerId, file.name, file, false, $scope.user.userId).then(
              function() {
                uploadSuccess(file);
              },
              function(reason) {
                uploadFailure(reason);
              }
            );
          }
          if ($scope.type === 'Video' || $scope.type === 'Audio') {
            uploadService.uploadVideo($scope.assetId, ownerId, file.name, file, $scope.user.userId).then(
              function() {
                uploadSuccess(file);
              },
              function(reason) {
                uploadFailure(reason);
              }
            );
          }
        }
      };

      $scope.cancelUpload = function() {
        $log.debug('Upload Cancelled!');
        $scope.uploadToServerComplete = false;
        $scope.fileTooLargeError = false;
        $scope.videoUploadError = false;
        $scope.enableMetaData = false;
        uploadService.cancelUpload();
      };

      $scope.downloadFile = function() {
        fileUrlService.openFile($scope.task.assetUrl, $scope.task.fileName);
      };

      $scope.parseVideo = function(url, task) {
        $scope.parseError = false;
        var result = thirdPartyVideoService.getVideoDataFromUrl(url);
        if (result !== undefined) {
          if (task !== undefined) {
            task.videoUrl = result.url;
          }
        } else {
          $scope.parseError = true;
        }
      };

      $scope.openDuplicateNameModal = function() {
        var modal = $modal({
          show: true,
          templateUrl: 'duplicateNameModal.html',
          placement: 'center',
          scope: $scope,
          controller: 'TaskCreateEditCtrl',
        });
        modal.$promise.then(modal.show);
      };

      $scope.openThirdPartyVideoPreviewModal = function() {
        $scope.modal = $modal({
          show: true,
          templateUrl: 'previewVideoExternalContent.html',
          placement: 'center',
          scope: $scope,
          controller: 'TaskCreateEditCtrl',
        });
        $scope.modal.$promise.then(function() {
          $scope.videoUrl = $scope.task.videoUrl;
          $scope.modal.show();
        });
      };

      init();
    },
  ] //End Tasks Ctrl
);
