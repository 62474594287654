import React from 'react';
import styled from 'styled-components';
import { Grid, Breakpoints } from '../../StyleGuide';
import { Container } from '../../components/Layout';
import { PrimaryButton } from '../../components/Buttons';

export const PageContainer = styled(Container)`
  margin: ${Grid._6} auto ${Grid._10} auto;
`;

export const MainSectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Grid._6};

  > div:first-child {
    order: 2;
    grid-column-end: span 4;
  }

  > div:last-child {
    order: 1;
    grid-column-end: span 4;
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: repeat(6, 1fr);
    padding: 0;

    > div:first-child {
      order: 1;
    }

    > div:last-child {
      order: 2;
      grid-column-end: span 2;
    }
  }

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    grid-template-columns: repeat(8, 1fr);
    padding: 0;

    > div:first-child {
      grid-column-end: span 6;
    }
  }
`;

export const ContentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
`;

export const CourseTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${Grid._6};
`;

export const HeaderRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: ${Grid._4};
  width: 100%;
  margin-bottom: ${Grid._6};
`;

export const HeaderButtonGroup = styled.div`
  display: flex;
  gap: ${Grid._4};
  width: 100%;

  > button {
    flex: 1;
    margin: 0;
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    width: auto;

    > button {
      flex: 1 1 auto;
    }
  }
`;

export const PublishButton = styled(PrimaryButton)`
  flex: 0 1 180px;
  margin-left: ${Grid._4};
`;
