angular.module('lwNamb').controller('manageTrainingCompilationAddTrainingCtrl', ['$scope', '$localStorage',
  function($scope, $localStorage) {
    $localStorage.selectTrainingBackHash = undefined;

    function getTrainingItemsFromCompilation(compilation) {
      if (compilation && compilation.trainingItems && compilation.trainingItems.length) {
        return compilation.trainingItems;
      } else {
        return [];
      }
    }

    function addSelectedFlag(trainingItems) {
      if (trainingItems && trainingItems.length) {
        trainingItems.forEach(function(item) { item.selected = true; });
      }

      return trainingItems;
    }

    $scope.preselectedItems = addSelectedFlag(getTrainingItemsFromCompilation(JSON.parse(sessionStorage.getItem("createCompilation"))));

    $scope.onAddTraining = function(items) {
      localStorage.setItem("compilationTrainingItems", JSON.stringify(items));
      window.location = window.location.hash.split('/add-training')[0];
    };
  }
]);
