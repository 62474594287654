angular.module('lwNamb').controller(
  'redeemInviteCtrl',

  [
    '$scope',
    '$log',
    '$location',
    '$window',
    '$timeout',
    'alertService',
    '$routeParams',
    'userService',
    'blockUX',
    'windowService',
    'membershipService',
    'profileService',
    'redeemService',
    function(
      $scope,
      $log,
      $location,
      $window,
      $timeout,
      alertService,
      $routeParams,
      userService,
      blockUX,
      windowService,
      membershipService,
      profileService,
      redeemService
    ) {
      var init = function() {
        $scope.errorMessage = '';

        $scope.codeComplete = '';
        $scope.preventDoubleRedeem = false;

        userService.user().then(
          function(user) {
            $scope.user = user;
            //Auto redeem after Login/Register
            if ($routeParams.prefix !== undefined && $routeParams.suffix !== undefined) {
              $scope.redeemFromLogin = true;
              $scope.codeComplete = $routeParams.prefix + '-' + $routeParams.suffix;
              $scope.redeem(true);
            }
          },
          function() {
            $log.error('No user returned in redeemInviteCtrl');
          }
        );
      };

      $scope.redeem = function(isValid) {
        $scope.submitted = true;
        if (isValid && !$scope.preventDoubleRedeem) {
          $scope.preventDoubleRedeem = true;
          //Start Block UX
          blockUX.start('Accepting Invite...', 0, 15000, false, 'Error');
          var codeParts = redeemService.parseCode($scope.codeComplete);
          membershipService
            .joinOrgViaMembershipCode(
              $scope.user.userId,
              $scope.user.logInEmail,
              $scope.user.firstName,
              $scope.user.lastName,
              codeParts[0],
              codeParts[1]
            )
            .then(
              function() {
                blockUX.stop();
                alertService.show({ type: 'success', title: 'Easy Link Redeemed', duration: 5 });
                userService.checkSession().then(function(updatedUser) {
                  $scope.user = updatedUser;
                  $scope.redirectHashAfterLogin();
                });
                $scope.preventDoubleRedeem = false;
              },
              function(reason) {
                showError(reason);
              }
            );
        }
      };

      function showError(reason) {
        $log.error(reason);
        $scope.preventDoubleRedeem = false;
        blockUX.stop();
        //Show Error
        $scope.error = true;
        if (reason === 'MembershipCodeAlreadyRedeemedForUser') {
          $scope.errorHeader = "You've Already Used This Easy Link!";
          $scope.errorMessage = "No need to use this Easy Link again, you're already in.";
        } else if (reason === 'MembershipCodeFull') {
          $scope.errorHeader = 'Redemption Limit!';
          $scope.errorMessage =
            'The Easy Link could not be used because it has been redeemed the maximum number of times.';
        } else if (reason === 'MembershipCodeExpired') {
          $scope.errorHeader = 'Easy Link Is Expired!';
          $scope.errorMessage = 'The Easy Link could not be used because it has expired.';
        } else if (reason === 'MembershipCodeIsInactive') {
          $scope.errorHeader = 'Easy Link Is Not Active!';
          $scope.errorMessage =
            'The Easy Link could not be used because it has been deactivated. Please contact the person who gave you this Easy Link.';
        } else if (reason === 'MembershipCodeDoesNotExist') {
          $scope.errorHeader = 'Easy Link Is Not Valid!';
          $scope.errorMessage =
            "We couldn't recognize the Easy Link. Please try again and check for any mistyped characters.";
        } else if (reason === 'NO_SEATS_AVAILABLE') {
          $scope.errorHeader = "Uh oh! It looks like there aren't any open seats left for you. ";
          profileService.getProfile($scope.invitorId).then(
            function(user) {
              $scope.errorMessage =
                'Please contact ' + user.email + " to receive access. They'll need to add more seats. ";
            },
            function() {
              $scope.errorMessage =
                "Please contact the person who sent you this invitation to receive access. They'll need to add more seats.";
            }
          );
        } else {
          $scope.errorHeader = 'Something Went Wrong!';
          $scope.errorMessage = "Oops! We're sorry for the inconvenience. Please try again.";
        }
      }

      $scope.redirectHashAfterLogin = function() {
        windowService.redirectHash(userService.getRedirectHashAfterLogin($scope.user));
      };

      init();
    },
  ]
);
