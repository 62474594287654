import React from 'react';
import { notify } from 'react-notify-toast';

const DEFAULT_ERROR_MESSAGE = 'An error occurred. Please try again.';

const alertService = {
  showAngularAlert: (msg, type = 'success', duration = 5) => {
    angular.element(document.body).injector().get('alertService').show({ title: msg, type: type, duration: duration });
  },
  show: function (msg, type = 'success', timeout = 5000, customTheme = {}) {
    // msg can be a React component
    // Type can also be "warning", "error", or "custom"
    // customTheme has to be an object like { background: "#0E1717", text: "#FFFFFF" }
    notify.show(
      <div data-qa-hook="reactBanner" className={`banner-${type}`}>
        {msg}
        <button onClick={alertService.hide}>×</button>
      </div>,
      type,
      type === 'error' ? 20000 : timeout,
      customTheme
    );
  },
  showOnNextPage: (msg, type = 'success', timeout = 5000, customTheme = {}) => {
    const onHashChange = () => {
      try {
        alertService.show(msg, type, timeout, customTheme);
      } catch {
        alertService.showAngularAlert(msg, type, timeout / 1000);
      }

      window.removeEventListener('hashchange', onHashChange);
    };
    window.addEventListener('hashchange', onHashChange);
  },
  showError: (msg = DEFAULT_ERROR_MESSAGE, duration = 20000) => alertService.show(msg, 'error', duration),
  hide: notify.hide,
};

export default alertService;
