angular.module("lwNamb").controller(
  "DashboardTrainingController",

  [
    "$rootScope",
    "$scope",
    "$log",
    "alertService",
    "userService",
    "$window",
    "$timeout",
    "taskListService",
    "windowService",
    "taskListInstanceService",
    "State",
    "profileService",
    "apiUrlService",
    "orgService",
    "purchaseService",
    "$modal",
    "fileUrlService",
    "dashboardService",
    function (
      $rootScope,
      $scope,
      $log,
      alertService,
      userService,
      $window,
      $timeout,
      taskListService,
      windowService,
      taskListInstanceService,
      State,
      profileService,
      apiUrlService,
      orgService,
      purchaseService,
      $modal,
      fileUrlService,
      dashboardService
    ) {
      var history = $window.location.hash.indexOf("history") > -1;

      var init = function () {
        var assessmentState = State("assessmentRedirects");
        if (history) {
          assessmentState.put("lastRootView", "history");
          $scope.reportVisibility = "PUBLIC";
        } else {
          assessmentState.put("lastRootView", "myTasks");
        }

        $scope.loading = true;

        $scope.trainingTabLabel = getTrainingTabLabel();

        $scope.showCurriculumTab = !!dashboardService.shouldShowCurriculumTab();

        return userService.user().then(
          function (user) {
            $scope.user = user;

            checkCurriculumSub();
            retrieveUserData();

            if (dashboardService.shouldShowCurriculumTab() === undefined) {
              $scope.showCurriculumTab = dashboardService.determineCurriculumTabVisisbilityByOrg(
                user.lastSelectedAccount
              );
              $scope.$applyAsync();
            }
          },
          function () {
            $log.error("No user returned in MyTasksController");
          }
        );
      };

      function getTrainingTabLabel() {
        return "Your Training";
      }

      function checkCurriculumSub() {
        orgService.hasCurriculumSubscription($scope.user.lastSelectedAccount).then(
          function (result) {
            $scope.hasCurriculumSubscription = result;
          },
          function () {
            $scope.hasCurriculumSubscription = false;
          }
        );
      }

      var reportFilter = function (report) {
        return report.visibility && report.visibility === "PUBLIC";
      };

      var retrieveUserData = function () {
        profileService.getProfile($scope.user.userId).then(function (response) {
          $rootScope.userProfile = response;
        });
        if (history) {
          taskListInstanceService.getTrainingOverview($scope.user.userId, true).then(
            function (completedTraining) {
              completedTraining.forEach(function (tl) {
                tl.reports = (tl.reports && tl.reports.filter(reportFilter)) || [];

                if (tl.completedCourses) {
                  tl.reports = tl.reports.concat(
                    tl.completedCourses
                      .flatMap(function (cc) {
                        return cc.reports;
                      })
                      .filter(reportFilter)
                  );
                }
              });
              $scope.completedTraining = completedTraining;
              $scope.loading = false;
            },
            function (reason) {
              $scope.loading = false;
              $log.error(reason);
              alertService.show({
                title: "Unexpected Error!",
                content: "We are unable to get your history at this time.",
                type: "danger danger-with-content",
                duration: 20,
              });
            }
          );
        } else {
          taskListInstanceService.getTrainingOverview($scope.user.userId).then(
            function (assignedTraining) {
              $scope.currentDate = new Date();
              $scope.assignedTraining = assignedTraining;
              $scope.loading = false;
              $scope.originalTasklists = assignedTraining;
            },
            function (reason) {
              $scope.loading = false;
              $log.error(reason);
              alertService.show({
                title: "Unexpected Error!",
                content: "We are unable to get your training at this time.",
                type: "danger danger-with-content",
                duration: 20,
              });
            }
          );
        }
      };

      $scope.$on("TaskInstanceStatusInTaskListInstanceSetComplete", function () {
        retrieveUserData();
      });

      $scope.$on("TaskListInstanceSetComplete", function () {
        retrieveUserData();
      });

      $scope.$on("TaskListInstanceCreated", function () {
        retrieveUserData();
      });

      $scope.$on("BulkAssignSucceeded", function () {
        retrieveUserData();
      });

      $scope.$on("AssignSucceeded", function () {
        retrieveUserData();
      });

      $scope.$on("ORG_LOADED", function () {
        checkCurriculumSub();
      });

      $scope.redirectToSearch = function () {
        windowService.redirectHash("#/search");
      };

      $scope.removeMyTraining = function (training, closeModal) {
        $scope.removing = true;
        training.id = training.instanceId;
        var trainingType = training._type === "ActiveWorkflowOverview" ? "Workflow" : "TaskList";
        taskListService.unassignMyTraining(training, trainingType, $scope.user.userId).then(
          function () {
            $scope.assignedTraining.splice($scope.assignedTraining.indexOf(training), 1);
            alertService.show({
              type: "success",
              title: "Training removed",
              duration: 5,
            });
            closeModal();
            $scope.removing = false;
          },
          function (reason) {
            $log.error(reason);
            alertService.show({ type: "danger", title: "Error. Please try again.", duration: 20 });
            closeModal();
            $scope.removing = false;
          }
        );
      };

      $scope.downloadFile = function (tasklist, report) {
        var lookupFileName = encodeURIComponent(tasklist.name.replace(/ /gi, "_"));
        var lookupFilePath = encodeURIComponent(report.reportId);
        windowService.openUrl(
          apiUrlService.getUrl() + "/v1/files?fileName=" + lookupFileName + "&filePath=" + lookupFilePath
        );
      };

      $scope.getShares = function (taskList) {
        $scope.training = taskList;
        $scope.openShareResults();
        taskListInstanceService.getShares(taskList.instanceId).then(function (response) {
          var shareableOrgs = [{ name: "Select Organization", value: "" }];
          function formatDate(dLong) {
            var d = new Date(dLong);
            return (
              d.toLocaleString("en-us", { month: "long" }).split(" ")[0] + " " + d.getDate() + ", " + d.getFullYear()
            );
          }
          Array.from($scope.orgs)
            .sort(function (a, b) {
              var nameA = a.name.toUpperCase();
              var nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            })
            .forEach(function (org) {
              var sharedWith =
                response.sharedWith !== undefined
                  ? response.sharedWith.filter(function (o) {
                      return o.orgId.id === org._id.id;
                    })
                  : [];
              if (org._id.id === taskList.assignorId.id) {
                shareableOrgs.push({
                  name: org.name + " (Shared on: " + formatDate(taskList.assignedOn) + ")",
                  value: org._id.id,
                  disabled: true,
                });
              } else if (sharedWith.length > 0) {
                shareableOrgs.push({
                  name: org.name + " (Shared on: " + formatDate(sharedWith[0].share.on) + ")",
                  value: org._id.id,
                  disabled: true,
                });
              } else {
                shareableOrgs.push({ name: org.name, value: org._id.id });
                $scope.hasNotSharedAvailable = true;
              }
            });
          $scope.training.shareableOrgs = taskList.shareableOrgs = shareableOrgs;
          $scope.training.selectedOrg = taskList.selectedOrg = taskList.shareableOrgs[0];
        });
      };

      $scope.downloadCertificate = function (tasklistId) {
        $scope.isDownloading = true;
        $window.open(fileUrlService.getCertificate(tasklistId, $scope.user.userId));
        $scope.isDownloading = false;
      };

      $scope.openShareResults = function () {
        var modal = $modal({
          show: true,
          templateUrl: "shareResults.html",
          placement: "center",
          scope: $scope,
          controller: "DashboardTrainingController",
        });
        modal.$promise.then(modal.show);
      };

      $scope.openNotAvailableModal = function () {
        var modal = $modal({
          show: true,
          templateUrl: "notAvailable.html",
          placement: "center",
          scope: $scope,
          controller: "DashboardTrainingController",
        });
        modal.$promise.then(modal.show);
      };

      $scope.shareResults = function (id, org, $hide) {
        $scope.sharing = true;
        taskListInstanceService
          .shareResults(id, org, $scope.user.userId, $scope.user.userId)
          .then(
            function () {
              alertService.show({ type: "success", title: "Results shared", duration: 5 });
              $hide();
            },
            function (reason) {
              $log.error(reason);
              alertService.show({ type: "danger", title: "Error. Please try again.", duration: 20 });
              $hide();
            }
          )
          .finally(function () {
            $scope.sharing = false;
          });
      };

      $scope.redirectToTask = function (training, trainingType) {
        var upNext = training.activeCourse !== undefined ? training.activeCourse.upNext : training.upNext;
        var isWorkflow = trainingType === "workflow";
        var path =
          "#/training-steps/" +
          training.instanceId +
          "?stepId=" +
          upNext.instanceId +
          (isWorkflow ? "&isWorkflow=true" : "");
        windowService.redirectHash(path);
      };

      $scope.redirectToTaskReact = function (training, trainingType) {
        $scope.redirectToTask(training, trainingType);
        $rootScope.$apply();
      };

      $scope.redirectToCurriculum = function () {
        dashboardService.markNavigatedByTab();
        windowService.redirectHash("#/dashboard");
      };

      $scope.redirectToCurriculumReact = function () {
        $scope.redirectToCurriculum();
        $rootScope.$apply();
      };

      init();
    },
  ]
);
