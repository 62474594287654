import React from 'react';
import PropTypes from 'prop-types';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { MediumButton, MediumPrimaryButton } from '../../../components/Buttons';
import { Radio, RadioListWithDescription, FormField } from '../../../components/FormElements';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../components/ModalElements';
import { useUser } from '../../../authentication';
import useManageTrainingRoute from '../../../hooks/useManageTrainingRoute';
import trainingService from '../../../services/trainingService';
import windowService from '../../../services/windowService';
import { handleError } from '../../../utils/apiUtils';

type CourseType = 'useExistingCourse' | 'addNewCourse';

type Params = {
  ownerId: string;
  closeModal: () => void;
};

type FormValues = {
  name: string;
  courseType?: CourseType;
};

export const AddCourseModal = ({ ownerId, closeModal }: Params) => {
  const user = useUser();
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();

  const createCourse = ({ name, courseType }: FormValues, { setFieldError }: FormikHelpers<FormValues>) => {
    if (!user || !courseType) return;

    if (courseType === 'useExistingCourse') {
      return windowService.redirectTo('/search');
    }

    return trainingService
      .createCourse(ownerId, name, user.userId, [])
      .then(response => {
        windowService.redirectTo(`${manageTrainingRoutePrefix}/courses/${response.id}`);
      })
      .catch(error => {
        if (error.eventType === 'TaskListNameDuplicateError') {
          return setFieldError('name', 'Sorry, that course name is taken');
        }

        handleError(error);
        closeModal();
      });
  };

  return (
    <ModalContainer dismissHandler={closeModal}>
      <ModalHeader data-qa-hook="addSectionModal">
        <h3>Add Course</h3>
      </ModalHeader>
      <Formik
        initialValues={{
          name: '',
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(64, 'Name is too long')
            .when('courseType', {
              is: 'addNewCourse',
              then: Yup.string().required('A name is required'),
            }),
        })}
        onSubmit={createCourse}
      >
        {({ values, handleChange, isSubmitting }) => (
          <Form>
            <ModalBody>
              <h3>Do you want to start with an existing course or add one from scratch?</h3>
              <RadioListWithDescription>
                <li>
                  <label>
                    <Radio
                      name="courseType"
                      value="useExistingCourse"
                      onChange={handleChange}
                      checked={values.courseType === 'useExistingCourse'}
                    />
                    Customize Existing Course
                  </label>
                  <p>Search courses to customize an existing course</p>
                </li>

                <li>
                  <label>
                    <Radio
                      name="courseType"
                      value="addNewCourse"
                      onChange={handleChange}
                      checked={values.courseType === 'addNewCourse'}
                    />
                    Add New Course
                  </label>
                  <p>Provide a name and begin building a course from scratch</p>
                  <FormField
                    id="name"
                    label="Name"
                    name="name"
                    type="text"
                    placeholder="Enter Name"
                    disabled={values.courseType !== 'addNewCourse'}
                  />
                </li>
              </RadioListWithDescription>
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton
                type="submit"
                disabled={isSubmitting || values.courseType === 'addNewCourse' ? !values.name : !values.courseType}
                operating={isSubmitting}
              >
                Continue
              </MediumPrimaryButton>
              <MediumButton onClick={closeModal}>Cancel</MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};
