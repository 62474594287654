import React from 'react';
import PropTypes from 'prop-types';
import { react2angular } from 'react2angular';

// I don't believe this ever worked, but I've updated the content to ensure it doesn't break.
const LifewayImpersonationBanner = ({ email, logOut }) => {
  const defaultStyles = {
    banner: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
    },
    italicText: {},
    styledText: {},
    button: {},
  };
  return (
    <div style={defaultStyles.banner} data-testid="ImpersonationBanner">
      <span
        dangerouslySetInnerHTML={{
          __html: `
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
          width="1em"
          height="1em"
          style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg); borderRadius: 28px; backgroundColor: white; height: 45px; width: auto; margin: 5px; "
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 1536 1536"
        >
          <path
            d="M768 0q209 0 385.5 103T1433 382.5T1536 768t-103 385.5t-279.5 279.5T768 1536t-385.5-103T103 1153.5T0 768t103-385.5T382.5 103T768 0zm128 1247v-190q0-14-9-23.5t-22-9.5H673q-13 0-23 10t-10 23v190q0 13 10 23t23 10h192q13 0 22-9.5t9-23.5zm-2-344l18-621q0-12-10-18q-10-8-24-8H658q-14 0-24 8q-10 6-10 18l17 621q0 10 10 17.5t24 7.5h185q14 0 23.5-7.5T894 903z"
            fill="#ffcc00ff"
          />
          <rect x="0" y="0" width="1536" height="1536" fill="rgba(0, 0, 0, 0)" />
        </svg>
      `,
        }}
      />
      <span style={defaultStyles.italicText}>Reminder</span>
      <span style={defaultStyles.styledText}>You are currently impersonating {email}.</span>
      {logOut && (
        <button style={defaultStyles.button} onClick={logOut}>
          Log Out
        </button>
      )}
    </div>
  );
};

LifewayImpersonationBanner.displayName = 'LifewayImpersonationBanner';

export default function EnterpriseImpersonationBanner({ user, logout }) {
  return <LifewayImpersonationBanner email={user.logInEmail} logOut={logout} />;
}

EnterpriseImpersonationBanner.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

angular.module('lwNamb').component('enterpriseImpersonationBanner', react2angular(EnterpriseImpersonationBanner));
