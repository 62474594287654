import submitCommand from './submitCommand';

const feedbackResponseService = {
  addFeedbackResponseUser: function (id, step, email, firstName, callbackHost, initiatingUserId) {
    const cmd = {
      id: id,
      taskInstance: step,
      email: email.trim(),
      firstName: firstName,
      callbackHost: callbackHost,
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(id, cmd, 'AddFeedbackResponseUser', 'FeedbackResponseUserAdded', 'TaskListInstanceError');
  },
  removeFeedbackResponseUser: function (id, step, email, initiatingUserId) {
    const cmd = {
      id: id,
      taskInstance: step,
      email: email,
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(id, cmd, 'RemoveFeedbackResponseUser', 'FeedbackResponseUserRemoved', 'TaskListInstanceError');
  },
  resendFeedbackUser: function (id, step, email, firstName, initiatingUserId) {
    const cmd = {
      id: id,
      taskInstance: step,
      email: email,
      firstName: firstName,
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(
      id,
      cmd,
      'ReSendFeedbackEmailToObserver',
      'FeedbackEmailToObserverReSent',
      'TaskListInstanceError'
    );
  },
  forceCompleteFeedback: function (id, taskInstanceId, requester) {
    const cmd = {
      id: id,
      taskInstanceId: taskInstanceId,
      requester: requester,
      initiatingUserId: requester,
    };
    return submitCommand(id, cmd, 'ForceCompleteFeedbackTask', 'FeedbackTaskForceCompleted', 'TaskListInstanceError');
  },
};

export default feedbackResponseService;
