import { AxiosResponse } from 'axios';
import cacheableRequest from '../../services/cacheableRequest';
import submitCommand from '../../services/submitCommand';
import apiClient from '../../services/apiClient';
import windowService from '../../services/windowService';
const uploadService = require('../../services/uploadService');

export const uploadPoster: (
  videoId: string,
  file: Blob,
  fileName: string,
  orgId: string,
  initiatingUserId: string
) => Promise<any> = (videoId, file, fileName, orgId, initiatingUserId) => {
  const key = videoId + fileName.toLowerCase();
  const location = apiClient?.getConfig().baseURL + '/v1/storage/images/download' + '?key=' + key;

  return uploadService.uploadImage(file, key).then((res: { config: { url: string } }) => {
    const cmd = {
      id: videoId,
      poster: location,
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(videoId, cmd, 'UpdateVideoPoster', 'VideoPosterUpdated', 'VideoPosterError');
  });
};
