import cacheableRequest from './cacheableRequest';
import submitCommand from './submitCommand';
import uuid from './uuid';
import apiClient from './apiClient';
import cacheService from './cacheService';
import uploadService from './uploadService';

export const transformAssignmentsForUser = assignments =>
  Array.isArray(assignments)
    ? assignments.map(assignment => {
        const shared = assignment.assignor?._type === 'Share';
        const progress = assignment.assignable.progress;
        return {
          ...assignment,
          trainingName: assignment.assignable.name,
          assignedOn: assignment.assignable.assignedOn,
          startedOn: assignment.assignable.startedOn,
          completedOn: assignment.assignable.completedOn,
          dueOn: assignment.assignable.dueDate,
          shared,
          sharedOn: shared ? assignment.assignor.on : undefined,
          assignor: assignment.assignor ? assignment.assignor.name : null,
          progress,
          isComplete: progress ? progress._type === 'Finished' : false,
          _type: assignment.assignable._type === 'AssignedTaskList' ? 'course' : 'workflow',
        };
      })
    : [];

const getAssigneeProfileImageUrl = assignment => {
  const url = assignment.assignee.profileImageUrl;
  return url ? `${getApiUrl()}/v1/images/profile/${url}` : null;
};
const transformAssignment = assignment => {
  const shared = assignment.assignor?._type === 'Share';

  return {
    ...assignment,
    id: assignment.id ?? assignment._id,
    assignable: {
      ...assignment.assignable,
      completedOn: assignment.assignable?.completedOn || 0,
      progress: Object.assign(
        {
          _type: 'NotStarted',
          percent: 0,
        },
        assignment.assignable?.progress ?? {}
      ),
    },
    assignee: assignment.assignee
      ? { ...assignment.assignee, profileImageUrl: getAssigneeProfileImageUrl(assignment) }
      : undefined,
    shared,
    sharedOn: shared ? assignment.assignor.on : undefined,
    assignedOn: assignment.assignable.assignedOn,
    completedOn: assignment.assignable.completedOn || 0,
    firstName: assignment.assignee?.name?.split(' ')[0] ?? '',
    lastName: assignment.assignee?.name?.split(' ')[1] ?? '',
    groupNames: assignment.assignee?.groupNames ?? [],
  };
};
const getIconForType = iconType => {
  if (!iconType) return 'icon ion-ios-compose';

  const iconObject = {
    Assessment: 'icon ion-ios-compose',
    Feedback: 'fas fa-envelope',
    Link: 'icon ion-link',
    MyWSBLink: 'icomoon-myWSB',
    Text: 'icon ion-android-list',
    Upload: 'icon ion-ios-upload',
    Download: 'icon ion-ios-download',
    Video: 'icon ion-ios-play',
    ThirdPartyVideo: 'icon ion-ios-play',
    Audio: 'fas fa-volume-up',
  };

  return iconObject[iconType];
};

export const transformAssignments = assignments => {
  if (!Array.isArray(assignments)) return [];

  return assignments.filter(assignment => assignment.assignable._type !== 'PendingTraining').map(transformAssignment);
};

const removeSelfAssignments = (assignments, initiatingUserId) => {
  const selfAssignedIds = assignments.filter(a => a.assignable.selfAssigned === true).map(a => a.id);

  if (selfAssignedIds.length === 0) {
    return Promise.resolve(0);
  }

  const id = uuid.generate();
  const cmd = {
    id,
    initiatingUserId: initiatingUserId,
    assignments: selfAssignedIds,
  };

  return submitCommand(id, cmd, 'RemoveAssignments', 'AssignmentsRemoved', 'AssignmentsError').then(
    event => event.successfullyRemoved
  );
};

const removeOrgAssignments = (assignments, initiatingUserId) => {
  const assignables = assignments
    .filter(t => t.assignable.selfAssigned !== true)
    .map(t =>
      t.assignable._type === 'AssignedTaskList'
        ? {
            taskListInstanceId: t.assignable.taskListInstanceId,
            _type: 'TaskList',
          }
        : {
            workflowInstanceId: t.assignable.workflowInstanceId,
            _type: t.assignable._type,
          }
    );

  if (assignables.length === 0) {
    return Promise.resolve(0);
  }

  const id = uuid.generate();
  const cmd = {
    id,
    assignables: assignables,
    initiatingUserId: initiatingUserId,
  };

  return submitCommand(id, cmd, 'UnassignTraining', 'AssignmentsRemoved', 'NoTrainingReceivedToUnassign').then(
    event => event.successfullyRemoved
  );
};

const transformTrainingContent = content => ({
  ...content,
  type: content.contentType,
  icon: getIconForType(content.contentType),
  questions: content.numberOfQuestions,
  lastModifiedDate: content.lastEditedDate,
});

const transformCourse = course => ({
  ...course,
  id: course._id.id,
  lastModifiedDate: new Date(course.lastModifiedDate),
  deliverCertificate: !!course.certificateId,
  category: course.categories !== undefined ? course.categories[0] : undefined,
  discussionDisabled: !!course.discussionDisabled,
  hasAccess: !!course.hasAccess,
  ...(course.tasks && {
    tasks: course.tasks.map(task => ({
      ...task,
      icon: getIconForType(task._type),
      sortStatus: 0,
    })),
  }),
});

const trainingService = {
  getTraining: id => {
    return apiClient
      .get(`/v1/training/${id}`)
      .then(response => response.data)
      .catch(err => err.response);
  },
  getTasklist: tasklistId => {
    return apiClient
      .get(`/v1/tasklists/${tasklistId}`)
      .then(response => ({
        id: response.data.tasklist._id.id,
        name: response.data.tasklist.name,
        description: response.data.tasklist.description,
        posterImage: response.data.tasklist.posterImage,
        owner: response.data.tasklist.owner,
        reportToGenerate: response.data.tasklist.reportToGenerate,
        lastModifiedDate: new Date(response.data.tasklist.lastModifiedDate),
        published: response.data.tasklist.published,
        suggestedTimeFrameDays: response.data.tasklist.suggestedTimeFrameDays,
        deliverCertificate: response.data.tasklist.certificateId !== undefined ? true : false,
        itemNumber: response.data.tasklist.itemNumber?.value,
        category: response.data.tasklist.categories !== undefined ? response.data.tasklist.categories[0] : undefined,
        discussionDisabled: response.data.tasklist.discussionDisabled === true,
        reports: response.data.tasklist.reports || [],
        tasks: response.data.tasklist.tasks || [],
      }))
      .catch(reason => reason);
  },
  updateName: (id, name, orgId, initiatingUserId) => {
    const cmd = {
      id: id,
      name: name,
      owner: orgId,
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(id, cmd, 'UpdateTaskListName', 'TaskListRenamed', 'TaskListNameDuplicateError');
  },
  updateDescription: (id, description, initiatingUserId) => {
    const cmd = {
      id: id,
      description: description,
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(id, cmd, 'SetDescriptionForTaskList', 'DescriptionForTaskListSet');
  },
  updateCategory: (id, category, oldCategory, initiatingUserId) => {
    if (oldCategory !== 'No Category') {
      removeCategory(id, oldCategory, initiatingUserId);
    }
    const cmd = {
      id: id,
      category: category,
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(id, cmd, 'AddCategoryToTaskList', 'CategoryAddedToTaskList', 'TaskListError');
  },
  removeCategory: (id, category, initiatingUserId) => {
    return removeCategory(id, category, initiatingUserId);
  },
  getCategories: () => [
    'No Category',
    'Abuse Prevention and Response',
    'Bible Studies for Life',
    'Church Administration',
    'Church Leadership',
    'Church Ministry',
    'Church Planting and Multisite',
    'Church Safety and Security',
    'Collegiate and Young Adult Ministry',
    'Communications',
    'Conferences and Events',
    'Deacon Ministry',
    'Discipleship',
    'Explore the Bible',
    'Hyfi',
    'Generosity',
    'Guest Services',
    'Kids Ministry',
    'Leadership Frameworks',
    'Leadership Pipeline',
    'Marriage and Family Ministry',
    "Men's Ministry",
    'Missions and Evangelism',
    'Older Adult Ministry',
    'Pastoral Development',
    'Ready-to-Use Resources',
    'Seminary Training',
    'Small Groups',
    'Student Ministry',
    'Sunday School',
    'The Gospel Project',
    "Women's Ministry",
    'Worship and Music Ministry',
  ],
  setSuggestedTimeFrame: (id, days, initiatingUserId) => {
    const cmd = {
      id: id,
      days: parseInt(days),
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(id, cmd, 'SetSuggestedTimeFrameForTaskList', 'SuggestedTimeFrameForTaskListSet');
  },
  unSetSuggestedTimeFrame: (id, initiatingUserId) => {
    return submitDefaultCommand(
      id,
      'UnSetSuggestedTimeFrameForTaskList',
      'SuggestedTimeFrameForTaskListUnSet',
      'TaskListError',
      initiatingUserId
    );
  },
  enableDiscussion: (id, initiatingUserId) => {
    return submitDefaultCommand(
      id,
      'EnableDiscussionForTaskList',
      'DiscussionForTaskListEnabled',
      'TaskListError',
      initiatingUserId
    );
  },
  disableDiscussion: (id, initiatingUserId) => {
    return submitDefaultCommand(
      id,
      'DisableDiscussionForTaskList',
      'DiscussionForTaskListDisabled',
      'TaskListError',
      initiatingUserId
    );
  },
  setCertificate: (id, initiatingUserId) => {
    return submitDefaultCommand(
      id,
      'SetCertificateForTaskList',
      'CertificateForTaskListSet',
      'TaskListError',
      initiatingUserId
    );
  },
  unSetCertificate: (id, initiatingUserId) => {
    return submitDefaultCommand(
      id,
      'UnSetCertificateForTaskList',
      'CertificateForTaskListUnSet',
      'TaskListError',
      initiatingUserId
    );
  },
  setItemNumber: (id, itemNumber, initiatingUserId) => {
    const cmd = {
      id: id,
      itemNumber: itemNumber,
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(id, cmd, 'SetItemNumberToTasklist', 'ItemNumberIsSetToTaskList', 'TaskListError');
  },
  removeItemNumber: (id, initiatingUserId) => {
    const cmd = {
      id: id,
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(id, cmd, 'RemoveItemNumberFromTasklist', 'ItemNumberRemovedFromTaskList', 'TaskListError');
  },
  updatePathwayName: (id, name, orgId, initiatingUserId) => {
    const cmd = {
      id: id,
      name: name,
      owner: orgId,
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(id, cmd, 'UpdateWorkflowName', 'WorkflowNameChanged', 'WorkflowNameDuplicateError');
  },
  updatePathwayDescription: (id, description, initiatingUserId) => {
    const cmd = {
      id: id,
      description: description,
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(id, cmd, 'SetWorkflowDescription', 'WorkflowDescriptionSet', 'WorkflowError');
  },
  getAssignments: orgId =>
    cacheableRequest(`/v1/organizations/${orgId}/assignments`).then(response => transformAssignments(response.data)),
  getAssignment: ({ assignId }) =>
    cacheableRequest(`/v1/assignments/${assignId}`).then(response => transformAssignment(response.data[0])),
  getCPCAAssignments: () =>
    cacheableRequest(`/v1/cpca-assignments`).then(response => transformAssignments(response.data)),
  getAssignmentsForUser: userId =>
    cacheableRequest(`/v1/assignments/users/${userId}`).then(response => transformAssignmentsForUser(response.data)),
  getAssignmentsCSV: (orgId, assignmentIds) => {
    const idsObj = !assignmentIds || assignmentIds.length === 0 ? {} : { ids: assignmentIds };
    return apiClient
      .post(`/v1/organizations/${orgId}/assignmentsCSV`, idsObj, {
        responseType: 'arraybuffer',
      })
      .then(response => new Blob([response.data], { type: response.headers['content-type'] }));
  },
  unsetDueDate: (taskListInstanceId, assigneeId) => {
    const cmd = {
      id: taskListInstanceId,
      initiatingUserId: assigneeId,
    };
    return submitCommand(taskListInstanceId, cmd, 'UnsetDueDate', 'DueDateUnset', 'DueDateUnsetError');
  },
  setDueDate: (taskListInstanceId, assigneeId, date) => {
    const cmd = {
      id: taskListInstanceId,
      initiatingUserId: assigneeId,
      month: date.getUTCMonth() + 1,
      year: date.getUTCFullYear(),
      timeZone: -date.getTimezoneOffset() / 60,
      dayOfMonth: date.getDate(),
    };
    return submitCommand(taskListInstanceId, cmd, 'SetDueDate', 'DueDateSet', 'DueDateSetError');
  },
  assignTraining: (trainingId, isWorkflow, assigneeIds, dueDate, orgId, sendEmail, invite, initiatingUserId) => {
    cacheService.remove(`/v1/cpca-assignments`);
    cacheService.remove(`/v1/organizations/${orgId}/assignments`);
    cacheService.removeAllInPath(`/v1/assignments/users`);

    const id = uuid.generate();

    const cmd = {
      assigneeIds,
      id,
      initiatingUserId,
      orgId: {
        id: orgId,
      },
      invite,
      sendEmail,
      training: { id: {}, entityOptions: {} },
    };

    cmd.training.id[isWorkflow ? 'workflowId' : 'taskListId'] = { id: trainingId };

    if (dueDate) {
      cmd.training.dueDate = dueDate;
    }

    return submitCommand(id, cmd, 'AssignTraining', 'AssignSucceeded', 'TrainingassignedError');
  },
  unassignMyTraining: (trainingId, trainingType, initiatingUserId) => {
    const id = uuid.generate();
    let assignables = [];
    if (trainingType === 'TaskList') {
      assignables.push({
        taskListInstanceId: { id: trainingId },
        _type: trainingType,
      });
    } else if (trainingType === 'Workflow') {
      assignables.push({
        workflowInstanceId: trainingId,
        _type: trainingType,
      });
    }
    let cmd = { id: id, assignables: assignables, initiatingUserId: initiatingUserId };
    return submitCommand(id, cmd, 'UnassignTraining', 'TrainingUnassigned', 'TrainingUnassignedError');
  },
  unassignTraining: (training, initiatingUserId) => {
    if (!Array.isArray(training)) training = [training];

    if (training.length === 0) return Promise.resolve(0);

    cacheService.removeAllInPath('/v1/organizations/');

    return removeSelfAssignments(training, initiatingUserId).then(removedSelfAssignments =>
      removeOrgAssignments(training, initiatingUserId).then(
        removedOrgAssignments => removedSelfAssignments + removedOrgAssignments
      )
    );
  },
  cloneTraining: (trainingType, trainingId, newName, orgId, initiatingUserId) => {
    let cmd, cmdName, evtName, errNames;
    const id = uuid.generate();
    if (trainingType === 'Course') {
      cacheService.removeAllInPath(`/v1/tasklists?orgId=${orgId}`);
      cmd = { id: id, fromId: trainingId, newName: newName, newOwner: orgId, initiatingUserId };
      cmdName = 'CloneTaskList';
      evtName = 'TaskListCloned';
      errNames = ['TaskListFailedToClone', 'TaskListNameDuplicateError'];
    } else if (trainingType === 'Pathway') {
      cacheService.removeAllInPath('/v1/workflows');
      cmd = { id: id, fromId: trainingId, newName: newName, ownerId: orgId, initiatingUserId };
      cmdName = 'CopyWorkflow';
      evtName = 'WorkflowNameChanged';
      errNames = ['WorkflowFailedToClone', 'WorkflowNameDuplicateError'];
    }
    return submitCommand(id, cmd, cmdName, evtName, errNames);
  },
  uploadCourseImage: (trainingId, file, key) => {
    return uploadService
      .uploadImage(file, key, '/training')
      .then(() =>
        submitCommand(trainingId, { id: trainingId, image: key }, 'SetTaskListPosterImage', 'TaskListPosterImageSet')
      );
  },
  removeCourseImage: trainingId => {
    return submitCommand(trainingId, { id: trainingId }, 'SetTaskListPosterImage', 'TaskListPosterImageSet');
  },
  uploadPathwayImage: (trainingId, file, key) => {
    return uploadService
      .uploadImage(file, key, '/training')
      .then(() =>
        submitCommand(trainingId, { id: trainingId, image: key }, 'SetWorkflowPosterImage', 'WorkflowPosterImageSet')
      );
  },
  removePathwayImage: trainingId => {
    return submitCommand(trainingId, { id: trainingId }, 'SetWorkflowPosterImage', 'WorkflowPosterImageSet');
  },
  getTrainingImageUrl: key => {
    return `${apiClient.getConfig().baseURL}/v1/images/training/${key}`;
  },
  getNextAction: (trainingType, trainingId) => {
    return apiClient
      .get(`/v1/training-next-action?${trainingType === 'Pathway' ? 'pathwayId' : 'courseId'}=${trainingId}`)
      .then(response => response.data);
  },
  isIncludedWithGridSubscription: itemNumber => {
    return !!itemNumber && itemNumber.includes('005802975');
  },
  isCourseLocked: training => {
    return training.tasks?.find && !!training.tasks.find(step => step.isPreview == true);
  },
  getTrainingContent: orgId => {
    return cacheableRequest(`/v1/organizations/${orgId}/content`).then(response =>
      response?.data?.content?.map(transformTrainingContent)
    );
  },
  downloadArchive: fileId => apiClient.post(`v1/files/${fileId}/archive`),
  getCourses: (orgId, showUnpublished, filterOwned) => {
    const url = `/v1/tasklists?orgId=${orgId}${showUnpublished ? '&showUnPublished=true' : ''}${
      filterOwned ? '&filterOwned=true' : ''
    }`;

    return cacheableRequest(url).then(response => response?.data?.availableTasklists?.map(transformCourse));
  },
  removeCourse: (courseId, orgId, initiatingUserId) => {
    cacheService.removeAllInPath(`/v1/tasklists?orgId=${orgId}`);
    return submitCommand(
      courseId,
      {
        id: courseId,
        initiatingUserId,
        taskListId: courseId,
        organizationId: orgId,
      },
      'DeleteTaskList',
      'TaskListDeleted',
      'DeleteTaskListFailed'
    );
  },
  createCourse: (ownerId, name, initiatingUserId, tasks) => {
    cacheService.removeAllInPath(`/v1/tasklists?orgId=${ownerId}`);

    const id = uuid.generate();
    const cmd = {
      id,
      owner: ownerId,
      name: name,
      tasks: tasks ? tasks : [],
      reports: [],
      initiatingUserId,
    };

    return submitCommand(id, cmd, 'CreateTaskList', 'TaskListCreated', 'TaskListNameDuplicateError');
  },

  getProgressSummary: orgId =>
    cacheableRequest(`/v1/organizations/${orgId}/progress-summary`).then(response => response.data),

  publishCourse: (trainingId, initiatingUserId) => {
    return submitCommand(
      trainingId,
      { id: trainingId, initiatingUserId },
      'PublishTaskList',
      'TaskListPublished',
      'TaskListError'
    );
  },

  unpublishCourse: (trainingId, initiatingUserId) => {
    return submitCommand(
      trainingId,
      { id: trainingId, initiatingUserId },
      'UnPublishTaskList',
      'TaskListUnPublished',
      'TaskListError'
    );
  },

  removeTask: (id, taskId, initiatingUserId) => {
    var cmd = { id, taskId, initiatingUserId };
    return submitCommand(id, cmd, 'RemoveTaskFromTasklist', 'TaskIsRemovedFromTasklist', 'TaskListError');
  },

  reorderTasks: (id, newSeqOfTasks, initiatingUserId) => {
    var cmd = { id, initiatingUserId, newSeqOfTasks };
    return submitCommand(id, cmd, 'ReorderTaskList', 'TaskListIsReordered', 'TaskListError');
  },
};

const submitDefaultCommand = (id, cmdName, evtName, errName, initiatingUserId) => {
  const cmd = {
    id: id,
    initiatingUserId: initiatingUserId,
  };
  return submitCommand(id, cmd, cmdName, evtName, errName);
};

const removeCategory = (id, category, initiatingUserId) => {
  const cmd = { id: id, category: category, initiatingUserId: initiatingUserId };
  return submitCommand(id, cmd, 'RemoveCategoryFromTaskList', 'CategoryRemovedFromTaskList', 'TaskListError');
};

export default trainingService;
