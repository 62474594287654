import apiClient from './apiClient';
import submitCommand from './submitCommand';
import uuid from './uuid';
import cacheService from './cacheService';
import EventList from './EventList';

const addDisplayName = people =>
  people.map(person => ({
    ...person,
    displayName: person.firstName ? person.firstName + ' ' + person.lastName : null,
  }));

const transformGroups = groups =>
  groups
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
    .map(group => {
      const membersData = group.groupUsers.map(Object.values).flat();
      return { ...group, filterableData: [group.name, ...membersData] };
    });

const transformSingleGroup = group => {
  const sortedUsers = group.groupUsers
    .sort((a, b) => (a.email.toLowerCase() < b.email.toLowerCase() ? -1 : 1))
    .sort((a, b) => (a.firstName.toLowerCase() < b.firstName.toLowerCase() ? -1 : 1));
  const sortedPendingUsers = group.pendingMembers.sort((a, b) =>
    a.emailAddress.toLowerCase() < b.emailAddress.toLowerCase() ? -1 : 1
  );

  return {
    ...group,
    people: addDisplayName([...sortedUsers, ...sortedPendingUsers]),
  };
};

const unsignedImg = url => url.split('?')[0];

const listReducer = (groups, evt) => {
  switch (evt.type) {
    case 'MembersAdded':
      return groups.map(group =>
        group.id.id === evt.value.groupId
          ? (() => {
              let newMembers = evt.value.members.filter(
                m => group.groupUsers.findIndex(u => u.email === (m.email ?? m.emailAddress)) === -1
              );
              return {
                ...group,
                groupUsers: group.groupUsers.concat(
                  newMembers.map(m => {
                    return { displayName: m.displayName, email: m.email ?? m.emailAddress };
                  })
                ),
                profileImages:
                  group.profileImages.length < 3
                    ? group.profileImages.concat(newMembers.map(m => m.profile?.imageUrl).filter(i => !!i)).slice(0, 3)
                    : group.profileImages,
              };
            })()
          : group
      );

    case 'MemberRemoved':
      return groups.map(group =>
        group.id.id === evt.value.groupId
          ? {
              ...group,
              groupUsers: group.groupUsers.filter(u => u.email !== evt.value.email),
              profileImages: group.profileImages.filter(i => unsignedImg(i) !== unsignedImg(evt.value.profileImage)),
            }
          : group
      );

    case 'PositionSet':
      return groups.map(group =>
        group.id.id === evt.value.groupId
          ? {
              ...group,
              leaderCount: group.leaderCount + (evt.value.position === 'Leader' ? 1 : -1),
            }
          : group
      );

    default:
      return groups;
  }
};

const singleReducer = (group, evt) => {
  switch (evt.type) {
    case 'MembersAdded': {
      let newMembers = evt.value.members
        .filter(m => m._type === 'UserAndRole' && group.groupUsers.findIndex(u => u.userId.id === m._id.id) === -1)
        .map(m => {
          return {
            userId: m._id,
            firstName: m.firstName,
            lastName: m.lastName,
            email: m.email,
            position: 'Member',
            profileImage: m.profile?.imageUrl,
          };
        });
      let newPendingMembers = evt.value.members
        .filter(
          m =>
            m._type === 'PendingUser' && group.pendingMembers.findIndex(p => p.emailAddress === m.emailAddress) === -1
        )
        .map(m => {
          return {
            emailAddress: m.emailAddress,
            firstName: m.firstName,
            lastName: m.lastName,
            position: 'Member',
            inviteId: m.inviteId,
            workflowId: m.workflowId,
          };
        });
      return {
        ...group,
        groupUsers: group.groupUsers.concat(newMembers),
        pendingMembers: group.pendingMembers.concat(newPendingMembers),
      };
    }

    case 'MemberRemoved':
      return {
        ...group,
        groupUsers: group.groupUsers.filter(u => u.email !== evt.value.email),
        pendingMembers: group.pendingMembers.filter(m => m.emailAddress !== evt.value.email),
      };

    case 'PositionSet':
      return {
        ...group,
        groupUsers: group.groupUsers.map(u =>
          u.userId.id === evt.value.memberId ? { ...u, position: evt.value.position } : u
        ),
        pendingMembers: group.pendingMembers.map(m =>
          m.emailAddress === evt.value.memberId ? { ...m, position: evt.value.position } : m
        ),
      };

    default:
      return group;
  }
};

const reducer = (data, evt) => {
  return data.type === 'list'
    ? data.orgId === evt.value.orgId
      ? { ...data, groups: listReducer(data.groups, evt) }
      : data
    : data.orgId === evt.value.orgId && data.groupId === evt.value.groupId
    ? { ...data, group: singleReducer(data.group, evt) }
    : data;
};

const cacheSeconds = 30;

const recentEvents = EventList(reducer, cacheSeconds + 10);

const setCache = (key, value) => {
  cacheService.set(key, value, cacheSeconds / 60);
  return value;
};

const clearCurriculumCollectionCache = () => cacheService.removeAllInPath('v1/org/curriculum');

const groupService = {
  getGroups: orgId => {
    const cacheKey = `${orgId}:groups`;
    const cachedGroups = cacheService.get(cacheKey);
    const promise = cachedGroups
      ? Promise.resolve(cachedGroups)
      : apiClient
          .get('/v1/organizations/' + orgId + '/groups')
          .then(response =>
            response.data.length ? setCache(cacheKey, { groups: transformGroups(response.data) }) : { groups: [] }
          );
    return promise.then(result => {
      return recentEvents.isEmpty()
        ? result.groups
        : transformGroups(recentEvents.applyTo({ type: 'list', orgId, groups: result.groups }).groups);
    });
  },
  getSingleGroup: (groupId, orgId) => {
    const cacheKey = `${orgId}:groups:${groupId}`;
    const cachedGroup = cacheService.get(cacheKey);
    const promise = cachedGroup
      ? Promise.resolve(cachedGroup)
      : apiClient
          .get('/v1/organizations/' + orgId + '/groups/' + groupId)
          .then(response => (response.data ? setCache(cacheKey, transformSingleGroup(response.data)) : {}));
    return promise.then(group => {
      return recentEvents.isEmpty()
        ? group
        : transformSingleGroup(recentEvents.applyTo({ type: 'single', orgId, groupId, group }).group);
    });
  },
  removeGroup: function (groupId, removeTraining, orgId, initiatingUserId) {
    const cmd = {
      id: orgId,
      groupId: groupId,
      removeTraining: removeTraining,
      initiatingUserId: initiatingUserId,
    };
    cacheService.removeAll();
    return submitCommand(orgId, cmd, 'DeleteOrgAccountGroup', 'OrgAccountGroupDeleted');
  },
  createGroup: function (groupName, orgId, initiatingUserId) {
    const cmd = {
      id: orgId,
      groupId: { id: uuid.generate() },
      groupName: groupName,
      initiatingUserId: initiatingUserId,
    };
    cacheService.removeAll();
    return submitCommand(orgId, cmd, 'CreateOrgAccountGroup', 'OrgAccountGroupCreated', 'GroupNameDuplicateError');
  },
  updateGroupName: function (orgId, groupId, name, initiatingUserId) {
    const cmd = {
      id: orgId,
      groupId: { id: groupId },
      name: name,
      initiatingUserId: initiatingUserId,
    };
    cacheService.removeAll();
    return submitCommand(orgId, cmd, 'RenameOrgAccountGroup', 'OrgAccountGroupRenamed', 'GroupNameDuplicateError');
  },
  changeGroupMemberPosition: function (orgId, groupId, memberId, position, initiatingUserId) {
    const cmd = {
      id: orgId,
      orgId: { id: orgId },
      groupId: { id: groupId },
      memberId: memberId,
      position: position,
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(orgId, cmd, 'SetGroupMemberPosition', 'GroupMemberPositionSet', [
      'OrgAccountError',
      'NoChange',
      'SetGroupMemberPositionFailed',
    ])
      .then(evt => {
        recentEvents.add('PositionSet', { orgId, groupId, memberId, position });
        return evt;
      })
      .catch(error => {
        cacheService.removeAll();
        return Promise.reject(error);
      });
  },
  addMember: function (groupId, orgId, userId, members, groupName) {
    clearCurriculumCollectionCache();
    const cmd = {
      id: uuid.generate(),
      groupName: groupName,
      orgId: { id: orgId },
      initiatingUserId: userId,
      groupId: { id: groupId },
      assigneeIds: members.map(m => (m._id ? m._id.id : m.emailAddress)),
    };
    return submitCommand(cmd.id, cmd, 'AddToGroup', 'AddToGroupSucceeded', 'AddToGroupFailed')
      .then(evt => {
        recentEvents.add('MembersAdded', { orgId, groupId, members });
        return evt;
      })
      .catch(error => {
        cacheService.removeAll();
        return Promise.reject(error);
      });
  },
  removePerson: function (groupId, orgId, member, userId) {
    clearCurriculumCollectionCache();
    const cmd = {
      id: uuid.generate(),
      groupId: { id: groupId },
      possibleUserId: member.emailAddress ?? member.userId.id,
      orgId: { id: orgId },
      initiatingUserId: userId,
    };
    return submitCommand(cmd.id, cmd, 'RemoveFromGroup', 'RemoveFromGroupSucceeded', 'RemoveFromGroupFailed')
      .then(evt => {
        recentEvents.add('MemberRemoved', {
          orgId,
          groupId,
          email: member.emailAddress ?? member.email,
          profileImage: member.profileImage,
        });
        return evt;
      })
      .catch(error => {
        cacheService.removeAll();
        return Promise.reject(error);
      });
  },
};

// reducer is exported for unit testing
export { groupService, reducer };
