import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Type } from '../StyleGuide';
import windowService from '../services/windowService';

const Crumb = styled.nav`
  text-transform: uppercase;
  font-weight: ${Type.Weight.semibold};
  letter-spacing: 0.05em;
  p {
    margin: 0;
  }
  .fa-chevron-left {
    font-size: ${Type.Scale._1};
    position: relative;
    color: ${Type.Color.dark};
    opacity: 0.5;
    margin-right: ${Grid._2};
  }
`;

export default function MobileCrumb({ crumbs }) {
  const crumb = crumbs.length < 2 ? crumbs[0] : crumbs[crumbs.length - 2];
  return (
    <Crumb>
      <p>
        <span className="fa fa-chevron-left"></span>{' '}
        <a data-qa-hook="mobileBreadcrumb" onClick={() => windowService.redirectTo(crumb.route)}>
          {crumb.name}
        </a>
      </p>
    </Crumb>
  );
}

MobileCrumb.propTypes = {
  crumbs: PropTypes.array.isRequired,
};
