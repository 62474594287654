import React from 'react';
import PropTypes from 'prop-types';
import NotStartedAssessment from './NotStartedAssessment';
import InProgressAssessment from './InProgressAssessment';
import CompletedAssessment from './CompletedAssessment';
import AssessmentTaker from '../AssessmentTaker';
import Section from '../Section';
import LoadingSection from '../LoadingSection';

export default function AssessmentViewer({
  assessment,
  progress,
  handleAllQuestionsAnswered,
  allAnswered,
  setShowTaker,
  showTaker,
  step,
  startAssessment,
  completeAssessment,
  takerId,
  isPublicAssessment,
}) {
  const showNotStarted = step.status === 'not_started' && !allAnswered;
  const showInProgress = step.status === 'in_progress' && !allAnswered && progress < assessment?.questions.length;
  const showCompleted =
    step.status === 'complete' ||
    (step.status === 'in_progress' && (progress === assessment?.questions.length || !!allAnswered));

  return (
    <>
      {!assessment ? (
        <LoadingSection />
      ) : (
        <Section>
          {isPublicAssessment && <h1>{assessment?.name}</h1>}
          {showTaker ? (
            <AssessmentTaker
              assessment={assessment}
              userId={takerId}
              setShowTaker={setShowTaker}
              handleAllQuestionsAnswered={handleAllQuestionsAnswered}
            />
          ) : (
            <>
              {showNotStarted ? (
                <NotStartedAssessment assessment={assessment} startAssessment={startAssessment} />
              ) : showInProgress ? (
                <InProgressAssessment assessment={assessment} progress={progress} setShowTaker={setShowTaker} />
              ) : (
                showCompleted && (
                  <CompletedAssessment assessment={assessment} step={step} completeStep={completeAssessment} />
                )
              )}
            </>
          )}
        </Section>
      )}
    </>
  );
}

AssessmentViewer.propTypes = {
  assessment: PropTypes.object,
  progress: PropTypes.number.isRequired,
  setShowTaker: PropTypes.func.isRequired,
  startAssessment: PropTypes.func.isRequired,
  showTaker: PropTypes.bool.isRequired,
  completeAssessment: PropTypes.func,
  step: PropTypes.object.isRequired,
  takerId: PropTypes.string.isRequired,
  handleAllQuestionsAnswered: PropTypes.func.isRequired,
  allAnswered: PropTypes.bool,
  isPublicAssessment: PropTypes.bool,
};
