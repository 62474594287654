import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../../StyleGuide';

const SelectContainer = styled.div`
  align-items: start;
  display: grid;
  grid-template-columns: 1fr;
  font-size: ${Type.Scale._1};
  color: ${Type.Color.dark};
  position: relative;
  label {
    color: ${Type.Color.medium};
    display: flex;
    flex-direction: column;
  }
`;

const Select = styled.select`
  padding: ${Grid._3} 12px;
  border-radius: ${Border.radius};
  border: 1px solid ${Color.Gray._30};
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._2};
  display: block;
  margin: ${Grid._3} auto ${Grid._5};
  height: ${Grid._7};
  width: 100%;
`;

const SelectDropdown = ({ items, setCurrentQuestionAnswer }) => {
  const [options, setOptions] = useState([]);
  const [selectValue, setSelectValue] = useState('');
  const handleChange = value => {
    if (value !== '') {
      setCurrentQuestionAnswer({ id: value });
    } else {
      setCurrentQuestionAnswer(undefined);
    }
    setSelectValue(value.id);
  };

  useEffect(() => {
    let opts = [
      { value: '', label: 'Select an Answer' },
      ...items.map(answer => ({
        value: answer.possibleAnswerId.id,
        label: answer.value,
      })),
    ];
    if (options !== opts) {
      setOptions(opts);
      setCurrentQuestionAnswer(undefined);
      setSelectValue('');
    }
  }, [items]);

  return (
    <SelectContainer>
      <label htmlFor="select-dropdown">Select:</label>
      <Select id="select-dropdown" value={selectValue} onChange={e => handleChange(e.target.value)}>
        {options.map((o, i) => (
          <option key={'option_' + i} value={o.value}>
            {o.label}
          </option>
        ))}
      </Select>
    </SelectContainer>
  );
};

export default SelectDropdown;
