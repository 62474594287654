import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useSteps } from './StepsContext';

import VideoStepPlayer from '../components/VideoStepPlayer';
import DeletedStep from '../steps/DeletedStep';
import StepMenu from '../steps/StepMenu';
import videoService from '../services/VideoService';
import { handleError } from '../utils/apiUtils';

const Video = styled.div`
  width: 100%;
  z-index: 100;
`;

export default function VideoStep() {
  const [{ training, currentStep, currentStepIsIncomplete }] = useSteps();
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [video, setVideo] = useState({});
  const [deletedVideo, setDeletedVideo] = useState(false);

  useEffect(() => {
    setShowVideoPlayer(false);
    if (currentStep.task?.videoId) {
      videoService
        .getVideoPlaybackById(currentStep.task.videoId)
        .then(response => {
          setVideo(response);
        })
        .catch(error => {
          if (error.response.status === 404) {
            setDeletedVideo(true);
          } else {
            handleError(error);
          }
        })
        .finally(() => setShowVideoPlayer(true));
    } else {
      setShowVideoPlayer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep.id?.id]);

  if (deletedVideo) {
    return (
      <DeletedStep
        deletedMessage="This video has been removed by an admin."
        isComplete={currentStep.status === 'complete'}
      />
    );
  } else {
    return (
      <Video>
        {showVideoPlayer && (
          <>
            <VideoStepPlayer
              currentStep={currentStep}
              userId={training?.assignedUser === undefined ? training?.assigneeId?.id : training?.assignedUser}
              currentStepIsIncomplete={currentStepIsIncomplete}
            />
            {currentStep.task?.taskDescription ? (
              <StepMenu type="text" name="Description" content={currentStep.task.taskDescription} />
            ) : (
              <StepMenu />
            )}
          </>
        )}
      </Video>
    );
  }
}
