import React, { useEffect, useState } from 'react';
import GroupContainer from '../groups/GroupContainer';
import Breadcrumbs from '../components/Breadcrumbs';
import windowService from '../services/windowService';
import orgService from '../services/orgService';
import EmptyState from '../groups/EmptyState';
import SettingsHeader from '../components/SettingsHeader';
import AddPeopleTo from '../components/AddPeopleTo';
import { useUser } from '../authentication';
import LoadingState from '../components/LoadingState';
import { handleError } from '../utils/apiUtils';

const RoleAddPeople = () => {
  const user = useUser();
  const orgId = user.lastSelectedAccount;
  const roleId = windowService.getRoutePart(2);
  const [data, setData] = useState({
    people: [],
    groups: [],
    roles: [],
    role: [],
    isLoading: true,
    isSubmitting: false,
    isError: false,
  });
  const { people, role, isLoading, isSubmitting, isError } = data;

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Roles', route: '#/roles' },
    ...(role?.name ? [{ name: role?.name, route: `#/roles/${roleId}` }, { name: 'Add People' }] : []),
  ];

  useEffect(() => {
    const getOrg = orgService.getOrgUsers(orgId);
    const getRoles = orgService.getRolesOverview(orgId, true);
    const getRole = orgService.getRole(orgId, roleId);

    Promise.all([getOrg, getRoles, getRole])
      .then(response => {
        setData({
          roles: response[1],
          role: response[2],
          people: response[0].users?.filter(person => person.roles.every(role => role?.id.id !== roleId)),
          groups: response[0].groups,
          isLoading: false,
          isError: false,
        });
      })
      .catch(() => setData({ isError: true, isLoading: false }));
  }, [orgId, roleId]);

  const handleAddPeople = selectedPeople => {
    setData(prev => ({ ...prev, isSubmitting: true }));
    orgService
      .assignRoleToUsers(orgId, selectedPeople, role?.id, role?.name, user.userId)
      .then(() => windowService.redirectTo(`/roles/${roleId}`))
      .catch(handleError)
      .finally(() => setData(prev => ({ ...prev, isSubmitting: false })));
  };

  return isError ? (
    <EmptyState isError={'is-error'} />
  ) : (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <GroupContainer data-qa-hook="addPeopleView" rounded>
        <header>
          {!isLoading && (
            <SettingsHeader title={role?.name} subtitle="Role" icon={<i className="fas fa-tags fa-fw" />} />
          )}
        </header>

        <section>
          {data.isLoading ? (
            <LoadingState />
          ) : (
            <AddPeopleTo
              user={user}
              isSubmitting={isSubmitting}
              alreadyAddedPeople={people}
              handleAddPeople={handleAddPeople}
              filters={{
                roles: { displayName: 'Roles', items: data.roles },
                groups: { displayName: 'Groups', items: data.groups },
              }}
            />
          )}
        </section>
      </GroupContainer>
    </>
  );
};

export default RoleAddPeople;
