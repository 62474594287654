angular.module("lwNamb").controller("securityCtrl", [
  "$rootScope",
  "$scope",
  "$location",
  "$window",
  "userService",
  "windowService",
  "$log",
  "profileService",
  "eventDispatchService",
  "apiUrlService",
  "orgService",
  "$localStorage",
  "$route",
  "api",
  "$interval",
  "State",
  "$timeout",
  "blockUX",
  "CacheFactory",
  "serverPropertiesService",
  "$q",
  "licenseService",
  "fullstoryService",
  "intercomService",
  "ssoService",
  "activeCampaignService",
  "config",
  function (
    $rootScope,
    $scope,
    $location,
    $window,
    userService,
    windowService,
    $log,
    profileService,
    eventDispatchService,
    apiUrlService,
    orgService,
    $localStorage,
    $route,
    api,
    $interval,
    State,
    $timeout,
    blockUX,
    CacheFactory,
    serverPropertiesService,
    $q,
    licenseService,
    fullstoryService,
    intercomService,
    ssoService,
    activeCampaignService,
    config
  ) {
    var assessmentState = State("assessmentRedirects");

    var init = function () {
      $rootScope.impersonating = false;
      $rootScope.ssoImpersonating = false;
      $rootScope.origin = apiUrlService.getOrigin();
      $rootScope.brandClass = "";
      $rootScope.hideNavbar = true;
      $scope.orgs = [];
      getRootGridOrgId();
      $rootScope.bodyClass = getBodyClass();

      $rootScope.showClassicTheme = $localStorage.showClassicTheme ? true : false;
      $rootScope.isCurriculumEnabled = config.isCurriculumEnabled;

      if (intercomService.show()) {
        // startup Intercom as guest so it's available on all pages
        // it will be supplied with user info if a user is retrieved
        intercomService.bootForGuest();
      }

      ssoService.getUser().then(
        function (user) {
          if (user) {
            $log.debug("We've already got a user logged in, nothing to do!");
          } else {
            $log.debug(
              "Unable to get a local user from library state - attempt a sign-in silent in so if they have an SSO session we join it"
            );
            ssoService.signInSilent();
          }
        },
        function () {
          $log.debug(
            "Unable to get a local user from library state - attempt a sign-in silent in so if they have an SSO session we join it"
          );
          ssoService.signInSilent();
        }
      );
      clearStaleState();
    };

    function clearStaleState() {
      $timeout(function () {
        ssoService.clearStaleState();
        clearStaleState();
      }, 60000);
    }

    var getBodyClass = function () {
      return $location.path().split("/")[1];
    };

    var getRootGridOrgId = function () {
      serverPropertiesService.getGridRootOrgId().then(function (gridRootOrgId) {
        $scope.rootGridId = gridRootOrgId;
      });
    };

    var loadUserSuccess = function (user) {
      $rootScope.impersonating = user.impersonating;
      $rootScope.ssoImpersonating = user.ssoImpersonation;
      $scope.loggedIn = true;
      $scope.user = user;
      $rootScope.userPermissions = user.permissions;
      $rootScope.$broadcast("CheckSocket", user);

      window.dispatchEvent(new Event("USER_LOADED"), user);

      loadOrg();
      loadUserProfile($scope.user);
    };

    function loadUserProfile(user) {
      profileService.getProfile(user.userId).then(function (response) {
        user.profileImageUrl = response && response.profile && response.profile.imageUrl;
        $rootScope.userProfile = response;
      });
    }

    var syncWhiteLabelOrg = function () {
      $timeout(function () {
        getUserOrgsData();
        if ($localStorage.syncOrg) {
          var userHasOrgId = function (orgId) {
            return $scope.orgs.find(function (org) {
              return org._id.id === orgId;
            });
          };
          var handleSyncOrg = function (whiteLabelOrgId) {
            if (userHasOrgId(whiteLabelOrgId)) {
              userService.switchOrg($scope.user.userId, whiteLabelOrgId);
              $localStorage.newHash = "/dashboard";
              windowService.redirectHash("/dashboard");
            } else {
              //Because of the async Candidate setup flow, we can't guarantee the timing for them. No one is coming to SendPortal accidentally.
              $localStorage.is401 = true;
              $localStorage.newHash = "/401";
              windowService.redirectHash("/401");
            }
          };
          if (getWhiteLabelOrgId() !== undefined && $scope.user.lastSelectedAccount !== getWhiteLabelOrgId()) {
            handleSyncOrg(getWhiteLabelOrgId());
          }
          $localStorage.syncOrg = false;
        }
      }, 3000);
    };

    var getUserOrgsData = function () {
      if ($scope.orgs === undefined || $scope.orgs.length === 0) {
        userService.lookupUserOrgs().then(
          function (response) {
            $scope.orgs = response;
            $scope.onlyNonChurchOrgs =
              response.length === 0
                ? false
                : response.every(function (org) {
                    return (
                      [
                        "e9b28fe3-82f5-47fd-b97e-fe5a37115e12",
                        "f04347b9-ed70-4f05-f49f-2adad4c508a0",
                        "fb782e99-b1d7-44df-bc18-206922301c8b",
                        "531da824-2f13-41fe-d693-b22b8f0dfd88",
                        "ce69d3d6-91eb-4b1f-b137-ae3a379240bb",
                        "1cce7507-4cbe-421f-8e60-470fdc127466",
                      ].indexOf(org._id.id) > -1
                    );
                  });
            $scope.orgs.forEach(function (org) {
              if (org.logo) {
                org.logo = apiUrlService.getUrl() + "/v1/images/org/" + org.logo;
              }
            });
            serverPropertiesService.getGridCommunityOrgId().then(function (gridCommunityOrgId) {
              $scope.gridCommunityOrgId = gridCommunityOrgId;
              $scope.orgs = $scope.orgs.filter(function (org) {
                if (org._id.id === gridCommunityOrgId) {
                  var adminRoles = org.memberRoles.filter(function (role) {
                    return (
                      role.permission.permissions.filter(function (perm) {
                        return perm.access !== "View Only";
                      }).length > 0
                    );
                  });
                  if (adminRoles.length > 0) {
                    $scope.gridCommunityAdmin = true;
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              });
            });
          },
          function (error) {
            $scope.orgs = undefined;
            $log.error("Failed to retrieve User Organizations.");
          }
        );
      }
    };

    var loadOrg = function () {
      getUserOrgsData();
      function mapRoles(org) {
        return org.memberRoles.map(function (role) {
          return role.permission.permissions.map(function (p) {
            return p.access;
          });
        });
      }
      function getRolesForTracking(user) {
        var allRoles = Array.from(new Set($scope.orgs.map(mapRoles).flat(2)));
        var orgRoles = $scope.orgs
          .filter(function (o) {
            return o._id.id === user.lastSelectedAccount;
          })
          .map(mapRoles)
          .flat(2);
        return { all: allRoles, org: orgRoles };
      }
      function identify() {
        var user = $scope.user;
        var promises = [];
        promises.push(orgService.hasGridSubscription(user.lastSelectedAccount));
        promises.push(orgService.hasCurriculumSubscription(user.lastSelectedAccount));
        promises.push(licenseService.getIndividualLicenses(user.userId));
        $q.all(promises).then(function (results) {
          var impersonatingDisplay =
            user.impersonatedBy !== undefined ? " impersonated by: " + user.impersonatedBy : "";
          var username = user.displayName + impersonatingDisplay;
          var orgName = $rootScope.org !== undefined ? $rootScope.org.name : "No org";

          $scope.hasGridSubscription = results[0];

          if (
            $window.location.host.indexOf("local") === -1 &&
            $window.location.host.indexOf(".dev.") === -1 &&
            $window.location.host.indexOf(".stage.") === -1
          ) {
            var r = getRolesForTracking(user);
            activeCampaignService.track(
              user.userId,
              user.logInEmail,
              user.firstName,
              user.lastName,
              username,
              user.lastSelectedAccount,
              orgName,
              r.org,
              apiUrlService.getCommitHash(),
              $window.location.href,
              results[0],
              results[1],
              results[2].length > 0,
              r.all
            );
          }

          if (intercomService.show()) {
            var roles = getRolesForTracking(user);
            intercomService.bootForUser(
              user.userId,
              user.logInEmail,
              user.firstName,
              user.lastName,
              username,
              user.lastSelectedAccount,
              orgName,
              roles.org,
              apiUrlService.getCommitHash(),
              $window.location.href,
              results[0],
              results[1],
              results[2].length > 0,
              roles.all,
              user.businessPartnerSources
            );
          }

          if (
            $window.location.host.indexOf("ministrygrid.lifeway.com") > -1 ||
            $window.location.host.indexOf("bcguestservices.org") > -1 ||
            $window.location.host.indexOf("hub.transformationchurch.tc") > -1 ||
            $window.location.host.indexOf("training.idlewild.org") > -1 ||
            $window.location.host.indexOf("training.bellevue.org") > -1 ||
            $window.location.host.indexOf("training.dswnetwork.org") > -1 ||
            $window.location.host.indexOf("training.mbcb.org") > -1 ||
            $window.location.host.indexOf("thegrid.caminouniversity.org") > -1 ||
            $window.location.host.indexOf("training.connexuschurch.com") > -1 ||
            $window.location.host.indexOf("training.rca.org") > -1 ||
            $window.location.host.indexOf("training.centrikid.lifeway.com") > -1 ||
            $window.location.host.indexOf("training.fuge.lifeway.com") > -1 ||
            $window.location.host.indexOf("training.studentlife.lifeway.com") > -1 ||
            $window.location.host.indexOf("training.sbc.net") > -1 ||
            $window.location.host.indexOf("training.a21.org") > -1
          ) {
            fullstoryService.identify(user.userId, user.logInEmail, user.firstName, user.lastName);
          } else {
            fullstoryService.shutdown();
          }
        });
      }
      if ($rootScope.org === undefined || $rootScope.org.id !== $scope.user.lastSelectedAccount) {
        if ($scope.user.lastSelectedAccount !== "" && $scope.user.lastSelectedAccount !== undefined) {
          orgService.getOrg($scope.user.lastSelectedAccount).then(
            function (response) {
              if (angular.equals(response, {}) && $scope.orgs.length) {
                userService.switchOrg($scope.user.userId, $scope.orgs[0]._id.id);
              } else {
                $rootScope.org = response;
                if ($rootScope.org.logo) {
                  $rootScope.orgLogo = apiUrlService.getUrl() + "/v1/images/org/" + $rootScope.org.logo;
                }
                identify();
                // Not sure the below reload is needed. Seems to be messing up the reloading of a controller that
                // had already submitted calls.
                // $log.error("reloading route in loadOrg of sec con");
                // $route.reload();
                $rootScope.$broadcast("ORG_LOADED");
              }
            },
            function (reason) {
              $log.error(reason);
              $log.error(
                "CRITICAL: Error looking up org. " +
                  $scope.user.lastSelectedAccount +
                  " <> " +
                  JSON.stringify($scope.orgs)
              );
            }
          );
          orgService.hasCurriculumSubscription($scope.user.lastSelectedAccount).then(
            function (result) {
              $scope.hasCurriculumSubscription = result;
            },
            function () {
              $scope.hasCurriculumSubscription = false;
            }
          );
        } else if ($scope.orgs.length) {
          userService.switchOrg($scope.user.userId, $scope.orgs[0]._id.id);
        } else {
          identify();
        }
      } else {
        identify();
      }
    };

    var clearReactCache = function () {
      if (window.cacheService) {
        window.cacheService.removeAll();
      }
    };

    var clear = function () {
      //Keep the events log so that we don't get duplicates on a re-login
      var events = $localStorage.receivedEvents ? $localStorage.receivedEvents.slice(0, 1000) : [];
      $localStorage.$reset();
      $localStorage.user = undefined;
      $localStorage.receivedEvents = events;
      CacheFactory.clearAll();
      clearReactCache();
      $scope.user = undefined;
      $rootScope.org = undefined;
      $rootScope.orgLogo = undefined;
      $scope.orgs = [];
      eventDispatchService.shutdown();
      $scope.loginForm = {};
      $rootScope.impersonating = false;
      $rootScope.ssoImpersonating = false;
      $scope.loggedIn = false;
      intercomService.shutdown();
    };

    var redirectToLogin = function (attachCurrentLocation, shouldWait) {
      var loginParam = shouldWait ? "/login?isLoading=true" : "/login";

      if (!attachCurrentLocation) {
        windowService.redirectHash(loginParam);
      } else {
        var qs = getQueryStringFromUrlForStoringInRoute();
        if (qs.indexOf("login") === -1) {
          windowService.redirectHash(loginParam + qs);
        }
      }
    };

    var loginFailure = function (httpResponse) {
      $log.error(httpResponse.status + ": " + httpResponse.data);
      windowService.redirectHash("/login");
      $scope.error = true;
    };

    function getQueryStringFromUrlForStoringInRoute() {
      return "?route=" + encodeURIComponent($location.url().substring(1));
    }

    $scope.redirectToLifeWayLoginFromLoggedOutState = function () {
      userService
        .redirectToLoginV2($window.location.href + getQueryStringFromUrlForStoringInRoute())
        .then(function (result) {
          windowService.redirect(result);
        }, loginFailure);
    };

    $scope.redirectToLifeWayRegisterFromLoggedOutState = function () {
      userService
        .redirectToRegisterV2($window.location.href + getQueryStringFromUrlForStoringInRoute())
        .then(function (result) {
          windowService.redirect(result);
        }, loginFailure);
    };

    $scope.logout = function (attachCurrentLocation, shouldWait, email) {
      clear();
      var loginParam = shouldWait ? "/login?isLoading=true" : "/login";
      $localStorage.redirectEmail = email;

      userService
        .logout(attachCurrentLocation, loginParam + getQueryStringFromUrlForStoringInRoute())
        .finally(function () {
          ssoService.getUser().then(
            function (u) {
              $log.error("user found, logging out: " + u.id_token);
              ssoService.signOut();
            },
            function () {
              $log.error("no user found, not logging out");
              redirectToLogin(attachCurrentLocation || false, shouldWait);
            }
          );
          $scope.isProcessing = false; // is this supposed to be here or after ssoService callbacks?
        });
    };

    $scope.logoutAndRedirectToLifeWay = function (cmd, redirectURL, email) {
      if (apiUrlService.getIsSSO()) {
        clear();

        ssoService.getUser().then(
          function (user) {
            $log.log("user found, logging out: " + user.id_token);
            userService.logout().then(function () {
              $localStorage.redirectToLifeWay = {
                cmd: cmd,
                redirectURL: redirectURL,
                email: email,
              };
              ssoService.signOut();
            });
          },
          function () {
            $log.error("no user found, not logging out");

            if (cmd === "login") {
              userService.redirectToLoginV2(redirectURL, email);
            } else {
              userService.redirectToRegisterV2(redirectURL, email);
            }
          }
        );
      } else {
        $scope.logout(true, undefined, email);
      }
    };

    //Window
    $scope.redirectTo = function (path) {
      windowService.redirectHash(path);
    };

    $scope.backToLastLevel = function (trainingInstanceId, trainingType) {
      var saved = assessmentState.get();
      var lastRootLevel = saved.lastRootView;
      switch (lastRootLevel) {
        case "myTasks":
          $scope.redirectTo("/training-viewer/" + trainingType + "/" + trainingInstanceId);
          break;
        case "prepareEnrich":
          $scope.redirectTo("/prepare-enrich");
          break;
        case "history":
          $scope.redirectTo("/training-viewer/" + trainingType + "/" + trainingInstanceId);
          break;
        default:
          $scope.redirectTo("/training-viewer/" + trainingType + "/" + trainingInstanceId);
          break;
      }
    };

    //Set Active Class
    $scope.isActive = function (route) {
      return route === $location.path();
    };

    function checkSessionAndLoadUser(redirect) {
      $timeout(function () {
        //going to try and give the back end a half second to catch up
        userService.checkSession().then(function (user) {
          loadUserSuccess(user);
          if (redirect !== undefined && redirect === true) {
            blockUX.stop();
            windowService.redirectHash(userService.getRedirectHashAfterLogin(user));
          }
        }); //new permissions, requery for their info
      }, 500);
    }

    $scope.$on("UserLoggedIn", function (event_label, event) {
      if (event.impersonating) {
        clear();
        $localStorage.user = event;
      }
      loadUserSuccess(event); //setup user upon login
    });

    $scope.$on("LOGIN_PAGE_VALID_SESSION", function (event_label, event) {
      loadUserSuccess(event);
    });

    $scope.$on("SYNC_WHITELABEL_ORG", function (event_label, event) {
      syncWhiteLabelOrg();
    });

    $rootScope.$on("REDIRECT_TO_LIFEWAY", function (event_label, cmd, redirectURL, email) {
      $scope.logoutAndRedirectToLifeWay(cmd, redirectURL, email);
    });

    [
      "OrgAccountRoleUpdated",
      "RemoveFromRoleSucceeded",
      "OrgAccountRoleUnassigned",
      "AddToRoleSucceeded",
      "OrgAccountRoleAssignedById",
      "OrgAccountRoleAssigned",
      "RoleAssignedToUsers",
    ].map(function (eventName) {
      $scope.$on(eventName, function () {
        checkSessionAndLoadUser();
      });
    });

    $scope.$on("GroupMemberPositionChanged", function (_, event) {
      if (event.userId.id === $scope.user.userId) {
        checkSessionAndLoadUser();
      }
    });

    $scope.$on("OrgAccountGroupDeleted", function (_, event) {
      checkSessionAndLoadUser();
    });

    $scope.$on("AccountSelected", function (event_label, event) {
      if ($scope.user !== undefined && event.id === $scope.user.userId) {
        $timeout(function () {
          CacheFactory.clearAll();
          clearReactCache();
          $scope.orgs = [];

          $rootScope.org = undefined;
          $rootScope.orgLogo = undefined;

          checkSessionAndLoadUser();
        }, 1000);
      }
    });

    $scope.$on("InviteAccepted", function () {
      $scope.orgs = [];
      checkSessionAndLoadUser(false);
      $localStorage.isRedeemingInvite = true;
    });

    $scope.$on("LicenseCreated", function () {
      if ($location.$$url.location.indexOf("pairing") === -1) {
        windowService.redirectHash("/pairing");
      }
    });

    $scope.$on("unauthorized", function () {
      if ($route.current && !$route.current.$$route.publiclyAccessible) {
        $scope.logout(true);
      }
    });

    $scope.$on("UserSignedOut", function (eventName, event) {
      if ($localStorage.user !== undefined && $localStorage.redirectToLifeWay === undefined) {
        $scope.logout(event === undefined || event.storeRoute !== false);
      }
    });

    $scope.$on("NoUserFound", function () {
      clear();
    });

    $scope.$on("503", function () {
      $localStorage.currentPage = window.location.hash;
      windowService.redirectHash("/503");
    });

    $scope.$on("siteIsUp", function () {
      windowService.redirectHash(
        $localStorage.currentPage === undefined || $localStorage.currentPage.indexOf("503") > -1
          ? "/dashboard"
          : $localStorage.currentPage
      );
    });

    $scope.$on("$routeChangeStart", function (event, next, current) {
      if (next.$$route && next.$$route.controller === "StepsViewerCtrl" && current && current.$$route)
        localStorage.setItem("routeBeforeSteps", current.$$route.originalPath);

      if (next.$$route && next.$$route.controller === "selfAssignLinkCtrl" && current && current.$$route)
        localStorage.setItem("routeBeforeSelfAssign", current.$$route.originalPath);

      if (next.$$route !== undefined && (next.$$route.controller !== undefined || next.$$route.template !== undefined || $localStorage.user !== undefined)) {
        userService.user().then(
          function (user) {
            if (apiUrlService.getIsSSO()) {
              ssoService.getUser().then(
                function () {
                  $log.debug("still have user");
                  loadUserSuccess(user);
                },
                function () {
                  if (!next.$$route.publiclyAccessible) {
                    $log.debug("NO user");
                    $scope.logout(true);
                  }
                }
              );
            } else {
              loadUserSuccess(user);
            }
          },
          function () {
            if (!next.$$route.publiclyAccessible) {
              if (apiUrlService.getIsSSO()) {
                ssoService.getSignedIn().then(null, function () {
                  $scope.logout(true);
                });
              } else {
                $scope.logout(true);
              }
            }
          }
        );
      }

      if ($localStorage.is401 === true && next.$$route && next.$$route.originalPath.indexOf("401") === -1) {
        $scope.logout(false);
      }
    });

    $rootScope.$on("$routeChangeSuccess", function (event, current, previous) {
      if (window.onAngularRouteChanged) window.onAngularRouteChanged($location.path());

      $rootScope.bodyClass = getBodyClass();

      if (previous && previous.$$route) {
        $rootScope.previousRoute = previous;

        var sectionTab = previous.$$route.section ? previous.$$route.section.tab : null;
        $rootScope.prevTab = previous.$$route.tab || sectionTab; // todo: remove dependency
      }
    });

    init();
  },
]);
