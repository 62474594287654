const acctId = '2034960640001';
const API_BASE_URL = getApiUrl(); // todo: improve config injection

const baseQS = (event, videoId, sessionId) => {
  const date = new Date();
  const baseURL = '//metrics.brightcove.com/v2/tracker';
  return (
    baseURL +
    '?video=' +
    videoId +
    '&event=' +
    event +
    '&domain=videocloud&account=' +
    acctId +
    '&session=' +
    sessionId +
    '&source=' +
    encodeURIComponent(window.location.href) +
    '&time=' +
    date.getTime()
  );
};

const track = requestURL => {
  if (
    API_BASE_URL.indexOf('.int.') === -1 &&
    API_BASE_URL.indexOf('.dev.') === -1 &&
    API_BASE_URL.indexOf('.stage.') === -1 &&
    API_BASE_URL.indexOf('.uat.') === -1
  ) {
    var scriptElement = document.createElement('img');
    scriptElement.setAttribute('src', requestURL);
    document.getElementsByTagName('body')[0].appendChild(scriptElement);
    return true;
  }
};

const brightcoveTrackingService = {
  playerLoad: (videoId, sessionId) => {
    return track(baseQS('player_load', videoId, sessionId));
  },
  playRequest: (videoId, sessionId) => {
    return track(baseQS('play_request', videoId, sessionId));
  },
  videoImpression: (videoId, sessionId) => {
    return track(baseQS('video_impression', videoId, sessionId));
  },
  videoView: (videoId, sessionId) => {
    return track(baseQS('video_view', videoId, sessionId));
  },
  videoEngagement: (videoId, sessionId, from, to, duration) => {
    return track(
      baseQS('video_engagement', videoId, sessionId) + '&range=' + from + '..' + to + '&video_duration=' + duration
    );
  },
};

export default brightcoveTrackingService;
