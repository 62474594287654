angular.module('lwNamb').controller(
  'AssessmentFeedbackStepViewerCtrl',
  [
    '$rootScope',
    '$scope',
    '$log',
    'taskListInstanceService',
    '$routeParams',
    function(
      $rootScope,
      $scope,
      $log,
      taskListInstanceService,
      $routeParams
    ) {
      var init = function() {
        $scope.loading = true;
        $rootScope.$broadcast('CreateSocketForEmail', $routeParams.email);
        retrieveTLData();
      };

      var retrieveTLData = function() {
        taskListInstanceService
          .getByInstanceId($routeParams.courseId)
          .then(
            function(training) {
              $scope.training = training;
              $scope.stepInstanceId = $routeParams.stepId;
              $scope.email = $routeParams.email;
            },
            function(reason) {
              $log.error(reason);
            }
          )
          .finally(function() {
            $scope.loading = false;
          });
      };

      init();
    },
  ]
);
