import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import feedbackResponseService from '../services/feedbackResponseService';
import ObserverForm from './ObserverForm';
import { PrimaryButton } from '../components/Buttons';
import ForceCompleteModal from './ForceCompleteModal';
import { useSteps, useCompleteStep as DefaultUseCompleteStep } from './StepsContext';

export default function FeedbackStep({ useCompleteStep = DefaultUseCompleteStep }) {
  const { completeStep } = useCompleteStep();
  const [{ currentStep, training, isWorkflow, currentCourse }] = useSteps();
  const [showResponses, setShowResponses] = useState(false);
  const [requests, setRequests] = useState([]);
  const [showForceCompleteModal, setShowForceCompleteModal] = useState(false);
  const numCompleteResponses = currentStep.task.feedbackResponses.filter(r => r.status === 'complete').length;
  const courseInstanceId = isWorkflow ? currentCourse.id : training.id;

  const forceComplete = () =>
    feedbackResponseService
      .forceCompleteFeedback(courseInstanceId, currentStep.id, currentStep.assigneeId?.id)
      .then(() => {
        setShowForceCompleteModal(false);
        completeStep();
      });

  useEffect(() => {
    setShowResponses(false);
    let arr = [];
    for (let i = 0; i < currentStep.task.numEmailsToGather; i++) {
      if (currentStep.task.feedbackResponses[i]) {
        arr.push(currentStep.task.feedbackResponses[i]);
      } else {
        arr.push({
          email: '',
          firstName: '',
          status: '',
          responseId: i,
        });
      }
    }
    setRequests([...arr]);
  }, [currentStep]);

  useEffect(() => {
    setShowResponses(true);
  }, [requests]);

  const addFeedbackResponseUser = (emailAddress, firstName) => {
    return feedbackResponseService.addFeedbackResponseUser(
      courseInstanceId,
      currentStep,
      emailAddress,
      firstName,
      window.location.origin,
      currentStep.assigneeId.id
    );
  };

  const removeFeedbackResponseUser = (emailAddress, firstName) => {
    return feedbackResponseService.removeFeedbackResponseUser(
      courseInstanceId,
      currentStep,
      emailAddress,
      firstName,
      window.location.origin,
      currentStep.assigneeId.id
    );
  };

  const resendFeedbackUser = (emailAddress, firstName) => {
    return feedbackResponseService.resendFeedbackUser(
      courseInstanceId,
      currentStep,
      emailAddress,
      firstName,
      currentStep.assigneeId.id
    );
  };

  return (
    <>
      <div style={{ alignItems: 'start' }} className="grid-container grid-gap-24 grid-sm-col-2">
        {showResponses &&
          requests.map(req => (
            <ObserverForm
              key={req.responseId}
              status={req.status}
              hasBeenInvited={Boolean(req.email)}
              firstName={req.firstName}
              emailAddress={req.email}
              addFeedbackResponseUser={addFeedbackResponseUser}
              removeFeedbackResponseUser={removeFeedbackResponseUser}
              resendFeedbackUser={resendFeedbackUser}
            />
          ))}
      </div>
      {currentStep.status !== 'complete' && numCompleteResponses >= currentStep.task.responsesReqForComplete && (
        <p>
          <PrimaryButton data-qa-hook="feedbackViewerMarkAllComplete" onClick={() => setShowForceCompleteModal(true)}>
            Mark as Complete
          </PrimaryButton>
        </p>
      )}
      {showForceCompleteModal && (
        <ForceCompleteModal setShowForceCompleteModal={setShowForceCompleteModal} forceComplete={forceComplete} />
      )}
    </>
  );
}

FeedbackStep.propTypes = {
  useCompleteStep: PropTypes.func,
};
