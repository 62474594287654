import React, { useState, useEffect } from 'react';
import { react2angular } from 'react2angular';
import PropTypes from 'prop-types';
import Notification from 'react-notify-toast';
import { Grid } from '../StyleGuide';
import { ContainerFluid } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import TrainingHeader from './TrainingHeader';
import CourseContents from './CourseContents';
import PathwayContents from './PathwayContents';
import { UserProvider } from '../authentication';
import { OrgProvider } from '../contexts/OrgContext';
import TrainingContext from '../contexts/TrainingContext';
import trainingService from '../services/trainingService';
import licenseService from '../services/licenseService';

export default function Training({
  training,
  user,
  org,
  searchData,
  hasGridSubscription,
  hasIndividualGridSubscription,
  hasLimitedGridSubscription,
  canAccessWithoutSubscription,
  redirectToLifeWayLoginFromLoggedOutState,
  $localStorage,
}) {
  const [state, setState] = useState({ isLoading: true, isError: false });
  const { isLoading, isError } = state;
  const itemNumber = training?.itemNumber?.value || searchData?.itemNumber;

  const setupUserAndTraining = (hasIndividualLicenseForItem = false) => {
    if (user) {
      user.hasGridSubscription = hasGridSubscription;
      user.hasIndividualGridSubscription = hasIndividualGridSubscription;
      user.hasLimitedGridSubscription = hasLimitedGridSubscription;
      user.hasAssignPermission = user.permissions.filter(p => p === 'Assign View').length > 0;
      user.hasBuildPermission = user.permissions.filter(p => p === 'Build View').length > 0 && hasGridSubscription;
      user.isAdmin = user.permissions.filter(p => p === 'Account View').length > 0;
      user.isGroupLeader = user.permissions.filter(p => p === 'Group Leader').length > 0;
      user.canAssign = user.hasAssignPermission || user.isGroupLeader || user.isAdmin;
      user.canTake =
        searchData.searchAccess === 'Free' ||
        (searchData.searchAccess === 'Paid' &&
          (hasGridSubscription || hasIndividualGridSubscription || hasIndividualLicenseForItem)) ||
        canAccessWithoutSubscription;
      user.canAccess =
        (searchData.searchAccess === 'Paid' && hasGridSubscription) ||
        canAccessWithoutSubscription ||
        (searchData.searchAccess === 'Free' && org);
    }

    if (training && searchData) {
      training.type = searchData.isTasklist ? 'Course' : searchData.isWorkflow ? 'Pathway' : '';
      training.numItems = searchData.isTasklist
        ? training.tasks.length
        : training.entities.filter(e => e._type === 'TaskList').length;
      training.ownerName = searchData.ownerName;
      training.searchAccess = searchData.searchAccess;
      training.categories = searchData.categories;
      training.locked =
        searchData.searchAccess !== 'Free' && training.type === 'Course' && trainingService.isCourseLocked(training);
      training.isIncludedWithGridSubscription = trainingService.isIncludedWithGridSubscription(itemNumber);
    }

    setState({ isLoading: false, isError: false });
  };

  useEffect(() => {
    licenseService
      .hasIndividualLicenseForItem(itemNumber)
      .then(hasLicense => {
        setupUserAndTraining(hasLicense);
      })
      .catch(error => {
        console.error(error);

        // We want to hard-fail on this error because the user may not
        // need to utilize their individual licenses for this training.
        setupUserAndTraining(false);
      });
  }, []);

  return isLoading ? (
    <LoadingState />
  ) : isError ? (
    <ErrorMessage />
  ) : (
    <UserProvider user={user}>
      <OrgProvider org={org}>
        <Notification />
        <TrainingContext.Provider value={training}>
          <ContainerFluid style={{ margin: `${Grid._6} auto` }}>
            <TrainingHeader redirectToLifeWayLoginFromLoggedOutState={redirectToLifeWayLoginFromLoggedOutState} />
            {training.type === 'Course' && <CourseContents localStorage={$localStorage} />}
            {training.type === 'Pathway' && <PathwayContents localStorage={$localStorage} />}
          </ContainerFluid>
        </TrainingContext.Provider>
      </OrgProvider>
    </UserProvider>
  );
}

Training.propTypes = {
  training: PropTypes.object.isRequired,
  searchData: PropTypes.object.isRequired,
  user: PropTypes.object,
  org: PropTypes.object,
  hasGridSubscription: PropTypes.bool,
  hasIndividualGridSubscription: PropTypes.bool,
  hasLimitedGridSubscription: PropTypes.bool,
  canAccessWithoutSubscription: PropTypes.bool.isRequired,
  redirectToLifeWayLoginFromLoggedOutState: PropTypes.func,
};

// eslint-disable-next-line no-undef
angular
  .module('lwNamb')
  .component(
    'training',
    react2angular(
      Training,
      [
        'training',
        'searchData',
        'user',
        'org',
        'hasGridSubscription',
        'hasIndividualGridSubscription',
        'hasLimitedGridSubscription',
        'canAccessWithoutSubscription',
        'redirectToLifeWayLoginFromLoggedOutState',
      ],
      ['$localStorage']
    )
  );
