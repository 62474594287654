import React from 'react';
import { useParams } from 'react-router-dom';
import windowService from '../services/windowService';
import Breadcrumbs from '../components/Breadcrumbs';
import { PartialSearchResultItem } from '../search';
import { localStorage, sessionStorage } from '../utils/storageUtils';
import { AddTraining } from './components/AddTraining';

type UrlParams = {
  atIndex: string;
  id?: string;
};

export const AddTrainingToCarousel = () => {
  const { atIndex, id } = useParams<UrlParams>();

  const carouselName = id && sessionStorage.getItem('createCarouselItem')?.name;
  const carouselItemPath = `#/manage-catalog/carousel-item/${atIndex}${id ? `/${id}` : ''}`;

  const redirectToManageCatalog = () => {
    windowService.redirectTo(carouselItemPath);
  };

  const addTraining = (selectedTraining: PartialSearchResultItem[]) => {
    localStorage.setItem('carouselTrainingItem', selectedTraining);
    redirectToManageCatalog();
  };

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Catalog', route: '#/manage-catalog' },
    { name: carouselName || 'New Carousel Item', route: carouselItemPath },
    { name: 'Add Training' },
  ];

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <AddTraining onAddSelectedTraining={addTraining} singleSelect />
    </>
  );
};
