import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Breakpoints, Grid, Type, Color } from '../../StyleGuide';
import { SmallRoundedIconButton, OutlineButton } from '../../components/Buttons';
import { OrderingDragContainer, OrderingDragHandle } from '../../components/OrderingElements';
import { SubHeaderWithButton } from '../../components/Layout';
import { SortableElement } from 'react-sortable-hoc';
import ConfirmationModal from '../../components/ConfirmationModal';
import CopyHeadersModal from './CopyHeadersModal';
import alertService from '../../services/AlertService';
import curriculumService from '../../services/curriculumService';
import windowService from '../../services/windowService';
import EditTextModal from '../../components/EditTextModal';
import ErrorMessage from '../../components/ErrorMessage';
import useCurriculum from '../../hooks/useCurriculum';
import { useParams } from 'react-router-dom';

const ListRowItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Type.Color.dark};
  transition: all 0.15s ease-in-out;
  button {
    margin: 0 ${Grid._4};
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      margin: 0 ${Grid._6};
    }
  }
  .list-row-text {
    flex: 1;
    margin: 0 ${Grid._4};
    padding: ${Grid._6} 0;
    display: flex;
    flex-direction: column;
    h4,
    p {
      flex: 1;
      margin: 0;
      font-size: ${Type.Scale._3};
    }
    p {
      flex: 2;
      color: ${Type.Color.medium};
    }
    &:hover {
      cursor: pointer;
      h4 {
        text-decoration: underline;
      }
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      flex-direction: row;
      align-items: center;
      margin: 0 ${Grid._5};
      h4 {
        margin-right: ${Grid._6};
      }
    }
  }
  + div {
    border-top: 1px solid ${Color.Gray._20};
  }
  &:hover {
    background: ${Color.white};
  }
`;

const VolumeHeadersList = ({ headers, user }) => {
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, volumeId } = useParams();

  const [volumeHeaders, setVolumeHeaders] = useState(headers);
  const [removeModal, setRemoveModal] = useState({ show: false, name: '', id: '' });
  const [showCopyHeadersModal, setShowCopyHeadersModal] = useState(false);
  const [showCopyHeadersError, setShowCopyHeadersError] = useState(false);
  const [showAddHeaderModal, setShowAddHeaderModal] = useState(false);

  const reorderHeaders = ({ oldIndex, newIndex }) => {
    const list = [...volumeHeaders];
    list.splice(newIndex, 0, list.splice(oldIndex, 1)[0]);
    setVolumeHeaders(list);
    curriculumService
      .reorderVolumeHeaders(
        volumeId,
        list.map(h => h.id),
        user.userId
      )
      .then(() => alertService.show('Volume Headers Reordered', 'success', 2000))
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please refresh and try again.', 'error');
      });
  };

  const OrderingDragItem = SortableElement(props => <ListRowItem {...props}>{props.children}</ListRowItem>);

  const removeHeader = headerId => {
    curriculumService
      .removeVolumeHeader(volumeId, headerId, user.userId)
      .then(() => {
        setVolumeHeaders(volumeHeaders.filter(h => h.id !== headerId));
        alertService.show('Volume Header Removed');
      })
      .catch(failure => {
        console.error(failure);
        failure.reason && failure.reason.id === 'NotEmpty'
          ? alertService.show('An error occurred. Please remove all the header materials before removing.', 'error')
          : alertService.show('An error occurred. Please try again.', 'error');
      })
      .finally(() => setRemoveModal({ show: false, name: '', id: '' }));
  };

  const addHeader = name =>
    curriculumService
      .createVolumeHeader(volumeId, name, volumeHeaders.length, user.userId)
      .then(response => {
        alertService.showOnNextPage('Volume Header Added');
        windowService.redirectTo(
          `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/volume/${volumeId}/header/${response.section.id}`
        );
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
        setShowAddHeaderModal(false);
      });

  const copyHeaders = () =>
    curriculumService
      .copyVolumeHeaders(volumeId, user.userId)
      .then(response => {
        setVolumeHeaders(response.sections);
        alertService.show('Volume Headers Copied');
      })
      .catch(err => {
        if (err && err.reason && err.reason.id === 'NotFound') {
          switchHeadersModal(true);
        } else {
          console.error(err);
          alertService.show('An error occurred. Please try again.', 'error');
        }
      })
      .finally(() => setShowCopyHeadersModal(false));

  const handleCopyHeadersSelection = isCopy => (isCopy ? copyHeaders() : switchHeadersModal());

  const switchHeadersModal = showError => {
    setShowCopyHeadersError(!!showError);
    setShowCopyHeadersModal(false);
    setShowAddHeaderModal(true);
  };

  return (
    <div className="grid-container grid-col-4 grid-sm-col-8 grid-col-gap-32">
      <div className="grid-col-span-4 grid-sm-col-span-8">
        <SubHeaderWithButton>
          <h4>Volume Headers</h4>
          <OutlineButton
            onClick={() => (!volumeHeaders.length ? setShowCopyHeadersModal(true) : setShowAddHeaderModal(true))}
          >
            <i className="icomoon-addList"></i> Add Header
          </OutlineButton>
        </SubHeaderWithButton>
      </div>
      <div className="grid-col-span-4 grid-sm-col-span-8" style={{ margin: '32px 0' }}>
        {volumeHeaders.length ? (
          <OrderingDragContainer onSortEnd={reorderHeaders} useDragHandle>
            {volumeHeaders.map((header, i) => (
              <OrderingDragItem key={i} index={i}>
                {volumeHeaders.length > 1 && <OrderingDragHandle />}
                <div
                  className="list-row-text"
                  onClick={() =>
                    windowService.redirectTo(
                      `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/volume/${volumeId}/header/${header.id}`
                    )
                  }
                >
                  <h4 data-qa-hook="volumeHeaderName">{header.header}</h4>
                  <p>{header.materials.length === 0 ? 'No' : header.materials.length} Materials</p>
                </div>
                <SmallRoundedIconButton
                  onClick={() => setRemoveModal({ show: true, name: header.header, id: header.id })}
                >
                  <i className="far fa-trash-alt fa-fw"></i>
                </SmallRoundedIconButton>
              </OrderingDragItem>
            ))}
          </OrderingDragContainer>
        ) : (
          <div>
            <p>No Volume Headers Added</p>
            <p>Use Add Header to copy volume headers from the previous volume or start from scratch</p>
          </div>
        )}
      </div>
      {showCopyHeadersModal && (
        <CopyHeadersModal
          volumeId={volumeId}
          onConfirm={handleCopyHeadersSelection}
          handleDismiss={() => setShowCopyHeadersModal(false)}
        />
      )}
      {showAddHeaderModal && (
        <EditTextModal
          isEdit={false}
          label="Header Name"
          value=""
          handleSubmit={addHeader}
          handleDismiss={() => setShowAddHeaderModal(false)}
        >
          {showCopyHeadersError && (
            <ErrorMessage>Sorry, there are no headers to copy from the previous volume</ErrorMessage>
          )}
        </EditTextModal>
      )}
      {removeModal.show && (
        <ConfirmationModal
          buttonActionText="Remove"
          buttonType="danger"
          title={'Remove Header'}
          prompt={<span>Are you sure you want to remove this volume header?</span>}
          subtext={removeModal.name}
          handleSubmit={() => removeHeader(removeModal.id)}
          handleDismiss={() => setRemoveModal({ show: false, name: '', id: '' })}
        />
      )}
    </div>
  );
};

VolumeHeadersList.propTypes = {
  headers: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default VolumeHeadersList;
