import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '../StyleGuide';
import { InlineList } from '../components/Layout';
import { TrainingPoster, TrainingInformation, Labels, numTrainingItemsLabelText } from '../components/TrainingElements';
import TrainingPosterPlaceholder from '../training/TrainingPosterPlaceholder';
import { MediumIconBadge } from '../components/Badge';
import TextTruncate from '../containers/TextTruncate';
import capitalizeFilter from '../filters/capitalize-filter';
import WhiteLabel from '../filters/WhiteLabel';
import trainingService from '../services/trainingService';
import useOrg from '../hooks/useOrg';

const TrainingListItemContainer = styled.div`
  margin-bottom: ${Grid._7};
  &:hover {
    cursor: pointer;
    h2 {
      text-decoration: underline;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const TrainingListItem = ({ training, redirectToTraining }) => {
  const org = useOrg();
  const isOrgOwned = !!org && org.id === training.ownerId;
  const trainingOwnerName = isOrgOwned ? org.name : 'Ministry Grid';

  return (
    <TrainingListItemContainer
      data-qa-hook="trainingItem"
      className="grid-container grid-col-4 grid-sm-col-8 grid-gap-16 grid-sm-gap-24"
      onClick={() => redirectToTraining(training.id)}
    >
      <div className="grid-col-span-4 grid-sm-col-span-3">
        {training.image !== undefined ? (
          <TrainingPoster src={trainingService.getTrainingImageUrl(training.image)} alt={training.name} />
        ) : (
          <TrainingPosterPlaceholder ownerName={isOrgOwned ? org.name : WhiteLabel.name} />
        )}
      </div>
      <div className="grid-col-span-4 grid-col-span-5">
        <TrainingInformation>
          <Labels>
            {training._type === 'Pathway' && (
              <MediumIconBadge className="icon-badge" style={{ marginLeft: '0' }}>
                <i className="fas fa-route"></i>
              </MediumIconBadge>
            )}
            {training._type === 'Course'
              ? capitalizeFilter(WhiteLabel.labels.course)
              : capitalizeFilter(WhiteLabel.labels.pathway)}
          </Labels>
          <h2 data-qa-hook="trainingName">{training.name}</h2>
          <InlineList content="•" fontSize="18px" style={{ marginBottom: '16px' }}>
            <li>{numTrainingItemsLabelText(training)}</li>
            {trainingOwnerName && <li data-qa-hook="searchPreviewTrainingOwner">{trainingOwnerName}</li>}
          </InlineList>
          {training.description && (
            <TextTruncate text={training.description} truncateLength={250} canShowMore={false} />
          )}
        </TrainingInformation>
      </div>
    </TrainingListItemContainer>
  );
};

TrainingListItem.propTypes = {
  training: PropTypes.object.isRequired,
  redirectToTraining: PropTypes.func.isRequired,
};

export default TrainingListItem;
