angular.module('lwNamb').controller('CreateOrgCtrl', [
  '$rootScope',
  '$scope',
  '$routeParams',
  'uuid4',
  '$modal',
  'alertService',
  'windowService',
  'userService',
  'orgService',
  '$log',
  'analyticsService',
  function(
    $rootScope,
    $scope,
    $routeParams,
    uuid4,
    $modal,
    alertService,
    windowService,
    userService,
    orgService,
    $log,
    analyticsService
  ) {
    var init = function() {
      $scope.error = false;
      $scope.fromLogin = $routeParams.fromLogin || false;
      userService.user().then(
        function(user) {
          $scope.user = user;
        },
        function() {
          $log.error('No user returned in CreateOrgCtrl');
        }
      );
    };

    $scope.openModal = function() {
      if ($scope.name !== undefined && $scope.name.length && $scope.name.length < 64) {
        var confirmationModal = $modal({
          scope: $scope,
          templateUrl: 'viewOrgName.html',
          placement: 'center',
          show: false,
        });
        confirmationModal.$promise.then(confirmationModal.show);
      }
    };

    $scope.createOrg = function(closeModal) {
      $scope.spinner = true;
      var orgId = uuid4.generateId();
      orgService
        .createOrgAccount(
          orgId.id,
          $scope.user.lastSelectedAccount,
          $scope.name,
          $scope.user.userId,
          analyticsService.trackFeature,
          true
        )
        .then(
          function() {
            analyticsService.orgCreated(
              orgId.id,
              $scope.name,
              $scope.user.userId,
              $scope.user.firstName,
              $scope.user.lastName,
              $scope.user.logInEmail
            );
            $rootScope.orgLogo = undefined;
            windowService.redirectHash('/org');
          },
          function(reason) {
            $log.error(reason);
            $scope.error = true;
          }
        )
        .finally(function() {
          closeModal();
          $scope.spinner = false;
        });
    };

    $scope.redirectAfterLogin = function() {
      windowService.redirectHash(userService.getRedirectHashAfterLogin($scope.user));
    };

    init();
  },
]);
