import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid, Color } from '../StyleGuide';
import { PrimaryButton, MediumDangerButton } from '../components/Buttons';
import ConfirmationModal from '../components/ConfirmationModal';
import {
  Label,
  FormFieldContainer,
  FormGroupContainer,
  FormFieldMaxCharCountStatus,
  FormFieldSet,
  Radio,
} from '../components/FormElements';
import useModal from '../hooks/useModal';
import windowService from '../services/windowService';
import alertService from '../services/AlertService';
import orgService from '../services/orgService';
import { handleError } from '../utils/apiUtils';
import useAngularScope from '../hooks/useAngularScope';

const HorizontalBar = styled.div`
  border-bottom: 2px solid ${Color.Blue._15};
  margin-bottom: ${Grid._6};
`;

function RemoveRoleModal({ role, ...props }) {
  return (
    <ConfirmationModal
      buttonActionText="Remove"
      buttonType="danger"
      title="Remove Role"
      prompt={<span>Are you sure you want to remove this Role?</span>}
      {...props}
    >
      <p>
        <Label>{role.name}</Label>
      </p>
      <p className="help-block">
        <i className="icon ion-android-alert"></i> Any existing members will be removed from this role
      </p>
      <p className="help-block">
        <i className="icon ion-android-alert"></i> Any active invitations using this role will be affected
      </p>
      <p className="help-block">
        <i className="icon ion-android-alert"></i> This action cannot be undone
      </p>
    </ConfirmationModal>
  );
}

RemoveRoleModal.propTypes = {
  role: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleDismiss: PropTypes.func,
};

function PermissionField({ permission, updateRolePermission, index }) {
  const { label, selected } = permission;
  const slug = `permission-${index}`;
  return (
    <FormFieldContainer data-qa-hook="roleSettingsPermission">
      <FormGroupContainer>
        <p>
          <Label data-qa-hook="roleSettingsPermissionName">{label}</Label>
        </p>
        <div className="controls">
          <Radio
            data-qa-hook="roleEnable"
            id={`${slug}-yes`}
            name={slug}
            onChange={() => updateRolePermission(permission, true)}
            checked={selected}
          />
          <label htmlFor="discussion-yes">Yes</label>
          <Radio
            data-qa-hook="roleDisable"
            id={`${slug}-no`}
            name={slug}
            onChange={() => updateRolePermission(permission, false)}
            checked={!selected}
          />
          <label htmlFor="discussion-no">No</label>
        </div>
      </FormGroupContainer>
    </FormFieldContainer>
  );
}

PermissionField.propTypes = {
  permission: PropTypes.object,
  updateRolePermission: PropTypes.func,
  index: PropTypes.number,
};

export default function RoleSettings({ user, orgId, roleId, role, permissions, setPermissions, setData }) {
  const [isSaving, setIsSaving] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [, updateAngularScope] = useAngularScope();

  function onDismiss() {
    dismissModal();
    setIsRemoving(false);
  }

  const [modal, openModal, dismissModal] = useModal((type, payload, dismissModal) => {
    switch (type) {
      case 'removeRole':
        return (
          <RemoveRoleModal
            role={payload.role}
            handleSubmit={() => removeRole(payload.role)}
            handleDismiss={onDismiss}
          />
        );
      default:
        return null;
    }
  });

  function saveName({ roleName }, { setFieldError }) {
    if (roleName !== role.name) {
      setIsSaving(true);

      orgService
        .updateRoleName(orgId, role.id, roleName, user.userId)
        .then(() => {
          updateAngularScope(scope => {
            scope.org.roles = scope.org.roles?.map(prevRole => {
              if (prevRole.id.id === role.id.id) {
                return { ...role, name: roleName };
              } else {
                return prevRole;
              }
            });
          });
        })
        .then(() => {
          setData(prevData => ({ ...prevData, role: { ...role, name: roleName } }));
          setIsSaving(false);
        })
        .catch(error => {
          setIsSaving(false);
          if (error?.code === 'RoleNameTaken') {
            handleError(error);
            setFieldError('roleName', 'Sorry, that role name is taken');
          } else {
            handleError(error);
          }
        });
    }
  }

  function removeRole(role) {
    orgService
      .removeRole(orgId, role.id, user.userId)
      .then(() => {
        updateAngularScope(scope => {
          scope.org.roles = scope.org.roles.filter(roleScope => roleScope.id.id !== role.id.id);
        });

        alertService.showOnNextPage('Role Removed');
        windowService.redirectTo('/roles');
      })
      .catch(error => {
        if (error?.code === 'NoAdminRemaining') {
          console.error(error);
          alertService.showError('Removing this role will leave no administrator in this organization.');
        } else {
          handleError(error);
        }
      })
      .finally(() => {
        setIsRemoving(false);
        dismissModal();
      });
  }

  function doesMatchPermission(permission, permissionToMatch) {
    return permission.label === permissionToMatch.label;
  }

  function updateSinglePermission(prev, permission, selected) {
    return prev.map(p => {
      if (doesMatchPermission(p, permission)) {
        return {
          ...p,
          selected,
        };
      }
      return p;
    });
  }

  function updateRolePermission(permission, selected) {
    setPermissions(prev => updateSinglePermission(prev, permission, selected));

    if (selected) {
      orgService
        .addPermission(orgId, roleId, permission.access, user.userId)
        .then(() => alertService.show('Permission updated'))
        .catch(() => {
          handleError();
          setPermissions(prev => updateSinglePermission(prev, permission, !selected));
        });
    } else {
      orgService
        .removePermission(orgId, roleId, permission.access, user.userId)
        .then(() => alertService.show('Permission updated'))
        .catch(error => {
          if (error?.code === 'NoAdminRemaining') {
            console.error(error);
            alertService.showError('Sorry! Removing this permission will leave no admin.');
          } else {
            handleError(error);
          }
          setPermissions(prev => updateSinglePermission(prev, permission, !selected));
        });
    }
  }

  function onRemove() {
    setIsRemoving(true);
    openModal('removeRole', { role });
  }

  return (
    <>
      <div className="grid-container grid-col-3 grid-sm-col-6 grid-gap-40 mt-10">
        <div className="grid-col-span-3">
          <Formik
            initialValues={{
              roleName: role.name,
            }}
            onSubmit={saveName}
            validationSchema={Yup.object({
              roleName: Yup.string().trim().required('Role name is required'),
            })}
          >
            {({ dirty, isValid }) => (
              <Form name="settingsForm" role="form" noValidate>
                <FormFieldMaxCharCountStatus
                  id="roleName"
                  name="roleName"
                  placeholder="Enter Name"
                  maxLength={64}
                  label="Name"
                />
                <FormFieldContainer>
                  <PrimaryButton
                    data-qa-hook="roleSettingsNameSave"
                    operating={isSaving}
                    disabled={isSaving || !dirty || !isValid}
                    type="submit"
                  >
                    Save
                  </PrimaryButton>
                </FormFieldContainer>
              </Form>
            )}
          </Formik>
          <HorizontalBar />
          <FormFieldContainer>
            <FormGroupContainer>
              <p>If this role is no longer needed, you can remove it. This action is not reversible.</p>
              <MediumDangerButton
                data-qa-hook="roleSettingsRemove"
                onClick={onRemove}
                operating={isRemoving}
                disabled={isRemoving}
              >
                Remove Role
              </MediumDangerButton>
            </FormGroupContainer>
          </FormFieldContainer>
        </div>
        <div className="grid-col-span-3">
          <FormFieldSet name={'Role Permissions'}>
            {permissions.map((permission, index) => (
              <PermissionField
                permission={permission}
                updateRolePermission={updateRolePermission}
                index={index}
                key={permission.label}
              />
            ))}
          </FormFieldSet>
          <div className="grid-container grid-col-1">
            <div className="grid-col-span-1">
              <p className="info-block">
                <i className="icon ion-android-alert"></i> All Roles can take Training
              </p>
            </div>
          </div>
        </div>
      </div>
      {modal}
    </>
  );
}

RoleSettings.propTypes = {
  user: PropTypes.object,
  role: PropTypes.object,
  orgId: PropTypes.string,
  roleId: PropTypes.string,
  permissions: PropTypes.array,
  setPermissions: PropTypes.func,
  setData: PropTypes.func,
};
