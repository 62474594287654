import React from 'react';
import { SearchResultItem } from '../models/Search';
import { useSearch } from '../hooks/useSearch';
import { SearchAccessDetails, SearchResultItemContainer, FreeBadge } from './SearchElements';
import ThumbnailIcon from '../../components/ThumbnailIcon';
import windowService from '../../services/windowService';
import limitFilter from '../../filters/limit-filter';

type Props = {
  result: SearchResultItem;
};

const TrainingTypeIconMap = {
  Workflow: 'fas fa-fw fa-route',
  Tasklist: 'fas fa-fw fa-tasks',
};

const isFree = (access: string) => access.toLowerCase() === 'free';

export const SearchResultListItem = ({ result }: Props) => {
  const { hasSubscription } = useSearch();

  return (
    <SearchResultItemContainer onClick={() => windowService.redirectTo(`/training/${result.id}`)}>
      <div>
        <ThumbnailIcon>
          <i className={TrainingTypeIconMap[result.trainingType] || ''} />
        </ThumbnailIcon>
      </div>

      <div>
        <h4>{result.name}</h4>

        <SearchAccessDetails>
          <p>{result.ownerName}</p>

          {!hasSubscription && isFree(result.searchAccess) && <FreeBadge>Free</FreeBadge>}
        </SearchAccessDetails>

        {result.description && <p>{limitFilter(result.description, 195)}</p>}
      </div>
    </SearchResultItemContainer>
  );
};
