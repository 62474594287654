import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Grid, Type } from '../StyleGuide';
import {
  Card,
  CardPoster,
  InlineList,
  SixteenNineAspectRatioPlaceholder,
  InlineDotLoadingAnimation,
} from '../components/Layout';
import { SmallTransparentRoundedIconButton } from '../components/Buttons';
import PropTypes from 'prop-types';
import VideoService from '../services/VideoService';

const Details = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${Grid._4};
  text-transform: capitalize;
  color: ${Type.Color.dark};
  h4 {
    margin: 0 0 ${Grid._4} 0;
  }
`;

const PosterPlaceholder = () => (
  <SixteenNineAspectRatioPlaceholder borderRadius="4px 4px 0 0">
    <InlineDotLoadingAnimation />
  </SixteenNineAspectRatioPlaceholder>
);
export default function BibleStudyVideoCard({ video, redirectToMaterial }) {
  const [poster, setPoster] = useState();

  useEffect(() => {
    VideoService.getPoster(video.mediaId)
      .then(response => setPoster(response))
      .catch(err => console.error(err));
  }, []);

  const downloadFile = e => {
    e.stopPropagation();
    const downloadUrl = `${getApiUrl()}/curriculum-storage/downloadvideo/${video.mediaId}`;
    window.open(downloadUrl);
  };

  return (
    <Card style={{ flexDirection: 'column' }} onClick={() => redirectToMaterial(video.materialId)}>
      {poster ? (
        <CardPoster src={poster}>
          <SmallTransparentRoundedIconButton onClick={e => downloadFile(e)}>
            <i className="fas fa-download fa-fw"></i>
          </SmallTransparentRoundedIconButton>
        </CardPoster>
      ) : (
        <PosterPlaceholder />
      )}
      <Details>
        <h4>{video.name}</h4>
        <InlineList content={'•'}>
          {video.series.map(c => (
            <li key={c.collectionId}>{c.name}</li>
          ))}
        </InlineList>
      </Details>
    </Card>
  );
}

BibleStudyVideoCard.propTypes = {
  video: PropTypes.object.isRequired,
  redirectToMaterial: PropTypes.func.isRequired,
};
