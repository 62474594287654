angular.module('lwNamb').directive('backButton', [
  '$window',
  function($window) {
    return {
      restrict: 'A',
      link: function(scope, element) {
        element.bind('click', function() {
          $window.history.back();
        });
      },
    };
  },
]);
