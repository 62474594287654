angular.module('lwNamb').directive('videoPlayer', [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'partials/directives/video-player.html',
      scope: {
        videoId: '@',
        preview: '@',
      },
      replace: true,
      controller: [
        '$scope',
        'videoService',
        'userService',
        'windowService',
        'purchaseService',
        'alertService',
        '$log',
        '$window',
        '$location',
        '$timeout',
        '$q',
        function(
          $scope,
          videoService,
          userService,
          windowService,
          purchaseService,
          alertService,
          $log,
          $window,
          $location,
          $timeout,
          $q
        ) {
          var d = new Date();
          $scope.uniqueVideoId = $scope.videoId + '-' + d.getTime();
          $scope.isLoading = true;
          $scope.isReady = false;
          $scope.isError = false;
          $scope.previewEnded = false;
          $scope.loggedIn = false;
          $scope.audioOnly = false;
          $scope.autoPlay = false;
          var lastSaved = 0;
          var currentTime = 0;

          userService.user().then(function(user) {
            $scope.userId = user.userId;
          });

          var getVideoUrl = $q.all([
            videoService.getUrls($scope.videoId),
            videoService.getProgress($scope.videoId),
            videoService.getPoster($scope.videoId),
          ]);

          getVideoUrl.then(
            function success(responses) {
              $scope.videoUrls = responses[0];
              $log.debug('urls set: ' + JSON.stringify(responses[0]));
              $scope.progress = responses[1];
              $scope.posterImage = responses[2];
              if ($scope.video === undefined || ($scope.video.poster && $scope.video.poster.indexOf('llnwd') > -1)) {
                $scope.videoPoster = $scope.posterImage;
              }
            },
            function failure() {
              $scope.isError = true;
            }
          );

          getVideoUrl.finally(function() {
            $scope.isLoading = false;
            $log.debug('isLoading: ' + $scope.isLoading);
            if ($scope.fallbackToggleTooEarly === true) {
              $timeout(function() {
                checkIsReady();
              }, 1000);
            }
          });

          videoService.getVideo($scope.videoId).then(function(response) {
            $scope.video = response.video;
            if ($scope.video.poster && $scope.video.poster.indexOf('llnwd') > -1) {
              $scope.videoPoster = $scope.posterImage;
            } else {
              $scope.videoPoster = $scope.video.poster;
            }
            if ($scope.video.deleted) {
              $scope.videoRemoved = true;
            }
            $log.debug('video: ' + JSON.stringify($scope.video));
          });

          $scope.$on('TimeUpdated', function(evtName, evt) {
            currentTime = Math.floor(evt.time);
            if ($scope.userId !== undefined) {
              if (currentTime !== 0 && currentTime % 15 === 0 && lastSaved !== currentTime) {
                lastSaved = currentTime;
                videoService.saveProgress($scope.userId, $scope.videoId, currentTime);
              }
            }
          });

          $scope.$on('VideoComplete', function(evtName, evt) {
            videoService.saveProgress($scope.userId, $scope.videoId, 0);
          });

          $scope.$on('VideoPosterUpdated', function(evtName, evt) {
            $scope.video.poster = evt.poster;
            $scope.$digest();
            $log.debug('poster: ' + JSON.stringify(evt.poster));
          });

          function checkIsReady() {
            if ($scope.isReady === false) {
              $scope.isReady = true;
              $log.debug('isReady from fallback: ' + $scope.isReady);
            }
          }

          $timeout(function() {
            if ($scope.isLoading === false) {
              checkIsReady();
            } else {
              $scope.fallbackToggleTooEarly = true;
            }
          }, 3000);

          $scope.$on('VideoDurationFound', function() {
            $log.debug('VideoDurationFound');
            $scope.isReady = true;
            $scope.$digest();
            $log.debug('isReady: ' + $scope.isReady);
          });

          $scope.$on('PreviewEnded', function() {
            $scope.previewEnded = true;
            userService.user().then(
              function() {
                $scope.loggedIn = true;
              },
              function() {
                $scope.loggedIn = false;
              }
            );
            $scope.$digest();
            $log.debug('previewEnded: ' + $scope.previewEnded);
          });

          $scope.purchase = function() {
            purchaseService.getShoppingUrl('').then(
              function(response) {
                windowService.redirect(response.data.url);
              },
              function(reason) {
                $log.error(reason);
                alertService.show({
                  title: 'Unexpected Error!',
                  content: 'We were unable to access shopping at this time.',
                  type: 'danger danger-with-content',
                  duration: 20,
                });
              }
            );
          };

          $scope.redirectToLifeWayLoginFromLoggedOutState = function() {
            function getQueryStringFromUrlForStoringInRoute() {
              return '?route=' + encodeURIComponent($location.url().substring(1));
            }
            userService
              .redirectToLoginV2($window.location.href + getQueryStringFromUrlForStoringInRoute())
              .then(function(result) {
                windowService.redirect(result);
              });
          };

          $scope.redirectTo = function(path) {
            windowService.redirectHash(path);
          };
        },
      ],
    };
  },
]);
