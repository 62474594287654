angular.module('lwNamb').controller(
  'TrainingCtrl',

  [
    '$rootScope',
    '$scope',
    'alertService',
    '$routeParams',
    '$localStorage',
    '$location',
    'userService',
    'orgService',
    'windowService',
    'purchaseService',
    'trainingService',
    'searchDetailService',
    'workflowService',
    '$window',
    '$log',
    'buildService',
    function (
      $rootScope,
      $scope,
      alertService,
      $routeParams,
      $localStorage,
      $location,
      userService,
      orgService,
      windowService,
      purchaseService,
      trainingService,
      searchDetailService,
      workflowService,
      $window,
      $log,
      buildService
    ) {
      var trainingId = $routeParams.id,
        isAlternatePreview = !!$location.search().alt;

      var init = function () {
        $scope.loading = true;
        $scope.isSelectTraining = $routeParams.isSelectTraining;
        $scope.isAlternatePreview = isAlternatePreview;
        $scope.searchQuery = $localStorage.searchQueryString;
        $scope.trainingId = trainingId;
        $scope.lockedCurriculum = false;
        $scope.canAccessWithoutSubscription = ['A21'].includes($scope.origin);

        userService.user().then(
          function (user) {
            $scope.user = user;
            $scope.hasAssignPermission =
              user.permissions.filter(function (p) {
                return p === 'Assign View';
              }).length > 0;
            $scope.hasBuildPermission =
              user.permissions.filter(function (p) {
                return p === 'Build View';
              }).length > 0;
            $scope.isGroupLeader =
              user.permissions.filter(function (p) {
                return p === 'Group Leader';
              }).length > 0;
            $scope.orgId = $scope.user.lastSelectedAccount;
            orgService.hasGridSubscription($scope.orgId).then(function (hasSub) {
              $scope.hasGridSubscription = hasSub;
            });
            orgService.hasIndividualGridSubscription($scope.user.userId).then(function (hasSub) {
              $scope.hasIndividualGridSubscription = hasSub;
            });
            orgService.hasLimitedGridSubscription($scope.user.userId).then(function (hasSub) {
              $scope.hasLimitedGridSubscription = hasSub;
            });
            if ($scope.lockedCurriculum) {
              orgService.hasPurchasedCurriculumSubscription($scope.orgId).then(function (hasSub) {
                if (hasSub) unlockCurriculumActions();
              });
            }
          },
          function () {
            $log.error('No user returned in TrainingCtrl');
          }
        );
        retrieveTrainingData();
      };

      var retrieveTrainingData = function () {
        if ($routeParams.trainingType && $routeParams.trainingType === 'Workflow') {
          workflowService
            .getWorkflowDetail(trainingId)
            .then(
              function (response) {
                $scope.ownerName = $scope.org.name;
                $scope.trainingType = $routeParams.trainingType;
                $scope.trainingSearchAccess = true;
                $scope.training = response;
              },
              function () {
                showGetTrainingFailureAlert();
                $log.error('Workflow not found!');
              }
            )
            .finally(function () {
              $scope.loading = false;
            });
        } else {
          searchDetailService
            .getTrainingDetail(trainingId)
            .then(
              function (response) {
                $scope.ownerName = response.ownerName;
                $scope.trainingType = response.trainingType;
                $scope.trainingSearchAccess = response.searchAccess;
                $scope.training = response.training;
                $scope.searchData = response;
                setCrumbs();

                if (!isLockedCurriculumItem(response.itemNumber)) unlockCurriculumActions();
              },
              function () {
                showGetTrainingFailureAlert();
                $log.error('Training access not found!');
              }
            )
            .finally(function () {
              $scope.loading = false;
            });
        }
      };

      var unlockCurriculumActions = function () {
        $scope.lockedCurriculum = false;
      };

      var isLockedCurriculumItem = function (itemNumber) {
        return itemNumber == orgService.getCurriculumLicenseItemNumber();
      };

      var setCrumbs = function () {
        if ($location.$$url.indexOf('catalog') >= 0) {
          $scope.crumbs = [{ name: 'Catalog', route: '#/catalog' }, { name: $scope.training.name }];
        } else if ($location.$$url.indexOf('training') >= 0) {
          var searchTerm = localStorage.getItem('trainingSearchTerm');
          var searchTermQuery = '';
          if (searchTerm) {
            searchTermQuery = '?query=' + searchTerm;
          }
          $scope.crumbs = [
            { name: 'Search Results', route: '#/search' + searchTermQuery },
            { name: $scope.training.name },
          ];
        }
      };

      $scope.addToMyTasks = function (training) {
        training.adding = true;
        var t = { trainingId: training.id, trainingType: $scope.trainingType };
        trainingService.selfAssign($scope.user.userId, t, $scope.orgId).then(
          function (instances) {
            localStorage.setItem('showBanner', 'Added To Your Dashboard');
            if (instances.length === 1) {
              var id = instances[0].instanceId;
              if ($scope.trainingType === 'Tasklist') {
                training.taskListInstanceId = id;
                training.adding = false;
                training.added = true;
                windowService.redirectHash('#/training-steps/' + training.taskListInstanceId);
              } else {
                workflowService.getWorkflowByInstanceId(id).then(function (workflow) {
                  var eiOption = workflow.entityInstances.find(function (ei) {
                    return ei._type === 'TaskList';
                  });
                  if (eiOption !== undefined) {
                    training.taskListInstanceId = eiOption._instanceId;
                    windowService.redirectHash('#/training-viewer/workflow/' + id);
                  }
                  training.adding = false;
                  training.added = true;
                  // Remove this after Workflows have been added to new Steps UI
                  alertService.show({ title: 'Added To Your Dashboard', type: 'success', duration: 500 });
                });
              }
            } else {
              training.adding = false;
              training.added = true;
              alertService.show({ title: 'Added To Your Dashboard', type: 'success', duration: 500 });
            }
          },
          function (reason) {
            $log.error(reason);
            var label = 'steps';
            alertService.show({
              title: 'Unexpected Error!',
              content: 'We were unable to add this training to your ' + label + ' at this time.',
              type: 'danger danger-with-content',
              duration: 20,
            });
            training.adding = false;
          }
        );
      };

      $scope.redirectToPrevious = function () {
        var isBuilderPreview = $scope.isSelectTraining === 'preview';
        if (isBuilderPreview && $scope.trainingType === 'Tasklist') {
          buildService.ctxRedirectTo('/courses/' + trainingId);
        } else if (isBuilderPreview && $scope.trainingType === 'Workflow') {
          buildService.ctxRedirectTo('/pathways/' + trainingId);
        } else {
          windowService.redirectHash($localStorage.selectTrainingOriginHash);
        }
      };

      $scope.clearResultsAndRedirect = function () {
        $localStorage.searchResults = undefined;
        $localStorage.searchQueryString = undefined;
        $localStorage.hitTotal = undefined;
        $localStorage.cachedFacets = undefined;
        windowService.redirectHash('#/search');
      };

      $scope.toggleSelect = function () {
        $rootScope.$broadcast('ToggleTrainingSelected', $scope.training);
        if ($localStorage.selectTrainingOriginHash) {
          windowService.redirectHash($localStorage.selectTrainingOriginHash);
        } else {
          $window.history.back();
        }
      };

      $scope.purchase = function (itemNumber) {
        var item = itemNumber ? itemNumber.value : '';
        purchaseService.getShoppingUrl(item).then(
          function (response) {
            if (item) purchaseService.addPendingPurchase(item);
            windowService.redirect(response.data.url);
          },
          function (reason) {
            $log.error(reason);
            alertService.show({
              title: 'Unexpected Error!',
              content: 'We were unable to access shopping at this time.',
              type: 'danger danger-with-content',
              duration: 20,
            });
          }
        );
      };

      $scope.purchaseCurriculum = function () {
        $window.location.href = 'http://ministrygrid.com/curriculum';
      };

      function showGetTrainingFailureAlert() {
        alertService.show({
          title: 'Unexpected Error!',
          content: 'We were unable to retrieve training at this time.',
          type: 'danger danger-with-content',
          duration: 20,
        });
      }

      $scope.redirectToTask = function (taskIndex) {
        var select = $routeParams.isSelectTraining ? '/' + $routeParams.isSelectTraining : '',
          query = isAlternatePreview ? '?alt=1' : '';
        $location.url('/training-task/' + trainingId + '/' + taskIndex + select + query);
      };

      init();
    },
  ] //End Search Preview Ctrl
);
