angular.module('lwNamb').controller(
  'AssessPublicCtrl',

  [
    '$scope',
    '$rootScope',
    '$log',
    'assessBuilderService',
    'assessTakerService',
    'taskListService',
    'taskService',
    'taskListInstanceService',
    'windowService',
    '$routeParams',
    'uuid4',
    'blockUX',
    'alertService',
    function(
      $scope,
      $rootScope,
      $log,
      assessBuilderService,
      assessTakerService,
      taskListService,
      taskService,
      taskListInstanceService,
      windowService,
      $routeParams,
      uuid4,
      blockUX,
      alertService
    ) {
      var initiatingUserId = '';
      $scope.loggedIn = $routeParams.loggedIn === 'true';
      $scope.taskListInstanceId = $routeParams.taskListInstanceId; //could be undefined due to old route, do not user for anything other than the back button

      var orgId = $routeParams.orgId == 'undefined' ? undefined : $routeParams.orgId;

      var init = function() {
        $scope.spinner = false;
        $scope.loading = true;
        $scope.taskLoading = true;
        $scope.fromTaker = $routeParams.fromTaker ? $routeParams.fromTaker : false;
        if ($routeParams.workflowId) {
          $scope.trainingType = 'workflow';
          $scope.trainingInstanceId = $routeParams.workflowId;
        } else {
          $scope.trainingType = 'course';
          $scope.trainingInstanceId = $routeParams.taskListInstanceId;
        }
        //Set Task List id
        $scope.tlId = '';

        $rootScope.publicUserEmail = $routeParams.email;
        initiatingUserId = $routeParams.userId;

        if ($routeParams.loggedIn === 'false') {
          initiatingUserId = $routeParams.email;
          $rootScope.$broadcast('CreateSocketForEmail', $routeParams.email);
        }

        //Set Assessment
        assessBuilderService.getAssessment($routeParams.id, $routeParams.userId).then(
          function(response) {
            $scope.assessment = response;
            retrieveUserData(
              $routeParams.id,
              $routeParams.userId,
              orgId,
              $routeParams.taskInstanceId,
              $routeParams.email
            );
          },
          function(reason) {
            $log.error(reason);
          }
        );
      };

      var retrieveUserData = function(assessmentId, userId, orgId, taskInstanceId, email) {
        if ($scope.loggedIn) {
          taskListInstanceService
            .getByInstanceId($routeParams.taskListInstanceId)
            .then(
              function(taskList) {
                $scope.taskList = taskList;
                $scope.tlId = taskList.id;
                for (var i = 0; i < taskList.tasks.length; i++) {
                  if (taskList.tasks[i].id.id === $routeParams.taskInstanceId) {
                    $scope.task = taskList.tasks[i];
                    break;
                  }
                }
              },
              function(reason) {
                $log.error(reason);
              }
            )
            .finally(function() {
              $scope.loading = false;
              $scope.taskLoading = false;
              setUpAssessmentState(assessmentId);
            });
        } else {
          taskListInstanceService
            .getTaskInstance(taskInstanceId, userId, orgId)
            .then(
              function(data) {
                $scope.tlId = data.taskListInstanceId;
                $scope.task = data.taskInstance;
                if($scope.task.task._type === 'Feedback') {
                  windowService.redirectHash("/feedback-assessment-taker/" + $scope.tlId + "/" + $routeParams.taskInstanceId + "/" + $routeParams.email);
                }
              },
              function(reason) {
                $log.error(reason);
              }
            )
            .finally(function() {
              $scope.loading = false;
              $scope.taskLoading = false;
              setUpAssessmentState(assessmentId);
            });
        }
      };

      function setUpAssessmentState(assessmentId) {
        if ($routeParams.loggedIn === 'true') {
          $scope.publicTaskStarted = $scope.task.status;
          if ($scope.task.task.responseId === '') {
            $scope.assessResponseId = uuid4.generateId().id;
            $scope.task.task.responseId = $scope.assessResponseId;
          } else {
            $scope.assessResponseId = $scope.task.task.responseId;
          }
        } else {
          $scope.assessResponseId = getAssessResponseIdForFeedbackUser($scope.task, $routeParams.email);
        }

        if ($scope.assessResponseId === undefined) {
          acceptFailure();
        }
        //Get Assessment Response id if Task List status is in_progress or complete
        assessTakerService.getAssessmentResponse(initiatingUserId, assessmentId, $scope.assessResponseId).then(
          function(response) {
            $scope.assessmentResponse = response;
          },
          function(reason) {
            $log.debug(reason);
          }
        );
      }

      function getAssessResponseIdForFeedbackUser(task, email) {
        for (var i = 0; i < task.task.feedbackResponses.length; i++) {
          if (task.task.feedbackResponses[i].email === email) {
            $scope.publicTaskStarted = task.task.feedbackResponses[i].status;
            if (
              task.task.feedbackResponses[i].responseId === undefined ||
              task.task.feedbackResponses[i].responseId === ''
            ) {
              var id = uuid4.generateId().id;
              task.task.feedbackResponses[i].responseId = id;
              return id;
            } else {
              return task.task.feedbackResponses[i].responseId;
            }
          }
        }
      }

      var redirectToViewer = function() {
        var assessmentId = '',
          assessResponseId = '';
        if ($scope.task.task._type === 'Assessment' || $scope.task.task._type === 'PrepareEnrich') {
          assessmentId = $scope.task.task.assessmentId;
          assessResponseId = $scope.task.task.responseId;
        } else {
          // if($scope.task.task._type === "Feedback"){
          assessResponseId = getAssessResponseIdForFeedbackUser($scope.task, $routeParams.email);
          assessmentId = $scope.task.task.relatedTask.task.assessmentId;
        }
        assessTakerService.getAssessmentResponse(initiatingUserId, assessmentId, assessResponseId).then(
          function(response) {
            if (Object.keys(response).length !== 0 && response._id.id === assessResponseId) {
              var taskInstanceId = $scope.task.id.id;
              taskListInstanceService.getTaskById($scope.tlId, taskInstanceId).then(
                function(task) {
                  if (task.task.responseId === $scope.task.task.responseId) {
                    blockUX.stop();
                    if ($scope.task.task._type === 'PrepareEnrich') {
                      windowService.redirectHash(
                        '/prepare-enrich-taker/' +
                          $routeParams.id +
                          '/' +
                          $routeParams.userId +
                          '/' +
                          $routeParams.orgId +
                          '/' +
                          $routeParams.taskInstanceId +
                          '/' +
                          $routeParams.email +
                          '/' +
                          $routeParams.loggedIn
                      );
                    } else {
                      var path =
                        '/assessment-taker-publicTraining/' +
                        $routeParams.id +
                        '/' +
                        $routeParams.userId +
                        '/' +
                        $routeParams.orgId +
                        '/' +
                        $routeParams.taskListInstanceId +
                        '/' +
                        $routeParams.taskInstanceId +
                        '/' +
                        $routeParams.email +
                        '/' +
                        $routeParams.loggedIn;
                      if ($scope.trainingType === 'workflow') {
                        path = path + '/' + $scope.trainingInstanceId;
                      }
                      windowService.redirectHash(path);
                    }
                  } else {
                    $scope.startAssessment();
                  }
                },
                function(reason) {
                  $log.debug(reason);
                }
              );
            } else {
              $scope.startAssessment();
            }
          },
          function(reason) {
            $log.debug(reason);
          }
        );
      };

      //Start Assessment
      $scope.startAssessment = function() {
        blockUX.start('Setting up your Assessment...', 0, 30000, false, 'Error! Please try again.', null);
        $scope.spinner = true;
        if ($routeParams.loggedIn === 'true') {
          taskService.startTask($scope.tlId, $scope.task, $routeParams.userId, initiatingUserId).then(
            function() {
              redirectToViewer();
            },
            function(reason) {
              $log.error(reason);
              blockUX.stop();
              $scope.spinner = false;
              alertService.show({
                title: 'Unexpected Error!',
                content: 'Please try again.',
                type: 'danger danger-with-content',
                duration: 20,
              });
            }
          );
        } else {
          taskService
            .startFeedbackTask(
              $scope.tlId,
              $scope.task,
              $routeParams.userId,
              $routeParams.email,
              $scope.assessResponseId,
              initiatingUserId
            )
            .then(
              function() {
                redirectToViewer();
              },
              function(reason) {
                $log.error(reason);
                blockUX.stop();
                $scope.spinner = false;
                alertService.show({
                  title: 'Unexpected Error!',
                  content: 'Please try again.',
                  type: 'danger danger-with-content',
                  duration: 20,
                });
              }
            );
        }
      };

      $scope.redirectToTaskViewer = function() {
        if ($routeParams.workflowId) {
          windowService.redirectHash(
            'task-viewer/' + $scope.tlId + '/' + $routeParams.taskInstanceId + '/' + $routeParams.workflowId
          );
        } else {
          windowService.redirectHash('task-viewer/' + $scope.tlId + '/' + $routeParams.taskInstanceId);
        }
      };

      $scope.resumeAssessment = function() {
        $scope.spinner = true;
        redirectToViewer();
      };

      $scope.completeDeletedAssessment = function() {
        $scope.spinner = true;
        taskListService
          .completeTask($routeParams.taskListInstanceId, $scope.task.assigneeId.id, $scope.task, true)
          .then(
            function() {
              $scope.redirectToTaskViewer();
            },
            function(reason) {
              $log.error(reason);
              alertService.show({
                title: 'Unexpected Error!',
                content: 'Please try again.',
                type: 'danger danger-with-content',
                duration: 20,
              });
            }
          )
          .finally(function() {
            $scope.spinner = false;
          });
      };

      var acceptFailure = function() {
        alertService.show({
          title: 'Something is wrong with your invitation',
          content: 'Please contact the person who sent it to you.',
          type: 'danger danger-with-content',
          duration: 20,
        });
      };

      init();
    },
  ] //End Assess Ctrl
);
