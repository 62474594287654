import React from 'react';
import emptyStateData from './emptyStateData';

type EmptyStateProps = {
  noResults?: boolean;
  isError?: string;
  defaultMsg?: string;
  everyone?: boolean;
};

const EmptyState: React.FC<EmptyStateProps> = ({ noResults, isError, defaultMsg, everyone }) => {
  let key = 'no-people';

  if (noResults) {
    key = 'no-results';
  } else if (isError) {
    key = isError;
  } else if (everyone) {
    key = 'everyone';
  } else if (defaultMsg) {
    key = defaultMsg;
  } else {
    key = 'no-people';
  }

  return (
    <div data-qa-hook="emptyStateUI" className="empty-state-ui">
      <span className={'fas ' + emptyStateData[key as keyof typeof emptyStateData].icon}></span>
      <h4>{emptyStateData[key as keyof typeof emptyStateData].title}</h4>
      <div dangerouslySetInnerHTML={{ __html: emptyStateData[key as keyof typeof emptyStateData].content }}></div>
    </div>
  );
};

export default EmptyState;
