import React from 'react';
import { InvitationType } from '../models/invite';

export const EmailIconString = 'fas fa-paper-plane';
export const EasyLinkIconString = 'fas fa-link';

const InviteTypeToIconMap: Record<InvitationType, string> = {
  email: EmailIconString,
  code: EasyLinkIconString,
};

type Props = {
  invitationType: InvitationType;
  additionalClasses?: string;
};

export const InviteIcon = ({ invitationType, additionalClasses = '' }: Props) => {
  return <i className={`${InviteTypeToIconMap[invitationType]} ${additionalClasses}`} />;
};
