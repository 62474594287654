import styled from 'styled-components';
import { Grid, Color, Type } from '../StyleGuide';

const StatusBanner = styled.div`
  text-align: center;
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.semibold};
  text-transform: uppercase;
  padding: ${Grid._4};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 60px;
  z-index: 10;
`;

export const SuccessBanner = styled(StatusBanner)`
  color: ${Color.Primary._70};
  background: ${Color.Primary._05};
`;

export const InfoBanner = styled(StatusBanner)`
  color: ${Color.Blue._60};
  background: ${Color.Secondary._20};
`;
