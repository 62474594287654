import React from 'react';
import { useParams } from 'react-router-dom';

const useManageTrainingRoute = () => {
  const { buildType: manageTrainingType, trainingType } = useParams();
  const manageTrainingRoutePrefix = `/manage-training/${manageTrainingType}`;

  const createManageTrainingCrumb = (trainingType, addRoute = true) => ({
    name: `${manageTrainingType === 'author' ? 'Author' : 'Manage'} Training`,
    route: addRoute ? `#${manageTrainingRoutePrefix}/${trainingType}` : undefined,
  });

  return { manageTrainingType, trainingType, manageTrainingRoutePrefix, createManageTrainingCrumb };
};

export default useManageTrainingRoute;
