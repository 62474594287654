import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../../StyleGuide';

const AnswersList = styled.ul`
  margin: ${Grid._6} auto;
  padding: 0;
  li {
    list-style: none;
    display: flex;
    border-top: 1px solid ${Color.Gray._30};
    margin: 0;
    color: ${Type.Color.medium};
    font-size: ${Type.Scale._3};
    &:last-child {
      border-bottom: 1px solid ${Color.Gray._30};
    }
    &.isWrong > .choice-text {
      color: #fff;
      background: ${Color.Red._50};
    }
    &.isCorrect > .choice-text {
      color: #fff;
      background: ${Color.Primary._50};
    }
    .choice-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: ${Color.Secondary._80};
      min-width: 60px;
      i {
        font-size: 26px;
      }
    }
    .choice-text {
      display: flex;
      flex: 1;
      align-items: stretch;
      padding: 16px;
    }
  }
`;

const Answers = ({ isGraded, answers }) => (
  <AnswersList>
    {answers
      .sort((a, b) => a.index - b.index)
      .map(c => {
        const answerStatus = classNames({
          isCorrect: isGraded && c.wasChosen && c.isCorrect,
          isWrong: isGraded && c.wasChosen && !c.isCorrect,
        });
        const choiceIcon = classNames({
          'icon ion-ios-circle-outline': (!isGraded && !c.wasChosen) || (isGraded && !c.wasChosen && !c.isCorrect),
          'ion-ios-checkmark': (!isGraded && c.wasChosen) || (isGraded && c.isCorrect),
          'ion-ios-close': isGraded && c.wasChosen && !c.isCorrect,
        });
        return (
          <li key={c.possibleAnswerId.id} className={answerStatus}>
            <span className="choice-icon">
              <i className={choiceIcon} />
            </span>
            <span className="choice-text">{c.value}</span>
          </li>
        );
      })}
  </AnswersList>
);

Answers.defaultProps = {
  isGraded: false,
};

Answers.propTypes = {
  isGraded: PropTypes.bool.isRequired,
  answers: PropTypes.array.isRequired,
};

export default Answers;
