import React, { useState } from 'react';
import styled from 'styled-components';
import { Type } from '../StyleGuide';
import { TrainingItem } from './CompilationElements';
import { FormFieldContainer, Label } from '../components/FormElements';
import { Buttons, Button, SmallRoundedIconButton } from '../components/Buttons';
import ErrorMessage from '../components/ErrorMessage';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ConfirmationModal from '../components/ConfirmationModal';
import windowService from '../services/windowService';

const Description = styled.p`
  font-size: ${Type.Scale._2};
  color: ${Type.Color.medium};
`;

const getStoredAddedTraining = () => JSON.parse(localStorage.getItem('buttonTrainingItem')) || [];
const clearStoredAddedTraining = () => localStorage.removeItem('buttonTrainingItem');

const getByTrainingType = (type, ifPathway, ifCompilation, ifCourse) => {
  const lowerType = type.toLowerCase();
  if (lowerType.indexOf('workflow') >= 0 || lowerType.indexOf('pathway') >= 0) return ifPathway;
  if (lowerType.indexOf('compilation') >= 0) return ifCompilation;
  return ifCourse;
};

export default function ManageTrainingButtonTrainingForm({ button, onSubmit, children }) {
  const [removeModal, setRemoveModal] = useState({ show: false, name: '', id: '' });
  const [isTrainingRemoved, setIsTrainingRemoved] = useState(false);
  const [form, setForm] = useState(() => {
    const formState = { id: '', trainingItem: {} };

    if (button) {
      const idKey = Object.keys(button.link)[0];

      formState.id = button.id;
      formState.trainingItem = {
        id: button.link[idKey],
        name: button.text,
        trainingType: getByTrainingType(idKey, 'Pathway', 'Compilation', 'Course'),
      };
    }

    const addedTraining = getStoredAddedTraining()[0];
    if (addedTraining)
      formState.trainingItem = {
        ...addedTraining,
        trainingType: getByTrainingType(addedTraining.trainingType, 'Pathway', 'Compilation', 'Course'),
      };
    clearStoredAddedTraining();

    return formState;
  });

  const updateFormData = newValues => setForm(form => ({ ...form, ...newValues }));

  const submitForm = () => {
    const id = form.trainingItem.id;
    onSubmit(
      form.trainingItem.name,
      getByTrainingType(form.trainingItem.trainingType, { pathwayId: id }, { compilationId: id }, { courseId: id })
    );
  };

  const redirectToAddCompilation = () =>
    windowService.redirectTo('#/manage-catalog/add-compilation-to-training-button');
  const redirectToAddTraining = () => windowService.redirectTo(`${window.location.hash}/add-training`);

  return (
    <>
      <Description>This training button will link to the selected training.</Description>
      <Formik
        enableReinitialize
        initialValues={form}
        validationSchema={Yup.object({
          trainingItem: Yup.object().shape({
            id: Yup.string().required(),
            name: Yup.string(),
            trainingType: Yup.string(),
          }),
        })}
        onSubmit={submitForm}
      >
        {({ errors }) => (
          <Form>
            <div className="grid-container grid-col-4 grid-sm-col-8 grid-gap-40" style={{ margin: '32px 0' }}>
              <div className="grid-col-span-4">
                <FormFieldContainer>
                  <Label>Training Item</Label>
                  {Object.keys(form.trainingItem).length > 0 ? (
                    <TrainingItem>
                      <div>
                        <h4 data-qa-hook="trainingName">{form.trainingItem.name}</h4>
                        <p data-qa-hook="trainingType">{form.trainingItem.trainingType}</p>
                      </div>
                      <SmallRoundedIconButton
                        data-qa-hook="trainingRemove"
                        title="Remove Training"
                        onClick={() =>
                          setRemoveModal({ show: true, name: form.trainingItem.name, id: form.trainingItem.id })
                        }
                      >
                        <i className="far fa-trash-alt"></i>
                      </SmallRoundedIconButton>
                    </TrainingItem>
                  ) : (
                    <Buttons style={{ margin: '0' }}>
                      <Button data-qa-hook="selectCompilation" onClick={redirectToAddCompilation}>
                        Select Compilation
                      </Button>
                      <Button data-qa-hook="selectCourseOrPathway" onClick={redirectToAddTraining}>
                        Select Course or Pathway
                      </Button>
                    </Buttons>
                  )}
                  {errors.trainingItem && errors.trainingItem.id && (
                    <ErrorMessage>A training item is required</ErrorMessage>
                  )}
                  {isTrainingRemoved && (
                    <ErrorMessage>
                      The training attached to this item has been removed. Please choose a new training item.
                    </ErrorMessage>
                  )}
                </FormFieldContainer>
                {children}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {removeModal.show && (
        <ConfirmationModal
          buttonActionText="Remove"
          buttonType="danger"
          title="Remove Training"
          prompt={<span>Are you sure you want to remove this training?</span>}
          subtext={removeModal.name}
          handleSubmit={() => {
            updateFormData({ trainingItem: '' });
            setRemoveModal({ show: false, name: '', id: '' });
          }}
          handleDismiss={() => setRemoveModal({ show: false, name: '', id: '' })}
        />
      )}
    </>
  );
}
