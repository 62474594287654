import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Breakpoints, Color, Grid, Type } from '../StyleGuide';
import { useUser } from '../authentication';
import { SessionBadge } from '../components/Badge';
import { OutlineButton, TileButton } from '../components/Buttons';
import CalendarDateIcon from '../components/CalendarDateIcon';
import ErrorMessage from '../components/ErrorMessage';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import { Container, FullWidthCard, Image, InlineList, SubHeaderWithButton } from '../components/Layout';
import { ListItemFlexRow } from '../components/ListItemFlexRow';
import LoadingState from '../components/LoadingState';
import PopOver from '../components/PopOver';
import { PreviewRestrictionModal } from '../components/PreviewRestrictionModal';
import { monthAbbrFilter } from '../filters/date-filters';
import useCurriculum from '../hooks/useCurriculum';
import useModal from '../hooks/useModal';
import curriculumService from '../services/curriculumService';
import windowService from '../services/windowService';
import BibleStudyNavigation from './BibleStudyNavigation';
import RescheduleSessionModal from './RescheduleSessionModal';
import LeaderTimelinesOverlay from './timeline/LeaderTimelinesOverlay';

const VolumeHeader = styled.div`
  padding: ${Grid._3} ${Grid._4};
  margin: 0 -${Grid._4} 0 -${Grid._4};
  color: ${Type.Color.dark};
  border-top: 1px solid ${Color.Blue._15};
  border-bottom: 1px solid ${Color.Blue._15};
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._3};
    margin: 0;
  }
  h4 {
    margin: 0;
    font-size: ${Type.Scale._3};
    font-weight: ${Type.Weight.semibold};
  }
`;

const EmptyVolumeSeparator = styled.div`
  border-top: 1px solid ${Color.Blue._15};
`;

const Sessions = styled.ul`
  list-style: none;
  padding: 0 0 ${Grid._4};
`;

const Date = styled.div`
  width: 102px;
`;

const Details = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${Grid._4} 0;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: 0;
  }
  h2,
  h3 {
    margin-top: 0;
    font-weight: ${Type.Weight.semibold};
    color: ${Type.Color.dark};
  }
  h2 {
    font-size: ${Type.Scale._5};
  }
  p {
    color: ${Type.Color.medium};
    margin-bottom: 10px;
  }
`;

const MoreUpcomingContainer = styled.div`
  padding-bottom: ${Grid._6};

  > div {
    margin: 0;
  }
`;

const FullWidthCardContainer = styled(FullWidthCard)`
  padding-top: ${Grid._5};
`;

const VolumeWrapper = ({ volume, index, children }) => (
  <>
    {volume && (
      <VolumeHeader>
        <h4>
          {volume.number && `Volume ${volume.number} • `}
          {volume.name}
        </h4>
      </VolumeHeader>
    )}
    {!volume && index > 0 && <EmptyVolumeSeparator />}
    <div className="grid-container grid-sm-col-8 grid-gap-24" style={{ margin: '16px auto' }}>
      {volume && (
        <div className="grid-sm-col-span-1 hidden-xs">
          <Image src={volume.imageUrl} alt={volume.name} />
        </div>
      )}
      <div className={volume ? 'grid-sm-col-span-7' : 'grid-sm-col-span-8'}>{children}</div>
    </div>
  </>
);

export default function ManageBibleStudySchedule() {
  const user = useUser();
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId } = useParams();
  const [data, setData] = useState({ isLoading: true });

  const crumbs = [{ name: 'Curriculum', route: '#/bible-studies' }, { name: `${brand.name}: ${ageCategory}` }];

  useEffect(() => {
    getSessions();
  }, []);

  const getVolume = (volumes, volumeId) => volumes.find(v => v._id === volumeId);

  const haveSameVolume = (session1, session2) => session1 && session2 && session1.volumeId === session2.volumeId;

  const buildSections = (schedule, sections = []) => {
    const { sessions, volumes } = schedule;

    const displaySessions = sessions.filter(s => !brand.timelineEnabled || s.timelines?.length > 0);

    displaySessions.forEach((session, index) => {
      if (index > 0 && haveSameVolume(displaySessions[index - 1], session)) {
        sections[sections.length - 1].sessions.push(session);
      } else {
        sections.push({ volume: getVolume(volumes, session.volumeId), sessions: [session] });
      }
    });

    return sections;
  };

  const getSessions = () => {
    setData({ isLoading: true });
    Promise.all([
      curriculumService.getIndividualPricing(brand.code, curriculumId),
      curriculumService.getCurriculumSchedule(curriculumId),
    ])
      .then(([curriculum, schedule]) => {
        setData({
          curriculum,
          schedule,
          sections: buildSections(schedule),
          isLoading: false,
        });
      })
      .catch(reason => {
        setData({ isLoading: false, isError: true });
        console.error(reason);
      });
  };

  const updateSessionTimelines = (sessionId, timelines) => {
    setData(prev => {
      const updatedSessions = prev.schedule.sessions.map(session => {
        if (session.sessionId !== sessionId) return session;

        return {
          ...session,
          timelines,
        };
      });

      const updatedSchedule = {
        ...prev.schedule,
        sessions: updatedSessions,
      };

      return {
        ...prev,
        schedule: updatedSchedule,
        sections: buildSections(updatedSchedule),
      };
    });
  };

  const handleSessionClick = session => {
    if (brand.timelineEnabled) return openModal('timelines', { session });

    windowService.redirectTo(
      `/manage-bible-study/schedule/${brand.code}/${ageCategory}/${curriculumId}/${session.sessionId}`
    );
  };

  const showModal = (e, session) => {
    e.stopPropagation();
    openModal('reschedule', { session });
  };

  const [modal, openModal] = useModal((type, payload, dismissModal) => {
    if (type === 'reschedule') {
      if (brand.previewOptions?.restrictions.reschedule)
        return <PreviewRestrictionModal handleDismiss={dismissModal} />;
      return (
        <RescheduleSessionModal
          orgId={user.lastSelectedAccount}
          curriculumId={curriculumId}
          initiatingUserId={user.userId}
          session={payload.session}
          handleDismiss={dismissModal}
          getSessions={getSessions}
          firstAllowedDate={brand.rescheduleStartDate}
        />
      );
    }

    if (type === 'timelines') {
      const session = data.schedule.sessions.find(s => s.sessionId === payload.session.sessionId);

      return (
        <LeaderTimelinesOverlay
          session={session}
          updateSessionTimelines={updateSessionTimelines}
          handleDismiss={dismissModal}
        />
      );
    }

    return null;
  });

  return (
    <>
      <BibleStudyNavigation
        isLoading={data.isLoading}
        crumbs={crumbs}
        curriculumId={curriculumId}
        activeTab="schedule"
      ></BibleStudyNavigation>
      <FullWidthCardContainer>
        <Container data-qa-hook="scheduleViewContainer">
          {data.isLoading ? (
            <LoadingState />
          ) : data.isError ? (
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          ) : (
            <>
              <SubHeaderWithButton style={{ margin: '24px 0px' }}>
                <h4>All Sessions</h4>
                {data.curriculum.offCycleEnabled && (
                  <PopOver
                    title="Are you using a previous volume?"
                    description="Now you can reset your schedule to start with a volume in the past."
                    buttonText="Got it, Thanks"
                    storageKey="reset-schedule"
                  >
                    <OutlineButton
                      onClick={() =>
                        windowService.redirectTo(
                          `#/manage-bible-study/reset-schedule/${brand.code}/${ageCategory}/${curriculumId}`
                        )
                      }
                    >
                      <i className="fas fa-cog"></i> Reset Schedule
                    </OutlineButton>
                  </PopOver>
                )}
              </SubHeaderWithButton>
              {data.sections.map(({ volume, sessions }, index) => (
                <VolumeWrapper volume={volume} index={index} key={index}>
                  <Sessions data-qa-hook="sessionsContainer">
                    {sessions.map(session => {
                      return (
                        <ListItemFlexRow
                          key={session.sessionId}
                          id={session.sessionId}
                          onClick={() => handleSessionClick(session)}
                        >
                          <Date>
                            {session.isScheduled && (
                              <CalendarDateIcon
                                month={monthAbbrFilter(session.scheduledDate.getMonth())}
                                day={session.scheduledDate.getDate().toString()}
                                primary={session.isThisWeek}
                                large={session.isThisWeek}
                              />
                            )}
                          </Date>
                          <Details>
                            <SessionBadge session={session} />

                            {session.isScheduled && session.isThisWeek ? (
                              <h2 data-qa-hook="sessionName">{session.name}</h2>
                            ) : (
                              <h3 data-qa-hook="sessionName">{session.name}</h3>
                            )}
                            <InlineList content={'•'}>
                              <li data-qa-hook="unitName">{session.issueName}</li>
                              <li data-qa-hook="sessionNumber">Session {session.sessionNumber}</li>
                              <li data-qa-hook="availableThrough">{session.getAvailableThroughMessage('MMMM d')}</li>
                            </InlineList>
                          </Details>
                          <TileButton data-qa-hook="rescheduleButton" onClick={e => showModal(e, session)}>
                            <i className="far fa-calendar-alt"></i> Reschedule
                          </TileButton>
                        </ListItemFlexRow>
                      );
                    })}
                  </Sessions>
                </VolumeWrapper>
              ))}
              {!brand.hideUpcomingSessionsMessage && (
                <MoreUpcomingContainer>
                  <HelpAndInfoBox
                    title="Don't worry, more sessions are on the way"
                    description="We'll add more as they get closer."
                  />
                </MoreUpcomingContainer>
              )}
            </>
          )}
        </Container>
      </FullWidthCardContainer>
      {modal}
    </>
  );
}
