angular.module('lwNamb').factory(
  'Interceptor',

  [
    '$rootScope',
    '$q',
    'jwt',
    '$window',
    function($rootScope, $q, jwt, $window) {
      var failureThreshold = 0,
        networkFailure = false;

      return {
        // Appending the token by reading it from the storage (localStorage by default)
        request: function(config) {
          var token = jwt.get();
          if (config.url.indexOf('amazonaws') > -1 || config.url.indexOf('slack') > -1) {
            config.headers.Authorization = undefined;
          } else if (token) {
            config.headers.Authorization = token;
          } else {
            config.headers.Authorization = undefined;
          }
          return config;
        },

        response: function (response) {
          if (networkFailure) networkFailure = false;
          if (failureThreshold > 0) failureThreshold -= 1;

          return response;
        },

        // Sending events for notification if we detect an authentication problem
        responseError: function(rejection) {
          if (rejection.status === 401 || rejection.status === 403) {
            jwt.forget();
            $rootScope.$broadcast('unauthorized');
          } else if (rejection.status === -1 && !networkFailure && $window.location.hash !== '#/503') {

            if (failureThreshold > 10) {
              failureThreshold = 0;
              
              // Ping external API to check Network Connectivity
              $window.fetch('https://api.github.com/status')
                .then(function() {
                  // External API responded. Redirecting to 503 page.
                  $rootScope.$broadcast('503');
                })
                .catch(function() {
                  // External API did not respond.
                  networkFailure = true;
                });
              
            } else {
              failureThreshold += 1;
            }

          }

          return $q.reject(rejection);
        },
        // These methods are only for Unit Tests.
        setNetworkFailure: function(val) {
          networkFailure = val;
          return networkFailure;
        },
        getNetworkFailure: function() {
          return networkFailure;
        },
        setFailureThreshold: function(val) {
          failureThreshold = val;
          return failureThreshold;
        },
        getFailureThreshold: function() {
          return failureThreshold;
        },
      };
    },
  ]
);
