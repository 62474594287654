import React, { useState, useEffect } from 'react';
import Notifications from 'react-notify-toast';

import { groupService } from '../services/groupService';

import LoadingState from '../components/LoadingState';
import Breadcrumbs from '../components/Breadcrumbs';
import GroupContainer from './GroupContainer';
import GroupSettings from './GroupSettings';
import { TextFilterInput } from '../components/FormElements';
import { PrimaryButton, OutlineButton, FullWidthButton } from '../components/Buttons';
import EmptyState from './EmptyState';
import ConfirmationModal from '../components/ConfirmationModal';
import PeopleTable from '../components/PeopleTable';
import { useUser } from '../authentication';

const hasItem = (obj, item) =>
  Object.values(obj).find(val => typeof val === 'string' && val.toLowerCase().includes(item));

export default function Group() {
  const user = useUser();
  const [data, setData] = useState({ isLoading: true });
  const [activeTab, setActiveTab] = useState('people');
  const [filter, setFilter] = useState('');
  const [isDuplicateName, setIsDuplicateName] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [removeModal, setRemoveModal] = useState({ show: false, person: '' });
  const assignBtnLabel = 'Assign Training';

  const groupId = window.location.hash.split('/')[2];
  const orgId = user.lastSelectedAccount;
  const userId = user.userId;
  const isAdmin = user.permissions.includes('Groups View');
  const isLeader = user.permissions.includes('Group Leader');

  const crumbs = [{ name: 'Groups', route: '#/groups' }, { name: data.isLoading ? '' : data.group.groupName }];
  if (isAdmin) crumbs.unshift({ name: 'Organization', route: '#/org' });

  useEffect(() => {
    getSingleGroup();
  }, []);

  useEffect(() => {
    if (!isAdmin && !isLeader) window.location = '#/dashboard';
  }, [user, isAdmin, isLeader]);

  const getSingleGroup = () =>
    groupService
      .getSingleGroup(groupId, orgId)
      .then(response => setData({ group: response, isLoading: false }))
      .catch(() => setData({ isError: true, isLoading: false }));

  const updateGroupName = name => {
    setIsDuplicateName(false);
    groupService
      .updateGroupName(orgId, groupId, name, userId)
      .then(() => {
        setData(prevData => {
          return { group: { ...prevData.group, groupName: name }, isLoading: false };
        });
        setActiveTab('people');
      })
      .catch(reason => {
        if (reason?.code === 'GroupNameDuplicateError') {
          return setIsDuplicateName('Sorry, that Group name is taken');
        } else {
          setData({ isError: true, isLoading: false });
        }
      });
  };

  const downloadGroup = () => window.open(getApiUrl() + '/v1/organizations/' + orgId + '/groups/' + groupId + '/csv');

  const handleQuickAction = (action, personToUpdate) => {
    const personToUpdateId = personToUpdate.emailAddress ?? personToUpdate.userId.id;

    if (action === 'emailPerson') return (window.location = `#/email/person/${orgId}/${personToUpdateId}`);
    if (action === 'confirmRemovePerson') return setRemoveModal({ show: true, person: personToUpdate });

    const promise =
      action === 'removePerson'
        ? groupService.removePerson(groupId, orgId, personToUpdate, userId)
        : groupService.changeGroupMemberPosition(orgId, groupId, personToUpdateId, action, userId);

    setIsUpdating(true);

    promise
      .then(() => {
        setData(prevData => ({
          ...prevData,
          group: {
            ...prevData.group,
            people:
              action === 'removePerson'
                ? removePersonFromList(prevData.group.people, personToUpdateId)
                : updatePersonPosition(prevData.group.people, personToUpdateId, action),
          },
        }));
      })
      .finally(() => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        setIsUpdating(false);
        if (removeModal.show) setRemoveModal({ show: false, person: '' });
      })
      .catch(reason => (reason?.code === 'NoChange' ? getSingleGroup() : setData({ isError: true, isLoading: false })));
  };

  const updatePersonPosition = (people, id, position) => {
    return people.map(person => (isPerson(person, id) ? { ...person, position } : person));
  };

  const removePersonFromList = (people, id) => people.filter(person => !isPerson(person, id));

  const isPerson = (person, id) => person.emailAddress === id || person.userId?.id === id;

  const GroupPeople = () => {
    const people = data.group.people.filter(person => hasItem(person, filter.toLowerCase()));

    return people.length ? (
      <PeopleTable people={people} user={user} isUpdating={isUpdating} handleQuickAction={handleQuickAction} />
    ) : (
      <EmptyState noResults={true} />
    );
  };

  const Tabs = () => (
    <ul className="group-page-tabs">
      <li className={activeTab === 'people' ? 'active-tab' : ''}>
        <button onClick={() => setActiveTab('people')}>
          <span className="fas fa-user-friends fa-fw"></span> People
        </button>
      </li>
      <li className={activeTab === 'settings' ? 'active-tab' : ''}>
        <button data-qa-hook="groupDetailSettingsTab" onClick={() => setActiveTab('settings')}>
          <span className="fas fa-cog fa-fw"></span> Settings
        </button>
      </li>
    </ul>
  );

  return data.isError ? (
    <EmptyState isError={'is-error-group'} />
  ) : (
    <>
      <Notifications />
      <Breadcrumbs crumbs={crumbs} />
      <GroupContainer>
        <header className="space-between">
          <div>
            <h1 data-qa-hook="groupDetailName" className={data.isLoading ? 'isLoading' : ''}>
              {data.isLoading ? <span>&nbsp;</span> : data.group.groupName}
            </h1>
          </div>
          <div>
            {!!data.group?.people.length && (
              <OutlineButton
                data-qa-hook="groupDetailEmail"
                disabled={data.isLoading}
                onClick={() => (window.location = `#/email/group/${orgId}/${groupId}`)}
              >
                <span className="far fa-envelope"></span> Email Group
              </OutlineButton>
            )}
          </div>
        </header>
        <Tabs />
        {activeTab === 'people' && (
          <section>
            {!data.isLoading && (
              <div className="grid-container grid-gap-16 grid-sm-col-2 grid-md-col-6">
                {!!data.group?.people.length && (
                  <div className="secondary-action">
                    <FullWidthButton onClick={() => (window.location = `#/search`)}>{assignBtnLabel}</FullWidthButton>
                  </div>
                )}
                <div className="primary-action">
                  <PrimaryButton
                    data-qa-hook="groupDetailAdd"
                    disabled={data.isLoading}
                    onClick={() => (window.location = `#/groups/${groupId}/${orgId}/add-people`)}
                  >
                    Add People
                  </PrimaryButton>
                </div>
                <div
                  className={
                    'grid-sm-col-span-2 ' + (data.group.people.length > 0 ? 'grid-md-col-span-4' : 'grid-md-col-span-5')
                  }
                >
                  {!!data.group.people.length && (
                    <TextFilterInput
                      id="groupDetailSearchFilter"
                      placeholder="Search People"
                      value={filter}
                      onChangeHandler={e => setFilter(e.target.value)}
                      clearInput={() => setFilter('')}
                    />
                  )}
                </div>
              </div>
            )}
            {data.isLoading ? <LoadingState /> : data.group.people.length ? <GroupPeople /> : <EmptyState />}
            {removeModal.show && (
              <ConfirmationModal
                buttonActionText="Remove"
                buttonType="danger"
                title="Remove Person"
                prompt={
                  <span>
                    Are you sure you want to remove this person from <b>{data.group.groupName}</b>?
                  </span>
                }
                subtext={removeModal.person.displayName}
                handleSubmit={() => handleQuickAction('removePerson', removeModal.person)}
                handleDismiss={() => setRemoveModal({ show: false, person: '' })}
              >
                <p>
                  <b>{removeModal.person.emailAddress ?? removeModal.person.email}</b>
                </p>
              </ConfirmationModal>
            )}
          </section>
        )}
        {activeTab === 'settings' && (
          <GroupSettings
            originalName={data.group.groupName}
            isDuplicateName={isDuplicateName}
            handleSubmit={updateGroupName}
            downloadGroup={data.group?.people.length > 0 ? downloadGroup : null}
          />
        )}
      </GroupContainer>
    </>
  );
}
