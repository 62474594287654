import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Grid } from '../StyleGuide';
import { BaseImageCrop } from './ImageCrop';
import ProfileImage from '../groups/ProfileImage';

export const PlaceholderProfileImage = styled.div`
  & > i {
    width: 150px;
    height: 150px;
    font-size: 144px;
    background: #f4f4f4;
    background-clip: border-box;
    color: #b3b3b3 !important;
    border-radius: 50%;
    background-clip: padding-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ProfileImageCrop = ({ profileImage, onUpdate, onProfileImageLoaded, aspectRatio = 1 / 1, width = '150' }) => {
  const onUpdateProfileImage = inputRef => {
    inputRef.current.click();
  };

  return (
    <BaseImageCrop onUpdate={onUpdate} aspectRatio={aspectRatio} isCircularCrop={true} autoClose={false}>
      {({ inputRef, closeModal }) =>
        profileImage ? (
          <div onClick={() => onUpdateProfileImage(inputRef)}>
            <ProfileImage src={profileImage} width={width} onLoad={() => onProfileImageLoaded(closeModal)} />
            <p style={{ textAlign: 'center' }}>Change Image</p>
          </div>
        ) : (
          <PlaceholderProfileImage onClick={() => onUpdateProfileImage(inputRef)}>
            <i className="fas fa-user-circle"></i>
            <p style={{ textAlign: 'center' }}>Upload Image</p>
          </PlaceholderProfileImage>
        )
      }
    </BaseImageCrop>
  );
};

ProfileImageCrop.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  aspectRatio: PropTypes.number,
  profileImage: PropTypes.string,
};

export default ProfileImageCrop;
