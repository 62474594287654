import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Grid, Type } from '../../StyleGuide';
import { Card, ThreeTwoAspectRatioPlaceholder, Image } from '../../components/Layout';
import windowService from '../../services/windowService';
import useCurriculum from '../../hooks/useCurriculum';
import { ymdToLocalMidnight } from '../../filters/date-filters';

const CollectionCardContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  font-weight: inherit;
  color: inherit;

  ${Image}, .three-two-aspect-ratio-placeholder {
    border-radius: 4px 4px 0 0;
  }
`;

const CollectionDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${Grid._4};

  h4 {
    margin: 0 0 ${Grid._2} 0;
  }

  p {
    color: ${Type.Color.medium};
    margin: 0;

    & + p {
      margin-top: ${Grid._4};

      i {
        margin-right: ${Grid._2};
      }
    }
  }
`;

const CollectionCard = ({ collection }) => {
  const { brand, ageCategory } = useCurriculum();
  const { name, image, startDate, endDate } = collection;
  const plan = collection.package === 'Plus' ? 'Plus Only' : 'All Plans';

  const formatDate = date => {
    return format(ymdToLocalMidnight(date), 'MMMM d, yyyy');
  };

  return (
    <CollectionCardContainer
      data-qa-hook="collectionCard"
      onClick={() =>
        windowService.redirectTo(
          `/manage-curriculum/${brand.code}/${ageCategory}/${collection.curriculumId}/collection/${collection.collectionId}`
        )
      }
    >
      {image ? (
        <Image src={image} alt={name} />
      ) : (
        <ThreeTwoAspectRatioPlaceholder>No Image Provided</ThreeTwoAspectRatioPlaceholder>
      )}
      <CollectionDetails>
        <h4>{name}</h4>
        <p>{plan}</p>

        {(startDate || endDate) && (
          <p>
            <i className="far fa-calendar-alt fa-fw" />
            {startDate && endDate ? (
              <>
                {formatDate(startDate)} - {formatDate(endDate)}
              </>
            ) : startDate && !endDate ? (
              <>{`Starts ${formatDate(startDate)}`}</>
            ) : (
              !startDate && endDate && <>Ends {formatDate(endDate)}</>
            )}
          </p>
        )}
      </CollectionDetails>
    </CollectionCardContainer>
  );
};

CollectionCard.propTypes = {
  collection: PropTypes.object.isRequired,
};

export default CollectionCard;
