import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import RichTextEditor from '../../containers/RichTextEditor';
import useAutosave from '../../hooks/useAutosave';

const BodySectionRichTextEditor = ({ content, onSave }) => {
  const editorRef = useRef();
  const [editorState, setEditorState] = useState(
    content ? EditorState.createWithContent(stateFromHTML(content)) : EditorState.createEmpty()
  );

  useAutosave(() => {
    onSave({ text: stateToHTML(editorState.getCurrentContent()) });
  }, [editorRef]);

  return <RichTextEditor ref={editorRef} editorState={editorState} setEditorState={setEditorState} />;
};

BodySectionRichTextEditor.propTypes = {
  content: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default BodySectionRichTextEditor;
