import React, { useState, useMemo, useCallback } from 'react';

//filters: an object of filter objects e.g. {myTextFilter, myArrayFilter}
export const useMultiFilter = filters => {
  const filterArray = useMemo(() => Object.values(filters), [filters]);
  const reset = useCallback(() => filterArray.forEach(filter => filter.reset()), [filterArray]);
  //sequentially apply every filter to items
  const filter = useCallback(
    items => filterArray.reduce((items, filter) => filter.filter(items), items),
    [filterArray]
  );
  const activeFilterCount = useMemo(
    () => filterArray.reduce((sum, filter) => (!filter.isEmpty ? 1 : 0) + sum, 0),
    [filterArray]
  );
  const isEmpty = useMemo(() => activeFilterCount === 0, [activeFilterCount]);
  const state = useMemo(() => Object.fromEntries(Object.entries(filters).map(([k, v]) => [k, v.state])), [filters]);
  const setState = useCallback(state => Object.entries(state).forEach(([k, v]) => filters[k].setState(v)), [filters]);

  return { state, setState, filters, reset, filter, isEmpty, activeFilterCount };
};

//comparators: object of functions, each of which is a comparator for a sort method by the same name
//initialState: {method: "sortMethod", direction: one of "ascending", "descending", or null}
export const useSortFilter = ({ comparators, initialState }) => {
  const [state, setState] = useState(initialState ?? { method: null, order: null });
  const { method, order } = state;

  const reset = useCallback(() => setState({ method: null, order: null }), [setState]);

  //calling update with a method different than the current method will switch to sorting ascending by that method
  //calling update with the same method will cycle between ascending->descending->none->ascending...
  const update = useCallback(
    newMethod => {
      if (!(newMethod in comparators)) {
        throw new Error(`Invalid sort method '${newMethod}'. Must be one of: ${Object.keys(comparators).join(', ')}`);
      }

      if (newMethod !== method) {
        setState({
          method: newMethod,
          order: 'ascending',
        });
        return;
      }

      const newOrder = order === 'ascending' ? 'descending' : 'ascending';
      setState({
        method: newOrder === null ? null : newMethod,
        order: newOrder,
      });
    },
    [comparators, method, order]
  );

  const isEmpty = useMemo(() => method === null && order === null, [method, order]);

  const filter = useCallback(
    items => {
      if (isEmpty) {
        return items;
      }

      const comparator = comparators[method];
      const direction = order === 'ascending' ? 1 : -1;
      return [...items].sort((a, b) => direction * comparator(a, b));
    },
    [order, comparators, method]
  );

  return { state, setState, method, order, reset, update, isEmpty, filter };
};

//matcher: (item, value) => trueIfValueRepresentsItem
//initialState: initial array of values to filter by
//if an item matches any value, we keep that item
export const useArrayFilter = ({ matcher = (a, b) => a === b, initialState }) => {
  const [values, setValues] = useState(initialState ?? []);

  //when given a value, update function toggles between adding and removing that value from values
  const update = useCallback(
    value => setValues(prev => (prev.includes(value) ? prev.filter(i => i !== value) : [...prev, value])),
    [setValues]
  );
  const reset = useCallback(() => setValues([]), [setValues]);
  const isEmpty = useMemo(() => values.length === 0, [values]);
  const filter = useCallback(
    items => {
      if (isEmpty) {
        return items;
      }

      return items.filter(item => values.some(value => matcher(item, value)));
    },
    [values, matcher]
  );

  return { state: values, setState: setValues, values, update, reset, filter, isEmpty };
};

//matcher: (item, value) => trueIfValueRepresentsItem
//initialState: initial value of filter
//if the filter's value is the emptyState, it will return all items
export const useValueFilter = ({ matcher = (a, b) => a === b, emptyState, initialState = emptyState }) => {
  const [value, setValue] = useState(initialState);

  const update = useCallback(value => setValue(value), [setValue]);
  const reset = useCallback(() => setValue(emptyState), [setValue, emptyState]);
  const isEmpty = useMemo(() => value === emptyState, [value, emptyState]);
  const filter = useCallback(
    items => {
      if (isEmpty) {
        return items;
      }

      return items.filter(item => matcher(item, value));
    },
    [value, emptyState, matcher]
  );

  return { state: value, setState: setValue, value, update, reset, filter, isEmpty };
};

//a value filter that uses an empty string as the empty filter state by default
export const useTextFilter = ({ matcher, emptyState = '', initialState = emptyState }) =>
  useValueFilter({ matcher, emptyState, initialState });
