import React from 'react';
import PropTypes from 'prop-types';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Grid, Breakpoints } from '../StyleGuide';
import { PrimaryButton, Button } from '../components/Buttons';
import ErrorMessage from '../components/ErrorMessage';
import { Label, FormField, FormFieldContainer } from '../components/FormElements';
import alertService from '../services/AlertService';
import emailService from '../services/emailService';
import { handleError } from '../utils/apiUtils';

const Container = styled.div`
  width: 750px;
  float: left;
  margin: ${Grid._5} auto;
  @media (max-width: ${Breakpoints.screen_md}) {
    width: 100%;
  }
  label[for='message'] {
    margin-bottom: -${Grid._5};
  }
  .email-preview {
    @media (max-width: ${Breakpoints.screen_md}) {
      margin-left: -15px !important;
      margin-right: -15px !important;
    }
  }
  #message {
    text-align: center;
    margin: 0 auto;
    width: 75%;
    @media (max-width: ${Breakpoints.screen_md}) {
      width: 100%;
    }
  }
  textarea {
    resize: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  column-gap: ${Grid._4};
  float: right;
`;

export default function InlineEmailEditor({
  orgId,
  groupName,
  recipientEmail,
  htmlTemplateString,
  messagePlaceholder,
  defaultSubject,
  defaultReplyTo,
  bodyText,
  buttonText = 'Log In',
  ...props
}) {
  const convertNode = (node, overrideName) => {
    const Tag = overrideName ?? node.name;
    return <Tag {...attributesToProps(node.attribs)}>{domToReact(node.children, parseOptions)}</Tag>;
  };

  const parseOptions = {
    replace: domNode => {
      const { attribs, name, data, parent } = domNode;
      if (attribs && (name === 'html' || name === 'body')) {
        return convertNode(domNode, 'div');
      }

      if (name === 'head') {
        return <></>;
      }

      if (name === 'tr' && parent?.name === 'table') {
        return <tbody>{convertNode(domNode)}</tbody>;
      }

      return (
        data &&
        data === '###MSG###' && (
          <Field
            data-qa-hook="emailPreviewBody"
            component="textarea"
            id="message"
            name="message"
            placeholder={messagePlaceholder}
            className="form-control"
            rows="10"
          />
        )
      );
    },
  };

  const htmlTemplate = parse(htmlTemplateString, parseOptions);

  const goBack = e => {
    e.preventDefault();
    window.history.back();
  };

  const EmailFormSchema = Yup.object().shape({
    subject: Yup.string().max(78, 'Subject is too long').required('Subject is required'),
    replyTo: Yup.string().email('Reply To is not a valid email address').required('Reply To is required'),
    message: Yup.string().max(1000, 'Personal message is too long').required('Personal message is required'),
  });

  const onSubmit = values => {
    const bodyText = document.getElementById('message').value;
    emailService
      .sendEmailForTemplate(orgId, 'blank', values.subject, bodyText, buttonText, recipientEmail, values.replyTo)
      .then(
        success => {
          alertService.showOnNextPage('Email Sent');
          window.history.back();
        },
        error => {
          handleError(error);
        }
      );
  };

  return (
    <>
      <Container>
        <Formik
          initialValues={{
            subject: defaultSubject,
            replyTo: defaultReplyTo,
            message: bodyText || '',
          }}
          validationSchema={EmailFormSchema}
          onSubmit={onSubmit}
          render={props => (
            <Form>
              <FormFieldContainer>
                <Label htmlFor="email">To:</Label>
                <p data-qa-hook="emailAddress">{groupName ? groupName : recipientEmail}</p>
              </FormFieldContainer>
              <FormFieldContainer>
                <FormField
                  data-qa-hook="emailReplyTo"
                  type="text"
                  name="replyTo"
                  placeholder="Enter Reply To"
                  className="form-control"
                  label="Reply To:"
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <FormField
                  data-qa-hook="emailSubject"
                  type="text"
                  name="subject"
                  placeholder="Enter Subject"
                  className="form-control"
                  label="Subject:"
                />
              </FormFieldContainer>
              <FormFieldContainer>
                <Label htmlFor="message">Email Preview:</Label>
                <div className="email-preview">{htmlTemplate}</div>
              </FormFieldContainer>
              {props.errors.subject && <ErrorMessage>{props.errors.subject}</ErrorMessage>}
              {props.errors.replyTo && <ErrorMessage>{props.errors.replyTo}</ErrorMessage>}
              {props.errors.message && <ErrorMessage>{props.errors.message}</ErrorMessage>}
              <ButtonContainer>
                <PrimaryButton
                  data-qa-hook="sendEmailButton"
                  className="btn btn-lg btn-success btn-flex-right"
                  type="submit"
                  disabled={props.isSubmitting || !props.isValid || !props.dirty}
                  operating={props.isSubmitting}
                >
                  Send Email
                </PrimaryButton>
                <Button data-qa-hook="cancelButton" className="btn btn-lg btn-default btn-flex-right" onClick={goBack}>
                  Cancel
                </Button>
              </ButtonContainer>
            </Form>
          )}
        />
      </Container>
    </>
  );
}

InlineEmailEditor.propTypes = {
  orgId: PropTypes.string.isRequired,
  htmlTemplateString: PropTypes.string.isRequired,
  defaultSubject: PropTypes.string.isRequired,
  defaultReplyTo: PropTypes.string.isRequired,
  messagePlaceholder: PropTypes.string.isRequired,
  recipientEmail: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  groupName: PropTypes.string,
  bodyText: PropTypes.string,
  buttonText: PropTypes.string,
};
