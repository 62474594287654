import React from 'react';
import Section from './Section';

const LoadingSection = () => (
  <Section className="loading">
    <h1>
      <span className="loading-context-bar"></span>
    </h1>
    <p>
      <span className="loading-context-bar"></span>
    </p>
    <p>
      <span className="loading-context-bar"></span>
    </p>
    <p>
      <span className="loading-context-bar"></span>
    </p>
  </Section>
);

export default LoadingSection;
