angular.module('lwNamb').factory('commandSubmissionService', [
  '$rootScope',
  'api',
  'qt',
  '$log',
  function($rootScope, api, qt, $log) {
    var timeoutSeconds = 30;
    return {
      submitAndWait: function(id, cmd, cmdName, evtNames, errNames, caches, timeoutOverride, returnOnlyId) {
        var deferred = qt.defer({ timeoutSeconds: timeoutOverride || timeoutSeconds });

        if (caches !== undefined)
          caches.forEach(function(cache) {
            cache.removeAll();
          });

        api.post('/v1/commands/' + cmdName, cmd).then(null, function failure(reason) {
          deferred.reject(reason);
        });

        if (!Array.isArray(evtNames)) {
          evtNames = [evtNames];
        }
        evtNames.forEach(function(evtName) {
          $rootScope.$on(evtName, function(name, event) {
            if (event.id === id) {
              deferred.resolve(returnOnlyId === true ? id : event);
            }
          });
        });

        if (!Array.isArray(errNames)) {
          errNames = [errNames];
        }
        errNames.forEach(function(errName) {
          $rootScope.$on(errName, function(name, event) {
            if (event.id === id) {
              deferred.reject(event);
            }
          });
        });

        return deferred.promise;
      },
    };
  },
]);
