import cacheService from '../../services/cacheService';
import submitCommand from '../../services/submitCommand';

type VideoResponse = {
  bc_id: string;
  description: string;
  title: string;
  id: string;
  duration: number;
  eventType: string;
  published: false;
  uniqueId: string;
  version: number;
};

export const saveVideo: (
  videoId: string,
  fileName: string,
  orgId: string,
  initiatingUserId: string
) => Promise<VideoResponse> = (videoId, fileName, orgId, initiatingUserId) => {
  const cmd = {
    id: videoId,
    orgId: orgId,
    fileName: fileName,
    title: fileName,
    description: '',
    initiatingUserId: initiatingUserId,
  };
  cacheService.removeAllInPath(`/v1/organizations/${orgId}/content`);
  return submitCommand(videoId, cmd, 'SaveVideo', 'VideoCreated', 'VideoCreatedError');
};
