import React from 'react';
import PropTypes from 'prop-types';

const OrgContext = React.createContext(undefined);
OrgContext.displayName = 'OrgContext';

export const OrgProvider = ({ org, children }) => <OrgContext.Provider value={org}>{children}</OrgContext.Provider>;

OrgProvider.propTypes = {
  org: PropTypes.object,
  children: PropTypes.any,
};

export default OrgContext;
