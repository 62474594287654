import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MediumButton, MediumPrimaryButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';
import { RadioListWithDescription, Radio } from '../components/FormElements';
import windowService from '../services/windowService';

const AddTrainingButtonModal = ({ handleDismiss }) => {
  const [buttonType, setButtonType] = useState();

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader data-qa-hook="addTrainingButtonModal">
        <h3>Add Training Button</h3>
      </ModalHeader>
      <ModalBody>
        <h4>Do you want to link this training button to a training item or a search term?</h4>
        <form>
          <RadioListWithDescription>
            <li>
              <label>
                <Radio
                  data-qa-hook="trainingItemRadio"
                  name="button-type"
                  onChange={() => setButtonType('training')}
                  checked={buttonType === 'training'}
                />
                Training Item
              </label>
              <p>Link this button to a training compilation, pathway, or course</p>
            </li>
            <li>
              <label>
                <Radio
                  data-qa-hook="searchTermRadio"
                  name="button-type"
                  onChange={() => setButtonType('search')}
                  checked={buttonType === 'search'}
                />
                Search Term
              </label>
              <p>Link this button to results for a specific search term and/or category</p>
            </li>
          </RadioListWithDescription>
        </form>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton
          data-qa-hook="continue"
          onClick={() => windowService.redirectTo(`#/manage-catalog/training-button/${buttonType}`)}
          disabled={!buttonType}
        >
          Continue
        </MediumPrimaryButton>
        <MediumButton data-qa-hook="cancel" onClick={() => handleDismiss()}>
          Cancel
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

AddTrainingButtonModal.propTypes = {
  handleDismiss: PropTypes.func.isRequired,
};

export default AddTrainingButtonModal;
