import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Grid, Color, Type, Breakpoints } from '../StyleGuide';
import StepIcons from '../StepIcons';
import { MediumOutlineButton } from './Buttons';

import windowService from '../services/windowService';
import fileService from '../services/fileService';
import cacheService from '../services/cacheService';

type StepIcon = keyof typeof StepIcons;

type Training = {
  _type: string;
  purchaseRequired: string | boolean;
  taskListInstanceId: { id: string };
  name: string;
};

type Task = {
  taskName: string;
  _type: StepIcon;
  assessmentId: string;
  linkUrl: string;
  assetUrl: string;
  responseId: string;
  uploadedAssetUrl?: string;
};

type Step = {
  _id?: {
    id: string;
  };
  id?: {
    id: string;
  };
  status: string;
  task: Task;
};

type StepProps = {
  isLocked: boolean | string;
  step: Step;
  training: Training;
};

const StepRow = styled.div`
  color: ${Color.Gray._90} !important;
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.medium};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    margin: 0;
  }

  @media screen and (max-width: ${Breakpoints.screen_sm}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    button {
      width: 100%;
    }
  }
`;

const StepName = styled.div`
  .icon,
  .fas {
    color: ${Color.Gray._90};
    margin-right: ${Grid._4};
    font-size: ${Type.Scale._4};
  }
  .fa-lock {
    font-size: ${Type.Scale._3};
  }
  .fa-check-circle {
    color: #85a657;
  }
`;

type TaskButtonProps = {
  text: string;
  iconClass: string;
  onClick: () => void;
};

const TaskButton = ({ text, iconClass, onClick }: TaskButtonProps) => (
  <MediumOutlineButton onClick={onClick}>
    <i className={iconClass} />
    {text}
  </MediumOutlineButton>
);

type Params = {
  assignmentId: string;
};

export default function Step({ isLocked, step, training }: StepProps) {
  const { assignmentId } = useParams<Params>();
  const task = step?.task || step;
  const taskName = task.taskName;
  const trainingName = training.name;
  const type = task._type;
  const isComplete = step.status ? step.status === 'complete' : false;
  const stepId = step._id?.id ?? (step.id?.id || '');
  const trainingInstanceId = training.taskListInstanceId;
  const assessmentId = task?.assessmentId;
  const responseId = task?.responseId;
  const downloadUrl = task.assetUrl || task.uploadedAssetUrl;

  const cacheAndRedirectToAssessment = () => {
    cacheService.set('assessmentId', assessmentId);
    cacheService.set('trainingName', trainingName);
    windowService.redirectTo(`/manage-assignments/${assignmentId}/view/${assessmentId}/${responseId}`);
  };

  const openDownloadUrl = () => window.open(fileService.urlOpenFile(downloadUrl, taskName));
  const redirectToFeedbackViewer = () =>
    windowService.redirectTo(`/feedback-viewer/${stepId}/${trainingInstanceId.id}`);

  function getStepIcon() {
    if (isComplete) {
      return StepIcons['Complete'];
    } else if (isLocked) {
      return StepIcons['Locked'];
    } else {
      return StepIcons[type];
    }
  }
  return (
    <StepRow data-qa-hook="assignedTaskListTaskName">
      <StepName>
        <i className={getStepIcon()} />
        {taskName}
      </StepName>

      {type === 'Link' && (
        <TaskButton
          text="View Link"
          iconClass="fas fa-link"
          onClick={() => {
            windowService.openUrl(task.linkUrl);
          }}
        />
      )}
      {type === 'Assessment' && isComplete && (
        <TaskButton text="View Results" iconClass="fas fa-poll" onClick={cacheAndRedirectToAssessment} />
      )}
      {type === 'Feedback' && (
        <TaskButton text="View Feedback" iconClass="fas fa-comments" onClick={redirectToFeedbackViewer} />
      )}
      {(type === 'Download' || type === 'Upload') && downloadUrl && (
        <TaskButton text="Download File" iconClass="fas fa-download" onClick={openDownloadUrl} />
      )}
    </StepRow>
  );
}
