import styled from 'styled-components';
import { Grid, Color, Type } from '../StyleGuide';

const ThumbnailIcon = styled.div`
  background: ${Color.Gray._10};
  border-radius: 100%;
  width: ${Grid._10};
  height: ${Grid._10};
  color: ${Color.Gray._70};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${Grid._5} auto;
  font-size: ${Type.Scale._5};
  position: relative;
  .fa-stack {
    position: absolute;
    width: ${Grid._5};
    height: ${Grid._5};
    display: flex;
    align-items: center;
    align-content: center;
    right: -${Grid._2};
    top: 0;
  }
  .fa-stack-2x {
    font-size: ${Type.Scale._5};
  }
  .fa-inverse {
    font-size: ${Type.Scale._1};
    line-height: 1;
  }
`;

export default ThumbnailIcon;
