angular.module('lwNamb').directive('browserDetect', [
  function() {
    return {
      restrict: 'E',
      templateUrl: 'partials/directives/browser-detect.html',
      controller: [
        '$scope',
        function($scope) {
          $scope.localStorageSupport = typeof Storage !== 'undefined';

          if (window.navigator.userAgent > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            $scope.localStorageSupport = false;
          }
        },
      ],
    };
  },
]);
