import React from 'react';

export default function StepLabel(props) {
  return (
    <span data-qa-hook="stepVerbAction">
      {(t => {
        switch (t) {
          case 'Video':
            return 'Stream';
          case 'ThirdPartyVideo':
            return 'Stream';
          case 'Text':
            return 'Read';
          case 'Upload':
            return 'Upload';
          case 'Download':
            return 'Download';
          case 'Link':
            return 'Visit';
          case 'MyWSBLink':
            return 'Visit';
          case 'Assessment':
            return 'Take';
          case 'Feedback':
            return 'Send';
          default:
            return 'Do';
        }
      })(props.taskType)}
    </span>
  );
}
