import React, { useState, useEffect } from 'react';
import { Container } from '../../components/Layout';
import Breadcrumbs from '../../components/Breadcrumbs';
import SectionHeader from '../../components/SectionHeader';
import { Header } from '../../components/FormElements';
import LoadingState from '../../components/LoadingState';
import ErrorMessage from '../../components/ErrorMessage';
import windowService from '../../services/windowService';
import curriculumService from '../../services/curriculumService';
import { CurriculumItem, CurriculumImage, TrainingImage } from '../CurriculumElements';
import { createCurriculum } from '../../hooks/useCurriculum';

const Curriculum = ({ id, brandName, brandCode, categoryName }) => {
  const curriculum = createCurriculum(brandCode);

  return (
    <CurriculumItem
      data-qa-hook={`${brandName}-${categoryName}`}
      className="grid-container grid-col-1 grid-sm-col-6 grid-gap-24"
      onClick={() =>
        windowService.redirectTo(
          curriculum?.brand.timelineEnabled === true
            ? `/manage-curriculum/${brandCode}/${categoryName}/${id}/session-calendar`
            : `/manage-curriculum/${brandCode}/${categoryName}/${id}`
        )
      }
    >
      <div className="grid-col-span-1">{brandCode ? <CurriculumImage brandCode={brandCode} /> : <TrainingImage />}</div>
      <div className="grid-container grid-col-1 grid-sm-col-6 grid-col-gap-8 grid-col-span-5">
        <h4 data-qa-hook="curriculumName" className="grid-col-span-2">
          {brandName}
        </h4>
        <p data-qa-hook="curriculumAgeGroup" className="grid-col-span-4">
          {categoryName}
        </p>
      </div>
    </CurriculumItem>
  );
};

export default function ManageCurriculums() {
  const [data, setData] = useState({ curriculum: [], isLoading: true, isError: false });
  const crumbs = [{ name: 'Organization', route: '#/org' }, { name: 'Manage Curriculum' }];

  const compareCategories = (a, b) => {
    try {
      return curriculumService.compareAgeCategory(a, b);
    } catch {
      return a.localeCompare(b);
    }
  };

  const sortCurriculum = crm =>
    crm.sort((a, b) => a.brand.localeCompare(b.brand) * 10 + compareCategories(a.name, b.name));

  useEffect(() => {
    curriculumService
      .getAllCurriculum()
      .then(crm => {
        setData({
          ...data,
          isLoading: false,
          curriculum: sortCurriculum(crm),
        });
      })
      .catch(err => {
        console.error(err);
        setData({ isError: true, isLoading: false });
      });
  }, []);

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container data-qa-hook="manageCurriculumView">
        <Header className="mt-40">Manage Curriculum</Header>
        <p className="mb-48">View and edit curriculum by brand and collection.</p>
        {data.isLoading ? (
          <LoadingState />
        ) : data.isError ? (
          <ErrorMessage />
        ) : (
          <>
            <SectionHeader title="All Curriculum" margin="0 0 2px" />
            <div data-qa-hook="curriculumContainer" className="grid-container">
              {data.curriculum.map(c => (
                <Curriculum key={c.id} id={c.id} brandName={c.brand} brandCode={c.brandCode} categoryName={c.name} />
              ))}
            </div>
          </>
        )}
      </Container>
    </>
  );
}
