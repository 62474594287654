import { useRef } from 'react';

const useDebounceCallback = delay => {
  const callbackRef = useRef();
  const timerRef = useRef();

  return fn => {
    clearTimeout(timerRef.current);

    if (typeof fn === 'function') {
      callbackRef.current = fn;

      timerRef.current = setTimeout(() => {
        callbackRef.current();
      }, delay);
    }
  };
};

export default useDebounceCallback;
