import React from 'react';
import ActiveFilters from '../../components/ActiveFilters';
import { useSearch } from '../hooks/useSearch';

export const ActiveSearchFilters = () => {
  const { searchResultCount, selectedFacets, deselectFacet } = useSearch();
  const activeFilters = Object.fromEntries(selectedFacets.map(facet => [facet.key, () => deselectFacet(facet)]));

  return <ActiveFilters count={searchResultCount} filters={activeFilters} />;
};
