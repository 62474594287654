import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { react2angular } from 'react2angular';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import Notifications from 'react-notify-toast';
import { OutlineButton, SmallRoundedIconButton } from '../../components/Buttons';
import ErrorMessage from '../../components/ErrorMessage';
import { Header, Label, FormFieldSet, FormFieldInlineEdit } from '../../components/FormElements';
import { Container, Image, SubHeaderWithButton } from '../../components/Layout';
import { MaterialList, MaterialListItem } from '../../curriculum/MaterialList';
import { DeemphasizedBadge } from '../../components/Badge';
import { OrderingDragContainer, OrderingDragHandle } from '../../components/OrderingElements';
import { SortableElement } from 'react-sortable-hoc';
import EditTextModal from '../../components/EditTextModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import LoadingState from '../../components/LoadingState';
import alertService from '../../services/AlertService';
import curriculumService from '../../services/curriculumService';
import windowService from '../../services/windowService';
import useCurriculum from '../../hooks/useCurriculum';
import { useUser } from '../../authentication';

export default function ManageCurriculumVolumeHeader() {
  const user = useUser();
  const { brand, ageCategory } = useCurriculum();
  const [data, setData] = useState({ isLoading: true, isError: false, isSubmitting: false, header: {} });
  const [materials, setMaterials] = useState([]);
  const [editTextModal, setEditTextModal] = useState({ show: false, label: '', value: '', materialId: '' });
  const [removeModal, setRemoveModal] = useState({ show: false, name: '', id: '' });

  const { curriculumId, volumeId, headerId } = useParams();

  const [crumbs, setCrumbs] = useState([
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Curriculum', route: '#/manage-curriculum' },
  ]);

  useEffect(() => {
    Promise.all([
      curriculumService.getIndividualCurriculum(curriculumId),
      curriculumService.getVolume(volumeId),
      curriculumService.getVolumeHeader(volumeId, headerId),
    ])
      .then(values => {
        const curriculum = values[0];
        const volume = values[1];
        const header = values[2];

        setCrumbs([
          ...crumbs,
          {
            name: `${curriculum.name}: ${curriculum.brand}`,
            route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}`,
          },
          {
            name: volume.name,
            route: `#/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/volume/${volumeId}`,
          },
          { name: header.header },
        ]);
        setData({ ...data, isLoading: false, curriculum: curriculum, volume: volume, header: header });
        setMaterials(header.materials);
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
        setData({ ...data, isLoading: false, isError: true });
      });
  }, []);

  const reorderMaterials = ({ oldIndex, newIndex }) => {
    const list = [...materials];
    list.splice(newIndex, 0, list.splice(oldIndex, 1)[0]);
    setMaterials(list);
    curriculumService
      .reorderVolumeHeaderMaterials(
        volumeId,
        headerId,
        list.map(m => m.materialId),
        user.userId
      )
      .then(() => alertService.show('Header Materials Reordered', 'success', 2000))
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please refresh and try again.', 'error');
      });
  };

  const updateCrumbName = name => {
    const copy = [...crumbs];
    copy[copy.length - 1].name = name;
    setCrumbs(copy);
  };

  const updateElementName = (name, materialId) => {
    if (materialId) {
      return curriculumService
        .updateVolumeHeaderMaterialName(volumeId, headerId, materialId, name, user.userId)
        .then(() => {
          alertService.show('Material Name Saved');
          setMaterials(
            materials.map(material => (material.materialId === materialId ? { ...material, name: name } : material))
          );
        });
    } else {
      return curriculumService.updateVolumeHeader(volumeId, headerId, name, user.userId).then(() => {
        alertService.show('Header Name Saved');
        updateCrumbName(name);
        setData({ ...data, header: { ...data.header, header: name } });
      });
    }
  };

  const OrderingDragItem = SortableElement(props => <MaterialListItem {...props}>{props.children}</MaterialListItem>);

  return (
    <>
      <Notifications />
      <Breadcrumbs crumbs={crumbs} />
      {data.isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <Container>
          <Header>Edit Volume Header</Header>
          <FormFieldSet name={`${data.curriculum.name}: ${data.curriculum.brand}`} style={{ marginTop: '32px' }} />
          <div className="grid-container grid-col-4 grid-sm-col-8 grid-gap-32">
            <div className="grid-col-span-4 grid-sm-col-span-2">
              <Label>{data.volume.name}</Label>
              <Image src={data.volume.imageUrl} />
            </div>
            <div className="grid-col-span-4 grid-sm-col-span-6">
              <FormFieldInlineEdit
                label="Name"
                value={data.header.header}
                editText={() => setEditTextModal({ show: true, label: 'Header Name', value: data.header.header })}
              />
              <SubHeaderWithButton>
                <h4>Materials</h4>
                <OutlineButton
                  onClick={() =>
                    windowService.redirectTo(
                      `/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/volume/${volumeId}/header/${headerId}/add-material`
                    )
                  }
                >
                  <i className="icomoon-addList"></i> Add Material
                </OutlineButton>
              </SubHeaderWithButton>
              {materials.length ? (
                <MaterialList style={{ marginTop: '24px' }}>
                  <OrderingDragContainer onSortEnd={reorderMaterials} useDragHandle>
                    {materials.map((material, i) => {
                      const isPlus = material.package === 'plus';
                      return (
                        <OrderingDragItem key={i} index={i} className="hover">
                          {materials.length > 1 && <OrderingDragHandle style={{ marginRight: '24px' }} />}
                          <div className="material-text">
                            <p className="material-name">
                              {material.name}
                              {isPlus && <DeemphasizedBadge style={{ marginLeft: '8px' }}>Plus</DeemphasizedBadge>}
                            </p>
                          </div>
                          <SmallRoundedIconButton>
                            <i
                              className="fas fa-pen"
                              onClick={() =>
                                setEditTextModal({
                                  show: true,
                                  label: 'Material Name',
                                  value: material.name,
                                  materialId: material.materialId,
                                })
                              }
                            ></i>
                          </SmallRoundedIconButton>
                          <SmallRoundedIconButton style={{ marginLeft: '8px' }}>
                            <i
                              className="far fa-trash-alt"
                              onClick={() =>
                                setRemoveModal({ show: true, name: material.name, id: material.materialId })
                              }
                            ></i>
                          </SmallRoundedIconButton>
                        </OrderingDragItem>
                      );
                    })}
                  </OrderingDragContainer>
                </MaterialList>
              ) : (
                <p style={{ marginTop: '24px' }}>No Materials Added</p>
              )}
            </div>
          </div>
        </Container>
      )}
      {editTextModal.show && (
        <EditTextModal
          label={editTextModal.label}
          value={editTextModal.value}
          handleSubmit={name => {
            updateElementName(name, editTextModal.materialId)
              .catch(reason => {
                console.error(reason);
                alertService.show('An error occurred. Please try again.', 'error');
              })
              .finally(() => setEditTextModal({ show: false, label: '', value: '', materialId: '' }));
          }}
          handleDismiss={() => setEditTextModal({ show: false, label: '', value: '', materialId: '' })}
        />
      )}
      {removeModal.show && (
        <ConfirmationModal
          buttonActionText="Remove"
          buttonType="danger"
          title="Remove Material"
          prompt={<span>Are you sure you want to remove this material?</span>}
          subtext={removeModal.name}
          handleSubmit={() => {
            curriculumService
              .removeVolumeHeaderMaterial(volumeId, headerId, removeModal.id, user.userId)
              .then(() => {
                setMaterials(materials.filter(m => m.materialId !== removeModal.id));
                alertService.show('Header Material Removed');
              })
              .catch(reason => {
                console.error(reason);
                alertService.show('An error occurred. Please try again.', 'error');
              })
              .finally(() => setRemoveModal({ show: false, name: '', id: '' }));
          }}
          handleDismiss={() => setRemoveModal({ show: false, name: '', id: '' })}
        />
      )}
    </>
  );
}

ManageCurriculumVolumeHeader.propTypes = {
  user: PropTypes.object,
};

angular.module('lwNamb').component('manageCurriculumVolumeHeader', react2angular(ManageCurriculumVolumeHeader));
