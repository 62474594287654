import React from 'react';
import { react2angular } from 'react2angular';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';

// Global Components
import ProgressBar from '../components/ProgressBar';
import ProgressBarContainer from '../components/ProgressBarContainer';
import TrainingContainer from '../components/TrainingContainer';

// Helper functions
const formatDate = date => new Date(date).toLocaleDateString();

// Main Component
export default function AssignmentProgress({ user, assignment, assignmentLabel, label }) {
  const isPendingUser = user._type === 'PendingUser';
  const isAssignor = user.name === assignment.assignor;
  const isSelfAssigned = assignment.assignable && assignment.assignable.selfAssigned;
  const isWorkFlow = assignment._type === 'workflow';
  const isComplete = assignment.isComplete;
  const hasStarted = assignment.progress && assignment.progress._type !== 'NotStarted';
  const assignedOn = assignment.assignedOn && formatDate(assignment.assignedOn);
  const isShared = assignment.shared;
  const sharedOn = assignment.sharedOn && formatDate(assignment.sharedOn);
  const dueDate = assignment.dueDate && formatDate(assignment.dueDate);
  const completedOn = assignment.completedOn && formatDate(assignment.completedOn);
  const hasSteps =
    (assignment.progress && assignment.progress.steps) || (assignment.assignable && assignment.assignable.tasklists);
  const taskListProgress = !isPendingUser && !isWorkFlow && assignment.progress.percent;
  const totalItems =
    hasSteps && (isWorkFlow ? assignment.assignable.tasklists.length : assignment.progress.steps.total);
  const completedItems =
    hasSteps &&
    (isWorkFlow
      ? assignment.assignable.tasklists.filter(task => task.progress._type === 'Finished').length
      : assignment.progress.steps.completed);
  const stepLabel = hasSteps && (totalItems > 1 ? label + 's' : label);

  const AssignmentDetails = () => (
    <React.Fragment>
      {isComplete && hasSteps && (
        <li>
          {totalItems} {stepLabel} completed {completedOn ? `on ${completedOn}` : ''}
        </li>
      )}
      {isComplete && !hasSteps && (
        <li>
          {assignmentLabel} completed {completedOn ? `on ${completedOn}` : ''}
        </li>
      )}
      {!isComplete && hasSteps && (
        <li>
          {completedItems} of {totalItems} {stepLabel} complete
        </li>
      )}
      {isShared ? (
        <li>Shared on {sharedOn}</li>
      ) : (
        <li>
          {isSelfAssigned && (hasStarted ? 'Self-assigned' : `${assignmentLabel} self-assigned`)}{' '}
          {!isSelfAssigned && (hasStarted ? 'Assigned' : `${assignmentLabel} assigned`)}{' '}
          {!isSelfAssigned && (assignment.assignor ? `by ${assignment.assignor}` : '')}{' '}
          {assignedOn ? `on ${assignedOn}` : ''}
        </li>
      )}
      {dueDate && <li>Due on {dueDate}</li>}
    </React.Fragment>
  );

  return (
    <TrainingContainer>
      <h4 data-qa-hook="assignmentTrainingName">
        {isPendingUser ? (
          `${assignment.trainingName}`
        ) : (
          <a href={'#/manage-assignments/' + assignment._id}>{assignment.trainingName}</a>
        )}
      </h4>
      <ul>{isPendingUser ? <li>Will be assigned upon login</li> : <AssignmentDetails />}</ul>
      {isWorkFlow ? (
        <ProgressBarContainer>
          {assignment.assignable.tasklists.map((tasklist, index) => (
            <ProgressBar
              isWorkFlow
              key={`${assignment._id}_${index}`}
              progress={tasklist.progress ? tasklist.progress.percent : 0}
              className="wf-progress-bar"
            />
          ))}
        </ProgressBarContainer>
      ) : (
        <ProgressBar progress={taskListProgress} className="tl-progress-bar" />
      )}
    </TrainingContainer>
  );
}

AssignmentProgress.propTypes = {
  assignment: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  assignmentLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

angular.module('lwNamb').component('assignmentProgress', react2angular(AssignmentProgress));
