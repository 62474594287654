import styled from 'styled-components';
import { Grid, Breakpoints, Color, Type } from '../StyleGuide';

const TrainingContainer = styled.div`
  background: white;
  overflow: hidden;
  margin: 0;
  padding: 0;
  h4 {
    line-height: ${Type.Leading.normal};
    margin: 0 ${Grid._8} ${Grid._3} 0;
    font-weight: ${Type.Weight.bold};
    word-break: break-word;
    a {
      color: ${Color.Gray._90};
      display: block;
    }
    a:hover {
      text-decoration: underline;
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      line-height: ${Type.Leading.tall};
    }
  }
  ul {
    list-style: none;
    margin: 0 ${Grid._8} ${Grid._4} 0;
    padding: 0;
  }
  li {
    color: ${Color.Gray._70};
    display: inline-block;
    &:not(:last-of-type)::after {
      content: '•';
      margin: 0 ${Grid._3};
    }
  }
`;

export default TrainingContainer;
