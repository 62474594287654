import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Grid } from '../StyleGuide';
import { MediumDangerButton, MediumButton } from '../components/Buttons';
import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../components/ModalElements';
import { Label, FormField, FormFieldContainer, FormFieldSet, Select } from '../components/FormElements';
import ErrorMessage from '../components/ErrorMessage';
import { handleError } from '../utils/apiUtils';
import alertService from '../services/AlertService';
import formatDate from './utils/formatDate';
import LicenseDetails from './LicenseDetails';
import { ModalBodyHeader, LicenseDetailsContainer, SubscriptionModalList } from './SubscriptionModalElements';
import useCancellationForm from './hooks/useCancellationForm';

const StyledFormFieldSet = styled(FormFieldSet)`
  margin: ${Grid._5} 0 ${Grid._4} 0;

  legend {
    margin-bottom: 0;
  }
`;

const StyledFormFieldContainer = styled(FormFieldContainer)`
  margin-bottom: ${Grid._4};
`;

const CancelSubscriptionModal = ({ license, updateSubscriptionStatus, handleDismiss }) => {
  const { initialValues, validationSchema, CancellationReasonMap, cancelSubscription } = useCancellationForm();

  const handleConfirm = useCallback(
    ({ reason, explanation }) =>
      cancelSubscription(license.subscription.id, reason, explanation)
        .then(() => {
          alertService.show('Subscription Cancelled');
          updateSubscriptionStatus('Inactive');
          handleDismiss();
        })
        .catch(reason => {
          handleError(reason);
          handleDismiss();
        }),
    [cancelSubscription, handleDismiss, license.subscription.id, updateSubscriptionStatus]
  );

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleConfirm}>
      {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
        <ModalContainer dismissHandler={handleDismiss}>
          <ModalHeader>
            <h3 data-qa-hook="cancelSubscriptionModalHeader">Cancel Subscription</h3>
          </ModalHeader>
          <ModalBody data-qa-hook="cancelSubscriptionModalMessage">
            <ModalBodyHeader>Are you sure you want to cancel this subscription?</ModalBodyHeader>
            <LicenseDetailsContainer>
              <LicenseDetails license={license} />
            </LicenseDetailsContainer>
            <SubscriptionModalList>
              <li>This subscription will expire on {formatDate(license.subscription.nextRenewalDate)}</li>
              <li>You may resubscribe at any time before it expires</li>
            </SubscriptionModalList>
            <StyledFormFieldSet name="Your Feedback Matters to Us" />
            <StyledFormFieldContainer>
              <Label htmlFor="reason">Help us understand your reason for cancelling</Label>
              <Select value={values.reason} name="reason" placeholder="Select a reason" onChange={handleChange}>
                {Object.entries(CancellationReasonMap).map(([key, displayName]) => (
                  <option key={key} value={key}>
                    {displayName}
                  </option>
                ))}
              </Select>
              {errors.reason && <ErrorMessage>{errors.reason}</ErrorMessage>}
            </StyledFormFieldContainer>
            <FormField
              label="What else should we know?"
              name="explanation"
              as="textarea"
              rows="5"
              placeholder="Please provide additional details"
              value={values.explanation}
              Wrapper={StyledFormFieldContainer}
            />
          </ModalBody>
          <ModalFooter>
            <MediumDangerButton
              data-qa-hook="cancelSubscriptionModalConfirm"
              onClick={handleSubmit}
              disabled={isSubmitting}
              operating={isSubmitting}
            >
              Yes, Cancel Subscription
            </MediumDangerButton>
            <MediumButton data-qa-hook="cancelSubscriptionModalCancel" onClick={handleDismiss}>
              No
            </MediumButton>
          </ModalFooter>
        </ModalContainer>
      )}
    </Formik>
  );
};

CancelSubscriptionModal.propTypes = {
  license: PropTypes.shape({
    subscription: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
      nextRenewalDate: PropTypes.string,
    }),
  }).isRequired,
  updateSubscriptionStatus: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default CancelSubscriptionModal;
