import styled from 'styled-components';
import { Grid, Color, Type } from '../StyleGuide';

const WorkflowCourseIcon = styled.div`
  position: relative;
  background: ${props => (props.isComplete ? Color.Green._50 : Color.Gray._10)};
  border-radius: 100%;
  width: ${Grid._10};
  height: ${Grid._10};
  color: ${Color.Gray._70};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${Grid._5} auto;
  font-size: ${Type.Scale._6};
  z-index: 1;
  &::before {
    ${props =>
      props.isComplete
        ? `font-family: 'Font Awesome 5 Free'; font-weight: 900; content: '\f00c'; color: #fff`
        : `font-weight: ${Type.Weight.bold}; content: '${props.number}'; color: ${Color.Gray._70}`}
  }
`;

export default WorkflowCourseIcon;
