import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { MediumButton, MediumPrimaryButton } from '../../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../../components/ModalElements';
import { Label } from '../../components/FormElements';
import FileUploader from '../../components/FileUploader';
import { useUser } from '../../authentication';
import curriculumService from '../../services/curriculumService';
import { getFileType } from '../../utils/fileUtils';

const AddSectionMediaModal = ({ curriculumId, sectionType, addMediaToSection, onError, handleDismiss }) => {
  const user = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState();

  const allowedMediaTypes = sectionType === 'video' ? ['video'] : ['image', 'audio', 'video'];

  const determineUploadLocationByFile = fileToUpload => {
    switch (getFileType(fileToUpload.type)) {
      case 'audio':
      case 'video':
        return 'curriculum-storage/videos';
      default:
        return 'curriculum-storage/timeline/images';
    }
  };

  const processFile = () => {
    if (getFileType(file.type) === 'image') return Promise.resolve();

    return curriculumService.saveCurriculumVideo(file.id, curriculumId, file.name, '', user.userId);
  };

  const addSectionMedia = () => {
    setIsSubmitting(true);

    processFile()
      .then(() => addMediaToSection(file))
      .catch(onError);
  };

  const onFileRemoved = file => {
    setFile(file);
    setIsSubmitting(false);
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>Add Section Media</h3>
      </ModalHeader>
      <ModalBody>
        <Label>Upload Media</Label>
        <FileUploader
          userId={user.userId}
          onFileUploaded={setFile}
          onFileRemoved={onFileRemoved}
          uploadLocation={determineUploadLocationByFile}
          accepts={allowedMediaTypes}
        />
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton
          data-qa-hook="addSectionMediaConfirm"
          onClick={addSectionMedia}
          disabled={!file || isSubmitting}
          operating={isSubmitting}
        >
          Add Section Media
        </MediumPrimaryButton>
        <MediumButton onClick={handleDismiss}>Cancel</MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

AddSectionMediaModal.propTypes = {
  curriculumId: PropTypes.string.isRequired,
  sectionType: PropTypes.string.isRequired,
  addMediaToSection: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default AddSectionMediaModal;
