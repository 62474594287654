import React, { useState, ReactNode } from 'react';
import { MediumPrimaryButton, MediumDangerButton, MediumButton } from './Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from './ModalElements';

type ConfirmationModalProps = {
  title: string;
  prompt?: ReactNode | string;
  subtext?: string;
  buttonActionText?: string;
  buttonType?: 'primary' | 'danger';
  handleDismiss: () => void;
  handleSubmit: () => void;
  children?: ReactNode;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  prompt,
  subtext,
  buttonActionText,
  buttonType,
  handleDismiss,
  handleSubmit,
  children,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const ButtonComponent = buttonType === 'danger' ? MediumDangerButton : MediumPrimaryButton;

  const onSubmitClick = () => {
    setIsSubmitting(true);
    handleSubmit();
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3 data-qa-hook="modalHeader">{title}</h3>
      </ModalHeader>
      <ModalBody data-qa-hook="modalMessage">
        <h4>{prompt}</h4>
        <p className="modal-component-subtext">{subtext}</p>
        {children}
      </ModalBody>
      <ModalFooter>
        <ButtonComponent
          data-qa-hook="modalConfirm"
          onClick={onSubmitClick}
          disabled={isSubmitting}
          operating={isSubmitting}
        >
          {buttonActionText ? `Yes, ${buttonActionText}` : 'Yes'}
        </ButtonComponent>
        <MediumButton data-qa-hook="modalCancel" onClick={handleDismiss}>
          No
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

export default ConfirmationModal;
