import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { MediumButton, MediumPrimaryButton } from '../../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../../components/ModalElements';
import { Label, FormFieldContainer, FormField, Select } from '../../components/FormElements';
import useSessionTimeline from '../../hooks/useSessionTimeline';

const AddSegmentModal = ({ segment, defaultGroup, defaultLeader, isFlexOption, onSubmit, handleDismiss }) => {
  const { segmentGroupMap, segmentLeaderMap, segmentActivityMap } = useSessionTimeline();

  const isEdit = !!segment?.segmentId;
  const isFlexSegment = !!segment?.isFlex;

  const shouldShowFlexActivity = (isEdit && isFlexSegment) || (!isEdit && !isFlexOption);

  const entityType = isFlexOption ? 'Flex Option' : 'Segment';
  const ModalHeaderText = `${isEdit ? 'Edit' : 'Add'} ${entityType}`;
  const ConfirmationButtonText = `${isEdit ? 'Save' : 'Add'} ${entityType}`;

  const submit = values => {
    if (isFlexSegment) delete values.activity;

    onSubmit(values);
  };

  const getFirstKey = obj => Object.keys(obj)[0];

  const getInitialValues = () => {
    if (isEdit) {
      if (isFlexSegment) return { ...segment, activity: 'Flex' };

      return segment;
    }

    const initialValues = {
      name: '',
      group: defaultGroup || getFirstKey(segmentGroupMap),
      leader: defaultLeader || getFirstKey(segmentLeaderMap),
      duration: 5,
      activity: getFirstKey(segmentActivityMap),
    };

    if (isFlexOption) return { ...initialValues, ...segment };

    return initialValues;
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader data-qa-hook="addEditSegmentModal">
        <h3>{ModalHeaderText}</h3>
      </ModalHeader>
      <Formik
        initialValues={getInitialValues()}
        enableReinitialize={true}
        validationSchema={Yup.object({
          name: Yup.string().max(64, 'Name is too long').required('A name is required'),
          duration: Yup.number().min(0, 'Duration can not be negative'),
        })}
        onSubmit={submit}
      >
        {({ dirty, values, isSubmitting, handleChange }) => (
          <Form id="add-session-form">
            <ModalBody>
              <div className="grid-container grid-col-4 grid-col-gap-24">
                <div className="grid-col-span-4">
                  <FormField
                    data-qa-hook="addSegmentName"
                    label="Segment Name"
                    name="name"
                    type="text"
                    placeholder="Enter Segment Name"
                  />
                </div>
                <div className="grid-sm-col-span-2 grid-col-span-4">
                  <FormFieldContainer>
                    <Label htmlFor="group">Group</Label>
                    <Select
                      value={values.group}
                      id="group"
                      name="group"
                      aria-label="Select Group"
                      onChange={handleChange}
                      disabled={isFlexOption}
                    >
                      {Object.entries(segmentGroupMap).map(([key, displayName]) => (
                        <option key={key} value={key}>
                          {displayName}
                        </option>
                      ))}
                    </Select>
                  </FormFieldContainer>
                </div>
                <div className="grid-sm-col-span-2 grid-col-span-4">
                  <FormFieldContainer>
                    <Label htmlFor="leader">Leader</Label>
                    <Select
                      value={values.leader}
                      id="leader"
                      name="leader"
                      aria-label="Select Leader"
                      onChange={handleChange}
                      disabled={isFlexOption}
                    >
                      {Object.entries(segmentLeaderMap).map(([key, displayName]) => (
                        <option key={key} value={key}>
                          {displayName}
                        </option>
                      ))}
                    </Select>
                  </FormFieldContainer>
                </div>
                <div className="grid-sm-col-span-2 grid-col-span-4">
                  <FormField
                    data-qa-hook="addDuration"
                    label="Duration (minutes)"
                    name="duration"
                    type="number"
                    placeholder="Enter Duration Minutes"
                    min="0"
                    disabled={isFlexOption}
                  />
                </div>
                <div className="grid-sm-col-span-2 grid-col-span-4">
                  <FormFieldContainer>
                    <Label htmlFor="activity">Type</Label>
                    <Select
                      value={values.activity}
                      id="activity"
                      name="activity"
                      aria-label="Select Activity"
                      onChange={handleChange}
                      disabled={isFlexSegment}
                    >
                      {Object.entries(segmentActivityMap).map(([key, displayName]) => (
                        <option key={key} value={key}>
                          {displayName}
                        </option>
                      ))}
                      {shouldShowFlexActivity && (
                        <option key="Flex" value="Flex">
                          Flex
                        </option>
                      )}
                    </Select>
                  </FormFieldContainer>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              {isEdit && !dirty ? (
                <MediumPrimaryButton data-qa-hook="addSegmentConfirm" onClick={() => onSubmit()}>
                  {ConfirmationButtonText}
                </MediumPrimaryButton>
              ) : (
                <MediumPrimaryButton
                  data-qa-hook="addSegmentConfirm"
                  type="submit"
                  disabled={isSubmitting}
                  operating={isSubmitting}
                >
                  {ConfirmationButtonText}
                </MediumPrimaryButton>
              )}
              <MediumButton data-qa-hook="addSegmentCancel" onClick={handleDismiss}>
                Cancel
              </MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};

AddSegmentModal.propTypes = {
  segment: PropTypes.object,
  defaultGroup: PropTypes.string,
  defaultLeader: PropTypes.string,
  isFlexOption: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default AddSegmentModal;
