import React, { useState } from 'react';
import styled from 'styled-components';
import Breadcrumbs from './Breadcrumbs';
import { Grid, Type } from '../StyleGuide';
import { handleError } from '../utils/apiUtils';
import orgService from '../services/orgService';
import uploadService from '../services/uploadService';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import useOrg from '../hooks/useOrg';
import { useUser } from '../authentication';
import useAngularScope from '../hooks/useAngularScope';
import ConfirmationModal from './ConfirmationModal';
import { RaisedContainer } from '../components/Layout';
import { Radio, FormFieldSet, FormFieldContainer, FormGroupContainer, Label } from '../components/FormElements';
import SettingsHeader from './SettingsHeader';
import LoadingState from './LoadingState';
import useModal from '../hooks/useModal';
import OrgLogoSelector from './OrgLogoSelector';
import { PrimaryButton } from './Buttons';
import capitalizeFilter from '../filters/capitalize-filter';
import WhiteLabel from '../filters/WhiteLabel';
import { ImageCropUtils } from './ImageCrop';
import uuid from '../services/uuid';
import ErrorMessage from './ErrorMessage';

const SettingsNote = styled.p`
  margin-bottom: ${Grid._6};
  color: ${Type.Color.medium};
`;

const StyledFormGroupContainer = styled(FormGroupContainer)`
  display: flex;
  justify-content: space-between;
`;

const NoLicenseModal = ({ handleSubmit, handleDismiss }) => (
  <ConfirmationModal
    buttonActionText="Get Started"
    buttonType="primary"
    title="Get Started"
    prompt={
      <>
        When you purchase and pair a <strong>team</strong> subscription, you can personalize the site and notification
        emails with your custom icon."
      </>
    }
    handleSubmit={handleSubmit}
    handleDismiss={handleDismiss}
  />
);

const ContentContainer = styled(RaisedContainer)`
  margin: ${Grid._10} auto;
  padding: ${Grid._6};
`;

export default function OrganizationSettings() {
  const { userId } = useUser();
  const [angularScope, updateAngularScope] = useAngularScope();
  const [data, setData] = useState({ isLoading: true, isError: false, isSaving: false });
  const crumbs = [{ name: 'Organization', route: '#/org' }, { name: `Organization Settings` }];
  const capitalizedOrgLabel = capitalizeFilter(WhiteLabel.labels.org);

  const org = useOrg(({ org, isLoading, error }) => {
    if (error) {
      console.error('Unable to retrieve org', error);
      return setData({ isError: true });
    }

    if (isLoading) return;

    orgService
      .hasSubscription(org.id)
      .then(hasSubscription =>
        setData(prev => ({
          ...prev,
          hasSubscription,
          showSearch: org.showSearch,
          isLoading: false,
        }))
      )
      .catch(error => {
        console.error(error);
        setData({ isLoading: false, isError: true });
      });
  });

  const updateAngularOrgLogo = url => updateAngularScope(scope => (scope.orgLogo = url));

  const setIsSaving = value => setData(prev => ({ ...prev, isSaving: value }));
  const setLogo = value => setData(prev => ({ ...prev, logo: value }));

  const resetLogo = () =>
    orgService
      .resetOrgLogo(org.id, userId)
      .then(() => {
        setLogo();
        updateAngularOrgLogo();
        alertService.show(`${capitalizedOrgLabel} Logo Removed`);
      })
      .catch(handleError)
      .finally(() => setIsSaving(false));

  const saveLogo = () => {
    setIsSaving(true);

    const { logo } = data;

    if (!logo) return resetLogo();

    ImageCropUtils.convertCroppedImageToBlob(logo)
      .then(blob => uploadService.uploadOrgLogo(org.id, blob))
      .then(() => orgService.setOrgLogo(org.id, userId))
      .then(response => {
        setLogo();
        updateAngularOrgLogo(`${getApiUrl()}/v1/images/org/${response.logo}?key=${uuid.generate()}`);
        alertService.show(`${capitalizedOrgLabel} Logo Saved`);
      })
      .catch(handleError)
      .finally(() => setIsSaving(false));
  };

  const handlePreferenceChange = preference => {
    if (preference === data.showSearch) return;

    setData(prev => ({ ...prev, showSearch: preference }));
    updateAngularScope(scope => (scope.org.showSearch = preference));

    const orgServiceFn = preference ? orgService.unhideSearch : orgService.hideSearch;
    orgServiceFn(org.id, userId)
      .then(() => alertService.show('Settings Saved'))
      .catch(error => {
        handleError(error);

        setData(prev => ({ ...prev, showSearch: !preference }));
        updateAngularScope(scope => (scope.org.showSearch = !preference));
      });
  };

  const purchaseLicense = () => windowService.redirectToPurchase();

  const [modal, openModal] = useModal((type, payload, dismissModal) =>
    type === 'unlock' ? <NoLicenseModal handleSubmit={purchaseLicense} handleDismiss={dismissModal} /> : null
  );

  const getUnlockFn = () => (data.hasSubscription ? null : () => openModal('unlock'));

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      {data.isLoading ? (
        <LoadingState />
      ) : data.isError ? (
        <ErrorMessage>
          A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
        </ErrorMessage>
      ) : (
        <ContentContainer data-qa-hook="organizationSettingsView">
          <div className="grid-col-span-4">
            <SettingsHeader title={org.name} subtitle="Organization Settings" icon={<i className="fas fa-cog" />} />
          </div>
          <div className="grid-container grid-col-3 grid-sm-col-6 grid-gap-40 mt-40">
            <div className="grid-col-span-3">
              <OrgLogoSelector
                initialLogo={angularScope.orgLogo}
                onLogoUpdate={setLogo}
                onUnlockClick={getUnlockFn()}
              />
              <PrimaryButton
                data-qa-hook="saveChurchLogo"
                onClick={saveLogo}
                disabled={data.isSaving || data.logo === undefined}
                operating={data.isSaving}
              >
                Save {capitalizedOrgLabel} Logo
              </PrimaryButton>
            </div>
            <div className="grid-col-span-3">
              <FormFieldSet name="Advanced Settings" />
              <FormFieldContainer>
                <SettingsNote>
                  If your organization wants to prevent those being trained from searching for training on their own,
                  toggling this preference to "no" will remove Training from the menu on both desktop and mobile views.
                  Administrators can still access Training from the Organization page and will have access to all
                  Ministry Grid content when building training or creating Easy Links.
                </SettingsNote>
                <StyledFormGroupContainer>
                  <Label>Show Training in Menu</Label>
                  <div className="controls">
                    <Radio
                      id="showSearch-yes"
                      name="showSearch"
                      onChange={() => handlePreferenceChange(true)}
                      checked={data.showSearch}
                    />
                    <label htmlFor="showSearch-yes">Yes</label>
                    <Radio
                      id="showSearch-no"
                      name="showSearch"
                      onChange={() => handlePreferenceChange(false)}
                      checked={!data.showSearch}
                    />
                    <label htmlFor="showSearch-no">No</label>
                  </div>
                </StyledFormGroupContainer>
              </FormFieldContainer>
            </div>
          </div>
        </ContentContainer>
      )}
      {modal}
    </>
  );
}

OrganizationSettings.propTypes = {};
