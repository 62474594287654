angular.module('lwNamb').factory(
  'thirdPartyVideoService',

  [
    '$sce',
    function($sce) {
      return {
        getVideoDataFromUrl: function(url) {
          return getData(url);
        },
        getThirdPartyData: function(url) {
          return getData(url);
        }
      };

      function getData(url) {
        // - Supported YouTube URL formats:
        //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
        //   - http://youtu.be/My2FRPA3Gf8
        //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
        // - Supported Vimeo URL formats:
        //   - http://vimeo.com/25451551
        //   - http://player.vimeo.com/video/25451551
        // - Also supports relative URLs:
        //   - //player.vimeo.com/video/25451551

        var regex = /(http:\/\/|https:\/\/|)(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%\-/]*)/;
        var regex_time = /(\&|\?)(t|start)=([A-Za-z0-9._%-]*)/ig;
        var matches = regex.exec(url);
        var url_time = regex_time.exec(url);
      
        var type = '';
        if (matches !== null) {
          if (matches[3].indexOf('youtu') > -1) {
            type = 'youtube';
          } else if (matches[3].indexOf('vimeo') > -1) {
            type = 'vimeo';
          }

          return {
            type: type,
            id: matches[6],
            url: matches[0] + (url_time ? '?' + url_time[2] + '=' + url_time[3] : ''),
            time: url_time ? url_time[3] : undefined,
          };
        } else return undefined;
      }
    },
  ]
);
