import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../components/ModalElements';
import { MediumButton, MediumPrimaryButton } from '../components/Buttons';

import uuid from '../services/uuid';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import assessmentService from '../services/assessmentService';

import { useUser } from '../authentication';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';

const CopyQuestionModal = ({ assessment, assessmentId, setCopyQuestionModalOpen, question }) => {
  const user = useUser();
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();
  const [status, setStatus] = useState({ isSubmitting: false });
  const [updatedPossibleAnswers, setUpdatedPossibleAnswers] = useState([]);

  const updatePossibleAnswers = () => {
    const possibleAnswersWithAnswerText = [];
    question.possibleAnswers.map(question => {
      possibleAnswersWithAnswerText.push({
        id: question.id.id,
        answer: question.value[0].value,
        isCorrect: question.isCorrect,
      });
    });
    setUpdatedPossibleAnswers(possibleAnswersWithAnswerText);
  };

  useEffect(() => {
    updatePossibleAnswers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyQuestion = () => {
    const newQuestionId = uuid.generate();
    setStatus({ isSubmitting: true });
    assessmentService
      .addQuestion(
        assessmentId,
        assessment.ownerId,
        newQuestionId,
        question.questionValue[0].value,
        question.questionType,
        updatedPossibleAnswers,
        user.userId,
        question.displayType
      )
      .then(() => {
        alertService.showOnNextPage('Question Duplicated');
        windowService.redirectTo(
          `${manageTrainingRoutePrefix}/assessments/${assessmentId}/questions/${newQuestionId}?questionType=${question.questionType}`
        );
      })
      .catch(error => {
        console.error(error);
        alertService.showError('Error duplicating question. Please try again.');
        setCopyQuestionModalOpen(false);
        setStatus({ isSubmitting: false });
      })
      .finally(() => setStatus({ isSubmitting: false }));
  };

  return (
    <ModalContainer dismissHandler={() => setCopyQuestionModalOpen(false)}>
      <ModalHeader data-qa-hook="copyQuestionModal">
        <h3>Copy Question</h3>
      </ModalHeader>
      <ModalBody>
        <p>Copying this question will take you directly to the new question to begin editing.</p>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton
          data-qa-hook="continue"
          disabled={status.isSubmitting}
          operating={status.isSubmitting}
          onClick={() => copyQuestion()}
        >
          Copy Question
        </MediumPrimaryButton>
        <MediumButton data-qa-hook="cancel" onClick={() => setCopyQuestionModalOpen(false)}>
          Cancel
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

CopyQuestionModal.propTypes = {
  setCopyQuestionModalOpen: PropTypes.func.isRequired,
  assessment: PropTypes.object.isRequired,
  assessmentId: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
};

export default CopyQuestionModal;
