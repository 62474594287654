angular.module('lwNamb').controller(
  'selfAssignLinkCtrl',

  [
    '$scope',
    '$location',
    '$log',
    '$routeParams',
    '$q',
    'taskListInstanceService',
    'taskListService',
    'trainingService',
    'userService',
    function (
      $scope,
      $location,
      $log,
      $routeParams,
      $q,
      taskListInstanceService,
      taskListService,
      trainingService,
      userService
    ) {
      var trainingId = $routeParams.trainingId;

      function goToTraining(trainingType, trainingId) {
        if ($routeParams.home) {
          $location.url('/dashboard-training');
        }
        else {
          if (trainingType === 'Workflow') {
            $location.url('/training-steps/' + trainingId + '?isWorkflow=true');
          } else {
            $location.url('/training-steps/' + trainingId);
          }
        }
      }

      function selfAssign(trainingItem, trainingType, user, showBanner) {
        trainingService.selfAssign(user.userId, trainingItem, user.lastSelectedAccount).then(
          function (res) {
            if (showBanner) {
              localStorage.setItem('showBanner', 'Added To Your Dashboard');
            }
            goToTraining(trainingType, res[0].instanceId);
          },
          function (error) {
            $log.error(error);
            $scope.msg = 'An error occurred. Please try again.';
          }
        );
      }

      userService.user().then(
        function (user) {
          if ($routeParams.noLogout) {
            $q.all([trainingService.getTraining(trainingId), taskListInstanceService.getTrainingOverview(user.userId)]).then(
              function (result) {
                var training, trainingType;
                var trainingItem = { trainingId: trainingId, trainingType: result[0].trainingType === 'Workflow' ? 'Workflow' : 'Tasklist' };
                var existingAssignedTraining = result[1].find(function (training) {
                  return training.originalTrainingId === trainingId;
                });
                var existingSelfAssignedTraining = result[1].find(function (training) {
                  return training.originalTrainingId === trainingId && training.selfAssigned;
                });
                if ($routeParams.reassign !== '1') {
                  if (existingAssignedTraining) {
                    training = { id: existingAssignedTraining.instanceId };
                    trainingType = existingAssignedTraining._type === 'ActiveCourseOverview' ? 'TaskList' : 'Workflow';
                    goToTraining(trainingType, existingAssignedTraining.instanceId);
                  } else {
                    localStorage.setItem('unAssignOnBack', true);
                    selfAssign(trainingItem, trainingItem.trainingType, user);
                  }
                } else if (existingSelfAssignedTraining) {
                  training = { id: existingSelfAssignedTraining.instanceId };
                  trainingType = existingSelfAssignedTraining._type === 'ActiveCourseOverview' ? 'TaskList' : 'Workflow';
                  if ($routeParams.reassign === '1') {
                    taskListService.unassignMyTraining(training, trainingType, user.userId);
                    selfAssign(trainingItem, trainingItem.trainingType, user, $routeParams.reassign === '1');
                  } else {
                    goToTraining(trainingType, existingSelfAssignedTraining.instanceId);
                  }
                } else {
                  selfAssign(trainingItem, trainingItem.trainingType, user, $routeParams.reassign === '1');
                }
              },
              function (error) {
                $log.error(error);
                $scope.msg =
                  "The content you're looking for has been removed. Please contact Lifeway Customer Service for more information using the chat icon at the lower right.";
              }
            );
          } else {
            $location.search('noLogout', 1);
            $scope.logoutAndRedirectToLifeWay('login', 'login?route=' + encodeURIComponent($location.url().substring(1)), user.logInEmail);
          }
        },
        function() {
          $location.search('noLogout', 1);
          $location.url('login?route=' + encodeURIComponent($location.url().substring(1)));
        }
      );
    },
  ]
);
