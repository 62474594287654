import React, { useState } from 'react';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { Container, FixedHeaderContainer, FixedHeaderContent, RaisedContainer } from '../components/Layout';
import {
  PrimaryButton,
  OutlineButton,
  RoundedIconButton,
  SmallPrimaryRoundedIconButton,
  SmallRadioRoundedIconButton,
} from '../components/Buttons';
import { Grid, Breakpoints, Type } from '../StyleGuide';
import windowService from '../services/windowService';
import licenseService from '../services/licenseService';
import orgService from '../services/orgService';
import alertService from '../services/AlertService';
import curriculumService from '../services/curriculumService';
import userService from '../services/userService';
import capitalizeFilter from '../filters/capitalize-filter';
import WhiteLabel from '../filters/WhiteLabel';
import { useUser } from '../authentication';
import useOrg from '../hooks/useOrg';
import useAngularScope from '../hooks/useAngularScope';
import { createCurriculum } from '../hooks/useCurriculum';
import useEventTracking from '../hooks/useEventTracking';
import ActionableTitleBar from '../components/ActionableTitleBar';
import { ChurchImage, ListItemTitleContainer, ListItemImageContainer } from '../components/PairingElements';
import cacheService from '../services/cacheService';
import purchaseService from '../services/purchaseService';

const ContentContainer = styled(RaisedContainer)`
  margin: ${Grid._10} 0 ${Grid._6};
  padding: ${Grid._4};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._5} ${Grid._8} ${Grid._5} ${Grid._4};
  }
`;

const SectionLabel = styled.p`
  font-weight: ${Type.Weight.semibold};
  margin: ${Grid._6} 0 ${Grid._4} 0;
`;

const Footer = styled.div`
  text-align: center;
  padding-bottom: ${Grid._10};

  div:first-child {
    width: auto;
    margin-bottom: ${Grid._5};

    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      width: max-content;
    }
  }

  .steps-primary-button {
    margin: 0;
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    .steps-primary-button {
      margin: 5px;
    }
  }
`;

const LicenseList = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: ${Grid._6};
`;

const ListItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: ${Grid._4};

  > div:first-child {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: ${Grid._4};
    grid-row-gap: ${Grid._3};

    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      grid-template-columns: auto 1fr;
    }
  }

  > div:last-child {
    display: grid;
    place-content: start;
    padding-top: ${Grid._4};

    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      place-content: center;
      padding-top: 0;
    }
  }
`;

const ChurchListItemContainer = styled(ListItemContainer)`
  grid-template-columns: 1fr;

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 1fr auto;
  }

  > div:first-child {
    grid-template-columns: auto 1fr;
  }

  > div:last-child {
    place-content: initial;

    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      place-content: center;
    }
  }
`;

const CenteredImageContainer = styled(ListItemImageContainer)`
  height: 77px;
  width: 160px;
  overflow: hidden;

  img {
    height: 100%;
    width: auto;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
`;

const LogoImageContainer = styled(CenteredImageContainer)`
  display: grid;
  place-content: center;

  img {
    height: 36px;
    max-width: 128px;
  }
`;

const ChurchListItem = ({ church, onButtonClick }) => (
  <ChurchListItemContainer>
    <div>
      <ChurchImage church={church} />
      <ListItemTitleContainer>
        <h4>{church.name}</h4>
        <p>{church.members.length} People</p>
      </ListItemTitleContainer>
    </div>

    <div>
      <OutlineButton onClick={onButtonClick}>Switch {capitalizeFilter(WhiteLabel.labels.org)}</OutlineButton>
    </div>
  </ChurchListItemContainer>
);

const LicenseListItem = ({ license, onToggle }) => {
  const curriculum = createCurriculum(license.curriculum?.brand.code);

  return (
    <ListItemContainer>
      <div>
        <>
          {curriculum ? (
            <CenteredImageContainer backgroundColor={curriculum.brand.background}>
              <img alt={curriculum.brand.name} src={curriculum.brand.imgSrc} />
            </CenteredImageContainer>
          ) : (
            <LogoImageContainer>
              <img alt={WhiteLabel.name} src={WhiteLabel.logoSmSrc || WhiteLabel.logoSrc} />
            </LogoImageContainer>
          )}
        </>
        <ListItemTitleContainer>
          <h4>{license.subscription?.item.title || license.item.title}</h4>
          {license.subscription?.period ? (
            <p>{license.subscription.period} Subscription</p>
          ) : license.endDate ? (
            <p>Ends {format(parseISO(license.endDate), 'MMMM d, y')}</p>
          ) : (
            <></>
          )}
        </ListItemTitleContainer>
      </div>
      <div>
        {license.selected ? (
          <SmallPrimaryRoundedIconButton onClick={onToggle}>
            <i className="fas fa-check" />
          </SmallPrimaryRoundedIconButton>
        ) : (
          <SmallRadioRoundedIconButton onClick={onToggle}>
            <i />
          </SmallRadioRoundedIconButton>
        )}
      </div>
    </ListItemContainer>
  );
};

export default function RecentPurchases() {
  const [angularScope] = useAngularScope();
  const user = useUser();
  const [org, setOrg] = useState();
  const [status, setStatus] = useState({ isLoading: true, isError: false, isPairing: false });
  const [licenses, setLicenses] = useState([]);
  const [uniqueLicenses, setUniqueLicenses] = useState([]);
  const { trackEvent, trackingEvents } = useEventTracking();
  const submitDisabled = status.isPairing || !licenses.find(l => l.selected);

  useOrg(({ org: _org, isLoading, error }) => {
    if (error) {
      console.error('Unable to retrieve org', error);
      return setStatus({ isError: true });
    }

    if (isLoading) return;

    const cachedLicenses = cacheService.get('pairingLicenses');

    const licensePromise = cachedLicenses ? Promise.resolve(cachedLicenses) : fetchUnpairedLicenses();

    setOrg(_org);

    licensePromise
      .then(unpairedLicenses => {
        updateLicenses(unpairedLicenses);

        orgService
          .getLicensePairingOrgs()
          .then(pairingOrgs => {
            if (pairingOrgs.length === 1) {
              // auto pair if only one org
              if (user.lastSelectedAccount !== pairingOrgs[0]._id.id) {
                userService.switchOrg(user.userId, pairingOrgs[0]._id.id);
              } else {
                pairLicenses(unpairedLicenses);
              }
            } else {
              return Promise.reject();
            }
          })
          .catch(() => {
            setStatus({ ...status, isLoading: false });
          });
      })
      .catch(error => {
        console.error(error);
        setStatus({ isLoading: false, isError: true });
      });
  });

  const updateLicenses = newLicenses => {
    setLicenses(newLicenses);
    setUniqueLicenses(licenseService.filterUniqueSubscriptions(newLicenses));
  };

  const storePairedItemNumbers = pairedLicenses =>
    cacheService.set(
      'pairedItemNumbers',
      pairedLicenses.map(l => l.item.number)
    );

  const hasCurriculumLicense = pairedLicenses => !!pairedLicenses.find(licenseService.isCurriculumLicense);

  const isSingleTraining = licenses => {
    var gridLicenseItemNumber = '005801686';
    return licenses && licenses.length == 1 && licenses[0].item && licenses[0].item.number !== gridLicenseItemNumber;
  };

  const redirectToSuccessDestination = pairedLicenses => {
    const itemRedirectLocation = purchaseService.getRedirectLocation(pairedLicenses.map(l => l.item?.number));

    if (itemRedirectLocation) {
      cacheService.remove('pairedItemNumbers');
      alertService.showAngularAlert('Purchase Paired');
      return windowService.redirectTo(itemRedirectLocation);
    }

    if (hasCurriculumLicense(pairedLicenses)) return windowService.redirectTo('/bible-studies');

    if (isSingleTraining(pairedLicenses)) {
      cacheService.remove('pairedItemNumbers');
      alertService.showAngularAlert('Purchase Paired');
      return windowService.redirectTo('/dashboard-training');
    }

    return windowService.redirectTo('/catalog');
  };

  const pairLicenses = allLicenses => {
    setStatus({ ...status, isPairing: true });

    const selectedLicenses = allLicenses.filter(l => !!l.selected);
    licenseService
      .pairLicenses(selectedLicenses, user.lastSelectedAccount, user.userId)
      .then(() => {
        selectedLicenses.forEach(l => trackEvent(trackingEvents.LICENSE_PAIRED, l));
        angularScope.dropPairingCaches();
        storePairedItemNumbers(selectedLicenses);
        redirectToSuccessDestination(selectedLicenses);
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
        setStatus({ ...status, isPairing: false });
      });
  };

  const fetchUnpairedLicenses = () =>
    Promise.all([licenseService.getAllUnpairedLicenses(), curriculumService.getPricing()]).then(
      ([[unpairedGroupLicenses], allCurriculum]) =>
        unpairedGroupLicenses.map(lic => ({
          ...lic,
          selected: true,
          curriculum: licenseService.getCorrespondingCurriculum(lic, allCurriculum),
        }))
    );

  const doLicensesMatch = (l1, l2) =>
    l1.id === l2.id || (l1.subscription?.id && l2.subscription?.id && l1.subscription?.id === l2.subscription?.id);

  const toggle = license =>
    updateLicenses(licenses.map(l => (doLicensesMatch(l, license) ? { ...l, selected: !license.selected } : l)));

  const redirectToSelectOrg = () => {
    cacheService.set('pairingLicenses', licenses, 5);
    windowService.redirectTo('#/pairing/select-org');
  };

  return (
    <FixedHeaderContainer fullViewport>
      {status.isLoading ? (
        <LoadingState />
      ) : status.isError ? (
        <ErrorMessage>
          A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
        </ErrorMessage>
      ) : (
        <>
          <ActionableTitleBar
            title="Confirm Your Recent Purchases"
            subtitle={
              status.isLoading ? (
                <span>&nbsp;</span>
              ) : (
                `We found ${uniqueLicenses.length} new ${uniqueLicenses.length === 1 ? 'purchase' : 'purchases'}`
              )
            }
            leftButton={
              <RoundedIconButton data-qa-hook="back" onClick={() => windowService.redirectTo('#/pairing')}>
                <i className="fas fa-arrow-left fa-fw"></i>
                Back
              </RoundedIconButton>
            }
          />
          <FixedHeaderContent>
            <Container>
              <ContentContainer>
                <div>
                  {org && <ChurchListItem church={org} onButtonClick={redirectToSelectOrg} />}
                  <SectionLabel>New Purchases</SectionLabel>
                  <LicenseList>
                    {uniqueLicenses.map((l, i) => (
                      <LicenseListItem license={l} onToggle={() => toggle(l)} key={i} />
                    ))}
                  </LicenseList>
                </div>
              </ContentContainer>
              <Footer>
                {!!licenses.find(l => !l.selected) && (
                  <ErrorMessage>
                    Any unchecked purchases will not be connected to this {WhiteLabel.labels.org}
                  </ErrorMessage>
                )}
                <PrimaryButton
                  onClick={() => pairLicenses(licenses)}
                  disabled={submitDisabled}
                  operating={status.isPairing}
                >
                  Connect Purchases
                </PrimaryButton>
              </Footer>
            </Container>
          </FixedHeaderContent>
        </>
      )}
    </FixedHeaderContainer>
  );
}
