import apiClient from './apiClient';
import cacheableRequest from './cacheableRequest';
import submitCommand from './submitCommand';
import cacheService from './cacheService';
import uuid from './uuid';

const transformUser = user => {
  const formattedUser = {
    ...user,
    loggedIn: true,
    logInEmail: user.email,
    pristine: user.firstLogin,
    author: user.catalogs,
    isAuthor: false,
    permissions: [],
  };

  if (user.roles) {
    formattedUser.permissions = Array.from(new Set(user.roles.flatMap(r => r.permissions.flatMap(rp => rp.access))));
  }

  return formattedUser;
};

const userService = {
  getUserOrgs: () =>
    apiClient
      .get('/v2/user/organizations')
      .then(response => response.data)
      .catch(reason => reason),

  switchOrg: (userId, orgId) =>
    submitCommand(userId, { id: userId, accountId: orgId }, 'SelectAccount', 'AccountSelected'),

  getProfile: userId => cacheableRequest(`/v1/users/${userId}`).then(response => response.data),
  savePreferences: (userId, showAllInfoBoxes) => {
    cacheService.remove('/preferences');
    return apiClient.post('/preferences', { _id: { id: userId }, showAllInformationBoxes: showAllInfoBoxes });
  },

  impersonateUser: email => {
    return apiClient.post(`/v1/impersonate?email=${email}`).then(response => {
      if (response.status !== 200) {
        return Promise.reject('Invalid response');
      }

      // TODO: refactor JWT into a service
      if (response.headers && response.headers['set-authorization']) {
        const token = response.headers['set-authorization'];
        if (JWT.validate(JWT.read(token))) JWT.keep(token);
      }

      const transformedUser = transformUser(response.data);

      const angularRoot = angular.element(document.body);
      angularRoot.injector().get('$localStorage').user = transformedUser;
      angularRoot.injector().get('$rootScope').$broadcast('UserLoggedIn', transformedUser);

      return transformedUser;
    });
  },

  getPreferences: () => cacheableRequest('/preferences').then(response => response.data),

  setProfileImage: userId => {
    const key = `${userId}profile.png`;
    const cmd = { id: userId, imageUrl: key, initiatingUserId: userId };

    return submitCommand(userId, cmd, 'UpdateUserProfileImage', 'UserProfileImageUpdated');
  },

  sendFeedbackEmail: (userId, name, email, subject, message, orgName = '') => {
    const id = uuid.generate();
    const gitHash = getCommitHash();
    const cmd = {
      id,
      userId,
      name,
      email,
      subject,
      description: message,
      callbackHost: window.location.origin,
      orgName,
      browserInfo: `${navigator.userAgent} gitHash: ${gitHash}`,
    };
    return submitCommand(id, cmd, 'SendFeedbackEmail', 'FeedbackEmailSent', 'EmailError');
  },

  getUserIdByEmail: email =>
    cacheableRequest(`/users/id?email=${encodeURIComponent(email)}`).then(response => response.data),

  getUserById: userId => cacheableRequest(`/v1/users/${userId}`).then(response => response.data),

  getProfileImageUrlById: id => `${apiClient.getConfig().baseURL}/v1/images/profile/${id}profile.png`,

  forgetUser: (userId, email, initiatingUserId) =>
    submitCommand(
      userId,
      { userId: { id: userId }, email, initiatingUserId },
      'ForgetUser',
      'UserForgotten',
      'GenericForgetUserSagaError'
    ),

  getHasAdminPermission: () => apiClient.get('/v1/admins/permissions').then(response => !!response.data?.isAdmin),
  logout: () => {
    const angularRoot = angular.element(document.body);
    angularRoot.injector().get('$rootScope').$broadcast('unauthorized');
  },
};

export default userService;
