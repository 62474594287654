import React from 'react';
import PropTypes from 'prop-types';

const AngularContext = React.createContext(undefined);
AngularContext.displayName = 'AngularContext';

export const AngularProvider = ({ scope, children }) => (
  <AngularContext.Provider value={scope}>{children}</AngularContext.Provider>
);

AngularProvider.propTypes = {
  scope: PropTypes.object.isRequired,
  children: PropTypes.any,
};

export default AngularContext;
