import React from 'react';
import { Facet } from '../models/Facet';
import { SearchRequestStatus, SearchResultItem } from '../models/Search';

type Context = {
  searchText: string;
  searchResults: SearchResultItem[];
  searchResultCount: number;
  allFacets: Facet[];
  selectedFacets: Facet[];
  hasSubscription: boolean;
  status: SearchRequestStatus;
  updateSearchText: (text: string) => void;
  selectFacet: (facetValue: Facet) => void;
  deselectFacet: (facetValue: Facet) => void;
  resetSearch: () => void;
};

export const SearchContext = React.createContext<Context>({
  searchText: '',
  searchResults: [],
  searchResultCount: 0,
  allFacets: [],
  selectedFacets: [],
  hasSubscription: false,
  status: { isLoading: true, isSearching: false, isError: false },
  updateSearchText: () => {},
  selectFacet: () => {},
  deselectFacet: () => {},
  resetSearch: () => {},
});
SearchContext.displayName = 'SearchContext';
