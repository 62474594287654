import React from 'react';
import styled from 'styled-components';
import { Grid, Color, Type, Breakpoints } from '../StyleGuide';
import { Button } from '../components/Buttons';
import BibleStudyTabs from '../curriculum/BibleStudyTabs';

export const SessionMenu = styled.aside`
  display: none;
  background-color: #f7f8fa;
  border-right: 1px solid ${Color.Gray._20};
  @media only screen and (min-width: ${Breakpoints.screen_md}) {
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    flex-direction: column;
  }
`;

export const SessionContainer = styled.div`
  background: #fcfcfd;
  border-top: 1px solid ${Color.Gray._20};
`;

export const SessionLayout = styled(SessionContainer)`
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;
  h3,
  h5,
  p {
    margin: 0;
  }
  @media only screen and (min-width: ${Breakpoints.screen_md}) {
    grid-template-columns: 25% 1fr;
  }

  ${SessionMenu}, .segment-header {
    top: ${props => props.stickyOffset}px;
  }

  ${SessionMenu} {
    height: calc(100vh - ${props => props.topOffset}px);
  }
`;

export const SessionTimelinePreferences = styled.div``;

export const SessionMenuContents = styled.ol`
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex: 1;
  overflow-y: auto;
`;

export const SessionMenuListItem = styled.li`
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: ${Type.Scale._3};
  color: ${Type.Color.medium};

  &.excluded-segment a {
    opacity: 0.5;
  }

  a {
    flex: 1;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
    padding: 14px ${Grid._4};
    &.indented {
      padding: 14px ${Grid._4} 14px ${Grid._8};
    }
    &.is-draggable {
      padding: 14px ${Grid._7} 14px ${Grid._4};
    }
    &.active {
      color: ${Type.Color.dark};
      font-weight: ${Type.Weight.semibold};
      background: ${Color.Secondary._20};
      border-right: 4px solid ${Color.Blue._50};
    }
    &:not(.active):hover {
      background: ${Color.Gray._20};
    }
    &.group {
      font-weight: ${Type.Weight.semibold};
      i {
        position: absolute;
        left: ${Grid._4};
        color: ${Type.Color.medium};
        font-size: 18px;
      }
    }
  }
  i.drag-handle {
    cursor: pointer;
    position: absolute;
    right: 0;
    padding: ${Grid._4};
  }
`;

export const SessionMenuHeader = styled.div`
  padding: ${Grid._4};
  border-bottom: 1px solid ${Color.Gray._20};
  p {
    color: ${Type.Color.medium};
    margin-top: ${Grid._2};
  }
`;

export const SessionMenuButtons = styled.div`
  display: grid;
  grid-gap: ${Grid._4};
  padding: ${Grid._4} ${Grid._3};
  border-bottom: 1px solid ${Color.Gray._20};

  @media only screen and (min-width: ${Breakpoints.screen_lg}) {
    padding: ${Grid._4} ${Grid._6};
  }

  button {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const SessionTimelineContainer = styled.div`
  max-width: ${Breakpoints.screen_lg};
  margin: ${Grid._6} ${Grid._4};
  @media only screen and (min-width: ${Breakpoints.screen_lg}) {
    margin: ${Grid._6} ${Grid._10} 75vh;
  }
`;

export const SessionTimelineHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div:first-child {
    flex: 1;
    h1 {
      margin: 0;
    }
    p {
      color: ${Type.Color.medium};
    }
  }
`;

export const DurationIconContainer = styled.div`
  padding-left: ${Grid._4};
  p {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: ${Type.Color.medium};
    margin: 0;

    i {
      font-size: ${Type.Scale._5};
      margin-right: ${Grid._3};
    }
    span {
      color: ${Type.Color.dark};
      font-size: ${Type.Scale._7};
      font-weight: ${Type.Weight.medium};
    }
    &:last-child {
      margin-top: -${Grid._3};
    }
  }
`;

export const DurationIcon = ({ duration }) => (
  <DurationIconContainer>
    <p>
      <i className="fas fa-clock"></i>
      <span data-qa-hook="duration">{duration}</span>
    </p>
    <p>Minutes</p>
  </DurationIconContainer>
);

const DurationStepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: ${Grid._3};
  button {
    font-size: ${Type.Scale._2};
    width: ${Grid._5};
    height: ${Grid._5};
    padding: 0;
    i {
      margin: 0;
    }
  }
  button + button {
    margin-top: ${Grid._2};
  }
`;

export const DurationStepper = ({ increment, decrement }) => (
  <DurationStepperContainer>
    <Button data-qa-hook="addDurationMinute" onClick={increment} disabled={!increment}>
      <i className="fas fa-chevron-up"></i>
    </Button>
    <Button data-qa-hook="subtractDurationMinute" onClick={decrement} disabled={!decrement}>
      <i className="fas fa-chevron-down"></i>
    </Button>
  </DurationStepperContainer>
);

export const SessionTimelineTabs = styled(BibleStudyTabs)`
  justify-content: center;
`;

export const SessionTimelineTab = ({ page, activePage, onTabClick, children }) => (
  <li className={page === activePage ? 'active-tab' : ''}>
    <button data-qa-hook={`${page}Tab`} onClick={() => onTabClick(page)}>
      {children}
    </button>
  </li>
);

export const SessionAgenda = styled.div`
  display: none;

  .session-agenda__title {
    display: flex;
    justify-content: space-between;
    padding: 0;
    border: none;
  }

  .session-agenda__list {
    margin-top: 16px;
    padding: 0;
    list-style: none;

    p {
      font-weight: ${Type.Weight.light};
      color: ${Type.Color.medium};
    }
  }

  .session-agenda__list,
  .session-agenda__list-item {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .session-agenda__list-item__transition {
    display: flex;
    gap: 14px;

    i {
      margin: auto 0;
      font-size: 22px;
    }
  }

  .session-agenda__list-item__segment {
    display: flex;
    justify-content: space-between;
  }
`;
