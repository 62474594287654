import React, { useState } from 'react';
import PropTypes from 'prop-types';

const SessionTimelineContext = React.createContext(undefined);
SessionTimelineContext.displayName = 'SessionTimelineContext';

export const SessionTimelineProvider = ({
  timeline: initialTimline,
  curriculum: initialCurriculum,
  session,
  children,
}) => {
  const [timeline, setTimeline] = useState(initialTimline);

  const curriculum = {
    brandCode: initialCurriculum.brandCode ?? initialCurriculum.brand?.code,
    name: initialCurriculum.name ?? initialCurriculum.ageCategory,
  };

  return (
    <SessionTimelineContext.Provider value={{ timeline, setTimeline, curriculum, session }}>
      {children}
    </SessionTimelineContext.Provider>
  );
};

SessionTimelineProvider.propTypes = {
  timeline: PropTypes.object.isRequired,
  curriculum: PropTypes.object,
  session: PropTypes.object,
  children: PropTypes.any,
};

export default SessionTimelineContext;
