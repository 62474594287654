angular.module('lwNamb').factory(
  'curriculumService',

  [
    'api',
    'qt',
    'transformer',
    'CacheFactory',
    'licenseService',
    'fileUrlService',
    function(api, qt, transformer, CacheFactory, licenseService, fileUrlService) {
      var curriculumCache = CacheFactory('curriculumCache', {
        maxAge: 2 * 60 * 1000, // two minutes
        deleteOnExpire: 'aggressive',
      });

      return {
        createCurriculum: function(name, brand, basicItemNumber, plusItemNumber) {
          dropCaches();
          return post('/curriculum-api/curriculums', {
            name: name,
            brand: brand,
            basicItemNumber: basicItemNumber,
            plusItemNumber: plusItemNumber,
          });
        },
        getAllCurriculum: function(orgId) {
          if (orgId === undefined) {
            return get('/curriculum-api/curriculums', transformer.transformCurriculums);
          } else {
            var deferred = qt.defer();
            qt.all([
              get('/curriculum-api/curriculums', transformer.transformCurriculums, true),
              licenseService.getPairedLicenses(orgId, true),
            ]).then(function(results) {
              var curriculumItemNumbers = results[1]
                .filter(function(license) {
                  return !license.isGridSubscription;
                })
                .map(function(license) {
                  return license.item === undefined ? '' : license.item.number;
                });
              var curriculumWithAccess = results[0].filter(function(curriculum) {
                return curriculumItemNumbers.indexOf(curriculum.defaultItemNumber) > -1;
              });
              deferred.resolve(curriculumWithAccess);
            });
            return deferred.promise;
          }
        },
        getCurriculum: getById,
        createSeries: function(curriculumId, name, ageRange, target, subdivision) {
          dropCaches();
          return post('/curriculum-api/curriculums/' + curriculumId + '/series', {
            name: name,
            ageRange: ageRange,
            target: target,
            subdivision: subdivision,
          });
        },
        getCurriculumDetail: function(curriculumId) {
          var deferred = qt.defer();
          qt.all([
            this.getCurriculum(curriculumId),
            this.getAllIssues(curriculumId),
            this.getAllSeries(curriculumId),
          ]).then(
            function(responses) {
              qt.all(
                responses[1].map(function(issue) {
                  return getAndCacheSessions(issue.id);
                })
              ).then(
                function(sessionsResponses) {
                  sessionsResponses.forEach(function(sessions, i) {
                    responses[1][i].sessions = sessions;
                  });
                  deferred.resolve({
                    curriculum: responses[0],
                    issues: responses[1],
                    series: responses[2],
                  });
                },
                function(response) {
                  deferred.reject(response);
                }
              );
            },
            function(response) {
              deferred.reject(response);
            }
          );
          return deferred.promise;
        },
        getAllSeries: function(curriculumId) {
          return get('/curriculum-api/curriculums/' + curriculumId + '/series');
        },
        getSingleSeries: function(curriculumId, seriesId) {
          return get('/curriculum-api/curriculums/' + curriculumId + '/series/' + seriesId);
        },
        updateSingleSeries: function(curriculumId, seriesId, name, ageRange, target, subdivision, planPackage, startDate, endDate, image) {
          dropCaches();
          return put('/curriculum-api/curriculums/' + curriculumId + '/series/' + seriesId, {
            name: name,
            ageRange: ageRange,
            target: target,
            subdivision: subdivision,
            package: planPackage,
            startDate: startDate,
            endDate: endDate,
            image: image
          });
        },
        createIssue: function(name, sessionCount, firstUseDate, curriculumId, publishStart, publishEnd) {
          dropCaches();
          var payload = {
            name: name,
            sessionCount: sessionCount,
            firstUseDate: firstUseDate,
          };
          if (publishStart !== undefined) {
            payload.publishStartDate = publishStart;
          }
          if (publishEnd !== undefined) {
            payload.publishEndDate = publishEnd;
          }
          return post('/curriculum-api/curriculums/' + curriculumId + '/issues', payload);
        },
        getAllIssues: function(curriculumId) {
          return get('/curriculum-api/curriculums/' + curriculumId + '/issues', function(issues) {
            return issues.map(transformer.transformCurriculumIssue);
          });
        },
        getIssue: function(issueId, curriculumId) {
          return getAndCacheIssue(issueId, curriculumId);
        },
        getIssueAndMaterials: function(issueId, curriculumId) {
          var deferred = qt.defer({ timeoutSeconds: 15 });
          qt.all([
            this.getIssue(issueId, curriculumId),
            this.getIssueMaterials(issueId),
            this.getAllSeries(curriculumId),
          ]).then(
            function(responses) {
              deferred.resolve({
                issue: responses[0],
                materials: responses[1],
                series: setMaterialsOnSeries(responses[2], responses[1]),
              });
            },
            function(response) {
              deferred.reject(response);
            }
          );
          return deferred.promise;
        },
        getCurriculumIssueAndSessions: function(issueId, curriculumId) {
          var deferred = qt.defer();

          qt.all([
            this.getCurriculum(curriculumId),
            this.getIssue(issueId, curriculumId),
            this.getAllSessions(issueId),
            this.getAllSeries(curriculumId),
          ]).then(
            function(responses) {
              var sessionsSorted = responses[2]
                .map(function(session) {
                  session.useDate = session.useDate !== undefined ? session.useDate.split('[UTC]')[0] : '';
                  return session;
                })
                .sort(function(a, b) {
                  return a.useDate < b.useDate ? -1 : a.useDate > b.useDate ? 1 : 0;
                });
              deferred.resolve({
                curriculum: responses[0],
                issue: responses[1],
                sessions: sessionsSorted,
                series: responses[3],
              });
            },
            function(response) {
              deferred.reject(response);
            }
          );

          return deferred.promise;
        },
        setIssueDates: function(publishStartDate, publishEndDate, issueId, curriculumId) {
          dropCaches();
          var payload = {};
          if (publishStartDate !== undefined) {
            payload.publishStartDate = publishStartDate;
          }
          if (publishEndDate !== undefined) {
            payload.publishEndDate = publishEndDate;
          }
          return post('/curriculum-api/curriculums/' + curriculumId + '/issues/' + issueId + '/publish-dates', payload);
        },
        createSession: function(name, useDate, issueId, curriculumId, publishStartDate, publishEndDate) {
          dropCaches();
          var payload = {
            name: name,
            useDate: useDate,
            curriculumId: curriculumId,
            publishStartDate: publishStartDate,
            publishEndDate: publishEndDate
          };
          return post('/curriculum-api/issues/' + issueId + '/sessions', payload);
        },
        getAllSessions: function(issueId) {
          return getAndCacheSessions(issueId);
        },
        getIssueSessionAndMaterials: function(issueId, sessionId, curriculumId) {
          var deferred = qt.defer();

          qt.all([
            this.getIssue(issueId, curriculumId),
            this.getSession(issueId, sessionId),
            this.getSessionMaterials(sessionId),
            this.getAllSeries(curriculumId),
          ]).then(
            function(responses) {
              deferred.resolve({
                issue: responses[0],
                session: responses[1],
                materials: responses[2],
                series: responses[3],
              });
            },
            function(response) {
              deferred.reject(response);
            }
          );

          return deferred.promise;
        },
        getSession: function(issueId, sessionId) {
          return get(
            '/curriculum-api/issues/' + issueId + '/sessions/' + sessionId,
            transformer.transformCurriculumSession
          );
        },
        getSessionMaterials: function(sessionId) {
          return get('/curriculum-api/sessions/' + sessionId + '/materials', transformer.transformMaterials);
        },
        getIssueMaterials: function(issueId) {
          return get('/curriculum-api/issues/' + issueId + '/materials', transformer.transformMaterials);
        },
        getSeriesMaterials: function(curriculumId, seriesId) {
          return get('/v1/org/curriculum/' + curriculumId + '/' + seriesId + '/materials', transformer.transformMaterials);
        },
        getSessionMaterialAndSeries: function(curriculumId, issueId, sessionId, materialId) {
          var deferred = qt.defer();
          qt.all([
            this.getSession(issueId, sessionId),
            this.getSingleSessionMaterial(sessionId, materialId),
            this.getAllSeries(curriculumId),
          ]).then(
            function(responses) {
              deferred.resolve({ session: responses[0], material: responses[1], series: responses[2] });
            },
            function(response) {
              deferred.reject(response);
            }
          );
          return deferred.promise;
        },
        getSupportMaterialAndSeries: function(curriculumId, seriesId, issueId, materialId) {
          var deferred = qt.defer();
          qt.all([
            this.getSingleSupportMaterial(issueId, materialId),
            this.getSingleSeries(curriculumId, seriesId),
          ]).then(
            function(responses) {
              deferred.resolve({ material: responses[0], series: responses[1] });
            },
            function(response) {
              deferred.reject(response);
            }
          );
          return deferred.promise;
        },
        createIssueDownloadMaterial: function(name, order, mediaId, issueId, collectionIds, curriculumId) {
          return createMaterial(
            {
              name: name,
              order: order,
              mediaId: mediaId,
              materialType: 'download',
              seriesIds: collectionIds,
            },
            issueId,
            undefined,
            curriculumId
          );
        },
        createSessionDownloadMaterial: function(name, order, mediaId, sessionId, collectionIds, curriculumId, packageType) {
          return createMaterial(
            {
              name: name,
              order: order,
              mediaId: mediaId,
              materialType: 'download',
              seriesIds: collectionIds,
              package: packageType
            },
            undefined,
            sessionId,
            curriculumId
          );
        },
        createIssueVideoMaterial: function(name, order, mediaId, issueId, collectionIds, curriculumId) {
          return createMaterial(
            {
              name: name,
              order: order,
              mediaId: mediaId,
              materialType: 'video',
              seriesIds: collectionIds,
            },
            issueId,
            undefined,
            curriculumId
          );
        },
        createSessionVideoMaterial: function(name, order, mediaId, sessionId, collectionIds, curriculumId, packageType) {
          return createMaterial(
            {
              name: name,
              order: order,
              mediaId: mediaId,
              materialType: 'video',
              seriesIds: collectionIds,
              package: packageType
            },
            undefined,
            sessionId,
            curriculumId
          );
        },
        createSessionAudioMaterial: function(name, order, mediaId, sessionId, collectionIds, curriculumId, packageType) {
          return createMaterial(
            {
              name: name,
              order: order,
              mediaId: mediaId,
              materialType: 'audio',
              seriesIds: collectionIds,
              package: packageType
            },
            undefined,
            sessionId,
            curriculumId
          );
        },
        createIssueTextMaterial: function(name, description, order, issueId, collectionIds, curriculumId) {
          return createMaterial(
            {
              name: name,
              description: description,
              order: order,
              materialType: 'text',
              seriesIds: collectionIds,
            },
            issueId,
            undefined,
            curriculumId
          );
        },
        createSessionTextMaterial: function(name, description, order, sessionId, collectionIds, curriculumId, packageType) {
          return createMaterial(
            {
              name: name,
              description: description,
              order: order,
              materialType: 'text',
              seriesIds: collectionIds,
              package: packageType
            },
            undefined,
            sessionId,
            curriculumId
          );
        },
        createIssueLinkMaterial: function(name, description, link, order, issueId, collectionIds, curriculumId) {
          return createMaterial(
            {
              name: name,
              link: link,
              order: order,
              materialType: 'link',
              seriesIds: collectionIds,
              description: description,
            },
            issueId,
            undefined,
            curriculumId
          );
        },
        createSessionLinkMaterial: function(name, description, link, order, sessionId, collectionIds, curriculumId, packageType) {
          return createMaterial(
            {
              name: name,
              link: link,
              order: order,
              materialType: 'link',
              seriesIds: collectionIds,
              description: description,
              package: packageType
            },
            undefined,
            sessionId,
            curriculumId
          );
        },
        createVolumeMaterial: function(curriculumId, volumeId, data) {
          dropCaches();
          return post('/curriculum-api/volume/' + volumeId + '/materials', data);
        },
        reorderSessionsForIssue: function(sessions, issueId) {
          dropCaches();
          var sessionIdUseDates = sessions.map(function(session) {
            return { sessionId: session.sessionId, useDate: session.useDate };
          });
          return post('/curriculum-api/issues/' + issueId + '/sessions/reorder', {
            useDates: sessionIdUseDates,
          });
        },
        reorderMaterialsForSession: function(id, materials) {
          dropCaches();
          var materialIdAndOrder = materials.map(function(material) {
            return { materialId: material.materialId, newOrder: material.order };
          });
          return post('/curriculum-api/sessions/' + id + '/materials/reorder', {
            newOrder: materialIdAndOrder,
          });
        },
        reorderMaterialsForIssue: function(id, materials) {
          dropCaches();
          var materialIdAndOrder = materials.map(function(material) {
            return { materialId: material.materialId, newOrder: material.order };
          });
          return post('/curriculum-api/issues/' + id + '/materials/reorder', {
            newOrder: materialIdAndOrder,
          });
        },
        getSingleSessionMaterial: function(sessionId, materialId) {
          return get(
            '/curriculum-api/sessions/' + sessionId + '/materials/' + materialId,
            transformer.transformSingleMaterial
          );
        },
        getSingleSupportMaterial: function(issueId, materialId) {
          return get(
            '/curriculum-api/sessions/' + issueId + '/materials/' + materialId,
            transformer.transformSingleMaterial
          );
        },
        downloadAllUrl: function(materials, fileName) {
          var fileIds = materials
            .filter(function(material) {
              return material.materialType === 'Download';
            })
            .map(function(material) {
              return material.mediaId;
            });
          if (fileIds.length > 1) {
            var ids = fileIds.join(',');
            return '/curriculum-storage/files?fileIds=' + ids + '&fileName=' + fileName;
          } else if (fileIds.length === 1) {
            return '/curriculum-storage/files?fileIds=' + fileIds[0] + '&fileName=' + fileName;
          } else {
            return '';
          }
        },
        deleteIssueMaterial: function(issueId, materialId) {
          dropCaches();
          return api.delete('/curriculum-api/issues/' + issueId + '/materials/' + materialId);
        },
        deleteSessionMaterial: function(sessionId, materialId) {
          dropCaches();
          return api.delete('/curriculum-api/sessions/' + sessionId + '/materials/' + materialId);
        },
        deleteIssue: function(curriculumId, issueId) {
          dropCaches();
          return api.delete('/curriculum-api/curriculums/' + curriculumId + '/issues/' + issueId);
        },
        deleteSeries: function(curriculumId, seriesId) {
          dropCaches();
          return api.delete('/curriculum-api/curriculums/' + curriculumId + '/series/' + seriesId);
        },
        changeSessionName: function(issueId, sessionId, newName){
         dropCaches();
         var payload = {name: newName};
         return post('/curriculum-api/issues/' + issueId + '/sessions/' + sessionId + '/rename', payload);
        },
        setImage: function(issueId, sessionId, image){
          dropCaches();
          var payload = {image: image};
          return post('/curriculum-api/issues/' + issueId + '/sessions/' + sessionId + '/image', payload);
         },
        setSessionDescription: function(issueId, sessionId, description){
          dropCaches();
          var payload = {description: description};
          return post('/curriculum-api/issues/' + issueId + '/sessions/' + sessionId + '/description', payload);
         },
        changeMaterialName: function(id, materialId, newName){
          dropCaches();
          var payload = {name: newName};
          return post('/curriculum-api/sessions/' + id + '/materials/' + materialId + '/rename', payload);
        },
        changeIssueName: function(curriculumId, issueId, newName){
          dropCaches();
          var payload = {name: newName};
          return post('/curriculum-api/curriculums/' + curriculumId + '/issues/' + issueId + '/rename', payload);
        },
        updateMaterial: function(sessionId, materialId, seriesIds, packageType) {
          dropCaches();
          return api.post('/curriculum-api/sessions/' + sessionId + '/materials/' + materialId + '/update',
            { seriesIds: seriesIds, package: packageType });
        }
      };

      function setMaterialsOnSeries(seriesList, materials) {
        return seriesList.map(function(series) {
          series.materials = materials.filter(function(material) {
            return series.seriesId === material.seriesId;
          });
          return transformer.transformSeries(series);
        });
      }

      function createMaterial(payload, issueId, sessionId, curriculumId) {
        dropCaches();
        var q = qt.defer();
        getById(curriculumId).then(function(curriculum) {
          var url = '/curriculum-api/';
          if (issueId !== undefined && issueId !== 'undefined') url = url + 'issues/' + issueId;
          if (sessionId !== undefined && sessionId !== 'undefined') url = url + 'sessions/' + sessionId;
          url = url + '/materials';
          post(url, payload).then(
            function(response) {
              q.resolve(response);
            },
            function(response) {
              q.reject(response);
            }
          );
        });
        return q.promise;
      }

      function get(url, transform, bustCache) {
        var q = qt.defer();
        var possibleCache = curriculumCache.get(url);
        if (bustCache !== true && possibleCache !== undefined) {
          q.resolve(possibleCache);
        } else {
          api.get(url).then(
            function(response) {
              var data = response.data;
              if (transform !== undefined) {
                data = transform(data);
              }
              curriculumCache.put(url, data);
              q.resolve(data);
            },
            function(response) {
              q.reject(response);
            }
          );
        }
        return q.promise;
      }

      function post(url, body) {
        var q = qt.defer();
        api.post(url, body).then(
          function(response) {
            q.resolve(response.data.id !== undefined ? response.data.id : response.data);
          },
          function(response) {
            q.reject(response);
          }
        );
        return q.promise;
      }

      function put(url, body) {
        var q = qt.defer();
        api.put(url, body).then(
          function(response) {
            q.resolve(response.data.id !== undefined ? response.data.id : response.data);
          },
          function(response) {
            q.reject(response);
          }
        );
        return q.promise;
      }

      function dropCaches() {
        curriculumCache.removeAll();
      }

      function getById(id) {
        var deferred = qt.defer({ timeoutSeconds: 15 });
        getAndCacheSingleCurriculum(id).then(
          function(curriculum) {
            deferred.resolve(curriculum);
          },
          function(response) {
            deferred.reject(response);
          }
        );
        return deferred.promise;
      }

      function getAndCacheSessions(issueId) {
        return get('/curriculum-api/issues/' + issueId + '/sessions');
      }

      function getAndCacheSingleCurriculum(curriculumId) {
        return get('/curriculum-api/curriculums/' + curriculumId);
      }

      function getAndCacheIssue(issueId, curriculumId) {
        return get(
          '/curriculum-api/curriculums/' + curriculumId + '/issues/' + issueId,
          transformer.transformCurriculumIssue
        );
      }
    },
  ]
);
