import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MediumButton } from './Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from './ModalElements';
import { VideoPlaceholder, LoadingVideoPlaceholder } from './Layout';
import VideoPlayer from '../containers/VideoPlayer';
import ThirdPartyVideoPlayer from './ThirdPartyVideoPlayer';

const VideoPreviewModal = ({ name, type, videoId, handleDismiss }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader data-qa-hook="VideoPreviewModal">
        <h3>Video Preview</h3>
      </ModalHeader>
      <ModalBody>
        <h3 style={{ marginBottom: '16px' }}>{name}</h3>
        {type === 'ThirdPartyVideo' ? (
          <VideoPlaceholder thirdParty>
            <ThirdPartyVideoPlayer url={videoId} />
          </VideoPlaceholder>
        ) : (
          <VideoPlaceholder>
            {isLoading && <LoadingVideoPlaceholder />}
            <VideoPlayer setIsLoading={setIsLoading} videoId={videoId} onEnd={() => null} />
          </VideoPlaceholder>
        )}
      </ModalBody>
      <ModalFooter>
        <MediumButton data-qa-hook="VideoPreviewCloseButton" onClick={handleDismiss}>
          Close
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

VideoPreviewModal.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default VideoPreviewModal;
