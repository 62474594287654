angular.module('lwNamb').controller(
  'TaskListsCtrl',

  [
    '$scope',
    'alertService',
    'userService',
    'taskListService',
    '$log',
    '$q',
    'buildService',
    '$timeout',
    function($scope, alertService, userService, taskListService, $log, $q, buildService, $timeout) {
      var ownerId;
      var init = function() {
        $scope.loading = true;
        $scope.tl = {};
        $scope.selectedItems = [];
        $scope.form = {};
        $scope.tabs = [];
        $scope.tabs.activeTab = 2;
        $scope.filteredTasklists = [];
        $scope.extras = {
          resetModalErrors: function() {
            $scope.extras.deleteFailure = undefined;
          },
        };
        $scope.submitted = false;
        $scope.ctxRedirectTo = buildService.ctxRedirectTo;

        $q.all([userService.user(), buildService.ownerId()]).then(function(result) {
          var user = result[0];
          ownerId = result[1];
          $scope.user = user;
          $scope.clearInput();
          retrieveUserData();
        });
      };

      var retrieveUserData = function() {
        //Get All Available Task Lists
        taskListService.getAvailableTasklistsForOrg(ownerId, true).then(
          function(allTasklists) {
            $scope.allTasklists = allTasklists;
            $scope.loading = false;
            $timeout(function() {
              if ($scope.filter && !$scope.filter.sortDropDown) {
                $scope.filter.sortDropDown = 'lastModifiedDate';
              }
            }, 100);
            var categories = allTasklists.reduce(function (categories, tl) {
              if (tl.category !== undefined && !categories.includes(tl.category)) {
                categories.push(tl.category);
              } else if (!categories.includes("No Category")) {
                categories.splice(1, 0, "No Category");
              }
              return categories;
            }, []);
            categories.sort();
            categories.unshift("Show All");
            $scope.categories = categories;
            $scope.filter.category = "Show All";
          },
          function(reason) {
            $log.error(reason);
            alertService.show({
              title: 'Error! Something went wrong.',
              type: 'danger',
              show: true,
              dismissible: true,
              duration: 20,
            });
          }
        );
      };

      $scope.clearInput = function() {
        if ($scope.filter && $scope.filter.sortDropDown) {
          $scope.filter = Object.assign({}, { sortDropDown: $scope.filter.sortDropDown });
        } else {
          $scope.filter = {};
        }
        $scope.filter.category = 'Show All';
      };

      $scope.clearForm = function() {
        $scope.tasklist = { name: '' };
        $scope.submitted = false;
        $scope.duplicateNameError = false;
      };

      $scope.resetModalErrors = function() {
        $scope.deleteFailure = undefined;
      };

      //Add Task List
      $scope.addTasklist = function(name, isValid) {
        $scope.submitted = true;
        if (!isValid) {
          return;
        }
        $scope.duplicateNameError = false;
        $scope.spinner = true;
        taskListService.createTasklist(ownerId, name, $scope.user.userId).then(
          function(tasklistId) {
            $scope.goToTasklist(tasklistId);
          },
          function(reason) {
            $scope.submitted = false;
            $scope.spinner = false;
            if (reason === 'DUPLICATE_NAME') {
              $scope.duplicateNameError = true;
            } else {
              showAlert('danger', 'Error! Please try again.', false);
            }
          }
        );
      };

      $scope.deleteTaskList = function(taskList, closeModal) {
        $scope.extras.spinner = true;
        $scope.extras.deleteFailure = false;
        taskListService.deleteTaskList(taskList.id, $scope.user.userId, ownerId).then(
          function() {
            var label = 'Course Removed';
            showAlert('success', label, 5);
            closeModal();
            $scope.allTasklists.splice($scope.allTasklists.indexOf(taskList), 1);
            $scope.extras.spinner = false;
          },
          function(failure) {
            $log.error(failure);
            $scope.extras.spinner = false;
            $scope.extras.deleteFailure = typeof failure === 'string' ? { reason: 'unknown' } : failure;
          }
        );
      };

      $scope.goToTasklist = function(id) {
        buildService.ctxRedirectTo('/courses/' + id);
      };

      function showAlert(type, msg, duration) {
        alertService.show({ title: msg, type: type, show: true, dismissible: true, duration: duration });
      }

      init();
    },
  ]
);
