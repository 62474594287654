import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../../StyleGuide';

const ChoicesList = styled.ul`
  margin: ${Grid._6} auto;
  padding: 0;
  li {
    list-style: none;
    display: flex;
    cursor: pointer;
    border-top: 1px solid ${Color.Gray._30};
    margin: 0;
    color: ${Type.Color.medium};
    font-size: ${Type.Scale._3};
    &:last-child {
      border-bottom: 1px solid ${Color.Gray._30};
    }
    &.wasChosen,
    &:hover {
      background: ${Color.Gray._10};
    }
    .choice-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: ${Color.Secondary._80};
      min-width: 60px;
      i {
        font-size: 26px;
      }
    }

    .choice-text {
      display: flex;
      flex: 1;
      align-items: stretch;
      padding: 16px;
    }
  }
`;

export default function Choices({ items, setCurrentQuestionAnswer }) {
  const [choices, setChoices] = useState(items);

  useEffect(() => {
    setChoices(items);
  }, [items]);

  const updateChoices = id => {
    const newChoices = choices.map(choice => {
      if (choice.possibleAnswerId.id === id) {
        choice.wasChosen = true;
        setCurrentQuestionAnswer(choice.possibleAnswerId);
      } else {
        choice.wasChosen = false;
      }
      return choice;
    });
    setChoices(newChoices);
  };

  return (
    <ChoicesList>
      {choices.map(c => {
        const choiceStatus = classNames({
          wasChosen: c.wasChosen,
        });
        const choiceIcon = classNames({
          'icon ion-ios-circle-outline': !c.wasChosen,
          'icon ion-ios-circle-filled': c.wasChosen,
        });
        return (
          <li
            key={`choices-${c.possibleAnswerId.id}`}
            onClick={() => updateChoices(c.possibleAnswerId.id)}
            className={choiceStatus}
          >
            <span className="choice-icon">
              <i data-qa-hook="choiceIcon" className={choiceIcon} />
            </span>
            <span className="choice-text">{c.value}</span>
          </li>
        );
      })}
    </ChoicesList>
  );
}
