import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Color, Grid } from '../../StyleGuide';
import { FormFieldContainer, FormFieldMaxCharCountStatus, FormField } from '../../components/FormElements';
import { RaisedContainer } from '../../components/Layout';
import { LinkStyleButton } from '../../components/Buttons';
import { useUser } from '../../authentication';
import useOrg from '../../hooks/useOrg';
import windowService from '../../services/windowService';
import { ChurchImage, ListItemTitleContainer } from '../../components/PairingElements';
import { ContentTitle, ListItem, ContinueButton, ViewContainer, ViewPropTypes } from './OnboardingElements';
import ErrorMessage from '../../components/ErrorMessage';
import useCurriculum from '../../hooks/useCurriculum';

const ChurchItem = styled(RaisedContainer)`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${Grid._4};
  padding: ${Grid._4};
  margin-bottom: ${Grid._6};
  background: ${Color.Gray._05};
  width: 100%;
`;

const CreateChurchView = ({ onboardingType, onSubmit, back }) => {
  return (
    <>
      <ContentTitle>
        <h2>Create Your Church</h2>
        <p>Create a church to use with this {onboardingType}</p>
      </ContentTitle>

      <ViewContainer>
        <div className="grid-container grid-gap-32">
          <Formik
            initialValues={{ name: '', zip: '' }}
            validationSchema={Yup.object({
              name: Yup.string().required('A church name is required'),
              zip: Yup.string().required('A zip code is required'),
            })}
            validateOnChange={false}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormFieldContainer>
                  <FormFieldMaxCharCountStatus
                    label="Church Name"
                    name="name"
                    type="text"
                    placeholder="Enter Church Name"
                    maxLength={64}
                  />
                </FormFieldContainer>

                <FormFieldContainer>
                  <FormField label="Church Zip Code" name="zip" type="text" placeholder="Enter Zip Code" />
                </FormFieldContainer>

                <div className="grid-container grid-content-center">
                  <ContinueButton
                    data-qa-hook="continueButton"
                    type="submit"
                    disabled={isSubmitting}
                    operating={isSubmitting}
                  >
                    Continue
                  </ContinueButton>
                </div>

                {back && (
                  <div className="grid-container grid-content-center mt-40">
                    <LinkStyleButton data-qa-hook="backToSelectChurch" onClick={back}>
                      Back to Select Church
                    </LinkStyleButton>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </ViewContainer>
    </>
  );
};

CreateChurchView.propTypes = {
  onboardingType: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  back: PropTypes.func,
};

const SelectChurchView = ({ onboardingType, onSubmit, createChurch, org, isAdmin }) => {
  const switchChurch = () => {
    windowService.redirectTo(`/org/switch?redirect=${encodeURIComponent(windowService.getCurrentRoute())}`);
  };

  return (
    <>
      <ContentTitle>
        <h2>Choose Your Church</h2>
        <p>Select the church that will use this {onboardingType}</p>
      </ContentTitle>

      <ViewContainer>
        {!isAdmin && (
          <ErrorMessage>
            We are unable to connect your {onboardingType} to this church because you are not an administrator. Please
            contact your ministry leader or choose another church to finalize your {onboardingType} registration.
          </ErrorMessage>
        )}

        <div className="grid-container grid-gap-32">
          <Formik
            initialValues={{ zip: '' }}
            validationSchema={Yup.object({
              zip: Yup.string().required('A zip code is required'),
            })}
            validateOnChange={false}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                {org && (
                  <ChurchItem>
                    <ChurchImage church={org} />
                    <ListItemTitleContainer>
                      <h4 data-qa-hook="orgName">{org.name}</h4>
                      <p>
                        {org.members.length} {org.members.length > 1 ? 'members' : 'member'}
                      </p>
                    </ListItemTitleContainer>
                  </ChurchItem>
                )}

                {isAdmin && (
                  <>
                    <FormFieldContainer>
                      <FormField label="Church Zip Code" name="zip" type="text" placeholder="Enter Zip Code" />
                    </FormFieldContainer>

                    <div className="grid-container grid-content-center">
                      <ContinueButton
                        data-qa-hook="continueButton"
                        type="submit"
                        disabled={isSubmitting}
                        operating={isSubmitting}
                      >
                        Continue
                      </ContinueButton>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>

          <div className="grid-container grid-gap-16 mt-40">
            <ListItem data-hook="anotherChurch" onClick={switchChurch}>
              <h4>Choose another church</h4>
              <i className="fas fa-chevron-right" />
            </ListItem>
            <ListItem data-qa-hook="newChurch" onClick={createChurch}>
              <h4>Create a new church</h4>
              <i className="fas fa-chevron-right" />
            </ListItem>
          </div>
        </div>
      </ViewContainer>
    </>
  );
};

SelectChurchView.propTypes = {
  onboardingType: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  createChurch: PropTypes.func.isRequired,
  org: PropTypes.object,
  isAdmin: PropTypes.bool,
};

const ChurchView = ({ handler }) => {
  const user = useUser();
  const org = useOrg();
  const { brand } = useCurriculum();
  const [hasChurch] = useState(!!user?.lastSelectedAccount);
  const [isCreating, setIsCreating] = useState(!hasChurch);
  const isAdmin = user?.permissions.includes('Account View');
  const onboardingType = brand.isPreview ? 'preview' : 'trial';

  const submit = ({ name, zip }) => (isCreating ? handler({ name, zip }) : handler({ ...org, zip }));

  return isCreating ? (
    <CreateChurchView
      onboardingType={onboardingType}
      onSubmit={submit}
      back={hasChurch ? () => setIsCreating(false) : null}
    />
  ) : (
    <SelectChurchView
      onboardingType={onboardingType}
      onSubmit={submit}
      org={org}
      isAdmin={isAdmin}
      createChurch={() => setIsCreating(true)}
    />
  );
};

ChurchView.propTypes = ViewPropTypes;

export default ChurchView;
