angular.module('lwNamb').factory(
  'noteService',

  [
    '$rootScope',
    'api',
    'qt',
    'uuid4',
    function($rootScope, api, qt, uuid4) {
      var timeoutSeconds = 15;

      return {
        createNote: function(subjectId, orgId, title, message, user) {
          var noteId = uuid4.generate();
          var createNote = {
            id: noteId,
            subject: {
              id: subjectId,
            },
            orgId: {
              id: orgId,
            },
            title: title,
            authorId: {
              id: user.userId,
            },
            initiatingUserId: user.userId,
          };

          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          api.post('/v1/commands/CreateNote', createNote).then(null, function failure(reason) {
            deferred.reject(reason);
          });

          $rootScope.$on('NoteCreated', function(name, event) {
            if (event.id == noteId) {
              if (title !== undefined && message !== undefined) {
                saveTitleAndMessage(noteId, subjectId, orgId, title, message, user).then(
                  function() {
                    deferred.resolve(noteId);
                  },
                  function(reason) {
                    deferred.reject(reason);
                  }
                );
              } else if (message !== undefined) {
                saveMessage(noteId, subjectId, orgId, message, user).then(
                  function() {
                    deferred.resolve(noteId);
                  },
                  function(reason) {
                    deferred.reject(reason);
                  }
                );
              } else if (title !== undefined) {
                saveTitle(noteId, subjectId, orgId, title, user).then(
                  function() {
                    deferred.resolve(noteId);
                  },
                  function(reason) {
                    deferred.reject(reason);
                  }
                );
              } else {
                deferred.resolve(noteId);
              }
            }
          });

          return deferred.promise;
        },
        saveMessage: function(noteId, subjectId, orgId, message, user) {
          return saveMessage(noteId, subjectId, orgId, message, user);
        },
        saveTitle: function(noteId, subjectId, orgId, title, user) {
          return saveTitle(noteId, subjectId, orgId, title, user);
        },
        saveTitleAndMessage: function(noteId, subjectId, orgId, title, message, user) {
          return saveTitleAndMessage(noteId, subjectId, orgId, title, message, user);
        },
        getNotesForUserOrg: function(userId, orgId, adminUser) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          api.get('/v1/notes?orgId=' + orgId + '&userId=' + userId).then(
            function(response) {
              var notes = response.data.notes.notesOverview;
              notes.forEach(function(note) {
                note.viewOnly = note.authorId.id !== adminUser.userId;
              });
              deferred.resolve(notes);
            },
            function(error) {
              if (error.status == 404) {
                deferred.resolve([]);
              }
            }
          );

          return deferred.promise;
        },

        getNote: function(noteId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          api.get('/v1/notes/' + noteId).then(function(response) {
            deferred.resolve(response.data.note);
          });

          return deferred.promise;
        },
        deleteNote: function(note, subjectId, adminUser) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
          var noteRequest = {
            id: note.id.id,
            subject: {
              id: subjectId,
            },
            authorId: {
              id: adminUser.userId,
            },
            orgId: {
              id: adminUser.lastSelectedAccount,
            },
            status: 'Deleted',
            initiatingUserId: adminUser.userId,
          };
          api.post('/v1/commands/UpdateNoteStatus', noteRequest).then(null, function(error) {
            deferred.reject(error);
          });
          $rootScope.$on('NoteStatusUpdated', function(name, event) {
            if (event.id == note.id.id) {
              deferred.resolve(note.id);
            }
          });
          return deferred.promise;
        },
      };

      function saveMessage(noteId, subjectId, orgId, message, user) {
        var updateNoteMessage = {
          id: noteId,
          subject: {
            id: subjectId,
          },
          orgId: {
            id: orgId,
          },
          message: message,
          modifiedBy: {
            id: user.userId,
          },
          initiatingUserId: user.userId,
        };

        var deferredMessage = qt.defer({ timeoutSeconds: timeoutSeconds });

        api.post('/v1/commands/UpdateNoteMessage', updateNoteMessage).then(null, function failure(reason) {
          deferredMessage.reject(reason);
        });

        $rootScope.$on('NoteMessageUpdated', function(name, event) {
          if (event.id == noteId) {
            deferredMessage.resolve();
          }
        });

        return deferredMessage.promise;
      }

      function saveTitle(noteId, subjectId, orgId, title, user) {
        var updateNoteTitle = {
          id: noteId,
          subject: {
            id: subjectId,
          },
          orgId: {
            id: orgId,
          },
          title: title,
          modifiedBy: {
            id: user.userId,
          },
          initiatingUserId: user.userId,
        };

        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        api.post('/v1/commands/UpdateNoteTitle', updateNoteTitle).then(null, function failure(reason) {
          deferred.reject(reason);
        });

        $rootScope.$on('NoteTitleUpdated', function(name, event) {
          if (event.id == noteId) {
            deferred.resolve();
          }
        });

        return deferred.promise;
      }

      function saveTitleAndMessage(noteId, subjectId, orgId, title, message, user) {
        var titleReceived = false,
          messageReceived = false;
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        saveMessage(noteId, subjectId, orgId, message, user).then(
          function() {
            messageReceived = true;
            if (titleReceived === true) {
              deferred.resolve();
            }
          },
          function(reason) {
            deferred.reject(reason);
          }
        );
        saveTitle(noteId, subjectId, orgId, title, user).then(
          function() {
            titleReceived = true;
            if (messageReceived === true) {
              deferred.resolve();
            }
          },
          function(reason) {
            deferred.reject(reason);
          }
        );

        return deferred.promise;
      }
    },
  ]
);
