import { useContext } from 'react';
import AngularContext from '../contexts/AngularContext';

const useAngularScope = () => {
  const scope = useContext(AngularContext);

  const updateScope = fn => {
    fn(scope);
    scope.$applyAsync();
  };

  return [scope, updateScope];
};

export default useAngularScope;
