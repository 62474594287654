angular.module('lwNamb').factory(
  'permissionsService',

  [
    function () {
      var permissions = [
        { access: 'Roles View', label: 'Can Create/Remove Invites' },
        { access: 'Groups View', label: 'Can Create/Remove Groups' },
        { access: 'Build View', label: 'Can Build Training' },
        { access: 'Assign View', label: 'Can Assign Training' },
        { access: 'Account View', label: 'Can Manage Organization' },
        { access: 'ManagePrepareEnrich', label: 'Can Manage Prepare-Enrich' },
        { access: 'Build Curriculum View', label: 'Can Build Curriculum' },
      ];

      permissions.push({ access: 'Comment Moderator', label: 'Can Moderate Comments' });

      return {
        get: function () {
          return angular.copy(
            permissions.filter(function (permission) {
              return permission.access !== 'ManagePrepareEnrich';
            })
          );
        },
      };
    },
  ]
);
