import React from 'react';
import useModal from '../../hooks/useModal';
import { AddPathwayStepModal } from '../components/modals/AddPathwayStepModal';
import { RemoveTrainingStepModal } from '../components/modals/RemoveTrainingStepModal';
import { TrainingEasyLinkModal } from '../components/modals/TrainingEasyLinkModal';
import { PublishTrainingModal } from '../components/modals/PublishTrainingModal';
import { UnpublishTrainingModal } from '../components/modals/UnpublishTrainingModal';
import { CloneTrainingModal } from '../components/modals/CloneTrainingModal';

type ModalType = 'addStep' | 'removeStep' | 'easyLink' | 'publishModal' | 'unpublishModal' | 'cloneTraining';

type AddStepPayload = {
  selectedIndex: number;
  workflowEntities: { id: string }[];
  tasklists: { id: string }[];
};

type RemoveStepPayload = {
  tasklist: { _type: string; id: string; subject: string; name: string };
  remove: (id: string) => Promise<void>;
};

type EasyLinkPayload = {
  trainingId: string;
  workflowName: string;
  orgId: string;
};

type HandlerPayload<T = null> = {
  handler: (data?: T) => Promise<void>;
};

type Payload = AddStepPayload | RemoveStepPayload | EasyLinkPayload | HandlerPayload | HandlerPayload<string>;

export const usePathwayModal = () => {
  const [modal, openModal, dismissModal] = useModal((type: ModalType, payload: Payload, dismissHandler: () => void) => {
    if (type === 'addStep') {
      const { selectedIndex, workflowEntities, tasklists } = payload as AddStepPayload;
      return (
        <AddPathwayStepModal
          previousId={selectedIndex === 0 ? workflowEntities[selectedIndex]?.id : tasklists[selectedIndex - 1]?.id}
          nextId={
            selectedIndex === tasklists?.length ? workflowEntities[selectedIndex + 1]?.id : tasklists[selectedIndex]?.id
          }
          dismissHandler={dismissHandler}
        />
      );
    }

    if (type === 'removeStep') {
      const { tasklist, remove } = payload as RemoveStepPayload;
      return (
        <RemoveTrainingStepModal
          subtext={tasklist._type === 'Email' ? tasklist.subject : tasklist.name}
          handleSubmit={() => remove(tasklist.id)}
          handleDismiss={dismissHandler}
        />
      );
    }

    if (type === 'easyLink') {
      const { trainingId, workflowName, orgId } = payload as EasyLinkPayload;
      return (
        <TrainingEasyLinkModal
          trainingId={trainingId}
          trainingName={workflowName}
          orgId={orgId}
          handleDismiss={dismissHandler}
        />
      );
    }

    if (type === 'publishModal') {
      const { handler } = payload as HandlerPayload;
      return <PublishTrainingModal trainingType="Pathway" publish={handler} handleDismiss={dismissHandler} />;
    }

    if (type === 'unpublishModal') {
      const { handler } = payload as HandlerPayload;
      return <UnpublishTrainingModal trainingType="Pathway" unpublish={handler} handleDismiss={dismissHandler} />;
    }

    if (type === 'cloneTraining') {
      const { handler } = payload as HandlerPayload<string>;
      return <CloneTrainingModal trainingType="Pathway" handleCopy={handler} handleDismiss={dismissHandler} />;
    }

    return null;
  });

  const openAddStepModal = (selectedIndex: number, workflowEntities: { id: string }[], tasklists: { id: string }[]) =>
    openModal('addStep', { selectedIndex, workflowEntities, tasklists });

  const openRemoveStepModal = (
    tasklist: { _type: string; id: string; subject?: string; name: string },
    remove: (id: string) => Promise<void>
  ) => openModal('removeStep', { tasklist, remove });

  const openEasyLinkModal = (trainingId: string, workflowName: string, orgId: string) =>
    openModal('easyLink', { trainingId, workflowName, orgId });

  const openPublishModal = (handler: () => Promise<void>) => openModal('publishModal', { handler });

  const openUnpublishModal = (handler: () => Promise<void>) => openModal('unpublishModal', { handler });

  const openCopyModal = (handler: (name: string) => Promise<void>) => openModal('cloneTraining', { handler });

  return {
    modal,
    openAddStepModal,
    openRemoveStepModal,
    openEasyLinkModal,
    openPublishModal,
    openUnpublishModal,
    openCopyModal,
    dismissModal,
  };
};
