angular.module('lwNamb').directive(
  'addPeople',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/add-people.html',
        scope: {
          callback: '=',
        },
        controller: [
          '$scope',
          function($scope) {
            $scope.hideFormOnMobile = true;

            $scope.toggleForm = function() {
              $scope.hideFormOnMobile = false;
            };

            $scope.handleSubmit = function() {
              $scope.isAdding = true;
              $scope.noEmailsFound = false;
              $scope.addedMsg = false;
              $scope.existingMsg = false;

              var emails = parseEmails(($scope.emailsInput ? $scope.emailsInput : ''));
              if (emails.length === 0) {
                $scope.noEmailsFound = true;
                $scope.isAdding = false;
              } else {
                var result = $scope.callback(emails);
                if (result.addedCount > 0) {
                  $scope.addedMsg = result.addedCount + ' ' + (result.addedCount === 1 ? 'person was' : 'people were') + ' added.';
                } else if (result.alreadyAddedCount > 0) {
                  $scope.addedMsg = 'All people are already added.';
                }
                if (result.existingCount > 0) {
                  $scope.existingMsg = 'We ' + ($scope.addedMsg ? 'also ' : '') +  'found ' + result.existingCount + ' existing ' + (result.existingCount === 1 ? 'person' : 'people') + ', so we\'ve included them in the selection below.';
                }
                $scope.addPeople.$setPristine();
                $scope.addPeople.$setUntouched();
                $scope.isAdding = false;
              }
            };

            var validEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"-]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/);

            function parseEmails(input) {
              return input.replace(/[\s<>():=|!;"]/g, ',').split(',').map(function (maybeEmail) {
                if (validEmail.test(String(maybeEmail))) {
                  return maybeEmail.toLowerCase();
                } else {
                  return false;
                }
              }).filter(function (result) { return !!result; });
            }
          },
        ],
      };
    },
  ]
);
