import React from 'react';
import styled from 'styled-components';
import { Grid, Type } from '../StyleGuide';

import { useSteps } from './StepsContext';
import WhiteLabel from '../filters/WhiteLabel';
import capitalizeFilter from '../filters/capitalize-filter';

const Container = styled.div`
  padding: ${Grid._5};
  color: ${Type.Color.dark};
  p:last-of-type {
    color: ${Type.Color.medium};
    margin-top: ${Grid._5};
  }
`;

export default function TrainingOverview() {
  const [{ training, isWorkflow }] = useSteps();
  const trainingType = isWorkflow
    ? capitalizeFilter(WhiteLabel.labels.pathway)
    : capitalizeFilter(WhiteLabel.labels.course);
  const trainingDescription = isWorkflow ? training.workflowDescription : training.description;
  const description = trainingDescription ? trainingDescription : 'No description provided';

  return (
    <Container>
      <p data-qa-hook="trainingDescription" dangerouslySetInnerHTML={{ __html: description }}></p>
      <p>
        {trainingType} &bull; {!isWorkflow && training.ownerName}
      </p>
    </Container>
  );
}
