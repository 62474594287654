angular.module('lwNamb').directive('profileFallback', [
  '$timeout',
  function($timeout) {
    return {
      restrict: 'C',
      scope: {},
      link: function(scope, element, attrs) {
        $timeout(function() {
          element.bind('error', function() {
            attrs.$set('style', 'display:none !important;');
            var fallback = angular.element('<i class="fa fa-user-circle profile-image"></i>');
            fallback.insertAfter(element);
          });
        }, 10);
      },
    };
  },
]);
