angular.module('lwNamb').filter('questionIconClass', function() {
  var classes = {
    radio: 'fa-list-ul',
    sort: 'fa-sort',
    textbox: 'fa-font',
  };

  return function(questionType) {
    return classes[questionType] || '';
  };
});
