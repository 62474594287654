import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Breakpoints, Type } from '../StyleGuide';
import styled from 'styled-components';
import { Container } from '../components/Layout';
import BibleStudyTabs from './BibleStudyTabs';
import BibleStudyBanner from './BibleStudyBanner';
import BreadcrumbsSubMenu from '../components/BreadcrumbsSubMenu';
import MobileCrumb from '../components/MobileCrumb';
import { MediumOutlineButton } from '../components/Buttons';
import curriculumService from '../services/curriculumService';
import licenseService from '../services/licenseService';
import useCurriculum from '../hooks/useCurriculum';
import useModal from '../hooks/useModal';
import { useUser } from '../authentication';
import AnnualSubscriptionModal, { AnnualUpgradeActionWrapper } from './AnnualSubscriptionModal';
import alertService from '../services/AlertService';

const Header = styled.header`
  display: flex;
  flex-direction: column;
  margin: ${Grid._4} auto;
  @media screen and (min-width: ${Breakpoints.screen_lg}) {
    margin: ${Grid._5} auto;
  }
  h1 {
    font-weight: ${Type.Weight.bold};
    font-size: ${Type.Scale._6};
    text-transform: capitalize;
    color: ${Type.Color.dark};
    margin: ${Grid._3} 0 0;
    order: 1;
  }
`;

const ContentContainer = styled(Container)`
  position: relative;

  ${MediumOutlineButton} {
    position: absolute;
    top: 0;
    right: ${Grid._4};
  }
`;

export default function BibleStudyNavigation({ crumbs, curriculumId, activeTab }) {
  const user = useUser();
  const { brand, ageCategory } = useCurriculum();
  const [showAdvancePlanning, setShowAdvancePlanning] = useState(false);
  const [subscription, setSubscription] = useState();

  const findCurriculum = (crmId, curriculums) =>
    curriculums.map(c => c.curriculum.find(({ id }) => id === crmId)).filter(Boolean)[0];

  const getActiveCurriculumSubscription = (licenses, curriculumLicenses) =>
    licenses.find(license => {
      if (!curriculumLicenses.find(cl => cl.itemNumber === license.item?.number)) return;
      if (!licenseService.isActiveSubscription(license) || !licenseService.isMonthlySubscription(license)) return;
      return true;
    })?.subscription;

  useEffect(() => {
    if (!curriculumId || !user) return;

    curriculumService
      .getAdvancePlanningMaterials(curriculumId)
      .then(volumes => setShowAdvancePlanning(volumes.length > 0))
      .catch(reason => console.error(reason));

    Promise.all([licenseService.getPairedLicenses(user.lastSelectedAccount), curriculumService.getPricing()]).then(
      ([licenses, curriculums]) => {
        const curriculum = findCurriculum(curriculumId, curriculums);
        if (!curriculum) return;

        const curriculumSubscription = getActiveCurriculumSubscription(licenses, curriculum.license);
        if (!curriculumSubscription) return;

        setSubscription(curriculumSubscription);
      }
    );
  }, [curriculumId, user]);

  const [modal, openModal] = useModal((type, payload, dismissModal) => {
    return type ? (
      <AnnualSubscriptionModal
        subscription={payload}
        onUpgradeComplete={handleUpgradedSubscription}
        handleDismiss={dismissModal}
      />
    ) : null;
  });

  function handleUpgradedSubscription(newSubscription) {
    alertService.show('Subscription Updated');
    setSubscription(prev => ({ ...prev, ...newSubscription }));
  }

  return (
    <>
      <div className="hidden-xs hidden-sm">
        <BreadcrumbsSubMenu crumbs={crumbs} />
      </div>
      <BibleStudyBanner />
      <ContentContainer>
        <Header>
          <h1>{ageCategory}</h1>
          <div className="visible-xs-block visible-sm-block">
            <MobileCrumb crumbs={crumbs} />
          </div>
        </Header>
        <BibleStudyTabs>
          <li className={activeTab === 'people' ? 'active-tab' : ''}>
            <button
              data-qa-hook="peopleTab"
              onClick={() =>
                (window.location = `#/manage-bible-study/people/${brand.code}/${ageCategory}/${curriculumId}`)
              }
            >
              <span className="fas fa-user-friends fa-fw hidden-xs"></span>People
            </button>
          </li>
          <li className={activeTab === 'schedule' ? 'active-tab' : ''}>
            <button
              data-qa-hook="scheduleTab"
              onClick={() =>
                (window.location = `#/manage-bible-study/schedule/${brand.code}/${ageCategory}/${curriculumId}`)
              }
            >
              <span className="far fa-calendar-alt fa-fw hidden-xs"></span>Schedule
            </button>
          </li>
          {showAdvancePlanning && (
            <li className={activeTab === 'advance-planning' ? 'active-tab' : ''}>
              <button
                data-qa-hook="advancePlanningTab"
                onClick={() =>
                  (window.location = `#/manage-bible-study/advance-planning/${brand.code}/${ageCategory}/${curriculumId}`)
                }
              >
                <span className="far fa-folder-open fa-fw hidden-xs"></span>Advance Planning
              </button>
            </li>
          )}
        </BibleStudyTabs>
        <AnnualUpgradeActionWrapper subscription={subscription}>
          {annualSubscription => (
            <MediumOutlineButton onClick={() => openModal('annual', annualSubscription)}>
              <i className="fas fa-cog"></i> Switch to Annual
            </MediumOutlineButton>
          )}
        </AnnualUpgradeActionWrapper>
      </ContentContainer>
      {modal}
    </>
  );
}

BibleStudyNavigation.propTypes = {
  crumbs: PropTypes.array.isRequired,
  curriculumId: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
};
