angular.module('lwNamb').controller(
  'InviteEditCtrl',

  [
    '$scope',
    'userService',
    'inviteService',
    '$routeParams',
    '$log',
    'alertService',
    function($scope, userService, inviteService, $routeParams, $log, alertService) {
      var init = function() {
        $scope.loading = true;
        $scope.orgId = $routeParams.orgId;
        $scope.inviteId = $routeParams.inviteId;

        userService.user().then(
          function(user) {
            $scope.user = user;
            retrieveUserData();
          },
          function() {
            $log.error('No user returned in InviteEditCtrl');
          }
        );
      };

      var retrieveUserData = function() {
        inviteService.getInvite($scope.orgId, $scope.inviteId).then(
          function(response) {
            $scope.invite = response;
            $scope.loading = false;
          },
          function(reason) {
            $scope.loading = false;
            $log.error(reason);
            alertService.show({ title: 'Error! Something went wrong.', type: 'danger', show: true, dismissible: true, duration: 20, });
          }
        );
      };

      $scope.resendEmail = function(recipientEmail, closeModal) {
        $scope.resending = true;
        inviteService
          .resendEmailInvite($scope.invite.id, $scope.invite.orgId.id, recipientEmail, $scope.user.userId)
          .then(
            function(response) {
              alertService.show({
                title: 'Invitation Resent',
                type: 'success',
                show: true,
                duration: 5,
                dismissible: true,
              });
            },
            function(reason) {
              $log.error(reason);
              alertService.show({ title: 'Error! Resend Failed.', type: 'danger', show: true, dismissible: true, duration: 20, });
            }
          )
          .finally(function() {
            closeModal();
            $scope.resending = false;
          });
      };

      $scope.disableInvitee = function(recipientEmail, closeModal) {
        $scope.disabling = true;
        inviteService
          .removeInvitee($scope.inviteId, recipientEmail, $scope.user.userId)
          .then(
            function(response) {
              alertService.show({
                title: 'Invitation Disabled',
                type: 'success',
                show: true,
                duration: 5,
                dismissible: true,
              });
              retrieveUserData();
            },
            function(reason) {
              $log.error(reason);
              alertService.show({ title: 'Error! Disable Failed.', type: 'danger', show: true, dismissible: true, duration: 20, });
            }
          )
          .finally(function() {
            closeModal();
            $scope.disabling = false;
          });
      };

      $scope.clearInput = function() {
        $scope.searchRecipients = {};
        $scope.searchRedemptions = {};
        $scope.searchByTextField = { $: undefined };
      };

      init();
    },
  ] //End Invite Edit Ctrl
);
