import React from 'react';
import styled from 'styled-components';
import { Grid, Color, Type, Breakpoints } from '../StyleGuide';
import PropTypes from 'prop-types';

const Header = styled.h4`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: ${Type.Weight.bold};
  color: ${Type.Color.dark};
  border-bottom: ${Grid._1} solid ${Color.Gray._10};
  padding-bottom: ${Grid._2};
  margin: ${props => (props.margin ? props.margin : `0 0 ${Grid._5} 0`)};
  span {
    padding-bottom: 10px;
    margin-bottom: -8px;
    border-bottom: ${Grid._1} solid ${Color.Orange._50};
    &.with-subtitle {
      border-bottom: 0px;
      @media screen and (min-width: ${Breakpoints.screen_sm}) {
        border-bottom: ${Grid._1} solid ${Color.Orange._50};
      }
    }
  }
  .subtitle {
    color: ${Type.Color.medium};
    border-bottom: ${Grid._1} solid ${Color.Orange._50};
    &::before {
      content: '\\a';
      white-space: pre;
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      border-bottom: 0;
      &::before {
        content: '';
      }
    }
  }
`;

export default function SectionHeader({ title, subtitle, margin, children }) {
  return (
    <Header data-qa-hook="sectionHeader" margin={margin}>
      <span className={subtitle ? 'sectionHeader with-subtitle' : 'sectionHeader'}>
        {title}
        {subtitle && <span className="subtitle"> {subtitle}</span>}
      </span>
      {children}
    </Header>
  );
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  margin: PropTypes.string,
};
