angular.module('lwNamb').directive(
  'completedTasklistAdmin',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/completed-tasklist-admin.html',
      };
    },
  ]
);
