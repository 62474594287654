import React, { Fragment, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from '../components/Buttons';
import ConfirmationModal from '../components/ConfirmationModal';
import { Select, TextFilterInput } from '../components/FormElements';
import PeopleTable from '../components/PeopleTable';
import useOrg from '../hooks/useOrg';
import useModal from '../hooks/useModal';
import usePropertyFilter from '../hooks/usePropertyFilter';
import alertService from '../services/AlertService';
import orgService from '../services/orgService';
import windowService from '../services/windowService';
import ActiveFilters from '../components/ActiveFilters';
import WhiteLabel from '../filters/WhiteLabel';

function RemoveModal({ dismissModal, setPeople, org, user, person, ...props }) {
  const onSubmit = () => {
    const removePerson = () =>
      person._type === 'PendingUser'
        ? orgService.removePendingMember(person.inviteId.id, person.emailAddress, org.id, user.userId)
        : orgService.removeMembers(org.id, [person], user.userId);

    removePerson()
      .then(() => {
        alertService.show('Person removed');
        if (person._type === 'PendingUser') {
          setPeople(prev => ({
            ...prev,
            people: prev?.people.filter(member =>
              member.inviteId ? member.inviteId.id !== person.inviteId.id : member
            ),
          }));
        } else {
          setPeople(prev => ({
            ...prev,
            people: prev?.people.filter(member => (member._id ? member._id.id !== person._id.id : member)),
          }));
        }
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => dismissModal());
  };

  return (
    <ConfirmationModal
      buttonActionText={'Remove'}
      buttonType="danger"
      title="Remove Member"
      prompt={<span>{`Are you sure you want to remove this person from ${org.name}`}?</span>}
      handleSubmit={onSubmit}
      {...props}
    >
      {person.firstName && person.lastName && (
        <p>
          <b>{`${person.firstName} ${person.lastName}`}</b>
        </p>
      )}

      <p>
        <b>{`${person.emailAddress || person.email}`}</b>
      </p>
      <p>
        Doing so will cause them to lose access to any content that your {WhiteLabel.labels.org} subscribes to. If you
        want to add them back to your {WhiteLabel.labels.org}, you will have to re-add them.
      </p>
      <p className="help-block">
        <i className="icon ion-android-alert"></i> This action cannot be undone
      </p>
    </ConfirmationModal>
  );
}

RemoveModal.propTypes = {
  person: PropTypes.object.isRequired,
  dismissModal: PropTypes.func,
  user: PropTypes.object,
  org: PropTypes.object.isRequired,
  setPeople: PropTypes.func,
};

const People = ({ user, filters, unfilteredMembers, filteredMembers, query, setQuery, setPeople }) => {
  const { sortBy, sortedResults, updateSortBy, resetSortBy } = usePropertyFilter(filteredMembers, filters);
  const [status, setStatus] = useState('None Selected');
  const org = useOrg();

  const activeFilters = Object.entries(sortBy).reduce((acc, [key, value]) => {
    if (value.length > 0) acc[key] = () => resetSortBy(key);
    return acc;
  }, {});
  if (status !== 'None Selected') activeFilters['status'] = () => setStatus('None Selected');

  const activePeople = useMemo(() => {
    return sortedResults.filter(person => person._type !== 'PendingUser');
  }, [sortedResults]);

  const pendingPeople = useMemo(() => {
    return sortedResults.filter(person => person._type === 'PendingUser');
  }, [sortedResults]);

  const resetStatus = () => setStatus('None Selected');

  const resetFilters = () => {
    Object.keys(sortBy).forEach(filter => resetSortBy(filter));
    resetStatus();
  };

  const onDismiss = () => {
    dismissModal();
  };

  const onOpenModal = person => {
    openModal('removePerson', {
      person,
    });
  };

  const onResendInviteEmail = (inviteId, emailAddress, initiatingUserId) => {
    orgService
      .resendEmailInvite(inviteId, org.id, emailAddress, initiatingUserId)
      .then(() => {
        alertService.show('Invitation Resent');
      })
      .catch(() => {
        alertService.showError('Error! Resend Failed.');
      });
  };

  const [modal, openModal, dismissModal] = useModal((type, payload, dismissModal) => {
    switch (type) {
      case 'removePerson':
        return (
          <RemoveModal
            handleDismiss={onDismiss}
            dismissModal={dismissModal}
            org={org}
            user={user}
            person={payload.person}
            setPeople={setPeople}
          />
        );
      default:
        return null;
    }
  });

  const sortedResultsWithStatus =
    status === 'active' ? activePeople : status === 'pending' ? pendingPeople : sortedResults;

  return (
    <>
      <div className={`grid-container grid-gap-16 grid-sm-col-6 add-people-controls`}>
        <div className="primary-action">
          <PrimaryButton data-qa-hook="addPeopleButton" onClick={() => windowService.redirectTo('#/add-people')}>
            Add
          </PrimaryButton>
        </div>

        {Object.entries(sortBy).map(([key, items]) => {
          const defaultValue = items.length ? items.length + ' Selected' : 'None Selected';

          return (
            <div key={key} className="add-people-sort">
              <label>{filters[key].filterLabel ? filters[key].filterLabel : filters[key].displayName}</label>
              <Select
                data-qa-hook={key}
                value={defaultValue}
                onChange={event => {
                  updateSortBy(event.target.value, key);
                }}
              >
                <option defaultValue>{defaultValue}</option>
                {filters[key].items?.map(item => (
                  <option name={item.name} key={item.name} value={item.id} id={item.id}>
                    {items.includes(item.id) ? '✓ ' : ' '} {item.name}
                  </option>
                ))}
              </Select>
            </div>
          );
        })}

        <div className="add-people-sort">
          <label>Status</label>

          <Select data-qa-hook="status" value={status} onChange={event => setStatus(event.target.value)}>
            <option defaultValue="">None Selected</option>
            <option name="active" value="active">
              Active User ({activePeople.length})
            </option>
            <option name="pending" value="pending">
              Pending User ({pendingPeople.length})
            </option>
          </Select>
        </div>

        <div className={`grid-sm-col-span-2`}>
          <TextFilterInput
            id="addPeopleFilter"
            value={query || ''}
            onChangeHandler={e => setQuery(e.target.value)}
            clearInput={() => {
              setQuery('');
            }}
            placeholder={'Search People'}
          />
        </div>
      </div>
      <div className="grid-sm-col-span-1">
        <div className="sort-selections-container">
          <div className="grid-sm-col-span-1">
            {!!Object.keys(activeFilters).length && (
              <ActiveFilters
                count={sortedResultsWithStatus?.length}
                filters={activeFilters}
                onClearAll={resetFilters}
              />
            )}
          </div>
        </div>
      </div>

      {unfilteredMembers?.length > 0 && (
        <>
          {sortedResultsWithStatus?.length > 0 ? (
            <PeopleTable
              people={sortedResultsWithStatus}
              user={user}
              onOpenModal={onOpenModal}
              resendEmail={onResendInviteEmail}
            />
          ) : (
            <>
              <h4>Your search does not have any matches.</h4>
              <p>Please try another search.</p>
            </>
          )}
          {!filteredMembers && <p>Loading</p>}
        </>
      )}

      {modal}
    </>
  );
};

People.propTypes = {
  user: PropTypes.object,
  filters: PropTypes.object,
  unfilteredMembers: PropTypes.array,
  filteredMembers: PropTypes.array,
  query: PropTypes.string,
  setQuery: PropTypes.func,
  setPeople: PropTypes.func,
};

export default People;
