import React from 'react';
import { useSearch } from '../hooks/useSearch';
import { SearchResultItem } from '../models/Search';
import { SearchResultListItem } from './SearchResultListItem';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorMessage from '../../components/ErrorMessage';
import EmptyState from '../../components/EmptyState';

const NoResultsFound = () => {
  return (
    <EmptyState icon="fa-search" title="Your search does not have any matches" description="">
      <h6>Try these tips for better results</h6>
      <p>Use a broader search term</p>
      <p>Clear any active search filters</p>
      <p>Double check your spelling</p>
    </EmptyState>
  );
};

type Props = {
  render?: (results: SearchResultItem[]) => React.ReactNode;
  ListItemComponent?: React.ComponentType<{ result: SearchResultItem }>;
};

export const SearchResultsList = ({ render, ListItemComponent = SearchResultListItem }: Props) => {
  const { searchResults, status } = useSearch();

  if (status.isLoading || status.isSearching) return <LoadingIndicator />;
  if (status.isError) return <ErrorMessage />;

  if (!searchResults.length) return <NoResultsFound />;

  if (render) return render(searchResults);

  return searchResults.map(result => <ListItemComponent key={result.id} result={result} />);
};
