import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Color, Type } from '../StyleGuide';

export const Badge = styled.span`
  display: inline-block;
  color: ${Type.Color.dark};
  background: none;
  border: 1px solid ${Color.Gray._30};
  font-size: ${Type.Scale._1};
  margin: 0;
  padding: ${Grid._2} ${Grid._3};
  border-radius: 2px;

  &.clickable {
    cursor: pointer;
  }
`;

export const SuccessBadge = styled(Badge)`
  color: ${Color.Primary._70};
  background: ${Color.Primary._05};
  border-color: ${Color.Primary._10};
`;

export const InfoBadge = styled(Badge)`
  color: ${Type.Color.dark};
  background: ${Color.Blue._125};
  border-color: ${Color.Blue._15};
`;

export const PrimaryBadge = styled(Badge)`
  color: ${Color.white};
  background: ${Color.Primary._50};
  border: none;
`;

export const SecondaryBadge = styled(Badge)`
  color: ${Color.white};
  background: ${Color.Secondary._80};
  border: none;
`;

export const OutlineBadge = styled(Badge)`
  background: transparent;
  border: 1px solid ${Color.Gray._80};
  color: ${Color.Gray._80};
`;

export const DeemphasizedBadge = styled(Badge)`
  color: ${Type.Color.placeholder};
  font-weight: ${Type.Weight.light};
`;

const withRounded = Component => styled(Component)`
  border-radius: 15px;
`;

export const RoundedBadge = withRounded(Badge);
export const RoundedPrimaryBadge = withRounded(PrimaryBadge);
export const RoundedSecondaryBadge = withRounded(SecondaryBadge);
export const RoundedDeemphasizedBadge = withRounded(DeemphasizedBadge);

export const IconBadge = styled.span`
  background: ${Color.Blue._50};
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${Type.Scale._5};
  margin-right: ${Grid._3};
`;

export const MediumIconBadge = styled(IconBadge)`
  height: 40px;
  width: 40px;
  font-size: 20px;
`;

export const SmallIconBadge = styled(IconBadge)`
  height: ${Grid._6};
  width: ${Grid._6};
  font-size: ${Type.Scale._3};
`;

export const SessionBadge = ({ session }) => (
  <span className="session-badge">
    {session.isScheduled === false ? (
      <SecondaryBadge data-qa-hook="notScheduled">Not Scheduled</SecondaryBadge>
    ) : (
      session.isThisWeek && <SecondaryBadge data-qa-hook="thisWeeksSession">This Week's Session</SecondaryBadge>
    )}
  </span>
);
