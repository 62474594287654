import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import windowService from '../../../services/windowService';
import { PrimaryButton } from '../../../components/Buttons';
import { Radio, RadioList } from '../../../components/FormElements';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../components/ModalElements';
import { Grid } from '../../../StyleGuide';
import { UrlParams } from '../../models/url';

const StyledRadioList = styled(RadioList)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: ${Grid._3};
`;

const StyledList = styled.li`
  width: 100%;
  margin: 0 !important;
`;

type Params = {
  position: number;
  isOwner: boolean;
  handleDismiss: () => void;
};

const StepTypes = [
  'Text',
  'Upload',
  'Link',
  'Assessment',
  'Download',
  'YouTube',
  'Video',
  'Vimeo',
  'Audio',
  'Zoom',
] as const;
type StepType = (typeof StepTypes)[number];

export const AddCourseStepModal = ({ position, isOwner, handleDismiss }: Params) => {
  const { trainingId, buildType } = useParams<UrlParams>();

  const handleStep = (stepType: StepType) => {
    switch (stepType) {
      case 'Text':
      case 'Upload':
      case 'Link':
      case 'YouTube':
      case 'Vimeo':
      case 'Zoom':
        return windowService.redirectTo(
          `${buildType}/courses/${trainingId}/tasks/?taskType=${stepType}&order=${position}`
        );

      case 'Assessment':
      case 'Video':
      case 'Download':
      case 'Audio':
        return windowService.redirectTo(
          `${buildType}/courses/${trainingId}/content-select/?taskType=${stepType}&order=${position}&isOwner=${isOwner}`
        );

      default:
        return null;
    }
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>Add Step</h3>
      </ModalHeader>

      <Formik
        initialValues={{
          stepType: '',
        }}
        onSubmit={({ stepType }) => {
          handleStep(stepType as StepType);
        }}
      >
        {({ handleChange, isSubmitting }) => (
          <Form>
            <ModalBody>
              <h3>Select a step type you want to add.</h3>
              <StyledRadioList>
                {StepTypes.map(type => (
                  <StyledList key={type}>
                    <label>
                      <Radio name="stepType" value={type} onChange={handleChange} />
                      {type}
                    </label>
                  </StyledList>
                ))}
              </StyledRadioList>
            </ModalBody>
            <ModalFooter>
              <PrimaryButton type="submit" operating={isSubmitting}>
                Continue
              </PrimaryButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};
