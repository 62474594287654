angular.module('lwNamb').factory('buildService', [
  '$route',
  '$location',
  'userService',
  'catalogService',
  'properties',
  function ($route, $location, userService, catalogService, props) {
    var service = {
      isAuthoring: function () {
        return currentRoute().section === props.sections.author;
      },
      isScorecards: function () {
        return currentRoute().section === props.sections.scorecards;
      },
      ownerId: function () {
        if (service.isAuthoring()) {
          return catalogService.getCatalogForSite().then(function (catalog) {
            return catalog._id ? catalog._id.id : 'catalog-id-not-found';
          });
        } else {
          return userService.user().then(function (user) {
            return user.lastSelectedAccount;
          });
        }
      },
      ctxRedirectTo: function (path, optQueryParams) {
        var context = '/build';
        if (service.isAuthoring()) {
          context = '/author';
        }
        $location.url(context + path);
        if (optQueryParams) {
          $location.search(optQueryParams);
        }
      },
      ctxRedirectBackTo: function (path, optQueryParams) {
        var context = '/manage-training/build';
        if (service.isAuthoring()) {
          context = 'manage-training/author';
        }
        $location.url(context + path);
        if (optQueryParams) {
          $location.search(optQueryParams);
        }
      },
    };

    return service;

    function currentRoute() {
      var current = $route.current || {};
      return current.$$route || {};
    }
  },
]);
