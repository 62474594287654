import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';

const TrainingWrapper = styled.div`
  margin-bottom: ${Grid._11};
  padding: ${Grid._5} ${Grid._4} ${Grid._11};
  background: white;
  color: ${Color.Gray._90};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: ${Grid._7} auto ${Grid._11};
    padding: ${Grid._10} ${Grid._7} ${Grid._11};
    border-radius: ${Border.radius};
  }
  @media screen and (min-width: ${Breakpoints.screen_md}) {
    padding: ${Grid._10} ${Grid._4} ${Grid._11};
  }
`;

export default TrainingWrapper;
