import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Type } from '../StyleGuide';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { Container } from '../components/Layout';
import Breadcrumbs from '../components/Breadcrumbs';
import EmptyState from '../components/EmptyState';
import Training from './Training';
import { useUser } from '../authentication';
import trainingService from '../services/trainingService';

const TrainingContainer = styled(Container)`
  background: ${Color.white};
  margin: ${Grid._6} auto;
  padding-bottom: ${Grid._2};
`;

const FilterContainer = styled.div`
  padding: ${Grid._4} 0;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._4} ${Grid._5};
  }
  div {
    flex: 1;
    margin-right: ${Grid._3};
  }
  label {
    display: flex;
    align-items: center;
    color: ${Type.Color.medium};
    margin: 0;
    b {
      display: none;
      @media screen and (min-width: 420px) {
        display: inline-block;
        margin-right: ${Grid._3};
      }
    }
  }
  input {
    padding: ${Grid._3};
    border-radius: ${Border.radius};
    border: 1px solid #ccc;
    box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 440px;
  }
  p {
    color: ${Type.Color.medium};
    margin: 0;
  }
`;

export default function TrainingProgress() {
  const user = useUser();
  const [data, setData] = useState({ training: [], isLoading: true, isError: false });
  const [filter, setFilter] = useState('');

  const { training, isLoading, isError } = data;

  const crumbs = [{ name: 'Organization', route: '#/org' }, { name: 'Training Progress' }];

  useEffect(() => {
    trainingService
      .getProgressSummary(user.lastSelectedAccount)
      .then(trainingProgress => setData({ isLoading: false, training: trainingProgress }))
      .catch(() => setData({ isLoading: false, isError: true }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredTraining = training.filter(training => training.name.toLowerCase().includes(filter.toLowerCase()));

  return isLoading ? (
    <LoadingState />
  ) : isError ? (
    <ErrorMessage />
  ) : (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <TrainingContainer>
        <>
          <FilterContainer>
            <div>
              <label>
                <b>Filter:</b>
                <input
                  data-qa-hook="assignmentFilter"
                  type="text"
                  placeholder="Enter assignment name"
                  value={filter}
                  onChange={e => setFilter(e.target.value)}
                />
              </label>
            </div>
            <p data-qa-hook="numberOfAssignments">
              {filteredTraining.length} assignment{filteredTraining.length !== 1 ? 's' : ''}
            </p>
          </FilterContainer>
          {filteredTraining.length ? (
            filteredTraining.map(training => <Training key={training.id} training={training} />)
          ) : (
            <EmptyState title="Your search does not have any matches." description="Please try another search." />
          )}
        </>
      </TrainingContainer>
    </>
  );
}
