import React from 'react';
import PropTypes from 'prop-types';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';
import { MediumPrimaryButton, MediumButton } from '../components/Buttons';
import windowService from '../services/windowService';

const DiscussionModal = ({ courseId, handleDismiss }) => {
  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3 data-qa-hook="removeModalHeader">Discussion is Turned Off</h3>
      </ModalHeader>
      <ModalBody data-qa-hook="removeModalMessage">
        <p>
          This Discussion is turned off for your organization. To turn on discussion for this course, go to the Settings
          page for the course in the course builder.
        </p>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton
          onClick={() => windowService.redirectTo(`#/build/courses/${courseId}/settings?origin=none`)}
        >
          Go to Settings
        </MediumPrimaryButton>
        <MediumButton onClick={() => handleDismiss()}>Cancel</MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

DiscussionModal.propTypes = {
  courseId: PropTypes.string,
  handleDismiss: PropTypes.func,
};

export default DiscussionModal;
