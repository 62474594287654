import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export default function CompletedAssessment({ assessment, step, completeStep }) {
  useEffect(() => {
    if (step === 'complete') return;
    completeStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <p>You have finished the {assessment.name} assessment.</p>
      <p className="text-color-medium">
        {assessment.questions.length} of {assessment.questions.length} questions answered
      </p>
      <div className="text-color-medium" dangerouslySetInnerHTML={{ __html: assessment.overview }}></div>
    </>
  );
}

CompletedAssessment.propTypes = {
  assessment: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  completeStep: PropTypes.func.isRequired,
};
