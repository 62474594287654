import apiClient from './apiClient';

const API_BASE_URL = getApiUrl(); // todo: improve config injection

const iOS = (() => {
  let result;
  return () => {
    if (result === undefined) {
      const ua = window.navigator.userAgent;
      result = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/iPod/i);
    }
    return result;
  };
})();

const queryString = fileName => {
  if (fileName !== undefined && iOS() === true && (fileName.indexOf('.mp4') > -1 || fileName.indexOf('.mov') > -1)) {
    return '?zip=true';
  } else {
    return '';
  }
};

const fileService = {
  getMetaData: fileId => {
    return apiClient.get(`/v1/files/${fileId}/meta-data`).then(response => response.data);
  },

  urlOpenFile: (fileId, fileName) => {
    return `${API_BASE_URL}/v1/files/${fileId}${queryString(fileName)}`;
  },

  urlDownloadFile: mediaId => {
    return `${API_BASE_URL}/curriculum-storage/files/${mediaId}`;
  },

  urlOpenPath: (filePath, fileName) => {
    return `${API_BASE_URL}${filePath}${queryString(fileName)}`;
  },
};

export default fileService;
