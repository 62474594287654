angular.module('lwNamb').controller(
  'AssessmentResultsViewerCtrl',

  [
    '$scope',
    '$log',
    '$routeParams',
    '$localStorage',
    'userService',
    'windowService',
    'assessResponseAggregatorService',
    function ($scope, $log, $routeParams, $localStorage, userService, windowService, assessResponseAggregatorService) {
      var init = function () {
        $scope.isLoadingResults = true;

        $scope.assessmentId = $routeParams.assessmentId;

        $scope.allResults = $localStorage.allResults !== undefined ? $localStorage.allResults : true;

        $scope.assessmentResultResponseIds =
          $localStorage.assessmentResultResponseIds !== undefined
            ? $localStorage.assessmentResultResponseIds
            : undefined;

        userService.user().then(
          function (user) {
            $scope.user = user;
            getResultsData();
          },
          function () {
            $log.error('No user returned in AssessmentResultsViewerCtrl');
          }
        );
      };

      $scope.filterByAllUsers = function () {
        $scope.assessmentResultResponseIds = $localStorage.assessment.responseIds;
        getResultsData();
      };

      $scope.filterByUsers = function () {
        windowService.redirectHash(`#/assessment/results/${$scope.assessmentId}/filter`);
      };

      function getResultsData() {
        //Send command to get results data
        assessResponseAggregatorService.getAllAssessmentResults($scope.assessmentResultResponseIds).then(
          function (response) {
            $scope.assessmentName = response.assessmentName;
            $scope.results = response;
            $scope.isLoadingResults = false;
          },
          function (reason) {
            $log.error(reason);
            $scope.error = true;
            $scope.isLoadingResults = false;
          }
        );
      }

      init();
    },
  ] //End Assessment Results Viewer Ctrl
);
