import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Border, Color, Depth, Type } from '../StyleGuide';
import RemoveIcon from '../components/RemoveIcon';
import ConfirmationModal from '../components/ConfirmationModal';
import ProfileImageList from '../components/ProfileImageList';

const Container = styled.div`
  background: white;
  box-shadow: inset 0 0 0 1px ${Color.Blue._20};
  box-sizing: border-box;
  border-radius: ${Border.radius};
  cursor: pointer;
  transition: box-shadow 0.125s linear;
  position: relative;
  overflow: hidden;
  .group-card-content {
    padding: ${Grid._5};
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 200px;
    .profile-images {
      margin: ${Grid._4} auto;
    }
  }
  &:hover {
    box-shadow: inset 0 0 0 1px ${Color.Blue._20}, ${Depth._1};
  }
  h3 {
    font-size: ${Type.Scale._2};
    color: ${Type.Color.dark};
    font-weight: ${Type.Weight.semibold};
    margin-bottom: ${Grid._2};
    margin-top: 0;
  }
  p {
    font-size: ${Type.Scale._1};
    color: ${Type.Color.medium};
    margin: 0;
  }
`;

const RemoveIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  .remove-icon-component {
    border-radius: 0 ${Border.radius} 0 ${Border.radius};
    &:hover {
      box-shadow: inset -1px 1px 0 ${Color.Red._20};
    }
  }
`;

const Label = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: ${Color.Gray._80};
  padding: ${Grid._2} ${Grid._3};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: white;
  text-shadow: ${Type.Shadow.light};
  font-weight: ${Type.Weight.semibold};
  font-size: ${Type.Scale._05};
  border-radius: ${Border.radius} 0 ${Border.radius} 0;
`;

export default function GroupCard({ group, redirectToGroup, handleRemoval }) {
  const [showRemoveGroupModal, setShowRemoveGroupModal] = useState(false);
  const totalMembers = group.groupUsers.length;
  const members = totalMembers === 1 ? '1 person' : totalMembers > 1 ? totalMembers + ' people' : 'No people';
  const noLeader = group.leaderCount === 0;

  return (
    <Container data-qa-hook="groupCard" className="group-card">
      <div className="group-card-content" onClick={redirectToGroup}>
        {totalMembers >= 1 && <ProfileImageList srcUrls={group.profileImages} totalUsers={totalMembers} />}
        <h3 data-qa-hook="groupName">{group.name}</h3>
        {group.subtext && <p>{group.subtext}</p>}
        <p>{members}</p>
        {noLeader && <Label>No Leader</Label>}
      </div>
      <RemoveIconContainer>
        <RemoveIcon title={'Remove Group'} onClickHandler={() => setShowRemoveGroupModal(true)} />
      </RemoveIconContainer>
      {showRemoveGroupModal && (
        <ConfirmationModal
          buttonActionText="Remove"
          buttonType="danger"
          title="Remove Group"
          prompt="Are you sure you want to remove this group?"
          subtext={group.name}
          handleSubmit={handleRemoval}
          handleDismiss={() => setShowRemoveGroupModal(false)}
        >
          {group.groupUsers.length >= 1 && (
            <p className="modal-component-state-message">
              <span className="icon ion-android-alert"></span> Any existing members will be removed from the group
            </p>
          )}
        </ConfirmationModal>
      )}
    </Container>
  );
}

GroupCard.propTypes = {
  redirectToGroup: PropTypes.func.isRequired,
  handleRemoval: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
};
