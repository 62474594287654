import apiClient from './apiClient';

const sortByDateAscending = arr => arr.sort((a, b) => new Date(a.created) - new Date(b.created));

const trackIDs = (user, originalTaskListId, currentStep, discussionId, commentId) => {
  return {
    orgId: {
      id: user.lastSelectedAccount,
    },
    originalTaskListId: {
      id: originalTaskListId,
    },
    stepId: currentStep.task._id.id,
    discussionId: discussionId ? discussionId : null,
    commentId: commentId ? commentId : null,
  };
};

const discussionService = {
  getDiscussion: (user, originalTaskListId, currentStep) =>
    apiClient
      .get(
        '/discussion-api/discussion?key=' +
          user.lastSelectedAccount +
          '&excludeUnapproved=1' +
          '&filter=' +
          originalTaskListId +
          '_' +
          currentStep.task._id.id
      )
      .then(response => {
        return response.data[0]
          ? {
              ...response.data[0],
              comments:
                response.data[0].comments?.length > 1
                  ? sortByDateAscending(response.data[0].comments)
                  : response.data[0].comments,
            }
          : response.data[0];
      }),
  createDiscussion: (user, originalTaskListId, currentStep, origin) => {
    let cmd = {
      author: user.displayName,
      title: currentStep.task.taskName,
      description: 'Description',
      channel: origin,
      key: user.lastSelectedAccount,
      filter: originalTaskListId + '_' + currentStep.task._id.id,
      ids: trackIDs(user, originalTaskListId, currentStep),
    };
    return apiClient.post('/discussion-api/discussion', cmd).then(response => response.data);
  },
  postComment: (commentText, discussionId, user, originalTaskListId, currentStep) => {
    let cmd = {
      lifeway_id: user.userId,
      text: commentText,
      status: 'approved',
      author: user.displayName,
      email: user.logInEmail,
      ids: trackIDs(user, originalTaskListId, currentStep, discussionId),
    };
    return apiClient.post('/discussion-api/' + discussionId + '/comment', cmd).then(response => response.data);
  },
  postReply: (commentText, commentId, discussionId, user, originalTaskListId, currentStep) => {
    let cmd = {
      lifeway_id: user.userId,
      text: commentText,
      status: 'approved',
      author: user.displayName,
      email: user.logInEmail,
      ids: trackIDs(user, originalTaskListId, currentStep, discussionId, commentId),
    };
    return apiClient
      .post('/discussion-api/' + discussionId + '/comment/' + commentId + '/reply', cmd)
      .then(response => response.data);
  },
  upvoteComment: (commentId, discussionId, user, originalTaskListId, currentStep) => {
    let cmd = {
      lifeway_id: user.userId,
      ids: trackIDs(user, originalTaskListId, currentStep, discussionId, commentId),
    };
    return apiClient
      .put('/discussion-api/' + discussionId + '/comment/' + commentId + '/upvote', cmd)
      .then(response => response.data);
  },
  removeComment: (commentId, discussionId) =>
    apiClient.delete('/discussion-api/' + discussionId + '/comment/' + commentId).then(response => response.data),
  rejectComment: (commentId, discussionId) =>
    apiClient
      .put('/discussion-api/' + discussionId + '/comment/' + commentId + '/reject')
      .then(response => response.data),
  getComments: discussionId =>
    apiClient
      .get('/discussion-api/' + discussionId + '/comments/top')
      .then(response => sortByDateAscending(response.data.Items.filter(item => item.status === 'approved'))),
  getCommentsAndReplies: discussionId =>
    apiClient
      .get('/discussion-api/' + discussionId + '/comments')
      .then(response => sortByDateAscending(response.data.Items.filter(item => item.status === 'approved'))),
  getCommentReplies: (commentId, discussionId) =>
    apiClient
      .get('/discussion-api/' + discussionId + '/comment/' + commentId + '/replies')
      .then(response => sortByDateAscending(response.data.Items.filter(item => item.status === 'approved'))),
  subscribeToDiscussion: (userId, orgId, discussionId) => {
    const subscribe = () => {
      apiClient.post('/v1/commands/SubscribeToDiscussion', {
        userId: userId,
        orgId: orgId,
        discussionId: discussionId,
      });
    };
    const unsubscribe = () => {
      apiClient.post('/v1/commands/UnsubscribeFromDiscussion', {
        userId: userId,
        orgId: orgId,
        discussionId: discussionId,
      });
    };
    subscribe();
    return [subscribe, unsubscribe];
  },
};

export default discussionService;
