import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Color, Type } from '../StyleGuide';
import userService from '../services/userService';

const Container = styled.div`
  display: grid;
  grid-template-columns: ${Grid._5};
  grid-auto-flow: column;
  grid-auto-columns: ${Grid._5};
  transform: translateX(-10px);
  img,
  div {
    background-color: ${Color.Secondary._10};
    width: ${Grid._7};
    height: ${Grid._7};
    border-radius: ${Grid._7};
    border: ${Grid._1} solid #fff;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    font-weight: ${Type.Weight.semibold};
    font-size: ${Type.Scale._1};
    color: ${Type.Color.medium};
    box-sizing: content-box;
  }
`;

const anonymousProfileImage = 'img/default_profile_photo.png';

const imageUrlsFromUserIds = userIds =>
  userIds.map(id => (id.indexOf('@') === -1 ? userService.getProfileImageUrlById(id) : anonymousProfileImage));

const fillThumbnailList = (urls, totalUsers, numImagesToDisplay) =>
  Array.from(Array(numImagesToDisplay))
    .map((_, index) => {
      if (index >= totalUsers) return;
      return urls[index] ?? anonymousProfileImage;
    })
    .filter(url => !!url);

const handleBrokenUrl = e => {
  if (e.target.src !== anonymousProfileImage) e.target.src = anonymousProfileImage;
};

export default function ProfileImageList({ srcUrls, userIds, totalUsers, numImagesToDisplay = 3 }) {
  const thumbnails = fillThumbnailList(srcUrls ?? imageUrlsFromUserIds(userIds), totalUsers, numImagesToDisplay);
  const numMoreUsers = totalUsers - numImagesToDisplay;

  return (
    <Container className="profile-images">
      {thumbnails.map((src, index) => (
        <img key={index} src={src} alt="" onError={handleBrokenUrl} />
      ))}
      {numMoreUsers > 0 && <div>+{numMoreUsers}</div>}
    </Container>
  );
}

ProfileImageList.propTypes = {
  srcUrls: PropTypes.array,
  userIds: PropTypes.array,
  totalUsers: PropTypes.number.isRequired,
  numImagesToDisplay: PropTypes.number,
};
