import React, { useState } from 'react';
import PropTypes from 'prop-types';

import windowService from '../services/windowService';

import { ModalContainer, ModalHeader, ModalBody, ModalFooter } from '../components/ModalElements';
import { MediumButton, MediumPrimaryButton } from '../components/Buttons';
import { Radio, RadioListWithDescription } from '../components/FormElements';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';

const AddAssessmentQuestionModal = ({ setAddQuestionModalOpen, assessmentId }) => {
  const [questionType, setQuestionType] = useState('');
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();

  const handleClick = questionType => {
    switch (questionType) {
      case 'radio':
        return windowService.redirectTo(
          `${manageTrainingRoutePrefix}/assessments/${assessmentId}/questions/?questionType=${questionType}`
        );
      case 'textbox':
        return windowService.redirectTo(
          `${manageTrainingRoutePrefix}/assessments/${assessmentId}/questions/?questionType=${questionType}`
        );
    }
  };
  return (
    <ModalContainer dismissHandler={() => setAddQuestionModalOpen(false)}>
      <ModalHeader data-qa-hook="addAssessmentQuestionModal">
        <h3>Add Question</h3>
      </ModalHeader>
      <ModalBody>
        <h4>What type of question do you want to add?</h4>
        <form>
          <RadioListWithDescription>
            <li>
              <label>
                <Radio
                  data-qa-hook="multipleChoiceRadioItem"
                  name="question-type"
                  onChange={() => setQuestionType('radio')}
                  checked={questionType === 'radio'}
                />
                Multiple Choice
              </label>
              <p>Create a list of choices</p>
            </li>
            <li>
              <label>
                <Radio
                  data-qa-hook="shortAnswerRadioItem"
                  name="question-type"
                  onChange={() => setQuestionType('textbox')}
                  checked={questionType === 'textbox'}
                />
                Short Answer
              </label>
              <p>Get a short essay response</p>
            </li>
          </RadioListWithDescription>
        </form>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton data-qa-hook="continue" onClick={() => handleClick(questionType)} disabled={!questionType}>
          Continue
        </MediumPrimaryButton>
        <MediumButton data-qa-hook="cancel" onClick={() => setAddQuestionModalOpen(false)}>
          Cancel
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

AddAssessmentQuestionModal.propTypes = {
  setAddQuestionModalOpen: PropTypes.func.isRequired,
  assessmentId: PropTypes.string.isRequired,
};

export default AddAssessmentQuestionModal;
