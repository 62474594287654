angular.module('lwNamb').controller(
  'IssueSettingsCtrl',

  [
    '$rootScope',
    '$scope',
    '$log',
    '$routeParams',
    'curriculumService',
    'alertService',
    'windowService',
    function ($rootScope, $scope, $log, $routeParams, curriculumService, alertService, windowService) {
      function init() {
        $scope.loading = true;
        $scope.curriculumId = $routeParams.curriculumId;
        $scope.issueId = $routeParams.issueId;
        $scope.issue = {};
        $scope.issueName = '';

        loadIssue();
      }

      function loadIssue() {
        curriculumService
          .getIssue($scope.issueId, $scope.curriculumId)
          .then(
            function (response) {
              $scope.issue = response;
              $scope.issueName = response.name;
            },
            function (reason) {
              $log.error(reason + ' in issue settings controller');
            }
          )
          .finally(function () {
            $scope.loading = false;
          });
      }

      $scope.saveDates = function (valid) {
        $scope.submitted = true;
        if (valid) {
          $scope.isSaving = true;
          curriculumService.changeIssueName($scope.curriculumId, $scope.issueId, $scope.issue.name);
          curriculumService
            .setIssueDates(
              $scope.issue.publishStartDate ? new Date($scope.issue.publishStartDate).toISOString() : undefined,
              $scope.issue.publishEndDate ? new Date($scope.issue.publishEndDate).toISOString() : undefined,
              $scope.issueId,
              $scope.curriculumId
            )
            .then(
              function (response) {
                alertService.show({ title: 'Unit Settings Saved', type: 'success', duration: 5 });
                windowService.redirectHash('/curriculum/' + $scope.curriculumId + '/issue-builder/' + $scope.issueId);
              },
              function (reason) {
                alertService.show({
                  type: 'danger danger-with-content',
                  title: 'Save Settings Error!',
                  content: 'Something went wrong. Please try again.',
                  duration: 20,
                });
              }
            )
            .finally(function () {
              $scope.isSaving = false;
            });
        }
      };

      $scope.deleteUnit = function () {
        $scope.isDeleting = true;

        curriculumService
          .deleteIssue($scope.curriculumId, $scope.issueId)
          .then(function () {
            alertService.show({ title: 'Unit Deleted', type: 'success', duration: 5 });
            windowService.redirectHash('/manage-curriculum/' + $scope.curriculumId);
          })
          .catch(function () {
            alertService.show({
              type: 'danger danger-with-content',
              title: 'Unexpected Error!',
              content: 'Something went wrong. Please try again.',
              duration: 20,
            });

            $scope.isDeleting = false;
          });
      };

      init();
    },
  ]
);
