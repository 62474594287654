import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Type, Border, Breakpoints, Color, Shadow } from '../StyleGuide';

const OverlayContainer = styled.div`
  position: relative;
  background-color: ${Color.white};
  box-shadow: ${Shadow.regular};
  margin: 0 auto;
  padding: 0;
  border-radius: 0;
  width: auto;
  height: 100vh;
  height: 100svh;
  overflow-y: auto;
  color: ${Type.Color.dark};
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    border-radius: ${Border.radius};
    height: auto;
    max-height: 95vh;
  }
`;

const Background = styled.div`
  position: fixed;
  height: 100vh;
  height: 100svh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: 0 ${Grid._5};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  padding: ${Grid._3};
  top: ${Grid._2};
  right: ${Grid._3};
  background: none;
  border: 0;
  font-size: ${Type.Scale._3};
  color: ${Type.Color.medium};
`;

const OverlayContext = React.createContext();

export default function Overlay({ dismissHandler, children }) {
  const preventBackgroundScroll = () => document.body.setAttribute('style', 'position: fixed; width: 100%;');
  const resetBackgroundScroll = () => document.body.removeAttribute('style');

  useEffect(() => {
    preventBackgroundScroll();
    return () => resetBackgroundScroll();
  }, []);

  return (
    <Background
      data-qa-hook="overlay-background"
      id="overlay-background"
      onClick={e => (e.target.id === e.currentTarget.id ? dismissHandler() : _ => _)}
    >
      <OverlayContext.Provider value={{ handleDismiss: dismissHandler }}>
        <OverlayContainer>{children}</OverlayContainer>
      </OverlayContext.Provider>
    </Background>
  );
}

Overlay.propTypes = {
  dismissHandler: PropTypes.func.isRequired,
  children: PropTypes.any,
};

const OverlayCloseButton = props => {
  const { handleDismiss } = useContext(OverlayContext);

  return (
    <CloseButton aria-label="Close" onClick={handleDismiss} {...props}>
      <i className="fas fa-times" />
    </CloseButton>
  );
};

Overlay.CloseButton = OverlayCloseButton;
