import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useUser } from '../authentication';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';
import Breadcrumbs from '../components/Breadcrumbs';
import LoadingIndicator from '../components/LoadingIndicator';
import VideoPlayer from '../containers/VideoPlayer';
import ImageCrop, { ImageCropUtils } from '../components/ImageCrop';
import SettingsHeader from '../components/SettingsHeader';
import { PageContainer, HeaderRow } from './components/LayoutElements';
import ErrorMessage from '../components/ErrorMessage';
import { useContentBuilder } from './hooks/useContentBuilder';
import {
  Container,
  ImageDimensionLabel,
  LoadingVideoPlaceholder,
  SixteenNineAspectRatioPlaceholder,
  VideoPlaceholder,
} from '../components/Layout';
import { Breakpoints, Grid, Type } from '../StyleGuide';

export type UrlParams = {
  contentId: string;
  buildType: string;
  trainingType: string;
};

const DISABLED_OPACITY = 0.6;

export const ContentBuilderContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Grid._5};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 5fr 2fr;
  }
`;

export const PosterImageContainer = styled(SixteenNineAspectRatioPlaceholder)`
  overflow: hidden;
`;

export const Header = styled.h1`
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._6};
  font-weight: ${Type.Weight.semibold};
`;

const PosterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const Label = styled.label`
  display: block;
  margin: 0 0 ${Grid._3};
  color: ${Type.Color.dark};
  font-weight: ${Type.Weight.semibold};

  &[disabled] {
    opacity: ${DISABLED_OPACITY};
  }
`;

export const ManageVideoContent = () => {
  const user = useUser();
  const orgId = user?.lastSelectedAccount || '';
  const userId = user?.userId || '';
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();
  const [videoIsLoading, setVideoIsLoading] = useState(true);

  const { content, isLoading, isError, uploadPoster } = useContentBuilder();

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Training', route: `#${manageTrainingRoutePrefix}/content` },
    { name: 'Edit Content' },
  ];

  const onBannerImageUpdated = (image: string) => {
    new Promise((resolve, reject) => {
      const fileName = ImageCropUtils.generateFilename();
      if (ImageCropUtils.isCroppedImage(image) && content) {
        ImageCropUtils.convertCroppedImageToBlob(image)
          .then(blob => uploadPoster(content._id, blob, fileName, orgId, userId))
          .catch(reason => reject(reason));
      } else {
        resolve(image);
      }
    });
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <PageContainer>
        {isLoading ? (
          <LoadingIndicator />
        ) : isError ? (
          <ErrorMessage />
        ) : (
          content && (
            <>
              <HeaderRow>
                <SettingsHeader title={content?.title || ''} subtitle="Video" icon={<i className="fas fa-tasks"></i>} />
              </HeaderRow>

              <ContentBuilderContainer>
                <VideoPlaceholder>
                  {videoIsLoading && <LoadingVideoPlaceholder />}
                  <VideoPlayer setIsLoading={setVideoIsLoading} videoId={content?._id} userId={userId} />
                </VideoPlaceholder>

                <PosterContainer>
                  <Label>Poster Image</Label>
                  <PosterImageContainer actionable={true}>
                    <ImageCrop defaultImage={content.poster} onUpdate={onBannerImageUpdated} aspectRatio={16 / 9} />
                  </PosterImageContainer>
                  <ImageDimensionLabel>Recommended: 1280 x 720</ImageDimensionLabel>
                </PosterContainer>
              </ContentBuilderContainer>
            </>
          )
        )}
      </PageContainer>
    </>
  );
};
