import React, { useEffect } from 'react';
import LoadingIndicator from './LoadingIndicator';
import useQueryParams from '../hooks/useQueryParams';
import { useUser } from '../authentication';
import useEventTracking from '../hooks/useEventTracking';

const defaultCheckoutLocation = 'https://www.ministrygrid.com/pricing';

const ItemNumberUrlMap = {
  'https://www.ministrygrid.com/pricing': ['005801686', '005802975'],
};

const ParentChildItemNumberMap = {
  '005841737': ['005841738', '005841739'],
};

const getKeyForItemInMap = (map, value) => {
  const [key] = Object.entries(map).find(([, values]) => values.includes(value)) || [];
  return key;
};

const getPurchaseableItemNumber = itemNumber => {
  const parentItemNumber = getKeyForItemInMap(ParentChildItemNumberMap, itemNumber);
  return parentItemNumber ?? itemNumber;
};

const getPurchaseRedirectUrl = itemNumber =>
  itemNumber ? getKeyForItemInMap(ItemNumberUrlMap, itemNumber) : defaultCheckoutLocation;

const doesHostMatch = arr => arr.some(str => window.location.host.includes(str));

const getCheckoutHost = () => {
  if (doesHostMatch(['grid.local', 'ministrygrid.dev'])) return 'https://www.int.lifeway.com';
  if (doesHostMatch(['ministrygrid.stage'])) return 'https://www.uat.lifeway.com';
  return 'https://www.lifeway.com';
};

export default function PurchaseRedirectHandler() {
  const user = useUser();
  const { itemNumber } = useQueryParams({ preserveParams: true });
  const { trackEvent, trackingEvents } = useEventTracking();

  useEffect(() => {
    const redirectUrl = getPurchaseRedirectUrl(itemNumber);
    if (redirectUrl) {
      return window.location.replace(redirectUrl);
    }

    const returnUrl = encodeURIComponent(`${window.location.origin}/#/purchaseCheck`);
    const refDomain = encodeURIComponent('ministrygrid.lifeway.com');
    const checkoutPath = `${getCheckoutHost()}/en/checkout`;

    const purchasableItemNumber = getPurchaseableItemNumber(itemNumber);

    Promise.all(
      trackEvent(trackingEvents.ITEM_CARTED, { itemNumber: purchasableItemNumber, userEmail: user.logInEmail })
    ).finally(() => {
      window.location.replace(
        `${checkoutPath}?buyNow=1&${purchasableItemNumber}&digitalAccessUrl=${returnUrl}&ref=${refDomain}`
      );
    });
  }, []);

  return <LoadingIndicator />;
}

PurchaseRedirectHandler.propTypes = {};
