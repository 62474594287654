import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { PrimaryButton } from '../components/Buttons';
import AddGroupModal from './AddGroupModal';

const AddGroup = ({ handleCreateGroup, isDuplicateName, setIsDuplicateName }) => {
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const dismissHandler = () => {
    setShowAddGroupModal(false);
    if (isDuplicateName) setIsDuplicateName('');
  };

  return (
    <>
      <PrimaryButton data-qa-hook="groupsAddButton" onClick={() => setShowAddGroupModal(true)}>
        Add Group
      </PrimaryButton>
      {showAddGroupModal && (
        <AddGroupModal
          isDuplicateName={isDuplicateName}
          handleSubmit={handleCreateGroup}
          handleDismiss={dismissHandler}
        />
      )}
    </>
  );
};

AddGroup.propTypes = {
  handleCreateGroup: PropTypes.func.isRequired,
  setIsDuplicateName: PropTypes.func,
  isDuplicateName: PropTypes.string,
};

export default AddGroup;
