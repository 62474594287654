import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid } from '../StyleGuide';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import ConfirmationModal from '../components/ConfirmationModal';
import InfoModal from '../components/InfoModal';
import Breadcrumbs from '../components/Breadcrumbs';
import { FullWidthCard, Container } from '../components/Layout';
import { LinkStyleButton, SortButton, ButtonGroup } from '../components/Buttons';
import ActiveFilters from '../components/ActiveFilters';
import useModal from '../hooks/useModal';
import { useArrayFilter, useTextFilter, useMultiFilter, useSortFilter } from '../hooks/filters';
import { UserContext } from '../authentication';
import windowService from '../services/windowService';
import orgService from '../services/orgService';
import alertService from '../services/AlertService';
import trainingService from '../services/trainingService';
import analyticsService from '../services/analyticsService';
import cacheService from '../services/cacheService';
import { handleError } from '../utils/apiUtils';
import bulkSelect from '../utils/bulkSelect';
import ManageAssignmentsControls from './ManageAssignmentsControls';
import ManageAssignmentsResults from './ManageAssignmentsResults';

const ManageAssignmentsContainer = styled(Container)`
  margin-top: ${Grid._5};
  button > i {
    margin-left: ${Grid._2};
  }
`;

const ManageAssignmentsCard = styled(FullWidthCard)`
  padding: ${Grid._5};
`;

const SelectAllNone = styled.th`
  width: 120px;
  text-align: center;
`;

const AssignmentsHeader = ({ filteredAssignments, allAssignments, loading, handleSelect, sortFilter }) => {
  const anyFilteredSelected = filteredAssignments.some(assignment => assignment.isSelected);

  return (
    <thead>
      <tr>
        <th>
          <ButtonGroup>
            <SortButton
              sortActive={sortFilter.method === 'lastName'}
              direction={sortFilter.order === 'ascending' ? 'up' : 'down'}
              onClick={() => sortFilter.update('lastName')}
            >
              People
            </SortButton>
            <SortButton
              sortActive={sortFilter.method === 'completionDate'}
              direction={sortFilter.order === 'ascending' ? 'up' : 'down'}
              onClick={() => sortFilter.update('completionDate')}
            >
              Completion Date
            </SortButton>
          </ButtonGroup>
        </th>
        {allAssignments.length > 0 && !loading && (
          <SelectAllNone>
            {anyFilteredSelected ? (
              <LinkStyleButton onClick={() => handleSelect(null, false)} data-qa-hook="manageAssignmentsDeselectAll">
                Select None
              </LinkStyleButton>
            ) : (
              <LinkStyleButton onClick={() => handleSelect(null, true)} data-qa-hook="manageAssignmentsSelectAll">
                Select All
              </LinkStyleButton>
            )}
          </SelectAllNone>
        )}
      </tr>
    </thead>
  );
};

AssignmentsHeader.propTypes = {
  filteredAssignments: PropTypes.array.isRequired,
  allAssignments: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  handleSelect: PropTypes.func.isRequired,
  sortFilter: PropTypes.object.isRequired,
};

const UnassignTrainingModal = ({ incompleteAssignments, ...props }) => {
  return (
    <>
      <ConfirmationModal
        buttonActionText={'Unassign'}
        buttonType="danger"
        title="Unassign Training"
        prompt={
          <span>
            Are you sure you want to unassign {incompleteAssignments?.length} incomplete training{' '}
            {incompleteAssignments.length === 1 ? 'item' : 'items'}?
          </span>
        }
        {...props}
      >
        <p className="help-block">
          <i className="icon ion-android-alert"></i> All Progress will be lost
        </p>
      </ConfirmationModal>
    </>
  );
};

UnassignTrainingModal.propTypes = {
  incompleteAssignments: PropTypes.array.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const UnassignNoTrainingModal = props => {
  return (
    <>
      <InfoModal
        title="Unassign Training"
        prompt={<span>Please choose one or more incomplete assignments to unassign.</span>}
        {...props}
      />
    </>
  );
};

UnassignNoTrainingModal.propTypes = {
  handleDismiss: PropTypes.func.isRequired,
};

const ExportTrainingModal = ({ selectedAssignments, ...props }) => {
  return (
    <>
      <ConfirmationModal
        buttonActionText={'Export'}
        title="Export Assignments"
        prompt={
          <span>
            This will export {selectedAssignments.length}{' '}
            {selectedAssignments.length === 1 ? 'assignment' : 'assignments'} as a CSV.
          </span>
        }
        {...props}
      />
    </>
  );
};

ExportTrainingModal.propTypes = {
  selectedAssignments: PropTypes.array.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default function ManageAssignments() {
  const { search } = useLocation();
  const searchParams = Object.fromEntries(new URLSearchParams(search).entries());
  const user = useContext(UserContext);
  const orgId = user.lastSelectedAccount;
  const [data, setData] = useState({ isLoadingOrg: true });
  const { isLoadingOrg, isLoadingAssignments, isError, org, allAssignments = [] } = data;
  const [isUnassigning, setIsUnassigning] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const rolesMatcher = (assignment, roleId) => assignment.assignee.roles.some(role => role.id === roleId);
  const groupsMatcher = (assignment, groupName) => assignment.assignee.groupNames.some(name => name === groupName);
  const statusMatcher = (assignment, status) => assignment.assignable.progress._type === status;

  const assignmentMatcher = (assignment, assignmentName) => {
    if (assignmentName === 'ALL_TL') {
      return assignment.assignable._type === 'AssignedTaskList';
    }
    if (assignmentName === 'ALL_WF') {
      return assignment.assignable._type === 'Workflow';
    }

    return assignment.assignable.name === assignmentName;
  };

  const queryMatcher = (assignment, query) => {
    query = query.toLowerCase();

    if (assignment.assignable?.name?.toLowerCase().includes(query)) return true;
    if (assignment.assignee?.name?.toLowerCase().includes(query)) return true;
    if (assignment.assignee?.email?.toLowerCase().includes(query)) return true;
    if (assignment.assignee?.groupNames.some(name => name.toLowerCase().includes(query))) return true;

    return false;
  };

  const rolesFilter = useArrayFilter({ matcher: rolesMatcher });
  const groupsFilter = useArrayFilter({ matcher: groupsMatcher });
  const statusFilter = useTextFilter({ matcher: statusMatcher });
  const assignmentFilter = useTextFilter({ matcher: assignmentMatcher });
  const queryFilter = useTextFilter({ matcher: queryMatcher });
  const multiFilter = useMultiFilter({
    roles: rolesFilter,
    groups: groupsFilter,
    status: statusFilter,
    assignment: assignmentFilter,
    query: queryFilter,
  });

  const { filters } = multiFilter;
  const activeFilters = {};
  if (!filters.roles.isEmpty) activeFilters['Role'] = filters.roles.reset;
  if (!filters.groups.isEmpty) activeFilters['Group'] = filters.groups.reset;
  if (!filters.status.isEmpty) activeFilters['Status'] = filters.status.reset;
  if (!filters.assignment.isEmpty) activeFilters['Assignment'] = filters.assignment.reset;

  const completionDateComparator = (a, b) => {
    const aCompleted = !!a.completedOn;
    const bCompleted = !!b.completedOn;

    if (aCompleted && bCompleted) return a.completedOn - b.completedOn;
    if (aCompleted && !bCompleted) return -1;
    if (bCompleted && !aCompleted) return 1;

    return 0;
  };

  const sortFilter = useSortFilter({
    comparators: {
      lastName: (a, b) => a.lastName.localeCompare(b.lastName),
      completionDate: completionDateComparator,
    },
    initialState: { method: 'completionDate', order: 'ascending' },
  });

  const [modal, openModal, dismissModal] = useModal((type, payload, dismissModal) => {
    switch (type) {
      case 'unassignTraining':
        return (
          <UnassignTrainingModal
            incompleteAssignments={payload.incompleteAssignments}
            handleDismiss={dismissModal}
            handleSubmit={() => unassignTraining(incompleteAssignments)}
          />
        );
      case 'unassignNoTraining':
        return <UnassignNoTrainingModal handleDismiss={dismissModal} />;
      case 'exportAssignments':
        return (
          <ExportTrainingModal
            selectedAssignments={payload.selectedAssignments}
            handleDismiss={dismissModal}
            handleSubmit={() => exportData()}
          />
        );
      default:
        return null;
    }
  });

  useEffect(() => {
    const { orgId: cachedOrgId, filters: cachedFilters } = cacheService.get('manageAssignmentsFilters') || {};
    if (cachedOrgId === orgId && cachedFilters) {
      multiFilter.setState(cachedFilters);
    } else {
      multiFilter.reset();
    }

    setData({ isLoadingOrg: true });

    Promise.all([orgService.getOrg(orgId), getAssignments()])
      .then(([org, assignments]) => {
        if (typeof org !== 'object') {
          throw org;
        }
        setData({ org, allAssignments: assignments });
      })
      .catch(error => {
        setData({ isError: true });
        handleError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  useEffect(() => {
    cacheService.set('manageAssignmentsFilters', { orgId, filters: multiFilter.state });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiFilter]);

  const isCPCA = !!searchParams.cpca;
  const pageTitle = isCPCA ? 'CPCA Assignments' : 'Manage Assignments';
  const crumbs = [{ name: 'Organization', route: '#/org' }, { name: pageTitle }];

  const filteredAssignments = sortFilter.filter(multiFilter.filter([...allAssignments]));
  const selectedAssignments = allAssignments.filter(assignment => assignment.isSelected);
  const incompleteAssignments = selectedAssignments.filter(
    assignment => assignment.assignable.progress._type !== 'Finished'
  );

  const handleSelect = (assignment = null, selected) => {
    setData(prevData => {
      const allAssignmentsWithSelectionUpdates = bulkSelect(
        prevData.allAssignments,
        assignment ? [assignment] : filteredAssignments,
        (a, b) => a.id === b.id,
        selected
      );

      return { ...prevData, allAssignments: allAssignmentsWithSelectionUpdates };
    });
  };

  const unassignTraining = training => {
    setIsUnassigning(true);

    trainingService.unassignTraining(training, user.userId).then(
      removedCount => {
        alertService.show(`${removedCount} Assignment${removedCount > 1 ? 's' : ''} removed`);
        dismissModal();
        setIsUnassigning(false);

        setData(prevData => ({
          org: prevData.org,
          isLoadingAssignments: true,
        }));

        getAssignments()
          .then(assignments =>
            setData(prevData => ({
              org: prevData.org,
              allAssignments: assignments,
            }))
          )
          .catch(error => {
            setData({ isError: true });
            handleError(error);
          });
      },
      reason => {
        handleError(reason);
        setTimeout(() => {
          dismissModal();
          setIsUnassigning(false);
          windowService.locationReload();
        }, 5000);
      }
    );
  };

  const exportData = () => {
    setIsExporting(true);

    const selectedAssignmentIds = selectedAssignments.map(item => item._id);

    analyticsService.trackFeature(
      'ExportAssignments',
      `exported: ${selectedAssignmentIds.length === 0 ? 'All' : selectedAssignmentIds.length.toString()}`
    );

    trainingService.getAssignmentsCSV(orgId, selectedAssignmentIds).then(
      blob => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'assignments.csv';
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setIsExporting(false);
        dismissModal();
        alertService.show('Export Success');
      },
      error => {
        dismissModal();
        setIsExporting(false);
        handleError(error);
      }
    );
  };

  const getAssignments = () => (isCPCA ? trainingService.getCPCAAssignments() : trainingService.getAssignments(orgId));

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <ManageAssignmentsContainer data-qa-hook="manageAssignmentsView">
        <ManageAssignmentsCard>
          {isLoadingOrg ? (
            <LoadingState />
          ) : isError ? (
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          ) : (
            <>
              <ManageAssignmentsControls
                org={org}
                allAssignments={allAssignments}
                multiFilter={multiFilter}
                isCPCA={isCPCA}
                incompleteAssignments={incompleteAssignments}
                selectedAssignments={selectedAssignments}
                isUnassigning={isUnassigning}
                isExporting={isExporting}
                openModal={openModal}
              />
              {!multiFilter.isEmpty && (
                <ActiveFilters
                  count={filteredAssignments.length}
                  filters={activeFilters}
                  onClearAll={multiFilter.reset}
                />
              )}
              <table className="table">
                <AssignmentsHeader
                  allAssignments={allAssignments}
                  filteredAssignments={filteredAssignments}
                  handleSelect={handleSelect}
                  multiFilter={multiFilter}
                  sortFilter={sortFilter}
                />
                <ManageAssignmentsResults
                  allAssignments={allAssignments}
                  filteredAssignments={filteredAssignments}
                  handleSelect={handleSelect}
                  isLoadingAssignments={isLoadingAssignments}
                />
              </table>
            </>
          )}
        </ManageAssignmentsCard>
      </ManageAssignmentsContainer>
      {modal}
    </>
  );
}
