import React from 'react';
import styled from 'styled-components';
import { Grid, Color, Breakpoints, Type } from '../StyleGuide';
import { ContainerFluid, Container } from './Layout';

const NavbarContainer = styled(ContainerFluid)`
  background: ${Color.white};
  border-bottom: 1px solid #ededed;
  min-height: 95px;

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    min-height: 110px;
  }
`;

const InnerContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  align-items: center;
  padding: ${Grid._4} 0;
  text-align: center;
  color: ${Type.Color.dark};

  h2 {
    margin: 0 ${Grid._4};
    font-weight: ${Type.Weight.semibold};
  }

  p {
    margin: ${Grid._4} ${Grid._4} 0;
    color: ${Type.Color.medium};
    font-weight: ${Type.Weight.normal};
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: ${Grid._10} 1fr ${Grid._10};
    padding: ${Grid._4} 0 ${Grid._4};

    h2,
    p {
      padding: 0 ${Grid._4};
    }
  }
`;

export default function ActionableTitleBar({ title, subtitle, leftButton, rightButton }) {
  return (
    <NavbarContainer>
      <Container>
        <InnerContainer>
          {leftButton && <div>{leftButton}</div>}
          <div>
            <h2>{title}</h2>
            {subtitle && <p>{subtitle}</p>}
          </div>
          {rightButton && <div>{rightButton}</div>}
        </InnerContainer>
      </Container>
    </NavbarContainer>
  );
}
