import React from 'react';
import PropTypes from 'prop-types';
import TrainingCard from '../training/TrainingCard';
import windowService from '../services/windowService';
import useViewportDimensions from '../hooks/useViewportDimensions';

const redirectToCompilationTraining = (compilationId, trainingId) =>
  windowService.redirectTo(`#/catalog/compilation/${compilationId}/${trainingId}`);

const TrainingCompilationGrid = ({ compilationId, trainingItems, canRedirect = true }) => {
  const viewportDimensions = useViewportDimensions();
  const widthSizeThreshold = 767;

  const getTruncatedTrainingItems = () => {
    return viewportDimensions.width > widthSizeThreshold ? trainingItems.slice(0, 6) : trainingItems.slice(0, 3);
  };

  return (
    <div className="grid-container grid-col-2 grid-sm-col-6 grid-gap-24">
      {getTruncatedTrainingItems().map(training => (
        <div key={training.id} className="grid-col-span-2 grid-sm-col-span-3 grid-md-col-span-2">
          <TrainingCard
            training={training}
            redirectToTraining={() => redirectToCompilationTraining(compilationId, training.id)}
            canRedirect={canRedirect}
          />
        </div>
      ))}
    </div>
  );
};

TrainingCompilationGrid.propTypes = {
  compilationId: PropTypes.string.isRequired,
  trainingItems: PropTypes.array.isRequired,
  canRedirect: PropTypes.bool,
};

export default TrainingCompilationGrid;
