import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MediumButton, MediumPrimaryButton } from '../../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../../components/ModalElements';
import { RadioListWithDescription, Radio } from '../../components/FormElements';
import capitalizeFilter from '../../filters/capitalize-filter';
import useSessionTimeline from '../../hooks/useSessionTimeline';

const AddSectionModal = ({ addSectionToSegment, handleDismiss }) => {
  const [sectionType, setSectionType] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { sectionTypeMap } = useSessionTimeline();

  const isSubmitDisabled = !sectionType || isSubmitting;

  const addSection = () => {
    setIsSubmitting(true);
    addSectionToSegment(sectionType);
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader data-qa-hook="addSectionModal">
        <h3>Add Section</h3>
      </ModalHeader>
      <ModalBody>
        <h3>What type of content do you want to add?</h3>
        <form>
          <RadioListWithDescription>
            {Object.entries(sectionTypeMap).map(([type, { iconClass, description }]) => (
              <li key={type}>
                <label>
                  <Radio
                    data-qa-hook={`${type}SectionType`}
                    name="section-type"
                    onChange={() => setSectionType(type)}
                    checked={sectionType === type}
                  />
                  <i className={iconClass}></i> {capitalizeFilter(type)}
                </label>
                <p>{description}</p>
              </li>
            ))}
          </RadioListWithDescription>
        </form>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton
          data-qa-hook="addSectionConfirm"
          onClick={addSection}
          disabled={isSubmitDisabled}
          operating={isSubmitting}
        >
          Add Section
        </MediumPrimaryButton>
        <MediumButton data-qa-hook="addSectionCancel" onClick={handleDismiss}>
          Cancel
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

AddSectionModal.propTypes = {
  addSectionToSegment: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default AddSectionModal;
