import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Type, Shadow } from '../StyleGuide';
import { Image } from './Layout';
import { ChurchImage } from './PairingElements';
import WhiteLabel from '../filters/WhiteLabel';
import capitalizeFilter from '../filters/capitalize-filter';
import { MediumOutlineButton } from './Buttons';
import ImageCrop from './ImageCrop';
import { Label } from './FormElements';
import uuid from '../services/uuid';

const UploadImage = styled.div`
  margin-bottom: ${Grid._4};
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: ${Grid._4};

  > div:last-child {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-gap: ${Grid._2};

    p {
      color: ${Type.Color.medium};
      font-size: ${Type.Scale._1};
      font-style: italic;
    }
  }
`;

const OrgImage = styled(Image)`
  height: auto;
  width: 100%;
  box-shadow: ${Shadow.small};
`;

const Logo = styled.div`
  width: 77px;
  height: 77px;
`;

const OrgLogoSelector = ({ initialLogo, onLogoUpdate, onUnlockClick }) => {
  const [logo, setLogo] = useState(initialLogo);
  const [logoKey, setLogoKey] = useState(uuid.generate());
  const capitalizedOrgLabel = capitalizeFilter(WhiteLabel.labels.org);

  const updateLogo = image => {
    if (image === '' || image == logo) return;

    setLogo(image);
    setLogoKey(uuid.generate());
    onLogoUpdate(image);
  };

  const resetLogo = () => {
    setLogo('');
    setLogoKey(uuid.generate());
    onLogoUpdate('');
  };

  return (
    <>
      <Label>{capitalizedOrgLabel} Logo</Label>
      <UploadImage>
        <Logo>{logo ? <OrgImage data-qa-hook="orgIcon" src={logo} /> : <ChurchImage />}</Logo>
        <div>
          <div className="grid-container grid-col-6 grid-gap-16">
            {onUnlockClick ? (
              <MediumOutlineButton data-qa-hook="uploadImageLocked" onClick={onUnlockClick}>
                Upload Image
              </MediumOutlineButton>
            ) : (
              <ImageCrop
                key={logoKey}
                buttonText={logo ? 'Change Image' : 'Upload Image'}
                onUpdate={updateLogo}
                aspectRatio={1}
                showRemoveButton={false}
              />
            )}
            {!!logo && (
              <MediumOutlineButton data-qa-hook="imageRemove" onClick={resetLogo}>
                Reset Image
              </MediumOutlineButton>
            )}
          </div>
          <p>For best results, use a PNG or SVG image with a clear background. You can add a logo later too.</p>
        </div>
      </UploadImage>
    </>
  );
};

OrgLogoSelector.propTypes = {
  initialLogo: PropTypes.string,
  onLogoUpdate: PropTypes.func.isRequired,
  onUnlockClick: PropTypes.func,
};

export default OrgLogoSelector;
