import { react2angular } from 'react2angular';
import PageNotFound from './status/PageNotFound';

export * from './ReactApp';
export * from './curriculum/SubscribeBibleStudy';
export * from './curriculum/CurriculumDashboard';
export * from './curriculum/volume/AddVolumeHeaderMaterial';
export * from './curriculum/volume/ManageCurriculumVolume';
export * from './curriculum/volume/ManageCurriculumVolumeHeader';
export * from './training/PathwaySettings';
export * from './training/CourseSettings';
export * from './training/Training';
export * from './catalog/TrainingCatalog';
export * from './catalog/TrainingCompilationHeader';
export * from './groups/ProfileImage';
export * from './groups/Groups';
export * from './catalog/TrainingCompilation';
export * from './groups/Group';
export * from './groups/GroupAddPeople';
export * from './components/YourDashboardSubMenu';
export * from './components/EnterpriseImpersonationBanner';
export * from './components/Breadcrumbs';
export * from './components/TrainingSubMenu';
export * from './components/LoadingState';
export * from './steps/FeedbackAssessmentViewer';
export * from './steps/Steps';
export * from './steps/TrainingMenu';
export * from './steps/Discussion';
export * from './containers/AssignmentProgress';
export * from './containers/CourseCard';
export * from './containers/WorkflowCard';
export * from './containers/TrainingCard';
export * from './containers/InlineEmailEditor';
export * from './containers/Wizard';
export * from './admin/Impersonate';

angular.module('lwNamb').component('pageNotFound', react2angular(PageNotFound, ['user', 'org'], ['$rootScope']));
export * from './status/PageNotFound';
