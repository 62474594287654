angular
  .module('lwNamb')

  .factory(
    'prepareEnrichService',

    [
      'api',
      function(api) {
        return {
          get: function(orgId) {
            return api.get('/v1/organizations/' + orgId + '/prepare-enrich').then(transform);
          },
        };

        function transform(response) {
          return response.data;
        }
      },
    ]
  );
