import React from 'react';
import styled from 'styled-components';
import {
  SortableContainer,
  SortableElement,
  SortableContainerProps,
  SortableElementProps,
  SortableHandle,
} from 'react-sortable-hoc';
import { Grid, Color } from '../../StyleGuide';
import { ListItemFlexRow } from '../../components/ListItemFlexRow';
import { SmallRoundedIconButton } from '../../components/Buttons';

type ContainerProps = SortableContainerProps & {
  children: React.ReactNode;
};

const CourseItemsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const ContentListSortableContainer = SortableContainer<ContainerProps>(({ children }: ContainerProps) => (
  <CourseItemsContainer>{children}</CourseItemsContainer>
));

type SortIndexes = {
  oldIndex: number;
  newIndex: number;
};

export type SortableCourseStepListProps = {
  onSort: ({ oldIndex, newIndex }: SortIndexes) => void;
  children: React.ReactNode;
};

export const SortableCourseStepList = ({ onSort, children }: SortableCourseStepListProps) => {
  return (
    <ContentListSortableContainer onSortEnd={onSort} useDragHandle>
      {children}
    </ContentListSortableContainer>
  );
};

type ItemProps = SortableElementProps & {
  children: React.ReactNode;
};

export const TrainingListItemContainer = styled(ListItemFlexRow)`
  gap: ${Grid._6};
  padding: ${Grid._4} ${Grid._3};
`;

export const SortableCourseStepListItem = SortableElement<ItemProps>(({ children, ...props }: ItemProps) => (
  <TrainingListItemContainer hoverStyles={`background: ${Color.white};`} {...props}>
    {children}
  </TrainingListItemContainer>
));

export const SortableDragHandle = SortableHandle(() => (
  <SmallRoundedIconButton>
    <i className="fas fa-bars drag-handle"></i>
  </SmallRoundedIconButton>
));
