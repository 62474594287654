import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, RoundedIconButton } from '../components/Buttons';
import { Container } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import windowService from '../services/windowService';
import { Grid, Breakpoints, Color, Type } from '../StyleGuide';
import { useUser } from '../authentication';
import WhiteLabel from '../filters/WhiteLabel';
import useFetchPairableLicenses from './useFetchPairableLicenses';

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PageContainer = styled(CenteredContainer)`
  position: relative;
  padding-bottom: ${Grid._10};

  .pair-landing-close-btn {
    position: absolute;
    top: ${Grid._5};
    right: ${Grid._5};

    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      right: ${Grid._6};
    }
  }

  .pair-landing-content {
    text-align: center;
    max-width: 620px;
    width: 100%;
    margin-top: ${Grid._11};
  }

  .loading-state {
    margin: ${Grid._7} 0;
  }

  h1 {
    margin: ${Grid._6} 0 ${Grid._5} 0;
  }

  .steps-primary-button {
    margin: ${Grid._9} 0 0 0;

    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      margin: ${Grid._9} 5px 5px 5px;
    }
  }
`;

const GridIcon = styled.div`
  background: center / cover no-repeat url('/img/mg-pairing-icon.png');
  height: 186px;
  width: 186px;
  position: relative;
`;

const CountIndicator = styled.span`
  background-color: ${Color.Orange._50};
  height: 32px;
  min-width: 36px;
  border-radius: 11px;
  color: ${Color.white};
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.semibold};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 33px;
  right: 22px;
`;

export default function PairLanding() {
  const user = useUser();
  const { licenses, pairableOrgs, isLoading, isChecking, isError } = useFetchPairableLicenses();

  const exit = () => windowService.redirectTo('/dashboard');

  const proceed = () => {
    if (!!user.lastSelectedAccount && pairableOrgs.some(o => o._id.id === user.lastSelectedAccount)) {
      windowService.redirectTo('/pairing/purchases');
    } else if (!!user.lastSelectedAccount && pairableOrgs.length) {
      windowService.redirectTo('/pairing/select-org');
    } else {
      windowService.redirectTo('/org/create');
    }
  };

  return isLoading ? (
    <LoadingState />
  ) : isError ? (
    <ErrorMessage />
  ) : (
    <PageContainer data-qa-hook="unpairedView">
      <RoundedIconButton data-qa-hook="exit" className="pair-landing-close-btn" onClick={exit}>
        <i className="fas fa-times" />
      </RoundedIconButton>
      <Container className="pair-landing-content" style={{ textAlign: 'center', maxWidth: '620px' }}>
        {!isChecking && licenses.length === 0 ? (
          <>
            <h1>Your Purchases Aren't Ready Yet</h1>
            <p>
              Please use the chat icon in the lower right hand corner to reach out to our customer service team so that
              we can resolve this issue as soon as possible.
            </p>

            <PrimaryButton data-qa-hook="continueNoLicenses" onClick={exit}>
              Continue
            </PrimaryButton>
          </>
        ) : (
          <>
            <CenteredContainer>
              <GridIcon>
                {!!licenses?.length && <CountIndicator data-qa-hook="licenseCount">{licenses.length}</CountIndicator>}
              </GridIcon>
            </CenteredContainer>

            {isChecking && !licenses?.length ? (
              <>
                <h1>We're Checking For Recent Purchases</h1>
                <LoadingState hideText />
              </>
            ) : (
              <>
                <h1>We Found Some Recent Purchases</h1>
                <p>Let's connect your digital purchases to your {WhiteLabel.labels.org}.</p>
                <PrimaryButton data-qa-hook="continue" onClick={proceed}>
                  Continue
                </PrimaryButton>
              </>
            )}
          </>
        )}
      </Container>
    </PageContainer>
  );
}
