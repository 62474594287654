import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Color, Type } from '../StyleGuide';

const Contents = styled.div`
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.semibold};
  color: ${Type.Color.dark};
  padding: ${Grid._5};
  p:nth-child(2) {
    height: 50px;
  }
  li {
    font-weight: ${Type.Weight.light};
    padding: ${Grid._3} 0;
    i {
      color: ${Color.Primary._60};
    }
  }
`;

export default function PlanContents({ plans }) {
  return (
    <>
      {plans.map(plan => (
        <Contents key={plan.title}>
          <p>
            <strong>{plan.title}</strong>
          </p>
          <p>{plan.description}</p>
          <ul className="fa-ul">
            {plan.contents.map(content => (
              <li key={content}>
                <span className="fa-li">
                  <i className="fas fa-check"></i>
                </span>
                {content}
              </li>
            ))}
          </ul>
        </Contents>
      ))}
    </>
  );
}

PlanContents.propTypes = {
  plans: PropTypes.array.isRequired,
};
