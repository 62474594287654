angular.module('lwNamb').factory(
  'apiUrlService',

  [
    '$log',
    function($log) {
      return {
        getUrl: function() {
          try {
            return getApiUrl();
          } catch (e) {
            $log.error(e);
            return '';
          }
        },
        getOrigin: function() {
          try {
            return getOrigin();
          } catch (e) {
            $log.error(e);
            return '';
          }
        },
        getCommitHash: function() {
          try {
            return getCommitHash();
          } catch (e) {
            $log.error(e);
            return '';
          }
        },
        getIsSSO: function() {
          try {
            return getIsSSO();
          } catch (e) {
            $log.error(e);
            return '';
          }
        }
      };
    },
  ]
);
