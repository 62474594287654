import axios from 'axios';
import Retry from './Retry';

const API_BASE_URL = getApiUrl(); // todo: improve config injection
const jwtService = JWT;

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  transformRequest: axios.defaults.transformRequest.concat((data, headers) => {
    headers.Authorization = jwtService.get();
    return data;
  }),
  timeout: 60 * 1000, // default 60s timeout, override if needed, 0 = never
});

const defaultMaxRetryCount = 6;
const retryStatuses = [500, 502, 503, 504];
const retryCondition = statuses => error =>
  error.response && statuses.findIndex(s => s === error.response.status) !== -1;
const retryWrapper = additional =>
  Array.isArray(additional) ? retryCondition(retryStatuses.concat(additional)) : retryCondition(retryStatuses);
const maxRetryCount = optionalMaxRetryCount =>
  typeof optionalMaxRetryCount === 'number' ? optionalMaxRetryCount : defaultMaxRetryCount;

const apiClient = {
  get: (url, config, additionalRetryStatuses, optionalMaxRetryCount) => {
    return Retry(
      () => axiosInstance.get(url, config),
      maxRetryCount(optionalMaxRetryCount),
      retryWrapper(additionalRetryStatuses)
    );
  },

  post: (url, data, config, additionalRetryStatuses, optionalMaxRetryCount) => {
    return Retry(
      () => axiosInstance.post(url, data, config),
      maxRetryCount(optionalMaxRetryCount),
      retryWrapper(additionalRetryStatuses)
    );
  },

  put: (url, data, config, additionalRetryStatuses, optionalMaxRetryCount) => {
    return Retry(
      () => axiosInstance.put(url, data, config),
      maxRetryCount(optionalMaxRetryCount),
      retryWrapper(additionalRetryStatuses)
    );
  },

  delete: (url, config, additionalRetryStatuses, optionalMaxRetryCount) => {
    return Retry(
      () => axiosInstance.delete(url, config),
      maxRetryCount(optionalMaxRetryCount),
      retryWrapper(additionalRetryStatuses)
    );
  },

  getConfig: () => {
    return axiosInstance.defaults;
  },
};

export default apiClient;
