import React from 'react';
import styled from 'styled-components';
import { Grid, Color, Type } from '../StyleGuide';
import CourseInformation from './CourseInformation';
import CourseSteps from './CourseSteps';
import WhiteLabel from '../filters/WhiteLabel';
import { SmallPrimaryButton } from '../components/Buttons';
import windowService from '../services/windowService';
import apiClient from '../services/apiClient';

const CourseHeader = styled.div`
  color: ${Type.Color.dark};
  padding: ${Grid._4};
  position: relative;
  border-top: 1px solid ${Color.Gray._30};
  &:first-child {
    border-top: 0;
  }
  &:last-child {
    border-bottom: 1px solid ${Color.Gray._30};
  }
`;

const ToggleIcon = styled.div`
  .fas {
    font-size: ${Type.Scale._4};
  }
`;

const StyledSmallPrimaryButton = styled(SmallPrimaryButton)`
  &:not(:last-child) {
    margin-bottom: ${Grid._4};
  }
`;

const ViewReportButton = ({ fileName, filePath }) => {
  const encodedFileName = encodeURIComponent(fileName.replace(/ /gi, '_'));
  const encodedFilePath = encodeURIComponent(filePath);

  const openReport = () =>
    windowService.openUrl(
      `${apiClient.getConfig().baseURL}/v1/files?fileName=${encodedFileName}&filePath=${encodedFilePath}`
    );

  return (
    <StyledSmallPrimaryButton onClick={openReport}>
      <i className="fa fa-chart-bar" /> View Report
    </StyledSmallPrimaryButton>
  );
};

export default function CourseMenu({
  training,
  course,
  isCurrentCourse,
  courseIndex,
  isCourseExpanded,
  toggleIsCourseExpanded,
}) {
  const keepCourseOpen = course.progress._type === 'Started';
  const isExpanded = isCourseExpanded || keepCourseOpen;
  const numCompletedSteps = course.tasks.filter(step => step.status === 'complete').length;
  const isInstanceDetail = course._type === 'CourseInstanceDetail';
  const chevronDirection = isExpanded ? 'up' : 'down';

  const CourseName = styled.div`
    display: flex;
    align-items: center;
    h4 {
      flex: 1;
      padding-right: ${Grid._4};
      font-weight: ${Type.Weight.bold};
      &:hover {
        text-decoration: ${keepCourseOpen ? 'none' : 'underline'};
      }
    }
  `;

  const shouldShowViewReport = report =>
    report?.reportId && report?.id === 'CPCACandidate' && report?.visibility === 'PUBLIC';

  return (
    <>
      <CourseHeader
        style={{ cursor: keepCourseOpen ? 'default' : 'pointer' }}
        onClick={() => {
          if (!keepCourseOpen) toggleIsCourseExpanded();
        }}
      >
        <CourseName>
          <h4 data-qa-hook="courseName">{course.name}</h4>
          <ToggleIcon>{!keepCourseOpen && <i className={'fas fa-chevron-' + chevronDirection}></i>}</ToggleIcon>
        </CourseName>
        {course.reports &&
          course.reports.map(
            report =>
              shouldShowViewReport(report) && (
                <ViewReportButton key={report.reportId} fileName={training.workflowName} filePath={report.reportId} />
              )
          )}
        {isExpanded && isInstanceDetail && (
          <CourseInformation course={course} numCompletedSteps={numCompletedSteps} progress={course.progress.percent} />
        )}
        {isExpanded && !isInstanceDetail && (
          <p style={{ color: Type.Color.medium }}>
            Upcoming {WhiteLabel.labels.course} will be unlocked upon completion of previous {WhiteLabel.labels.course}
          </p>
        )}
      </CourseHeader>
      {isExpanded && <CourseSteps course={course} courseIndex={courseIndex} isCurrentCourse={isCurrentCourse} />}
    </>
  );
}
