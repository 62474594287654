import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Grid, Color, Breakpoints, Type } from '../StyleGuide';
import { Container, PageTitle } from './Layout';
import { FormFieldSet } from '../components/FormElements';
import userService from '../services/userService';
import alertService from '../services/AlertService';
import { useUser } from '../authentication';
import useOrg from '../hooks/useOrg';
import Breadcrumbs from './Breadcrumbs';
import { PrimaryButton } from './Buttons';
import { FormField } from './FormElements';
import WhiteLabel, { isGrid } from '../filters/WhiteLabel';

const FeedbackFormContainer = styled(Form)`
  width: 100%;
  max-width: 600px;
  margin: ${Grid._8} 0;
`;

const FeedbackForm = () => {
  const user = useUser();
  const org = useOrg();

  return (
    <Formik
      initialValues={{
        name: '',
        subject: '',
        message: '',
      }}
      validationSchema={Yup.object({
        name: Yup.string().required('A name is required'),
        subject: Yup.string().required('A subject is required'),
        message: Yup.string().required('A message is required'),
      })}
      onSubmit={(values, { resetForm }) => {
        userService
          .sendFeedbackEmail(user.userId, values.name, user.logInEmail, values.subject, values.message, org.name)
          .then(() => {
            alertService.show('Thank you for your feedback!', 'success');
            resetForm();
          })
          .catch(() => alertService.show('Something went wrong. Please try again.', 'danger'));
      }}
    >
      {({ values, isSubmitting }) => (
        <FeedbackFormContainer>
          <FormFieldSet name="Send Feedback" />
          <p>Let us know how we can better serve your needs.</p>
          <FormField label="Name" name="name" type="text" placeholder="Enter Name" value={values.name} />
          <FormField label="Subject" name="subject" type="text" placeholder="Enter Subject" value={values.subject} />
          <FormField
            label="Message"
            name="message"
            as="textarea"
            rows="7"
            placeholder="Enter Message"
            value={values.message}
          />
          <PrimaryButton type="submit" disabled={isSubmitting} operating={isSubmitting}>
            Send Feedback
          </PrimaryButton>
        </FeedbackFormContainer>
      )}
    </Formik>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: ${Grid._11};
  row-gap: ${Grid._7};
  max-width: 800px;

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
  }

  legend {
    margin-bottom: ${Grid._4};
  }
`;

const Footer = styled.div`
  border-top: 1px solid ${Color.Gray._20};
  margin: ${Grid._11} 0 ${Grid._7} 0;
  padding-top: ${Grid._4};
  font-size: ${Type.Scale._1};

  > * {
    margin-right: ${Grid._7};
  }
`;

const WhiteLabelHelpContent = ({ version }) => (
  <>
    <PageTitle className="mt-40 mb-48">
      <h2>{`Help${WhiteLabel.contact.feedback ? ' and Feedback' : ''}`}</h2>
    </PageTitle>
    <h5>{WhiteLabel.name}</h5>
    {WhiteLabel.contact.phone && <p>Phone: {WhiteLabel.contact.phone}</p>}
    {WhiteLabel.contact.email && (
      <p>
        Email: <a href={`mailto:${WhiteLabel.contact.email}`}>{WhiteLabel.contact.email}</a>
      </p>
    )}
    {WhiteLabel.contact.feedback && <FeedbackForm />}
    <Footer>{version}</Footer>
  </>
);

WhiteLabelHelpContent.propTypes = {
  version: PropTypes.string.isRequired,
};

const IntercomLink = ({ children }) => {
  const showIntercom = e => {
    e.preventDefault();
    window.Intercom('show');
  };

  return !window.Intercom ? children : <a onClick={showIntercom}>{children}</a>;
};

const GridHelpContent = ({ version }) => (
  <>
    <PageTitle className="mt-40 mb-48">
      <h2>Help</h2>
      <p>
        We're here to help! Whether you need more information about training or a question about your billing, we're
        ready to serve.
      </p>
    </PageTitle>
    <ContentContainer className="mb-24">
      <div>
        <FormFieldSet name="Live Chat" />
        <p>
          Search our FAQs and chat with a live customer service specialist during business hours using the{' '}
          <IntercomLink>speech bubble icon</IntercomLink> on the lower right-hand corner of every page. Our chat is
          available during regular business hours.
        </p>
      </div>
    </ContentContainer>
    <ContentContainer>
      <div>
        <FormFieldSet name="Contact Information" />
        <p>
          <a href="tel:8666278553">866-627-8553</a>
        </p>
        <p>Hours: 7:30 AM - 6:00 PM CST</p>
        <p>Monday - Friday</p>
      </div>
      <div>
        <FormFieldSet name="Knowledge Base and Support" />
        <p>
          <a href="https://support.ministrygrid.com/">
            View step-by-step tutorials and answers to frequently asked questions
          </a>
        </p>
        <p>
          <a href="https://ministrygrid.lifeway.com/#/browser-support">View browsers we support</a>
        </p>
      </div>
    </ContentContainer>
    <Footer>
      <a href="https://www.lifeway.com/en/about/privacy-policy">Privacy Policy</a>
      <a href="https://www.lifeway.com/en/about/terms-of-use">Terms of Use</a>
      {version}
    </Footer>
  </>
);

GridHelpContent.propTypes = {
  version: PropTypes.string.isRequired,
};

export default function HelpAndFeedback() {
  const crumbs = [{ name: 'Dashboard', route: '#/dashboard' }, { name: `Help` }];
  const Content = isGrid() ? GridHelpContent : WhiteLabelHelpContent;

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container>
        <Content version={`Version ${getCommitHash()}`} />
      </Container>
    </>
  );
}
