import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Grid, Color, Type } from '../StyleGuide';
import { useSteps, selectStep } from './StepsContext';

const Step = styled.li`
  display: flex;
  align-items: stretch;
  font-size: ${Type.Scale._2};
  color: ${Type.Color.medium};
  .content-header {
    font-size: ${Type.Scale._3};
    font-weight: ${Type.Weight.normal};
    color: ${Type.Color.dark};
  }
  &.course-menu-active .content-header {
    color: ${Type.Color.dark};
    font-weight: ${Type.Weight.bold};
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
  }
  &.complete {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Status = styled.div`
  flex: 0 0 ${Grid._7};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.Primary._50};
  font-size: ${Type.Scale._2};
  i.sync,
  i.fa-lock {
    color: ${Color.Gray._50};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: ${Grid._4} ${Grid._5} ${Grid._4} 0;
`;

const stepType = type => {
  if (type === 'ThirdPartyVideo' || type === 'Video') return 'Media';
  else return type;
};

export default function CourseSteps({ course, courseIndex, isCurrentCourse }) {
  const [{ currentStepIndex, isWorkflow, completingStepIndexes }, dispatch] = useSteps();
  const isLocked = isWorkflow && course._type !== 'CourseInstanceDetail';

  return (
    <ul className="steps-container" data-qa-hook="stepsContainer" style={{ margin: 0, paddingLeft: 0 }}>
      {course.tasks.map((step, index) => (
        <Step
          data-qa-hook="stepItem"
          key={course.id + '_' + index}
          onClick={() => {
            selectStep(dispatch, {
              stepIndex: index,
              courseIndex,
              setShowLockedStep: isLocked,
            });
          }}
          className={`${isCurrentCourse && currentStepIndex == index ? 'course-menu-active' : ''} ${step.status}`}
        >
          <Status className="course-menu-status">
            {(() => {
              if (step.status === 'complete') {
                return <i className="fas fa-check-circle"></i>;
              } else if (completingStepIndexes.includes(index)) {
                return <i className="fas fa-sync-alt fa-spin sync"></i>;
              } else if (isWorkflow && isLocked) {
                return <i className="fas fa-lock"></i>;
              }
            })()}
          </Status>
          <Content>
            <span data-qa-hook="stepName" className="content-header">
              {step.taskName || step.task.taskName}
            </span>
            <span data-qa-hook="stepType" className="content-type">
              {stepType(step._type || step.task._type)}
            </span>
          </Content>
        </Step>
      ))}
    </ul>
  );
}

CourseSteps.propTypes = {
  course: PropTypes.object,
  courseIndex: PropTypes.number,
  isCurrentCourse: PropTypes.bool,
};
