import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Border, Color, Type, Breakpoints } from '../StyleGuide';
import { SmallPrimaryButton } from './Buttons';
import { SuccessMessage, ErrorMessage } from './AlertMessages';

//TODO: Refactor PeopleSelectList css to reduce specificity
const AddNewPersonRow = styled.tr`
  grid-template-columns: 1fr !important;
  padding: 0 !important;
  cursor: inherit !important;
  td {
    display: flex;
    flex-direction: column;
    padding: ${Grid._4} !important;
    background: ${Color.Gray._10};
    border-left: 3px solid ${Type.Color.medium};
    text-align: left !important;
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      padding: ${Grid._4} ${Grid._10} !important;
    }
    p {
      color: ${Type.Color.dark};
      margin: 0 0 ${Grid._2};
      &.description {
        color: ${Type.Color.medium};
        font-weight: 300;
      }
    }
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 4fr 1fr;
  }
  button {
    width: inherit;
  }
`;

const Input = styled.input`
  border: 1px solid ${Color.Gray._30};
  border-radius: ${Border.radius};
  margin: 0 0 ${Grid._4} 0;
  padding: ${Grid._3};
  font-size: inherit;
  font-family: inherit;
  font-weight: ${Type.Weight.medium};
  ::placeholder {
    font-weight: ${Type.Weight.light};
    color: ${Type.Color.placeholder};
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: 0 ${Grid._4} 0 0;
  }
`;

const Alerts = styled.div`
  padding: 0;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: 0 ${Grid._4} 0 0;
  }
`;

export default function InlineAddNewPerson({ allPeople, selectedPeople, addAndSelect }) {
  const [isValidating, setIsValidating] = useState(false);
  const [inputText, setInputText] = useState('');

  const initialAlertState = { noEmailsFound: false, addedMsg: false, existingMsg: false, accessMsg: false };
  const [alertState, setAlertState] = useState(initialAlertState);

  useEffect(() => {
    if (!inputText.length) {
      setAlertState(initialAlertState);
    }
  }, [inputText]);

  const submitEmails = e => {
    e.preventDefault();
    setIsValidating(true);

    let emails = parseEmails(inputText);

    let existingCount = 0,
      addedCount = 0,
      alreadyAddedCount = 0,
      newPeople = [],
      existingPeople = [],
      existingAccessCount = 0,
      processedEmails = [];

    const getEmail = p => p.emailAddress || p.email;

    emails.forEach(email => {
      if (processedEmails.includes(email)) return;

      let existingPerson = allPeople.find(p => getEmail(p).toLowerCase() === email);
      let selectedPerson = selectedPeople.find(p => getEmail(p).toLowerCase() === email);
      if (existingPerson) {
        if (existingPerson.excluded) {
          existingAccessCount++;
        } else {
          if (!selectedPerson) {
            existingPeople.push(existingPerson);
            existingCount += 1;
          } else {
            alreadyAddedCount += 1;
          }
        }
      } else {
        newPeople.push({
          email: email,
          emailAddress: email,
          _type: 'PendingUser',
          toBeInvited: true,
          roles: [],
          groups: [],
          profile: {},
          imageUrl: '',
        });
        addedCount += 1;
      }

      processedEmails.push(email);
    });

    const allAlreadySelected = addedCount === 0 && existingCount === 0 && alreadyAddedCount > 0;
    const existingAccessMsg = (count, except, also) => {
      const alsoPhrase = `We ${also ? 'also ' : ''}found `;
      return count > 0
        ? `${except ? ' except' : alsoPhrase} ${count} ${
            count === 1 ? 'person who already has' : 'people who already have'
          } this collection`
        : '';
    };

    if (emails.length === 0) {
      setAlertState({ noEmailsFound: true, addedMsg: false, existingMsg: false, accessMsg: false });
      setIsValidating(false);
    } else {
      if (allAlreadySelected) {
        setAlertState({
          noEmailsFound: false,
          addedMsg: `All people are already added${existingAccessMsg(existingAccessCount, true, false)}`,
          existingMsg: false,
          accessMsg: false,
        });
      } else {
        addAndSelect(newPeople, existingPeople);

        const newAlertState = Object.assign({}, initialAlertState);

        if (addedCount > 0) {
          newAlertState.addedMsg = `${addedCount} ${addedCount === 1 ? 'person was' : 'people were'} added`;
        }

        if (existingCount > 0) {
          newAlertState.existingMsg = `We ${newAlertState.addedMsg ? 'also ' : ''}found ${existingCount} existing ${
            existingCount === 1 ? 'person' : 'people'
          }, so we've selected them for you`;
        }

        if (existingAccessCount > 0) {
          newAlertState.accessMsg = existingAccessMsg(
            existingAccessCount,
            false,
            newAlertState.addedMsg || newAlertState.existingMsg
          );
        }

        setAlertState(newAlertState);
      }
      setIsValidating(false);
    }
  };

  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"-]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
  );

  function parseEmails(input) {
    return input
      .replace(/[\s<>():=|!;"]/g, ',')
      .split(',')
      .map(function (maybeEmail) {
        if (validEmail.test(String(maybeEmail))) {
          return maybeEmail.toLowerCase();
        } else {
          return false;
        }
      })
      .filter(function (result) {
        return !!result;
      });
  }

  return (
    <AddNewPersonRow>
      <td>
        <p>
          <strong>Don't see who needs this collection?</strong>
        </p>
        <p className="deemphasized-text">Add a new person by email address:</p>
        <Form onSubmit={submitEmails}>
          <Input
            type="text"
            placeholder="Enter comma separated email addresses"
            data-qa-hook="addEmailAddress"
            value={inputText}
            onChange={e => setInputText(e.target.value)}
          />
          {inputText.length > 0 && (
            <SmallPrimaryButton
              type="submit"
              disabled={isValidating}
              operating={isValidating}
              data-qa-hook="addPersonButton"
            >
              Add to List
            </SmallPrimaryButton>
          )}
          <Alerts>
            {alertState.noEmailsFound && (
              <ErrorMessage>We couldn’t find any email addresses in the information you gave us</ErrorMessage>
            )}
            {alertState.addedMsg && (
              <SuccessMessage data-qa-hook="addPersonSuccess">{alertState.addedMsg}</SuccessMessage>
            )}
            {alertState.existingMsg && <SuccessMessage>{alertState.existingMsg}</SuccessMessage>}
            {alertState.accessMsg && <SuccessMessage>{alertState.accessMsg}</SuccessMessage>}
          </Alerts>
          <div></div>
        </Form>
      </td>
    </AddNewPersonRow>
  );
}

InlineAddNewPerson.propTypes = {
  allPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPeople: PropTypes.arrayOf(PropTypes.object).isRequired,
  addAndSelect: PropTypes.func.isRequired,
};
