import { useReducer } from 'react';
import PropTypes from 'prop-types';

const useCurriculumOnboardingDatastore = (storagePrefix, initialData = {}) => {
  const storageKey = `${storagePrefix}-curriculum-onboarding`;

  const storeData = data => {
    sessionStorage.setItem(storageKey, JSON.stringify(data));
    return data;
  };

  const [datastore, dispatch] = useReducer((state, { type, payload }) => {
    switch (type) {
      case 'update':
        return storeData({ ...state, ...payload });
      default:
        return state;
    }
  }, JSON.parse(sessionStorage.getItem(storageKey)) || initialData);

  const updateDatastore = data => dispatch({ type: 'update', payload: data });
  const deleteDatastore = () => sessionStorage.removeItem(storageKey);

  return { datastore, updateDatastore, deleteDatastore };
};

export default useCurriculumOnboardingDatastore;

useCurriculumOnboardingDatastore.propTypes = {
  storagePrefix: PropTypes.string.isRequired,
  initialData: PropTypes.object,
};
