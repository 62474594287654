import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Type, Grid } from '../StyleGuide';

const Header = styled.h2`
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._5};
  font-weight: ${Type.Weight.bold};
  overflow-wrap: break-word;
  margin: 0;
`;

const SubHeader = styled.h5`
  color: ${Type.Color.medium};
  font-size: ${Type.Scale._2};
  margin: 0;

  i {
    margin-right: ${Grid._2};
  }
`;

const SettingsHeader = ({ title, subtitle, icon }) => (
  <div className="grid-container grid-row-gap-8">
    <SubHeader>
      {icon}
      {subtitle}
    </SubHeader>
    <Header>{title}</Header>
  </div>
);

SettingsHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  icon: PropTypes.object,
};

export default SettingsHeader;
