import React, { useState, useEffect } from 'react';
import { MediumPrimaryButton, MediumOutlineButton } from '../components/Buttons';
import { Input } from '../components/FormElements';
import ErrorMessage from '../components/ErrorMessage';
import MaxChars from '../components/MaxChars';

const GroupSettings = ({ originalName, handleSubmit, isDuplicateName, downloadGroup }) => {
  const [newGroupName, setNewGroupName] = useState(originalName);
  const [UIState, setUIState] = useState({ isSubmitting: false, isError: '' });

  useEffect(() => {
    if (isDuplicateName && UIState.isSubmitting) return setUIState({ isSubmitting: false, isError: isDuplicateName });
  }, [isDuplicateName]);

  const handleOnClick = () => {
    if (!newGroupName.trim()) return setUIState({ isSubmitting: false, isError: 'A name is required' });
    setUIState({ isSubmitting: true, isError: '' });
    handleSubmit(newGroupName.trim());
  };

  const handleOnChange = e => {
    if (UIState.isError) setUIState({ isSubmitting: false, isError: '' });
    setNewGroupName(e.target.value);
  };

  return (
    <section>
      <div className="group-settings__name">
        <div className="space-between">
          <label htmlFor="groupName">Name</label>
          {originalName !== newGroupName && <MaxChars maxChars={64} currentChars={newGroupName.length} />}
        </div>
        <Input
          id="groupName"
          placeholder={'A name is required'}
          value={newGroupName}
          maxLength={64}
          onChange={handleOnChange}
        />
        {UIState.isError && <ErrorMessage>{UIState.isError}</ErrorMessage>}
        <div className="group-settings__name-actions">
          <MediumPrimaryButton
            data-qa-hook="groupSettingsNameSave"
            disabled={originalName === newGroupName || !newGroupName || UIState.isSubmitting}
            onClick={handleOnClick}
            operating={UIState.isSubmitting}
          >
            Save
          </MediumPrimaryButton>
        </div>
      </div>
      {downloadGroup && (
        <div className="group-settings__download grid-container grid-col-3 grid-gap-40">
          <p className="grid-col-span-2">Download all member information from this group as a CSV file.</p>
          <MediumOutlineButton onClick={downloadGroup}>
            <span className="fas fa-download"></span> Download Group
          </MediumOutlineButton>
        </div>
      )}
    </section>
  );
};

export default GroupSettings;
