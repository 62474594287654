import React, { useContext, useState } from 'react';
import { PartialSearchResultItem } from '../models/Search';

type ContextType = {
  selectedTraining: PartialSearchResultItem[];
  setSelectedTraining: React.Dispatch<React.SetStateAction<PartialSearchResultItem[]>>;
  onAddSelectedTraining: (selectedTraining: PartialSearchResultItem[]) => void | Promise<void>;
  singleSelect: boolean;
};

const Context = React.createContext<ContextType>({
  selectedTraining: [],
  setSelectedTraining: () => {},
  onAddSelectedTraining: () => {},
  singleSelect: false,
});
Context.displayName = 'SelectTrainingContext';

export const useSelectTraining = () => {
  const { selectedTraining, setSelectedTraining, onAddSelectedTraining, singleSelect } = useContext(Context);

  const selectTraining = (training: PartialSearchResultItem) => {
    setSelectedTraining(prevSelectedTraining => (singleSelect ? [training] : [...prevSelectedTraining, training]));
  };
  const deselectTraining = (training: PartialSearchResultItem) => {
    setSelectedTraining(prevSelectedTraining => prevSelectedTraining.filter(selected => selected.id !== training.id));
  };

  const isTrainingSelected = (training: PartialSearchResultItem) =>
    selectedTraining.some(selected => selected.id === training.id);

  return { selectedTraining, selectTraining, deselectTraining, isTrainingSelected, onAddSelectedTraining };
};

type Props = {
  preselectedTraining?: PartialSearchResultItem[];
  onAddSelectedTraining: (selectedTraining: PartialSearchResultItem[]) => void | Promise<void>;
  singleSelect?: boolean;
  children: React.ReactNode;
};

export const SelectTrainingProvider = ({
  preselectedTraining = [],
  onAddSelectedTraining,
  singleSelect = false,
  children,
}: Props) => {
  const [selectedTraining, setSelectedTraining] = useState<PartialSearchResultItem[]>(preselectedTraining);

  return (
    <Context.Provider value={{ selectedTraining, setSelectedTraining, onAddSelectedTraining, singleSelect }}>
      {children}
    </Context.Provider>
  );
};
