import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Grid, Breakpoints, Type, Shadow } from '../StyleGuide';
import { monthAbbrFilter } from '../filters/date-filters';
import { CollectionImgPlaceholder, Image } from '../components/Layout';
import { SessionBadge } from '../components/Badge';
import CalendarDateIcon from '../components/CalendarDateIcon';
import { SessionDescription } from '../components/SessionElements';

const Session = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: ${Grid._5};
  .session-image {
    margin-top: ${Grid._4};
    img {
      box-shadow: ${Shadow.light};
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      margin-top: -${Grid._5};
    }
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 1fr 2fr;
    margin-top: 0;
  }
  @media screen and (min-width: ${Breakpoints.screen_md}) {
    grid-template-columns: 2fr 5fr;
  }
`;

const SessionDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._4} ${Grid._6};
  }
`;

const SessionDateAndName = styled.div`
  display: flex;
  margin: ${Grid._4} 0;
  color: ${Type.Color.dark};
  h3 {
    margin-top: 0;
    font-size: ${Type.Scale._5};
    font-weight: ${Type.Weight.semibold};
  }
  h4 {
    margin: 0;
    color: ${Type.Color.medium};
    font-weight: ${Type.Weight.light};
  }
  .full-scheduled-date {
    display: none;
  }
`;

const SessionName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: ${Grid._4};
`;

const SessionImageAndDetails = ({ session, preDescriptionDetails }) => (
  <Session data-qa-hook="sessionInfo" className="session-info">
    <div className="session-image">
      {session.image ? <Image src={session.image} alt={session.name} /> : <CollectionImgPlaceholder />}
    </div>
    <SessionDetails>
      <SessionDateAndName>
        {session.isScheduled && (
          <CalendarDateIcon
            month={monthAbbrFilter(session.scheduledDate.getMonth())}
            day={session.scheduledDate.getDate().toString()}
            primary={session.isThisWeek}
            large={true}
          />
        )}
        <SessionName className="session-name">
          <SessionBadge session={session} />
          <h3>{session.name}</h3>
          <h4 className="available-through-date">{session.getAvailableThroughMessage('MMMM d')}</h4>
          {session.scheduledDate && (
            <h4 className="full-scheduled-date">{format(session.scheduledDate, 'MMMM dd, yyyy')}</h4>
          )}
        </SessionName>
      </SessionDateAndName>
      {preDescriptionDetails}
      <SessionDescription dangerouslySetInnerHTML={{ __html: session.description }} />
    </SessionDetails>
  </Session>
);

SessionImageAndDetails.propTypes = {
  session: PropTypes.object.isRequired,
  preDescriptionDetails: PropTypes.object,
};

export default SessionImageAndDetails;
