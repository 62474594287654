angular.module('lwNamb').factory(
  'inviteService',

  [
    '$rootScope',
    'api',
    'qt',
    'CacheFactory',
    'transformer',
    'uuid4',
    'commandSubmissionService',
    function ($rootScope, api, qt, CacheFactory, transformer, uuid4, commandSubmissionService) {
      var timeoutSeconds = 15,
        invitesCache = CacheFactory('invitesCache', {
          maxAge: 60 * 1000, // 1 minute
          deleteOnExpire: 'aggressive',
        }),
        inviteSettingsCache = CacheFactory('inviteSettingsCache', {
          maxAge: 60 * 1000, // 1 minute
          deleteOnExpire: 'aggressive',
        }),
        pendingInvites = CacheFactory('pendingInvitesCache', {
          maxAge: 15 * 1000, // 15 seconds
          deleteOnExpire: 'aggressive',
        });

      return {
        lookupInvitesForEmail: function (email) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          if (pendingInvites.get(email) !== undefined) {
            deferred.resolve(pendingInvites.get(email));
          } else {
            api.get('/v1/invites/' + email + '/lookup').then(function (response) {
              pendingInvites.put(email, response.data.invites);
              deferred.resolve(response.data.invites);
            });
          }

          return deferred.promise;
        },
        getInvites: function (orgId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          if (invitesCache.get(orgId)) {
            deferred.resolve(invitesCache.get(orgId));
          } else {
            getInvitesAndCache(orgId).then(function (invites) {
              deferred.resolve(invites);
            });
          }
          return deferred.promise;
        },

        getInvite: function (orgId, inviteId) {
          var deferred = qt.defer();

          getInvite(orgId, inviteId, deferred);

          return deferred.promise;
        },
        getInviteSettings: function (orgId, inviteId) {
          var deferred = qt.defer();

          findInviteSettings(orgId, inviteId, deferred);

          return deferred.promise;
        },
        getAllSettings: function (orgId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          if (inviteSettingsCache.get(orgId)) {
            deferred.resolve(inviteSettingsCache.get(orgId));
          } else {
            getAllSettingsAndCache(orgId).then(function (invites) {
              deferred.resolve(invites);
            });
          }
          return deferred.promise;
        },
        createMembershipCode: function (
          name,
          orgId,
          roles,
          trainings,
          licenses,
          groups,
          redemptionLimit,
          expirationDate,
          initiatingUserId
        ) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
            id = uuid4.generate();

          dropCaches();

          var obj = {
            id: id,
            name: name,
            orgId: {
              id: orgId,
            },
            invitor: {
              id: initiatingUserId,
            },
            initiatingUserId: initiatingUserId,
          };

          obj = addFieldToObject(
            obj,
            { redemptionLimit: redemptionLimit },
            function (redemptionLimit) {
              return redemptionLimit !== undefined && redemptionLimit > 0;
            },
            redemptionLimit
          );
          obj = addFieldToObject(
            obj,
            { expirationDate: setupDate(expirationDate) },
            function (expirationDate) {
              return expirationDate !== undefined;
            },
            expirationDate
          );
          obj = addFieldToObject(
            obj,
            { inviteOptions: { roles: roles } },
            function (roles) {
              return roles && roles.length > 0;
            },
            roles
          );
          obj = addFieldToObject(
            obj,
            { inviteOptions: { trainings: trainings } },
            function (trainings) {
              return trainings && trainings.length > 0;
            },
            trainings
          );
          obj = addFieldToObject(
            obj,
            { inviteOptions: { licenses: licenses } },
            function (licenses) {
              return licenses && licenses.length > 0;
            },
            licenses
          );
          obj = addFieldToObject(
            obj,
            { inviteOptions: { groups: groups } },
            function (groups) {
              return groups && groups.length > 0;
            },
            groups
          );

          api.post('/v1/commands/CreateMembershipCodeInvite', obj).then(null, function failure(reason) {
            deferred.reject(reason);
          });

          $rootScope.$on('MembershipCodeInviteCreated', function (name, event) {
            if (event.id === id) {
              deferred.resolve(event.inviteId);
            }
          });

          $rootScope.$on('InviteError', function (name, event) {
            if (event.id === id) {
              deferred.reject(event.code);
            }
          });

          return deferred.promise;
        },
        createPendingInvite: function (email, orgId, firstName, lastName, personalMessage, initiatingUserId) {
          var id = uuid4.generate(),
            cmd = {
              id: id,
              email: email,
              orgId: {
                id: orgId,
              },
              invitorId: initiatingUserId,
              firstName: firstName,
              lastName: lastName,
              personalMessage: personalMessage,
              initiatingUserId: initiatingUserId,
            };

          dropCaches();
          return commandSubmissionService.submitAndWait(
            id,
            cmd,
            'CreatePendingInvite',
            'PendingInviteCreated',
            'PendingInviteError'
          );
        },
        createPendingInvites: function (users, orgId, personalMessage, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
          var eventsRemaining = users.length;
          var totalInvites = 0;
          var cmd = {
            invites: users.map(function (user) {
              return {
                id: uuid4.generate(),
                email: user.email,
                orgId: {
                  id: orgId,
                },
                invitorId: initiatingUserId,
                firstName: user.firstName,
                lastName: user.lastName,
                personalMessage: personalMessage,
                initiatingUserId: initiatingUserId,
              };
            }),
          };

          dropCaches();
          api.post('/v1/commands/CreatePendingInvites', cmd).then(null, function failure(reason) {
            deferred.reject(reason);
          });

          $rootScope.$on('PendingInviteCreated', function (name, event) {
            eventsRemaining = eventsRemaining - 1;
            totalInvites += 1;
            if (eventsRemaining <= 0) {
              deferred.resolve(totalInvites);
            }
          });

          return deferred.promise;
        },
        createEmailInvite: function (
          name,
          orgId,
          roles,
          trainings,
          licenses,
          groups,
          invitees,
          email,
          initiatingUserId
        ) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
            id = uuid4.generate();

          dropCaches();

          var obj = {
            id: id,
            name: name,
            orgId: {
              id: orgId,
            },
            invitees: invitees,
            email: email,
            invitor: { id: initiatingUserId },
            initiatingUserId: initiatingUserId,
          };
          obj = addFieldToObject(
            obj,
            { inviteOptions: { roles: roles } },
            function (roles) {
              return roles && roles.length > 0;
            },
            roles
          );
          obj = addFieldToObject(
            obj,
            { inviteOptions: { trainings: trainings } },
            function (trainings) {
              return trainings && trainings.length > 0;
            },
            trainings
          );
          obj = addFieldToObject(
            obj,
            { inviteOptions: { licenses: licenses } },
            function (licenses) {
              return licenses && licenses.length > 0;
            },
            licenses
          );
          obj = addFieldToObject(
            obj,
            { inviteOptions: { groups: groups } },
            function (groups) {
              return groups && groups.length > 0;
            },
            groups
          );

          api.post('/v1/commands/CreateEmailInvite', obj).then(null, function failure(reason) {
            deferred.reject(reason);
          });

          $rootScope.$on('EmailInviteCreated', function (name, event) {
            if (event.id === id) {
              deferred.resolve(event.inviteId);
            }
          });

          $rootScope.$on('InviteError', function (name, event) {
            if (event.id === id) {
              deferred.reject(event.code);
            }
          });

          return deferred.promise;
        },
        addEmailInvitees: function (inviteId, orgId, invitees, email, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          dropCaches();

          var obj = {
            id: inviteId,
            orgId: {
              id: orgId,
            },
            invitees: invitees,
            email: email,
            invitor: { id: initiatingUserId },
            initiatingUserId: initiatingUserId,
          };
          api.post('/v1/commands/AddEmailInvitees', obj).then(null, function failure(reason) {
            deferred.reject(reason);
          });

          $rootScope.$on('EmailInviteesAdded', function (name, event) {
            if (event.id === inviteId) {
              deferred.resolve(event.id);
            }
          });

          $rootScope.$on('DirectInviteError', function (name, event) {
            if (event.id === inviteId) {
              deferred.reject('INVITE_DOES_NOT_EXIST');
            }
          });

          return deferred.promise;
        },
        resendEmailInvite: function (inviteId, orgId, emailAddress, initiatingUserId) {
          return commandSubmissionService.submitAndWait(
            inviteId,
            {
              id: inviteId,
              orgId: { id: orgId },
              forEmail: emailAddress,
              initiatingUserId: initiatingUserId,
            },
            'ResendEmailInvite',
            'EmailInviteResent'
          );
        },
        getInviteEmailPreview: function (orgId, emails, personalMessage) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          var obj = {
            templateName: 'invite',
            orgId: orgId,
            emails: emails,
            data: { personalMessage: personalMessage },
          };
          api.post('/v1/template/preview', obj).then(
            function failure(response) {
              deferred.resolve(response.data);
            },
            function failure(reason) {
              deferred.reject(reason);
            }
          );

          return deferred.promise;
        },
        removeInvitee: function (inviteId, emailAddress, initiatingUserId) {
          dropCaches();
          return commandSubmissionService.submitAndWait(
            inviteId,
            {
              id: inviteId,
              emailAddress: emailAddress,
              initiatingUserId: initiatingUserId,
            },
            'RemoveInvitee',
            'InviteeRemoved',
            [invitesCache, inviteSettingsCache]
          );
        },
        deactivateInvitation: function (inviteId, initiatingUserId, type) {
          var cmd = { id: inviteId, initiatingUserId: initiatingUserId };
          if (type === 'email') {
            return commandSubmissionService.submitAndWait(
              inviteId,
              cmd,
              'DeactivateDirectInvite',
              'DirectInviteDeactivated',
              'DirectInviteError',
              [invitesCache, inviteSettingsCache]
            );
          } else {
            return commandSubmissionService.submitAndWait(
              inviteId,
              cmd,
              'DeactivateMembershipCode',
              'MembershipCodeDeactivated',
              'MembershipCodeError',
              [invitesCache, inviteSettingsCache]
            );
          }
        },
        setMembershipCodeName: function (inviteId, name, initiatingUserId) {
          var cmd = { id: inviteId, name: name, initiatingUserId: initiatingUserId };
          return commandSubmissionService.submitAndWait(
            inviteId,
            cmd,
            'SetMembershipCodeName',
            'MembershipCodeNameSet',
            'MembershipCodeError',
            [invitesCache, inviteSettingsCache]
          );
        },
        activateMembershipCode: function (inviteId, initiatingUserId) {
          var cmd = { id: inviteId, initiatingUserId: initiatingUserId };
          return commandSubmissionService.submitAndWait(
            inviteId,
            cmd,
            'ActivateMembershipCode',
            'MembershipCodeActivated',
            'MembershipCodeError',
            [invitesCache, inviteSettingsCache]
          );
        },
        setRedemptionLimit: function (inviteId, redemptionLimit, initiatingUserId) {
          var cmd = { id: inviteId, initiatingUserId: initiatingUserId };
          cmd = addFieldToObject(
            cmd,
            { redemptionLimit: redemptionLimit },
            function (redemptionLimit) {
              return redemptionLimit !== undefined;
            },
            redemptionLimit
          );
          return commandSubmissionService.submitAndWait(
            inviteId,
            cmd,
            'SetMembershipCodeRedemptionLimit',
            'MembershipCodeRedemptionLimitSet',
            'MembershipCodeError',
            [invitesCache, inviteSettingsCache]
          );
        },
        setExpirationDate: function (inviteId, date, initiatingUserId) {
          var cmd = { id: inviteId, initiatingUserId: initiatingUserId };
          cmd = addFieldToObject(
            cmd,
            { date: setupDate(date) },
            function (date) {
              return date !== undefined;
            },
            date
          );
          return commandSubmissionService.submitAndWait(
            inviteId,
            cmd,
            'SetMembershipCodeExpirationDate',
            'MembershipCodeExpirationDateSet',
            'MembershipCodeError',
            [invitesCache, inviteSettingsCache]
          );
        },
        saveMemCodeSettings: function (inviteId, redemptionLimit, date, name, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          var redLim = this.setRedemptionLimit(inviteId, redemptionLimit, initiatingUserId);
          var setExp = this.setExpirationDate(inviteId, date, initiatingUserId);
          var namePromise = this.setMembershipCodeName(inviteId, name, initiatingUserId);

          qt.all([redLim, setExp, namePromise]).then(
            function () {
              deferred.resolve(inviteId);
            },
            function (reason) {
              deferred.reject(reason);
            }
          );

          return deferred.promise;
        },
        setMemCodeOptions: function (inviteId, trainings, roles, licenses, groups, initiatingUserId, settingsId) {
          var id = settingsId === undefined ? uuid4.generate() : settingsId;
          var cmd = {
            id: id,
            inviteId: inviteId,
            inviteOptions: { trainings: trainings },
            initiatingUserId: initiatingUserId,
          };
          cmd = addFieldToObject(
            cmd,
            { inviteOptions: { roles: roles } },
            function (roles) {
              return roles && roles.length > 0;
            },
            roles
          );
          cmd = addFieldToObject(
            cmd,
            { inviteOptions: { licenses: licenses } },
            function (licenses) {
              return licenses && licenses.length > 0;
            },
            licenses
          );
          cmd = addFieldToObject(
            cmd,
            { inviteOptions: { groups: groups } },
            function (groups) {
              return groups && groups.length > 0;
            },
            groups
          );
          return commandSubmissionService.submitAndWait(
            id,
            cmd,
            'SetOptionsForInvite',
            'OptionsForInviteSet',
            'InviteError',
            [invitesCache, inviteSettingsCache]
          );
        },
        getEasyLink: function (orgId) {
          return api.get('/v1/organizations/' + orgId + '/easy-link').then(function (response) {
            return response.data;
          });
        },
        validateGroupEasyLink: function (prefix, groupCode) {
          return api.get('/v1/easy-link/' + prefix + '/' + groupCode);
        },
        acceptForPrefix: function (prefix, groupCode) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
          var success = groupCode === undefined ? 'OrgAccountMemberAdded' : 'MembersAddedToOrgAccountGroup';
          var path = groupCode === undefined ? '/v1/easy-link/' + prefix : '/v1/easy-link/' + prefix + '/' + groupCode;
          api.post(path).then(
            function (response) {
              var orgId = response.data;

              if (groupCode === undefined) {
                $rootScope.$on('AlreadyExists', function () {
                  deferred.resolve();
                });
              }

              $rootScope.$on(success, function (name, event) {
                if (event.id === orgId) {
                  deferred.resolve();
                }
              });

              $rootScope.$on('OrgAccountError', function (name, event) {
                if (event.id === orgId) {
                  deferred.reject(event);
                }
              });

              $rootScope.$on('Already Exists Error', function (name, event) {
                deferred.reject(event.code);
              });
            },
            function (reason) {
              if (reason.status === 404) {
                deferred.reject('NOT_FOUND');
              } else {
                deferred.reject(reason);
              }
            }
          );

          return deferred.promise;
        },
      };

      // function commandSubmissionService.submitAndWait(inviteId, cmd, cmdName, evtName, errName){
      //   var deferred = qt.defer({timeoutSeconds: timeoutSeconds});
      //
      //   dropCaches();
      //
      //   api.post('/v1/commands/'+cmdName, cmd).then(null, function failure(reason) {
      //     deferred.reject(reason);
      //   });
      //
      //   $rootScope.$on(evtName, function(name, event) {
      //     if (event.id === inviteId) {
      //       deferred.resolve(event.id);
      //     }
      //   });
      //
      //   $rootScope.$on(errName, function(name, event) {
      //     if (event.id === inviteId) {
      //       deferred.reject(event.apiError.code);
      //     }
      //   });
      //
      //   return deferred.promise;
      // }

      function getInviteFromInvites(inviteId, invites, deferred) {
        var invite = invites.find(function (i) {
          return i.id === inviteId;
        });
        if (invite) {
          deferred.resolve(invite);
        } else {
          deferred.reject('NOT_FOUND');
        }
      }

      function getInvite(orgId, inviteId, deferred) {
        var possibleInvites = invitesCache.get(orgId);
        if (possibleInvites !== undefined) {
          getInviteFromInvites(inviteId, possibleInvites, deferred);
        } else {
          getInvitesAndCache(orgId).then(function (invites) {
            getInviteFromInvites(inviteId, invites, deferred);
          });
        }
      }

      function getSettingFromSettings(inviteId, invites, deferred) {
        var invite = invites.find(function (i) {
          return i.id === inviteId;
        });
        if (invite) {
          deferred.resolve(invite);
        } else {
          deferred.reject('NOT_FOUND');
        }
      }

      function findInviteSettings(orgId, inviteId, deferred) {
        // var possibleInviteSettings = inviteSettingsCache.get(orgId);
        // if (possibleInviteSettings !== undefined) {
        //   getSettingFromSettings(inviteId, possibleInviteSettings, deferred);
        // } else {
          getAllSettingsAndCache(orgId).then(function (inviteSettings) {
            getSettingFromSettings(inviteId, inviteSettings, deferred);
          });
        // }
      }

      function getAllSettingsAndCache(orgId) {
        return api.get('/v1/workflows?orgId=' + orgId + '_invite').then(function (response) {
          var settings = transformer.transformInviteSettings(response.data.workflows);
          inviteSettingsCache.put(orgId, settings);
          return settings;
        });
      }

      function getInvitesAndCache(orgId) {
        return api.get('/v1/invites/' + orgId).then(function (response) {
          var invites = transformer.transformInvites(response.data.invites);
          invitesCache.put(orgId, invites);
          return invites;
        });
      }

      function addFieldToObject(originalObject, objectToAdd, checkFunction, checkValue) {
        if (checkFunction(checkValue)) {
          originalObject = recursiveAdd(originalObject, objectToAdd);
        }
        return originalObject;
      }

      function recursiveAdd(o1, o2) {
        for (var attrname in o2) {
          if (o1[attrname] === undefined) {
            o1[attrname] = o2[attrname];
          } else {
            recursiveAdd(o1[attrname], o2[attrname]);
          }
        }
        return o1;
      }

      function setupDate(date) {
        if (date) {
          var d = {};
          d.year = date.getFullYear();
          d.month = date.getMonth() + 1; //uses 0 based month structure
          d.dayOfMonth = date.getDate();
          d.timeZone = 0;
          return d;
        } else {
          return date;
        }
      }

      function dropCaches() {
        invitesCache.removeAll();
        inviteSettingsCache.removeAll();
      }
    },
  ]
);
