import styled from 'styled-components';
import { Grid, Breakpoints, Color, Type } from '../StyleGuide';

const TrainingDetailsContainer = styled.div`
  padding: ${Grid._6} ${Grid._5};
  > div:nth-child(1) {
    display: none;
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._6} ${Grid._7} ${Grid._6} ${Grid._5};
    display: grid;
    grid-template-columns: ${Grid._12} 1fr;
    align-items: center;

    > div:nth-child(1) {
      display: flex;
      height: 100%;
      padding-right: ${Grid._5};
      text-align: center;
    }
  }
  .share-results-button {
    width: ${Grid._5};
    height: ${Grid._5};
    display: inline-flex;
    align-items: center;
    margin-left: ${Grid._3};
    cursor: pointer;
    .fa-circle {
      color: ${Color.Gray._10};
      font-size: ${Type.Scale._5};
    }
    .ion-share {
      color: ${Color.Gray._90};
    }
  }
`;

export default TrainingDetailsContainer;
