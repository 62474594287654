import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Color } from '../StyleGuide';
import windowService from '../services/windowService';
import alertService from '../services/AlertService';
import trainingInstanceService from '../services/trainingInstanceService';
import apiClient from '../services/apiClient';
import { ListItemFlexRow } from '../components/ListItemFlexRow';
import { OutlineButton } from '../components/Buttons';
import { useUser } from '../authentication';
import { handleError } from '../utils/apiUtils';
import { stopProp } from '../utils/domUtils';

const ReportRow = ({ initialReport, tasklistInstanceId, tasklistName = '', assigneeName = '' }) => {
  const user = useUser();
  const [data, setData] = useState({ report: initialReport, isGenerating: false });
  const { report, isGenerating } = data;

  const setIsGenerating = value => setData(prev => ({ ...prev, isGenerating: value }));

  const getHeadingByReportType = reportType =>
    ['CPIA', 'CPCA'].includes(reportType) ? 'Assessor Cumulative' : 'Candidate Cumulative Report';

  const getViewTypeByReportType = reportType => (['CPIA', 'CPCA'].includes(reportType) ? 'Assessor' : 'Candidate');

  const encodeFileName = reportType =>
    encodeURIComponent(`${assigneeName} ${tasklistName}_${getViewTypeByReportType(reportType)}`).replace(/ /gi, '_');

  const encodeFilePath = reportUrl => encodeURIComponent(reportUrl);

  const constructDownloadUrl = (fileName, filePath) =>
    `${apiClient.getConfig().baseURL}/v1/files?fileName=${fileName}&filePath=${filePath}`;

  const downloadFile = report =>
    windowService.openUrl(constructDownloadUrl(encodeFileName(report.id), encodeFilePath(report.reportId)));

  const generateReport = () => {
    setIsGenerating(true);

    trainingInstanceService
      .generateReport(tasklistInstanceId, user.userId)
      .then(response => {
        const { reportResult } = response || {};
        if (!reportResult) throw new Error('Invalid report generation response');

        setData({ report: reportResult, isGenerating: false });
        alertService.show('Report Generated');
      })
      .catch(error => {
        handleError(error);
        setIsGenerating(false);
      });
  };

  return report ? (
    <ListItemFlexRow onClick={() => downloadFile(report)}>
      <h4>{getHeadingByReportType(report.id)}</h4>

      {tasklistInstanceId && (
        <OutlineButton
          onClick={stopProp(generateReport)}
          loadingIndicatorColor={Color.Gray._80}
          operating={isGenerating}
          disabled={isGenerating}
        >
          Regenerate
        </OutlineButton>
      )}
    </ListItemFlexRow>
  ) : null;
};

ReportRow.propTypes = {
  initialReport: PropTypes.object,
  tasklistInstanceId: PropTypes.string,
  tasklistName: PropTypes.string,
  assigneeName: PropTypes.string,
};

export default ReportRow;
