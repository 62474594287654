import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MediumButton, MediumPrimaryButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';
import catalogService from '../services/catalogService';
import { FormFieldMaxCharCountStatus } from '../components/FormElements';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

export default function EditCatalogSectionModal({
  catalogId,
  section,
  userId,
  hideName,
  onSave,
  onError,
  handleDismiss,
}) {
  const submitForm = values =>
    catalogService
      .updateSectionDetails(catalogId, section.sectionId, values.name, values.shortDescription, userId)
      .then(() => onSave(values.name, values.shortDescription))
      .catch(reason => onError(reason));

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader data-qa-hook="editCatalogSectionModal">
        <h3>Edit Section</h3>
      </ModalHeader>
      <Formik
        enableReinitialize
        initialValues={{ name: section.title || '', shortDescription: section.description || '' }}
        validationSchema={Yup.object(
          Object.assign({}, hideName ? {} : { name: Yup.string().required('A name is required') }, {
            shortDescription: Yup.string(),
          })
        )}
        onSubmit={submitForm}
      >
        {({ isSubmitting }) => (
          <Form>
            <ModalBody>
              {!hideName && (
                <FormFieldMaxCharCountStatus
                  label="Name"
                  name="name"
                  type="text"
                  placeholder="Enter Name"
                  maxLength={64}
                />
              )}
              <FormFieldMaxCharCountStatus
                label="Short Description"
                name="shortDescription"
                type="text"
                placeholder="Enter Short Description"
                maxLength={124}
              />
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton data-qa-hook="save" type="submit" disabled={isSubmitting} operating={isSubmitting}>
                Save
              </MediumPrimaryButton>
              <MediumButton data-qa-hook="cancel" onClick={handleDismiss}>
                Cancel
              </MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
}

EditCatalogSectionModal.propTypes = {
  catalogId: PropTypes.string.isRequired,
  section: PropTypes.object.isRequired,
  userId: PropTypes.string,
  hideName: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};
