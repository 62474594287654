angular.module('lwNamb').service('blockUX', [
  'blockUI',
  'windowService',
  '$timeout',
  function(blockUI, windowService, $timeout) {
    var delayTimer;
    var timer;
    var blockWindow = function(phrase, timeout, reload, errorMessage, action) {
      var blockUXTimeoutAction = action;
      blockUI.start(phrase);

      if (reload || (action !== undefined && action !== null)) {
        blockUXTimeoutAction = function() {
          windowService.locationReload(true);
        };
      }

      timer = $timeout(function() {
        if (blockUI.state().blocking) {
          blockUI.stop();
          if (blockUXTimeoutAction !== undefined && blockUXTimeoutAction !== null) {
            blockUXTimeoutAction();
          }
        }
      }, timeout);
    };

    var update = function(phrase) {
      blockUI.message(phrase);
    };

    return {
      start: function(phrase, delay, timeout, reload, errorMessage, action) {
        if (delay !== null && delay > 0) {
          delayTimer = $timeout(function() {
            blockWindow(phrase, timeout, reload, errorMessage, action);
          }, delay);
        } else {
          blockWindow(phrase, timeout, reload, errorMessage, action);
        }
      },
      stop: function() {
        blockUI.stop();
        $timeout.cancel(timer);
        $timeout.cancel(delayTimer);
      },
      updatePhrase: function(phrase) {
        update(phrase);
      },
    };
  },
]);
