import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../../StyleGuide';

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  border: 1px solid ${Color.Gray._70};
  border-radius: ${Border.radius};
  padding: ${Grid._3};
`;

export default function ShortAnswer({ answers, setCurrentQuestionAnswer }) {
  const [answer, setAnswer] = useState(answers[0].value);

  useEffect(() => {
    if (!answers[0].wasChosen) {
      setAnswer('');
    }
  }, [answers]);

  const setShortAnswer = value => {
    setAnswer(value);
    setCurrentQuestionAnswer(value);
  };

  return (
    <TextArea
      data-qa-hook="shortAnswerText"
      value={answer}
      onChange={e => {
        setShortAnswer(e.target.value);
      }}
      placeholder="Enter short answer"
      rows="5"
    />
  );
}
