import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Color, Shadow, Border, Type, Grid, Breakpoints } from '../StyleGuide';

export const TabContainer = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  box-shadow: 0 0 0 1px ${Color.Blue._15}, ${Shadow.regular};
  overflow-x: auto;
  white-space: nowrap;
  margin-top: ${Grid._6};
  border-radius: ${Border.radius} ${Border.radius} 0 0;
`;

export const Tab = styled.li`
  list-style-type: none;
  text-align: center;
  background-color: ${props => (props.isActive ? 'white' : `${Color.Gray._05}`)};
  border-right: 1px solid ${Color.Blue._15};
  border-bottom: ${props => (props.isActive ? 'none' : `1px solid ${Color.Blue._15}`)};
  color: ${props => (props.isActive ? `${Type.Color.dark}` : `${Type.Color.medium}`)};
  cursor: pointer;
  font-size: ${Type.Scale._3};
`;

export const TabButton = styled.button`
  padding: ${Grid._4} ${Grid._7};
  border: 0;
  font-weight: ${Type.Weight.semibold};
  background: transparent;

  span {
    display: none;
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      display: inline-block;
      opacity: 0.6;
      color: ${Type.Color.medium};
      font-size: ${Type.Scale._2};
      position: relative;
      top: -1px;
    }
  }
`;

const ContentContainer = styled.div`
  padding: ${Grid._7};
  background: white;
  box-shadow: 0 0 0 1px ${Color.Blue._15}, ${Shadow.regular};
  border-radius: 0 0 ${Border.radius} ${Border.radius};

  @media screen and (max-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._5} 0;
  }
`;

const Tabs = ({ items, handleTabClick, defaultActiveTab, children }) => {
  const [activeTabId, setActiveTabId] = useState(defaultActiveTab);

  const handleClick = id => {
    setActiveTabId(id);
    handleTabClick(id);
  };

  return (
    <>
      <TabContainer>
        {items.map(tab => (
          <Tab key={tab.id} isActive={activeTabId === tab.id} data-qa-hook={tab.qaHook}>
            <TabButton onClick={() => handleClick(tab.id)}>{tab.label}</TabButton>
          </Tab>
        ))}
      </TabContainer>
      <ContentContainer>{children}</ContentContainer>
    </>
  );
};

Tabs.propTypes = {
  items: PropTypes.array.isRequired,
  handleTabClick: PropTypes.func,
  defaultActiveTab: PropTypes.string,
  children: PropTypes.node,
};

export default Tabs;
