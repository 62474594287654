import React, { useState, useEffect } from 'react';
import InfoBox from '../components/InfoBox';
import { Input } from '../components/FormElements';
import { PrimaryButton } from '../components/Buttons';
import ErrorMessage from '../components/ErrorMessage';

export default function Step2(props) {
  const { emailAddress, churchName, type } = props.user;
  const [info, toggleInfo] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const isAuthenticating = props.isAuthenticating;

  function handleToggle(event) {
    event.preventDefault();
    event.stopPropagation();
    toggleInfo(!info);
  }

  function handleOnChange(event) {
    props.setEmailAddress(event);
    if (!emailIsValid) {
      var isValid = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+\.[A-Za-z.]+$/.test(event.target.value);
      setEmailIsValid(isValid);
    }
  }

  function handleOnClick(event) {
    event.preventDefault();
    event.stopPropagation();
    var isValid = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+\.[A-Za-z.]+$/.test(emailAddress);
    setEmailIsValid(isValid);
    if (isValid) {
      props.handleEmailSubmission(emailAddress, type, null, props.handleNextStep);
    }
  }

  return (
    <React.Fragment>
      <h3 data-qa-hook="step2Header">Almost There</h3>
      <p>Church Cares uses Ministry Grid&#8482; by Lifeway to allow you to track training progress.</p>
      <Input
        style={{ margin: '16px 0 8px' }}
        data-qa-hook="emailAddressField"
        type="email"
        aria-label="Email Address"
        placeholder="Enter Email Address"
        value={emailAddress}
        onChange={handleOnChange}
        required
      />
      {!emailIsValid && <ErrorMessage>A valid email address is required</ErrorMessage>}
      <InfoBox>
        <p>
          <a onClick={handleToggle}>Why do we need this?</a>
        </p>
        {info && (
          <p>
            Your email address is your identity on Ministry Grid and is used to secure your results. We do not
            automatically add you to any email lists when you take training. You will receive emails from
            MinistryGrid.com related to training you are assigned.
            <br />
            <a
              href="https://support.ministrygrid.com/category/becoming-a-church-that-cares-well-for-the-abused/"
              target="blank"
            >
              View FAQs
            </a>
          </p>
        )}
      </InfoBox>
      <PrimaryButton
        data-qa-hook="nextButton"
        disabled={isAuthenticating}
        operating={isAuthenticating}
        onClick={handleOnClick}
      >
        Next
      </PrimaryButton>
    </React.Fragment>
  );
}
