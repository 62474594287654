import React from 'react';
import { SearchResultItem } from '../models';
import { useSelectTraining } from '../state/SelectTrainingProvider';
import { Search } from './Search';
import { SelectableSearchResultsListItem } from './SelectableSearchResultsListItem';
import { SelectedTraining, SelectedTrainingProps } from './SelectedTraining';

export const SelectTraining = (props: SelectedTrainingProps) => {
  const { selectTraining, deselectTraining, isTrainingSelected } = useSelectTraining();

  return (
    <>
      <SelectedTraining {...props} />

      <Search>
        {(results: SearchResultItem[]) =>
          results.map(result => {
            const isSelected = isTrainingSelected(result);
            return (
              <SelectableSearchResultsListItem
                key={result.id}
                training={result}
                isSelected={isSelected}
                onClick={isSelected ? deselectTraining : selectTraining}
              />
            );
          })
        }
      </Search>
    </>
  );
};
