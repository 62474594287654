import * as Yup from 'yup';
import { useUser } from '../../authentication';
import licenseService from '../../services/licenseService';

const useCancellationForm = () => {
  const user = useUser();

  const CancellationReasonMap = Object.freeze({
    TooExpensive: 'Too expensive',
    UsingAnotherCurriculum: 'Using another curriculum',
    ChangeSubscription: 'Change subscription',
    ChangeInAdmin: 'Change in staff/admin',
    UnsatisfactoryService: 'Did not meet my expectations',
    Other: 'Other',
  });

  const initialValues = {
    reason: '',
    explanation: '',
  };

  const isExplanationRequired = reason => {
    switch (reason) {
      case 'Other':
      case 'UnsatisfactoryService':
      case 'ChangeSubscription':
        return true;
      default:
        return false;
    }
  };

  const validationSchema = Yup.object().shape({
    reason: Yup.string().required('Please provide a reason for cancellation'),
    explanation: Yup.string().when('reason', {
      is: isExplanationRequired,
      then: Yup.string().required('Please provide more information'),
    }),
  });

  const cancelSubscription = (subscriptionId, reason, explanation) => {
    const cancellationReason = {
      type: reason,
      explanation,
    };

    return licenseService.cancelSubscription(subscriptionId, cancellationReason, user.userId);
  };

  return { initialValues, validationSchema, CancellationReasonMap, cancelSubscription };
};

export default useCancellationForm;
