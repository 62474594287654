import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  format,
  formatISO,
  addQuarters,
  subQuarters,
  startOfQuarter,
  endOfQuarter,
  addMonths,
  isBefore,
  isSunday,
  nextSunday,
  startOfMonth,
  endOfMonth,
  isSameDay,
  isSameMonth,
  parseISO,
} from 'date-fns';
import { Container } from '../../components/Layout';
import { Grid } from '../../StyleGuide';
import Breadcrumbs from '../../components/Breadcrumbs';
import LoadingState from '../../components/LoadingState';
import ErrorMessage from '../../components/ErrorMessage';
import BibleStudyHeader from '../../curriculum/BibleStudyHeader';
import BibleStudyHeaderImg from '../../curriculum/BibleStudyHeaderImg';
import { carouselControls as CarouselControl } from '../../components/CarouselElements';
import { Quarter, QuarterContainer, CalendarHeader } from '../../components/CalendarCarouselElements';
import AddSessionModal from './AddSessionModal';
import { useUser } from '../../authentication';
import windowService from '../../services/windowService';
import curriculumService from '../../services/curriculumService';
import alertService from '../../services/AlertService';
import { getDateFromDateTime } from '../../utils/dateUtils';
import useCurriculum from '../../hooks/useCurriculum';
import { useParams } from 'react-router-dom';
import VolumeCarousel from '../volume/VolumeCarousel';
import EmptyState from '../../components/EmptyState';
import SectionHeader from '../../components/SectionHeader';
import { SmallOutlineButton } from '../../components/Buttons';

const CarouselContainer = styled(Container)`
  margin: ${Grid._4} 0 ${Grid._8} 0;
`;

const fillTo = (arr, length, value = '') => arr.concat(Array.from(Array(length - arr.length)).fill(value));

const todayOrNextDay = (isDayFn, nextDayFn, date) => (isDayFn(date) ? date : nextDayFn(date));

const getAllOfDayBetween = (nextDayFn, day, end, days) =>
  isBefore(day, end) ? getAllOfDayBetween(nextDayFn, nextDayFn(day), end, days.concat([day])) : days;

const getMonthsFrom = (date, numMonths) => Array.from(Array(numMonths).keys()).map(i => addMonths(date, i));

const getSessionForDate = (date, sessions) => sessions.find(s => s.useDate && isSameDay(date, new Date(s.useDate)));

const buildQuarter = (date, sessions) =>
  getMonthsFrom(startOfQuarter(date), 3).map(m => ({
    name: format(m, 'MMMM'),
    sundays: fillTo(
      getAllOfDayBetween(nextSunday, todayOrNextDay(isSunday, nextSunday, startOfMonth(m)), endOfMonth(m), []),
      5
    ).map(date => ({ date, session: !!date && getSessionForDate(date, sessions) })),
  }));

const formatISODate = date => formatISO(date, { representation: 'date' });

const formatQuarterHeading = date => `${format(startOfQuarter(date), 'MMMM')} - ${format(endOfQuarter(date), 'MMMM')}`;

const getIssueByDate = (issues, date) =>
  issues.find(issue => isSameMonth(parseISO(getDateFromDateTime(issue.firstUseDate)), date))?.id;

const CalendarContainer = styled.div`
  position: relative;

  > button {
    position: absolute;
    top: 0;
    height 80px;
    z-index: 100;

    &:first-child {
      left: 0;
    }
    &:last-child {
      right: 0;
    }
  }
`;

export default function SessionCalendar() {
  const user = useUser();
  const [data, setData] = useState({
    curriculum: {},
    issues: [],
    collections: [],
    volumes: [],
    isLoading: true,
    isError: false,
  });
  const [viewingDate, setViewingDate] = useState(new Date());
  const [quarter, setQuarter] = useState();
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId } = useParams();
  const [modalData, setModalData] = useState();

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Curriculum', route: '#/manage-curriculum' },
  ];

  if (brand) crumbs.push({ name: `${brand.name}: ${ageCategory}` });

  const handleError = error => {
    console.error(error);
    setData({ ...data, isLoading: false, isError: true });
  };

  useEffect(() => {
    Promise.all([
      curriculumService.getIndividualCurriculum(curriculumId),
      curriculumService.getUnits(curriculumId),
      curriculumService.getCollections(curriculumId),
      curriculumService.getVolumes(curriculumId),
    ])
      .then(([curriculum, issues, collections, volumes]) => {
        setData({ ...data, curriculum, issues, collections, isLoading: false, volumes });
      })
      .catch(handleError);
  }, []);

  useEffect(() => {
    setQuarter();

    const waitForUserNavigation = setTimeout(
      () =>
        curriculumService
          .getSessionsAndTimelines(
            curriculumId,
            formatISODate(startOfQuarter(viewingDate)),
            formatISODate(endOfQuarter(viewingDate))
          )
          .then(sessionsAndTimelines => setQuarter(buildQuarter(viewingDate, sessionsAndTimelines)))
          .catch(handleError),
      1000
    );

    return () => clearTimeout(waitForUserNavigation);
  }, [viewingDate]);

  const redirectToSessionTimeline = (curriculumId, sessionId, timelineId) =>
    windowService.redirectTo(
      `/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/session/${sessionId}/timeline/${timelineId}`
    );

  const handleSessionAdded = (sessionId, timelineId) => {
    alertService.showOnNextPage('Session Added');
    redirectToSessionTimeline(curriculumId, sessionId, timelineId);
  };

  const handleDateSelected = (date, session, timeline) => {
    if (timeline) return redirectToSessionTimeline(curriculumId, session.sessionId, timeline.id);

    setModalData({ sessionId: session?.sessionId, sessionDate: date });
  };

  const previous = () => setViewingDate(subQuarters(viewingDate, 1));
  const next = () => setViewingDate(addQuarters(viewingDate, 1));

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container data-qa-hook="sessionCalendarView">
        {data.isLoading ? (
          <LoadingState />
        ) : data.isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : (
          <>
            <div className="grid-container grid-md-col-2" style={{ margin: `${Grid._6} 0` }}>
              <BibleStudyHeader
                className="grid-sm-col-span-1"
                thumbnail={<BibleStudyHeaderImg src={brand.imgSrc} background={brand.background} alt={brand.name} />}
                subtitle={brand.name}
                title={data.curriculum.name}
              />
              <div className="grid-sm-col-span-1">
                <h1 className="no-margin-top">Session Calendar</h1>
                <p>View and edit sessions by date.</p>
              </div>
            </div>
            <CalendarContainer>
              <CarouselControl type={'PREV'} onClick={previous} />
              <QuarterContainer>
                <CalendarHeader>
                  <h4 data-qa-hook="yearLabel">{format(viewingDate, 'yyyy')}</h4>
                  <h2 data-qa-hook="quarterLabel">{formatQuarterHeading(viewingDate)}</h2>
                </CalendarHeader>
                {quarter ? <Quarter quarter={quarter} onDateClick={handleDateSelected} /> : <LoadingState />}
              </QuarterContainer>
              <CarouselControl type={'NEXT'} onClick={next} />
            </CalendarContainer>
            <CarouselContainer>
              <SectionHeader title="Volumes for Advanced Planning">
                <div>
                  <SmallOutlineButton
                    onClick={() => {
                      windowService.redirectTo(
                        `/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/volume`
                      );
                    }}
                  >
                    Add Volume
                  </SmallOutlineButton>
                </div>
              </SectionHeader>
              {data.volumes.length > 0 ? (
                <VolumeCarousel
                  volumes={data.volumes}
                  brandCode={data.curriculum.brandCode}
                  name={data.curriculum.name}
                />
              ) : (
                <>
                  <EmptyState
                    title="There are no volumes"
                    description="Use the Add Volume button to begin creating volumes"
                  />
                </>
              )}
            </CarouselContainer>
          </>
        )}
        {!!modalData && (
          <AddSessionModal
            curriculumId={curriculumId}
            issueId={getIssueByDate(data.issues, modalData.sessionDate)}
            sessionId={modalData.sessionId}
            collections={data.collections}
            date={modalData.sessionDate}
            handleSessionAdded={handleSessionAdded}
            handleDismiss={() => setModalData()}
            initiatingUserId={user.userId}
          />
        )}
      </Container>
    </>
  );
}
