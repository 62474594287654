angular.module('lwNamb').directive('shareMembershipCode', [
  function() {
    return {
      restrict: 'A',
      templateUrl: 'partials/directives/share-membership-code.html',
      scope: {
        invite: '=',
        element: '@',
      },
      controller: [
        '$scope',
        '$log',
        '$timeout',
        function($scope, $log, $timeout) {
          $scope.copyInviteCode = function(e) {
            $log.info(e);
            $scope.codeCopied = true;
            $timeout(function() {
              $scope.codeCopied = false;
              e.clearSelection();
            }, 2000);
          };
        },
      ],
    };
  },
]);
