import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Type } from '../StyleGuide';

const Text = styled.p`
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._3};
  line-height: ${Type.Leading.taller};
  margin: ${Grid._3} 0 ${Grid._5};
`;

function TextTruncate({ text, truncateLength, canShowMore }) {
  const [showLess, setShowLess] = useState(true);
  const isTruncated = text.length > truncateLength;
  const truncate = text.length > truncateLength ? `${text.substring(0, truncateLength)}...` : text;

  return (
    <Text data-qa-hook="textContent" className="collapsable-data">
      {showLess ? truncate : text}
      {isTruncated && <br />}
      {isTruncated && canShowMore && (
        <a className="link" onClick={() => setShowLess(!showLess)}>
          {showLess ? 'Show More' : 'Show Less'}
        </a>
      )}
    </Text>
  );
}

TextTruncate.defaultProps = {
  truncateLength: 325,
  canShowMore: true,
};

TextTruncate.propTypes = {
  text: PropTypes.string.isRequired,
  truncateLength: PropTypes.number,
  canShowMore: PropTypes.bool,
};

export default TextTruncate;
