import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Type, Breakpoints } from '../StyleGuide';
import { InlineList } from '../components/Layout';
import { SmallToggleRoundedIconButton } from '../components/Buttons';
import TrainingPosterPlaceholder from './TrainingPosterPlaceholder';
import { TrainingPoster, TrainingInformation, Labels } from '../components/TrainingElements';
import PathwayTrack, { TrackContainer, TrackLine } from './PathwayTrack';
import CourseSteps from './CourseSteps';
import TrainingOverview from './TrainingOverview';
import capitalizeFilter from '../filters/capitalize-filter';
import WhiteLabel from '../filters/WhiteLabel';
import trainingService from '../services/trainingService';

const CourseContainer = styled.div`
  margin-bottom: ${Grid._5};
  &:last-child {
    margin-bottom: 0;
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin-bottom: ${Grid._7};
  }
`;

const CollapseContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PathwayCourse = ({ course, i, length, localStorage }) => {
  const [showSteps, setShowSteps] = useState(false);

  const toggleShowSteps = () => setShowSteps(prevState => (prevState = !prevState));

  return (
    <CourseContainer>
      <div className="grid-container grid-col-5 grid-sm-col-8 grid-gap-16 grid-sm-gap-24">
        <div className="grid-col-span-1">
          <PathwayTrack i={i + 1} length={length} />
        </div>
        <div className="grid-col-span-4 grid-sm-col-span-2">
          {course.posterImage ? (
            <TrainingPoster src={trainingService.getTrainingImageUrl(course.posterImage)} alt={course.name} />
          ) : (
            <TrainingPosterPlaceholder ownerName={course.ownerName} />
          )}
        </div>
        <div className="grid-col-span-1 visible-xs-block">
          <TrackContainer>{i + 1 < length && <TrackLine />}</TrackContainer>
        </div>
        <div className="grid-col-span-4 grid-sm-col-span-4">
          <TrainingInformation>
            <Labels fontSize="16px">Course</Labels>
            <h2 onClick={() => toggleShowSteps()}>{course.name}</h2>
            <InlineList content="•" fontSize="16px">
              <li>
                {course.tasks.length} {capitalizeFilter(WhiteLabel.labels.step)}
                {course.tasks.length > 1 ? 's' : ''}
              </li>
              {course.ownerName && <li>{course.ownerName}</li>}
            </InlineList>
          </TrainingInformation>
        </div>
        <CollapseContainer className="grid-col-span-1 hidden-sm hidden-xs">
          <SmallToggleRoundedIconButton onClick={() => toggleShowSteps()} status={showSteps}>
            <i className="fas fa-chevron-down"></i>
          </SmallToggleRoundedIconButton>
        </CollapseContainer>
      </div>
      {showSteps && (
        <div
          className="grid-container grid-col-5 grid-sm-col-8 grid-gap-16 grid-sm-gap-24"
          style={{ marginTop: '24px' }}
        >
          <div className="grid-col-span-1 hidden-xs">
            <TrackContainer>{i + 1 < length && <TrackLine />}</TrackContainer>
          </div>
          <div className="grid-col-span-5 grid-sm-col-span-2">
            <TrainingOverview
              description={course.description}
              truncateLength={500}
              showPlaceholder={false}
              categories={course.categories}
            />
          </div>
          <div className="grid-col-span-5 grid-sm-col-span-5">
            <CourseSteps course={course} localStorage={localStorage} />
          </div>
        </div>
      )}
    </CourseContainer>
  );
};

export default PathwayCourse;
