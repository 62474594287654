angular.module('lwNamb').directive(
  'taskViewerVideoPreview',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/task-viewer-video-preview.html',
        scope: {
          task: '=',
          isPreview: '=',
          colClass: '@',
          showThirdPartyVideo: '=',
        },
      };
    },
  ]
);
