angular.module('lwNamb').factory(
  'fileUrlService',

  [
    '$http',
    'apiUrlService',
    'windowService',
    '$window',
    function($http, apiUrlService, windowService, $window) {
      return {
        get: function(fileId) {
          return $http({
            method: 'GET',
            url: apiUrlService.getUrl() + '/v1/files/' + fileId + '/url',
          }).then(function(response) {
            return response.data;
          });
        },
        getFileMetaData: function(fileId) {
          return $http({
            method: 'GET',
            url: apiUrlService.getUrl() + '/v1/files/' + fileId + '/meta-data',
          }).then(function(response) {
            return response.data;
          });
        },
        getCertificate: function(tasklistId, userId) {
          return apiUrlService.getUrl() + '/v1/files/certificates/' + tasklistId + '/' + userId;
        },
        openFile: function(fileId, fileName, overrideBase, forceZip) {
          var ua = $window.navigator.userAgent;
          var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i) || !!ua.match(/iPod/i);
          var url = apiUrlService.getUrl();
          var base = '/v1/files/' + fileId;
          if (overrideBase !== undefined) base = overrideBase;
          url = url + base;
          if (
            (fileName !== undefined &&
              iOS === true &&
              (fileName.indexOf('.mp4') > -1 || fileName.indexOf('.mov') > -1)) ||
            forceZip
          )
            url = url + '?zip=true';
          windowService.openUrl(url);
        },
      };
    },
  ]
);
