angular.module('lwNamb').factory('toastService', [
  function() {
    var defaultOptions = {
      positionClass: 'toast-top-right',
      closeButton: true,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      preventDuplicates: true,
      showDuration: '0',
      hideDuration: '0',
      timeOut: '0',
      extendedTimeOut: '0',
      tapToDismiss: false,
      escapeHtml: false,
    };

    function initializeOptions(customOptions) {
      // If custom options exist, merge default options with custom ones
      toastr.options = customOptions ? Object.assign({}, defaultOptions, customOptions) : defaultOptions;
    }

    var success = function(message, title, customOptions) {
        initializeOptions(customOptions);
        toastr.success(message, title);
      },
      error = function(message, title, customOptions) {
        initializeOptions(customOptions);
        toastr.error(message, title);
      },
      info = function(message, title, customOptions) {
        initializeOptions(customOptions);
        toastr.info(message, title);
      },
      warning = function(message, title, customOptions) {
        initializeOptions(customOptions);
        toastr.warning(message, title);
      },
      remove = function() {
        toastr.remove();
      },
      clear = function() {
        toastr.clear();
      },
      render = function(options) {
        var returnSvc = {
            $toast: null,
            close: null,
          },
          tplContent = $templateCache.get(options.templateUrl),
          scope = $rootScope.$new(),
          ctrlInstance,
          ctrlLocals = {},
          resolveIndex = 0,
          promisesArr = [];

        // Define promises that we want to resolve
        angular.forEach(options.resolves, function(value) {
          if (angular.isFunction(value) || angular.isArray(value)) {
            promisesArr.push($q.when($injector.invoke(value)));
          }
        });

        // Resolve any resolves for which we gathered promises
        var resolvePromise = $q.all(promisesArr);

        // Once we finish resolving, get busy!
        resolvePromise.then(function resolveSuccess(vars) {
          //controllers
          if (options.controller) {
            ctrlLocals.scope = scope;
            ctrlLocals.$scope = scope;

            // Set the resolves we got back as members on the controller's locals
            angular.forEach(options.resolves, function(value, key) {
              ctrlLocals[key] = vars[resolveIndex++];
            });

            // Now create our controller with its locals
            ctrlInstance = $controller(options.controller, ctrlLocals);

            // If controllerAs was passed in as an option, set it.
            if (options.controllerAs) {
              scope[controllerAs] = ctrlInstance;
            }
          }

          // Compile our DOM element
          var angularDomEl = $compile(tplContent)(scope);

          // Instantiate the $toast
          var toast = toastr.info(angularDomEl, '', defaultOptions);
          returnSvc.toast = toast;

          toast.on('$destroy', function() {
            scope.$destroy();
          });

          // Create a cloase method accessible by the return object
          returnSvc.close = function() {
            toast.fadeOut(500, function() {
              $(this).remove();
            });
          };
        });

        return returnSvc;
      };

    return {
      success: success,
      error: error,
      info: info,
      warning: warning,
      remove: remove,
      clear: clear,
      render: render,
    };
  },
]);
