import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../authentication';
import { Container } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import Breadcrumbs from '../components/Breadcrumbs';
import InlineEmailEditor from '../containers/InlineEmailEditor';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import alertService from '../services/AlertService';
import orgService, { getUserId, getUserEmail } from '../services/orgService';
import emailService from '../services/emailService';

export default function EmailPerson() {
  const user = useUser();
  const [data, setData] = useState({ isLoading: true });
  const [crumbs, setCrumbs] = useState([
    { name: 'Organization', route: '#/org' },
    { name: 'People', route: '#/people' },
  ]);
  const { orgId, personId: memberId } = useParams();
  const { person, template, emailConfig, isLoading, isError } = data;

  const getEmailConfig = person => {
    const receiverName = person.firstName ? person.firstName : person.email;
    const defaults = {
      subject: `A message from ${user.firstName}`,
      placeholder: `Enter a personal message for ${receiverName}`,
      buttonText: 'Log In',
    };

    const localStorageConfig = localStorage.getItem('ngStorage-emailPerson');
    if (localStorageConfig) {
      return { ...defaults, ...JSON.parse(localStorageConfig) };
    }

    return defaults;
  };

  const loadData = () =>
    orgService
      .getMember(memberId)
      .then(person => ({ person, emailConfig: getEmailConfig(person) }))
      .then(({ person, emailConfig }) =>
        emailService.getEmailPreview(orgId, 'blank', '###MSG###', emailConfig.buttonText).then(template => ({
          person,
          emailConfig,
          template: template.emails[0].content.html.replace(/>\s+</g, '><').trim(),
        }))
      );

  useEffect(() => {
    loadData()
      .then(({ person, template, emailConfig }) => {
        setData({ person, template, emailConfig, isLoading: false });
        const personName = person.name && person.name.trim() !== '' ? person.name : getUserEmail(person);
        setCrumbs(prevCrumbs => [
          ...prevCrumbs,
          { name: personName, route: `#/person/${getUserId(person)}` },
          { name: `Email ${personName}` },
        ]);
      })
      .catch(err => {
        setData({ isLoading: false, isError: true });
        console.error(err);
        alertService.showError('An error occurred. Please try again.');
      });
  }, []);

  return (
    <>
      {<Breadcrumbs crumbs={crumbs} />}
      <Container data-qa-hook="emailPersonView">
        {isLoading ? (
          <LoadingState />
        ) : isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : (
          <>
            <HelpAndInfoBox
              title="Edit Your Email"
              description="Add your personal message in the email preview. Use the Send Email button below the email to finish."
            />
            <InlineEmailEditor
              orgId={orgId}
              htmlTemplateString={template}
              defaultSubject={emailConfig.subject}
              defaultReplyTo={user.logInEmail}
              messagePlaceholder={emailConfig.placeholder}
              recipientEmail={getUserEmail(person)}
              bodyText={emailConfig.bodyText}
              buttonText={emailConfig.buttonText}
            />
          </>
        )}
      </Container>
    </>
  );
}
