import { AxiosResponse } from 'axios';
import cacheableRequest from '../../services/cacheableRequest';
import { License, Person, Seats, OrgUserResponse, addDisplayName } from '../models/license';

type LicenseResponse = {
  createdAt: string;
  startDate: string;
  id: string;
  item: {
    number: string;
    title: string;
  };
  managers: {
    email?: string;
    firstName?: string;
    lastName?: string;
    id: string;
  }[];
  seats: Seats;
  source: {
    grantedAt: string;
  };
};

const transformLicense = (license: LicenseResponse): License => {
  return {
    name: license.item.title,
    startDate: license.startDate,
    createdAt: license.createdAt,
    id: license.id,
    managers: license.managers,
    users: addDisplayName(license.seats.occupants).map(user => ({
      ...user,
      profile: {
        imageUrl: user.profile?.imageUrl || '',
      },
    })),
  };
};

export const getLicenses: (orgId: string, licenseId: string) => Promise<License> = (orgId, licenseId) =>
  Promise.all([
    cacheableRequest(`/v1/licenses?detail=true&orgId=${orgId}`),
    cacheableRequest(`/v1/organizations/${orgId}/users`),
  ]).then(
    ([licenseResponse, usersInOrganization]: [
      AxiosResponse<{ licenses: LicenseResponse[] }>,
      AxiosResponse<{ org: { users: OrgUserResponse[] } }>
    ]) => {
      const license = transformLicense(
        licenseResponse.data.licenses.find(item => item.id === licenseId) as LicenseResponse
      );

      const updatedUsers = license.users.map((person: Person) => {
        const orgUser = usersInOrganization.data.org.users.find((item: OrgUserResponse) => item?._id?.id === person.id);
        return {
          ...person,
          profile: orgUser?.profile,
        };
      });

      return {
        ...license,
        users: updatedUsers,
      } as License;
    }
  );
