import React, { useEffect } from 'react';
import { react2angular } from 'react2angular';
import Notifications from 'react-notify-toast';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import StepContent from './StepContent';
import TrainingMenu from './TrainingMenu';
import LockedCourse from './LockedCourse';
import { Breakpoints } from '../StyleGuide';
import TrainingCompleted from './TrainingCompleted';
import alertService from '../services/AlertService';

import { useSteps, toggleMobileTrainingMenu, StepsProvider } from './StepsContext';

const StepsLayout = styled.div`
  display: flex;
  min-height: 100vh;
  .mobile-menu-bg.visible {
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 250;
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      display: none;
    }
  }
`;

export default function Steps({ trainingInput, stepInstanceId, user }) {
  return (
    <StepsProvider trainingInput={trainingInput} stepInstanceId={stepInstanceId} user={user}>
      <CurrentSteps />
    </StepsProvider>
  );
}

Steps.propTypes = {
  trainingInput: PropTypes.object.isRequired,
  stepInstanceId: PropTypes.string,
  user: PropTypes.object.isRequired,
};

const CurrentSteps = () => {
  const [
    { localStorageKey, currentStepIndex, isMobileTrainingMenuOpen, currentCourse, showTrainingCompletedModal },
    dispatch,
  ] = useSteps();

  useEffect(() => {
    localStorage.setItem(localStorageKey, currentStepIndex);
    return () => {
      localStorage.removeItem(localStorageKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  useEffect(() => {
    setTimeout(() => toggleMobileTrainingMenu(dispatch, true), 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localStorage.getItem('showBanner')) {
      const text = localStorage.getItem('showBanner');
      alertService.show(text);
      localStorage.removeItem('showBanner');
    }
    return () => {
      if (localStorage.getItem('routeBeforeSteps')) localStorage.removeItem('routeBeforeSteps');
    };
  }, []);

  const purchaseRequired = !!currentCourse.purchaseRequired;

  const stepsClass = classNames({
    'no-scroll': isMobileTrainingMenuOpen,
  });

  const mobileMenuClass = classNames({
    'mobile-menu-bg': true,
    visible: isMobileTrainingMenuOpen,
  });

  return (
    <>
      <Notifications />
      <StepsLayout className={stepsClass}>
        {purchaseRequired ? <LockedCourse /> : <StepContent />}
        <TrainingMenu close={() => toggleMobileTrainingMenu(dispatch, false)} />
        <div onClick={() => toggleMobileTrainingMenu(dispatch, false)} className={mobileMenuClass}></div>
        {showTrainingCompletedModal && <TrainingCompleted />}
      </StepsLayout>
    </>
  );
};

// eslint-disable-next-line no-undef
angular.module('lwNamb').component('steps', react2angular(Steps));
