import React from 'react';
import { Grid } from '../StyleGuide';
import { Container, PageTitle } from '../components/Layout';
import TrainingSubMenu from '../components/TrainingSubMenu';
import { Search, SearchProvider } from '../search';

const SearchTraining = () => {
  return (
    <>
      <TrainingSubMenu activeTab={'search'} />

      <Container style={{ margin: `${Grid._7} auto ${Grid._10} auto` }}>
        <PageTitle className="mb-40">
          <h1>Search</h1>
          <p>Find and preview any course or pathway within Ministry Grid</p>
        </PageTitle>

        <SearchProvider>
          <Search />
        </SearchProvider>
      </Container>
    </>
  );
};

export default SearchTraining;
