import cacheableRequest from './cacheableRequest';
import cacheService from './cacheService';
import submitCommand from './submitCommand';
import { generateUUID } from './uuid';
import { transformSession } from './curriculumService';
import apiClient from './apiClient';
import { ymdToUTCString } from '../utils/dateUtils';

export const SESSION_TIMELINE_STATUS = Object.freeze({
  IN_PROGRESS: 'in_progress',
  IN_REVIEW: 'in_review',
  PUBLISHED: 'published',
});

export const renderSegments = (timeline, renderer) =>
  timeline.segments.reduce(
    ([elements, durationRemaining], segment, i) => [
      [...elements, renderer(segment, durationRemaining, i)],
      durationRemaining - segment.duration,
    ],
    [[], timeline.duration]
  )[0];

const calculateSegmentDuration = segment => {
  if (segment.enabled === false || !segment.duration) return 0;

  return segment.duration;
};

export const transformSessionTimeline = timeline => ({
  ...timeline,
  id: timeline.timelineCustomizationId ?? timeline._id,
  originalTimelineId: timeline.timelineCustomizationId ? timeline._id : undefined,
  status: timeline.status || {},
  segments: timeline.segments?.map(transformSegment) || [],
  duration: (timeline.segments || []).reduce((duration, segment) => duration + calculateSegmentDuration(segment), 0),
  name: timeline.name ?? 'Leader Guide',
});

export const transformSegment = (segment = {}) => ({
  ...segment,
  isFlex: !!segment.options,
  enabled: segment.enabled ?? true,
  sections: segment.sections?.map(transformSection) || [],
  options: segment.options?.map(option => ({
    ...transformSegment(option),
    flexSegmentId: segment.segmentId,
    enabled: option.enabled ?? true,
  })),
});

export const transformSection = (section = {}) => ({
  ...section,
  type: section._type,
});

const clearTimelineCache = timelineId => {
  cacheService.remove(`/author/curriculum/timelines/${timelineId}`);
  clearAdminAndTeacherTimelineCache();
};

const clearAdminAndTeacherTimelineCache = () => {
  cacheService.removeAllInPath(`/v1/org/curriculum/`);
  cacheService.removeAllInPath(`/v2/org/curriculum/`);
};

export const insertAtIndex = (array, item, index) => {
  if (Array.isArray(array)) array.splice(index, 0, item);

  return array;
};

export const updateItemInList = (list, key, id, updateFn) =>
  list.map(item => (item[key] === id ? updateFn(item) : item));

const updateFlexOptionsFromFlexSegment = (options, segment) =>
  options.map(option => {
    const { group, leader, duration } = segment;
    return { ...option, group, leader, duration };
  });

export const mapSections = (timeline, fn) =>
  timeline.segments
    .flatMap(segment => (segment.isFlex ? segment.options : segment))
    .flatMap(segment => segment.sections.map(section => fn(section, segment)));

const removeRestrictedCharactersFromArchiveName = archiveName => archiveName.replaceAll(/[^a-zA-Z0-9\-_.]+/g, '');

const sessionTimelineService = {
  createSessionTimeline: (sessionId, collectionId, initiatingUserId) =>
    generateUUID(id =>
      submitCommand(
        id,
        { id, sessionId, collectionId, initiatingUserId },
        'CtCreateTimeline',
        'CtTimelineCreated',
        'CtCommandFailed'
      )
    ),

  copySessionTimeline: ({
    curriculumId,
    unitId,
    collectionId,
    sessionId,
    sessionName,
    timelineId,
    useDate,
    publishStartDate,
    publishEndDate,
  }) => {
    cacheService.removeAllInPath(`/author/curriculum/sessions-and-timelines`);

    const payload = {
      useDate: ymdToUTCString(useDate),
      publishStartDate,
      publishEndDate,
      name: sessionName,
      curriculumId,
      collectionId: collectionId,
      unitId: unitId,
      sourceSessionId: sessionId,
      sourceTimelineId: { value: timelineId },
    };

    // We're adding a delay to the response because by the time the response comes
    // back to us, we can't be entirely sure that the new timeline has been
    // completely filled with the proper data (namely, the sections). If we find that
    // this delay isn't consistently working, we may need to poll the timeline
    // endpoint until we have all the data we need.
    return apiClient
      .post(`/curriculum-api/issues/${unitId}/sessions/copy`, payload, undefined, [], 0)
      .then(response => new Promise(resolve => setTimeout(() => resolve(response), 500)));
  },

  updateSessionTimelineStatus: (timelineId, status, initiatingUserId) => {
    cacheService.removeAllInPath(`/author/curriculum/timelines`);
    cacheService.removeAllInPath(`/author/curriculum/sessions-and-timelines`);

    return submitCommand(
      timelineId,
      { id: timelineId, status, initiatingUserId },
      'CtSetStatus',
      'CtStatusSet',
      'CtCommandFailed'
    );
  },

  getSessionTimelines: (sessionId, collectionId) =>
    cacheableRequest(`/author/curriculum/timelines?sessionId=${sessionId}&collectionId=${collectionId}`).then(
      response => response.data[0]?.map(transformSessionTimeline)
    ),

  getSessionTimeline: timelineId =>
    cacheableRequest(`/author/curriculum/timelines/${timelineId}`).then(
      response => !!response?.data && transformSessionTimeline(response.data)
    ),

  deleteSessionTimeline: (timelineId, initiatingUserId) => {
    cacheService.removeAllInPath(`/author/curriculum/timelines`);
    cacheService.removeAllInPath(`/author/curriculum/sessions-and-timelines`);

    return submitCommand(
      timelineId,
      { id: timelineId, initiatingUserId },
      'CtDeleteTimeline',
      'CtTimelineDeleted',
      'CtCommandFailed'
    );
  },

  createSegment: (timelineId, { name, group, leader, duration, activity, tags = [] }, atIndex, initiatingUserId) => {
    clearTimelineCache(timelineId);

    return generateUUID(segmentId =>
      submitCommand(
        timelineId,
        {
          id: timelineId,
          segmentId,
          name,
          group,
          leader,
          duration: Number(duration),
          activity,
          tags,
          atIndex,
          initiatingUserId,
        },
        'CtCreateSegment',
        'CtSegmentCreated',
        'CtCommandFailed'
      ).then(response => transformSegment(response?.segment))
    );
  },

  editSegment: (timelineId, { segmentId, name, group, leader, duration, activity, tags = [] }, initiatingUserId) => {
    clearTimelineCache(timelineId);

    return submitCommand(
      timelineId,
      { id: timelineId, segmentId, name, group, leader, duration: Number(duration), activity, tags, initiatingUserId },
      'CtSetSegmentAttributes',
      'CtSegmentAttributesSet',
      'CtCommandFailed'
    );
  },

  reorderSegments: (timelineId, segmentIds, initiatingUserId) => {
    clearTimelineCache(timelineId);
    return submitCommand(
      timelineId,
      { id: timelineId, segmentIds, initiatingUserId },
      'CtOrderSegments',
      'CtSegmentsOrdered',
      'CtCommandFailed'
    );
  },

  deleteSegment: (timelineId, segmentId, initiatingUserId) => {
    clearTimelineCache(timelineId);
    return submitCommand(
      timelineId,
      { id: timelineId, segmentId, initiatingUserId },
      'CtDeleteSegment',
      'CtSegmentDeleted',
      'CtCommandFailed'
    );
  },

  createFlexSegment: (timelineId, { name, group, leader, duration }, atIndex, initiatingUserId) => {
    clearTimelineCache(timelineId);

    return generateUUID(segmentId =>
      submitCommand(
        timelineId,
        { id: timelineId, segmentId, name, group, leader, duration: Number(duration), atIndex, initiatingUserId },
        'CtCreateFlexSegment',
        'CtSegmentCreated',
        'CtCommandFailed'
      ).then(response => transformSegment(response?.segment))
    );
  },

  createFlexOption: (timelineId, { flexSegmentId, name, label, activity, tags = [] }, atIndex, initiatingUserId) => {
    clearTimelineCache(timelineId);

    return generateUUID(segmentId =>
      submitCommand(
        timelineId,
        { id: timelineId, segmentId, flexSegmentId, name, label, activity, tags, atIndex, initiatingUserId },
        'CtCreateFlexOption',
        'CtFlexOptionCreated',
        'CtCommandFailed'
      ).then(response => transformSegment({ ...response?.option, flexSegmentId: response?.flexSegmentId }))
    );
  },

  createSection: (timelineId, segmentId, sectionType, atIndex, initiatingUserId) => {
    clearTimelineCache(timelineId);

    return generateUUID(sectionId =>
      submitCommand(
        timelineId,
        { id: timelineId, segmentId, sectionId, sectionType, atIndex, initiatingUserId },
        'CtCreateSection',
        'CtSectionCreated',
        'CtCommandFailed'
      ).then(response => transformSection(response?.section))
    );
  },

  updateSectionContent: (timelineId, sectionId, content, initiatingUserId) => {
    clearTimelineCache(timelineId);

    const optionalData = Object.fromEntries(Object.entries(content).filter(([key, value]) => value !== undefined));

    const data = { id: timelineId, sectionId, ...optionalData, initiatingUserId };
    return submitCommand(timelineId, data, 'CtSetSectionContent', 'CtSectionContentSet', 'CtCommandFailed');
  },

  updateSectionMedia: (timelineId, sectionId, media, initiatingUserId) => {
    clearTimelineCache(timelineId);
    return submitCommand(
      timelineId,
      { id: timelineId, sectionId, media, initiatingUserId },
      'CtSetSectionMedia',
      'CtSectionMediaSet',
      'CtCommandFailed'
    );
  },

  deleteSection: (timelineId, sectionId, initiatingUserId) => {
    clearTimelineCache(timelineId);
    return submitCommand(
      timelineId,
      { id: timelineId, sectionId, initiatingUserId },
      'CtDeleteSection',
      'CtSectionDeleted',
      'CtCommandFailed'
    );
  },

  insertSegmentIntoTimeline: (timeline, flexSegmentId, segment, atIndex) =>
    transformSessionTimeline({
      ...timeline,
      ...(flexSegmentId
        ? {
            segments: updateItemInList(timeline.segments, 'segmentId', flexSegmentId, s => ({
              ...s,
              options: insertAtIndex(s.options, segment, atIndex),
            })),
          }
        : {
            segments: insertAtIndex(timeline.segments, transformSegment(segment), atIndex),
          }),
    }),

  reorderSegmentsInTimeline: (timeline, reorderedSegments) =>
    transformSessionTimeline({
      ...timeline,
      segments: [...reorderedSegments],
    }),

  insertSectionIntoTimeline: (timeline, flexSegmentId, segmentId, section, atIndex) => ({
    ...timeline,
    segments: updateItemInList(timeline.segments, 'segmentId', flexSegmentId || segmentId, segment => ({
      ...segment,
      ...(flexSegmentId
        ? {
            options: updateItemInList(segment.options, 'segmentId', segmentId, option => ({
              ...option,
              sections: insertAtIndex(option.sections, section, atIndex),
            })),
          }
        : {
            sections: insertAtIndex(segment.sections, section, atIndex),
          }),
    })),
  }),

  removeSegmentFromTimeline: (timeline, flexSegmentId, segmentId) =>
    transformSessionTimeline({
      ...timeline,
      ...(flexSegmentId
        ? {
            segments: updateItemInList(timeline.segments, 'segmentId', flexSegmentId, segment => ({
              ...segment,
              options: segment.options.filter(option => option.segmentId !== segmentId),
            })),
          }
        : {
            segments: timeline.segments.filter(segment => segment.segmentId !== segmentId),
          }),
    }),

  removeSectionFromTimeline: (timeline, flexSegmentId, segmentId, sectionId) => ({
    ...timeline,
    segments: updateItemInList(timeline.segments, 'segmentId', flexSegmentId || segmentId, segment => ({
      ...segment,
      ...(flexSegmentId
        ? {
            options: updateItemInList(segment.options, 'segmentId', segmentId, option => ({
              ...option,
              sections: option.sections.filter(section => section.id !== sectionId),
            })),
          }
        : {
            sections: segment.sections.filter(section => section.id !== sectionId),
          }),
    })),
  }),

  updateStatusForTimeline: (timeline, { status: reviewStatus, firstName, lastName, at }) => ({
    ...timeline,
    status: { status: reviewStatus, firstName, lastName, at },
  }),

  updateSegmentInTimeline: (timeline, segmentId, updatedSegment, transform = true) => {
    const updatedTimeline = {
      ...timeline,
      segments: updateItemInList(timeline.segments, 'segmentId', segmentId, segment => ({
        ...segment,
        ...updatedSegment,
        ...(segment.options ? { options: updateFlexOptionsFromFlexSegment(segment.options, updatedSegment) } : {}),
      })),
    };

    return transform ? transformSessionTimeline(updatedTimeline) : updatedTimeline;
  },

  updateFlexOptionInTimeline: (timeline, flexSegmentId, segmentId, updatedSegment) => ({
    ...timeline,
    segments: updateItemInList(timeline.segments, 'segmentId', flexSegmentId, segment => ({
      ...segment,
      options: updateItemInList(segment.options, 'segmentId', segmentId, option => ({
        ...option,
        ...updatedSegment,
      })),
    })),
  }),

  updateSectionContentInTimeline: (timeline, flexSegmentId, segmentId, sectionId, content) => ({
    ...timeline,
    segments: updateItemInList(timeline.segments, 'segmentId', flexSegmentId || segmentId, segment => ({
      ...segment,
      ...(flexSegmentId
        ? {
            options: updateItemInList(segment.options, 'segmentId', segmentId, option => ({
              ...option,
              sections: updateItemInList(option.sections, 'id', sectionId, section => ({ ...section, ...content })),
            })),
          }
        : {
            sections: updateItemInList(segment.sections, 'id', sectionId, section => ({ ...section, ...content })),
          }),
    })),
  }),

  updateMediaInTimeline: (timeline, media) => ({
    ...timeline,
    segments: timeline.segments.map(segment => ({
      ...segment,
      ...(segment.isFlex
        ? {
            options: segment.options.map(option => ({
              ...option,
              sections: option.sections.map(section => ({
                ...section,
                media: section.media?.id === media.id ? { ...section.media, ...media } : section.media,
              })),
            })),
          }
        : {
            sections: segment.sections.map(section => ({
              ...section,
              media: section.media?.id === media.id ? { ...section.media, ...media } : section.media,
            })),
          }),
    })),
  }),

  updateFlexOptionSelectedStatus: (timeline, flexSegmentId, segmentId, isEnabled) => ({
    ...timeline,
    segments: updateItemInList(timeline.segments, 'segmentId', flexSegmentId, segment => ({
      ...segment,
      options: updateItemInList(segment.options, 'segmentId', segmentId, option => ({
        ...option,
        enabled: isEnabled,
      })),
    })),
  }),

  updateViewPreferenceForTimeline: (timeline, viewPreference) => ({ ...timeline, viewPreference }),

  clearTimelineArchiveId: timeline => ({ ...timeline, archiveId: null }),

  changeOrderOfSegment: (timeline, oldIndex, newIndex) => {
    const originalOrderedSegments = [...timeline.segments];

    const reorderedSegments = [...timeline.segments];
    reorderedSegments.splice(newIndex, 0, reorderedSegments.splice(oldIndex, 1)[0]);

    return [reorderedSegments, originalOrderedSegments];
  },

  getPublishedAdminSessionTimeline: (originalTimelineId, timelineCustomizationId) =>
    cacheableRequest(
      `/v1/org/curriculum/timelines/${originalTimelineId}?timelineCustomizationId=${timelineCustomizationId}`
    ).then(response => !!response?.data && transformSessionTimeline(response.data)),

  getPublishedSessionTimelines: sessionId =>
    cacheableRequest(`/v1/org/curriculum/timelines?sessionId=${sessionId}`).then(
      response => response?.data?.map(transformSessionTimeline) ?? []
    ),

  customizeSegmentDuration: (timelineId, originalTimelineId, orgId, segmentId, duration, initiatingUserId) => {
    clearTimelineCache(timelineId);
    return submitCommand(
      timelineId,
      { id: timelineId, timelineId: originalTimelineId, orgId, segmentId, duration, initiatingUserId },
      'TcSetSegmentDuration',
      'TcSegmentDurationSet',
      'TcCommandFailed'
    );
  },

  createNote: (timelineId, originalTimelineId, orgId, note, initiatingUserId) => {
    clearTimelineCache(timelineId);
    return submitCommand(
      timelineId,
      { id: timelineId, timelineId: originalTimelineId, orgId, note, initiatingUserId },
      'TcCreateNote',
      'TcNoteCreated',
      'TcCommandFailed'
    );
  },

  updateNote: (timelineId, originalTimelineId, orgId, { id: noteId, title, body }, initiatingUserId) => {
    clearTimelineCache(timelineId);
    return submitCommand(
      timelineId,
      { id: timelineId, timelineId: originalTimelineId, orgId, noteId, title, body, initiatingUserId },
      'TcSetNoteContent',
      'TcNoteContentSet',
      'TcCommandFailed'
    );
  },

  deleteNote: (timelineId, originalTimelineId, noteId, initiatingUserId) => {
    clearTimelineCache(timelineId);
    return submitCommand(
      timelineId,
      { id: timelineId, timelineId: originalTimelineId, noteId, initiatingUserId },
      'TcDeleteNote',
      'TcNoteDeleted',
      'TcCommandFailed'
    );
  },

  toggleSegment: (timelineId, originalTimelineId, segmentId, isEnabled, initiatingUserId) => {
    clearTimelineCache(timelineId);
    return submitCommand(
      timelineId,
      { id: timelineId, timelineId: originalTimelineId, segmentId, enabled: isEnabled, initiatingUserId },
      'TcToggleSegment',
      'TcSegmentToggled',
      'TcCommandFailed'
    );
  },

  customizeSegmentOrder: (timelineId, originalTimelineId, orgId, segmentIds, initiatingUserId) => {
    clearTimelineCache(timelineId);
    return submitCommand(
      timelineId,
      { id: timelineId, timelineId: originalTimelineId, orgId, segmentIds, initiatingUserId },
      'TcOrderSegments',
      'TcSegmentsOrdered',
      'TcCommandFailed'
    );
  },

  getTeacherSessionAndTimeline: (issueId, sessionId, timelineId, timelineCustomizationId) =>
    cacheableRequest(`/v1/org/curriculum/${issueId}/${sessionId}`).then(response => {
      if (!response || !response.data) return Promise.reject('Invalid response');

      const { timelines = [], ...session } = response.data;
      const timeline = timelines.find(t => {
        if (!timelineCustomizationId) {
          return t._id === timelineId;
        }

        return t._id === timelineId && t.timelineCustomizationId === timelineCustomizationId;
      });

      if (!timeline) return Promise.reject('No timeline found');

      return [transformSession(session), transformSessionTimeline(timeline)];
    }),

  getArchiveUrl: (archiveId, archiveName) =>
    cacheableRequest(
      `/v1/org/curriculum/timeline-archives/${archiveId}?fileName=${removeRestrictedCharactersFromArchiveName(
        archiveName
      )}`
    ).then(response => (response?.data?.url ? response.data.url : Promise.reject('Invalid URL response'))),

  setLeaderViewPreference: (curriculumId, leader) => {
    clearAdminAndTeacherTimelineCache();
    return apiClient.post('/v1/org/curriculum/timelines/leader/view-preference', { curriculumId, leader });
  },

  matchesLeaderViewPreference: (viewPreference, segmentLeader) =>
    !viewPreference || viewPreference === 'all' || segmentLeader === 'all' || viewPreference === segmentLeader,

  getRecentlyUsedSectionMedia: curriculumId =>
    apiClient
      .get(`/author/curriculum/timelines-recent-media?curriculumId=${curriculumId}`)
      .then(response => response.data),

  getTeacherSessionTimelineUrl: (curriculum, session, timeline) =>
    timeline.originalTimelineId
      ? `/curriculum/${curriculum.brandCode}/${curriculum.name}/session/${session.sessionId}/issue/${session.issueId}/timeline/${timeline.originalTimelineId}/${timeline.id}`
      : `/curriculum/${curriculum.brandCode}/${curriculum.name}/session/${session.sessionId}/issue/${session.issueId}/timeline/${timeline.id}`,

  createCustomizedTimeline: (name, originalTimelineId, orgId, initiatingUserId) => {
    clearAdminAndTeacherTimelineCache();
    return generateUUID(id =>
      submitCommand(
        id,
        {
          id,
          timelineId: originalTimelineId,
          orgId: { id: orgId },
          name,
          initiatingUserId,
        },
        'TcCreateCustomization',
        'TcCustomizationCreated',
        'TcCommandFailed'
      )
    );
  },

  copyCustomizedTimeline: (name, originalTimelineId, customizedTimelineId, orgId, initiatingUserId) => {
    clearAdminAndTeacherTimelineCache();
    return generateUUID(id =>
      submitCommand(
        id,
        {
          id,
          timelineId: originalTimelineId,
          orgId: { id: orgId },
          name,
          sourceCustomizationId: customizedTimelineId,
          initiatingUserId,
        },
        'TcCreateFromExistingCustomization',
        'TcCreatedFromExistingCustomization',
        'TcCommandFailed'
      )
    );
  },

  renameCustomizedTimeline: (name, originalTimelineId, customizedTimelineId, orgId, initiatingUserId) => {
    clearAdminAndTeacherTimelineCache();
    return submitCommand(
      customizedTimelineId,
      {
        id: customizedTimelineId,
        timelineId: originalTimelineId,
        orgId: { id: orgId },
        name,
        initiatingUserId,
      },
      'TcSetName',
      'TcNameSet',
      'TcCommandFailed'
    );
  },

  deleteCustomizedTimeline: (originalTimelineId, customizedTimelineId, orgId, initiatingUserId) => {
    clearAdminAndTeacherTimelineCache();
    return submitCommand(
      customizedTimelineId,
      {
        id: customizedTimelineId,
        timelineId: originalTimelineId,
        orgId: {
          id: orgId,
        },
        initiatingUserId,
      },
      'TcDeleteCustomization',
      'TcCustomizationDeleted',
      'TcCommandFailed'
    );
  },

  createDefaultTimeline: timelineId => transformSessionTimeline({ _id: timelineId }),
};

export default sessionTimelineService;
