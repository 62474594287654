import React from 'react';
import { useSteps, useCompleteStep } from './StepsContext';
import { FullWidthButton } from '../components/Buttons';

export default function LinkStep() {
  const { completeStep } = useCompleteStep();
  const [{ currentStep }] = useSteps();

  const openLink = () => {
    window.open(currentStep.task.linkUrl);
    completeStep();
  };

  return (
    <p>
      <FullWidthButton data-qa-hook="linkButton" onClick={openLink}>
        <i className="fas fa-external-link-alt"></i> Go to Link
      </FullWidthButton>
    </p>
  );
}
