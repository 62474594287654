import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';

const DialogContainer = styled.div`
  * {
    box-sizing: border-box;
  }
  h1 {
    margin: 0 auto;
    max-width: 240px;
  }
  h3 {
    font-size: ${Type.Scale._5};
    font-weight: ${Type.Weight.bold};
    margin: ${Grid._7} auto;
    color: ${Color.Gray._90};
  }
  background: white;
  padding: ${Grid._7} ${Grid._4} ${Grid._10};
  text-align: center;
  border-radius: 0 0 ${Border.radius} ${Border.radius};
  box-shadow: ${Shadow.regular};
  border-top: ${Grid._2} solid ${Color.Orange._50};
  width: 100%;
  max-width: 700px;
  margin: auto;
  color: ${Color.Gray._90};
  font-size: ${Type.Scale._4};
  line-height: ${Type.Leading.tall};
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding-left: ${Grid._14};
    padding-right: ${Grid._14};
  }
`;

export default DialogContainer;
