import styled from 'styled-components';
import { Grid, Color, Type } from '../StyleGuide';

export const LicenseDetailsContainer = styled.div`
  h4 {
    margin-bottom: 0;
    font-size: ${Type.Scale._3};
    font-weight: ${Type.Weight.semibold};
  }

  .curriculum-element--curriculum-image,
  .curriculum-element--training-image {
    width: 158px;
    height: 77px;
  }
`;

export const ModalBodyHeader = styled.h4`
  margin-bottom: ${Grid._5};
`;

export const SubscriptionModalList = styled.ul`
  margin-top: ${Grid._4};
  padding-left: ${Grid._4};
  color: ${Color.dark};

  li {
    margin-bottom: ${Grid._3};
  }
`;
