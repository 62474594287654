import React from 'react';
import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';
import { Grid } from '../../StyleGuide';
import {
  TimelineContainer,
  TimelineVerticalSpacer,
  TimelineLabel,
  SegmentContainer,
  SegmentHeader,
  SegmentHeaderContent,
  SegmentLeader,
  SectionHeader,
  AddSegmentPlaceholder,
  EditSegmentToolbar,
  AddFlexOptionPlaceholder,
  AddSectionPlaceholder,
  SectionContent,
  SectionMedia,
  StickySectionMediaItem,
  SectionBody,
} from '../../components/SessionTimelineContentElements';
import SectionMediaThumbnail from './SectionMediaThumbnail';
import ConditionalFlexOptionWrapper from './ConditionalFlexOptionWrapper';
import AddSegmentModal from './AddSegmentModal';
import AddSectionModal from './AddSectionModal';
import AddSupplyModal from './AddSupplyModal';
import BodySectionRichTextEditor from './BodySectionRichTextEditor';
import ScriptSectionEditor from './ScriptSectionEditor';
import SuppliesSectionEditor from './SuppliesSectionEditor';
import TipSectionEditor from './TipSectionEditor';
import VideoSectionEditor from './VideoSectionEditor';
import alertService from '../../services/AlertService';
import sessionTimelineService, { renderSegments } from '../../services/sessionTimelineService';
import { useUser } from '../../authentication';
import {
  ButtonGroup,
  SmallOutlineButton,
  SmallRoundedIconButton,
  SmallTertiaryRoundedIconButton,
} from '../../components/Buttons';
import ConfirmationModal from '../../components/ConfirmationModal';
import ErrorMessage from '../../components/ErrorMessage';
import useModal from '../../hooks/useModal';
import { handleError } from '../../utils/apiUtils';
import useEventBusSubscription from '../../hooks/useEventBusSubscription';
import useSessionTimeline from '../../hooks/useSessionTimeline';
import FlexBuilderSegment from './FlexBuilderSegment';
import windowService from '../../services/windowService';

const RemoveSegmentModal = ({ segment, ...props }) => {
  const entityType = segment.flexSegmentId ? 'Flex Option' : 'Segment';

  return (
    <ConfirmationModal
      buttonActionText="Remove"
      buttonType="danger"
      title={`Remove ${entityType}`}
      prompt={<span>Are you sure you want to remove this {entityType.toLowerCase()}?</span>}
      {...props}
    >
      <div style={{ padding: `${Grid._5} 0` }}>
        <SegmentHeaderContent name={segment.name} group={segment.group} duration={segment.duration} />
      </div>
      <ErrorMessage>
        This will also remove all sections and any media related to this {entityType.toLowerCase()}
      </ErrorMessage>
    </ConfirmationModal>
  );
};

const RemoveSectionModal = props => (
  <ConfirmationModal
    buttonActionText="Remove"
    buttonType="danger"
    title="Remove Section"
    prompt={<span>Are you sure you want to remove this section?</span>}
    {...props}
  >
    <SectionHeader type={props.sectionType} />
  </ConfirmationModal>
);

const RemoveSectionMediaModal = props => (
  <ConfirmationModal
    buttonActionText="Remove"
    buttonType="danger"
    title="Remove Section Media"
    prompt={<span>Are you sure you want to remove the media from this section?</span>}
    {...props}
  />
);

const RemoveSupplyModal = props => (
  <ConfirmationModal
    buttonActionText="Remove"
    buttonType="danger"
    title="Remove Supply"
    prompt={<span>Are you sure you want to remove this supply?</span>}
    subtext={props.supplyName}
    {...props}
  ></ConfirmationModal>
);

const buildFlexOptionFromFlexSegment = ({ group, leader, duration, segmentId: flexSegmentId }) => ({
  group,
  leader,
  duration,
  flexSegmentId,
});

const SessionTimelineBuilderContent = () => {
  const user = useUser();
  const { timeline, setTimeline } = useSessionTimeline();

  const updateMediaFromEvent = (id, url) =>
    setTimeline(previousTimeline => sessionTimelineService.updateMediaInTimeline(previousTimeline, { id, url }));

  useEventBusSubscription('CtMediaThumbnailGenerated', ({ mediaId, url }) => updateMediaFromEvent(mediaId, url));
  useEventBusSubscription('VideoIsViewable', ({ id, poster }) => updateMediaFromEvent(id, { thumbnail: poster }));

  const scrollToSegment = (id, additionalOffset = 0) =>
    scroller.scrollTo(`contents-${id}`, { offset: -60 - additionalOffset });

  const isFlexActivity = activityType => activityType === 'Flex';

  const getFlexSegmentHeaderHeight = flexSegmentId =>
    document.querySelector(`#contents-${flexSegmentId} header`)?.offsetHeight || 0;

  const getSegmentToolbarLabel = segment => {
    if (!segment.isFlex) return null;

    if (!segment.options?.length) return <p>No Flex Options Added</p>;

    return (
      <p>
        {segment.options.length} Flex Option{segment.options.length > 1 ? 's' : ''}
      </p>
    );
  };

  const handleErrorAndCloseModal = promise =>
    promise
      .catch(error => {
        handleError(error);
      })
      .finally(dismissModal);

  const addSegmentToSessionTimeline = atIndex => values => {
    if (isFlexActivity(values.activity)) return addFlexSegmentToSessionTimeline(atIndex, values);

    handleErrorAndCloseModal(
      sessionTimelineService.createSegment(timeline.id, values, atIndex, user.userId).then(segment => {
        setTimeline(previousTimeline =>
          sessionTimelineService.insertSegmentIntoTimeline(previousTimeline, null, segment, atIndex)
        );
        alertService.show('Segment Added');
        scrollToSegment(segment.segmentId);
      })
    );
  };

  const addFlexSegmentToSessionTimeline = (atIndex, values) => {
    handleErrorAndCloseModal(
      sessionTimelineService.createFlexSegment(timeline.id, values, atIndex, user.userId).then(segment => {
        setTimeline(previousTimeline =>
          sessionTimelineService.insertSegmentIntoTimeline(previousTimeline, null, segment, atIndex)
        );
        alertService.show('Flex Segment Added');
        scrollToSegment(segment.segmentId);
      })
    );
  };

  const addFlexOptionToFlexSegment = (atIndex, segmentId) => option => {
    handleErrorAndCloseModal(
      sessionTimelineService.createFlexOption(timeline.id, option, atIndex, user.userId).then(responseOption => {
        setTimeline(previousTimeline =>
          sessionTimelineService.insertSegmentIntoTimeline(previousTimeline, segmentId, responseOption, atIndex)
        );
        scrollToSegment(responseOption.segmentId, getFlexSegmentHeaderHeight(segmentId));
        alertService.show('Flex Option Added');
      })
    );
  };

  const editTimelineSegment = segment => values => {
    const { segmentId, flexSegmentId } = segment;

    const showSuccessBanner = () => alertService.show(`${flexSegmentId ? 'Flex Option' : 'Segment'} Saved`);

    if (!values) {
      showSuccessBanner();
      dismissModal();
      return;
    }

    handleErrorAndCloseModal(
      sessionTimelineService.editSegment(timeline.id, values, user.userId).then(response => {
        setTimeline(previousTimeline =>
          flexSegmentId
            ? sessionTimelineService.updateFlexOptionInTimeline(previousTimeline, flexSegmentId, segmentId, response)
            : sessionTimelineService.updateSegmentInTimeline(previousTimeline, segmentId, response)
        );

        showSuccessBanner();
      })
    );
  };

  const removeSegment = segment => () => {
    const { segmentId, flexSegmentId } = segment;

    handleErrorAndCloseModal(
      sessionTimelineService.deleteSegment(timeline.id, segmentId, user.userId).then(() => {
        setTimeline(previousTimeline =>
          sessionTimelineService.removeSegmentFromTimeline(previousTimeline, flexSegmentId, segmentId)
        );
        alertService.show(`${flexSegmentId ? 'Flex Option' : 'Segment'} Removed`);
      })
    );
  };

  const addSectionToSegment = (atIndex, segment) => sectionType => {
    const { segmentId, flexSegmentId } = segment;

    handleErrorAndCloseModal(
      sessionTimelineService.createSection(timeline.id, segmentId, sectionType, atIndex, user.userId).then(section => {
        setTimeline(previousTimeline =>
          sessionTimelineService.insertSectionIntoTimeline(previousTimeline, flexSegmentId, segmentId, section, atIndex)
        );

        alertService.show('Section Added');
      })
    );
  };

  const updateSectionContent = section => content => {
    Object.entries(content).forEach(([key, value]) => (section[key] = value));

    sessionTimelineService
      .updateSectionContent(timeline.id, section.id, content, user.userId)
      .then(() => {
        alertService.show('Leader Guide Autosaved');
      })
      .catch(error => {
        console.error(error);
      });
  };

  const removeSection = (segment, sectionId) => () => {
    const { segmentId, flexSegmentId } = segment;

    handleErrorAndCloseModal(
      sessionTimelineService.deleteSection(timeline.id, sectionId, user.userId).then(() => {
        setTimeline(previousTimeline =>
          sessionTimelineService.removeSectionFromTimeline(previousTimeline, flexSegmentId, segmentId, sectionId)
        );
        alertService.show('Section Removed');
      })
    );
  };

  const removeMediaFromSection = (segment, sectionId) => () => {
    const { segmentId, flexSegmentId } = segment;

    handleErrorAndCloseModal(
      sessionTimelineService.updateSectionMedia(timeline.id, sectionId, null, user.userId).then(() => {
        setTimeline(previousTimeline =>
          sessionTimelineService.updateSectionContentInTimeline(previousTimeline, flexSegmentId, segmentId, sectionId, {
            media: undefined,
          })
        );
        alertService.show('Section Media Removed');
      })
    );
  };

  const updateSupplySection = (segment, sectionId, supplies, type) => {
    const { segmentId, flexSegmentId } = segment;

    handleErrorAndCloseModal(
      sessionTimelineService.updateSectionContent(timeline.id, sectionId, { supplies }, user.userId).then(response => {
        setTimeline(previousTimeline =>
          sessionTimelineService.updateSectionContentInTimeline(previousTimeline, flexSegmentId, segmentId, sectionId, {
            supplies: response.supplies,
          })
        );
        alertService.show(`Supply ${type}`);
      })
    );
  };

  const reorderSupplies = ({ segment, section, oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }

    const { segmentId, flexSegmentId } = segment;
    const originalOrderedSupplies = [...section.supplies];
    const reorderedSupplies = [...section.supplies];
    reorderedSupplies.splice(newIndex, 0, reorderedSupplies.splice(oldIndex, 1)[0]);

    setTimeline(previousTimeline =>
      sessionTimelineService.updateSectionContentInTimeline(previousTimeline, flexSegmentId, segmentId, section.id, {
        supplies: reorderedSupplies,
      })
    );

    sessionTimelineService
      .updateSectionContent(timeline.id, section.id, { supplies: reorderedSupplies }, user.userId)
      .then(response => {
        alertService.show('Supplies Updated');
      })
      .catch(error => {
        handleError(error);
        setTimeline(previousTimeline =>
          sessionTimelineService.updateSectionContentInTimeline(
            previousTimeline,
            flexSegmentId,
            segmentId,
            section.id,
            { supplies: originalOrderedSupplies }
          )
        );
      });
  };

  const addSupplyToSection = (segment, section) => supply =>
    updateSupplySection(segment, section.id, [...section.supplies, supply], 'Added');

  const editSectionSupply = (segment, section, index) => supply => {
    const newSupplies = [...section.supplies];
    newSupplies.splice(index, 1, supply);
    updateSupplySection(segment, section.id, newSupplies, 'Saved');
  };

  const removeSupplyFromSection = (segment, section, supply) => () =>
    updateSupplySection(
      segment,
      section.id,
      section.supplies.filter(s => s !== supply),
      'Removed'
    );

  const redirectToAddSectionMedia = section =>
    windowService.redirectTo(
      `${windowService.getCurrentRoute()}/add-media/${section.id}${section._type === 'video' ? '?type=video' : ''}`
    );

  const [modal, openModal, dismissModal] = useModal((type, payload, dismissModal) => {
    switch (type) {
      case 'addSegment':
        return (
          <AddSegmentModal
            defaultGroup={payload.segment?.group}
            defaultLeader={payload.segment?.leader}
            onSubmit={addSegmentToSessionTimeline(payload.index)}
            handleDismiss={dismissModal}
          />
        );
      case 'addFlexOption':
        return (
          <AddSegmentModal
            isFlexOption
            atIndex={payload.index}
            segment={payload.option}
            onSubmit={addFlexOptionToFlexSegment(payload.index, payload.segmentId)}
            handleDismiss={dismissModal}
          />
        );
      case 'editSegment':
        return (
          <AddSegmentModal
            isFlexOption={!!payload.segment.flexSegmentId}
            atIndex={payload.index}
            segment={payload.segment}
            onSubmit={editTimelineSegment(payload.segment)}
            handleDismiss={dismissModal}
          />
        );
      case 'removeSegment':
        return (
          <RemoveSegmentModal
            segment={payload.segment}
            handleSubmit={removeSegment(payload.segment)}
            handleDismiss={dismissModal}
          />
        );
      case 'addSection':
        return (
          <AddSectionModal
            addSectionToSegment={addSectionToSegment(payload.index, payload.segment)}
            handleDismiss={dismissModal}
          />
        );
      case 'removeSection':
        return (
          <RemoveSectionModal
            sectionType={payload.section.type}
            handleSubmit={removeSection(payload.segment, payload.section.id)}
            handleDismiss={dismissModal}
          />
        );
      case 'removeSectionMedia':
        return (
          <RemoveSectionMediaModal
            handleSubmit={removeMediaFromSection(payload.segment, payload.sectionId)}
            handleDismiss={dismissModal}
          />
        );
      case 'addSupply':
        return (
          <AddSupplyModal
            onSubmit={addSupplyToSection(payload.segment, payload.section)}
            handleDismiss={dismissModal}
          />
        );
      case 'editSupply':
        return (
          <AddSupplyModal
            onSubmit={editSectionSupply(payload.segment, payload.section, payload.index)}
            supply={payload.supply}
            handleDismiss={dismissModal}
          />
        );
      case 'removeSupply':
        return (
          <RemoveSupplyModal
            supplyName={payload.supply.name}
            handleSubmit={removeSupplyFromSection(payload.segment, payload.section, payload.supply)}
            handleDismiss={dismissModal}
          />
        );
      default:
        return null;
    }
  });

  return (
    <>
      <TimelineContainer>
        <TimelineVerticalSpacer spaces="3" />
        <AddSegmentPlaceholder showModal={() => openModal('addSegment', { index: 0 })} />
        <TimelineVerticalSpacer spaces="3" />

        {!!timeline.segments.length &&
          renderSegments(timeline, (segment, durationRemaining, i) => (
            <div
              key={segment.segmentId}
              data-qa-hook={`segmentContainer-${i}`}
              id={`contents-${i}`}
              name={`contents-${i}`}
            >
              <SegmentContainer id={`contents-${segment.segmentId}`} name={`contents-${segment.segmentId}`}>
                <SegmentHeader name={segment.name} group={segment.group} duration={segment.duration} />
                <TimelineLabel
                  totalDuration={timeline.duration}
                  remainingDuration={durationRemaining}
                  currentDuration={segment.duration}
                />
                <EditSegmentToolbar
                  leftContent={getSegmentToolbarLabel(segment)}
                  rightContent={
                    <ButtonGroup>
                      <SmallTertiaryRoundedIconButton
                        data-qa-hook="editSegment"
                        onClick={() => openModal('editSegment', { index: i, segment })}
                      >
                        <i className="fas fa-pen"></i>
                      </SmallTertiaryRoundedIconButton>
                      <SmallTertiaryRoundedIconButton
                        data-qa-hook="removeSegment"
                        onClick={() => openModal('removeSegment', { segment })}
                      >
                        <i className="far fa-trash-alt"></i>
                      </SmallTertiaryRoundedIconButton>
                    </ButtonGroup>
                  }
                />
                <SegmentLeader leader={segment.leader} />

                {segment.isFlex && (
                  <>
                    <TimelineVerticalSpacer spaces="1" />
                    <AddFlexOptionPlaceholder
                      showModal={() =>
                        openModal('addFlexOption', {
                          index: 0,
                          segmentId: segment.segmentId,
                          option: buildFlexOptionFromFlexSegment(segment),
                        })
                      }
                    />
                    <TimelineVerticalSpacer spaces="1" />
                  </>
                )}

                <ConditionalFlexOptionWrapper
                  FlexOptionComponent={FlexBuilderSegment}
                  segment={segment}
                  openModal={openModal}
                  timelineId={timeline.id}
                >
                  {({ timelineClassName, segment: segmentOrOption, sections }) => (
                    <>
                      <TimelineVerticalSpacer timelineClassName={timelineClassName} spaces="1" />
                      <AddSectionPlaceholder
                        timelineClassName={timelineClassName}
                        showModal={() => openModal('addSection', { index: 0, segment: segmentOrOption })}
                      />

                      {sections?.map((section, sectionIndex) => (
                        <React.Fragment key={section.id}>
                          <SectionContent data-qa-hook={`sectionContainer-${sectionIndex}`} verticalSpaces={6}>
                            <SectionMedia className={segment.isFlex && 'timeline-highlight-mobile-only'}>
                              <StickySectionMediaItem
                                className={segment.isFlex && 'flex-segment-sticky-section-media-item'}
                              >
                                <SectionMediaThumbnail
                                  media={section.media}
                                  onAddMedia={() => redirectToAddSectionMedia(section)}
                                  buttons={
                                    <SmallRoundedIconButton
                                      data-qa-hook="removeSectionMediaButton"
                                      className="transparent"
                                      onClick={() =>
                                        openModal('removeSectionMedia', {
                                          segment: segmentOrOption,
                                          sectionId: section.id,
                                        })
                                      }
                                    >
                                      <i className="far fa-trash-alt"></i>
                                    </SmallRoundedIconButton>
                                  }
                                />
                              </StickySectionMediaItem>
                            </SectionMedia>
                            <SectionBody className={timelineClassName}>
                              <SectionHeader
                                type={section.type}
                                onRemove={() => openModal('removeSection', { segment: segmentOrOption, section })}
                              >
                                {section.type === 'supplies' && (
                                  <SmallOutlineButton
                                    data-qa-hook="addSupply"
                                    onClick={() => openModal('addSupply', { segment: segmentOrOption, section })}
                                  >
                                    Add Supply
                                  </SmallOutlineButton>
                                )}
                              </SectionHeader>
                              {section.type === 'body' && (
                                <BodySectionRichTextEditor
                                  content={section.text}
                                  onSave={updateSectionContent(section)}
                                />
                              )}
                              {section.type === 'script' && (
                                <ScriptSectionEditor
                                  leader={segmentOrOption.leader}
                                  content={section.text}
                                  onSave={updateSectionContent(section)}
                                />
                              )}
                              {section.type === 'supplies' && (
                                <SuppliesSectionEditor
                                  supplies={section.supplies}
                                  onEdit={(supply, index) =>
                                    openModal('editSupply', { segment: segmentOrOption, section, supply, index })
                                  }
                                  onRemove={supply =>
                                    openModal('removeSupply', { segment: segmentOrOption, section, supply })
                                  }
                                  onReorder={({ oldIndex, newIndex }) =>
                                    reorderSupplies({ segment: segmentOrOption, section, oldIndex, newIndex })
                                  }
                                />
                              )}
                              {section.type === 'tip' && (
                                <TipSectionEditor
                                  type={section.tipType}
                                  content={section.text}
                                  onSave={updateSectionContent(section)}
                                />
                              )}
                              {section.type === 'video' && (
                                <VideoSectionEditor content={section.name} onSave={updateSectionContent(section)} />
                              )}
                            </SectionBody>
                          </SectionContent>

                          <TimelineVerticalSpacer timelineClassName={timelineClassName} spaces="1" />
                          <AddSectionPlaceholder
                            timelineClassName={timelineClassName}
                            showModal={() =>
                              openModal('addSection', { index: sectionIndex + 1, segment: segmentOrOption })
                            }
                          />
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </ConditionalFlexOptionWrapper>

                {!!segment.options?.length && (
                  <>
                    <TimelineVerticalSpacer spaces="1" />
                    <AddFlexOptionPlaceholder
                      showModal={() =>
                        openModal('addFlexOption', {
                          index: segment.options.length,
                          segmentId: segment.segmentId,
                          option: buildFlexOptionFromFlexSegment(segment),
                        })
                      }
                    />
                  </>
                )}

                <TimelineVerticalSpacer spaces="3" />
                <AddSegmentPlaceholder showModal={() => openModal('addSegment', { index: i + 1, segment })} />
                <TimelineVerticalSpacer spaces="3" />
              </SegmentContainer>
            </div>
          ))}
      </TimelineContainer>
      {modal}
    </>
  );
};

SessionTimelineBuilderContent.propTypes = {};

export default SessionTimelineBuilderContent;
