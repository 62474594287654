import React, { useContext } from 'react';
import CurriculumContext from '../contexts/CurriculumContext';

declare global {
  interface Window {
    featureToggles: {
      get: (feature: string) => boolean;
    };
  }
}

type BrandCode = 'BSFL' | 'ETB' | 'TGP' | 'YOU' | 'HYFI' | 'HYFICHRISTMAS' | 'ETB2025' | 'TGP5';

type AgeCategory = 'Preschool' | 'Kids' | 'Students' | 'Adult';

// Factory method for creating a curriculum object.
// Should be used instead of useCurriculum when not using CurriculumProvider.
export const createCurriculum = (brandCode: BrandCode, ageCategory?: AgeCategory) => {
  if (!brandCode) return null;

  const code = brandCode?.toUpperCase() as BrandCode;
  const brand = brands[code];

  if (!brand) {
    console.error(`Invalid curriculum brand code: ${code}`);
    return null;
  }

  const getAgeProperty = (property: string) =>
    ageCategory && brand[property] ? brand[property][ageCategory.toLowerCase()] : null;

  return {
    brand: {
      ...brand,
      code,
      banner: getAgeProperty('banners'),
      atHomeBanner: getAgeProperty('atHomeBanners'),
      printOptions: getAgeProperty('printOptions'),
      ageInfo: getAgeProperty(ageCategory?.toLowerCase() || ''),
      isPreview: !!brand.previewOptions,
    },
    ageCategory,
  };
};

export const useCurriculum = () => useContext(CurriculumContext) || null;
export default useCurriculum;

type Restrictions = Record<'distribute' | 'reschedule', boolean>;

class PreviewOptions {
  constructor(public restrictions: Restrictions, public accentColor: string) {}
}

const brands: Record<BrandCode, any> = {
  BSFL: {
    name: 'Bible Studies for Life',
    theme: 'light',
    description: 'Intentional, biblical discipleship rooted in everyday life',
    imgSrc: 'img/BSFL.png',
    background: 'linear-gradient(180deg, #4CB37C 0%, #69BEE2 100%)',
    banners: {
      preschool: 'img/BSFL-kids-banner.jpg',
      kids: 'img/BSFL-kids-banner.jpg',
      students: 'img/BSFL-students-banner.jpg',
      adult: 'img/BSFL-adult-banner.jpg',
    },
    atHomeBanners: {
      preschool: {
        src: 'img/AtHome_BSFL.png',
        url: '/catalog/compilation/acc2ff97-0b89-4e90-e89e-fa2fe214792a',
      },
      kids: {
        src: 'img/AtHome_BSFL.png',
        url: '/catalog/compilation/acc2ff97-0b89-4e90-e89e-fa2fe214792a',
      },
    },
    kids: [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for 1st-6th Grade weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Leader Guide PDFs',
          'Kids Activity Page PDFs',
          'Special Buddies Resources',
          'Visual Aids PDFs (Games, Teaching Pictures, Maps, etc.)',
          'Additional Printables',
          'Coloring Page PDFs',
          'Bible Story Videos',
          'Life Action Videos',
          'Missions Video',
          'Bible Story Time Videos (Special Buddies)',
          'Session-related Songs',
          'PowerPoint Presentation',
          'At Home Resources for Family Worship',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS all you need for an extra hour:',
        contents: [
          'Worship Guide PDFs & RTFs',
          'Huddle-ups',
          'Optional Bible Stories',
          'Decorating Ideas',
          'Worship Videos and Audio',
          'Slides',
          'Lead Sheets',
          'Countdown Videos',
        ],
      },
    ],
    preschool: [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for Babies - Kindergarten weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Leader Guide PDFs',
          'Preschool Activity Page PDFs',
          'Bonus Teaching Hour PDFs',
          'Visual Aids PDFs (Games, Teaching Pictures, Maps, etc.)',
          'Additional Printables',
          'Coloring Page PDFs',
          'Bible Story Time Videos',
          'Mission Videos',
          'Session-related Songs',
          'PowerPoint Presentations',
          'At Home Resources for Family Worship',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS all you need for an extra hour:',
        contents: [
          'Worship Guide PDFs & RTFs',
          'Huddle-ups',
          'Decorating Ideas',
          'Worship Videos and Audio',
          'Slides',
          'Lead Sheets',
          'Countdown Videos',
        ],
      },
    ],
    students: [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: ['Leader Guide PDFs', 'Daily Discipleship Guide PDFs', 'CSB, ESV, and KJV Options'],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS:',
        contents: [
          'Middle School Leader Guide PDFs',
          'Middle School Daily Discipleship Guide PDFs',
          'One Sheet Printables',
          'Family Connection Printables',
          'Midweek Plans',
          'Visual Aids (Poster, Social Media Share Squares, etc.)',
          'PowerPoint Templates',
        ],
      },
    ],
    adult: [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Leader Guide PDFs for Adults and Senior Adults',
          'Personal Study Guide PDFs for Adults and Senior Adults',
          'Daily Discipleship Guide Leader and Learner PDFs',
          'CSB and KJV Options',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS:',
        contents: [
          'NIV options for Basic materials',
          'Leader Guide Word Documents',
          'Visual Aids (Charts, Maps, etc.)',
          'Advanced Bible Study and Advanced Bible Study Teacher',
          'Promo Videos',
          'Social Media Messages',
          'Family Connection Printables',
        ],
      },
    ],
    'preschool and kids': [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Leader Guide PDFs',
          'Kids Activity Page PDFs',
          'Special Buddies Resources',
          'Visual Aids PDFs (Games, Teaching Pictures, Maps, etc.)',
          'Additional Printables',
          'Coloring Page PDFs',
          'Bible Story Videos',
          'Life Action Videos',
          'Missions Video',
          'Bible Story Time Videos (Special Buddies)',
          'Session-related Songs',
          'PowerPoint Presentation',
          'At Home Resources for Family Worship',
          'Preschool Activity Page PDFs',
          'Bonus Teaching Hour PDFs',
          'Bible Story Time Videos',
          'Mission Videos',
          'PowerPoint Presentations',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS all you need for an extra hour:',
        contents: [
          'Worship Guide PDFs & RTFs',
          'Huddle-ups',
          'Optional Bible Stories',
          'Decorating Ideas',
          'Worship Videos and Audio',
          'Slides',
          'Lead Sheets',
          'Countdown Videos',
        ],
      },
    ],
  },
  ETB: {
    name: 'Explore the Bible',
    description: 'An expository, book-by-book \nstudy of Scripture',
    imgSrc: 'img/ETB.png',
    background: 'linear-gradient(180deg, #CECECE 0%, #EAEAEA 100%)',
    banners: {
      preschool: 'img/ETB-kids-banner.jpg',
      kids: 'img/ETB-kids-banner.jpg',
      students: 'img/ETB-students-banner.jpg',
      adult: 'img/ETB-adult-banner.jpg',
    },
    atHomeBanners: {
      preschool: {
        src: 'img/AtHome_ETB.png',
        url: '/catalog/compilation/97c2caa9-6c2a-48ac-efdc-633e7fd3dd87',
      },
      kids: {
        src: 'img/AtHome_ETB.png',
        url: '/catalog/compilation/97c2caa9-6c2a-48ac-efdc-633e7fd3dd87',
      },
    },
    kids: [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for 1st-6th Grade weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Leader Guide PDFs',
          'Explorer Guide PDFs',
          'Explore the Bible on Location Videos',
          'Music Videos and Audio',
          'PowerPoint Presentations',
          'Visual Aids (Charts, Maps, etc.)',
          'At Home Resources for Family Worship',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS all you need for an extra hour:',
        contents: ['Worship Guide PDFs', 'Worship Videos and Audio', 'Music and Missions Videos', 'Slides'],
      },
    ],
    preschool: [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for Babies - Preschool weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Leader Guide PDFs',
          'Diaper Bag Card PDFs',
          'Activity Page PDFs',
          'Music Audio',
          'PowerPoint Presentations',
          'Visual Aids (Teaching Pictures, Books, etc.)',
          'At Home Resources for Family Worship',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS all you need for an extra hour:',
        contents: ['Worship Guide PDFs', 'Music and Missions Videos'],
      },
    ],
    students: [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Training Documents',
          'Leader Guide PDFs',
          'Daily Discipleship Guide PDFs',
          'CSB, ESV, KJV, and NIV Options',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS:',
        contents: [
          'Middle School Leader Guide PDFs (CSB)',
          'Middle School Daily Discipleship Guide PDFs (CSB)',
          'Daily Bible Reading Plan',
          'Animated Videos',
          'One Sheet Printables',
          'Family Connection Printables',
          'Midweek Plans',
          'Visual Aids (Social Media Share Squares, Charts, Maps, etc.)',
          'PowerPoint Templates',
        ],
      },
    ],
    adult: [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Guide PDFs',
          'Personal Study Guide PDFs',
          'Daily Discipleship Guide Leader and Learner PDFs',
          'CSB and KJV Options',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS:',
        contents: [
          'NIV and ESV options for Basic materials',
          'Group Plans Word Documents',
          'Visual Aids (Charts, Maps, etc.)',
          'QuickSource Leader Guides',
          'Adult Commentary',
          'Social Media Messages',
          'Memory Verse Masters',
        ],
      },
    ],
    'preschool and kids': [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Leader Guide PDFs',
          'Explorer Guide PDFs',
          'Explore the Bible on Location Videos',
          'Music Videos and Audio',
          'PowerPoint Presentations',
          'Visual Aids (Charts, Maps, etc.)',
          'At Home Resources for Family Worship',
          'Diaper Bag Card PDFs',
          'Activity Page PDFs',
          'Music Audio',
          'Visual Aids (Teaching Pictures, Books, etc.)',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS all you need for an extra hour:',
        contents: ['Worship Guide PDFs', 'Worship Videos and Audio', 'Music and Missions Videos', 'Slides'],
      },
    ],
  },
  TGP: {
    name: 'The Gospel Project',
    theme: 'light',
    description: 'A Christ-centered, chronological study of Scripture',
    imgSrc: 'img/TGP_v2.png',
    background: 'linear-gradient(180deg, #1F2638 0%, #6375A5 100%)',
    banners: {
      preschool: 'img/TGP-preschool-banner_v2.jpg',
      kids: 'img/TGP-kids-banner_v2.jpg',
      students: 'img/TGP-students-banner_v2.jpg',
      adult: 'img/TGP-adult-banner_v2.jpg',
    },
    atHomeBanners: {
      preschool: {
        src: 'img/AtHome_TGP.png',
        url: '/catalog/compilation/5fb9683c-987e-4d4f-a3e6-a26549a0e0c7',
      },
      kids: {
        src: 'img/AtHome_TGP.png',
        url: '/catalog/compilation/5fb9683c-987e-4d4f-a3e6-a26549a0e0c7',
      },
    },
    kids: [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Leader Guide PDFs and RTFs',
          'Activity Page PDFs',
          'Activity Printables',
          'Big Picture Cards',
          'Preteen Learner Guide PDFs',
          'Bible Story Pictures',
          'Big Picture Question & Answer Posters',
          'Bible Story Videos',
          'Questions from Kids Videos',
          'Key Passage Songs Videos and Audio',
          'Missions Videos',
          'Volume Leader Resources',
          'Presentation Files',
          'At Home Resources for Family Worship',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS all you need for an extra hour:',
        contents: [
          'Worship Guide PDFs and RTFs',
          'Midweek Guide PDFs and RTFs',
          'Worship Activity Printables',
          'Midweek Activity Printables',
          'Worship Music Videos and Audio',
        ],
      },
    ],
    preschool: [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for Babies - Preschool group ministry:',
        contents: [
          'Leader Audio Training',
          'Leader Guide PDFs and RTFs',
          'Activity Page PDFs',
          'Activity Printables',
          'Big Picture Cards',
          'Bible Story Pictures',
          'Big Picture Question & Answer Posters',
          'Bible Story Videos',
          'Key Passage Songs Videos and Audio',
          'Missions Videos',
          'Volume Leader Resources',
          'Presentation Files',
          'At Home Resources for Family Worship',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS all you need for an extra hour:',
        contents: ['Worship Guide PDFs and RTFs', 'Worship Music Videos and Audio'],
      },
    ],
    students: [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Training Documents',
          'Leader Guide PDFs',
          'Daily Discipleship Guide PDFs',
          'CSB and ESV Options',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS:',
        contents: [
          'Middle School Leader Guide PDFs',
          'Middle School Daily Discipleship Guide PDFs',
          'One Sheet Printables',
          'Family Connection Printables',
          'Midweek Plans',
          'Visual Aids (Posters, Social Media Share Squares, Charts, etc.)',
          'PowerPoint Templates',
        ],
      },
    ],
    adult: [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: ['Leader Guide PDFs', 'Daily Discipleship Guide PDFs'],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS:',
        contents: [
          'Leader Guide Word Documents',
          'Daily Discipleship Guide Word Documents',
          'Visual Aids (Charts, Maps, etc.)',
          'PowerPoint Resources',
          'Memory Verse Handouts',
        ],
      },
    ],
    'preschool and kids': [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Leader Guide PDFs and RTFs',
          'Activity Page PDFs',
          'Activity Printables',
          'Big Picture Cards',
          'Preteen Learner Guide PDFs',
          'Bible Story Pictures',
          'Big Picture Question & Answer Posters',
          'Bible Story Videos',
          'Questions from Kids Videos',
          'Key Passage Songs Videos and Audio',
          'Missions Videos',
          'Volume Leader Resources',
          'Presentation Files',
          'At Home Resources for Family Worship',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS all you need for an extra hour:',
        contents: [
          'Worship Guide PDFs and RTFs',
          'Midweek Guide PDFs and RTFs',
          'Worship Activity Printables',
          'Midweek Activity Printables',
          'Worship Music Videos and Audio',
        ],
      },
    ],
  },
  YOU: {
    name: 'You',
    description: 'A Bible Study for Urban and Multicultural Believers',
    imgSrc: 'img/YOU.png',
    background: 'linear-gradient(69.95deg, #EBF3F9 4.99%, #B0DDFC 69.32%)',
    banners: {
      adult: 'img/YOU-adult-banner.jpg',
    },
    adult: [
      {
        title: 'Basic Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Leader Guide PDFs for Adults and Senior Adults',
          'Personal Study Guide PDFs for Adults and Senior Adults',
          'Daily Discipleship Guide Leader and Learner PDFs',
          'CSB and KJV Options',
        ],
      },
      {
        title: 'Plus Plan Details',
        description: 'Everything in the Basic plan PLUS:',
        contents: [
          'NIV options for Basic materials',
          'Leader Guide Word Documents',
          'Visual Aids (Charts, Maps, etc.)',
          'Advanced Bible Study and Advanced Bible Study Teacher',
          'Promo Videos',
          'Social Media Messages',
          'Family Connection Printables',
        ],
      },
    ],
  },
  HYFI: {
    name: 'Hyfi',
    theme: 'light',
    description: 'Digital Curriculum for the \nNext Generation',
    imgSrc: 'img/hyfi.png',
    background: 'center / cover url("img/hyfi-background.jpg") no-repeat',
    banners: {
      preschool: 'img/hyfi-banner.jpg',
      kids: 'img/hyfi-banner.jpg',
      students: 'img/hyfi-banner.jpg',
    },
    atHomeBanners: {
      preschool: {
        src: 'img/AtHome_HYFI.jpg',
        url: '/catalog/compilation/80a4b2c4-f549-4d45-c5c5-02c6fba76f3b',
      },
      kids: {
        src: 'img/AtHome_HYFI.jpg',
        url: '/catalog/compilation/80a4b2c4-f549-4d45-c5c5-02c6fba76f3b',
      },
    },
    timelineEnabled: true,
    onboarding: {
      background: 'center / cover url("img/hyfi-onboarding-background.jpg") no-repeat',
      subscriptionUrl: 'https://myhyfi.com',
    },
    printOptions: {
      kids: {
        color: '#ee5244',
      },
      preschool: {
        color: '#23b1b0',
      },
      students: {
        color: '#518fc5',
      },
    },
    rescheduleStartDate: new Date('2023-09-01T00:00:00'),
    kids: [
      {
        title: 'Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Interactive Digital Leader Guides and RTFs',
          'Activity Page PDFs',
          'Activity Printables',
          'Bible Story Videos',
          'Theme Song MP3 and Motions/Lyric Videos',
          'Countdown Videos',
          'Intro Videos',
          'Theme Verse Videos',
          'Monthly Leader Resources',
          'Monthly Family and Volunteer Resources',
          'Presentation Files',
          'Launch Resources',
        ],
      },
    ],
    preschool: [
      {
        title: 'Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Interactive Digital Leader Guides and RTFs',
          'Activity Page PDFs',
          'Activity Printables',
          'Bible Story Videos',
          'Theme Song MP3 and Motions Video',
          'Countdown Videos',
          'Theme Verse Videos',
          'Monthly Leader Resources',
          'Monthly Family and Volunteer Resources',
          'Presentation Files',
          'Launch Resources',
        ],
      },
    ],
    students: [
      {
        title: 'Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Training',
          'Social Media Assets (Including Blank Slides)',
          'Digital Theme Posters',
          'Activity Printables',
          'Weekly Bible Story Videos',
          'Monthly Theme Videos',
          'Monthly Leader Resources',
          'Monthly Family and Volunteer Resources',
          'Presentation Files',
          'Launch Resources',
        ],
      },
    ],
    'preschool and kids': [
      {
        title: 'Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Interactive Digital Leader Guides and RTFs',
          'Activity Page PDFs',
          'Activity Printables',
          'Bible Story Videos',
          'Theme Song MP3 and Motions Video',
          'Countdown Videos',
          'Theme Verse Videos',
          'Monthly Leader Resources',
          'Monthly Family and Volunteer Resources',
          'Theme Song MP3 and Motions/Lyric Videos',
          'Intro Videos',
          'Presentation Files',
          'Launch Resources',
        ],
      },
    ],
    'preschool, kids and students': [
      {
        title: 'Plan Details',
        description: 'Contains essentials for weekly group ministry:',
        contents: [
          'Leader Audio Training',
          'Interactive Digital Leader Guides and RTFs',
          'Activity Page PDFs',
          'Activity Printables',
          'Bible Story Videos',
          'Theme Song MP3 and Motions Video',
          'Countdown Videos',
          'Theme Verse Videos',
          'Monthly Leader Resources',
          'Monthly Family and Volunteer Resources',
          'Theme Song MP3 and Motions/Lyric Videos',
          'Intro Videos',
          'Leader Training',
          'Social Media Assets (Including Blank Slides)',
          'Digital Theme Posters',
          'Weekly Bible Story Videos',
          'Monthly Theme Videos',
          'Presentation Files',
          'Launch Resources',
        ],
      },
    ],
  },
  TGP5: {
    hidden: !window.featureToggles.get('TGP5_PREVIEW'),
    name: 'The Gospel Project 5.0',
    theme: 'light',
    description: 'A Christ-centered, chronological study of Scripture',
    imgSrc: 'img/tgp5/logo_fall2024.png',
    background: '#294d3c',
    previewOptions: new PreviewOptions({ distribute: true, reschedule: true }, '#577769'),
    hideUpcomingSessionsMessage: true,
    banners: {
      preschool: 'img/tgp5/kids-banner.jpg',
      kids: 'img/tgp5/kids-banner.jpg',
      students: 'img/tgp5/students-banner_v2.jpg',
      adult: 'img/tgp5/adult-banner_v2.png',
    },
    onboarding: {
      background: '#243646',
    },
  },
  ETB2025: {
    hidden: !window.featureToggles.get('ETB2025_PREVIEW'),
    name: 'Explore the Bible',
    theme: 'light',
    description: 'Preview Explore the Bible with selected sessions coming in Fall 2025',
    imgSrc: 'img/ETB2025-logo.png',
    background: 'top center / cover url("img/ETB2025-card-background.png") no-repeat',
    previewOptions: new PreviewOptions({ distribute: false, reschedule: false }, '#3a5f6d'),
    hideUpcomingSessionsMessage: true,
    banners: {
      preschool: 'img/ETB2025-banner-kids.png',
      kids: 'img/ETB2025-banner-kids.png',
      students: 'img/ETB2025-banner-students.png',
      adult: 'img/ETB2025-banner-adults.png',
    },
    onboarding: {
      background: 'center / cover url("img/ETB2025-onboarding-background.png") no-repeat',
    },
  },
  HYFICHRISTMAS: {
    hidden: !window.featureToggles.get('HYFI_PREVIEW'),
    name: 'Hyfi Christmas Preview',
    theme: 'light',
    description: 'Preview Hyfi with prior sessions \nfrom Christmas 2023',
    imgSrc: 'img/hyfi.png',
    background: 'top center / cover url("img/hyfi-christmas-preview/bible-study-card-bg.jpg") no-repeat',
    timelineEnabled: true,
    previewOptions: new PreviewOptions({ distribute: false, reschedule: true }, '#099799'),
    hideUpcomingSessionsMessage: true,
    banners: {
      preschool: 'img/hyfi-christmas-preview/banner.png',
      kids: 'img/hyfi-christmas-preview/banner.png',
      students: 'img/hyfi-christmas-preview/banner.png',
    },
    onboarding: {
      background: 'center / cover url("img/hyfi-christmas-preview/onboarding-background.jpg") no-repeat',
      subscriptionUrl: 'https://myhyfi.com',
    },
    printOptions: {
      kids: {
        color: '#ee5244',
      },
      preschool: {
        color: '#23b1b0',
      },
      students: {
        color: '#518fc5',
      },
    },
  },
};
