angular.module('lwNamb').directive(
  'coachPaneTask',

  [
    function() {
      return {
        restrict: 'E',
        templateUrl: 'partials/directives/coach-pane-task.html',
        scope: {
          userId: '@',
          paneId: '@',
          orgId: '@',
        },
        replace: true,
        controller: [
          '$scope',
          '$log',
          'State',
          'orgService',
          'taskListInstanceService',
          'thirdPartyVideoService',
          'assessTakerService',
          function(
            $scope,
            $log,
            State,
            orgService,
            taskListInstanceService,
            thirdPartyVideoService,
            assessTakerService
          ) {
            $scope.isLoading = true;

            orgService
              .hasSubscription($scope.orgId)
              .then(
                function(hasSub) {
                  $scope.hasSubscription = hasSub;
                },
                function(reason) {
                  $log.error('Unable to get subscription information: ' + reason);
                }
              )
              .finally(function() {
                getTaskData();
              });

            var state = State($scope.paneId);
            $scope.selected = state.get();

            var getTaskData = function() {
              taskListInstanceService.getTaskInstance($scope.selected.taskId, $scope.userId, $scope.orgId).then(
                function(data) {
                  $scope.task = data.taskInstance;
                  $scope.task.task.isPreview = !$scope.hasSubscription;
                  $scope.taskListInstanceId = data.taskListInstanceId;
                  if (!$scope.hasSubscription) {
                    if (
                      $scope.task.task.isPreview &&
                      $scope.task.task._type === 'ThirdPartyVideo' &&
                      $scope.task.task.thirdPartySite === 'YouTube'
                    ) {
                      var videoId = thirdPartyVideoService.getThirdPartyData($scope.task.task.videoUrl);
                      $scope.task.task.videoImageUrl =
                        'https://img.youtube.com/vi/' + videoId.id + '/maxresdefault.jpg';
                    }
                  }
                  $scope.isLoading = false;
                },
                function(reason) {
                  $log.error(reason);
                  $scope.isLoading = false;
                  $scope.isError = true;
                }
              );
            };

            $scope.close = function() {
              state.put('menu', 'task-list');
              state.put('taskListId', taskListId);
            };
          },
        ],
      };
    },
  ]
);
