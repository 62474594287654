import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Grid, Color, Breakpoints, Type } from '../StyleGuide';

import windowService from '../services/windowService';
import assessmentService from '../services/assessmentService';

import { useUser } from '../authentication';

import Breadcrumbs from '../components/Breadcrumbs';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import { LinkStyleButton } from '../components/Buttons';
import { Container, PageTitle, InlineList } from '../components/Layout';
import { Label, Select, TextFilterInput } from '../components/FormElements';
import EmptyState from '../components/EmptyState';

const PageContainer = styled(Container)`
  margin: ${Grid._6} auto;
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: ${Grid._5};
  margin: ${Grid._6} 0;

  @media screen and (min-width: ${Breakpoints.screen_xs}) {
    flex-direction: row;
  }
`;

const FilterContainer = styled.div`
  flex: 0 1 0;
  min-width: 240px;
  width: 100%;
`;

const SearchContainer = styled.div`
  flex: 1 1 auto;
  width: 100%;
`;

const AssessmentResultsListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AssessmentRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${Grid._4} 0;
  border-bottom: 1px solid ${Color.Gray._30};
  transition: all 0.15s ease-in-out;

  &:hover:not([disabled]) {
    background: ${Color.white};
    cursor: pointer;

    h4 {
      text-decoration: underline;
    }
  }
`;

const AssessmentResultsListItem = styled.div`
  margin-bottom: ${Grid._7};
  display: flex;
  width: 100%;

  h2 {
    margin-top: ${Grid._3};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const AssessmentData = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  grid-gap: ${Grid._3};

  h4 {
    margin: 0;
  }
`;

const ActionContainer = styled.div`
  display: grid;
  place-content: center;
  flex: 0 1 140px;
`;

const DisabledText = styled.p`
  margin: 0;
  color: ${Type.Color.medium};
`;

const StatList = styled(InlineList)`
  li {
    &:not(:last-of-type)::after {
      content: '${props => props.content}';
      margin: 0 ${Grid._3};
    }
  }
`;

const AssessmentResults = () => {
  const user = useUser();
  const orgId = user.lastSelectedAccount;
  const [data, setData] = useState({
    assessments: [],
    isLoading: true,
    isError: false,
    status: 'Show All',
    sortBy: 'Recently Completed',
  });
  const crumbs = [{ name: 'Organization', route: '#/org' }, { name: 'Assessment Results' }];

  const { assessments, isLoading, isError } = data;

  const [sortedAndFilteredAssessments, setSortedAndFilteredAssessments] = useState([]);

  const [filters, setFilters] = useState({
    status: 'Show All',
    text: '',
    sortBy: '',
  });

  const sortAssessments = (assessments, sortFn) => {
    const sorted = [...assessments];
    sorted.sort(sortFn);
    return sorted;
  };

  useEffect(() => {
    assessmentService
      .getAssessmentsAggregate(orgId)
      .then(response => {
        setData(prevState => ({
          ...prevState,
          assessments: response.sort((a, b) => b.mostRecentCompletion - a.mostRecentCompletion),
          sortedAndFilteredAssessments: response.sort((a, b) => b.mostRecentCompletion - a.mostRecentCompletion),
          isLoading: false,
        }));
      })
      .catch(error => {
        console.error(error);
        setData(prev => ({ ...prev, isLoading: false, isError: true }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSortedAndFilteredAssessments(
      sortAssessments(
        assessments
          .filter(
            asmt =>
              filters.status === 'Show All' ||
              (filters.status === 'Show Completed' && asmt.numComplete > 0) ||
              (filters.status === 'Show Not Completed' && asmt.numComplete === 0)
          )
          .filter(asmt => !filters.text || asmt.name.toLowerCase().includes(filters.text.toLowerCase())),
        (a, b) => {
          if (filters.sortBy === 'Name [A - Z]') return a.name.localeCompare(b.name);
          if (filters.sortBy === 'Name [Z - A]') return b.name.localeCompare(a.name);
          return b.mostRecentCompletion - a.mostRecentCompletion;
        }
      )
    );
  }, [filters, assessments]);

  const updateFilter = updated => setFilters(prev => ({ ...prev, ...updated }));

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <PageContainer>
        {isLoading ? (
          <LoadingState />
        ) : isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : (
          <>
            <PageTitle>
              <h1>Assessment Results</h1>
            </PageTitle>
            <ActionRow>
              <FilterContainer>
                <Label>Status:</Label>
                <Select
                  data-qa-hook="assessmentResultsStatusSelect"
                  onChange={e => updateFilter({ status: e.target.value })}
                >
                  <option>Show All</option>
                  <option>Show Completed</option>
                  <option>Show Not Completed</option>
                </Select>
              </FilterContainer>
              <FilterContainer>
                <Label>Sort By:</Label>
                <Select
                  data-qa-hook="assessmentResultsSortSelect"
                  onChange={e => updateFilter({ sortBy: e.target.value })}
                >
                  <option>Recently Completed</option>
                  <option>Name [A - Z]</option>
                  <option>Name [Z - A]</option>
                </Select>
              </FilterContainer>
              <SearchContainer>
                <TextFilterInput
                  value={filters.text}
                  onChangeHandler={e => updateFilter({ text: e.target.value })}
                  placeholder="Search Assessments"
                  clearInput={() => updateFilter({ text: '' })}
                />
              </SearchContainer>
            </ActionRow>
            <AssessmentResultsListContainer>
              {assessments.length === 0 ? (
                <EmptyState
                  title="No assessments assigned"
                  description="Assessment results will appear once an assessment has been assigned"
                />
              ) : sortedAndFilteredAssessments.length > 0 ? (
                sortedAndFilteredAssessments.map((result, index) => {
                  return (
                    <AssessmentRowContainer
                      key={result.id}
                      onClick={() =>
                        result.numComplete > 0 && windowService.redirectTo(`/assessment/results/${result.id}`)
                      }
                      disabled={result.numComplete === 0}
                    >
                      <AssessmentResultsListItem>
                        <AssessmentData>
                          <h4 data-qa-hook={`assessment-result-name-${index}`}>{result.name}</h4>
                          <StatList content="•">
                            <li>{result.numAssigned} Assigned</li>
                            <li>{result.numStarted + result.numComplete} Attempted</li>
                            <li>{result.numStarted} In Progress</li>
                            <li>{result.numComplete} Completed</li>
                          </StatList>
                        </AssessmentData>
                        <ActionContainer>
                          {result.numComplete === 0 ? (
                            <DisabledText>No Results</DisabledText>
                          ) : (
                            <LinkStyleButton>View Results</LinkStyleButton>
                          )}
                        </ActionContainer>
                      </AssessmentResultsListItem>
                    </AssessmentRowContainer>
                  );
                })
              ) : (
                <EmptyState
                  icon="fa-search"
                  title="Your search does not have any matches"
                  description="Please try another search"
                />
              )}
            </AssessmentResultsListContainer>
          </>
        )}
      </PageContainer>
    </>
  );
};

export default AssessmentResults;
