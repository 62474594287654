import React from 'react';
import styled from 'styled-components';
import { Color, Shadow } from '../StyleGuide';
import useCurriculum from '../hooks/useCurriculum';

const BannerContainer = styled.div`
  min-height: 190px;
  background: ${props => props.backgroundColor || Color.Gray._50};
  box-shadow: ${Shadow.small};
`;

const BannerImg = styled.img`
  width: 100%;
  min-height: 190px;
  object-fit: cover;
  margin: 0;
`;

export default function BibleStudyBanner() {
  const { brand } = useCurriculum();

  return (
    <BannerContainer className="bible-study-banner" backgroundColor={brand.backgroundColor}>
      {brand.banner && <BannerImg src={brand.banner} alt={brand.name} />}
    </BannerContainer>
  );
}
