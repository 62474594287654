import React from 'react';
import PropTypes from 'prop-types';
import { MediumPrimaryButton, MediumButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';
import windowService from '../services/windowService';
import capitalizeFilter from '../filters/capitalize-filter';
import WhiteLabel from '../filters/WhiteLabel';

const OrgRequiredModal = ({ handleDismiss }) => {
  const redirectToOrgCreate = () =>
    windowService.redirectTo(`/org/create?redirect=${encodeURIComponent(windowService.getCurrentRoute())}`);

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader data-qa-hook="OrgRequiredModal">
        <h3>Get Started</h3>
      </ModalHeader>
      <ModalBody>
        <h4>To share with others, let's begin by setting up your {WhiteLabel.labels.org}.</h4>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton data-qa-hook="OrgRequiredCreateButton" onClick={redirectToOrgCreate}>
          Create {capitalizeFilter(WhiteLabel.labels.org)}
        </MediumPrimaryButton>
        <MediumButton data-qa-hook="OrgRequiredCloseButton" onClick={handleDismiss}>
          Close
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

OrgRequiredModal.propTypes = {
  handleDismiss: PropTypes.func.isRequired,
};

export default OrgRequiredModal;
