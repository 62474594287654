angular.module('lwNamb').factory(
  'transformer',

  [
    'iconForType',
    'apiUrlService',
    '$window',
    function(iconForType, apiUrlService, $window) {
      return {
        transformTaskListInstances: function(response) {
          var array = [];
          if (response && response.length > 0) {
            for (var i = 0; i < response.length; i++) {
              var ap = response[i].expanded ? 0 : -1;
              var tlProg = response[i].progress === undefined ? 0 : response[i].progress;
              if (tlProg === 0 && response[i].tasks !== undefined && response[i].tasks.length !== 0) {
                for (var j = 0; j < response[i].tasks.length; j++) {
                  response[i].tasks[j].task.icon = iconForType.with(response[i].tasks[j].task, '_type');
                  if (response[i].tasks[j].status === 'complete') {
                    response[i].tasks[j].sortStatus = 1;
                    tlProg++;
                  }
                  if (response[i].tasks[j].status === 'in_progress' || response[i].tasks[j].status === 'not_started') {
                    response[i].tasks[j].sortStatus = 0;
                  }
                }
                tlProg = tlProg / response[i].tasks.length;
              }
              if (response[i]._id) {
                array.push({
                  id: response[i]._id.id,
                  originalTaskListId: response[i].originalTaskListId ? response[i].originalTaskListId.id : '',
                  name: response[i].name,
                  description: response[i].description,
                  assignedUser: response[i].assigneeId ? response[i].assigneeId.id : '',
                  assigningOrg: response[i].assignorId ? response[i].assignorId.id : '',
                  status: response[i].status,
                  tasks: response[i].tasks.sort(function(a, b){
                    if (a.task.order < b.task.order)
                      return -1;
                    if (a.task.order > b.task.order)
                      return 1;
                    return 0;
                  }),
                  hideCompleted: response[i].hideCompleted,
                  activePanel: ap,
                  expanded: response[i].expanded,
                  progress: tlProg,
                  reports: response[i].reports,
                  reportId:
                    response[i].reports && response[i].reports[0] !== undefined
                      ? response[i].reports[0].reportId
                      : undefined,
                  reportToGenerate:
                    response[i].reports && response[i].reports[0] !== undefined ? response[i].reports[0].id : undefined,
                  timeAssigned: response[i].timeAssigned || 0,
                  timeStarted: response[i].timeStarted || 0,
                  timeCompleted: response[i].timeCompleted || 0,
                  purchaseRequired: response[i].purchaseRequired ? response[i].purchaseRequired.value : null,
                  dueDate: response[i].dueDate,
                  ownerName: response[i].assignorOrgName,
                  selfAssigned: response[i].selfAssigned,
                  certificateInfo: response[i].certificateInfo ? response[i].certificateInfo : null,
                  discussionDisabled: response[i].discussionDisabled === true,
                });
              }
            }
          }
          return array;
        },
        transformAssignmentDetail: transformAssignmentDetail,
        transformAssessment: function(response) {
          var assessment = {};
          if (response) {
            assessment.id = response._id;
            assessment.title = '';
            if (response.name && response.name[0]) assessment.title = response.name[0].value;
            assessment.version = 1;
            assessment.instructions = '';
            assessment.overview = '';
            if (response.instructions && response.instructions[0]) {
              assessment.instructions = response.instructions[0].value;
            }
            if (response.overview && response.overview[0]) {
              assessment.overview = response.overview[0].value;
            }
            if (response.responseVisibility) {
              assessment.responseVisibility = response.responseVisibility;
            }
            if (response.questions) {
              assessment.questions = response.questions;
              for (var i = 0; i < response.questions.length; i++) {
                assessment.questions[i].question = response.questions[i].questionValue[0].value;
                assessment.questions[i].order = i;
                var choices = [];
                angular.forEach(
                  response.questions[i].possibleAnswers,
                  function(possibleAnswer) {
                    var choice = {};
                    choice.id = possibleAnswer.id.id;
                    choice.text = possibleAnswer.value[0] !== undefined ? possibleAnswer.value[0].value : '';
                    choice.selected = possibleAnswer.isCorrect;
                    this.push(choice);
                  },
                  choices
                );
                assessment.questions[i].choices = choices;
              }
            }
            assessment.published = response.published || false;
            assessment.deleted = response.deleted || false;
            if (assessment.deleted) {
              assessment.deletedBy = response.deletedBy;
            }
          }
          return assessment;
        },

        transformWorkflows: function(workflows) {
          if (!angular.isArray(workflows)) return [];
          workflows.forEach(function(workflow) {
            workflow.id = workflow._id;
            delete workflow._id;

            workflow.entities = orderEntities(workflow.entities, workflow.transitions);
          });
          return workflows;
        },
        transformWorkflowInstanceDetail: function(workflow) {
          workflow.id = workflow._id;
          delete workflow._id;
          workflow.courses.forEach(function(course) {
            course.id = course._id;
            delete course._id;

            course.discussionDisabled = course.discussionDisabled === true;
          });
          workflow.courses.sort(function(a, b) {
            return a.order - b.order;
          });
          return workflow;
        },
        transformWorkflowInstance: function(workflow) {
          workflow.id = workflow._id;
          delete workflow._id;
          workflow.entityInstances = orderEntities(
            workflow.entityInstances.map(function(ei) {
              ei._entity._instanceId = ei._instanceId;
              return ei._entity;
            }),
            workflow.transitions
          );
          return workflow;
        },
        transformWorkflow: function(workflow) {
          workflow.id = workflow._id;
          delete workflow._id;
          workflow.entities = orderEntities(workflow.entities, workflow.transitions);
          if (workflow.itemNumber !== undefined) {
            var itemNumbers = workflow.itemNumber.split(',');
            workflow.itemNumber = {
              value: itemNumbers[0],
            };
          }
          workflow.entities.forEach(function(entity) {
            if (entity._type === 'TaskList') {
              if (entity.taskList !== undefined) {
                if (entity.taskList.tasks !== undefined && entity.taskList.tasks.length > 0) {
                  for (var j = 0; j < entity.taskList.tasks.length; j++) {
                    entity.taskList.tasks[j].icon = iconForType.with(entity.taskList.tasks[j], '_type');
                  }
                }
              }
            }
          });
          indexWorkflowTasks(workflow);
          return workflow;
        },
        transformSeries: function(series) {
          series.hasDownloadMaterial = false;
          for (var j = 0; j < series.materials.length; j++) {
            if (series.materials[j].materialType === 'Download') {
              series.hasDownloadMaterial = true;
              break;
            }
          }
          return series;
        },
        transformMaterial: transformMaterial,
        transformSingleMaterial: function(material) {
          var m = material;
          m.materialType = transformMaterialType(material.materialType);
          //Transform to fit task viewer directive models
          m.task = {
            taskName: material.name,
            taskDescription: material.description,
            _type: material.materialType,
            videoId: material.mediaId,
            linkUrl: material.link,
          };
          return m;
        },
        transformMaterials: function(materials) {
          var materialsArray = [];
          for (var j = 0; j < materials.length; j++) {
            materialsArray.push(transformMaterial(materials[j]));
          }
          materialsArray.sort(function(m1, m2) {
            return m1.order - m2.order;
          });
          return materialsArray;
        },
        transformCurriculums: function(curriculums) {
          if (!angular.isArray(curriculums)) return [];
          curriculums.forEach(function(curriculum) {
            curriculum.searchData = [];
            curriculum.searchData.push(curriculum.name);
          });
          return curriculums;
        },
        transformCurriculumIssue: function(issue) {
          var issueObj = {};
          if (issue) {
            issueObj = {
              id: issue.id,
              name: issue.name,
              sessionCount: issue.sessionCount,
              firstUseDate: issue.firstUseDay.split('[UTC]')[0],
              publishStartDate: issue.publishStartDay ? issue.publishStartDay.split('[UTC]')[0] : undefined,
              publishEndDate: issue.publishEndDay ? issue.publishEndDay.split('[UTC]')[0] : undefined,
              curriculumId: issue.curriculumId,
            };
          }
          return issueObj;
        },
        transformCurriculumSession: function(session) {
          var sessionObj = {};
          if (session) {
            sessionObj = {
              id: session.id,
              name: session.name,
              useDate: session.useDate.split('T')[0],
              issueId: session.issueId,
              curriculumId: session.curriculumId,
              publishStartDate: session.publishStartDate.split('T')[0],
              publishEndDate: session.publishEndDate.split('T')[0],
              image: session.image,
              description: session.description,
            };
          }
          return sessionObj;
        },
        transformTaskLists: function(response) {
          var array = [];
          if (response && response.length > 0) {
            for (var i = 0; i < response.length; i++) {
              if (response[i].tasks !== undefined && response[i].tasks.length > 0) {
                for (var j = 0; j < response[i].tasks.length; j++) {
                  response[i].tasks[j].icon = iconForType.with(response[i].tasks[j], '_type');
                  response[i].tasks[j].sortStatus = 0;
                }
              }
              if (response[i]._id) {
                array.push({
                  id: response[i]._id.id,
                  name: response[i].name,
                  description: response[i].description,
                  owner: response[i].owner,
                  tasks: response[i].tasks,
                  workflowIds: response[i].workflowIds,
                  reportToGenerate: response[i].reportToGenerate,
                  lastModifiedDate: new Date(response[i].lastModifiedDate),
                  published: response[i].published,
                  posterImage: response[i].posterImage,
                  suggestedTimeFrameDays: response[i].suggestedTimeFrameDays,
                  deliverCertificate: response[i].certificateId !== undefined ? true : false,
                  itemNumber: response[i].itemNumber !== undefined ? response[i].itemNumber : undefined,
                  category: response[i].categories !== undefined ? response[i].categories[0] : undefined,
                  discussionDisabled: response[i].discussionDisabled === true,
                  reports: response[i].reports || [],
                  hasAccess: response[i].hasAccess === true,
                });
              }
            }
          }
          return array;
        },
        transformAssignment: function(assignment, includeAssignmentDetail) {
          if (assignment.assignable._type === 'Workflow') {
            var flatEntities = assignment.assignable.entityInstances.map(function(eI) {
              return { _type: eI._entity._type, id: eI._entity.id, _instanceId: eI._instanceId };
            });
            var orderedEntities = orderEntities(flatEntities, assignment.assignable.transitions);
            var orderedTLs = [];
            orderedEntities.forEach(function(entity) {
              if (entity._instanceId !== undefined) {
                assignment.assignable.tasklists.forEach(function(tasklist) {
                  if (tasklist.taskListInstanceId.id === entity._instanceId) {
                    orderedTLs.push(tasklist);
                  }
                });
              }
            });
            assignment.assignable.tasklists = transformTaskListsforManageAssignment(orderedTLs);
          } else if (assignment.assignable._type === 'AssignedTaskList') {
            assignment.assignable.tasks.forEach(function(task) {
              task.task.icon = iconForType.with(task.task, '_type');
            });
          } else {
            //PendingTraining
            //todo: transform this appropriately
            // console.log(JSON.stringify(assignment));
          }
          serveImage(assignment);
          if (includeAssignmentDetail) assignment.assignmentDetail = transformAssignmentDetail(assignment);
          return assignment;
        },
        transformAssignments: function(assignments) {
          var toReturn = [];
          if (!angular.isArray(assignments)) return [];
          assignments
            .filter(function(assignment) {
              return assignment.assignable._type !== 'PendingTraining';
            })
            .forEach(function(assignment) {
              assignment.searchData = [];
              serveImage(assignment);
              assignment.shared = assignment.assignor && assignment.assignor._type === 'Share';
              assignment.sharedOn = assignment.shared ? assignment.assignor.on : undefined;
              assignment.assignedOn = assignment.assignable.assignedOn;
              assignment.completedOn = assignment.assignable.completedOn || 0;
              assignment.firstName =
                assignment.assignee.name !== undefined ? assignment.assignee.name.split(' ')[0] : '';
              assignment.lastName =
                assignment.assignee.name !== undefined ? assignment.assignee.name.split(' ')[1] : '';
              assignment.searchData.push(assignment.assignee.name);
              assignment.searchData.push(assignment.assignee.email);
              assignment.groupNames = assignment.assignee !== undefined ? assignment.assignee.groupNames : [];
              toReturn.push(assignment);
            });
          return toReturn;
        },
        transformAssignmentsForUser: function(assignments) {
          if (!angular.isArray(assignments)) return [];
          assignments.forEach(function(assignment) {
            assignment.trainingName = assignment.assignable.name;
            assignment.assignedOn = assignment.assignable.assignedOn;
            assignment.startedOn = assignment.assignable.startedOn;
            assignment.completedOn = assignment.assignable.completedOn;
            assignment.dueOn = assignment.assignable.dueDate;
            assignment.shared = assignment.assignor && assignment.assignor._type === 'Share';
            assignment.sharedOn = assignment.shared ? assignment.assignor.on : undefined;
            assignment.assignor = assignment.assignor ? assignment.assignor.name : null;
            assignment.progress = assignment.assignable.progress;
            assignment.isComplete = assignment.progress ? assignment.progress._type === 'Finished' : false;
            assignment._type = assignment.assignable._type === 'AssignedTaskList' ? 'course' : 'workflow';
          });
          return assignments;
        },
        transformInvites: function(invites) {
          if (!angular.isArray(invites)) return [];
          invites.forEach(function(item) {
            item.searchData = [];
            item.searchData.push(item.name);
            if (item.membershipCode === undefined) {
              item.type = 'email';
              if (item.invitees !== undefined) {
                item.numInvited = item.invitees.length;
                item.numRedeemed = item.invitees.filter(function(invitee) {
                  return invitee.status === 'Accepted';
                }).length;
                transformInviteInviteeNames(item.invitees);
                item.invitees.forEach(function(invitee) {
                  item.searchData.push(invitee.name);
                  item.searchData.push(invitee.email);
                });
              }
            } else {
              item.type = 'code';
              if (item.redemptionLimit !== undefined) {
                item.numInvited = item.redemptionLimit;
              }
              item.membershipCode.fullCode = item.membershipCode.prefix + '-' + item.membershipCode.suffix;
              item.redemptionLink =
                $window.location.origin +
                '/#/redeem-invite/' +
                item.membershipCode.prefix +
                '/' +
                item.membershipCode.suffix;
              item.numRedeemed = item.redemptions === undefined ? 0 : item.redemptions.length;
              item.redemptions.forEach(function(redemption) {
                item.searchData.push(redemption.email);
                item.searchData.push(redemption.firstName + ' ' + redemption.lastName);
              });
              item.searchData.push(item.membershipCode.fullCode);
            }
            item.id = item._id.id;
            delete item._id;
          });
          return invites;
        },
        transformInviteSettings: function(inviteSettings) {
          if (!angular.isArray(inviteSettings)) return [];
          inviteSettings.forEach(function(setting) {
            setting.id = setting._id;
            delete setting._id;
            setting.roles = setting.entities.filter(function(item) {
              return item._type === 'RoleView';
            });
            setting.groups = setting.entities.filter(function(item) {
              return item._type === 'GroupView';
            });
            setting.licenses = setting.entities.filter(function(item) {
              return item._type === 'LicenseView';
            });
            setting.trainingIds = setting.entities.filter(function(item) {
              return item._type === 'Assign';
            });
            setting.trainingIds.forEach(function(item) {
              item.name = item.trainingName;
              item.active = true; //Default to true

              if (item.trainingId) {
                item._type = item.trainingId._type;
                if (item.trainingId.taskListId) {
                  item.id = item.trainingId.taskListId.id;
                  item.trainingId = item.trainingId.taskListId.id;
                } else if (item.trainingId.workflowId) {
                  item.id = item.trainingId.workflowId;
                  item.trainingId = item.trainingId.workflowId;
                }
              } else if (item.training && item.training.id && item.training.id.taskListId) {
                item.trainingId = item.training.id.taskListId.id;
              } else if (item.training && item.training.id && item.training.id.workflowId) {
                item.trainingId = item.training.id.workflowId;
              }

              // May 9: I'm leaving comment below because I have no idea what it means:
              //Overwrite instance id with training id for search data synchronization
              //Add trainingId for invite settings data formatter
            });
          });
          return inviteSettings;
        },
        transformLicenses: function(licenses) {
          if (!angular.isArray(licenses)) return [];
          licenses.forEach(function(license) {
            license.searchData = [];
            license.searchData.push(license.item.title);
            license.searchData.push(license.owner.email);
            license.searchData.push(license.owner.firstName + ' ' + license.owner.lastName);
            if (license.item.title !== undefined) license.name = license.item.title;

            if (license.startDate !== undefined) license.startDate = license.startDate.split('T')[0];
            if (license.endDate !== undefined) {
              license.endDate = license.endDate.split('T')[0];
              if (Date.parse(license.endDate) < Date.now()) {
                license.expired = true;
              } else {
                license.expired = false;
              }
            }
            if (license.createdAt !== undefined) license.createdAt = license.createdAt.split('T')[0];
            if (license.lastRenewalDate !== undefined) license.lastRenewalDate = license.lastRenewalDate.split('T')[0];
            if (license.nextRenewalDate !== undefined) license.nextRenewalDate = license.nextRenewalDate.split('T')[0];
            if (license.subscription !== undefined && license.subscription.period !== undefined)
              license.subscription.period =
                license.subscription.period === 'Months' || license.subscription.period === 'Monthly'
                  ? 'Monthly'
                  : 'Yearly';
            if (license.seats && license.seats.occupants)
              license.seats.occupants.forEach(function(occupant) {
                occupant.profileImageUrl = apiUrlService.getUrl() + '/v1/images/profile/' + occupant.id + 'profile.png';
                license.searchData.push(occupant.email);
                license.searchData.push(occupant.firstName + ' ' + occupant.lastName);
              });
            if (
              license.seats &&
              license.seats.count &&
              license.seats.count.total === undefined &&
              license.seats.isLimited === false
            ) {
              license.seats.count.total = 'Unlimited';
              if (license.subscription !== undefined) license.subscription.modifiable = false;
            }
            if (license.item) {
              var format = license.item.mediumFormat;
              license.isGridSubscription = format === undefined || format === '' || format === 'Learning Object';
              if (license.isGridSubscription === false && license.subscription !== undefined) {
                license.subscription.modifiable = false;
              }
            }
          });
          return licenses;
        },
      };

      function orderEntities(entities, transitions) {
        var es = [],
          start = entities.find(function(e) {
            return e._type === 'Start';
          });
        es.push(start);
        addNext(start.id);
        if (es.length == 1) {
          es.push(
            entities.find(function(e) {
              return e._type == 'End';
            })
          );
        }

        function addNext(entityId) {
          var transition = transitions.find(function(t) {
            return t.from == entityId;
          });
          if (transition) {
            es.push(
              entities.find(function(e) {
                return e.id == transition.to;
              })
            );
            addNext(transition.to);
          }
        }

        return es;
      }

      function indexWorkflowTasks(workflow) {
        var lastIndex = 0;
        workflow.entities.map(function(entity) {
          if (entity._type === 'TaskList' && entity.taskList && entity.taskList.tasks.length) {
            entity.taskList.tasks.map(function(task, index) {
              task.taskIndex = index + lastIndex;
              if (index === entity.taskList.tasks.length - 1) {
                lastIndex = task.taskIndex + 1;
              }
            });
          }
        });
      }

      function transformTaskListsforManageAssignment(tasklists) {
        var array = [];
        tasklists.forEach(function(tasklist) {
          if (tasklist._type === 'AssignedTaskList') {
            tasklist.tasks.forEach(function(task) {
              task.task.icon = iconForType.with(task.task, '_type');
            });
            array.push({
              taskListInstanceId: tasklist.taskListInstanceId,
              name: tasklist.name,
              progress: tasklist.progress,
              dueDate: tasklist.dueDate,
              assignedOn: tasklist.assignedOn,
              completedOn: tasklist.completedOn,
              tasks: tasklist.tasks,
              reports: tasklist.reports,
              _type: tasklist._type,
              activePanel: -1,
            });
          } else {
            tasklist.tasks.forEach(function(task) {
              task.icon = iconForType.with(task, '_type');
            });
            array.push(tasklist);
          }
        });
        tasklists = array;
        return tasklists;
      }

      function serveImage(assignment) {
        if (assignment.assignee.profileImageUrl) {
          assignment.assignee.profileImageUrl =
            apiUrlService.getUrl() + '/v1/images/profile/' + assignment.assignee.profileImageUrl;
        }
      }

      function transformInviteInviteeNames(invitees) {
        if (invitees === undefined) return [];
        invitees.forEach(function(invitee) {
          invitee.name = invitee.firstName.concat(' ', invitee.lastName);
        });
        return invitees;
      }

      function transformMaterialType(materialType) {
        if (materialType === 'download') return 'Download';
        else if (materialType === 'video') return 'Video';
        else if (materialType === 'audio') return 'Audio';
        else if (materialType === 'text') return 'Text';
        else if (materialType === 'link') return 'Link';

        return materialType;
      }

      function transformMaterial(material) {
        var m = material;
        m.materialType = transformMaterialType(material.materialType);
        m.icon = iconForType.with(material, 'materialType');
        return m;
      }

      function transformAssignmentDetail(assignment) {
        return assignment.assignable.tasklists
          ? transformWorkflowDetail(assignment)
          : transformCourseDetail(assignment);
      }

      function transformCourseDetail(assignment, withinWorkflow) {
        return {
          id: assignment._id,
          originalTaskListId: assignment.id,
          name: assignment.assignable.name,
          description: '',
          assigneeName: assignment.assignee.name,
          assignedUser: assignment.assignee.userId,
          assignedBy: assignment.assignor.name,
          assigningOrg: assignment.assignor.userId,
          status: assignment.assignable.progress._type,
          tasks: assignment.assignable.tasks,
          hideCompleted: false,
          activePanel: assignment.assignable.activePanel,
          expanded: true,
          progress: withinWorkflow ? assignment.assignable.progress : assignment.assignable.progress.percent * 0.01,
          reports: assignment.assignable.reports,
          reportId: assignment.assignable.reports,
          reportToGenerate: assignment.assignable.reports,
          taskListId: assignment.assignable.taskListId && assignment.assignable.taskListId.id,
          taskListInstanceId: assignment.assignable.taskListInstanceId && assignment.assignable.taskListInstanceId.id,
          timeAssigned: assignment.assignable.assignedOn || 0,
          timeStarted: assignment.assignable.startedOn || 0,
          timeCompleted: assignment.assignable.completedOn,
          completedOn: assignment.assignable.completedOn,
          purchaseRequired: null,
          dueDate: assignment.assignable.dueDate,
          ownerName: '',
          selfAssigned: assignment.assignable.selfAssigned,
          certificateInfo: assignment.certificateInfo ? assignment.certificateInfo : null,
          profileImageUrl: assignment.assignee.profileImageUrl
            ? assignment.assignee.profileImageUrl
            : 'img/default_profile_photo.png',
        };
      }

      function transformWorkflowDetail(assignment) {
        return {
          assigneeId: {
            id: assignment.assignee.userId,
          },
          assignorId: {
            id: assignment.assignor.userId,
          },
          assignedUser: assignment.assignee.userId,
          courses: assignment.assignable.tasklists.map(function(task) {
            return transformCourseDetail(
              {
                assignable: task,
                assignee: assignment.assignee,
                assignor: assignment.assignor,
                id: assignment.id,
                _id: assignment._id,
                orgId: assignment.orgId,
              },
              true
            );
          }),
          assigneeName: assignment.assignee.name,
          assignedBy: assignment.assignor.name,
          id: assignment._id,
          owner: assignment.orgId,
          progress: assignment.assignable.progress,
          selfAssigned: assignment.assignable.selfAssigned,
          timeCreated: assignment.assignable.assignedOn || 0,
          unAssigned: false,
          upNext: '',
          workflowName: assignment.assignable.name,
          doNotScroll: true,
          profileImageUrl: assignment.assignee.profileImageUrl
            ? assignment.assignee.profileImageUrl
            : 'img/default_profile_photo.png',
        };
      }
    },
  ]
);
