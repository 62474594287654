import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MediumButton, MediumPrimaryButton } from '../../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../../components/ModalElements';
import { RadioListWithDescription, Radio } from '../../components/FormElements';

const CopyHeadersModal = ({ volumeId, onConfirm, handleDismiss }) => {
  const [copyHeaders, setCopyHeaders] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onContinueClick = () => {
    setIsSubmitting(true);
    onConfirm(copyHeaders);
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>Copy Headers</h3>
      </ModalHeader>
      <ModalBody>
        <h3>Would you like to add the volume headers from the previous volume?</h3>
        <form>
          <RadioListWithDescription>
            <li>
              <label>
                <Radio
                  data-qa-hook="radioCopyVolumeHeaders"
                  name="copy-volume-headers"
                  onChange={() => setCopyHeaders(true)}
                  checked={copyHeaders}
                />
                Copy Headers
              </label>
              <p>Use the headers from the previous volume</p>
            </li>
            <li>
              <label>
                <Radio
                  data-qa-hook="radioCopyVolumeHeaders"
                  name="copy-volume-headers"
                  onChange={() => setCopyHeaders(false)}
                  checked={!copyHeaders}
                />
                Don't Copy Headers
              </label>
              <p>I’m going to create my own headers</p>
            </li>
          </RadioListWithDescription>
        </form>
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton
          data-qa-hook="copyHeadersConfirm"
          onClick={onContinueClick}
          disabled={isSubmitting}
          operating={isSubmitting}
        >
          Continue
        </MediumPrimaryButton>
        <MediumButton onClick={() => handleDismiss()}>Cancel</MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
};

CopyHeadersModal.propTypes = {
  volumeId: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};

export default CopyHeadersModal;
