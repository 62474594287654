import EventBus from './EventBus';

// global var to bridge between frameworks
if (window.eventBus === undefined) {
  window.eventBus = EventBus();
}

const eventBus = window.eventBus;

export default eventBus;
