import apiClient from '../../services/apiClient';
import { AnalyticsAdapter } from './AnalyticsAdapter';

type ItemCartedData = {
  itemNumber: string;
  userEmail: string;
};

export class ActiveCampaignAdapter extends AnalyticsAdapter {
  #callTrackingAPI(eventName: string, email: string) {
    if (window.location.host !== 'ministrygrid.lifeway.com') return Promise.resolve();

    return apiClient.post('/trackingAC', {
      event: {},
      eventName,
      email,
    });
  }

  handleItemCarted(data: ItemCartedData) {
    const { itemNumber, userEmail } = data;
    return this.#callTrackingAPI(`Carted_${itemNumber}`, userEmail);
  }
}
