import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../../StyleGuide';

const List = styled.ol`
  counter-reset: section;
  list-style: none;
  padding: 0;
  margin: 0;
  border-top: 1px solid ${Color.Gray._50};
`;

const ListItem = styled.li`
  display: flex;
  background: white;
  align-items: center;
  border-bottom: 1px solid ${Color.Gray._30};
  color: ${Type.Color.dark};
  &::before {
    counter-increment: section;
    content: counter(section) ' ';
    width: ${Grid._10};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${Grid._5} ${Grid._3};
    background: ${Color.Gray._10};
    align-items: stretch;
    box-sizing: border-box;
    font-size: ${Type.Scale._4};
  }
  &:hover {
    background: ${Color.Gray._10};
  }
`;

const SortContainer = styled.div`
  background: ${Color.Gray._70};
  width: ${Grid._10};
  margin-right: ${Grid._4};
  align-self: stretch;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    background: transparent;
    border: 0;
  }
`;

const SortArrow = ({ direction, handleOnClick }) => (
  <button title={'Move ' + direction} onClick={handleOnClick}>
    <span className={'arrow-' + direction} />
  </button>
);

export default function Sort({ items, setCurrentQuestionAnswer }) {
  const [sortedAnswers, setSortedAnswers] = useState([]);

  useEffect(() => {
    let sorted = items.map(answer => ({
      id: answer.possibleAnswerId.id,
      value: answer.value,
    }));
    if (sortedAnswers !== sorted) {
      setSortedAnswers(sorted);
      setCurrentQuestionAnswer(sorted);
    }
  }, [items]);

  const moveAnswer = (direction, answerToMove, indexToMove) => {
    const desiredIndex = direction === 'up' ? indexToMove - 1 : indexToMove + 1;
    let updatedAnswers = sortedAnswers.map((answer, index) => {
      if (index === desiredIndex) {
        return answerToMove;
      } else if (answer.id === answerToMove.id) {
        return sortedAnswers[desiredIndex];
      } else {
        return answer;
      }
    });
    setSortedAnswers(updatedAnswers);
    setCurrentQuestionAnswer(updatedAnswers);
  };

  const answers = sortedAnswers.map((answer, index) => {
    const isNotFirst = index !== 0;
    const isNotLast = index < sortedAnswers.length - 1;

    return (
      <ListItem key={answer.id}>
        <SortContainer>
          {isNotFirst && <SortArrow direction="up" handleOnClick={() => moveAnswer('up', answer, index)} />}
          {isNotLast && <SortArrow direction="down" handleOnClick={() => moveAnswer('down', answer, index)} />}
        </SortContainer>
        {answer.value}
      </ListItem>
    );
  });

  return (
    <>
      <List>{answers}</List>
    </>
  );
}
