import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Color, Grid, Type } from '../../StyleGuide';
import { Container, PageTitle } from '../../components/Layout';
import { SmallRoundedIconButton, LinkStyleButton } from '../../components/Buttons';
import { mapSections } from '../../services/sessionTimelineService';
import SectionTitle from '../../components/SectionTitle';
import { SessionContainer } from '../../components/SessionTimelineElements';
import useSessionTimeline from '../../hooks/useSessionTimeline';
import alertService from '../../services/AlertService';

const SupplyRowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  min-height: 90px;
  padding: ${Grid._3} 0;
  border-bottom: 1px solid ${Color.Gray._10};
  transition: all 0.15s ease-in-out;

  &.downloadable {
    h4 {
      text-decoration: underline;
    }

    &:hover {
      background: ${Color.white};
      cursor: pointer;
    }
  }

  > div {
    &:nth-child(1) {
      h4,
      p {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0;
      }

      p {
        color: ${Type.Color.medium};
      }
    }

    &:nth-child(2) {
      display: grid;
      place-content: center;
    }
  }
`;

const SupplyRow = ({ supply, children }) => {
  const downloadFile = () =>
    supply.downloadable.url ? window.open(supply.downloadable.url) : alertService.showError();

  if (!supply.downloadable) return <SupplyRowContainer>{children}</SupplyRowContainer>;

  return (
    <SupplyRowContainer className="downloadable" onClick={downloadFile}>
      {children}
    </SupplyRowContainer>
  );
};

SupplyRow.propTypes = {
  supply: PropTypes.shape({
    downloadable: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  children: PropTypes.any,
};

const SessionTimelineSupplies = ({ onViewClick }) => {
  const { timeline } = useSessionTimeline();
  const [items, setItems] = useState([]);

  useEffect(
    () =>
      setItems(
        mapSections(
          timeline,
          (section, segment) =>
            segment.enabled &&
            section.supplies?.length > 0 && {
              segmentId: segment.segmentId,
              segmentName: segment.name,
              segmentActivity: segment.activity,
              isFlexOption: !!segment.flexSegmentId,
              supplies: section.supplies,
            }
        ).filter(Boolean)
      ),
    [timeline]
  );

  return (
    <SessionContainer>
      <Container>
        <PageTitle className="mt-20">
          <h2>Supplies</h2>
          <p>All the activity supplies you’ll need for this session.</p>
        </PageTitle>
        <div className="grid-container grid-col-1 grid-gap-56 pb-64 mt-56">
          {items.map(item => (
            <div key={item.segmentId}>
              <SectionTitle title={item.segmentName} subtitle={item.segmentActivity}>
                <div>
                  <LinkStyleButton
                    data-qa-hook="sectionViewAll"
                    onClick={() => onViewClick(item.segmentId, item.isFlexOption)}
                  >
                    View Activity
                  </LinkStyleButton>
                </div>
              </SectionTitle>
              {item.supplies.map(supply => (
                <SupplyRow key={`${item.segmentId}-${supply.name}`} supply={supply}>
                  <div className="grid-container grid-sm-col-2">
                    <h4>{supply.name}</h4>
                    <p>{supply.quantity}</p>
                  </div>
                  <div>
                    {!!supply.downloadable && (
                      <SmallRoundedIconButton>
                        <i className="fas fa-download fa-fw"></i>
                      </SmallRoundedIconButton>
                    )}
                  </div>
                </SupplyRow>
              ))}
            </div>
          ))}
        </div>
      </Container>
    </SessionContainer>
  );
};

SessionTimelineSupplies.propTypes = {
  onViewClick: PropTypes.func.isRequired,
};

export default SessionTimelineSupplies;
