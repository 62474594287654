angular.module('lwNamb').factory(
  'membershipService',

  [
    '$rootScope',
    'api',
    'qt',
    'uuid4',
    'userService',
    'commandSubmissionService',
    function($rootScope, api, qt, uuid4, userService, commandSubmissionService) {
      var timeoutSeconds = 60;

      return {
        joinOrgViaInvite: function(userId, orgId, forEmail, redeemingEmail, currentOrgId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
            id = uuid4.generate();

          var obj = {
            id: id,
            userId: {
              id: userId,
            },
            orgId: {
              id: orgId,
            },
            forEmail: forEmail,
            redeemingEmail: redeemingEmail,
            initiatingUserId: userId,
          };
          api.post('/v1/commands/AcceptInvite', obj).then(null, function failure(reason) {
            deferred.reject(reason);
          });

          $rootScope.$on('InviteAccepted', function(name, event) {
            if (event.id === id) {
              deferred.resolve(event.id);
            }
          });

          $rootScope.$on('AcceptInviteError', function(name, event) {
            if (event.id === id) {
              if (event.code === 'NO_PENDING_INVITES') {
                if (currentOrgId === orgId) {
                  deferred.resolve(event.id);
                } else {
                  userService.lookupUserOrgs().then(
                    function(response) {
                      var inOrg = false;
                      response.forEach(function(org) {
                        if (org._id.id === orgId) {
                          inOrg = true;
                        }
                      });
                      if (inOrg) {
                        deferred.resolve(event.id);
                      } else {
                        deferred.reject(event.code);
                      }
                    },
                    function() {
                      deferred.reject(event.code);
                    }
                  );
                }
              } else {
                deferred.reject(event.code);
              }
            }
          });
          return deferred.promise;
        },
        joinOrgViaMembershipCode: function(userId, email, firstName, lastName, prefix, suffix) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
            id = uuid4.generate();

          var obj = {
            id: id,
            userId: {
              id: userId,
            },
            email: email,
            firstName: firstName,
            lastName: lastName,
            code: {
              prefix: prefix,
              suffix: suffix,
              _type: 'MembershipCode',
            },
            initiatingUserId: userId,
          };

          api.post('/v1/commands/RedeemCode', obj).then(null, function failure(reason) {
            deferred.reject(reason);
          });

          $rootScope.$on('CodeRedeemed', function(name, event) {
            if (event.id === id) {
              deferred.resolve(event.id);
            }
          });

          $rootScope.$on('RedeemCodeSagaError', function(name, event) {
            if (event.id === id) {
              deferred.reject(event.code);
            }
          });

          return deferred.promise;
        },
      };
    },
  ]
);
