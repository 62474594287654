import React, { useState, useEffect } from 'react';
import { react2angular } from 'react2angular';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Type } from '../StyleGuide';
import { ContainerFluid, Container, FullWidthCard } from '../components/Layout';
import { HeaderUnderlined } from '../components/TrainingElements';
import { CompilationBanner } from './CompilationElements';
import TrainingCard from '../training/TrainingCard';
import TrainingListItem from './TrainingListItem';
import BreadcrumbsSubMenu from '../components/BreadcrumbsSubMenu';
import MobileCrumb from '../components/MobileCrumb';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import windowService from '../services/windowService';
import compilationService from '../services/compilationService';
import { OrgProvider } from '../contexts/OrgContext';

const CompilationInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Name = styled.h1`
  margin: ${Grid._3} 0;
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._6};
  font-weight: ${Type.Weight.bold};
  line-height: ${Type.Leading.normal};
`;

const Description = styled.p`
  color: ${Type.Color.mediumDark};
  font-size: ${Type.Scale._4};
  line-height: ${Type.Leading.tall};
  margin: ${Grid._3} 0;
`;

export default function TrainingCompilation({ org }) {
  const [crumbs, setCrumbs] = useState([{ name: 'Catalog', route: '#/catalog' }, { name: '' }]);
  const [data, setData] = useState({
    compilation: { trainingItems: [] },
    isLoading: true,
  });

  const compilationId = windowService.getRoutePart(3);

  const redirectToTraining = id => windowService.redirectTo(`#/catalog/compilation/${compilationId}/${id}`);

  useEffect(() => {
    compilationService
      .getCompilation(compilationId)
      .then(compilation => {
        setData({ compilation: compilation, isLoading: false });
        setCrumbs([{ name: 'Catalog', route: '#/catalog' }, { name: `${compilation.name}` }]);
      })
      .catch(err => {
        console.error(err);
        setData({ isError: true, isLoading: false });
      });
  }, []);

  return (
    <OrgProvider org={org}>
      <div className="hidden-xs hidden-sm">
        <BreadcrumbsSubMenu crumbs={crumbs} />
      </div>
      {data.isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <>
          {data.compilation.image && (
            <ContainerFluid>
              {data.compilation.image && (
                <CompilationBanner
                  src={compilationService.getCompilationImageUrl(data.compilation.image)}
                  alt={data.compilation.name}
                />
              )}
            </ContainerFluid>
          )}
          <Container className="visible-xs-block visible-sm-block" style={{ margin: `${Grid._4} 0 -${Grid._4} 0` }}>
            <MobileCrumb crumbs={crumbs} />
          </Container>
          <Container data-qa-hook="compilationView" style={{ margin: `${Grid._6} auto` }}>
            <CompilationInformation>
              <Name data-qa-hook="compilationName">{data.compilation.name}</Name>
              <Description data-qa-hook="compilationDescription">{data.compilation.longDescription}</Description>
            </CompilationInformation>
          </Container>
          <Container>
            <HeaderUnderlined className="header-underlined">Contents</HeaderUnderlined>
          </Container>
          {data.compilation.trainingItems.length && data.compilation.trainingItemsDisplayStyle === 'Grid' ? (
            <Container
              className="grid-container grid-col-2 grid-sm-col-6 grid-gap-24"
              style={{ margin: `${Grid._6} auto` }}
            >
              {data.compilation.trainingItems.map(training => (
                <div key={training.id} className="grid-col-span-2 grid-sm-col-span-3 grid-md-col-span-2">
                  <TrainingCard training={training} redirectToTraining={() => redirectToTraining(training.id)} />
                </div>
              ))}
            </Container>
          ) : data.compilation.trainingItems.length && data.compilation.trainingItemsDisplayStyle === 'List' ? (
            <FullWidthCard style={{ padding: '40px 0' }}>
              <Container>
                {data.compilation.trainingItems.map(training => (
                  <TrainingListItem key={training.id} training={training} redirectToTraining={redirectToTraining} />
                ))}
              </Container>
            </FullWidthCard>
          ) : (
            <Container>
              <HelpAndInfoBox
                title="Don't worry, training is on the way"
                description="Authors are curating content for this compilation and it will be visible here soon."
              />
            </Container>
          )}
        </>
      )}
    </OrgProvider>
  );
}

TrainingCompilation.propTypes = {
  org: PropTypes.object,
};

angular.module('lwNamb').component('trainingCompilation', react2angular(TrainingCompilation));
