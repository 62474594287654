angular.module('lwNamb').controller(
  'WorkflowBuilderCtrl',

  [
    '$scope',
    '$routeParams',
    '$log',
    '$localStorage',
    'userService',
    'workflowService',
    'taskListService',
    'alertService',
    '$timeout',
    'analyticsService',
    '$q',
    'buildService',
    function(
      $scope,
      $routeParams,
      $log,
      $localStorage,
      userService,
      workflowService,
      taskListService,
      alertService,
      $timeout,
      analyticsService,
      $q,
      buildService
    ) {
      var ownerId;
      function init() {
        analyticsService.trackFeature('WFBuilder', 'loaded');
        $scope.workflowId = $routeParams.workflowId;
        $scope.loading = true;
        $scope.workflow = {};
        $scope.showToolbox = true;
        $scope.sortableOptions = {
          orderChanged: $scope.orderChanged,
          containerPositioning: 'relative',
          additionalPlaceholderClass: 'panel-boxshadow',
        };
        $scope.ctxRedirectTo = buildService.ctxRedirectTo;
        $scope.ctxRedirectBackTo = buildService.ctxRedirectBackTo;

        //Clear Local Storage (Cache)
        $localStorage.workflowEmail = undefined;

        $q.all([userService.user(), buildService.ownerId()]).then(function(result) {
          var user = result[0];
          ownerId = result[1];
          $scope.user = user;
          loadData();
        });
      }

      $scope.orderChanged = function(event) {
        analyticsService.trackFeature('WFBuilder', 'itemsReordered');
        var movedToIndex = event.dest.index;
        $scope.workflow.entities[movedToIndex].saved = false;
        $scope.workflow.entities[movedToIndex].failed = false;
        var entityIds = [];
        $scope.workflow.entities.forEach(function(entity) {
          entityIds.push(entity.id);
        });
        workflowService.reorderWorkflowEntities($scope.workflowId, entityIds, $scope.user.userId).then(
          function() {
            $scope.workflow.entities[movedToIndex].saved = true;
            $timeout(function() {
              $scope.workflow.entities[movedToIndex].saved = false;
            }, 2000);
          },
          function(reason) {
            $log.error(reason);
            $scope.workflow.entities[movedToIndex].failed = true;
            $timeout(function() {
              $scope.workflow.entities[movedToIndex].failed = false;
            }, 2000);
          }
        );
      };

      function loadData() {
        workflowService.getWorkflow(ownerId, $scope.workflowId).then(
          function success(workflow) {
            $scope.workflow = workflow;
            $scope.showAssignButton =
              !buildService.isAuthoring() &&
              workflow.entities.length > 2 &&
              $scope.user.permissions.indexOf('Assign View') > -1;
            $scope.loading = false;
            if ($scope.workflow.entities.length > 2) {
              $scope.hasTaskList = hasTaskList();
            }
          },
          function failure(reason) {
            $log.error(reason);
            $scope.loading = false;
          }
        );
      }

      function hasTaskList() {
        for (var i = 0; i < $scope.workflow.entities.length; i++) {
          if ($scope.workflow.entities[i]._type === 'TaskList') {
            return true;
          }
        }
        return false;
      }

      $scope.clearForm = function(index) {
        $scope.step = { type: '' };
        $scope.submitted = false;
      };

      //Toggle Published
      $scope.togglePublished = function(published, closeModal) {
        analyticsService.trackFeature('WFBuilder', 'publishWF');
        $scope.spinner = true;
        workflowService
          .togglePublished(ownerId, $scope.workflowId, !published, $scope.user.userId)
          .then(
            function(workflowId) {
              $scope.workflow.published = !published;
              alertService.show({ type: 'success', title: 'Pathway Updated', duration: 5 });
            },
            function(reason) {
              $log.error(reason);
              alertService.show({
                type: 'danger danger-with-content',
                title: 'Unexpected Error!',
                content: 'Something went wrong. Please try again.',
                duration: 20,
              });
            }
          )
          .finally(function() {
            $scope.spinner = false;
            closeModal();
          });
      };

      $scope.redirectToTaskList = function(entity) {
        analyticsService.trackFeature('WFBuilder', 'previewTaskList');
        redirectToListBuilder(entity.taskListId.id);
      };

      $scope.addStep = function(isValid, index, type, name) {
        var path;
        $scope.submitted = true;
        if (!isValid) {
          return;
        }
        index = index || 0;
        if (type === 'NewTaskList') {
          analyticsService.trackFeature('WFBuilder', 'addingStep:NewTaskList');
          $scope.duplicateNameError = false;
          $scope.creating = true;
          createTaskListThenAddToWorkflow(name, index).then(
            function(tasklistId) {
              $scope.creating = false;
              redirectToListBuilder(tasklistId);
            },
            function(reason) {
              $scope.creating = false;
              if (reason === 'DUPLICATE_NAME') {
                $scope.duplicateNameError = true;
              } else {
                $log.error(reason);
                alertService.show({
                  type: 'danger danger-with-content',
                  title: 'Unexpected Error!',
                  content: 'Something went wrong. Please try again.',
                  duration: 20,
                });
              }
            }
          );
        } else {
          switch (type) {
            case 'TaskList':
              $localStorage.searchQueryString = undefined;
              path = '/select';
              break;
            case 'Email':
              path = '/email';
          }
          analyticsService.trackFeature('WFBuilder', 'addingStep:' + type);
          buildService.ctxRedirectTo('/pathways/' + $scope.workflow.id + path, {
            previousId: $scope.workflow.entities[index].id,
            nextId: $scope.workflow.entities[index + 1].id,
          });
        }
      };

      function createTaskListThenAddToWorkflow(name, index) {
        return taskListService.createTasklist(ownerId, name, $scope.user.userId).then(function(tasklistId) {
          return workflowService
            .addTaskListsToWorkflow(
              $scope.workflowId,
              [tasklistId],
              $scope.workflow.entities[index].id,
              $scope.workflow.entities[index + 1].id,
              $scope.user.userId
            )
            .then(function() {
              return tasklistId;
            });
        });
      }

      function checkWorkflowIsEmptyAndUnpublish() {
        if ($scope.workflow.entities.length === 2 && $scope.workflow.published) {
          workflowService.togglePublished(ownerId, $scope.workflowId, false, $scope.user.userId).then(
            function() {
              $scope.workflow.published = false;
              $scope.showAssignButton = false;
            },
            function(reason) {
              $log.error(reason + ': Failed to Unpublish Pathway');
            }
          );
        }
      }

      //Remove Task List from Workflow
      $scope.removeStep = function(entityId, closeModal) {
        $scope.spinner = true;
        workflowService.removeStepFromWorkflow($scope.workflowId, entityId, $scope.user.userId).then(
          function() {
            //Success
            $scope.spinner = false;
            for (var t = 0; t < $scope.workflow.entities.length; t++) {
              if (entityId === $scope.workflow.entities[t].id) {
                analyticsService.trackFeature('WFBuilder', 'removingStep:' + $scope.workflow.entities[t]._type);
                closeModal();
                alertService.show({ type: 'success', title: 'Step Removed', duration: 5 });
                $scope.workflow.entities.splice(t, 1);
                checkWorkflowIsEmptyAndUnpublish();
              }
            }
          },
          function(reason) {
            $log.error(reason);
            closeModal();
            alertService.show({
              type: 'danger danger-with-content',
              title: 'Unexpected Error!',
              content: 'Something went wrong. Please try again.',
              duration: 20,
            });
          }
        );
      };

      function redirectToListBuilder(taskListId) {
        buildService.ctxRedirectTo('/courses/' + taskListId, { workflowId: $scope.workflowId });
      }

      $scope.redirectToEmailStep = function(entityId) {
        buildService.ctxRedirectTo('/pathways/' + $scope.workflowId + '/email', { entityId: entityId });
      };

      $scope.$on('$locationChangeStart', function(event, current, previous) {
        if (current.indexOf('select') > -1) {
          $localStorage.selectTrainingBackHash = previous.split('#')[1];
        }
      });

      init();
    },
  ]
);
