import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { FormField, FormFieldContainer, FormFieldSet } from '../components/FormElements';
import { PrimaryButton } from '../components/Buttons';
import { Grid } from '../StyleGuide';
import assessmentService from '../services/assessmentService';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import uuid from '../services/uuid';
import { PageTitle } from '../components/Layout';
import { useUser } from '../authentication';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute';

const FormContainer = styled.div`
  max-width: 630px;
  margin: ${Grid._6} 0px;
  grid-gap: ${Grid._3};

  legend {
    margin-bottom: ${Grid._4};
  }
`;

const SaveButton = styled(PrimaryButton)`
  margin-top: ${Grid._6};
  width: 260px;
`;

const QuestionFormFieldContainer = styled(FormFieldContainer)`
  margin-bottom: ${Grid._4};
`;

const possibleAnswersArray = [{ id: 'possibleansid', answer: 'text', isCorrect: false }];

const newEmptyQuestion = {
  question: '',
  displayType: 'textbox',
};

const AssessmentShortAnswerView = ({ assessmentId, questionType, question, ownerId }) => {
  const user = useUser();
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();

  const initialQuestionState = question
    ? {
        question: question.questionValue[0].value,
        displayType: question.displayType,
      }
    : newEmptyQuestion;

  const [formData] = useState(initialQuestionState);

  const submitQuestion = (values, { setSubmitting }) => {
    if (question !== undefined) {
      assessmentService
        .updateQuestion(assessmentId, user.userId, question.id.id, values.question, questionType, possibleAnswersArray)
        .then(() => {
          alertService.showOnNextPage('Question Updated');
          windowService.redirectTo(`${manageTrainingRoutePrefix}/assessments/${assessmentId}`);
        })
        .catch(error => {
          console.error(error);
          alertService.showError();
          setSubmitting(false);
        });
    } else {
      assessmentService
        .addQuestion(
          assessmentId,
          ownerId,
          uuid.generate(),
          values.question,
          questionType,
          possibleAnswersArray,
          user.userId
        )
        .then(() => {
          alertService.showOnNextPage('Question Updated');
          windowService.redirectTo(`${manageTrainingRoutePrefix}/assessments/${assessmentId}`);
        })
        .catch(error => {
          console.error(error);
          alertService.showError();
          setSubmitting(false);
        });
    }
  };

  return (
    <>
      <PageTitle className="mt-40">
        <h1>Short Answer</h1>
      </PageTitle>
      <Formik
        initialValues={formData}
        validationSchema={Yup.object({
          question: Yup.string().required('A valid question is required'),
        })}
        onSubmit={submitQuestion}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <FormContainer>
                <FormFieldSet name="Question">
                  <FormField
                    data-qa-hook="questionBuilderName"
                    name="question"
                    as="textarea"
                    rows="5"
                    placeholder="This Is The Question That Will Be Answered"
                    Wrapper={QuestionFormFieldContainer}
                  />
                </FormFieldSet>
                <div>
                  <p className="info-block">
                    <i className="icon ion-android-alert"></i> Short answer questions will not be machine-graded
                  </p>
                </div>
                <SaveButton
                  data-qa-hook="questionBuilderSave"
                  type="submit"
                  disabled={isSubmitting}
                  operating={isSubmitting}
                >
                  Save Question
                </SaveButton>
              </FormContainer>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AssessmentShortAnswerView;

AssessmentShortAnswerView.propTypes = {
  assessmentId: PropTypes.string.isRequired,
  questionType: PropTypes.string.isRequired,
  question: PropTypes.object,
  ownerId: PropTypes.string.isRequired,
};
