import styled from 'styled-components';
import { Grid, Border, Breakpoints, Color, Depth, Shadow, Transition, Type } from '../StyleGuide';

const Section = styled.section`
  color: ${Type.Color.dark};
  margin: 0 0 ${Grid._12};
  padding: ${Grid._5};
  background-color: ${Color.white};
  box-shadow: ${Shadow.light};
  width: 100%;

  h2 {
    font-size: ${Type.Scale._5};
    font-weight: ${Type.Weight.normal};
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      font-size: ${Type.Scale._6};
    }
  }
  p {
    margin: ${Grid._5} auto;
    font-size: ${Type.Scale._3};
    line-height: ${Type.Leading.tall};
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      font-size: ${Type.Scale._4};
    }
    &.text-color-medium {
      color: ${Type.Color.medium};
    }
  }
  .text-color-medium > p {
    color: ${Type.Color.medium};
  }
  ul,
  ol {
    font-size: ${Type.Scale._3};
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      font-size: ${Type.Scale._4};
    }
  }
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    width: 90%;
    border: 1px solid ${Color.Gray._10};
    border-radius: ${Border.radius};
    margin: ${Grid._10} auto ${Grid._14};
    padding: ${Grid._5} ${Grid._12};
  }
  @media screen and (min-width: ${Breakpoints.screen_lg}) {
    width: 100%;
    max-width: 800px;
  }
`;

export default Section;
