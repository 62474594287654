angular.module('lwNamb').directive('cancelSubscription', [
  function() {
    return {
      restrict: 'A',
      templateUrl: 'partials/directives/cancel-subscription.html',
      controller: [
        '$scope',
        '$log',
        'licenseService',
        'alertService',
        'windowService',
        function($scope, $log, licenseService, alertService, windowService) {
          $scope.cancelSubscription = function(license, closeModal) {
            $scope.cancelling = true;
            licenseService
              .cancelSubscription(license.subscription.id, $scope.user.userId)
              .then(
                function() {
                  alertService.show({ title: 'Subscription Cancelled', type: 'success', duration: 5 });
                  license.subscription.status = 'Inactive';
                  if (license.seats.count.total > 1) {
                    windowService.redirectHash('/licenses/' + $scope.licenseId);
                  }
                },
                function(reason) {
                  alertService.show({
                    title: 'Unexpected Error!',
                    content:
                      'We have encountered a problem updating your account. Please call 866-627-8553 to resolve.',
                    type: 'danger danger-with-content',
                    duration: 20,
                  });
                  $log.error(reason);
                }
              )
              .finally(function() {
                $scope.cancelling = false;
                $scope.retrieveUserData();
                closeModal();
              });
          };
        },
      ],
    };
  },
]);
