import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { react2angular } from 'react2angular';

const CircularProfileImage = styled.img`
  border-radius: 50%;
`;

export default function ProfileImage(props) {
  return props.src ? <CircularProfileImage {...props} /> : <span className="fas fa-user-circle"></span>;
}

ProfileImage.propTypes = {
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onLoad: PropTypes.func,
};

angular.module('lwNamb').component('profileImage', react2angular(ProfileImage));
