import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Type, Border, Color } from '../StyleGuide';
import { formatCurrency } from '../utils/textUtils';

const TierInfo = styled.p`
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.light};
  color: ${Type.Color.medium};
`;

const TierName = styled(TierInfo)`
  text-transform: capitalize;
  font-size: ${Type.Scale._8};
  font-weight: ${Type.Weight.semibold};
  color: ${Type.Color.dark};
  margin: -${Grid._4} 0 0;
`;

const TierPrice = styled(TierInfo)`
  font-size: 96px;
  font-weight: ${Type.Weight.semibold};
  margin: 0;
  &:before,
  &:after {
    position: relative;
  }
  &:before {
    content: '$';
    top: -45px;
    font-size: ${Type.Scale._6};
  }
  &:after {
    content: 'monthly';
    top: -55px;
    font-size: ${Type.Scale._4};
    font-weight: ${Type.Weight.light};
  }
`;

const TierBilling = styled(TierInfo)`
  font-size: ${Type.Scale._2};
  margin: -${Grid._5} 0 ${Grid._5} 0;
`;

const TierLabel = styled(TierInfo)`
  font-size: ${Type.Scale._5};
`;

const TierRange = styled(TierInfo)`
  font-size: ${Type.Scale._7};
  font-weight: ${Type.Weight.semibold};
  margin: 0;
`;

const SavingsBanner = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  border-radius: ${Border.radius} ${Border.radius} 0 0;
  padding: ${Grid._2} 0;
  background: ${Color.Primary._50};
  text-align: center;
  text-transform: uppercase;
  color: white;
  font-size: ${Type.Scale._1};
  font-weight: ${Type.Weight.semibold};
`;

const Tier = styled.label`
  flex: 1 1 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: ${Color.Gray._10};
  border: 2px solid transparent;
  padding: ${Grid._10} ${Grid._5};
  color: ${Type.Color.dark};
  margin: ${Grid._4} 0;
  &:first-child {
    border-radius: ${Border.radius} 0 0 ${Border.radius};
  }
  &:last-child {
    border-radius: 0 ${Border.radius} ${Border.radius} 0;
  }
  & + label:not(.selected) {
    border-left: 1px solid ${Color.Gray._30};
  }
  &.selected {
    background: white;
    border: 2px solid ${Color.Gray._80};
    border-radius: ${Border.radius};
    margin: 0;
    height: auto;
  }
  &.selected + label {
    border-left-color: transparent;
  }
`;

const SelectTier = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.normal};
  text-transform: capitalize;
  padding: ${Grid._5} 0;
  color: ${Type.Color.dark};
  border-top: 1px solid ${Color.Gray._30};
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const RadioIcon = styled.span`
  height: 25px;
  width: 25px;
  border: 1px solid ${Type.Color.dark};
  border-radius: 50%;
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
  &.selected:after {
    display: block;
    position: relative;
    top: 3px;
    left: 3px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background: ${Color.Gray._80};
  }
`;

const SizeTiersContainer = styled.div`
  margin: ${Grid._5} 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > label {
    max-width: 260px;
    padding: 64px ${props => (props.numTiers > 3 ? '16px' : '40px')};
  }
`;

const PlanTiersContainer = styled.div`
  margin: ${Grid._5} 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  align-items: start;
`;

const SELECTED_CLASSNAME = 'selected';

function SizeTier({ size, isSelected, onSelect }) {
  return (
    <Tier
      data-qa-hook="selectableTier"
      className={isSelected && SELECTED_CLASSNAME}
      onChange={event => onSelect(event.target.value)}
    >
      <TierRange data-qa-hook="sizeTierRange">{size}</TierRange>
      <TierLabel>Attendees</TierLabel>
      <SelectTier>
        {isSelected ? 'Selected' : 'Select'}
        <Input type="radio" value={size} name="tiers" />
        <RadioIcon className={isSelected && SELECTED_CLASSNAME} />
      </SelectTier>
    </Tier>
  );
}

SizeTier.propTypes = {
  size: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export function SizeTiers({ tiers, selectedTier, setSelectedTier }) {
  return (
    <SizeTiersContainer numTiers={tiers.length}>
      {tiers.map(tier => (
        <SizeTier key={tier.size} size={tier.size} isSelected={selectedTier === tier.size} onSelect={setSelectedTier} />
      ))}
    </SizeTiersContainer>
  );
}

SizeTiers.propTypes = {
  tiers: PropTypes.array.isRequired,
  selectedTier: PropTypes.string.isRequired,
  setSelectedTier: PropTypes.func.isRequired,
};

function PlanTier({ name, subtitle, price, savings, period, size, isSelected, onSelect, children }) {
  const isYearly = period === 'year';

  return (
    <Tier
      data-qa-hook="selectableTier"
      className={isSelected && SELECTED_CLASSNAME}
      onChange={event => onSelect(event.target.value)}
    >
      {savings && (
        <SavingsBanner>
          Save ${formatCurrency(savings)} a {period}
        </SavingsBanner>
      )}
      {subtitle && <TierInfo>{subtitle}</TierInfo>}
      <TierName>{name}</TierName>
      <TierPrice>{isYearly ? Math.ceil(price / 12) : price}</TierPrice>
      <TierBilling>{isYearly ? `Billed yearly at $${formatCurrency(price)}` : <>&nbsp;</>}</TierBilling>
      <TierInfo>Access for {size} average attendees </TierInfo>
      {children}
    </Tier>
  );
}

PlanTier.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  price: PropTypes.string.isRequired,
  savings: PropTypes.string,
  period: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  children: PropTypes.element,
};

export function SinglePlanTier({ curriculumName, tier }) {
  return (
    <PlanTiersContainer>
      <PlanTier
        key={tier.package}
        name={curriculumName}
        price={tier.price}
        savings={tier.savings}
        period={tier.period}
        size={tier.size}
        isSelected={true}
        onSelect={() => {}}
      >
        <SelectTier>
          Plan Selected
          <Input type="radio" value="" name="tiers" />
          <RadioIcon className={SELECTED_CLASSNAME} />
        </SelectTier>
      </PlanTier>
    </PlanTiersContainer>
  );
}

SinglePlanTier.propTypes = {
  curriculumName: PropTypes.string.isRequired,
  tier: PropTypes.object.isRequired,
};

export function PlanTiers({ curriculumName, tiers, selectedTier, setSelectedTier }) {
  return (
    <PlanTiersContainer>
      {tiers.map(tier => {
        const isSelected = selectedTier === tier.package;

        return (
          <PlanTier
            key={tier.package}
            name={tier.package}
            subtitle={curriculumName}
            price={tier.price}
            savings={tier.savings}
            period={tier.period}
            size={tier.size}
            isSelected={isSelected}
            onSelect={setSelectedTier}
          >
            <SelectTier>
              {isSelected ? `${tier.package} Plan Selected` : `Select ${tier.package} Plan`}
              <Input type="radio" value={tier.package} name="tiers" />
              <RadioIcon className={isSelected && SELECTED_CLASSNAME} />
            </SelectTier>
          </PlanTier>
        );
      })}
    </PlanTiersContainer>
  );
}

PlanTiers.propTypes = {
  curriculumName: PropTypes.string.isRequired,
  tiers: PropTypes.array.isRequired,
  selectedTier: PropTypes.string.isRequired,
  setSelectedTier: PropTypes.func.isRequired,
};
