import React from 'react';
import { MediumButton, MediumPrimaryButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';
import windowService from '../services/windowService';

type Props = {
  handleDismiss: () => void;
};

export const PreviewRestrictionModal = ({ handleDismiss }: Props) => {
  const redirectToBibleStudies = () => {
    windowService.redirectTo('/bible-studies');
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>Curriculum Preview</h3>
      </ModalHeader>
      <ModalBody>
        <p className="modal-component-subtext">This feature is unavailable during the preview</p>
        <p>To unlock all the features of digital curriculum, subscribe today!</p>
      </ModalBody>
      <ModalFooter>
        <MediumButton onClick={handleDismiss}>Back to Preview</MediumButton>
        <MediumPrimaryButton onClick={redirectToBibleStudies}>Explore Bible Studies</MediumPrimaryButton>
      </ModalFooter>
    </ModalContainer>
  );
};
