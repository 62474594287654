import { Grid, Type } from '../StyleGuide';
import styled from 'styled-components';

const BibleStudyDescription = styled.p`
  font-size: ${Type.Scale._2};
  font-weight: ${Type.Weight.light};
  text-align: center;
  color: ${props => (props.theme === 'light' ? 'white' : Type.Color.dark)};
  white-space: pre-line;
  margin: 0;
  padding: ${Grid._5} 0;
  text-shadow: ${props => (props.theme === 'light' ? Type.Shadow.header : 'none')};
`;

export default BibleStudyDescription;
