import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Color } from '../StyleGuide';

import windowService from '../services/windowService';
import alertService from '../services/AlertService';
import assessmentService from '../services/assessmentService';
import { useUser } from '../authentication';
import useManageTrainingRoute from '../hooks/useManageTrainingRoute.js';

import { SmallRoundedIconButton } from '../components/Buttons';
import { OrderingDragContainer, OrderingDragItem, OrderingDragHandle } from '../components/OrderingElements';
import CopyQuestionModal from './CopyQuestionModal';
import DeleteQuestionModal from './RemoveQuestionModal.js';

const QuestionRowDiv = styled.div`
  display: flex;
  width: 100%;
  padding: ${Grid._3};
  border-bottom: 1px solid ${Color.Gray._30};
  align-items: center;
`;

const IconDiv = styled.div`
  padding: ${Grid._3};
`;

const TextDiv = styled.div`
  padding: ${Grid._2};
  margin-left: ${Grid._5};
`;

const QuestionTitleText = styled.h4`
  color: ${Color.Gray._95};
`;

const QuestionTypeText = styled.p`
  color: ${Color.Gray._80};
`;

export const ButtonRowWrapper = styled.div`
  display: flex;
  margin-left: auto;

  & > * {
    margin: ${Grid._2};
    padding: ${Grid._2};
  }
`;

const InformationDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InformationHeader = styled.h1`
  align-self: center;
`;

const InformationText = styled.p`
  align-self: center;
`;

const AssessmentQuestionsList = ({
  assessment,
  assessmentQuestions,
  assessmentId,
  setAssessment,
  setFilteredQuestions,
  isFiltering,
}) => {
  const user = useUser();
  const [copyQuestionModalOpen, setCopyQuestionModalOpen] = useState(false);
  const [removeQuestionModalOpen, setRemoveQuestionModalOpen] = useState(false);
  const [clickedQuestion, setClickedQuestion] = useState();
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();

  const copyQuestion = question => {
    setCopyQuestionModalOpen(true);
    setClickedQuestion(question);
  };

  const removeQuestion = question => {
    setRemoveQuestionModalOpen(true);
    setClickedQuestion(question);
  };

  const questionIcon = questionType => {
    switch (questionType) {
      case 'radio':
        return <i className="fas hidden-xs icon-medium fa-list-ul"></i>;
      case 'textbox':
        return <i className="fas hidden-xs icon-medium fa-font"></i>;
    }
  };

  const questionType = questionType => {
    switch (questionType) {
      case 'radio':
        return 'Multiple Choice';
      case 'textbox':
        return 'Short Answer';
    }
  };

  const updateItemIndex = (questions, fromIndex, toIndex) => {
    const questionListCopy = [...questions];
    questionListCopy.splice(toIndex, 0, questionListCopy.splice(fromIndex, 1)[0]);
    setFilteredQuestions(questionListCopy);

    const seqOfQuestions = questionListCopy.reduce((prev, current, index) => {
      prev[current.id.id] = index;
      return prev;
    }, {});

    assessmentService
      .reorderQuestions(assessmentId, seqOfQuestions, user.userId)
      .then(() => {
        alertService.show('Questions Reordered');
      })
      .catch(error => {
        console.error(error);
        alertService.showError();
        setFilteredQuestions(questions);
      });
  };

  return (
    <>
      {assessmentQuestions.length === 0 && isFiltering ? (
        <InformationDiv>
          <InformationHeader>Your search does not have any matches</InformationHeader>
          <InformationText>Please try another search</InformationText>
        </InformationDiv>
      ) : assessmentQuestions.length === 0 ? (
        <InformationDiv>
          <InformationHeader>No Questions Added</InformationHeader>
          <InformationText>To add questions, use the add button</InformationText>
        </InformationDiv>
      ) : (
        <OrderingDragContainer
          data-qa-hook="assessmentQuestionResultList"
          onSortEnd={({ oldIndex, newIndex }) => updateItemIndex(assessmentQuestions, oldIndex, newIndex)}
          useDragHandle
        >
          {assessmentQuestions.map((question, i) => (
            <OrderingDragItem key={question.id.id} index={i} data-qa-hook="assessmentQuestion">
              <QuestionRowDiv>
                <IconDiv>{questionIcon(question.questionType)}</IconDiv>
                <TextDiv>
                  <div>
                    <QuestionTitleText data-qa-hook="assessmentQuestionName">
                      {question.questionValue[0].value}
                    </QuestionTitleText>
                  </div>
                  <div>
                    <QuestionTypeText data-qa-hook="assessmentQuestionType">
                      {questionType(question.questionType)}
                    </QuestionTypeText>
                  </div>
                </TextDiv>
                <ButtonRowWrapper>
                  {!assessment.published && (
                    <SmallRoundedIconButton
                      data-qa-hook="assessmentQuestionCopy"
                      onClick={() => copyQuestion(question)}
                    >
                      <i className="fas fa-copy"></i>
                    </SmallRoundedIconButton>
                  )}
                  <SmallRoundedIconButton
                    data-qa-hook="assessmentQuestionEdit"
                    onClick={() =>
                      windowService.redirectTo(
                        `${manageTrainingRoutePrefix}/assessments/${assessmentId}/questions/${question.id.id}?questionType=${question.questionType}`
                      )
                    }
                  >
                    <i className="fas fa-pencil-alt"></i>
                  </SmallRoundedIconButton>
                  {!assessment.published && !isFiltering && <OrderingDragHandle />}
                  {!assessment.published && (
                    <SmallRoundedIconButton
                      data-qa-hook="assessmentQuestionRemove"
                      onClick={() => removeQuestion(question)}
                    >
                      <i className="far fa-trash-alt"></i>
                    </SmallRoundedIconButton>
                  )}
                </ButtonRowWrapper>
              </QuestionRowDiv>
            </OrderingDragItem>
          ))}
        </OrderingDragContainer>
      )}
      {copyQuestionModalOpen && (
        <CopyQuestionModal
          question={clickedQuestion}
          assessment={assessment}
          assessmentId={assessmentId}
          setCopyQuestionModalOpen={setCopyQuestionModalOpen}
        />
      )}
      {removeQuestionModalOpen && (
        <DeleteQuestionModal
          question={clickedQuestion}
          assessmentId={assessmentId}
          setRemoveQuestionModalOpen={setRemoveQuestionModalOpen}
          setAssessment={setAssessment}
        />
      )}
    </>
  );
};

AssessmentQuestionsList.propTypes = {
  assessmentQuestions: PropTypes.array.isRequired,
  assessmentId: PropTypes.string.isRequired,
  assessment: PropTypes.object.isRequired,
  setAssessment: PropTypes.func.isRequired,
  setFilteredQuestions: PropTypes.func.isRequired,
  isFiltering: PropTypes.bool.isRequired,
};

export default AssessmentQuestionsList;
