import styled from 'styled-components';
import { Grid, Color, Type, Shadow, Breakpoints, Border, Transition } from '../../StyleGuide';
import { Container } from '../../components/Layout';
import ThumbnailIcon from '../../components/ThumbnailIcon';
import { LinkStyleButton } from '../../components/Buttons';

export const SearchContainer = styled(Container)`
  margin: ${Grid._7} auto ${Grid._10} auto;
`;

export const SearchResultItemContainer = styled.div`
  margin: ${Grid._4} 0;
  padding: ${Grid._3} ${Grid._4};
  display: flex;
  box-shadow: ${Shadow.medium};
  background: ${Color.white};
  border-radius: ${Border.radius};
  cursor: pointer;

  > div:first-child {
    display: none;
    margin: ${Grid._4};
    flex-shrink: 0;

    ${ThumbnailIcon} {
      margin: 0;
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${Grid._3};
    flex-grow: 1;

    h4,
    p {
      margin: 0;
    }

    &:hover {
      h4 {
        text-decoration: underline;
      }
    }
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding-left: 0;

    > div:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  & span p {
    color: ${Color.Gray._70};
  }
`;

export const SearchResults = styled.div`
  display: grid;
  row-gap: ${Grid._4};
  grid-template-columns: 1fr;
  grid-template-areas:
    'filters'
    'training';

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 1fr 250px;
    column-gap: ${Grid._6};

    grid-template-areas:
      'filters filterButtons'
      'training training';
  }

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    grid-template-areas:
      'filters filterButtons'
      'training facets';
  }
`;

export const SearchResultsListContainer = styled.div`
  width: 100%;
  grid-area: training;
`;

export const FacetListSidebarOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: ${Color.black};
  opacity: 0.5;
  transition: ${Transition.normal};
  top: 0;
  left: 0;
  z-index: 1000;
`;

export const FacetContainer = styled.div`
  h5 {
    padding-bottom: ${Grid._2};
    color: ${Type.Color.mediumDark};
    border-bottom: 1px solid ${Color.Gray._20};
  }

  ul {
    margin-bottom: ${Grid._6};
    padding: 0;
  }

  li {
    display: flex;
    margin-bottom: ${Grid._3};
    padding: ${Grid._2};
    color: ${Type.Color.medium};
    border-radius: ${Border.radius};
    cursor: pointer;
    list-style: none;

    &:hover {
      background: ${Color.Gray._30};
    }

    &.facet-value--selected {
      background: ${Color.Gray._70};
      color: ${Type.Color.white};

      &::after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f057';
        margin-left: auto;
      }
    }
  }
`;

export const FacetListContainer = styled.div<{ show: boolean }>`
  position: fixed;
  background: ${Color.white};
  top: 0;
  right: ${props => (props.show ? '0' : '-300px')};
  width: 300px;
  max-width: 70vw;
  z-index: 1001;
  padding: ${Grid._4};
  transition: all 400ms ease-in-out;
  height: 100%;
  overflow-y: auto;

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    align-self: start;
    grid-area: facets;
    background: none;
    width: auto;
    height: auto;
    padding: 0;
    position: initial;
    z-index: 1;
  }
`;

export const SearchControls = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: ${Grid._4};
  grid-column-gap: ${Grid._6};

  > div:first-child {
    order: 2;
  }

  > div:last-child {
    order: 1;
  }

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    grid-template-columns: 1fr 250px;

    > div:first-child {
      order: 1;
    }

    > div:last-child {
      order: 2;
    }
  }
`;

export const FacetListSidebarToggleButton = styled(LinkStyleButton)`
  @media screen and (min-width: ${Breakpoints.screen_md}) {
    display: none;
  }
`;

export const SearchControlsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    justify-content: center;
  }
`;

export const FreeBadge = styled.span`
  color: ${Type.Color.medium};
  margin-left: ${Grid._3};
  background: rgba(0, 0, 0, 0.025);
  padding: ${Grid._2} ${Grid._3};
  border-radius: ${Grid._7};
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-size: ${Type.Scale._05};
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

export const SearchAccessDetails = styled.div`
  display: flex;
  align-items: center;
`;

export const FacetListSidebarHeader = styled.h4`
  color: ${Type.Color.mediumDark};
  margin-bottom: ${Grid._5};

  i {
    margin-right: ${Grid._2};
  }
`;

export const SearchBar = styled.div`
  display: grid;
  grid-row-gap: ${Grid._4};
  grid-column-gap: ${Grid._4};
  margin-bottom: ${Grid._4};

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 1fr 220px;
  }

  @media screen and (min-width: ${Breakpoints.screen_md}) {
    grid-template-columns: 1fr 250px;
    grid-column-gap: ${Grid._6};
  }
`;
