angular.module('lwNamb').factory(
  'assessBuilderService',

  [
    '$rootScope',
    'api',
    'qt',
    'uuid4',
    'CacheFactory',
    'transformer',
    'commandSubmissionService',
    function($rootScope, api, qt, uuid4, CacheFactory, transformer, commandSubmissionService) {
      var timeoutSeconds = 15,
        cache = CacheFactory('assessment', {
          maxAge: 60 * 1000, // 1 minute
          deleteOnExpire: 'aggressive',
        });

      return {
        getAssessment: function(assessmentId, userId) {
          var deferred;
          if (cache.get(assessmentId)) {
            deferred = qt.defer();
            deferred.resolve(cache.get(assessmentId));
            return deferred.promise;
          } else {
            return getAndCacheAssessment(assessmentId, userId);
          }
        },
        createAssessment: function(assessmentId, initiatingUserId, ownerId, name) {
          var gettingAssessments,
            deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          api
            .post('/v1/commands/CreateAssessment', {
              id: assessmentId,
              initiatingUserId: initiatingUserId,
              ownerId: ownerId,
              name: name,
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('AssessmentCreated', function(name, event) {
            if (event.id == assessmentId) {
              deferred.resolve();
            }
          });
          $rootScope.$on('AssessmentNameDuplicateError', function() {
            deferred.reject('AssessmentNameTaken');
          });

          // check for success by polling assessments since AssessmentCreated is not reliable
          deferred.promise.then(null, null, function(elapsedSeconds) {
            if (elapsedSeconds > 1 && !gettingAssessments) {
              gettingAssessments = true;
              getAndCacheAssessment(assessmentId, initiatingUserId).then(
                function(assessment) {
                  gettingAssessments = false;
                  deferred.resolve();
                },
                function() {
                  gettingAssessments = false;
                }
              );
            }
          });

          return deferred.promise;
        },
        deleteAssessment: function(assessment, orgId, userId) {
          return commandSubmissionService.submitAndWait(
            assessment.id,
            {
              id: assessment.id,
              deletedBy: orgId + '_' + userId,
              initiatingUserId: userId,
            },
            'DeleteAssessment',
            'AssessmentDeleted',
            'AssessmentError'
          );
        },
        clone: function(assessmentIdToClone, newName, owner, initiatingUserId) {
          var gettingAssessments,
            deferred = qt.defer({ timeoutSeconds: timeoutSeconds }),
            newId = uuid4.generate();

          api
            .post('/v1/commands/CloneAssessment', {
              id: newId,
              initiatingUserId: initiatingUserId,
              fromId: assessmentIdToClone,
              newName: newName,
              owner: owner,
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('AssessmentNameUpdated', function(name, event) {
            if (event.id == newId && event.name[0].value == newName) {
              deferred.resolve(newId);
            }
          });
          $rootScope.$on('AssessmentFailedToClone', function(name, event) {
            if (event.id == newId) {
              deferred.reject('AssessmentFailedToClone');
            }
          });
          $rootScope.$on('AssessmentNameDuplicateError', function(name, event) {
            if (event.id == newId) {
              deferred.reject('DUPLICATE_NAME');
            }
          });

          return deferred.promise;
        },
        updateAssessmentName: function(assessmentId, initiatingUserId, ownerId, name) {
          var gettingAssessments,
            deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          cache.remove(assessmentId);

          api
            .post('/v1/commands/UpdateAssessmentName', {
              id: assessmentId,
              initiatingUserId: initiatingUserId,
              owner: ownerId,
              assessmentName: name,
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('AssessmentNameUpdated', function(name, event) {
            if (event.id == assessmentId) {
              deferred.resolve();
            }
          });
          $rootScope.$on('AssessmentNameDuplicateError', function() {
            deferred.reject('AssessmentNameTaken');
          });

          // check for success by polling assessments since AssessmentCreated is not reliable
          deferred.promise.then(null, null, function(elapsedSeconds) {
            if (elapsedSeconds > 1 && !gettingAssessments) {
              gettingAssessments = true;
              getAndCacheAssessment(assessmentId, initiatingUserId).then(
                function(assessment) {
                  gettingAssessments = false;
                  if (assessment.title === name) {
                    gettingAssessments = false;
                    deferred.resolve();
                  }
                },
                function() {
                  gettingAssessments = false;
                }
              );
            }
          });

          return deferred.promise;
        },
        updateAssessmentIntro: function(assessmentId, initiatingUserId, intro) {
          var gettingAssessments,
            deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          cache.remove(assessmentId);

          api
            .post('/v1/commands/UpdateAssessmentIntro', {
              id: assessmentId,
              initiatingUserId: initiatingUserId,
              instructions: intro,
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('AssessmentIntroUpdated', function(name, event) {
            if (event.id == assessmentId) {
              deferred.resolve();
            }
          });

          deferred.promise.then(null, null, function(elapsedSeconds) {
            if (elapsedSeconds > 1 && !gettingAssessments) {
              gettingAssessments = true;
              getAndCacheAssessment(assessmentId, initiatingUserId).then(
                function(assessment) {
                  gettingAssessments = false;
                  if (assessment.instructions === intro) {
                    gettingAssessments = false;
                    deferred.resolve();
                  }
                },
                function() {
                  gettingAssessments = false;
                }
              );
            }
          });

          return deferred.promise;
        },
        updateAssessmentOutro: function(assessmentId, initiatingUserId, outro) {
          var gettingAssessments,
            deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          cache.remove(assessmentId);

          api
            .post('/v1/commands/UpdateAssessmentOutro', {
              id: assessmentId,
              initiatingUserId: initiatingUserId,
              overview: outro,
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('AssessmentOutroUpdated', function(name, event) {
            if (event.id == assessmentId) {
              deferred.resolve();
            }
          });

          deferred.promise.then(null, null, function(elapsedSeconds) {
            if (elapsedSeconds > 1 && !gettingAssessments) {
              gettingAssessments = true;
              getAndCacheAssessment(assessmentId, initiatingUserId).then(
                function(assessment) {
                  gettingAssessments = false;
                  if (assessment.overview === outro) {
                    deferred.resolve();
                  }
                },
                function() {
                  gettingAssessments = false;
                }
              );
            }
          });

          return deferred.promise;
        },
        setResponseVisibility: function(assessmentId, initiatingUserId, responseVisibility) {
          var gettingAssessments,
            deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          cache.remove(assessmentId);

          api
            .post('/v1/commands/SetResponseVisibility', {
              id: assessmentId,
              initiatingUserId: initiatingUserId,
              responseVisibility: responseVisibility,
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('ResponseVisibilitySet', function(name, event) {
            if (event.id == assessmentId) {
              deferred.resolve();
            }
          });

          deferred.promise.then(null, null, function(elapsedSeconds) {
            if (elapsedSeconds > 1 && !gettingAssessments) {
              gettingAssessments = true;
              getAndCacheAssessment(assessmentId, initiatingUserId).then(
                function(assessment) {
                  gettingAssessments = false;
                  if (assessment.responseVisibility === responseVisibility) {
                    deferred.resolve();
                  }
                },
                function() {
                  gettingAssessments = false;
                }
              );
            }
          });

          return deferred.promise;
        },
        removeQuestion: function(assessmentId, questionId, initiatingUserId) {
          var gettingAssessments,
            deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          cache.remove(assessmentId);

          api
            .post('/v1/commands/RemoveQuestionFromAssessment', {
              id: assessmentId,
              initiatingUserId: initiatingUserId,
              questionId: questionId,
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('QuestionRemovedFromAssessment', function(name, event) {
            if (event.id == assessmentId) {
              deferred.resolve();
            }
          });

          deferred.promise.then(null, null, function(elapsedSeconds) {
            if (elapsedSeconds > 1 && !gettingAssessments) {
              gettingAssessments = true;
              getAndCacheAssessment(assessmentId, initiatingUserId).then(
                function(assessment) {
                  gettingAssessments = false;
                  var removed = true;
                  for (var i = 0; i < assessment.questions.length; i++) {
                    if (assessment.questions[i].id.id === questionId) {
                      removed = false;
                    }
                  }
                  if (removed) {
                    deferred.resolve();
                  }
                },
                function() {
                  gettingAssessments = false;
                }
              );
            }
          });

          return deferred.promise;
        },
        addQuestion: function(
          assessmentId,
          ownerId,
          questionId,
          questionText,
          questionType,
          possibleAnswers,
          initiatingUserId,
          displayType
        ) {
          var gettingAssessments,
            deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          cache.remove(assessmentId);

          var payload = {
            id: assessmentId,
            initiatingUserId: initiatingUserId,
            ownerId: ownerId,
            questionId: questionId,
            questionText: questionText,
            questionType: questionType,
            possibleAnswers: possibleAnswers,
          };

          if (displayType !== undefined) {
            payload.displayType = displayType;
          }

          api.post('/v1/commands/AddQuestionToAssessment', payload).then(null, function(reason) {
            deferred.reject(reason);
          });

          $rootScope.$on('QuestionAddedToAssessment', function(name, event) {
            if (event.id == assessmentId) {
              deferred.resolve();
            }
          });

          $rootScope.$on('AssessmentError', function() {
            deferred.reject('AssessmentError');
          });

          deferred.promise.then(null, null, function(elapsedSeconds) {
            if (elapsedSeconds > 1 && !gettingAssessments) {
              gettingAssessments = true;
              getAndCacheAssessment(assessmentId, initiatingUserId).then(
                function(assessment) {
                  gettingAssessments = false;
                  var added = false;
                  for (var i = 0; i < assessment.questions.length; i++) {
                    if (assessment.questions[i].id.id === questionId) {
                      added = true;
                    }
                  }
                  if (added) {
                    deferred.resolve();
                  }
                },
                function() {
                  gettingAssessments = false;
                }
              );
            }
          });

          return deferred.promise;
        },
        updateQuestion: function(
          assessmentId,
          questionId,
          questionText,
          questionType,
          possibleAnswers,
          initiatingUserId,
          displayType
        ) {
          var gettingAssessments,
            deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          cache.remove(assessmentId);

          var payload = {
            id: assessmentId,
            initiatingUserId: initiatingUserId,
            questionId: questionId,
            questionText: questionText,
            questionType: questionType,
            possibleAnswers: possibleAnswers,
          };

          if (displayType !== undefined) {
            payload.displayType = displayType;
          }

          api.post('/v1/commands/UpdateQuestionForAssessment', payload).then(null, function(reason) {
            deferred.reject(reason);
          });

          $rootScope.$on('QuestionForAssessmentUpdated', function(name, event) {
            if (event.id == assessmentId) {
              deferred.resolve();
            }
          });
          $rootScope.$on('AssessmentError', function() {
            deferred.reject('AssessmentError');
          });

          deferred.promise.then(null, null, function(elapsedSeconds) {
            if (elapsedSeconds > 1 && !gettingAssessments) {
              gettingAssessments = true;
              getAndCacheAssessment(assessmentId, initiatingUserId).then(
                function(assessment) {
                  gettingAssessments = false;
                  var updated = false;
                  for (var h = 0; h < assessment.questions.length; h++) {
                    if (
                      assessment.questions[h].id.id === questionId &&
                      assessment.questions[h].question === questionText &&
                      possibleAnswers.length === assessment.questions[h].choices.length
                    ) {
                      for (var i = 0; i < possibleAnswers.length; i++) {
                        for (var j = 0; j < assessment.questions[h].choices.length; j++) {
                          if (
                            possibleAnswers[i].id === assessment.questions[h].choices[j].id &&
                            possibleAnswers[i].isCorrect === assessment.questions[h].choices[j].selected
                          ) {
                            updated = true;
                            break;
                          }
                        }
                      }
                      break;
                    }
                  }
                  if (updated) {
                    deferred.resolve();
                  }
                },
                function() {
                  gettingAssessments = false;
                }
              );
            }
          });

          return deferred.promise;
        },
        publishAssessment: function(assessmentId, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          cache.remove(assessmentId);

          api
            .post('/v1/commands/PublishAssessment', {
              id: assessmentId,
              initiatingUserId: initiatingUserId,
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('AssessmentPublished', function(name, event) {
            if (event.id === assessmentId) {
              deferred.resolve();
            }
          });

          return deferred.promise;
        },
        reorderQuestions: function(assessId, seqOfQuestions, initiatingUserId) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

          api
            .post('/v1/commands/ReorderQuestions', {
              id: assessId,
              newSeqOfQuestions: seqOfQuestions,
              initiatingUserId: initiatingUserId,
            })
            .then(null, function(reason) {
              deferred.reject(reason);
            });

          $rootScope.$on('QuestionsReordered', function(name, event) {
            if (event.id === assessId && angular.equals(event.newSeqOfQuestions, seqOfQuestions)) {
              deferred.resolve();
            }
          });

          $rootScope.$on('AssessmentError', function(name, event) {
            if (event.id === assessId) {
              deferred.reject();
            }
          });

          return deferred.promise;
        },
      };

      function getAndCacheAssessment(assessmentId, userId) {
        return api.get('/v1/assessments/' + assessmentId + '?userId=' + userId).then(function(responseData) {
          var assessment = {};
          if (responseData && responseData.data && responseData.data.assessment) {
            assessment = transformer.transformAssessment(responseData.data.assessment);
            cache.put(assessmentId, assessment);
          }
          return assessment;
        });
      }
    },
  ]
);
