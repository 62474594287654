import React, { useState } from 'react';
import { LinkStyleButton } from '../../components/Buttons';
import { useSearch } from '../hooks/useSearch';
import { SearchResultItem } from '../models/Search';
import { ActiveSearchFilters } from './ActiveSearchFilters';
import { FacetList } from './FacetList';
import { FacetListSidebar } from './FacetListSidebar';
import {
  FacetListSidebarToggleButton,
  SearchBar,
  SearchControls,
  SearchControlsContainer,
  SearchResults,
  SearchResultsListContainer,
} from './SearchElements';
import { SearchInput } from './SearchInput';
import { SearchResultsList } from './SearchResultsList';

const ResetSearchButton = () => {
  const { resetSearch } = useSearch();
  return <LinkStyleButton onClick={resetSearch}>Reset Search</LinkStyleButton>;
};

type Props = {
  children?: (results: SearchResultItem[]) => React.ReactNode;
};

export const Search = ({ children }: Props) => {
  const [showMobileSidebar, setShowMobileSidebar] = useState(false);
  const openMobileSidebar = () => setShowMobileSidebar(true);
  const closeMobileSidebar = () => setShowMobileSidebar(false);

  return (
    <>
      <SearchBar>
        <SearchInput />
      </SearchBar>

      <SearchControls>
        <ActiveSearchFilters />

        <SearchControlsContainer>
          <FacetListSidebarToggleButton onClick={openMobileSidebar}>Show Search Filters</FacetListSidebarToggleButton>
          <ResetSearchButton />
        </SearchControlsContainer>
      </SearchControls>

      <SearchResults>
        <SearchResultsListContainer>
          <SearchResultsList render={children} />
        </SearchResultsListContainer>

        <FacetListSidebar show={showMobileSidebar} close={closeMobileSidebar}>
          <FacetList />
        </FacetListSidebar>
      </SearchResults>
    </>
  );
};
