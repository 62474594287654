import React from 'react';
import { Formik, Form } from 'formik';
import { FormFieldContainer, FormFieldSet, FormField, Label } from '../components/FormElements';
import { PrimaryButton } from '../components/Buttons';
import { ImageDimensionLabel, SixteenNineAspectRatioPlaceholder } from '../components/Layout';
import ImageCrop from '../components/ImageCrop';
import * as Yup from 'yup';

export default function CourseInformationForm({ course, categories, showSuccess, saveInformation }) {
  return (
    <Formik
      initialValues={course}
      enableReinitialize={true}
      validationSchema={Yup.object({
        name: Yup.string().max(256, 'Name is too long').required('A name is required'),
        description: Yup.string().max(32767, 'Description is too long'),
      })}
      onSubmit={saveInformation}
    >
      {({ dirty, isSubmitting, values, setValues }) => (
        <Form id="information-form">
          <FormFieldSet name={'Course Information'}>
            <FormFieldContainer>
              <Label>Poster</Label>
              <SixteenNineAspectRatioPlaceholder actionable={!values.posterImage}>
                <ImageCrop
                  defaultImage={values.posterImage}
                  onUpdate={image => setValues(prevValues => ({ ...prevValues, posterImage: image }), false)}
                  buttonText="Upload Poster"
                  aspectRatio={16 / 9}
                />
              </SixteenNineAspectRatioPlaceholder>
              <ImageDimensionLabel>Recommended: 405 x 227</ImageDimensionLabel>
            </FormFieldContainer>
            <FormField
              data-qa-hook="taskListSettingsName"
              label="Name"
              name="name"
              type="text"
              placeholder="Enter Name"
            />
            <FormField
              data-qa-hook="taskListSettingsDescription"
              label="Description"
              name="description"
              as="textarea"
              rows="10"
              placeholder="Enter Description"
            />
            <FormField data-qa-hook="taskListSettingsCategory" label="Category" name="category" as="select">
              {categories.map((category, i) => (
                <option key={i} value={category}>
                  {category}
                </option>
              ))}
            </FormField>
            <FormFieldContainer>
              {!dirty && (
                <PrimaryButton data-qa-hook="taskListSettingsSave" onClick={() => showSuccess('Information')}>
                  Save Information
                </PrimaryButton>
              )}
              {dirty && (
                <PrimaryButton
                  data-qa-hook="taskListSettingsSave"
                  type="submit"
                  disabled={isSubmitting}
                  operating={isSubmitting}
                >
                  Save Information
                </PrimaryButton>
              )}
            </FormFieldContainer>
          </FormFieldSet>
        </Form>
      )}
    </Formik>
  );
}
